import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Stepper } from "react-form-stepper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../../../auth/authSlice";
import CreateProductOfferDetails from "./CreateProductOFferDetail";
import CreateProductOfferLocation from "./CreateProductOfferLocation";
import CreateProductPublish from "./CreateProductPublish";
import { createProductOfferApiAsync } from "../../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { toast } from "react-toastify";

const PromotionalOffer = ({ close, offer }) => {
  const toastId = useRef(null);
  const [activeStep, setActiveStep] = useState(1);
  const [productDetails, setproductDetails] = useState(null);
  const [offerPrices, setOfferPrices] = useState({});
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [validVariants, setValidVariants] = useState([]);
  const [allAddress, setAllAddress] = useState([]);
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [offerDetails, setOfferDetails] = useState({
    start_date: new Date(),
    end_date: new Date(new Date().getTime() + 1),
    // offer_flag: "",
    price_flag: "",
    offerPrice: 0,
    quantity: 1,
  });
  const reset = () => {
    setOfferDetails({
      start_date: new Date(),
      end_date: new Date(new Date().getTime() + 1),
      // offer_flag: "",
      price_flag: "",
      offerPrice: 0,
      quantity: 1,
    });
  };
  const formattedVariants = useMemo(() => {
    if (!productDetails?.supplies || productDetails.supplies.length === 0) {
      return [];
    }

    return productDetails.supplies.flatMap((supply) =>
      (supply?.supply_variants || []).map((variant) => ({
        supply_id: supply?.id?.toString() || "",
        supply_variant_id: variant?.id?.toString() || "",
        attributes: variant?.attributes ? JSON.parse(variant.attributes) : {},
        prices: ["b2c", "pos", "b2b"].map((appName) => {
          const priceEntry = (supply?.supply_prices || []).find(
            (price) =>
              price?.app_name === appName &&
              price?.supply_variant_id === variant?.id
          );

          return {
            app_name: appName,
            actual_price: priceEntry?.selling_price?.toString() || "0",
            offer_price: offerPrices?.[variant.id]?.[appName] || "0", // Ensure offerPrices is defined
          };
        }),
        address_id: supply?.seller_address_id,
      }))
    );
  }, [productDetails, offerPrices]);
  const checkFieldsValidation = () => {
    for (let key in offerDetails) {
      if (offerDetails[key] == null || offerDetails[key] === "") {
        showToast(`${key} is required`);
        return false;
      }
    }
    if (!Array.isArray(formattedVariants) || formattedVariants.length === 0) {
      showToast("Invalid variant data.");
      return false;
    }
    const allAttributesNull = formattedVariants.every(
      (variant) =>
        !variant.attributes || Object.keys(variant.attributes).length === 0
    );
    if (allAttributesNull) {
      for (const variant of formattedVariants) {
        for (const price of variant.prices || []) {
          const actualPrice = Number(price.actual_price);
          const offerPrice = Number(price.offer_price);

          if (offerPrice === 0) {
            showToast(`Offer price for ${price.app_name} cannot be 0.`);
            return false;
          }
        }
      }
    } else {
      /**  If at least one variant has attributes, validate using validVariants */
      if (selectedVariants.length === 0) {
        showToast("Please select at least one variant.");
        return false;
      }
      const selectedVariantData = formattedVariants.filter((variant) =>
        selectedVariants.includes(Number(variant.supply_variant_id))
      );

      if (selectedVariantData.length === 0) {
        showToast("Selected variants do not match available variants.");
        return false;
      }

      for (const variant of selectedVariantData) {
        for (const price of variant.prices || []) {
          const actualPrice = Number(price.actual_price);
          const offerPrice = Number(price.offer_price);

          if (offerPrice === 0) {
            showToast(
              `Offer price for ${
                price.app_name
              } cannot be 0 of ${JSON.stringify(variant.attributes)}`
            );
            return false;
          }
        }
      }

      setValidVariants(selectedVariantData);
    }

    return true;
  };
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);

  console.log(activeStep, "activstep");

  const handleNext = () => {
    if (activeStep == 1) {
      if (!checkFieldsValidation()) return;
    } else if (activeStep == 2) {
      if (!validateStepTwo()) return;
      console.log("go second");
    } else if (activeStep == 3) {
      offerFunCall();
    }
    setActiveStep((prevStep) => Math.min(prevStep + 1, 3));
  };
  const validateStepTwo = () => {
    if (sellerAddressIds.length === 0) {
      showToast("Please select at least one seller address.");
      return false;
    }

    if (deliveryOptions.length === 0) {
      showToast("Please select at least one delivery option.");
      return false;
    }

    return true;
  };
  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 1));
  };
  // Handle step changes
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };

    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];
        // select default seller address if only one address
        let addressList = obj?.payload;
        setAllAddress(addressList || []);
        if (addressList?.length == 1) {
          // setSellerAddressIds([addressList[0]?.id]);
        }
      })
      .catch((obj) => {});
  };
  useEffect(() => {
    getAllAddress();
  }, []);
  const renderStepComponent = () => {
    switch (activeStep) {
      case 1:
        return (
          <CreateProductOfferDetails
            handleProduct={(product) => setproductDetails(product)}
            productDetails={productDetails}
            offerDetails={offerDetails}
            handleOfferDetails={(key, value) => {
              setOfferDetails({ ...offerDetails, [key]: value });
            }}
            reset={reset}
            setOfferPrices={setOfferPrices}
            offerPrices={offerPrices}
            selectedVariants={selectedVariants}
            setSelectedVariants={setSelectedVariants}
          />
        );
      case 2:
        return (
          <CreateProductOfferLocation
            allAddress={allAddress}
            sellerAddressIds={sellerAddressIds}
            setSellerAddressIds={setSellerAddressIds}
            deliveryOptions={deliveryOptions}
            setDeliveryOptions={setDeliveryOptions}
            formattedVariants={formattedVariants}
            validVariants={validVariants}
          />
        );
      case 3:
        return (
          <CreateProductPublish
            selectedproduct={productDetails}
            formattedVariants={formattedVariants}
            startDate={offerDetails.start_date}
            endDate={offerDetails.end_date}
            selectType={offerDetails.price_flag}
            validVariants={validVariants}
          />
        );
      default:
        return <div>Unknown Step</div>;
    }
  };
  let offerFunCall = () => {
    let variantsData = formattedVariants.every(
      (variant) =>
        variant.attributes && Object.keys(variant.attributes).length > 0
    )
      ? validVariants
      : formattedVariants;

    // **Remove attributes field**
    variantsData = variantsData.map(({ attributes, ...variant }) => variant);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        product_id: String(productDetails?.id),
        offer_validity: moment(offerDetails.end_date).format("YYYY-MM-DD"),
        offer_start_from: moment(offerDetails.start_date).format("YYYY-MM-DD"),
        actual_price: String(productDetails?.price),
        seller_address_ids:sellerAddressIds,
        delivery_options:deliveryOptions,
        // offer_price: priceDetails?.offerPrice,
        type: productDetails?.type==="physical"?"product":productDetails?.type,
        price_flag: offerDetails.price_flag,
        quantity:
          offerDetails.offer_flag == "per_box"
            ? Number(offerDetails?.quantity)
            : 1,
        category_id: String(productDetails?.category_id),
        sub_category_id: String(productDetails?.sub_category_id),
        brand_id: String(productDetails?.brand_id),
        variants: variantsData,
      },
    };

    // setButtonLoading(true)
    dispatch(createProductOfferApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        let obj = {
          productImg: productDetails?.image,
          id: productDetails?.id,
          name: productDetails?.name,
          offerId: res?.payload?.id,
          quantity: offerDetails?.quantity,
          offer_price: offerDetails?.offerPrice,
          offer_validity: moment(offerDetails.start_date).format("YYYY-MM-DD"),
          type: productDetails?.type,
          offer_flag: offerDetails.offer_flag,
        };
        offer(obj, "offer");
      })
      .catch((obj) => {
        // setButtonLoading(false)
        toast.error(obj.message, { id: toastId.current });
      });
  };
  return (
    <div className="productDetailsWrapper createOfferSection p-0">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-12">
            <div className="walletStats">
              <div className="createOfferHeader">
                <div className="row align-items-center">
                  <div className="col-10">
                    <h3 className="fontSize20 fw500 txtDarkblue">
                      Create Offer
                    </h3>
                    <p className="fontSize16 fw500 txtDarkblue mb-0">
                      Select one product and offer price wont be less than cost
                      price.
                    </p>
                  </div>
                  <div className="col-2 text-end">
                    <button
                      className="createOfferCloseBtn"
                      onClick={() => close()}
                    >
                      Close
                      <img
                        src={Images.lightCrossIcon}
                        alt="logo"
                        className="closeCrossIcon ms-1"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="createOfferContainer">
                <div
                  className={
                    activeStep == 3
                      ? "row h-100 justify-content-center"
                      : "row h-100"
                  }
                >
                  <div
                    className={
                      activeStep == 3 ? "col-xl-8 col-lg-10 col-12" : "col-md-8"
                    }
                  >
                    <div className="createOfferLeft">
                      <div className="stepperParent">
                        <Stepper
                          activeStep={activeStep}
                          onStepChange={handleStepChange}
                          steps={[
                            {
                              label: "Pricing",
                            },
                            { label: "Locations" },
                            { label: "Publish" },
                          ]}
                          disabledColor="#E4EBF1"
                          activeColor="red !important"
                        />
                      </div>

                      <div className="offerDetailBox createOffer_DetailBox">
                        {renderStepComponent()}

                        <div className="createOfferBtnsParent editVariantModal_Footer">
                          <button
                            className="modalDiscardBtn w-auto fontSize14"
                            onClick={handleBack}
                          >
                            <img
                              src={Images.backArrow}
                              alt="logo"
                              className="me-2"
                            />
                            Discard
                          </button>
                          <button
                            className={`modalNextBtn modalDiscardBtn w-auto ${productDetails?'active':''}  `}
                            onClick={handleNext}
                          >
                            Next
                            <img
                              src={Images.arrowTopRight}
                              alt="logo"
                              className="ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {activeStep !== 3 && (
                    <div className="col-md-4">
                      <div className="createOfferRight">
                        <h3 className="fontSize24 fw500 txtBlue mb-0">
                          Product Preview
                        </h3>
                        {productDetails && (
                          <>
                            <div className="productPreviewBox text-center">
                              <img
                                src={productDetails.image}
                                alt="img"
                                className="offerProductImg"
                              />
                              <h4 className="fontSize20 fw500 txtDarkblue mb-0 pt-4">
                                {productDetails?.name}
                              </h4>

                              <div className="d-flex align-items-center justify-content-center gap-2 pt-3">
                                <span className="greyDot"></span>
                                <h6 className="greyDotLabel mb-0">
                                  SKU{" "}
                                  {productDetails?.supply_variants?.[0]?.sku}
                                </h6>
                              </div>
                            </div>

                            <div>
                              <h3 className="fontSize16 fw500 txtDarkblue">
                                Product details
                              </h3>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Category
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {productDetails?.category?.name}
                                  </h3>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Sub-category
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {productDetails?.sub_category?.name}
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    SKU
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {productDetails?.supply_variants?.[0]?.sku}
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Barcode
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {
                                      productDetails?.supply_variants?.[0]
                                        ?.barcode
                                    }
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Unit Weight
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {productDetails?.weight} $
                                    {productDetails.weight_unit || ""}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionalOffer;