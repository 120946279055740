import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailBlue, phoneBlue, profileImg1 } from "../../../utilities/images";
import * as Images from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getProductServiceListApiAsync } from "../myProducts/productSlice";
import { addThousandSeparator } from "../../../utilities/thousandCommaSeparated";

const PosDetailsModal = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const posData = props?.selectedRow;

  const [allServiceData, setAllServiceData] = useState([]);

  const serviceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "service",
        filter_deleted: true,
        app_name: "merchant",
      },
    };
    setLoading(true);
    dispatch(getProductServiceListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        let selectedServicdIds = props?.selectedRow?.pos_staff_products?.map(
          (v) => v?.product_id
        );
        let serviceSelected = obj?.payload?.data?.filter((value) =>
          selectedServicdIds?.includes(value?.id)
        );
        setAllServiceData(serviceSelected);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    serviceHandle();
  }, []);
  return (
    <>
      {loading === true ? (
        <div className="pageLoader d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="d-flex align-items-start gap-3">
            <img
              // className="posUserDetailImg"
              className="employeeImg"
              src={
                posData?.user?.user_profiles?.profile_photo
                  ? posData?.user?.user_profiles?.profile_photo
                  : Images.userImg_
              }
              alt="user img"
            />
            <div>
              <h3 className="fontSize24 fw500 txtDarkblue">
                {(posData?.user?.user_profiles?.firstname
                  ? posData?.user?.user_profiles?.firstname
                  : "") +
                  " " +
                  (posData?.user?.user_profiles?.lastname
                    ? posData?.user?.user_profiles?.lastname
                    : "")}
              </h3>
              <h3 className="fontSize14 txtDarkblue ">
                <img className="me-2" src={Images.darkbluePhoneIcon} alt="" />
                {posData?.user?.user_profiles?.full_phone_number}
              </h3>
              <p className="fontSize14 txtDarkblue">
                <img className="me-2" src={Images.emailLogo} alt="" />
                {posData?.user?.email}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize20 fw500 txtDarkblue">Role Details</h3>

                <div className="contentappont">
                  <h5 className="fontSize16 txtLightBlue">Roles</h5>
                  <span className="fontSize16 txtLightBlue">
                    {posData?.user?.user_roles
                      ? posData?.user?.user_roles?.map((v, i) => {
                          return (
                            <React.Fragment key={i}>
                              {v?.role?.name +
                                (i === posData?.user?.user_roles?.length - 1
                                  ? ""
                                  : ", ")}
                            </React.Fragment>
                          );
                        })
                      : "No Role Selected"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize20 fw500 txtDarkblue">
                  Service Details
                </h3>
                <div className="contentappont">
                  <h5 className="fontSize16 txtLightBlue">Services</h5>
                  <span className="fontSize16 txtLightBlue">
                    {allServiceData?.length > 0
                      ? allServiceData?.map((v, i) => {
                          return (
                            <React.Fragment key={i}>
                              {v?.name +
                                (i === allServiceData?.length - 1 ? "" : ", ")}
                            </React.Fragment>
                          );
                        })
                      : "No Service Selected"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize20 fw500 txtDarkblue">Selected Color</h3>
                <div className="flexBox gap-1">
                  <h5 className="fontSize16 txtLightBlue mb-0">
                    Color Code :-
                  </h5>
                  <div className="flexBox gap-1">
                    <p className="fontSize14 txtLightBlue mb-0">
                      {props?.selectedRow?.color_code}
                    </p>
                    {/* <div
                      style={{
                        backgroundColor: props?.selectedRow?.color_code,
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                      }}
                    ></div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize18 fw500 txtDarkblue">Hourly Rate</h3>
                <div className="contentappont">
                  <span className="fontSize16 txtDarkblue">${`${addThousandSeparator(
                    props?.selectedRow?.hourly_rate
                      ? props?.selectedRow?.hourly_rate
                      : 0
                  )}`} hr</span>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize20 fw500 txtDarkblue">Overtime Rate</h3>
                <div className="contentappont">
                  <span className="fontSize16 txtLightBlue">${`${addThousandSeparator(
                    props?.selectedRow?.overtime_rate
                      ? props?.selectedRow?.overtime_rate
                      : 0
                  )}`} hr</span>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize20 txtDarkblue">Payment Cycle</h3>
                <div className="contentappont">
                  <span className="fontSize16 txtLightBlue">
                    {props?.selectedRow?.payment_cycle}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize20 fw500 txtDarkblue">Billing Type</h3>
                <div className="contentappont">
                  <span className="fontSize16 txtLightBlue">
                    {props?.selectedRow?.billing_type}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mt-4">
                <h3 className="fontSize20 fw500 txtDarkblue">Employee Type</h3>
                <div className="contentappont">
                  <span className="fontSize16 txtLightBlue">
                    {props?.selectedRow?.employment_type == "full_time"
                      ? "Full time"
                      : "Part time"}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mt-4">
                <div className="staffAvailability">
                  <h3 className="fontSize18 fw500 txtDarkblue">
                    Availability Details
                  </h3>
                  <div className="contentappont">
                    <span>
                      {props?.selectedRow?.pos_user_availability?.length > 0
                        ? props?.selectedRow?.pos_user_availability?.map(
                            (v, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {/* <p key={i}>{v?.day}: Start Time: {v?.opening_time ? v?.opening_time : '__'} - End Time: {v?.closing_time ? v?.closing_time : '__'}</p> */}
                                  <div className="scheduleFlex">
                                    <p className="fontSize18 businessUserName mb-0">
                                      {v?.day == 1
                                        ? "Monday"
                                        : v?.day == 2
                                        ? "Tuesday"
                                        : v?.day == 3
                                        ? "Wednesday"
                                        : v?.day == 4
                                        ? "Thursday"
                                        : v?.day == 5
                                        ? "Friday"
                                        : v?.day == 6
                                        ? "Saturday"
                                        : v?.day == 7
                                        ? "Sunday"
                                        : ""}
                                      :{" "}
                                    </p>
                                    <div className="flexBox gap-3">
                                      <div className="posShowTimeBox">
                                        {v?.opening_time
                                          ? v?.opening_time
                                          : "__"}{" "}
                                      </div>
                                      <div className="posShowTimeBox">
                                        {v?.closing_time
                                          ? v?.closing_time
                                          : "__"}
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          )
                        : "No Availability Details"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PosDetailsModal;
