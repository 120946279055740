import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguagesListApiAsync } from '../../systemConfig/systemConfigSlice';
import { unwrapResult } from '@reduxjs/toolkit';
const LanguageModal = ({ close }) => {
    const [allLanguageList, setAllLanguageList] = useState([
        {
            name: 'English',
            flag: Images.usa,
            country: 'United States',
              lang_code: 'en',
              selected: true,
          }
    ]);
    const [selectLanguage, setSelectLanguage] = useState([]);
    const [Loading, setLoading] = useState(false)
    const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
    const handleLanguage = (index) => {
        if (selectLanguage.includes(index)) {
            setSelectLanguage(selectLanguage.filter(i => i !== index));
        }
        else setSelectLanguage([...selectLanguage, index]);
    }
    const handleGetLanguages = () => {
        let data = {
          postData: {
            app_name: "merchant",
          },
          tokenData: auth?.payload?.token,
        };
        setLoading(true);
        dispatch(getLanguagesListApiAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            // const allLanguageList = obj?.payload?.filter(
            //   (item) => item?.lang_code != null
            // );
            // setGetLanguagesList(allLanguageList);
            // const selectedLanguageIds = getSelectedLanguages;
            // const getActiveLanguages = obj?.payload?.filter((item) =>
            //   selectedLanguageIds?.map((item) => item?.id)?.includes(item?.id)
            // );
            // setSelectedLanguages(getActiveLanguages?.map((item) => item?.id));
            setAllLanguageList(obj?.payload||[]);
            setLoading(false);
          })
          .catch((obj) => {
            setLoading(false);
          });
      };
      useEffect(()=>{
        // handleGetLanguages()
      },[])
    return <>
    <div className='mb-2'>
        <h3 className="text-center">
            Add a language
        </h3>
        <p>Select one or more language to add</p>
    </div>
        <div className="shippingModalParent">
            {
                allLanguageList?.map((language, index) => (
                    <div onClick={handleLanguage} key={index} className={`shippingBoxes ${selectLanguage.includes(index) ? "active" : ''}`}>
                        <figure className="shippingImgParent p-0">
                            <img
                                src={Images.language}
                                alt="img"
                                className="h-100 w-100"
                            />
                        </figure>
                        <div>
                            <h3 className="fontSize18  txtDarkblue">{language?.name
                            }</h3>
                            <h5 className="fontSize14 txtLightBlue mb-1">{language?.country
                            } </h5>
                        </div>
                    </div>
                ))}
        </div>
        <div className="d-flex align-items-center justify-content-between mt-3">
            <button onClick={() => {
                close();
            }} className="commonBlueBtn w-100 active">Cacnecl</button>
            <button onClick={() => {
                close();
            }} className="commonBlueBtn w-100 active">Select</button>
        </div>

    </>
}

export default LanguageModal