import React, { useEffect, useState } from "react";
import { selectLoginAuth } from "../auth/authSlice";
import { imageUploadAsync } from "../message/messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { imgGallery, modalCancel } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import ImgUploadedModal from "./imgUploadedModal";
import CustomLoader from "../../components/shared/CustomLoader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BrowseImgUpload from "../product/browseImgUpload";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";


const ProductImageAdd = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userData = location.state;
  let imageData;
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [selectedFlag, setSelectedFlag] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  const [fileBlob, setFileBlob] = useState();
  const [mainImage, setMainImage] = useState("");
  const [differentAngelsImage, setDifferentAngelsImage] = useState("");
  const [closeUpImage, setCloseUpImage] = useState("");
  const [inUseImage, setInUseImage] = useState("");
  const [styleImage, setStyleImage] = useState("");
  const [indexVal, setIndexVal] = useState("");
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const dispatch = useDispatch();
  const handleChange = (file) => {
    const image = file;
    uploadImage(image);
    //setFile(file);
  };

  console.log(imageUrl, "setImageIrrrrrrrrrrrrrrrrrr");

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  const cropImageFunc = (file, blobData) => {
    let data = {
      filePath: file,
      mimeType: blobData?.type,
      size: blobData?.size,
      originalname: blobData?.name,
    };
    let result = [...imageUrl];
    result[indexVal] = data;
    setImageUrl(result);
  };

  const uploadImage = (image) => {
    let formData = new FormData();
    let fileBlobs = fileBlob ? [...fileBlob] : [];
    const maxSize = 1.5 * 1024 * 1024; // 1.5MB limit

    // Check for oversized files
    const invalidFiles = Object.values(image).filter((file) => file.size > maxSize);
  
    if (invalidFiles.length > 0) {
      toast.error("File size should be less than 1.5MB");
      return; // Prevents API call
    }
    
    Object.values(image).forEach((val, index) => {
      formData.append(`file`, val);
      fileBlobs.push(val);
      setFileBlob(fileBlobs);
    });

    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    setLoading(true);
    if (image?.length <= 10) {
      dispatch(imageUploadAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          //   setMainImage(obj?.payload[0]?.filePath);
          imageData = {
            image: obj?.payload[0]?.filePath,
            images: obj?.payload?.map((val) => val.filePath),
          };
          props.imageData(imageData);
          setImageUrl([...imageUrl, ...obj?.payload]);
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg);
          }
        })
        .catch((obj) => {
          setLoading(false);
        });
    } else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please upload image less then 10");
      }
    }
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleSelectedImage = (e) => {
    const imageurls = imageUrl?.map((val) => val.filePath);

    if (selectedFlag === "mainImage") {
      setMainImage(e);
      imageData = {
        image: e,
        images: imageurls,
      };
      props.imageData(imageData);
    }
    if (selectedFlag === "styleImage") {
      setStyleImage(e);
    }
    if (selectedFlag === "differentAngelsImage") {
      setDifferentAngelsImage(e);
    }
    if (selectedFlag === "closeUpImage") {
      setCloseUpImage(e);
    }
    if (selectedFlag === "inUseImage") {
      setInUseImage(e);
    }
  };
  const setInitialData = () => {
    setMainImage(userData?.image ?? "");
    setImageUrl(
      userData?.product_images?.map((item) => {
        return { filePath: item?.url ?? "" };
      })
    );
  };
  useEffect(() => {
    if (userData?.from === "duplicateService") {
      setInitialData();
    }
  }, []);

  return (
    <div className="addProductContainer">
      <div className="product_uploadParent">
        <h3 className="fontSize18 fw500 txtDarkblue">{t("serviceImages")}</h3>
        <p className="fontSize14  txtDarkblue">{t("uploadServiceImages")}</p>
      </div>
      {loading ? (
        //    <CustomLoader/>
        <span className="spinner-border inner-spin spinner-border-sm spinnerDark"></span>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="product_Images">
              <div
                onClick={() => {
                  handleUserProfile("browseImageUpload");
                }}
                className="inputfile-box active"
              >
                {/* <input type="file" id="file" className="form-control inputfile d-none" name="images[]" data-id="1" accept="image/png, image/jpeg" multiple /> */}
                <label htmlFor="file">
                  <span id="file-name" className="file-box d-none">
                    {t("chooseFile")}
                  </span>
                  <div className="file-button">
                    {/* <img
                      src={imgGallery}
                      width="25"
                      id="profileImagePreview1"
                      className="beforeUpload"
                      alt=""
                    /> */}
                    <p className="uplImg_Inner">
                      {t("uploadFile")} <br /> {t("dragFile")}
                    </p>
                  </div>
                </label>
              </div>
              {/* <FileUploader
                            handleChange={handleChange}
                            name="file"
                            types={fileTypes}
                            classes="addUploadProduct"
                            multiple={true}
                            children={
                                <div className="inputfile-box active">
                                    <input type="file" id="file" className="form-control inputfile d-none" name="images[]" data-id="1" accept="image/png, image/jpeg" multiple />
                                    <label htmlFor="file">
                                        <span id="file-name" className="file-box d-none">Choose File</span>
                                        <div className="file-button">
                                            <img src={imgGallery} width="25" id="profileImagePreview1" className="beforeUpload" />
                                            <p className='subtextSmall_'>Drop your files here</p>
                                            <p className='linkTxt'>
                                                <u><b>Browse</b></u>
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            }
                        /> */}

              <div
                className={
                  mainImage
                    ? "fillUploadedFrames imgUploaded"
                    : "fillUploadedFrames"
                }
                onClick={() => {
                  setModalDetail({ show: true, flag: "ImageUpload" });
                  setKey(Math.random());
                  setSelectedFlag("mainImage");
                }}
              >
                {mainImage ? (
                  <img
                    src={mainImage}
                    className="uploadedImage"
                    alt="UploadImg"
                  />
                ) : (
                  <></>
                )}
                {/* <p className="mainImage">Main Image</p> */}
              </div>

              <div
                className={
                  differentAngelsImage
                    ? "fillUploadedFrames imgUploaded"
                    : "fillUploadedFrames"
                }
                onClick={() => {
                  setModalDetail({ show: true, flag: "ImageUpload" });
                  setKey(Math.random());
                  setSelectedFlag("differentAngelsImage");
                }}
              >
                {differentAngelsImage ? (
                  <img
                    src={differentAngelsImage}
                    className="uploadedImage"
                    alt="UploadImg"
                  />
                ) : (
                  <></>
                )}
                {/* <p className="mainImage">Close-Up</p> */}
              </div>

              <div
                className={
                  closeUpImage
                    ? "fillUploadedFrames imgUploaded"
                    : "fillUploadedFrames"
                }
                onClick={() => {
                  setModalDetail({ show: true, flag: "ImageUpload" });
                  setKey(Math.random());
                  setSelectedFlag("closeUpImage");
                }}
              >
                {closeUpImage ? (
                  <img
                    src={closeUpImage}
                    className="uploadedImage"
                    alt="UploadImg"
                  />
                ) : (
                  <></>
                )}
                {/* <p className="mainImage">Different Angel 1</p> */}
              </div>

              <div
                className={
                  inUseImage
                    ? "fillUploadedFrames imgUploaded"
                    : "fillUploadedFrames"
                }
                onClick={() => {
                  setModalDetail({ show: true, flag: "ImageUpload" });
                  setKey(Math.random());
                  setSelectedFlag("inUseImage");
                }}
              >
                {inUseImage ? (
                  <img
                    src={inUseImage}
                    className="uploadedImage"
                    alt="UploadImg"
                  />
                ) : (
                  <></>
                )}
                {/* <p className="mainImage">Different Angel 2</p> */}
              </div>

              {/* <div
                className={
                  styleImage
                    ? "fillUploadedFrames imgUploaded"
                    : "fillUploadedFrames"
                }
                onClick={() => {
                  setModalDetail({ show: true, flag: "ImageUpload" });
                  setKey(Math.random());
                  setSelectedFlag("styleImage");
                }}
              >
                {styleImage ? (
                  <img
                    src={styleImage}
                    className="uploadedImage"
                    alt="UploadImg"
                  />
                ) : (
                  <></>
                )}
                <p className="mainImage">In use</p>
              </div> */}
            </div>
          </div>
        </div>
      )}
      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "ImageUpload"
            ? "imageUpload"
            : modalDetail.flag === "browseImageUpload"
            ? "addProductOptions"
            : ""
        }
        size={modalDetail.flag === "ImageUpload" ? "xl" : "md"}
        child={
          modalDetail.flag === "browseImageUpload" ? (
            <BrowseImgUpload
              indexVal={setIndexVal}
              setMainImage={setMainImage}
              imageData={(e) => props.imageData(e)}
              loading={loading}
              uploadImageHandle={(e) => uploadImage(e)}
              fileBlob={fileBlob}
              selectedImage={(e) => handleSelectedImage(e)}
              images={imageUrl}
              setImageUrl={setImageUrl}
              setFileBlob={setFileBlob}
              indexId={indexVal}
              setCroppedImage={(e, data) => cropImageFunc(e, data)}
              setDifferentAngelsImage={setDifferentAngelsImage}
              setCloseUpImage={setCloseUpImage}
              setInUseImage={setInUseImage}
              setStyleImage={setStyleImage}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "ImageUpload" ? (
            <ImgUploadedModal
              close={() => handleOnCloseModal()}
              selectedImage={(e) => handleSelectedImage(e)}
              imageData={imageUrl}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "browseImageUpload" ? (
            <>
              <h4 className="fontSize24 txtDarkblue fw500">{t("uploadImage")}</h4>
              <p className="modal_cancel">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  src={Images.modalCross}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ProductImageAdd;
