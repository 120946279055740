import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Images from "../../../utilities/images";
import ReactSelect from "react-select";
import moment from "moment-timezone";

const EditOfferModal = (props) => {
  const {offerData}=props;
  const [showQuantity, setShowQuantity] = useState(false);
  const [offerDetails, setofferDetails] = useState({
    price_flag:'',
    offer_start_from:'',
    offer_validity:'',
    b2b:{
      actual_price:0,
      offer_price:0,
    },
    b2c:{
      actual_price:0,
      offer_price:0,
    },
    pos:{
      actual_price:0,
      offer_price:0,
    },
    offer_flag:'',
    quantity:1,
    id:null
  })
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(
    new Date().getDate() + 1
  ));
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );
  const handleUpdate=()=>{
    setLoading(true);
    setTimeout(() => {
      console.log(offerDetails);
      setLoading(false);
    }, 3000);
  }
  useEffect(()=>{
    let actualPrice=offerData?.actual_price;
    offerData?.supply_offers?.[0]?.supply_variant?.supply_offers
    ?.map((supply=>{
        handleDetailsChange({[supply?.app_name]:{
          offer_price:supply?.offer_price||actualPrice,
          actual_price :supply?.actual_price||actualPrice,
        }})
     
    }))

  setofferDetails(prev=>({
    ...prev,
    actual_price:actualPrice,
    offer_price:offerData?.offer_price,
    offer_flag:offerData?.offer_flag,
    price_flag:offerData?.price_flag,
    offer_start_from: new Date(offerData?.offer_start_from || new Date()),
    offer_validity: new Date(
      new Date(offerData?.offer_validity).getTime() < new Date().getTime()
    ? new Date() // Set to tomorrow
    : offerData?.offer_validity
    ),
    id:offerData?.id,
    quantity:offerData?.quantity
  }))
  console.log(offerData)
  if(offerData?.offer_flag=="per_box"){
    setShowQuantity(true);
  }
  },[offerData])
  const handleDetailsChange=(event,data)=>{
    if(event){
      // let key=event.target.name;
      // let value=event.target.value;
      // setofferDetails(prev=>({...prev,[key]:value}))
    }
   else if(data){
      setofferDetails(prev=>({...prev,...data}))
    }
  }
  const selectPriceType = [
    { value: "amount", label: "Amount" },
    { value: "percent", label: "Percentage" },
  ];
  const selectPriceOption = [
    { value: "per_box", label: "Per Box" },
    { value: "per_pack", label: "Per Pack" },
  ];
  return (
    <>
      <div>
        <h5 className="fontSize14 fw500 txtDarkblue">Product Name</h5>
        <h2 className="fontSize24 fw500 txtDarkblue mb-0 pt-2">
          {offerData?.product?.name}
        </h2>
      </div>
      <div>
        <h2 className="fontSize24 fw500 txtBlue commonPadding mb-0">
          Offer Details
        </h2>
        <h5 className="fontSize18 fw500 txtDarkblue mb-0">Period</h5>
      </div>
      <div className="row commonPadding">
        <div className="col-md-6">
          <div className="datePicker_Parent pt-0 offerModalDatePicker">
            <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
              Offer start from
            </label>
            <DatePicker
              showIcon
              selected={offerDetails.offer_start_from}
              onChange={(date) => {
                if(date>new Date(offerDetails.offer_validity)){
                  handleDetailsChange(null,{
                    offer_validity: date
                  })
                }
                handleDetailsChange(null, {
                  offer_start_from: date,
                });
              }}
              minDate={new Date()}
              // maxDate={new Date(new Date(offerDetails?.offer_validity) + 1)}
              customInput={<ExampleCustomInput className="example-custom-input" />}
            />
            <p className="pressEnterTxt pt-2 ps-4 mb-0">
              {offerDetails?.offer_start_from
                ? moment(offerDetails.offer_start_from).format('YYYY-MM-DD')
                : ''}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="datePicker_Parent pt-0 offerModalDatePicker">
            <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
              Offer end
            </label>
            <DatePicker
              showIcon
              minDate={offerDetails.offer_start_from}
              selected={offerDetails?.offer_validity}
              onChange={(date) => {
                handleDetailsChange(null, {
                  offer_validity: date,
                });
              }}
              customInput={<ExampleCustomInput className="example-custom-input" />}
            />
            <p className="pressEnterTxt pt-2 ps-4 mb-0">
            {offerDetails?.offer_validity
                ? moment(offerDetails.offer_validity).format('YYYY-MM-DD')
                : ''}
            </p>
          </div>
        </div>
      </div>

      <div className="periodTxtParent">
        <h5 className="fontSize18 fw500 txtDarkblue commonPadding pt-0 mb-0">
          Pricing
        </h5>
        <div className="editVariant_SecondBox">
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Offer price Option</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="reactSelectParent offerModalSelect">
                    <ReactSelect
                      options={selectPriceOption}
                      value={
                        selectPriceOption.filter(type=>type.value===offerDetails?.offer_flag)
                      }
                      onChange={(value) => {
                        if(value.value==="per_box"){
                          setShowQuantity(true);
                        }else{
                          setShowQuantity(false);
                          handleDetailsChange(null,{"quantity":1});
                        }
                        handleDetailsChange(null,{"offer_flag":value.value});
                      }}
                      placeholder="Different Offer Price"
                      classNamePrefix="react-select"
                      className="react-select-container anlyticSelect"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid #D7DEFF",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#263682" // Background color for selected option
                            : state.isFocused
                            ? "#f5f6fc" // Background color for hovered option
                            : "#fff", // Default background color
                          // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                          color: state.isSelected
                            ? "#fff" // Background color for selected option
                            : "#263682", // Text color
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#636E9F",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px",
                        }),
                      }}
                      // onChange={onChannelChange}
                      components={{
                        DropdownIndicator: () => (
                          <img src={Images.blueDownArrow} alt="drop_icon" />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Offer type</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="reactSelectParent offerModalSelect">
                    <ReactSelect
                      options={selectPriceType}
                      value={
                        selectPriceType.filter(type=>type.value===offerDetails?.price_flag)
                      }
                      onChange={(value) => {
                        handleDetailsChange(null,{"price_flag":value.value});
                      }}
                      placeholder="Price flag"
                      classNamePrefix="react-select"
                      className="react-select-container anlyticSelect"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid #D7DEFF",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#263682"
                            : state.isFocused
                            ? "#f5f6fc"
                            : "#fff",
                          color: state.isSelected ? "#fff" : "#263682",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#636e9f",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px",
                        }),
                      }}
                      // onChange={onChannelChange}
                      components={{
                        DropdownIndicator: () => (
                          <img src={Images.blueDownArrow} alt="drop_icon" />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">POS price</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox inputUpcBox">
                  <div className="inputGroup">
                    <input
                      className="productVariant_Input"
                      name="pos"
                      type="number"
                      value={
                        offerDetails.pos.offer_price
                      }
                      onChange={(event)=>{
                        let value=event.target.value;
                        handleDetailsChange(null,{
                          pos:{
                            ...offerDetails?.pos,
                            offer_price:Number(value)
                          }
                        })
                      }}
                    />
                    <span className="inputDollarSign">$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Marketplace price</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox inputUpcBox">
                  <div className="inputGroup">
                    <input
                      className="productVariant_Input"
                      name="b2c"
                      type="number"
                      value={
                        offerDetails.b2c.offer_price
                      }
                      onChange={(event)=>{
                        let value=event.target.value;
                        handleDetailsChange(null,{
                          b2c:{
                            ...offerDetails?.b2c,
                            offer_price:Number(value)
                          }
                        })
                      }}
                    />
                    <span className="inputDollarSign">$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">B2B Price</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox inputUpcBox">
                  <div className="inputGroup">
                    <input
                      className="productVariant_Input"
                      name="b2b"
                      type="number"
                      value={
                        offerDetails.b2b.offer_price
                      }
                      onChange={(event)=>{
                        let value=event.target.value;
                        handleDetailsChange(null,{
                          b2b:{
                            ...offerDetails?.b2b,
                            offer_price:Number(value)
                          }
                        })
                      }}
                    />
                    <span className="inputDollarSign">$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* quantity */}
          {
            showQuantity&& <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Quantity</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox inputUpcBox">
                  <div className="inputGroup">
                    <input
                      className="productVariant_Input"
                      name="quantity"
                      type="number"
                      value={
                        offerDetails.quantity
                      }
                      onChange={(event)=>{
                        let value=event.target.value;
                        handleDetailsChange(null,{
                          quantity:Number(value),
                        })
                      }}
                    />
                    <span className="inputDollarSign">$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>

      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
        disabled={loading}
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          Discard
        </button>
        <button
        disabled={loading}
        onClick={handleUpdate}
        className="modalNextBtn modalDiscardBtn active w-100">
          {/* show loading */}
          {loading && (
            <span className="spinner-border spinner-border-sm spinnerRed"></span>
          )}
          Update Offer
        </button>
      </div>
    </>
  );
};

export default EditOfferModal;
