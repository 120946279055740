import React, { useState } from "react";
import {
  Dimensions,
  Image,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Modal,
  Alert,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Spacer } from "./Spacer";
import { ArrowButton } from "./ArrowButton";
import { Button } from "./Button";
import { useEffect } from "react";
import moment from "moment-timezone";
import {
  calculateDuration,
  calculateTimeDuration,
  calculateTimeSlotSelection,
  getDaysAndDates,
} from "./GlobalMethods";
import {
  CALENDAR_MODES,
  CALENDAR_VIEW_MODES,
  APPOINTMENT_STATUS,
} from "../../../constants/bookings/allbooking";
import { memo } from "react";
import {
  bookingsDetails,
  getServiceTimeSlots,
  updateAppointmentStatus,
  reScheduleAppointment,
  setGetServiceTimeSlots,
  setReScheduleAppointment,
  onErrorStopLoad,
} from "../bookingSlice";
import { selectLoginAuth } from "../../auth/authSlice";
import { changeAppointmentStatusApiAsync, getBookingTimeSlotsApiAsync, resheduleBookingApiAsync } from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { drivefast_, orderDriver } from "../../../../utilities/images";
import ReactSelect from "react-select";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const { height, width } = Dimensions.get("window");
const windowWidth = Dimensions.get("window").width;
const ReScheduleDetailModal = ({
  showRecheduleModal,
  onCloseModal,
  appointmentData,
  setshowEventDetailModal,
  onAppointmentUpdate,
}) => {
  const dispatch = useDispatch();

  const authData = useSelector(selectLoginAuth);

  const sellerID = 
    authData?.payload?.uniqe_id
          ? authData?.payload?.uniqe_id
          : authData?.payload?.user?.unique_uuid;

  const appointmentDetail = appointmentData;
  const posUserDetails = appointmentData?.pos_user_details;
  const [selectedTimeSlotIndex, setselectedTimeSlotIndex] = useState(null);
  const appointmentsDetails = useSelector(bookingsDetails);
  const getStaffUsers = appointmentsDetails?.staffUsers;
  console.log(appointmentsDetails, "Appointments")
  const [posUserId, setposUserId] = useState(null);
  const [providerDetail, setProviderDetail] = useState(null);
  const [selectedTimeSlotData, setSelectedTimeSlotData] = useState("");
  const [selectedDate, setselectedDate] = useState(
    moment(appointmentData?.date).utc().format("YYYY-MM-DD")
  );

  const [preSelectedStartTime, setpreSelectedStartTime] = useState(
    appointmentData?.start_time
  );
  const [preSelectedEndTime, setpreSelectedEndTime] = useState(
    appointmentData?.end_time
  );
  const [timeSlotsData, setTimeSlotsData] = useState([]);

  const [selectedMonthData, setselectedMonthData] = useState(null);
  const [selectedYearData, setselectedYearData] = useState(null);

  const [monthDays, setmonthDays] = useState([]);
  const userDetails = appointmentData?.user_details;
  const invitedUserDetails = appointmentData?.invitation_details;
  const userAddress = userDetails?.current_address;

  const userId = appointmentData?.user_id;
  const customerDetails = userId != null ? userDetails : invitedUserDetails;
  //new flow
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const futureYears = Array.from(
    { length: 10 },
    (_, index) => currentYear + index
  );
  const yearOptions = futureYears.map((year) => ({ value: year, label: year }));

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);

    // date and slot clear select
    setselectedDate(null);
    setselectedTimeSlotIndex(null);
    setSelectedTimeSlotData("");
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);

    // date and slot clear select
    setselectedDate(null);
    setselectedTimeSlotIndex(null);
    setSelectedTimeSlotData("");
  };
  useEffect(() => {
    if (appointmentsDetails?.timeSlots) {
      const timeSlots = appointmentsDetails?.timeSlots?.filter(
        (timeSlot) => timeSlot?.is_available
      );
      setTimeSlotsData([...timeSlots]);
    }
  }, [appointmentsDetails?.timeSlots]);

  useEffect(() => {
    setProviderDetail(posUserDetails?.user);
    setposUserId(posUserDetails?.user?.unique_uuid);
  }, [posUserDetails]);

  useEffect(() => {
    // const params = {
    //   seller_id: sellerID,
    //   product_id: appointmentDetail?.product_id,
    //   date: selectedDate,
    //   pos_user_id: posUserId,
    // };
    if(!selectedDate)return;
    let slotData = {
      "postData": {
        "date": selectedDate,
        "product_id": appointmentDetail?.product_id,
        "seller_id": sellerID
      },
      "tokenData": authData?.payload?.token
    }
    dispatch(getServiceTimeSlots());
    dispatch(getBookingTimeSlotsApiAsync(slotData))
      .then(unwrapResult)
      .then(res => {
        dispatch(setGetServiceTimeSlots(res))
        if (res?.payload?.slots) {
          let availableSlots = res?.payload?.slots.filter(slot => slot?.is_available);
          setTimeSlotsData(availableSlots || []);

        }
      })
      .catch(err => {
        dispatch(onErrorStopLoad());

      })
  }, [posUserId, selectedDate]);

  useEffect(() => {
    const daysArray = getDaysAndDates(
      selectedYearData?.value,
      selectedMonthData?.value
    );
    setmonthDays(daysArray);
  }, [selectedMonthData, selectedYearData]);
  useEffect(() => {
    setSelectedYear({ value: currentYear, label: currentYear });
    setSelectedMonth({
      value: currentMonth,
      label: monthOptions[currentMonth - 1].label,
    });
  }, []);
  const isLoadingTimeSlot = appointmentsDetails?.serviceTimeSlotsLoading;

  const renderWeekItem = ({ item, index }) => {
    return <></>;

  };

  const renderSlotItem = ({ item, index }) => (
    <>
      {!item?.is_available ? (
        <Image
          source={
            "https://webpos-staging.jobr.com/_next/static/media/SearchIcon.0afe9d7c.svg"
            // "../images/calendarCell.svg"
          }
          resizeMode="contain"
          style={{ width: "24.1%", height: 23.5, marginVertical: 1.5 }}
        />
      ) : (
        <TouchableOpacity
          disabled={!item?.is_available}
          style={{
            borderWidth: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "23.5%",
            height: 32,
            borderColor:
              selectedTimeSlotIndex === index ? "#027547" : "#D7DEFF",
            backgroundColor:
              selectedTimeSlotIndex === index ? "#D1FADF" : "#fff",
            borderRadius: 8,
            margin: 1.5,
            overflow: "hidden",
          }}
          onPress={() => {
            setselectedTimeSlotIndex(index);
            setSelectedTimeSlotData(item);
          }}
        >
          {item?.is_available && (
            <Text
              style={{
                fontSize: 10,
                color: !item?.is_available
                  ? "#027547"
                  : selectedTimeSlotIndex === index
                    ? "#027547"
                    : "#636E9F",
              }}
            >
              {item?.start_time + " - " + item?.end_time}
            </Text>
          )}
        </TouchableOpacity>
      )}
    </>
  );

  const onReschuleAppointment = () => {
    if (!selectedTimeSlotData) {
      alert("Please select a time slot to rechedule the appointment");
      return;
    }

    const params = {
      appointmentId: appointmentData?.id,
    };

    params.requestData = {
      date: selectedDate,
      start_time: selectedTimeSlotData?.start_time,
      end_time: selectedTimeSlotData?.end_time,
      pos_user_id: posUserId,
    };
    let prams = {
      "postData": {
        'id': appointmentData?.id,
        date: selectedDate,
        start_time: selectedTimeSlotData?.start_time,
        end_time: selectedTimeSlotData?.end_time,
        pos_user_id: posUserId,
      },
      "tokenData": authData?.payload?.token
    }
    dispatch(reScheduleAppointment())
    dispatch(resheduleBookingApiAsync(prams))
      .then(unwrapResult)
      .then(res => {
        dispatch(setReScheduleAppointment(res))
        onAppointmentUpdate();

      })
      .catch(err => {
        dispatch(onErrorStopLoad());

      })
    onCloseModal();
    setshowEventDetailModal(false);
  };

  const onCancelAppoinment = () => {
    if (window.confirm("Do you want to cancel this appointment")) {
      handleCancelAppointment();
    } else {
    }
    // CustomAlert({
    //   title: "Do you want to cancel this appointment",
    //   onYesPress: handleCancelAppointment,
    // });
  };

  const updateBookingStatus = (appointmentId, status) => {
    let orderStatus = {
      postData: {
        id: appointmentId,
        status: status,
      },
      tokenData: authData?.payload?.token,
    };
    dispatch(
      changeAppointmentStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then(res => {
        if (res.status) {
          onAppointmentUpdate();

        }
      })
      .catch(err => { })

    // let params = {
    //   appointmentId,
    //   status,
    // };

  };

  const onClickServiceProvider = (item) => {
    setposUserId(item?.user?.unique_uuid);
    setProviderDetail(item?.user);
  };

  const handleCancelAppointment = () => {
    const appointmentID = appointmentDetail?.id ?? "";

    updateBookingStatus(appointmentID, APPOINTMENT_STATUS.REJECTED_BY_SELLER);
    onCloseModal();
    // setshowEventDetailModal(false);
  };

  const renderServiceProviderItem = ({ item }) => {
    const borderColor =
      item?.user?.unique_uuid === posUserId ? "transparent" : "#D7DEFF";
    const imageUrl = item?.user?.user_profiles?.profile_photo;
    const isPng = imageUrl?.toLowerCase().endsWith(".png");
    return (
      <TouchableOpacity
        onPress={() => onClickServiceProvider(item)}
        style={[
          styles.staffInfoContainer,
          {
            borderColor: borderColor,
            ...(item?.user?.unique_uuid === posUserId && {
              elevation: 5,
              shadowColor: "#ababab",
              shadowRadius: 1.41,
              shadowOpacity: 0.1,
              shadowOffset: {
                width: 0,
                height: 2,
              },
            }),
          },
        ]}
      >
        <Image
          source={
            imageUrl && isPng ? { uri: imageUrl } : "https://webpos-staging.jobr.com/_next/static/media/SearchIcon.0afe9d7c.svg" || "../images/userImages.png"
          }
          style={
            imageUrl && isPng
              ? styles.staffProfilePic
              : styles.staticEmployeeImages
          }
        />

        <Spacer horizontal space={5} />

        <View>
          <Text numberOfLines={1} style={styles.staffNameText}>{`${item?.user?.user_profiles?.firstname ?? ""
            } ${item?.user?.user_profiles?.lastname ?? ""}`}</Text>
          <Text numberOfLines={1} style={styles.occupationText}>
            {""}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 4000,

    customPaging: (i) => (
      <div
        style={{
          width: "8px",
          height: "8px",
          background: "#E4E6F2",
          borderRadius: "50%",
          marginTop: "20px",
        }}
        className="custom-dot"
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    <div className="rescheduleSection ">
      <div className="editAppointSub">
        <div className="topeditbooking_">
          <div className="userCheckin unpaidDetails requestBoxWrap tomorrowCart">
            <h6 className="userText">{"customer"}</h6>

            <figure className="appointmentDate w-auto scheduleDataBox">
              <img
                src={
          drivefast_
                
                  // Images.calenderIcon
                }
                alt="image"
                className="img-fluid appointmentCalender"
              />
              <span className="Ontime">
                {moment
                  .utc(appointmentData?.start_date_time)
                  .format("DD/MM/YY")}{" "}
                {calculateTimeDuration(appointmentData)}
              </span>
            </figure>
          </div>
          {customerDetails && (
            <div className="customerCheck d-flex mt-2">
              <figure className="profileImage">
                <img
                  src={customerDetails?.profile_photo ?? 
          drivefast_

                  // ) Images.defaultUser
                }
                  alt="customerImg"
                  width={50}
                  height={50}
                  className="img-fluid userImg40"
                />
              </figure>
              <div className="">
                <span className="innerHeading">
                  {(customerDetails?.firstname ?? "") +
                    " " +
                    (customerDetails?.lastname ?? "")}
                </span>
                <div className="">
                  {userAddress && (
                    <img
                      src={
          drivefast_
                      
                        // Images.locatePurple
                      }
                      alt="locate"
                      className="locate me-2"
                    />
                  )}

                  <span className="purpleText">
                    {(userAddress &&
                      `${userAddress?.city}, ${userAddress?.country}`) ||
                      customerDetails?.full_phone_number ||
                      "N/A"}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="slider-container mt-3 mb-4">
            <Slider {...settings}>
              {getStaffUsers?.map((item, index) => {
                const isSamePosUser = item?.user?.unique_uuid === posUserId;
                const borderColor = isSamePosUser ? "#12b76a" : "#D7DEFF";
                const imageUrl = item?.user?.user_profiles?.profile_photo;
                const isPng = imageUrl?.toLowerCase().endsWith(".png");
                return (
                  <div onClick={() => onClickServiceProvider(item)}>
                    <div
                      className={
                        isSamePosUser ? "sliderBox active" : "sliderBox"
                      }
                    >
                      <img
                        src={
                          imageUrl && isPng
                            ? { uri: imageUrl }
                            : drivefast_
                            // Images.userImages
                        }
                        alt="logo"
                        className="sliderImg"
                      />
                      <div>
                        <h3 className="fontSize12 fw500 txtDarkBlue mb-0">
                          {`${item?.user?.user_profiles?.firstname ?? ""} ${
                            item?.user?.user_profiles?.lastname ?? ""
                          }`}
                        </h3>
                        <h3 className="fontSize12 fw500 txtLightBlue mb-0 pt-1">
                          {item?.user?.user_roles?.[0]?.role?.name ?? "N/A"}
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        {/*MARK: Need to make UI Changes */}
        <div
          className="mt-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="serviceYear me-3">
            <ReactSelect
              options={yearOptions}
              value={selectedYear}
              onChange={handleYearChange}
              placeholder="Select a year"
              isSearchable={false}
            />
          </div>
          <div className=" serviceYear">
            <ReactSelect
              options={monthOptions}
              value={selectedMonth}
              onChange={handleMonthChange}
              placeholder="Select a month"
              isSearchable={false}
            />
          </div>
        </div>

        <div className="daycalendar">
          {/* <div className="serviceArrow">
            <Image
              src={Images.serviceLeft}
              alt="calendarimage"
              className="img-fluid"
            />
          </div> */}

          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              whiteSpace: "wrap",
              justifyContent: "center",
            }}
          >
            {monthDays?.map((item, index) => (
              <div
                className={
                  item?.completeDate === selectedDate
                    ? "serviceDate mx-3 active pointHand"
                    : "serviceDate mx-3 pointHand"
                }
                onClick={() => {
                  setselectedDate(item?.completeDate);
                  //Clear previous day selected time slot values
                  setselectedTimeSlotIndex(null);
                  setSelectedTimeSlotData("");
                }}
              >
                <h4 className="productName text-center"> {item?.day}</h4>
                <h4 className="dateText">
                  {item?.completeDate ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? "Today"
                    : item?.date}
                </h4>
              </div>
            ))}
          </div>
        </div>

        <div className="serviceDayTime">
          {isLoadingTimeSlot ? (
            <div className="mx-1 text-center mt-5">
              <span className="spinner-border spinner-border-sm "></span>
            </div>
          ) : timeSlotsData?.length == 0 ? (
            <div className="mx-1 text-center mt-5">
              <p>{"noSlot"}</p>
            </div>
          ) : (
            <>
              {selectedDate !== null ? (
                <div className="container">
                  {" "}
                  <div className="row" style={{ borderColor: "#027547" }}>
                    {timeSlotsData?.map((item, index) => {
                      return (
                        <>
                          <div
                            className=" col-lg-3 col-md-6 col-sm-3 editAppoinmentTimeParent"
                            key={index}
                            onClick={() => {
                              setselectedTimeSlotIndex(index);
                              setSelectedTimeSlotData(item);
                            }}
                          >
                            {item?.is_available ? (
                              <div
                                className={
                                  selectedTimeSlotIndex == index
                                    ? "scheduleTime active"
                                    : "scheduleTime"
                                }
                              >
                                <h3 className="addServicePara m-0 pointHand">
                                  {item?.start_time + " - " + item?.end_time}
                                </h3>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={
          drivefast_

                                    // Images.scheduleTimeImg
                                  }
                                  alt="scheduleTimeImg"
                                  className="newScheduleTimeImg"
                                />
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="mx-1 text-center mt-5">
                  <p>{"Please select any day to load time slots"}</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
    <div className="activateTaxBtn mt-2">
      <button
        className="serviceCancel w-100 "
        type="button"
        onClick={onCancelAppoinment}
      >
        {"cancel"}
      </button>
      <button
        className="nextverifyBtn w-100"
        type="button"
        onClick={() => onReschuleAppointment()}
      >
        {"saveChanges"}
        <img
          src={
          drivefast_
            // Images.ArrowRight
          }
          alt="rightArrow"
          className="img-fluid rightImg"
        />
      </button>
    </div>
  </>
  );
};

export default memo(ReScheduleDetailModal);

const styles = StyleSheet.create({
  staticEmployeeImages: {
    height: 21,
    width: 21,
    borderRadius: 21,
    resizeMode: "cover",
  },
  _eventTitle: {
    fontWeight: "600",
    fontSize: 12,
    color: "#263682",
  },
  eventAddress: {
    fontWeight: "600",
    fontSize: 10,
    color: "#7233C2",
    marginTop: 2,
  },
  eventAddressIcon: {
    height: 16,
    width: 16,
    resizeMode: "contain",
    tintColor: "#7233C2",
  },
  customerName: {
    fontWeight: "600",
    fontSize: 12,
    color: "#263682",
  },
  customerUserProfile: {
    height: 32,
    width: 32,
    resizeMode: "cover",
    borderRadius: 12,
  },
  customerDetailContainer: {
    paddingHorizontal: 10,
    // borderColor: COLORS.light_purple,
    // borderWidth: 1,
    paddingVertical: 5,
    borderRadius: 16,
  },
  //New Reschedule Modal
  addCartCon: {
    backgroundColor: "white",
    borderRadius: 10,
    width: windowWidth * 0.45,
    // paddingBottom: ms(15),
    paddingTop: 5,
    alignSelf: "flex-end",
    height: height * 0.8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  addCartConHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
    paddingHorizontal: 10,
    // borderBottomWidth: 1,
    // borderColor: COLORS.solidGrey,
    justifyContent: "space-between",
  },
  continueBtnCon: {
    height: 40,
    padding: 10,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    borderColor: "#ff0000",
    borderWidth: 1,
    backgroundColor: "transparent",
  },
  addToCartCon: {
    // width: "45%",
    height: 40,
    flex: 1,
  },
  detailBtnCon: {
    color: "#ff0000",
    fontSize: 14,
    fontWeight: "600",
  },
  addTocartText: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "600",
  },
  selected: {
    fontSize: 8,
    fontWeight: "600",
    color: "#14171A",
  },
  calendarIcon: {
    height: 18,
    width: 18,
    resizeMode: "contain",
    tintColor: "#263682",
  },
  bookedCalendarIcon: {
    height: 10,
    width: 10,
    resizeMode: "contain",
    tintColor: "#58C2E8",
  },
  staffNameText: {
    fontWeight: "600",
    fontSize: 10,
    color: "#263682",
  },
  occupationText: {
    fontWeight: "600",
    fontSize: 10,
    color: "#7E8AC1",
  },
  cancelSavebtnReschedule: {
    flex: 1,
    flexDirection: "row",
    marginTop: 10,
    paddingHorizontal: 32,
    alignSelf: "center",
    marginBottom: 10,
    width: "100%",
  },
  row: {
    flexDirection: "row",
  },
  rowAligned: {
    flexDirection: "row",
    alignItems: "center",
  },
  rowAlignedJustified: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeIconSmall: {
    height: 24,
    width: 24,
    tintColor: "#263682",
  },
  modalHeading: {
    fontWeight: "600",
    fontSize: 14,
    color: "#263682",
  },
  appointmentDateContainer: {
    height: 30,
    paddingHorizontal: 12,
    borderRadius: 20,
    backgroundColor: "#EFFBFF",
    borderColor: "#58C2E8",
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  bookedDate: {
    fontSize: 10,
    color: "#58C2E8",
  },
  staffInfoContainer: {
    borderWidth: 2,
    paddingHorizontal: 10,
    borderRadius: 20,
    height: 40,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 3,
    backgroundColor: "#fff",
    marginBottom: 3,
  },
  staffProfilePic: {
    width: 25,
    height: 25,
    borderRadius: 25,
    borderWidth: 2,
    borderColor: "#E4E6F2",
  },
});
