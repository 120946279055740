import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useEffect } from "react";
import {
  getSinglePosUserAsync,
  updateCustomerApiAsync,
  updateInvitedCustomerApiAsync,
  updateMerchantApiAsync,
  //   updateMerchantApiAsync2,
} from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../../message/messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import { toast } from "react-toastify";
import * as Images from "../../../../utilities/images";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useTranslation } from "react-i18next";

const EditProfileModal = (props) => {
  const { t } = useTranslation();
  const [document, setDocument] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [showDataOnly, setShowDataOnly] = useState('');
  const [isDocUploading, setisDocUploading] = useState(false);
    const [phoneNo, setPhoneNo] = useState("");
     const [phoneCode, SetPhoneCode] = useState("");
   const [country, setCountry] = useState({
    id:101
   });
   const [currentState, setCurrentState] = useState(null);
  const toastId = useRef(null);
  const { userInvitationData } = { ...props };
  const { userDetails } = props;
  const [hide, setHide] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    apt:"",
    state:"",
    state_code:"",
    format_address:"",
    country:"",
    country_code:"",
    city:"",
    zipcode:""
  });
  useEffect(()=>{
    if(userDetails){
      updateForm({email:(userDetails?.user?.email||userDetails?.email), ...userDetails?.user?.user_profiles})
    }
  },[userDetails]);
  const updateForm=(userDetail)=>{
    setDocument(userDetail?.profile_photo);
    setFormData(prev=>({
      ...prev,
      firstname:userDetail?.firstname,
      lastname:userDetail?.lastname,
      apt:userDetail?.current_address?.apt,
      ...(document&&{
        profile_photo:document
      }),
      ...(userDetail?.profile_photo&&({
        profile_photo:userDetail?.profile_photo
      })),
      state:userDetail?.current_address?.state,
      state_code:userDetail?.current_address?.state_code||userDetail?.current_address?.state?.substr(0,2),
      format_address:userDetail?.current_address?.format_address||"India",
      country:userDetail?.current_address?.country,
      country_code:userDetail?.current_address?.country_code,
      city:userDetail?.current_address?.city,
      zipcode:userDetail?.current_address?.zipcode,

    }))
    setShowDataOnly({
      phone_no: userDetail?.phone_no,
      phone_code: userDetail?.phone_code,
      email: userDetail?.email,
    })
  }
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
    
  };
  let imageFile;
  const handleChange = (e) => {
    e.preventDefault();
    imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    // setDataLoading(true)
    setisDocUploading(true);
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setisDocUploading(false)
        setDataLoading(false);
        setDocument(obj?.payload[0]?.filePath);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
      })
      .catch((obj) => {
        setisDocUploading(false);
        setDataLoading(false);
      });
  };
const handleSubmit=()=>{
  if(loading ||isDocUploading)return;
  let isFiledEmpty=checkIfFieldEmpty(formData);
  if(isFiledEmpty){
    toast.error(`${isFiledEmpty} is required`);
    return;
  }
  let params={
    postData: {
      firstname:formData.firstname,
      lastname:formData.lastname,
      ...((document||formData?.profile_photo)&&{
        profile_photo:document||formData?.profile_photo
      }),
      current_address:{
      apt:formData.apt,
      format_address:formData?.format_address||"india",
      country:formData.country,
      state:formData.state,
      state_code:formData?.state_code,
      country_code:formData.country_code,
      city:formData.city,
      zipcode:formData.zipcode,
      },
      id: auth?.payload?.user?.user_profiles?.user_id
        ? auth?.payload?.user?.user_profiles?.user_id
        : auth?.payload?.user_profiles?.user_id,
    },
    tokenData: auth?.payload?.token,
  }
setLoading(true);
dispatch(updateMerchantApiAsync(params))
    .then(unwrapResult)
    .then(res=>{
      toast.success("User details updated successfully");
      setLoading(false);
      // props?.getUserPosDetails();
      props?.close();
    })
    .catch(err=>{
      toast.error(err?.response||"Error updating user details");
      setLoading(false);
    })
}

const handlechange=(event,data)=>{
  if(event){
  let  name=event.target.name;
  let  value=event.target.value;
    setFormData(prev=>({
      ...prev,
      [name]:value
    }))
  }else if(data){
    setFormData(prev=>({
      ...prev,
      ...data
    }))
  }
}
const autoCompleteHandleChange = (address) => {
 setFormData(prev=>({
  ...prev,
  format_address:address
 }))
 
};
const autoCompleteHandleSelect = (address) => {
  geocodeByAddress(address)
      .then((results) => {
       
          fillAddressDetails(results);
        
      })
      .catch((error) => {
      });
};
const fillAddressDetails = (results) => {
  // setAddress(results[0].formatted_address);
  let format_address=results[0].formatted_address;
  setFormData(prev=>({
    ...prev,
    format_address:format_address
  }))
  

  for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
          setFormData(prev=>({
            ...prev,
            zipcode:results[0].address_components[j].short_name
          }))
      } else if (results[0].address_components[j].types[0] == "locality") {
          setFormData(prev=>({
            ...prev,
            city:results[0].address_components[j].long_name
          }))
          
      } else if (
          results[0].address_components[j].types[0] ==
          "administrative_area_level_1" ||
          results[0].address_components[j].types[0] ===
          "administrative_area_level_3" ||
          results[0].address_components[j].types[0] === "locality"
      ) {
          setFormData(prev=>({
            ...prev,
            state:results[0].address_components[j].long_name,
            state_code:results[0].address_components[j]?.short_name
          }))
      } else if (results[0].address_components[j].types[0] == "country") {
          setFormData(prev=>({
            ...prev,
            country:results[0].address_components[j].long_name,
            country_code:results[0].address_components[j].short_name
          }))
      } else if (results[0].address_components[j].types[0] == "street_number") {
       
          setFormData(prev=>({
            ...prev,
            apt:results[0].address_components[j].long_name
          }))
      } else if (results[0].address_components[j].types[0] == "route") {
          
      }
  }
};
const checkIfFieldEmpty = (obj) => {
  let keyName = null;
  for (let key in obj) {
      if (obj[key] instanceof Object) continue; // Skip nested objects
      if (obj[key] == null || obj[key] === "") {
          keyName = key;
          break;
      }
  }
  return keyName;
};
  return (
    <>
      <div className="editConsumerContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="editConsumerModalLeft">
                <div className="text-center">
                <div className="uploadStaffImg">
                    <div className="showImg_Parent">
                      {document && (
                        <img src={document} className="staff_Profile_Img" />
                      )}

                      <label htmlFor="file-upload" className="uploadFileLabel">
                        <img
                          src={Images.editIcon}
                          alt="img"
                          className="uploadStaffCameraImg"
                        />
                      </label>

                      <input
                        id="file-upload"
                        type="file"
                        accept="image/jpeg, image/png"
                        name="profile_image"
                        className="d-none"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <h2 className="fontSize24 fw500 txtDarkblue mb-3">
                    {
                      formData?.firstname+" "+
                      formData?.lastname
                    }
                  </h2>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                        {t('firstNameSin')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="Marie"
                          type="text"
                          name="firstname"
                          value={formData?.firstname}
                          onChange={(event) => handlechange(event,null)}
                
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                        {t('lastNameSin')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="Jenkins"
                          type="text"
                          name="lastname"
                          value={formData?.lastname}
                          onChange={(event) => handlechange(event,null)}
                    
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="phone-numbpart">
                  <div className="country-plugin">
                    <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                      {t('phoneNumber')}
                    </label>
                    <div id="result">
                <PhoneInput
                  country="us"
                  value={ showDataOnly.phone_code + showDataOnly.phone_no}
                  // enableSearch={true}
                  onlyCountries={["us", "in"]}
                  name={generateRandomName}
                  placeholder="Phone no."
                  autoComplete="off"
                  disabled={true}
                  
                />
              </div>
                    <img className="phnInputIcon" src={Images.commentTextImg2} alt="" />
                  </div>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                    {t('emailAddress')}
                  </label>
                  <div className="inputGroup position-relative">
                  <img className="streeMailIcon" src={Images.editEmailImg} alt="" />
                    <input
                      className="customInput modalInput iconInput"
                      placeholder="hello@email.com"
                      type="text"
                      name="email"
                      value={showDataOnly?.email}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center cancelSaveBtn">
                  <button
                  disabled={loading}
                    className="modalDiscardBtn w-100"
                    onClick={() => props?.close()}
                  >
                    {t('cancel')}
                  </button>
                  <button 
                  disabled={loading}
                  onClick={handleSubmit}
                  
                   className="modalNextBtn modalDiscardBtn active w-100">
                    {/* show loader */}
                    {loading && (
                    <span className="spinner-border spinner-border-sm spinnerRed"></span>
                    )}
                    {t('save')}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="editConsumerModalRight">
                <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                  {t('streetAdd')}
                </label>
                <div className="inputGroup">
                  <img className="streeMailIcon" src={Images.editprofLocImg} alt="" />
                  <PlacesAutocomplete
                    className=""
                    value={formData.format_address}
                    autoComplete="off"
                    onChange={autoCompleteHandleChange}
                    onSelect={autoCompleteHandleSelect}
                    searchOptions={{
                      componentRestrictions: {
                        country: ["us", "ind"],
                      },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Street Address",
                            className:
                              "location-search-input customform-control countrytags_ iconInput",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                backgroundColor: "#41b6e6",
                                cursor: "pointer",
                              }
                              : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={index}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                  {t('building')}
                  </label>
                  <div className="inputGroup">
                  <img className="streeMailIcon" src={Images.editprofLocImg} alt="" />
                    <input
                      className="customInput modalInput iconInput"
                      placeholder="Building, Interior"
                      type="text"
                      name="apt"
                      value={formData?.apt}
                      onChange={(event) => handlechange(event,null)}
                      
                    />
                  </div>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                    {t('country')}
                  </label>
                  <div className="countrySelect">
              <CountrySelect
                containerClassName="form-group"
                inputClassName=""
                defaultValue={
                  {
                    name:formData.country,
                    iso3:formData.country_code,
                    iso2:formData.country_code
                  }
                }
                onChange={(_country) => {
                  setCountry(_country)
                  handlechange(null,{
                    country:_country?.name,
                    country_code:_country.
                    iso2
                    
                  })
                
                }}
                onTextChange={(_txt) => console.log(_txt)}
                placeHolder="Select Country"
              />
            </div>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                    {t('state')}
                  </label>
                  <div className="countrySelect">
                                <StateSelect
                                countryid={country?.id}
                                  containerClassName="form-group"
                                  inputClassName=""
                                  onChange={(_state) => {
                                    handleChange(null,{
                                      state:_state?.name,
                                      state_code:_state.state_code
                                    })
                                  }}
                                  onTextChange={(_txt) => console.log(_txt)}
                                  defaultValue={{
                                    name:formData.state,
                                    state_code:formData.state_code
                                  }}
                                  placeHolder="Select State"
                                />
                        {/* <input
                          className="customInput modalInput"
                          placeholder="State"
                          type="text"
                          name="state"
                          value={formData?.state}
                          onChange={(event) => handlechange(event,null)}
                          
                        /> */}
                              </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                        {t('city')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="City"
                          type="text"
                          name="city"
                          value={formData?.city}
                          onChange={(event) => handlechange(event,null)}
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2 ps-4">
                        {t('zipCode')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="Zip Code"
                          name="zipcode"
                          type="text"
                          value={formData?.zipcode}
                          onChange={(event) => handlechange(event,null)}
                          
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfileModal;
