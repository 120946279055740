import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { forgetpassimg } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import ScheduleModal from "./ScheduleModal";
import {
  forgetPasswordAsync,
  verifyOtpAsync,
  verifyPasswordAsync,
} from "./authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import PhoneInput from "react-phone-input-2";
import VerifyModal from "./verifyModal";
import * as Images from "../../../utilities/images";
import SuccessFullyCreated from "./successfulModal/SuccessFullyCreated";

const ForgetPassword = () => {
  const [key, setKey] = useState(Math.random());
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");
  const [otp, setOtp] = useState("");
  const [userId, SetUserId] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const dispatch = useDispatch();
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(30)

  const toastId = React.useRef(null);
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };

  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
    if (phoneNumber?.length == 10) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  };
  useEffect(() => {
    if(String(otp).length === 5 && otp !== "" ) {
      handleSubmit()
    }
  }, [otp])


  const handleSubmit = async (e) => {
    
    if (!phoneCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select your phone code");
      }
      return false;
    }
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your phone number");
      }
      return false;
    }
    if (!userId) {
      e.preventDefault();
      let data = {
        type: "phone",
        phone_code: `+${phoneCode}`,
        phone_no: phoneNo,
      };
      setLoading(true);
      await dispatch(forgetPasswordAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          SetUserId(obj?.payload?.user_id);
          setButtonActive(false);
          
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg);
          }
        })
        .catch((obj) => {
          setLoading(false);
        });
    } else {
      if (otp.length < 5) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Otp must be of 5 digits");
        }
        return;
      }
      let data = {
        user_id: userId,
        reset_password_otp: otp,
      };
      setLoading(true);
      dispatch(verifyPasswordAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          // if (!toast.isActive(toastId.current)) {
          //   toastId.current = toast.success(obj?.msg);
          // }
          setResendOtp(0)
          handleOpenModal("SuccessFullyCreated")
          setTimeout(() => {
            history.push(`/resetPassword/${obj?.payload?.verification_id}`);
          }, 4000)
          // props.details("Successful")
        })
        .catch((obj) => {
          setLoading(false);
        });
    }
  };
  // Send OTP to the customer
  const sendOtp = () => {
    let data = {
      "type": "phone",
      "phone_code": `+${phoneCode}`,
      "phone_no": phoneNo
    }
    setLoadingOtp(true)
    dispatch(forgetPasswordAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoadingOtp(false)
        SetUserId(obj?.payload?.user_id)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        setResendOtp(30)
        // setModalDetail({ show: true, flag: 'ScheduleModal' });
        // setKey(Math.random());
      }
      )
      .catch((obj) => {
        setLoadingOtp(false)
      })
  }

  useEffect(() => {
    let otpTimer = setInterval(() => {
      if (resendOtp > 0) {
        setResendOtp(resendOtp - 1)
      }

    }, 1000)

    return () => {
      clearInterval(otpTimer)
    }
  }, [resendOtp]);

  return (
    <>
      <div className="commonLogin forgetcommon">
        <div className="loginForm authFormCommonWidth">
          <div className="loginhead">
            {!userId ? (<>
              <h1 className="fontSize30 txtDarkblue">
                Verify your phone <br /> number.
              </h1>
              <h4 className="fontSize18 txtLightBlue mb-0">
                Enter your phone number to get started.
              </h4>
            </>) : (<>
              <h1 className="fontSize30 txtDarkblue">
                Let's Verify your phone <br /> number.
              </h1>
            </>)}
          </div>
          <form
            autoComplete="nope"
            className="form_horizontal w-100"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            {!userId && <div className="form-group pb-5 pt-2">
              <div className="phone-numbpart">
                <div className="country-plugin">
                  <div id="result" className="countryCodePickerParent">
                    <PhoneInput
                      country="us"
                      onlyCountries={["us", "in"]}
                      // value={phoneNo}
                      name={generateRandomName()}
                      autoComplete="new-password"
                      // enableSearch={true}
                      placeholder="Phone no."
                      onChange={(value, data, event, formattedValue) => {
                        onChangePhoneNumber(value, data, event, formattedValue);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>}
            {userId ? (
              <VerifyModal
                phoneCode={phoneCode}
                phoneNo={phoneNo}
                buttonActive={(e) => setButtonActive(e)}
                SetUserId={SetUserId}
                otp={otp}
                setOtp={setOtp}
              />
            ) : (
              <></>
            )}

            <div className="d-flex gap-2 col-12 mx-auto">
              <button
                className="forgetPass_BackBtn w-100 d-flex align-items-center justify-content-center"
                onClick={() => {
                  history.push("/");
                }}
              >
                <img
                  src={Images.leftArrowColored}
                  alt="logo"
                  className="me-2"
                  height={"12px"}
                  width={"12px"}
                />
                Back
              </button>
              {!userId && <button
                className={`loginBtnSign w-100 d-flex align-items-center justify-content-center `}
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Next
                <img src={Images.upArrow} alt="logo" className="ms-2" />
              </button>}

              {userId &&
                <div
                
                  onClick={resendOtp <= 0 ? sendOtp : void 0}
                  style={{
                    fontSize: "18px",
                    color: "#7E8AC1",
                  }}
                  className="w-100 d-flex align-items-center justify-content-center resendCodeBtn">{
                    resendOtp > 0 ?
                      <span style={{
                        color: "#7E8AC1",
                        cursor: "pointer",
                        gap: "4px"
                      }}>{resendOtp}s to resend code</span>
                      :
                      <span  style={{
                        border: "1px solid #263682",
                        borderRadius: "30px",
                        color: "#263682",
                        padding: "18px 20px 18px 20px",
                        cursor: "pointer",
                        gap: "4px"
                      }}>Resend Code</span>
                  }</div>
              }
            </div>
          </form>
        </div>
        <div className="copyRight">
          <p>All Rights Reserved by JOBR LLC | 2022</p>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "ScheduleModal" ? "scheduleModal" : modalDetail.flag === "SuccessFullyCreated" ? "SuccessFullyCreated" : ""}
        // size={modalDetail.flag === "UnitoutTableProductModal" ? "xl" : "md"}
        child={
          modalDetail.flag === "ScheduleModal" ? (
            <ScheduleModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "SuccessFullyCreated" ? (
            <SuccessFullyCreated
             tittle="Successfully verified"
             handleClose={() => handleOnCloseModal()}/>
          ) : ""
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ForgetPassword;
