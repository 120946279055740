import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { generatePlaidTokenApiAsync, getBankAccountApiAsync, linkBankAccountAsync, selectWalletProfile } from '../dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { usePlaidLink } from 'react-plaid-link';
import DashboardNavbar from '../DashboardNavbar';

const AddBankAccount = () => {
    const history = useHistory()
    const [linkToken, setLinkToken] = useState("")
    const [bankDetails, setBankDetails] = useState([])
    const auth = useSelector(selectLoginAuth)
    const [loading, setLoading] = useState(false)
    const walletProfile = useSelector(selectWalletProfile)
    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            // send public_token to server
            let params = {
                postData: {
                    "plaid_public_token": public_token
                },
                tokenData: auth?.payload?.token
            }
            setLoading(true)
            dispatch(linkBankAccountAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    dispatch(getBankAccountApiAsync(auth?.payload?.token))
                        .then(unwrapResult)
                        .then((obj) => {
                            setBankDetails(obj?.payload)
                            setLoading(false)
                        }
                        )
                        .catch((obj) => {
                            setLoading(false)
                        })
                }
                )
                .catch((obj) => {
                    setLoading(false)
                })
        },
    });
    const dispatch = useDispatch()

    const getBankAccountHandle = () => {
        setLoading(true)
        dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setBankDetails(obj?.payload)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getBankAccountHandle()
        dispatch(generatePlaidTokenApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLinkToken(obj?.payload?.link_token)
            }
            )
            .catch((obj) => {
            })
    }, [])
    return (
        <>
            {loading === true ?
                <span className="spinner-border inner-spin spinner-border-sm"></span>
                :
                <div className='addBankAccount'>
                    {/* <div className='topHeader_'>
                        <div className="topStickyHeader_ mb-3">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <h2 className="mainHeading">
                                        <Link to="/plans"> <i className="las la-long-arrow-alt-left me-3"></i></Link>
                                        <span>Add bank account</span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <DashboardNavbar title="Add bank account" />
                    <div className='backContainer'>
                        <div className='row '>
                            {bankDetails?.length > 0 ? <>
                                {
                                    bankDetails?.map((val, index) => {
                                        return (
                                            // <div className='accountDetails' key={index}>
                                            //     <h4 className="textInnerDark mb-1">A/c No. - <span className='textinner_'>{val?.account_number}</span></h4>
                                            //     <h4 className="textInnerDark ">A/c Holder Name: <span className='textinner_'>{val?.account_owner_name}</span></h4>
                                            // </div>
                                            <div className='col-lg-4' key={index}>
                                                <div className='accountDetails addaccountBox_ mb-3'>
                                                    <figure className='bankIcon_'><i className="fas fa-university"></i></figure>
                                                    <div className='acountDetails_'>
                                                        <h4 className="textInnerDark mb-2">A/c No. - <span className='textinner_'>{val?.account_number}</span></h4>
                                                        <h4 className="textInnerDark mb-2">A/c Holder Name: <span className='textinner_'>{val?.account_owner_name}</span></h4>
                                                        <h4 className="textInnerDark ">Bank Name: <span className='textinner_'>{val?.bank_name}</span></h4>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </> :


                                <div className='updatedSuccessfully'>
                                    <div className='checkmark-circle mb-4'>
                                        <span className='checkmarkCircleIcon'></span>
                                    </div>
                                    <p className='text'>Documents are uploaded successfully</p>
                                </div>
                            }
                            <div className='row mt-4 justify-content-center'>

                                <button className='primary_btn mb-2 me-3' onClick={() => open()} disabled={!ready}> {!ready && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                    &nbsp;&nbsp;
                                    Add Bank account</button>


                                {bankDetails?.length > 0 && <button type="submit" className='primary_btn mb-2' onClick={() => history.push('/new-user')}>
                                    Continue</button>}

                            </div>
                        </div>
                    </div>
                </div>


            }</>
    )
}

export default AddBankAccount