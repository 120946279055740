import React, { forwardRef, useState } from "react";
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";
import DatePicker from "react-datepicker";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PlacesAutocomplete, {
    geocodeByAddress,
  } from "react-places-autocomplete";

const AddEmployementDetails = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [phoneCode, SetPhoneCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [hide, setHide] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const [address, setAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [countryValue, setCountryValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [aptValue, setAptValue] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setDisplayValue(results[0]?.formatted_address?.split(",")[0]);
        fillAddressDetails(results);
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());
        setHide(true);
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const fillAddressDetails = (results) => {
    // setAddress(results[0].formatted_address);
    setFormattedAddress(results[0].formatted_address);

    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCityValue(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
          "administrative_area_level_1" ||
        results[0].address_components[j].types[0] ===
          "administrative_area_level_3" ||
        results[0].address_components[j].types[0] === "locality"
      ) {
        setStateValue(results[0].address_components[j].long_name);
        setStateCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountryValue(results[0].address_components[j].long_name);
        setCountryCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "street_number") {
        setAptValue(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "route") {
        setAddress(results[0].address_components[j].long_name);
      }
    }
  };

  const autoCompleteHandleChange = (address) => {
    console.log(address, "address");
    setFormattedAddress(address);
    setZipCode("");
    setCityValue("");
    setStateValue("");
    setCountryValue("");
    setAptValue("");
    setAddress("");
  };
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <div className="d-flex align-items-center gap-2">
          <img
            src={Images.lightCalenderLogo}
            alt="img"
            width={24}
            height={24}
          />
          {value}
        </div>
        <img
          src={Images.blueDownArrow}
          width={24}
          height={24}
          alt="drop_icon"
        />
      </button>
    )
  );

  const selectOption = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  return (
    <form className="addStaffForm employmentDetails">
      <h3 className="fontSize24 fw500 txtDarkblue addStaffHeading mb-0">
        Employment Details
      </h3>
      <div className="row">
        <div className="col-md-12">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2">
              Job title
            </label>
            <div className="inputGroup">
              <input
                className="customInput"
                placeholder="POS Sales"
                name="text"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize14 fw500 txtDarkblue mb-2">
                Manager
              </label>
              <ReactSelect
                options={selectOption}
                isSearchable={true}
                placeholder="Select one"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2">
              Start Date
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<ExampleCustomInput className="datePickerInput" />}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2">
              Employee ID
            </label>
            <div className="inputGroup">
              <input
                className="customInput"
                placeholder="NA"
                name="text"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2">
              One time password
            </label>
            <div className="inputGroup">
              <input
                className="customInput"
                placeholder="NA"
                name="text"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize14 fw500 txtDarkblue mb-2">
                Actively
              </label>
              <ReactSelect
                options={selectOption}
                isSearchable={true}
                placeholder="Select one"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form_group">
            <div className="staffEmailInput position-relative">
              <label className="fontSize14 fw500 txtDarkblue mb-2">
                Work e-mail Address
              </label>
              <input
                type="email"
                className="customInput"
                name="confirmEmail"
                placeholder="hello@email.com"
              />
              <img
                src={Images.authEmailLogo}
                alt="img"
                className="createAccInputLogo"
              />
            </div>
          </div>
        </div>
      </div>

      <h3 className="fontSize24 fw500 txtDarkblue addStaffHeading mb-0">
      Work address
      </h3>
      <div className="row">
      <div className="col-md-12">
          <div className="form_group">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
              Business 
              </label>
              <ReactSelect
                options={selectOption}
                isSearchable={true}
                placeholder="Select your business"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue ps-4 mb-2">
              Street Address
            </label>
            <div className="inputGroup">
              {hide ? (
                <span
                  className="customform-control w-100"
                  onClick={() => setHide(!hide)}
                >
                  {displayValue}
                </span>
              ) : (
                <PlacesAutocomplete
                  className=""
                  autoComplete="off"
                  value={formattedAddress}
                  onChange={autoCompleteHandleChange}
                  onSelect={autoCompleteHandleSelect}
                  searchOptions={{
                    componentRestrictions: {
                      country: ["us", "ind"],
                    },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="position-relative ">
                      <input
                        {...getInputProps({
                          placeholder: "Street, Ave, West...",
                          className:
                            "location-search-input customform-control apartmentInput",
                        })}
                      />
                      <img
                        src={Images.loctionicn}
                        alt="img"
                        className="apartmentimg"
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#41b6e6",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={index}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
              Apartment/Suite
            </label>
            <div className="position-relative">
              <input
                className="customform-control apartmentInput"
                placeholder="Building, Interior"
                name={generateRandomName}
                autoComplete="new-password"
                type="text"
                value={aptValue}
                onChange={(e) => setAptValue(e.target.value)}
              />
              <img
                src={Images.apartmentLogo}
                alt="img"
                className="apartmentimg"
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
              Country
            </label>
            <div className="countrySelect">
              <CountrySelect
                containerClassName="form-group"
                inputClassName=""
                onChange={(_country) => setCountry(_country)}
                onTextChange={(_txt) => console.log(_txt)}
                placeHolder="Select Country"
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
              State
            </label>
            <div className="countrySelect">
              <StateSelect
                countryid={country?.id}
                containerClassName="form-group"
                inputClassName=""
                onChange={(_state) => setCurrentState(_state)}
                onTextChange={(_txt) => console.log(_txt)}
                defaultValue={currentState}
                placeHolder="Select State"
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
              City
            </label>
            <input
              className="customform-control id_password"
              placeholder="City"
              name={generateRandomName}
              autoComplete="new-password"
              type="text"
              value={cityValue}
              onChange={(e) => setCityValue(e.target.value)}
            />
            {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group ">
            <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
              Zip Code
            </label>
            <input
              type="number"
              className="customform-control"
              name={generateRandomName}
              autoComplete="new-password"
              placeholder="Zip code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddEmployementDetails;
