import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import ChartArea from "../dashboard/wallet/ChartArea";
import * as Images from "../../../utilities/images";
import { Link, useHistory } from "react-router-dom";
import MainHeader from "../../components/UI/Orders/MainHeader";
import { options1 } from "../../constants/bookings/overview";
import { PayrollHeaderStatisticsArr } from "../../constants/payrollAndStaff/overview";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import { getTotalOrdersGraphAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { onErrorStopLoad, setGetStaffUsers } from "../bookings/bookingSlice";

const PayrollOverview = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sellerAddress, setsellerAddress] = useState("");
  const [filterValue, setFilterValue] = useState("week");

  const [appointmentGraphData, setAppointmentGraphData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [totalAmount, settotalAmount] = useState(0)
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  let [dataSets, setDataSets] = useState([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [key, setKey] = useState(Math.random());
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());
  };

  

  const handleFilterValue = (value) => {
    setFilterValue(value);
  };
  const handleDateValue = (values) => {
  
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };
  const handleSellerAddress = (value) => {
    setsellerAddress(value);
  };
  // get all staff graph
  const [staffgraphs, setStaffgraphs] = useState(null);
  const [checked, setChecked] = useState({
    pos: true,
    delivery: true,
    shipping: true,
  });
  const getAllOrdersGraph = async () => {
    setLoading(true);
    let prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
        // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18",
        ...(startDate == null && { filter: filterValue }),
        ...(startDate && { start_date: startDate, end_date: endDate }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getTotalOrdersGraphAsync(prams))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        console.log("get orders graph", obj?.payload);
        setStaffgraphs(obj?.payload);
        setDataSets(handleDataSet(obj.payload?.graphData?.datasets) || []);
        settotalAmount(obj?.payload?.totalAmount
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleDataSet = (datasets) => {
    const colors = ["#4659B5", "#F0C01A", "#58C2E8"];
    let labels = ["POS Orders", "Delivery Orders", "Shipping Orders"];
    return datasets?.map((data, index) => ({
      fill: true,
      label: labels[index],
      data: data?.data,
      borderColor: colors[index],
      backgroundColor: "#FFFFFF00",
      cubicInterpolationMode: "monotone",
    }));
  };
  const TotalOrderAnalytics = {
    labels: staffgraphs?.graphData?.labels,
    datasets: dataSets,
  };
  useEffect(()=>{
    //initial load
    getAllOrdersGraph();
  },[filterValue, endDate, sellerAddress])
  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: staffgraphs?.graphData?.datasets
            ? staffgraphs.graphData.datasets[num]?.data
            : "",
          borderColor: color,
          backgroundColor: "#FFFFFF00",
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
  };
// get staff users list
const [staffUserListDetails, setstaffUserListDetails] = useState([]);

const getUserPosDetails = async () => {
  const prams = {
    postData: {
      seller_id: auth?.payload?.uniqe_id
      ? auth?.payload?.uniqe_id
      : auth?.payload?.user?.unique_uuid,
      limit:5,
      page:1
      
    },
    tokenData: auth?.payload?.token,
  };
  dispatch(getPosUsersAsync(prams))
    .then(unwrapResult)
    .then((res) => {
      dispatch(setGetStaffUsers(res));
      setstaffUserListDetails(res?.payload?.pos_staff || []);
   
    })
    .catch((err) => {
      dispatch(onErrorStopLoad());
    });
};
useEffect(()=>{
  getUserPosDetails();
},[])
  return (
    <section className="payrollOverviewSection">
      <DashboardNavbar
      setKeyword={()=>{}}
      title="Payroll & Staff" show={false} />
      <div className="bookingsOverview_ payrollHeaderParent">
        <div className="commonMainHeaderParent">
          <MainHeader
            handleFilter={handleFilterValue}
            handleDate={handleDateValue}
            seller_id={"29d0e7bc-af42-4505-bcea-3e54817fcb2d"}
            tokenData={auth?.payload?.token}
            enableFutureDate={true}
            handleLocation={handleSellerAddress}
            filterValue={filterValue}
            subhead="vs. Same day last week"
          />
        </div>
        {loading ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="verificationTotal commonMainHeaderParent">
              <div className="row">
                {PayrollHeaderStatisticsArr?.map((headerStatic, index) => (
                  <HeaderStatistics
                    className={headerStatic?.className}
                    img={Images?.[headerStatic?.img]}
                    value={300}
                    heading={headerStatic?.heading}
                  />
                ))}
              </div>
            </div>

            <div className="walletStats tradesValue">
              <div className="row">
                <div className="col-md-12">
                  <div className="totalOrders_">
                    <div className="chartHeader_ chartHeaderBox pt-0">
                      <div className="leftorderValue_">
                        <h3 className="textInnerHead mb-0">Total Orders</h3>
                        <h3 className="Innerhead">${totalAmount}</h3>
                        <Link to="/team-members">
                          <button className="viewAllbtn_" type="submit">
                            View All
                          </button>
                        </Link>
                      </div>
                      <div className="graphChecked_">
                        <div className="graphBox_ me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll2"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "POS Orders",
                                0,
                                "#4659B5"
                              );
                              setChecked({
                                ...checked,
                                pos: checked.pos === true ? false : true,
                              });
                            }}
                            checked={checked.pos}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll2"
                            className="checkBoxTxt masterChecked"
                          >
                            POS orders
                          </label>
                        </div>
                        <div className="checkYellow me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll3"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            checked={checked.delivery}
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "Delivery Orders",
                                1,
                                "#F0C01A"
                              );
                              setChecked({
                                ...checked,
                                delivery:
                                  checked.delivery === true ? false : true,
                              });
                            }}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll3"
                            className="checkBoxTxt "
                          >
                            Delivery orders
                          </label>
                        </div>

                        <div className="skyblueBox_ me-3 ">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll3412"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            checked={checked.shipping}
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "Shipping Orders",
                                2,
                                "#58C2E8"
                              );
                              setChecked({
                                ...checked,
                                shipping:
                                  checked.shipping === true ? false : true,
                              });
                            }}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll3412"
                            className="checkBoxTxt "
                          >
                            Shipping orders
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <ChartArea
                          className="img-fluid"
                          header=""
                          options={options1}
                          data={TotalOrderAnalytics}
                          chartType="Line"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="sellingProductContainer payrollOverviewTableParent mt-3 h-auto">
        <div className="flexBox gap-2">
          <h2 className="fontSize20 txtBlue fw500 mb-0">Top Staffs</h2>
          <Link to="/team-members">
                          <button className="viewAllbtn_" type="submit">
                            View All
                          </button>
                        </Link>
        </div>

        <div className="commonTable sellingProductTable table-responsive mt-3">
          <table className="w-100">
            <tbody>
              {
                staffUserListDetails?.length>0?staffUserListDetails.map((staff,index)=>{
                  let userDetails=staff?.user?.user_profiles;
                 return <tr>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={Images.teamMember2}
                        alt="img"
                        className="staffImg"
                      />
                      <div className="text-start">
                        <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1">
                          {
                            userDetails?.firstname
                          }
                        </h3>
                        <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                          {userDetails?.lastname}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td className="text-end">
                    <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">{
                      staff?.weekly_tracking_limit
                    }</h5>
                    <h6 className="fontSize10 fw500 txtLightBlue mb-0">Hours</h6>
                  </td>
                </tr>
              }):<tr>
                  <td colSpan="2" className="text-center">No data available</td>
                </tr>
              }
            
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PayrollOverview;
