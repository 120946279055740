export const resultSelectOption = [
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
];
export const checkIfFieldEmpty = (obj) => {
    let keyName = null;
    for (let key in obj) {
      if (obj[key] instanceof Object) continue; // Skip nested objects
      if (obj[key] == null || obj[key] === "") {
        keyName = key;
        break;
      }
    }
    return keyName;
};
 export const legalPoliciesFlag={
    privacy_policy:"Privacy Policy",
    terms_and_conditons:"Terms & Conditions",
    discount_coupon_policy:"Discount Coupon Policy",
    return_policy:"Return Policy",
    shipping_policy:"Shipping Policy",
    cancellation_policy:"Cancellation Policy"
  }