import React from "react";
import * as Images from "../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const TopStaff = (props) => {
  const { t } = useTranslation();
  const { topStaff, staffLoading, setStaffLimit } = props;
  const history = useHistory();
  return (
    <div className="col-md-6  mb-4 mb-md-0">
      <div className="sellingProductContainer">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="fontSize20 txtBlue fw500 mb-0">{t('topStaffs')}</h2>
          {Array.isArray(topStaff) &&
            topStaff?.length > 0 && (
              <button
                className="viewAllBtn"
                onClick={() => {
                  // setStaffLimit(null);
                  history.push("/posUser");
                }}
              >
                {t('viewAll')}
              </button>
            )}
        </div>

        <div className="commonTable sellingProductTable table-responsive mt-3">
          <table className="w-100">
            <tbody>
              {staffLoading ? (
                <tr>
                  <td colSpan={2}>
                    <div className="d-flex align-item-center justify-content-center">
                      <span className="spinner-border spinner-border-md spinnerDark "></span>
                    </div>
                  </td>
                </tr>
              ) : topStaff?.length > 0 ? (
                topStaff.map((value, idx) => (
                  <tr key={idx}>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={
                            value?.pos_user_detail?.user_profiles?.profile_photo
                              ? value?.pos_user_detail?.user_profiles
                                  ?.profile_photo
                              : Images.profileImg1
                          }
                          alt="img"
                          className="staffImg"
                        />
                        <div>
                          <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1 text-start">
                            {`${value.pos_user_detail.user_profiles?.firstname} ${value.pos_user_detail.user_profiles?.lastname}`}
                          </h3>
                          <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                            {
                              value?.pos_user_detail?.user_roles?.[0]?.role
                                ?.name
                            }
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td className="text-end">
                      <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">
                        {Math.floor(value?.total_working_minutes / 60)}h{" "}
                        {value?.total_working_minutes % 60}m
                      </h5>
                      <h6 className="fontSize10 fw500 txtLightBlue mb-0">
                        {t('hours')}
                      </h6>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex align-iten-center justify-content-center">
                        <h4 className="fontSize14 fw400 txtLightBlue mb-0 ps-1">
                          {t('noRecordfound')}
                        </h4>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TopStaff;
