import React, { useState } from "react";
import moment from "moment-timezone";
import {
  locationBlue,
  profileImg1,
  clockimg_,
} from "../../../utilities/images";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import CheckedInModal from "./CheckedInModal";
const CheckinModal = (props) => {
  let data = props?.data;
  const statusChange = (id, status) => {
    props.changeStatusOfAppointment(id, status);
    if (!props.loading2) {
      props.close();
    }
  };
  let userDetails=data?.user_details||data?.invitation_details;
  return (
    <div className="checkInModalSection scheduleModal">
      <img
        src={Images.modalCross}
        alt="img"
        className="inventoryModalCancleImg cursorPointer"
        onClick={() => props?.close()}
      />
      <div className="text-center">
        <img src={Images.skyBlueCheckInImg} alt="img" />
        <h3 className="fontSize24 fw500 txtDarkblue pt-3">Check In</h3>
        <p className="fontSize18 txtLightBlue">
          Confirm the details of your appointment
        </p>
      </div>
      <div className="checkInCustomerDetail">
        <div className="InnercheckIn d-flex justify-content-between gap-2">
          <div>
            <h5 className="fontSize14 fw500 txtDarkblue">Customer:</h5>
            <div className="flexBox gap-2">
              <img
                src={userDetails?.profile_photo || Images.userImg_}
                className="userImgMid"
                alt="img"
              />
              <div>
                <h3 className="fontSize16 fw500 txtDarkblue">
                  {userDetails?.firstname +
                    " " +
                    userDetails?.lastname}
                </h3>
                <h6 className="flexBox fontSize14 fw500 mb-0 gap-1 locationText">
                  <img
                    src={Images.locateOrder}
                    alt="img"
                    className="location"
                  />
                  {userDetails?.current_address?.city},
                  {userDetails?.current_address?.country}
                </h6>
              </div>
            </div>
          </div>
          <h3 className="unpaidTxt fontSize14 txtDarkblue mb-0">
            {
            // data?.mode_of_payment == "cash" ? "Unpaid" :
             "Paid"}
          </h3>
          {/* {data?.user_details ? (
            <div className="d-flex align-items-center">
              <img
                className="userInnerImg"
                src={
                  data?.user_details?.profile_photo
                    ? data?.user_details?.profile_photo
                    : profileImg1
                }
              />

              <div className="ms-2">
                <span className="haedingsemiBold_ ">
                  {data?.user_details?.firstname +
                    " " +
                    data?.user_details?.lastname}
                </span>
                {data?.user_details?.current_address && (
                  <p className="mb-0">
                    <img
                      src={Images.locationBlue}
                      className="img-fluid me-2 custImg"
                    />
                    <span className="contentSmallTxt">
                      {data?.user_details?.current_address?.city},
                      {data?.user_details?.current_address?.country}
                    </span>
                  </p>
                )}
              </div>
            </div>
          ) : (
            <>Not Available</>
          )} */}
          {/* <div className="contentappont">
            <h5 className="smallHeading mb-2">Assigned:</h5>
            <div className="d-flex align-items-center">
              <img
                className="userInnerImg"
                src={
                  data?.pos_user_details?.user?.user_profiles?.profile_photo
                    ? data?.pos_user_details?.user?.user_profiles?.profile_photo
                    : profileImg1
                }
              />
              <div className="ms-2">
                <span className="haedingsemiBold_">
                  {data?.pos_user_details?.user?.user_profiles?.firstname +
                    " " +
                    data?.pos_user_details?.user?.user_profiles?.lastname}
                </span>
                <p className="mb-0">
                  <span className="contentSmallTxt">
                    {data?.pos_user_details?.is_staff_member === true
                      ? "Staff"
                      : "Non-staff"}
                  </span>
                </p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flexBox justify-content-between mt-3 mb-3">
          <h5 className="fontSize14 fw500 txtDarkblue mb-0">
            Service Requested:
          </h5>
          <div className="flexBox flex-wrap gap-2">
            {/* <h5 className="checkInServiceRequest">Haircut</h5> */}
            <h5 className="checkInServiceRequest">{data?.product_name}</h5>
          </div>
        </div>
        <div className="checkInServiceParent pb-3">
          <h5 className="fontSize14 fw500 txtDarkblue mb-3">Service Time:</h5>
          <div className="flexBox gap-3">
            <div className="flexBox fontSize12 fw500 txtDarkblue">
              <span className="flexBox">
                <img
                  className="img-fluid clockimg_ me-2"
                  alt="clock"
                  src={Images.customCalendar}
                  width={16}
                  height={16}
                />
                {moment.utc(data?.date).format("dddd")},
              </span>
              <span> {moment.utc(data?.date).format("MMMM DD, YYYY")} </span>
            </div>
            <div className="flexBox gap-2">
              <img src={Images.blueClockImg} alt="img" />
              <span className="fontSize12 fw500 txtDarkblue">
                {data?.start_time + "-" + data?.end_time}
              </span>
            </div>
          </div>
        </div>
        {/* <div className="appointInner mb-2">
          <div className="contentappont">
            <span className="smallHeading">Duration : </span>
            <span className="timeText">
              {data?.approx_service_time + " min"}
            </span>
          </div>
        </div> */}
        <div className="flexBox justify-content-between pt-3">
          <h6 className="fontSize14 fw500 txtDarkblue mb-0">Total</h6>
          <h6 className="fontSize14 fw500 txtDarkblue mb-0">{`$ ${data?.price}`}</h6>
        </div>
      </div>

      <div className="flexBox gap-3 justify-content-between">
        <button
          className="commonBorderBtn w-100"
          type="submit"
          onClick={() => {
            props.changeStatusOfAppointment(data?.id, 4);
          }}
        >
          Decline
        </button>
        <button
          className="commonBlueBtn active w-100 flexBox gap-1"
          type="submit"
          onClick={() => {
            props.changeStatusOfAppointment(data?.id, 2);
            // setModalDetail({ show: true, flag: "checkedIn" });
            // setrandomKey(Math.random());
            props.open();
          }}
        >
          Confirm
          <img src={Images.closeTag} alt="img" />
        </button>
      </div>
    </div>
  );
};

export default CheckinModal;