import React, { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";


const GoogleMapView = ({ latitude, longitude, destination }) => {
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [map, setMap] = useState(null);
  const [mapStatus, setMapStatus] = useState(false);
  let showDefault=latitude==39.747747108122034;
  // Ensure valid center values
  const center = {
    lat: (latitude + Number(destination?.lat || 0)) / 2,
    lng: (longitude + Number(destination?.lng || 0)) / 2,
  };

  // Ensure destination is valid before creating options
  const directionsOptions = destination?.lat && destination?.lng
    ? {
        origin: { lat: latitude, lng: longitude },
        destination: { lat: destination.lat, lng: destination.lng },
        travelMode: "DRIVING",
      }
    : null;

  // Map Load Callback
  const onLoadCallback = useCallback((map) => {
    setMap(map);
  }, []);

  // Directions Callback
  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === "OK") {
        setDirectionsResponse(response);
      } else {
        console.error("Directions request failed:", response);
      }
    }
  };

  // Reset directions if necessary
  useEffect(() => {
    if (!directionsResponse) {
      setDirectionsResponse(null);
    }
  }, [directionsResponse, destination]);

  return (
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMap
          zoom={showDefault?4:15}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          center={center}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={onLoadCallback}
        >
          {/* Only request directions if we have a valid destination */}
          {directionsOptions && !directionsResponse && (
            <DirectionsService
              options={directionsOptions}
              callback={directionsCallback}
            />
          )}

          {/* Render directions when available */}
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div>
  );
};

export default GoogleMapView;
