import React, { useState } from 'react'
import * as Images from "../../../../utilities/images";
import Loader from '../../../components/UI/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { getUserSettingsApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';

const AdditionalSetting = () => {
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
   const [userSettings, setUserSettings] = useState(null);
    const [loading, setLoading] = useState(false);
    const getUserSettings=async()=>{
        let prams={
            postData: {
                app_name:"merchant",
                seller_id: auth?.payload?.uniqe_id
                ? auth?.payload?.uniqe_id
                : auth?.payload?.user?.unique_uuid,
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setUserSettings(res?.payload);
        setLoading(false);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getUserSettings();
    },[])
    const updateUserSettings=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        setUserSettings(res.payload);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const handleUserSetting=(key,value)=>{
        setUserSettings(prev=>({
            ...prev,[key]:value
        }));
        updateUserSettings({[key]:value});
    }
  return <>
  {loading&&
                <Loader/>
               }
  <div className='generalSetting_'>
            <div className="processignOrder featureseting">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">Sync booking</h3>
                    <p className="textInnerHead2">Enable synchronization with external calendars (e.g, Google Calendar).</p>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.enable_sync_google_calender} onChange={(event)=>handleUserSetting("enable_sync_google_calender",event.target.checked)} />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable sync to google calendar</h3>
                                <p className="textInnerHead2">Enable synchronization with external calendars</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                    <div className='shiperror_'>
                        <img className='shiperror_img' src={Images.info_error} />
                        <p className='shipPapra_'>No Integration Found</p>
                    </div>
                    </div>
                </div>
            </div>
    </div>
  </>
}

export default AdditionalSetting