import React from 'react'
import * as Images from "../../../../utilities/images";

const Breaks = () => {
  return <div className='orderProcessing_ '>
      {/* <div className=''> */}
      <div className="processignOrder">
      <div className='ordrsettingHead_  '>
                        <h3 className='normalHeading_ mb-1 d-flex align-items-center justify-content-between'>
                            <span>
                            OverTime
                            </span>
                           <button className='bg-body'>
                           <img src={Images.editIcon} alt="logo" className="ms-2" />
                           </button>

                        </h3>
                        <p className='textInnerHead2'>Use these settings to define plugin general settings and default settings for your services and appointments</p>
                    </div>
                    <div className="row mt-4">
                    <div class="align-items-center border rounded row">
                        <div class="align-items-center align-self-lg-stretch border-end col-2 d-flex">
                            <h3 class="fontSize14 txtDarkblue m-0">Break Name</h3>
                            </div><div class="amountvalue_ col-10 border-none" style={{border: "none"}}>
                                
    <input type="text" class="customform-control" placeholder="100"/><span class="mindollr">$</span></div>
    </div>
    <div class="align-items-center border rounded row">
                        <div class="align-items-center align-self-lg-stretch border-end col-2 d-flex">
                            <h3 class="fontSize14 txtDarkblue m-0">Break Time</h3>
                            </div><div class="amountvalue_ col-10 border-none" style={{border: "none"}}>
                                
    <input type="text" class="customform-control" placeholder="100"/><span class="mindollr">$</span></div>
    </div>
    <div class="align-items-center border rounded row">
                        <div class="align-items-center align-self-lg-stretch border-end col-2 d-flex">
                            <h3 class="fontSize14 txtDarkblue m-0">Compentation</h3>
                            </div><div class="amountvalue_ col-10 border-none" style={{border: "none"}}>
                                
    <input type="text" class="customform-control" placeholder="100"/><span class="mindollr">$</span></div>
    </div>
                    </div>
      </div>
      

</div>
}

export default Breaks