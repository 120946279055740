import React, { useState } from "react";
import * as Images from "../../../../utilities/images";
import { calculateTimeDuration } from "./GlobalMethods";
import CustomModal from "./CustomModal";
import CheckedInModal from "./CheckedInModal";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CheckinModal = ({
  bookingDetails,
  formattedTime,
  close,
  onConfirmPress,
  onDeclinePress,
}) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  const userDetails = bookingDetails?.user_details;
  const invitedUserDetails = bookingDetails?.invitation_details;
  const userId = bookingDetails?.user_id;
  const customerDetails = userId != null ? userDetails : invitedUserDetails;

  return (
    <>
      <div className="checkInModalSection scheduleModal p-10">
         <img
                src={Images.modalCross}
                alt="img"
                className="inventoryModalCancleImg cursorPointer"
                onClick={() => close()}
          />
      <div className="text-center">
          <img src={Images.skyBlueCheckInImg} alt="img" />
          <h3 className="fontSize24 fw500 txtDarkblue pt-3">{t('checkin')}</h3>
          <p className="fontSize18 txtLightBlue">
            {t('confrmDetailsApt')}
          </p>
      </div>
        <div className="checkInCustomerDetail">
          <div className="checkUser">
            <div className="InnercheckIn d-flex justify-content-between gap-2">
              <div>
              <h5 className="fontSize14 fw500 txtDarkblue">{t('customer')}</h5>
              <div className="flexBox gap-2">
                <img
                  src={customerDetails?.profile_photo ?? 
                    Images.userImg_
                  }
                  width={50}
                  height={50}
                 className="userImgMid"
                alt="img"
                />
              <div className="">
                <h3 className="fontSize16 fw500 txtDarkblue">
                  {`${customerDetails?.firstname ?? ""} ${
                    customerDetails?.lastname ?? ""
                  }`}{" "}
                </h3>
                <h6 className="flexBox fontSize14 fw500 mb-0 gap-1 locationText">
                  <img
                    src={Images.locateOrder}
                    alt="img"
                    className="location"
                  />
                  {`${
                        bookingDetails?.user_details?.current_address?.city ??
                        "-"
                      }, ${
                        bookingDetails?.user_details?.current_address?.state ??
                        " "
                      }`}
                </h6>
             
              </div>
            </div>


              </div>
              <h3 className="unpaidTxt fontSize14 txtDarkblue mb-0"></h3>
            </div>

            
            <div className="flexBox justify-content-between mt-3 mb-3">
              <h5 className="fontSize14 fw500 txtDarkblue mb-0">{t('serviceReq')}:</h5>
              <div className="flexBox flex-wrap gap-2">
                <h5 className="fontSize14 me-2">
                  {bookingDetails?.product_name}
                </h5>
              </div>
            </div>

            <div className="checkInServiceParent pb-3">
              <h5 className="fontSize14 fw500 txtDarkblue mb-3">{t('serviceTime')}</h5>
              <div className="flexBox gap-3">
                <div className="flexBox fontSize12 fw500 txtDarkblue">
                    <span className="flexBox">
                <img
                  className="img-fluid clockimg_ me-2"
                  alt="clock"
                  src={Images.customCalendar}
                  width={16}
                  height={16}
                />
                {moment.utc(bookingDetails?.start_date_time).format("dddd")},
              </span>
                  
                 <span> {moment.utc(bookingDetails?.date).format("DD/MM/YYYY")} </span>
                 
                </div>
                <div className="flexBox gap-2">
                  <img src={Images.blueClockImg} alt="img" />
                  <span className="fontSize12 fw500 txtDarkblue">
                    {" "}
                    {calculateTimeDuration(bookingDetails)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flexBox justify-content-between pt-3">
              <h6 className="fontSize14 fw500 txtDarkblue mb-0">{t('total')}</h6>
              <h6 className="fontSize14 fw500 txtDarkblue mb-0">{`$ ${bookingDetails?.actual_price}`}</h6>
            </div>
          </div>
        </div>
        <div className="flexBox gap-3 justify-content-between">
          <button
            className="commonBorderBtn w-100"
            type="submit"
            onClick={() => onDeclinePress()}
          >
            {t('decline')}
          </button>
          <button
            className="commonBlueBtn active w-100 flexBox gap-1"
            type="submit"
            onClick={() => {
              onConfirmPress();
                handleUserProfile("checkedIn");
            }}
          >
            {t('confirm')}
            <img
              src={Images.ArrowRight}
              alt="greenRight"
              className="img-fluid "
            />
          </button>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "checkedIn" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "checkedIn" ? "checkedIn" : ""}
        child={
          modalDetail.flag === "checkedIn" ? (
            <CheckedInModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "checkedIn" ? (
            <>
              {/* <div className='trackingSub headerModal '>
                           
                        </div> */}
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default CheckinModal;
