import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import LoaderModal from '../../myProducts/LoaderModal';
import { unwrapResult } from "@reduxjs/toolkit";
import { getPluginsListAsync } from '../../dashboard/dashboardSlice';
import { useTranslation } from 'react-i18next';
const BrowsepluginModal = (props) => {
  const { t } = useTranslation();
     const history = useHistory()
      const [loading, setLoading] = useState(false);
      const [pluginstData, setPluginsData] = useState(props.data);
      const dispatch = useDispatch();
      // Start Modal
        const [key, setKey] = useState(Math.random());
        const [modalDetail, setModalDetail] = useState({
          show: false,
          title: "",
          flag: "",
        });
      
        const handleOnCloseModal = () => {
          setModalDetail({
            show: false,
            title: "",
            flag: "",
          });
          setKey(Math.random());
        };
      
        const handleOpenModal = (flag) => {
          setModalDetail({
            show: true,
            flag: flag,
            title: flag,
          });
          setKey(Math.random());
      
          if (flag == "loaderModal") {
            setTimeout(() => {
              handleOnCloseModal();
            }, 5000);
          }
        };
      
        // End Modal
  
    return (
        <div className='browsepluginModal'>
            <button onClick={()=>{
                props?.close();

            }} className='closebtnn_' type='submit'>{t('close')} <img className='' src={Images.blueClose_} alt='cross' /> </button>
            <div className='browseHead_'>
                <h2 className='heading36Text_'>{t('discoverPlugin')} <br />{t('addEasily')}!</h2>
                <p className='blue20heading_'>JOBR {t('jobrApp')}</p>
            </div>
            <div className="row">
                          {loading ? (
                            <span className="spinner-border inner-spin spinner-border-sm"></span>
                          ) : (
                            <>
                              {pluginstData?.length > 0 ? (
                                <>
                                  {pluginstData?.map((val, index) => {
                                    return (
                                      <div
                                        className="col-lg-6 plugSubSecParent"
                                        key={index}
                                      >
                                        <div
                                          className="plugSubSection"
                                          onClick={() => {
                                            // handleOpenModal("loaderModal");
                                            props.handleDownload(val?.title, val?.url);
                                          }}
                                        >
                                          <img
                                            src={val?.logo}
                                            alt="wordpress image"
                                            className="img-fluid pluginImg"
                                          />
                                          <div className="d-flex align-items-start gap-2">
                                            <div className="plugHeadSection">
                                              <h4 className="fontSize24 fw500 txtDarkblue mb-0">
                                                {val?.title}
                                              </h4>
                                              <div className="pluginSubscriptionDiv">
                                                <span className="pinkDot"></span>
                                                <h4 className="freeText mb-0">
                                                  {val?.subscription}
                                                </h4>
                                              </div>
                                              <p className="fontSize16 fw500 txtDarkblue mb-0">
                                                {val?.discription}
                                              </p>
                                            </div>
                                            <img src={Images.blueArrowTopRight} alt="img" />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div>{t('noPlugin')}</div>
                              )}
                            </>
                          )}
                        </div>
        
        </div>
    )
}

export default BrowsepluginModal