import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { checkConnectAccount, loginAsync, selectLoginAuth, selectSignUpAuth, setPasswordApiAsync } from "../authSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SuccessfulModal from "../successfulModal";
import LoginProfileModal from "../loginProfileModal";
import CustomModal from "../../bookings/BookingCalendar/CustomModal";

const CreateNewPassModal = () => {
  const [loading, setLoading] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const phoneData = localStorage.getItem("phone_details")
    ? JSON.parse(localStorage.getItem("phone_details"))
    : "";
  const existing_user = localStorage.getItem("existing_user")
    ? JSON.parse(localStorage.getItem("existing_user"))
    : "";
  const [securityPin, setSecurityPin] = useState(
    existing_user?.user_profiles?.security_pin.length == 4
      ? existing_user?.user_profiles?.security_pin
      : ""
  );
  const auth = useSelector(selectLoginAuth);

  const [password, setPassword] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [pinToggle, setPinToggle] = useState(false);
  const signupAuth = useSelector(selectSignUpAuth);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [pinStrength, setPinStrength] = useState("");
  const [key, setKey] = useState(Math.random());
  const [isLoading, setIsLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // // check connected account
  // useEffect(() => {
  //   let params = {
  //     tokenData: signupAuth?.payload?.token
  //       ? signupAuth?.payload?.token
  //       : auth?.payload?.user?.token,
  //   };
  //   dispatch(checkConnectAccount(params))
  //     .then(unwrapResult)
  //     .then((res) => {
  //       if (res?.payload?.status != 2) {
  //         toast.error(res?.msg)
  //         // history.push("/connect-bank-account");
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch(() => {
  //       setIsLoading(false);
  //     });
  // }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to test if the input is numeric
    if (/^\d*$/.test(inputValue)) {
      setSecurityPin(inputValue);
      calculatePinStrength(inputValue);
    }
  };

  const blockInvalidChar = (e) => {
    // Allow backspace
    if (e.key === "Backspace") {
      return;
    }
    // Block alphabets and specific characters
    if (!/^\d$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleModalDetails = (data) => {
    if (data === "Successful" || data === "LoginProfile") {
      setModalDetail({ show: true, flag: data });
      setKey(Math.random());
    }
  };

  const calculatePasswordStrength = (password) => {
    if (password.length < 4) {
      setPasswordStrength("weak");
    }
    if (password.length > 4) {
      setPasswordStrength("moderate");
    }
    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)
    ) {
      setPasswordStrength("strong");
    }
    if (password.length == 0) {
      setPasswordStrength("");
    }
  };

  const calculatePinStrength = (pin) => {
    if (pin.length < 2) {
      setPinStrength("weak");
    }
    if (pin.length > 2) {
      setPinStrength("moderate");
    }
    if (pin.length == 4 && /^\d{4}$/.test(pin)) {
      setPinStrength("strong");
    }
    if (pin.length == 0) {
      setPinStrength("");
    }
  };

  const handleSetPassword = () => {
    // e.preventDefault();
    let data = {
      password: password,
    };
    if (password.length < 8) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password should be of 8 digits");
      }
      return;
    }
    if (
      !/[A-Z]/.test(password) || // Check for uppercase letter
      !/[0-9]/.test(password) || // Check for number
      !/[!@#$%^&*(),.?":{}|<>]/.test(password) // Check for special character
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Password should contain at least one uppercase letter, one number, and one special character"
        );
      }
      return;
    }
    if (
      !/^\d{4}$/.test(securityPin) &&
      (phoneData?.is_security_pin_exist === false ||
        phoneData?.is_security_pin_exist == undefined)
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Security pin must be number and of 4 digit"
        );
      }
      return;
    }
    if (!phoneData?.is_security_pin_exist) {
      data = {
        ...data,
        security_pin: securityPin,
      };
    }
    let params = {
      postData: data,
      tokenData: auth?.payload?.user?.token
        ? auth?.payload?.user?.token
        : signupAuth?.payload?.token ? signupAuth?.payload?.token :
          auth?.payload?.token,
    };
    setLoading(true);
    dispatch(setPasswordApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        // setLoading(false);
        // setModalDetail({ show: true, flag: "Successful" });
        history.push("/connect-bank-account");
        localStorage.removeItem("phone_details");
        localStorage.removeItem("email_details");
        localStorage.removeItem("email_info");
        localStorage.removeItem("existing_user");

        let loginData = {
          phone_code: obj?.payload?.user?.user_profiles?.phone_code,
          phone_number: obj?.payload?.user?.user_profiles?.phone_no,
          password: password,
          need_merchant_step_status: true,
        };
        localStorage.setItem("login_data", JSON.stringify(loginData))
        return
        // let loginData = {
        //   phone_code: obj?.payload?.user?.user_profiles?.phone_code,
        //   phone_number: obj?.payload?.user?.user_profiles?.phone_no,
        //   password: password,
        //   need_merchant_step_status: true,
        // };
        
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  return (
    <div className="commonLogin connectBankAccountSection">
      <div className="loginForm text-start">
        {isLoading ?
          <div className="full-loader">
            <span className="spinner-border inner-spin spinner-border-sm"></span>
          </div>
          :
          <>
            <div className="loginhead text-center">
              <h4 className="fontSize36 txtDarkblue">Create New Password</h4>
              {/* <p className="fontSize18 txtLightBlue mb-0 text-center pt-2">
                Your new password must be different from your previous password.
              </p> */}
            </div>
            <div autoComplete="nope" className="form_horizontal w-100">
              <div className=" form-group">
                <label className="fontSize14 fw500 txtDarkblue pb-2">
                  New Password
                </label>
                <input
                  className="customform-control id_password"
                  placeholder="Password"
                  autoComplete="new-password"
                  type={passwordShown1 ? "text" : "password"}
                  name="new_password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    calculatePasswordStrength(e.target.value);
                  }}
                />
                <img
                  src={Images.passIcon}
                  alt="passIcon"
                  className="passIcon resetFormPassIcon"
                />
                <span
                  className="toggle_password_ info_icon"
                  onClick={() => {
                    setPasswordShown1((prev) => !prev);
                  }}
                >
                  <span
                    className={
                      passwordShown1
                        ? "show-icon togglePassword"
                        : "hide-icon togglePassword"
                    }
                  ></span>
                </span>
              </div>
              <p className="fontSize14 txtLightBlue pt-2">
                Your password must be at least 8 characters, and can’t begin or end
                with a space.
              </p>
              <div className="protectionProgress mb-4">
                <div
                  className={
                    passwordStrength == "weak"
                      ? "progressRed"
                      : passwordStrength == "moderate"
                        ? "progressOrange"
                        : passwordStrength == "strong"
                          ? "progressGreen"
                          : "progresspart"
                  }
                ></div>
                <div
                  className={
                    passwordStrength == "weak"
                      ? "progressRed"
                      : passwordStrength == "moderate"
                        ? "progressOrange"
                        : passwordStrength == "strong"
                          ? "progressGreen"
                          : "progresspart"
                  }
                ></div>
                <div
                  className={
                    passwordStrength == "weak"
                      ? "progresspart"
                      : passwordStrength == "moderate"
                        ? "progressOrange"
                        : passwordStrength == "strong"
                          ? "progressGreen"
                          : "progresspart"
                  }
                ></div>
                <div
                  className={
                    passwordStrength == "weak"
                      ? "progresspart"
                      : passwordStrength == "moderate"
                        ? "progressOrange"
                        : passwordStrength == "strong"
                          ? "progressGreen"
                          : "progresspart"
                  }
                ></div>
                <div
                  className={
                    passwordStrength == "weak"
                      ? "progresspart"
                      : passwordStrength == "moderate"
                        ? "progresspart"
                        : passwordStrength == "strong"
                          ? "progressGreen"
                          : "progresspart"
                  }
                ></div>
                <div
                  className={
                    passwordStrength == "weak"
                      ? "progresspart"
                      : passwordStrength == "moderate"
                        ? "progresspart"
                        : passwordStrength == "strong"
                          ? "progressGreen"
                          : "progresspart"
                  }
                ></div>
              </div>

              <div>
                <label className="fontSize14 fw500 txtDarkblue pb-2">
                  Enter the 4 digit PIN
                </label>
                <div className="mb-3 mt-3 createNewPassOtpInput">
                  <OTPInput
                    numInputs={4}
                    className="input_digits_"
                    value={securityPin}
                    data-cy="pin-field"
                    isInputNum={true}
                    onChange={(e) => { setSecurityPin(e); calculatePinStrength(e); }}
                    // onChange={(e) => handleInputChange(e)}
                    // onKeyDown={(e) => blockInvalidChar(e)}
                    // isInputSecure={true}
                    autoComplete="false"
                    placeholder="00000"
                  // onComplete={(code) => onComplete(code)}
                  />
                </div>
                <div className="protectionProgress mb-4">
                  <div
                    className={
                      pinStrength == "weak"
                        ? "progressRed"
                        : pinStrength == "moderate"
                          ? "progressOrange"
                          : pinStrength == "strong"
                            ? "progressGreen"
                            : "progresspart"
                    }
                  ></div>
                  <div
                    className={
                      pinStrength == "weak"
                        ? "progressRed"
                        : pinStrength == "moderate"
                          ? "progressOrange"
                          : pinStrength == "strong"
                            ? "progressGreen"
                            : "progresspart"
                    }
                  ></div>
                  <div
                    className={
                      pinStrength == "weak"
                        ? "progresspart"
                        : pinStrength == "moderate"
                          ? "progressOrange"
                          : pinStrength == "strong"
                            ? "progressGreen"
                            : "progresspart"
                    }
                  ></div>
                  <div
                    className={
                      pinStrength == "weak"
                        ? "progresspart"
                        : pinStrength == "moderate"
                          ? "progressOrange"
                          : pinStrength == "strong"
                            ? "progressGreen"
                            : "progresspart"
                    }
                  ></div>
                  <div
                    className={
                      pinStrength == "weak"
                        ? "progresspart"
                        : pinStrength == "moderate"
                          ? "progresspart"
                          : pinStrength == "strong"
                            ? "progressGreen"
                            : "progresspart"
                    }
                  ></div>
                  <div
                    className={
                      pinStrength == "weak"
                        ? "progresspart"
                        : pinStrength == "moderate"
                          ? "progresspart"
                          : pinStrength == "strong"
                            ? "progressGreen"
                            : "progresspart"
                    }
                  ></div>
                </div>
              </div>
              <div className="modalfooterBtn align-items-center">
                <button
                  type="button"
                  className={`modalNextBtn modalDiscardBtn w-100 active ms-0`}
                  onClick={handleSetPassword}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  Set Password
                </button>
              </div>
            </div>
          </>
        }
      </div>

      {!isLoading &&
        <>
          <div className="copyRight">
            <p>All Rights Reserved by JOBR LLC | 2022</p>
          </div>
          <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            ids={
              modalDetail.flag === "VerifyModal"
                ? "verifyModal"
                : modalDetail.flag === "Successful"
                  ? "successful"
                  : modalDetail.flag === "LoginProfile"
                    ? "loginProfileModal"
                    : ""
            }
            size={
              modalDetail.flag === "VerifyModal"
                ? "md"
                : modalDetail.flag === "Successful"
                  ? "md"
                  : ""
            }
            child={
              modalDetail.flag === "Successful" ? (
                <SuccessfulModal
                  close={() => handleOnCloseModal()}
                  details={(e) => handleModalDetails(e)}
                />
              ) : modalDetail.flag === "LoginProfile" ? (
                <LoginProfileModal close={() => handleOnCloseModal()} />
              ) : (
                <></>
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </>
      }

    </div>
  );
};

export default CreateNewPassModal;
