import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectLoginAuth, selectUserSettings } from "../../auth/authSlice";
import { updateUserSettingsApiAsync } from "../../dashboard/dashboardSlice";
import { rewardsInactiveIcon } from "../../../../utilities/images";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import Loader from "../../../components/UI/Loader/Loader";

const RewardSetting = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth)
  const receiptSettings = useSelector(selectUserSettings);
  const isRewardEnabled = receiptSettings?.payload?.is_reward_enable;
  const [loading, setLoading] = useState(false);

  const handleChangeReward = (e) => {
    setLoading(true)
    e.preventDefault();
    const data = {
      postData: {
        is_reward_enable: !isRewardEnabled
      },
      tokenData: auth?.payload?.token
    };
    dispatch(updateUserSettingsApiAsync(data));
    setTimeout(() => setLoading(false), 1600)
  };

  const getSettingData = () => {
    dispatch(
      updateUserSettingsApiAsync({ postData: {}, tokenData: auth?.payload?.token })
    );
  };

  useEffect(() => {
    getSettingData();
  }, []);

  return (
    <>
      <DashboardNavbar title={t('rewardSetting')} backShow={false} />
      <div className="walletStats w-100 h-100">
        <>
          <div className='securityMain_'>
            {loading && <Loader />}
            <h3 className='appointMain'>
              <img
                src={rewardsInactiveIcon}
                alt="darkDevices"
                className="img-fluid me-3"
                width={30}
                height={30}
              />
              {t("rewards")}
            </h3>
            <div className='stepsVerification_ mt-4 ps-5'>
              <div className="serviceSettingBoxes justify-content-between d-flex align-items-start  gap-3 ">
                <div>
                  <h3 className="expectedHeading">
                    <img
                      src={rewardsInactiveIcon}
                      alt="darkDevices"
                      className="img-fluid me-3"
                      width={30}
                      height={30}
                    />
                    {t("activeRewards")}
                  </h3>
                  <h3 className="countNumber_ mb-0">
                    {t("rewardSubHead")}
                  </h3>
                </div>
                <div class="roundCheck mb-0">
                  <input
                    class="checkbox"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={isRewardEnabled}
                    onChange={(e) => {
                      handleChangeReward(e);
                    }}
                  />
                  <label
                    class="form-check-label d-none"
                    for="flexSwitchCheckChecked"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export default RewardSetting;
