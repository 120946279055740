import React, { useState } from 'react'
import SelectMenu from '../../../components/UI/Orders/SelectMenu'
import Loader from '../../../components/UI/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth, selectUserSettings } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getUserSettingsApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { useEffect } from 'react';
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";
import { useTranslation } from 'react-i18next';

const GeneralSetting = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [userSettings, setUserSettings] = useState(null);
    const userSettingRedux=useSelector(selectUserSettings);
    const [loading, setLoading] = useState(false);
    const getUserSettings = async () => {
        let prams = {
            postData: {
                app_name: "merchant",
                // seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
                seller_id: auth?.payload?.uniqe_id
                    ? auth?.payload?.uniqe_id
                    : auth?.payload?.user?.unique_uuid,
            },
            tokenData: auth?.payload?.token,
        }
        setLoading(true);
        dispatch(getUserSettingsApiAsync(prams))
            .then(unwrapResult)
            .then((res) => {
                setUserSettings(res?.payload);
                setLoading(false);
            })
            .catch((res) => {
                setLoading(false);
            })
    }
    useEffect(() => {
        getUserSettings();
    //   if(!userSettingRedux)  
    //   else{
    //     setUserSettings(userSettingRedux?.payload);
    //   }
    }, [])
    const updateUserSettings = async (data) => {
        let prams = {
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
        setLoading(true);
        dispatch(updateUserSettingsApiAsync(prams))
            .then(unwrapResult)
            .then((res) => {
                setLoading(false);
                setUserSettings(res.payload);
            })
            .catch((res) => {
                setLoading(false);
            })
    }
    const handleUserSetting = (key, value) => {
        setUserSettings(prev => ({
            ...prev, [key]: value
        }));
        updateUserSettings({ [key]: value });
    }
    const timeZoneHours=[
        { value: "12", label: "12 hours" },
        { value: "24", label: "24 hours" }
    
    ]
    return <>
        {loading &&
            <Loader />
        }
        <div className='generalSetting_'>
            <div className="processignOrder featureseting">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">{t('bookingType')}</h3>
                    <p className="textInnerHead2">{t('bookingTypeTitle')}</p>
                </div>
                <div className="row mt-4 ps-5">
                    <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.enable_online_booking} onChange={(event) => handleUserSetting("enable_online_booking", event.target.checked)} />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">{t("enableOnlineBooking")}</h3>
                                <p className="textInnerHead2">{t('enableOnlineBookingTitle')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.enable_onsite_booking} onChange={(event) => handleUserSetting("enable_onsite_booking", event.target.checked)} />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">{t("enableOnSite")}</h3>
                                <p className="textInnerHead2">{t("enableOnlineBookingTitle")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="processignOrder featureseting mt-4">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">{t("BookingAccept")}</h3>
                    <p className="textInnerHead2">{t("bookingAcceptTitle")}</p>
                </div>
                <div className="row mt-4 ps-5">
                    <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.accept_appointment_request=='manually'} onChange={(event) => handleUserSetting("accept_appointment_request", "manually")} />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">{t("acceptManually")} </h3>
                                <p className="textInnerHead2">{t("manuallayTitle")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.accept_appointment_request=='automatically'} onChange={(event) => handleUserSetting("accept_appointment_request", "automatically")} />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">{t("accAuto")}</h3>
                                <p className="textInnerHead2">{t("autoBook")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="processignOrder featureseting mt-4">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">{t("calendarZone")}</h3>
                    <p className="textInnerHead2">{("bookingAcceptTitle")}</p>
                </div>
                <div className="row mt-4 ps-5">
                    <div className="col-12">
                        <div className="calandermain_view ">
                            <h3 className="fontSize14 txtDarkblue m-0 mb-3">{t("calendarView")}</h3>
                            <div className='calenderViewchoose_'>
                                <div className="calenderViewradio">
                                    <input
                                        type="radio"
                                        id="day"
                                        name="calendarView"
                                        value="day"
                                        checked={userSettings?.calender_view === "day"}
                                        onChange={(event) => handleUserSetting("calender_view", event.target.value)}
                                    />
                                    <label htmlFor="day">{t("dayView")}</label>
                                </div>
                                <div className="calenderViewradio">
                                    <input
                                        type="radio"
                                        id="week"
                                        name="calendarView"
                                        value="week"
                                        checked={userSettings?.calender_view === "week"}
                                        onChange={(event) => handleUserSetting("calender_view", event.target.value)}
                                    />
                                    <label htmlFor="week">{t("weekView")}</label>
                                </div>
                                <div className="calenderViewradio">
                                    <input
                                        type="radio"
                                        id="month"
                                        name="calendarView"
                                        value="month"
                                        checked={userSettings?.calender_view === "month"}
                                        onChange={(event) => handleUserSetting("calender_view", event.target.value)}
                                    />
                                    <label htmlFor="month">{t("mnthView")}</label>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="ordrsettingHead_">
                            <p className="textInnerHead2 mb-2">{t("timeFormat")}</p>
                        </div>
                        <div className="serviceSettingBoxes  booktimezoon_ align-items-start  gap-3">
                            <SelectMenu
                                width="100%"
                                value={timeZoneHours.filter(time=>time.value==userSettings?.time_format)}
                                options={timeZoneHours}
                                placeholder={"select booking timezone"}
                                onChange={(event) => {
                                     handleUserSetting("time_format", event.value)
                                 }}
                            />
                        </div>
                        {/* 
                    <div className='col-12'>
                    <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Category
                  </label>
                  <ReactSelect
                    // value={selectedCategory}
                    // options={category}
                    // isDisabled={!servicesId}
                    // isSearchable={true}
                    placeholder=                      
                         "Select one"
                    
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
                    </div>
                        */}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default GeneralSetting