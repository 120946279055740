import React from "react";
import * as Images from "../../../utilities/images";

const AiTextCreatorModal = () => {
  return (
    <div className="aiTextCreatorParent">
      <div className="text-center">
        <h3 className="fontSize36 txtDarkblue">AI Text Creator</h3>
        <p className="fontSize16 txtLightBlue">
          AI can make mistakes, so double-check that the results are accurate
          before using them.
        </p>
      </div>
      <div className="aiTextBoxParent">
        <label className="fontSize14 fw500 txtDarkblue">Option 1</label>
        <div className="aiTextBox">
          <p className="fontsize14 fw400 txtDarkblue">
            Welcome to Chiq Butique, the go-to online destination for
            beautifully handmade children's clothing and accessories. Each of
            our products is lovingly crafted with attention to detail and the
            highest quality materials, ensuring that your little ones not only
            look adorable but also feel comfortable all day long. From charming
            dresses and rompers to cozy hats and booties, our collection offers
            a curated selection of timeless styles and playful designs to suit
            every occasion. Whether you're shopping for a special occasion or
            simply to add a touch of elegance to your child's everyday wardrobe,
            Chiq Butique has something for everyone. Discover the joy of
            dressing your little fashionista in unique, artisanal pieces that
            are as sweet and stylish as they are.
          </p>
          <button className="aiUseTextBtn">
            Use text <img src={Images.webPosArrow} alt="img" />
          </button>
        </div>
      </div>
      <div className="aiTextBoxParent">
        <label className="fontSize14 fw500 txtDarkblue">Option 1</label>
        <div className="aiTextBox">
          <p className="fontsize14 fw400 txtDarkblue">
            Welcome to Chiq Butique, the go-to online destination for
            beautifully handmade children's clothing and accessories. Each of
            our products is lovingly crafted with attention to detail and the
            highest quality materials, ensuring that your little ones not only
            look adorable but also feel comfortable all day long. From charming
            dresses and rompers to cozy hats and booties, our collection offers
            a curated selection of timeless styles and playful designs to suit
            every occasion. Whether you're shopping for a special occasion or
            simply to add a touch of elegance to your child's everyday wardrobe,
            Chiq Butique has something for everyone. Discover the joy of
            dressing your little fashionista in unique, artisanal pieces that
            are as sweet and stylish as they are.
          </p>
          <button className="aiUseTextBtn">
            Use text <img src={Images.webPosArrow} alt="img" />
          </button>
        </div>
      </div>
      <div className="aiTextBoxParent">
        <label className="fontSize14 fw500 txtDarkblue">Option 1</label>
        <div className="aiTextBox">
          <p className="fontsize14 fw400 txtDarkblue">
            Welcome to Chiq Butique, the go-to online destination for
            beautifully handmade children's clothing and accessories. Each of
            our products is lovingly crafted with attention to detail and the
            highest quality materials, ensuring that your little ones not only
            look adorable but also feel comfortable all day long. From charming
            dresses and rompers to cozy hats and booties, our collection offers
            a curated selection of timeless styles and playful designs to suit
            every occasion. Whether you're shopping for a special occasion or
            simply to add a touch of elegance to your child's everyday wardrobe,
            Chiq Butique has something for everyone. Discover the joy of
            dressing your little fashionista in unique, artisanal pieces that
            are as sweet and stylish as they are.
          </p>
          <button className="aiUseTextBtn">
            Use text <img src={Images.webPosArrow} alt="img" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AiTextCreatorModal;
