import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { selectedPaymentCards, selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSaveCardAsync, getMerchantActiveSubscriptionAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from '../../../components/UI/Loader/Loader';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const PlanandSubscription = ({setmoreSetting,setPlan,currentPlan,getCurrentPlan,setPlanType}) => {
    const { t } = useTranslation();
    const [paymentCardDetails, setpaymentCardDetails] = useState(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planIncludesApp, setPlanIncludesApp] = useState("");
  const paymentCards=useSelector(selectedPaymentCards);
  const [planFeatures, setPlanFeatures] = useState("");
useEffect(()=>{
    
    if(paymentCardDetails==null){
    let cardList=paymentCards?.payload;
        if(Array.isArray(cardList)){
            if(cardList.length) setpaymentCardDetails(cardList[0]);
        }else getPaymentCards();
    }
},[paymentCards])
const getPaymentCards=async()=>{
    try {
        let res=await dispatch(getAllSaveCardAsync(auth?.payload?.token)).then(unwrapResult);
        if(res){
            let cardsList=res?.payload;
            if(Array.isArray(cardsList)){
                if(cardsList.length){
                    setpaymentCardDetails(cardsList[0]);
                }
            }
        }
    } catch (error) {
        
    }
}
    const auth = useSelector(selectLoginAuth);
    const getMerchantActiveSubscription = () => {
        try {
            setLoading(true);
            let currentPlanDetails=getCurrentPlan();
            if(currentPlanDetails){
                handleCurrentPlanDetails(currentPlanDetails);
            }
        } catch (error) {
        }
        finally{
            setLoading(false);
        }
      };
    
      useEffect(() => {
        getMerchantActiveSubscription();
      }, []);
      const handleBtnClick=()=>{
        setmoreSetting("plans",selectedPlan)
      }
      useEffect(()=>{
       if(currentPlan) handleCurrentPlanDetails(currentPlan)
      },[currentPlan])
    const handleCurrentPlanDetails=(currentPlan)=>{
        setSelectedPlan(currentPlan?.[0]);
        setPlan(currentPlan?.[0]?.expiry_date||null);
        setPlanType(currentPlan?.[0]?.plan||"month");
        let planDetails=currentPlan?.[0]?.price_id;
        let planMetaData=planDetails?.metadata;
        let apps=planMetaData?.app_included;
        let features=planMetaData?.features;
        setPlanIncludesApp(JSON.parse(apps||"[]"));
        setPlanFeatures(JSON.parse(features||"[]"));
    }
    return <>
        {
        loading&&<Loader/>
        }
    {
        selectedPlan?

        <div className='planandSubscription_ position-relative'>
            <div className="planSubSection">
                <div>
                    <h3 className='appointMain'>{t("plan&Subscriptions")} </h3>
                    <div className="planApps">
                        <div className="planFlex">
                            <div className="enterLeft">
                                <h2 className="entreText">
                                    { selectedPlan?.price_id?.metadata?.name}
                                </h2>
                                <p className="orderPara">
                                    {
                                        selectedPlan?.price_id?.nickname
                                    }
                                </p>
                            </div>
                            <button
                            onClick={handleBtnClick}

                                className="createTaxBtn m-0"
                                type="button"
                            >
                                
                                {t(selectedPlan?.price_id?.metadata?.name=="Premium"?"viewPlan":"updatePlan")}
                                <img
                                    src={Images.fastimg}
                                    alt="Fast image"
                                    className="ms-1 img-fluid"
                                />
                            </button>
                        </div>
                        <div className="planAppSub">
                            <div className="planAppLeft">
                                <h4 className="settingText">{t("appIncluded")} :</h4>
                                {
                                    planIncludesApp.map((app, index) => (
                                        
                                <div key={index} className="planTickBox">
                                    <img
                                        src={app?.included?Images.cyanCircleTick:Images?.lightCircleTick}
                                        alt={` ${app?.included?"cyanCircleTick":"lightCircleTick"}  image`}
                                        className="img-fluid"
                                    />

                                    <h4 className="planCyan text-uppercase">
                                        JOBR {app?.feature}
                                    </h4>
                                </div>
                                    ))
                                }


                                {/* <div className="planTickBox">
                                    <img
                                        src={Images.cyanCircleTick}
                                        alt="lightCircleTick image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planCyan">JOBR Wallet</h4>
                                </div>
                                <div className="planTickBox">
                                    <img
                                        src={Images.lightCircleTick}
                                        alt="lightCircleTick image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planPara">JOBR POS</h4>
                                </div>
                                <div className="planTickBox">
                                    <img
                                        src={Images.lightCircleTick}
                                        alt="lightCircleTick image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planPara">JOBR Driver</h4>
                                </div> */}
                            </div>
                            <div className="planAppRight">
                                {
                                    planFeatures.map((feature, index) => (

                                <div key={index} className="planTickBox m-0">
                                    <img
                                        src={Images.simpleCheck}
                                        alt="simpleCheck image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planCyan">{feature}</h4>
                                </div>

                                    ))
                                }
                                {/* <div className="planTickBox m-0">
                                    <img
                                        src={Images.simpleCheck}
                                        alt="simpleCheck image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planCyan">Shareable product pages</h4>
                                </div>
                                <div className="planTickBox m-0">
                                    <img
                                        src={Images.simpleCheck}
                                        alt="simpleCheck image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planCyan">Unlimited products</h4>
                                </div>
                                <div className="planTickBox m-0">
                                    <img
                                        src={Images.simpleCheck}
                                        alt="simpleCheck image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planCyan">24/7 support</h4>
                                </div>
                                <div className="planTickBox m-0">
                                    <img
                                        src={Images.simpleCheck}
                                        alt="simpleCheck image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planCyan">Abandoned cart recovery</h4>
                                </div>
                                <div className="planTickBox m-0">
                                    <img
                                        src={Images.simpleCheck}
                                        alt="simpleCheck image"
                                        className="img-fluid"
                                    />
                                    <h4 className="planCyan">Advanced report builder</h4>
                                </div> */}
                            </div>

                        </div>
                        <div className="subscribeBox">
                            <button className="subscribeBtn">
                            {t("subscribed")}
                            </button>
                            <div className="subscribeRight">
                                <span className="subscribeText">
                                ${selectedPlan?.amount}
                                </span>
                                <span className="SubscribePara"> /{selectedPlan?.plan}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-6">
                            <div className="planMethod">
                                <h4 className="stateList_head  m-0">
                                {t("nextBillingdate")}
                                </h4>
                                <div className="scannerBx mt-2">
                                    <img
                                        src={Images.bookings}
                                        alt="calendarBlue image"
                                        className="img-fluid"
                                    />
                                    <h4 className="orderPara m-0">
                                        {
                                            moment(selectedPlan?.
                                                expiry_date
                                                ).format('YYYY-MM-DD')+" "
                                        }
                                         for ${selectedPlan?.amount} /{selectedPlan?.plan}
                                    </h4>
                                </div>
                            </div>
                        </div>
                       {paymentCardDetails&& <div className="col-lg-6">
                            <div className="planMethod">
                                <h4 className="stateList_head  m-0">{t("paymentMethod")}</h4>
                                <div className="scannerBx mt-2">
                                <img
                                        src={Images.mastercardicon}
                                        alt="calendarBlue image"
                                        className="img-fluid"
                                    />
                                    <h4 className="orderPara m-0">&#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; <span className='redgg'>{
                                        paymentCardDetails?.card?.last4}</span> </h4>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>

            </div>
        </div>:<div className='w-100 h-100 d-flex align-items-center justify-content-center'>
            <div >
                <p className='mb-3'>{t("noPlanFound")}</p>
                <button onClick={handleBtnClick} className='editbtn_'>{t("buyPlan")}</button>
            </div>
        </div>
    }
    
    </>
}

export default PlanandSubscription