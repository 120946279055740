import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { FileUploader } from "react-drag-drop-files";
import SelectMenu from "../../../components/UI/Orders/SelectMenu";
import {
  campaignTypeOptions,
  CategoryOptions,
  contentTypeOptions,
  promotionTypeOptions,
} from "./helper";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import CategoryOption from "./CategoryOption";
import { toast } from "react-toastify";
import { Slider } from "@mui/material";
import { useTranslation } from "react-i18next";

const CampainDetails = ({ setFormData, formData, setExcelFile,id }) => {
  const { t } = useTranslation();
  const [isImageUploaded, setisImageUploaded] = useState(false);
  const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
  const handleChange = (fileList) => {
    let fileArr = [];
    Object.values(fileList).forEach((file) => {
      const fileType = file.name.split(".").pop().toUpperCase();
      const fileSize = file.size / (1024 * 1024);
      let isvalid = true;

      if (!fileTypes.includes(fileType)) {
        toast.error(
          `Invalid file type: ${fileType}. Allowed: ${fileTypes.join(", ")}`
        );
        isvalid = false;
      }

      if (fileSize > 2) {
        toast.error("File size should be less than 2MB.");
        isvalid = false;
      }
      if (isvalid) fileArr.push(file);
    });
    setisImageUploaded(true);

    handleFormData(null, { images: fileArr });
  };

  const handleContactList = (file) => {
    console.log(file, "check excel file");

    const fileSize = file.size / (1024 * 1024); // Convert bytes to MB
    const fileType = file.name.split(".").pop().toUpperCase();
    let isValid = true;

    if (!["XLS", "XLSX"].includes(fileType)) {
      toast.error(
        "Invalid file type. Only Excel files (XLS, XLSX, CSV) are allowed."
      );
      isValid = false;
    }

    if (fileSize > 5) {
      toast.error("Excel file size should be less than 5MB.");
      isValid = false;
    }
    if (isValid) {
      setFormData((prev) => ({
        ...prev,
        contactList: file,
      }));
    }

    // Process the file
  };
  const autoCompleteHandleChange = (address) => {
    setFormData((prev) => ({
      ...prev,
      location: address,
    }));
  };
  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        fillAddressDetails(results);
        let lat = results[0].geometry.location.lat();
        let lng = results[0].geometry.location.lng();
        handleFormData(null, {
          latitude: lat,
          longitude: lng,
        });
      })
      .catch((error) => {});
  };
  const fillAddressDetails = (results) => {
    let location = results[0].formatted_address;
    setFormData((prev) => ({
      ...prev,
      location: location,
    }));
    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setFormData((prev) => ({
          ...prev,
          postal_code: results[0].address_components[j].short_name,
        }));
      } else if (results[0].address_components[j].types[0] == "locality") {
        setFormData((prev) => ({
          ...prev,
          city: results[0].address_components[j].long_name,
        }));
      }
    }
  };
  const handleFormData = (event, data) => {
    let obj = {};
    if (event) {
      let name = event.target.name;
      let value = event.target.value;
      obj = {
        [name]: value,
      };
    } else {
      obj = { ...data };
    }
    console.log("check Event", obj);
    setFormData((prev) => ({
      ...prev,
      ...obj,
    }));
  };
  const handleImages = (index) => {
    let imageArr = formData.images;
    imageArr.splice(index, 1);
    setFormData((prev) => ({
      ...prev,
      images: imageArr,
    }));
  };
 const formatSize=(bytes)=> {
    if (bytes < 1024) return `${bytes} B`;
    else if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
    else if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }


  return (
    <div className="campaignStep_">
      <div className="campaignMain_ mb-3">
        <div className="campaintop_">
          <h3 className="mb-1 normalHeading_">{t('campaignDetails')}</h3>
          <p className="textInnerHead2">
            {t('setupCampaign')}.
          </p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form_group">
              <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                {t('campaignName')}
              </label>
              <div className="inputGroup">
                <input
                  className="customInput"
                  placeholder="enter campaign name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleFormData}
                />
              </div>
              <p className="messageTxt ps-4">
                {t('createCampaignCode')}
              </p>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="p-3 calandermain_view_ pt-2">
              <h3 className="m-0 headblue18 mb-3">{t('campaignType')}</h3>
              <div className="calenderViewchoose_">
                {campaignTypeOptions.map((option, index) => (
                  <div key={index} className="calenderViewradio">
                    <input
                      type="radio"
                      id={option.value}
                      name="campaign_for"
                      // disabled={id?true:false}
                      checked={formData.campaign_for == option.value}
                      value={option.value}
                      onChange={handleFormData}
                    />
                    <label htmlFor={option.value}>
                      <img
                        className="campaintypeicon me-1"
                        src={Images[option.img]}
                      />
                      {t(option.languageLabel)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {formData.campaign_for != "sms" &&
            formData.campaign_for != "notification" && (
              <div className="col-md-12">
                <div className="form_group">
                  <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                    {t('promotionType')}
                  </label>
                  <div className="commonSelectParent reactSelectParent">
                    <SelectMenu
                      isSearchable={true}
                      placeholder="Select Promotion type"
                      options={promotionTypeOptions}
                      value={promotionTypeOptions.filter(
                        (option) => option.value == formData.promotion_type
                      )}
                      onChange={(event) => {
                        handleFormData(null, { promotion_type: event.value });
                      }}
                      classNamePrefix="react-select"
                      className="anlyticSelect react-select-container"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "100% !important",
                          width: "100%",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      <div className="campaignMain_ mb-3 mt-4">
        <div className="campaintop_">
          <h3 className="mb-1 normalHeading_">{t('campaignContext')}</h3>
          <p className="textInnerHead2">
           {t('uploadImagesContent')}.
          </p>
        </div>
        <div className="row">
          {formData.promotion_type != "top_banner" ||
          formData.campaign_for == "notification" ||
          formData.campaign_for == "sms" ? (
            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t('textontent')}
                </label>
                <div className="inputGroup textContent">
                  <textarea
                    name="content"
                    value={formData.content}
                    onChange={handleFormData}
                    className="formtextarea_"
                    placeholder="Enter your camping text here"
                  ></textarea>
                  <img
                    className="walletmsgicon_"
                    src={Images.walletmessageIcon}
                  />
                </div>
                <p class="messageTxt ps-4 pt-0">Max - words</p>
              </div>
            </div>
          ) : formData?.images?.length ? (
            <>
              <div className="d-flex flex-wrap gap-2 mt-4">
                {formData?.images?.map((media, index) => (
                  <div key={index} className="position-relative campaignContent_">
                    <img
                      className="img-fluid"
                      src={ isImageUploaded? URL.createObjectURL(media):media}
                      alt="img"
                      style={{ maxWidth: "150px", height: "120px" }}
                    />
                    <div
                      onClick={() => {
                        handleImages(index);
                      }}
                      className="options_menu position-absolute camimage"
                      style={{ right: "4px", top: "5px" }}
                    >
                      X
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="col-md-12 mt-4">
              <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                {t('UploadImages')} <img className="" src={Images.labelImg} />
              </label>
              <div id="dropzone">
                <FileUploader
                  multiple={true}
                  name="file"
                  types={fileTypes}
                  handleChange={handleChange}
                  children={
                    <div>
                      <label htmlFor="file">
                        <span className="file-button uploadImages_">
                          <img
                            src={Images.gallerySendImg}
                            className="editProfileImg img-fluid"
                            alt="user img"
                          />
                          <div className="dropText ms-2">
                            <span className="dragAndDrop_Txt">
                              {t('uploadFile')} <br /> {t('dragFile')}
                            </span>
                          </div>
                        </span>
                      </label>
                    </div>
                  }
                />
              </div>
              <p class="messageTxt pt-1">{t('imageSize')}</p>
            </div>
          )}
          {(formData.campaign_for == "product" ||
            formData.campaign_for == "store") && (
            <>
              {formData.campaign_for == "product" && (
                <div className="col-12 mt-4">
                  <div className="p-3 calandermain_view_ pt-2">
                    <h3 className="m-0 headblue18 mb-3">{t('campaignType')}</h3>
                    <div className="calenderViewchoose_">
                      <div
                        onClick={(e) => {
                          let value = "product";
                          if (formData.serviceType == "product") {
                            value = "service";
                          } else {
                            value = "product";
                          }
                          setFormData((prev) => ({
                            ...prev,
                            serviceType: value,
                          }));
                        }}
                        className="calenderViewradio"
                      >
                        <input
                          type="radio"
                          name="serviceType"
                          value={"product"}
                          checked={formData.serviceType == "product"}
                        />
                        <label>{t('product')}</label>
                      </div>
                      <div
                        onClick={(e) => {
                          let value = "product";
                          if (formData.serviceType == "product") {
                            value = "service";
                          } else {
                            value = "product";
                          }
                          setFormData((prev) => ({
                            ...prev,
                            serviceType: value,
                          }));
                        }}
                        className="calenderViewradio"
                      >
                        <input
                          type="radio"
                          name="serviceType"
                          value={"service"}
                          checked={formData.serviceType == "service"}
                        />
                        <label>{t('service')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <form className="mb-4 mt-4 ProductCategory">
                <h2 className="fontSize18 fw500 mb-0 txtDarkblue">
                  {formData.serviceType} Category
                </h2>
                <div className="row">
                  <CategoryOption
                    formData={formData}
                    handleFormData={handleFormData}
                    id={id}
                  />
                </div>
              </form>
            </>
          )}
        </div>
      </div>

      {formData.campaign_for == "sms" && (
        <div className="campaignMain_ mb-3 mt-4">
          <div className="campaintop_">
            <h3 className="mb-1 normalHeading_">{t('smsContact')}</h3>
            <p className="textInnerHead2">
              {t("chooseAction")}
            </p>
          </div>
          <div className="row">
            {
              formData?.contactList?<>
              <div className="position-relative smsContact_">
                    <img
                      className="img-fluid"
                      src={Images.excelfile}
                      alt="img"
                      style={{ maxWidth: "45px", height: "55px" }}
                    />
                    <div>
                      <p>{formData?.file?? formData?.contactList?.name
                      }</p>
                      <p>{formatSize(formData?.contactList?.size||1)}</p>
                    </div>
                    <div
                      onClick={() => {
                        setFormData(prev=>({
                          ...prev,contactList:null
                        }))
                      }}
                      className="options_menu position-absolute cursor-pointer"
                      style={{ right: "4px", top: "5px" }}
                    >
                      <img
                      className="img-fluid"
                      src={Images.Delete_Outline}
                      alt="img"
                    />
                    </div>
                  </div>
              </>:

            <div className="col-md-12 mt-4">
              <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                {t("uploadContacts")} <img className="" src={Images.labelImg} />
              </label>
              <div id="dropzone">
                <FileUploader
                  multiple={false}
                  name="file"
                  types={["xlsx"]}
                  handleChange={handleContactList}
                  children={
                    <div>
                      <label htmlFor="file">
                        <span className="file-button uploadImages_">
                          <img
                            src={Images.gallerySendImg}
                            className="editProfileImg img-fluid"
                            alt="user img"
                          />
                          <div className="dropText ms-2">
                            <span className="dragAndDrop_Txt">
                              {t("uploadFile")} <br /> {t("dragFile")}
                            </span>
                          </div>
                        </span>
                      </label>
                    </div>
                  }
                />
              </div>
              {/* <p class="messageTxt pt-1">Image size and px by px</p> */}
            </div>
            }

          </div>
        </div>
      )}
      {formData.campaign_for != "sms" && (
        <div className="campaignMain_ mb-3 mt-4">
          <div className="campaintop_">
            <h3 className="mb-1 normalHeading_">{t("targetAudience")}</h3>
            <p className="textInnerHead2">
              {t('audienceTagLine')}
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize16 mb-3 ps-4 txtDarkblue">
                  {t("ageRange")}
                </label>
                <div className="inputGroup ps-4 pe-4">
                  <Slider
                    value={[formData.min_age, formData.max_age]}
                    onChange={(e, value) => {
                      let min = value?.[0];
                      let max = value?.[1];
                      setFormData((prev) => ({
                        ...prev,
                        min_age: min,
                        max_age: max,
                      }));
                    }}
                    valueLabelDisplay="auto"
                    sx={{
                      "& .MuiSlider-track": {
                        backgroundColor: "#58C2E8", // Change the color of the track (the bar)
                        border: "none",
                      },
                      "& .MuiSlider-rail": {
                        backgroundColor: "#e5e8fe", // Change the color of the rail (the background)
                        border: "none",
                      },
                      "& .MuiSlider-thumb": {
                        backgroundColor: "#fff", // Change the color of the thumb (the handle)
                        border: "none",
                        width: "28px", // Set width to 28px
                        height: "28px", // Set height to 28px
                        borderRadius: "50%",
                      },
                      "& .MuiSlider-valueLabel": {
                        backgroundColor: "#58C2E8", // Change background color of the tooltip
                        color: "#fff", // Tooltip text color
                        fontWeight: "bold", // Optional: Make the text bold
                        padding: "4px 8px", // Optional: Adjust padding for better appearance
                        borderRadius: "4px", // Optional: Make the tooltip corners rounded
                      },
                    }}
                  />
                </div>
              </div>
              <div className="form_group">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t('location')}
                </label>
                <div className="inputGroup">
                  <PlacesAutocomplete
                    className=""
                    autoComplete="off"
                    value={formData.location}
                    onChange={autoCompleteHandleChange}
                    onSelect={autoCompleteHandleSelect}
                    searchOptions={{
                      componentRestrictions: {
                        country: ["us", "ind"],
                      },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Street Address",
                            className:
                              "location-search-input customform-control countrytags_ iconInput",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#41b6e6",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={index}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="form_group">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                 {t("radiusKm")}
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="enter radius"
                    name="radius"
                    type="number"
                    value={formData.radius}
                    onChange={handleFormData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="campaignMain_ mt-4">
        <div className="campaintop_">
          <h3 className="mb-1 normalHeading_">{t("budgetDuration")}</h3>
          <p className="textInnerHead2">
            {t("budgetTitle")}.
          </p>
        </div>
        <div className="row">
          {/* <div className="col-md-12">
            <div className="form_group">
              <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                Budget (USD)
              </label>
              <div className="inputGroup">
                <input
                  className="customInput"
                  placeholder="enter budget   "
                  name="budget"
                  type="text"
                  value={formData.budget}
                  onChange={handleFormData}
                />
              </div>
            </div>
          </div> */}
          {formData.campaign_for == "sms" ||
          formData.campaign_for == "notification" ? (
            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {formData.campaign_for == "sms" ? "Sms Count" : t()}{" "}
                  
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder={`enter ${
                      formData.campaign_for == "sms" ? t('smsCount') : t('notifyCount')}`}
                    name={
                      formData.campaign_for == "sms"
                        ? "sms_count"
                        : "notifications_count"
                    }
                    type="text"
                    value={
                      formData[
                        formData.campaign_for == "sms"
                          ? "sms_count"
                          : "notifications_count"
                      ]
                    }
                    onChange={handleFormData}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="form_group">
              <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                {t("durationDays")}
              </label>
              <div className="inputGroup">
                <input
                  className="customInput"
                  placeholder="enter duration"
                  name="duration"
                  type="text"
                  disabled={id?true:false}
                  value={formData.duration}
                  onChange={handleFormData}
                />
              </div>
            </div>
          )}
          {["store", "product"].includes(formData.campaign_for) && (
            <>
              <div className="col-md-6 mt-4">
                <div className="form-group ">
                  <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                    {t('startDate')}
                  </label>
                  <div className="customer-calendar-cnt">
                    <img className="calenderIcon_" src={Images.calenderLogo} />
                    <ReactDatePicker
                      selected={formData.start_date}
                      disabled={id?true:false}
                      onChange={(date) => {
                        const obj = {};
                        let startOfDay = new Date(date);
                        startOfDay.setHours(0, 0, 0, 0);
                        obj.start_date = startOfDay;
                        if (formData.end_date < date) {
                          let endOfDay = new Date(startOfDay);
                          endOfDay.setHours(23, 59, 59, 999);
                          obj.end_date = endOfDay;
                        }
                        handleFormData(null, obj);
                      }}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="10/02/2024"
                      minDate={new Date()}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form-group">
                  <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                    {t('endDate')}
                  </label>
                  <div className="customer-calendar-cnt">
                    <img className="calenderIcon_" src={Images.calenderLogo} />
                    <ReactDatePicker
                      disabled={id?true:false}

                      selected={formData.end_date}
                      onChange={(date) => {
                        let endOfDay = new Date(date);
                        endOfDay.setHours(23, 59, 59, 999);
                        handleFormData(null, { end_date: endOfDay });
                      }}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="10/02/2024"
                      minDate={formData.start_date}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampainDetails;
