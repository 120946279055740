import * as Yup from "yup";

// Validation schema using Yup
export const changePasswordValidationSchema = Yup.object({
  old_password: Yup.string().required("Old password is required"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New password is required")
    .notOneOf([Yup.ref('old_password')], 'New password cannot be the same as old password'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password')], 'Passwords must match')
    .required('Please confirm your password')
});