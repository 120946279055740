import React, { useEffect, useState } from 'react'
import { getTrendingArticleApiAsync, selectMerchantStatus } from '../dashboard/dashboardSlice'
import { selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import CustomModal from '../../components/shared/CustomModal';
import RefundoverviewModal from './refundoverviewModal';
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import useDebounce from '../../hooks/useDebounce'
import { unwrapResult } from '@reduxjs/toolkit'



const Support = () => {
  const { t } = useTranslation();
  const [trendingArticle, setTrendingArticle] = useState([])
  const [articleTitle, setArticleTitle] = useState("")
  const [articleDescription, setArticleDescription] = useState("")
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [key, setKey] = useState(Math.random());
  const [search, setSearch] = useState("")
  const [searchedTopic, setSearchedTopic] = useState([])
  const debouncedSearch = useDebounce(search, 900)
  const history = useHistory()

  const SUPPORT_TOPICS = [
    {
      title: t("gettingStarted"),
      subTittle: t("gettingStartedTitle"),
      link: "/getting-started",
      icon: Images.boardmenu
    },
    {
      title: t("accountSetting"),
      subTittle: t("gettingStartedTitle"),
      link: "/account-setting",
      icon: Images.businessdetails
    },
    {
      title: t("payment"),
      subTittle: t("gettingStartedTitle"),
      link: "/payment",
      icon: Images.credit_card
    },
    {
      title: t("item&Inventory"),
      subTittle: t("gettingStartedTitle"),
      link: "/itemsInventory",
      icon: Images.inventoryBox_
    },
    {
      title: t("orders"),
      subTittle: t("gettingStartedTitle"),
      link: "/orders-topic",
      icon: Images.ordersearch
    },
    {
      title: t("reports"),
      subTittle: t("gettingStartedTitle"),
      link: "/reports",
      icon: Images.analyticbox
    },
    {
      title: t("staff&Payroll"),
      subTittle: t("gettingStartedTitle"),
      link: "/staff-and-payroll",
      icon: Images.receiptIcon
    },
    {
      title: t("faq"),
      subTittle: t("gettingStartedTitle"),
      link: "/faq",
      icon: Images.settingdetails
    },
  ];


  const handelTopicsSearch = () => {
    const searchedTopic = SUPPORT_TOPICS.filter(
      item => item.title.toLowerCase().includes(debouncedSearch.toLowerCase())
    )
    setSearchedTopic(searchedTopic)
  }

  useEffect(() => {
    if (debouncedSearch !== "" && debouncedSearch?.length > 2) {
      handelTopicsSearch()
    } else {
      setSearchedTopic([])
    }
  }, [debouncedSearch]);

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const getTrendingArticle = () => {
    let params = {
      "postData": {
        "type": "article",
        ...(debouncedSearch !== "" && {
          "search": debouncedSearch
        })
      },
      "tokenData": auth?.payload?.token
    }

    setLoading(true)
    dispatch(getTrendingArticleApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setTrendingArticle(obj?.payload?.data)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Support";
  }, [])

  const merchantStatus = useSelector(selectMerchantStatus);
  const planActive = merchantStatus?.payload?.subscription_status;
  const productAdded = merchantStatus?.payload?.product_status;

  return (
    <div className='supportMain'>
      {(planActive && productAdded) && <DashboardNavbar title="Support" />}
      {(!planActive || !productAdded) && (
        <button
          className="forgetPass_BackBtn w-100 d-flex align-items-center justify-content-start"
          style={{
            padding: "0px"
          }}
          onClick={() => {
            history.goBack();
          }}
        >
          <img
            src={Images.leftArrowColored}
            alt="logo"
            className="me-2"
            height={"12px"}
            width={"12px"}
          />
          Back
        </button>
      )}
      <div className='supportHelp_'>
        <div className='chartsOuter mb-3'>

          <div className='helpSupport_'>
            <div className='row text-center justify-content-center'>
              <div className='col-md-8 col-sm-12'>
                <div className='howcanHelp_'>
                  <h4 className='priceHead_ mb-4'>{t('supportTitle')}</h4>
                  <div className='serach_box'>
                    <img
                      src={Images.SearchIcon}
                      alt=''
                      className='me-3' />
                    <input
                      type="text"
                      class="form-control searchBoc_"
                      placeholder={t('searchHere')} value={search} onChange={(e) => setSearch(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='needHelpbox_ mt-4'>
            <h3 className='appointMain'>{t('needHelp')}</h3>
            <p className='greyPara_'>{t('needHelpTitle')}</p>

            <div className='row mt-2'>
              {searchedTopic.length > 0 ? searchedTopic.map((item, idx) => {
                return (
                  <div className='col-md-3 mb-4' key={idx}>
                    <Link to={item.link} className='helpTopics'>
                      <img src={item.icon} alt='' className='mb-3' />
                      <h4 className='Innerhead mb-4'>{item.title}</h4>
                      <p>{item.subTittle}</p>
                    </Link>
                  </div>
                )
              }) : SUPPORT_TOPICS.map((item, idx) => {
                return (
                  <div className='col-md-3 mt-4' key={idx}>
                    <Link to={item.link} className='helpTopics'>
                      <img src={item.icon} alt='' className='mb-3' />
                      <h4 className='Innerhead mb-4'>{item.title}</h4>
                      <p>{item.subTittle}</p>
                    </Link>
                  </div>
                )
              })}
              {/* <div className='col-md-3 mb-4'>
                <Link to="/account-setting" className='helpTopics'>
                  <img src={Images.businessdetails} alt='' className='mb-3' />
                  <h4 className='Innerhead mb-4'>{t('accountSetting')}</h4>
                  <p>{t('gettingStartedTitle')}</p>
                </Link>
              </div>
              <div className='col-md-3 mb-4'>
                <Link to="/payment" className='helpTopics'>
                  <img src={Images.credit_card} alt='' className='mb-3' />
                  <h4 className='Innerhead mb-4'>{t('payment')}</h4>
                  <p>{t('gettingStartedTitle')}</p>
                </Link>
              </div>
              <div className='col-md-3 mb-4'>
                <Link to="/itemsInventory" className='helpTopics'>
                  <img src={Images.inventoryBox_} alt='' className='mb-3' />
                  <h4 className='Innerhead mb-4'>{t('item&Inventory')}</h4>
                  <p>{t('gettingStartedTitle')}</p>
                </Link>
              </div>
              <div className='col-md-3 mb-4'>
                <Link to="/orders-topic" className='helpTopics'>
                  <img src={Images.ordersearch} alt='' className='mb-3' />
                  <h4 className='Innerhead mb-4'>{t('orders')}</h4>
                  <p>{t('gettingStartedTitle')}</p>
                </Link>
              </div>
              <div className='col-md-3 mb-4'>
                <Link to="/reports" className='helpTopics'>
                  <img src={Images.analyticbox} alt='' className='mb-3' />
                  <h4 className='Innerhead mb-4'>{t('reports')}</h4>
                  <p>{t('gettingStartedTitle')}</p>
                </Link>
              </div>
              <div className='col-md-3 mb-4'>
                <Link to="/staff-and-payroll" className='helpTopics'>
                  <img src={Images.receiptIcon} alt='' className='mb-3' />
                  <h4 className='Innerhead mb-4'>{t('staff&Payroll')}</h4>
                  <p>{t('gettingStartedTitle')}</p>
                </Link>
              </div>
              <div className='col-md-3 mb-4'>
                <Link to="/faq" className='helpTopics'>
                  <img src={Images.settingdetails} alt='' className='mb-3' />
                  <h4 className='Innerhead mb-4'>{t('faq')}</h4>
                  <p>{t('gettingStartedTitle')}</p>
                </Link>
              </div> */}
            </div>
          </div>
          {/* <div className='trendingArticle_'>
            <h3 className='subheadsmall_ mb-4'>Trending Articles</h3>
            <div className='row'>

              {trendingArticle?.map((val, index) => {
                return (
                  <>
                    <div className='col-md-6 mb-3'>
                      <div className='aboutJobr_'>
                        <h3 className='subtextDark_'>{val?.title}</h3>
                        <i class="fa-solid fa-chevron-right" onClick={() => {
                          setModalDetail({ show: true, flag: "refundoverviewModal" });
                          setKey(Math.random());
                          setArticleTitle(val?.title)
                          setArticleDescription(val?.discription)
                        }}></i>
                      </div>
                    </div>
                  </>
                )
              })}

            </div>

          </div> */}
        </div>


        {/* <div className='scalePlans'>
              <Accordion defaultActiveKey="1" className='mb-4' flush>
                <Accordion.Item eventKey="1" className='backContainer'>
                  <Accordion.Header>
                    <div className=''>
                      <h3 className='subheadsmall_'>Explore more support</h3>
                      <p className='agentHeading mb-0'>Check out these resources for answers to your questions, videos, and best practices.</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className='mt-4'>
                    <div className='plansContainer moreExport'>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1" className={`plansItems ${activeTab == "HelpCenter" ? "active" : ""}`} onClick={() => setActiveTab("HelpCenter")}>
                          <Accordion.Header>
                            <img src={helpCenter} alt='' className='me-3' />
                            <div>
                              <h4 className='textInnerDark mb-1'>Help Center</h4>
                              <p className='smallText mb-0'>Before you open your store, first you need some products.</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className={`plansItems ${activeTab == "AskTopic" ? "active" : ""}`} onClick={() => setActiveTab("AskTopic")}>
                          <Accordion.Header>
                            <img src={topics} alt='' className='me-3' />
                            <div>
                              <h4 className='textInnerDark mb-1'>Ask us about a topic</h4>
                              <p className='smallText'>Before you open your store, first you need some products.</p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div> */}
        <div className='supportContact_'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='conatctUS_'>
                <div className='contactTop_ mb-4'>
                  <figure className='chatOut mb-0'> <img src={Images.chaticon_} alt='' className='' /></figure>
                  <Link to="#" className="contactArrow_"><img src={Images.ArrowRight} alt='Arrow' className='' /></Link>
                </div>

                <h5 className='heading20Black_ mb-1'>{t('contactUS')}</h5>
                <p className='darkgreyPar_'>{t('contactUsTitle')}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='conatctUS_'>
                <div className='contactTop_ mb-4'>
                  <figure className='chatOut mb-0'> <img src={Images.supportamail} alt='' className='' /></figure>
                  <Link to="/mySupport" className="contactArrow_"><img src={Images.ArrowRight} alt='Arrow' className='' /></Link>
                </div>

                <h5 className='heading20Black_ mb-1'>{t('mySupportBoxTitle')} </h5>
                <p className='darkgreyPar_'></p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "RefundoverviewModal" ? "commonWidth modalcommon_" : ""}
        ids={modalDetail.flag === "refundoverviewModal" ? "refundoverviewModal" :
          modalDetail.flag === "refundoverviewModal" ? "purchasetransitModule" : ""}
        size={modalDetail.flag === "refundoverviewModal" ? "xl" : "md"}


        child={

          modalDetail.flag === "refundoverviewModal" ? (
            <RefundoverviewModal close={() => handleOnCloseModal()} data={articleDescription} />
          ) :
            (
              ""
            )
        }
        header={
          <>
            {modalDetail.flag === 'refundoverviewModal'
              ?
              <>
                <div className="modalHeader_">
                  <div className="common_">
                    <h3 className='modalHeading_'>{articleTitle}</h3>

                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                      <i className="las la-times" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </> : ""
            }
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  )
}

export default Support