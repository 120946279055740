import React, { useState } from "react";
import * as Images from "../../../../../utilities/images";
import moment from "moment-timezone";
import { calculateTimeDuration } from "../GlobalMethods";
import { APPOINTMENT_STATUS } from "../../../../constants/bookings/allbooking";
import { useTranslation } from "react-i18next";

const RequestModal = ({selectedStaffIdArray,getAppointmentByStaffIdList,appointmentListArr,updateBookingStatus,close,loading}) => {
  const { t } = useTranslation();
  const [buttonIndex, setButtonIndex] = useState('');
  return (
    <div className="checkInModalSection scheduleModal">
      <img
        src={Images.modalCross}
        alt="img"
        className="inventoryModalCancleImg cursorPointer"
        onClick={() => close()}
      />
      <div className="text-center">
        <img src={Images.bookingCalenderImg} alt="img" />
        <h3 className="fontSize24 fw500 txtDarkblue pt-3">
          {t('requests')} <span style={{ letterSpacing: "1px" }}> {selectedStaffIdArray.length > 0
                    ? getAppointmentByStaffIdList?.length ?? 0
                    : appointmentListArr?.length ?? 0}</span>
        </h3>
        <p className="fontSize18 txtLightBlue">
          {t('confrmDetailsApt')}
        </p>
      </div>
      {
        selectedStaffIdArray.length > 0
        ? getAppointmentByStaffIdList
        : appointmentListArr?.map((item,index)=>{
          const userDetails = item?.user_details;
                    const invitedUserDetails = item?.invitation_details;
                    const userId = item?.user_id;
                    const customerDetails =
                      userId != null ? userDetails : invitedUserDetails;
                    const userAddress = userDetails?.current_address;
                    const appointmentID = item?.id;

  return    <div key={index} className="checkInCustomerDetail paid mb-0">
        <div className="InnercheckIn d-flex justify-content-between align-items-start gap-2">
          <div>
            <h5 className="fontSize14 fw500 txtDarkblue">{t('customer')}:</h5>
            <div className="flexBox gap-2">
              <img src={ customerDetails?.profile_photo ??Images.userImg_} className="userImgMid" alt="img" />
              <div>
                <h3 className="fontSize16 fw500 txtDarkblue">
                {(customerDetails?.firstname ?? "") +
                                  " " +
                                  (customerDetails?.lastname ?? "")}
                </h3>
                <h6 className="flexBox fontSize14 fw500 mb-0 gap-1 locationText">
                  <img
                    src={Images.locateOrder}
                    alt="img"
                    className="location"
                  />
                       {userAddress?.street_address ?? "-"}
                </h6>
              </div>
            </div>
          </div>
          <h3 className="unpaidTxt paidTxt fontSize14 txtDarkblue mb-0 flexBox gap-2">
            {t('paid')} <img src={Images.checkCircleImg} alt="img" />
          </h3>
        </div>
        <div className="flexBox justify-content-between mt-3 mb-3">
          <h5 className="fontSize14 fw500 txtDarkblue mb-0">
            {t('serviceRequested')}:
          </h5>
          <div className="flexBox flex-wrap gap-2">
            <h5 className="checkInServiceRequest">{item?.product_name}</h5>
            {/* <h5 className="checkInServiceRequest">Pet Bathing</h5> */}
          </div>
        </div>
        <div className="checkInServiceParent pb-3">
          <h5 className="fontSize14 fw500 txtDarkblue mb-3">{t('serviceTime')}:</h5>
          <div className="flexBox gap-3">
            <div className="flexBox fontSize12 fw500 txtDarkblue">
              <img
                className="img-fluid clockimg_ me-2"
                alt="clock"
                src={Images.customCalendar}
                width={16}
                height={16}
              />
              <span>{moment
                                    .utc(item?.start_date_time)
                                    .format("dddd, DD/MM/YYYY")}</span>
            </div>
            <div className="flexBox gap-2">
              <img src={Images.blueClockImg} alt="img" />
              <span className="fontSize12 fw500 txtDarkblue">
              {calculateTimeDuration(item)}
              </span>
            </div>
          </div>
        </div>

        <div className="flexBox justify-content-between pt-3">
          <div className="flexBox gap-3 ">
            <h6 className="fontSize14 fw500 txtDarkblue mb-0">{t('total')}</h6>
            <h6 className="fontSize14 fw500 txtDarkblue mb-0">{item?.mode_of_payment?.toUpperCase() === "JBR"
                                ? item?.mode_of_payment?.toUpperCase() + " "
                                : "$"}

                              {`${parseFloat(item?.price).toFixed(2)}`}</h6>
          </div>

          <div className="flexBox gap-3 justify-content-between">
            <button
            disabled={loading}
            onClick={async () => {
              setButtonIndex(index+"decline")
                                  updateBookingStatus(
                                    appointmentID,
                                    APPOINTMENT_STATUS.REJECTED_BY_SELLER
                                  );
                                }} className="commonBorderBtn w-100" type="submit">
                                    {loading&& buttonIndex==index+"accept" && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}
              {t('decline')}
            </button>
            <button
            disabled={loading}
            onClick={async () => {
              setButtonIndex(index+"accept")
              updateBookingStatus(
                appointmentID,
                APPOINTMENT_STATUS.ACCEPTED_BY_SELLER
              );
            }}
              className="commonBlueBtn active w-100 flexBox gap-1"
              type="submit"
            >
              {/* add loader */}
              {loading&& buttonIndex==index+"accept" && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}

              {t('accept')}
              <img src={Images.closeTag} alt="img" width={18} height={18} />
            </button>
          </div>
        </div>
      </div>
})
      }
     
    </div>
  );
};

export default RequestModal;
