import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoginAuth,
  getRoleApiAsync,
  deleteRoleApiAsync,
} from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "../../components/shared/CustomModal";
import CreateRoleModal from "./createRoleModal";
import { useEffect } from "react";
import RoleDetailsModal from "./RoleDetailsModal";
import DeleteModal from "./deleteModal";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import PaginationHeader from "../../components/PaginationHeader";
import * as Images from "../../../utilities/images";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CreateRole = (props) => {
  const { t } = useTranslation();
  const toastId = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [roleUsers, setRoleUsers] = useState([]);
  const [key, setKey] = useState(Math.random());

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const deleteHandle = (id) => {
    let params = {
      postData: {
        id: id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(deleteRoleApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        let data = {
          user_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          page: currentPage,
          limit: perPage,
        };
        dispatch(getRoleApiAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            setRoleUsers(obj?.payload?.roles);
            setLoading1(false);
            handleOnCloseModal();
          })
          .catch((obj) => {
            setLoading1(false);
          });
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  const handleOpenModal = () => {
    setSelectedProduct("");
    setModalDetail({ show: true, flag: "CreateRoleModal" });
    setKey(Math.random());
  };

  useEffect(() => {
    let data = {
      user_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      page: currentPage,
      limit: perPage,
    };
    setLoading(true);
    dispatch(getRoleApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setRoleUsers(obj?.payload?.roles);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  }, [perPage, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Payroll & Staff";
  }, []);
  return (
    <>
      <DashboardNavbar title={t('createRole')} show={true} />
      <section className="walletStats h-100">
        <div className="d-flex align-items-center justify-content-end gap-3 flex-wrap">
          <PaginationHeader
            currentPage={currentPage}
            totalPage={10}
            perPage={perPage}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            dataLength={roleUsers?.length}
            headerClass="posSalePagination orderTwo"
          />

          <div className="d-flex justify-content-end">
            <button
              className="addNew_ text-right fontSize14 orderOne"
              onClick={() => handleOpenModal()}
            >
              {t('createNewRole')}
            </button>
          </div>
        </div>

        <div className="commonTable table-responsive  mt-3 createNewRoleTable">
          <table className="w-100">
            <thead>
              <tr>
                <th className="text-start">#</th>
                <th>{t('roleName')}</th>
                <th>{t('description')}</th>
                <th>{t('role')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {roleUsers.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td className="orderTrackTableTxt text-start">
                      {currentPage > 1
                        ? (currentPage - 1) * totalRows + idx + 1
                        : idx + 1}
                    </td>
                    <td className="orderTrackTableTxt">{item?.name}</td>
                    <td className="orderTrackTableTxt">
                      {item?.description?.length > 20
                        ? item?.description?.substring(0, 20) + "..."
                        : item?.description}
                    </td>
                    <td className="orderTrackTableTxt">
                      {item?.role_privileges?.map((v, i) => {
                        return (
                          v?.privileges?.name +
                          (i === item?.role_privileges?.length - 1 ? "" : ", ")
                        );
                      })}
                    </td>
                    {/* <td className="fontSize12 txtLightBlue col-md-1">

                                            <div className='dropdown tableDropdown posUserTableDropdown '>
                                                <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                                    <img src={Images.moreVerticalLogo} alt="logo" />

                                                </span>
                                                <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                                    <li className='dropdown-item' onClick={() => {
                                                        setSelectedProduct(item)
                                                        setModalDetail({ show: true, flag: "RoleDetailsModal" });
                                                        setKey(Math.random());
                                                    }} >View Details</li>
                                                    <li className='dropdown-item' onClick={() => {
                                                        setSelectedProduct(item)
                                                        setModalDetail({ show: true, flag: "CreateRoleModal" });
                                                        setKey(Math.random());
                                                    }}>Edit</li>
                                                    <li className='dropdown-item' onClick={() => {
                                                        setSelectedProduct(item)
                                                        setModalDetail({ show: true, flag: "deleteRoleModal" });
                                                        setKey(Math.random());
                                                    }}>Delete</li>
                                                </div>
                                            </div>

                                        </td> */}
                    <td>
                      <Dropdown className="createRoleTableDropDown">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="variantsTable_Dropdown"
                        >
                          <img src={Images.moreVerticalLogo} alt="logo" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedProduct(item);
                              setModalDetail({
                                show: true,
                                flag: "RoleDetailsModal",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.darkBlueEyeIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0"> {t('viewDetails')}</h3>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              setSelectedProduct(item);
                              setModalDetail({
                                show: true,
                                flag: "CreateRoleModal",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.darkblueEditIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0">{t('edit')}</h3>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              setSelectedProduct(item);
                              setModalDetail({
                                show: true,
                                flag: "deleteRoleModal",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.newDeleteIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                {t('delete')}
                              </h3>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "CreateRoleModal" ||
            modalDetail.flag === "RoleDetailsModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "RoleDetailsModal"
            ? "RoleDetailsModal"
            : modalDetail.flag === "CreateRoleModal"
              ? "RoleDetailsModal"
              : modalDetail.flag === "deleteRoleModal"
                ? "DeleteRoleModal"
                : ""
        }
        child={
          modalDetail.flag === "deleteRoleModal" ? (
            <DeleteModal
              flag="deleteRole"
              loadingState={loading1}
              selectedProduct={selectedProduct}
              deleteHandle={(e) => deleteHandle(e)}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "RoleDetailsModal" ? (
            <RoleDetailsModal
              selectedProduct={selectedProduct}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "CreateRoleModal" ? (
            <CreateRoleModal
              selectedProduct={selectedProduct}
              setRoleUsers={setRoleUsers}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <>
            <div className="modalHeader_">
              <div className="common_">
                {modalDetail.flag === "deleteRoleModal" ? (
                  // <h2 className="subheading2_">Delete role</h2>
                  ""
                ) : modalDetail.flag === "CreateRoleModal" ? (
                  <>
                    <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                      {t('createRole')}
                    </h2>
                    <img
                      src={Images.modalCross}
                      alt="img"
                      className="cursorPointer ms-auto"
                      onClick={() => handleOnCloseModal()}
                    />
                  </>
                ) : (
                  <>
                    <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                      {t('roleDetails')}
                    </h2>
                    <img
                      src={Images.modalCross}
                      alt="img"
                      className="cursorPointer"
                      onClick={() => handleOnCloseModal()}
                    />
                  </>
                )}
                {/* <button className="closeButton fontSize24 fw500 txtDarkblue">
                                    <i className="las la-times txtDarkblue" onClick={() => handleOnCloseModal()}></i>
                                </button> */}
              </div>
            </div>
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default CreateRole;
