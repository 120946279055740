import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../dashboard/DashboardNavbar'
import { Link } from "react-router-dom";
import OrderHeader from "../../components/UI/Orders/MainHeader";
import * as Images from "../../../utilities/images";
import { getMarketingOverviewAPIAsync, getTotalOrdersGraphAsync, getTotalOrdersGraphOverviewAsync } from '../dashboard/dashboardSlice';
import MarketHeader from './marketHeader';
import { HeaderStatisticsArr } from './helper';
import ChartArea from '../dashboard/wallet/ChartArea';
import { selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { addThousandSeparator } from '../../../utilities/helpers';
import { options1 } from '../../constants/orders/dashboard';



const MarketingIndex = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterValue, setFilterValue] = useState("week");
    const [sellerAddress, setsellerAddress] = useState("");
    const [ordergraphs, setOrdergraphs] = useState(null);
    let [dataSets, setDataSets] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [checked, setChecked] = useState({
        revenue: true,
        coupons: true,
    });
    const [graphOverviewStatics, setGraphOverviewStatics] = useState({
      averageDiscountRate
      : 0,
      redeemptionRate: 0,
      totalMarketingValue: 0,
      totalRevenueGenerate: 0,
    });
    
    const getAllOrdersGraph = async () => {
        setLoading1(true);
        let prams = {
          postData: {
            seller_id: auth?.payload?.uniqe_id
                ? auth?.payload?.uniqe_id
                : auth?.payload?.user?.unique_uuid,
            // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18",
            filter: filterValue,
            ...(startDate == null && { filter: filterValue }),
            ...(startDate && { start_date: startDate, end_date: endDate }),
            ...(sellerAddress && { seller_address_id: sellerAddress }),
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getMarketingOverviewAPIAsync(prams))
          .then(unwrapResult)
          .then((obj) => {
            setLoading1(false);
            setGraphOverviewStatics(obj?.payload||{})
            let graphDetails={};
            let graph=obj?.payload?.graph;
            if(graph){
              Object.keys(graph).map((key)=>{
                if(!graphDetails["labels"]){
                  graphDetails["labels"]=graph?.[key]?.graph_data?.labels
                }
                if(!graphDetails["datasets"]){
                  graphDetails["datasets"]=[];
                }
                let data=graph?.[key]?.graph_data?.datasets?.[0]?.data;
                graphDetails["datasets"].push(data);

              })
            }
            console.log(graphDetails,"graphDetails",graph)
            setOrdergraphs(graphDetails);
            setDataSets(handleDataSet(graphDetails?.datasets) || []);
          })
          .catch((err) => {
            setLoading1(false);
          });
    };
    
    const handleDataSet = (datasets) => {
        const colors = ["#4659B5", "#039855"];
        let labels = ["coupon", "revenue"];
        return datasets?.map((data, index) => ({
          fill: true,
          label: labels[index],
          data: data,
          borderColor: colors[index],
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        }));
      };
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Marketing";
    }, []);
    const handleFilterValue = (value) => {
        setEndDate(null);
        setStartDate(null);
        setFilterValue(value);
    };
    const handleDateValue = (values) => {
        if (values.startDate == values.endDate) {
          setStartDate(null);
          setEndDate(null);
          return;
        }
        setStartDate(values.startDate);
        setEndDate(values.endDate);
    };
    const handleSellerAddress = (value) => {
        setsellerAddress(value);
      };
      const TotalMarketingAnalytics = {
        labels: ordergraphs?.labels,
        datasets: dataSets,
      };
      console.log(TotalMarketingAnalytics,"TotalMarketingAnalytics")
      useEffect(()=>{
        if(auth?.payload){
            getAllOrdersGraph();
        }
      },[filterValue,endDate,sellerAddress])
      const handelDataSetChange = (e, value, num, color) => {
        if (e.target.checked) {
          setDataSets([
            ...dataSets,
            {
              fill: true,
              label: value,
              data: ordergraphs?.datasets
                ? ordergraphs?.datasets?.[num]
                : "",
              borderColor: color,
              backgroundColor: "#FFFFFF00",
            },
          ]);
        } else {
          setDataSets(dataSets.filter((item) => item.label !== value));
        }
      };
    return (
        <div className='marketIndex_'>
            <DashboardNavbar title={t("marketingOverview")} backShow={false} />
            <div className='chartsOuter'>
                <OrderHeader
                 handleFilter={handleFilterValue}
                 handleDate={handleDateValue}
                 seller_id={    auth?.payload?.uniqe_id
                   ? auth?.payload?.uniqe_id
                   : auth?.payload?.user?.unique_uuid}
                 tokenData={auth?.payload?.token}
                 handleLocation={handleSellerAddress}
                 subhead={`All the following data is gathered ${filterValue}`}
                />
                <div className="verificationTotal">
                    <div className="row mt-4">

                        {HeaderStatisticsArr?.map((headerStatic, index) => (
                            <MarketHeader
                                key={index}
                                className={headerStatic?.className}
                                img={Images?.[headerStatic?.img]}
                                // value={headerStatic?.value}  
                                value={graphOverviewStatics?.[headerStatic?.key]}
                                heading={headerStatic?.heading}
                            />
                        ))}

                    </div>
                    <div className="tradesValue mt-4 mb-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="totalOrders_ mt-4 mb-3">
                                    <div className="chartHeader_">
                                        <div className="leftorderValue_">
                                            <h3
                                                style={{ cursor: "pointer" }}
                                                className="textInnerHead m-0"
                                            >
                                                {t("totalRevenueGenerated")}
                                            </h3>
                                            <h3 className="Innerhead ">
                                            {graphOverviewStatics?.totalRevenueGenerate
                                            ? `$${addThousandSeparator(
                                              graphOverviewStatics?.totalRevenueGenerate?.toFixed(2)
                                            )}`
                                            : "$0.00"}
                                            </h3>
                                        </div>

                                        <div className="graphChecked_ mt-3 mb-2 text-end gap-2">
                                            <div className="graphBox_ ">
                                                <input
                                                    type="checkbox"
                                                    id="custom-checkbox-selectAll3POS"
                                                    className="checkBox "
                                                    name="custom-checkbox-selectAll"
                                                    value="custom-checkbox-selectAll"
                                                    checked={checked.coupons}
                                                    onChange={(e) => {
                                                    handelDataSetChange(e,
                                                        "coupon",
                                                        0,
                                                        "#4659B5");
                                                        setChecked({
                                                            ...checked,
                                                            coupons: checked.coupons === true ? false : true,
                                                        });
                                                    }}
                                                />
                                                <label
                                                    htmlFor="custom-checkbox-selectAll3POS"
                                                    className="checkBoxTxt "
                                                >
                                                    {t('couponValue')}
                                                </label>
                                            </div>
                                            <div className="checkGreen ">
                                            <input
                                                type="checkbox"
                                                id="custom-checkbox-selectAll2POS"
                                                className="checkBox "
                                                name="custom-checkbox-selectAll"
                                                value="custom-checkbox-selectAll"
                                                onChange={(e) => {
                                                handelDataSetChange( e,
                                                    "revenue",
                                                    1,
                                                    "#039855");
                                                    setChecked({
                                                        ...checked,
                                                        revenue:
                                                          checked.revenue === true ? false : true,
                                                    });
                                                }}
                                                checked={checked.revenue}
                                            />
                                            <label
                                                htmlFor="custom-checkbox-selectAll2POS"
                                                className="checkBoxTxt "
                                            >
                                                {t('revenueGenerate')}
                                            </label>
                                            </div>
                                        
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-4 align-items-end">
                                        <ChartArea
                                            classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3"
                                            header="Sales Overview"
                                            chartType="Line"
                                            options={options1}
                                            data={TotalMarketingAnalytics}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MarketingIndex