import React from 'react'
import * as Images from "../../../utilities/images";
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const ReplyticketModal = (props) => {
    const { t } = useTranslation();
    // console.log({ latestComment: props?.latestSupportComment}, "latest comment =>>>")
    return (
        <div className='replyticketModal_ mt-4'>
            {/* <h5 className='smalldarkblue12 mb-3'>Last reply from agent</h5> */}
            <div className='infoaboutPerson_ mb-4'>
                <img

                    className=''
                    src={props?.latestSupportComment?.profile_photo || Images.userImg_}
                    alt='user icon' />
                <article>
                    <h4 className='headingblue14_'>
                        {props?.latestSupportComment?.full_name}
                    </h4>
                    {/* <h5 className='lighttext14 mb-0 mt-1'>JOBR Agent</h5> */}
                </article>
            </div>
            <h5 className='contentbalck_'>{props?.latestSupportComment?.text}</h5>
            <h5 className='datetimeText_'>
                {moment(props?.latestSupportComment?.created_at).format("DD MMM, YYYY | HH:MM a")}
            </h5>
            {/* <h5 className='datetimeText_'>20 May, 2022 | 08:09 am</h5> */}
            <div className="col-md-12 ">
                <div className="form_group">
                    <label className="fontSize16 txtDarkblue mb-2">
                        {t('replyToAgent')}
                    </label>
                    <div className="inputGroup textContent">
                        <textarea
                            value={props?.comment}
                            className='formtextarea_'
                            onChange={(e) => props?.setComment(e.target.value)}
                            placeholder="Cash cut-off before rush hour checked by Eugenia. It’s important to make inventories ." />
                        <img className='walletmsgicon_' src={Images.walletmessageIcon} alt='message' />
                    </div>
                    <p class="messageTxt ps-4 pt-0">{t('atLeast20char')}</p>

                </div>
                <div className='footemodalbtn_ mt-4'>
                    <button className='discardBtn_' type='submit'
                        onClick={
                            () => props?.close()
                        }
                    >
                        {t('discard')}
                    </button>
                    <button className='saveBtn_' type='submit' onClick={
                        () => {
                            // props?.createTicketComment()
                            props?.onSend()
                            props?.close()
                        }
                    } >
                        {t('reply')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ReplyticketModal