import React, { useState } from 'react'
import * as Images from "../../../../utilities/images";
import { useDispatch, useSelector } from 'react-redux';
import { connectAccountApiAsync, selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

const LinkbankwalletModal = (props) => {
    const { t } = useTranslation();
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    // add bank details
    const handleAddBankBankDetails = () => {
        let params = {
            tokenData: auth?.payload?.token ? auth?.payload?.token :
                auth?.payload?.user?.token
                    ? auth?.payload?.user?.token
                    : auth?.payload?.token,
            is_user_login: 1,
        };
        if(props?.bankExist > 0) {
            params.flag = "another_account"
        }
        setLoading(true);
        dispatch(connectAccountApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                window.location.href = obj.payload?.url;
            })
            .catch((obj) => {
                setLoading(false);
            });
    };

    return (
        <div className='linkbankMOdal_'>
            <div className='linkaccHead_'>
                <h4 className='appointMain'>{t('linkAccountTitle')}</h4>
                <img className='cursorPoint'
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => props?.close()}
                />
            </div>
            <div className='midaccountSection_ mt-5'>
                <figure className='bankimg_ mb-5'><img src={Images.bankaccImg} alt="bank" /></figure>
                <p className='lightpara18_ mb-5'>We use <span className='skyText_'>Plaid</span> to securely verify bank accounts and balances, help prevent fraud, and improve your Square experience.</p>
                <h4 className='heading20Black_ mb-3'> <img src={Images.lockicon} alt="bank" className='acclinkkicon_' /> {t('fastSecure')} </h4>
                <h4 className='heading20Black_'> <img src={Images.fastcahrgeIcon} alt="bank" className='acclinkkicon_' /> {t('startSending')}</h4>
                <button
                    onClick={handleAddBankBankDetails}
                    className='loginBtnSign mt-5' type="submit">{t('continue')} <img src={Images.webPosArrow} alt="img" /></button>
            </div>
        </div>
    )
}

export default LinkbankwalletModal