import React, { forwardRef, useEffect, useState } from "react";
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";
import moment from "moment-timezone";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { getFormatedDate } from "../../../../utilities/helpers";
import { getSellerAddressesApiAsync } from "../../../containers/dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectedUserAddressDetails } from "../../../containers/auth/authSlice";
import { useTranslation } from "react-i18next";

const MainHeader = (props) => {
   const { t } = useTranslation();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [filterValue, setFilterValue] = useState("week");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const addresses=useSelector(selectedUserAddressDetails);
  useEffect(() => {
    if (filterValue === "week") {
      // setEndDate(moment().endOf("week"))
      setStartDate(moment().startOf("week").toDate())
      setEndDate(moment().endOf("week").toDate())
    }
  }, []);

  const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => {
    let [start, end] = value.split("-");
    const startDate = moment.utc(start || new Date()).format("DD MMM, YYYY");
    const endDate = moment.utc(end || new Date()).format("DD MMM, YYYY");
    return (
      <div className="date-picker-container" onClick={onClick}>
        <img className="calendar-icon" src={Images.leftArrow} alt="Calendar" />
        <p className="date-text">
          {/* { moment.utc(calendarDate).format("MMMM YYYY")} */}
          {filterValue === "today" ?  startDate : (
            <>
            {startDate} -{" "}
            {endDate !== "Invalid date" ? endDate : moment().format("MMMM YYYY")}
            </>
          )}
        </p>
        <img
          className="arrow-icon"
          src={Images.newRightArrow}
          alt="Dropdown Arrow"
        />
      </div>
    );
  });

  const [sellerAddressesDetails, setsellerAddressesDetails] = useState(null);
  const selectOption = [
    { value: "all", label: "All" },
    { value: "pos", label: "Location 1" },
    { value: "b2c", label: "Location 2" },
    { value: "b2b", label: "Location 3" },
  ];
  const handleTimePeriodFilter = (value) => {
    setFilterValue(value);
    if (props?.handleFilterValue) {
      props?.handleFilterValue(value);
    }
    handleDateBasedOnTimePeriod(value);
    if (props.handleFilter) {
      props.handleFilter(value);
    }
  };
  const getSellerAddressDetails = async () => {
    const prams = {
      postData: {
        seller_id: props?.seller_id,
      },
      tokenData: props?.tokenData,
    };
    dispatch(getSellerAddressesApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        if (res?.payload) {
          processAddress(res?.payload||[]);
        }
      })
      .catch((err) => {});
  };

  let date_format = "YYYY-MM-DD";
  useEffect(() => {
    if (props.handleLocation){ 
     if(!addresses)  getSellerAddressDetails();
     else {
      let addressList=addresses?.payload||[];
      processAddress(addressList);
     }
    }
  }, []);

  const processAddress=(addressList)=>{
    let addreses = addressList?.map((address) => ({
      value: address?.id,
      label: address?.format_address,
    }));
    setsellerAddressesDetails([
      { value: null, label: "All" },
      ...addreses,
    ]);
  }
  const handleTimeBasedOnDate = (startDate, endDate) => {
    // console.log(startDate,endDate,"check")
    // if(!startDate||!endDate)return;
    //                               const differenceInDays = Math.floor((  endDate-startDate) / (1000 * 3600 * 24));
    //                               // Determine the filter value based on the difference in days
    //                               let filter = '';
    //                               console.log(differenceInDays,"check diff")
    //                               if (differenceInDays <= 0) {
    //                                 filter = 'today';
    //                               } else if (differenceInDays <= 7) {
    //                                 filter = 'week';
    //                               } else if (differenceInDays <= 30) {
    //                                 filter = 'month';
    //                               } else if (differenceInDays >= 365) {
    //                                 filter = 'year';
    //                               }
    //                               // Set the filter value
    //                               setFilterValue(filter);
  };
  const handleDateBasedOnTimePeriod = (value) => {
    let start, end;
    const today = new Date();

    switch (value) {
      case "today":
        start = today;
        end = today;
        break;

      case "week":
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the start of the week (Sunday)
        start = startOfWeek;

        // Set the end date to the end of the week (Saturday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        end = endOfWeek;
        break;

      case "month":
        // Set start date to the first day of the current month
        start = new Date(today.getFullYear(), today.getMonth(), 1);

        // Set end date to the last day of the current month
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;

      case "year":
        // Set start date to the first day of the current year
        start = new Date(today.getFullYear(), 0, 1);

        // Set end date to the last day of the current year
        end = new Date(today.getFullYear(), 11, 31);
        break;

      default:
        console.warn("Invalid filter value provided:", value);
        return;
    }
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          {props?.subhead && (
            <div>
              <h3 className="fontSize18 headerTxtColor mb-0 pb-1">
                {props?.head?props?.head: t("keyMetrics")}
              </h3>
              <h6 className="homeHeader_Subheading mb-0">{props?.subhead}</h6>
            </div>
          )}
          {props?.productHead && (
            <h6 className="homeHeader_Subheading mb-0">
            {t("gatheringData")} <span style={{
                textTransform: "capitalize",
                color: "#636e9f",
                fontSize: "14px",
                fontWeight: 400
              }}>
                {filterValue}
              </span>
          </h6>
          )}
        </div>
        <div className="col-md-10">
          <div className="d-flex align-items-center justify-content-md-end justify-content-center gap-2 flex-wrap mt-md-0 mt-2">
            {props?.handleDate && (
              <div className="bookingLeftTwo">
                <ReactDatePicker
                  onChange={(date) => {
                    const [start, end] = date;
                    setStartDate(start);
                    setEndDate(end);
                    setFilterValue("")
                    handleTimeBasedOnDate(start, end);
                    if (props?.handelCalanderDate) {
                      props?.handelCalanderDate({ start, end });
                    }
                    if (props?.handleDate) {
                      if (start && end)
                        props.handleDate({
                          startDate: getFormatedDate(start, date_format),
                          endDate: getFormatedDate(end, date_format),
                        });
                    }
                  }}
                  startDate={props?.startDate || startDate}
                  endDate={props?.endDate || endDate}
                  selectsRange
                  maxDate={!props?.enableFutureDate ? new Date() : undefined}
                  customInput={<DatePickerCustomComponent />}
                />
              </div>
            )}
            {props?.handleLocation && (
              <div className="reactSelectParent reactSelectParentProduct">
                <ReactSelect
                  options={
                    sellerAddressesDetails
                      ? sellerAddressesDetails
                      : selectOption
                  }
                  // defaultValue={channelSelected}
                  // value={channelSelected}
                  onChange={(event) => {
                    if (props?.handleLocation) {
                      props.handleLocation(event.value);
                    }
                  }}
                  placeholder={props?.selectText || t('location')}
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: "143px !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682" // Background color for selected option
                        : state.isFocused
                        ? "#f5f6fc" // Background color for hovered option
                        : "#fff", // Default background color
                      // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                      color: state.isSelected
                        ? "#fff" // Background color for selected option
                        : "#000", // Text color
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#263682", // Customize placeholder color if needed
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  // onChange={onChannelChange}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            )}

            <ul className="weekNav">
              <li onClick={() => handleTimePeriodFilter("today")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "today" ? "active" : ""
                  }`}
                  to="#"
                >
                  {t('today')}
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("week")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "week" ? "active" : ""
                  }`}
                  to="#"
                >
                  {t('weekly')}
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("month")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "month" ? "active" : ""
                  }`}
                  to="#"
                >
                  {t('monthly')}
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("year")}>
                <Link
                  className={`weekMenu ${
                    filterValue == "year" ? "active" : ""
                  }`}
                  to="#"
                >
                  {t('yearly')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
