import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import * as Images from "../../../../utilities/images";

const AddLeaveModal = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <div className="d-flex align-items-center gap-2">
          <img
            src={Images.lightCalenderLogo}
            alt="img"
            width={24}
            height={24}
          />
          {value}
        </div>
        <img
          src={Images.blueDownArrow}
          width={24}
          height={24}
          alt="drop_icon"
        />
      </button>
    )
  );
  return (
    <div className="container-fluid p-0">
      <div className="addLeaveModalParent">
        <div className="row">
          <div className="col-6">
            <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
              Start date
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<ExampleCustomInput className="datePickerInput" />}
            />
          </div>
          <div className="col-6">
            <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
              End date
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<ExampleCustomInput className="datePickerInput" />}
            />
          </div>
        </div>
        <h3 className="fontSize14 fw500 txtDarkblue ps-4 pt-3 pb-2">
          Leave type
        </h3>
        <div className="row">
          <div className="col-6">
            <form action="#">
              <p>
                <input type="radio" id="test1" name="radio-group" checked />
                <label for="test1">Apple</label>
              </p>
              <p>
                <input type="radio" id="test2" name="radio-group" />
                <label for="test2">Peach</label>
              </p>
              <p>
                <input type="radio" id="test3" name="radio-group" />
                <label for="test3">Orange</label>
              </p>
            </form>
          </div>
          <div className="col-6"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="pt-3">
              <h3 className="fontSize14 fw500 txtDarkblue mb-2">
                Comments
              </h3>
              <div className="inputGroup position-relative">
                <textarea
                  className="custom-textarea"
                  placeholder="Cash cut-off before rush hour checked by Eugenia. It’s important to make inventories ."
                  id="floatingTextarea"
                  rows="3"
                ></textarea>
                <img src={Images.commentTextImg1} alt="img" className="commentTextImg1"/>
                <img src={Images.commentTextImg2} alt="img" className="commentTextImg2"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flexBox">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          Discard
        </button>
        <button className="modalNextBtn modalDiscardBtn active w-100">
          Add leave
        </button>
      </div>
    </div>
  );
};

export default AddLeaveModal;
