import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { getAddCardAsync } from "../dashboard/dashboardSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const PaymentCheckoutForm = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);


  console.log(props?.selectedPlanId,"ssssssssssssssssssss");
  const addNewCardHandle = (token) => {
    let params = {
      postData: {
        card_token: token,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getAddCardAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSubscriptionLoading(false);
        props?.openModalflag("paymentCardInfo");
        props?.setSelectedPlanId(props?.selectedPlanId)
      })
      .catch((obj) => {
        setSubscriptionLoading(false);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setSubscriptionLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(cardNumberElement);
    if (error) {
      setSubscriptionLoading(false);
      setPaymentError(error.message);
    } else {
      setSubscriptionLoading(true);
      addNewCardHandle(token?.id);
      setPaymentError(null);
    }
  };

  const elementOptions = {
    style: {
      base: {
        iconColor: "#000000", // Black icon color
        color: "#000000", // Black text color
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#000000", // Black autofill text color
        },
        "::placeholder": {
          color: "#666666", // Dark grey placeholder color for readability
        },
      },
      invalid: {
        iconColor: "#FF0000", // Red icon color for invalid input
        color: "#FF0000", // Red text color for invalid input
      },
    },
    showIcon: true, // This ensures the card brand icon is shown
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12">
            <CardNumberElement
              options={elementOptions}
              className="customInput"
            />
          </div>
          <div className="col-lg-6 mt-4">
            <CardExpiryElement
              options={elementOptions}
              className="customInput"
            />
          </div>
          <div className="col-lg-6 mt-4">
            <CardCvcElement options={elementOptions} className="customInput" />
          </div>
        </div>

        <div className="text-center mt-4">
          <button
            type="submit"
            disabled={subscriptionLoading}
            className="commonBlueBtn active m-auto"
          >
            Add Card
            {subscriptionLoading && (
              <span className="spinner-border spinner-border-sm ms-2"></span>
            )}
          </button>
        </div>
        {paymentError && (
          <div className="fontSize18  pt-3 text-center">{paymentError}</div>
        )}
      </form>
    </>
  );
};

export default PaymentCheckoutForm;
