import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectLoginAuth } from "../auth/authSlice";
import { imageUploadAsync } from "../message/messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  calendar_light,
  labelImg,
  rewardImg,
  selectImg,
} from "../../../utilities/images";
import * as Images from "../../../utilities/images";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import {
  createGiftCardApiAsync,
  getGiftCategoriesAsync,
  updateGiftCardApiAsync,
} from "../dashboard/dashboardSlice";
import SelectMenu from "../../components/UI/Orders/SelectMenu";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

const CreateGift = (props) => {
  const { t } = useTranslation();
  const toastId = React.useRef(null);
  const [giftId] = useState(props?.selectedGift?.id);

  const [title, setTitle] = useState(
    props?.selectedGift?.title ? props?.selectedGift?.title : ""
  );
  console.log(props?.selectedGift, "dsssssssssssssssssssssssssssss");
  const date = moment.utc(props?.selectedGift?.start_date);
  const newDate = date.toDate();

  let dateFormatted = moment(
    props?.selectedGift?.end_date?.split("T")[0],
    "YYYY-MM-DD"
  ).toDate();
  const [startDate, setStartDate] = useState(
    props?.selectedGift?.end_date ? dateFormatted : ""
  );
  const [categoryId, setcategoryId] = useState(
    props?.selectedGift?.gift_category?.id
  );
  const [giftAmount, setGiftAmount] = useState(
    props?.selectedGift?.gift_amount ? props?.selectedGift?.gift_amount : ""
  );
  const [document, setDocument] = useState(
    props?.selectedGift?.image ? props?.selectedGift?.image : ""
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  let imageFile;
  const handleChange = (e) => {
    e.preventDefault();
    imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    setDataLoading(true);
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setDataLoading(false);
        setDocument(obj?.payload[0]?.filePath);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
      })
      .catch((obj) => {
        setDataLoading(false);
      });
  };

  const submitHandle = (e) => {
    e.preventDefault();
    if (!document) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please upload image");
      }
      return;
    }
    if (!title) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter title");
      }
      return;
    }
    if (!giftAmount) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select gift amount");
      }
      return;
    }
    if (!startDate) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select expiry date");
      }
      return;
    }
    if (!categoryId) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select gift category");
      }
      return;
    }
    let params = {
      postData: {
        image: document,
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: moment(startDate).format("YYYY-MM-DD"),
        title: title,
        gift_amount: giftAmount,
        gift_category_id: categoryId,
      },
      tokenData: auth?.payload?.token,
    };

    if (props?.editMode) {
      let params = {
        postData: {
          id: giftId,
          image: document,
          start_date: moment(new Date()).format("YYYY-MM-DD"),
          end_date: moment(startDate).format("YYYY-MM-DD"),
          title: title,
          gift_amount: giftAmount,
          gift_category_id: categoryId,
        },
        tokenData: auth?.payload?.token,
      };
      setLoading(true);
      dispatch(updateGiftCardApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          toast.success(obj?.msg);
          props?.getGiftCardList();
          props.close();
        })
        .catch((obj) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      dispatch(createGiftCardApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          toast.success(obj?.msg);
          props?.getGiftCardList();
          props.close();
        })
        .catch((obj) => {
          setLoading(false);
        });
    }
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <div className="d-flex align-items-center gap-2">
          <img
            src={Images.lightCalenderLogo}
            alt="calendar icon"
            width={24}
            height={24}
          />
          {value}
        </div>
        <img
          src={Images.blueDownArrow}
          width={24}
          height={24}
          alt="dropdown icon"
        />
      </button>
    )
  );

  return (
    <div>
      <form
        autoComplete="nope"
        onSubmit={(e) => submitHandle(e)}
        className="horizontalForms"
      >
        <div className="row">
          <div>
            {dataLoading ? (
              <span className="spinner-border inner-spin spinner-border-sm"></span>
            ) : (
              <div className="uploadStaffImg m-0 mb-2">
                <div className="showImg_Parent">
                  {document && (
                    <img src={document} className="staff_Profile_Img" />
                  )}
                  <label htmlFor="file-upload" className="uploadFileLabel">
                    <img
                      src={Images.uploadImgCamera}
                      alt="img"
                      className="uploadStaffCameraImg"
                    />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/jpeg, image/png"
                    name="profile_image"
                    className="d-none"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="col-md-6">
            <div className="form_group">
              <label
                className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                htmlFor="amount"
              >
                {t("title")}
              </label>
              <input
                type="text"
                name="text"
                className="customInput"
                placeholder="Please enter title"
                autoComplete="off"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form_group">
              <label
                className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                htmlFor="amount"
              >
                {t("giftAmount")}
              </label>
              <div className="inputGroup">
                <input
                  type="number"
                  name="gift_amount"
                  className="customInput costPriceInput"
                  placeholder="10"
                  autoComplete="off"
                  onKeyDown={blockInvalidChar}
                  value={giftAmount}
                  onChange={(e) => setGiftAmount(e.target.value)}
                />
                <span className="dollrsign1_">$</span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form_group">
              <div className="reactSelectParent commonSelectParent w-100">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t("selectCategory")}
                </label>
                <ReactSelect
                  options={props?.giftCategories || [{}]}
                  onChange={(event) => {
                    setcategoryId(event?.value);
                  }}
                  value={props?.giftCategories?.find(
                    (category) => category.value === categoryId
                  )}
                  placeholder="Select Category"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.arrowDown}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          {/* category */}
          <div className="col-6">
            <div className="form_group">
              <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                {t("giftCardValidity")}
              </label>
              <div className="inputGroup">
                {/* <img className='calanderImgg' src={calendar_light} /> */}
                {/* <DatePicker
                className="customform-control"
                selected={startDate}
                minDate={moment().toDate()}
                onChange={(date) => setStartDate(date)}
                placeholderText="Gift Card Validity Date"
              /> */}
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <ExampleCustomInput className="datePickerInput" />
                  }
                  minDate={moment().toDate()}
                  placeholderText="Gift Card Validity Date"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modalfooterBtn">
          <button className="modalDiscardBtn w-100" onClick={()=>props?.close()}>{t("cancel")}</button>
          <button
            className="modalDiscardBtn modalNextBtn active w-100"
            type="submit"
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            <span>{props?.editMode ? t("update") : t("create")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateGift;
