export const TimePeriodNav=[
    {
        value:"today",label:"Today",
        languageLabel:"today"
    },
    {
        value:"week",label:"Weekly",
        languageLabel:"weekly"
    },
    {
        value:"month",label:"Monthly",
        languageLabel:"monthly"
    },
    {
        value:"year",label:"Yearly",
        languageLabel:"yearly"
    },
    
]