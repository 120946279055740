import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import NotificationButton from "../../../components/NotificationButton";
import * as Images from "../../../../utilities/images";
import DocumentTable from "./DocumentTable";
import CustomModal from "../../../components/shared/CustomModal";
import ViewDocuments from "./ViewDocuments";
import { selectLoginAuth } from "../../auth/authSlice";
import { getDocumentApiAsync } from "../../myProducts/productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import SelectMenu from "../../../components/UI/Orders/SelectMenu";
import Viewer from "react-viewer";

const DocumentVerification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [documentStatus, setDocumentStatus] = useState(0);
  const auth = useSelector(selectLoginAuth);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [isDeclien, setIsDeclien] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [key, setKey] = useState(Math.random());
  const [documentList, setDocumentList] = useState([{}]);
  const [selectedDocument, setSelectedDocument] = useState({});

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [viewerImages, setViewerImages] = useState([]);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const resultSelectOption = [
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
  ];
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      const newPage = prev + value;
      if (newPage >= 1 && newPage <= totalPages) {
        return newPage;
      }
      return prev;
    });
  };
  const getDocuments = () => {
    let postData = {
      driver_verification_status: documentStatus,
      page: currentPage,
      limit: selectPageSize,
      search: keyword,
    };

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setLoading(true);
    dispatch(getDocumentApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setDocumentList(obj?.payload?.users?.users || []);
        setTotalProduct(obj?.payload?.users?.total);

        setTotalPages(
          Math.ceil(
            obj?.payload?.users?.total /
              (obj?.payload?.users?.per_page || selectPageSize)
          )
        );
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocuments();
  }, [currentPage, selectPageSize, documentStatus, keyword]);

  return (
    <>
      <div className="walletStats h-100">
        <div className="productOfferHead_Container">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="fontSize24 txtDarkblue fw500 mb-0">
              {"All Driver Documents"}{" "}
            </h3>
            <div className="d-flex align-items-center gap-4">
              <NotificationButton />
              <div className="input-group search_group">
                <img
                  src={Images.SearchIcon}
                  alt="img"
                  className="img-fluid searchIcon"
                />
                <input
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-xxl-4 col-xl-6">
              <div className="d-flex align-items-center gap-2">
                <button
                  className={`activeProductBtn ${
                    documentStatus == 0 ? "active" : ""
                  }`}
                  onClick={() => {
                    setDocumentStatus(0);
                  }}
                >
                  {t("all")}{" "}
                </button>
                <button
                  className={`activeProductBtn ${
                    documentStatus == 2 ? "active" : ""
                  }`}
                  onClick={() => {
                    setDocumentStatus(2);
                  }}
                >
                  {"Verified"}
                </button>
                <button
                  className={`activeProductBtn ${
                    documentStatus == 1 ? "active" : ""
                  }`}
                  onClick={() => {
                    setDocumentStatus(1);
                  }}
                >
                  {"Declined"}
                </button>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-6">
              <div className="d-flex justify-content-end gap-3 flex-wrap">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {t("showingResult")}
                  </h3>
                  <div className="reactSelectParent offerHeaderSelect">
                    <SelectMenu
                      options={resultSelectOption}
                      placeholder="10"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSelectPageSize(Number(e.value)); // Ensure value is a number
                      }}
                    />
                  </div>
                </div>

                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 ? "disablePrevbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(-1)}
                      disabled={currentPage <= 1}
                    >
                      <img src={Images.arrowDoubleLeft} alt="arrowDoubleLeft" />
                    </div>
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 ? "disablePrevbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(-1)}
                      disabled={currentPage <= 1}
                    >
                      <img src={Images.lightArrowLeft} alt="arrowLeft" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {currentPage ? (currentPage - 1) * selectPageSize + 1 : 1}-
                    {Math.min(
                      currentPage && selectPageSize
                        ? currentPage * selectPageSize
                        : 0,
                      totalProduct || 0
                    )}{" "}
                    of {totalProduct || 0}
                  </h6>
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages ? "disableNextbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(1)}
                      disabled={currentPage === totalPages}
                    >
                      <img src={Images.blueArrowRight} alt="arrowRight" />
                    </div>
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages ? "disableNextbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(1)}
                      disabled={currentPage === totalPages}
                    >
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DocumentTable
          viewDocuments={() => handleOpenModal("viewDocuments")}
          documentList={documentList}
          isDeclien={isDeclien}
          setIsDeclien={setIsDeclien}
          isVerifing={isVerifing}
          setIsVerifing={setIsVerifing}
          currentPage={currentPage}
          perPage={selectPageSize}
          setSelectedDocument={setSelectedDocument}
          loading={loading}
          documentStatus={documentStatus}
          getDocuments={getDocuments}
        />
        {/* custom Modal */}
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          className={
            modalDetail.flag === "viewDocuments"
              ? "shippingAddressContainer"
              : ""
          }
          ids={modalDetail.flag === "viewDocuments" ? "viewDocuments" : ""}
          child={
            modalDetail.flag === "viewDocuments" ? (
              <ViewDocuments
                close={() => handleOnCloseModal()}
                selectedDocument={selectedDocument}
                isViewerOpen={isViewerOpen}
                setIsViewerOpen={setIsViewerOpen}
                viewerImages={viewerImages}
                setViewerImages={setViewerImages}
              />
            ) : (
              ""
            )
          }
          header={
            modalDetail.flag === "viewDocuments" ? (
              <>
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  View Documents
                </h2>
                <img
                  src={Images.modalCross}
                  alt="img"
                  className="cursorPointer"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : (
              <></>
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
      <Viewer
        visible={isViewerOpen}
        onClose={() => setIsViewerOpen(false)}
        images={viewerImages}
        scalable={true}
        zoomable={true}
        rotatable={true}
        downloadable={false}
        noClose={false}
        className="viewer-container"
      />
    </>
  );
};

export default DocumentVerification;
