import React, { useState } from "react";
import OTPInput from "react-otp-input";
import * as Images from "../../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { changePinApiAsync, selectLoginAuth } from "../../../containers/auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// Yup validation schema
const validationSchema = Yup.object({
  otp: Yup.string()
    .length(4, "Passcode must be 4 digits")
    .matches(/^\d{4}$/, "Passcode must be numeric")
    .required("Passcode is required"),
});

const StoreUpdatePassword = (props) => {
  const { t } = useTranslation();
  const [buttonActive, setButtonActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);

  // Function to generate a random 4-digit passcode
  const generateRandomPin = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  // Handle form submission
  const handleSubmit = async (values) => {
    if (loading) return;

    const params = {
      postData: {
        new_pin: values.otp,
        pos_user_id: auth?.payload?.user?.user_profiles?.user_id + "",
      },
      tokenData: auth?.payload?.token,
    };

    setLoading(true);
    dispatch(changePinApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false);
        console.log(res,"check pin")
        toast.success("Passcode updated successfully");
        props?.close();
        // props?.getUserPosDetails();
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Failed to update passcode");
        props?.close();
      });
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <h1 className="fontSize24 fw500 txtBlue mb-0">
          {t('updateStorePasword')}
        </h1>
        <img
          className="ms-auto cursorPointer"
          src={Images.modalCross}
          alt="close"
          onClick={() => {
            props?.close();
          }}
        />
      </div>
      <p className="fontSize16 staffPositionTxt">
        {t('updateStoretitle')}
      </p>

      {/* Formik Form */}
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <div className="pin-box">
              <OTPInput
                numInputs={4}
                className="input_digits_"
                value={values.otp}
                name="otp"
                autoComplete="new-password"
                isInputNum={true}
                isInputSecure={false}
                placeholder="0000"
                onChange={(code) => {
                  setFieldValue("otp", code);
                  if (code.length === 4) {
                    setButtonActive(true);
                  } else {
                    setButtonActive(false);
                  }
                }}
              />
              {touched.otp && errors.otp && (
                <div className="error-message">{errors.otp}</div>
              )}
              <button
                type="button"
                className="generateOtpBtn gap-2"
                onClick={() => {
                  const generatedPin = generateRandomPin();
                  setFieldValue("otp", generatedPin);
                  setButtonActive(true);
                }}
              >
                {t('generate')} <img src={Images.rotateWhiteLogo} alt="img" />
              </button>
            </div>

            <div className="modalfooterBtn align-items-center">
              <button
                disabled={loading}
                className="modalDiscardBtn w-100 flexBox gap-2 justify-content-center"
                onClick={() => {
                  props?.close();
                }}
              >
                {t('cancel')}
              </button>

              <button
                type="submit"
                disabled={loading || !buttonActive}
                className={`modalNextBtn modalDiscardBtn w-100 flexBox gap-2 justify-content-center ${
                  values.otp.length === 4 ? "active" : ""
                }`}
              >
                {/* add loading */}
                {loading && <span className="spinner-border spinner-border-sm spinnerRed"></span>}
                {t('confirm')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StoreUpdatePassword;
