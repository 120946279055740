import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { getTermsConditionsPoliciesApiAsync } from "../../systemConfig/systemConfigSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Loader from "../../../components/UI/Loader/Loader";
import { useTranslation } from "react-i18next";
import { legalPoliciesFlag } from "../../../constants/common.constant";

const LegalPolicies = (props) => {
  const { t } = useTranslation();
  const { setmoreSetting, setPolicy } = props;
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [policyDetail, setPolicyDetail] = useState(null);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [allPolicies, setAllPolicies] = useState([]);
  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });

    setKey(Math.random());
  };

  // End Modal
  const getallPolicies = () => {
    setLoading(true);
    dispatch(getTermsConditionsPoliciesApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let allTypesPolicies = obj?.payload || [];
        let policies = [];
        for (let policyType of allTypesPolicies) {
          policies = [...policies, ...policyType?.flag_policies];
        }
        let allPolicy = policies.sort((a, b) => {
          // Priority Sorting
          if (a.deleted_at === null && b.deleted_at !== null) return -1;
          if (a.deleted_at !== null && b.deleted_at === null) return 1;
          
          if (a.deleted_at === null && b.deleted_at === null) {
              if (a.is_active && !b.is_active) return -1;
              if (!a.is_active && b.is_active) return 1;
          }
      
          // Sort by updated_at in descending order
          return moment(b.updated_at).diff(moment(a.updated_at));
      });
        console.log(allPolicy, "check policy");
        setAllPolicies(allPolicy);
      })
      .catch((obj) => {
        setLoading(false);
        toast.error("Unable to load policies");
      });
  };
  const deletePolicy = () => {
    //   if(!policyDetail.id)return;
    //     let params = {
    //         id:policyDetail.id,
    //         token: auth?.payload?.token
    //     }
    //     setIsDeleting(true);
    //     dispatch(deletePolicyApiAsync(params))
    //         .then(unwrapResult)
    //         .then(res => {
    //             //remove policies from all policies
    //             setAllPolicies(allPolicies.filter(policy => policy.id !== policyDetail.id))
    //             toast.success("Policy deleted successfully");
    //             setIsDeleting(false);
    //             setPolicy(null);
    //             handleOnCloseModal();
    //         })
    //         .catch(err => {
    //             toast.error("Unable to delete policy");
    //             console.log(err);
    //             setIsDeleting(false);
    //         })
  };
  useEffect(() => {
    getallPolicies();
  }, []);
  const handleViewPolicy = () => {
    setmoreSetting("ViewPolicy");
  };
  const availabilityStatusHandle = (id, flag) => {
    // let data = {
    //     "postData": {
    //         'id': id,
    //         "flag": flag
    //     },
    //     "tokenData": auth?.payload?.token
    // }
    // dispatch(activeInactivePolicyApiAsync(data))
    //     .then(unwrapResult)
    //     .then((obj) => {
    //         setLoading(false);
    //         getallPolicies()
    //     })
    //     .catch((obj) => {
    //         setLoading(false)
    //     })
  };
  return (
    <div className="legalMain_ position-relative">
      {loading && <Loader />}
      <div className="legalHead_ d-flex align-items-center justify-content-between">
        <div className="legalleft_">
          <h3 className="appointMain"> {t("legal")} </h3>
          <p className="lighttext14 ">{t("legalTitle")}</p>
        </div>
        <div className="legalrightBtn_">
          <button
            onClick={() => {
              setPolicy(null);
              setmoreSetting("CreateUpdateLegal");
            }}
            className="connectedBtn_"
            type="submit"
          >
            {t("newLegal")}
            <img className="batteryfull_" src={Images.addButtonImg} />
          </button>
        </div>
      </div>

      <div className="leagalInformation_ newLeagalInformation mt-4">
        <div className="row">
          {allPolicies?.map((policy, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div
                className={`legalPolicy_ ${
                  policy?.deleted_at != null
                    ? "archived"
                    : policy?.is_active
                    ? ""
                    : "draftMain"
                } `}
                onClick={() => {
                  setPolicy(policy);
                  setmoreSetting("ViewPolicy");
                }}
              >
                <h5 className={`greenText_ mb-3 d-flex align-items-center`}>
                  <span className="doticon_ me-2"></span>{" "}
                  <span>
                    {policy?.deleted_at != null
                      ? "Archived"
                      : policy?.is_active
                      ? "Published"
                      : "Draft"}
                  </span>
                </h5>
                <h3 className="countNumber_">
                  <img
                    className="batteryfull_ me-1"
                    src={Images.CalendarOutline}
                  />
                  {moment(policy?.created_at).format("MMM Do YYYY, h:mm:ss a")}
                </h3>
                <div className="refundContent_ mt-3 mb-3">
                  <h5 className="headingblue14_ mb-2">{policy?.flag?legalPoliciesFlag[policy?.flag]??policy?.flag:""}</h5>
                  <div
                    className="legalOuterText"
                    dangerouslySetInnerHTML={{
                      __html: policy?.content?.substr(0, 30),
                    }}
                  />
                </div>
                <h5 className="bankaccNumber_">{t("lastUpdate")}:</h5>
                <h5 className="bankaccNumber_date">
                  {moment(policy?.updated_at).format("MMM Do YYYY, h:mm:ss a")}
                </h5>
              </div>
            </div>
          ))}
          {/* <div className='col-md-4'>
                        <div className='legalPolicy_ mb-4'>
                            <h5 className='greenText_ mb-3 d-flex align-items-center'><span className='doticon_ me-2'></span> <span> Published</span></h5>
                            <h3 className='countNumber_'><img
                                className="batteryfull_ me-1"
                                src={Images.CalendarOutline}
                            /> 10 October 2024. 10:10 am</h3>
                            <div className='refundContent_ mt-3 mb-3'>
                                <h5 className='headingblue14_ mb-2'>Privacy Policy</h5>
                                <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.
                                    These Terms will be applied fully and affect to your use of </p>
                            </div>
                            <h5 className='bankaccNumber_'>Last update date:</h5>
                            <h5 className='bankaccNumber_date'>10 October 2024. 10:10 am</h5>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className={`legalPolicy_ ${policy} mb-4`}>
                            <h5 className='greenText_ mb-3 d-flex align-items-center'><span className='doticon_ me-2'></span> <span> Draft</span></h5>
                            <h3 className='countNumber_'><img
                                className="batteryfull_ me-1"
                                src={Images.CalendarOutline}
                            /> 10 October 2024. 10:10 am</h3>
                            <div className='refundContent_ mt-3 mb-3'>
                                <h5 className='headingblue14_ mb-2'>Terms of Service</h5>
                                <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.
                                    These Terms will be applied fully and affect to your use of </p>
                            </div>
                            <h5 className='bankaccNumber_'>Last update date:</h5>
                            <h5 className='bankaccNumber_date'>10 October 2024. 10:10 am</h5>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='legalPolicy_ archived mb-4'>
                            <h5 className='greenText_ mb-3 d-flex align-items-center'><span className='doticon_ me-2'></span> <span> Archived</span></h5>
                            <h3 className='countNumber_'><img
                                className="batteryfull_ me-1"
                                src={Images.CalendarOutline}
                            /> 10 October 2024. 10:10 am</h3>
                            <div className='refundContent_ mt-3 mb-3'>
                                <h5 className='headingblue14_ mb-2'>Shipping Policy</h5>
                                <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.
                                    These Terms will be applied fully and affect to your use of </p>
                            </div>
                            <h5 className='bankaccNumber_'>Last update date:</h5>
                            <h5 className='bankaccNumber_date'>10 October 2024. 10:10 am</h5>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default LegalPolicies;
