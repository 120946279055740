import React from "react";
import * as Images from "../../../../utilities/images";
import moment from "moment-timezone";

const ViewDocuments = (props) => {
  const { selectedDocument, setIsViewerOpen, setViewerImages, close } = props;

  const openImageViewer = (frontImage, backImage, title) => {
    const imagesToShow = [];

    if (frontImage) {
      imagesToShow.push({ src: frontImage, alt: `${title} Front` });
    }
    if (backImage) {
      imagesToShow.push({ src: backImage, alt: `${title} Back` });
    }

    if (imagesToShow.length > 0) {
      setViewerImages(imagesToShow);
      setIsViewerOpen(true);
      // close();
    }
  };

  return (
    <>
      <div className="shippingModalParent ">
        <div className="shippingBoxes">
          <figure
            className="shippingImgParent"
            onClick={() =>
              openImageViewer(
                selectedDocument?.driving_license_front_photo,
                selectedDocument?.driving_license_back_photo,
                "Driver's License"
              )
            }
          >
            <img
              src={selectedDocument?.driving_license_front_photo}
              alt="img"
              width={54}
              height={70}
            />
          </figure>
          <div>
            <h3 className="fontSize18 fw500 txtDarkblue mb-1">
              Driver’s License
            </h3>
            {/* <p className="textgreylight mb-1">PNG  | 3.98 MB</p> */}
            {/* <p className="textgreylight">
              {" "}
              <img
                className="me-1"
                src={Images.checkfillfull}
                alt="img"
                width={16}
                height={16}
              />{" "}
              Last Update: 12/27/2023
            </p> */}
          </div>
        </div>
        <div className="shippingBoxes">
          <figure
            className="shippingImgParent"
            onClick={() =>
              openImageViewer(
                selectedDocument?.registration_expiry_photo,
                selectedDocument?.registration_back_photo,
                "Vehicle Registration"
              )
            }
          >
            <img
              src={selectedDocument?.registration_expiry_photo}
              alt="img"
              width={54}
              height={70}
            />
          </figure>
          <div>
            <h3 className="fontSize18 fw500 txtDarkblue">
              Vehicle Registration
            </h3>
            {/* <p className="textgreylight mb-1">PNG  | 3.98 MB</p> */}

            <p className="textgreylight">
              <img
                className="me-1"
                src={Images.checkfillfull}
                alt="img"
                width={16}
                height={16}
              />
              LastUpdate:
              {moment(selectedDocument?.registration_expiry_date).format(
                "MM/DD/YYYY"
              )}
            </p>
          </div>
        </div>
        <div className="shippingBoxes">
          <figure
            className="shippingImgParent"
            onClick={() =>
              openImageViewer(
                selectedDocument?.insurance_expiry_photo,
                selectedDocument?.insurance_back_photo,
                "Vehicle Insurance"
              )
            }
          >
            <img
              src={selectedDocument?.insurance_expiry_photo}
              alt="img"
              width={54}
              height={70}
            />
          </figure>
          <div>
            <h3 className="fontSize18 fw500 txtDarkblue">Vehicle Insurance</h3>
            {/* <p className="textgreylight mb-1">PNG  | 3.98 MB</p> */}
            <p className="textgreylight">
              <img
                className="me-1"
                src={Images.checkfillfull}
                alt="img"
                width={16}
                height={16}
              />
              LastUpdate :
              {moment(selectedDocument?.insurance_expiry_date).format(
                "MM/DD/YYYY"
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDocuments;
