import moment from "moment-timezone"

export const    BookingColumns = [
    {
        isVisible: true,
        title: "Customer",
        isDefault:true,
        languageText:"customer"
    },
    {
        isVisible: true,
        title: "Time",
        isDefault:true,
        languageText:"time"
    },
    {
        isVisible: true,
        title: "Invoices",
        isDefault:true,
        languageText:"invoice"
    },
    {
        isVisible: true,
        title: "Staff",
        isDefault:true,
        languageText:"staff"
    },
    {
        isVisible: true,
        title: "Services",
        isDefault:true,
        languageText:"services"
    },
    {
        isVisible: true,
        title: "Payment Method",
        isDefault:true,
        languageText:"paymentMethod"
    },
    {
        isVisible: true,
        title: "Total Amount",
        isDefault:true,
        languageText:"totalAmount"
    },
    
    {
        isVisible: false,
        title: "Location",
        isDefault:false,
        languageText:"location"
    },
    {
        isVisible: false,
        title: "Tips",
        isDefault:false,
        languageText:"tip"
    },
    {
        isVisible: false,
        title: "Taxes",
        isDefault:false,
        languageText:"taxes"
    },
    // {
    //     isVisible: true,
    //     title: "Action"
    // }
]
export const AllStatus=[
    { value: "", label: "All Status",
        languageText:"allStatus"
     },
    {value:"0",label:"Review",
        className:"revieworder",
        languageText:"review"
    },
    {value:"1",label:"Checked-In",
        className:"accepted",
        languageText:"checkedIn"
    },
    {value:"2",label:"Mark Done",
        className:"prepariging",
        languageText:"markDone"
    },
    {value:"3",label:"Completed",
        className:"completed",
        languageText:"completed"
    },
    {value:"4",label:"Cancelled",
        className:"cancelled",
        languageText:"cancelled"
    },
    {value:"5",label:"Rejected",
        className:"returned",
        languageText:"returned"
    },
]

export const AllServices=[
    {value:"0",label:"All Services"}
]
export const AllStuffs=[
    {value:"0",label:"All Stuffs"}
]
export const filterOptions = [
    {
        title: "All Stuffs",
        data: AllStuffs
    },
    {
        title: "All Services",
        data: AllServices
    },
    {
        title: "All Status",
        data: AllStatus
    },
]
export const APPOINTMENT_STATUS = {
    REVIEWING: 0,
    ACCEPTED_BY_SELLER: 1,
    CHECKED_IN: 2,
    COMPLETED: 3,
    REJECTED_BY_SELLER: 4,
    CANCELLED_BY_CUSTOMER: 5,
  };
  export const CALENDAR_MODES = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
  };
  
  export const CALENDAR_VIEW_MODES = {
    LIST_VIEW: "LIST_VIEW",
    CALENDAR_VIEW: "CALENDAR_VIEW",
  };
  
  export const DELIVERY_MODE = {
    1: "Delivery",
    2: "Reservation",
    3: "Walkin",
    4: "Shipping",
  };
  
  export const CALENDAR_TIME_FORMAT = {
    TWELVE_HOUR: true, // In react native big calendar package `ampm` true means it is 12 hour format
    TWENTY_FOUR_HOURS: false, // In react native big calendar package `ampm` false means it is 24 hour format
  };
  
  export const APPOINTMENT_REQUEST_MODE = {
    MANUAL: "manually",
    AUTOMATIC: "automatically",
  };
  export const EMPLOYEES_COLOR_SET_MODE = {
    DEFAULT: "default",
    MANUAL: "manual",
  };
  
 export const isHideCheckInButton = (startDateTime,approxServiceTime) => {
      const formatedDate = moment(startDateTime);
      const currentDateTime = moment();
      const durationInMinutes = currentDateTime.diff(formatedDate, "minutes");
      return (
        formatedDate.isAfter(currentDateTime)||
        durationInMinutes > approxServiceTime
      );
    };

