import React, { useCallback, useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import * as Images from "../../../utilities/images";
import UploadImage from "./uploadImage";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import {
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import AddCashDrawModal from "../cashDrawer/addCashDrawModal";
import CustomModal from "../../components/shared/CustomModal";
import CreateCategoryModal from "./createCategoryModal";
import DeleteModal from "../myProducts/DeleteModal";
import DeleteItemModal from "../../components/shared/modals/deleteItemModal";
import { useTranslation } from "react-i18next";

const ManageCategories = () => {
  const { t } = useTranslation();
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [services, setServices] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    rowIndex: "",
  });
  const [selectedCategory, setSelectedCategory] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [servicesId, setServicesId] = useState("");

  const handleServicesChange = (e) => {
    setSelectedService(e);
    setServicesId(e.value);
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
    setSelectedCategory({});
    setCategoryId("");
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalRows / perPage)) {
      setCurrentPage(page);
    }
  };

  const handleDeleteProduct = () => {};

  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getAllCategory = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        check_product_existance: false,
        page: currentPage,
        limit: perPage,
        service_id:servicesId
      },
    };
    setCategoryLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setCategoryLoading(false);
        setCategory(obj?.payload?.data || []);
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setCategoryLoading(false);
      });
  };

  useEffect(() => {
    getAllCategory();
  }, [currentPage , servicesId]);
  useEffect(() => {
    getServices();
  }, []);

  return (
    <div className="manageCategoriesSection chartsOuter fullheightBox_ mt-0 p-4">
      {/* Start Manage Categories Section */}
      <div className="verificationTotal homePage">
        <DashboardNavbar title={t("manageCategories")} show={false} />
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <div className="form_group pt-0 me-2">
          <div className="reactSelectParent commonSelectParent w-100">
            <ReactSelect
              value={selectedService}
              options={services}
              onChange={handleServicesChange}
              isSearchable={true}
              placeholder={t("selectMasterCategory")}
              classNamePrefix="react-select"
              className="react-select-container anlyticSelect"
              styles={{
                control: (provided) => ({
                  ...provided,
                  maxWidth: "100% !important",
                  width: "100%",
                }),
                menu: (provided) => ({
                  ...provided,
                  border: "1px solid #D7DEFF",
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  paddingLeft: "unset !important",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#263682"
                    : state.isFocused
                    ? "#f5f6fc"
                    : "#fff",

                  color: state.isSelected ? "#fff" : "#263682",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#636E9F",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }),
              }}
              components={{
                DropdownIndicator: () => (
                  <img
                    src={Images.selectImg}
                    width={24}
                    height={24}
                    alt="drop_icon"
                    className="mt-1"
                  />
                ),
              }}
            />
          </div>
        </div>

        <button
          className="commonBtn"
          onClick={() => {
            setModalDetail({
              show: true,
              flag: "createCategory",
            });
            setKey(Math.random());
          }}
        >
          <img src={Images.btnPlusIcon} alt="logo" className="me-2" />
          {t("createCategory")}
        </button>
      </div>

      <div className="cashDrawOuter mt-4">
        {categoryLoading ? (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <span className="spinner-border spinner-border-md spinnerDark"></span>
            </div>
          </>
        ) : category && category.length > 0 ? (
          category.map((value, idx) => (
            <div className="cashDrawInner" key={idx}>
              <div className="InnerLeft d-flex align-items-center gap-3">
                <img src={value?.image} alt="img" className="productItemImg" />
                <div>
                  <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left textCapital">
                    {value.name}
                  </h3>
                  <div className="d-flex align-items-center gap-2">
                    {/* <span className="skyblueDot"></span> */}
                    <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                      {t("masterCategories")}: {value?.service?.name}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="cashInnerRight">
                <>
                  <button
                    className="cashBtnEdit"
                    onClick={() => {
                      setModalDetail({
                        show: true,
                        flag: "createCategory",
                        title: "edit",
                      });
                      setKey(Math.random());
                      setSelectedCategory(value);
                    }}
                  >
                    <img
                      src={Images.editIcon}
                      alt="editIcon"
                      className="cashIcon"
                    />
                    {t('edit')}
                  </button>
                  <button
                    className="cashBtnDelete"
                    onClick={() => {
                      setModalDetail({
                        show: true,
                        flag: "deleteProductModal",
                      });
                      setKey(Math.random());
                      setCategoryId(value?.id);
                    }}
                  >
                    <img
                      src={Images.Delete_Outline}
                      alt="editIcon"
                      className="img-fluid"
                    />
                    {t('delete')}
                  </button>
                </>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="cashDrawInner justify-content-center">
              <h4 className="fontSize20 txtDarkblue m-0">{t("noCategoryFound")}</h4>
            </div>
          </>
        )}

        {Array.isArray(category) && category.length > 0 && (
          <div className="allProductPagination d-flex justify-content-between align-items-center mt-3">
            <div
              className={`d-flex gap-2 cursorPointer ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              style={{
                pointerEvents: currentPage === 1 ? "none" : "auto",
              }}
            >
              <img
                src={Images.lightLeftArrowLogo}
                alt="Prev"
                width={16}
                height={16}
                style={{
                  filter:
                    currentPage !== 1
                      ? "invert(77%) sepia(73%) saturate(2071%) hue-rotate(165deg) brightness(100%) contrast(83%)"
                      : "",
                }}
              />
              <span
                className={`fontSize12 fw500 ${
                  currentPage === 1 ? "txtLightPurple" : "txtDarkblue"
                }`}
              >
                {t("previous")}
              </span>
            </div>

            <h3 className="fontSize12 fw500 txtLightBlue">
              Page {currentPage} of {Math.ceil(totalRows / perPage)}
            </h3>

            <div
              className={`d-flex gap-2 cursorPointer ${
                currentPage >= Math.ceil(totalRows / perPage) ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              style={{
                pointerEvents:
                  currentPage >= Math.ceil(totalRows / perPage)
                    ? "none"
                    : "auto",
              }}
            >
              <span
                className={`fontSize12 fw500 ${
                  currentPage >= Math.ceil(totalRows / perPage)
                    ? "txtLightPurple"
                    : "txtDarkblue"
                }`}
              >
                {t('next')}
              </span>
              <img
                src={Images.webPosArrow}
                alt="Next"
                width={16}
                height={16}
                style={{
                  filter:
                    currentPage >= Math.ceil(totalRows / perPage)
                      ? "brightness(0) saturate(100%) invert(75%) sepia(41%) saturate(351%) hue-rotate(196deg) brightness(95%) contrast(94%)"
                      : "",
                }}
              />
            </div>
          </div>
        )}
      </div>

      {/* End Manage Categories Section */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "createCategory" ? "addCategory" : ""}
        child={
          modalDetail.flag === "createCategory" ? (
            <CreateCategoryModal
              services={services}
              close={handleOnCloseModal}
              title={modalDetail?.title}
              selectedCategory={selectedCategory}
              getAllCategory={getAllCategory}
            />
          ) : modalDetail.flag === "deleteProductModal" ? (
            <DeleteItemModal
              close={() => handleOnCloseModal()}
              handleDeleteProduct={handleDeleteProduct}
              deleteLoading={deleteLoading}
            />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          modalDetail.flag === "createCategory" ? (
            <>
              <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                {modalDetail?.title ? t("updateCategory") : t("createCategory")}
              </h3>
            </>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default ManageCategories;
