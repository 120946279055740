import React from "react";
import * as Images from "../../../../utilities/images";

const InvoiceModal = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="staffInvoice_Parent">
              <div className="flexBox justify-content-between">
                <img src={Images.jobrMainLogo} alt="logo" />
                <img src={Images.modalCross} alt="logo" onClick={()=>props?.close()}/>
              </div>
              <div className="invoice_InfoBox">
                <div>
                  <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                    Miami, FL.
                  </h3>
                  <h5 className="fontSize10 txtDarkblue fw400 mb-0 pt-2">
                    +1 (305) 305-0000
                  </h5>
                  <h5 className="fontSize10 txtDarkblue fw400 pt-1">
                    mailto:contact@jobr.com
                  </h5>
                </div>
                <button className="jobrComBtn">jobr.com</button>
              </div>

              <div className="invoiceDetailContainer">
                <div className="row">
                  <div className="col-md-3">
                    <div>
                      <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                        Invoice
                      </h3>
                      <h5 className="fontSize10 txtDarkblue fw400 pt-1">
                        Nº 0000A
                      </h5>
                    </div>
                    <div className="date_Box">
                      <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                        Date
                      </h3>
                      <h5 className="fontSize10 txtDarkblue fw400 pt-1">
                        07/10/2021
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-9 ps-0">
                    <div className="billBox">
                      <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                        Bill To
                      </h3>
                      <div className="billBox_Inner pt-2 d-flex align-items-center justify-content-between">
                        <div>
                          <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                            Halton Company
                          </h3>
                          <h3 className="fontSize10 txtDarkblue fw500 pt-2">
                            123 Miami Avenue - London
                          </h3>
                        </div>
                        <div>
                          <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                            +00325 625 632
                          </h3>
                          <h3 className="fontSize10 txtDarkblue fw500 pt-2">
                            CIF 1238888B
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-8 pe-0">
                          <div className="invoiceDesc_Table">
                            <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                              Add item description for service or equipment
                            </h3>
                            <h3 className="fontSize10 txtDarkblue mb-0 fw300 pt-1">
                              Add observations{" "}
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end ">
                              00
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end">
                              $0000
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 pe-0">
                          <div className="invoiceDesc_Table">
                            <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                              Add item description for service or equipment
                            </h3>
                            <h3 className="fontSize10 txtDarkblue mb-0 fw300 pt-1">
                              Add observations{" "}
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end">
                              00
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end">
                              $0000
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 pe-0">
                          <div className="invoiceDesc_Table">
                            <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                              Add item description for service or equipment
                            </h3>
                            <h3 className="fontSize10 txtDarkblue mb-0 fw300 pt-1">
                              Add observations{" "}
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table  ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end">
                              00
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table  ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end">
                              $0000
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 pe-0">
                          <div className="invoiceDesc_Table">
                            <h3 className="fontSize10 txtDarkblue fw500 mb-0">
                              Add item description for service or equipment
                            </h3>
                            <h3 className="fontSize10 txtDarkblue mb-0 fw300 pt-1">
                              Add observations{" "}
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table  ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end">
                              00
                            </h3>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="invoiceDesc_Table  ps-0">
                            <h3 className="fontSize10 txtDarkblue fw400 text-end">
                              $0000
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      <div className="col-md-4">
                        <div className="invoicetotalBox">
                          <div className="invoiceTotalInner">
                            <h3 className="fontSize10 txtDarkblue fw400 mb-0">
                              Subtotal
                            </h3>
                            <h3 className="fontSize10 txtDarkblue fw400 mb-0">
                              $0000
                            </h3>
                          </div>
                          <div className="invoiceTotalInner">
                            <h3 className="fontSize10 txtDarkblue fw400 mb-0">
                              Tax
                            </h3>
                            <h3 className="fontSize10 txtDarkblue fw400 mb-0">
                              $0000
                            </h3>
                          </div>
                          <div className="invoiceTotalInner">
                            <h3 className="fontSize10 txtDarkblue fw400 ">
                              Total
                            </h3>
                            <h3 className="fontSize10 txtDarkblue fw400 ">
                              $0000
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="invoiceBottomBox">
                      <div className="row">
                        <div className="col-md-9 col-12">
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <h3 className="fontSize10 txtDarkblue fw500 ">
                                  Payment
                                </h3>
                                <h3 className="fontSize10 txtDarkblue fw400 pt-3 lineHeight">
                                  By Bank <br /> London State Bank <br /> LN34
                                  00 1258 99 6874 15587
                                </h3>
                              </div>
                            </div>
                            <div className="col-md-6 pe-0">
                              <div className="mt-md-0 mt-3">
                                <h3 className="fontSize10 txtDarkblue fw500 ">
                                  Conditions
                                </h3>
                                <h3 className="fontSize10 txtDarkblue fw400 pt-3 lineHeight">
                                  Lorem ipsum dolor sit amet, <br />
                                  consectetuer adipiscing
                                  <br /> elit,.
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceModal;
