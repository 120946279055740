import React, { forwardRef, useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getPosdetailsAsync, getPosUserdetailsAsync, selectedPosUserData, selectedPosUserDetails, selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { getStaffSalaryDetailsApiAsync, getStaffSalaryDetailsAsync, requestSalaryRequestAsync } from '../../dashboard/dashboardSlice';
import CustomModal from '../../../components/shared/CustomModal';
import AccountPassModal from '../../../components/shared/modals/AccountPassModal';
import CreateNewPassModal from '../../../components/shared/modals/CreateNewPassModal';
import StoreUpdatePassword from '../../../components/shared/modals/StoreUpdatePassword';
import InvoiceModal from './InvoiceModal';
import EditProfileModal from './EditProfileModal';
import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";
import Loader from '../../../components/UI/Loader/Loader';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


export const amountFormat = (price, notSign) => {
  const stringCheckAmount = typeof price === "string" ? Number(price) : price;


  // Check if the price is negative
  const isNegative = stringCheckAmount < 0;

  // Remove the negative sign temporarily for formatting
  const formattedAmount = Math.abs(stringCheckAmount).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );

  const dollarSign = notSign ? "" : "$";

  // Add the dollar sign before the formatted amount
  const amountWithSign = `${isNegative ? "-" : ""
    }${dollarSign}${formattedAmount}`;

  return amountWithSign || "$0.00";
};


const PersonalDetail = (props) => {
  const { t } = useTranslation();
  const history = useHistory()
    const [calendarDate, setCalendarDate] = useState(new Date());
   const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [posDetail, setposDetail] = useState(null)
  const [sallaryInfo, setSallaryInfo] = useState();
  const [expandedRow, setExpandedRow] = useState(null);
  const auth = useSelector(selectLoginAuth);
  const [currentPassword, setcurrentPassword] = useState(null);
    const userPosDetails = useSelector(selectedPosUserDetails);
    const [posStaffSalaryStatus, setposStaffSalaryStatus] = useState('');
    const [loadSalary, setloadSalary] = useState(false)
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState(null);
  const handleRequestClick = (index) => {
    // Toggle the expanded view
    setExpandedRow(expandedRow === index ? null : index);
  };
  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });

    setKey(Math.random());
  };
  // End Modal
  const getSalaryDetails = async (id) => {
    let params = {
      postData: {
        pos_staff_detail_id: id,
        ...(posStaffSalaryStatus.length&&{
          status: posStaffSalaryStatus
        }),
        ...((endDate&&endDate!=startDate)&&{
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD")

        })

      },
      tokenData: auth?.payload?.token
    }
    try {
      setloadSalary(true);
   let res=await dispatch(getStaffSalaryDetailsAsync(params))
      .then(unwrapResult);
        setSallaryInfo(res?.payload?.data || [])
        setloadSalary(false);
        
    } catch (error) {
      toast.error("Unable to load salary details");
      
    }
    finally{
      setloadSalary(false);

    }
  }
  const getPostDetils=(id)=>{
    let params={
     postData:{
      id:id,
     },
      tokenData: auth?.payload?.token
    }
    dispatch(getPosdetailsAsync(params))
    .then(unwrapResult)
    .then(res=>{
      setposDetail(res?.payload)
    })
    .catch(err=>{
    })
  }
  useEffect
    (() => {
      if (props?.userDetails?.id) {
        getSalaryDetails(props?.userDetails?.id);
        getPostDetils(props?.userDetails?.id);
      }
      setUserDetails(auth?.payload)
    }, [props.userDetails,endDate,posStaffSalaryStatus])
    useEffect(()=>{
      setUserDetails(auth?.payload);
    },[auth?.payload])

    const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => (
        <div className="date-picker-container" onClick={onClick}>
          <img className="calendar-icon" src={Images.leftArrow} alt="Calendar" />
          <p className="date-text">
            {moment.utc(calendarDate).format("MMMM YYYY")}
          </p>
          <img
            className="arrow-icon"
            src={Images.newRightArrow}
            alt="Dropdown Arrow"
          />
        </div>
      ));
        const [sellerAddressesDetails, setsellerAddressesDetails] = useState(null);
        const selectOption = [
          { value: "", label: "All" },
          { value: "0", label: "Not Requested" },
          { value: "1", label: "Requested" },
          { value: "2", label: "Paid" },
        ];
        const handleRequest = (data) => {
          if (data?.status === 0 || data?.status === 1) {
            let params = {
              postData:{
                // start_date: moment(data?.start_time).format("YYYY-MM-DD"),
              // end_date: moment(data?.end_time).format("YYYY-MM-DD"),
              // staff_details_id: props?.selectedItemId?.toString(),
              // "staff_details_id": '2'
              salary_id: data?.id,
              },
              tokenData: auth?.payload?.token
            };
            dispatch(
              requestSalaryRequestAsync(params)
            ).then(unwrapResult)
            .then(res=>{
              getSalaryDetails();
            }).catch(err=>{})
          } else {
            // props?.handleTouch("staffLocation", data);
          }
        };
        let currentAddress=userDetails?.user?.user_profiles?.current_address;
        const formattedAddress = currentAddress
    ? [
      currentAddress?.street_address,
      currentAddress?.city,
      currentAddress?.state,
      currentAddress?.zipcode,
      ]
        .filter(Boolean) // Removes undefined or null values
        .join(", ") // Joins the remaining values with a comma
    : "---------";
  return (
    <div className='personalDetail_'>
      <div className='moresettingHead_'>
        <h3 className='appointMain'>{t("personalDetails")}</h3>
        <button className='editprofile' type='submit' onClick={() => {
          handleOpenModal("editProfileModal")
        }}>{t("editProfile")} <img className='' src={Images.editprofile} alt='edit'  /> </button>
      </div>
      <div className="staffInfoBox">
        <div className='row'>
          <div className='col-md-6'>
            <div className="staffProfileInfo">
              <div className='sidebarProfileImg'>
                  <img className='img-fluid' src={userDetails?.user?.user_profiles?.profile_photo|| Images.userImg_} alt='edit' />
               </div>
              <div className="staffProfileSub">
                <h4 className="appointMain">
                  {
                    (userDetails?.user?.user_profiles?.firstname??"__") + " " +
                    (userDetails?.user?.user_profiles?.lastname??"__")
                  }
                </h4>
                <div className="staffAddress">
                  <img className='' src={Images.darkblueLocationIcon} alt='edit' />
                  <h4 className="headingblue14_ m-0">
                    {
                      formattedAddress
                    }
                  </h4>
                </div>
                <div className="staffAddress">
                  <img className='' src={Images.darkbluePhoneIcon} alt='edit' />
                  <h4 className="headingblue14_ m-0">
                    {
                      userDetails?.user?.user_profiles?.full_phone_number
                    }
                  </h4>
                </div>
                <div className="staffAddress">
                  <img className='' src={Images.emailLogo} alt='edit' />
                  <h4 className="headingblue14_ m-0">
                    {userDetails?.user?.email||userDetails?.email}
                  </h4>
                </div>
                
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="staffProfileData">
              <div className="flexDiv">
                <h4 className="stateList_head m-0">{t("joinedDate")}</h4>
                <h4 className="headingblue14_ m-0">
                  {
                    moment(userDetails?.user?.user_profiles?.created_at).format(
                      "MMM DD, YYYY"
                    )
                  }
                </h4>
              </div>
              <div className="flexDiv mt-2">
                <h4 className="stateList_head m-0">{t("activeSince")}:</h4>
                <h4 className="headingblue14_ m-0">
                  {
                  moment().diff(userDetails?.user?.user_profiles?.created_at, 'days')
                  }
                  {" "}days


                </h4>
              </div>
              <div className="flexDiv mt-2">
                <h4 className="stateList_head m-0">{t("employmenttype")}</h4>
                <h4 className="headingblue14_ m-0">
                  {
                    posDetail?.employment_type?.split("_")?.map((word)=>word?.charAt(0)?.toUpperCase()+word?.slice?.(1))?.join(" ")??"__"
                  }
                </h4>
              </div>
              <div className="flexDiv mt-2">
                <h4 className="stateList_head m-0">{t("dayOff")}</h4>
                <h4 className="headingblue14_ m-0">
                  {
                    posDetail?.
                    days_off??"__"
                    
                  }
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='persoanlSignin_'>
        <h4 className='headblue18 mt-3 ps-3' onClick={() => {
          handleOpenModal("invoiceModal")
        }}>Sign in</h4>
        <div className='merchantsign_'>
          <div  className='merchantsign_inner d-flex align-items-center justify-content-between'>
            <article>
              <h5 className='expectedHeading'>{t("merchantAdminLogin")}</h5>
              <h6 className='innerItemstext_'>{t("lastChanged")}{
                moment(userDetails?.user?.updated_at)
                 .format("MMM DD, YYYY")
                }</h6>
            </article>
            <div onClick={() => {
            handleOpenModal("accountPassModal")
          }} className='arrowImgbox_'>
              <img src={Images.upArrow} alt='' className='' />
            </div>
          </div>
          <div className='merchantsign_inner border-none'>
            <div  className='storeInfo_'>
              <article>
                <h5 className='expectedHeading'>{t("persoanlPos")}</h5>
                <h6 className='innerItemstext_'>{t('personalPostDetails')}</h6>
              </article>

            </div>
            <div className='storeInfo_'>
              <article>
                <h5 className='expectedHeading mt-4'>{
                  userDetails?.user?.user_profiles?.security_pin
}</h5>
                <h6 className='innerItemstext_'>Owner at Store LLC</h6>
              </article>
              <div onClick={() => {
              handleOpenModal("storeUpdatePassword")
            }} className='arrowImgbox_'>
                <img src={Images.upArrow} alt='' className='' />
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='usertimeset_'>
        <h4 className='headblue18 mt-3 ps-3'>{t("workLog")}</h4>
        <div className="staffTimeSection mt-3">
          <div className="staffSubTime">
            <h4 className="stateList_head m-0">{t("hoursRate")}</h4>
            <hr className="staffTimeDivide" />
            <h4 className="headingblue14_ m-0">
            ${" "}{Number(posDetail?.hourly_rate||0)}{" "} hr
            </h4>
          </div>
          <div className="staffSubTime">
            <h4 className="stateList_head m-0">{t("overTimeRate")}
            </h4>
            <hr className="staffTimeDivide" />
            <h4 className="headingblue14_ m-0">
            ${" "}{Number(posDetail?.overtime_rate||0)}{" "}hr
            </h4>
          </div>
          <div className="staffSubTime">
            <h4 className="stateList_head m-0">{t("paymentCycle")}</h4>
            <hr className="staffTimeDivide" />
            <h4
              className="headingblue14_ m-0"
              style={{
                textTransform: "capitalize",
              }}
            >
              {
                posDetail?.payment_cycle

              }
            </h4>
          </div>

          <div className="staffSubTime">
            <h4 className="stateList_head m-0">{t("billing")}</h4>
            <hr className="staffTimeDivide" />
            <h4
              className="headingblue14_ m-0"
              style={{
                textTransform: "capitalize",
              }}
            >
              {
                posDetail?.billing_type
              }
            </h4>
          </div>
        </div>
        <div className="staffTimeSection mt-3">
          <div className="staffSubTime">
            <h4 className="stateList_head m-0">{t('currentBillingCycle')}</h4>
            <hr className="staffTimeDivide" />
            <h4 className="headingblue14_ m-0">
            {posDetail?.payment_cycle?.toLowerCase() !== "daily"
                  ? (posDetail?.current_billing_cycle?.start ?? "__") +
                    "- " +
                    (posDetail?.current_billing_cycle?.end || "__")
                  : posDetail?.current_billing_cycle?.start ?? "__"}
            </h4>
          </div>
          <div className="staffSubTime">
            <h4 className="stateList_head m-0">{t('timeTracked')}
            </h4>
            <hr className="staffTimeDivide" />
            <h4 className="headingblue14_ m-0">
               {posDetail?.total_tracked_hours??"__"} hr
            </h4>
          </div>

          <div className="staffSubTime">
            <h4 className="stateList_head m-0">{t('weeklyTrackLimit')}</h4>
            <hr className="staffTimeDivide" />
            <h4
              className="headingblue14_ m-0"
              style={{
                textTransform: "capitalize",
              }}
            >
              {
                posDetail?.weekly_tracking_limit??"__"
              }hr
            </h4>
          </div>

        </div>
      </div>
      <div className="staffDetailtable perDetaiTable position-relative">
        {
          loadSalary&&<Loader/>
        }
      <div className="d-flex align-items-center gap-2 mb-2">
            <div className="bookingLeftTwo">
              <ReactDatePicker
                onChange={(date) => {
                  const [start, end] = date;
                 setStartDate(start);
                 setEndDate(end);

                  
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={<DatePickerCustomComponent />}
              />
            </div>
            <div className="reactSelectParent">
              <ReactSelect
                options={sellerAddressesDetails?sellerAddressesDetails:selectOption}
                onChange={(event)=>{
                  setposStaffSalaryStatus(event.value);
                  
                }}
                placeholder={t("status")}
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682" // Background color for selected option
                      : state.isFocused
                        ? "#f5f6fc" // Background color for hovered option
                        : "#fff", // Default background color
                    // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                    color: state.isSelected
                      ? "#fff" // Background color for selected option
                      : "#000", // Text color
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#263682", // Customize placeholder color if needed
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                // onChange={onChannelChange}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>

          
          </div>
        <div className="staffTableHeading">
          <div className="staffBoxData text-start">
            <h4 className="invoiceHeading ">{t('date')}</h4>
          </div>
          <div className="staffBoxData">
            <h4 className="invoiceHeading p-0">{t('duration')}</h4>
          </div>
          <div className="staffBoxData">
            <h4 className="invoiceHeading p-0">{t('amount')}</h4>
          </div>
          <div className="staffBoxData">
            <h4 className="invoiceHeading p-0">{t("status")}</h4>
          </div>
          <div className="staffBoxData">
            <h4 className="invoiceHeading p-0">{t("action")}</h4>
          </div>
        </div>
        <>
          {sallaryInfo?.length > 0 ? (
            sallaryInfo?.map((data, index) => (
              <div
                className={`staffTableOuter ${expandedRow === index ? "active" : ""
                  }`}
                key={index}
                onClick={() => handleRequestClick(index)}
              >
                <div className="staffTableData">
                  <div className="staffBoxData text-start">
                      <h4 className="staffTableText ps-2">
                        {moment(data?.start_time).format("MMM DD, YYYY")}
                          {moment(data?.start_time).isSame(
                            data?.end_time,
                            "day"
                          )
                            ? ""
                            : ` - ${moment(data?.end_time).format(
                                "MMM DD, YYYY"
                              )}`}
                      </h4>
                     
                  </div>
                  <div className="staffBoxData">
                    <h4 className="staffTableText">
                      {Number(data?.duration)?.toFixed(2)} hrs
                    </h4>
                  </div>
                  <div className="staffBoxData">
                    <h4 className="staffTableText">{amountFormat(data?.amount)}</h4>
                  </div>
                  <div className="staffBoxData">
                  {data?.status === 0 ? (
                          <button className="unpaidBtn " type="button">
                            {t('Not Requested')}
                          </button>
                        ) : data?.status === 2 ? (
                          <button className="paidBtn " type="button">
                            {t('paid')}
                          </button>
                        ) : data?.status === 1 ? (
                          <button
                            className="salary-requested-button  paidBtn"
                            type="button"
                          >
                            {t('requested')}
                          </button>
                        ) : (
                          ""
                        )}

                    {/* <button className="unpaidBtn" type="button">
                          {data.status === 0
                            ? "Unpaid"
                            : data.status === 1
                            ? "Request Sent"
                            : "Paid"}
                        </button> */}
                  </div>
                  <div className="staffBoxData" style={{
                    marginRight: data?.drawer_sessions?.length > 0 ? "-20px" : ""
                  }}>
                    {/* <button
                      className=" perDetViewBtn me-2"
                      type="button"
                      onClick={() =>
                       {
                        // history.push({
                        //   pathname: "/settings/staff/staffInvoice",
                        //   query: { data: JSON.stringify(data) },
                        // })
        

                       }
                      }
                    >
                      {t('view')}
                    </button> */}
                    {data.status == 0 ? (
                          <button
                            className="salary-requested-button  paidBtn"
                            type="button"
                            onClick={() => handleRequest(data)}
                          >
                            {t('request')}
                          </button>
                        ) : data.status == 2 ? (
                          <button
                            className="viewBtn "
                            type="button"
                            onClick={() =>
                              history.push({
                                pathname: "/settings/staff/staffInvoice",
                                query: { data: JSON.stringify(data) },
                              })
                            }
                          >
                            {t('view')}
                          </button>
                        ) : (
                          ""
                        )}

                    {/* {expandedRow === index ? (
                          <button
                            className="viewBtn "
                            type="button"
                            onClick={() =>
                              router.push({
                                pathname: "/settings/staff/staffInvoice",
                                query: { data: JSON.stringify(data) },
                              })
                            }
                          >
                            {t("view")}
                          </button>
                        ) : (
                          <>
                            {data.status === 0 ? (
                              <button
                                className="requestBtn"
                                type="button"
                                onClick={() => handleRequestClick(index)}
                              >
                                {t("request")}
                              </button>
                            ) : (
                              <button
                                className="viewBtn "
                                type="button"
                                onClick={() => handleRequestClick(index)}
                              >
                                {t("view")}
                              </button>
                            )}
                          </>
                        )} */}

                  </div>
                  <div>
                    {data?.drawer_sessions?.length > 0 && (
                      <img
                        src={Images.arrowDown}
                        alt="calendarimage"
                        className="img-fluid"
                      />
                    )}
                  </div>
                </div>


                {expandedRow == index && (
                  <>
                    {data?.drawer_sessions?.length > 0 && (
                      <>
                        {data?.drawer_sessions.map((val, index) => {
                          const end_session = moment(val?.end_session);
                          const start_session = moment(val?.start_session);
                          const differenceInHours = (
                            end_session.diff(start_session, "minutes") / 60
                          ).toFixed(2);
                          return (
                            <div
                              style={{ marginTop: index == 0 ? 22 : 0 }}
                              className="staffTableSub"
                            >
                              <div className="staffSecond">
                                <div className="staffBoxData text-start">
                                  <h4 className="staffTableText">
                                    {moment(val?.start_session).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </h4>
                                </div>
                                <div className="staffBoxData text-start">
                                  <h4 className="staffTableText">
                                    {moment(data?.start_time).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </h4>
                                </div>
                                <div className="staffBoxData text-start">
                                  <h4 className="staffTableText">
                                    {moment(val?.start_session).format(
                                      "hh:mm:ss A"
                                    )}
                                  </h4>
                                </div>
                                <div className="staffBoxData text-start">
                                  <h4 className="staffTableText">
                                    {moment(val?.end_session).format(
                                      "hh:mm:ss A"
                                    )}
                                  </h4>
                                </div>
                                <div className="staffBoxData text-start">
                                  <h4 className="staffTableText">
                                    {differenceInHours || 0} hrs
                                  </h4>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <p className="no-salary-message text-center">{t('noSalary')}</p>
          )}
        </>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "accountPassModal"
            ? "accountPassModal"
            : modalDetail.flag === "createNewPassModal"
              ? "createNewPassModal"
              : modalDetail.flag === "storeUpdatePassword"
                ? "storeUpdatePassword"
                : modalDetail.flag === "invoiceModal"
                  ? "invoiceModal"
                  : modalDetail.flag === "editProfileModal"
                  ? "editProfileModal"
                  : ""
        }
        child={
          modalDetail.flag === "accountPassModal" ? (
            <AccountPassModal
              close={() => handleOnCloseModal()}
              open={() => handleOpenModal("createNewPassModal")
              }
              setCurrentPassword={(password) => setcurrentPassword(password)}
            />
          ) : modalDetail.flag === "createNewPassModal" ? (
            <CreateNewPassModal flag="createNewPassModal"
            getUserPosDetails={props?.getUserPosDetails}
            
            close={() => handleOnCloseModal()}
              old_password={currentPassword}
            />
          ) : modalDetail.flag === "storeUpdatePassword" ? (
            <StoreUpdatePassword
            getUserPosDetails={props?.getUserPosDetails}
            
            close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "invoiceModal" ? (
            <InvoiceModal close={() => handleOnCloseModal()} />
          ) 
          : modalDetail.flag === "editProfileModal" ? (
            <EditProfileModal
            userDetails={userDetails}
            getUserPosDetails={props?.getUserPosDetails}
            
            close={() => handleOnCloseModal()} />
          ) :(
            <></>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>


  )
}
export default PersonalDetail