import React, { useEffect, useRef, useState } from "react";
import {
  OrderDown,
  cancleorder,
  incoiceuser_,
  modalCancel,
  pickuporder,
  profileImg1,
  returnlogo,
  serviceuser_,
  unselectedOrder,
  drivefast_,
  upArrow,
  locateOrder,
  clockBlue,
  moneyImg,
  mapimg,
  deliveryMap,
  warningicon,
  fillradio_,
  verifyorder,
  orderDriver,
  done,
  cancelledstep,
  readystep,
  acceptstep,
  openTag,
  closeTag,
  webPosArrow,
  assignDriverLogo,
  readyToPickLogo,
  userImg_,
  deliver_icon,
} from "../../../../../utilities/images";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  changeStatusApiAsync,
  getOrderDetailByOrderIdAsync,
} from "../../../dashboard/dashboardSlice";
import { selectLoginAuth } from "../../../auth/authSlice";
import { useParams } from "react-router-dom";
import CustomModal from "../../../../components/shared/CustomModal";
import VerifyOrderModal from ".././VerifyOrderModal";
import PrintLabelModal from ".././PrintLabelModal";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import GoogleMapView from "../../../../components/UI/Orders/GoogleMapView";
import ".././orderReview.css";
import { getFormatedDate } from "../../../../../utilities/helpers";
import OrderTrackStatusCard from "../OrderTrackStatusCard";
import OrderInvoiceDetails from "../OrderInvoiceDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { amountFormat, returnModeOfPayment } from "../../../../../utilities/globalMethods";
import { VarintColorSize } from "../VariantColorSize";
import BillDetails from "../../BillDetails";
import OrderButton from "../../OrderButton";
import { handleOrderSatus } from "../../../../constants/orders/orders";
import { useTranslation } from "react-i18next";
const OrderReview = () => {
  const { t } = useTranslation();
  const orderOveriewRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory()
  const { id } = useParams();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showModalContent, setShowModalContent] = useState(true);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState("");
  const [hideOrderStatus, setHideOrderStatus] = useState(true);
  const [printingUrl, setPrintingUrl] = useState("");
  const [key, setKey] = useState(Math.random());
  const [isBackgroundVisible, setIsBackgroundVisible] = useState(true);
  const [showDriver, setShowDriver] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  // handle order overview background
  const handleOrderOverview = () => {
    // let background = ( !showMap ) ? `url('${deliveryMap}') no-repeat center` : '';
    // orderOveriewRef.current.style.background = background;
  };
  const handleOrderStatusView = () => {
    setHideOrderStatus((prev) => !prev);
  };
  const checkStatusOrder = {
    delivery: done,
    cancelleds: cancelledstep,
    ready: readystep,
    accept: acceptstep,
  };
  useEffect(() => {
    handleOrderOverview();
  }, [showMap]);
  const subTotal = parseFloat(orderData?.total_sale_price);
  const discount = parseFloat(orderData?.discount);
  const [map, setMap] = useState(/** @type google.maps.Map */(null));
  const [mapStatus, setMapStatus] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  let deliveryOption=orderData?.delivery_option;
  let deliveryStatus=orderData?.status;
  const pickupLatitude = parseFloat(
    (deliveryOption==1&&deliveryStatus>=4)?
    orderData?.order_delivery?.order_pickup_latitude:
    orderData?.coordinates?.[0]
  );
  const pickupLongitude = parseFloat(
    (deliveryOption==1&&deliveryStatus>=4)?
    orderData?.order_delivery?.order_pickup_longitude:
    orderData?.coordinates?.[1]
  );
  const deliverLatitude = parseFloat(
   (deliveryOption==1&&deliveryStatus>=4)? orderData?.order_delivery?.order_delivery_latitude:
   orderData?.seller_details?.seller_location?.[1]
  );
  const deliverLongitude = parseFloat(
    (deliveryOption==1&&deliveryStatus>=4)? orderData?.order_delivery?.order_delivery_longitude:
    orderData?.seller_details?.seller_location?.[0]
  );
  const center = { lat: pickupLatitude, lng: pickupLongitude };
  const origin = { lat: pickupLatitude, lng: pickupLongitude };
  const Destination = { lat: deliverLatitude, lng: deliverLongitude };

  const directionsOptions = {
    destination: Destination,
    origin: origin,
    travelMode: "DRIVING",
  };
  const directionsCallback = (response) => {
    if (response !== null && map !== null) {
      if (response.status === "OK" && mapStatus == false) {
        setMapStatus(true);
        setDirectionsResponse(response);
      } else {
        console.error("Directions request failed:", response);
        setMapStatus(true);
      }
    }
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const getOrderDataById = () => {
    if(!id||id=='null'){
      history.push("/allOrder")
      
    }
    let data = {
      id: id,
      token: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getOrderDetailByOrderIdAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setOrderData(obj?.payload);
        let orderDetails = obj?.payload;
        if (orderDetails) {
          let isDelivery = handleDeliveryOptionStatus(
            [1, 2, 3, 4],
            orderDetails?.delivery_option
          );
          let isStatus = handleOrderStatus([0, 1, 2, 3,7,8], orderDetails?.status);

          if (!(isDelivery && isStatus )) {
           if(orderDetails?.delivery_option!=4) setShowMap(true);
           else{
            setShowMap(false);
           }
          } else {
            setShowMap(false);
          }
        }
      })
      .catch((obj) => {
        setLoading(false);
        history.push("/allOrder")
      });
  };
  const changeStatusOfOrderHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };

    status === 8 ? setLoading1(true) : setLoading2(true);
    dispatch(changeStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((res) => {
        if( res?.data?.payload?.delivery_option != "3" &&
          res?.data?.payload?.status == 3){
            setShowDriver(true);
            setTimeout(() => {
              setShowDriver(false);
        getOrderDataById();
            }, 4000);
          }
       else getOrderDataById();
        setLoading1(false);
        setLoading2(false);
      })
      .catch((obj) => {
        setLoading1(false);
        setLoading2(false);
      
      });
  };


  useEffect(() => {
    getOrderDataById();
  }, []);

  //check order type
  const handleDeliveryOptionStatus = (option, orderType_) => {
    //check if array
    if (Array.isArray(option) && option.length > 0) {
      //check if delivery_option is in array
      return option.includes(Number(orderType_));
    }
    //if not array then check if delivery_option is greater than 0
    return option == orderType_;
  };
  //check order status
  const handleOrderStatus = (option, orderStatus_) => {
    //check if array
    if (Array.isArray(option) && option.length > 0) {
      //check if delivery_option is in array
      return option.includes(Number(orderStatus_));
    }
    //if not array then check if delivery_option is greater than 0
    return option == orderStatus_;
  };
  let userDetails=orderData?.user_details||orderData?.invitation_details;
    return (
      <div
        ref={orderOveriewRef}
        className="orderReview_ w-100 h-100 position-relative p-0"
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "rgb(245 246 252 / 80%)",
            top: "0",
            left: "0",
            ...(!(orderData?.delivery_option==1&&orderData?.status>=4)&&{
              zIndex: "1",
            })
          }}
        ></div>
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "relative",
          }}
        >, 
          {<GoogleMapView
         latitude={pickupLatitude ||0}
         longitude={pickupLongitude||0}
         destination={{
           lat: deliverLatitude||0,
           lng: deliverLongitude||0,
         }}
        />}
        </div>

        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "calc(100% - 40px)",
            top: "20px",
            left: "20px",
            ...(!(orderData?.delivery_option==1&&orderData?.status>=4)&&{
              zIndex: "2",
            })
          }}
        >
          {loading ? (
            <span className="spinner-border inner-spin spinner-border-sm"></span>
          ) : (
            <div className="row">
              <div
                className={`col-xxl-${!showMap ? "5" : "3"} col-xl-${!showMap ? "5" : "3"
                  }`}
              >
                {  (orderData?.status === 0 ||
    orderData?.status === 1 ||
    orderData?.status === 2 ||
    orderData?.status === 8 ||
    (orderData?.status === 7 && orderData?.is_delivery_dispute === false) ||
    orderData?.status === 3 ||(orderData?.delivery_option==4))? (
                  <div className="chartsOuter mt-0">
                    <div className="scheduleModal orderscheduleModal_">
                      <div className="orderremodal_">
                        <div className="orderReviewid_">
                          <div onClick={() => history.goBack()}>
                            <img className="backarrow_" src={upArrow} />
                          </div>
                          <div className="oredreidText_">
                            <h3 className="blueHeading_">
                              # {orderData?.invoices?.id}
                            </h3>
                            <h6 className="bluesmall_"> {handleOrderSatus(
                              orderData?.delivery_option,
                              orderData?.status
                            )}</h6>
                          </div>
                        </div>
                        <div className="scheduleModalContent">
                          <div className="invoiceUser_ row">
                            <div className="col-md-6">
                              <div className="reviewuserinfo_ gap-2">
                                <img
                                  className="userInnerImg"
                                  src={
                                    userDetails?.profile_photo
                                      ? userDetails?.profile_photo
                                      : userImg_
                                  }
                                />
                                <article className="ivoiceuserDetail_">
                                  <h4 className="fontSize12 fw500 businessUserName mb-1">
                                    {userDetails?.firstname ?? "__" +
                                      " " +
                                      userDetails?.lastname ?? "__"}
                                  </h4>
                                 {orderData?.address&& <div className="d-flex align-items-center gap-2">
                                    <h6 className="orederLoc_ fontSize14 fw500 mb-0 fw500 fontSize12">
                                      <img
                                        src={locateOrder}
                                        alt="img"
                                        className="location"
                                      />{" "}
                                      {orderData?.address}
                                    </h6>
                                  </div>}
                                </article>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="reviewuserinfo_">
                                <article className="ivoiceuserDetail_">
                                  <h4 className="hourWindow_">
                                  <img
                                          src={orderData?.delivery_option==4?orderData?.shipping_details?.image: clockBlue}
                                          alt="img"
                                          className="location"
                                          style={{maxWidth:"30px"}}
                                        />{" "}
                                    {orderData?.delivery_details?.title?orderData?.delivery_details?.title :orderData?.delivery_option==4?orderData?.shipping_details?.title:orderData?.delivery_option==1?"Delivery":"Customer pickup"}
                                  </h4>
                                  
                                </article>
                              </div>
                            </div>
                          </div>
                          {orderData?.order_details?.length > 0
                            ? orderData?.order_details?.map((val, i) => {
                              return (
                                <div key={i} className="serviceDeatils_">
                                  <div className="serviceinfo_">
                                    <figure className="proservice_ me-3">
                                      <img
                                        className="img-fluid"
                                        src={val?.product_image}
                                      />
                                    </figure>
                                    <article className="duration_">
                                      <h3 className="darkblack">
                                        {val?.product_name}
                                      </h3>
                                      <div className="colorsBox_">
                                        <h6 className="lightgreyHeading_">
                                          {VarintColorSize(val?.variant)}
                                        </h6>
                                      </div>
                                    </article>
                                  </div>
                                  <div className="servicechanges_">
                                    <span className="serciceprice_">
                                  
                                      {Number(val?.actual_price)?.toFixed(2)}
                                    </span>
                                    <span className="serciceprice_">
                                      {val?.qty}
                                    </span>
                                    <span className="serciceprice_">
                                     
                                      <span className="">{`$${(
                                        Number(val?.actual_price || 0) *
                                        Number(val?.qty || 0)
                                      )?.toFixed(2)}`}</span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                            : orderData?.return_detail?.return_details?.map(
                              (val, i) => {
                                return (
                                  <div key={i} className="serviceDeatils_">
                                    <div className="serviceinfo_">
                                      <img
                                        style={{ width: "50px" }}
                                        className="img-fluid me-3"
                                        src={val?.order_details?.product_image}
                                      />
                                      <article className="duration_">
                                        <h3 className="lightgreyHeading_">
                                          {val?.order_details?.product_name}
                                        </h3>
                                        {/* <p className='designation_'>White / s</p> */}
                                      </article>
                                    </div>
                                    <div className="servicechanges_">
                                      <span className="serciceprice_">
                                        $
                                        {Number(
                                          val?.order_details?.price
                                        )?.toFixed(2)}
                                      </span>
                                      <span className="serciceprice_">
                                        {val?.order_details?.qty}
                                      </span>
                                      <span className="serciceprice_">
                                        $
                                        {Number(
                                          val?.order_details?.qty *
                                          val?.order_details?.price
                                        )?.toFixed(2)}
                                      </span>
                                      {/* <span className='serciceprice_'> <i className="fa-regular fa-rectangle-xmark"></i></span> */}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                      <>
                        <div className="grandTotal_ row">
                          {orderData?.order_delivery &&
                            orderData?.delivery_option == "1" &&
                            orderData?.order_delivery?.cancel_order_otp &&
                            !orderData?.order_delivery?.has_returned ? (
                            <div className="col-md-5 text-start">
                              <div className="orderinvoive">
                                <h4 className="monshead22">{t('return')}</h4>
                                <h4 className="monshead22">
                                  {orderData?.order_delivery?.updated_at
                                    ? moment(
                                      orderData?.order_delivery?.updated_at
                                    ).format("DD MMM YYYY | HH:mm A")
                                    : ""}
                                </h4>
                                <h5 className="lightgreyHeading_">
                                  {t('cancelOrderOtp')}
                                </h5>
                                <h4 className="monshead22">
                                  {orderData?.order_delivery?.cancel_order_otp
                                    ? orderData?.order_delivery?.cancel_order_otp
                                    : "1234"}
                                </h4>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-4 text-start">
                              <div className="orderinvoive">
                                <h5 className="lightgreyHeading_">{t('totalItem')}</h5>
                                <h4 className="monshead22">
                                  {orderData?.total_items}
                                </h4>
                                <h5 className="lightgreyHeading_">{t('orderDate')}</h5>
                                <h4 className="monshead22">
                                  {moment(orderData?.created_at).format(
                                    "MM/DD/YYYY"
                                  )}
                                </h4>
                                <h5 className="lightgreyHeading_">{t('orderId')} #</h5>
                                <h4 className="monshead22">
                                  {orderData?.invoices?.order_id}
                                </h4>
                                <h5 className="lightgreyHeading_">
                                  {t('payMethod')}
                                </h5>
                                <h4 className="monshead22">
                                  <div className="deliverTableBx">
                                    <img
                                      src={moneyImg}
                                      alt="location Image"
                                      className="img-fluid ms-1"
                                    />
                                    <span className="locateDistance">
                                      {
                                        // orderData?.mode_of_payment
                                        returnModeOfPayment(
                                          orderData?.mode_of_payment,
                                          orderData?.paid_by_reward,
                                          orderData?.partially_paid_by_reward
                                                                )
                                      }
                                    </span>
                                  </div>
                                </h4>
                              </div>
                            </div>
                          )}

                          <div className="col-md-8 text-start">
                            <div className="orderBill_">
                              <div className="Customertotal">
                               <BillDetails
                               orderDetails={orderData}
                               />
                                <OrderButton
                                 selected={orderData?.status}
                                 orderData={orderData}
                                 isLoading={loading2}
                                 declineLoading={loading1}
                                 setPrintingUrl={setPrintingUrl}
                                 setKey={setKey}
                                 setModalDetail={setModalDetail}
                                 changeStatusOfOrderHandle={changeStatusOfOrderHandle}
                                />

                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                ) : (
                  <OrderInvoiceDetails
                    status={handleOrderSatus(orderData?.delivery_option, orderData?.status)}
                    orderDetails={orderData} />
                )}
              </div>

              <div
                className={`col-xxl-${!showMap ? "7" : "9"} col-xl-${!showMap ? "7" : "9"
                  }`}
              >
                { (orderData?.status === 0 ||
    orderData?.status === 1 ||
    orderData?.status === 2 ||
    orderData?.status === 8 ||
    (orderData?.status === 7 && orderData?.is_delivery_dispute === false) ||
    orderData?.status === 3 || orderData?.delivery_option==4)?(orderData?.status==3&&orderData?.delivery_option==1)? <div className="loader-container">

   {showDriver&& <>
    <div className="spinner">
      <svg className="circle" viewBox="0 0 50 50">
        <circle
          className="track"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="4"
        ></circle>
        <circle
          className="progress"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="4"
        ></circle>
      </svg>

      <div className="icon">
        <img src={drivefast_} alt="Driver Icon" />
      </div>
    </div>
    <p className="loader-text mt-2">
      Looking for <br />
      driver
    </p>
   </>}
  </div>: (
                  <div className="MapOrders_inner">
                    <div className="nodataDisplay_">
                      <p>
                        <img className="warningicon me-1" src={warningicon} /> No
                        data Displayed
                      </p>
                    </div>
                  </div>
                ) : (
                  <div 
                  // consumerMap
                  className="
                   mt-0">
                  
                      <>
                        <div 
                        // className="MapOrders_inner"
                        >
                          {/* <GoogleMapView data={orderData?.delivery_option != 3 ? orderData?.order_delivery : {}} /> */}
                          
                            {/* // orderData?.delivery_option != 3 &&  */}
                            <OrderTrackStatusCard orderDetails={orderData} />
                          {/* //  */}
                        </div>
                      </>
                    
                  </div>
                )}
              </div>
            </div>
          )}
          <CustomModal
            key={key}
            show={modalDetail.show}
            // backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            ids={
              modalDetail.flag === "verifyOtp"
                ? "VerifyOtp"
                : modalDetail.flag === "printLabel"
                  ? "PrintLabel"
                  : ""
            }
            size={
              modalDetail.flag === "verifyOtp" ||
                modalDetail.flag === "printLabel"
                ? "md"
                : "xl"
            }
            child={
              modalDetail.flag === "printLabel" ? (
                <PrintLabelModal
                  handleChangeStatus={(id, status) =>
                    changeStatusOfOrderHandle(id, status)
                  }
                  orderData={orderData}
                  printingUrl={printingUrl}
                  close={() => handleOnCloseModal()}
                />
              ) : modalDetail.flag === "verifyOtp" ? (
                <VerifyOrderModal
                  handleChangeStatus={(id, status) =>
                    changeStatusOfOrderHandle(id, status)
                  }
                  getOrderDataById={getOrderDataById}
                  orderData={orderData}
                  close={() => handleOnCloseModal()}
                />
              ) : (
                ""
              )
            }
            header={
              modalDetail.flag === "verifyOtp" ? (
                <>
                  <div className="headerLeft">
                    <h4 className="modalHeading_ me-3">Verify Otp</h4>
                  </div>

                  <p
                    style={{ cursor: "pointer" }}
                    onClick={handleOnCloseModal}
                    className="modal_cancel"
                  >
                    <img
                      src={modalCancel}
                      className="ModalCancel"
                      alt="modalcancelImg"
                    />
                  </p>
                </>
              ) : modalDetail.flag === "printLabel" ? (
                <>
                  <div className="headerLeft">
                    <h4 className="modalHeading_ me-3">Print Label</h4>
                  </div>

                  <p
                    style={{ cursor: "pointer" }}
                    onClick={handleOnCloseModal}
                    className="modal_cancel"
                  >
                    <img
                      src={modalCancel}
                      className="ModalCancel"
                      alt="modalcancelImg"
                    />
                  </p>
                </>
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </div>
      </div>
    );
};

export default OrderReview;
