import React from 'react'
import * as Images from "../../../../utilities/images";

const DeviceDetail = () => {
    return (
        <div className='deviceDetailmain_'>
            <div className='devicedetalHead_ d-flex align-items-center justify-content-between'>
                <div className='deviceheadLeft_ d-flex align-items-center'>
                    <img
                        className="iphonemob_ me-3"
                        src={Images.leftarrow_}
                    /> <h4 className='headblue18 mb-0 me-3'>Mobile POS iPhone 14 </h4>
                    <div className="battryBox_">
                        <img
                            className="batteryfull_"
                            src={Images.batteryfull}
                        />{" "}
                        <span className="greenText_">95%</span>
                    </div>
                </div>
                <div className='deviceheadRight_'>
                    <button className='redborder_btn' type='submit'>Forgot Device</button>
                </div>
            </div>
            <div className='deviceInfo_ languageMain_ '>
                <div className="bussinessSub mt-4 ms-4">
                    <div className="locationHead">
                        <img
                            src={Images.Refreshicon}
                            alt="staffLocate image"
                            className="img-fluid"
                        />
                        <div className="bussinessHeading">
                            <h4 className="expectedHeading">
                                Software Updates
                            </h4>
                            <p className="countNumber_">Last Updated 9/23/2023</p>
                        </div>
                    </div>
                </div>
                <div className="bussinessSub mt-3 ms-4">
                    <div className="locationHead">
                        <img
                            src={Images.locationLogo}
                            alt="staffLocate image"
                            className="img-fluid"
                        />
                        <div className="bussinessHeading">
                            <h4 className="expectedHeading">
                                Location
                            </h4>
                            <p className="countNumber_">Daffodil Lane, Savage, Virginia(VA), 20763</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='deviceinternalImfo_ ms-4 mt-3'>
                <p className='devicedetail_inner pt-0'><span className='headingblue14_'>Device Model</span> <span className='headingblue14_'>iPad</span></p>
                <p className='devicedetail_inner'><span className='headingblue14_'>IMEI Number</span> <span className='headingblue14_'>543750t83</span></p>
                <p className='devicedetail_inner'><span className='headingblue14_'>Serial Number</span> <span className='headingblue14_'>543750t83</span></p>
                <p className='devicedetail_inner '><span className='headingblue14_'>Model Number</span> <span className='headingblue14_'>543750t83</span></p>
            </div>
            <div className='deviceinternalImfo_ ms-4 mt-3'>
                <p className='devicedetail_inner pt-0'><span className='headingblue14_'>Capacity</span> <span className='headingblue14_'>128 GB</span></p>
                <p className='devicedetail_inner'><span className='headingblue14_'>Available Storage</span> <span className='headingblue14_'>35 GB</span></p>
            </div>
            <div className='deviceinternalImfo_ ms-4 mt-3'>
                <p className='devicedetail_inner pt-0'><span className='headingblue14_'>WiFi Address</span> <span className='headingblue14_'>1c:23:34:67:N1</span></p>
                <p className='devicedetail_inner'><span className='headingblue14_'>Available Storage</span> <span className='headingblue14_'>1c:23:34:67:N1</span></p>
                <p className='devicedetail_inner'><span className='headingblue14_'>Network</span> <span className='headingblue14_'>Vodafone</span></p>
                <p className='devicedetail_inner '><span className='headingblue14_'>Network Password</span> <span className='headingblue14_'>*********</span></p>
            </div>
            <div className='languageMain_'>
                <div className="bussinessSub  mt-4 ms-4">
                    <div className="locationHead">
                        <img
                            src={Images.devices_icon}
                            alt="staffLocate image"
                            className="img-fluid"
                        />
                        <div className="bussinessHeading">
                            <h4 className="expectedHeading">
                                Software Updates
                            </h4>
                            <p className="countNumber_">Last Updated 9/23/2023</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceDetail