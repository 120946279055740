import { unwrapResult } from "@reduxjs/toolkit";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bussinesImg,
  pin_light,
  calendarsh2,
  shieldIImg,
  uploadImg,
  fileUploadImg,
  pdfImage,
  cameraImg,
  businesslocation,
} from "../../../utilities/images";
import PhoneInput from "react-phone-input-2";
import {
  getUserByIdApiAsync,
  selectLoginAuth,
  updateMerchantApiAsync,
} from "../auth/authSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import moment from "moment-timezone";
import { imageUploadAsync } from "../message/messageSlice";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { UserStatusAsync } from "./systemConfigSlice";
import Swal from "sweetalert2";

var fileUpload = true;
const BussinesseditDeatils = () => {
  const toastId = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [ssn, setSsn] = useState("");
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [profileData, setProfileData] = useState("");
  const [businessLogo, setBusinessLogo] = useState("");
  const [logoUploadLoading, setLogoUploadLoading] = useState(false);
  const optionsOne = [
    { id: "product", value: "product", label: "Product" },
    { id: "service", value: "service", label: "Services" },
  ];
  const [organization_name, setOrganization_name] = useState();
  const [business_type, setBusiness_type] = useState([]);
  const [email, setEmail] = useState();
  const [Website, setWebsite] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [shopFactoryImages, setShopFactoryImages] = useState([]);
  const [achievementsCertification, setAchievementsCertification] = useState(
    []
  );
  const [inspectionReports, setInspectionReports] = useState([]);
  const [allowEdit, setAllowEdit] = useState(false);
  const [phoneCode, SetPhoneCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const handleSelect = (e) => {
    let allIds = e?.map((v) => {
      return v?.id;
    });
    setBusiness_type(allIds);
  };

  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  };

  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };

  const handleEdit = () => {
    setAllowEdit(false);

    if (Website) {
      var urlPattern = new RegExp(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      );
      var resultUrl = urlPattern.test(
        Website?.split(":")[0] === "https" ? Website : `https://${Website}`
      );
    }
    if (resultUrl === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid webiste url");
      }
      return;
    }

    let data = {
      postData: {
        id: auth?.payload?.user?.user_profiles?.user_id
          ? auth?.payload?.user?.user_profiles?.user_id
          : auth?.payload?.user_profiles?.user_id,
      },
      tokenData: auth?.payload?.token,
    };
    if (businessLogo) {
      data = {
        postData: {
          ...data?.postData,
          business_logo: businessLogo,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (organization_name) {
      data = {
        postData: {
          ...data?.postData,
          organization_name: organization_name,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (business_type) {
      data = {
        postData: {
          ...data?.postData,
          business_type: business_type,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (Website) {
      data = {
        postData: {
          ...data?.postData,
          business_website: Website,
        },
        tokenData: auth?.payload?.token,
      };
    }

    if (shopFactoryImages) {
      data = {
        postData: {
          ...data?.postData,
          manufacturer_images: shopFactoryImages,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (achievementsCertification) {
      data = {
        postData: {
          ...data?.postData,
          certification: achievementsCertification,
        },
        tokenData: auth?.payload?.token,
      };
    }

    if (inspectionReports) {
      data = {
        postData: {
          ...data?.postData,
          business_inspection_report: inspectionReports,
        },
        tokenData: auth?.payload?.token,
      };
    }

    setUpdateLoading(true);
    dispatch(updateMerchantApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setUpdateLoading(false);
      })
      .catch((obj) => {
        setUpdateLoading(false);
      });
  };

  const getBussinessDetails = () => {
    let data = {
      postData: {
        id: auth?.payload?.user?.user_profiles?.user_id
          ? auth?.payload?.user?.user_profiles?.user_id
          : auth?.payload?.user_profiles?.user_id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserByIdApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setUserData(obj?.payload);
        setBusinessLogo(obj?.payload?.user_profiles?.business_logo);
        setOrganization_name(obj?.payload?.user_profiles?.organization_name);
        setSsn(obj?.payload?.user_profiles?.ssn_number);
        setWebsite(obj?.payload?.user_profiles?.business_website);
        setPhoneNumber(
          obj?.payload?.user_profiles?.phone_no ?? "Not Avialable"
        );
        SetPhoneCode(obj?.payload?.user_profiles?.phone_code);
        setShopFactoryImages(obj?.payload?.user_profiles?.manufacturer_images ? obj?.payload?.user_profiles?.manufacturer_images : []);
        setAchievementsCertification(
          obj?.payload?.user_profiles?.certification ? obj?.payload?.user_profiles?.certification :[]
        );
        setInspectionReports(
          obj?.payload?.user_profiles?.business_inspection_report ? obj?.payload?.user_profiles?.business_inspection_report : []
        );
        setBusiness_type(obj?.payload?.user_profiles?.business_type);
        setSelectedOptions(
          obj?.payload?.user_profiles?.business_type
            ? () => {
              return obj?.payload?.user_profiles?.business_type?.map(
                (item) => {
                  return { id: item, value: item, label: item };
                }
              );
            }
            : void 0
        );
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleConfirmChangeStatus = () => {
    Swal.fire({
      title: profileData?.is_active === true ? "Are you sure you want to Deactivate Business?" : "Are you sure you want to Activate Business?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let data = {
          id: auth?.payload?.id,
          is_active: profileData?.is_active === true ? false : true,
        };
        setLoading(true);
        dispatch(UserStatusAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false);
            getProfileData();
            getBussinessDetails();
          })
          .catch((obj) => {
            setLoading(false);
          });
      }
    });
  };




  let imageFile;
  const handleImage = (e, flag) => {
    fileUpload = true;
    let formData = new FormData();
    const files = e.target.files;

    if (!files || files.length === 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select Image");
      }
      return;
    }
    if (flag == "businessLogo") {
      imageFile = e.target.files[0];
      formData.append("file", imageFile);
    } else {
      console.log(Array.from(files)?.length, "image length ");
      if (
        Array.from(files)?.length + inspectionReports?.length > 10 &&
        flag == "business_inspection_report"
      ) {  
        fileUpload = false;
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Max upload image limit is 10");
        }
        return;
      }
      if (
        Array.from(files)?.length + shopFactoryImages?.length > 10 &&
        flag == "manufacturer_images"
      ) {
        fileUpload = false;
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Max upload image limit is 10");
        }
        return;
      }
      if (
        Array.from(files)?.length + achievementsCertification?.length > 10 &&
        flag == "certification"
      ) {
        fileUpload = false;
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Max upload image limit is 10");
        }
        return;
      }
      Array.from(files).forEach((file, index) => {
        formData.append("file", file);
      });
    }

    let params = {
      file: formData,
      token: auth?.payload?.token,
    };

    console.log(
      fileUpload,
      Array.from(files)?.length + shopFactoryImages?.length > 10,
      Array.from(files)?.length + inspectionReports?.length > 10,
      Array.from(files)?.length + achievementsCertification?.length > 10,
      Array.from(files)?.length + businessLogo?.length > 10 ||
      Array.from(files)?.length + shopFactoryImages?.length > 10 ||
      Array.from(files)?.length + achievementsCertification?.length > 10,
      "file upload"
    );

    if (fileUpload) {
      setLogoUploadLoading(true);
      dispatch(imageUploadAsync(params))
        .then(unwrapResult)
        .then(async (obj) => {
          if (flag == "businessLogo") {
            setBusinessLogo(obj?.payload[0]?.filePath);
          }
          if (flag == "manufacturer_images") {
            setShopFactoryImages([
              ...shopFactoryImages,
              ...obj?.payload?.map((image) => image.filePath),
            ]);
          }
          if (flag == "certification") {
            setAchievementsCertification([
              ...achievementsCertification,
              ...obj?.payload?.map((image) => image.filePath),
            ]);
          }
          if (flag == "business_inspection_report") {
            setInspectionReports([
              ...inspectionReports,
              ...obj?.payload?.map((image) => image.filePath),
            ]);
          }

          setLogoUploadLoading(false);
        })
        .catch((obj) => {
          setLogoUploadLoading(false);
        });
    }
  };



  const deleteImage = (item, flag) => {
    if (allowEdit === false) {
      return;
    }
    if (flag == "manufacturer_images") {
      const filterImage = shopFactoryImages.filter((image) => image != item);
      setShopFactoryImages(filterImage);
    }
    if (flag == "certification") {
      const filterImage = achievementsCertification.filter(
        (image) => image != item
      );
      setAchievementsCertification(filterImage);
    }
    if (flag == "business_inspection_report") {
      const filterImage = inspectionReports.filter((image) => image != item);
      setInspectionReports(filterImage);
    }
  };

  console.log(auth?.payload?.user?.user_profiles?.user_id
    ? auth?.payload?.user?.user_profiles?.user_id
    : auth?.payload?.user_profiles?.user_id,"autjh daaaaaaaaaaaaa");
  const getProfileData = () => {
    let data = {
      postData: {
        id: auth?.payload?.user?.user_profiles?.user_id
          ? auth?.payload?.user?.user_profiles?.user_id
          : auth?.payload?.user_profiles?.user_id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserByIdApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setProfileData(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    getBussinessDetails();
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "System Config";
  }, [])

  console.log(shopFactoryImages, "saaaaaaaaaaaaaaaaaaaaaaaaaa");

  return (
    <>
      <DashboardNavbar title="Business Details" backShow={false} />
      {loading == true ? (
        <Spinner variant="primary" animation="border" />
      ) : (
        <>
          <div className="mainCatalog bussinesHead mb-3">
            <div className="container-fluid">
              <div className="row p-o">
                <div className="col-lg-12">
                  <div className="businessDeatils">
                    <div className="bussinessBg mt-2"></div>
                    <div className="userBussinesDetails">
                      <div className="userBussines">
                        {/* <figure className=''>
                                            <img src={bussinesImg} alt='mapl' className='mapleImg me-2' />
                                        </figure> */}

                        {logoUploadLoading || loading === true ? (
                          <Spinner variant="primary" animation="border" />
                        ) : (
                          <>
                            <div className="bussinesFile_">
                              <input
                                type="file"
                                disabled={!allowEdit}
                                id="file"
                                className="form-control inputfile d-none"
                                name="images[]"
                                data-id="1"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) => {
                                  handleImage(e, "businessLogo");
                                }}
                              />
                              <label htmlFor="file">
                                {/* <span id="file-name" className="file-box d-none">Choose File</span> */}
                                <div className="file-button  fileBussiness d-flex ">
                                  <img
                                    src={
                                      businessLogo ? businessLogo : businesslocation
                                    }
                                    // bussinesImg
                                    width="25"
                                    id="profileImagePreview1"
                                    className="beforeUpload bussinesUpload  me-3"
                                    alt=""
                                  />
                                </div>
                              </label>
                              {allowEdit == true ? (
                                <img
                                  className="cameraImage"
                                  src={cameraImg}
                                  alt="camera"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}

                        <div className="bussinesEdit">
                          <div className="bussienes_ mt-2">
                            <h4 className="subheading_ fw-500 mt-3">
                              {organization_name ?? "Not Avialable"}
                            </h4>
                            <span className="itemsTxt mt-2 me-4">
                              <img
                                src={shieldIImg}
                                alt="shieldImages"
                                className="me-2"
                              />
                              {ssn ? `*****${ssn?.slice(ssn?.length - 4, ssn?.length)}` : ""}
                            </span>
                            {userData?.user_profiles?.current_address?.format_address &&
                              <span className="itemsTxt mt-2 me-4">
                                <img
                                  src={pin_light}
                                  alt="shieldImages"
                                  className="me-2"
                                />
                                {
                                  userData?.user_profiles?.current_address
                                    ?.format_address
                                }
                              </span>}
                            <span className="itemsTxt mt-2 me-4">
                              <img
                                src={calendarsh2}
                                alt="shieldImages"
                                className="me-2"
                              />
                              Registered:{" "}
                              {moment(
                                userData?.user_profiles?.created_at
                              ).format("YYYY-MM-DD")}
                            </span>
                          </div>
                          <div className="bussineslist mt-2">
                            {allowEdit ? (
                              updateLoading === true ? (
                                <Spinner variant="primary" animation="border" />
                              ) : (
                                <button
                                  className="saveBtn  mt-3"
                                  type="button"
                                  onClick={handleEdit}
                                >
                                  Save
                                </button>
                              )
                            ) : (
                              <button
                                className="saveBtn  mt-3"
                                type="button"
                                onClick={() => {
                                  setAllowEdit(true);
                                }}
                              >
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mainCatalog bussinessHead mb-3">
            <div className="shopDetails  mb-2">
              <h4 className="subheading_ fw-500 ">Business Details</h4>
            </div>
            <div className="productViewRight  w-60">
              <div className="roleSub   mt-2 mb-2">
                <h4 className="retailText m-0">Legal Business Name</h4>
              </div>
              <input
                type="text"
                class="personalControl inputBoxBussines "
                disabled={!allowEdit}
                value={organization_name ?? "Not Avialable"}
                onChange={(e) => {
                  setOrganization_name(e.target.value);
                }}
              // placeholder="Mashirian D Lippin"
              />
            </div>
            <div className="productViewRight  mb-2 w-60">
              <div className="roleSub ">
                <h4 className="retailText m-0">Business Type</h4>
              </div>
              <Multiselect
                className="signupSelect customform-control"
                displayValue="value"
                options={optionsOne}
                hidePlaceholder={false}
                selectedValues={selectedOptions}
                // placeholder="Business Type"
                disable={!allowEdit}
                onSelect={handleSelect}
                onRemove={handleSelect}
              // showCheckbox
              />
            </div>
            <div className="productViewRight mb-2  w-60">
              <div className="roleSub ">
                <h4 className="retailText m-0">Email Address</h4>
              </div>
              <input
                type="text"
                class="personalControl"
                // placeholder="Admin@mdl.com"
                value={userData.email}
                onChange={(e) => {
                  setUserData((prevData) => ({
                    ...prevData,
                    email: e.target.value,
                  }));
                }}
                disabled
              />
            </div>
            <div className="productViewRight  mb-2 w-60">
              <div className="roleSub ">
                <h4 className="retailText m-0">Website</h4>
              </div>
              <input
                type="text"
                class="personalControl "
                disabled={!allowEdit}
                value={Website ?? "Not Avialable"}
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
              />
            </div>
            <div className="productViewRight  mb-2 w-60">
              <div className="roleSub ">
                <h4 className="retailText m-0">Phone Number</h4>
              </div>
              <div id="result" className="PhoneBox countryCodePickerParent">
                <PhoneInput
                  disabled
                  country="us"
                  value={phoneCode + phoneNumber}
                  // enableSearch={true}
                  onlyCountries={["us", "in"]}
                  name={generateRandomName}
                  // value={phoneNumber}
                  placeholder="Phone no."
                  autoComplete="off"
                  onChange={(value, data, event, formattedValue) =>
                    onChangePhoneNumber(value, data, event, formattedValue)
                  }
                />
              </div>
              {/* <input
                disabled
                value={phoneNumber}
                type="Number"
                class="personalControl inputBoxBussines"
                max="999999999"
                // placeholder="+1 (319) 424-4476"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              /> */}
            </div>
          </div>

          <div className="mainCatalog bussinesHead mb-3">
            <div className="shopDetails">
              <h4 className="subheading_ fw-500 ">
                Shop/Factory Images <span className="itemsTxt">(Max 10)</span>
              </h4>
              <div className="">
                <input
                  // onChange={(e) => {
                  //   handleImage(e, "manufacturer_images");
                  // }}
                  onChange={(e) => {
                    handleImage(e, "manufacturer_images");
                  }}
                  type="file"
                  disabled={!allowEdit}
                  id="manufacturer_images"
                  className="form-control inputfile d-none"
                  name="images[]"
                  data-id="1"
                  accept="image/png, image/jpeg"
                  multiple
                />
                <label htmlFor="manufacturer_images">
                  {/* <span id="file-name" className="file-box d-none">Choose File</span> */}
                  <div className="file-button d-flex ">
                    <img
                      src={uploadImg}
                      width="25"
                      id="profileImagePreview1"
                      className="beforeUpload"
                      alt=""
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="equipMain">
              <div className="equipmentImages_ me-3 d-flex">
                {shopFactoryImages?.length > 0 ? (
                  shopFactoryImages?.map((item) => (
                    <div className="Imagesequip_">
                      <a
                        className="equipmentimage_  me-3"
                        style={{ width: "100px" }}
                        href={item}
                        target="_blank"
                        key={Math.random()}
                      >
                        <img
                          className="equipmentimage_  me-3"
                          style={{ width: "150px" }}
                          src={item}
                          key={Math.random()}
                          alt=""
                        />
                      </a>

                      <div
                        className="removeImage"
                        onClick={() => {
                          deleteImage(item, "manufacturer_images");
                        }}
                      >
                        <i class="fas fa-minus"></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="mainCatalog bussinesHead mb-3">
            <div className="shopDetails">
              <h4 className="subheading_ fw-500 ">
                Achievements /Certification
              </h4>
              <div className="">
                <input
                  type="file"
                  onChange={(e) => {
                    handleImage(e, "certification");
                  }}
                  disabled={!allowEdit}
                  id="certification"
                  className="form-control inputfile d-none"
                  name="images[]"
                  data-id="1"
                  accept="image/png, image/jpeg"
                  multiple
                />
                <label htmlFor="certification">
                  {/* <span id="file-name" className="file-box d-none">Choose File</span> */}
                  <div className="file-button d-flex ">
                    <img
                      src={fileUploadImg}
                      width="25"
                      id="profileImagePreview1"
                      className="beforeUpload"
                      alt=""
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="equipMain">
              <div className="equipmentImages_ me-3 d-flex">
                {achievementsCertification?.length > 0 ? (
                  achievementsCertification?.map((item) => (
                    <div className="Imagesequip_">
                      <a
                        className="equipmentimage_  me-3"
                        style={{ width: "100px" }}
                        href={item}
                        target="_blank"
                        key={Math.random()}
                      >
                        <img
                          className="equipmentimage_  me-3"
                          style={{ width: "100px" }}
                          src={item}
                          key={Math.random()}
                          alt=""
                        />
                      </a>

                      <div className="removeImage">
                        <i
                          class="fas fa-minus"
                          onClick={() => {
                            deleteImage(item, "certification");
                          }}
                        ></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="mainCatalog bussinesHead mb-3">
            <div className="shopDetails">
              <h4 className="subheading_  fw-500">Inspection reports</h4>
              <div className="">
                <input
                  type="file"
                  disabled={!allowEdit}
                  onChange={(e) => {
                    handleImage(e, "business_inspection_report");
                  }}
                  id="business_inspection_report"
                  className="form-control inputfile d-none"
                  name="images[]"
                  data-id="1"
                  accept=".pdf"
                  multiple
                />
                <label htmlFor="business_inspection_report">
                  {/* <span id="file-name" className="file-box d-none">Choose File</span> */}
                  <div className="file-button d-flex ">
                    <img
                      src={fileUploadImg}
                      width="25"
                      id="profileImagePreview1"
                      className="beforeUpload"
                      alt=""
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="equipMain">
              <div className="equipmentImages_ me-3 d-flex">
                {inspectionReports?.length > 0 ? (
                  inspectionReports?.map((item) => (
                    <div className="Imagesequip_">
                      <a
                        className="equipmentimage_  me-3"
                        style={{ width: "100px" }}
                        href={item}
                        target="_blank"
                        key={Math.random()}
                      >
                        <img className="equipmentimage_  me-3" src={pdfImage} />
                      </a>
                      <div className="removeImage">
                        <i
                          class="fas fa-minus"
                          onClick={() => {
                            deleteImage(item, "business_inspection_report");
                          }}
                        ></i>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mainCatalog bussinesHead mb-3">
        {profileData?.is_active === true ? (
          <div className="deactiveBussines">
            <h4 className="subheading_ fw-500 ">Deactivate your business</h4>
            <p className="repoetPara mt-3">
              Deactivating your business means that you will be unable to
              receive or recover any of your payment history or account
              information.
            </p>

            <button
              className="DeactivateBtn mt-3"
              type="button"
              onClick={() => handleConfirmChangeStatus()}
            >
              Deactivate Your Business
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}{" "}
            </button>
          </div>
        ) : (
          <div className="deactiveBussines">
            <h4 className="subheading_ fw-500 ">Activate your business</h4>
            {/* <p className="repoetPara mt-3">
              Activate your business means that you will be unable to
              receive or recover any of your payment history or account
              information.
            </p> */}

            <button
              className="saveBtn  mt-3"
              type="button"
              onClick={() => handleConfirmChangeStatus()}
            >
              Activate Your Business
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}{" "}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default BussinesseditDeatils;
