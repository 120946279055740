import React, { useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
// import ProductSidebar from "../../components/shared/productSidebar";
import ProductSidebar from "../../components/shared/productSidebar"
import SuccessfullyAddProductModal from "./SuccessfullyAddProductModal";
import {
  addProductsToInventoryAsync,
  getAddedToInventoryProductsAsync,
} from "../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { addProductsToInventory } from "../dashboard/dashboardApi";
import { useTranslation } from "react-i18next";

const AllProduct = () => {
  const { t } = useTranslation  ();
  const toastId = useRef(null);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // const[addProduct , setAddProduct]= useState(location?.state?.allProducts ||[])
  const addProduct = location?.state?.allProducts ||[];
  const [productData, setProductData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);


  

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });

    setKey(Math.random());
  };
  // End Modal

  const handleCheckboxChange = (index) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  
  const getSubCategoryId = (subCategoryId) => {
    const productIds = addProduct.map((product) => product.id);
    setSubCategoryIds(subCategoryId);
    let productData = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "physical",
        product_ids: productIds.join(","),
        sub_category_ids: subCategoryId ? subCategoryId.toString() : "",
        category_ids: selectedCategory ? selectedCategory.toString() : "",
      },
    };
    setLoading(true);
    dispatch(getAddedToInventoryProductsAsync(productData))
      .then(unwrapResult)
      .then((obj) => {
        setProductData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        // setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleProductsData = async () => {
    const productIds = addProduct.map((product) => product.id);
    let productData = {};
    productData = {
      tokenData: auth?.payload?.token,
      postData: {
        product_ids: productIds.join(","),
        type: "physical",
        page: currentPage,
        limit: perPage,
      },
    };
    setLoading(true);
    await dispatch(getAddedToInventoryProductsAsync(productData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
      
        setProductData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        // setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalRows / perPage)) {
      setCurrentPage(page);
    }
  };

  const handleAddProductssToShop = () => {
    if (selectedProducts.length === 0) {
      showToast("please select atleast one Product");
      return;
    }
    // Extract selected product IDs
    const selectedProductIds = selectedProducts.map(
      (index) => productData[index]?.id
    );

    // Filter `addProduct` to include only selected products
    const filteredProducts = addProduct.filter((product) =>
      selectedProductIds.includes(product.id)
    );

    let data = {
      postData: {
        products: filteredProducts,
      },
      tokenData: auth?.payload?.token,
    };
    setButtonLoading(true);
    dispatch(addProductsToInventoryAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setButtonLoading(false);
        handleOpenModal("successfullyAddProduct");
        setTimeout(() => {
          handleOnCloseModal(); // Ensure modal closes after 5 seconds
          history.push(`/productList`);
        }, 2000);
      })
      .catch((obj) => {
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    handleProductsData();
  }, [perPage, currentPage]);

  useEffect(() => {
    getSubCategoryId();
  }, [selectedCategory]);

  return (
    <>
      <div className="allProductHeader">
        <button
          className="headerBackBtn d-flex"
          onClick={() => {
            history.push({
              pathname: `/products`,
              state: { products: addProduct }, // Pass product details in state
            });
          }}
        >
          <img
            src={Images.blueBackArrow}
            alt="lightLeftArrowLogo"
            className="backbtnArrow me-1"
          />
          {t("back")}
        </button>
      </div>
      <ProductSidebar
        subCategoryId={(e) => getSubCategoryId(e)}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory} 
        // brandId={setBrandIds}
      />

      <div className="productRightWrapperParent">
        <div className="rightWrapper productRightWrapper  ProductsBox_ mt-0">
          <div className="content_areas mt-0">
            <div className="container-fluid p-0">
              {/* <ProductNavbar /> */}
              <div className="myProductHeader">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h5 className="fontSize14 fw500 txtDarkblue mb-0">
                      {t("allProducts")}
                      <span className="allproductCount fontSize12 ps-1">
                      {`(${productData?.length ?? 0})`}
                      </span>
                    </h5>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        className="commonBtn fontSize14 addToShopBtn"
                        onClick={() => {
                          handleAddProductssToShop();
                        }}
                      >
                        {t("addToShop")}
                        <img src={Images.upArrow} alt="logo" className="ms-2" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="commonTable allProductTable table-responsive mt-3">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>
                        <div className="productAvailabilityChild align-items-center">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id="selectAllCheckbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedProducts(
                                    productData?.map((_, idx) => idx)
                                  );
                                } else {
                                  setSelectedProducts([]);
                                }
                              }}
                              checked={
                                productData?.length > 0 &&
                                selectedProducts?.length === productData?.length
                              }
                            />
                            <label
                              htmlFor="selectAllCheckbox"
                              className="me-0"
                            ></label>
                          </div>
                          <h3 className="fontSize12 mb-0">
                            Product {`(${productData?.length})`}
                          </h3>
                        </div>
                      </th>

                      <th>{t("category")}</th>
                      <th>{t("subCategory")}</th>
                      <th>{t("costPrice")}</th>
                      {/* <th>Location</th> */}
                      <th>{t("stock")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        <tr>
                          <td colSpan={11}>
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="spinner-border spinner-border-md spinnerDark"></span>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : Array.isArray(productData) && productData.length > 0 ? (
                      productData.map((product, index) => (
                        <tr>
                          <td>
                            <div className="productAvailabilityChild align-items-center">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id={`product-checkbox-${index}`}
                                  checked={selectedProducts.includes(index)}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                                <label
                                  htmlFor={`product-checkbox-${index}`}
                                  className="me-0"
                                ></label>
                              </div>

                              <div className="d-flex align-items-center gap-3">
                                <img
                                  src={product?.image}
                                  alt="img"
                                  className="productItemImg"
                                />
                                <div>
                                  <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                    {product.name}
                                  </h3>

                                  {/* <div className="d-flex align-items-center gap-2">
                                    <span className="skyblueDot"></span>
                                    <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                      SKU{" "}
                                      {
                                        product?.supplies[0]?.supply_variants[0]
                                          ?.sku
                                      }
                                    </h6>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className="fontSize12 txtBlue">
                            {product.category?.name}
                          </td>
                          <td className="fontSize12 txtBlue">
                            {product.sub_category?.name}
                          </td>
                          <td className="fontSize12 txtLightBlue">
                            {" "}
                            {"$"} {product.price}
                          </td>
                          {/* <td className="fontSize12 txtBlue">30</td> */}
                          <td className="fontSize12 txtBlue">
                            {" "}
                            {
                              product?.supplies[0]?.supply_variants[0]
                                ?.total_quantity
                            }
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="fontSize14 txtLightBlue" colSpan={11}>
                          {t("noRecordsFound")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="allProductPagination d-flex justify-content-between align-items-center mt-3">
                <div
                  className={`d-flex gap-2 cursorPointer ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  style={{
                    pointerEvents: currentPage === 1 ? "none" : "auto",
                  }}
                >
                  <img
                    src={Images.lightLeftArrowLogo}
                    alt="Prev"
                    width={16}
                    height={16}
                    style={{
                      filter:
                        currentPage !== 1
                          ? "invert(77%) sepia(73%) saturate(2071%) hue-rotate(165deg) brightness(100%) contrast(83%)"
                          : "",
                    }}
                  />
                  <span
                    className={`fontSize12 fw500 ${
                      currentPage === 1 ? "txtLightPurple" : "txtDarkblue"
                    }`}
                  >
                    Prev
                  </span>
                </div>

                <h3 className="fontSize12 fw500 txtLightBlue">
                  Page {currentPage} of {Math.ceil(totalRows / perPage)}
                </h3>

                <div
                  className={`d-flex gap-2 cursorPointer ${
                    currentPage >= Math.ceil(totalRows / perPage)
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{
                    pointerEvents:
                      currentPage >= Math.ceil(totalRows / perPage)
                        ? "none"
                        : "auto",
                  }}
                >
                  <span
                    className={`fontSize12 fw500 ${
                      currentPage >= Math.ceil(totalRows / perPage)
                        ? "txtLightPurple"
                        : "txtDarkblue"
                    }`}
                  >
                    {t("next")}
                  </span>
                  <img
                    src={Images.webPosArrow}
                    alt="Next"
                    width={16}
                    height={16}
                    style={{
                      filter:
                        currentPage >= Math.ceil(totalRows / perPage)
                          ? "brightness(0) saturate(100%) invert(75%) sepia(41%) saturate(351%) hue-rotate(196deg) brightness(95%) contrast(94%)"
                          : "",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "successfullyAddProduct"
            ? "successfullyAddProduct"
            : ""
        }
        child={
          modalDetail.flag === "successfullyAddProduct" ? (
            <SuccessfullyAddProductModal
              close={() => handleOnCloseModal()}
              selectedProducts={selectedProducts.length}
            />
          ) : (
            <></>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default AllProduct;
