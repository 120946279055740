import React from "react";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";

const PauseOfferModal = (props) => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <img src={Images.pauseIcon} alt="cancleLogo" className="modalPauseImg" />
      <div className="stopOfferModalContainer">
        <h3 className="stopOfferTxt mb-0">Are you sure you want to {props?.offerStatus=='0'?'Reactive': 'Pause'} offer?</h3>
        {/* <p className="salesIncTxt mb-0">Sales Increased by 19% in 7 days </p> */}
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
        disabled={props?.isStatusChage}
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          {t('cancel')}
        </button>
        <button 
        disabled={props?.isStatusChage}
        className="activeProductBtn pauseOfferModalBtn w-100"
        onClick={() => {
          props?.productPauseFunc(props?.offerStatus||"1")
        }}>
          <img src={Images.pauseIcon} alt="stopIcon" className="me-2" />
          {/* show loader */}
          {props?.isStatusChage && (
            <span className="spinner-border spinner-border-sm spinnerRed"></span>
          )}
          {props?.offerStatus=='0'?t('resumeOffer'): t('pause')}
        </button>
      </div>
    </div>
  );
};

export default PauseOfferModal;
