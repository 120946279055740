import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import * as Images from "../../../utilities/images";
import DeleteProductModal from "../myProducts/DeleteProductModal";
import DeleteModal from "../myProducts/DeleteModal";
import EditServiceLocationModal from "./EditServiceLocationModal";
import EditServiceSalePriceModal from "./EditServiceSalePriceModal";
import CustomModal from "../../components/shared/CustomModal";
import { changeServiceAvailabilityStatusApiAsync } from "../myProducts/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  deleteProductApiAsync,
  editProductLocationApiAsync,
  editProductPriceApiAsync,
} from "../dashboard/dashboardSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
const ServiceSubTable = (props) => {
  const { t } = useTranslation();
  const { serviceData, serviceLoading, serviceListDataHandle } = props;
  const toastId = useRef(null);
  const [allAddress, setAllAddress] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [selectedServices, setSelectedServices] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [locationLoading, setLoactionLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);

  const history = useHistory();

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    serviceId: null,
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      serviceId: null,
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag, serviceId) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
      serviceId: serviceId,
    });
    setKey(Math.random());
  };
  // End Modal

  const handleCheckboxChange = (index) => {
    setSelectedServices((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  //availability status
  const availabilityStatusHandle = (id, status) => {
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "service",
        id: id,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      statusData: {
        availability_status: status === false ? 1 : 0,
      },
    };
    dispatch(changeServiceAvailabilityStatusApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        serviceListDataHandle();
      })
      .catch((err) => {});
  };

  // Download selected data as CSV
  const downloadCSV = () => {
    if (!Array.isArray(serviceData) || serviceData.length === 0) {
      showToast("No data available to download.");
      return;
    }

    // Filter selected products
    const selectedData = selectedServices.map((index) => serviceData[index]);

    if (selectedData.length === 0) {
      showToast("Please select services to download.");
      return;
    }

    // Prepare CSV headers and rows
    const headers = [
      "Service Name",
      "Service Image",
      "Category",
      "Sub-Category",
      "Cost Price",
      "POS Price",
      "B2C Price",
      "Staff",
    ];

    const rows = selectedData.map((service) => [
      service.name || "",
      service?.image || "",
      service.category?.name || "",
      service.sub_category?.name || "",
      service.price || "",
      service.supplies[0]?.supply_prices?.find((v) => v?.app_name === "pos")
        ?.selling_price || "",
      service.supplies[0]?.supply_prices?.find((v) => v?.app_name === "b2c")
        ?.selling_price || "",
      service?.staff_count || "",
    ]);

    // Convert to CSV string
    const csvContent = [
      headers.join(","), // Add headers
      ...rows.map((row) => row.map((value) => `"${value}"`).join(",")), // Add rows
    ].join("\n");

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "selected_service_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteService = () => {
    if (selectedServices.length === 0 && modalDetail.serviceId === null) {
      showToast("Please select at least one product to delete.");
      return;
    }
    setDeleteLoading(true);
    // Prepare params for the delete request
    let params = {};

    // Handle individual product deletion
    if (modalDetail.serviceId !== null) {
      const productToDelete = modalDetail.serviceId;
      params = {
        postData: { product_ids: [productToDelete] },
        tokenData: auth?.payload?.token,
      };
    } else {
      // Handle bulk deletion
      const serviceIds = selectedServices.map((index) => serviceData[index].id);
      params = {
        postData: { product_ids: serviceIds },
        tokenData: auth?.payload?.token,
      };
    }

    // Dispatch delete action
    dispatch(deleteProductApiAsync(params))
      .then(unwrapResult)
      .then(() => {
        // Open delete modal and close after 5 seconds
        handleOpenModal("deleteModal", null);

        setTimeout(() => {
          handleOnCloseModal(); // Ensure modal closes after 5 seconds
          props.serviceListDataHandle(); // Fetch updated product list only once
        }, 2000); // Adjusted timeout to 5 seconds
        setSelectedServices([]);
        setDeleteLoading(false);
      })
      .catch(() => {
        showToast("Error deleting products.");
        setDeleteLoading(false);
      });
  };

  const handleDeleteClick = () => {
    if (!selectedServices.length) {
      showToast("Please select at least one service to delete.");
      return;
    }
    handleOpenModal("deleteProductModal", null);
  };

  const handleBulkSale = () => {
    if (!selectedServices.length) {
      showToast("Please select at least one service ");
      return;
    }
    handleOpenModal("editSalePriceModal", null);
  };
  const handleLocation = () => {
    if (!selectedServices.length) {
      showToast("Please select at least one service ");
      return;
    }
    handleOpenModal("editLocationModal", null);
  };

  const handleEditLocation = (deliveryOption, id) => {
    const productIds = selectedServices.map((index) => serviceData[index].id);
    const params = {
      postData: {
        product_ids: productIds,
        seller_address_ids: id,
        delivery_options: deliveryOption,
      },
      tokenData: auth?.payload?.token,
    };
    setLoactionLoading(true);
    dispatch(editProductLocationApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        handleOnCloseModal();
        props.serviceListDataHandle();
        setLoactionLoading(false);
        setSelectedServices([]);
      })
      .catch((obj) => {
        setLoactionLoading(false);
      });
  };

  const handleEditPrice = (id, price, appName) => {
    const productIds = selectedServices.map((index) => serviceData[index].id);
    const params = {
      postData: {
        product_ids: productIds,
        seller_address_ids: id,
        selling_price: price,
        app_names: appName,
      },
      tokenData: auth?.payload?.token,
    };
    setPriceLoading(true);
    dispatch(editProductPriceApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        handleOnCloseModal();
        props.serviceListDataHandle();
        setPriceLoading(false);
        setSelectedServices([]);
      })
      .catch((obj) => {
        setPriceLoading(false);
      });
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];

        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  return (
    <>
      <div className="commonTable productTable table-responsive mt-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>
                <div className="productAvailabilityChild align-items-center">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="selectAllCheckbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedServices(serviceData.map((_, idx) => idx));
                        } else {
                          setSelectedServices([]);
                        }
                      }}
                      checked={
                        serviceData?.length > 0 &&
                        selectedServices.length === serviceData.length
                      }
                    />
                    <label htmlFor="selectAllCheckbox" className="me-0"></label>
                  </div>
                  <h3 className="fontSize12 mb-0">{t('service')}</h3>
                </div>
              </th>
              <th>{t('category')}</th>
              <th>{t('subCategory')}</th>
              <th>POS Price</th>
              <th>B2C Price</th>
              <th>{t('staff')}</th>
              <th>
                <div className="productTableLastChild d-flex align-items-center gap-1 justify-content-end">
                  <img
                    src={Images.downloadLogo}
                    alt="logo"
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadCSV();
                    }}
                    className="cursorPoint"
                    height={17}
                    width={17}
                  />

                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                        disabled={
                          !Array.isArray(selectedServices) ||
                          selectedServices.length === 0
                        }
                      >
                        <img src={Images.moreHorizontalLogo} alt="logo" style={{
                            filter:
                              Array.isArray(selectedServices) &&
                              selectedServices.length > 0
                                ? "brightness(0) saturate(100%) invert(14%) sepia(63%) saturate(2662%) hue-rotate(221deg) brightness(94%) contrast(89%)"
                                : "",
                          }}/>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu ">
                        <Dropdown.Item>
                          <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                            {t('bulkEditing')}
                          </h3>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLocation}>
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.locationLogo} alt="logo" />
                            <h3 className="fontSize14 mb-0">{t('editLocation')}</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleBulkSale}>
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.moneyPaperIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">
                              {t('editSalesPricing')}
                            </h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleDeleteClick}>
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              {t('delete')}
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {serviceLoading ? (
              <>
                <tr>
                  <td colSpan={11}>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="spinner-border spinner-border-md spinnerDark"></span>
                    </div>
                  </td>
                </tr>
              </>
            ) : Array.isArray(serviceData) && serviceData.length > 0 ? (
              serviceData.map((service, index) => (
                <tr key={index}>
                  <td>
                    <div className="productAvailabilityChild align-items-center">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id={`service-checkbox-${index}`}
                          checked={selectedServices.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label
                          htmlFor={`service-checkbox-${index}`}
                          className="me-0"
                        ></label>
                      </div>

                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={service?.image}
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                            {service?.name}
                          </h3>

                          {/* <div className="d-flex align-items-center gap-2">
                            <span className="skyblueDot"></span>
                            <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                              SKU 0199 - 3221
                            </h6>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="fontSize12 txtLightBlue">
                    {service?.category?.name?.length > 20
                      ? service?.category?.name?.substring(0, 20) + "..."
                      : service?.category?.name}
                  </td>
                  <td className="fontSize12 txtLightBlue">
                    {service?.sub_category?.name}
                  </td>
                  <td className="fontSize12 txtLightBlue">
                    {" "}
                    {"$"}{" "}
                    {
                      service.supplies[0]?.supply_prices?.find(
                        (v) => v?.app_name === "pos"
                      )?.selling_price
                    }
                  </td>
                  <td className="fontSize12 txtLightBlue">
                    {"$"}{" "}
                    {
                      service.supplies[0]?.supply_prices?.find(
                        (v) => v?.app_name === "b2c"
                      )?.selling_price
                    }
                  </td>
                  <td className="fontSize12 txtLightBlue">
                    {service?.staff_count}
                  </td>

                  <td className="text-end">
                    <div className="d-flex align-items-center justify-content-end gap-3">
                      <input
                        type="checkbox"
                        className="cursorPoint"
                        checked={service?.supplies[0]?.status === true}
                        onChange={() =>
                          availabilityStatusHandle(
                            service?.id,
                            service?.supplies[0]?.status
                          )
                        }
                      />
                      <Dropdown className="d-flex">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="variantsTable_Dropdown"
                        >
                          <img src={Images.moreVerticalLogo} alt="logo" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                          <Dropdown.Item
                            onClick={() =>
                              history.push({
                                pathname: `/editService`,
                                state: service,
                              })
                            }
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.darkBlueEyeIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0">{t('view')}</h3>
                            </div>
                          </Dropdown.Item>
                          {/* <Dropdown.Item href="#/action-2">
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.editIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0">Edit Variants</h3>
                            </div>
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() =>
                              handleOpenModal("deleteProductModal", service?.id)
                            }
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.newDeleteIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                {t('delete')}
                              </h3>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="fontSize14 txtLightBlue" colSpan={7}>
                  {t('noRecordFound')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "deleteProductModal"
            ? "stopOfferModal"
            : modalDetail.flag === "deleteModal"
            ? "deleteModal"
            : modalDetail.flag === "editLocationModal"
            ? "editLocationModal"
            : modalDetail.flag === "editSalePriceModal"
            ? "editLocationModal"
            : ""
        }
        child={
          modalDetail.flag === "deleteProductModal" ? (
            <DeleteProductModal
              close={() => handleOnCloseModal()}
              handleDeleteProduct={handleDeleteService}
              deleteLoading={deleteLoading}
              service={true}
            />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editLocationModal" ? (
            <EditServiceLocationModal
              close={() => handleOnCloseModal()}
              handleUpdate={(deliveryOption, id) =>
                handleEditLocation(deliveryOption, id)
              }
              loading={locationLoading}
              allAddress={allAddress}
            />
          ) : modalDetail.flag === "editSalePriceModal" ? (
            <EditServiceSalePriceModal
              close={() => handleOnCloseModal()}
              allAddress={allAddress}
              handleUpdate={(id, price, appName) =>
                handleEditPrice(id, price, appName)
              }
              loading={priceLoading}
            />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "editLocationModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      {selectedServices?.length} items selected items selected
                    </h6>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                     {t('updateVisbility')}
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    className="cursorPointer"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : modalDetail.flag === "editSalePriceModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      {selectedServices?.length} items selected items selected
                    </h6>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                      Update sale price
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    className="cursorPointer"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ServiceSubTable;
