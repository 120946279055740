import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Stepper } from "react-form-stepper";
import * as Images from "../../../../utilities/images";
import CreateOfferDetails from "./OfferDetails";
import CreateOfferLocation from "./OfferLocation";
import CreateOfferPublish from "./OfferPublish";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressApiAsync,
  selectAddressList,
  selectLoginAuth,
} from "../../auth/authSlice";
import {
  getProductServiceListApiAsync,
  getSingleProductDetailsByIdAsync,
  selectProductData,
} from "../productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createProductOfferApiAsync,
  getSingleOfferByIdAsync,
  updateProductOfferApiApiAsync,
} from "../../dashboard/dashboardSlice";
import moment from "moment-timezone";
import Loader from "../../../components/UI/Loader/Loader";
import { useTranslation } from "react-i18next";
import { getColorName } from "../../../../utilities/helpers";

const UpdateOffer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const { id } = useParams();
  const auth = useSelector(selectLoginAuth);
  const [activeStep, setActiveStep] = useState(1);
  const history = useHistory();
  const [searchData, setSearchData] = useState("");
  const [showProduct, setShowProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedproduct, setSelectedproduct] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [validVariants, setValidVariants] = useState([]);
  const [offerPrices, setOfferPrices] = useState({});

  const [allAddress, setAllAddress] = useState([]);
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [OfferDetails, setOfferDetails] = useState(null);
  const [isProduct, setIsProduct] = useState(true);
  const sellerAddressList = useSelector(selectAddressList);
  const [sellerAddressDetailss, setSellerAddressDetails] = useState([]);

  const [selectType, setSelectType] = useState({
    value: "percentage",
    label: "Percentage",
  });
  const [priceType, setPriceType] = useState({
    value: "different",
    label: "Different Offer Price",
  });
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const validateStepOne = () => {
    if (!selectedproduct) {
      showToast("Please search and select a product.");
      return false;
    }

    if (!selectType?.value) {
      showToast("Please select a type.");
      return false;
    }

    if (!startDate) {
      showToast("Start date is required.");
      return false;
    }

    if (!endDate) {
      showToast("End date is required.");
      return false;
    }

    if (new Date(endDate) < new Date(startDate)) {
      showToast("End date cannot be before start date.");
      return false;
    }

    if (!Array.isArray(formattedVariants) || formattedVariants.length === 0) {
      showToast("Invalid variant data.");
      return false;
    }

    /**  Check if all formattedVariants have null/empty attributes */
    const allAttributesNull = formattedVariants.every(
      (variant) =>
        !variant.attributes || Object.keys(variant.attributes).length === 0
    );

    /**  If all attributes are null/empty, validate using formattedVariants */
    if (allAttributesNull) {
      for (const variant of formattedVariants) {
        for (const price of variant.prices || []) {
          const actualPrice = Number(price.actual_price);
          const offerPrice = Number(price.offer_price);
          let productPrice = selectedproduct?.price;
          console.log(actualPrice, "Actual Price", "check this");

          if (offerPrice === 0) {
            const message =
              priceType?.value === "same"
                ? "The offer price cannot be zero."
                : `The offer price for ${
                    price.app_name == "b2c" ? "MarketPlace" : "POS"
                  } cannot be zero.`;
            showToast(message);
            return false;
          }
          if (
            selectType?.value === "amount" &&
            offerPrice < selectedproduct?.price
          ) {
            showToast("offer price wont be less than cost price.");
            return false;
          }
          if (
            selectType?.value === "percentage" &&
            (offerPrice < 1 || offerPrice >= 100)
          ) {
            showToast("Offer price percentage must be between 1 and 100.");
            return false;
          }
          if (
            selectType?.value !== "percentage" &&
            (offerPrice === actualPrice || offerPrice > actualPrice)
          ) {
            showToast(
              `The offer price cannot be higher or equal than the actual price ${actualPrice}`
            );
            return false;
          }
        }
      }
    } else {
      /**  If at least one variant has attributes, validate using validVariants */
      if (selectedVariants.length === 0) {
        showToast("Please select at least one variant.");
        return false;
      }
      const selectedVariantData = formattedVariants.filter((variant) =>
        selectedVariants.includes(variant.supply_variant_id)
      );

      if (selectedVariantData.length === 0) {
        showToast("Selected variants do not match available variants.");
        return false;
      }

      for (const variant of selectedVariantData) {
        for (const price of variant.prices || []) {
          const actualPrice = Number(price.actual_price);
          const offerPrice = Number(price.offer_price);
          let productPrice = selectedproduct?.price;
          const formattedAttributes = Object.entries(variant.attributes)
            .map(([key, val]) =>
              val.startsWith("#") &&
              /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(val)
                ? getColorName(val) // Convert hex color to name
                : val
            )
            .join(" / ");

          if (offerPrice === 0) {
            if (priceType.value != "same") {
              showToast(
                `Offer price for ${price.app_name} cannot be 0 of ${formattedAttributes}.`
              );
            } else {
              showToast(`Offer price  cannot be 0 of ${formattedAttributes}.`);
            }
            return false;
          }
          if (
            selectType?.value === "amount" &&
            offerPrice < selectedproduct?.price
          ) {
            showToast("offer price wont be less than cost price.");
            return false;
          }

          if (
            selectType?.value === "percentage" &&
            (offerPrice < 1 || offerPrice >= 100)
          ) {
            showToast("Offer price percentage must be between 1 and 100.");
            return false;
          }

          if (
            selectType?.value !== "percentage" &&
            (offerPrice === actualPrice || offerPrice > actualPrice)
          ) {
            showToast(
              `The offer price cannot be higher or equal than the actual price ${actualPrice} of ${formattedAttributes}.`
            );
            return false;
          }
        }
      }

      setValidVariants(selectedVariantData);
    }

    return true;
  };
  const uniqueSellerAddressIds = [
    ...new Set(
      selectedproduct?.supplies?.map((item) => item.seller_address_id)
    ),
  ];
  const validateStepTwo = () => {
    if (sellerAddressIds.length === 0) {
      showToast("Please select at least one seller address.");
      return false;
    }

    // if (deliveryOptions.length === 0) {
    //   showToast("Please select at least one delivery option.");
    //   return false;
    // }

    return true;
  };
  useEffect(() => {
    setSellerAddressIds((prevIds) =>
      prevIds.filter((id) => uniqueSellerAddressIds.includes(id))
    );
  }, [selectedproduct]);
  const handleNext = () => {
    if (activeStep === 1) {
      if (!validateStepOne()) return; // Stop if validation fails
    }

    if (activeStep === 2) {
      if (!validateStepTwo()) return; // Stop if validation fails
    }
    if (activeStep === 3) {
      createOfferProduct();
      return;
    }
    setActiveStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const createOfferProduct = () => {
    let variantsData = formattedVariants.every(
      (variant) =>
        variant.attributes && Object.keys(variant.attributes).length > 0
    )
      ? validVariants
      : formattedVariants;
    if (priceType.value == "same") {
      variantsData?.map((variant) => {
        let details = variant;
        let price = details.prices?.[1]?.offer_price;
        details.prices[0].offer_price = price;
        return {
          ...details,
        };
      });
    }
    let otherVariantsData = [];
    let variantSupplyIds = variantsData?.map(
      (variant) => variant.supply_variant_id
    );
    let supplies = selectedproduct?.supplies || [];
    let indexes = [];
    let offerId = OfferDetails?.supply_offers?.[0]?.supply_id;
    let indx = selectedproduct?.supplies?.findIndex(
      (supply) => supply.id == offerId
    );
    indx = indx === -1 ? 0 : indx;
    supplies?.[indx]?.supply_variants?.map((variant, index) => {
      if (variantSupplyIds.includes(variant.id)) {
        indexes.push(index);
      }
    });
    for (let i = 0; i < supplies.length; i++) {
      let supply = supplies[i];
      let j = 0;
      supply?.supply_variants?.map((item, index) => {
        if (indexes.includes(index)) {
          let id = supply?.id;
          let addressId = supply?.seller_address_id;
          if (sellerAddressIds.includes(addressId)) {
            let supply_variant_id = item.id;
            if (j < variantsData.length) {
              let variantDetails = variantsData[j++];
              otherVariantsData.push({
                ...variantDetails,
                supply_id: id,
                supply_variant_id,
                addressId,
              });
            }
          }
        }
      });
    }
    variantsData = [...otherVariantsData].filter((variant) =>
      sellerAddressIds.includes(variant.addressId)
    );
    // // **Remove `attributes` field**
    // variantsData = variantsData.map(({ attributes, ...variant }) => variant);
    let additionalField = {
      offer_price_option: "different_price",
    };
    if (priceType?.value == "same") {
      additionalField["offer_price_option"] = "same_price";
    }
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        product_id: String(selectedproduct?.id),
        offer_validity: moment(endDate).format("YYYY-MM-DD"),
        offer_start_from: moment(startDate).format("YYYY-MM-DD"),
        price_flag: selectType.value,
        seller_address_ids: sellerAddressIds,
        // delivery_options: deliveryOptions,
        variants: variantsData,
        type: OfferDetails?.type || "product",
        ...(selectedproduct?.category_id && {
          category_id: String(selectedproduct?.category_id),
        }),
        ...(selectedproduct?.sub_category_id && {
          sub_category_id: String(selectedproduct?.sub_category_id),
        }),
        id: OfferDetails.id,
        ...additionalField,
      },
    };
    setButtonLoading(true);
    dispatch(updateProductOfferApiApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setButtonLoading(false);
        toast.success(obj?.msg);
        history.push(
          OfferDetails?.type == "product" ? `/productOffer` : "/serviceOffer"
        );
      })
      .catch((obj) => {
        setButtonLoading(false);
      });
  };
  // Handle step changes
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => {
      if (prevStep - 1 <= 0) {
        history.goBack();
      }

      return Math.max(prevStep - 1, 1);
    });
  };
  // const formattedAllVariants = useMemo(() => {
  //   if (!selectedproduct?.supplies || selectedproduct.supplies.length === 0) {
  //     return [];
  //   }

  //   return selectedproduct.supplies.flatMap((supply) =>
  //     (supply?.supply_variants || []).map((variant) => ({
  //       supply_id: supply?.id?.toString() || "",
  //       supply_variant_id: variant?.id?.toString() || "",
  //       attributes: variant?.attributes ? JSON.parse(variant.attributes) : {},
  //       prices: ["b2c", "pos",
  //         // "b2b"

  //       ].map((appName) => {
  //         const priceEntry = (supply?.supply_prices || []).find(
  //           (price) =>
  //             price?.app_name === appName &&
  //             price?.supply_variant_id === variant?.id
  //         );

  //         return {
  //           app_name: appName,
  //           actual_price: priceEntry?.selling_price?.toString() || "0",
  //           offer_price: offerPrices?.[variant.id]?.[appName] || "0", // Ensure offerPrices is defined
  //         };
  //       }),
  //       address_id:supply?.seller_address_id
  //     }))
  //   );
  // }, [selectedproduct, offerPrices]);
  const formattedVariants = useMemo(() => {
    if (!selectedproduct?.supplies || selectedproduct.supplies.length === 0) {
      return [];
    }
    let offerId = OfferDetails?.supply_offers?.[0]?.supply_id;
    let index = 0;
    selectedproduct?.supplies?.map((supply, ind) => {
      console.log(supply);
      if (supply.id == offerId) {
        index = ind;
      }
    });
    index = index === -1 ? 0 : index;
    const allSupplyVariants =
      selectedproduct?.supplies?.[index]?.supply_variants?.map((variant) => {
        let supplyId = selectedproduct?.supplies?.[index]?.id;
        let addressId = selectedproduct?.supplies?.[index]?.seller_address_id;
        return {
          ...variant,
          supply_id: supplyId,
          addressId: addressId,
        };
      }) || [];

    const filteredVariants = allSupplyVariants;
    if (!filteredVariants || filteredVariants.length === 0) {
      return [];
    }
    return filteredVariants.map((variant) => ({
      supply_variant_id: variant?.id,
      supply_id: variant?.supply_id,
      attributes: variant?.attributes
        ? typeof variant.attributes === "string"
          ? JSON.parse(variant.attributes)
          : variant.attributes
        : {},
      prices: ["b2c", "pos"].map((appName) => {
        const priceEntry = (selectedproduct?.supplies || [])
          .flatMap((supply) => supply?.supply_prices || [])
          .find(
            (price) =>
              price?.app_name === appName &&
              price?.supply_variant_id === variant?.id
          );

        return {
          app_name: appName,
          actual_price: priceEntry?.selling_price?.toString() || "0",
          offer_price: offerPrices?.[variant.id]?.[appName] || "0", // Ensure offerPrices is defined
        };
      }),
      // address_id:supply?.seller_address_id
    }));
  }, [selectedproduct, offerPrices]);
  console.log(formattedVariants, "formattedVariants");
  const renderStepComponent = () => {
    switch (activeStep) {
      case 1:
        return (
          <CreateOfferDetails
            showProduct={showProduct}
            productList={productList}
            loading={loading}
            selectedproduct={selectedproduct}
            setSelectedproduct={setSelectedproduct}
            setShowProduct={setShowProduct}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectType={selectType}
            setSelectType={setSelectType}
            formattedVariants={formattedVariants}
            offerPrices={offerPrices}
            setOfferPrices={setOfferPrices}
            selectedVariants={selectedVariants}
            setSelectedVariants={setSelectedVariants}
            OfferDetails={OfferDetails}
            isProduct={isProduct}
            allAddress={allAddress}
            setPriceType={setPriceType}
            priceType={priceType}
          />
        );
      case 2:
        return (
          <CreateOfferLocation
            allAddress={allAddress}
            sellerAddressIds={sellerAddressIds}
            setSellerAddressIds={setSellerAddressIds}
            deliveryOptions={deliveryOptions}
            setDeliveryOptions={setDeliveryOptions}
            formattedVariants={formattedVariants}
            validVariants={validVariants}
            OfferDetails={OfferDetails}
            isProduct={isProduct}
            uniqueSellerAddressIds={uniqueSellerAddressIds}
          />
        );
      case 3:
        return (
          <CreateOfferPublish
            selectedproduct={selectedproduct}
            formattedVariants={formattedVariants}
            startDate={startDate}
            endDate={endDate}
            selectType={selectType}
            validVariants={validVariants}
            OfferDetails={OfferDetails}
            isProduct={isProduct}
            sellerAddressIds={sellerAddressIds}
            priceType={priceType}
          />
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  const getProductList = (id) => {
    if (!id) {
      setProductList([]);
      setShowProduct(false);
      return;
    }

    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        id,
        app_name: "merchant",
      },
    };
    setShowProduct(true);
    setLoading(true);
    dispatch(getSingleProductDetailsByIdAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setProductList(obj?.payload?.product_detail);
        setSelectedproduct(obj?.payload?.product_detail);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };

    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];
        // select default seller address if only one address
        let addressList = obj?.payload;
        setAllAddress(addressList || []);

        if (addressList?.length == 1) {
          // setSellerAddressIds([addressList[0]?.id]);
        }
      })
      .catch((obj) => {});
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    if (selectedproduct?.supplies) {
      const initialPrices = {};
      selectedproduct.supplies.forEach((supply) => {
        supply?.supply_variants?.forEach((variant) => {
          const variantId = variant?.id?.toString() || "";
          initialPrices[variantId] = {};
          [
            "b2c",
            "pos",
            // "b2b"
          ].forEach((appName) => {
            const priceObj = supply?.supply_prices?.find(
              (price) =>
                price?.app_name === appName &&
                price?.supply_variant_id === variant?.id
            );
            initialPrices[variantId][appName] = "";
            // priceObj?.selling_price?.toString() || "0";
          });
        });
      });
      setOfferPrices(initialPrices);
    }
  }, [selectedproduct]);

  //fetch offer Data
  useEffect(() => {
    if (id) {
      const params = {
        tokenData: auth?.payload?.token,
        postData: {
          id: id,
        },
      };
      setLoading(true);
      dispatch(getSingleOfferByIdAsync(params))
        .then(unwrapResult)
        .then((res) => {
          setLoading(false);
          setOfferDetails(res?.payload);
          let offerPriceOption = res?.payload?.offer_price_option;
          if (offerPriceOption == "same_price") {
            setPriceType({ value: "same", label: "Same Offer Price" });
          }
          let addressList = JSON.parse(
            res?.payload?.seller_address_ids || "[]"
          );
          setSellerAddressIds(addressList);
          setIsProduct(res?.payload?.type == "product" || false);
          getProductList(res?.payload?.product_id);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [id]);
  return (
    <div className="position-relative createOffBox createOfferSection productDetailsWrapper">
      {loading && <Loader />}
      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <div className="col-md-12">
            <div className="walletStats">
              <div className="createOfferHeader">
                <div className="row align-items-center">
                  <div className="col-8 col-sm-9">
                    <h3 className="fontSize20 fw500 txtDarkblue">
                      {t("editOffer")}
                    </h3>
                    <p className="fontSize16 fw500 mb-0 txtDarkblue">
                      Select one product and offer price wont be less than cost
                      price.
                    </p>
                  </div>
                  <div className="col-4 col-sm-3 text-end">
                    <button
                      className="createOfferCloseBtn"
                      onClick={() => history.goBack()}
                    >
                      {t("close")}
                      <img
                        src={Images.lightCrossIcon}
                        alt="logo"
                        className="closeCrossIcon ms-1"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="createOfferContainer">
                <div
                  className={
                    activeStep == 3
                      ? "row h-100 justify-content-center"
                      : "row h-100"
                  }
                >
                  <div
                    className={
                      activeStep == 3
                        ? "col-xl-8 col-lg-10 col-12"
                        : "col-lg-8 p-0"
                    }
                  >
                    <div className="createOfferLeft">
                      <div className="stepperParent">
                        <Stepper
                          activeStep={activeStep}
                          onStepChange={handleStepChange}
                          steps={[
                            {
                              label: `${
                                OfferDetails
                                  ? OfferDetails?.type == "product"
                                    ? "Product"
                                    : "Service"
                                  : "Product"
                              }  & Pricing `,
                            },
                            { label: "Locations" },
                            { label: "Publish" },
                          ]}
                          disabledColor="#E4EBF1"
                          activeColor="red !important"
                        />
                      </div>

                      <div className="createOffer_DetailBox offerDetailBox">
                        {renderStepComponent()}

                        <div className="createOfferBtnsParent editVariantModal_Footer">
                          <button
                            className="modalDiscardBtn w-auto fontSize14"
                            onClick={handleBack}
                          >
                            <img
                              src={Images.backArrow}
                              alt="logo"
                              className="me-2"
                            />
                            {t("discard")}
                          </button>
                          <button
                            className="modalDiscardBtn modalNextBtn w-auto"
                            onClick={handleNext}
                            disabled={buttonLoading}
                          >
                            {buttonLoading && (
                              <span className="spinner-border spinner-border-sm spinnerLight"></span>
                            )}{" "}
                            {activeStep == 3 ? t("publish") : t("next")}
                            <img
                              src={Images.arrowTopRight}
                              alt="logo"
                              className="ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {activeStep !== 3 && (
                    <div className="col-lg-4 mt-4 mt-lg-0">
                      <div className="createOfferRight">
                        <h3 className="fontSize24 fw500 mb-0 txtBlue">
                          {isProduct ? "Product" : "Service"} {t("preview")}
                        </h3>
                        {selectedproduct && (
                          <>
                            <div className="text-center productPreviewBox">
                              <img
                                src={selectedproduct?.image}
                                alt="img"
                                className="offerProductImg"
                              />
                              <h4 className="fontSize20 fw500 mb-0 pt-4 txtDarkblue">
                                {selectedproduct.name}
                              </h4>

                              {isProduct && (
                                <div className="d-flex align-items-center justify-content-center gap-2 pt-3">
                                  <span className="greyDot"></span>
                                  <h6 className="greyDotLabel mb-0">
                                    SKU{" "}
                                    {
                                      selectedproduct?.supplies?.[0]
                                        ?.supply_variants[0]?.sku
                                    }
                                  </h6>
                                </div>
                              )}
                            </div>

                            <div>
                              <h3 className="fontSize16 fw500 txtDarkblue">
                                {isProduct ? "Product" : "Service"}{" "}
                                {t("details")}
                              </h3>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Category
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="pe-0 productPreview_Detailtxt ps-0">
                                    {selectedproduct?.category?.name}
                                  </h3>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Sub-category
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="pe-0 productPreview_Detailtxt ps-0">
                                    {selectedproduct?.sub_category?.name}
                                  </h3>
                                </div>
                              </div>
                              {isProduct && (
                                <>
                                  <div className="row">
                                    <div className="col-6">
                                      <h3 className="productPreview_Detailtxt">
                                        SKU
                                      </h3>
                                    </div>
                                    <div className="col-6">
                                      <h3 className="pe-0 productPreview_Detailtxt ps-0">
                                        {
                                          selectedproduct?.supplies?.[0]
                                            ?.supply_variants?.[0]?.sku
                                        }
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <h3 className="productPreview_Detailtxt">
                                        Barcode
                                      </h3>
                                    </div>
                                    <div className="col-6">
                                      <h3 className="pe-0 productPreview_Detailtxt ps-0">
                                        {
                                          selectedproduct?.supplies?.[0]
                                            ?.supply_variants?.[0]?.barcode
                                        }
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">
                                      <h3 className="productPreview_Detailtxt">
                                        Unit Weight
                                      </h3>
                                    </div>
                                    <div className="col-6">
                                      <h3 className="pe-0 productPreview_Detailtxt ps-0">
                                        {selectedproduct?.weight
                                          ? `${selectedproduct?.weight} ${
                                              selectedproduct?.weight_unit || ""
                                            }`
                                          : ""}
                                      </h3>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateOffer;
