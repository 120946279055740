import { Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Images from "../../../utilities/images";
import SelectMenu from "../../components/UI/Orders/SelectMenu";
import { AllStatus, BookingColumns } from "../../constants/bookings/allbooking";
import { resultSelectOption } from "../../constants/common.constant";
import { getAllRolesApiAsync, getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { onErrorStopLoad, setGetStaffUsers } from "../bookings/bookingSlice";
import {
  StaffColumns,
  staffRoles,
  staffStatus,
  staffWorkType,
} from "../../constants/staff/staff.constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {Link as Href} from "react-router-dom"
const PayrollTeamMembers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsNames, setColumnNames] = useState(StaffColumns);
  const [allStaffList, setallStaffList] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const [searchKeyword, setsearchKeyword] = useState("");
    const [searchTimeout, setsearchTimeout] = useState(null);
    const [isSearching, setisSearching] = useState(false);
  
  const [totalData, settotalData] = useState(0);
  const [allRoles, setAllRoles] = useState([
    {
      value:'',
      label:"All Roles",
    }
  ]);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [role, setrole] = useState(null);
  const [status, setstatus] = useState(null);
  const [workType, setworkType] = useState(null);
  const [isCurrentPage, setisCurrentPage] = useState(false);

  const [filterOptions, setfilterOptions] = useState([
    {
      title: "Roles",
      data: allRoles,
    },
    {
      title: "Status",
      data: staffStatus,
    },
    {
      title: "Work type",
      data: staffWorkType,
    },
  ]);

  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      if (prev + value <= totalPages && prev + value >= 1) return prev + value;
      setisCurrentPage(true);
      return prev;
    });
  };
  const handleFilterOptions = (value, key) => {
    if (key == "Roles") {
      console.log("all orders", value);
      setrole(value);
    } else if (key == "Status") setstatus(value);
    else setworkType(value);
  };
  const getUserPosDetails = async () => {
    const prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: currentPage,
        limit: selectPageSize,
        ...(searchKeyword.length > 0 && {
          search: searchKeyword,
        }),
        ...(status && { status: status }),
        ...(workType && { employment_type:workType}),
        ...(role&&{role:role})
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosUsersAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setisCurrentPage(false);
        setLoading(false);
        dispatch(setGetStaffUsers(res));
        setallStaffList(res?.payload?.pos_staff || []);
        setTotalPages(res?.payload?.total_pages || 1);
      })
      .catch((err) => {
        setLoading(false);

        dispatch(onErrorStopLoad());
      });
  };
  useEffect(() => {
    setCurrentPage(1);
    getUserPosDetails();
  },[selectPageSize,status,workType,role])
  useEffect(()=>{
   if(isCurrentPage) getUserPosDetails();
  },[currentPage])
  useEffect(()=>{
    if(!isSearching)return;
    if(searchTimeout){
      clearTimeout(searchTimeout);
    }
    let searchTimer = setTimeout(() => {
      getUserPosDetails();
      setisSearching(false);
    }, 1000);
    setsearchTimeout(searchTimer);
    return () => {
      clearTimeout(searchTimeout);
    }
    
  },[searchKeyword]);
const getAllUserRoles=()=>{
  const params={
    tokenData:auth?.payload?.token
  }
  dispatch(getAllRolesApiAsync(params))
  .then(unwrapResult)
  .then(res=>{
    let rolesList=res?.payload||[];
    let roles=rolesList?.map((role=>{
      return {
        value:role.id,
        label:role.name
      }
    }));
    roles?.push({
      value: '',
      label: 'All Roles'
    })
    setAllRoles(rolesList);
    let filterOption=filterOptions;
    filterOption[0].data=roles;
    setfilterOptions(filterOption);
  })
  .catch(err=>{})
}
useEffect(()=>{
  getAllUserRoles();
},[])
  return  <>
      <div className="walletStats h-100">
        <div className="productOfferHead_Container">
          {/* header */}
          <div className="flexBox justify-content-between">
            <h3 className="fontSize24 txtDarkblue fw500 mb-0">Team Members</h3>
            <div className="flexBox gap-4">
              <img src={Images.NotificationIcon} alt="NotificationIcon" />
              <div className="input-group search_group">
                <img
                  src={Images.SearchIcon}
                  alt=""
                  className="img-fluid searchIcon"
                />
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    if (!isSearching) {
                      setisSearching(true);
                    }
                    setsearchKeyword(e.target.value);
                  }}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
              <button
                className="commonBlueBtn active gap-2 text-nowrap"
                onClick={() => history.push("/addNewStaff")}
              >
                Add team member <img src={Images.addButtonImg} alt="img" />
              </button>
            </div>
          </div>
          {/* header */}
          {/* menu */}
          <div className="row align-items-center mt-4">
            <div className="col-xl-6">
              <div className="flexBox gap-3">
                {filterOptions.map((filter, index) => (
                  <div className="reactSelectParent offerHeaderSelect gapDiffer_">
                    <SelectMenu
                      options={filter.data}
                      placeholder={filter.title}
                      onChange={(event) => {
                        handleFilterOptions(event.value, filter.title);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-6">
              <div className="d-flex justify-content-end gap-3">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fontSize14 txtDarkblue mb-0">
                    Showing Results
                  </h3>
                  <div className="reactSelectParent offerHeaderSelect">
                    <SelectMenu
                      options={resultSelectOption}
                      placeholder="10"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageLimit(e.value);
                        setSelectPageSize(e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 && "disablePrevbtn"
                      } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleLeft}
                        alt="arrowDoubleRight"
                      />
                    </div>
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 && "disablePrevbtn"
                      } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0">
                    {(currentPage - 1) * selectPageSize + 1}-
                    {currentPage * pageLimit > totalData
                      ? totalData
                      : currentPage * selectPageSize}{" "}
                    of {totalData}
                  </h6>
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages && "disableNextbtn"
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                    </div>
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages && "disableNextbtn"
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* menu */}
        </div>
        {/* table */}
        <div className="commonTable table-responsive mt-3">
          <table className="w-100 allorderTable_">
            <thead>
              <tr>
                <th>#</th>
                {columnsNames.map((column, index) => (
                  <th key={index} className={!column.isVisible && "d-none"}>
                    {column.title}
                  </th>
                ))}
                <th>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="variantsTable_Dropdown"
                    >
                      <img src={Images.plusIconWithBg} alt="icon" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                      <Link to="#" className="resetText_">
                        Reset
                      </Link>
                      {columnsNames.map((column, index) => (
                        <Dropdown.Item
                          onClick={() => {
                            let columns = columnsNames;
                            columns[index].isVisible = !column.isVisible;
                            setColumnNames([...columns]);
                          }}
                        >
                          <div className="productAvailabilityChild align-items-center">
                            <div className="form-group">
                              <input
                                type="checkbox"
                                checked={column.isVisible}
                              />
                              <label htmlFor="check3" className="me-0"></label>
                            </div>
                            <h3 className="fontSize14 mb-0 mt-1">
                              {column.title}
                            </h3>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr className="text-center border-none">
                  <td colSpan="100%">
                    <div className="d-flex">
                      <span className="spinner-border inner-spin spinner-border-sm"></span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {allStaffList?.length > 0 ? 
                    allStaffList?.map((staff, index) => {
                      let userDetails = staff?.user?.user_profiles;
                      let userRoles = staff?.user?.user_roles;
                      let userSalary = staff?.user?.pos_staff_salary;

                    return <tr key={index}>
                    <td className="orderTrackTableTxt">1</td>
                     {/* name */}
                    <td className={`${!columnsNames[0].isVisible && "d-none"}`}>
                      <div className="flexBox gap-2 ">
                        <img
                          src={Images.orderProfile}
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="orderTrackTableTxt mb-1 text-start">
                          {
                            userDetails?.firstname+" "+userDetails?.lastname
                          }
                          </h3>
                          <div className="flexBox gap-2">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            <h6 className="locationText fontSize12 fw500 mb-0">
                         {
                          userDetails?.current_address?.street_address?.slice(0,10)
                         }
                            </h6>
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* work type */}
                    <td className={`${!columnsNames[1].isVisible && "d-none"}`}>
                      <h3 className="orderTrackTableTxt mb-1">{
                        
                      }</h3>
                      <p className="onlineBtn">{staff?.employment_type}</p>
                    </td>
                    {/* role */}
                    <td className={`${!columnsNames[2].isVisible && "d-none"} orderTrackTableTxt`} >
                      <h3 className="orderTrackTableTxt mb-1">{
                        userRoles?.[0]?.role?.name
                      }</h3>
                      <div className="orderTrackLocation m-auto">
                        <img
                          src={Images.locateOrder}
                          alt="img"
                          className="location"
                        />
                        <h6 className="locationText fontSize12 fw500 mb-0">
                          {
                            userDetails?.[0]?.role?.description
                          }
                        </h6>
                      </div>
                    </td>
                    {/* payroll */}
                    <td  className={`${!columnsNames[3].isVisible && "d-none"} orderTrackTableTxt`}>{
                      staff?.payment_cycle
                    }</td>
                    {/* pay frequency */}
                    <td className={`${!columnsNames[4].isVisible && "d-none"} orderTrackTableTxt`}>
                      <div className="activeStatusBtn">{
                        staff?.billing_type
                      }</div>
                    </td>
                      {/* leave takne */}
                      <td className={`${!columnsNames[5].isVisible && "d-none"} orderTrackTableTxt`}>
                      <div className="activeStatusBtn">{
                        staff?.leave||0
                      }</div>
                    </td>
                    {/* salary */}
                    <td className={`${!columnsNames[6].isVisible && "d-none"} orderTrackTableTxt`}>
                      <div className="activeStatusBtn">${
                        userSalary?.amount||0
                      }</div>
                    </td>
                    {/* tyips */}
                    <td className={`${!columnsNames[7].isVisible && "d-none"} orderTrackTableTxt`}>
                      <div className="activeStatusBtn">${
                        userSalary?.tips ||0
                      }</div>
                    </td>
                    {/* over time */}
                    <td className={`${!columnsNames[8].isVisible && "d-none"} orderTrackTableTxt`}>
                      <div className="activeStatusBtn">{
                        staff?.overtime_rate
                      }</div>
                    </td>
                    {/* total payable */}
                    <td className={`${!columnsNames[9].isVisible && "d-none"} orderTrackTableTxt`}>
                      <div className="activeStatusBtn">Active</div>
                    </td>
                    {/* status */}
                    <td className={`${!columnsNames[10].isVisible && "d-none"} orderTrackTableTxt`}>
                      <div className="activeStatusBtn">{`${staff?.status?'active':'not-active'}`}</div>
                    </td>
                    <td className="orderTrackTableTxt">
                      <Href to={`/employee-details/${staff?.user?.unique_uuid}`}>
                      <img src={Images.webPosArrow} alt="img" />
                      </Href>
                    </td>
                  </tr>
                  }):              <tr className="text-center border-none">
                  {/* Full-width spinner row */}
                  <td colSpan="100%">
                    <div className="d-flex">
                      <p className="text-center w-100">No Data Found</p>
                    </div>
                  </td>
                </tr>
                }
                  
                  
                </>
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    </>
  
};
export default PayrollTeamMembers;
