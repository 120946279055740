import React from "react";
import { getColorName } from "../../bookings/BookingCalendar/GlobalMethods"; 

export function VarintColorSize(attributes) {
  const parsedAttributes = attributes ? JSON.parse(attributes) : {};

  const keys = Object.keys(parsedAttributes);
  const color = parsedAttributes.Color;

  const renderItems = keys.map((key, index) => {
    if (key === "Color") return null;

    return (
      <span className="" key={key}>
        {parsedAttributes[key]}
        {index < keys.length - 1 ? "/" : ""}
      </span>
    );
  });

  const checkOtherKeys = (attributes) => {
    const keys = Object.keys(attributes);

    return keys.some((key) => key !== "Color");
  };
  return (
    <div
      style={{
        borderWidth: 1,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        gap:"3",
      }}
    >
      {color ? (
        <>
          <div
            style={{
              borderWidth: 1,
              width: 10,
              height: 10,
              borderRadius: 50,
              background: color?.toLowerCase(),
              marginLeft:"2px"
            }}
          ></div>
          <span className=" mb-0">
            {getColorName(color)}
          </span>
          {checkOtherKeys(parsedAttributes) && (
            <span className="txtDarkBlue">/</span>
          )}
        </>
      ) : null}
      {renderItems}
    </div>
  );
}

