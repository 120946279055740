import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";
import { getColorName } from "../../../../utilities/helpers";
import { toast } from "react-toastify";

const OfferDetails = (props) => {
  const {
    setSearchData,
    showProduct,
    productList,
    loading,
    selectedproduct,
    setSelectedproduct,
    setShowProduct,
    startDate,
    setEndDate,
    endDate,
    setStartDate,
    setSelectType,
    selectType,
    formattedVariants,
    setOfferPrices,
    offerPrices,
    selectedVariants,
    setSelectedVariants,
    OfferDetails,
    isProduct,
    allAddress,
    setPriceType,
    priceType,
  } = props;

  const priceLabels = {
    pos: "POS price",
    b2c: "B2C price",
    // b2b: "B2B price",
  };
  const dropdownRef = useRef(null);
  const [storeList, setStoreList] = useState({});
  const [variantDetails, setvariantDetails] = useState({});
  // Toggle selection of a single variant
  const toggleVariantSelection = (variantId) => {
    setSelectedVariants((prevSelected) =>
      prevSelected.includes(variantId)
        ? prevSelected.filter((id) => id !== variantId)
        : [...prevSelected, variantId]
    );
  };

  // Select/Deselect all variants
  const toggleSelectAllVariants = () => {
    if (selectedVariants.length === formattedVariants.length) {
      setSelectedVariants([]); // Unselect all
    } else {
      setSelectedVariants(
        formattedVariants.map((variant) => variant.supply_variant_id)
      ); // Select all
    }
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );

  const selectOption = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];

  const handleTypeChange = (selectedOption) => {
    setSelectType(selectedOption);
  };

  // Handle price change
  const handlePriceChange = (variantId, appName, value) => {
    console.log(value, "check it");
    if (selectType?.value === "percentage") {
      value = value?.replace(/\D/g, ""); // Ensures only whole numbers
      if (value >= 100) return;
    }
    setOfferPrices((prev) => ({
      ...prev,
      [variantId]: {
        ...prev?.[variantId],
        [appName]: value,
      },
    }));
  };

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowProduct(false); // Close dropdown if clicked outside
      }
    };

    if (showProduct) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProduct]);
  useEffect(() => {
    setSelectedproduct(productList);
    let ids = productList?.supplies?.map((supply) => supply?.seller_address_id);
    let storeData = {};
    allAddress?.map((address) => {
      let id = address?.id;
      let details = (address?.city || "-") + "," + (address?.state || "-");
      if (ids?.includes(id)) {
        storeData[id] = { id, address: details };
      }
    });
    setStoreList(storeData);
    if (OfferDetails) updateDetails(OfferDetails);
  }, [productList, OfferDetails]);
  const updateDetails = (data) => {
    setStartDate(new Date(data?.offer_start_from || new Date()));
    setEndDate(
      new Date(
        data?.offer_validity ||
          new Date(new Date().setDate(new Date().getDate() + 1))
      )
    );
    setSelectType({
      value: data?.price_flag,
      label: data?.price_flag === "amount" ? "Amount" : "Percentage",
    });
    // setSelectedVariants(data?.variant_ids);
    if (data?.supply_offers) {
      let variantsIds = [];
      let prices = {};
      const uniqueVariants = new Map();
      let offerId = data?.supply_offers?.[0]?.supply_id;
      console.log(offerId, "offerId");

      data?.supply_offers
        ?.filter((supply) => supply.supply_id == offerId)
        .map((supply) => {
          let variants = supply?.supply_variant;
          let id = variants?.id;
          variants?.supply_offers?.map((supply) => {
            if (!prices[id]) {
              prices[id] = {};
            }
            if (!prices[id][supply]) {
              prices[id][supply.app_name] = supply.offer_price;
            } else prices[id][supply.app_name] = supply.offer_price;
          });
          variantsIds.push(id);
        });
      setTimeout(() => {
        setOfferPrices(prices);
      }, 1500);
      setSelectedVariants(variantsIds);
    }
  };
  const handleSamePriceChange = (variantId, value) => {
    if (selectType?.value === "percentage") {
      value = value?.replace(/\D/g, ""); // Ensures only whole numbers
      if (value >= 100) return;
    } else {
    }
    setOfferPrices((prev) => ({
      ...prev,
      [variantId]: {
        ...prev?.[variantId],
        pos: value, // Apply to POS
        b2c: value, // Apply to B2C
      },
    }));
  };
  const selectPriceOption = [
    { value: "same", label: "Same Offer Price" },
    { value: "different", label: "Different Offer Price" },
  ];

  const handlePriceTypeChange = (selectedOption) => {
    // setOfferPrices({});
    setPriceType(selectedOption);
  };
  console.log(formattedVariants, "formattedVariants");
  return (
    <section className="createOfferPublish_Section firstCreateOffer">
      <h2 className="fontSize24 fw500 mb-3 txtBlue">Offer Details</h2>
      {selectedproduct && (
        <div className="table-responsive commonTable mt-3 productOfferTable">
          <table className="w-100">
            <tbody>
              <tr>
                <td>
                  <div className="align-items-center productAvailabilityChild">
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={selectedproduct?.image || Images.offericon_}
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="text-start fontSize12 fw500 mb-0 pb-1 txtDarkblue">
                          {selectedproduct.name}
                        </h3>

                        {isProduct && (
                          <div className="d-flex align-items-center gap-2">
                            <span className="skyblueDot"></span>
                            <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                              SKU{" "}
                              {
                                selectedproduct?.supplies?.[0]
                                  ?.supply_variants?.[0]?.sku
                              }
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="fontSize12 txtLightBlue">
                  <h4 className="m-0 fontSize12 txtDarkblue">Cost price</h4>
                  <h4 className="fontSize12 mb-0 mt-1 txtDarkblue">
                    {" "}
                    {"$"} {selectedproduct.price}
                  </h4>
                </td>
                <td>
                  {/* <img
                    src={Images.offerCross}
                    className="cursorPoint img-fluid"
                    alt="cross image"
                    onClick={() => {
                      setSelectedproduct("");
                    }}
                  /> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className={`pb-4 ${!selectedproduct ? "opacityBox" : ""}`}>
        <div className="mt-4 offerPeriodBox">
          <h5 className="fontSize18 fw500 mb-0 txtDarkblue">Period</h5>

          <div className="row">
            <div className="col-md-6">
              <div className="datePicker_Parent mt-4 pt-0">
                <label className="fontSize14 fw500 pb-2 ps-4 txtDarkblue">
                  Start from
                </label>
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => {
                    let startOfDay = new Date(date);
                    startOfDay.setHours(0, 0, 0, 0);
                    setStartDate(startOfDay);
                    if (endDate < date) {
                      let endOfDay = new Date(startOfDay);
                      endOfDay.setHours(23, 59, 59, 999);
                      setEndDate(endOfDay);
                    }
                  }}
                  minDate={new Date()} // Start Date should be today or later
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="mb-0 pressEnterTxt ps-4 pt-2">
                  Starts at 00:00 AM
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="datePicker_Parent mt-4 pt-0">
                <label className="fontSize14 fw500 pb-2 ps-4 txtDarkblue">
                  End on
                </label>
                <DatePicker
                  showIcon
                  selected={endDate}
                  onChange={(date) => {
                    let endOfDay = new Date(date);
                    endOfDay.setHours(23, 59, 59, 999);
                    setEndDate(endOfDay);
                  }}
                  minDate={startDate} // End Date cannot be before Start Date
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="mb-0 pressEnterTxt ps-4 pt-2">Ends at 11:59 PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 mt-4">
          <h5 className="fontSize18 fw500 mb-0 txtDarkblue">Offer Pricing</h5>
        </div>

        {formattedVariants?.length > 0 &&
        formattedVariants[0]?.attributes !== null &&
        Object.keys(formattedVariants[0]?.attributes).length > 0 ? (
          <>
            <div className="row">
              <div className="form-group col-md-6">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Offer price option
                  </label>
                  <ReactSelect
                    options={selectPriceOption}
                    value={priceType}
                    onChange={(selectedOption) =>
                      handlePriceTypeChange(selectedOption)
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    isSearchable={true}
                    placeholder="select the type"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img src={Images.blueDownArrow} alt="drop_icon" />
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Offer Type
                  </label>
                  <ReactSelect
                    options={selectOption}
                    value={selectType}
                    onChange={(selectedOption) =>
                      handleTypeChange(selectedOption)
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    isSearchable={true}
                    placeholder="select the type"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img src={Images.blueDownArrow} alt="drop_icon" />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="productVariantsTable table-responsive mt-2">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Attributes</th>
                    {priceType?.value === "same" ? (
                      <th>Offer price</th>
                    ) : (
                      <>
                        <th>POS price</th>
                        <th>Marketplace price</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(formattedVariants) &&
                  formattedVariants.length > 0 ? (
                    formattedVariants.map((variant, idx) => {
                      console.log(variant, "check ");
                      return (
                        <tr key={idx}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedVariants.includes(
                                variant.supply_variant_id
                              )}
                              onChange={() =>
                                toggleVariantSelection(
                                  variant.supply_variant_id
                                )
                              }
                            />
                          </td>
                          <td>
                            {variant.attributes &&
                            Object.keys(variant.attributes).length > 0 ? (
                              <div className="d-flex align-items-center">
                                {Object.entries(variant.attributes)
                                  .map(([key, val]) =>
                                    val.startsWith("#") &&
                                    /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(
                                      val
                                    )
                                      ? getColorName(val) // Convert hex to color name
                                      : val
                                  )
                                  .join(" / ")}
                              </div>
                            ) : (
                              <span className="defaultVariantText">
                                Default Variant
                              </span>
                            )}
                          </td>

                          {priceType?.value === "same" ? (
                            <>
                              <td>
                                <input
                                  className="productVariant_Input"
                                  type="number"
                                  value={
                                    offerPrices?.[variant.supply_variant_id]?.[
                                      "pos"
                                    ] || ""
                                  }
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    handleSamePriceChange(
                                      variant.supply_variant_id,
                                      value
                                    );
                                  }}
                                />
                              </td>
                            </>
                          ) : (
                            ["pos", "b2c"].map((appName) => (
                              <td key={appName}>
                                <input
                                  className="productVariant_Input"
                                  type="number"
                                  value={
                                    offerPrices?.[variant.supply_variant_id]?.[
                                      appName
                                    ] || ""
                                  }
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    handlePriceChange(
                                      variant.supply_variant_id,
                                      appName,
                                      value
                                    );
                                  }}
                                />
                              </td>
                            ))
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No Variants Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="mb-3">
              <div className="editVariant_SecondBox mt-4">
                <div className="upcBoxParent">
                  <div className="row">
                    <div className="col-4">
                      <div className="upcBox">
                        <h3 className="fontSize18 mb-0">Offer price option</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="upcBox upcRightBox">
                        <div className="reactSelectParent offerModalSelect">
                          <ReactSelect
                            options={selectPriceOption}
                            value={priceType}
                            onChange={(selectedOption) =>
                              handlePriceTypeChange(selectedOption)
                            }
                            placeholder="Select one"
                            classNamePrefix="react-select"
                            className="anlyticSelect react-select-container"
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#263682"
                                  : state.isFocused
                                  ? "#f5f6fc"
                                  : "#fff",
                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#636e9f",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0px",
                              }),
                            }}
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  src={Images.blueDownArrow}
                                  alt="drop_icon"
                                />
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upcBoxParent">
                  <div className="row">
                    <div className="col-4">
                      <div className="upcBox">
                        <h3 className="fontSize18 mb-0">Offer Type</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="upcBox upcRightBox">
                        <div className="reactSelectParent offerModalSelect">
                          <ReactSelect
                            options={selectOption}
                            value={selectType}
                            onChange={(selectedOption) =>
                              handleTypeChange(selectedOption)
                            }
                            placeholder="Select one"
                            classNamePrefix="react-select"
                            className="anlyticSelect react-select-container"
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#263682"
                                  : state.isFocused
                                  ? "#f5f6fc"
                                  : "#fff",
                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#636e9f",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0px",
                              }),
                            }}
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  src={Images.blueDownArrow}
                                  alt="drop_icon"
                                />
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {priceType?.value === "same" ? (
                  <div className="upcBoxParent">
                    <div className="row">
                      <div className="col-4">
                        <div className="upcBox">
                          <h3 className="fontSize18 mb-0">Offer price</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="upcBox upcRightBox inputUpcBox">
                          <div className="inputGroup">
                            <input
                              className="productVariant_Input"
                              name="upc"
                              type="number"
                              maxLength={14}
                              value={
                                offerPrices[
                                  formattedVariants[0]?.supply_variant_id
                                ]?.pos || ""
                              }
                              onChange={(e) =>
                                handleSamePriceChange(
                                  formattedVariants[0]?.supply_variant_id,
                                  e.target.value
                                )
                              }
                            />
                            {selectType?.value === "amount" ? (
                              <span className="inputDollarSign">$</span>
                            ) : (
                              <span className="inputDollarSign usdTxt">%</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* POS Price Section */}
                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0">POS price</h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox inputUpcBox">
                            <div className="inputGroup">
                              <input
                                className="productVariant_Input"
                                name="upc"
                                type="number"
                                maxLength={14}
                                value={
                                  offerPrices[
                                    formattedVariants[0]?.supply_variant_id
                                  ]?.pos || ""
                                }
                                onChange={(e) =>
                                  handlePriceChange(
                                    formattedVariants[0]?.supply_variant_id,
                                    "pos",
                                    e.target.value
                                  )
                                }
                              />
                              {selectType?.value === "amount" ? (
                                <span className="inputDollarSign">$</span>
                              ) : (
                                <span className="inputDollarSign usdTxt">
                                  %
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* B2C Price Section */}
                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0">
                              Marketplace Price
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox inputUpcBox">
                            <div className="inputGroup">
                              <input
                                className="productVariant_Input"
                                name="upc"
                                type="number"
                                maxLength={14}
                                value={
                                  offerPrices[
                                    formattedVariants[0]?.supply_variant_id
                                  ]?.b2c || ""
                                }
                                onChange={(e) =>
                                  handlePriceChange(
                                    formattedVariants[0]?.supply_variant_id,
                                    "b2c",
                                    e.target.value
                                  )
                                }
                              />
                              {selectType?.value === "amount" ? (
                                <span className="inputDollarSign">$</span>
                              ) : (
                                <span className="inputDollarSign usdTxt">
                                  %
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default OfferDetails;
