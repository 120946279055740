
import { combineReducers } from "redux";
import authReducer from "../../src/app/containers/auth/authSlice"
import dashboardReducer from "../../src/app/containers/dashboard/dashboardSlice"
import productReducer from "../../src/app/containers/myProducts/productSlice"
import returnReducer from "../../src/app/containers/myOrders/returnOrder/returnOrderSlice"
import bookingReducer from "../../src/app/containers/bookings/bookingSlice.js";
import deliveryReducer from "../../src/app/containers/bookings/delivery.slice.js"
import walletReducer from "./containers/walletMain/wallet.slice.js";
import sessionData from "./containers/myPos/myPosSlice"
import marketingData from "./containers/marketing/marketingSlice"
import PagesColumnSlice  from "./pagesColumnReducer.js";


const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  auth: authReducer,
  dashboard: dashboardReducer,
  products: productReducer,
  return: returnReducer,
  bookings:bookingReducer,
  delivery:deliveryReducer,
  wallet: walletReducer,
  getSession: sessionData,
  marketing: marketingData,
  pagesColumn:PagesColumnSlice
});

export default rootReducer;