import React, { useEffect } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
} from "chart.js";

const ChartArea = (props) => {
  // console.log(props.data)
  const push = props?.push;
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    {
      id: "customSpacingLegend",
      beforeInit(chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in another answers
          this.height += 20;
        };
      },
    }
  );
  
  const scales = {
    y: {
      title: {
        display: true,
        text: "Customer Numbers",
        color: "red", 
        fontFamily: 'roobert_trialregular',
      },
      border: {
        dash: [2, 2],
        display: false,
        color: "rgba(180, 190, 235, 1)",
      },
      beginAtZero: true,
      ticks: {
        color: "red",
        fontFamily: 'roobert_trialregular',
      },
    },
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: "red",
        fontFamily: 'roobert_trialregular',
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales,
  };
useEffect(()=>{
  // console.log("first")
},[props.data])
  return (
    <div className={props.classes} onClick={push} style={{ cursor: "pointer" }}>
      <div className={props?.dataId === "1" ? "chartsOuter" : "graphContainer"}>
        {props.chartType === "Line" && (
          <Line options={{ ...options, ...props.options }} data={props.data} />
        )}
        {props.chartType === "Bar" && (
          <Bar options={props.options} data={props.data} />
        )}
        {props.chartType === "Doughnut" && (
          <Doughnut options={props.options} data={props.data} />
        )}
      </div>
    </div>
  );
};

export default ChartArea;
