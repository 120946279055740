import moment from "moment-timezone";
import ReactAudioPlayer from "react-audio-player";
import { handleCatalogSize } from "../../../constants/messageBox/inbox.constant";
import * as Images from "../../../../utilities/images";
import { Dropdown } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
export const OfferCard = ({ offer_details, userId, chat, deleteChat }) => {
  console.log(chat, userId);
  return (
    <div
      className={`userChatMess position-relative ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
      <div className="chatOrderBox p-0">
        <div className="chatOrderInner">
          <img
            src={offer_details?.productImg || Images.chatOrderImg}
            alt="img"
            className="chatOrderImg"
          />
          <div className="chatOrderDetails">
            <h3 className="fontSize16 fw700 txtDarkblue">
              {offer_details?.name}
            </h3>
            <p className="chatOrderDesc pb-2">
              {/* Comfortable Athletic Women Sneaker Shoes,New
          Style Fashion Sport Shoes */}
            </p>
            <h5 className="fontSize16 fw700 staffPositionTxt">
              MOQ: {offer_details?.quantity || 1}
            </h5>
            <h5 className="fontSize16 fw700 txtDarkblue mb-0">
              ${offer_details?.offer_price}
              <span className="fw400 perPeiceTxt">product</span>
            </h5>
          </div>
        </div>
        <div className="text-end mt-3 mb-3">
          <h3 className="fontSize10 fw500 txtDarkblue mb-1">
            {moment(offer_details?.offer_validity || new Date()).format(
              "DD.MM.YYYY"
            )}
          </h3>
          <img
            src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
            alt="img"
          />
        </div>
        <h3 className="chatAddCart chatProductOrdered fontSize12 fw500 mb-0">
          Added to cart
        </h3>
      </div>
      <div className="options_menu d-none">
        <Dropdown>
          <Dropdown.Toggle variant="success" className="variantsTable_Dropdown">
            <img
              src={Images.LightMoreVerticalImg}
              alt="logo"
              width={14}
              height={14}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                deleteChat();
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">Delete</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export const ShowImages = ({ images, userId, chat, deleteChat }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`align-items-end flex-column justify-content-end position-relative userChatMess ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
     <div className="d-flex">
     {images?.map((media, index) => (
        <div key={index}>
          <img
            className="img-fluid"
            src={media?.filePath}
            alt="img"
            style={{ maxWidth: "150px" }}
          />
        </div>
      ))}
      {chat.sender_id == userId&&<div className="options_menu  d-none">
        <Dropdown>
          <Dropdown.Toggle variant="success" className="variantsTable_Dropdown">
            <img src={Images.LightMoreVerticalImg} alt="logo" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                deleteChat();
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">{t('delete')}</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>}
     </div>
      <div className="flexBox justify-content-end gap-2">
          <h3 className="fontSize10 fw500 txtDarkblue mb-0">
            {moment(chat?.updated_at).format("HH:mm")}
          </h3>
          <img
            src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
            alt="img"
          />
        </div>
    </div>
  );
};
export const ShowAudio = ({ url, userId, chat, deleteChat,chatRef }) => {
  const { t } = useTranslation();
  const audioRef = useRef(null);

  

  // Handle play and pause events
  const handlePlay = () => {
    let audio=chatRef.current;
      if (audio && audio !== audioRef.current) {
        audio.pause();
      }
    
    if (audioRef.current) {
      chatRef.current = audioRef.current; 
    }
  };


  return (
    <div
      className={`align-items-end flex-column justify-content-end position-relative userChatMess ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
      <div className="d-flex">
      <audio 
      ref={audioRef}
      src={url}
      autoPlay={false}
      controls={true}
      onEnded={() => {
        chatRef.current = null; 
      }}
      controlsList="nodownload" 
      onPlay={handlePlay} // Detect when audio starts playing
       />
    { chat.sender_id == userId&& <div className="options_menu d-none">
        <Dropdown>
          <Dropdown.Toggle variant="success" className="variantsTable_Dropdown">
            <img src={Images.LightMoreVerticalImg} alt="logo" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                deleteChat();
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">{t('delete')}</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>}
      </div>
      <div className="flexBox justify-content-end gap-2">
          <h3 className="fontSize10 fw500 txtDarkblue mb-0">
            {moment(chat?.updated_at).format("HH:mm")}
          </h3>
          <img
            src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
            alt="img"
          />
        </div>
    </div>
  );
};
export const ShowCatalogs = ({ file, userId, chat, deleteChat }) => {
  return (
    <div
      className={`userChatMess position-relative ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
      <div>
        <div className="shippingBoxes active mb-2">
          <figure className="shippingImgParent p-0">
            <img
              src={file?.url || Images.catalogModalImg}
              alt="img"
              className="h-100 w-100"
            />
          </figure>
          <div>
            <h3 className="fontSize18  txtDarkblue">{file?.caption}</h3>
            <h5 className="fontSize14 txtLightBlue mb-1">
              {file?.mime_type} | {handleCatalogSize(file?.size || 10000)}
            </h5>
            <h5 className="fontSize14 txtLightBlue flexBox gap-1 mb-0">
              <img src={Images.catalogCheck} alt="img" />
              {moment(file?.updated_at).format("YYYY-MM-DD")}
            </h5>
          </div>
        </div>
        <div className="flexBox justify-content-end gap-2">
          <h3 className="fontSize10 fw500 txtDarkblue mb-0">
            {moment(chat?.updated_at).format("HH:mm")}
          </h3>
          <img
            src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
            alt="img"
          />
        </div>
      </div>
      <div className="options_menu  d-none">
        <Dropdown>
          <Dropdown.Toggle variant="success" className="variantsTable_Dropdown">
            <img src={Images.LightMoreVerticalImg} alt="logo" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                deleteChat();
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">Delete</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export const ShowBusinessCard = ({
  business_card,
  userId,
  chat,
  deleteChat,
}) => {
  return (
    <div
      className={`userChatMess position-relative ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
      <div>
        <div className="businessCardBox active mb-2">
          <div className="flexBox gap-3">
            <img
              src={Images.bankslogo1}
              alt="img"
              className="businessCardBoxImg"
            />
            <h3 className="fontSize18 fw500 txtDarkblue">
              {business_card?.company_name}
            </h3>
          </div>
          <div className="container-fluid p-0 mt-3">
            <div className="row">
              <div className="col-5">
                <h3 className="fontSize16 fw500 businessUserName">
                  {business_card?.firstName + " " + business_card?.lastName}
                </h3>
                <h3 className="fontSize14 fw500 txtLightBlue">
                  {business_card?.email}
                </h3>
                <h3 className="fontSize10  txtLightBlue mb-0">Apparel Unit</h3>
              </div>
              <div className="col-7">
                <h3 className="fontSize14 txtLightBlue flexBox gap-1">
                  <img src={Images.phoneLogoBlue} alt="img" />
                  {business_card?.mobile}
                </h3>
                <h3 className="fontSize14 txtLightBlue  flexBox gap-1">
                  <img src={Images.emailLogoBlue} alt="img" />
                  {business_card?.email}
                </h3>
                <h3 className="fontSize14 txtLightBlue  flexBox gap-1 mb-0">
                  <img src={Images.locationLogoBlue} alt="img" />
                  4554 Adamsville Road, TX.
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="flexBox justify-content-end gap-2">
          <h3 className="fontSize10 fw500 txtDarkblue mb-0">7:55 PM</h3>
          <img
            src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
            alt="img"
          />
        </div>
      </div>
      <div className="options_menu d-none">
        <Dropdown>
          <Dropdown.Toggle variant="success" className="variantsTable_Dropdown">
            <img
              src={Images.LightMoreVerticalImg}
              alt="logo"
              width={14}
              height={14}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                deleteChat();
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">Delete</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export const ShowAddress = ({ shipping_address, userId, chat, deleteChat }) => {
  return (
    <div
      className={`userChatMess position-relative ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
      <div>
        <div className="shippingBoxes active mb-2">
          <figure className="shippingImgParent">
            <img src={Images.storeImg} alt="img" width={24} height={24} />
          </figure>
          <div>
            <h3 className="fontSize18 fw500 txtDarkblue">
              {shipping_address?.company_name}
            </h3>
            <p className="fontSize14 txtBlack">
              {shipping_address?.full_address}
            </p>
          </div>
        </div>
        <div className="flexBox justify-content-end gap-2">
          <h3 className="fontSize10 fw500 txtDarkblue mb-0">7:55 PM</h3>
          <img
            src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
            alt="img"
          />
        </div>
      </div>
      <div className="options_menu d-none">
        <Dropdown>
          <Dropdown.Toggle variant="success" className="variantsTable_Dropdown">
            <img
              src={Images.LightMoreVerticalImg}
              alt="logo"
              width={14}
              height={14}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                deleteChat();
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">Delete</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export const ShowChat = ({ chat, userId, deleteChat }) => {
  return (
    <div
      className={`userChatMess position-relative ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
      <p>{chat?.content}</p>
      <div className="text-end">
        <div className="flexBox gap-2 mb-1">
          <h3 className="fontSize10 fw500 txtDarkblue text-nowrap mb-0">
            {moment(chat?.updatedAt).format("hh:mm A")}
          </h3>
          {/* <div className="chatMessDropdown"> */}
          {/* <Dropdown className="flexBox">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="variantsTable_Dropdown chatMessDropdownBtn"
          >
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-1"
              
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.darkblueEditIcon} alt="logo" />
                <h3 className="fontSize14 mb-0">
                  Edit
                </h3>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                // handleOpenModal("deleteCustomerModal");
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">Delete</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
          {/* </div> */}
        </div>
        <img
          src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
          alt="img"
        />
      </div>
    
    </div>
  );
};
export const ShowChat2 = ({ chat, userId, deleteChat }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`userChatMess position-relative ${
        !chat?.is_seen && chat.sender_id !== userId ? "unread" : ""
      }`}
    >
      <p>{chat?.content}</p>
      <div className="text-end">
        <h3 className="fontSize10 fw500 txtDarkblue text-nowrap">
          {moment(chat?.updatedAt).format("hh:mm A")}
        </h3>
        <img
          src={chat?.is_seen ? Images.readMessCheck : Images.unreadMessCheck}
          alt="img"
        />
      </div>
      <div className="options_menu d-none">
        <Dropdown className="d-flex">
          <Dropdown.Toggle variant="success" className="variantsTable_Dropdown">
            <img
              src={Images.LightMoreVerticalImg}
              alt="logo"
              width={14}
              height={14}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
            <Dropdown.Item
              href="#/action-2"
              onClick={() => {
                deleteChat();
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <img src={Images.blueDeleteLogo} alt="logo" />
                <h3 className="fontSize14 mb-0">{t('delete')}</h3>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
