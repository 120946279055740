import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { getColorName } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";

const LocationAndStockModal = ({
  close,
  allAddress,
  setSellerAddressIds,
  sellerAddressIds,
  productId,
  variantIds,
  addProduct,
  filteredVariants,
}) => {
  const { t } = useTranslation();
  const toastId = useRef(null);
  const history = useHistory();

  const [inventoryPointCheck, setInventoryPointCheck] = useState(false);
  const [reorderPoints, setReorderPoints] = useState({});
  const [sellerStock, setSellerStock] = useState({});

  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleSaleToggle = (type) => (event) => {
    if (type === "inventoryCheck") {
      setInventoryPointCheck(event.target.checked);
    }
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;
    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        return [...prevIds, storeId];
      } else {
        setSellerStock((prevStock) => {
          const updatedStock = { ...prevStock };
          delete updatedStock[storeId];
          return updatedStock;
        });
        setReorderPoints((prevPoints) => {
          const updatedPoints = { ...prevPoints };
          delete updatedPoints[storeId];
          return updatedPoints;
        });
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleStockChange = (storeId, variantId) => (event) => {
    const value = event.target.value;
    setSellerStock((prevStock) => ({
      ...prevStock,
      [storeId]: { ...prevStock[storeId], [variantId]: value },
    }));
  };

  const handleReorderPointChange = (storeId, variantId) => (event) => {
    const value = event.target.value;
    setReorderPoints((prevPoints) => ({
      ...prevPoints,
      [storeId]: { ...prevPoints[storeId], [variantId]: value },
    }));
  };

  const validateAndSave = () => {
    if (sellerAddressIds.length === 0) {
      showToast("Please select at least one seller.");
      return;
    }

    for (const storeId of sellerAddressIds) {
      if (!sellerStock[storeId]) {
        showToast("Stock quantity is required.");
        return;
      }

      for (const variantId of variantIds) {
        const attributes = getAttributesById(variantId) || {}; // Ensure it's an object
        const hasAttributes = Object.keys(attributes).length > 0;

        const formattedAttributes = hasAttributes
          ? Object.entries(attributes)
              .map(([key, val]) =>
                typeof val === "string" &&
                val.startsWith("#") &&
                /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(val)
                  ? getColorName(val) // Convert hex color to name
                  : val
              )
              .join(" / ")
          : null; // No attributes case

        const stock = sellerStock[storeId]?.[variantId];
        const reorderPoint = reorderPoints[storeId]?.[variantId];

        if (!stock || Number(stock) <= 0) {
          showToast(
            formattedAttributes
              ? `Stock quantity is required for ${formattedAttributes}.`
              : `Stock quantity is required for the selected product.`
          );
          return;
        }

        if (!reorderPoint || Number(reorderPoint) <= 0) {
          showToast(
            formattedAttributes
              ? `Reorder point is mandatory for ${formattedAttributes}.`
              : `Reorder point is mandatory for the selected product.`
          );
          return;
        }

        if (Number(stock) < Number(reorderPoint)) {
          showToast(
            formattedAttributes
              ? `Stock quantity must be greater than or equal to the reorder point for ${formattedAttributes}.`
              : `Stock quantity must be greater than or equal to the reorder point for the selected product.`
          );
          return;
        }
      }
    }

    const formattedProductData = {
      products: [
        ...addProduct,
        {
          id: productId,
          seller_addresses: sellerAddressIds.map((storeId) => ({
            id: storeId,
            variants: variantIds.map((variantId) => ({
              id: variantId,
              qty: sellerStock[storeId]?.[variantId] || "0",
              quantity_reorder_point:
                reorderPoints[storeId]?.[variantId] || "0",
            })),
          })),
        },
      ],
    };

    console.log(formattedProductData, "sellerAddressIds");

    close();
    history.push({ pathname: `/products`, state: formattedProductData });
  };

  const getAttributesById = (variantId) => {
    const variant = filteredVariants.find(
      (variant) => variant.id === variantId
    );
    return variant ? JSON.parse(variant.attributes) : null;
  };

  return (
    <>
      <h3 className="fontSize24 fw500 txtDarkblue text-center">
        {t("locationStock")}
      </h3>
      <div className="modalOptionBox">
        {allAddress.map((store ,idx) => (
          <div key={store.id} className="mb-4">
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0 gap-0">
                <input
                  type="checkbox"
                  id={`store-${store.id}`}
                  checked={sellerAddressIds.includes(store.id)}
                  onChange={handleStoreToggle(store.id)}
                />
                <label htmlFor={`store-${store.id}`}></label>
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  {/* {store.street_address} */}
                  Store {idx+1}
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  {store.format_address}
                </h3>
              </div>
            </div>

            {sellerAddressIds.includes(store.id) && (
              <div className="editVariant_SecondBox mt-3">
                {variantIds.map((variantId) => {
                  const attributes = getAttributesById(variantId);
                  return (
                    <div key={variantId} className="upcBoxParent">
                      <div className="row">
                        {attributes ? (
                          <>
                            <div className="col-3 pe-0">
                              <div className="upcBox">
                                <h3 className="fontSize16 mb-0">
                                  {attributes
                                    ? Object.values(attributes)
                                        .map((val) =>
                                          val.startsWith("#") &&
                                          /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(
                                            val
                                          )
                                            ? getColorName(val) // Convert color hex codes to names
                                            : val
                                        )
                                        .join(" / ")
                                    : ""}
                                </h3>
                              </div>
                            </div>

                            <div className="col-4 pe-0">
                              <div className="upcBox upcRightBox">
                                <input
                                  type="number"
                                  className="upcBox_Input p-0"
                                  placeholder={t("addStock")}
                                  value={
                                    sellerStock[store.id]?.[variantId] || ""
                                  }
                                  onChange={handleStockChange(
                                    store.id,
                                    variantId
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-5 ps-0">
                              <div className="upcBox upcRightBox">
                                <div className="inputGroup">
                                  <input
                                    type="number"
                                    className="upcBox_Input p-0"
                                    placeholder={t("inverntoryReorderPoint")}
                                    value={
                                      reorderPoints[store.id]?.[variantId] || ""
                                    }
                                    onChange={handleReorderPointChange(
                                      store.id,
                                      variantId
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-6 pe-0">
                              <div className="upcBox">
                                <input
                                  type="number"
                                  className="upcBox_Input p-0"
                                  placeholder={t("addStock")}
                                  value={
                                    sellerStock[store.id]?.[variantId] || ""
                                  }
                                  onChange={handleStockChange(
                                    store.id,
                                    variantId
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-6 ps-0">
                              <div className="upcBox upcRightBox">
                                <div className="inputGroup">
                                  <input
                                    type="number"
                                    className="upcBox_Input p-0"
                                    placeholder={t("inverntoryReorderPoint")}
                                    value={
                                      reorderPoints[store.id]?.[variantId] || ""
                                    }
                                    onChange={handleReorderPointChange(
                                      store.id,
                                      variantId
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button className="modalDiscardBtn w-100" onClick={close}>
          {t('discard')}
        </button>
        <button
          className="modalNextBtn modalDiscardBtn w-100 active"
          onClick={validateAndSave}
        >
          {t('save')}
        </button>
      </div>
    </>
  );
};

export default LocationAndStockModal;
