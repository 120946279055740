import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth, updateAgeVerificationAsync } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { crossBorderIcon, upArrow } from '../../../../utilities/images';
import { useTranslation } from 'react-i18next';

const AgeVerificationModal = (props) => {
    const { t } = useTranslation();
    const identity_urls = props?.userData?.user_profiles?.identity_urls;
    const documents_type = props?.userData?.user_profiles?.identity_type;
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const onVerify = () => {
        const data = {
            tokenData: auth?.payload?.token,
            postData: {
                is_age_verified: true,
                id: props?.userData?.id
            }
        }
        dispatch(updateAgeVerificationAsync(data))
            .then(unwrapResult)
            .then(res => {
                toast.success("Age Verification Completed")
                props?.close()
            }).catch(
                e => {
                    console.log(e)
                }
            )
    }
    return (
        <div className="authFormContainer">
            <img
                src={crossBorderIcon}
                alt='cross modal'
                style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    position: "absolute",
                    right: "1%",
                    top: '1%'
                }}
                onClick={props?.close}
            />
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px"
            }}>
                <div className="authFormHead">
                    <h1 className="fontSize24 txtDarkblue fw500 mb-0 text-center">
                        {t("userAgeVerification")}
                    </h1>
                </div>

            </div>
            <div>
                <div className="image-container">
                    <div

                        style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "20px",
                            flexWrap: "wrap",
                        }}
                    >
                        {identity_urls?.length > 0 &&
                            identity_urls?.map((urls, key) => (

                                <img
                                    key={key}
                                    className="mb-4"
                                    width={400}
                                    height={300}
                                    src={urls?.document}
                                    alt="document"
                                    style={{
                                        borderRadius: "12px",
                                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                        transition: "transform 0.3s ease",
                                    }}
                                    onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
                                    onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                                />

                            ))}
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        marginTop: "20px",
                    }}
                >
                    {identity_urls?.length > 0 ? (
                        <>
                            <button
                                className="loginBtnSign mt-4"
                                type="submit"
                                onClick={onVerify}
                                disabled={loading}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                {t("verifyDoc")}{" "}
                                <img src={upArrow} alt="logo" className="ms-2" />
                            </button>
                            <button
                                className="loginBtnSign mt-4"
                                type="submit"
                                disabled={loading}
                                onClick={props?.close}
                                style={{
                                    background: "red"
                                }}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                {t("discard")}{" "}
                            </button>
                        </>
                    ) : (
                        <p>{t("noVerificationDoc")}</p>
                    )}

                </div>
            </div>
        </div>
    )
}

export default AgeVerificationModal
