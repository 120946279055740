import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { imageUploadAsync } from '../message/messageSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDropzone } from 'react-dropzone';
import * as Images from "../../../utilities/images";
import { selectLoginAuth } from '../auth/authSlice';
import { useTranslation } from 'react-i18next';

const UploadImage = (props) => {
  const { t } = useTranslation();
    const { file, setFile } = props; // Props destructuring
    const toastId = useRef(null);
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);
  
    const showToast = (msg) => {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(msg);
      }
    };
  
    const onDrop = useCallback(
      async (acceptedFiles) => {
        const validTypes = ["image/jpeg", "image/png"];
        const maxSize = 5 * 1024 * 1024; // 5MB max size
  
        const file = acceptedFiles[0];
        if (!validTypes.includes(file.type)) {
          showToast("Invalid file type. Only JPEG and PNG are allowed.");
          return;
        }
  
        if (file.size > maxSize) {
          showToast("File size exceeds the 5MB limit.");
          return;
        }
  
        const formData = new FormData();
        formData.append("file", file);
        const params = {
          file: formData,
          token: auth?.payload?.token,
        };
        setLoading(true);
        dispatch(imageUploadAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setFile(obj?.payload[0]?.filePath); // Update the single image
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      },
      [dispatch, setFile, auth]
    );
  
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: "image/jpeg, image/png", // Restrict file types
      multiple: false, // Allow only one file
    });
  
    const deleteImage = () => {
      setFile(""); // Clear the uploaded image
      toast.success("Image removed successfully.");
    };
  return (
    <>
      <div className="showToken_Img chartUploadSection">
        {file ? (
          // Show uploaded image with delete option
          <div className="uploadedImgContainer position-relative">
            <img
              className="uploadedChart"
              src={file}
              alt="Uploaded file"
            />
            <button className="optionDelete" onClick={deleteImage}>
              <img
                src={Images.Delete_Outline}
                alt="Delete image"
                className="img-fluid"
              />
            </button>
          </div>
        ) : (
          // Show placeholder with camera logo
          <div {...getRootProps({ className: "" })}>
            <input
              name="image"
              type="file"
              {...getInputProps()}
              accept="image/jpeg, image/png" // Restrict file types
            />
            <div className="uploadImg_Parent">
              <div className="d-flex align-items-center">
                {loading ? (
                  <div className="spinner-container">
                    <span className="spinner-border spinner-border-md spinnerDark"></span>
                  </div>
                ) : (
                  <>
                    <img
                      src={Images.CameraLogo}
                      alt="Camera"
                      style={{ cursor: "pointer" }}
                    />
                    <p className="dragAndDrop_Txt ms-2">
                      {t("uploadFile")} <br /> {t("dragFile")}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="supportImgTxt">{t("supportFormats")}: png, jpg</p>
        <p className="supportImgTxt">{t("maxImageSize")}: 5 MB</p>
      </div>
    </>
  )
}

export default UploadImage
