import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { profileImg1 } from "../../../utilities/images";
import ProductDetails from "./ProductDetails";
import ProductImageAdd from "./productImageAdd";
import AddServiceHeader from "./AddServiceHeader";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAddressApiAsync,
  getPosUsersAsync,
  selectLoginAuth,
} from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formValidator } from "./formValidator";
import {
  createProductApiAsync,
  getUserSettingsApiAsync,
} from "../dashboard/dashboardSlice";
import CustomModal from "../../components/shared/CustomModal";
import AddStaffModal from "./AddStaffModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import AssignedTeamMemberModal from "./AssignedTeamMemberModal";
import AddServiceVariationModal from "./AddServiceVariationModal";
import ChartImageUploader from "./chartImageUploader";
import ConfirmationModal from "../../components/UI/Product/confirmationModal";
import CreateCouponModal from "./createCouponModal";
import { useTranslation } from "react-i18next";

const AddService = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userData = location?.state;
  const [storeCheck, setStoreCheck] = useState(false);
  const [marketCheck, setMarketCheck] = useState(false);
  const [userSettingData, setUserSettingData] = useState("");
  console.log(userSettingData, "userSettingData");
  const history = useHistory();
  const [maximumValue, setMaximumValue] = useState(
    userData?.supplies[0]?.max_capacity
      ? userData?.supplies[0]?.max_capacity
      : "Max"
  );
  const [minimumValue, setMinimumValue] = useState(
    userData?.supplies[0]?.min_capacity
      ? userData?.supplies[0]?.min_capacity
      : "Min"
  );
  const [productDetails, setProductDetails] = useState({
    productName: userData?.name,
    service_id: userData?.service_id,
    category_id: userData?.category_id,
    sub_category_id: userData?.sub_category_id,
  });
  const [posList, setPosList] = useState([]);
  const [costPrice, setcostPrice] = useState(userData?.price ?? "");
  const [productDescription, setProductDescription] = useState("");
  const [imageDetails, setImageDetails] = useState({});
  const [chartImage, setChartImage] = useState("");
  const [couponData, setCouponData] = useState({});

  const [prices, setPrices] = useState([
    {
      app_name: "pos",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
    {
      app_name: "b2c",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
  ]);
  const [activePos, setActivePos] = useState(
    userData?.supplies[0]?.supply_prices?.find(
      (item) => item.app_name === "pos"
    )
      ? true
      : false
  );
  const [activeOnlineStore, setActiveOnlineStore] = useState(
    userData?.supplies[0]?.supply_prices?.find(
      (item) => item.app_name === "b2c" || item.app_name === "b2b"
    )
      ? true
      : false
  );
  const [activeBtoc, setActiveBtoc] = useState(
    userData?.supplies[0]?.supply_prices?.find(
      (item) => item.app_name === "b2c"
    )
      ? true
      : false
  );
  const [activeBtob, setActiveBtob] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saleSame, setSaleSame] = useState(false);
  const [differentSale, setDifferentSale] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(["reservation"]);
  const [type, setType] = useState("service");
  const [posUserId, setPosUserId] = useState([]);
  const [skipPosDetails, setSkipPosDetails] = useState(false);
  const [syncGoogleCalendar, setSyncGoogleCalendar] = useState(false);
  const [selectedPosId, setSelectedPosId] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);

  const [posPrice, setPosPrice] = useState(null);
  const [marketplacePrice, setMarketplacePrice] = useState(null);
  const [posProfit, setPosProfit] = useState(null);
  const [posMargin, setPosMargin] = useState(null);
  const [marketplaceProfit, setMarketplaceProfit] = useState(null);
  const [marketplaceMargin, setMarketplaceMargin] = useState(null);
  const [variants, setVariants] = useState([]);
  console.log(variants, "variants");
  const [isTax, setIsTax] = useState(false);
  const [cancelFee, setCancelFee] = useState("");
  const [discountOption, setDiscountOption] = useState("amount");
  const [discountValue, setDiscountValue] = useState("");
  const [salePrice, setSalePrice] = useState("");

  const [offer, setOffer] = useState({
    price_flag: discountOption,
    actual_price: posPrice,
    offer_price: discountValue,
  });

  const handleDiscountOption = (options) => {
    setDiscountOption(options);
  };
  const handleDiscountValue = (e) => {
    setDiscountValue(e.target.value);
  };

  // Calculate Sale Price & Update Offer State
  useEffect(() => {
    if (!posPrice || !discountValue) {
      setSalePrice(posPrice);
      setOffer((prev) => ({
        ...prev,
        price_flag: discountOption,
        actual_price: posPrice,
        offer_price: discountValue, // Keep showing entered discount value
      }));
      return;
    }

    let calculatedSalePrice = 0;

    if (discountOption === "amount") {
      calculatedSalePrice = Math.max(0, posPrice - discountValue);
    } else if (discountOption === "percentage") {
      const discountAmount = (posPrice * parseFloat(discountValue)) / 100;
      calculatedSalePrice = Math.max(0, posPrice - discountAmount);
    }

    setSalePrice(calculatedSalePrice.toFixed(2));
    setOffer({
      price_flag: discountOption,
      actual_price: posPrice,
      offer_price: discountValue, // Keep showing the input value
    });
  }, [posPrice, discountValue, discountOption]);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  // Handle changes for POS and Marketplace Sale Prices
  const handlePriceChange = (e, platform) => {
    const newPrice = parseFloat(e.target.value);

    if (newPrice === null || newPrice < 0 || newPrice === "" || !newPrice) {
      resetPlatformPriceAndProfit(platform);
      return;
    }

    if (platform === "pos") {
      setPosPrice(newPrice);
      calculatePosProfitAndMargin(newPrice, costPrice);

      if (!differentSale) {
        setMarketplacePrice(newPrice);
        calculateMarketplaceProfitAndMargin(newPrice, costPrice);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      calculateMarketplaceProfitAndMargin(newPrice, costPrice);
    }

    updatePriceArray(platform, newPrice);
  };

  const calculatePosProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setPosProfit(profit.toFixed(2)); // Update profit
      setPosMargin(margin.toFixed(2)); // Update margin
    } else {
      setPosProfit(null); // Reset profit if invalid
      setPosMargin(null); // Reset margin if invalid
    }
  };

  const calculateMarketplaceProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setMarketplaceProfit(profit.toFixed(2)); // Update profit
      setMarketplaceMargin(margin.toFixed(2)); // Update margin
    } else {
      setMarketplaceProfit(null); // Reset profit if invalid
      setMarketplaceMargin(null); // Reset margin if invalid
    }
  };

  const calculateMargin = (price) => {
    let margin = 0; // Default value for margin
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      margin = (profit / costPrice) * 100; // Formula to calculate margin
    }
    return margin; // Return the calculated margin
  };

  const resetPlatformPriceAndProfit = (platform) => {
    if (platform === "pos") {
      setPosPrice(null);
      setPosProfit(null);
      setPosMargin(null);
      updatePriceArray("pos", null);

      if (!differentSale) {
        setMarketplacePrice(null);
        setMarketplaceProfit(null);
        setMarketplaceMargin(null);
        updatePriceArray("b2c", null);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(null);
      setMarketplaceProfit(null);
      setMarketplaceMargin(null);
      updatePriceArray("b2c", null);
    }
  };

  const updatePriceArray = (platform, newPrice) => {
    setPrices((prevPrices) => {
      return prevPrices.map((price) => {
        if (price.app_name === platform) {
          if (!newPrice) {
            // Only reset selling_price and margin_percentage when the price is null or empty
            return {
              ...price,
              selling_price: "",
              margin_percentage: "",
            };
          } else {
            // Update selling_price and margin_percentage for the given platform
            const margin = calculateMargin(newPrice);
            return {
              ...price,
              selling_price: newPrice.toString(),
              margin_percentage: margin.toFixed(2),
            };
          }
        }
        return price; // Keep other platforms as is
      });
    });
  };

  const getIdsHandle = (data) => {
    setSelectedPosId(data);
    handleOnCloseModal();
  };

  const handleSkipPOSDetails = (e) => {
    setSkipPosDetails(e.target.checked);
  };
  const handleSyncGoogleCalendar = (e) => {
    setSyncGoogleCalendar(e.target.checked);
  };
  const handleChangeJobrPos = () => {
    setActiveOnlineStore(!activeOnlineStore);
    if (activeBtoc) {
      setActiveBtoc(false);
    }
    if (activeBtob) {
      setActiveBtob(false);
    }
  };

  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else if (flag == "maximum") {
      setMaximumValue(e);
    } else if (flag == "minimum") {
      setMinimumValue(e);
    } else {
      setProductDetails(e);
    }
  };
  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleSave = () => {
    // Remove empty keys from each variant
    const sanitizedVariants = variants.map((variant) => {
      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (
          value !== null &&
          value !== undefined &&
          String(value).trim() !== ""
        ) {
          acc[key] = value;
        }
        return acc;
      }, {});
    });

    // Check for negative margin percentages
    const hasNegativeMargin = sanitizedVariants.some((variant) =>
      variant.prices?.some((price) => Number(price.margin_percentage) < 0)
    );
    if (hasNegativeMargin) {
      setModalDetail({
        show: true,
        flag: "confirmModal",
      });
      setKey(Math.random());
      return;
    }

    proceedWithSave();
  };

  const proceedWithSave = () => {
    let product_attribute = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      name: productDetails?.productName,
      description: productDescription,
      images: imageDetails?.images,
      image: imageDetails?.image,
      service_id: productDetails?.service_id,
      category_id: productDetails?.category_id?.toString(),
      sub_category_id: productDetails?.sub_category_id?.toString(),
      // is_taxable: isTax,
      cost_price: costPrice,
      type: type,
      // delivery_options: deliveryOptions,
      // seller_address_ids: sellerAddressIds,
      variants: variants,
      approx_service_time: productDetails?.approx_service_time,
      pos_staff_ids: posUserId,
      sync_google_calendar: syncGoogleCalendar,
      min_capacity: minimumValue,
      max_capacity: maximumValue,
      buffer_time_before: productDetails?.buffer_time_before,
      buffer_time_after: productDetails?.buffer_time_after,
      // size_chart_image:chartImage.toString()
      // skip_detail_screen_in_pos: skipPosDetails,
      // prices: prices,
      // sync_google_calendar: syncGoogleCalendar,
      // skip_detail_screen_in_pos: skipPosDetails,
      // "need_pos_user":true
      // "barcode": barCode,
      // "sku": skuu
    };
    console.log(product_attribute, "product_attribute");
    if (sellerAddressIds?.length > 0) {
      product_attribute = {
        ...product_attribute,
        seller_address_ids: sellerAddressIds,
      };
    }
    if (deliveryOptions.length > 0) {
      product_attribute = {
        ...product_attribute,
        delivery_options: deliveryOptions,
      };
    }
    if (saleSame) {
      product_attribute = { ...product_attribute, offer: offer };
    }
    if (cancelFee !== "" && cancelFee !== null && cancelFee !== undefined) {
      product_attribute = {
        ...product_attribute,
        cancellation_fee: cancelFee,
      };
    }
    if (chartImage) {
      product_attribute = {
        ...product_attribute,
        size_chart_image: chartImage.toString(),
      };
    }
    if (Object.keys(couponData).length > 0) {
      product_attribute = {
        ...product_attribute,
        coupon: couponData,
      };
    }
    let result = formValidator(product_attribute);
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };
      dispatch(createProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success("Service Created Successfully");
          history.push(`/service-list`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };

  // Handle confirmation modal actions
  const handleConfirmNegativeMargin = () => {
    handleOnCloseModal();
    proceedWithSave();
  };

  const handleOptionValue = (e) => {
    return <>{e}</>;
  };

  const handleSelect = (e) => {
    let allIds = e.map((v) => {
      return v.pos_staff_details_id ? v.pos_staff_details_id : v?.id;
    });
    setPosUserId(allIds);
  };
  const setInitialData = () => {
    setMaximumValue(userData?.supplies[0]?.max_capacity);
    setMinimumValue(userData?.supplies[0]?.min_capacity);
    setImageDetails({
      image: userData?.image ?? "",
      images: userData?.product_images?.map((item) => {
        return item?.url ?? " ";
      }),
    });
    setSkipPosDetails(userData?.supplies[0]?.skip_detail_screen_in_pos);
    setSyncGoogleCalendar(userData?.supplies[0]?.sync_google_calendar);
    setProductDescription(userData?.description);
    // setPrices(userData?.supplies[0].supply_prices ?? []);
    setPosUserId(
      userData?.pos_staff?.map((item) => item?.pos_staff_details?.id)
    );
  };

  useEffect(() => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
      },
      tokenData: auth?.payload?.token,
    };

    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        // const modifiedData = obj.payload.pos_staff.map((item) => ({
        //   value: `${item.user.user_profiles.firstname} ${item.user.user_profiles.lastname}`,
        //   id: item.id,
        // }));
        if (userData) {
          let staffIds = userData?.pos_staff?.map(
            (item) => item?.pos_staff_details_id
          );
          setSelectedPosId(
            obj?.payload?.pos_staff?.filter((value) =>
              staffIds?.includes(value?.id)
            )
          );
        }
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
    if (userData?.from === "duplicateService") {
      setInitialData();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-Services";
  }, []);

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;

    if (type === "saleSame") {
      setSaleSame(isChecked);
      if (isChecked) {
        if (differentSale) {
          setDifferentSale(false); // Ensure only one can be selected at a time
          setPosPrice(null);
          setMarketplacePrice(null);
          setPosProfit(null);
          setMarketplaceProfit(null);
          setPosMargin(null);
          setMarketplaceMargin(null);
          setPrices([
            {
              app_name: "pos",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
            {
              app_name: "b2c",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
          ]);
        }
      }
    } else if (type === "differentSale") {
      setDifferentSale(isChecked);
      if (isChecked) {
        setSaleSame(false); // Ensure only one can be selected at a time
      }
    }
    setDiscountValue("");
    if (type === "differentSale") {
      setPosPrice(null);
      setMarketplacePrice(null);
      setPosProfit(null);
      setMarketplaceProfit(null);
      setPosMargin(null);
      setMarketplaceMargin(null);
      setPrices([
        {
          app_name: "pos",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
        {
          app_name: "b2c",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
      ]);
    }
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  // toggle
  const handletaxToggle = (event) => {
    const isChecked = event.target.checked; // Get the checkbox state
    setIsTax(isChecked); // Update local state
  };
  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        // Add option if checked
        return [...prevOptions, option];
      } else {
        // Remove option if unchecked
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };
  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];

        setAllAddress(obj?.payload);
      })
      .catch((obj) => {});
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    if (costPrice > 0) {
      // Update POS profit and margin
      if (posPrice > 0) {
        calculatePosProfitAndMargin(posPrice, costPrice);
      }

      // Update Marketplace profit and margin
      if (marketplacePrice > 0) {
        calculateMarketplaceProfitAndMargin(marketplacePrice, costPrice);
      }

      // Update margin in prices array for variants
      setPrices((prevPrices) =>
        prevPrices.map((price) => {
          const updatedMargin =
            price.selling_price > 0
              ? calculateMargin(
                  parseFloat(price.selling_price),
                  costPrice
                ).toFixed(2)
              : price.margin_percentage;

          return {
            ...price,
            margin_percentage: updatedMargin,
          };
        })
      );
    } else {
      // Reset profits and margins when costPrice is invalid
      setPosProfit("");
      setPosMargin("");
      setMarketplaceProfit("");
      setMarketplaceMargin("");

      setPrices((prevPrices) =>
        prevPrices.map((price) => ({
          ...price,
          margin_percentage: "",
        }))
      );
    }
  }, [costPrice]);

  const handleProfitChange = (e, platform) => {
    let newProfit = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newProfit)) {
      newProfit = 0; // Reset to 0 if invalid
    }

    newProfit = parseFloat(newProfit.toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));
    const newMargin =
      cost > 0 ? parseFloat(((newProfit / cost) * 100).toFixed(2)) : 0;

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  const handleMarginChange = (e, platform) => {
    let newMargin = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newMargin)) {
      newMargin = 0;
    }

    newMargin = parseFloat(newMargin.toFixed(2));
    const newProfit = parseFloat(((newMargin / 100) * cost).toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  useEffect(() => {
    setVariants([
      {
        cost_price: costPrice,
        prices: prices,
        images: imageDetails?.images,
        image: imageDetails?.image,
      },
    ]);
  }, [costPrice, imageDetails, prices]);

  const getUserSettingHandle = () => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        app_name: "pos",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserSettingsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setUserSettingData(obj?.payload);
      })
      .catch((obj) => {});
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setVariants((prevVariants) =>
        prevVariants.map((variant) => ({
          ...variant,
          prices: marketCheck
            ? variant.prices.some((price) => price.app_name === "b2c")
              ? variant.prices.map((price) =>
                  price.app_name === "b2c" && !differentSale
                    ? {
                        ...price,
                        selling_price:
                          variant.prices.find((p) => p.app_name === "pos")
                            ?.selling_price || "",
                        margin_percentage:
                          variant.prices.find((p) => p.app_name === "pos")
                            ?.margin_percentage || "",
                      }
                    : price
                )
              : [
                  ...variant.prices,
                  {
                    app_name: "b2c",
                    price_type: "fixed",
                    selling_price: !differentSale
                      ? variant.prices.find((p) => p.app_name === "pos")
                          ?.selling_price || ""
                      : "",
                    margin_percentage: !differentSale
                      ? variant.prices.find((p) => p.app_name === "pos")
                          ?.margin_percentage || ""
                      : "",
                  },
                ]
            : variant.prices.filter((price) => price.app_name !== "b2c"), // **Ensure removal of b2c**
        }))
      );

      setPrices(
        (prevPrices) =>
          marketCheck
            ? prevPrices.some((price) => price.app_name === "b2c")
              ? prevPrices.map((price) =>
                  price.app_name === "b2c" && !differentSale
                    ? {
                        ...price,
                        selling_price:
                          prevPrices.find((p) => p.app_name === "pos")
                            ?.selling_price || "",
                        margin_percentage:
                          prevPrices.find((p) => p.app_name === "pos")
                            ?.margin_percentage || "",
                      }
                    : price
                )
              : [
                  ...prevPrices,
                  {
                    app_name: "b2c",
                    price_type: "fixed",
                    selling_price: !differentSale
                      ? prevPrices.find((p) => p.app_name === "pos")
                          ?.selling_price || ""
                      : "",
                    margin_percentage: !differentSale
                      ? prevPrices.find((p) => p.app_name === "pos")
                          ?.margin_percentage || ""
                      : "",
                  },
                ]
            : prevPrices.filter((price) => price.app_name !== "b2c") // **Ensure removal of b2c**
      );
    }, 2000);

    return () => clearTimeout(timer);
  }, [marketCheck ,posPrice]);

  const handleMarketCheckChange = () => {
    setMarketCheck((prev) => !prev);
    setMarketplacePrice(null);
  };

  useEffect(() => {
    getUserSettingHandle();
  }, []);

  return (
    <div className="addProductManually addserviceManually">
      {/* Start FixedTopHeader */}
      <AddServiceHeader
        save={(e) => handleSave(e)}
        successLoader={successLoader}
        name={t("addService")}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper addProductRightWrap">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-xl-8 p-0">
              <div className="productRightContainer">
                {/* Start Uploaded ProductImg Area */}
                <ProductImageAdd imageData={(e) => handleImageDetails(e)} />
                {/* End Uploaded ProductImg Area */}

                {/* Start ProductDetails Area */}

                <ProductDetails
                  productDetails={(e, flag) => handleProductDetails(e, flag)}
                  timeSlots={userSettingData?.default_time_slot_step}
                />
                {/* Start CostPrice Area */}
                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("serviceCostPrice")}
                    </h2>
                    <div className="col-md-12">
                      <div className="form_group">
                        <label className="fontSize16 fw500 txtDarkblue mb-2 ps-4">
                          {t("costOfGoodSold")}
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="Cost Price"
                            name="text"
                            type="number"
                            value={costPrice}
                            onChange={(e) => setcostPrice(e.target.value)}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* End CostPrice Area */}

                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("serviceSalePrice")}
                    </h2>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={differentSale}
                          onChange={handleSaleToggle("differentSale")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t("useDifferentSalePrice")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("salePriceFor")} B2B, Marketplace & POS
                          </h3>
                        </div>
                      </div>
                      {differentSale && (
                        <>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              POS {t("pricing")}
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("salePrice")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="posPrice"
                                      type="number"
                                      value={posPrice}
                                      onChange={(e) =>
                                        handlePriceChange(e, "pos")
                                      }
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("profit")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput costPriceInput"
                                      value={
                                        posProfit !== null &&
                                        posProfit !== undefined
                                          ? posProfit
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleProfitChange(e, "pos")
                                      }
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("margin")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput"
                                      value={
                                        posMargin !== null &&
                                        posMargin !== undefined
                                          ? posMargin
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleMarginChange(e, "pos")
                                      }
                                      placeholder={t("margin")}
                                    />

                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {marketCheck && (
                            <div className="productSalePriceBox">
                              <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                                {t("marketingPrice")}
                              </h3>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("salePrice")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        min="1"
                                        onKeyDown={(e) => blockInvalidChar(e)}
                                        className="customInput costPriceInput"
                                        placeholder="1,000.00"
                                        name="marketplacePrice"
                                        type="number"
                                        value={marketplacePrice}
                                        onChange={(e) =>
                                          handlePriceChange(e, "b2c")
                                        }
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("profit")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput costPriceInput"
                                        value={
                                          marketplaceProfit !== null &&
                                          marketplaceProfit !== undefined
                                            ? marketplaceProfit
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleProfitChange(e, "b2c")
                                        }
                                        placeholder="Profit"
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("margin")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput"
                                        value={
                                          marketplaceMargin !== null &&
                                          marketplaceMargin !== undefined
                                            ? marketplaceMargin
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleMarginChange(e, "b2c")
                                        }
                                        placeholder="Margin"
                                      />
                                      <span className="dollrsign1_ usdTxt">
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={saleSame}
                          onChange={handleSaleToggle("saleSame")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t("onSale")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("salePriceCaption")}
                          </h3>
                        </div>
                      </div>
                    </div>
                    {!differentSale && (
                      <>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("salePrice")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="posPrice"
                                type="number"
                                value={posPrice}
                                onChange={(e) => handlePriceChange(e, "pos")}
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("profit")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput costPriceInput"
                                value={
                                  posProfit !== null && posProfit !== undefined
                                    ? posProfit
                                    : ""
                                }
                                onChange={(e) => handleProfitChange(e, "pos")}
                                placeholder="Profit"
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("margin")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput"
                                value={
                                  posMargin !== null && posMargin !== undefined
                                    ? posMargin
                                    : ""
                                }
                                placeholder="Margin"
                                onChange={(e) => handleMarginChange(e, "pos")}
                              />

                              <span className="dollrsign1_ usdTxt">%</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {saleSame && (
                      <>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              {t("discount")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="100"
                                name="text"
                                type="number"
                                value={discountValue}
                                onChange={handleDiscountValue}
                              />
                              <span className="dollrsign1_">
                                {discountOption === "amount" ? "$" : "%"}
                              </span>

                              <ul className="discountTab m-0">
                                <li
                                  className={`discountTabList ${
                                    discountOption === "amount" ? "active" : ""
                                  }`}
                                  onClick={() => handleDiscountOption("amount")}
                                >
                                  $
                                </li>
                                <li
                                  className={`discountTabList discountTabListTwo ${
                                    discountOption === "percentage"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleDiscountOption("percentage")
                                  }
                                >
                                  %
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              {t("salePrice")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="text"
                                type="number"
                                value={salePrice}
                                readOnly
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {saleToggle && (
                      <>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              Discount
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="100"
                                name="text"
                                type="number"
                              />
                              <span className="dollrsign1_">$</span>

                              <ul className="discountTab m-0">
                                <li
                                  className={`discountTabList ${
                                    discountOption === "dollar" ? "active" : ""
                                  }`}
                                  onClick={() => handleDiscountOption("dollar")}
                                >
                                  $
                                </li>
                                <li
                                  className={`discountTabList discountTabListTwo ${
                                    discountOption === "percentage"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleDiscountOption("percentage")
                                  }
                                >
                                  %
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              Sale price
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="text"
                                type="number"
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}

                    <div className="col-md-12 mt-2">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                          {t("cancellationFee")}
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="1,000.00"
                            name="posPrice"
                            type="number"
                            value={cancelFee}
                            onChange={(e) => {
                              setCancelFee(e.target.value);
                            }}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                        <p className="messageTxt ps-2">
                          {t("turnOnShowProtection")}.
                        </p>
                      </div>
                    </div>
                  </form>

                  <div className="col-md-12">
                    {/* <div className="productDetailsContainer productSale_Parent">
                      <input
                        type="checkbox"
                        checked={isTax} // Bind to state
                        onChange={handletaxToggle} // Use the updated handler
                      />
                      <div>
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Service Tax
                          </h3>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            No Service tax setup yet!
                          </h3>
                        </div>
                      </div>
                    </div> */}

                    <div className="productDetailsContainer">
                      <div className="productSale_Parent serviceSale_Parent  mt-0 d-block">
                        <h3 className="fontSize16 fw500 txtDarkblue mb-3">
                          {t("assignedTeamMemeber")}
                        </h3>
                        {/* <h3 className="turnOnShowTxt mb-0">
                          All team members by default
                        </h3> */}

                        {selectedPosId?.length === 0 && (
                          <div
                            className="assignedMember_Btn cursorPointer"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "assignedTeamMember",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </div>
                        )}

                        {selectedPosId?.length > 0 ? (
                          <div className="d-flex align-items-center mt-2">
                            <div className="teamMemberImgContainer">
                              {selectedPosId.slice(0, 4)?.map((val, index) => {
                                const profilePhoto =
                                  val?.pos_staff_details?.user?.user_profiles
                                    ?.profile_photo ||
                                  val?.user?.user_profiles?.profile_photo ||
                                  profileImg1;

                                return (
                                  <img
                                    key={index}
                                    className="posUserImg"
                                    src={profilePhoto}
                                    alt="pos_img"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                );
                              })}
                              {selectedPosId?.length > 4 && (
                                <div className="moreTeamMemberCount">
                                  {" "}
                                  +{selectedPosId.length - 4}
                                </div>
                              )}
                            </div>
                            <div
                              className="assignedMember_Btn addTeamMemberBtn cursorPointer mt-0 ms-2"
                              onClick={() => {
                                setModalDetail({
                                  show: true,
                                  flag: "assignedTeamMember",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    {/* <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            Service Variations
                          </h3>
                          <p className="fontSize14  txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? Add them here.
                          </p>
                          <button
                            className="commonBtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "serviceVariationModal",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            Add Variation
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            Attributes
                          </h3>
                          <p className="fontSize14  txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? Add them here.
                          </p>
                          <button
                            className="commonBtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "addAttributeModal",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            Add Attribute
                          </button>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0 justify-content-between align-items-start">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Sync Google Calendar
                          </h3>
                          <h3 className="turnOnShowTxt fw500 mb-0">
                            Enable this option if you want to include your event
                            in Google Calendar
                          </h3>
                        </div>
                        <input
                          type="checkbox"
                          checked={syncGoogleCalendar}
                          onChange={(e) => {
                            handleSyncGoogleCalendar(e);
                          }}
                        />
                      </div>
                    </div> */}

                    {/* <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0 justify-content-between align-items-start">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Advance booking
                          </h3>
                          <h3 className="turnOnShowTxt fw500 mb-0">
                            Let customers buy this product when it’s out of
                            stock.
                          </h3>
                        </div>
                        <input type="checkbox" />
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* End ProductDetails Area */}
                {/* <div className="row mt-3 mb-3">
                  <div className="col-md-8">
                    <div className="outerArea ">
                      <label className="labelTxt mb-3" htmlFor="amount">
                        <b>Pos User</b>
                      </label>

                      {selectedPosId?.length > 0 ? (
                        <>
                          {selectedPosId?.map((val) => {
                            return (
                              <img
                                className="me-2"
                                src={
                                  val?.pos_staff_details?.user?.user_profiles
                                    ?.profile_photo
                                    ? val?.pos_staff_details?.user
                                        ?.user_profiles?.profile_photo
                                    : val?.user?.user_profiles?.profile_photo
                                    ? val?.user?.user_profiles?.profile_photo
                                    : profileImg1
                                }
                                alt="pos_img"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50px",
                                }}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                     
                      <span class="editIcon_">
                        <i
                          class="fa-solid fa-plus"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalDetail({ show: true, flag: "addStaff" });
                            setKey(Math.random());
                          }}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-xl-4 px-md-2 p-0">
              {/* Start ProductAvailability Area */}
              <div className="productLeftContainer mt-xl-0 mt-3">
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    {t("serviceAvailability")}
                  </h3>
                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.physicalStoreIcon}
                        alt="logo"
                        className="physicalStoreIcon me-2"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            {t("physicalStore")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            JOBR POS
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="productAvailOption_Wrapper">
                      {allAddress.map((store, idx) => (
                        <div
                          key={store.id}
                          className="productAvailabilityChild mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={`store-${store.id}`}
                              checked={sellerAddressIds.includes(store.id)}
                              onChange={handleStoreToggle(store.id)} // Correct usage
                            />
                            <label htmlFor={`store-${store.id}`}></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                              Store {idx + 1}
                              {/* Store {store.id} - {store.format_address} */}
                            </h3>
                            <p className="fontSize12 txtLightBlue mb-0">
                              {store.street_address}, {store.city},{" "}
                              {store.state} {store.zipcode}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="productAvail_Second">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.jobrMarketplaceIcon}
                        alt="logo"
                        className="physicalStoreIcon me-2"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input
                            type="checkbox"
                            checked={marketCheck}
                            onChange={handleMarketCheckChange}
                          />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            JOBR Marketplace
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("allowCustomerService")}.
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* {marketCheck && (
                      <ul className="productAvailabilityList">
                        <li className="productAvailabilityChild mt-4 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="reservation"
                              checked={deliveryOptions.includes("reservation")}
                              onChange={handleDeliveryOptionToggle(
                                "reservation"
                              )}
                            />
                            <label for="reservation"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              Online Booking
                            </h3>
                          </div>
                        </li>
                      </ul>
                    )} */}
                  </div>
                </div>

                <div className="outerArea mb-0">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    {t("marketOption")}
                  </h3>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      {t("createCoupon")}
                    </h3>
                    <img
                      src={Images.upArrow}
                      alt="logo"
                      className="cursorPoint"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "createCoupon",
                        });
                        setKey(Math.random());
                      }}
                    />
                  </div>
                  {/* <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      Promote this service
                    </h3>
                    <img src={Images.upArrow} alt="logo" />
                  </div> */}
                </div>
                {/* <div className="chartUploadBox mb-2">
                  <h2 className="fontSize18 fw500 txtDarkblue ">
                    Service Size Chart
                  </h2>
                  <ChartImageUploader
                    chartImage={chartImage}
                    setChartImage={setChartImage}
                  />
                </div> */}
              </div>

              {/* <div className="outerArea mt-3">
                      <ul className="productToggleMenus">
                        <li className="productToggleItems">
                          <div className="form-check form-switch me-2 mb-0">
                            <input
                              type="checkbox"
                              id="skipPOS"
                              checked={syncGoogleCalendar}
                              onChange={(e) => {
                                handleSyncGoogleCalendar(e);
                              }}
                            />
                          </div>
                          <h3 className="labelTxt">
                            <b>Sync Google Calendar </b>
                          </h3>
                        </li>
                      </ul>
                      <p className="descriptionPara_ mt-4">
                        Enable this option if you want to include your event in
                        Google Calendar.
                      </p>
                    </div> */}

              {/* <div className="outerArea mt-3">
                      <ul className="productToggleMenus">
                        <li className="productToggleItems">
                          <div className="form-check form-switch me-2 mb-0">
                            <input
                              type="checkbox"
                              id="skipPOS"
                              checked={skipPosDetails}
                              onChange={(e) => {
                                handleSkipPOSDetails(e);
                              }}
                            />
                          </div>
                          <h3 className="labelTxt">
                            <b>Skip Details Screen in POS</b>
                          </h3>
                        </li>
                      </ul>
                      <p className="descriptionPara_ mt-4">
                        Enable this option if the product needs to be physically
                        delivered to customers either via shipping or by
                        self-pickup. If this product is a service or a
                        downloadable item that doesn’t require delivery, keep
                        this option disabled.
                      </p>
                    </div> */}

              {/* <div className='outerArea mb-3'>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="physical"
                                                            value="physical"
                                                            checked={type === "physical"}
                                                            onChange={(e) => { setType(e.target.value) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Physical Product</b></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="digital"
                                                            value="digital"
                                                            checked={type === "digital"}
                                                            // onChange={(e) => { setType(e.target.value) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Digital product or service</b></h3>
                                                </li>
                                            </ul>
                                            {type ?
                                                <form className='productDimensions mt-4'>
                                                    <div className='form-group mb-3'>
                                                        <label className="deatilsHead mb-2 fw-normal">Barcode(ISBN,UPC,GTIN,etc.)
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input className="customform-control border" placeholder="888 1235647890" name="weight" type="text" value={barCode} onChange={(e) => { setBarCode(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <label className="deatilsHead mb-2 fw-normal">SKU (Stock Keeping Unit)
                                                            <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <input className="customform-control border" placeholder="GO-123" name="weight" type="text" value={sku} onChange={(e) => { setSku(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </form>
                                                : ""}
                                        </div> */}
              {/* End ProductAvailability Area */}
              {/* Start Inventory Area */}

              {/* End Inventory Area */}
              {/* Start Attributes Area */}

              {/* End Attributes Area */}
              {/* Start ShippingPickup Area */}

              {/* End ShippingPickup Area */}
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
        ids={
          modalDetail.flag === "ActivePos"
            ? "activePos"
            : modalDetail.flag === "addStaff"
            ? "addStaff"
            : modalDetail.flag === "assignedTeamMember"
            ? "addProductOptions"
            : modalDetail.flag === "serviceVariationModal"
            ? "editVariantModal"
            : modalDetail.flag === "confirmModal"
            ? "confrimPriceOption"
            : modalDetail.flag === "createCoupon"
            ? "createCouponModal"
            : ""
        }
        size={modalDetail.flag === "addStaff" ? "lg" : "md"}
        child={
          modalDetail.flag === "addStaff" ? (
            <AddStaffModal
              posIds={selectedPosId}
              close={() => handleOnCloseModal()}
              getIdsHandle={(e) => getIdsHandle(e)}
              handleSelect={(e) => handleSelect(e)}
            />
          ) : modalDetail.flag === "assignedTeamMember" ? (
            <AssignedTeamMemberModal
              posIds={selectedPosId}
              close={() => handleOnCloseModal()}
              getIdsHandle={(e) => getIdsHandle(e)}
              handleSelect={(e) => handleSelect(e)}
            />
          ) : modalDetail.flag === "serviceVariationModal" ? (
            <AddServiceVariationModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "confirmModal" ? (
            <ConfirmationModal
              onConfirm={handleConfirmNegativeMargin}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "createCoupon" ? (
            <CreateCouponModal
              close={() => handleOnCloseModal()}
              setCouponData={setCouponData}
              couponData={couponData}
            />
          ) : (
            <></>
          )
        }
        header={
          modalDetail.flag === "assignedTeamMember" ? (
            <h3 className="fontSize24 fw500 txtDarkblue mb-0">
              {t("assignedTeamMemeber")}
            </h3>
          ) : modalDetail.flag === "serviceVariationModal" ? (
            <>
              <div></div>
              <img
                src={Images.modalCross}
                alt="logo"
                className="cursorPointer"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : modalDetail.flag === "confirmModal" ? (
            <>
              <div className="d-flex align-items-center justify-content-center w-100">
                <img
                  src={Images?.confirmImg}
                  alt="confirm image"
                  className="img-fluid"
                />
              </div>
              <img
                src={Images.modalCross}
                alt="logo"
                className="cursorPointer confirmCancel"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : modalDetail.flag === "createCoupon" ? (
            <>
              <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                {t("createCoupon")}
              </h3>

              <img
                src={Images.modalCross}
                alt="logo"
                className="cursorPointer confirmCancel"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : (
            <div className="modalHeader_">
              <div className="common_">
                <h2 className="modalHeading">{t("staffs")}</h2>
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default AddService;
