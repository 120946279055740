import React, { useEffect, useState } from 'react'
import CustomModal from '../../../components/shared/CustomModal';
import TwostepsecurityModal from './twostepsecurityModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from '../../../components/UI/Loader/Loader';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Security = (props) => {
    const { t } = useTranslation();
    const [userDetails, setUserDetails] = useState(null)
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false);

    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            flag: flag,
            title: flag,
        });
        setKey(Math.random());
    };
    useEffect(()=>{
        setUserDetails(props?.userDetails||auth?.payload)
    },[props?.userDetails])
    // update user settings
    const updateOrderProcessingData=async(data)=>{
        if(!userDetails?.user?.user_profiles?.is_two_fa_enabled)  handleOpenModal("twostepsecurityModal");
    //     return;
    //     let prams={
    //         postData: {
    //         is_two_fa_enabled:true
    //         },
    //         tokenData: auth?.payload?.token,
    //     }
    // setLoading(true);
    // dispatch(updateUserSettingsApiAsync(prams))
    // .then(unwrapResult)
    // .then((res)=>{
    //     setLoading(false);
    //     // setOrderProcessingData(res.data);
    //   if(!userDetails?.user?.user_profiles?.is_two_fa_enabled)  handleOpenModal("twostepsecurityModal");
    // })
    // .catch((res)=>{
    //     setLoading(false);
    //     //use toast
    //     toast.error('Failed to update user settings');
    // })
    } 
       return (
        <>
        {
            loading?
        
        <Loader/>:
            <div className='securityMain_'>
                <h3 className='appointMain'>{t("twoStepVerify")}</h3>
                <p className='lighttext14'>{t("twoStepSubheading")}</p>
                <div className='stepsVerification_ mt-4 ps-5'>
                    <div className="serviceSettingBoxes justify-content-between d-flex align-items-start  gap-3 ">
                        <div>
                            <h3 className="expectedHeading">
                            {t("twoStepTeamMember")}
                            </h3>
                            <h3 className="countNumber_ mb-0">
                            {t("teamMemberVerify")}
                            </h3>
                        </div>
                        <input type="checkbox"
                        checked={userDetails?.user?.user_profiles?.is_two_fa_enabled}
                        onChange={()=>{
                            updateOrderProcessingData()
                        }}/>
                    </div>
                </div>
            </div>
}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "twostepsecurityModal"
                        ? "twostepsecurityModal"

                        : ""
                }
                child={
                    modalDetail.flag === "twostepsecurityModal" ? (
                        <TwostepsecurityModal
                        getUserPosDetails={props?.getUserPosDetails}
                        close={() => handleOnCloseModal()} />
                    ) :

                        (
                            <></>
                        )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default Security