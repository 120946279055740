import React from "react";
import {
  OrderDown,
  cancleorder,
  incoiceuser_,
  modalCancel,
  pickuporder,
  profileImg1,
  returnlogo,
  serviceuser_,
  unselectedOrder,
  drivefast_,
  upArrow,
  locateOrder,
  clockBlue,
  moneyImg,
  mapimg,
  deliveryMap,
  warningicon,
  fillradio_,
  verifyorder,
  orderDriver,
  done,
  cancelledstep,
  readystep,
  acceptstep,
  openTag,
  closeTag,
  Logomvoice,
  barCodeScanImg,
} from "../../../../utilities/images";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { getFormatedDate } from "../../../../utilities/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { amountFormat, getMaskedUserId, returnModeOfPayment } from "../../../../utilities/globalMethods";
import { VarintColorSize } from "./VariantColorSize";
import { useTranslation } from "react-i18next";
import { AllOrders, deliveryStatus } from "../../../constants/orders/orders";
const OrderInvoiceDetails = ({ setopenOrderDetails, orderDetails,status }) => {
  const { t } = useTranslation();
  const history = useHistory()
  return (
    <>
      <div className="orderTrack p-0">
        <div className="consumerOrder">
          <div className="col-lg-12">
            <div className="invoiceMain_">
              <div className="invoiveHeader_">
                <div className="select_profile pb-3">
                  {setopenOrderDetails ? (
                    <img
                      onClick={() => {
                        setopenOrderDetails(false);
                      }}
                      style={{ transform: "rotate(-94deg)" }}
                      src={closeTag}
                      alt="downSelect"
                      className="cursor-pointer"
                    />
                  ) : (
                    <div onClick={() => history.goBack()}>
                      <img
                        style={{ transform: "rotate(-94deg)" }}
                        src={closeTag}
                        alt="downSelect"
                        className="cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                <div className="invoiceidHead_">
                  <h4 className="blueHeading_">
                    # {
                    orderDetails?.return_detail
                    ? orderDetails?.return_detail?.invoices?.invoice_number
                    : orderDetails?.invoices?.invoice_number ?? "-"
                    }
                  </h4>
                  <h6 className="bluesmall_">
                    {
                      status
                    }
                  </h6>
                </div>
              </div>
              <div className="mt-3 me-0 mapleLeft ">
                <div className="confirmRightSub confirmAddress">
                  <h2 className="mapleHeading text-center">
                    {orderDetails?.seller_details?.organization_name}
                  </h2>
                  <h4 className="mapleAddress text-center">
                    {
                      orderDetails?.seller_details?.current_address
                        ?.format_address
                    }
                  </h4>
                  <h4 className="mapleAddress text-center p-0">
                    {orderDetails?.seller_details?.phone_number}
                  </h4>
                </div>
                <div className="mainscrollinvoice_">
                  <div className="mapleProductDetails confirmRightSub">
                    {orderDetails?.order_details?.length > 0
                      ? orderDetails?.order_details?.map((val, i) => (
                          <div className="flexBox mapleProductDetailsBox">
                            <div className="flexbase">
                              <p className="mapleProductcount">{val?.qty}</p>
                              <article className="ms-2">
                                <p className="mapleProductHeading">
                                  {val?.product_name}
                                </p>
                                  <span className="mapleProductHeading">
                                  {VarintColorSize(val?.variant)}
                                  </span>
                              </article>
                            </div>
                            <article>
                              <p className="mapleProductPrice">
                                $
                                {`${(
                                  Number(val?.actual_price || 0) *
                                  Number(val?.qty || 0)
                                )?.toFixed(2)}`}
                              </p>
                            </article>
                          </div>
                        ))
                      : orderDetails?.return_detail?.return_details?.map(
                          (val, i) => (
                            <div className="flexBox mapleProductDetailsBox">
                              <div className="flexbase">
                                <p className="mapleProductcount">
                                  {val?.order_details?.qty}
                                </p>
                                <article className="ms-2">
                                  <p className="mapleProductHeading">
                                    {val?.order_details?.product_name}
                                    <span className="mapleProductHeading">
                                  {VarintColorSize(val?.variant)}
                                  </span>
                                  </p>
                                </article>
                              </div>
                              <article>
                                <p className="mapleProductPrice">
                                  $
                                  {Number(
                                    val?.order_details?.qty *
                                      val?.order_details?.price
                                  )?.toFixed(2)}
                                </p>
                              </article>
                            </div>
                          )
                        )}
                  </div>
                </div>
                <div className="commonInvoice_ mt-auto">
                  <div className="flexBox mapleInvoiceBox confirmRightSub">
                    <article>
                      <p className="mapleProductPrice">{t('paymentOption')}</p>
                      <p className="mapleProductHeading">
                        {
                        returnModeOfPayment(
                          orderDetails?.mode_of_payment,
                          orderDetails?.paid_by_reward,
                          orderDetails?.partially_paid_by_reward
                        )
                        // orderDetails?.mode_of_payment
                        }
                      </p>
                      <p className="mapleProductPrice">{t('invoice')}</p>
                      <p className="mapleProductHeading">
                        # {orderDetails?.return_detail
                    ? orderDetails?.return_detail?.invoices?.invoice_number
                    : orderDetails?.invoices?.invoice_number ?? "-"}
                      </p>
                    </article>
                    <article>
                      <p className="mapleProductPrice">{t('date')}</p>
                      <p className="mapleProductHeading">
                        {getFormatedDate(orderDetails?.date, "MM-DD-YYYY")}
                      </p>
                      <p className="mapleProductPrice">POS No.</p>
                            <p className="mapleProductHeading">{orderDetails?.drawer_number??"---"}</p>
                    </article>
                    <article>
                      <p className="mapleProductPrice">Mode</p>
                            <p className="mapleProductHeading">{
                              orderDetails?.delivery_option==1?"Delivery":
                              orderDetails?.delivery_option==3?"Walk-In":
                              orderDetails?.delivery_option==2?"Store":"Shipping"
                            
                              }</p>
                            <p className="mapleProductPrice">User UD</p>
                            <p className="mapleProductHeading">****{getMaskedUserId(orderDetails??{})}</p>
                    </article>
                  </div>
                  <div className="flexBox maplePriceBox">
                    <article>
                      <p className="productName">{t('subTotal')}</p>
                      <p className="productName">{t('discount')}</p>
                      <p className="productName">{t('tip')}</p>
                      <p className="productName">{t('taxes')}</p>
                      <p className="productName">{
                        orderDetails?.delivery_option==1?"Delivery Charges":"Shipping Charges"
                        }</p>
                      <p className="productName">{t('platformFees')}</p>
                      {orderDetails?.redeemed_reward_points > 0 && (
                <p className="productName">{"Reward Point"}</p>
              )}
                      <p className="productName fw-bold">{t('total')}</p>
                    </article>
                    <article>
                      <p className="productName">
                        $
                        {Number(orderDetails?.actual_amount ?? 0)?.toFixed(2)}
                      </p>
                      <p className="productName">
                        {(Number(orderDetails?.discount) || 0)?.toFixed(2)}
                      </p>
                      <p className="productName">
                        $
                        {(Number(orderDetails?.tips) || 0)?.toFixed(
                          2
                        )}
                      </p>
                      <p className="productName">
                        ${(Number(orderDetails?.tax) || 0)?.toFixed(2)}
                      </p>
                      <p className="productName">
                        $
                        {(Number(orderDetails?.delivery_option==1? orderDetails?.delivery_charge:orderDetails?.shipping_charge) || 0)?.toFixed(
                          2
                        )}
                      </p>
                      <p className="productName">${(Number(orderDetails?.platform_fee) || 0)?.toFixed(2)}</p>
                      {(orderDetails?.paid_by_reward ||
                orderDetails?.partially_paid_by_reward) && (
                <p className="productName">
                  {amountFormat(orderDetails?.redeemed_reward_points / 100)}
                </p>
              )}
                      <p className="totalBtn">
                        ${Number(orderDetails?.payable_amount)?.toFixed(2)}
                      </p>
                    </article>
                  </div>
                  <div className="confirmFooter">
                    <img
                      src={Logomvoice}
                      alt="logo"
                      className="img-fluid logo"
                    />
                    <img
                      src={orderDetails?.invoices?.barcode || barCodeScanImg}
                      alt="barCodeScanImg"
                      className="img-fluid barCodeScanImg"
                      style={{ maxWidth: "195px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInvoiceDetails;
