import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_USER_SERVICES } from "../../config";
import { getCurrentTimeZone } from "./../../../utilities/timezone";
const queryString = require("query-string");
const timeZone = getCurrentTimeZone();

export function createCashDrawer(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/cash_drawer`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      })
      .then(function (response) {
        resolve(response);
        toast.success(response?.data?.msg);
      })
      .catch(function (error) {
        reject(error);
        toast.error(
          error?.response?.data?.payload
            ? error?.response?.data?.payload[0]
            : error?.response?.data?.msg
        );
        // toast.error(error?.response?.data?.msg)

        console.log(error?.response, "errorrrrrrrrrrrrrrrrr");
      })
  );
}

export function getAllCashDrawer(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${REACT_APP_USER_SERVICES}api/v1/cash_drawer?seller_id=${
          data.sellerId
        }&seller_address_id=${data.selectedAddress || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
            "app-name": "merchant",
          },
        }
      )
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function deleteCashDrawer(token, id) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_USER_SERVICES}api/v1/cash_drawer/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      })
      .then(function (response) {
        resolve(response);
        toast.success(response?.data?.msg);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function updateCashDrawer(token, data) {
  let id = data?.id;
  delete data?.id;
  // delete data?.id;
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/cash_drawer/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "app-name": "merchant",
        },
      })
      .then(function (response) {
        resolve(response);
        toast.success(response?.data?.msg);
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
