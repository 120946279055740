import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import * as Images from "../../../../utilities/images";
import PaymentMethod from './paymentMethod';
import BankaccountWallet from './bankaccountWallet';
import WalletNotification from './walletNotification';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserSetting } from '../../systemConfig/systemConfigApi';
import { setWalletData } from '../wallet.slice';
import { useTranslation } from 'react-i18next';

const WalletSetting = () => {
    const { t } = useTranslation();
    const [walletSetting, setOrderSetting] = useState("Order Processing");
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch();

    const getSettings = () => {
        updateUserSetting({}, auth?.payload?.token)
            .then(res => {
                dispatch(setWalletData(res?.data?.payload))
            }).catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getSettings()
    }, []);

    const updateSetting = async (data) => {
        updateUserSetting({ ...data }, auth?.payload?.token)
            .then(res => {
                getSettings()
                dispatch(setWalletData(res?.data?.payload))
            }).catch(error => {
                console.log(error)
            })
    }
    return (
        <div className='walletSetting'>
            <DashboardNavbar title={t("transactionSetting")} backShow={false} />
            <div className='row'>
                <div className='col-md-3'>
                    <div className='orderleft'>
                        {/* <OrdersettingSidebar /> */}
                        <div className="leftsidebarOrder">
                            <ul className='ordersettingmenu_'>
                                <li className='listinner_ mb-3'>
                                    <p className={`listinner_a ${walletSetting === "Order Processing" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Order Processing") }}>
                                        <img className='ordersetting' src={Images.walletIcon} alt='pay methode' /> {t('payMethod')}
                                    </p>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <p className={`listinner_a ${walletSetting === "Bank Account" ? "active" : ""}`} onClick={() => { setOrderSetting("Bank Account") }}>
                                        <img className='ordersetting' src={Images.bankIcon_} alt='Bank-accounts' /> {t('bankAccount')}
                                    </p>
                                </li>
                                {/* <li className='listinner_ mb-3'>
                                    <p className={`listinner_a ${walletSetting === "Withdraw Setting" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Withdraw Setting") }}>
                                        <img className='ordersetting' src={Images.cardTransfer} alt='withdrwaels' /> {t('withdrawals')}
                                    </p>
                                </li> */}
                                <li className='listinner_ mb-3'>
                                    <p href='#' className={`listinner_a ${walletSetting === "Wallet Notification" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Wallet Notification") }}>
                                        <img className='ordersetting' src={Images.orderring} alt='notification' /> {t('notifications')}
                                    </p>
                                </li>
                                {/* <li className='listinner_'>
                                    <p href='#' className={`listinner_a ${walletSetting === "Additional Setting" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Additional Setting") }}>
                                        <img className='ordersetting' src={Images.detailorder} alt='additional' /> {t('additional')}
                                    </p>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                </div>
                <div className='col-md-9'>
                    <div className={`position-relative ${walletSetting === "Additional Settings" ? 'additionalsetmain_ rightContentorder_' : "Customer Notifications" ? 'additionalsetmain_ rightContentorder_' : 'rightContentorder_ '}`}>
                        {(walletSetting === "Order Processing") && <PaymentMethod updateSetting={updateSetting} />}
                        {(walletSetting === "Bank Account") && <BankaccountWallet />}
                        {/* hide */}
                        {/* {(walletSetting === "Withdraw Setting") && <Walletwithdraw />} */}
                        {(walletSetting === "Wallet Notification") && <WalletNotification updateSetting={updateSetting}/>}
                        {/* hide */}
                        {/* {(walletSetting === "Additional Setting") && <AdditionalSettingwallet />} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletSetting