import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderStatistics from "./HeaderStatistics";
import ChartArea from "./ChartArea";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import {
  getMerchantStatisticsAsync,
  getMerchantOrderGraphAsync,
  getMerchantTotalCustomerGraphAsync,
  getMerchantTotalOrderGraphAsync,
  totalAnalyticsInventoryApiAsync,
  getOrderWalletTransactionsAsync,
  totalOrdersGraphDataAsync,
  notificationListAsync,
} from "../dashboardSlice";
import * as Images from "../../../../utilities/images";
import DashboardNavbar from "../DashboardNavbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HomeHeader from "../../../components/UI/Orders/MainHeader";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";


const WalletStats = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [merchantStatistics, setMerchantStatistics] = useState({});
  const [merchantOrderGraph, setMerchantOrderGraph] = useState({});
  const [merchantCustomerGrpah, setMerchantCustomerGrpah] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [merchantOrderGraphAnalysic, setMerchantOrderGraphAnalysis] =useState("");
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [sellerAddress, setsellerAddress] = useState(null);

  const [totalOrderAnalyticsData, setTotalOrderAnalyticsData] = useState("");
  const [checked, setChecked] = useState({
    Sales: true,
    Cost: true,
  });
  let [dataSets, setDataSets] = useState([]);
  let [dataSets1, setDataSets1] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);
  const [filterValue, setFilterValue] = useState("week");
  const [totalDatasets, setTotalDatasets] = useState([]);
  const [orderWalletTransactions, setOrderWalletTransactions] = useState();
  const [keyword, setKeyword] = useState("");
  
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          pointStyle: "circle",
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          dash: [2, 2],
          display: false,
          color: "rgba(180, 190, 235, 1)",
        }
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: "#7E8AC1",
          maxRotation: 0,  // Set maxRotation to 0 to prevent rotation
          minRotation: 0,  // Set minRotation to 0 to keep labels horizontal
        },
      },
    }
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          dash: [2, 2],
          display: false,
          color: "rgba(180, 190, 235, 1)",
        }
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: "#7E8AC1",
        },
      },
    }
  };

  const barData = {
    labels: merchantOrderGraph?.labels,
    datasets: [
      checked.Sales && {
        fill: true,
        label: "Sales",
        data:
          merchantOrderGraph?.datasets?.length > 0
            ? merchantOrderGraph?.datasets[0]?.data
            : [],
        borderColor: "#275AFF",
        backgroundColor: "#FFFFFF00",
        cubicInterpolationMode: "monotone",
      },
      checked.Cost && {
        fill: true,
        label: "Cost",
        data:
          totalOrderAnalyticsData?.datasets?.length > 0
            ? totalOrderAnalyticsData?.datasets[0]?.data
            : [],
        borderColor: "#DBAF18",
        backgroundColor: "#DBAF18",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  function addThousandSeparator(number) {
    if(!number) return "00.00";
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // orders graph data api
  const totalOrderAnalyticsHandle = () => {
    let params = {
      postData: {
        
        channel: "all",
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
          ...(startDate === null && endDate === null && {filter: filterValue,}),
          ...(sellerAddress && {
            seller_address_id: sellerAddress
          })
      },
      tokenData: auth?.payload?.token,
    };
    if (startDate && endDate) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(totalOrdersGraphDataAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setTotalOrders(obj?.payload);
        setLoading(false);
        setTotalDatasets([
          {
            fill: true,
            label: "Delivered",
            data: obj?.payload?.graph_data?.datasets[0]?.data,
            borderColor: "#32D583",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Returned",
            data: obj?.payload?.graph_data?.datasets[1]?.data,
            borderColor: "#914BEB",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Canceled",
            data: obj?.payload?.graph_data?.datasets[2]?.data,
            borderColor: "#F04438",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  // merchat statistics api
  const getMerchantStatistics = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        need_sales: true,
        ...(filterValue && { filter: filterValue }),
        ...(startDate !== null && endDate !== null && {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        }),
        ...(sellerAddress && {
          seller_address_id: sellerAddress
        })
      },
    };
    setLoading(true);
    dispatch(getMerchantStatisticsAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setMerchantStatistics(obj.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getMerchantOrderGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        ...(filterValue && { filter: filterValue}),
        need_sales: true,
        ...(sellerAddress && {
          seller_address_id: sellerAddress
        }),
        ...(startDate !== null && endDate !== null && {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        }),

      },
    };
    setLoading(true);
    dispatch(getMerchantOrderGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setMerchantOrderGraph(obj.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  // merchnat total coustomers graph api
  const getMerchantTotalCustomerGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        ...(startDate === null &&
          endDate === null && {
            filter: value,
          }),
        ...(startDate !== null &&
          endDate !== null && {
            start_date: startDate,
            end_date: endDate,
          }),
          ...(sellerAddress && {
            seller_address_id: sellerAddress
          })
      },
    };
    setLoading(true);
    dispatch(getMerchantTotalCustomerGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);

        setMerchantCustomerGrpah(obj.payload);
        setDataSets([
          {
            fill: true,
            label: "Walkin Customer",
            data: obj?.payload?.graphData?.datasets[0]?.data,
            borderColor: "#4659B5",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Online Customer",
            data: obj?.payload?.graphData?.datasets[1]?.data,
            borderColor: "#F0C01A",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "New Customer",
            data: obj?.payload?.graphData?.datasets[2]?.data,
            borderColor: "#32D583",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const totalInventoryAnalyticsHandle = () => {
    let params = {
      postData: {
        channel: "all",
        page: 1,
        limit: 10,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
          ...(startDate === null  && endDate === null && { filter: filterValue }),
          ...(startDate !== null && endDate !== null && { 
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD")
          }),
          ...(sellerAddress && {
            seller_address_id: sellerAddress
          })
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(totalAnalyticsInventoryApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setTotalOrderAnalyticsData(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getMerchantTotalOrderGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        ...(startDate === null &&
          endDate === null && {
            filter: value,
          }),
        ...(startDate !== null &&
          endDate !== null && {
            start_date: startDate,
            end_date: endDate,
          }),
          ...(sellerAddress && {
            seller_address_id: sellerAddress
          })
      },
    };
    setLoading(true);
    dispatch(getMerchantTotalOrderGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setMerchantOrderGraphAnalysis(obj.payload);
        setDataSets1([
          {
            fill: true,
            label: "Reward",
            data: obj.payload?.graphData?.datasets[0]?.data,
            borderColor: "#4659B5",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Cash",
            data: obj.payload?.graphData?.datasets[1]?.data,
            borderColor: "#58C2E8",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Card",
            data: obj.payload?.graphData?.datasets[2]?.data,
            borderColor: "#32D583",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const paymentDataSets = [
    {
      fill: true,
      label: "Reward",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[0]
        : "",
      borderColor: "#4659B5",
      backgroundColor: "#4659B5",
      borderRadius: 12,
    },

    {
      fill: true,
      label: "Cash",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[1]
        : "",
      borderColor: "#32D583",
      backgroundColor: "#32D583",
      borderRadius: 12,
    },
    {
      fill: true,
      label: "Card",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[2]
        : "",
      borderColor: "#58C2E8",
      backgroundColor: "#58C2E8",
      borderRadius: 12,
    },
  ];
  const getOrderWalletTransactionsAsyncHandler = () => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        ...(startDate !== null && endDate !== null && { 
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD")
        }),
        ...(filterValue && { filter : filterValue}),
        ...(sellerAddress && {
          seller_address_id: sellerAddress
        })
      },
      tokenData: auth?.payload?.token,
    };

    setLoading(true);
    dispatch(getOrderWalletTransactionsAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setOrderWalletTransactions(obj?.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showNotifications = () => {
    let params = {
      tokenData: auth?.payload?.token,
    };
    dispatch(notificationListAsync(params))
      .then(unwrapResult)
      .then((obj) => {
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showNotifications();
  }, []);

  useEffect(() => {
    getMerchantStatistics(filterValue || "week");
    getMerchantOrderGraph(filterValue || "week");
    getMerchantTotalCustomerGraph(filterValue || "week");
    getMerchantTotalOrderGraph(filterValue || "week");
    totalOrderAnalyticsHandle();
    getOrderWalletTransactionsAsyncHandler();
    totalInventoryAnalyticsHandle();
  }, [filterValue, endDate, sellerAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Dashboard";
  }, []);

  const handleFilterValue = (value) => {
    setEndDate(null);
    setStartDate(null);
    setFilterValue(value);
  };
  const handleDateValue = (values) => {
    setFilterValue(null);
    if (values.startDate == values.endDate) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };
  const handleSellerAddress = (value) => {
    setsellerAddress(value);
  };
  const TotalOrderAnalytics = {
    labels: totalOrders?.graph_data?.labels,
    datasets: totalDatasets,
  };

  return (
    <div className="walletStats">
      <div className="row mb-3">
        <HomeHeader
          filterValue={filterValue}
          handleFilter={handleFilterValue}
          handleDate={handleDateValue}
          seller_id={
            auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid
          }
          tokenData={auth?.payload?.token}
          handleLocation={handleSellerAddress}
          subhead="vs. Same day last week"
        />
      </div>

      {/* Start PercentageContainer Section */}
      <div className="verificationTotal homePage">
        <DashboardNavbar title={t('home')} setKeyword={setKeyword} show={false} />

        {loading ? (
          <div className="col-md-12 mt-5 mb-5 pt-5 pb-5">
            <span className="spinner-border inner-spin spinner-border-sm"></span>
          </div>
        ) : Object.keys(merchantStatistics).length > 0 ? (
          <>
            <div className="col-md-12">
              <div className="row">
                <HeaderStatistics
                  img={Images.grossSales}
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.gross_sales
                      ? merchantStatistics?.gross_sales
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading={t('grossSale')}
                  percentage={`${(merchantStatistics?.gross_sales_stats
                    ? merchantStatistics?.gross_sales_stats
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/Total-Orders")}
                  positive={
                    merchantStatistics?.gross_sales_stats < 0 ? false : true
                  }
                />

                <HeaderStatistics
                  img={Images.netSales}
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.net_sales
                      ? merchantStatistics?.net_sales
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading={t('netSale')}
                  percentage={`${(merchantStatistics?.net_sales_stat
                    ? merchantStatistics?.net_sales_stat
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/Gross-Profits")}
                  positive={
                    merchantStatistics?.net_sales_stat < 0 ? false : true
                  }
                />
                <HeaderStatistics
                  img={Images.netAvgSales}
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.avg_net_sales
                      ? merchantStatistics?.avg_net_sales
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading={t('avgSale')}
                  percentage={`${(merchantStatistics?.avg_sales_stats
                    ? merchantStatistics?.avg_sales_stats
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/Total-Revenue")}
                  positive={
                    merchantStatistics?.avg_sales_stats < 0 ? false : true
                  }
                />
                <HeaderStatistics
                  img={Images.transactionsLogo}
                  className="transectionsBox"
                  value={`$${addThousandSeparator(
                    (merchantStatistics?.total_transactions
                      ? merchantStatistics?.total_transactions
                      : 0
                    )?.toFixed(2)
                  )}`}
                  heading={t('transactions')}
                  percentage={`${(merchantStatistics?.total_transactions_stats
                    ? merchantStatistics?.total_transactions_stats
                    : 0
                  )?.toFixed(2)}`}
                  push={() => history.push("/transections")}
                  positive={
                    merchantStatistics?.total_transactions_stats < 0
                      ? false
                      : true
                  }
                />
              </div>
              <div className="chartsOuter mt-0 mt-sm-4">
                <div className="chartHeader_ chartHeaderBox pt-0 flex-wrap gap-2">
                  <div className="leftorderValue_">
                    <h3 className="textInnerHead mb-0">{t('netSale')}</h3>
                    <h3 className="Innerhead">
                      ${addThousandSeparator(
                        merchantStatistics?.net_sales?.toFixed(2)
                      )}
                    </h3>
                    <Link to="/analytics">
                      <button
                        className="viewAllbtn_"
                        type="submit"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {t('viewAll')}
                      </button>
                    </Link>
                  </div>
                  <div className="graphChecked_ ">
                    <div className="graphBox_ me-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll1"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          setChecked({
                            ...checked,
                            Sales: checked.Sales === true ? false : true,
                          });
                        }}
                        checked={checked.Sales}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll1"
                        className="checkBoxTxt masterChecked"
                      >
                        {t('sales')}
                      </label>
                    </div>
                    <div className="checkYellow">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll2"
                        className="checkBox cost"
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          setChecked({
                            ...checked,
                            Cost: checked.Cost === true ? false : true,
                          });
                        }}
                        checked={checked.Cost}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll2"
                        className="checkBoxTxt masterChecked"
                        style={{
                          color: "#DBAF18",
                          // backgroundColor: "#DBAF18"
                        }}
                      >
                        {t('costOfGoods')}
                      </label>
                    </div>
                  </div>
                </div>
                <ChartArea
                  classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3"
                  push={() => history.push("/analytics")}
                  header="Sales Overview"
                  options={options}
                  data={barData}
                  chartType="Line"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="chartsOuter ">
                  <div className="FinalAnalytics">
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/userList")}
                      className="textInnerHead"
                    >
                      {t('totalCustomer')}
                    </h3>
                    <h6 className="textInnerHead">
                      {merchantCustomerGrpah?.total_customers?.totalCustomer}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center gap-2 justify-content-end">
                    <div className="chartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('walking')}</h3>
                    </div>
                    <div className="chartDetailData onlineChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('online')}</h3>
                    </div>
                    <div className="chartDetailData newChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('new')}</h3>
                    </div>
                  </div>

                  <ChartArea
                    classes="col-sm-12 col-md-12 col-lg-12 mt-1"
                    header="Sales Overview"
                    options={options1}
                    data={{
                      labels: merchantCustomerGrpah?.graphData?.labels,
                      datasets: dataSets,
                    }}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="chartsOuter">
                  <div className="FinalAnalytics">
                    <h3 className="textInnerHead" style={{ cursor: "pointer" }}>
                      {t('paymentType')}{" "}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center gap-2 justify-content-end">
                    <div className="chartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('reward')}</h3>
                    </div>
                    <div className="chartDetailData cashChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('cash')}</h3>
                    </div>
                    <div className="chartDetailData newChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('card')}</h3>
                    </div>
                  </div>
                  <ChartArea
                    classes="col-sm-12 col-md-12 col-lg-12 mt-1"
                    header="Sales Overview"
                    options={options1}
                    data={{
                      labels: orderWalletTransactions?.graphData?.labels.map(
                        (e) => (isNaN(e) ? e?.slice(0, 3) : e)
                      ),
                      datasets: paymentDataSets,
                    }}
                    chartType="Bar"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="chartsOuter">
                  <div className="FinalAnalytics">
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/Total-Orders")}
                      className="textInnerHead"
                    >
                      {t('totalOrders')}
                    </h3>
                    <h6 className="textInnerHead">
                      {totalOrders?.totalOrdersCount
                        ? `${addThousandSeparator(
                            totalOrders?.totalOrdersCount
                          )}`
                        : "0"}
                    </h6>
                  </div>

                  <div className="d-flex align-items-center gap-2 justify-content-end">
                    <div className="chartDetailData newChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('delivered')}</h3>
                    </div>
                    <div className="chartDetailData returnChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('returned')}</h3>
                    </div>
                    <div className="chartDetailData cancleChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">{t('cancelled')}</h3>
                    </div>
                  </div>
                  <ChartArea
                    classes="col-sm-12 col-md-12 col-lg-12 mt-1"
                    header="Sales Overview"
                    options={options1}
                    data={TotalOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col-md-12">
            {" "}
            <h2>{t('noRecordFound')}</h2>
          </div>
        )}
      </div>
      {/* End PercentageContainer Section */}
    </div>
  );
};

export default WalletStats;
