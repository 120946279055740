import React from "react";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";

const StopOfferModal = (props) => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <img src={Images.crossCircle} alt="cancleLogo" />
      <div className="stopOfferModalContainer">
        <h3 className="stopOfferTxt mb-0">{t('offerStopTitle')}</h3>
        {/* <p className="salesIncTxt mb-0">Sales Increased by 19% in 7 days </p> */}
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          disabled={props?.isStatusChage}
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          {t('cancel')}
        </button>
        <button 
        disabled={props?.isStatusChage}
        className="modalNextBtn modalDiscardBtn modalStopBtn w-100"
        onClick={() => {
          props?.productStopFunc("2")
        }}>
          <img src={Images.stopIcon} alt="stopIcon" className="me-2" />
          {/* show loader */}
          {props?.isStatusChage && (
            <span className="spinner-border spinner-border-sm spinnerRed"></span>
          )}
          {t('stop')}
        </button>
      </div>
    </div>
  );
};

export default StopOfferModal;
