import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import ReactSelect from "react-select";
import { Dropdown, Form } from "react-bootstrap";
import { selectLoginAuth } from '../../auth/authSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { getPickupDeliveryDataApiAsync, getSellerAddressesApiAsync, getUserSettingsApiAsync, updatePickupDeliveryDataApiAsync, updateSellerInfoApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from '../../../components/UI/Loader/Loader';
import { toast } from 'react-toastify';
import { dayList, options } from '../../../../utilities/helpers';
import TimePicker from 'react-time-picker';
import { useTranslation } from 'react-i18next';
import { daysName, weekOptions } from '../../../constants/moreSettings/moreSettings.constansts';
const OrderpickupDelivery = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const auth = useSelector(selectLoginAuth);
    const [sellerAddressesDetails, setsellerAddressesDetails] = useState(null);
    const [userSettingInfo, setuserSettingInfo] = useState(null);
   const [businessHours, setBusinessHours] = useState([]);
   const [defaultSellerAddress, setdefaultSellerAddress] = useState(null);
   const [isWorkHourUpdating, setisWorkHourUpdating] = useState(false);
     const [timeData, setTimeData] = useState({
       monday: { start: "09:00", end: "23:00" },
       tuesday: { start: "09:00", end: "23:00" },
       wednesday: { start: "09:00", end: "23:00" },
       thursday: { start: "09:00", end: "23:00" },
       friday: { start: "09:00", end: "23:00" },
       saturday: { start: "09:00", end: "23:00" },
       sunday: { start: "09:00", end: "23:00" },
     });
    let selectedOptionNew = [];
    
    const resultSelectOption = [
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "200", label: "200" },
    ];
    const deliveryRadius = [
        { value: 0, label: "0 miles" },
        { value: 5, label: "5 miles" },
        { value: 10, label: "10 miles" },
        { value: 15, label: "15 miles" },
        { value: 20, label: "20 miles" },
        { value: 25, label: "20 miles" },
    ];
    const [loading, setLoading] = useState(false)
    const handleDiscountOption = (options) => {
        setDiscountOption(options);
    };
    
    const getSellerAddressDetails=async()=>{
        const prams={
            postData: {
                seller_id: auth?.payload?.uniqe_id
                ? auth?.payload?.uniqe_id
                : auth?.payload?.user?.unique_uuid,
            },
            tokenData: auth?.payload?.token,
        };
        dispatch(getSellerAddressesApiAsync(prams))
        .then(unwrapResult)
        .then(res=>{
          if(res?.payload){
                setsellerAddressesDetails(res?.payload);
                let defaultAddress=res?.payload?.filter(address=>address.is_default)?.[0]||null;
                console.log(defaultAddress,"Check address")
                if(defaultAddress){
                    setdefaultSellerAddress(defaultAddress);
                    let product = defaultAddress?.product_availability?.availability;
                    let service = defaultAddress?.service_availability?.availability;
                    let isWorkHoursAvailable = product ? product : service ? service : [];
                    processDefaultWorkingHours(isWorkHoursAvailable);
 
                }
            }
        })
        .catch(err=>{
        })
    }
    const updateSellerAddressWorkingHours=async()=>{
        let bussinessHourDetails = convertBusinessHours();
        if(!Array.isArray(bussinessHourDetails)){
          toast.error("Please select business hours");
          return;
    
        }
        else{
          let isError=false;
          if(businessHours?.length!==bussinessHourDetails.length){
            toast.error("Please select business hours");
            return;
          }
          for(let businessHour of bussinessHourDetails){
            if(!businessHour.closing_time){
              toast.error(`please select ${businessHour.day} closing time`);
              break;
            }
            if(!businessHour.opening_time){
              toast.error(`please select ${businessHour.day} opening time`);
              break;
            }
          }
          if(isError)return;
        }
        setisWorkHourUpdating(true)
        updateSellerAddressInfo({
            product_availability: {
                availability: bussinessHourDetails,
            },
            service_availability:{
                availability: bussinessHourDetails,
            },
            id:defaultSellerAddress?.id
        });
        let defaultAddress={
            ...defaultSellerAddress,product_availability: {
                availability: bussinessHourDetails,
            },
            service_availability:{
                availability: bussinessHourDetails,
            },
        }
        setsellerAddressesDetails(defaultAddress)
        let sellerAddressList=sellerAddressesDetails?.map((address=>{
            return address?.id==defaultAddress?.id?defaultAddress:address;
        }));
        setsellerAddressesDetails(sellerAddressList)

    }
    const processDefaultWorkingHours = (days) => {
        let workHours = {};
        let dayList = [];
        for (let day of days) {
          let open = day?.opening_time;
          let close = day?.closing_time;
          if (open && close) {
            let dayName = weekOptions[day?.day - 1].value;
            workHours[dayName] = {
              start: convertTo24HourFormat(open),
              end: convertTo24HourFormat(close),
            };
            dayList.push(dayName);
          }
        }
        setTimeData((prev) => ({ ...prev, ...workHours }));
        setBusinessHours([...dayList]);
      };
    function convertTo24HourFormat(time) {
        const [timeStr, period] = time?.split(" "); // Split time and AM/PM part
        let [hours, minutes] = timeStr?.split(":").map(Number); // Split hours and minutes
    
        if (period?.toLowerCase() === "pm" && hours !== 12) {
          hours += 12; // Convert PM to 24-hour format
        } else if (period?.toLowerCase() === "am" && hours === 12) {
          hours = 0; // Convert 12 AM to 00:00 (midnight)
        }
    
        // Format hours and minutes to maintain two digits
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        return formattedTime;
      }
    const updateSellerAddressInfo=(data)=>{
        setLoading(true);
        let prams={
            postData: {
               ...data
            },
            tokenData: auth?.payload?.token,
        }
        dispatch(updateSellerInfoApiAsync(prams))
        .then(unwrapResult)
        .then(res=>{
            setLoading(false);
            setisWorkHourUpdating(false);
            // getSellerAddressDetails();

        })
        .catch(err=>{
            setLoading(false);
            setisWorkHourUpdating(false);
        })
    }
    const handleLocationChange = (index,key, value) => {
        let locations = [...sellerAddressesDetails];
       let location= {...locations[index]}
       if(!location){
        toast.error("No location found");
        return;
       }
       location[key] = value;
       locations[index]=location;
        setsellerAddressesDetails(locations);
        if(value===false||value===true){
           value= value===true?'1':'0';
        }
        updateSellerAddressInfo({   id:locations[index].id,[key]:value});
    };
    useEffect(()=>{
        getSellerAddressDetails();
    },[])
    const [discountOption, setDiscountOption] = useState("dollar");

    const getUserSetting=async()=>{
        let prams={
            postData: {
                app_name:"merchant",
                seller_id: auth?.payload?.uniqe_id
                ? auth?.payload?.uniqe_id
                : auth?.payload?.user?.unique_uuid,
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setuserSettingInfo(res?.payload);
        setLoading(false);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getUserSetting();
    },[])
    const updateUserSetting=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        // setOrderProcessingData(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const handleUserSetting=(key,value)=>{
        setuserSettingInfo(prev=>({
            ...prev,[key]:value
        }));
        updateUserSetting({[key]:value});
    }
    const handleCheckboxChange1 = (event) => {
        var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
        if (event.target.checked) {
          updatedList =
            businessHours?.length > 0
              ? [...businessHours, event.target.value]
              : [event.target.value];
        } else {
          updatedList.splice(businessHours?.indexOf(event.target.value), 1);
        }
        setBusinessHours(updatedList);
    };
    const handleStartTimeChange = (newStartTime, flag) => {
        handleTimeChange(newStartTime, flag, "start");
      };
      const handleTimeChange = (newTime, flag, type) => {
        if(newTime){
          let [hours, minutes] = newTime?.split(":").map(Number);
      
          const period = hours >= 12 ? "PM" : "AM";
          hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
      
          const formattedTime = `${hours}:${
            minutes < 10 ? "0" + minutes : minutes
          } ${period}`;
          setTimeData((prevData) => ({
            ...prevData,
            [flag]: {
              ...prevData[flag],
              [type]: formattedTime,
            },
          }));
        }
          else{
            setTimeData((prevData) => ({
              ...prevData,
              [flag]: {
                ...prevData[flag],
                [type]: "",
              },
            }));
          }
      };
    
      const handleEndTimeChange = (newEndTime, flag) => {
        handleTimeChange(newEndTime, flag, "end");
      };
    const convertBusinessHours = () => {
          let bussinessHourDetails = [];
      
          for (let day of businessHours) {
            if (timeData[day].start != "" && timeData[day].end != "") {
              let index = daysName.findIndex((val) => val == day);
              bussinessHourDetails.push({
                day: index + 1,
                opening_time: timeData[day].start,
                closing_time: timeData[day].end,
              });
            }
          }
          console.log(bussinessHourDetails,"bussinessHourDetails")
          return bussinessHourDetails;
    };
    
    
    
    return (
        <>
            {/* Start Pickup and Delivery */}
            {loading&&
                <Loader/>
               }
            <div className='deliveryPickup_'>
                <div className='processignOrder'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("pickup&Delivery")}</h3>
                        <p className='textInnerHead2'>{t("pickupTitle")}</p>
                    </div>
                    <div className="commonTable pickuptable_ table-responsive mt-3">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>{t("location")}</th>
                                    <th>{t("address")}</th>
                                    <th>{t("pickup")}</th>
                                    <th>{t("delivery")}</th>
                                    {/* 
                                            Add functionality later
                                    <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sellerAddressesDetails?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='fontSize14 txtLightBlue'>
                                                {item?.business_name??"Store"}
                                            </td>
                                            <td className="fontSize14 txtLightBlue">{item?.format_address}</td>
                                            <td className="fontSize14 txtLightBlue"><input type="checkbox" onChange={(event)=>{
                                                let value=event.target.checked;
                                                handleLocationChange(index,"allow_local_pickup",value);
                                            }} checked={item?.allow_local_pickup} /></td>
                                            <td className="fontSize14 txtLightBlue"><input type="checkbox" onChange={(event)=>{
                                                let value=event.target.checked;
                                                handleLocationChange(index,"allow_jobr_delivery",value);
                                            }} checked={item?.allow_jobr_delivery} /></td>
                                            {/* 
                                            Add functionality later
                                            <td className="fontSize14 txtLightBlue"><button className={`${item.confirm? 'confirmbtn_':'notconfirmbtn_'}`} type='submit'>{item.confirm? 'Confirmed':'Confirm Address'}
                                            {
                                                !item.confirm&& <img className='uparrowpickup_' src={Images.redarrow_} />
                                            }
                                             </button></td> */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className='processignOrder mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("deliveryRadius")} </h3>
                        <p className='textInnerHead2'>{t("deliveryRadiusTitle")}</p>
                    </div>
                    <div className="commonTable pickuptable_  mt-3">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>{t("location")}</th>
                                    <th>{t("address")}</th>
                                    <th>{t("radius")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sellerAddressesDetails?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='fontSize14 txtLightBlue'>
                                                {item?.business_name??"Store"}
                                            </td>
                                            <td className="fontSize14 txtLightBlue">{item?.format_address}</td>
                                            <td className="reactSelectParent commonSelectParent">
                                                <ReactSelect
                                        options={deliveryRadius}
                                        value={deliveryRadius.find(option => option.value === item?.delivery_area_radius)}
                                        onChange={(event)=>{
                                            handleLocationChange(index,"delivery_area_radius",event.value);
                                        }}
                                        placeholder="Manual"
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                maxWidth: "100% !important",
                                                width: "100%",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                paddingLeft: "unset !important",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",

                                                color: state.isSelected ? "#fff" : "#263682",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.selectImg}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                    className="mt-1"
                                                />
                                            ),
                                        }}
                                    /></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("featureSettings")}</h3>
                        <p className='textInnerHead2'>{t("featureSettingTitle")}</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox" checked={userSettingInfo?.auto_assign_pickup_delivery_time}
                                onChange={(event)=>{
                                    handleUserSetting("auto_assign_pickup_delivery_time",event.target.checked)
                                }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                        {t("calculateAssign")}
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <div className=" d-flex align-items-start  gap-3">
                            
                                <input type="checkbox"
                                checked={userSettingInfo?.allow_custmr_to_schdl_pickup_delivery}
                                onChange={(event)=>{
                                    handleUserSetting("allow_custmr_to_schdl_pickup_delivery",event.target.checked)
                                }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                        {t("allowCustomerDelivery")}
                                    </h3>

                                </div>
                                </div>

                            </div>
                            {
                                            (userSettingInfo?.allow_custmr_to_schdl_pickup_delivery)&&<div className='amountvalue_ mt-3'>
                                                <h3 className="fontSize14 m-0 p-1 px-3 txtDarkblue">
                                            {t("canBeSchedule")}
                                        </h3>
                                            <input type="text"
                                                value={userSettingInfo?.allow_custmr_to_schdl_pick_delivery_upto}
                                                 onChange={(event)=>{
                                                    if (debounceTimeout) {
                                                        clearTimeout(debounceTimeout);
                                                      }
                                                      setuserSettingInfo(prev=>({
                                                         ...prev,
                                                         allow_custmr_to_schdl_pick_delivery_upto: event.target.value,

                                                      }))

                                                      // Set a new timeout to call handleUserSetting after the user stops typing for 500ms (debounce delay)
                                                      const timeoutId = setTimeout(() => {
                                                         if(event.target.value<=0){
                                                            toast.error("Minimum days can be 0");
                                                            return;
                                                        }
                                                        if(event.target.value>365){
                                                            toast.error("Maximum days can be 365");
                                                            return;
                                                        }
                                                        
                                                        handleUserSetting("allow_custmr_to_schdl_pick_delivery_upto", event.target.value);
                                                        setDebounceTimeout(null);
                                                      }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)
                                                      setDebounceTimeout(timeoutId);

                                                 }}
                                                className='customform-control' placeholder='100' />
                                            <span className='mindollr' style={{
                                                left:"unset",
                                                right:"8px",
                                                top:"40%"
                                            }}>{t("days")}</span>
                                            <h3 className="fontSize12 m-0 px-3 py-1 txtDarkblue">
                                            {t("maxDays")}
                                        </h3>
                                        </div>
                                        }
                        </div>
                    </div>
                </div>
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("timing")}</h3>
                        <p className='textInnerHead2'>{t("timingTitle")}</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                 checked={userSettingInfo?.pickup_order_same_day}
                                 onChange={(event)=>{
                                     handleUserSetting("pickup_order_same_day",event.target.checked);
                                 }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                    {t("sameDay")}
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                 checked={userSettingInfo?.pickup_order_following_day}
                                 onChange={(event)=>{
                                    handleUserSetting("pickup_order_following_day",event.target.checked);
                                 }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                        {t("followingdays")}
                                    </h3>

                                </div>
                            </div>
                            <div className="w-100">
                 {userSettingInfo?.pickup_order_following_day&& <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('availablIty')}
                    </label>
                    <div className="checkboxGroup businessChekbox d-block staffAvailability">
                      <div className='w-100 d-flex justify-content-end mb-2'>
                        <button className='editbtn_'
                        disabled={loading ||isWorkHourUpdating}
                        onClick={updateSellerAddressWorkingHours}
                        >
                            {/* show loading */}
                            {
                                isWorkHourUpdating&&(
                                    <div
                                    style={{
                                        width:"20px",
                                        height:"20px"
                                    }}
                                      className="spinner-border text-primary"
                                      role="status"
                                      aria-hidden="true"
                                    ></div>
                                  )
                            }
                            
                            {t("update")}</button>
                      </div>
                      {dayList.map((item, index) => {
                        return (
                          <div
                            className="row align-items-center mb-3"
                            key={index}
                          >
                            <div className="col-md-3">
                              <div className="flexBox">
                                <input
                                  type="checkbox"
                                  id={item.day}
                                  className="checkBox"
                                  name={item.day}
                                  value={item.day}
                                  onChange={(e) => handleCheckboxChange1(e)}
                                  checked={businessHours?.includes(item.day)}
                                />
                                <label
                                  htmlFor={item.day}
                                  className="checkBoxTxt"
                                ></label>
                                <h3 className="fontSize18 businessUserName mb-0">
                                  {item.value}
                                </h3>
                              </div>
                            </div>
                            <div className="col-md-9">
                              {businessHours?.includes(item.day) && (
                                <>
                                  <div className="flexBox justify-content-md-end justify-content-start gap-md-4 gap-3 flex-wrap mt-md-0 mt-3">
                                    <TimePicker
                                      onChange={(e) =>
                                        handleStartTimeChange(e, item.day)
                                      }
                                      value={
                                        timeData[
                                                                                  weekOptions[item.id - 1]?.value
                                                                                ]?.start
                                      }
                                      format="h:mm a"
                                      showLeadingZeros={false}
                                      amPmAriaLabel="Select AM/PM"
                                      className="custom-time-picker customPicker"
                                      clearIcon={null}
                                      clockIcon={null}
                                    />

                                    <TimePicker
                                      onChange={(e) =>
                                        handleEndTimeChange(e, item.day)
                                      }
                                      value={
                                       timeData[
                                          weekOptions[item.id - 1]?.value
                                        ]?.end
                                      } // Use the 12-hour formatted time string directly
                                      format="h:mm a" // Specify the format as 12-hour with AM/PM
                                      className="custom-time-picker customPicker"
                                      clearIcon={null}
                                      clockIcon={null}
                                    />

                                    {/* <Dropdown
                                      onClick={() => {
                                        handleCopy(item.id);
                                      }}
                                      className="dropdownToggleBtn"
                                    >
                                      <Dropdown.Toggle
                                        variant="primary"
                                        id="dropdown-basic"
                                      >
                                        <label className="iconcopy_ mb-0">
                                          <i className="fa-solid fa-copy"></i>
                                        </label>
                                      </Dropdown.Toggle>
                                      <div className="staffAvailDropdown">
                                        <Dropdown.Menu>
                                          <Form className="formCheckParent">
                                            {options.map((option) => (
                                              <Form.Check
                                                key={option.id}
                                                type="checkbox"
                                                label={option.label}
                                                id={`option-${option.id}`}
                                                checked={selectedOptions.find(
                                                  (val) => val?.id == option?.id
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange2(
                                                    option,
                                                    item.id
                                                  )
                                                }
                                              />
                                            ))}
                                          </Form>
                                        </Dropdown.Menu>
                                      </div>
                                    </Dropdown> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>}
                </div>
                        </div>
                    </div>
                </div>
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("minOrder")}</h3>
                        <p className='textInnerHead2'>{t("minOrderTitle")}</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className='serviceSettingBoxes'>
                                <div className=" d-flex align-items-start  gap-3">
                                    <input type="checkbox" 
                                    checked={userSettingInfo?.min_amt_order_enable}
                                    onChange={(event)=>{
                                        handleUserSetting("min_amt_order_enable",event.target.checked);
                                    }}
                                    />
                                    <div>
                                        <h3 className="fontSize14 txtDarkblue m-0">
                                            {t("requireMin")}
                                        </h3>
                                    </div>
                                </div>
                               {userSettingInfo?.min_amt_order_enable&& <div className='amountvalue_ mt-3'>
                                    <input type="text" 
                                     value={userSettingInfo?.min_amt_order_amount}
                                     disabled={!(userSettingInfo?.min_amt_order_enable)}
                                     onChange={(event)=>{
                                        if (debounceTimeout) {
                                            clearTimeout(debounceTimeout);
                                          }
                                          setuserSettingInfo(prev=>({
                                             ...prev,
                                              min_amt_order_amount: Number(event.target.value),
  
                                          }))
                                      
                                          // Set a new timeout to call handleUserSetting after the user stops typing for 500ms (debounce delay)
                                          const timeoutId = setTimeout(() => {
                                            handleUserSetting("min_amt_order_amount", Number(event.target.value));
                                            setDebounceTimeout(null);
                                          }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)
                                          setDebounceTimeout(timeoutId);
                                         
                                     }}
                                    className='customform-control' placeholder='100' />
                                    <span className='mindollr'>$</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='processignOrder featureseting mt-4'> */}
                    {/* <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Delivery rates</h3>
                        <p className='textInnerHead2'>Set up delivery rates for each region you want to ship to, or for specific items. </p>
                    </div> */}
                    {/* <div className="row mt-4">
                        <div className="col-md-12">
                            <div className='serviceSettingBoxes'>
                                <div className="reactSelectParent commonSelectParent w-100">
                                    <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                        Delivery fee
                                        <img src={Images.labelImg} alt="logo" className="ms-2" />
                                    </label>
                                    <ReactSelect
                                        options={resultSelectOption}
                                        onChange={(event)=>{
                                            let value=event.value;
                                        setOrderPickupDelivery(prev=>({
                                            ...prev,
                                            delivery_rates:{
                                               ...prev.delivery_rates,
                                               delivery_fee:value
                                            }
                                        }))
                                        }}
                                        placeholder="Manual"
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                maxWidth: "100% !important",
                                                width: "100%",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                paddingLeft: "unset !important",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",

                                                color: state.isSelected ? "#fff" : "#263682",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.selectImg}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                    className="mt-1"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='amountvalue_ mt-3'>
                                    <input type="text"
                                     value={OrderPickupDelivery.delivery_rates.order_amount}
                                     onChange={(event)=>{
                                         setOrderPickupDelivery(prev=>({
                                             ...prev,
                                             delivery_rates:{
                                                ...prev.delivery_rates,
                                                order_amount:event.target.checked
                                             }
                                         }))
                                     }}
                                    
                                    className='customform-control' placeholder='100' />
                                    <span className='mindollr'>$</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row mt-4">
                        <div className="col-12">
                            <div className='serviceSettingBoxes'>
                                <div className=" d-flex align-items-start  gap-3">
                                    <input type="checkbox" 
                                    
                                    checked={userSettingInfo?.service_fees_charge_enable}
                                    onChange={(event)=>{
                                       handleUserSetting("service_fees_charge_enable",event.target.checked);
                                    }}
                                    />
                                    <div>
                                        <h3 className="fontSize14 txtDarkblue">
                                            Charge a service fee on delivery orders
                                        </h3>
                                        <h3 className="fontSize12 txtLightBlue mb-0">
                                            This service fee is charged in addition to the delivery fee. You keep the amount charged, minus standard processing fees.
                                        </h3>
                                    </div>
                                </div>
                                <div className='service_fee'>
                                    <div className='amountvalue_'>
                                        <input type="text"
                                         value={userSettingInfo?.service_fees_charge_amount}
                                         disabled={!(userSettingInfo?.service_fees_charge_enable)}
                                         onChange={(event)=>{
                                            if (debounceTimeout) {
                                                clearTimeout(debounceTimeout);
                                              }
                                              setuserSettingInfo(prev=>({
                                                ...prev,
                                                service_fees_charge_amount:event.target.value,
                                              }))
                                          
                                              // Set a new timeout to call handleUserSetting after the user stops typing for 500ms (debounce delay)
                                              const timeoutId = setTimeout(() => {
                                                handleUserSetting("service_fees_charge_amount", event.target.value);
                                                setDebounceTimeout(null);
                                              }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)
                                              setDebounceTimeout(timeoutId);
                                         }}
                                        className='customform-control' placeholder='100' />
                                        <span className='mindollr'>$</span>
                                    </div>

                                    <ul className="discountTab m-0">
                                        <li
                                            className={`discountTabList ${discountOption === "dollar" ? "active" : ""
                                                }`}
                                            onClick={() => handleDiscountOption("dollar")}
                                        >
                                            $
                                        </li>
                                        <li
                                            className={`discountTabList discountTabListTwo ${discountOption === "percentage"
                                                ? "active"
                                                : ""
                                                }`}
                                            onClick={() =>
                                                handleDiscountOption("percentage")
                                            }
                                        >
                                            %
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
            {/* End Pickup and Delivery */}
        </>
    )
}

export default OrderpickupDelivery