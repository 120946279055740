import dayjs from 'dayjs';
import * as React from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { eventCellCss, u } from '../commonStyles';
import { useTheme } from '../theme/ThemeContext';
import { isToday } from '../utils/datetime';
import { objHasContent, stringHasContent } from '../utils/object';
import { typedMemo } from '../utils/react';
function _CalendarHeader(_a) {
    var dateRange = _a.dateRange, cellHeight = _a.cellHeight, style = _a.style, allDayEvents = _a.allDayEvents, onPressDateHeader = _a.onPressDateHeader, onPressEvent = _a.onPressEvent, activeDate = _a.activeDate, _b = _a.headerContentStyle, headerContentStyle = _b === void 0 ? {} : _b, _c = _a.dayHeaderStyle, dayHeaderStyle = _c === void 0 ? {} : _c, _d = _a.dayHeaderHighlightColor, dayHeaderHighlightColor = _d === void 0 ? '' : _d, _e = _a.weekDayHeaderHighlightColor, weekDayHeaderHighlightColor = _e === void 0 ? '' : _e, _f = _a.showAllDayEventCell, showAllDayEventCell = _f === void 0 ? true : _f, _g = _a.hideHours, hideHours = _g === void 0 ? false : _g;
    var _onPressHeader = React.useCallback(function (date) {
        onPressDateHeader && onPressDateHeader(date);
    }, [onPressDateHeader]);
    var _onPressEvent = React.useCallback(function (event) {
        onPressEvent && onPressEvent(event);
    }, [onPressEvent]);
    var theme = useTheme();
    var borderColor = { borderColor: theme.palette.gray['200'] };
    var primaryBg = { backgroundColor: theme.palette.primary.main };
    return (React.createElement(View, { style: [
            // showAllDayEventCell ? u['border-b-2'] : {},
            // showAllDayEventCell ? borderColor : {},
            theme.isRTL ? u['flex-row-reverse'] : u['flex-row'],
            style,
        ] },
        !hideHours && React.createElement(View, { style: [u['z-10'], u['w-50'], borderColor] }),
        dateRange.map(function (date) {
            var shouldHighlight = activeDate ? date.isSame(activeDate, 'date') : isToday(date);
            return (React.createElement(TouchableOpacity, { style: [
                    u['flex-1'],
                    u['pt-12'],
                    {
                        backgroundColor: shouldHighlight ? '#F5F6FC' : 'transparent',
                        borderRadius: 14,
                    },
                ], onPress: function () { return _onPressHeader(date.toDate()); }, disabled: onPressDateHeader === undefined, key: date.toString() },
                React.createElement(View, { style: [
                        { height: cellHeight },
                        objHasContent(headerContentStyle) ? headerContentStyle : {},
                    ] },
                    React.createElement(Text, { style: [
                            theme.typography.sm,
                            u['text-center'],
                            {
                                // fontFamily: Fonts.SemiBold,
                                color: '#263682',
                                // color: shouldHighlight
                                //   ? stringHasContent(weekDayHeaderHighlightColor)
                                //     ? weekDayHeaderHighlightColor
                                //     : theme.palette.primary.main
                                //   : theme.palette.gray['500'],
                            },
                        ] }, date.format('dddd')),
                    React.createElement(View, { style: objHasContent(dayHeaderStyle)
                            ? dayHeaderStyle
                            : shouldHighlight
                                ? [
                                    // primaryBg,
                                    u['h-36'],
                                    u['w-36'],
                                    u['pb-6'],
                                    // u['rounded-full'],
                                    u['items-center'],
                                    u['justify-center'],
                                    u['self-center'],
                                    u['z-20'],
                                ]
                                : [u['mb-6']] },
                        React.createElement(Text, { style: [
                                {
                                    // fontFamily: Fonts.SemiBold,
                                    // color: shouldHighlight
                                    //   ? stringHasContent(dayHeaderHighlightColor)
                                    //     ? dayHeaderHighlightColor
                                    //     : theme.palette.primary.contrastText
                                    //   : theme.palette.gray['800'],
                                    color: '#263682',
                                },
                                theme.typography.xl,
                                u['text-center'],
                                Platform.OS === 'web' &&
                                    shouldHighlight &&
                                    !stringHasContent(dayHeaderHighlightColor) &&
                                    u['mt-6'],
                            ] }, date.format('D')))),
                showAllDayEventCell ? (React.createElement(View, { style: [
                        // u['border-l'],
                        // { borderColor: theme.palette.gray['200'] },
                        { height: cellHeight },
                    ] }, allDayEvents.map(function (event, index) {
                    if (!dayjs(date).isBetween(event.start, event.end, 'day', '[]')) {
                        return null;
                    }
                    return (React.createElement(TouchableOpacity, { style: [eventCellCss.style, primaryBg, u['mt-2']], key: index, onPress: function () { return _onPressEvent(event); } },
                        React.createElement(Text, { style: {
                                // fontFamily: Fonts.SemiBold,
                                fontSize: theme.typography.sm.fontSize,
                                color: theme.palette.primary.contrastText,
                            } }, event.title)));
                }))) : null));
        })));
}
export var CalendarHeader = typedMemo(_CalendarHeader);
