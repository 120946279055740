import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  checkConnectAccount,
  connectAccountApiAsync,
  getconnectAccountApiAsync,
  loginAsync,
  selectLoginAuth,
  selectSignUpAuth,
} from "../authSlice";

import { toast } from "react-toastify";


const ConnectBankAccount = () => {
  const [loading, setLoading] = useState(true);
  const toastId = React.useRef(null);
  const signupAuth = useSelector(selectSignUpAuth);
  const [userConnectDetails, setUserConnectDetails] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation();
  const { pathname } = location;
  // add bank details
  const handleAddBankBankDetails = () => {
    // e.preventDefault();
    let params = {
      tokenData: auth?.payload?.token ? auth?.payload?.token :
      auth?.payload?.user?.token
        ? auth?.payload?.user?.token
        : signupAuth?.payload?.token,
      is_user_login: pathname === "/payout-details" ? 1 : 0,
    };
    setLoading(true);
    dispatch(connectAccountApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
          window.location.href = obj.payload?.url;
          // window.open(obj.payload?.url, "_blank")
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if(signupAuth?.payload?.token && auth?.payload?.user?.token) {
      let params = {
        tokenData: signupAuth?.payload?.token
          ? signupAuth?.payload?.token
          : auth?.payload?.user?.token,
      };
      dispatch(getconnectAccountApiAsync(params))
        .then(unwrapResult)
        .then((res) => {
            setLoading(false);
            if (res?.payload?.data && res?.payload?.data?.length > 0) {
              setUserConnectDetails(res?.payload?.data);
            }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    
  }, []);

  useEffect(() => {
    let params = {
      tokenData: signupAuth?.payload?.token
        ? signupAuth?.payload?.token
        : auth?.payload?.user?.token,
    };
    dispatch(checkConnectAccount(params))
      .then(unwrapResult)
      .then((res) => {
        if (res?.payload?.status == 2) {
          toast.success(res?.msg);
          const login_data = JSON.parse(localStorage.getItem("login_data"))
          console.log({ login_data }, "this is login data here =>>>")
          dispatch(loginAsync(login_data))
          .then(unwrapResult)
          .then(obj => {
            setLoading(false)
            if (obj?.status_code == 206) {
              // history.push("/chooseProtection");
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(obj?.msg);
              }
            } else {
              history.push("/new-user");
              // history.push("/connect-bank-account");
            }
          }).catch(err => {
            console.log(err)
          })
          // history.push("/chooseProtection");
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="commonLogin connectBankAccountSection">
      <div className="loginForm">
        <img src={Images.bankLogo} alt="img" className="mb-3" />
        <h2 className="fontSize24 fw500 txtBlue mb-3">Connect Bank Account</h2>
        <h5 className="fontSize14 txtBlue mb-3">
          Connect Your Bank Account For Seamless Payment To You.
        </h5>
        <button
          className="commonBlueBtn active w-100 gap-2"
          onClick={() => {
            // history.push("/create-new-password");
            handleAddBankBankDetails()
          }}
          type="submit"
        >
          <img src={Images.plusIcon} alt="img" /> Add Bank Account
        </button>
        {/* <AddBankDetails /> */}
      </div>
      <div className="copyRight">
        <p>All Rights Reserved by JOBR LLC | 2022</p>
      </div>
    </div>
  );
};

export default ConnectBankAccount;
