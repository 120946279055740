import React, { forwardRef, useEffect, useState } from "react";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import moment from "moment-timezone";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { getCurrentMonthDetails } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";
const { startOfMonth, endOfMonth, monthName, year } = getCurrentMonthDetails();

const AnalyticsHeader = (props) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState({
    start: props?.startDate,
    end: props?.endDate,
  });
  const [localtimeSpan, setLocalTimeSpan] = useState(props?.timeSpan || "week")
  useEffect(() => {
    if (props?.timeSpan === "week") {
      // setEndDate(moment().endOf("week"))
      setDates((prev) => ({
        ...prev,
        start: moment().startOf("week").toDate(),
        end: moment().endOf("week").toDate(),
      }))
    }
  }, []);

  const goToPreviousRange = () => {
    props?.onTimeSpanSelect(null);
    const startDateMoment = moment(props?.startDate || new Date());
    const endDateMoment = moment(props?.endDate || new Date());

    if (startDateMoment.isValid() && endDateMoment.isValid()) {
      let nextRangeStartDate = "";
      let nextRangeEndDate = "";
      if (localtimeSpan === "today") {
        nextRangeStartDate = startDateMoment.clone().subtract(2, "day");
        nextRangeEndDate = endDateMoment.clone().subtract(1, "day");
      }
      if (localtimeSpan === "week") {
        nextRangeStartDate = startDateMoment.clone().subtract(1, localtimeSpan).startOf(localtimeSpan);
        nextRangeEndDate = endDateMoment.clone().endOf(localtimeSpan).subtract(1, localtimeSpan);
      }
      if (localtimeSpan === "month") {
        nextRangeStartDate = startDateMoment.clone().startOf(localtimeSpan).subtract(1, localtimeSpan);
        nextRangeEndDate = endDateMoment.clone().subtract(1, localtimeSpan).endOf(localtimeSpan);
      }
      if (localtimeSpan === "year") {
        nextRangeStartDate = startDateMoment.clone().startOf(localtimeSpan).subtract(1, localtimeSpan);
        nextRangeEndDate = endDateMoment.clone().endOf(localtimeSpan).subtract(1, localtimeSpan);
      }

      props?.setStartDate(nextRangeStartDate.toDate());
      props?.setEndDate(nextRangeEndDate.toDate());
      setDates((prev) => {
        return {
          ...prev,
          start: nextRangeStartDate.toDate(),
          end: nextRangeEndDate.toDate()
        }
      })
    } else {
      console.error("Invalid start or end date");
    }
  };

  const goToNextRange = () => {
    props?.onTimeSpanSelect(null);
    const startDateMoment = moment(props?.startDate || new Date());
    const endDateMoment = moment(props?.endDate || new Date());

    if (startDateMoment.isValid() && endDateMoment.isValid()) {
      let nextRangeStartDate = "";
      let nextRangeEndDate = "";
      if (localtimeSpan === "today") {
        nextRangeStartDate = startDateMoment.clone().add(1, "day");
        nextRangeEndDate = endDateMoment.clone().add(1, "day");
      }
      if (localtimeSpan === "week") {
        nextRangeStartDate = startDateMoment.clone().add(1, localtimeSpan).startOf(localtimeSpan);
        nextRangeEndDate = endDateMoment.clone().add(1, localtimeSpan).endOf(localtimeSpan);
        // // Handle the end of the month scenario
        // if (nextRangeEndDate.isAfter(nextRangeStartDate.clone().endOf('month'))) {
        //   nextRangeEndDate = nextRangeStartDate.clone().endOf('month');
        // }
      }
      if (localtimeSpan === "month") {
        nextRangeStartDate = startDateMoment.clone().startOf(localtimeSpan).add(1, localtimeSpan);
        // Adjust end date to the end of the next month
        nextRangeEndDate = endDateMoment.clone().add(1, 'month').endOf('month');
      }
      if (localtimeSpan === "year") {
        nextRangeStartDate = startDateMoment.clone().startOf(localtimeSpan).add(1, localtimeSpan);
        nextRangeEndDate = endDateMoment.clone().endOf(localtimeSpan).add(1, localtimeSpan);
      }
      props?.setStartDate(nextRangeStartDate.toDate());
      props?.setEndDate(nextRangeEndDate.toDate());
      setDates((prev) => {
        return {
          ...prev,
          start: nextRangeStartDate.toDate(),
          end: nextRangeEndDate.toDate()
        }
      })
    } else {
      console.error("Invalid start or end date");
    }
  };
 
  const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) =>
   {
    value = value.split("-");
    const startDate = moment(value[0]).format("MMM DD, YYYY");
    const endDate = moment(value[1]).format("MMM DD, YYYY");
    return (
    <div className="date-picker-container" >
      <img 
      onClick={goToPreviousRange}
      className="calendar-icon" 
      src={Images.leftArrow} 
      alt="Calendar"  />
      <p className="date-text" onClick={onClick}>
      {startDate === endDate ?  startDate : (
            <>
            {startDate} -{" "}
            {endDate !== "Invalid date" ? endDate : moment().format("MMMM YYYY")}
            </>
          )}
      </p>
      <img
        className="arrow-icon"
        src={Images.newRightArrow}
        alt="Dropdown Arrow"
        onClick={goToNextRange}
      />
    </div>
  )});

  const selectOption = [
    { value: "all", label: "All Channels"},
    { value: "pos", label: "Checkout"},
    { value: "b2c", label: "Market"},
    { value: "b2b", label: "Trade"},
  ];

  const handleDateChange = (dates) => {
    setDates((prev) => {
      return ({ ...prev, start: "", end: "" })
    })
    const [start, end] = dates;
    props?.setStartDate(start);
    props?.setEndDate(end);
    setDates((prev) => {
      return { ...prev, start, end }
    })
    props?.onDateChange(dates)
  };

  const onChannelChange = (e) => {
    props?.setChannelFilter(e?.value || "All")
  }
   // Update dates based on the selected timeSpan
   const handleTimeSpanSelect = (el) => {
    props?.onTimeSpanSelect(el);
    props?.setStartDate(null);
    props?.setEndDate(null);
    setLocalTimeSpan(el)
    setDates((prev) => {
      let start = "";
      let end_date = "";
      if (el === "today") {
        start = moment().toDate();
        end_date = start
      }
      if (el === "week") {
        start = moment().startOf("week").toDate();
        end_date = moment().endOf("week").toDate();
      }
      if (el === "month") {
        start = moment().startOf("month").toDate();
        end_date = moment().endOf("month").toDate();
      }
      if (el === "year") {
        start = moment().startOf("year").toDate();
        end_date = moment().endOf("year").toDate();
      }
      return { ...prev, start, end: end_date };
    });
  };
  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-md-2">
          <div>
            <h3 className="fontSize18 headerTxtColor mb-0 pb-1">{props?.header || ""}</h3>
            {/* <h6 className="homeHeader_Subheading mb-0">{props?.subhead}</h6> */}
          </div>
        </div>
        <div className="col-md-10 d-flex justify-content-end align-items-start">
          <div className="d-flex align-items-center gap-2">
            <div className="bookingLeftTwo">
              <ReactDatePicker
                onChange={handleDateChange}
                startDate={dates?.start}
                endDate={dates?.end}
                selectsRange
                maxDate={new Date()}
                customInput={<DatePickerCustomComponent />}
              />
            </div>
            <div className="reactSelectParent ">
              <ReactSelect
                options={selectOption}
                value={props?.channelFilter?.value}
                placeholder={t("allChannels")}
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682" // Background color for selected option
                      : state.isFocused
                        ? "#f5f6fc" // Background color for hovered option
                        : "#fff", // Default background color
                    // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                    color: state.isSelected
                      ? "#fff" // Background color for selected option
                      : "#000", // Text color
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#263682", // Customize placeholder color if needed
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                onChange={onChannelChange}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
            <ul className="weekNav">
              <li onClick={() => handleTimeSpanSelect("today")}>
                <Link
                  className={`weekMenu ${props?.timeSpan == "today" ? "active" : ""
                    }`}
                  to="#"
                >
                  {t("today")}
                </Link>
              </li>
              <li onClick={() => handleTimeSpanSelect("week")}>
                <Link
                  className={`weekMenu ${props?.timeSpan == "week" ? "active" : ""
                    }`}
                  to="#"
                >
                  {t("weekly")}
                </Link>
              </li>
              <li onClick={() => handleTimeSpanSelect("month")}>
                <Link
                  className={`weekMenu ${props?.timeSpan == "month" ? "active" : ""
                    }`}
                  to="#"
                >
                  {t("monthly")}
                </Link>
              </li>
              <li onClick={() => handleTimeSpanSelect("year")}>
                <Link
                  className={`weekMenu ${props?.timeSpan == "year" ? "active" : ""
                    }`}
                  to="#"
                >
                  {t("yearly")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsHeader;
