import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth } from '../../auth/authSlice';
import { getAdditionalSettingApiAsync, getUserSettingsApiAsync, updateAdditionalSettingApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from '../../../components/UI/Loader/Loader';
import { useTranslation } from 'react-i18next';

const AdditionalSetting = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectLoginAuth);
    const [additionalSetting, setadditionalSetting] = useState(null);
    const [collectBuyer, setCollectBuyer] = useState({
        delivery:true,
        pickup:true,
        shipping:false
    })
    const resultSelectOption = [
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "200", label: "200" },
    ];
    const [loading, setLoading] = useState(false)
   const handleAdditionalSetting=(key,value)=>{
    setadditionalSetting(prev=>({...prev,[key]:value}));
    updateOrderProcessingData({[key]:value})
   }    

    const getOrderProcessingData=async()=>{
        let prams={
            postData: {
               app_name: "merchant",
                seller_id: auth?.payload?.uniqe_id
                    ? auth?.payload?.uniqe_id
                    : auth?.payload?.user?.unique_uuid,
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        setadditionalSetting(res.payload);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const updateOrderProcessingData=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        setadditionalSetting(res.payload);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getOrderProcessingData();
    },[])
    return (
        <div className='additionalSetting_ customerNotification_ position-relative'>
            {
                loading&&<Loader/>
            }
            <div className='processignOrder p-0'>
                <div className='ordrsettingHead_'>
                    <h3 className='normalHeading_ mb-1'>{t("additionalSetting")}</h3>
                    <p className='textInnerHead2'>{t("shippingtitle")}</p>
                </div>

                {/* <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Order Numbering</h3>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                checked={
                                    additionalSetting?.order_numbering?.automatic
                                }
                                onChange={(event)=>{
                                    handleAdditionalSetting('order_numbering','automatic',event.target.checked);
                                }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue">
                                        Automatic (default)
                                    </h3>
                                    <p className='textInnerHead2'>Set up carrier accounts to purchase shipping labels through JOBR</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox" 
                                checked={
                                    additionalSetting?.order_numbering?.custom_format
                                }
                                onChange={(event)=>{
                                    handleAdditionalSetting('order_numbering','custom_format',event.target.checked);
                                }}
                                
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue">
                                        Custom format
                                    </h3>
                                    <p className='textInnerHead2'>This option gives you the flexibility to create a unique format for your order numbers using specific characters and patterns.
                                        For example, ORD-2309-0001</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("tipping")}</h3>
                        <p className='textInnerHead2'>{t("tippingTitle")}</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                checked={
                                    additionalSetting?.show_tips_option
                                }
                                onChange={(event)=>{
                                    handleAdditionalSetting("show_tips_option",event.target.checked);
                                }}
                                 />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue">
                                        {t("tippingOption")}
                                    </h3>
                                    <p className='textInnerHead2'>{t("tippingOptionTitle")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Collect buyer address for</h3>
                        <p className='textInnerHead2'>Recipient address is automatically collected where necessary for tax, and for all shipping and delivery orders.</p>
                    </div>
                    <div className='deliveryCheckbox'>
                        <div className="row mt-2">
                            {
                                Object.entries(collectBuyer)?.map((option,index)=>(
                                    <div className="col-12">
                                    <div className="graphChecked_ mt-3 mb-1 ">
                                        <div className="graphBox_ me-3">
                                            <input
                                                type="checkbox"
                                                id="custom-checkbox-selectAll2"
                                                className="checkBox "
                                                name="custom-checkbox-selectAll"
                                                checked={option[1]}
                                                onChange={(event)=>{
                                                    // handleAdditionalSetting('collect_buyer',option[0],event.target.checked);
                                                }}
                                               
    
                                            />
                                            <label
                                                htmlFor="custom-checkbox-selectAll2"
                                                className="checkBoxTxt masterChecked text-capitalize"
                                            >
                                                {option[0]}
                                            </label>
                                        </div>
                                    </div>
                                </div> 
                                ))
                            }
                          
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default AdditionalSetting