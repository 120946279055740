import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectRegisterAuth } from '../authSlice'
import { profileImg1 } from '../../../../utilities/images'

const LoginProfileModal = () => {
  const history = useHistory()
  let date = new Date()
  const verifyData = useSelector(selectRegisterAuth)
  return (
    <div className='loginProfileModal'>
      <div className="contentContainer_">
        <h5 className="subheading_">Confirm your log in</h5>
        <img src={profileImg1} className="profileUserImg mt-4 mb-4" alt="user" />
        <div className="userDetais">
          <div className="userValues">
            <p className="textInnerDark mb-2">{verifyData?.payload?.user?.user_profiles?.firstname}{" "}{verifyData?.payload?.user?.user_profiles?.lastname}</p>
            <p className="textInnerDark mb-2">ID:{verifyData?.payload?.user?.id}</p>
          </div>
          <p className="subtextSmall_">Today {moment(date).format('dddd DD MMM YYYY')}<br />
            Time {moment(date).format('hh:mm A')}</p>
          <button className="loginBtn mt-4 w-100" onClick={() => history.push('/new-user')}>Continue</button>
        </div>
      </div>
    </div>
  )
}

export default LoginProfileModal