import React, { useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey:
    "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", // Replace with your actual API key
  dangerouslyAllowBrowser: true,
});

const AiTextModal = (props) => {
  const [productName, setProductName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [customerInfo, setCustomerInfo] = useState("");
  const [generatedTexts, setGeneratedTexts] = useState([]);
  const [loading, setLoading] = useState(false);

  const generateTextOptions = async () => {
    console.log("Function called!");
    setLoading(true);
    try {
      console.log("Before API Call");
      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "Generate marketing content for a product.",
          },
          {
            role: "user",
            content: `Generate **3 different marketing text options** in a single response for a product:
            - Product Name: ${productName}
            - Business Type: ${businessType}
            - Key Information: ${customerInfo}
            
            Separate each option with **"Option 1:", "Option 2:", "Option 3:"**.`,
          },
        ],
        // max_retries: 0,  
        // n: 1,
        // max_tokens: 100, // Increase slightly to accommodate 3 responses
      });

      const options = response.choices[0].message.content.split("Option "); // Parse options
      setGeneratedTexts(options);
      console.log("After API Call", response);
      // const options = response.choices.map((choice) => choice.message.content);
      // setGeneratedTexts(options);
    } catch (error) {
      console.error("Error generating text:", error);
    }
    setLoading(false);
  };

  

  
  return (
    <div className="aiTextModalParent">
      <div className="text-center">
        <img src={Images.aiTextModalImg} alt="img" className="mb-3" />
        <h3 className="fontSize36 txtDarkblue">AI Text Creator</h3>
        <p className="fontSize16 txtLightBlue">
          AI can make mistakes, so double-check that the results are accurate
          before using them.
        </p>
      </div>
      <form>
        <div className="form_group">
          <label className="fontSize16 txtDarkblue mb-2 ps-4">
            Product Name
          </label>
          <div className="inputGroup">
            <input
              className="customInput"
              placeholder="Product name"
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value.slice(0, 70))}
              maxLength={70}
            />
          </div>
          <span className="turnOnShowTxt ps-4 pt-1">
            {70 - productName.length}
          </span>
        </div>
        <div className="form_group">
          <label className="fontSize16 txtDarkblue mb-2 ps-4">
            Business type
            <img src={Images.labelImg} alt="logo" className="ms-2" />
          </label>
          <div className="inputGroup">
            <input
              className="customInput"
              placeholder="Business type"
              value={businessType}
              onChange={(e) => setBusinessType(e.target.value.slice(0, 60))}
              maxLength={60} // Enforces limit
              type="text"
            />
          </div>
          <span className="turnOnShowTxt ps-4 pt-1">
            {60 - businessType.length}
          </span>
        </div>
        <div className="form_group">
          <label className="fontSize16 txtDarkblue mb-2">
            What would you like customers to know?
            <img src={Images.labelImg} alt="logo" className="ms-2" />
          </label>
          <div className="inputGroup">
            <textarea
              className="customInput customTextarea"
              placeholder="Text here"
              id="floatingTextarea"
              rows="3"
              value={customerInfo}
              onChange={(e) => setCustomerInfo(e.target.value.slice(0, 350))}
              maxLength={350} // Enforces limit
            ></textarea>
          </div>
          <span className="turnOnShowTxt ps-4 pt-1">
            {350 - customerInfo.length}
          </span>
        </div>
        <div className="modalfooterBtn d-block">
          <button
            type="button"
            className="modalDiscardBtn modalNextBtn flexBox gap-2 justify-content-center active w-100 m-0"
            // onClick={() => {
            //   // props?.open();
            //   generateTextOptions();
            // }}
            onClick={generateTextOptions}
            disabled={loading}
          >
            <img src={Images.ckBtnLogo} alt="img" />
            Create text
          </button>
        </div>
      </form>
    </div>
  );
};

export default AiTextModal;
