import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Images from "../../../../utilities/images";
import AnnuallyBilling from "./annuallyBilling";
import MonthlyBilling from "./MonthlyBilling";
import { getAllPlansAsync } from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import AddCardModal from "../../scalePlan/addCardModal";
import PaymentDetail from "../../scalePlan/paymentDetails";
import CustomModal from "../../bookings/BookingCalendar/CustomModal";
import Loader from "../../../components/UI/Loader/Loader";
import { useTranslation } from "react-i18next";

const Planfit = ({ selectedPlan, setmoreSetting, planType }) => {
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const { t } = useTranslation();
  // const settingData = useSelector(settingInfo);
  const settingData = [];
  const [activeTab, setActiveTab] = useState("monthly");
  const [plansData, setPlansData] = useState();
  const [activePlan, setActivePlan] = useState([]);
  const [monthlyPlans, setMonthlyPlans] = useState(null);
  const [yearlyPlans, setYearlyPlans] = useState(null);
  const [showCardModal, setShowCardModal] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSelectedPlan, setisSelectedPlan] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [currentPlans, setcurrentPlans] = useState(null);
  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });

    setKey(Math.random());
  };
  // End Modal
  useEffect(() => {
    setisSelectedPlan(selectedPlan);
    setPlanId(selectedPlan?.price_id?.id);
  }, [selectedPlan]);

  // const t = (string) => {
  //     return string
  // }
  useEffect(() => {
    if (planType == "month") {
      setActiveTab("monthly");
    } else {
      setActiveTab("yearly");
    }
  }, [planType]);
  const getAllPlans = () => {
    setLoading(true);
    dispatch(getAllPlansAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false);
        let monthly = obj?.payload?.data?.find(
          (val) => val?.name === "Monthly"
        );
        let yearly = obj?.payload?.data?.find(
          (val) => val?.name === "Annually"
        );
        setMonthlyPlans(monthly);
        setYearlyPlans(yearly);
        // setMonthlyPlans(monthlyPlans);
        // setYearlyPlans(yearlyPlans);
        let planName = selectedPlan?.plan;
        if (planName == "year") {
          setcurrentPlans(yearly);
          setActivePlan("yearly");
        } else setcurrentPlans(monthly);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setcurrentPlans(activeTab == "yearly" ? yearlyPlans : monthlyPlans);
  }, [monthlyPlans, yearlyPlans, activeTab]);
  useEffect(() => {
    getAllPlans();
  }, []);
  const planTypes = (index) => {
    let plansType = ["planCommon_", "professionEnter", "bussinessEnter"];
    return plansType[index];
  };
  const handlePlanData = (id) => {
    const data = {
      flag: "paymentCardInfo",
      id: id,
    };
    handleOpenModal("paymentCardInfo");
  };
  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="row">
          <div>
            <div className="settingOuter planFitRight">
              <h4 className="appointMain text-center">{t("planFitScale")}</h4>
              <h6 className="countNumber_ text-center mt-2">
                {t("simplePricing")}
              </h6>

              {showCardModal ? (
                ""
              ) : (
                //   <AddCardModal
                //     plan_id={selectedPlanId}
                //     setShowCardModal={setShowCardModal}
                //     goToMain={() => props?.handleTouch("Plans")}
                //   />
                <div className="PlansTabSection">
                  <div className="planSubTab">
                    <div className="plansTabBtn">
                      <button
                        className={`billingActiveBtn ${
                          activeTab === "monthly" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("monthly")}
                        type="button"
                      >
                        {t("monthlyBilling")}
                      </button>
                      <button
                        className={`billingActiveBtn ${
                          activeTab === "yearly" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("yearly")}
                        type="button"
                      >
                        {"Yearly Billing"}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    {currentPlans?.prices
                      ?.sort((a, b) => a.unit_amount - b.unit_amount)
                      ?.map((plan, index) => (
                        <div
                          onClick={() => {
                            setPlanId(plan?.id);
                          }}
                          key={index}
                          className="col-lg-4 mt-4"
                        >
                          <div
                            className={`planCommon_ ${planTypes(index)}  ${
                              planId === plan?.id ? "active monthEnter" : ""
                            }`}
                          >
                            <div className="monthSubEnter professionEnterSub">
                              <h2 className="entreText">
                                {plan?.metadata?.name}
                              </h2>
                              <p className="orderPara">{plan?.nickname}</p>
                              <h4 className="settingText my-2">
                                {t("appIncluded")}:
                              </h4>
                              {JSON.parse(
                                plan?.metadata?.app_included || []
                              ).map((app, index) => (
                                <div key={index} className="planTickBox">
                                  <img
                                    src={
                                      app?.included
                                        ? Images.cyanCircleTick
                                        : Images?.lightCircleTick
                                    }
                                    alt={` ${
                                      app?.included
                                        ? "cyanCircleTick"
                                        : "lightCircleTick"
                                    }  image`}
                                    className="img-fluid"
                                  />

                                  <h4 className="planCyan text-uppercase">
                                    JOBR {app?.feature}
                                  </h4>
                                </div>
                              ))}
                              {/* <div className="planTickBox">
                                              <img
                                                src={Images.cyanCircleTick}
                                                alt="cyanCircleTick image"
                                                className="img-fluid"
                                              />
                                              
                                              <h4 className="planCyan">JOBR POS</h4>
                                            </div>
                                            <div className="planTickBox">
                                              <img
                                                src={Images.cyanCircleTick}
                                                alt="cyanCircleTick image"
                                                className="img-fluid"
                                              />
                                            
                                              <h4 className="planCyan">JOBR Wallet</h4>
                                            </div>
                                            <div className="planTickBox">
                                              <img
                                                src={Images.lightCircleTick}
                                                alt="lightCircleTick image"
                                                className="img-fluid"
                                              />
                                              <h4 className="planPara">JOBR Driver</h4>
                                            </div>
                                            <div className="planTickBox">
                                              <img
                                                src={Images.lightCircleTick}
                                                alt="lightCircleTick image"
                                                className="img-fluid"
                                              />
                                              <h4 className="planPara">JOBR B2B</h4>
                                            </div> */}
                              <hr className="dottedDivide" />
                              {JSON.parse(plan?.metadata?.features || []).map(
                                (feature, index) => (
                                  <div key={index} className="planTickBox m-0">
                                    <img
                                      src={Images.simpleCheck}
                                      alt="simpleCheck image"
                                      className="img-fluid"
                                    />
                                    <h4 className="planCyan">{feature}</h4>
                                  </div>
                                )
                              )}
                              {/* <div className="planTickBox">
                                              <img
                                                src={Images.simpleCheck}
                                                alt="simpleCheck image"
                                                className="img-fluid"
                                              />
                                              <h4 className="planCyan">Shareable product pages</h4>
                                            </div>
                                            <div className="planTickBox">
                                              <img
                                                src={Images.simpleCheck}
                                                alt="simpleCheck image"
                                                className="img-fluid"
                                              />
                                              <h4 className="planCyan">Unlimited products</h4>
                                            </div>
                                            <div className="planTickBox">
                                              <img
                                                src={Images.simpleCheck}
                                                alt="simpleCheck image"
                                                className="img-fluid"
                                              />
                                              <h4 className="planCyan">24/7 support</h4>
                                            </div>
                                            <div className="planTickBox">
                                              <img
                                                src={Images.simpleCheck}
                                                alt="simpleCheck image"
                                                className="img-fluid"
                                              />
                                              <h4 className="planCyan">Abandoned cart recovery</h4>
                                            </div>
                                            <div className="planTickBox">
                                              <img
                                                src={Images.simpleCheck}
                                                alt="simpleCheck image"
                                                className="img-fluid"
                                              />
                                              <h4 className="planCyan">Advanced report builder</h4>
                                            </div> */}
                            </div>
                            <div>
                              <div className="subscribeRightn my-3 text-center">
                                <span className="subscribeText">
                                  $
                                  {Number(plan?.unit_amount / 100 || 0).toFixed(
                                    2
                                  )}
                                </span>
                                <span className="SubscribePara">
                                  {" "}
                                  /{currentPlans?.name}
                                </span>
                              </div>
                              {isSelectedPlan?.price_id?.id == plan.id ? (
                                <button className="monthBtn">
                                  {t("subscribed")}
                                </button>
                              ) : (
                                <>
                                  <button
                                    onClick={() => {
                                      handlePlanData(plan?.id);
                                    }}
                                    className="changeBtn"
                                  >
                                    Change
                                    <img
                                      src={Images.ArrowRight}
                                      alt="lightCircleTick image"
                                      className="img-fluid ms-1"
                                    />
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* <div className="billingSubSection">
                  <div className="row">
                    {settingData?.loading ? (
                      <div className="loaderOuter">
                        <div className="spinner-grow loaderSpinner text-center my-5"></div>
                      </div>
                    ) : (
                      <>
                        {plansData && plansData?.length > 0 ? (
                          <>
                            {dataToDisplay?.map((item, index) => (
                              <div
                                key={index}
                                className="col-xl-4 col-lg-6 mt-4"
                              >
                                <div
                                  className={
                                    item?.id === activePlan?.price_id?.id
                                      ? "monthEnter"
                                      : "professionEnter"
                                  }
                                >
                                  <div className="professionEnterSub">
                                    <h3
                                      className={
                                        item?.id === activePlan?.price_id?.id
                                          ? "entreText"
                                          : "returnConfirmedHeading p-0"
                                      }
                                    >
                                      {item?.metadata?.name}
                                    </h3>
                                    <p className="orderPara">
                                      {item?.nickname}
                                    </p>
                                    <h4 className="settingText my-2">
                                      {t("appIncluded")}:
                                    </h4>
                                    {included_apps?.map((tag, index) => (
                                      <>
                                        <div
                                          key={index}
                                          className="planTickBox"
                                        >
                                         

                                          <img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' />
                                          <h4
                                            className={
                                              tag?.plan?.includes(
                                                item?.metadata?.name
                                              )
                                                ? "planCyan text-uppercase"
                                                : "professionText text-uppercase"
                                            }
                                          >
                                            JOBR {tag?.name}
                                          </h4>
                                        </div>
                                      </>
                                    ))}
                                    <hr className="dottedDivide" />
                                    {tags?.map((value, index) => (
                                      <>
                                        {
                                          value?.plan?.includes(
                                            item?.metadata?.name
                                          ) && (
                                            <div className="planTickBox">
                                              <img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' />
                                              <h4 className="planCyan">
                                                {value?.name}
                                              </h4>
                                            </div>
                                          )

                                          // : (
                                          //   <h6
                                          //     key={index}
                                          //     className="professionText"
                                          //   >
                                          //     {value?.name}
                                          //   </h6>
                                          // )
                                        }
                                      </>
                                    ))}
                                  </div>
                                  <div className="subscribeRightn my-3 text-center">
                                    <span className="subscribeText">
                                      ${item?.unit_amount / 100}.00
                                    </span>
                                    <span className="SubscribePara"> /mo</span>
                                  </div>
                                  {item?.id === activePlan?.price_id?.id ? (
                                    <button className="monthBtn">
                                      {t("subscribed")}
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => handleSubscribePlan(item)}
                                      className="changeBtn"
                                    >
                                      {!activePlan ? "Buy" : "Change"}

                                      <img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' />
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="loaderOuter">
                            <p>{t("noDatafound")}</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* custom modal */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "paymentCardInfo"
            ? "aboutBusinessModal"
            : modalDetail.flag === "AddCard"
            ? "deactivatemodal"
            : ""
        }
        size={
          modalDetail.flag === "PickPlan"
            ? "xl"
            : modalDetail.flag === "addBalance"
            ? "md"
            : modalDetail.flag === "FindProduct"
            ? "lg"
            : modalDetail.flag === "paymentCardInfo"
            ? "lg"
            : modalDetail.flag === "AddCard"
            ? "lg"
            : "md"
        }
        child={
          modalDetail.flag === "paymentCardInfo" ? (
            <AddCardModal
              details={(e) => handleOpenModal(e)}
              close={() => {
                handleOnCloseModal();
                setmoreSetting();
              }}
              isPlanPage={true}
              openModalflag={(flag) => handleOpenModal(flag)}
              selectedPlanId={planId}
            />
          ) : (
            (modalDetail.flag = "AddCard" ? (
              <PaymentDetail
                selectedPlanId={planId}
                setSelectedPlanId={planId}
                close={() => {
                  handleOnCloseModal();
                  getAllPlans();
                }}
                openModalflag={(flag) => handleOpenModal(flag)}
              />
            ) : (
              <></>
            ))
          )
        }
        header={
          modalDetail.flag == "paymentCardInfo" ? (
            <div className="d-flex justify-content-end flexBox w-100">
              <img
                src={Images.modalCross}
                className="ModalCancel cursorPointer"
                onClick={handleOnCloseModal}
              />
            </div>
          ) : (
            <></>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Planfit;
