import React from 'react'

const MarketHeader = ({ className, img, value, heading }) => {
    console.log({ className, img, value, heading }, "props");

    return (
        <div className="col-sm-6 col-md-3 col-lg-3">
            <div className={`percentageContainer ${className}`}>
                <img src={img} alt="img" />
                <div className="percentageValues homeBoxesValues">
                    <p className="textInner">{heading}</p>
                    <h3 className="heading_">{value}</h3>
                </div>
            </div>
        </div>
    );
}

export default MarketHeader;
