import React, { forwardRef, useEffect, useState } from "react";
import PaginationHeader from "../../components/PaginationHeader";
import * as Images from "../../../utilities/images";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  getPosUsersAsync,
  selectLoginAuth,
} from "../../containers/auth/authSlice";
import moment from "moment-timezone";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { useTranslation } from "react-i18next";
import {
  getSessionHistoryAsync,
  selectSessionData,
} from "./myPosSlice";
import SelectMenu from "../../components/UI/Orders/SelectMenu";
import DatePicker from "react-datepicker";

const PosSales = () => {
  const { t } = useTranslation();
  // const history = useHistory();
  // const location = useLocation();
  // const [key, setKey] = useState(Math.random());
  const dispatch = useDispatch();
  const [calanderDate, setCalanderDate] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [posList, setPosList] = useState([]);
  // const [allServiceData, setAllServiceData] = useState([]);
  // const [selectedId, setSelectedId] = useState("");
  // const [selectedRow, setSelectedRow] = useState("");
  const auth = useSelector(selectLoginAuth);
  const getSession = useSelector(selectSessionData);
  // const [filter, setFilter] = useState(
  //   location?.state?.filter ? location?.state?.filter : false
  // );
  // const [needAllPickUp, setNeedAllPickup] = useState(
  //   location?.state?.isNeedAllPickup ? location?.state?.isNeedAllPickup : false
  // );
  // const [needReturn, setNeedReturn] = useState(
  //   location?.state?.isNeedReturnFalse
  //     ? location?.state?.isNeedReturnFalse
  //     : false
  // );
  // const [listData, setListData] = useState([]);
  // const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [staffList, setGetAllStaffList] = useState([]);
  const [staffId, setStaffId] = useState("");

  const getPosListingData = () => {
    const data = {
      postData: {
        page: currentPage,
        limit: perPage,
        ...(calanderDate !== null && {
          filter_date: moment(calanderDate).format("YYYY-MM-DD"),
        }),
        ...(staffId !== "" &&
          staffId !== "all" && {
          pos_user_id: staffId,
        }),
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getSessionHistoryAsync(data))
      .then(unwrapResult)
      .then((res) => { })
      .catch((err) => { });
  };

  useEffect(() => {
    getPosListingData();
  }, [perPage, currentPage, staffId, calanderDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Payroll & Staff";
  }, []);

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <div
          className="d-flex align-items-center gap-1"
          style={{
            minWidth: "125px",
          }}
        >
          <img
            src={Images.lightCalenderLogo}
            alt="img"
            width={24}
            height={24}
          />
          {value || moment().format("DD MMM, YYYY")}
        </div>
        <img
          src={Images.blueDownArrow}
          width={24}
          height={24}
          alt="drop_icon"
        />
      </button>
    )
  );

  const getUserLists = () => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getPosUsersAsync(data))
      .then(unwrapResult)
      .then((res) => {
        const staffSelect = res?.payload?.pos_staff.map((item) => ({
          value: item?.user?.unique_uuid,
          label: item?.user?.user_profiles?.firstname,
        }));
        staffSelect?.unshift({ value: "all", label: "All" });
        setGetAllStaffList(staffSelect);
      })
      .catch((e) => { });
  };

  useEffect(() => {
    getUserLists();
  }, []);

  return (
    <>
      <DashboardNavbar title={t('staffSales')} />
      <div className="walletStats h-100">
        <div className="cashDrawOuter cashDrawSessionMain">
          <div className="sessionHeader">
            <div className="row">
              <div className="col-md-5">
                <div className="flexBox gap-3">
                  <div style={{ maxWidth: "190px" }}>
                    <DatePicker
                      selected={calanderDate}
                      onChange={(date) => setCalanderDate(date)}
                      customInput={
                        <ExampleCustomInput className="datePickerInput commonfildate" />
                      }
                    />
                  </div>
                  <div className="reactSelectParent offerHeaderSelect">
                    <SelectMenu
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "160px !important",
                          minWidth: "160px !important",
                        }),
                      }}
                      options={staffList}
                      placeholder={t('allStaff')}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setStaffId(e?.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <PaginationHeader
                currentPage={currentPage}
                totalPage={getSession?.sessionData?.payload?.total}
                perPage={perPage}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                dataLength={getSession?.sessionData?.payload?.data?.length}
              />
            </div>
          </div>

          <div className="table-responsive commonTable mt-4">
            <table className="table customers-stats-table mt-0 ">
              <thead>
                <tr>
                  <th
                    className="customers-table-data"
                    style={{
                      border: "none",
                      color: "#7E8AC1",
                      textAlign: "left",
                    }}
                  >
                    #
                  </th>
                  <th
                    className="customers-table-data"
                    style={{
                      border: "none",
                      color: "#7E8AC1",
                      textAlign: "left",
                    }}
                  >
                    {t("date")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("starts")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("ends")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("endedBy")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("sessionStarted")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("totalCashIn")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("totalCashOut")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("countedCash")}
                  </th>
                  <th
                    className="customers-table-data"
                    style={{ border: "none", color: "#7E8AC1" }}
                  >
                    {t("sessionEnded")}
                  </th>
                </tr>
              </thead>
              {getSession?.loading ? (
                <tbody>
                  <tr className="customers-table-row">
                    <td colSpan={9}>
                      <div className="text-center">
                        <div className="spinner-grow loaderSpinner text-center my-5"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {getSession?.sessionData?.payload?.data?.length == 0 ? (
                    <td className="text-center" colSpan={12}>
                      {t("noDatafound")}
                    </td>
                  ) : (
                    getSession?.sessionData?.payload?.data?.map((item, idx) => (
                      <tr className="customers-table-row">
                        <td
                          // onClick={() => handleNavigateToTrackStatus(item)}
                          className="orderTrackTableTxt"
                        >
                          {currentPage > 1 ? (
                            <>{(currentPage - 1) * perPage + idx + 1}</>
                          ) : (
                            <>{idx + 1}</>
                          )}
                        </td>
                        <td className="orderTrackTableTxt">
                          {item?.created_at
                            ? moment(item?.created_at).format("L")
                            : "date not found"}
                        </td>
                        <td className="orderTrackTableTxt">
                          {item?.cash_drawer_session &&
                            item?.cash_drawer_session?.start_session != null &&
                            moment(item?.cash_drawer_session?.start_session)
                              .local()
                              .format("hh:mm A")}
                          {!item?.cash_drawer_session &&
                            item?.start_session !== null &&
                            moment(item?.start_session)
                              .local()
                              .format("hh:mm A")}
                        </td>
                        <td className="orderTrackTableTxt">
                          {item?.cash_drawer_session &&
                            item?.cash_drawer_session?.end_session != null &&
                            moment(item?.cash_drawer_session?.end_session)
                              .local()
                              .format("hh:mm A")}
                          {!item?.cash_drawer_session &&
                            item?.end_session !== null &&
                            moment(item?.end_session).local().format("hh:mm A")}
                        </td>
                        <td className="orderTrackTableTxt">
                          <div className="d-flex align-items-center gap-1 justify-content-center">
                            <img
                              alt="okskks"
                              className="sessionImg"
                              width={36}
                              height={36}
                              style={{
                                borderRadius: "50%",
                              }}
                              src={
                                item?.pos_user_details?.user_profiles
                                  ?.profile_photo
                                  ? item?.pos_user_details?.user_profiles
                                    ?.profile_photo
                                  : Images.userImg_
                              }
                            />
                            <h6 className="orderTrackTableTxt m-0">
                              {item?.pos_user_details?.user_profiles
                                ?.firstname == undefined
                                ? t("systemEnded")
                                : item?.pos_user_details?.user_profiles
                                  ?.firstname}
                            </h6>
                          </div>
                        </td>
                        <td className="orderTrackTableTxt">
                          {item?.start_tracking_session
                            ? item.start_tracking_session
                            : "$0.00"}
                        </td>
                        <td className="orderTrackTableTxt">
                          {item?.add_cash ? item.add_cash : "$0.00"}
                        </td>
                        <td className="orderTrackTableTxt">
                          {item?.removed_cash ? item.removed_cash : "$0.00"}
                        </td>
                        <td className="orderTrackTableTxt">
                          {item?.counted_cash ? item.counted_cash : "$0.00"}
                        </td>
                        <td className="orderTrackTableTxt">
                          <div
                            style={{
                              backgroundColor:
                                item.end_tracking_session < 0
                                  ? "#FEEFC6"
                                  : "#F5F6FC",
                              color:
                                item.end_tracking_session < 0
                                  ? "#DC6803"
                                  : "#263682",
                              padding: "6px 14px",
                              borderRadius: " 30px",
                              width: "auto",
                              display: "inline-block",
                            }}
                          >
                            {item?.end_tracking_session
                              ? item.end_tracking_session?.toFixed(2)
                              : "$0.00"}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PosSales;
