import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your translation JSON files
import en from "./app/public/locales/en/common.json";
import es from "./app/public/locales/es/common.json";
import pt from "./app/public/locales/pt/common.json";
import ru from "./app/public/locales/ru/common.json";
import it from "./app/public/locales/it/common.json";
import fr from "./app/public/locales/fr/common.json";

i18n
  .use(initReactI18next) // Bind i18next to react-i18next
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
      pt: { translation: pt },
      ru: { translation: ru },
      it: { translation: it },
      fr: { translation: fr },
    },
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language if the current language translation is not available
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
