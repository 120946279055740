import React, { useEffect, useRef, useState } from "react";
import { infoImg, user } from "../../../utilities/images";
import { CKEditor } from "ckeditor4-react";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import * as Images from "../../../utilities/images";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ProductDetails = (props) => {
  const { t } = useTranslation();
  const toastId = useRef(null);
  const location = useLocation();
  const userData = location.state;
  let productResponse;
  const [productName, setProductName] = useState(userData?.name ?? "");
  const [bufferTime, setBufferTime] = useState(false);
  const [serviceCapacity, setServiceCapacity] = useState(false);

  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [productDescription, setProductDescription] = useState(
    userData?.description ?? ""
  );
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState(
    userData?.service_id ?? "Select Services"
  );
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(
    userData?.category_id ?? "Select Category"
  );
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryId, setSubcategoryId] = useState(
    userData?.sub_category_id ?? "Select Sub-Category"
  );
  const [brandId, setBrandId] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const dispatch = useDispatch();

  const [selectedHourService, setSelectedHourService] = useState(null); // Hour selection
  const [selectedMinuteService, setSelectedMinuteService] = useState(null); // Minute selection

  const [bufferHourBefore, setBufferHourBefore] = useState(null);
  const [bufferMinBefore, setBufferMinBefore] = useState(null);
  const [bufferHourAfter, setBufferHourAfter] = useState(null);
  const [bufferMinAfter, setBufferMinAfter] = useState(null);

  const [minimumValue, setMinimumValue] = useState(null);
  const [maximumValue, setMaximumValue] = useState(null);

  const selectValuesMinMaxOptions = Array.from({ length: 20 }, (_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }));

  const selectValuesHoursOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i,
    label: `${i} Hour${i !== 1 ? "s" : ""}`,
  }));
  // const selectValuesMinutesOptions = Array.from({ length: 60 }, (_, i) => ({
  //   value: i,
  //   label: `${i} Minute${i !== 1 ? "s" : ""}`,
  // }));

  const selectValuesMinutesOptions = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1) * 5,
    label: `${(i + 1) * 5} Minutes`,
  }));

  // Hour and minute options for service
  const hourOptions = Array.from({ length: 24 }, (_, i) => ({
    value: i,
    label: `${i} Hour${i !== 1 ? "s" : ""}`,
  }));

  const minuteOptions = Array.from({ length: 60 }, (_, i) => ({
    value: i,
    label: `${i} Minute${i !== 1 ? "s" : ""}`,
  }));

  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleMinimumChange = (selectedOption) => {
    setMinimumValue(selectedOption);
    props.productDetails(selectedOption?.value, "minimum");
  };

  const handleMaximumChange = (selectedOption) => {
    setMaximumValue(selectedOption);
    props.productDetails(selectedOption?.value, "maximum");
  };

  const handleHourChange = (selectedOption) => {
    setSelectedHourService(selectedOption);

    // Calculate approx_service_time immediately using the selectedOption
    const approxServiceTime =
      (selectedOption?.value || 0) * 60 + (selectedMinuteService?.value || 0);

    // Create product response with the calculated approx_service_time
    const productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
      approx_service_time: approxServiceTime, // Total time in minutes
      buffer_time_before:
        (bufferHourBefore?.value || 0) * 60 + (bufferMinBefore?.value || 0),
      buffer_time_after:
        (bufferHourAfter?.value || 0) * 60 + (bufferMinAfter?.value || 0),
    };

    // Call productDetails with the updated productResponse
    props.productDetails(productResponse, "");
  };

  const handleMinuteChange = (selectedOption) => {
    // Calculate approx_service_time immediately using the selectedOption
    const approxServiceTime =
      (selectedHourService?.value || 0) * 60 + (selectedOption?.value || 0);

    // Enforce minimum service time limit
    const minServiceTime = props?.timeSlots || 0;

    if (approxServiceTime < minServiceTime) {
      showToast(`Service time cannot be less than ${minServiceTime} minutes.`);
      return;
    }

    setSelectedMinuteService(selectedOption);

    // Create product response with the calculated approx_service_time
    const productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
      approx_service_time: approxServiceTime, // Total time in minutes
      buffer_time_before:
        (bufferHourBefore?.value || 0) * 60 + (bufferMinBefore?.value || 0),
      buffer_time_after:
        (bufferHourAfter?.value || 0) * 60 + (bufferMinAfter?.value || 0),
    };

    // Call productDetails with the updated productResponse
    props.productDetails(productResponse, "");
  };

  const handleBufferHourChange = (selectedOption, field) => {
    if (field === "before") {
      setBufferHourBefore(selectedOption);
    } else if (field === "after") {
      setBufferHourAfter(selectedOption);
    }

    // Calculate total buffer time in minutes
    const totalBufferTimeBefore =
      (bufferHourBefore?.value || 0) * 60 + (bufferMinBefore?.value || 0);
    const totalBufferTimeAfter =
      (bufferHourAfter?.value || 0) * 60 + (bufferMinAfter?.value || 0);

    // Create product response with buffer times
    const productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
      buffer_time_before: totalBufferTimeBefore, // Total time in minutes
      buffer_time_after: totalBufferTimeAfter, // Total time in minutes
      approx_service_time:
        (selectedHourService?.value || 0) * 60 +
        (selectedMinuteService?.value || 0), // Approx service time
    };

    props.productDetails(productResponse, "");
  };

  const handleBufferMinuteChange = (selectedOption, field) => {
    if (field === "before") {
      setBufferMinBefore(selectedOption);
    } else if (field === "after") {
      setBufferMinAfter(selectedOption);
    }

    // Calculate total buffer time in minutes
    const totalBufferTimeBefore =
      (bufferHourBefore?.value || 0) * 60 + (selectedOption.value || 0);
    const totalBufferTimeAfter =
      (bufferHourAfter?.value || 0) * 60 + (selectedOption.value || 0);

    // Create product response with buffer times
    const productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
      buffer_time_before: totalBufferTimeBefore, // Total time in minutes
      buffer_time_after: totalBufferTimeAfter, // Total time in minutes
      approx_service_time:
        (selectedHourService?.value || 0) * 60 +
        (selectedMinuteService?.value || 0), // Approx service time
    };

    props.productDetails(productResponse, "");
  };

  const handleKeyDownSubcategory = (event) => {
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = subCategory?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (subCategory?.length > 0) {
            setSubCategory([...subCategory, newOption]);
          } else {
            setSubCategory([newOption]);
          }
        }
      }
    }
  };

  const handleServicesChange = (e) => {
    setCategoryLoading(true);
    setSelectedService(e);
    setServicesId(e.value);
    setSelectedCategory("");
    setSelectedSubCategory("");
    setCategory([]);
    setCategoryId("");
    setSubCategory([]);
    setSubcategoryId("");
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: e.value,
        main_category: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategory(filterResult);
        setCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: e?.value,
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
          approx_service_time:
            (selectedHourService?.value || 0) * 60 +
            (selectedMinuteService?.value || 0), // Approx service time
          buffer_time_before:
            (bufferHourBefore?.value || 0) * 60 + (bufferMinBefore?.value || 0),
          buffer_time_after:
            (bufferHourAfter?.value || 0) * 60 + (bufferMinAfter?.value || 0),
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (e) => {
    setSubCategoryLoading(true);
    setCategoryId(e.value);
    setSelectedCategory(e);
    setSubCategory([]);
    setSubcategoryId("");
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        need_subcategory: true,
        check_product_existance: false,
        // main_category: true,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSubCategory(filterResult);
        setSubCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: e.value,
          sub_category_id: subCategoryId,
          brand_id: brandId,
          approx_service_time:
            (selectedHourService?.value || 0) * 60 +
            (selectedMinuteService?.value || 0), // Approx service time
          buffer_time_before:
            (bufferHourBefore?.value || 0) * 60 + (bufferMinBefore?.value || 0),
          buffer_time_after:
            (bufferHourAfter?.value || 0) * 60 + (bufferMinAfter?.value || 0),
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSubCategoryChange = (e) => {
    setSubcategoryId(e.value);
    setBrandId("");
    setSelectedSubCategory(e);

    productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: e.value,
      brand_id: brandId,
      approx_service_time:
        (selectedHourService?.value || 0) * 60 +
        (selectedMinuteService?.value || 0), // Approx service time
      buffer_time_before:
        (bufferHourBefore?.value || 0) * 60 + (bufferMinBefore?.value || 0),
      buffer_time_after:
        (bufferHourAfter?.value || 0) * 60 + (bufferMinAfter?.value || 0),
    };
    props.productDetails(productResponse, "");
  };

  const handleProductName = (e) => {
    setProductName(e.target.value);
    productResponse = {
      productName: e.target.value,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
      approx_service_time:
        (selectedHourService?.value || 0) * 60 +
        (selectedMinuteService?.value || 0), // Approx service time
      buffer_time_before:
        (bufferHourBefore?.value || 0) * 60 + (bufferMinBefore?.value || 0),
      buffer_time_after:
        (bufferHourAfter?.value || 0) * 60 + (bufferMinAfter?.value || 0),
    };
    props.productDetails(productResponse, "");
  };
  const handleProductDescription = (e) => {
    props.productDetails(e, "description");
    setProductDescription(e);
  };

  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "service",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
        // setServices(obj.payload.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const setInitialData = () => {
    if (userData?.from === "duplicateService") {
      setProductName(userData?.name ?? "");
      setServicesId(userData?.service_id ?? "");
      handleServicesChange({ target: { value: userData?.service_id ?? "" } });
      setCategoryId(userData?.category_id ?? "");
      handleCategoryChange({ target: { value: userData?.category_id ?? "" } });
      setSubcategoryId(userData?.sub_category_id ?? "");
    }
  };

  const getEditServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "service",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        let selectedMastercategory = userData?.service_id.toString();
        setServicesId(selectedMastercategory ? selectedMastercategory : null);
        let selectedService = filterResult.find(
          (item) => item.value == selectedMastercategory
        );
        setSelectedService(selectedService ? selectedService : null);
        setServices(filterResult);
        let data = {
          tokenData: auth?.payload?.token,
          postData: {
            service_id: selectedMastercategory,
            main_category: true,
            check_product_existance: false,
          },
        };
        setLoading(true);
        dispatch(getCategoriesAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false);

            let filterResult = obj?.payload?.data?.map((item) => {
              return {
                value: item?.id,
                label: item?.name,
              };
            });
            setCategory(filterResult);

            let selectedCategory = userData?.category_id.toString();
            setCategoryId(selectedCategory ? selectedCategory : null);
            let selectedCatrgory = filterResult.find(
              (item) => item.value == selectedCategory
            );
            setSelectedCategory(selectedCatrgory ? selectedCatrgory : null);
            let data = {
              tokenData: auth?.payload?.token,
              postData: {
                category_id: selectedCategory,
                need_subcategory: true,
                check_product_existance: false,
              },
            };
            setLoading(true);
            dispatch(getCategoriesAsync(data))
              .then(unwrapResult)
              .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                  return {
                    value: item?.id,
                    label: item?.name,
                  };
                });
                setSubCategory(filterResult);
                let selectedSubCategory = userData?.sub_category_id.toString();
                setSubcategoryId(
                  selectedSubCategory ? selectedSubCategory : null
                );

                let selectedsubCatrgory = filterResult.find(
                  (item) => item.value == selectedSubCategory
                );
                setSelectedSubCategory(
                  selectedsubCatrgory ? selectedsubCatrgory : null
                );
              })
              .catch((obj) => {
                setLoading(false);
              });
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getServices();
    setInitialData();
    getEditServices();
  }, []);

  const handleToggle = (type) => (event) => {
    const isChecked = event.target.checked;
    if (type === "blockTime") {
      setBufferTime(isChecked);
      setBufferHourAfter(null);
      setBufferMinAfter(null);
      setBufferHourBefore(null);
      setBufferMinBefore(null);
    }
    if (type == "setupSevice") {
      setServiceCapacity(isChecked);
      setMinimumValue(null);
      setMaximumValue(null);
    }
  };

  useEffect(() => {
    // setSelectedMinuteService({
    //   value: props?.timeSlots,
    //   label: `${props?.timeSlots} Minutes`,
    // });
    handleMinuteChange({
      value: props?.timeSlots,
      label: `${props?.timeSlots} Minutes`,
    });
  }, [props?.timeSlots]);

  return (
    <>
      <div className="productDetailsContainer">
        <h2 className="fontSize18 fw500 txtDarkblue">{t("serviceDetail")}</h2>

        {/* Start ProductName Area */}
        <div className="productDetailsSpc mt-4">
          <form className="productName">
            <div className="row">
              <div className="col-md-12 ">
                <div className="form-group">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("serviceName")}
                  </label>
                  <div className="inputGroup">
                    <input
                      className="customInput"
                      placeholder={t("serviceName")}
                      name="text"
                      type="text"
                      value={productName}
                      onChange={(e) => handleProductName(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* End ProductName Area */}

        {/* Start ProductCategory Area */}

        <form className="ProductCategory mt-3 mb-4">
          <h2 className="fontSize18 fw500 txtDarkblue mb-0">
            {t("serviceCategory")}
          </h2>
          <div className="row">
            <div className="col-md-12">
              <div className="form_group">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("masterCategory")}
                  </label>
                  <ReactSelect
                    value={selectedService}
                    options={services}
                    onChange={handleServicesChange}
                    isSearchable={true}
                    placeholder={t("selectOne")}
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_group">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("category")}
                  </label>
                  <ReactSelect
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={category}
                    isDisabled={!servicesId}
                    isSearchable={true}
                    placeholder={
                      category && categoryLoading
                        ? t("loadingCategory")
                        : t("selectOne")
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("subcategory")}
                  </label>
                  <ReactSelect
                    value={selectedSubCategory}
                    onChange={handleSubCategoryChange}
                    onKeyDown={handleKeyDownSubcategory}
                    options={subCategory}
                    isDisabled={!categoryId}
                    isSearchable={true}
                    placeholder={
                      subCategory && subCategoryLoading
                        ? t("loadingCategory")
                        : t("selectOne")
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />

                  <p className="messageTxt ps-4">
                    {t("subcategoryInfo")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>

        {/* End ProductCategory Area */}
        {/* Start CK EditorArea */}
        <div>
          <label className="fontSize16 txtDarkblue ps-4 ">{t("description")}</label>
          <div className="productDetailsSpc">
            <CKEditor
              initData={productDescription}
              config={{
                versionCheck: false,
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                  "image",
                ],
              }}
              name="editor"
              readOnly={false}
              onChange={(event, editor) => {
                const data = event.editor.getData();
                handleProductDescription(data);
              }}
              type="classic"
            />
            {/* <button className="ckEditorBtn">
              <img src={Images.ckBtnLogo} alt="logo" /> Generate AI Text
            </button> */}
          </div>
        </div>

        <div className="row">
          <form className="ProductCategory mt-3">
            <h2 className="fontSize18 fw500 txtDarkblue mb-0 d-flex align-items-cetner">
              {t("serviceEstimateTime")}
              <img src={Images.infoIcon} alt="logo" className="ms-2" />
            </h2>
            <div className="row">
              <div className="col-md-6">
                <div className="form_group">
                  <div className="reactSelectParent commonSelectParent w-100">
                    <label className="fontSize16 txtDarkblue mb-2 ps-4">
                      {t("hour")}
                    </label>
                    <ReactSelect
                      value={selectedHourService}
                      onChange={handleHourChange}
                      options={hourOptions}
                      isSearchable={true}
                      placeholder="0 Hours"
                      classNamePrefix="react-select"
                      className="react-select-container anlyticSelect"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "100% !important",
                          width: "100%",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid #D7DEFF",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          paddingLeft: "unset !important",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#263682"
                            : state.isFocused
                            ? "#f5f6fc"
                            : "#fff",

                          color: state.isSelected ? "#fff" : "#263682",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#636E9F",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                      }}
                      components={{
                        DropdownIndicator: () => (
                          <img
                            src={Images.selectImg}
                            width={24}
                            height={24}
                            alt="drop_icon"
                            className="mt-1"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_group">
                  <div className="reactSelectParent commonSelectParent w-100">
                    <label className="fontSize16 txtDarkblue mb-2 ps-4">
                      {t("minute")}
                    </label>
                    <ReactSelect
                      value={selectedMinuteService}
                      onChange={handleMinuteChange}
                      options={minuteOptions}
                      isSearchable={true}
                      placeholder="0 Minutes"
                      classNamePrefix="react-select"
                      className="react-select-container anlyticSelect"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "100% !important",
                          width: "100%",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid #D7DEFF",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          paddingLeft: "unset !important",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#263682"
                            : state.isFocused
                            ? "#f5f6fc"
                            : "#fff",

                          color: state.isSelected ? "#fff" : "#263682",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#636E9F",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                      }}
                      components={{
                        DropdownIndicator: () => (
                          <img
                            src={Images.selectImg}
                            width={24}
                            height={24}
                            alt="drop_icon"
                            className="mt-1"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="col-md-12">
            <div className="productDetailsContainer">
              <div className="productSale_Parent serviceSale_Parent  mt-0 justify-content-between align-items-start">
                <div>
                  <h3 className="fontSize16 fw500 txtDarkblue mb-3">
                    {t("blockTime")}
                  </h3>
                  <h3 className="fontSize14 txtLightBlue fw500 mb-0">
                    {t("avoidRushing")}.
                  </h3>
                </div>
                <input
                  type="checkbox"
                  className="cursorPointer"
                  checked={bufferTime}
                  onChange={handleToggle("blockTime")}
                />
              </div>
            </div>

            {bufferTime && (
              <>
                <form className="ProductCategory mt-3">
                  <h2 className="fontSize18 fw500 txtDarkblue mb-0 d-flex align-items-center">
                    {t("bufferTime")}
                    <img src={Images.infoIcon} alt="logo" className="ms-2" />
                  </h2>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form_group">
                        <div className="reactSelectParent commonSelectParent w-100">
                          <label className="fontSize16 txtDarkblue mb-2 ps-4">
                            {t("hour")}
                          </label>
                          <ReactSelect
                            value={bufferHourAfter}
                            onChange={(selectedOption) =>
                              handleBufferHourChange(selectedOption, "after")
                            }
                            options={selectValuesHoursOptions}
                            isSearchable={true}
                            placeholder="0 Hours"
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100% !important",
                                width: "100%",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                paddingLeft: "unset !important",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#263682"
                                  : state.isFocused
                                  ? "#f5f6fc"
                                  : "#fff",

                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#636E9F",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                            }}
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  src={Images.selectImg}
                                  width={24}
                                  height={24}
                                  alt="drop_icon"
                                  className="mt-1"
                                />
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_group">
                        <div className="reactSelectParent commonSelectParent w-100">
                          <label className="fontSize16 txtDarkblue mb-2 ps-4">
                            {t("minute")}
                          </label>
                          <ReactSelect
                            value={bufferMinAfter}
                            onChange={(selectedOption) =>
                              handleBufferMinuteChange(selectedOption, "after")
                            }
                            options={selectValuesMinutesOptions}
                            isSearchable={true}
                            placeholder="0 Minutes"
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100% !important",
                                width: "100%",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                paddingLeft: "unset !important",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#263682"
                                  : state.isFocused
                                  ? "#f5f6fc"
                                  : "#fff",

                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#636E9F",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                            }}
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  src={Images.selectImg}
                                  width={24}
                                  height={24}
                                  alt="drop_icon"
                                  className="mt-1"
                                />
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0 d-flex align-items-center">
                      {t("bufferTimeBefore")}
                      <img src={Images.infoIcon} alt="logo" className="ms-2" />
                    </h2>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form_group">
                          <div className="reactSelectParent commonSelectParent w-100">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4">
                              {t("hour")}
                            </label>
                            <ReactSelect
                              value={bufferHourBefore}
                              onChange={(selectedOption) =>
                                handleBufferHourChange(selectedOption, "before")
                              }
                              options={selectValuesHoursOptions}
                              isSearchable={true}
                              placeholder="0 Hours"
                              classNamePrefix="react-select"
                              className="react-select-container anlyticSelect"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "100% !important",
                                  width: "100%",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  border: "1px solid #D7DEFF",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  paddingLeft: "unset !important",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#263682"
                                    : state.isFocused
                                    ? "#f5f6fc"
                                    : "#fff",

                                  color: state.isSelected ? "#fff" : "#263682",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "#636E9F",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }),
                              }}
                              components={{
                                DropdownIndicator: () => (
                                  <img
                                    src={Images.selectImg}
                                    width={24}
                                    height={24}
                                    alt="drop_icon"
                                    className="mt-1"
                                  />
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_group">
                          <div className="reactSelectParent commonSelectParent w-100">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4">
                              {t("minute")}
                            </label>
                            <ReactSelect
                              value={bufferMinBefore}
                              onChange={(selectedOption) =>
                                handleBufferMinuteChange(
                                  selectedOption,
                                  "before"
                                )
                              }
                              options={selectValuesMinutesOptions}
                              isSearchable={true}
                              placeholder="0 Minutes"
                              classNamePrefix="react-select"
                              className="react-select-container anlyticSelect"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  maxWidth: "100% !important",
                                  width: "100%",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  border: "1px solid #D7DEFF",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  paddingLeft: "unset !important",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#263682"
                                    : state.isFocused
                                    ? "#f5f6fc"
                                    : "#fff",

                                  color: state.isSelected ? "#fff" : "#263682",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "#636E9F",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }),
                              }}
                              components={{
                                DropdownIndicator: () => (
                                  <img
                                    src={Images.selectImg}
                                    width={24}
                                    height={24}
                                    alt="drop_icon"
                                    className="mt-1"
                                  />
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>

          <div className="col-md-12">
            <div className="productDetailsContainer">
              <div className="productSale_Parent serviceSale_Parent mt-0 justify-content-between align-items-start">
                <div>
                  <h3 className="fontSize16 fw500 txtDarkblue mb-3">
                    {t("setupServiceCapacity")}
                  </h3>
                  <h3 className="fontSize14 txtLightBlue fw500 mb-0">
                    {t("avoidServicePressure")}
                  </h3>
                </div>
                <input
                  type="checkbox"
                  className="cursorPointer"
                  checked={serviceCapacity}
                  onChange={handleToggle("setupSevice")}
                />
              </div>
            </div>
            {serviceCapacity && (
              <>
                <form className="ProductCategory mt-3">
                  <h2 className="fontSize18 fw500 txtDarkblue mb-0 d-flex align-items-center">
                    {t("serviceCapacity")}
                    <img src={Images.infoIcon} alt="logo" className="ms-2" />
                  </h2>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form_group">
                        <div className="reactSelectParent commonSelectParent w-100">
                          <label className="fontSize16 txtDarkblue mb-2 ps-4">
                            {t("minimum")}
                          </label>
                          <ReactSelect
                            value={minimumValue}
                            onChange={handleMinimumChange}
                            options={selectValuesMinMaxOptions}
                            isSearchable={true}
                            placeholder="Select Minimum"
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100% !important",
                                width: "100%",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                paddingLeft: "unset !important",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#263682"
                                  : state.isFocused
                                  ? "#f5f6fc"
                                  : "#fff",

                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#636E9F",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                            }}
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  src={Images.selectImg}
                                  width={24}
                                  height={24}
                                  alt="drop_icon"
                                  className="mt-1"
                                />
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_group">
                        <div className="reactSelectParent commonSelectParent w-100">
                          <label className="fontSize16 txtDarkblue mb-2 ps-4">
                            {t("maximum")}
                          </label>
                          <ReactSelect
                            value={maximumValue}
                            onChange={handleMaximumChange}
                            options={selectValuesMinMaxOptions}
                            isSearchable={true}
                            placeholder="Select Maximum"
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                maxWidth: "100% !important",
                                width: "100%",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                paddingLeft: "unset !important",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#263682"
                                  : state.isFocused
                                  ? "#f5f6fc"
                                  : "#fff",

                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#636E9F",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                            }}
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  src={Images.selectImg}
                                  width={24}
                                  height={24}
                                  alt="drop_icon"
                                  className="mt-1"
                                />
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
        {/* End CK EditorArea */}
      </div>
    </>
  );
};

export default ProductDetails;
