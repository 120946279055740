import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginAuth } from '../auth/authSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getAllSupportTicketsListAsync, getSupportTicketCountApiAsync } from '../dashboard/dashboardSlice'
import moment from 'moment-timezone'
import PaginationHeader from '../../components/PaginationHeader'
import { useTranslation } from 'react-i18next'
import NotificationButton from '../../components/NotificationButton'

const Mysupport = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [ticketData, setTicketData] = useState("")
    const [ticketStatistics, setTicketStatistics] = useState("")
    const [filter, setFilter] = useState("active");

    const auth = useSelector(selectLoginAuth)

    const dispatch = useDispatch()
    // get list of all open tickets 
    const getSupporticketsList = () => {
        const data = {
            tokenData: auth?.payload?.token,
            postData: {
                page: currentPage,
                limit: perPage,
                filter: filter
            }
        }
        dispatch(getAllSupportTicketsListAsync(data))
            .then(unwrapResult)
            .then(res => {
                console.log(res)
                setTicketData(res?.payload?.data || {})
                setTotalRows(res?.payload?.total)
            }).catch(error => {
                console.log(error)
            })
    }

    const getOpenTicketsCount = () => {
        const data = {
            tokenData: auth?.payload?.token,
            postData: {
                // user_id: auth?.payload?.uniqe_id || ""
            }
        }
        dispatch(getSupportTicketCountApiAsync(data))
            .then(unwrapResult)
            .then(res => {
                setTicketStatistics(res?.payload || {})
            }).catch(error => {
                console.log(error)
            })
    }
    
    const handleFilterValue = (value) => {
        setFilter(value)
    }   

    useEffect(() => {
        getSupporticketsList();
        getOpenTicketsCount();

    }, [currentPage, perPage]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "My Support";
    }, []);
    return (
        <div className='mysupportMain_ chartsOuter mt-0'>
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="fontSize24 txtDarkblue fw500 mb-0">
                    {t('mySupport')}
                </h3>
                <div className="d-flex align-items-center gap-4">
                    <NotificationButton />
                    <img src={Images.SearchIcon} alt="SearchIcon" />
                    <Link to="/supportTicketinner" className='addNew_' type='submit'>{t('support')} <img src={Images.upArrow} alt="addNew_Image" className='ps-2'></img></Link>
                </div>
            </div>
            <div className="verificationTotal mt-3">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center gap-2">
                            <button
                                className="activeProductBtn active"
                                onClick={() => handleFilterValue("active")}
                            >
                                {t('active')}  
                                <span className="activeProductofferItem">
                                    ({ticketStatistics?.open_tickets || 0})
                                </span>
                            </button>
                            <button
                                className="activeProductBtn "
                                onClick={() => handleFilterValue("expired")}
                            >
                                {t('expired')}
                                <span className="activeProductofferItem">
                                    ({ticketStatistics?.cancel_tickets || 0})
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className='col-lg-8'>
                        <PaginationHeader
                            totalPage={totalRows}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            setPerPage={setPerPage}
                            perPage={perPage}
                            headerClass={"col-md-14"}
                            dataLength={ticketData?.length}
                        />
                    </div>
                </div>
            </div>
            <div className="commonTable  table-responsive mt-4">
                <table className="w-100 couponmain_ mysupporttable_">
                    <thead>
                        <tr >

                            <th>{t('ticket')} #</th>
                            <th><h3 className="fontSize12 mb-0">{t('')}</h3></th>
                            <th>{t('created')}</th>
                            <th>{t('lastRespond')}</th>
                            <th>{t('status')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ticketData?.length > 0 && ticketData.map(item => {
                            return (
                                <tr >
                                    <td className='fontSize12 txtDarkblue'>{item?.track_number}</td>
                                    <td>
                                        <div>
                                            <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                                                {item?.subject?.name || "___"}
                                            </h3>

                                            <div className="d-flex align-items-center gap-2">
                                                <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                    {item?.subject?.description || ""}
                                                </h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="fontSize12 txtDarkblue">
                                        {moment(item?.created_at).format("DD/MM/YYYY") || ""}
                                    </td>
                                    <td className="fontSize12 txtDarkblue">
                                        {moment(item?.updated_at).format("DD/MM/YYYY") || ""}
                                    </td>

                                    <td>
                                        {item?.status?.id === 3 ? (
                                            <Link to={`supportTicketinner?support_id=${item?.id}`}>
                                                <h6 className="fontSize12 txtLightBlue mb-0">
                                                    <span className='Activestatus'>
                                                        {t('active')}
                                                    </span>
                                                    <img src={Images.upArrow} alt="addNew_Image" className='ps-3' /></h6>
                                            </Link>

                                        ) : (
                                            <h6 className="fontSize12 txtLightBlue"><span className='xpired_'>
                                                {item?.status?.name}
                                            </span></h6>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Mysupport