import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import PersonalDetail from "./personalDetail";
import BusinessDetails from "./businessDetail";
import Security from "./security";
import Notificationindex from "./notification";
import CustomerNotification from "./notification/customerNotification";
import StaffNotification from "./notification/staffNotification";
import FeatureNotification from "./notification/featureNotification";
import SalesNotification from "./notification/salesNotification";
import Planfit from "./plansandSubscription/planfit";
import {
  getPosUserdetailsAsync,
  selectAddressList,
  selectedPosUserData,
  selectedPosUserDetails,
  selectLoginAuth,
  selectUserSettings,
} from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getMerchantActiveSubscriptionAsync,
  getSellerAddressesApiAsync,
  getUserSettingsApiAsync,
  updateSellerInfoApiAsync,
  updateUserSettingsApiAsync,
} from "../dashboard/dashboardSlice";
import Loader from "../../components/UI/Loader/Loader";
import { toast } from "react-toastify";
import PlanandSubscription from "./plansandSubscription";
import Languageindex from "./language";
import BusinessLocations from "./BusinessLocations";
import AddEditLocation from "./BusinessLocations/AddEditLocation";
import Languages from "./Languages";
import DeviceConnected from "./DeviceConnected";
import DeviceDetail from "./DeviceConnected/deviceDetail";
// import PolicyIndex from './policies/policyIndex';
import PluginandIntigration from "./pluginandintegration";
import LegalPolicies from "./Policies";
import CreateUpdateLegal from "./Policies/CreateUpdateLegal";
import ViewPolicy from "./Policies/ViewPolicy";
import AddLocation from "./BusinessLocations/AddLocation";
import EditLocation from "./BusinessLocations/EditLocation";
import CashDrawer from "../cashDrawer";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

const MoresettingIndex = () => {
   const { t } = useTranslation();
   const [defaultLanguage, setdefaultLanguage] = useState('English');
   const [storeCount, setStoreCount] = useState(0);
  const [moreSetting, setmoreSetting] = useState("Personal Details");
  const [hasPlan, setHasPlan] = useState(null);
  const [planType, setPlanType] = useState("month");
  const [loading, setLoading] = useState(false);
  const [policy, setPolicy] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("");
  const userPosDetails = useSelector(selectedPosUserDetails);
  const userSettingDetails = useSelector(selectUserSettings);
  const sellerAddressList=useSelector(selectAddressList);
  const [currentPlanDetails, setcurrentPlanDetails] = useState(null)
  const queryParameters = new URLSearchParams(window.location.search)

  useEffect(() => {
    if(queryParameters.get("addLocation") === "1") {
      setmoreSetting("AddLocation",null);
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "More Settings";
  }, []);
  const [locationDetails, setlocationDetails] = useState({
    isNew: true,
    location: {},
  });
  const included_apps = [
    { name: "JOBR MARKET", plan: ["Basic", "Standard", "Premium"] },
    { name: "JOBR CHECKOUT", plan: [, "Standard", "Premium"] },
    { name: "JOBR DRIVE", plan: ["Standard", "Premium"] },
    { name: "JOBR TRADE", plan: ["Premium"] },
  ];
  const tags = [
    { name: "Online Store", plan: ["Basic", "Standard", "Premium"] },
    { name: "Shareable products", plan: ["Basic", "Standard", "Premium"] },
    { name: "Unlimited products", plan: ["Basic", "Standard", "Premium"] },
    { name: "24/7 support", plan: ["Standard", "Premium"] },
    { name: "Abandoned cart recovery", plan: ["Premium"] },
    { name: "Advanced report builder", plan: ["Premium"] },
  ];
  const [userDetails, setUserDetails] = useState();
  const [userSettings, setUserSettings] = useState(null);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const getUserPosDetails = () => {
    let userId = auth?.payload?.uniqe_id || auth?.payload?.user?.uniqe_id;
    if (!userId) return;
    const params = {
      postData: {
        seller_id: userId,
        pos_user_id: userId,
        seller_uuid: userId,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosUserdetailsAsync(params))
      .then(unwrapResult)
      .then((res) => {
        setUserDetails(res?.payload||auth?.payload);
        // getSalaryDetails(res?.payload.user?.id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    //get user details
    if (userSettingDetails) {
      setUserSettings(userSettingDetails?.payload);
    }else{
      getUserSettings();
    }
    if (!userPosDetails) getUserPosDetails();
    else setUserDetails(userPosDetails?.payload);
  }, []);
  const getUserSettings = async () => {
    if (userSettingDetails) {
      setUserSettings(userSettingDetails?.payload);
      return;
    }
    let prams = {
      postData: {
        app_name: "merchant",
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setUserSettings(res?.payload);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
      });
  };
  const [isSettingUpdating, setisSettingUpdating] = useState(false);
  const handleUserSettings = (data) => {
    let prams = {
      postData: {
        ...data,
      },
      tokenData: auth?.payload?.token,
    };
    setisSettingUpdating(true);
    dispatch(updateUserSettingsApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setisSettingUpdating(false);
        setUserSettings(res?.payload);
      })
      .catch((err) => {
        setisSettingUpdating(false);
        // toast.error("Unable to set user settings");
      });
  };
  useEffect(()=>{
    if(userSettings){
      let language=userSettings?.language?.filter(
        (item) => item?.status
      );
      if(language?.length){
        let name=language?.[0]?.name??"English";
        setdefaultLanguage(name);
  
      }
    }
  },[userSettings])
  const getSellerAddressDetails = async () => {
    const params = {
      postData: {
        seller_id: auth?.payload?.unique_id
          ? auth.payload.unique_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
  
    try {
      let data = await dispatch(getSellerAddressesApiAsync(params)).then(unwrapResult);
      return data;
    } catch (error) {
      throw error; // Rethrow error so the caller can handle it
    }
  };
  useEffect(()=>{
    if(sellerAddressList?.payload){
      setStoreCount(sellerAddressList?.payload?.length || 1);
    }
  },[sellerAddressList])
  const getCurrentPlan=async()=>{
    try {
    let data=await  dispatch(getMerchantActiveSubscriptionAsync(auth?.payload?.token))
          .then(unwrapResult);
    if(data){
      setcurrentPlanDetails(data?.payload);
      setPlanType(data?.payload?.plan||"month");
      let planExpiry=data?.payload?.[0]?.expiry_date||null;
      setHasPlan(planExpiry);
    }
    return data;
    } catch (error) {
      return null;
    }
  }
  useEffect(()=>{
    getCurrentPlan();
  },[])
  return (
    <div className="moresettingMain position-relative">
      {loading && <Loader />}
      <div className="row">
        <div className="col-md-3">
          <div className="orderleft">
            {/* <OrdersettingSidebar /> */}
            <div className="leftsidebarOrder">
              <ul className="ordersettingmenu_">
                <li className="listinner_ mb-2">
                  <div
                    className={`listinner_a ${
                      moreSetting === "Personal Details" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("Personal Details");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.authUserLogo}
                      alt="pay method"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('personalDetails')}</h4>
                      <h5 className="datetimeText_ mb-0">{t('default')}</h5>
                    </div>
                  </div>
                </li>
                <li className="listinner_ mb-2">
                  <div
                    className={`listinner_a ${
                      moreSetting === "Business Details" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("Business Details");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.businessDetailsicon}
                      alt="Bank-accounts"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('businessDetails')}</h4>
                      <h5 className="datetimeText_ mb-0">{t('updated')}</h5>
                    </div>
                  </div>
                </li>
                <li className="listinner_ mb-2">
                  <div
                    className={`listinner_a ${
                      moreSetting === "Security Setting" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("Security Setting");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.settingssecurity}
                      alt="withdrwaels"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('security')}</h4>
                      <h5 className="datetimeText_ mb-0">{t('uptoDate')}</h5>
                    </div>
                  </div>
                </li>
                <li className="listinner_ mb-2">
                  <div
                    href="#"
                    className={`listinner_a ${
                      moreSetting === "Notification" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("Notification");

                      getUserSettings();
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.orderring}
                      alt="notification"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('notifications')}</h4>
                      <h5 className="datetimeText_ mb-0">{t('uptoDate')}</h5>
                    </div>
                  </div>
                </li>
                {/* <li className='listinner_ mb-2'>
                                    <div href='#' className={listinner_a ${moreSetting === "Payment Setting" ? "active" : ""}}
                                        onClick={() => { setmoreSetting("Payment Setting") }}>
                                        <img className='ordersetting' src={Images.settingsmoney} alt='additional' /> 
                                        <div className='infotextline_'><h4 className='countNumber_'>Payment Method</h4><h5 className='datetimeText_ mb-0'>1 Account connect</h5></div>
                                    </div>
                                </li> */}
                <li className="listinner_ mb-2">
                  <div
                    href="#"
                    className={`listinner_a ${
                      moreSetting === "businessLocation" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("businessLocation");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.loctionicn}
                      alt="additional"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('businessLocation')}</h4>
                      <h5 className="datetimeText_ mb-0">{storeCount} {t('location')}</h5>
                    </div>
                  </div>
                </li>
                <li className="listinner_ mb-2">
                  <div
                    href="#"
                    className={`listinner_a ${
                      moreSetting === "plan subscription" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("plan subscription");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.settingsmoney}
                      alt="additional"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('plan&Subscriptions')} </h4>
                     {hasPlan&& <h5 className="datetimeText_ mb-0">
                        Expire on {moment(hasPlan).format('YYYY-MM-DD')}
                      </h5>}
                    </div>
                  </div>
                </li>
                {/* <li className='listinner_ mb-2'>
                                    <div href='#' className={`listinner_a ${moreSetting === "DeviceConnected" ? "active" : ""}`}
                                        onClick={() => { setmoreSetting("DeviceConnected") }}>
                                        <img className='ordersetting' src={Images.settingsdevices} alt='additional' /> 
                                        <div className='infotextline_'><h4 className='countNumber_'>Devices </h4><h5 className='datetimeText_ mb-0'>5 Connected</h5></div>
                                    </div>
                                </li> */}
                <li className="listinner_ mb-2">
                  <div
                    href="#"
                    className={`listinner_a ${
                      moreSetting === "language" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("language");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.settingslanguage}
                      alt="additional"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('language')} </h4>
                      <h5 className="datetimeText_ mb-0">{defaultLanguage}</h5>
                    </div>
                  </div>
                </li>
                <li className="listinner_ mb-2">
                  <div
                    href="#"
                    className={`listinner_a ${
                      moreSetting === "Legal" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("Legal");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.settingslaw}
                      alt="additional"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('legal')} </h4>
                      <h5 className="datetimeText_ mb-0">{t('updated')}</h5>
                    </div>
                  </div>
                </li>
                {/* <li className='listinner_ mb-2'>
                                    <div href='#' className={listinner_a ${moreSetting === "Policies Setting" ? "active" : ""}}
                                        onClick={() => { setmoreSetting("Policies") }}>
                                        <img className='ordersetting' src={Images.settingspolicies} alt='additional' /> 
                                        <div className='infotextline_'><h4 className='countNumber_'>Policies </h4><h5 className='datetimeText_ mb-0'>3 intregrated </h5></div>
                                    </div>
                                </li> */}
                <li className="listinner_ mb-2">
                  <div
                    href="#"
                    className={`listinner_a ${
                      moreSetting === "Plugin Integration" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("Plugin Integration");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.Pluginsicon}
                      alt="additional"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">Plugin & Integration </h4>
                      <h5 className="datetimeText_ mb-0">3 intregrated </h5>
                    </div>
                  </div>
                </li>
                <li className="listinner_ ">
                  <div
                    href="#"
                    className={`listinner_a ${
                      moreSetting === "Cash Drawer" ? "active" : ""
                    }`}
                    onClick={() => {
                      setmoreSetting("Cash Drawer");
                    }}
                  >
                    <img
                      className="ordersetting"
                      src={Images.staffInactiveIcon}
                      alt="additional"
                    />
                    <div className="infotextline_">
                      <h4 className="countNumber_">{t('cashDrawer')}</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div
            className={`position-relative ${
              moreSetting === "Additional Settings"
                ? "additionalsetmain_ rightContentorder_"
                : "Customer Notifications"
                ? "additionalsetmain_ rightContentorder_"
                : "rightContentorder_ "
            }`}
          >
            {moreSetting === "Personal Details" && (
              <PersonalDetail
                getUserPosDetails={getUserPosDetails}
                userDetails={userDetails}
              />
            )}
            {moreSetting === "Business Details" && (
              <BusinessDetails
                getDetails={getUserPosDetails}
                userDetails={userDetails}
              />
            )}
            {moreSetting === "Security Setting" && (
              <Security
                getUserPosDetails={getUserPosDetails}
                userDetails={userDetails}
              />
            )}
            {moreSetting === "Notification" && (
              <Notificationindex setmoreSetting={setmoreSetting} />
            )}
            {/* {(moreSetting === "customerNotify") && <CustomerNotification/>} */}
            {/* {(moreSetting === "staffNotify") && <StaffNotification/>} */}
            {moreSetting === "deviceDetail" && <DeviceDetail />}
            {moreSetting === "language" && <Languageindex 
            setLanguage={setdefaultLanguage}
            
            />}
            {moreSetting === "customerNotify" && (
              <CustomerNotification
                isSettingUpdating={isSettingUpdating}
                userSetting={userSettings}
                setmoreSetting={setmoreSetting}
                userDetails={userDetails}
                updateSetting={(obj) => handleUserSettings(obj)}
              />
            )}
            {moreSetting === "staffNotify" && (
              <StaffNotification
                isSettingUpdating={isSettingUpdating}
                setmoreSetting={setmoreSetting}
                userSetting={userSettings}
                userDetails={userDetails}
                updateSetting={(obj) => handleUserSettings(obj)}
              />
            )}
            {moreSetting === "plans" && (
              <Planfit
                planType={planType}
                selectedPlan={selectedPlan}
                setmoreSetting={() => setmoreSetting("plan subscription")}
              />
            )}
            {moreSetting === "plan subscription" && (
              <PlanandSubscription
              setPlan={setHasPlan}
              setPlanType={setPlanType}
              currentPlan={currentPlanDetails}
              getCurrentPlan={getCurrentPlan}
                setmoreSetting={(value, plan) => {
                  setmoreSetting("plans");
                  setSelectedPlan(plan);
                }}
              />
            )}
            {moreSetting === "featureNotify" && (
              <FeatureNotification
                isSettingUpdating={isSettingUpdating}
                setmoreSetting={setmoreSetting}
                userSetting={userSettings}
                userDetails={userDetails}
                updateSetting={(obj) => handleUserSettings(obj)}
              />
            )}
            {moreSetting === "salesNotify" && (
              <SalesNotification
                isSettingUpdating={isSettingUpdating}
                setmoreSetting={setmoreSetting}
                userSetting={userSettings}
                userDetails={userDetails}
                updateSetting={(obj) => handleUserSettings(obj)}
              />
            )}
            {moreSetting === "businessLocation" && (
              <BusinessLocations
              setCount={setStoreCount}
              getSellerAddressDetails={getSellerAddressDetails}
                setmoreSetting={(data, store) => {
                  if (store) setlocationDetails(store);
                  setmoreSetting(data);
                }}
              />
            )}
            {moreSetting === "addEditLocation" && (
              <EditLocation
              getSellerAddressDetails={getSellerAddressDetails}
                setmoreSetting={setmoreSetting}
                locationDetails={locationDetails}
              />
            )}
            {moreSetting === "AddLocation" && (
              <AddLocation
              getSellerAddressDetails={getSellerAddressDetails}
              setmoreSetting={setmoreSetting} />
            )}

            {moreSetting === "Languages" && (
              <Languages
              setLanguage={setdefaultLanguage}
              setmoreSetting={setmoreSetting} />
            )}
            {moreSetting === "DeviceConnected" && (
              <DeviceConnected setmoreSetting={setmoreSetting} />
            )}
            {moreSetting === "Legal" && (
              <LegalPolicies
                setmoreSetting={setmoreSetting}
                setPolicy={(policy) => setPolicy(policy)}
              />
            )}
            {moreSetting === "CreateUpdateLegal" && (
              <CreateUpdateLegal
                setmoreSetting={() => setmoreSetting("Legal")}
                isNew={policy ? false : true}
                policy={policy}
              />
            )}
            {moreSetting === "ViewPolicy" && (
              <ViewPolicy
                setmoreSetting={(value, policy) => {
                  setmoreSetting(value);
                  setPolicy(policy);
                }}
                policy={policy}
              />
            )}
            {moreSetting === "Plugin Integration" && (
              <PluginandIntigration setmoreSetting={setmoreSetting} />
            )}
            {moreSetting === "Cash Drawer" && (
              <CashDrawer setmoreSetting={setmoreSetting} />
            )}
            {
              // (moreSetting==="Policies")&&<PolicyIndex setmoreSetting={setmoreSetting}/>
            }
            {/* {
                            (moreSetting==="Legal")&&<LegalIndex setmoreSetting={setmoreSetting}/>
                        }
                        {
                            (moreSetting==="Refund Policy")&&<RefundPolicy setmoreSetting={setmoreSetting}/>
                        }
                        {
                            (moreSetting==="Edit Policy")&&<Editpolicy setmoreSetting={setmoreSetting}/>
                        }
                        {/* {
                            (moreSetting==="Policies")&&<PolicyIndex setmoreSetting={setmoreSetting}/>
                        } */}
            {/* {
                            (moreSetting==="Plugin Integration")&&<PluginandIntigration setmoreSetting={setmoreSetting}/>
                        } */}
            {/* {(walletSetting === "Additional Setting") && <AdditionalSettingwallet />}  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoresettingIndex;
