import React, { forwardRef, useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import * as Images from "../../../../../utilities/images";
import { getProductServiceListApiAsync } from "../../../myProducts/productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFormatedDate } from "../../../../../utilities/helpers";
import DatePicker from "react-datepicker";
import DatePickerCustomComponent from "../../../../components/UI/Orders/DatePickerCustomComponent";

const CreateProductOfferDetails = ({
  handleOfferDetails,
  offerDetails,
  productDetails,
  handleProduct,
  reset,
  setOfferPrices,
  offerPrices,
  selectedVariants,
  setSelectedVariants,
}) => {
  const [allProducts, setallProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showQuantity, setShowQuantity] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );
  const dropdownRef = useRef(null);

  const selectPriceType = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];
  const selectPriceOption = [
    { value: "per_box", label: "Per Box" },
    { value: "per_pack", label: "Per Pack" },
  ];
  const [search, setsearch] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const getProductList = async () => {
    if (search == "") return;
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        app_name: "merchant",
        page: 1,
        limit: 5,
        search: search,
      },
    };
    setShowProduct(true);
    setLoading(true);
    dispatch(getProductServiceListApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false);
        setallProducts(res?.payload?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const toggleVariantSelection = (variantId) => {
    setSelectedVariants((prevSelected) =>
      prevSelected.includes(variantId)
        ? prevSelected.filter((id) => id !== variantId)
        : [...prevSelected, variantId]
    );
  };
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    let timer = setTimeout(() => {
      getProductList();
    }, 1000);
    setsearchTimeout(timer);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [search]);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowProduct(false); // Close dropdown if clicked outside
      }
    };

    if (showProduct) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProduct]);
  const handlePriceChange = (variantId, appName, value) => {
    setOfferPrices((prev) => ({
      ...prev,
      [variantId]: {
        ...prev?.[variantId],
        [appName]: value,
      },
    }));
  };
  return (
    <section className="createOfferPublish_Section">
      <h2 className="fontSize24 fw500 txtBlue mb-3">Offer Details</h2>
      <div className="position-relative">
        <div className="input-group search_group searchOfferInput">
          <img
            src={Images.SearchIcon}
            alt=""
            className="img-fluid searchIcon"
          />
          <input
            type="text"
            className="form-control"
            placeholder="Search Product here by Barcode, SKU, Name..."
            aria-label="Recipient's username"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
            value={search}
            aria-describedby="basic-addon2"
          />
        </div>
        {showProduct && (
          <div className="SearchDrop" ref={dropdownRef}>
            <h3 className="fontSize16 mt-3 fw500 txtDarkblue">Match result</h3>
            <div className=" commonTable productOfferTable table-responsive">
              <table className="w-100">
                <tbody>
                  {loading ? (
                    <>
                      <tr>
                        <td colSpan={11}>
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="spinner-border spinner-border-md spinnerDark"></span>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : Array.isArray(allProducts) && allProducts.length > 0 ? (
                    allProducts.map((product, index) => (
                      <tr key={index}>
                        <td>
                          <div className="productAvailabilityChild align-items-center">
                            <div className="form-group">
                              <input
                                id={index}
                                type="checkbox"
                                checked={productDetails?.id === product.id} // Ensure correct state
                                onChange={(e) => {
                                  setShowProduct(false);
                                  handleProduct(
                                    e.target.checked ? product : null
                                  );

                                  setsearch("");
                                }}
                              />

                              <label className="me-0" htmlFor={index}></label>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={product?.image}
                                alt="img"
                                className="productItemImg"
                              />
                              <div>
                                <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-start">
                                  {product.name}
                                </h3>

                                <div className="d-flex align-items-center gap-2">
                                  <span className="skyblueDot"></span>
                                  <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                    SKU{" "}
                                    {
                                      product?.supplies[0]?.supply_variants[0]
                                        ?.sku
                                    }
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="fontSize12 txtLightBlue">
                          <h4 className="fontSize12 txtDarkblue m-0">
                            Cost price
                          </h4>
                          <h4 className="fontSize12 txtDarkblue mt-1 mb-0">
                            {" "}
                            {"$"} {product.price}
                          </h4>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="fontSize14 txtLightBlue" colSpan={7}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {/*  */}
      {productDetails && (
        <div className="commonTable productOfferTable table-responsive mt-3">
          <table className="w-100">
            <tbody>
              <tr>
                <td>
                  <div className="productAvailabilityChild align-items-center">
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={productDetails?.image}
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-start">
                          {productDetails.name}
                        </h3>

                        <div className="d-flex align-items-center gap-2">
                          <span className="skyblueDot"></span>
                          <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                            SKU{" "}
                            {
                              productDetails?.supplies[0]?.supply_variants[0]
                                ?.sku
                            }
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="fontSize12 txtLightBlue">
                  <h4 className="fontSize12 txtDarkblue m-0">Cost price</h4>
                  <h4 className="fontSize12 txtDarkblue mt-1 mb-0">
                    {" "}
                    {"$"} {productDetails.price}
                  </h4>
                </td>
                <td>
                  <img
                    src={Images.offerCross}
                    className="img-fluid cursorPoint"
                    alt="cross image"
                    onClick={() => {
                      handleProduct(null);
                      reset();
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div
        className={`${
          !productDetails ? "opacityBox user-select-none" : ""
        } position-relative pb-4`}
      >
        {/* {allProducts?.length == 0 && (
          <div className="opacityBox w-100 position-absolute z-1"></div>
        )} */}
       <div className="offerPeriodBox mt-4">
          <h5 className="fontSize18 fw500 txtDarkblue mb-0">Period</h5>

          <div className="row">
            <div className="col-md-6">
              <div className="datePicker_Parent pt-0 mt-4">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  Start from
                </label>
                <DatePicker
                  showIcon
                  selected={offerDetails.start_date}
                  disabled={!productDetails}
                  onChange={(date) => {
                    handleOfferDetails("start_date", date);
                    if (offerDetails.end_date < date) {
                      handleOfferDetails("end_date", date);
                    }
                  }}
                  minDate={new Date()} // Start Date should be today or later
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">
                  Starts at 00:00 AM
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="datePicker_Parent pt-0 mt-4">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  Start from
                </label>
                <DatePicker
                  showIcon
                  selected={offerDetails.end_date}
                  disabled={!productDetails}
                  onChange={(date) => {
                    handleOfferDetails("end_date", date);
                  }}
                  minDate={offerDetails.start_date} // End Date cannot be before Start Date
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">Ends at 11:59 PM</p>
              </div>
            </div>
          </div>
        </div>
        {productDetails?.type=="physical" ? (
          <>
            <div className="mt-4 form-group col-md-12">
              <div className="reactSelectParent commonSelectParent w-100">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Select Type
                </label>
                <ReactSelect
                  options={selectPriceType}
                  value={selectPriceType.filter(
                    (price) => price.value === offerDetails.price_flag
                  )}
                  onChange={(value) => {
                    handleOfferDetails("price_flag", value.value);
                  }}
                  disabled={!productDetails}
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  isSearchable={true}
                  placeholder="select the type"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img src={Images.blueDownArrow} alt="drop_icon" />
                    ),
                  }}
                />
              </div>
            </div>

            <h5 className="fontSize18 fw500 txtDarkblue mb-0 mt-4">
              Offer Pricing
            </h5>

            {productDetails.supplies?.map((supply, supplyIdx) => (
              <div
                key={supplyIdx}
                className="supplyBox mt-3 p-3 border rounded"
              >
                <h6 className="fontSize16 fw500 txtDarkblue mb-2">
                  {supply.name || `Supply ${supplyIdx + 1}`}
                </h6>

                <div className="productVariantsTable table-responsive mt-2 createProductOfferTable">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th>
                          {/* <input
                            type="checkbox"
                            onChange={() =>
                              toggleSelectAllVariants(
                                supply.supply_variants || []
                              )
                            }
                            checked={
                              Array.isArray(selectedVariants) &&
                              Array.isArray(supply.supply_variants) &&
                              selectedVariants.length ===
                                supply.supply_variants.length
                            }
                          /> */}
                        </th>
                        <th>Attributes</th>
                        <th>POS Price</th>
                        <th>B2B Price</th>
                        <th>Marketplace Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(supply.supply_variants) &&
                      supply.supply_variants.length > 0 ? (
                        supply.supply_variants.map((variant, idx) => (
                          <tr key={idx}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedVariants.includes(variant.id)}
                                onChange={() =>
                                  toggleVariantSelection(variant.id)
                                }
                              />
                            </td>
                            <td>
                              {variant.attributes ? (
                                <div className="d-flex align-items-center">
                                  {Object.entries(
                                    JSON.parse(variant.attributes)
                                  )
                                    .map(([key, val]) => val)
                                    .join(" / ")}
                                </div>
                              ) : (
                                <span className="defaultVariantText">
                                  Default Variant
                                </span>
                              )}
                            </td>

                            {["pos", "b2b", "b2c"].map((appName) => (
                              <td key={appName}>
                                <input
                                  className="productVariant_Input"
                                  type="number"
                                  value={
                                    offerPrices?.[variant.id]?.[appName] || ""
                                  }
                                  onChange={(e) =>
                                    handlePriceChange(
                                      variant.id,
                                      appName,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No Variants Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="mt-4 mb-3">
              <h5 className="fontSize18 fw500 txtDarkblue mb-0">
                Offer Pricing
              </h5>
              <div className="editVariant_SecondBox mt-4">
                <div className="upcBoxParent">
                  <div className="row">
                    <div className="col-4">
                      <div className="upcBox">
                        <h3 className="fontSize18 mb-0">Offer Type</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="upcBox upcRightBox">
                        <div className="reactSelectParent offerModalSelect">
                          <ReactSelect
                            options={selectPriceType}
                            value={selectPriceType.filter(
                              (price) => price.value === offerDetails.price_flag
                            )}
                            onChange={(value) => {
                              handleOfferDetails("price_flag", value.value);
                            }}
                            disabled={!productDetails}
                            placeholder="Select one"
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#263682"
                                  : state.isFocused
                                  ? "#f5f6fc"
                                  : "#fff",
                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#636e9f",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0px",
                              }),
                            }}
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  src={Images.blueDownArrow}
                                  alt="drop_icon"
                                />
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {productDetails?.supplies?.map((supply, supplyIdx) => (
                  <div
                    key={supplyIdx}
                    className="supplyBox mt-3 p-3 border rounded"
                  >
                    <h6 className="fontSize16 fw500 txtDarkblue mb-2">
                      {supply.name || `Store ${supplyIdx + 1}`}
                    </h6>

                    {["pos", "b2b", "b2c"].map((appName) => (
                      <div key={appName} className="upcBoxParent mt-3">
                        <div className="row">
                          <div className="col-4">
                            <div className="upcBox">
                              <h3 className="fontSize18 mb-0">
                                {appName.toUpperCase()} Price
                              </h3>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="upcBox upcRightBox inputUpcBox">
                              <div className="inputGroup">
                                <input
                                  className="productVariant_Input"
                                  name={appName}
                                  type="number"
                                  value={
                                    offerPrices?.[
                                      supply?.supply_variants[0].id
                                    ]?.[appName] || ""
                                  }
                                  onChange={(e) =>
                                    handlePriceChange(
                                      supply?.supply_variants[0].id,
                                      appName,
                                      e.target.value
                                    )
                                  }
                                />
                                <span className="inputDollarSign">
                                  {offerDetails?.price_flag === "percentage"
                                    ? "%"
                                    : "$"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

      </div>
    </section>
  );
};

export default CreateProductOfferDetails;
