import React from 'react'
import { amountFormat } from '../bookings/BookingCalendar/GlobalMethods'
import { useTranslation } from 'react-i18next';

const BillDetails = ({
    orderDetails
}) => {
    const { t } = useTranslation();
    return <>
        <div className="totalDetails">
            <h5 className="lightgreyHeading_ ">
                {t('subTotal')}
            </h5>
            <h5 className="personalinfoHead ">
                $
                {orderDetails?.actual_amount
                    ? Number(orderDetails?.actual_amount).toFixed(2)
                    : "0"}
            </h5>
        </div>
        <div className="totalDetails">
            <h5 className="textInnerHead2"> {t('discount')}</h5>
            <h5 className="personalinfoHead">
                ${Number(orderDetails?.discount || 0)?.toFixed(2)}
            </h5>
        </div>
        <div className="totalDetails">
            <h5 className="textInnerHead2"> {t('tips')}</h5>
            <h5 className="personalinfoHead">
            ${Number(orderDetails?.tips)?.toFixed(2)}
            </h5>
        </div>
        <div className="totalDetails">
            <h5 className="textInnerHead2">{t('tax')}</h5>
            <h5 className="personalinfoHead">
                ${Number(orderDetails?.tax)?.toFixed(2)}
            </h5>
        </div>
        <div className="totalDetails">
            <h5 className="textInnerHead2"> {
                orderDetails?.delivery_option==1?"Delivery Charges":"Shipping Charges"
                }</h5>
                                                <h5 className="personalinfoHead">
                ${Number((orderDetails?.delivery_option==1? orderDetails?.delivery_charge:orderDetails?.shipping_charge) || 0)?.toFixed(2)}
            </h5>
        </div>
        {orderDetails?.platform_fee && (
            <div className="totalDetails">
                <h5 className="textInnerHead2">
                    {t('platformFees')}
                </h5>
                <h5 className="personalinfoHead">
                    $
                    {Number(
                        orderDetails?.platform_fee
                    )?.toFixed(2)}
                </h5>
            </div>
        )}
        {(orderDetails?.paid_by_reward ||
            orderDetails?.partially_paid_by_reward) && (
                <div className="totalDetails">
                    <h5 className="textInnerHead2">{"Reward point"}</h5>
                    <h5 className="personalinfoHead">
                        -{amountFormat(orderDetails?.redeemed_reward_points / 100)}
                    </h5>
                </div>
            )}
        <hr className="dashedrow"></hr>
        <div className="totalDetails">
            <h5 className="itemsText ">{t('total')}</h5>
            <h5 className="itemsText">

                {orderDetails?.paid_by_reward ||
                    orderDetails?.partially_paid_by_reward
                    ? amountFormat(
                        orderDetails?.payable_amount -
                        orderDetails?.redeemed_reward_points / 100
                    )
                    : amountFormat(orderDetails?.payable_amount)}
            </h5>
        </div>
    </>
}

export default BillDetails