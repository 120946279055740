import React from "react";
import * as Images from "../../../../utilities/images";

const SuccessfullAddCustomerModal = (props) => {
  let currentChatHeadData=props?.userData;
  return (
    <div>
      <img
        src={Images.modalCross}
        alt="img"
        className="inventoryModalCancleImg cursorPointer"
        onClick={() => props?.close()}
      />
      <img src={Images.successfullLogo} alt="img" className="mt-4" />
      <h3 className="fontSize36 fw500 txtDarkblue mt-3 mb-3">Successfully </h3>
      <p className="fontSize20  txtLightBlue mb-3">
        <span className="txtDarkblue">{
          currentChatHeadData?.chatPartner?.firstname+" "+
          currentChatHeadData?.chatPartner?.lastname 
        }</span> has been added to
        your contact list.
      </p>
      <button onClick={()=>{
        props?.close()
      }} className="modalNextBtn modalDiscardBtn active w-100 ms-0">
      Done
      </button>
    </div>
  );
};

export default SuccessfullAddCustomerModal;
