import React from 'react'
import { userlistImg, dots, merchantActive, emailBlue, phoneBlue, manage, profileImg1 } from '../../../../utilities/images'
import DataTable from 'react-data-table-component'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'

const ManufactureView = (props) => {
    const { t } = useTranslation();
    const history = useHistory()
    const { flag } = useParams();
    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };
    
      const handlePageChange = (page) => {
        props.setCurrentPage(page);
      };
    
      const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        props.setPerPage(currentRowsPerPage);
        props.setCurrentPage(1);
      };
    const columns1 = [
        {
            selector: row => {
                return (
                    <>
                        <div className='productViewCard mb-4'>
                            <div className='userlistCard_'>
                                <div className='userproImage_'>
                                    <img className='tokenprofileImg' src={row?.user_profiles?.profile_photo ? row?.user_profiles?.profile_photo : profileImg1} />
                                </div>
                                <div className="subheaderInnerInstant">
                                    <div className='productViewHead'>
                                        <h1 className='driverHead_'>{row?.user_profiles?.firstname + " " + row?.user_profiles?.lastname}</h1>
                                        <div className='dropdown tableDropdown ms-3 '>
                                            <span className='dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" id='barMenus'>
                                                <img src={dots} alt="icon" />
                                            </span>
                                            <div className='dropdown-menu tableDropdownMenus' aria-labelledby='barMenus'>
                                                <li className='dropdown-item' onClick={flag === 'consumer' ? void(0) : ()=>history.push(`/userDetails/${window.location.pathname.split("/")[2]}/${row?.unique_uuid}`)}>{t("viewDetails")}</li>
                                                {/* <li className='dropdown-item'>History</li>
                                                        <li className='dropdown-item' onClick={() => {
                                                            setSelectedProduct(row)
                                                            setModalDetail({ show: true, flag: "UpdateinventoryModal" });
                                                            setKey(Math.random());
                                                        }}>Update Inventory</li>
                                                        <li className='dropdown-item' onClick={() => {
                                                            setSelectedProduct(row)
                                                            setModalDetail({ show: true, flag: "InventoryadjustModal" });
                                                            setKey(Math.random());
                                                        }}>Adjust Inventory</li> */}
                                                {/* <li className='dropdown-item'>Profile</li> */}
                                            </div>
                                        </div>
                                    </div>
                                    <p className='contentSubtextbold'>
                                        <img src={merchantActive} /> <span>4703-515-1193</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={phoneBlue} /> <span>{row?.user_profiles?.full_phone_number}</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <img src={emailBlue} /> <span>{row?.email}</span>
                                    </p>
                                    <p className='contentextLight'>
                                        <span>{row?.user_profiles?.current_address?.street_address}</span>
                                    </p>
                                </div>
                            </div>

                            <div className='botmpaid_ mt-3'>
                                <h4 className='tablesubHead mb-2'>{t("masterCategory")} : <strong>{row?.master_category?.name}</strong></h4>
                                <h4 className='tablesubHead mb-2'>{t("paidAmount")} : <strong>JBR {row?.total_payment?.toFixed(2)} </strong></h4>
                                <h4 className='tablesubHead '>{t("products")} <strong>{row?.products}</strong></h4>
                            </div>
                        </div>
                    </>
                );
            },
        },
    ];
    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        
    }

    return (
        <div className='brandListmain_'>
            <section className='productListView mt-3'>
                <div className='row'>
                    <section className='recentTransections mt-1 table-responsive'>
                        <DataTable
                            className='columsGrid'
                            pagination
                            columns={columns1}
                            data={props?.data}
                            customStyles={customStyles}
                            defaultSortFieldId={1}
                            onRowClicked={handleRowClick}
                            progressPending={props.loading}
                            paginationServer
                            paginationTotalRows={props.totalRows}
                            paginationPerPage={props.perPage}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                        />
                    </section>
                </div>
            </section>
        </div>
    )
}

export default ManufactureView