import { createSlice } from "@reduxjs/toolkit";

export const PagesColumnSlice = createSlice({
  name: "page_column_reducer",
  initialState: { 
    settings: {}  // Stores column settings for different pages
  },
  reducers: {
    addMoreColumnsSettings: (state, action) => {
      // Updates column settings dynamically based on payload
      const { key, data } = action.payload;
      state.settings[key] = data;
    }
  }
});

// Exporting the action for updating column settings
export const { addMoreColumnsSettings } = PagesColumnSlice.actions;

// Selector to access the stored column settings
export const selectPagesColumnData = (state) => state.pagesColumn.settings;

// Reducer export
export default PagesColumnSlice.reducer;
