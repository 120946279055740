import React, { useState, useEffect } from "react";
import * as Images from "../../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth, updateMerchantApiAsync } from "../../auth/authSlice";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

const UpdateBusiness = (props) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [desc, setdesc] = useState(props?.data);
  const [wordCount, setWordCount] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  
  // Word Count Function
  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    return words.filter(Boolean).length;
  };

  // Handle Text Change and Validate Word Count
  const handleTextChange = (event) => {
    const text = event.target.value;
    const currentWordCount = countWords(text);
    
    setdesc(text);
    setWordCount(currentWordCount);

    // Check if word count exceeds the limit (500 words)
    if (currentWordCount > 500) {
      setErrorMessage("Word limit exceeded. Maximum is 500 words.");
    } else {
      setErrorMessage(""); // Clear error message if under limit
    }
  };

  const handleSubmit = () => {
    if (wordCount > 500) {
      toast.error("Cannot save. Word limit exceeded.");
      return; // Prevent form submission if word count is exceeded
    }
    
    setLoading(true);
    let params = {
      postData: {
        id: auth?.payload?.user?.user_profiles?.user_id
          ? auth?.payload?.user?.user_profiles?.user_id
          : auth?.payload?.user_profiles?.user_id,
        business_details: desc,
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(updateMerchantApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        toast.success("Business details updated successfully");
        setLoading(false);
        props?.handleBusinessUpdate();
        props?.close();
      })
      .catch((err) => {
        toast.error("Failed to update business details");
        setLoading(false);
      });

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
useEffect(()=>{
  if(props?.data){
    setWordCount(countWords(props?.data))
  }
},[props?.data])
  return (
    <>
      <div className="container-fluid p-0">
        <div className="addLeaveModalParent aboutbusinessModal">
          <div className="row">
            <div className="col-12">
              <div className="pt-4">
                <div className="inputGroup position-relative">
                  <textarea
                    className="custom-textarea"
                    placeholder=""
                    value={desc}
                    onChange={handleTextChange}
                    id="floatingTextarea"
                    rows="6"
                  ></textarea>
                </div>
                <p className="countNumber_ ps-3">
                  {wordCount} / 500 words
                </p>
                {errorMessage && (
                  <p className="text-danger ps-3 fontSize12 mt-1">{errorMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flexBox mt-4">
          <button
            className="modalDiscardBtn w-100"
            disabled={loading}
            onClick={() => {
              props?.close();
            }}
          >
            {t('cancel')}
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading || wordCount > 500}  // Disable Save button if word count exceeds limit
            className="modalNextBtn modalDiscardBtn active w-100 f"
          >
            {/* add loader */}
            {loading && (
              <span className="spinner-border spinner-border-sm spinnerLight"></span>
            )}

            {t('save')}
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateBusiness;
