import React, { useEffect, useState } from 'react'
import { totalInventoryIcon, totalInventoryValueIcon, averageOrderValue, grossgreenIcon } from '../../../utilities/images';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { totalAnalyticsInventoryApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { addThousandSeparator } from '../../../utilities/helpers';
import AnalyticsHeader from './AnalyticsHeader';
import PaginationHeader from './PaginationHeader';
import { useTranslation } from 'react-i18next';

const TotalInventory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [totalOrderAnalyticsData, setTotalOrderAnalyticsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [calanderDate,setCalanderDate] = useState(new Date())

    const handelPerPageResult = (e) => {
        setPerPage(e?.value)
    }
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };

    const totalInventoryAnalyticsHandle = () => {
        let params = {
            "postData": {
                filter: filterValue,
                channel: channelFilter,
                page: currentPage,
                limit: perPage,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true)
        dispatch(totalAnalyticsInventoryApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setTotalOrderAnalyticsData(obj?.payload)
                setTotalRows(obj?.payload?.inventory_list?.total)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        totalInventoryAnalyticsHandle()
    }, [filterValue, channelFilter, endDate, currentPage])

    return (
        <>
            <DashboardNavbar title={t("totalInventory")} backShow="/analytics" />
            <div className='analyticGross chartsOuter'>
                <AnalyticsHeader
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onTimeSpanSelect={setFilterValue}
                    calanderDate={calanderDate}
                    setCalanderDate={setCalanderDate}
                    filterValue={filterValue}
                    setChannelFilter={setChannelFilter}
                    channelFilter={channelFilter}
                    subhead="vs. Same day last week"
                    onDateChange={handleDateChange}
                    timeSpan={filterValue}
                    header={t("totalInventory")}
                />
                <div className='grossProfitData'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6  pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalInventoryIcon} className='img-fluid grossImages' alt='total order' />
                                <p className='groSub mt-4 mb-0'>{t("totalInventory")}</p>
                                <h4 className='groMain'>{addThousandSeparator(totalOrderAnalyticsData?.inventory_overview?.total_inventory) || "0"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalInventoryValueIcon} className='img-fluid grossImages' alt='anlyseinventory' />
                                <p className='groSub mt-4 mb-0'>{t('totalInventoryValue')}</p>
                                <h4 className='groMain'>{totalOrderAnalyticsData?.inventory_overview?.total_inventory_cost ? `$${addThousandSeparator((totalOrderAnalyticsData?.inventory_overview?.total_inventory_cost)?.toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={averageOrderValue} className='img-fluid grossImages' alt='analysis' />
                                <p className='groSub mt-4 mb-0'>{t('averageOrderValue')}</p>
                                <h4 className='groMain'>{totalOrderAnalyticsData?.inventory_overview?.average_value ? `$${addThousandSeparator((totalOrderAnalyticsData?.inventory_overview?.average_value)?.toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 '>
                            <div className='grossTotalorder'>
                                <img src={grossgreenIcon} className='img-fluid grossImages' alt='grossProfit' />
                                <p className='groSub mt-4 mb-0'>{t('grossProfit')}</p>
                                <h4 className='groMain'>{totalOrderAnalyticsData?.inventory_overview?.total_profit ? `$${addThousandSeparator((totalOrderAnalyticsData?.inventory_overview?.total_profit)?.toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <PaginationHeader
                    perPage={perPage}
                    handelPerPageResult={handelPerPageResult}
                    currentPage={currentPage}
                    totalPage={totalRows}
                    setCurrentPage={setCurrentPage}
                    totalLength={totalOrderAnalyticsData?.inventory_list?.data?.length}
                />
                {
                    loading ? (
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                    ) : (
                        <div className="commonTable productTable table-responsive mt-3">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('productName')}</th>
                                        <th>{t('category')}</th>
                                        <th>{t('upc')}</th>
                                        <th>{t('price')}</th>
                                        <th>{t('inStock')}</th>
                                        <th>{t('lastSoldDate')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {totalOrderAnalyticsData?.inventory_list?.data?.length > 0 && (
                                        totalOrderAnalyticsData?.inventory_list?.data.map(
                                            (row, idx) => (
                                                <tr>
                                                    <td className="fontSize12 txtLightBlue">
                                                    {currentPage > 1 ? (
                                                        (currentPage - 1) * perPage + idx + 1
                                                        ) : idx + 1}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.name}
                                                    </td>

                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.category?.name}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.upc || row?.supplies?.[0]?.supply_variants?.[0]?.upc}
                                                    </td>

                                                    <td className="fontSize12 grosshead_">
                                                        {addThousandSeparator((row?.supplies?.[0]?.supply_variants?.[0]?.cost_price)?.toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        {row?.supplies?.[0]?.supply_variants?.[0]?.rest_quantity}
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        {moment(row.last_sold_date).format('MM/DD/YYYY')}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default TotalInventory