import React, { useState } from 'react'
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import CustomModal from '../../components/shared/CustomModal';
import SupportcontactModal from './supportcontactModal';

const SupportInnertopic = () => {
     const [key, setKey] = useState(Math.random());
        const [modalDetail, setModalDetail] = useState({
            show: false,
            title: "",
            flag: "",
        });
    
        const handleOnCloseModal = () => {
            setModalDetail({
                show: false,
                title: "",
                flag: "",
            });
            setKey(Math.random());
        };
    
        const handleOpenModal = (flag) => {
            setModalDetail({
                show: true,
                flag: flag,
                title: flag,
            });
            setKey(Math.random());
        };
    return (
        <>
            <div className='supportinnerTopic_ itemInventory_'>
                <div className='chartsOuter jbrHeight mt-0'>
                    <div className='inventoryHead_'>
                        <h2 className='Innerhead mb-2'><Link to="/itemsInventory"><img className='arrowicon_ me-2' src={Images.leftArrowColored} /></Link> Creating and Managing Items in JOBR</h2>
                        <p className='bankaccNumber_ ps-4'>Build your catalog of items and sync to your point-of-sale devices and online store. Learn how to track inventory and manage vendors.</p>
                    </div>

                    <div className='innerInventory_ mt-5 ps-5 pe-4 mb-3'>
                        <div className='managingContent_'>
                            <h5 className='lighttext14'>Last updated: 10 Dec 2021</h5>
                            <p className='lighttextPara'>JOBR LLC. and its affiliates and third party service providers (“JOBR”) may need to provide you with certain communications,
                                notices, agreements, billing statements, or disclosures in writing (“Communications”) regarding our Services.
                                Your agreement to this E-sign Consent confirms your ability and consent to receive Communications electronically from JOBR,
                                its affiliates, and its third party service providers, rather than in paper form, and to the use of electronic signatures in
                                our relationship with you (“Consent”). If you choose not to agree to this Consent or you withdraw your consent, you may be
                                restricted from using the Services.</p>

                            <h3 className='lightpara18_ mt-4'>About item creation</h3>
                            <p className='lighttextPara'>Under this Consent, JOBR may provide all Communications electronically by email, by text message, or by making them accessible
                                via JOBR websites or applications. Communications include, but are not limited to, (1) agreements and policies required to use the
                                Services (e.g. this Consent, the JOBR Privacy Notice, the JOBR Seller Agreement, the JOBR Commercial Entity Agreement, and
                                the JOBR Cash Agreement), (2) payment authorizations and transaction receipts or confirmations, (3) account statements and
                                history, (4) and all federal and state tax statements and documents. We may also use electronic signatures and obtain them
                                from you.</p>

                            <h3 className='lightpara18_ mt-4'>Before you begin</h3>
                            <p className='lighttextPara'>You have the right to receive Communications in paper form. To request a paper copy of any Communication at no charge,
                                please write to Block, Inc., 1455 Market Street, Suite 600, San Francisco, CA 94103, USA, Attn: Customer Support - Legal
                                (“JOBR Address”) within 180 days of the date of the Disclosure, specifying in detail the Communication you would like to receive.
                                For the avoidance of doubt, requesting a paper copy of any Communication, in and of itself, will not be treated as withdrawal of
                                consent to receive electronic Communications.</p>

                            <h3 className='lightpara18_ mt-4'>Create and edit an item</h3>
                            <p className='lighttextPara'>You have the right to receive Communications in paper form. To request a paper copy of any Communication at no charge,
                                please write to Block, Inc., 1455 Market Street, Suite 600, San Francisco, CA 94103, USA, Attn: Customer Support - Legal
                                (“JOBR Address”) within 180 days of the date of the Disclosure, specifying in detail the Communication you would like to receive. For the avoidance of doubt, requesting a paper copy of any Communication, in and of itself, will not be treated as withdrawal of consent to receive electronic Communications.</p>
                        </div>
                        <div className='subInventory_ mt-4'>
                            <h4 className='subhaedHeading mb-3'>Related articles</h4>
                            <div className='listinvent_'>
                                <article className='inventleft'>
                                    <h5 className='mainboldHead'>Create and edit item modifiers</h5>
                                    <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                                </article>
                                <img className='arrowicon_ me-2' src={Images.upArrow} />
                            </div>
                            <div className='listinvent_'>
                                <article className='inventleft'>
                                    <h5 className='mainboldHead'>Create and edit item variations and options</h5>
                                    <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                                </article>
                                <img className='arrowicon_ me-2' src={Images.upArrow} />
                            </div>
                            <div className='listinvent_'>
                                <article className='inventleft'>
                                    <h5 className='mainboldHead'>Create non-sellable variations</h5>
                                    <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                                </article>
                                <img className='arrowicon_ me-2' src={Images.upArrow} />
                            </div>
                        </div>
                        <div className='articleFaq_ mt-4'>
                            <h4 className='heading20Black_'>Was this article helpful?</h4>
                            <div className='rightactionbtn_'>
                                <button className='connectedBtn_' type='button'><img src={Images.chartlinee} alt='Arrow' className='' /> Yes</button>
                                <button className='connectedBtn_' type='button'>No <img src={Images.ArrowRight} alt='Arrow' className='' /></button>
                            </div>
                        </div>
                        <div className='supportContact_ mt-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h3 className='appointMain'>Need help? we’ve got your back</h3>
                                    <p className='greyPara_'>Perhaps you can find the answer in our collections </p>
                                </div>
                                <div className='col-md-12'>
                                    <div className='conatctUS_'>
                                        <div className='contactTop_ mb-4'>
                                            <figure className='chatOut mb-0'> <img src={Images.chaticon_} alt='' className='' /></figure>
                                            <Link to="#" className="contactArrow_" onClick={() => {
                                                setModalDetail({
                                                    show: true,
                                                    flag: "supportcontactModal",
                                                });
                                                setKey(Math.random());
                                            }}><img src={Images.ArrowRight} alt='Arrow' className='' /></Link>
                                        </div>

                                        <h5 className='heading20Black_ mb-1'>Contact us</h5>
                                        <p className='darkgreyPar_'>Get help from our support team</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "supportcontactModal"
                        ? "supportcontactModal"

                        : ""
                }
                child={
                    modalDetail.flag === "supportcontactModal" ? (
                        <SupportcontactModal close={() => handleOnCloseModal()} />
                    ) :
                        (
                            <></>
                        )
                }
              
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default SupportInnertopic