import React, { forwardRef, useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";

const CreateServiceOfferDetails = (props) => {
  const {
    searchData,
    setSearchData,
    showProduct,
    productList,
    loading,
    selectedproduct,
    setSelectedproduct,
    setShowProduct,
    startDate,
    setEndDate,
    endDate,
    setStartDate,
    setSelectType,
    selectType,
    formattedVariants,
    setOfferPrices,
    offerPrices,
    allAddress,
    setPriceType,
    priceType,
  } = props;

  const priceLabels = {
    pos: "POS price",
    b2c: "Marketplace price",
    b2b: "B2B price",
  };
  const dropdownRef = useRef(null);

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );

  const selectOption = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];

  const selectPriceOption = [
    { value: "same", label: "Same Offer Price" },
    { value: "different", label: "Different Offer Price" },
  ];

  const handlePriceTypeChange = (selectedOption) => {
    setOfferPrices({});
    setPriceType(selectedOption);
  };

  const handleSamePriceChange = (variantId, value) => {
    if (selectType?.value === "percentage") {
      value = value.replace(/\D/g, ""); // Ensures only whole numbers
    }
    setOfferPrices((prev) => ({
      ...prev,
      [variantId]: {
        ...prev?.[variantId],
        pos: value, // Apply to POS
        b2c: value, // Apply to B2C
      },
    }));
  };

  const handleTypeChange = (selectedOption) => {
    setSelectType(selectedOption);
  };

  // Handle price change
  const handlePriceChange = (variantId, appName, value) => {
    if (selectType?.value === "percentage") {
      value = value.replace(/\D/g, ""); // Ensures only whole numbers
    }
    setOfferPrices((prev) => ({
      ...prev,
      [variantId]: {
        ...prev?.[variantId],
        [appName]: value,
      },
    }));
  };

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowProduct(false); // Close dropdown if clicked outside
      }
    };

    if (showProduct) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProduct]);

  return (
    <section className="createOfferPublish_Section firstCreateOffer">
      <h2 className="fontSize24 fw500 txtBlue mb-3">Offer Details</h2>
      <div className="position-relative">
        <div className="input-group search_group searchOfferInput">
          <img
            src={Images.SearchIcon}
            alt=""
            className="img-fluid searchIcon"
          />
          <input
            type="text"
            className="form-control"
            placeholder="Search Service here"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
          />
        </div>
        {showProduct && (
          <div className="SearchDrop" ref={dropdownRef}>
            <h3 className="fontSize16 mt-3 fw500 txtDarkblue">Match result</h3>
            <div className=" commonTable productOfferTable table-responsive">
              <table className="w-100">
                <tbody>
                  {loading ? (
                    <>
                      <tr>
                        <td colSpan={11}>
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="spinner-border spinner-border-md spinnerDark"></span>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : Array.isArray(productList) && productList.length > 0 ? (
                    productList.map((product, index) => (
                      <tr
                        key={index}
                        // onClick={() => {
                        //   setSelectedproduct(product);
                        //   setShowProduct(false);
                        //   setSearchData("");
                        // }}
                      >
                        <td>
                          <div className="productAvailabilityChild align-items-center">
                            <div className="form-group">
                              <input
                                id={index}
                                type="checkbox"
                                checked={selectedproduct?.id === product.id} // Ensure correct state
                                onChange={(e) => {
                                  setSelectedproduct(
                                    e.target.checked ? product : null
                                  ); // Set or reset
                                  setShowProduct(false);
                                  setSearchData("");
                                }}
                              />

                              <label className="me-0" htmlFor={index}></label>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={product?.image}
                                alt="img"
                                className="productItemImg"
                              />
                              <div>
                                <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-start">
                                  {product.name}
                                </h3>

                                {/* <div className="d-flex align-items-center gap-2">
                                  <span className="skyblueDot"></span>
                                  <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                    SKU{" "}
                                    {
                                      product?.supplies[0]?.supply_variants[0]
                                        ?.sku
                                    }
                                  </h6>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="fontSize12 txtLightBlue">
                          <h4 className="fontSize12 txtDarkblue m-0">
                            Cost price
                          </h4>
                          <h4 className="fontSize12 txtDarkblue mt-1 mb-0">
                            {" "}
                            {"$"} {product.price}
                          </h4>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="fontSize14 txtLightBlue" colSpan={7}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {selectedproduct && (
        <div className="commonTable productOfferTable table-responsive mt-3">
          <table className="w-100">
            <tbody>
              <tr>
                <td>
                  <div className="productAvailabilityChild align-items-center">
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={selectedproduct?.image}
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-start">
                          {selectedproduct.name}
                        </h3>

                        {/* <div className="d-flex align-items-center gap-2">
                          <span className="skyblueDot"></span>
                          <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                            SKU{" "}
                            {
                              selectedproduct?.supplies[0]?.supply_variants[0]
                                ?.sku
                            }
                          </h6>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="fontSize12 txtLightBlue">
                  <h4 className="fontSize12 txtDarkblue m-0">Cost price</h4>
                  <h4 className="fontSize12 txtDarkblue mt-1 mb-0">
                    {" "}
                    {"$"} {selectedproduct.price}
                  </h4>
                </td>
                <td>
                  <img
                    src={Images.offerCross}
                    className="img-fluid cursorPoint"
                    alt="cross image"
                    onClick={() => {
                      setSelectedproduct("");
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className={`pb-4 ${!selectedproduct ? "opacityBox" : ""}`}>
        <div className="offerPeriodBox mt-4">
          <h5 className="fontSize18 fw500 txtDarkblue mb-0">Period</h5>

          <div className="row">
            <div className="col-md-6">
              <div className="datePicker_Parent pt-0 mt-4">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  Start from
                </label>
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    if (endDate < date) {
                      setEndDate(date); // Ensure endDate is not before startDate
                    }
                  }}
                  minDate={new Date()} // Start Date should be today or later
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">
                  Starts at 00:00 AM
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="datePicker_Parent pt-0 mt-4">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  End on
                </label>
                <DatePicker
                  showIcon
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={startDate} // End Date cannot be before Start Date
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">Ends at 11:59 PM</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3">
          {/* <h5 className="fontSize18 fw500 txtDarkblue mb-0">Offer Pricing</h5> */}
          <div className="editVariant_SecondBox mt-4">
            <div className="upcBoxParent">
              <div className="row">
                <div className="col-4">
                  <div className="upcBox">
                    <h3 className="fontSize18 mb-0">Offer price option</h3>
                  </div>
                </div>
                <div className="col-8">
                  <div className="upcBox upcRightBox">
                    <div className="reactSelectParent offerModalSelect">
                      <ReactSelect
                        options={selectPriceOption}
                        value={priceType}
                        onChange={(selectedOption) =>
                          handlePriceTypeChange(selectedOption)
                        }
                        placeholder="Select one"
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682" // Background color for selected option
                              : state.isFocused
                              ? "#f5f6fc" // Background color for hovered option
                              : "#fff", // Default background color
                            // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                            color: state.isSelected
                              ? "#fff" // Background color for selected option
                              : "#263682", // Text color
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0px",
                          }),
                        }}
                        // onChange={onChannelChange}
                        components={{
                          DropdownIndicator: () => (
                            <img src={Images.blueDownArrow} alt="drop_icon" />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="upcBoxParent">
              <div className="row">
                <div className="col-4">
                  <div className="upcBox">
                    <h3 className="fontSize18 mb-0">Offer Type</h3>
                  </div>
                </div>
                <div className="col-8">
                  <div className="upcBox upcRightBox">
                    <div className="reactSelectParent offerModalSelect">
                      <ReactSelect
                        options={selectOption}
                        value={selectType}
                        onChange={(selectedOption) =>
                          handleTypeChange(selectedOption)
                        }
                        placeholder="Select one"
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682"
                              : state.isFocused
                              ? "#f5f6fc"
                              : "#fff",
                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636e9f",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0px",
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <img src={Images.blueDownArrow} alt="drop_icon" />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {priceType?.value === "same" ? (
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Offer price</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox inputUpcBox">
                      <div className="inputGroup">
                        <input
                          className="productVariant_Input"
                          name="upc"
                          type="number"
                          maxLength={14}
                          value={
                            offerPrices[formattedVariants[0]?.supply_variant_id]
                              ?.pos || ""
                          }
                          onChange={(e) =>
                            handleSamePriceChange(
                              formattedVariants[0]?.supply_variant_id,
                              e.target.value
                            )
                          }
                        />
                          {selectType?.value === "amount" ? (
                              <span className="inputDollarSign">$</span>
                            ) : (
                              <span className="inputDollarSign usdTxt">%</span>
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* POS Price Section */}
                <div className="upcBoxParent">
                  <div className="row">
                    <div className="col-4">
                      <div className="upcBox">
                        <h3 className="fontSize18 mb-0">POS price</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="upcBox upcRightBox inputUpcBox">
                        <div className="inputGroup">
                          <input
                            className="productVariant_Input"
                            name="upc"
                            type="number"
                            maxLength={14}
                            value={
                              offerPrices[
                                formattedVariants[0]?.supply_variant_id
                              ]?.pos || ""
                            }
                            onChange={(e) =>
                              handlePriceChange(
                                formattedVariants[0]?.supply_variant_id,
                                "pos",
                                e.target.value
                              )
                            }
                          />
                            {selectType?.value === "amount" ? (
                              <span className="inputDollarSign">$</span>
                            ) : (
                              <span className="inputDollarSign usdTxt">%</span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* B2C Price Section */}
                <div className="upcBoxParent">
                  <div className="row">
                    <div className="col-4">
                      <div className="upcBox">
                        <h3 className="fontSize18 mb-0">Marketplace price</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="upcBox upcRightBox inputUpcBox">
                        <div className="inputGroup">
                          <input
                            className="productVariant_Input"
                            name="upc"
                            type="number"
                            maxLength={14}
                            value={
                              offerPrices[
                                formattedVariants[0]?.supply_variant_id
                              ]?.b2c || ""
                            }
                            onChange={(e) =>
                              handlePriceChange(
                                formattedVariants[0]?.supply_variant_id,
                                "b2c",
                                e.target.value
                              )
                            }
                          />
                             {selectType?.value === "amount" ? (
                              <span className="inputDollarSign">$</span>
                            ) : (
                              <span className="inputDollarSign usdTxt">%</span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateServiceOfferDetails;
