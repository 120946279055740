import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import moment from 'moment-timezone';
import { activeDeactiveUserAccountAsync, dashboardEmpty, deleteUserAccountAsync, getSellerAddressesApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressApiAsync, logout, selectAddressList, selectLoginAuth, updateMerchantApiAsync } from '../../auth/authSlice';
import CustomModal from '../../../components/shared/CustomModal';
import UpdateBusiness from '../../payrollAndStaff/Modals/UpdateBusiness';
import DeleteModal from '../../../components/shared/modals/DeleteModal';
import { toast } from 'react-toastify';
import { UserStatusAsync } from '../../systemConfig/systemConfigSlice';
import { useTranslation } from 'react-i18next';
import { imageUploadAsync } from '../../message/messageSlice';
import { productEmpty } from '../../myProducts/productSlice';
import { bookingEmpty } from '../../bookings/bookingSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MarketingEmpty } from '../../marketing/marketingSlice';


const BusinessDetails = (props) => {
    const history=useHistory()
    const { t } = useTranslation();
    const [userDetails, setuserDetails] = useState(null);
    const [document, setdocument] = useState(null);
    const [uploadProfile, setuploadProfile] = useState(false);
    const [isActive, setisActive] = useState(true);
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const addressList=useSelector(selectAddressList);
    const [loading, setLoading] = useState(false);
    // Start Modal
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            flag: flag,
            title: flag,
        });

        setKey(Math.random());
    };
    // End Modal
    useEffect(() => {
        setuserDetails(auth?.payload);
        setisActive(auth?.payload?.is_seller_active);
        setdocument(auth?.payload?.user?.user_profiles?.business_logo

            ||null
        )
    }, [auth?.payload])
    const [storeList, setstoreList] = useState([])
    const getSellerAddressDetails = async () => {
        const prams = {
            sellerId: auth?.payload?.uniqe_id
                    ? auth?.payload?.uniqe_id
                    : auth?.payload?.user?.unique_uuid,
                    token: auth?.payload?.token,
        };
        dispatch(getAddressApiAsync(prams))
            .then(unwrapResult)
            .then(res => {
                if (res?.payload) {
                    setstoreList(res?.payload);
                }
            })
            .catch(err => {
            })
    }
    useEffect(() => {
       if(addressList?.payload){
        setstoreList(addressList?.payload);
       }
       else getSellerAddressDetails();
    }, [])
    const userLogout = async () => {
        await dispatch(logout());
        await dispatch(productEmpty());
        await dispatch(dashboardEmpty());
        await dispatch(bookingEmpty());
        await dispatch(MarketingEmpty());
        window.localStorage.clear();
        toast.success("Successfully Logout");
        await history.push("/");
      };
    const getCapitalize=(word)=>{
        if(!word||word=="")return "";
        let len=word?.length;
        let str= word?.charAt(0)?.toUpperCase();
        if(len>1)str+=word?.slice(1);
        return str;
    }
    const handleDeleteBusiness = (value) => {
        setLoading(true);
        let data = {
            postData:{
                is_seller_active:value
            },
            tokenData:auth?.payload?.token
        };
        dispatch(activeDeactiveUserAccountAsync(data))
        .then(unwrapResult)
        .then(res => {
                setisActive(value);
                setLoading(false);
                handleOnCloseModal();
                // userLogout();
            })
            .catch(err => {
                setLoading(false);
                handleOnCloseModal();
                // create toast
                toast.error("Unable to delete business");
            })
    }
    const handleBusinessUpdate = () => {
        handleOnCloseModal();
        props?.getDetails()
    }
  const handleChange = (e) => {
    e.preventDefault();
  let  imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    setuploadProfile(true);
    // setDataLoading(true)
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then(async(obj) => {
        let url=obj?.payload[0]?.filePath;
        let params={
            postData: {
                id: auth?.payload?.user?.user_profiles?.user_id
        ? auth?.payload?.user?.user_profiles?.user_id
        : auth?.payload?.user_profiles?.user_id,
        business_logo: url,
            },
            tokenData: auth?.payload?.token,
        }
       let data=await dispatch(updateMerchantApiAsync(params));
       if(!data){
        setuploadProfile(false)
         toast.error("Unable to update profile image");
         return;
       }else{
        setdocument(url);
        setuploadProfile(false)
       }

      })
      .catch((obj) => {
        setuploadProfile(false);
        toast.error("Unable to upload profile image");
      });
  };
    return <>
        <div className='businessDetail_ personalDetail_'>
            <h3 className='appointMain'>{t("businessDetails")}</h3>
            <div className="staffInfoBox ">
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="staffProfileInfo">
                            <div className="uploadStaffImg">
                                <div className="showImg_Parent">
                                    {document && (
                                        <img src={document|| Images.userImg_} className="staff_Profile_Img" />
                                    )}

                                    <label htmlFor="file-upload" className="uploadFileLabel">
                                        {
                                            uploadProfile?<>
                                            <span style={{
                                                position: "absolute",
                                                color: "rebeccapurple",
                                                right: "-8%"
                                            }} className="spinner-border spinner-border-sm spinnerLight"></span>
                                            </>:<img
                                            src={Images.uploadImgCamera}
                                            alt="img"
                                            className="uploadStaffCameraImg"
                                        />

                                        }
                                    </label>

                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        name="profile_image"
                                        className="d-none"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>
                            {/* <img className='' src={Images.supportuser1} alt='edit' /> */}
                            <div className="staffProfileSub">
                                <h4 className="appointMain">
                                    {
                                        userDetails?.user?.user_profiles?.organization_name

                                    }
                                </h4>
                                <div className="staffAddress">
                                    <img className='' src={Images.darkblueLocationIcon} alt='edit' />
                                    <h4 className="headingblue14_ m-0">
                                        {
                                            userDetails?.user?.user_profiles?.current_address?.format_address
                                        }
                                    </h4>
                                </div>
                                <div className="staffAddress">
                                    <img className='' src={Images.darkbluePhoneIcon} alt='edit' />
                                    <h4 className="headingblue14_ m-0">
                                        {
                                            userDetails?.user?.user_profiles?.full_phone_number
                                        }
                                    </h4>
                                </div>
                                <div className="staffAddress">
                                    <img className='' src={Images.emailLogo} alt='edit' />
                                    <h4 className="headingblue14_ m-0">
                                        {userDetails?.user?.email||userDetails?.email}
                                    </h4>
                                </div>
                                <div className="staffAddress">
                                    <img className='' src={Images.wwwIcon} alt='edit' />
                                    <h4 className="headingblue14_ m-0">
                                        {userDetails?.user?.user_profiles?.business_website??"___"}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="staffProfileData">
                            <div className="flexDiv">
                                <h4 className="stateList_head m-0">Joined Data</h4>
                                <h4 className="headingblue14_ m-0">
                                    {
                                        moment(userDetails?.user?.user_profiles?.created_at).format(
                                        "MMM DD, YYYY"
                                        )
                                    }
                                </h4>
                            </div>
                            <div className="flexDiv mt-2">
                                <h4 className="stateList_head m-0">Active since:</h4>
                                <h4 className="headingblue14_ m-0">
                                    {
                                        moment().diff(userDetails?.user?.user_profiles?.created_at, 'days')
                                        
                                        
                                    }
                                    {" "}Days
                                </h4>
                            </div>
                            <div className="flexDiv mt-2">
                                <h4 className="stateList_head m-0">Business type</h4>
                                <h4 className="headingblue14_ m-0">
                                    {
                                        
                                        getCapitalize(userDetails?.user?.user_profiles?.business_type?.[0])
                                    }
                                    {
                                        userDetails?.user?.user_profiles?.business_type?.length&&<>
                                        {" & "}
                                        {
                                        getCapitalize(userDetails?.user?.user_profiles?.business_type?.[1])

                                        }
                                        </>
                                    }
                                </h4>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='storeLocation_ aboutbusiness mt-3' style={{
            flexDirection: "column"
        }}>
            <div className='storeTop_'>
                <h3 className='headblue18 mb-0'>{t("aboutBusiness")}</h3>
                <figure onClick={() => {
                    handleOpenModal("aboutBusinessModal")
                }} className='editprofileIcon_ mb-0'>
                    <img className='editicon_' src={Images.editIcon} />
                </figure>
            </div>
            <div className='shipCrries mt-1' style={{
                flexDirection: "column"
            }}>
                <div className='shipcarierLeft_'>
                    {
                        userDetails?.user?.user_profiles?.business_details
                    }

                </div>
            </div>
        </div>
        <div className='storeLocation_ aboutbusiness mt-3' style={{
            flexDirection: "column"
        }}>
            <div className='storeTop_'>
                <h3 className='headblue18 mb-0'>{t("storeDetails")}</h3>
                {/* <figure onClick={() => {
                    // handleOpenModal("aboutBusinessModal")
                }} className='editprofileIcon_ mb-0'>
                    <img className='editicon_' src={Images.editIcon} />
                </figure> */}
            </div>




            <div className='shipCrriesabout mt-1' style={{
                flexDirection: "column"
            }}>
                <div className='shipCarrier_new'>
                    {
                        storeList?.map((store, index) => (

                            <div key={index} className='shipcarierLeft_'>
                                <img className='carrir_img' src={Images.businesStore} />
                                <div className='rightcontentCarrer'>
                                    <h4 className='textInnerHead'>{store?.business_name??"Store"}</h4>
                                    <h5 className='smallbluehead bglight'><img className='editicon_' src={Images.locprofileimg} /> {(store?.format_address??store?.street_address??(store?.city+""+store?.state))}</h5>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
        <div className='businesserror_ mt-3' >
            <div className='ordrsettingHead_'>
                <h3 className='normalHeading_ mb-1'>{t("DeactiveBusiness")}</h3>
                <p className='textInnerHead2'>{t("deactiveBusiness")}</p>
            </div>
            <div className='d-flex pt-2'>
                <button onClick={() => {
                    handleOpenModal("DeleteBusiness")
                }} className='deactive_business_btn'>
                    {`${!(isActive)?t('activeBusiness'):t('DeactiveBusiness')}`}
                </button>
            </div>
        </div>
        {/* custom modal */}
        <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            ids={
                modalDetail.flag === "aboutBusinessModal"
                    ? "aboutBusinessModal"
                    : modalDetail.flag === "DeleteBusiness"
                        ? "deactivatemodal"
                        : ""
            }


            child={
                modalDetail.flag === "aboutBusinessModal" ? (
                    <UpdateBusiness
                        close={() => handleOnCloseModal()}
                        handleBusinessUpdate={() => handleBusinessUpdate()}
                        data={
                            userDetails?.user?.user_profiles?.business_details
                        }

                    />) : modalDetail.flag = "DeleteBusiness" ? (
                        <DeleteModal
                            title={`${!(isActive)?"A":"Dea"}ctivate your Business"`}
                            close={() => handleOnCloseModal()}
                            isDeleting={loading}
                            deleteLoading={loading}
                            delete={(value) => {
                                handleDeleteBusiness(!(isActive));
                            }}
                        />
                    ) : (
                        <></>
                    )
            }
            header={
                modalDetail.flag === "aboutBusinessModal" ? (
                    <>
                        <div className="flexBox gap-3">
                            <h4 className="fontSize24new txtDarkblue mb-0">
                                {t('aboutBusiness')}
                            </h4>
                        </div>
                        <img
                            src={Images.modalCross}
                            className="ModalCancel cursorPointer"
                            onClick={handleOnCloseModal}
                        />
                    </>
                ) : ("")
            }
            onCloseModal={() => handleOnCloseModal()}
        />


    </>

}

export default BusinessDetails