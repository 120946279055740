import React, { forwardRef, useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images"
import ReactSelect from "react-select";
import { resultSelectOption } from "../../../constants/common.constant";
import { Dropdown } from "react-bootstrap";
import moment from "moment-timezone";
import { Link } from 'react-router-dom'
import NotificationButton from '../../../components/NotificationButton';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import useDebounce from '../../../../utilities/helpers';
import { selectLoginAuth } from '../../auth/authSlice';
import { deleteCampaignApiAsync, getCampaignDataApiAsync, getCoupnsDataAsync, selectMarketingData, updateCampaignApiAsync } from '../marketingSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import PaginationHeader from '../../../components/PaginationHeader';
import Loader from '../../../components/UI/Loader/Loader';
import ReactDatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CampaignIndex = () => {
    const { t } = useTranslation();
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [selectedCouponData, setSelectedCouponData] = useState()
    const marketingData = useSelector(selectMarketingData);
    console.log(marketingData , "marketingData")
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const couponList = marketingData?.campaignData;
    console.log(couponList,"check it")
    const [page, setPage] = useState(couponList?.current_page)
    const [totalRows,setTotalRows] = useState(couponList?.total);
    const [perPage, setPerPage] = useState(10)
    const auth = useSelector(selectLoginAuth)
    const token = auth?.payload?.token
    const dispatch = useDispatch();
    const [search, setSearch] = useState("")
    const debouncedSearch = useDebounce(search, 900);
    const [showActiveTab, setShowActiveTab] = useState(true);
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [IsSelectedAll, setIsSelectedAll] = useState(false);
    const history=useHistory();

     const getCampaignList = () => {
        const params = {
            tokenData: token,
            postData: {
                seller_id: auth?.payload?.uniqe_id,
                page: page,
                limit: perPage,
                ...(endDate !== null && {
                    end_date: moment(endDate).format("YYYY-MM-DD"),
                    start_date: moment(startDate).format("YYYY-MM-DD"),
                }),
                ...(debouncedSearch !== "" && {
                    search: debouncedSearch
                }),
                // need_active:showActiveTab,
               ...(showActiveTab?{ need_in_progress:true}: {need_completed:true})
            }
        }
        setSelectedCoupons([]);
        dispatch(getCampaignDataApiAsync(params)).then(unwrapResult).then(res=>{
            let current_page=res?.payload?.current_page??1;
            let total=res?.payload?.total??0;
            setPage(current_page)
            setTotalRows(total);
        });
    }
    const handleSelectedCoupons = (couponId) => {
        if (selectedCoupons?.includes(couponId)) {
          setSelectedCoupons(selectedCoupons?.filter(id => id !== couponId))
          setIsSelectedAll(false);
        }
        else {
          setIsSelectedAll(selectedCoupons?.length === Number(couponList?.data?.length||0)- 1);
          setSelectedCoupons(prev => ([...prev, couponId]));
        }
    }
    const handleAllSelectedCoupons = (value) => {
        if (value) {
            setSelectedCoupons(couponList?.data?.map(item => item.id))
        }
        else {
            setSelectedCoupons([])
        }
    }

    useEffect(() => {
        getCampaignList()
    }, [page, perPage, endDate, debouncedSearch,showActiveTab])
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Marketings";
    }, []);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            flag: flag,
            title: flag,
        });
        setKey(Math.random());
    };
    const handleShowActiveTab=(value)=>{
        setShowActiveTab(value);
    }
    const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => {
        let [start, end] = value.split("-");
     return   <div className="date-picker-container" onClick={onClick}>
            <img className="calendar-icon" src={Images.bookings} alt="Calendar" />
            <p className="date-text">
                {moment.utc(start).format("MMMM YYYY")}
            </p>
        </div>
    });
    const handleCampaignDelete=async(id)=>{
        const params={
            postData:{
                id:id
            },
            tokenData:token
        };
        try {
            const data=await dispatch(deleteCampaignApiAsync(params)).then(unwrapResult);
            if(data){
                
            }
        } catch (error) {
            toast.error("Unable to delete campaign");
        }
    }
    const handleMultiDeleteCampaigns=async()=>{
        if(selectedCoupons?.length==0){
            toast.error("Please select campaign to delete");
            return;
        }
        try {
            const params={
                postData:{
                    seller_id: auth?.payload?.uniqe_id,
                    campaign_ids:selectedCoupons
                },
                tokenData:token
            };
            const data=await dispatch(deleteCampaignApiAsync(params)).then(unwrapResult);
            if(data){
                setIsSelectedAll(false);
                setSelectedCoupons([]);
                getCampaignList();
            }
            
        } catch (error) {
            toast.error("Unable to delete campaigns")
        }
    }
    const handleChangeCampaignStatus=async(id,status)=>{
        try {
            const params={
                postData:{
                    id,
                    status:String(status)
                },
                tokenData:auth?.payload?.token
            }
            const res=await updateCampaignDetail(params);
            if(res){
                toast.success("status has been changed");
                getCampaignList();
            }
            
        } catch (error) {
            
        }

    }
    const updateCampaignDetail=async(params)=>{
        try {
            const data=await dispatch(updateCampaignApiAsync(params)).then(unwrapResult);
            return data;
            
        } catch (error) {
            return null;
        }

    }

    return (
        <>
            <div className='couponListing_ chartsOuter'>
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className="fontSize24 txtDarkblue fw500 mb-0">
                        {t('campaign')}
                    </h3>
                    <div className="d-flex align-items-center gap-4">
                        <NotificationButton />
                        <div className="input-group search_group" style={{
                            width: "150px"
                        }}>
                            <img
                                src={Images.SearchIcon}
                                alt="img"
                                className="img-fluid searchIcon"
                            />
                            <input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type="text"
                                className="form-control" />
                        </div>
                        <Link to="/createCampaign" className='addNew_' type='submit'>{t('createCampaign')} <img src={Images.addButtonImg} alt="addNew_Image" className='ps-2'></img></Link>
                    </div>
                </div>
                <div className="verificationTotal mt-3">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-flex align-items-center gap-2">
                                <button
                                    className={`activeProductBtn ${showActiveTab?"active":""}`}
                                    onClick={()=>handleShowActiveTab(true)}

                                >
                                    {t('active')}  <span className="activeProductofferItem">

                                    </span>
                                </button>
                                <button
                                    className={`activeProductBtn ${!showActiveTab?"active":""}`}
                                    onClick={()=>{
                                        handleShowActiveTab(false);
                                        setSelectedCoupons([]);
                                        setIsSelectedAll(false);
                                        setEndDate(null);
                                    }
                                    }
                                >
                                    {t('expired')}
                                    <span className="activeProductofferItem">

                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="d-flex align-items-center justify-content-end gap-3">
                            <div className="bookingLeftTwo">
                                <ReactDatePicker
                                onChange={(date) => {
                                    const [start, end] = date;
                                   if(start==end){
                                    setEndDate(null)
                                   }else setEndDate(end);
                                    setStartDate(start);
                                    
                                   
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                               
                                customInput={<DatePickerCustomComponent />}
                                />
              </div>
                                <PaginationHeader
                                    currentPage={page}
                                    totalPage={totalRows}
                                    perPage={perPage}
                                    setCurrentPage={setPage}
                                    setPerPage={setPerPage}
                                     headerClass="col-md-8"
                                    dataLength={totalRows}
                                />
                                {/* <div className="d-flex align-items-center gap-2">
                                    <h3 className="fontSize14 txtDarkblue mb-0">Showing Results</h3>
                                    <div className="reactSelectParent offerHeaderSelect">
                                        <ReactSelect
                                            options={resultSelectOption}
                                            placeholder={50}
                                            classNamePrefix="react-select"
                                            className="react-select-container anlyticSelect"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minWidth: "75px",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    border: "1px solid #D7DEFF",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                        ? "#263682"
                                                        : state.isFocused
                                                            ? "#f5f6fc"
                                                            : "#fff",

                                                    color: state.isSelected ? "#fff" : "#636E9F",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "#636E9F",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator: () => (
                                                    <img
                                                        src={Images.blueDownArrow}
                                                        width={24}
                                                        height={24}
                                                        alt="drop_icon"
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="paginationParent">
                                    <div className="d-flex align-items-center gap-1" >
                                        <div className="backPageBox ">
                                            <img src={Images.arrowDoubleLeft} alt="arrowDoubleRight" />
                                        </div>
                                        <div className="backPageBox backPageBox2">
                                            <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                                        </div>
                                    </div>
                                    <h6 className="fontSize14 txtDarkblue mb-0">{page}-{total_page} of {totalRows}</h6>
                                    <div className="d-flex align-items-center gap-1" >
                                        <div className="nextPageBox nextPageBox2">
                                            <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                                        </div>
                                        <div className="nextPageBox ">
                                            <img src={Images.arrowDoubleRight} alt="arrowDoubleRight" />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="commonTable productOfferTable table-responsive mt-4">
                    <table className="w-100 couponmain_">
                        <thead>
                            <tr >
                                <th>
                                    <div className="productAvailabilityChild align-items-center">
                                        <div className="form-group">
                                            <input 
                                            checked={IsSelectedAll}
                                            onChange={()=>{
                                                if(couponList?.data?.length==0)return;
                                                handleAllSelectedCoupons(!IsSelectedAll);
                                                setIsSelectedAll(prev=>!prev);
                                            }}
                                             type="checkbox" id="id1"
                                            />
                                            <label htmlFor="id1" className="me-0"></label>
                                        </div>
                                    </div>
                                </th>
                                <th>#</th>
                                <th><h3 className="fontSize12 mb-0">{t('campaignName')}</h3></th>
                                <th>{t('created')}</th>
                                <th>{t('startDate')}</th>
                                <th>{t('endDate')}</th>
                                <th>{t('dayLive')}</th>
                                {/* <th>Redeemed</th> */}
                                <th>{t('conversion')}</th>
                                <th>{t('netSale')}</th>
                                <th>{t('status')}</th>

                                <th>
                                    <div className="d-flex align-items-center gap-1 justify-content-end">

                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="variantsTable_Dropdown headrotate_"
                                                    style={{backgroundColor:selectedCoupons?.length?'blue!important':''}}
                                                    disabled={selectedCoupons?.length==0}
                                                >
                                                    <img src={Images.moreVerticalLogo}
                                                    style={{
                                                        filter:
                                                        selectedCoupons?.length
                                                            ? "brightness(0) saturate(100%) invert(14%) sepia(63%) saturate(2662%) hue-rotate(221deg) brightness(94%) contrast(89%)"
                                                            : "",
                                                      }} alt="logo" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                                                    <Dropdown.Item
                                                        href="#/action-3"
                                                        onClick={handleMultiDeleteCampaigns}

                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                                                {t('cancelCampaign')}
                                                            </h3>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {marketingData?.loading && page === 1 && <Loader />}
                            {couponList?.data?.length>0?couponList?.data?.map((item, idx) => {
                                const daysLive =item?.end_date==null?"__": moment(item?.end_date).diff(item?.start_date, "days")
                                return (
                                    <tr >
                                        <td>
                                            <div className="productAvailabilityChild align-items-center">
                                                <div className="form-group">
                                                    <input type="checkbox" 
                                                    id={idx}
                                                    checked={selectedCoupons?.includes(item?.id)}
                                                    onChange={()=>{
                                                        handleSelectedCoupons(item?.id)
                                                    }}
                                                    />
                                                    <label htmlFor={idx} className="me-0"></label>
                                                </div>

                                            </div>
                                        </td>
                                        <td className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                                            {page > 1 ? (
                                                (page - 1) * perPage + 1 + idx
                                            ) : idx + 1}
                                        </td>
                                        <td>
                                            <div>
                                                <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                                                    {item?.code}
                                                </h3>

                                                <div className="d-flex align-items-center gap-2">
                                                    <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                        {item?.name}
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="fontSize12 txtDarkblue">
                                            {moment(item?.created_at).format("DD/MM/YYYY")}
                                        </td>
                                        <td className="fontSize12 txtDarkblue">
                                            {item?.start_date==null?"__": moment(item?.start_date).format("DD/MM/YYYY")??"__"}
                                        </td>
                                        <td className="fontSize12 txtDarkblue">
                                            {item?.end_date==null?"__":moment(item?.end_date).format("DD/MM/YYYY")??"__"}
                                        </td>
                                        <td>
                                            <h6 className="fontSize12 txtDarkblue">
                                                {daysLive??"___"}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6 className="fontSize12 txtDarkblue text-capitalize">
                                            {/* {item?.campaign_for} */}
                                            {item?.conversion}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6 className="fontSize12 txtDarkblue">{item?.net_sales}</h6>
                                        </td>
                                        <td>
                                            {showActiveTab==false?
                                            <h6 className="fontSize12 txtLightBlue "><span className='xpired_'>{t('expired')}</span></h6>:<h6 className={`fontSize12 ${item?.status==0?"txtLightBlue":"colorRed_"} mb-0`}><span className='Activestatus'>{item?.status==0? "Active":"Pause"}</span></h6>}
                                        </td>
                                        <td className="text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="variantsTable_Dropdown"
                                                >
                                                    <img src={Images.moreVerticalLogo} alt="logo" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                                                    {/* <Dropdown.Item
                                                        href="#"
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0">Duplicate</h3>
                                                        </div>
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            history.push(`/campaign/edit/${item.id}`)
                                                          }}
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0">{t('view')}</h3>
                                                        </div>
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item
                                                        href="#"
                                                        onClick={() => {
                                                            // setModalDetail({
                                                            //     show: true,
                                                            //     flag: "CouponModal",
                                                            // });
                                                            // setKey(Math.random());
                                                            // setSelectedCouponData(item)
                                                        }}
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0">Edit</h3>
                                                        </div>
                                                    </Dropdown.Item> */}
                                                    {
                                                        showActiveTab&&<>
                                                        <Dropdown.Item
                                                        href="#"
                                                        onClick={()=>handleChangeCampaignStatus(item?.id,item?.status==1?0:1)}
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0">{item?.status==1?t("reactive"): t("pause")}</h3>
                                                        </div>
                                                    </Dropdown.Item>
                                                    
                                                    <Dropdown.Item
                                                        href="#"
                                                        onClick={()=>handleChangeCampaignStatus(item?.id,2)}
                                                       
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 
                                                            
                                                            className="fontSize14 mb-0 colorRed_">{t('stop')}</h3>
                                                        </div>
                                                    </Dropdown.Item></>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            }):<>
                            <tr className="text-center border-none">
                                <td colSpan="100%">
                                <div className="d-flex">
                                    <p className="text-center w-100">{t('noDataFound')}</p>
                                </div>
                                </td>
                            </tr>
                            
                            </>}
                        </tbody>
                    </table>
                </div>
            </div>
        
        </>
    )
}

export default CampaignIndex