import React, { forwardRef, useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images"
import ReactDatePicker from "react-datepicker";

import { Dropdown } from "react-bootstrap";
import moment from "moment-timezone";
import CustomModal from '../../../components/shared/CustomModal';
import CouponModal from './couponModal';
import { Link } from 'react-router-dom'
import NotificationButton from '../../../components/NotificationButton';
import { getCoupnsDataAsync, selectMarketingData } from '../marketingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import PaginationHeader from '../../../components/PaginationHeader';
import Loader from '../../../components/UI/Loader/Loader';
import { deleteCouponApiAsync, removeMultipleCoupnsApiAsync } from '../../dashboard/dashboardSlice';
import useDebounce from '../../../../utilities/helpers';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const CouponsListing = () => {
    const { t } = useTranslation();
    const [calendarDate, setCalendarDate] = useState(null);
    const [selectedCouponData, setSelectedCouponData] = useState()
    const marketingData = useSelector(selectMarketingData);
    const couponList = marketingData?.couponData;
    console.log(couponList , "couponList")

    const [page, setPage] = useState(couponList?.current_page)
    const [totalRows,setTotalRows] = useState(couponList?.total);
    const [perPage, setPerPage] = useState(10)
    const auth = useSelector(selectLoginAuth)
    const token = auth?.payload?.token
    const dispatch = useDispatch();
    const [search, setSearch] = useState("")
    const debouncedSearch = useDebounce(search, 900);
    const [showActiveTab, setShowActiveTab] = useState(true);
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [IsSelectedAll, setIsSelectedAll] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const history=useHistory();

    const getCouponList = () => {
        const params = {
            tokenData: token,
            postData: {
                seller_id: auth?.payload?.uniqe_id,
                page: page,
                limit: perPage,
                ...(endDate !== null && {
                    end_date: moment(endDate).format("YYYY-MM-DD"),
                    start_date: moment(startDate).format("YYYY-MM-DD"),
                }),
                ...(debouncedSearch !== "" && {
                    search: debouncedSearch
                }),
                // need_active:showActiveTab,
                ...(showActiveTab?{ need_in_progress:true}: {need_completed:true})
            }
        }
        setSelectedCoupons([]);
        dispatch(getCoupnsDataAsync(params)).then(unwrapResult).then(res=>{
            let current_page=res?.payload?.current_page??1;
            let total=res?.payload?.total??0;
            setPage(current_page)
            setTotalRows(total);
        });
    }
    const handleSelectedCoupons = (couponId) => {
        if (selectedCoupons.includes(couponId)) {
            setSelectedCoupons(selectedCoupons.filter(id => id !== couponId))
            setIsSelectedAll(false);
        }
        else {
            setIsSelectedAll(selectedCoupons.length === Number(couponList?.data?.length || 0) - 1);
            setSelectedCoupons(prev => ([...prev, couponId]));
        }
    }
    const handleAllSelectedCoupons = (value) => {
        if (value) {
            setSelectedCoupons(couponList?.data?.map(item => item.id))
        }
        else {
            setSelectedCoupons([])
        }
    }

    useEffect(() => {
        getCouponList()
    }, [page, perPage, endDate, debouncedSearch, showActiveTab])

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Marketings";
    }, []);

    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handelCouponDelete = (id) => {
        const params = {
            postData: {
                id: id ?? selectedCouponData?.id
            },
            tokenData: token
        }
        dispatch(deleteCouponApiAsync(params))
            .then(unwrapResult)
            .then(res => {
                handleOnCloseModal()
                getCouponList()
            }).catch(e => { })
    }
    const handleShowActiveTab = (value) => {
        setShowActiveTab(value);
    }

    const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => {
        let [start, end] = value.split("-");
     return   <div className="date-picker-container" onClick={onClick}>
            <img className="calendar-icon" src={Images.bookings} alt="Calendar" />
            <p className="date-text">
                {moment.utc(start).format("MMMM YYYY")}
            </p>
        </div>
    });
    const handleMultiDeleteCoupons = async () => {
        if (selectedCoupons.length == 0) {
            toast.error("Please select coupons to delete");
            return;
        }
        try {
            const params = {
                postData: {
                    seller_id: auth?.payload?.uniqe_id
                        ? auth?.payload?.uniqe_id
                        : auth?.payload?.user?.unique_uuid,
                    coupon_ids: selectedCoupons
                },
                tokenData: auth?.payload?.token
            }
            const data = await dispatch(removeMultipleCoupnsApiAsync(params)).then(unwrapResult);
            if (data) {
                getCouponList();
            }
            setSelectedCoupons([]);
            setIsSelectedAll(false);

        } catch (error) {
            toast.error("Unable to delete multiple coupons")
        }
    }
    return (
        <>
            <div className='couponListing_ chartsOuter'>
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className="fontSize24 txtDarkblue fw500 mb-0">
                        {t("coupon")}
                    </h3>
                    <div className="d-flex align-items-center gap-4">
                        <NotificationButton />
                        <div className="input-group search_group" style={{
                            width: "150px"
                        }}>
                            <img
                                src={Images.SearchIcon}
                                alt="img"
                                className="img-fluid searchIcon"
                            />
                            <input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type="text"
                                className="form-control" />
                        </div>
                        <Link to="/createCoupons" className='addNew_' type='submit'>{t("createCoupon")} <img src={Images.addButtonImg} alt="addNew_Image" className='ps-2'></img></Link>
                    </div>
                </div>
                <div className="verificationTotal mt-3">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-flex align-items-center gap-2">
                                <button
                                    className={`activeProductBtn ${showActiveTab ? "active" : ""}`}
                                    onClick={() => handleShowActiveTab(true)}
                                >
                                    {t("active")}  <span className="activeProductofferItem">

                                    </span>
                                </button>
                                <button
                                    className={`activeProductBtn ${!showActiveTab ? "active" : ""}`}
                                    onClick={() => handleShowActiveTab(false)}
                                >
                                    {t("expired")}
                                    <span className="activeProductofferItem">

                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="d-flex align-items-center justify-content-end gap-3">
                                <div className="bookingLeftTwo">
                                <ReactDatePicker
                                onChange={(date) => {
                                    const [start, end] = date;
                                   if(start==end){
                                    setEndDate(null)
                                   }else setEndDate(end);
                                    setStartDate(start);
                                    
                                   
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                               
                                customInput={<DatePickerCustomComponent />}
                                />
                                </div>
                                <PaginationHeader
                                    currentPage={page}
                                    totalPage={totalRows}
                                    perPage={perPage}
                                    setCurrentPage={setPage}
                                    setPerPage={setPerPage}
                                    headerClass="col-md-8"
                                    dataLength={totalRows}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="commonTable productOfferTable table-responsive mt-4">
                    <table className="w-100 couponmain_">
                        <thead>
                            <tr >
                                <th>
                                    <div className="productAvailabilityChild align-items-center">
                                        <div className="form-group">
                                            <input
                                                checked={IsSelectedAll}
                                                onChange={() => {

                                                    handleAllSelectedCoupons(!IsSelectedAll);
                                                    setIsSelectedAll(prev => !prev);
                                                }}
                                                type="checkbox" id="id1"
                                            />
                                            <label htmlFor="id1" className="me-0"></label>
                                        </div>
                                    </div>
                                </th>
                                <th>#</th>
                                <th><h3 className="fontSize12 mb-0">{t("couponName")}</h3></th>
                                <th>{t('created')}</th>
                                <th>{t('startDate')}</th>
                                <th>{t("endDate")}</th>
                                <th>{t('dayLive')}</th>
                                <th>{t('redeemed')}</th>
                                <th>{t("netSales")}</th>
                                <th>{t('status')}</th>

                                <th>
                                    <div className="d-flex align-items-center gap-1 justify-content-end">
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="variantsTable_Dropdown headrotate_"
                                                    style={{ backgroundColor: selectedCoupons.length ? 'blue!important' : '' }}
                                                    disabled={selectedCoupons.length == 0}
                                                >
                                                    <img src={Images.moreVerticalLogo} alt="logo"
                                                        style={{
                                                            filter:
                                                                selectedCoupons.length
                                                                    ? "brightness(0) saturate(100%) invert(14%) sepia(63%) saturate(2662%) hue-rotate(221deg) brightness(94%) contrast(89%)"
                                                                    : "",
                                                        }}
                                                    />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                                                    <Dropdown.Item
                                                        href="#/action-3"
                                                        onClick={handleMultiDeleteCoupons}

                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                                                {t('cancelCoupon')}
                                                            </h3>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {marketingData?.loading && page === 1 && <Loader />}
                            {couponList?.data?.length > 0 ? couponList?.data?.map((item, idx) => {
                                const daysLive = moment(item?.end_time).diff(item?.start_time, "days")
                                return (
                                    <tr >
                                        <td>
                                            <div className="productAvailabilityChild align-items-center">
                                                <div className="form-group">
                                                    <input type="checkbox"
                                                        id={idx}
                                                        checked={selectedCoupons.includes(item?.id)}
                                                        onChange={() => {
                                                            handleSelectedCoupons(item?.id)
                                                        }}
                                                    />
                                                    <label htmlFor={idx} className="me-0"></label>
                                                </div>

                                            </div>
                                        </td>
                                        <td className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                                            {page > 1 ? (
                                                (page - 1) * perPage + 1 + idx
                                            ) : idx + 1}
                                        </td>
                                        <td>
                                            <div>
                                                <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                                                    {item?.code}
                                                </h3>

                                                <div className="d-flex align-items-center gap-2">
                                                    <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                        {item?.description}
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="fontSize12 txtDarkblue">
                                            {moment(item?.created_at).format("DD/MM/YYYY")}
                                        </td>
                                        <td className="fontSize12 txtDarkblue">
                                            {moment(item?.start_time).format("DD/MM/YYYY")}
                                        </td>
                                        <td className="fontSize12 txtDarkblue">
                                            {moment(item?.end_time).format("DD/MM/YYYY")}
                                        </td>
                                        <td>
                                            <h6 className="fontSize12 txtDarkblue">
                                                {daysLive}
                                            </h6>
                                        </td>
                                        <td>
                                            <h6 className="fontSize12 txtDarkblue">{item?.total_usage}</h6>
                                        </td>
                                        <td>
                                            <h6 className="fontSize12 txtDarkblue">${Number(item?.net_sales||0)?.toFixed(2)}</h6>
                                        </td>
                                        <td>
                                           {showActiveTab? <h6 className="fontSize12 txtLightBlue mb-0"><span className='Activestatus'>{t('active')}</span></h6>:
                                            <h6 className="fontSize12 txtLightBlue"><span className='xpired_'>{t('expired')}</span></h6>}
                                        </td>
                                        <td className="text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="variantsTable_Dropdown"
                                                >
                                                    <img src={Images.moreVerticalLogo} alt="logo" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            history.push(`/coupons/edit/${item?.id}`)
                                                          }}
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0">{t('duplicate')}</h3>
                                                        </div>
                                                    </Dropdown.Item>
                                                    {
                                                        showActiveTab&&<>
                                                        <Dropdown.Item
                                                        href="#"
                                                        onClick={() => {
                                                            setModalDetail({
                                                                show: true,
                                                                flag: "CouponModal",
                                                            });
                                                            setKey(Math.random());
                                                            setSelectedCouponData(item)
                                                        }}
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0">{t('view')}</h3>
                                                        </div>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        href="#"
                                                        onClick={() => {
                                                            handelCouponDelete(item?.id);
                                                        }}
                                                    >
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <h3 className="fontSize14 mb-0 colorRed_">{t("cancelCoupon")}</h3>
                                                        </div>
                                                    </Dropdown.Item></>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            }) : <>
                                <tr className="text-center border-none">
                                    <td colSpan="100%">
                                        <div className="d-flex">
                                            <p className="text-center w-100">{t('noDataFound')}</p>
                                        </div>
                                    </td>
                                </tr>

                            </>}

                        </tbody>
                    </table>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "CouponModal"
                        ? "couponModal"

                        : ""
                }
                child={
                    modalDetail.flag === "CouponModal" ? (
                        <CouponModal
                            couponData={selectedCouponData}
                            close={() => handleOnCloseModal()} />
                    ) :
                        (
                            <></>
                        )
                }
                header={
                    <>
                        {modalDetail.flag === "CouponModal" ? (
                            <>
                                <div className="d-flex align-items-start justify-content-between w-100">
                                    <div>
                                        <h3 className="fontSize24 fw500 txtDarkblue mb-2 d-flex align-items-center">
                                            {selectedCouponData?.code} <span className='activeSatus ms-2'>Active</span>
                                        </h3>
                                        <h5 className='bankaccNumber_'>{selectedCouponData?.description}</h5>

                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className="d-flex align-items-center gap-1 justify-content-end pe-2">

                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic"
                                                        className="variantsTable_Dropdown headrotate_"
                                                    >
                                                        <img src={Images.moreVerticalLogo} alt="logo" />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                                                        <Dropdown.Item
                                                            href="#/action-3"
                                                            onClick={() => {
                                                                history.push(`/coupons/edit/${selectedCouponData?.id}`)
                                                              }}
                                                        >
                                                            <div className="d-flex gap-2 align-items-center">
                                                                <h3 className="fontSize14 mb-0 ">
                                                                    Duplicate
                                                                </h3>
                                                            </div>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="#/action-3"

                                                        >
                                                            <div
                                                                onClick={handelCouponDelete}
                                                                className="d-flex gap-2 align-items-center">
                                                                <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                                                    Cancel Coupon
                                                                </h3>
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        <img
                                            className='modalcross'
                                            src={Images.modalCross}
                                            alt="logo"
                                            onClick={() => handleOnCloseModal()}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                            : (
                                ""
                            )}
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default CouponsListing