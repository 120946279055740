import React, { useEffect, useState } from "react";
import ProductSidebar from "../../components/shared/productSidebar";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getAddedToInventoryProductsAsync } from "../dashboard/dashboardSlice";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import CustomModal from "../../components/shared/CustomModal";
import MyProductDetials from "../myProducts/myProductDetails";
import DBImportProductModal from "../myProducts/DBImportProductModal";
import SuccessfulModal from "../auth/successfulModal";
import UploadproductModal from "../scalePlan/ uploadproductModal";
import {
  blueBackArrow,
  SearchIcon,
  webPosArrow,
  plusCircleOutline,
} from "../../../utilities/images";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";

const Product = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [addProduct, setAddProduct] = useState(location?.state?.products || []);

  const [selectedProduct, setSelectedProduct] = useState({});
  const dispatch = useDispatch();

  const [productData, setProductData] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [brandIds, setBrandIds] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const [checkedState, setCheckedState] = useState([]);

  const [checkedValue, setCheckedValue] = useState([]);

  const [selectedProductData, setSelectedProductData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState("");

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setCheckedValue([]);
    setSelectedProductData([]);
    setAllAddressIds([]);
    setSelectedAddress([]);
    setCheckedState(new Array(productData?.length)?.fill(false));
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalRows / perPage)) {
      setCurrentPage(page);
    }
  };

  const handleData = (position) => {
    const updatedCheckedState = checkedState?.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    let data = updatedCheckedState?.map((val, index) => {
      return val === true ? productData[index]?.id : false;
    });
    let databaseProductModal = updatedCheckedState?.map((val, index) => {
      return val === true ? productData[index] : false;
    });
    let result = data?.filter((val) => val !== false);
    setCheckedValue(result);
    let databaseProductModalResult = databaseProductModal?.filter(
      (val) => val !== false
    );
    setSelectedProductData(databaseProductModalResult);
  };

  const handleModalDetails = (data) => {
    if (data === "productAdded") {
      setModalDetail({ show: false, flag: "" });
      setKey(Math.random());
    }
  };

  const handleProductsData = async () => {
    let productData = {};
    if (filteredData?.length >= 3) {
      productData = {
        tokenData: auth?.payload?.token,
        postData: {
          type: "physical",
          search: filteredData,
          page: currentPage,
          limit: perPage,
        },
      };
    } else {
      productData = {
        tokenData: auth?.payload?.token,
        postData: {
          type: "physical",
          page: currentPage,
          limit: perPage,
        },
      };
    }
    setLoading(true);
    await dispatch(getAddedToInventoryProductsAsync(productData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);

        setProductData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getSubCategoryId = (subCategoryId) => {
    // if (!subCategoryId && !selectedCategory) return;
    setSubCategoryIds(subCategoryId);
    let productData = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "physical",
        sub_category_ids: subCategoryId ? subCategoryId.toString() : "",
        category_ids: selectedCategory ? selectedCategory.toString() : "",
        page: currentPage,
        limit: perPage,
      },
    };
    setLoading(true);
    dispatch(getAddedToInventoryProductsAsync(productData))
      .then(unwrapResult)
      .then((obj) => {
        setProductData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filteredData?.length >= 3 || filteredData?.length === 0) {
      handleProductsData();
    }
  }, [perPage, currentPage, filteredData]);

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  useEffect(() => {
    getSubCategoryId();
  }, [selectedCategory]);

  useEffect(() => {
    // Automatically select if only one address is present
    if (allAddress?.length === 1) {
      setSelectedAddress(allAddress);
      let allIds = allAddress?.map((v) => v?.id);
      setAllAddressIds(allIds);
    }
  }, [allAddress]);

  useEffect(() => {
    getAllAddress();
  }, []);

  return (
    <main>
      <div className="wrapper">
        <div className="allProductHeader">
          <button
            className="headerBackBtn d-flex"
            onClick={() => history.push("/find-product")}
          >
            <img
              src={blueBackArrow}
              alt="lightLeftArrowLogo"
              className="backbtnArrow me-1"
            />
            {t('back')}
          </button>
        </div>
        <ProductSidebar
          subCategoryId={(e) => getSubCategoryId(e)}
          brandId={setBrandIds}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />

        <div className="productRightWrapperParent">
          <div className="rightWrapper productRightWrapper mt-0 ProductsBox_">
            <div className="content_areas mt-0">
              <div className="container-fluid p-0">
                {/* <ProductNavbar /> */}
                <div className="myProductHeader">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <h5 className="fontSize14 fw500 txtDarkblue mb-0">
                        {t('allProducts')}
                        <span className="allproductCount fontSize12 ps-1">
                          (+{productData?.length})
                        </span>
                      </h5>
                    </div>
                    <div className="col-md-9">
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <div className="search_group allProduct_SearchInput">
                          <img
                            src={SearchIcon}
                            alt=""
                            className="img-fluid searchIcon"
                          />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name..."
                            value={filteredData}
                            onChange={(e) => {
                              setFilteredData(e.target.value);
                            }}
                          />
                        </div>

                        {/* <div className="productAvailabilityChild align-items-baseline">
                          <div
                            className="form-group"
                            checked={selectAll}
                            onChange={() => handleSelectAll(selectAll)}
                          >
                            <input type="checkbox" id="checkid1" />
                            <label htmlFor="checkid1" className="me-0"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            Select All
                          </h3>
                        </div> */}

                        <button
                          className={`allProductBtn ${
                            addProduct.length === 0 ? "" : "active"
                          }`}
                          onClick={() => {
                            if (addProduct.length === 0) {
                              return;
                            }
                            history.push({
                              pathname: `/all-product`,
                              state: {
                                allProducts: addProduct, // Updated list of products
                              },
                            });
                          }}
                        >
                          {t('addProducts')} <span>{`(${addProduct.length})`}</span>
                          <img src={webPosArrow} alt="webPosArrow" />
                        </button>

                        {/* <div
                          className="productFilter"
                          onClick={() => history.push("/all-product")}
                        >
                          Filters
                          <img src={filterIcon} alt="filterIcon" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {loading ? (
                    <span className="spinner-border inner-spin spinner-border-sm mt-4"></span>
                  ) : productData?.length > 0 ? (
                    productData?.map((items, index) => {
                      // Check if this product ID exists in your provided list
                      const productExists = addProduct.some(
                        (p) => p.id === items.id
                      );
                      return (
                        <div className="col-md-2 mt-3" key={index}>
                          <div className="productBoxContainer">
                            <figure className="productImageBox m-0 productFitImg">
                              <img
                                src={items.image}
                                alt="image-product"
                                className="img-fluid"
                              />
                            </figure>
                            <div className="productDetailsWrapperBox">
                              <h3 className="productNameTxt mb-0">
                                {items.name}
                              </h3>
                              {/* <h3 className="productNameTxt mb-0">
                                Sku :{" "}
                                {items?.supplies[0]?.supply_variants[0]?.sku}
                              </h3> */}

                              <div className="productInnerDetails">
                                {items?.supplies[0]?.supply_variants?.length >
                                  1 && (
                                  <h5 className="fontSize12 fw600 txtDarkblue mb-0">
                                    {
                                      items?.supplies[0]?.supply_variants
                                        ?.length
                                    }{" "}
                                    {t('variations')}
                                  </h5>
                                )}
                                {productExists ? (
                                  <img
                                    src={Images.productCheck}
                                    alt="add-image"
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={plusCircleOutline}
                                    alt="add-image"
                                    className="cursorPointer"
                                    onClick={() => {
                                      history.push({
                                        pathname: `/product-details`,
                                        state: {
                                          selectedItem: items, // The item clicked
                                          allProducts: addProduct, // Updated list of products
                                        },
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-center">
                        <h4 className="fontSize16 fw500 txtDarkblue mt-4">
                          {t('noRecordFound')}
                        </h4>
                      </div>
                    </>
                  )}
                </div>
                {Array.isArray(productData) && productData.length > 0 && (
                  <div className="allProductPagination d-flex justify-content-between align-items-center mt-3">
                    <div
                      className={`d-flex gap-2 cursorPointer ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={() => handlePageChange(currentPage - 1)}
                      style={{
                        pointerEvents: currentPage === 1 ? "none" : "auto",
                      }}
                    >
                      <img
                        src={Images.lightLeftArrowLogo}
                        alt="Prev"
                        width={16}
                        height={16}
                        style={{
                          filter:
                            currentPage !== 1
                              ? "invert(77%) sepia(73%) saturate(2071%) hue-rotate(165deg) brightness(100%) contrast(83%)"
                              : "",
                        }}
                      />
                      <span
                        className={`fontSize12 fw500 ${
                          currentPage === 1 ? "txtLightPurple" : "txtDarkblue"
                        }`}
                      >
                        {t("previous")}
                      </span>
                    </div>

                    <h3 className="fontSize12 fw500 txtLightBlue">
                      Page {currentPage} of {Math.ceil(totalRows / perPage)}
                    </h3>

                    <div
                      className={`d-flex gap-2 cursorPointer ${
                        currentPage >= Math.ceil(totalRows / perPage)
                          ? "disabled"
                          : ""
                      }`}
                      onClick={() => handlePageChange(currentPage + 1)}
                      style={{
                        pointerEvents:
                          currentPage >= Math.ceil(totalRows / perPage)
                            ? "none"
                            : "auto",
                      }}
                    >
                      <span
                        className={`fontSize12 fw500 ${
                          currentPage >= Math.ceil(totalRows / perPage)
                            ? "txtLightPurple"
                            : "txtDarkblue"
                        }`}
                      >
                        {t('next')}
                      </span>
                      <img
                        src={Images.webPosArrow}
                        alt="Next"
                        width={16}
                        height={16}
                        style={{
                          filter:
                            currentPage >= Math.ceil(totalRows / perPage)
                              ? "brightness(0) saturate(100%) invert(75%) sepia(41%) saturate(351%) hue-rotate(196deg) brightness(95%) contrast(94%)"
                              : "",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer {...props}/> */}
        <CustomModal
          key={key}
          show={modalDetail.show}
          // backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={
            modalDetail.flag === "MyProductDetials"
              ? "myProductDetails"
              : modalDetail.flag === "successfullyAdded"
              ? "successful"
              : modalDetail.flag === "DBImportProductModal"
              ? "DBImportProductModal"
              : ""
          }
          size={
            modalDetail.flag === "MyProductDetials"
              ? "xl"
              : modalDetail.flag === "DBImportProductModal"
              ? "xl"
              : "md"
          }
          child={
            modalDetail.flag === "MyProductDetials" ? (
              <MyProductDetials
                close={() => handleOnCloseModal()}
                type="product"
                selectedProduct={selectedProduct}
              />
            ) : modalDetail.flag === "successfullyAdded" ? (
              <SuccessfulModal
                flag={"productsAdded"}
                details={(e) => handleModalDetails(e)}
              />
            ) : modalDetail.flag === "UploadproductModal" ? (
              <UploadproductModal close={() => handleOnCloseModal()} />
            ) : modalDetail.flag === "DBImportProductModal" ? (
              <DBImportProductModal
                close={() => handleOnCloseModal()}
                selectedProductData={selectedProductData}
                allAddressIds={allAddressIds}
                loading={loading}
                allAddress={allAddress}
              />
            ) : (
              ""
            )
          }
          header={
            <>
              <div className="modalHeader_">
                <div className="common_ justify-content-end">
                  {modalDetail.flag === "DBImportProductModal" ? (
                    <div className="modalHeader_">
                      <div className="common_">
                        <h3 className="modalHeading_">{t('addProduct')}</h3>

                        <span
                          className="crossIcon"
                          onClick={() => handleOnCloseModal()}
                        >
                          <i className="las la-times" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          }
        />
        {/* <Footer {...props}/> */}
      </div>
    </main>
  );
};

export default Product;
