import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getProductListApi,
  getProductServiceListApi,
  getProductListByIdApi,
  getProductSalesGraphApi,
  getTopSellingApi,
  getCountApi,
  getProductStatisticData,
  getSingleProductStatisticDataById,
  getServiceCountDataApi,
  getServiceSalesBarGraphApi,
  changeServiceAvailabilityStatusApi,
  getProductTrendingGraphApi,
  getLowStockInventoryApi,
  updateInventoryApi,
  adjustInventoryApi,
  createCoupon,
  getLeastSellingApi,
  getOfferProductApi,
  getRecentProductApi,
  getTopStaffApi,
  getSingleProductByIdApi,
  createCategoryApi,
  updateCategoryApi,
  getDocumentApi,
  updateDocStatusApi,
} from "./productApi";

const initialState = {
  status: "idle",
  productData: null,
};

export const getProductListAsync = createAsyncThunk(
  "product/getProductListApi",
  async (data) => {
    const response = await getProductListApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getProductServiceListApiAsync = createAsyncThunk(
  "product/getProductServiceLisApi",
  async (data) => {
    const response = await getProductServiceListApi(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const getProductListByIdAsync = createAsyncThunk(
  "product/getProductListByIdApi",
  async (data) => {
    const response = await getProductListByIdApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getSingleProductDetailsByIdAsync = createAsyncThunk(
  "product/getSingleProductByIdApi",
  async (data) => {
    const response = await getSingleProductByIdApi(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const getProductSalesGraphApiAsync = createAsyncThunk(
  "product/getProductSalesGraphApi",
  async (data) => {
    const response = await getProductSalesGraphApi(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const getTopSellingApiAsync = createAsyncThunk(
  "product/getTopSellingApi",
  async (data) => {
    const response = await getTopSellingApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getLeastSellingApiAsync = createAsyncThunk(
  "product/getLeastSellingApi",
  async (data) => {
    const response = await getLeastSellingApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getTopStaffApiAsync = createAsyncThunk(
  "product/getTopStaffApi",
  async (data) => {
    const response = await getTopStaffApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getOfferProductApiAsync = createAsyncThunk(
  "product/getOfferProductApi",
  async (data) => {
    const response = await getOfferProductApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getRecentProductApiAsync = createAsyncThunk(
  "product/getRecentProductApi",
  async (data) => {
    const response = await getRecentProductApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getCountApiAsync = createAsyncThunk(
  "product/getCountApi",
  async (data) => {
    const response = await getCountApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getMyProductStatisticDataAsync = createAsyncThunk(
  "product/getProductStatisticData",
  async (data) => {
    const response = await getProductStatisticData(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const getSingleProductStatisticDataByIdAsync = createAsyncThunk(
  "product/getSingleProductStatisticDataById",
  async (data) => {
    const response = await getSingleProductStatisticDataById(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const getServiceCountDataApiAsync = createAsyncThunk(
  "product/getServiceCountDataApi",
  async (data) => {
    const response = await getServiceCountDataApi(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const getServiceSalesBarGraphApiAsync = createAsyncThunk(
  "product/getServiceSalesBarGraphApi",
  async (data) => {
    const response = await getServiceSalesBarGraphApi(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const changeServiceAvailabilityStatusApiAsync = createAsyncThunk(
  "product/changeServiceAvailabilityStatusApi",
  async (data) => {
    const response = await changeServiceAvailabilityStatusApi(
      data.postData,
      data.tokenData,
      data.statusData
    );
    return response.data;
  }
);

export const getProductTrendingGraphAsync = createAsyncThunk(
  "product/getProductTrendingGraphApi",
  async (data) => {
    const response = await getProductTrendingGraphApi(
      data.postData,
      data.tokenData,
      data.statusData
    );
    return response.data;
  }
);

export const getLowStockInventoryAsync = createAsyncThunk(
  "product/getLowStockInventoryApi",
  async (data) => {
    const response = await getLowStockInventoryApi(
      data.postData,
      data.tokenData,
      data.statusData
    );
    return response.data;
  }
);

export const updateInventoryApiAsync = createAsyncThunk(
  "product/updateInventoryApi",
  async (data) => {
    const response = await updateInventoryApi(
      data.postData,
      data.bodyData,
      data.tokenData
    );
    return response.data;
  }
);
export const adjustInventoryApiAsync = createAsyncThunk(
  "product/adjustInventoryApi",
  async (data) => {
    const response = await adjustInventoryApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const createCategoryAsync = createAsyncThunk(
  "product/createCategoryApi",
  async (data) => {
    const response = await createCategoryApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const updateCategoryAsync = createAsyncThunk(
  "product/updateCategoryApi",
  async (data) => {
    const response = await updateCategoryApi(data.tokenData, data.postData);
    return response.data;
  }
);

// new
export const createCouponAsync = createAsyncThunk(
  "product/createCoupon",
  async (data) => {
    const response = await createCoupon(data.postData, data.tokenData);
    return response.data;
  }
);

export const getDocumentApiAsync = createAsyncThunk(
  "product/getDocumentApi",
  async (data) => {
    const response = await getDocumentApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const updateDocStatusApiAsync = createAsyncThunk(
  "product/updateDocStatusApi",
  async (data) => {
    const response = await updateDocStatusApi(data.postData, data.tokenData);
    return response.data;
  }
);


export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productEmpty: (state) => {
      state.productData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductServiceListApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getProductServiceListApiAsync.fulfilled,
        (state, { payload }) => {
          state.productData = payload;
          state.status = "idle";
        }
      )
      .addCase(getProductServiceListApiAsync.rejected, (state, { error }) => {
        state.status = "idle";
        state.productData = null;
      });
  },
});


export const { productEmpty } = productSlice.actions;
export const selectProductData = (state) => state?.products?.productData;

export default productSlice.reducer;
