import React from "react";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";

const DeleteProductModal = (props) => {
  const { t } = useTranslation();
  const handleProductDeleteModal = () => {
    props?.handleDeleteProduct();
  };
  return (
    <div className="text-center">
      <img src={Images.crossCircle} alt="cancleLogo" />
      <div className="stopOfferModalContainer">
        <h3 className="stopOfferTxt mb-0">Are you sure you want to delete?</h3>
      </div>
      <ul className="deleteProductModalList">
        <li>This will remove the {props?.service ? "service" : "product"} from all listings.</li>
        <li>Inventory records will be retained.</li>
        <li>This action cannot be undone.</li>
      </ul>

      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          disabled={props?.deleteLoading ||props?.isStatusChage}
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          {t('cancel')}
        </button>
        <button
          className="modalNextBtn modalDiscardBtn modalStopBtn w-100"
          onClick={() => handleProductDeleteModal()}
          disabled={props?.deleteLoading ||props?.isStatusChage}
        >
          {(props?.deleteLoading ||props?.isStatusChage) && (
            <span className="spinner-border spinner-border-sm spinnerRed"></span>
          )}
          <img src={Images.newDeleteIcon} alt="stopIcon" className="me-2" />
          {t('delete')}
        </button>
      </div>
    </div>
  );
};

export default DeleteProductModal;
