import React, { useState } from 'react'
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import CustomModal from '../../components/shared/CustomModal';
import ThankyouModal from './thankyouModal';

const SupportcontactModal = () => {
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };
  return (
    <>
      <div className='supportcontactModal_'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='supportcontactLeft_'>
              <h3 className='Heading_48'>Contact JOBR <br /> Support Team</h3>
              <p className='paragrph24_'>Get help from our support team</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='supportcontactRight_'>
              <div className="row">
                <div className="col-md-12">
                  <div className="form_group">
                    <div className="reactSelectParent commonSelectParent w-100">
                      <label className="fontSize16 txtDarkblue mb-2 ps-4">
                        Select Issue
                      </label>
                      <ReactSelect
                        placeholder="Withdrawal Issue "
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            maxWidth: "100% !important",
                            width: "100%",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            paddingLeft: "unset !important",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682"
                              : state.isFocused
                                ? "#f5f6fc"
                                : "#fff",

                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <img
                              src={Images.selectImg}
                              width={24}
                              height={24}
                              alt="drop_icon"
                              className="mt-1"
                            />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 ">
                  <div className="form_group">
                    <label className="fontSize16 txtDarkblue mb-2">
                      Tell us a little more about your issue.
                    </label>
                    <div className="inputGroup textContent">
                      <textarea className='formtextarea_' placeholder="Cash cut-off before rush hour checked by Eugenia. It’s important to make inventories ."></textarea>
                      <img className='walletmsgicon_' src={Images.walletmessageIcon} />
                    </div>
                    <p class="messageTxt ps-4 pt-0">At least 20 characters</p>
                    <div className='footemodalbtn_ mt-4'>
                      <button className='discardBtn_' type='submit'>Discard</button>
                      <button className='saveBtn_' type='submit' onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "thankyouModal",
                        });
                        setKey(Math.random());
                      }}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "thankyouModal"
            ? "refundsucessfulModal"

            : ""
        }
        child={
          modalDetail.flag === "thankyouModal" ? (
            <ThankyouModal close={() => handleOnCloseModal()} />
          ) :
            (
              <></>
            )
        }

        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default SupportcontactModal