import React from "react";
import moment from "moment-timezone";

const GiftDetails = (props) => {
  return (
    <>
      <div className="giftModalDetails">
        <div className="flexBox gap-3">
          <img className="employeeImg" src={props?.selectedGift?.image} />
          <div className="userProfileinforight_">
            <h3 className="fontSize24 fw500 txtDarkblue mb-0">
              {props?.selectedGift?.title}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="contentappont">
              <h3 className="fontSize20 fw500 txtDarkblue mb-1">
                Gift Card Price
              </h3>
              <p className="fontSize16 txtLightBlue">
                ${props?.selectedGift?.gift_amount?.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="contentappont">
              <h3 className="fontSize20 fw500 txtDarkblue mb-1">
                Gift Card Category
              </h3>
              <p className="fontSize16 txtLightBlue">
                {props?.selectedGift?.gift_category?.title}
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="contentappont">
              <h3 className="fontSize20 fw500 txtDarkblue mb-1">
                Offer Validity
              </h3>
              <p className="fontSize16 txtLightBlue">
                {moment
                  ?.utc(props?.selectedGift?.end_date)
                  .format("MMM DD, YYYY")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftDetails;
