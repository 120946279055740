import React, { useState } from "react";
import SelectMenu from "../../../components/UI/Orders/SelectMenu";
import * as Images from "../../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DeviceConnected = (props) => {
  const [loading, setLoading] = useState(false);
  const{setmoreSetting}=props
      const history = useHistory();
  const [deviceList, setDeviceList] = useState([1]);
  const [allRoles, setAllRoles] = useState([
    {
      value: "",
      label: "All Roles",
    },
  ]);
  const [staffStatus, setstaffStatus] = useState([
    {
      value: "",
      label: "All Status",
    },
  ]);
  const [staffWorkType, setstaffWorkType] = useState([
    {
      value: "",
      label: "All Work Type",
    },
  ]);

  const [filterOptions, setfilterOptions] = useState([
    {
      title: "Status: All",
      data: staffStatus,
    },
    {
      title: "Locations: All",
      data: allRoles,
    },

    {
      title: "Device: All",
      data: staffWorkType,
    },
  ]);
  const handleFilterOptions = (key, value) => {};
  const images=['webposdisplay.svg', 'tabletIcon.svg', 'desktopIcon.svg', 'monitorIcon.svg', 'laptopicon.svg'];
  

  return (
    <>
      <div className="securityMain_ deviceMain_">
        <h3 className="appointMain ">Devices</h3>
        {/* show options */}
        <div className="row align-items-center mt-5">
          <div className="col-xl-6">
            <div className="flexBox gap-3">
              {filterOptions.map((filter, index) => (
                <div className="reactSelectParent offerHeaderSelect gapDiffer_">
                  <SelectMenu
                    options={filter.data}
                    placeholder={filter.title}
                    onChange={(event) => {
                      handleFilterOptions(event.value, filter.title);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* add table here */}
        <div className="commonTable table-responsive mt-3">
          <table className="w-100 allorderTable_">
            <thead>
              <tr>
                <th>#</th>
                <th>Device</th>
                <th>Device Id</th>
                <th>Last Active</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {/* table rows */}
              {loading ? (
                <tr className="text-center border-none">
                  <td colSpan="100%">
                    <div className="d-flex">
                      <span className="spinner-border inner-spin spinner-border-sm"></span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {deviceList?.map((device, index) => (
                    <tr onClick={() => {setmoreSetting("deviceDetail")}}>
                      <td className="orderTrackTableTxt">1</td>
                      <td>
                        <div className="deviceNmae_">
                          <img
                            className="iphonemob_ me-2"
                            src={Images.iPhonemob}
                          />
                          <h5 className="decivenameText_ me-2">
                            Mobile POS iPhone 14
                          </h5>
                          <div className="battryBox_">
                            <img
                              className="batteryfull_"
                              src={Images.batteryfull}
                            />{" "}
                            <span className="greenText_">95%</span>
                          </div>
                        </div>
                      </td>
                      <td>1F03C3A7-0971-4386-AF59-E2696219CB7C</td>
                      <td>
                        <p className="mb-1">11/23/2023 11:00 am</p>
                        <h6 className="orderTrackLocation fontSize12 fw500 mb-0">
                          <img
                            src={Images.locateOrder}
                            alt="img"
                            className="location"
                          />
                          Florida 330883
                        </h6>
                      </td>
                      <td ><span className="activedevice">Active</span></td>
                    </tr>
                    
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* add table here */}
      </div>
    </>
  );
};

export default DeviceConnected;
