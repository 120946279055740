import React, { useState } from "react";
import * as Images from "../../../../utilities/images";
import ReactSelect from "react-select";

const Compensation = () => {
  const selectOption = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];
  return (
    <form className="addStaffForm">
      <h3 className="fontSize24 fw500 txtDarkblue addStaffHeading mb-2">
      Compensation
      </h3>
      <div className="row">
        <div className="col-md-6">
          <div className="form_group">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize14 fw500 txtDarkblue mb-2">
              Employment Status
              </label>
              <ReactSelect
                options={selectOption}
                isSearchable={true}
                placeholder="Full Time (30+ hours per week)"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize14 fw500 txtDarkblue mb-2">
                Employee type
              </label>
              <ReactSelect
                options={selectOption}
                isSearchable={true}
                placeholder="Salary"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize14 fw500 txtDarkblue mb-2">
                Pay frequency
              </label>
              <ReactSelect
                options={selectOption}
                isSearchable={true}
                placeholder="Weekly"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize16 txtDarkblue mb-2  ">Wage</label>
            <div className="inputGroup">
              <input
                min="1"
                className="customInput costPriceInput"
                placeholder="0.00"
                name="text"
                type="number"
              />
              <span className="dollrsign1_">$</span>
              <span className="dollrsign1_ usdTxt txtDarkblue">USD</span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize16 txtDarkblue mb-2  ">
              Default hours
            </label>
            <div className="inputGroup">
              <input
                min="1"
                className="customInput costPriceInput"
                placeholder="40"
                name="text"
                type="number"
              />
              <span className="dollrsign1_">$</span>
              <span className="dollrsign1_ usdTxt txtDarkblue">Hours</span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize16 txtDarkblue mb-2  ">Leave</label>
            <div className="inputGroup">
              <input
                min="1"
                className="customInput costPriceInput"
                placeholder="10"
                name="text"
                type="number"
              />
              <span className="dollrsign1_">$</span>
              <span className="dollrsign1_ usdTxt txtDarkblue">Days</span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize14 fw500 txtDarkblue mb-2">
                Overtime
              </label>
              <ReactSelect
                options={selectOption}
                isSearchable={true}
                placeholder="Not Allowed"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group">
            <label className="fontSize16 txtDarkblue mb-2  ">
              Overtime wage
            </label>
            <div className="inputGroup">
              <input
                min="1"
                className="customInput costPriceInput"
                placeholder="0.00"
                name="text"
                type="number"
              />
              <span className="dollrsign1_">$</span>
              <span className="dollrsign1_ usdTxt txtDarkblue">USD</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Compensation;
