import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useTranslation } from "react-i18next";
import { selectedPaymentCards, selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllSaveCardAsync, getMerchantSettingsAsync } from "../../dashboard/dashboardSlice";

const MakePayment = ({
  formData,setFormData
}) => {
  const { t } = useTranslation();
  const [paymentCardDetails, setpaymentCardDetails] = useState(null);
  const paymentCards=useSelector(selectedPaymentCards);
  const auth = useSelector(selectLoginAuth);
  const [showDetails, setShowDetails] = useState({
    subTotal:0,
    tax:0,
    platformFee:0,
    total:0,
  })

  const dispatch = useDispatch();
  const getPaymentCards=async()=>{
    try {
        let res=await dispatch(getAllSaveCardAsync(auth?.payload?.token)).then(unwrapResult);
        if(res){
            let cardsList=res?.payload;
            if(Array.isArray(cardsList)){
                if(cardsList.length){
                    setpaymentCardDetails(cardsList[0]);
                }
            }
        }
    } catch (error) {
        
    }
}
const getMerchantSettings = async () => {
  try {
    const data = await dispatch(getMerchantSettingsAsync(auth?.payload?.token)).then(unwrapResult);
    if (data) {
      const campaignCharges = data?.payload?.campaign_charges;
      const taxPercentage = campaignCharges?.tax_percentage || 0;
      const platformPercentage = campaignCharges?.platform_fee_percentage || 0;
      let subTotal = 0;
      switch (formData.campaign_for) {
        case "notification":
          subTotal = (campaignCharges?.notification?.cents_per_notification || 0) * (formData?.notifications_count || 0)/100;
          break;

        case "sms":
          subTotal = (campaignCharges?.sms?.cents_per_sms || 0) * (formData?.sms_count || 0)/100;
          break;
        default:
          subTotal = (1) * (campaignCharges?.store?.dollar_per_day || 0);
          break;
      }

      // Calculate tax and platform fee
      const tax = (subTotal * taxPercentage) / 100;
      const platformFee = (subTotal * platformPercentage) / 100;
      const total = subTotal + tax + platformFee;
      setShowDetails({ subTotal, tax, platformFee, total });
    }
  } catch (error) {
    console.error("Error fetching merchant settings:", error);
  }
};
useEffect(()=>{
    
    if(paymentCardDetails==null){
    let cardList=paymentCards?.payload;
        if(Array.isArray(cardList)){
            if(cardList.length) setpaymentCardDetails(cardList[0]);
        }else getPaymentCards();
    }
},[paymentCards]);
useEffect(()=>{
  getMerchantSettings();
},[])
useEffect(()=>{
  if(paymentCardDetails){
    console.log("paymentCardDetail",paymentCardDetails?.id)
   if(paymentCardDetails?.id) handlePyamentDetails(paymentCardDetails?.id)
  }
},[paymentCardDetails]);
const handlePyamentDetails=(id)=>{
  console.log(id,"check it")
  setFormData(prev=>({
    ...prev,payment_method_id:id
  }))
}
  return (
    <>
      <div className="campaignStep_">
        <div className="campaignMain_">
          <div className="campaintop_ mb-3">
            <h3 className="mb-1 normalHeading_">{t('paymentOption')}</h3>
            <p className="textInnerHead2">
              {t('uploadImagesContent')}.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="planMethod">
                <h4 className="m-0 stateList_head">{t("paymentMethod")}</h4>
                <div className="mt-2 scannerBx">
                  <img
                    src={Images.mastercardicon}
                    alt="calendarBlue image"
                    className="img-fluid"
                  />
                  <h4 className="m-0 orderPara">
                    &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;
                    &#8226;&#8226;&#8226;&#8226;{" "}
                    <span className="redgg">{paymentCardDetails?.card?.last4}</span>{" "}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="campaignMain_ mb-3 mt-4">
          <div className="campaintop_">
            <h3 className="mb-1 normalHeading_">{t('paymentDetails')}</h3>
            <p className="textInnerHead2">
              {t('uploadImagesContent')}.
            </p>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="flexBox maplePriceBox">
                <article>
                  <p className="productName">{t("subTotal")}</p>
                  <p className="productName">{t("Tax")}</p>
                  <p className="productName">{t("PlatformFee")}</p>
                </article>
                <article>
                  <p className="productName">${Number(showDetails.subTotal)?.toFixed(2)}</p>
                  <p className="productName">${(Number(showDetails.tax) || 0)?.toFixed(2)}</p>
                  <p className="productName">${(Number(showDetails.platformFee) || 0)?.toFixed(2)}</p>
                </article>
                </div>

                <div className="d-flex align-items-center justify-content-between pymntOptiontotal">
                    <p className="productName">{t("total")}</p>
                    <p className="totalBtn">${Number(showDetails.total)?.toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default MakePayment;
