import React, { useState } from 'react'
import * as Images from "../../../../utilities/images";
import { selectWalletData } from '../wallet.slice';
import { useSelector } from 'react-redux';
import Loader from '../../../components/UI/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const PaymentMethod = (props) => {
    const { t } = useTranslation();
    const walletData = useSelector(selectWalletData);
    const paymentData = walletData?.walletSetting;
    const [loading, setLoading] = useState(false)

    const handelPaymentChange = (e) => {

        const { name, checked } = e.target;
        if(!checked&& isAllPaymentMethodDisabled(name)){
            toast.error("One payment method should be enabled")
            return;
        }
        setLoading(true)
        props?.updateSetting({ [name]: checked });
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }
    const isAllPaymentMethodDisabled=(name)=>{
        let obj={...paymentData,[name]:false};
        return !(obj["accept_jbr_coin_payment"]||obj["accept_tap_and_pay"]||obj["accept_card_payment"]||obj["accept_cash_payment"])
    }

    return (
        <div className='paymentMethodmain'>
            <div className="ordrsettingHead_">
                <h3 className="normalHeading_ mb-1"> {t('payMethod')} </h3>
                <p className="textInnerHead2">{t('paymentTitle')}</p>
            </div>
            {loading && <Loader />}
            <div className="paymentMethodInner mt-5">
                <div class="bussinessSub mb-3">
                    <div class="locationHead">
                        <img className='ordersetting' src={Images.paymethod_} alt='jbr coin' />
                        <div class="bussinessHeading">
                            <h4 class="customerLink text-start">{t('payWithCoin')}</h4>
                            <h5 class="monthText mt-2">Online Shop / POS</h5>
                            <p class="settingText mt-0">{t("payWithCashSubhead")}</p>
                        </div>
                    </div>
                    <div class="roundCheck  mb-0">
                        <input
                            checked={paymentData?.accept_jbr_coin_payment}
                            onChange={handelPaymentChange}
                            class="form-check-input"
                            type="checkbox"
                            name='accept_jbr_coin_payment'
                            id="flexSwitchCheckChecked"
                            disabled="" />
                        <label class="form-check-label d-none" for="flexSwitchCheckChecked"></label>
                    </div>
                </div>
                <div class="bussinessSub mb-3">
                    <div class="locationHead">
                        <img className='ordersetting' src={Images.paymethod_} alt=' pay with apple pay' />
                        <div class="bussinessHeading">
                            <h4 class="customerLink text-start">{t('payWithApplePay')}</h4>
                            <h5 class="monthText mt-2"> POS</h5>
                            <p class="settingText mt-0">{t("payWithCashSubhead")}</p>
                        </div>
                    </div>
                    <div class="roundCheck  mb-0">
                        <input
                            checked={paymentData?.accept_tap_and_pay}
                            onChange={handelPaymentChange}
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            name='accept_tap_and_pay'
                            disabled="" />
                        <label class="form-check-label d-none" for="flexSwitchCheckChecked"></label>
                    </div>
                </div>
                <div class="bussinessSub mb-3">
                    <div class="locationHead">
                        <img className='ordersetting' src={Images.contactlessIcon} alt='card' />
                        <div class="bussinessHeading">
                            <h4 class="customerLink text-start">{t('payByCard')}</h4>
                            <h5 class="monthText mt-2"> Online Shop / POS</h5>
                            <p class="settingText mt-0">{t('payCardTitle')} </p>
                        </div>
                    </div>
                    <div class="roundCheck  mb-0">
                        <input
                            checked={paymentData?.accept_card_payment}
                            onChange={handelPaymentChange}
                            class="form-check-input"
                            type="checkbox"
                            name='accept_card_payment'
                            id="flexSwitchCheckChecked"
                            disabled="" />
                        <label class="form-check-label d-none" for="flexSwitchCheckChecked"></label>
                    </div>
                </div>
                <div class="bussinessSub">
                    <div class="locationHead">
                        <img className='ordersetting' src={Images.cashwalletIcon} alt='pay with cash' />
                        <div class="bussinessHeading">
                            <h4 class="customerLink text-start">{t('paywithcash')}</h4>
                            <h5 class="monthText mt-2"> POS</h5>
                            <p class="settingText mt-0">{t('paywithCashTitle')} </p>
                        </div>
                    </div>
                    <div class="roundCheck  mb-0">
                        <input
                            checked={paymentData?.accept_cash_payment}
                            onChange={handelPaymentChange}
                            class="form-check-input"
                            type="checkbox"
                            name='accept_cash_payment'
                            id="flexSwitchCheckChecked"
                            disabled="" />
                        <label class="form-check-label d-none" for="flexSwitchCheckChecked"></label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethod