import React from 'react'
import * as Images from "../../../utilities/images";

const ThankyouModal = () => {
    return (
        <div className='refund'>
            <img className="mb-2" src={Images.success} alt="img" />
            <h4>Thank you! <br /> Submitted</h4>
        </div>
    )
}

export default ThankyouModal