import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({onConfirm}) => {
  const { t } = useTranslation();
  return (
    <div className="confirmModalSec ">
      <p className="fontSize24 txtDarkblue text-center">
        {t('negativeMargingCaption')}
      </p>
      <button className="modalNextBtn modalDiscardBtn active w-100 ms-0 mt-4" onClick={onConfirm}>
       {t('confirm')}
      </button>
    </div>
  );
};

export default ConfirmationModal;
