import React from 'react'
import * as Images from "../../../../utilities/images";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from 'react-i18next';
const Notificationindex = (props) => {
    const { t } = useTranslation();
    const{setmoreSetting}=props
    const history = useHistory();
    return (
        <div className='settingNotification_ '>
            <h3 className='appointMain'>{t("notifications")}</h3>
            <div className='settingNotificationinner mt-5'>
                <div className='leftnotificationContent_'>
                <img className='ordersetting' src={Images.userclientonline} alt='additional' /> 
                <article>
                    <h4 className='headblue18'>{t("customerNotifications")}</h4>
                    <h5 className='notifiText_'>{t("customerNotifyTitle")}</h5>
                </article>

                </div>
                <div  className='rightnotificationContent_' onClick={() => {setmoreSetting("customerNotify")}}>
                    <img className='ordersetting' src={Images.upArrow} alt='additional' /> 
                </div>
            </div>
            <div className='settingNotificationinner mt-3'>
                <div className='leftnotificationContent_'>
                <img className='ordersetting' src={Images.usersoutline} alt='additional' /> 
                <article>
                    <h4 className='headblue18'>{t("staffNotifications")}</h4>
                    <h5 className='notifiText_'>{t("staffNotificationTitle")}</h5>
                </article>

                </div>
                <div className='rightnotificationContent_' onClick={() => {setmoreSetting("staffNotify")}}>
                    <img className='ordersetting' src={Images.upArrow} alt='additional' /> 
                </div>
            </div>
            <div className='settingNotificationinner mt-3'>
                <div className='leftnotificationContent_'>
                <img className='ordersetting' src={Images.shippingoutline} alt='additional' /> 
                <article>
                    <h4 className='headblue18'>{t("newProductNotifications")} </h4>
                    <h5 className='notifiText_'>{t("newProductNotificationTitle")}</h5>
                </article>

                </div>
                <div className='rightnotificationContent_' onClick={() => {setmoreSetting("featureNotify")}}>
                    <img className='ordersetting' src={Images.upArrow} alt='additional' /> 
                </div>
            </div>
            <div className='settingNotificationinner mt-3'>
                <div className='leftnotificationContent_'>
                <img className='ordersetting' src={Images.receiptoutline} alt='additional' /> 
                <article>
                    <h4 className='headblue18'>{t("salesNotification")} </h4>
                    <h5 className='notifiText_'>{t("salesNotificationTitle")}</h5>
                </article>

                </div>
                <div  className='rightnotificationContent_' onClick={() => {setmoreSetting("salesNotify")}}>
                    <img className='ordersetting' src={Images.upArrow} alt='additional' /> 
                </div>
            </div>
        </div>
    )
}

export default Notificationindex