import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LoaderModal = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* Start Loader */}
      <div className="loader-container">
        <div className="spinner">
          <svg className="circle" viewBox="0 0 50 50">
            <circle
              className="track"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="4"
            ></circle>
            <circle
              className="progress"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="4"
            ></circle>
          </svg>

          {/* <div className="icon">
            <img src={Images.loaderInnerImg} alt="Driver Icon" />
          </div> */}
        </div>
        <p className="loader-text">{t('connecting')} <br/> {t('toshop')}.</p>
      </div>
    </>
  );
};

export default LoaderModal;
