import React from "react";
import * as Images from "../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const RecentProduct = (props) => {
  const { t } = useTranslation();
  const { recentProduct, recentLoading, setRecentLimit } = props;
  const history = useHistory();

  return (
    <div className="col-md-6">
      <div className="sellingProductContainer p-0 recentlyProductContainer">
        <div className="d-flex align-items-center justify-content-between recentlyProduct_Head">
          <h2 className="fontSize20 txtBlue fw500 mb-0">
            {t("recentProducts")}
          </h2>
          {Array.isArray(recentProduct) && recentProduct?.length > 0 && (
            <button
              className="viewAllBtn"
              onClick={() => {
                history.push({
                  pathname: "/products",
                  // state: { category: "recent" },
                });
              }}
            >
              {t("viewAll")}
            </button>
          )}
        </div>

        <div className="commonTable sellingProductTable table-responsive me-3 ms-3">
          <table className="w-100">
            <tbody>
              {recentLoading ? (
                <tr>
                  <td colSpan={2}>
                    <div className="d-flex align-item-center justify-content-center">
                      <span className="spinner-border spinner-border-md spinnerDark "></span>
                    </div>
                  </td>
                </tr>
              ) : recentProduct?.length > 0 ? (
                recentProduct
                  .filter((value) => value?.type === "physical")
                  .map((value, idx) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={value?.image}
                            alt="img"
                            className="productItemImg"
                          />
                          <div>
                            <h3 className="topSellProductHead mb-0 pb-1 text-start">
                              {value?.name}
                            </h3>
                            {/* <div className="d-flex align-items-center">
                              {(() => {
                                const variantAttribute =
                                  value?.supplies?.[0]?.supply_variants?.[0]
                                    ?.attributes;

                                // Ensure attributes is a valid object before parsing
                                const attributes =
                                  typeof variantAttribute === "string"
                                    ? JSON.parse(variantAttribute) // Parse only if it's a valid JSON string
                                    : variantAttribute || {}; // Use empty object if undefined

                                const color =
                                  attributes?.color || attributes?.Color; // Handle both lowercase & uppercase

                                return (
                                  <>
                                    {color && (
                                      <span
                                        className="dot"
                                        style={{ backgroundColor: color }}
                                      ></span>
                                    )}
                                    <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                      {Object.values(attributes).join(" / ")}
                                    </h6>
                                  </>
                                );
                              })()}
                            </div> */}
                          </div>
                        </div>
                      </td>
                      <td className="text-end">
                        <h5 className="fontSize12 fw400 mb-0 txtBlack">
                          ${value?.price}
                        </h5>
                      </td>
                      <td>
                        <img
                          src={Images.plusCircleIcon}
                          alt="plusCircleIcon"
                          className="cursorPoint"
                          // onClick={() =>
                          //   history.push("/add-product/myProducts")
                          // }
                          onClick={() => {
                            history.push({
                              pathname: `/product-details`,
                              state: {
                                selectedItem: value, // The item clicked
                              },
                            });
                          }}
                        />
                      </td>
                    </tr>
                  ))
              ) : (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex align-iten-center justify-content-center">
                        <h4 className="fontSize14 fw400 txtLightBlue mb-0 ps-1">
                          {t("noRecordfound")}
                        </h4>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecentProduct;
