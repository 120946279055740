export const PayrollHeaderStatisticsArr = [
  {
    heading: "Total Staffs",
    key: "total_staff",
    img: "totalStaffLogo",
    className: "totalProductBox",
  },
  {
    heading: "Total Paid",
    key: "total_paid",
    img: "totalPaidLogo",
    className: "totalBrandBox ",
  },
  {
    heading: "Total hours",
    key: "total_hour",
    img: "totalHourLogo",
    className: "totalSubCategoryBox",
  },
  {
    heading: "Due Payments",
    key: "due_payment",
    img: "duePaymentLogo",
    className: "transectionsBox",
  },
];
export const MyPosStaticsArray = [
  {
    heading: "Total Sales",
    headingText:"totalSales",
    key: "total_staff",
    img: "totalStaffLogo",
    className: "totalProductBox",
  },
  {
    heading: "Order Delivered",
    headingText:"orderDelivered",
    key: "total_paid",
    img: "totalPaidLogo",
    className: "totalBrandBox ",
  },
  {
    heading: "Order Picked Up",
    headingText:"orderPickUp",
    key: "total_hour",
    img: "totalHourLogo",
    className: "totalSubCategoryBox",
  },
  {
    heading: "Delivery Returned",
    key: "due_payment",
    headingText:"deliveredReturn",
    img: "duePaymentLogo",
    className: "transectionsBox",
  },
];
export const options1 = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      align: "start",
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        pointStyle: "circle",
        usePointStyle: true,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
