export const staffWorkType=[
    { value: "", label: "All Status" },
    { value: "full_time", label: "Full Time" },
    { value: "part_time", label: "Part Time" },
    
  
]
export const staffRoles=[
    { value: "", label: "All Status" },
   
]
export const staffStatus=[
    { value: "", label: "All Status" },
    { value: "1", label: "Active" },
    { value: "0", label: "Not Active" },
   
]
export const   StaffColumns = [
    {
        isVisible: true,
        title: "Name"
    },
    {
        isVisible: true,
        title: "WrokType"
    },
    {
        isVisible: true,
        title: "Role"
    },
    {
        isVisible: true,
        title: "Payroll"
    },
    {
        isVisible: true,
        title: "Pay Frequency"
    },
    {
        isVisible: true,
        title: "Leave Taken"
    },
    {
        isVisible: true,
        title: "Salary"
    },
    {
        isVisible: true,
        title: "Tips"
    },
    
    {
        isVisible: false,
        title: "OverTime"
    },
    {
        isVisible: false,
        title: "Total Payable"
    },
    {
        isVisible: false,
        title: "Status"
    },
    
]