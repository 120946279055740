import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Loader from "../../../components/UI/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth, selectedUserAddressDetails } from "../../auth/authSlice";
import * as Images from "../../../../utilities/images";
import WorkOptions from "./WorkOptions";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/shared/CustomModal";
import DeleteProductModal from "../../myProducts/DeleteProductModal";
import DeleteModal from "../../../components/shared/modals/DeleteModal";
import { deleteSellerAddressApiAsync } from "../../dashboard/dashboardSlice";

const BusinessLocations = ({ setmoreSetting,getSellerAddressDetails,setCount }) => {
  const { t } = useTranslation();
   const [key, setKey] = useState(Math.random());
  const [storeList, setStoreList] = useState([]);
 const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };
  // End Modal
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const addresses=useSelector(selectedUserAddressDetails);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectStoreId, setSelectStoreId] = useState(null);
  const getSellerAddress= async () => {
    setLoading(true);
    try {
     let res=await getSellerAddressDetails();
     if (res?.payload) {
      setStoreList(res?.payload);
      setCount(res?.payload?.length||1);
    }
    } catch (error) {
    }
    finally{
      setLoading(false);
    }
   
  };
  useEffect(() => {
    getSellerAddress();
    // if(!addresses){
    // }else{
    //   console.log(addresses)
    //   setStoreList(addresses?.payload||[]);
    // }
  }, []);
  const handleDeleteBusinessLocation=()=>{
    if(!selectStoreId){
      toast.error("Select Store to delete");
      return;
    }
    let params={
      postData:{
        id:selectStoreId
      },
      tokenData:auth?.payload?.token
    }
    setIsDeleting(true);
    dispatch(deleteSellerAddressApiAsync(params))
    .then(unwrapResult)
    .then(res=>{
      let id=selectStoreId;
      let filterStore=storeList.filter(store=>store.id!=id);
      setStoreList(filterStore);
      setIsDeleting(false);
      setSelectStoreId(null);
      handleOnCloseModal();
    })
    .catch(err=>{
      setIsDeleting(false);

    })
    // setTimeout(() => {
    //   setIsDeleting(false);
    //   setSelectStoreId(null);
    // }, 3000);
  }
  return (
    <>
      <div className="settingNotification_ position-relative businessLocation">
        {loading && <Loader />}
        <div className="d-flex align-items-center justify-content-between businessLocaHead">
          <h3 className="appoinMain fw500 fontSize24 txtDarkblue mb-0">
          {t("businessLocation")}
          </h3>
          <button
            onClick={() => {
              setmoreSetting("AddLocation",null);
            }}
            className="editbtn_"
          >
            {t("addLocation")} <img src={Images.plusRound} alt="" />
          </button>
        </div>
        <div
          className="settingNotificationinner flex-column"
          style={{ background: "none" }}
        >
          {storeList?.map((store,index) => (
            <div
            key={index}
              className="storeLocation_  w-100"
              style={{
                flexDirection: "column",
              }}
            >
              <div className="storeTop_">
                <div>
                  <h3 className="headblue18" style={{
                    textTransform: "capitalize"
                  }}>
                    {store?.business_name??"Store"}{" "}
                    <span className="retailTxt fw500 fontSize12">
                      {" "}
                      <img
                        className="me-1"
                        src={Images.MarketplaceImg}
                        alt=""
                      />
                      Retail{" "}
                    </span>{" "}
                    <span className="serviceTxt fw500 fontSize12">
                      {" "}
                      <img
                        className="me-1"
                        src={Images.serviceimg}
                        alt=""
                      />
                      Service{" "}
                    </span>
                  </h3>
                  <div className="rightcontentCarrer">
                    <h5 className="fw500 fontSize14 staffPositionTxt mb-0">
                      <img className="editicon_" src={Images.locprofileimg} />{" "}
                      {(store?.format_address??store?.street_address??(store?.city+""+store?.state))}
                    </h5>
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center">
                 {!(store?.is_default)&& <figure
                  onClick={()=>{
                    if(store?.is_default){
                      toast.error("unable to delete default address");
                      return;
                    }
                    setSelectStoreId(store.id);
                    handleOpenModal("deleteModal");
                  }}
                  ><img src={Images.newDeleteIcon} alt="" /></figure>}
                <figure
                  onClick={() => {
                    setmoreSetting("addEditLocation",store);
                  }}
                  className="editprofileIcon_ me-3"
                >
                  <img
                    className="editicon_ cursorPointer"
                    src={Images.busLocationEdit}
                  />
                </figure>
                </div>
              </div>

              <div>
                <WorkOptions
                  options={store?.product_availability?.availability}
                />
              </div>
            </div>
          ))}
        </div>
        <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
           modalDetail.flag === "deleteModal"
                    ? "deleteModal"
                    : ""
        }
        child={
           modalDetail.flag === "deleteModal" ? (
            <DeleteModal
            title={"Delete your Business Store"}
            close={() => handleOnCloseModal()}
            isDeleting={isDeleting}
            deleteLoading={isDeleting}
            delete={(value) => {
                handleDeleteBusinessLocation();
            }}
            />
          ) : (
            <></>
          )
        }
        
        onCloseModal={() => handleOnCloseModal()}
      />
      </div>
    </>
  );
};

export default BusinessLocations;