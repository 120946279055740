import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { archivePolicyApiAsync } from '../../systemConfig/systemConfigSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { selectLoginAuth } from '../../auth/authSlice';
import { useTranslation } from 'react-i18next';
import { legalPoliciesFlag } from '../../../constants/common.constant';

const ViewPolicy = (props) => {
    const { t } = useTranslation();
    const { setmoreSetting,policy } = props;
    console.log(policy,"check it")
    const [loading, setLoading] = useState(false);
       const auth = useSelector(selectLoginAuth);
    const [currentPolicy, setCurrentPolicy] = useState(null);
   
   const dispatch=useDispatch();
    const handleUpdateStatus=(status)=>{
        let params={
            postData: {
                'id': policy.id,
                "status": status
            },
            "tokenData": auth?.payload?.token
        }
        setLoading(true);   
        dispatch(archivePolicyApiAsync(params))
        .then(unwrapResult)
        .then(res=>{
            setLoading(false);
            if(status==0){
                setCurrentPolicy((prev)=>({...prev,deleted_at:null}));
            }else{
                policy.deleted_at=new Date();
                setCurrentPolicy((prev)=>({...prev,deleted_at:new Date()}));
            }
        })
        .catch(err=>{
            setLoading(false);
            toast.error(`Unable to ${status==0?'UnArchieve':'Archieve'} policy `);
        })
    }
    const history = useHistory();
    useEffect(()=>{
        setCurrentPolicy(policy);
    },[policy]);
    
    return (
        <div className='refundPolicy_'>
            <div className='legalHead_ d-flex align-items-center justify-content-between'>
                <div className='legalleft_'>
                    <h3 onClick={() => {
                    setmoreSetting("Legal",null)
                }} className='appointMain'><img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' />
                {policy?.flag?legalPoliciesFlag[policy?.flag]??policy?.flag:""}
                  </h3>
                </div>
                <div className='legalrightBtn_ d-flex align-items-center'>
                    {(policy?.is_active)&&<button
                    disabled={loading}
                    onClick={()=>handleUpdateStatus(currentPolicy?.deleted_at?0:1)} className='greyBtn' type='submit'>
                        {/* show loading */}
                        {loading && <div style={{width:"18px",height:"18px"}}  className="spinner-border text-primary" role="status" aria-hidden="true"></div>}
                        {currentPolicy?.deleted_at?t('unarchive'):t("archive")}
                    </button>}
                    <button
                    disabled={loading}
                    className='connectedBtn_' type='submit'
                    onClick={()=>{
                        let {user_id,created_by,created_at,deleted_at
                            ,updated_at,...rest}=policy;
                        setmoreSetting("CreateUpdateLegal",{...rest})
                    }}
                    >{t('edit')}
                        <img className="batteryfull_" src={Images.editprofile}
                        />
                    </button>
                </div>
            </div>
            <div className='refundPolicyContent_ mt-5'>
                {/* <h4 className='text18head_ mb-4'>{policy?.flag}</h4> */}
                <div className="py-2 px-4"
                dangerouslySetInnerHTML={{
                    __html: policy?.content 
                }}/>
            </div>
            


        </div>
















    )
}

export default ViewPolicy 