import React, { useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify";

const EditSalePriceModal = (props) => {
  const { allAddress } = props;
  const toastId = useRef(null);
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [sellingPrice, setSellingPrice] = useState("");
  const [appName, setAppName] = useState([]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleAppOption = (option) => (event) => {
    const isChecked = event.target.checked;

    setAppName((prevOptions) => {
      if (isChecked) {
        return [...prevOptions, option];
      } else {
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };

  const handleSave = () => {
    if (!sellerAddressIds.length) {
      showToast("Please select store");
      return;
    }
    if (!appName.length) {
      showToast(
        "Please select a valid store type (e.g., JOBR POS or Marketplace)"
      );
      return;
    }
    if (!sellingPrice) {
      showToast("Please enter selling price");
      return;
    }
    props.handleUpdate(sellerAddressIds, sellingPrice, appName);
  };

  return (
    <div>
      <div className="outerArea EditVariation_ModalBox editSaleModalBox d-block">
        <div className="form_group  p-0">
          <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
            New Sales Price Amount
          </label>
          <div className="inputGroup">
            <input
              min="1"
              onKeyDown={blockInvalidChar}
              className="customInput costPriceInput"
              placeholder="0.00"
              name="text"
              type="number"
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
            />
            <span className="dollrsign1_">$</span>
            <span className="dollrsign1_ usdTxt">USD</span>
          </div>
        </div>

        {/* <div className="reactSelectParent editSalePriceSelect  position-relative w-100">
          <label className="fontSize16 txtDarkblue mb-2 ps-3">
            Apply Sale Price by
          </label>
          <ReactSelect
            options={resultSelectOption}
            placeholder="Amount"
            classNamePrefix="react-select"
            className="react-select-container anlyticSelect"
            styles={{
              control: (provided) => ({
                ...provided,
                maxWidth: "100% !important",
                width: "100%",
              }),
              menu: (provided) => ({
                ...provided,
                border: "1px solid #D7DEFF",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#263682"
                  : state.isFocused
                  ? "#f5f6fc"
                  : "#fff",

                color: state.isSelected ? "#fff" : "#263682",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#636E9F",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
            }}
            components={{
              DropdownIndicator: () => (
                <img
                  src={Images.selectImg}
                  width={24}
                  height={24}
                  alt="drop_icon"
                  className="mt-1"
                />
              ),
            }}
          />
          <span className="reactSelectDollar">$</span>
        </div>
        <div className="reactSelectParent editSalePriceSelect  position-relative w-100">
          <label className="fontSize16 txtDarkblue mb-2 ps-3">
          New Sales Price Amount
          </label>
          <ReactSelect
            options={resultSelectOption}
            placeholder="0.00"
            classNamePrefix="react-select"
            className="react-select-container anlyticSelect"
            styles={{
              control: (provided) => ({
                ...provided,
                maxWidth: "100% !important",
                width: "100%",
              }),
              menu: (provided) => ({
                ...provided,
                border: "1px solid #D7DEFF",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#263682"
                  : state.isFocused
                  ? "#f5f6fc"
                  : "#fff",

                color: state.isSelected ? "#fff" : "#263682",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#636E9F",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
            }}
            components={{
              DropdownIndicator: () => (
                <img
                  src={Images.selectImg}
                  width={24}
                  height={24}
                  alt="drop_icon"
                  className="mt-1"
                />
              ),
            }}
          />
          <span className="reactSelectDollar">$</span>
          <span className="reactSelectDollar reactSelectUsd">USD</span>
        </div> */}
      </div>
      <div className="outerArea EditVariation_ModalBox mb-0">
        <h3 className="fontSize18 fw500 txtDarkblue">
          Updated Sales Price will effect on
        </h3>

        <div className="productAvail_Second">
          <div className="d-flex align-items-center">
            <img
              src={Images.physicalStoreIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input
                  type="checkbox"
                  checked={appName.includes("pos")}
                  onChange={handleAppOption("pos")}
                />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">Physical store</h3>
                <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
              </div>
            </div>
          </div>
          {appName.includes("pos") && (
            <div className="productAvailOption_Wrapper">
              {allAddress.map((store, idx) => (
                <div className="productAvailabilityChild mt-3" key={store.id}>
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id={`stores-${store.id}`}
                      checked={sellerAddressIds.includes(store.id)}
                      onChange={handleStoreToggle(store.id)} // Correct usage
                    />
                    <label htmlFor={`stores-${store.id}`}></label>
                  </div>
                  <div>
                    <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                      Store {idx + 1}
                    </h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      {store.street_address}, {store.city}, {store.state}{" "}
                      {store.zipcode}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="jobrMarketBox">
          <div className=" d-flex align-items-center">
            <img
              src={Images.jobrMarketplaceIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input
                  type="checkbox"
                  checked={appName.includes("b2c")}
                  onChange={handleAppOption("b2c")}
                />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  JOBR Marketplace
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Online fulfillment methods
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => props?.close()}
        >
          Discard
        </button>
        <button
          className="modalNextBtn modalDiscardBtn active w-100"
          type="button"
          disabled={props.loading}
          onClick={handleSave}
        >
          {props.loading && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}
          Update
        </button>
      </div>
    </div>
  );
};

export default EditSalePriceModal;
