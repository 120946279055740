
import dayjs from 'dayjs';
import * as React from 'react';
import { Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { u } from '../commonStyles';
import { useNow } from '../hooks/useNow';
import { usePanResponder } from '../hooks/usePanResponder';
import { useTheme } from '../theme/ThemeContext';
import { getWeeksWithAdjacentMonths } from '../utils/datetime';
import { typedMemo } from '../utils/react';
import { CalendarEventForMonthView } from './CalendarEventForMonthView';
import calendarize from 'calendarize';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
function _CalendarBodyForMonthView(_a) {
    var containerHeight = _a.containerHeight, targetDate = _a.targetDate, style = _a.style, onPressCell = _a.onPressCell, onPressDateHeader = _a.onPressDateHeader, events = _a.events, onPressEvent = _a.onPressEvent, eventCellStyle = _a.eventCellStyle, calendarCellStyle = _a.calendarCellStyle, calendarCellTextStyle = _a.calendarCellTextStyle, onSwipeHorizontal = _a.onSwipeHorizontal, hideNowIndicator = _a.hideNowIndicator, showAdjacentMonths = _a.showAdjacentMonths, renderEvent = _a.renderEvent, maxVisibleEventCount = _a.maxVisibleEventCount, weekStartsOn = _a.weekStartsOn, eventMinHeightForMonthView = _a.eventMinHeightForMonthView, moreLabel = _a.moreLabel, sortedMonthView = _a.sortedMonthView;
    var now = useNow(!hideNowIndicator).now;
    var _b = React.useState(0), calendarWidth = _b[0], setCalendarWidth = _b[1];
    var panResponder = usePanResponder({
        onSwipeHorizontal: onSwipeHorizontal,
    });
    var weeks = showAdjacentMonths
        ? getWeeksWithAdjacentMonths(targetDate, weekStartsOn)
        : calendarize(targetDate.toDate(), weekStartsOn);
    var minCellHeight = Math.max(127,containerHeight / 5 - 30);
    var theme = useTheme();
    var getCalendarCellStyle = React.useMemo(function () { return (typeof calendarCellStyle === 'function' ? calendarCellStyle : function () { return calendarCellStyle; }); }, [calendarCellStyle]);
    var getCalendarCellTextStyle = React.useMemo(function () {
        return typeof calendarCellTextStyle === 'function'
            ? calendarCellTextStyle
            : function () { return calendarCellTextStyle; };
    }, [calendarCellTextStyle]);
    var sortedEvents = React.useCallback(function (day) {
        return sortedMonthView
            ? events
                .filter(function (_a) {
                var start = _a.start, end = _a.end;
                return day.isBetween(dayjs(start).startOf('day'), dayjs(end).endOf('day'), null, '[)');
            })
                .sort(function (a, b) {
                if (dayjs(a.start).isSame(b.start, 'day')) {
                    var aDuration = dayjs.duration(dayjs(a.end).diff(dayjs(a.start))).days();
                    var bDuration = dayjs.duration(dayjs(b.end).diff(dayjs(b.start))).days();
                    return bDuration - aDuration;
                }
                return a.start.getTime() - b.start.getTime();
            })
            : events.filter(function (_a) {
                var start = _a.start, end = _a.end;
                return day.isBetween(dayjs(start).startOf('day'), dayjs(end).endOf('day'), null, '[)');
            });
    }, [events, sortedMonthView]);
    return (React.createElement(ScrollView, __assign({ showsVerticalScrollIndicator: false, style: [
            // {
            //     height: containerHeight,
            // },
            u['flex-column'],
            u['flex-1'],
            u['border-b'],
            u['border-l'],
            u['border-r'],
            u['rounded'],
            { borderColor: theme.palette.gray['200'] },
            style,
        ], onLayout: function (_a) {
            var layout = _a.nativeEvent.layout;
            return setCalendarWidth(layout.width);
        } }, panResponder.panHandlers), weeks.map(function (week, i) { return (React.createElement(View, { key: i, style: [
            u['flex-1'],
            theme.isRTL ? u['flex-row-reverse'] : u['flex-row'],
            Platform.OS === 'android' && style, // TODO: in Android, backgroundColor is not applied to child components
            {
                minHeight: minCellHeight,
            },
        ] }, week
        .map(function (d) {
        return showAdjacentMonths ? targetDate.date(d) : d > 0 ? targetDate.date(d) : null;
    })
        .map(function (date, ii) { return (React.createElement(TouchableOpacity, { onPress: function () { return date && onPressCell && onPressCell(date.toDate()); }, style: [
            i > 0 && u['border-t'],
            theme.isRTL && ii > 0 && u['border-r'],
            !theme.isRTL && ii > 0 && u['border-l'],
            { borderColor: theme.palette.gray['200'] },
            u['p-2'],
            u['flex-1'],
            u['flex-column'],
            {
                minHeight: minCellHeight,
            },
            __assign({}, getCalendarCellStyle(date === null || date === void 0 ? void 0 : date.toDate(), i)),
        ], key: ii },
        React.createElement(TouchableOpacity, { onPress: function () {
                return date &&
                    (onPressDateHeader
                        ? onPressDateHeader(date.toDate())
                        : onPressCell && onPressCell(date.toDate()));
            } },
            React.createElement(Text, { style: [
                    { textAlign: 'center' },
                    theme.typography.sm,
                    {
                        color: (date === null || date === void 0 ? void 0 : date.format('YYYY-MM-DD')) === now.format('YYYY-MM-DD')
                            ? theme.palette.primary.main
                            : (date === null || date === void 0 ? void 0 : date.month()) !== targetDate.month()
                                ? theme.palette.gray['500']
                                : theme.palette.gray['800'],
                    },
                    __assign({}, getCalendarCellTextStyle(date === null || date === void 0 ? void 0 : date.toDate(), i)),
                ] }, date && date.format('D'))),
        date &&
            sortedEvents(date).reduce(function (elements, event, index, events) {
                if (index > maxVisibleEventCount) {
                    return elements; // Don't render more events than the limit
                }
                if (index === maxVisibleEventCount) {
                    return __spreadArray(__spreadArray([], elements, true), [
                        React.createElement(Text, { key: index, style: [
                                theme.typography.moreLabel,
                                { marginTop: 2, color: theme.palette.moreLabel },
                            ] }, moreLabel.replace('{moreCount}', "".concat(events.length - maxVisibleEventCount))),
                    ], false);
                }
                return [
                    // ...elements,
                    React.createElement(CalendarEventForMonthView, { allEvents: events, key: index, event: event, eventCellStyle: eventCellStyle, onPressEvent: onPressEvent, renderEvent: renderEvent, date: date, dayOfTheWeek: ii, calendarWidth: calendarWidth, isRTL: theme.isRTL, eventMinHeightForMonthView: 50, showAdjacentMonths: showAdjacentMonths }),
                ];
            }, []))); }))); })));
}
export var CalendarBodyForMonthView = typedMemo(_CalendarBodyForMonthView);
