import React, { useEffect, useState } from 'react'
import OrdersettingSidebar from './ordersettingSidebar'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import * as Images from "../../../../utilities/images";
import OrderProcessing from './orderProcessing';
import OrderpickupDelivery from './orderpickupDelivery';
import OrderShiping from './orderShiping';
import CustomerNotification from './customerNotification';
import AdditionalSetting from './additionalSetting';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

const OrderSettings = () => {
    const { t } = useTranslation();
    const [orderSetting, setOrderSetting] = useState("Order Processing")
    const location = useLocation();
  
    // Extract query parameters from location.search
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page'); 
   
      /*
      get prams from page and check
      */
  useEffect(()=>{
      if(page){
        setOrderSetting("Shipping");
      }
  },[])

    return (
        <div className='orderSetting_'>
            <DashboardNavbar title={t("orderSettings")} backShow={false} />
            <div className='row'>
                <div className='col-md-3'>
                    <div className='orderleft'>
                        {/* <OrdersettingSidebar /> */}
                        <div className="leftsidebarOrder">
                            <ul className='ordersettingmenu_'>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${orderSetting === "Order Processing" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Order Processing") }}>
                                        <img className='ordersetting' src={Images.ordersetting} /> {t("orderProcessing")}
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${orderSetting === "Pickup & Delivery" ? "active" : ""}`} onClick={() => { setOrderSetting("Pickup & Delivery") }}>
                                        <img className='ordersetting' src={Images.pickupsetting} /> {t("pickup&Delivery")}
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${orderSetting === "Shipping" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Shipping") }}>
                                        <img className='ordersetting' src={Images.shippingOrder} /> {t("shipping")}
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${orderSetting === "Customer Notifications" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Customer Notifications") }}>
                                        <img className='ordersetting' src={Images.orderring} /> {t("customerNotifications")}
                                    </a>
                                </li>
                                <li className='listinner_'>
                                    <a href='#' className={`listinner_a ${orderSetting === "Additional Settings" ? "active" : ""}`}
                                        onClick={() => { setOrderSetting("Additional Settings") }}>
                                        <img className='ordersetting' src={Images.detailorder} /> {t("additionalSetting")}
                                    </a>
                                </li>

                            </ul>


                        </div>
                    </div>

                </div>
                <div className='col-md-9'>
                    <div className={`position-relative ${orderSetting === "Additional Settings" ? 'additionalsetmain_ rightContentorder_': "Customer Notifications" ? 'additionalsetmain_ rightContentorder_' : 'rightContentorder_ '}`}>
                        {(orderSetting === "Order Processing") && <OrderProcessing />}
                        {(orderSetting === "Pickup & Delivery") && <OrderpickupDelivery />}
                        {(orderSetting === "Shipping") && <OrderShiping />}
                        {(orderSetting === "Customer Notifications") && <CustomerNotification />}
                        {(orderSetting === "Additional Settings") && <AdditionalSetting />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderSettings
