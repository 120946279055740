
export const RewardColumns = [
    {
        isVisible: true,
        title: "name"
    },
    {
        isVisible: true,
        title: "totalSpent"
    },
    {
        isVisible: true,
        title: "JBRPointsEarned"
    },
    {
        isVisible: true,
        title: "totalOrders"
    },
  
]