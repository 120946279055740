import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import CustomModal from '../../../components/shared/CustomModal';
import BrowsepluginModal from './browsepluginModal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getPluginsListAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

const PluginandIntigration = () => {
  const { t } = useTranslation();
     const history = useHistory()
      const [loading, setLoading] = useState(false);
      const [pluginstData, setPluginsData] = useState([]);
      const dispatch = useDispatch();
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleDownload = (flag, url) => {
        if (flag == "Wordpress") {
          const fileUrl = `/jobr-products-management.zip`;
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = "jobr-wordpress.zip"; // Specify the downloaded file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
    
      useEffect(() => {
        setLoading(true);
        dispatch(getPluginsListAsync())
          .then(unwrapResult)
          .then((obj) => {
            setPluginsData(obj?.payload);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }, []);
    
    return (
        <>
            <div className='pluginsIntegration_'>
                <div className='pluginhead_ d-flex align-items-center justify-content-between'>
                    <div>
                        <h3 className='appointMain'>{t('plugins')} </h3>
                        <p className='lighttext14'>JOBR {t('jobrApp')}</p>
                    </div>
                    <button className="editbtn_">{t('browse')}<img src={Images.upArrow} alt="" /></button>
                </div>

                <div className='row mt-5'>
                {loading ? (
                            <span className="spinner-border inner-spin spinner-border-sm"></span>
                          ) : (
                            <>
                        
                     {pluginstData?.length > 0 ? (
                                <>
                                  {pluginstData?.map((val, index) => {
                                    return (
                                        <div className='col-md-6 mb-4' key={index}>
                        <div className='pluginsName_'  onClick={() => {
                                            // handleOpenModal("loaderModal");
                                            handleDownload(val?.title, val?.url);
                                          }}>
                            <img className='inegrationimg' src={val?.logo} alt='woocommerce' />
                            <article className='pluginContent_'>
                                <h4 className='smalblueText_ mb-1 d-flex align-items-center justify-content-between'>{val?.title} <img className='' src={Images.upArrow} alt='arrpw' /></h4>
                                <h5 className='bgbox_ mb-2'><span className='doticon_ me-1'></span> <span> Connected </span></h5>
                                <p className='bluesmall_'>{val?.discription}</p>
                            </article>
                        </div>
                    </div>
                                    
                                    );
                                  })}
                                </>
                              ) : (
                                <div>No Plugins Found</div>
                              )}
                            </>
                          )
                    }
                </div>
                <div className='browseBtn_ mt-4'>
                    <button className='browsebtn' onClick={() => {
                        setModalDetail({ show: true, flag: "BrowsepluginModal" });
                        setKey(Math.random());
                    }}><img
                            className="batteryfull_"
                            src={Images.plusCircleOutline}
                        />{t('browsePlugin')}</button>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="false"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "BrowsepluginModal" ? "browsepluginModal" : ""}
                // className={
                //     modalDetail.flag === "BrowsepluginModal"
                //         ? " modal-fullscreen"
                //         : ""
                // }
                dialogcustomClass={
                    modalDetail.flag === "BrowsepluginModal"
                        ? " modal-fullscreen"
                        : ""
                }
                child={
                    modalDetail.flag === "BrowsepluginModal" ? (
                        <BrowsepluginModal
                        handleDownload={handleDownload}
                        data={pluginstData}
                            close={() => handleOnCloseModal()}
                        />
                    ) : (
                        ""
                    )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default PluginandIntigration