import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useHistory } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";

import CustomModal from "../../../components/shared/CustomModal";
import CropImageModal from "../../product/CropImageModal";
import { uploadFileInCatelogAsync } from "../../dashboard/dashboardSlice";
import { imageUploadAsync } from "../messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
const UploadFileModal = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const dispatch = useDispatch()



  const auth = useSelector(selectLoginAuth)

  const toastId = React.useRef(null);
 
  const handleChange = (file) => {
    uploadImage(file);
  };
  const uploadImage = (file) => {
    let formData = new FormData();
    Object.values(file).forEach((val, index) => {
        formData.append(`file`, val);
    });

    let params = {
        file: formData,
        token: auth?.payload?.token
    }
    setLoading(true)
    if (props.flag === "catalog") {
        dispatch(uploadFileInCatelogAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                console.log(obj?.payload)
                setFile([...file,...obj?.payload]);
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    } else {
        dispatch(imageUploadAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setFile(obj?.payload);
             
                if (!toast.isActive(toastId.current)) {
                }

            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
}
  

 

  const sendPhoto = (file) => {
    if (props.flag === "catalog") {
      props.uploadHandleInCatelog(file)
      props.close();
  } else {
      props.file(file, props.flag);
      props.close();
  }
  };
  
  const deleteFile = (item) => {
    const filterFile = file.filter(file => file.filePath != item);
    setFile(filterFile)
}
 

  return (
    <>
      <div className="browseUploadSection modalBodyBg p-0">
        <form className="emplyoeeDetails horizontalForms" style={{margin:"0"}}>
          <div className="row">
            <div className="form-group col-md-12 text-start">
              <div className="mainCatalog p-0">
               {
                file==null||file?.length==0&&<>
                 <div id="dropzone">
                  <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    children={
                      <div>
                        <label htmlFor="file">
                          <span className="file-button uploadImages_">
                            <img
                              src={Images.gallerySendImg}
                              className="img-fluid editProfileImg"
                              alt="user img"
                            />
                            <div className="dropText ms-2">
                              <span className="dragAndDrop_Txt">
                                {t('uploadFile')} <br /> {t('dragFile')}
                              </span>
                            </div>
                          </span>
                        </label>
                      </div>
                    }
                  />
                </div>

                <p className="fontSize18 fw500 txtDarkblue text-center mt-3">
                  {t('uploadFileTitle')}
                </p></>
               }

                {props?.loading || loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm spinnerDark"></span>
                  </>
                ) : (
                  <>
                    {file && file?.length > 0 ? (
                      <div className="row gap-2">
                        {file?.map((item, index) => {
                          return (
                            <div className="rounded col-lg-3 col-12 mt-3 border position-relative" key={index}>
                              <div className="galleryView">
                                <img
                                  className="image-gallery"
                                  src={item.filePath}
                                  alt=""
                                />
                              </div>
                              <div className="gallery-icons position-absolute" style={{right:"2px",top:"10px"}}>
                                <img
                                  src={Images.deleteIcon}
                                  onClick={(e) =>
                                    deleteFile(item.filePath)
                                  }
                                  alt=""
                                />
                               
                              </div>
                            </div>
                          );
                        })}
                        
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          
        </form>
        <button className="commonBlueBtn active m-auto"
        onClick={() => {
          if(file.length==0){
            toast.error("Please select image")
            return
          }
          else{
            sendPhoto(file)
          }
        }}
        >{t('upload')} <img src={Images.closeTag} alt="img"/></button>
      </div>
      
    </>
  );
};

export default UploadFileModal;
