export const HeaderStatisticsArr=[
    {
        heading:"Total Makreting value",
        key:"totalMarketingValue",
        img:"marketvalueIcon",
        className:" totalCategoryBox",
        value:"304"

    },
    {
        heading:"Total revenue generated",
        key:"totalRevenueGenerate",
        img:"revenueGenerate",
        className:"totalSubCategoryBox ",
        value:"$3305.90"


    },
    {
        heading:"Redemption rate",
        key:"redeemptionRate",
        img:"redemptionIcon",
        className:"totalProductBox ",
        value:"45.9%"


    },
    {
        heading:"Average discount per coupon",
        key:"averageDiscountRate",
        img:"averageOrder",
        className:"totalBrandBox",
        value:"$4.50"


    },
    
]