import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { success } from "../../../../utilities/images";

const SuccessfulCreated = () => {
  const history = useHistory();
  return (
    <>
      <div className="successFullCreate text-center">
        {/* <div className='bigTickSection'>
        <img src={bigTick} className='img-fluid bigTickImg'/>
     </div> */}
        <img src={success} alt="success" />
        
          <h4 className="successfullyProductTxt">Successfully Created</h4>
          <p className="fontSize16 txtLightBlue">Please Login Back </p>
        
        <div className="modalfooterBtn">
        <button
          onClick={() => history.push("/")}
          className="modalDiscardBtn modalNextBtn active ms-0 active w-100"
        >
          Back to login
        </button>
        </div>
      </div>
    </>
  );
};

export default SuccessfulCreated;
