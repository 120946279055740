import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const AddProductHeader = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = window.location.pathname;

  const handleSave = () => {
    props.save();
  };
  return (
    <div className="topHeader_">
      <div className="productTopStickyHeader_ addPhysicalProductHeader">
        <div className="row align-items-center">
          <div className="col-6">
            <h2 className="innerSubheadNormal">
              {/* <span onClick={() => history.goBack()}>
                                <i className="las la-angle-left me-3 directionArrow"></i>
                            </span> */}
              <span className="txtDarkblue fw500 fontSize20 textCapital">
                {pathname === "/editProducts"
                  ? props?.productName
                  : "Add Physical Product"}
              </span>
            </h2>
          </div>
          <div className="col-6 text-end mt-md-0">
            <button
              className="me-sm-3 me-2 headerDiscardBtn"
              type="button"
              onClick={() => history.goBack()}
            >
              {t("discard")}
            </button>
            <button
              className="headerSaveBtn "
              type="button"
              onClick={() => handleSave()}
              disabled={props?.successLoader}
            >
              {props?.successLoader && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              {pathname === "/editProducts" ? t("update") : t("save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductHeader;
