import React from 'react'
import { useSelector } from 'react-redux';
import { selectNotificationData } from '../containers/dashboard/dashboardSlice';
import { NotificationIcon, notifyImg } from '../../utilities/images';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const NotificationButton = (props) => {
    const notificationData = useSelector(selectNotificationData)
    const NewNotifications = notificationData?.some((e) => e.is_read === false);
    return (
        <Link to="/notifications" className={props?.className || "notifyBellDesktop"}>
            <img src={NewNotifications ? NotificationIcon : notifyImg} alt="NotificationIcon" />
        </Link>
    )
}

export default NotificationButton
