import React from "react";

const LeaveDetailsModal = () => {
  return (
    <div className="leaveDetailParent">
      <div className="container-fluid p-0">
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">
                Employee name
              </h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">
                Andrea Gonzalez-Iturbide
              </h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Department</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">POS Admin</h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Employee Id</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">9037840</h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Start date</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">20-11-2024</h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">End date</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">25-11-2024</h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Days used</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">5</h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Days remain</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">20</h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Leave type</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Other</h5>
            </div>
          </div>
        </div>
        <div className="leaveDetailInnerBox">
          <div className="row">
            <div className="col-5">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">Leave reason</h5>
            </div>
            <div className="col-7">
              <h5 className="fontSize12 fw500 mb-0 uncheckTxt">
                Personal issue
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveDetailsModal;
