import React, { useState } from "react";
import PersonalInfo from "./PersonalInfo";
import { Stepper } from "react-form-stepper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../../utilities/images";
import AddEmployementDetails from "./AddEmployementDetails";
import Compensation from "./Compensation";

const AddNewStaff = () => {
  const [activeStep, setActiveStep] = useState(1);
  const history = useHistory();

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 1));
  };
  const renderStepComponent = () => {
    switch (activeStep) {
      case 1:
        return <PersonalInfo />;
        case 2:
          return <AddEmployementDetails />;
        case 3:
          return <Compensation />;
      default:
        return <PersonalInfo />;
    }
  };
  return (
    <div className="productDetailsWrapper addNewStaffSection">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="walletStats">
              <div className="flexBox gap-3">
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  Add New Staff
                </h3>
                <div className="addStaffStepperBtn ms-auto">
                  <button
                    className="modalDiscardBtn w-auto fontSize14"
                    onClick={handleBack}
                  >
                    Discard
                  </button>
                  <button
                    className="modalNextBtn modalDiscardBtn w-auto"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-8">
                  <div className="stepperParent">
                    <Stepper
                      activeStep={activeStep}
                      onStepChange={handleStepChange}
                      steps={[
                        {
                          label: "Personal info",
                        },
                        { label: "Employment Details" },
                        { label: "Compensation " },
                        { label: "Payment method" },
                      ]}
                      disabledColor="#E4EBF1"
                    />
                  </div>
                  {renderStepComponent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewStaff;
