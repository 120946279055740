import React, { useState, useEffect, act } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Images from "../../../utilities/images";

import {
  brandLogo,
  logoutImg,
  home,
  purchase,
  setting,
  support,
  products,
  chat,
  service,
  orders,
  pos,
  trackings,
  wallet,
  users,
  campaign,
  analytics,
  catalog,
  analyticsicon,
  SidebarLogo,
  PositiveIcon,
  productsIcon,
  serviceIcon,
  ordersIcon,
  bookings,
  userDirectory,
  walletIcon,
  analyticsIcon,
  settings,
  supportIcon,
  coupons,
  logOut,
  homeIcon,
  sideArrow,
  marketing,
  catalogs,
  POS,
  awardover_,
  allordericon,
  trackDeliveryicon,
  sideBarSettingIcon,
  cashDraweIcon,
  payrollAndStaffLogo,
  homenewIcon,
} from "../../../utilities/images";

import {
  getUserByIdApiAsync,
  logout,
  selectLoginAuth,
} from "../../containers/auth/authSlice";
import { Wallet } from "../../containers";
import { toast } from "react-toastify";
import {
  dashboardEmpty,
  getMerchantStepStatusAsync,
  getWalletBallanceApiAsync,
  selectMerchantStatus,
} from "../../containers/dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { productEmpty } from "../../containers/myProducts/productSlice";
import { bookingEmpty } from "../../containers/bookings/bookingSlice";
import { useTranslation } from "react-i18next";
import { MarketingEmpty } from "../../containers/marketing/marketingSlice";

const HOME_ROUTE = ["/wallet"];
const MESSAGES_ROUTES = ["/directMessage", "/inquaries", "/rfqMessage"];
const PRODUCT_ROUTES = [
  "/myProducts",
  "/productList",
  "/productOffer",
  "/categoryListview",
  "/product-subcategory",
  "/product-brands",
];
const SERVICE_ROUTES = [
  "/myService",
  "/service-list",
  "/serviceOffer",
  "/service-setting",
];
const BOOKIN_ROUTES = [
  "/bookings",
  "/all-bookings",
  "/calendar",
  "/BookingSetting",
];
const POS_ROUTES = [
  "/myPos",
  "/posSales",
  "/createUser",
  "/createRole",
  "/posUser",
];
const TRANSACTION_ROUTES = [
  "/WalletMain",
  "/transections",
  "/jbrwallet",
  "/localcurrencywallet",
  "/withdrawal",
  "/walletPayment",
  "/walletRefund",
  "/WalletSetting",
];
const USER_ROUTES = ["/userList", "/customer", "/users/age-verification","/users/document-verification"];
const ORDERS_ROUTES = [
  "/myOrders",
  "/newOrder",
  "/processingOrders",
  "/completeOrders",
  "/cancelOrders",
  "/allOrder",
  "/orderSettings",
  "/orderTrack",
  "/refundOrders",
];
const ANALYTICS_ROUTES = [
  "/analytics",
  "/stats",
  "/Gross-Profits",
  "/Total-Revenue",
  "/Total-Costs",
  "/Total-POS-Orders",
  "/Total-Delivery-Orders",
  "/Total-Shipping-Orders",
  "/Total-Orders",
  "/Total-Inventory",
  "/Total-Product-Sold",
];
const SUPPORT_ROUTES = [
  "/support",
  "/supportTicket",
  "/supportCall",
  "/supportChat",
  "/supportEmail",
  "/mySupport",
];
const MARKETING_ROUTES = [
  "/marketingIndex",
  "/couponsListing",
  "/campaignIndex",
];
const STAFFANDPAYROLL_ROUTES = [
  "/myPos",
  "/posSales",
  "/createUser",
  "/posUser",
  "/staffPayment",
];
const REWARDS_ROUTES = ["/rewards", "/all-rewards", "/reward-setting"];
const CATEGORY_ROTES = ["/manage-categories"];
const GIFTCARD_ROTES = ["/giftcard"];
const CASHDRAWER_ROUTES = ["/cashDrawer"];

const Sidebar = (props) => {
  const { t } = useTranslation();
  const [activeSidebar, setActiveSidebar] = useState(true);
  const location = useLocation();
  const pathName = location.pathname;
  // const [isWallet, setIsWallet] = useState('');
  const auth = useSelector(selectLoginAuth);
  const userData = auth?.payload?.user;
  const sellerType = auth?.payload?.user?.user_profiles?.seller_type;

  const SUPPER_ADMIN = () => {
    const isSuperAdmin = auth?.payload?.user?.user_roles?.some(
      // check for super admin
      (e) => e?.role?.slug === "admin"
      // (e) => e?.role?.slug === "seller"
    );
    return isSuperAdmin;
  };

  const ADMIN = () => {
    const admin = auth?.payload?.user?.user_roles?.filter(
      (item) => item?.role?.slug == "admin"
    );
    return admin;
  };

  const merchantStatus = useSelector(selectMerchantStatus);
  const activePathName = window.location.pathname;

  const [activeData, setActiveData] = useState(activePathName || "Dashboard");
  const history = useHistory();
  const dispatch = useDispatch();

  const closeActions = () => {
    document.body.setAttribute("style", "overflow:auto");
  };
  const closeSide = () => {
    props.closeNav();
    document.body.setAttribute("style", "overflow:auto");
  };

  const userLogout = async () => {
    await dispatch(logout());
    await dispatch(productEmpty());
    await dispatch(dashboardEmpty());
    await dispatch(bookingEmpty());
    await dispatch(MarketingEmpty());
    window.localStorage.clear();
    toast.success("Successfully Logout");
    await history.push("/");
  };

  const getMerchantStepStatus = () => {
    dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => { })
      .catch((obj) => { });
  };
  useEffect(() => {
    // getUserDataByIdHandle()
    getMerchantStepStatus();
  }, []);

  useEffect(() => {
    setActiveData(pathName);
  }, [pathName]);

  const planActive =  merchantStatus?.payload?.subscription_status;
  const productAdded = merchantStatus?.payload?.product_status;

  return (
    <aside
      style={{
        display: ((!planActive || !productAdded)&&!SUPPER_ADMIN()) ? "none" : "",
      }}
      className={`vertical-tab  ${props?.nav?.open_nav ? "slideOpen" : ""} ${(activeSidebar&&!SUPPER_ADMIN()) ? "hide" : "full"
        }`}
      id="myNav"
    >
      <div
        onClick={() => setActiveSidebar((prev) => !prev)}
        className="ToggleIcon"
      >
        <img
          src={Images.homeIcon}
          alt="home"
          className="img-fluid sideToggle"
        />
      </div>
      <div>
        <Link to="/wallet" className="brandLogo">
          <img src={SidebarLogo} className="img-fluid" alt="" />
        </Link>
        <Link to="/moresettingIndex" className="sidebarProfileParent">
          <figure className="sidebarProfileImg">
            <img
              src={
                userData?.user_profiles?.profile_photo
                  ? userData.user_profiles.profile_photo
                  : Images.adminImg
              }
              alt="img"
              className="img-fluid"
            />
          </figure>
          <div className="text-start">
            <h3 className="fontSize18 fw500 txtDarkblue mb-1">
              {userData?.user_profiles?.firstname +
                " " +
                userData?.user_profiles?.lastname}
            </h3>
            <h5 className="fontSize14 fw500 txtLightBlue mb-0">{t("admin")}</h5>
          </div>
        </Link>
      </div>
      <ul className="sidebarMenus navbar_overlay_content_">
        {/* home routes */}
        <li
          className={
            HOME_ROUTE.includes(activeData) || HOME_ROUTE.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="/wallet"
            onClick={() =>
              HOME_ROUTE.includes(activeData)
                ? setActiveData("")
                : setActiveData("/wallet")
            }
            className={
              HOME_ROUTE.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            id="sidebarDropdown"
          >
            {/* <img src={homeIcon} className="img-fluid showImg" alt="" /> */}
            <img
              src={
                HOME_ROUTE.includes(activeData) || HOME_ROUTE.includes(pathName)
                  ? homeIcon
                  : homenewIcon
              }
              className="img-fluid showImg"
              alt=""
            />
            <span className="menusTxt">{t('home')}</span>
            {/* <i className="fa-solid fa-caret-down caretIcon"
                            ></i> */}
          </Link>
          {/* Start SidebarInnerMenus */}
          {/* <ul className={activeData === "/wallet" ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/wallet"
                                    className={pathName == '/wallet' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Wallet
                                </Link>
                            </li>
                        </ul> */}
          {/* End SidebarInnerMenus */}
        </li>
        {/* analytics routes */}
        {ADMIN()?.length > 0 && (
          <>
            <li
              className={
                activeData == "/subscriber"
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="/subscriber"
                onClick={() =>
                  activeData === "/subscriber"
                    ? setActiveData("")
                    : setActiveData("/subscriber")
                }
                className={
                  activeData === "/subscriber"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                id="sidebarDropdown"
              >
                <img src={users} className="img-fluid showImg" alt="" />
                <span className="menusTxt">{t('subscriber')}</span>
              </Link>
            </li>
            <li
              className={
                activeData == "/googleAnalytics"
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="/googleAnalytics"
                onClick={() =>
                  activeData === "/googleAnalytics"
                    ? setActiveData("")
                    : setActiveData("/googleAnalytics")
                }
                className={
                  activeData === "/googleAnalytics"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                id="sidebarDropdown"
              >
                <img src={users} className="img-fluid showImg" alt="" />
                <span className="menusTxt">{t('googleAnalytics')}</span>
              </Link>
            </li>
          </>
        )}
        {/* messages routes */}
        <li
          className={
            MESSAGES_ROUTES.includes(activeData) || MESSAGES_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="/directMessage"
            className={
              MESSAGES_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() =>
              MESSAGES_ROUTES.includes(activeData)
                ? setActiveData("")
                : setActiveData("/directMessage")
            }
            aria-expanded="false"
            id="sidebarDropdown"
          >
            <img
              src={
                MESSAGES_ROUTES.includes(activeData) ||
                  MESSAGES_ROUTES.includes(pathName)
                  ? Images.inboxFill
                  : PositiveIcon
              }
              className="img-fluid showImg"
              alt=""
            />
            <span className="menusTxt">{t('inbox')}</span>
            {/* <i class="caretIcon">
                <img src={sideArrow} className="sideArrowImg"></img>
              </i> */}
          </Link>
          {/* Start SidebarInnerMenus */}
          {/* <ul
              className={
                activeData === "/directMessage" ||
                  activeData == "/inquaries" ||
                  activeData == "/rfqMessage"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/directMessage"
                  className={
                    pathName === "/directMessage"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img src={Images.chatround} className="submenuImg_"></img>
                  Direct Message
                </Link>
              </li>
             
            </ul> */}
          {/* End SidebarInnerMenus */}
        </li>
        {/* product routes */}
        <li
          className={
            PRODUCT_ROUTES.includes(activeData) ||
              PRODUCT_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              PRODUCT_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show "
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {

              if (PRODUCT_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/myProducts")
                setActiveData("/myProducts")
              }
            }}
            aria-expanded="false"
            id="sidebarDropdown"
          >
            <img
              src={
                PRODUCT_ROUTES.includes(activeData) ||
                  PRODUCT_ROUTES.includes(pathName)
                  ? Images.productFill
                  : productsIcon
              }
              className="img-fluid showImg"
              alt=""
            />
            <span className="menusTxt">{t('products')}</span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg" alt="ueeuuy"></img>
            </i>
          </Link>
          {/* Start SidebarInnerMenus */}
          <ul
            className={
              PRODUCT_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/myProducts"
                className={
                  pathName == "/myProducts"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_"></img>
                {t('overview')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/productList"
                className={
                  pathName == "/productList"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.productbox_} className="submenuImg_"></img>
                {t('products')}
              </Link>
            </li>
            {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/category/product"
                                    className={pathName == '/categoryListview/category' || (pathName.split('/')[2] === "category" && pathName.split('/')[3] === "product") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Categories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/sub_category/product"
                                    className={pathName == '/categoryListview/sub_category' || (pathName.split('/')[2] === "sub_category" && pathName.split('/')[3] === "product") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Sub-categories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/brand/product"
                                    className={pathName == '/categoryListview/brand' || (pathName.split('/')[2] === "brand" && pathName.split('/')[3] === "product") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Brands
                                </Link>
                            </li>*/}
            <li className="sidebarInnerItems">
              <Link
                to="/productOffer"
                className={
                  pathName == "/productOffer"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.offericon_} className="submenuImg_"></img>
                {t('offers')}
              </Link>
            </li>
          </ul>
          {/* End SidebarInnerMenus */}
        </li>
        {/* service routes */}
        {sellerType == "manufacturer" || sellerType == "whole_seller" ? (
          ""
        ) : (
          <li
            className={
              SERVICE_ROUTES.includes(activeData) ||
                SERVICE_ROUTES.includes(pathName)
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                SERVICE_ROUTES.includes(activeData)
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() => {
                if (SERVICE_ROUTES.includes(activeData)) {
                  setActiveData("")
                } else {
                  history.push("/myService")
                  setActiveData("/myService")
                }
              }}
              id="sidebarDropdown"
            >
              <img
                src={
                  SERVICE_ROUTES.includes(activeData) ||
                    SERVICE_ROUTES.includes(pathName)
                    ? Images.serviceFill
                    : serviceIcon
                }
                className="img-fluid showImg"
                alt=""
              />
              <span className="menusTxt">{t('services')}</span>
              <i class="caretIcon">
                <img src={sideArrow} className="sideArrowImg" alt="fix"></img>
              </i>
            </Link>
            {/* Start SidebarInnerMenus */}
            <ul
              className={
                SERVICE_ROUTES.includes(activeData)
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/myService"
                  className={
                    pathName == "/myService"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img src={awardover_} className="submenuImg_"></img>
                  {t('overview')}
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/service-list"
                  className={
                    pathName == "/service-list"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img src={Images.serviceIcon} className="submenuImg_"></img>
                  {t('services')}
                </Link>
              </li>

              <li className="sidebarInnerItems">
                <Link
                  to="/serviceOffer"
                  className={
                    pathName == "/serviceOffer"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img src={Images.offericon_} className="submenuImg_"></img>
                  {t('offers')}
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/service-setting"
                  className={
                    pathName == "/service-setting"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img
                    src={sideBarSettingIcon}
                    alt="sideBarSettingIcon"
                    className="me-3"
                  />
                  {t('serviceSettings')}
                </Link>
              </li>
            </ul>
            {/* End SidebarInnerMenus */}
          </li>
        )}
        {SUPPER_ADMIN() && (
          <li
            className={
              CATEGORY_ROTES.includes(activeData) || CATEGORY_ROTES.includes(pathName)
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="/manage-categories"
              onClick={() =>
                CATEGORY_ROTES.includes(activeData)
                  ? setActiveData("")
                  : setActiveData("/manage-categories")
              }
              className={
                CATEGORY_ROTES.includes(activeData)
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              id="sidebarDropdown"
            >
              <img
                src={
                  CATEGORY_ROTES.includes(activeData) ||
                    CATEGORY_ROTES.includes(pathName)
                    ? Images.categoryActiveIcon
                    : Images.categoryInactiveIcon
                }
                alt="manage-categories"
              ></img>

              <span className="menusTxt coupons">{t('manageCategory')}</span>
            </Link>
          </li>
        )}
        {/* Order Routes */}
        <li
          className={
            ORDERS_ROUTES.includes(activeData) ||
              ORDERS_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              ORDERS_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {
              if (ORDERS_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/myOrders")
                setActiveData("/myOrders")
              }
            }}
            id="sidebarDropdown"
          >
            <img
              src={
                ORDERS_ROUTES.includes(activeData) ||
                  ORDERS_ROUTES.includes(pathName)
                  ? Images.orderFill
                  : ordersIcon
              }
              className="img-fluid showImg"
              alt=""
            />
            {/* <img src={fiatTockenActive} className="img-fluid hoverImg" alt="" /> */}
            <span className="menusTxt">{t('orders')}</span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg"></img>
            </i>
          </Link>
          {/* Start SidebarInnerMenus */}
          <ul
            className={
              ORDERS_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/myOrders"
                className={
                  pathName == "/myOrders"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_"></img>
                {t('overview')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/allOrder"
                className={
                  pathName == "/allOrder" ||
                    pathName.split("/")[1] === "orderReview"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={allordericon} className="submenuImg_"></img>
                {t('allorders')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/orderTrack"
                className={
                  pathName.split("/")[1] === "orderTrack"
                    ? // ||
                    // pathName.split("/")[1] === "orderReview" ||
                    // pathName.split("/")[1] === "orderTrack"
                    "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={trackDeliveryicon} className="submenuImg_"></img>
                {t('orderTracking')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/orderSettings"
                className={
                  pathName == "/orderSettings"
                    ? // ||
                    // pathName.split("/")[1] === "orderReview" ||
                    // pathName.split("/")[1] === "orderTrack"
                    "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={settings} className="submenuImg_"></img>
                {t("orderSetting")}
              </Link>
            </li>
            {/* <li className='sidebarInnerItems'>
                                <Link to="/processingOrders"
                                    className={pathName == '/processingOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Processing Orders
                                </Link>
                            </li> */}
            {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/completeOrders"
                                    className={pathName == '/completeOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Completed Orders
                                </Link>
                            </li> */}
            {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/cancelOrders"
                                    className={pathName == '/cancelOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Cancelled Orders
                                </Link>
                            </li> */}
            {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/refundOrders"
                                    className={pathName == '/refundOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Returned Orders
                                </Link>
                            </li> */}
          </ul>
          {/* End SidebarInnerMenus */}
        </li>
        {sellerType == "manufacturer" || sellerType == "whole_seller" ? (
          <li
            className={
              BOOKIN_ROUTES.includes(activeData) ||
                BOOKIN_ROUTES.includes(pathName)
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                BOOKIN_ROUTES.includes(activeData)
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() => {
                if (BOOKIN_ROUTES.includes(activeData)) {
                  setActiveData("")
                } else {
                  history.push("/bookings")
                  setActiveData("/bookings")
                }
              }}
              id="sidebarDropdown"
            >
              <img
                src={
                  BOOKIN_ROUTES.includes(activeData) ||
                    BOOKIN_ROUTES.includes(pathName)
                    ? Images.BookingFill
                    : bookings
                }
                className="img-fluid"
                alt=""
              />
              <span className="menusTxt">{t("bookings")} </span>
              <i class="caretIcon">
                <img src={sideArrow} className="sideArrowImg" alt="side"></img>
              </i>
            </Link>
            <ul
              className={
                BOOKIN_ROUTES.includes(activeData)
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/bookings"
                  className={
                    pathName == "/bookings"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#8D99D2"
                      className="circledot"
                    />
                  </svg>
                  {t('overview')}
                </Link>
              </li>

              <li className="sidebarInnerItems">
                <Link
                  to="/all-bookings"
                  className={
                    activeData.includes("/all-bookings") ||
                      pathName.includes("all-bookings")
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#8D99D2"
                      className="circledot"
                    />
                  </svg>
                  {t('allBookings')}
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/calendar"
                  className={
                    activeData.includes("calander") ||
                      pathName.includes("calander")
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#8D99D2"
                      className="circledot"
                    />
                  </svg>
                  {t('calendar')}
                </Link>
              </li>
              {/* <li className="sidebarInnerItems">
                  <Link
                    to="/completed-bookings"
                    className={
                      pathName == "/completed-bookings"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    Appointments
                  </Link>
                </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/bookingSettings"
                  className={
                    activeData.includes("bookingSettings") ||
                      pathName.includes("bookingSettings")
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#8D99D2"
                      className="circledot"
                    />
                  </svg>
                  {t("bookingSettings")}
                </Link>
              </li>
            </ul>
          </li>
        ) : (
          <li
            className={
              BOOKIN_ROUTES.includes(activeData) ||
                BOOKIN_ROUTES.includes(pathName)
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                BOOKIN_ROUTES.includes(activeData)
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() => {
                if (BOOKIN_ROUTES.includes(activeData)) {
                  setActiveData("")
                } else {
                  history.push("/bookings")
                  setActiveData("/bookings")
                }
              }}
              id="sidebarDropdown"
            >
              <img
                src={
                  BOOKIN_ROUTES.includes(activeData) ||
                    BOOKIN_ROUTES.includes(pathName)
                    ? Images.BookingFill
                    : bookings
                }
                className="img-fluid"
                alt=""
              />
              <span className="menusTxt">{t("bookings")} </span>
              <i class="caretIcon">
                <img src={sideArrow} className="sideArrowImg" alt="side"></img>
              </i>
            </Link>
            <ul
              className={
                BOOKIN_ROUTES.includes(activeData)
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/bookings"
                  className={
                    pathName == "/bookings"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img
                    src={awardover_}
                    className="submenuImg_"
                    alt="side"
                  ></img>
                  {t('overview')}
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/all-bookings"
                  className={
                    activeData.includes("/all-bookings") ||
                      pathName.includes("all-bookings")
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img
                    src={Images.allbookingsicon}
                    className="submenuImg_"
                    alt="book"
                  ></img>
                  {t('allBookings')}
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/calendar"
                  className={
                    activeData.includes("calendar") ||
                      pathName.includes("calendar")
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img
                    src={Images.CalendarOutline}
                    className="submenuImg_"
                    alt="all "
                  ></img>
                  {t('calendar')}
                </Link>
              </li>
              {/* <li className="sidebarInnerItems">
                  
                   <Link
                    to="/completed-bookings"
                    className={
                      pathName == "/completed-bookings"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    Appointments
                  </Link>
                </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/BookingSetting"
                  className={
                    activeData.includes("BookingSetting") ||
                      pathName.includes("BookingSetting")
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img
                    src={Images.settings}
                    className="submenuImg_"
                    alt="setting"
                  ></img>
                  {t('bookingSettings')}
                </Link>
              </li>
            </ul>
          </li>
        )}
        {/* Inventory list */}
        {/* <li className={activeData == '/inventory' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#"
                            className={activeData === "/inventory" ? "sidebarLinks dropdown-toggle show" : "sidebarLinks dropdown-toggle"}
                            onClick={() => activeData === "/inventory" ? setActiveData("") : setActiveData("/inventory")}
                            id="sidebarDropdown">
                            <img src={Images.inventory} className="img-fluid" alt="" />
                            <span className='menusTxt'>Inventory</span>
                            <i class="fa-solid fa-angle-down caretIcon"></i>
                        </Link>
                        <ul className={activeData === '/inventory' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/inventory"
                                    className={pathName == '/inventory' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/inventoryList"
                                    className={pathName == '/inventoryList' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    List of inventories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/lowstockInventory"
                                    className={pathName == '/lowstockInventory' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Low-Stock inventories
                                </Link>
                            </li>
                        </ul>
        </li> */}
        {/* Analytics routes */}
        <li
          className={
            ANALYTICS_ROUTES.includes(activeData) ||
              ANALYTICS_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              ANALYTICS_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {
              if (ANALYTICS_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/analytics")
                setActiveData("/analytics")
              }
            }}
            id="sidebarDropdown"
          >
            <img
              src={
                ANALYTICS_ROUTES.includes(activeData) ||
                  ANALYTICS_ROUTES.includes(pathName)
                  ? Images.analyticsFill
                  : analyticsIcon
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t('analytics')} </span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg" alt="sides"></img>
            </i>
          </Link>
          <ul
            className={
              ANALYTICS_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/analytics"
                className={
                  pathName == "/analytics"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_"></img>
                {t('overview')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Gross-Profits"
                className={
                  pathName == "/Gross-Profits"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.bluegrossicon_} className="submenuImg_"></img>
                {t('grossProfits')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-Revenue"
                className={
                  pathName == "/Total-Revenue"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.blueRevenue} className="submenuImg_"></img>
                {t('totalRevenue')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-Costs"
                className={
                  pathName == "/Total-Costs"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.blueCost} className="submenuImg_"></img>
                {t("totalCost")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-POS-Orders"
                className={
                  pathName == "/Total-POS-Orders"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.posorderBlue} className="submenuImg_"></img>
                {t("totalPosOrders")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-Delivery-Orders"
                className={
                  pathName == "/Total-Delivery-Orders"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.deliverorderBlue}
                  className="submenuImg_"
                ></img>
                {t("totalDeliveryOrders")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-Shipping-Orders"
                className={
                  pathName == "/Total-Shipping-Orders"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.shippingorderBlue}
                  className="submenuImg_"
                ></img>
                {t("totalShippingOrders")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-Orders"
                className={
                  pathName == "/Total-Orders"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.totalOrderblue} className="submenuImg_"></img>
                {t("totalOrders")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-Inventory"
                className={
                  pathName == "/Total-Inventory"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.inventoryBlue} className="submenuImg_"></img>
                {t("totalInventory")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/Total-Product-Sold"
                className={
                  pathName == "/Total-Product-Sold"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.productsoldBlue} className="submenuImg_"></img>
                {t("totalProductSold")}
              </Link>
            </li>
          </ul>
        </li>
     
        {/* user directory routes */}
        <li
          className={
            USER_ROUTES.includes(activeData) || USER_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              USER_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {
              if (USER_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/userList")
                setActiveData("/userList")
              }
            }}
            id="sidebarDropdown"
          >
            <img
              src={
                USER_ROUTES.includes(activeData) ||
                  USER_ROUTES.includes(pathName)
                  ? Images.userFill
                  : userDirectory
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t("userDirectory")}</span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg" alt="side"></img>
            </i>
          </Link>
          <ul
            className={
              USER_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/userList"
                className={
                  pathName === "/userList"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_" alt="side"></img>
                {t('overview')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/customer"
                className={
                  pathName === "/customer" ||
                    pathName === "/consumerProfile" ||
                    pathName === "/consumerOrder"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.UserIcon}
                  className="submenuImg_"
                  alt="side"
                ></img>
                {t('customers')}
              </Link>
            </li>
            {SUPPER_ADMIN() && (
              <li className="sidebarInnerItems">
                <Link
                  to="/users/age-verification"
                  className={
                    pathName === "/users/age-verification" ||
                      pathName === "/consumerProfile" ||
                      pathName === "/consumerOrder"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img src={Images.ageIcon} className="submenuImg_"></img>
                  {t('ageVerification')}
                </Link>
              </li>
            )}
            {SUPPER_ADMIN() && (
              <li className="sidebarInnerItems">
                <Link
                  to="/users/document-verification"
                  className={
                    pathName === "/users/document-verification" ||
                      pathName === "/consumerProfile" ||
                      pathName === "/consumerOrder"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img src={Images.staffActiveIcon} className="submenuImg_"></img>
                  {"Document Verification"}
                </Link>
              </li>
            )}
            {ADMIN()?.length > 0 && (
              <>
                <li className="sidebarInnerItems">
                  <Link
                    to="/user-list/manufacturer"
                    className={
                      pathName.split("/")[2] == "manufacturer" ||
                        pathName.split("/")[2] === "manufacturer"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t('manufacturers')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/user-list/whole_seller"
                    className={
                      pathName.split("/")[2] == "whole_seller" ||
                        pathName.split("/")[2] === "whole_seller"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t('wholeSalers')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/user-list/retailer"
                    className={
                      pathName.split("/")[2] == "retailer" ||
                        pathName.split("/")[2] === "retailer"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t('retailers')}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </li>
        {/* Wallet Routes */}
        <li
          className={
            TRANSACTION_ROUTES.includes(activeData) ||
              TRANSACTION_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              TRANSACTION_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {
              if (TRANSACTION_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/WalletMain")
                setActiveData("/WalletMain")
              }
            }}
            id="sidebarDropdown"
          >
            <img
              src={
                TRANSACTION_ROUTES.includes(activeData) ||
                  TRANSACTION_ROUTES.includes(pathName)
                  ? Images.walletFill
                  : walletIcon
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t("transactions")}</span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg"></img>
            </i>
          </Link>
          <ul
            className={
              TRANSACTION_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/WalletMain"
                className={
                  pathName == "/WalletMain"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_"></img>
                {t('overview')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/transections"
                className={
                  pathName == "/transections"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.alltransactionicon_}
                  className="submenuImg_"
                  alt="side"
                ></img>
                {t("allTransactions")}
              </Link>
            </li>
            {/* hide withdrawels =>>>>>>>>>>>. */}
            {/* <li className="sidebarInnerItems">
                <Link
                  to="/withdrawal"
                  className={
                    pathName == "/withdrawal" ||
                      pathName == "/instantbankTransfer"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <img src={Images.cashoutIcon} className="submenuImg_"></img>
                  Withdrawals
                </Link>
              </li> */}
            <li className="sidebarInnerItems">
              <Link
                to="WalletSetting"
                className={
                  pathName == "/WalletSetting" ||
                    pathName == "/instantbankTransfer"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.settings} className="submenuImg_" alt="dummpy"></img>
                {t("transactionSettings")}
              </Link>
            </li>
          </ul>
        </li>
        {/* payroll and staff routes */}
        <li
          className={
            POS_ROUTES.includes(activeData) || POS_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              POS_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {
              if (POS_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/myPos")
                setActiveData("/myPos")
              }
            }}
            id="sidebarDropdown"
          >
            <img
              src={
                STAFFANDPAYROLL_ROUTES.includes(activeData) ||
                  STAFFANDPAYROLL_ROUTES.includes(pathName)
                  ? Images.staffActiveIcon
                  : Images.staffInactiveIcon
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t("payroll&Staff")}</span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg " alt="side"></img>
            </i>
          </Link>
          <ul
            className={
              POS_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/myPos"
                className={
                  pathName == "/myPos"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_" alt="sode"></img>
                {t('overview')}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/posSales"
                className={
                  pathName == "/posSales"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.totalOrderblue}
                  className="submenuImg_ "
                  alt="side"
                ></img>
                {t("staffSales")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/createUser"
                className={
                  pathName == "/createUser"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.addNewUserImg}
                  className="submenuImg_"
                  alt="side"
                ></img>
                {t("createUser")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/createRole"
                className={
                  pathName == "/createRole"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.createRoles}
                  className="submenuImg_"
                  alt="side"
                ></img>
                {t("createRole")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/posUser"
                className={
                  pathName == "/posUser"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img
                  src={Images.authUserLogo}
                  className="submenuImg_"
                  alt="side"
                ></img>
                {t("teamMembers")}
              </Link>
            </li>
            {/* <li className='sidebarInnerItems'>
                                <Link to="/staffPayment"
                                    className={pathName == '/staffPayment' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Staff Payment
                                </Link>
                            </li> */}
          </ul>
        </li>
        {/* rewards */}
        {SUPPER_ADMIN() && <li
          className={
            REWARDS_ROUTES.includes(activeData) || REWARDS_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              REWARDS_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {
              if (REWARDS_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/rewards")
                setActiveData("/rewards")
              }
            }}
            aria-expanded="false"
            id="sidebarDropdown"
          >
            <img
              src={
                REWARDS_ROUTES.includes(activeData) ||
                  REWARDS_ROUTES.includes(pathName)
                  ? Images.rewardsActiveIcon
                  : Images.rewardsInactiveIcon
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t("reward")}</span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg" alt="side"></img>
            </i>
          </Link>
          {/* Start SidebarInnerMenus */}
          <ul
            className={
              REWARDS_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/rewards"
                className={
                  pathName === "/rewards"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_" alt="side"></img>
                {t("overview")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/all-rewards"
                className={
                  pathName === "/all-rewards"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.allrewards_} className="submenuImg_" alt="side"></img>
                {t("allRewards")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/reward-setting"
                className={
                  pathName === "/reward-setting"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={sideBarSettingIcon} className="submenuImg_" alt="side"></img>
                {t("rewardSettings")}
              </Link>
            </li>
            {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/inquaries"
                                    className={pathName === '/inquaries' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Inquaries
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/rfqMessage"
                                    className={pathName === '/rfqMessage' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    RFQ
                                </Link>
                            </li> */}
          </ul>
          {/* End SidebarInnerMenus */}
        </li>}
        {/* Gift card routes */}
        {SUPPER_ADMIN() && (
          <li
            className={
              GIFTCARD_ROTES.includes(activeData) || GIFTCARD_ROTES.includes(pathName)
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="/giftcard"
              className={
                GIFTCARD_ROTES.includes(activeData)
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                GIFTCARD_ROTES.includes(activeData)
                  ? setActiveData("")
                  : setActiveData("/giftcard")
              }
              id="sidebarDropdown"
            >
              <img
                src={
                  GIFTCARD_ROTES.includes(activeData) ||
                    GIFTCARD_ROTES.includes(pathName)
                    ? Images.giftCardActiveIcon
                    : Images.giftCardInactiveIcon
                }
                className="img-fluid"
                alt=""
              />
              <span className="menusTxt">{t("giftCard")} </span>
            </Link>
          </li>
        )}
        {/* marketing routes */}
        <li
          className={
            MARKETING_ROUTES.includes(activeData) ||
              MARKETING_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="#"
            className={
              MARKETING_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() => {
              if (MARKETING_ROUTES.includes(activeData)) {
                setActiveData("")
              } else {
                history.push("/marketingIndex")
                setActiveData("/marketingIndex")
              }
            }}
            id="sidebarDropdown"
          >
            <img
              src={
                MARKETING_ROUTES.includes(activeData) ||
                  MARKETING_ROUTES.includes(pathName)
                  ? Images.marketingFill
                  : marketing
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t("marketing")}</span>
            <i class="caretIcon">
              <img src={sideArrow} className="sideArrowImg" alt="side"></img>
            </i>
          </Link>
          <ul
            className={
              MARKETING_ROUTES.includes(activeData)
                ? "dropdown-menu sidebarDropMenus show"
                : "dropdown-menu sidebarDropMenus"
            }
            aria-labelledby="sidebarDropdown"
          >
            <li className="sidebarInnerItems">
              <Link
                to="/marketingIndex"
                className={
                  pathName == "/marketingIndex"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={awardover_} className="submenuImg_"></img>
                {t("overview")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/couponsListing"
                className={
                  pathName == "/couponsListing"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.discountTicket} className="submenuImg_"></img>
                {t("coupons&Promotion")}
              </Link>
            </li>
            <li className="sidebarInnerItems">
              <Link
                to="/campaignIndex"
                className={
                  pathName == "/campaignIndex"
                    ? "dropdown-item sidebarInnerLink active"
                    : "dropdown-item sidebarInnerLink"
                }
              >
                <img src={Images.campaignIcon} className="submenuImg_"></img>
                {t("campaign")}
              </Link>
            </li>
          </ul>
        </li>
        {/* more settings */}
        <li
          className={
            activeData === "/moresettingIndex" ||
              pathName === "/moresettingIndex"
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="/moresettingIndex"
            className={
              activeData === "/moresettingIndex"
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() =>
              activeData === "/moresettingIndex"
                ? setActiveData("")
                : setActiveData("/moresettingIndex")
            }
            id="sidebarDropdown"
          >
            <img
              src={
                activeData === "/moresettingIndex" ||
                  pathName === "/moresettingIndex"
                  ? Images.settingFill
                  : settings
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t("moreSettings")}</span>
          </Link>
        </li>
        {/* support */}
        <li
          className={
            SUPPORT_ROUTES.includes(activeData) ||
              SUPPORT_ROUTES.includes(pathName)
              ? "sidebarItems dropdown active"
              : "sidebarItems dropdown"
          }
        >
          <Link
            to="/support"
            className={
              SUPPORT_ROUTES.includes(activeData)
                ? "sidebarLinks dropdown-toggle show"
                : "sidebarLinks dropdown-toggle"
            }
            onClick={() =>
              SUPPORT_ROUTES.includes(activeData)
                ? setActiveData("")
                : setActiveData("/support")
            }
            id="sidebarDropdown"
          >
            <img
              src={
                SUPPORT_ROUTES.includes(activeData) ||
                  SUPPORT_ROUTES.includes(pathName)
                  ? Images.supportFill
                  : supportIcon
              }
              className="img-fluid"
              alt=""
            />
            <span className="menusTxt">{t("support")}</span>
          </Link>

          {/* <ul
              className={
                activeData === "/support" ||
                activeData === "/supportTicket" ||
                activeData === "/supportCall" ||
                activeData === "/supportChat" ||
                activeData === "/supportEmail"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/support"
                  className={
                    pathName == "/support"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#8D99D2"
                      className="circledot"
                    />
                  </svg>
                  Support
                </Link>
              </li>
              <li className='sidebarInnerItems'>
                                <Link to="/agents"
                                    className={pathName == '/agents' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Agents
                                </Link>
                            </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/supportTicket"
                  className={
                    pathName == "/supportTicket" ||
                    pathName?.split("/")[1] === "supportticketDetail"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#8D99D2"
                      className="circledot"
                    />
                  </svg>
                  My Tickets
                </Link>
              </li>

              <li className='sidebarInnerItems'>
                                <Link to="/supportCall"
                                    className={pathName == '/supportCall' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Calls
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/supportChat"
                                    className={pathName == '/supportChat' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Chats
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/supportEmail"
                                    className={pathName == '/supportEmail' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Emails
                                </Link>
                            </li>
            </ul> */}
        </li>
        {/* catalogs */}
        {/* <li
            className={
              activeData == "/catalogs"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="/catalogs"
              className={
                activeData === "/catalogs"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/catalogs"
                  ? setActiveData("")
                  : setActiveData("/catalogs")
              }
              id="sidebarDropdown"
            >
              <img src={catalogs} className="img-fluid" alt="" />
              <span className="menusTxt">Catalogs </span>
            </Link>
          </li> */}

      </ul>
      {/* <ul className='sidebarMenus navbar_overlay_content'>
            </ul> */}
      {/* <div  className="main-sidebar" id="myNav" >
                <div className="vertical_top" id="vertical_top">
                    <a className="closebtn2"
                        onClick={() => {
                            closeSide();
                            closeActions()
                        }}
                    ><i className="las la-times"></i></a>
                    <span className="expanded_tab"><i className="fas fa-arrows-alt-h"></i></span>
                </div>
            </div> */}

      <div className="sidbarfixedMenus">
        <li
          className="sidebarItems"
          onClick={() => {
            userLogout();
          }}
        >
          <Link
            to="#"
            className="sidebarLinks"
            onClick={() => setActiveData("power")}
          >
            <img src={logOut} className="img-fluid" alt="" />
            <span className="menusTxt LogoutOption fw500">{t("logout")}</span>
          </Link>
        </li>
        <li></li>
      </div>
    </aside>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  nav: state.nav,
});
const mapDispatchToProps = (dispatch) => ({
  // logout: () =>  dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
