import React, { useState, useEffect } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getCategoriesAsync,
  getServicesAsync,
} from "../../containers/dashboard/dashboardSlice";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";

const ProductSidebar = (props) => {
  const { t } = useTranslation();
  const { selectedCategory, setSelectedCategory } = props;
  const [selectedService, setSelectedService] = useState(null);
  const [subCategoryIds, setSubCategoryIds] = useState([]);

  const [activeData, setActiveData] = useState("");

  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingSubCategories, setLoadingSubCategories] = useState(false);


  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);

  useEffect(() => {
    getServices();
  }, []);

  // Fetch Services
  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "product",
        check_product_existance: false,
      },
    };

    setLoadingServices(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoadingServices(false);
        let filterResult = obj?.payload?.data?.map((item) => ({
          value: item?.id,
          label: item?.name,
        }));
        setServices(filterResult);
      })
      .catch(() => {
        setLoadingServices(false);
      });
  };

  // Fetch categories when a service is selected
  const handleServiceChange = (serviceId) => {
    if (selectedService === serviceId) {
      setSelectedService(null);
      setCategories([]);
      setSelectedCategory(null);
      setSubCategories([]);
      return;
    }

    setSelectedService(serviceId);
    setCategories([]);
    setSelectedCategory(null);
    setSubCategories([]);

    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: serviceId,
        main_category: true,
        check_product_existance: false,
      },
    };

    setLoadingCategories(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoadingCategories(false);
        setCategories(obj?.payload?.data || []);
      })
      .catch(() => {
        setLoadingCategories(false);
        setCategories([]);
      });
  };

  // Fetch subcategories when a category is selected
  const handleCategoryChange = (categoryId) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
      setSubCategories([]);
      return;
    }

    setSelectedCategory(categoryId);
    setSubCategories([]);

    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: categoryId,
        need_subcategory: true,
        check_product_existance: false,
      },
    };

    setLoadingSubCategories(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoadingSubCategories(false);
        setSubCategories(obj?.payload?.data || []);
      })
      .catch(() => {
        setLoadingSubCategories(false);
        setSubCategories([]);
      });
  };

  const handleSubcategoryChange = (subCategoryId) => {
    let updatedIds = subCategoryIds.includes(subCategoryId)
      ? subCategoryIds.filter((id) => id !== subCategoryId) // Remove if already selected
      : [...subCategoryIds, subCategoryId]; // Add if not selected

    setSubCategoryIds(updatedIds);
    props.subCategoryId(updatedIds);
  };

  return (
    <div className="productSidebar">
      <aside
        className={`vertical-tab${props?.nav?.open_nav ? " slideOpen" : ""}`}
        id="myNav"
      >
        <Link to="/wallet" className="brandLogo">
          <img
            src={Images.SidebarLogo}
            className="img-fluid"
            alt="Sidebar Logo"
          />
        </Link>

        <div className="sidebarMenus navbar_overlay_content_">
          <Accordion activeKey={selectedService}>
            {/* SERVICES SECTION */}
            {loadingServices ? (
              <Spinner animation="border" size="sm" />
            ) : (
              services.length > 0 &&
              services.map((service) => (
                <Accordion.Item eventKey={service.value} key={service.value}>
                  <Accordion.Header
                    className={
                      selectedService === service.value ? "active" : ""
                    }
                    onClick={() => handleServiceChange(service.value)}
                  >
                    {service.label}
                  </Accordion.Header>
                  <Accordion.Body className="p-0 categoryMenu">
                    {/* CATEGORIES SECTION */}
                    <Accordion activeKey={selectedCategory}>
                      {loadingCategories ? (
                        <Spinner animation="border" size="sm" />
                      ) : categories.length > 0 ? (
                        categories.map((category) => (
                          <Accordion.Item
                            eventKey={category.id}
                            key={category.id}
                          >
                            <Accordion.Header
                              className={
                                selectedCategory === category.id ? "active" : ""
                              }
                              onClick={() => handleCategoryChange(category.id)}
                            >
                              {category.name}
                            </Accordion.Header>
                            <Accordion.Body>
                              {/* SUBCATEGORIES */}

                              {loadingSubCategories ? (
                                <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList"></span>
                              ) : (
                                <>
                                  {subCategories?.length > 0 ? (
                                    <>
                                      <ul className="accordionInnerMenus">
                                        {subCategories?.map((val, index) => {
                                          return (
                                            <li
                                              className="accordionInnerItems"
                                              key={index}
                                            >
                                              <button
                                                // to="#"
                                                className={`accordionInnerLinks  sidebarInnerBtn ${
                                                  subCategoryIds.includes(
                                                    val?.id
                                                  )
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setActiveData(val?.name);
                                                  handleSubcategoryChange(
                                                    val?.id
                                                  );
                                                }}
                                              >
                                                {val?.name}
                                              </button>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))
                      ) : (
                        <span className="noData">{t('noCategories')}</span>
                      )}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            )}
          </Accordion>
        </div>
      </aside>
    </div>
  );
};

export default ProductSidebar;
