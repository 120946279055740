import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectLoginAuth } from '../../auth/authSlice';
import { updateSellerInfoApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

const EditshipModal = ({locations,close}) => {
    const { t } = useTranslation();
    const [locationDetails, setlocationDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locationIndex, setlocationIndex] = useState(0);
    const auth = useSelector(selectLoginAuth);
    const dispatch=useDispatch();
    const handleLocation=(index)=>{
        setlocationIndex(index);
        let location=locations[Number(index)];
        if(location){
            setlocationDetails({
                phone_no:location?.phone_code,
                phone_code:location?.phone_code,
                format_address:location?.format_address||location?.street_address||"",
                apt:location?.apt,
                state:location?.state,
                city:location?.city,
                zipcode:location?.zipcode,
                email:location?.email,
                id:location?.id
            });
        }
    }
    const [getAllLocations, setgetAllLocations] = useState([]);
    useEffect(()=>{
        if(Array.isArray(locations)){
            let locationNames=locations?.map((location)=>{
                return {
                    name:location.format_address,
                    id:location.id
                }
            });
            if(locations?.length){
                handleLocation(0);
            }
        setgetAllLocations(locationNames);
        }
    },[locations])
    const handleUpdate=async(e)=>{
        e.preventDefault();
        if (loading) return;
    let isFiledEmpty = checkIfFieldEmpty(locationDetails);
    if(isFiledEmpty){
        toast.error(`${isFiledEmpty} is required`);
        return;
    }
        setLoading(true);
        try {
            const params={
                postData:{
                    ...locationDetails
                },
                tokenData:auth?.payload?.token,
            };
            const data=await dispatch(updateSellerInfoApiAsync(params))
                  .then(unwrapResult);
            if(data){
                console.log("Updated");
                handleDiscard();
            }
        } catch (error) {
            // toast.error("Unable to update location");
        }finally{
            setLoading(false);
        }

    }
    const checkIfFieldEmpty = (obj) => {
        let keyName = null;
        for (let key in obj) {
          if (obj[key] instanceof Object) continue; // Skip nested objects
          if (obj[key] == null || obj[key] === "") {
            keyName = key;
            break;
          }
        }
        return keyName;
      };
    const handleDiscard=()=>{
        close();
    }
    useEffect(()=>{
        console.log(locationDetails,"check details");
    },[locationDetails])
    return (
        <div className='editshipModal_'>
            <form onSubmit={handleUpdate} className="shipeditModal_form">
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("selectLocation")}</label>
                    <select
                        autoComplete="new-password"
                        className="customform-control select"
                        value={locationIndex}
                        onChange={(event)=>{
                            handleLocation(event.target.value);
                        }}
                    >
                        {
                            getAllLocations.map((location, index) => (
                                <option key={index} value={index}>{location?.name}</option>
                            ))
                        }
                        {/* <option value="operations" disabled>
                            Store LLC
                        </option>
                        <option value="0">Store LLC</option>
                        <option value="1">Online</option>
                        <option value="2">Both</option> */}
                    </select>
                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("streetAdd")}</label>
                    <div className='from-add_'>
                        <input
                            type="text"
                            disabled={locationDetails==null}
                            value={locationDetails?.format_address||''}
                            name='format_address'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                            className="customform-control ps-5"
                            autoComplete="new-password"
                            placeholder="Street, Ave, West..."
                        />
                        <span className='locationicon_'><img className='locicon_' src={Images.loctionicn} /></span>
                    </div>
                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("apartmentSuite")}</label>
                    <div className='from-add_'>
                        <input
                            type="text"
                            className="customform-control ps-5"
                            // autoComplete="new-password"
                            disabled={locationDetails==null}
                            value={locationDetails?.apt||''}
                            name='apt'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                            placeholder="Building, Interior"
                        />
                        <span className='locationicon_'><img className='locicon_' src={Images.loctionicn} /></span>
                    </div>
                </div>
                <div className='row'>
                    <div className="mb-4 form-group col-md-6">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("state")}</label>
                        <input
                            className="customform-control id_password"
                            placeholder="State"
                            autoComplete="new-password"
                            type="text"
                            disabled={locationDetails==null}
                            value={locationDetails?.state||''}
                            name='state'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}

                        />
                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group col-md-6">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("city")}</label>
                        <input
                            className="customform-control id_password"
                            placeholder="City"
                            autoComplete="new-password"
                            type="text"
                            disabled={locationDetails==null}
                            value={locationDetails?.city||''}
                            name='city'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                        />

                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                </div>
                <div className="mb-4 form-group col-md-6">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("zipCode")}</label>
                    <input
                        type="number"
                        className="customform-control"
                        autoComplete="new-password"
                        placeholder="Zip code"
                        disabled={locationDetails==null}
                        value={locationDetails?.zipcode||''}
                        name='zipcode'
                        onChange={(event)=>{
                            let key=event.target.name;
                            let value=event.target.value;
                            setlocationDetails({...locationDetails,[key]:value});
                        }}
                    />
                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("phoneNumber")}</label>
                    {/* <div style={{ display: "flex" }}>
                        <PhoneInput
                            className=""
                            autoComplete="new-password"
                            country="us"
                            disabled={locationDetails===null}
                            disabled={locationDetails==null}
                            value={locationDetails.state}
                            name='state'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                            placeholder="Phone no."
                        // onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}

                        />

                    </div> */}
                    <div style={{ display: "flex" }}>
    <PhoneInput
        country="us"
        disabled={locationDetails === null}
        value={locationDetails?.phone_code+""+ locationDetails?.phone_no}
        name="phone_no"
        onlyCountries={["us", "in"]}
        autoComplete="off"
        onChange={(value,data) => {
            let phoneCode = data.dialCode;
            let phoneNumber = value.slice(data.dialCode.length);
            setlocationDetails({
                ...locationDetails,
                phone_no: phoneNumber, // Updating the phone number field
                phone_code:phoneCode
            });
        }}
        placeholder="Phone no."
    />
</div>

                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">{t("emailAddress")}</label>

                    <div className='from-add_' >
                        <input
                            type="email"
                            className="customform-control ps-5"
                            autoComplete="new-password"
                            placeholder="Email"
                            disabled={locationDetails==null}
                        value={locationDetails?.email||''}
                        name='email'
                        onChange={(event)=>{
                            let key=event.target.name;
                            let value=event.target.value;
                            setlocationDetails({...locationDetails,[key]:value});
                        }}

                        />
                        <span className='locationicon_'><img className='locicon_' src={Images.emailicn_} /></span>

                        {/* {phoneData?.is_email_verified === true ? <img style={{ width: "20px" }} src={check} alt="" /> : <></>} */}
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='formbuttons_'>
                        <button
                        
                        disabled={loading} onClick={handleDiscard} className='greyBtn_' type='submit'>{t("discard")}</button>
                        <button disabled={locationDetails===null||loading}  className='blueButton_' type='submit'>{t("update")}</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default EditshipModal