import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { customerNotificationOptions, staffNotificationOptions } from './notification.constant';
import Loader from '../../../components/UI/Loader/Loader';
import { useTranslation } from 'react-i18next';

const StaffNotification = ({ userDetails, setmoreSetting, userSetting, updateSetting,isSettingUpdating }) => {
    const { t } = useTranslation();
    const [userSettingDetails, setuserSettingDetails] = useState(null)
    useEffect(() => {
        setuserSettingDetails(userDetails)
    }, [userSetting])
    const [notificationOptions, setNotificationOptions] = useState(staffNotificationOptions)
    return (
        <div className='customerNotiication_ additionalsetmain_ p-0 position-relative'>
            {
                isSettingUpdating&&<Loader/>
            }
            <h3 onClick={() => {
                setmoreSetting("Notification")
            }} className='headblue18 mb-5'><img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' /> {t('staffNotification')} </h3>
            {
                notificationOptions?.map((customOptions, index) => (

                    <div key={index} className={`allNotofication_ ps-4 ${index >= 1 ? 'mt-5' : ''}`}>
                        <h3 className='headblue18'>{
                            t(customOptions?.languageTitle)
                        }</h3>
                        <div className='notification_'>
                            {
                                customOptions?.options?.map((option, indexs) => (
                                    <div key={indexs} className='toggleNotification_ mb-2'>
                                        <h5 className='headingblue14_'>{
                                            t(option?.languageTitle)
                                        }</h5>
                                       
                                        <input checked={
                                            userSetting?.[customOptions?.key]?.[option?.key] || false
                                        } type="checkbox" id="toggle" name="toggle"
                                            onChange={(event) => {
                                                let checked = event.target.checked
                                                let data = {
                                                    [customOptions?.key]: {
                                                        [option?.key]: checked
                                                    }
                                                }
                                                updateSetting(data)
                                            }}
                                        />
                                    </div>

                                ))
                            }

                        </div>
                    </div>
                ))

            }

        </div>
    )
}

export default StaffNotification