import React, { useEffect, useState } from 'react'
import * as Images from "../../../utilities/images";
import ReplyticketModal from './replyticketModal';
import CustomModal from '../../components/shared/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { closeTicketAsync, createNewTicketCommentAsync, getSingleSupportDataApiAsync, getSupportTicketCountApiAsync } from '../dashboard/dashboardSlice';
import { selectLoginAuth } from '../auth/authSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { io } from 'socket.io-client';
import { getMessageheadsAsync, getMessageListAsync } from '../message/messageSlice';
import { useTranslation } from 'react-i18next';

const SupportTicketinner = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const history = useHistory()
  const query = new URLSearchParams(window.location.search)
  const auth = useSelector(selectLoginAuth)
  const [comment, setComment] = useState("")
  const [key, setKey] = useState(Math.random());
  const [supportData, setSupportData] = useState("")
  const [userData, setUserData] = useState("")
  const [statisticsData, setStatisticData] = useState({})
  const support_id = query.get("support_id");
  const [messagesData, setMessages] = useState([]);
  const [messageHeadId, setMessageHeadId] = useState('');
  const [recipientId, setRecipientId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const seller_id = auth?.payload?.uniqe_id;
  const ticketId = support_id;
  const [socket, setSocket] = useState(null)

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };

  useEffect(() => {
    let socketCon = io(
      `https://staging-apichat.jobr.com:8056?userId=${auth?.payload?.uniqe_id}`,
      {
        path: '/api/v1/connect',
      }
    );
    setSocket(socketCon)
  }, [])

  useEffect(() => {
    if(!socket) return;
    setIsLoading(true);
    socket.on('connect', () => {
      console.log('Connected');
      getMessages();
    });
   
    socket.on('send_message', msg => {
      setMessageHeadId(msg?.data?.chathead_id);
    });
       
    socket.on('get_messages', message => {
      console.log("Dataaaaaaa>>>>>>>>>>>>>>>>>$$$$$$$$$>>>>>>>>>", message, supportData?.user_id )
      if (message?.data?.data?.length > 0) {
        const sender_id = 
                message?.data?.data?.[0]?.sender_id == supportData?.user_id 
                ? message?.data?.data?.[0]?.recipient_id : 
                message?.data?.data?.[0]?.sender_id;

        console.log({ sender_id }, "this is sender id =>>>>>>>>>>")
        setRecipientId(sender_id);
        setMessageHeadId(message?.data?.data?.[0]?.chathead_id);

        const arr = message?.data?.data?.map(item => ({
          _id: item?._id,
          text: item?.content,
          createdAt: item?.createdAt,
          user: {
            _id: item?.sender_id,
          },
        })) || [];

        setMessages(arr);
      } else {
        // No messages found, set loading to false
        setMessages([]);
      }
      setIsLoading(false);
    });

    socket.on('disconnect', () => {
      console.log('disconnected');
    });

    // return () => {
    //   socket.off('get_messages');
    //   socket.off('send_message');
    //   socket.disconnect();
    // };
  }, [socket, supportData]);

  useEffect(() => {
    if(messageHeadId) {
      socket.emit("join_room_user", {
        chathead_id: messageHeadId,
      });
      socket.emit("personal_room", { userId: seller_id });
      socket.on("join_room_user", (data) => {
        console.log("joineed room =>", data)
      })
      socket.emit("get_messagehead", {
        user_id: seller_id,
        headtype: "inquiry",
        page: 1,
        limit: 10
      })
    }
    
  }, [messageHeadId])

  useEffect(() => {
    if(!socket) return;
    socket.on(`JobrUser_${messageHeadId}_room`, data => {
      const newMsg = {
        _id: data?.data?._id,
        text: data?.data?.content,
        createdAt: data?.data?.createdAt,
        user: {
          _id: data?.data?.sender_id,
        },
      };
      console.log(data?.data, 'when room idid');
      setMessages(prev => [newMsg, ...prev]);
    });
    return () => {
      socket.off(`JobrUser_${messageHeadId}_room`);
    };
  }, [messageHeadId]);


  const getMessages = () => {
    socket.emit('get_messages', {
      id: ticketId.toString(),
      idtype: 'ticket_id',
    });
  };

  const onSend = () => {
    const params = {
      recipient_id: supportData?.user_id?.toString(),
      media_type: 'text',
      content: comment,
      chatHeadType: 'inquiry',
      sender_id: recipientId !== "" ? recipientId : auth?.payload?.uniqe_id,
      ticket_id: ticketId.toString(),
    };
    console.log("paramos0", params)
    socket.emit('send_message', params);
    createTicketComment()
  };

  const seller_details = {
    seller_id: seller_id,
    profile_photo: auth?.payload?.user?.user_profiles?.profile_photo,
    fullName: [
      auth?.payload?.user?.user_profiles?.firstname,
      auth?.payload?.user?.user_profiles?.lastname
    ].filter(Boolean).join(" ")
  }

  const getTickedDataById = () => {
    const data = {
      tokenData: auth?.payload?.token,
      postData: {
        id: support_id
      }
    }
    dispatch(getSingleSupportDataApiAsync(data))
      .then(unwrapResult)
      .then(res => {
        setSupportData(res?.payload || {})
        setUserData(res?.payload?.user_detail || {})
        getUserTicketStatistics(res?.payload?.user_id)
        // getChatHeadId(res?.payload?.user_id)
      }).catch(
        error => {

        }
      )
  }

  const getUserTicketStatistics = (user_id) => {
    const data = {
      tokenData: auth?.payload?.token,
      postData: {
        user_id: user_id
      }
    }
    dispatch(getSupportTicketCountApiAsync(data))
      .then(unwrapResult)
      .then(res => {
        setStatisticData(res?.payload)
      }).catch(e => {
        console.log(e)
      })
  }
  const createTicketComment = () => {
    // if (comment === "") {
    //   toast.error("comment cannot be empty")
    // }
    // if (comment.length < 20) {
    //   toast.error("Please add least 20 characters")
    // }
    const data = {
      tokenData: auth?.payload?.token,
      postData: {
        support_id: support_id,
        comment: comment
      }
    }
    dispatch(createNewTicketCommentAsync(data))
      .then(unwrapResult)
      .then(res => {
        getTickedDataById()
      }).catch(e => {
        console.log(e)
      })
  }

  // close  a ticket
  const closeTicket = () => {
    const data = {
      tokenData: auth?.payload?.token,
      postData: {
        id: support_id
      }
    }
    dispatch(closeTicketAsync(data))
      .then(unwrapResult)
      .then(res => {
        getTickedDataById();
      }).catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (support_id) {
      getTickedDataById()
      // getUserTicketStatistics()
    }
  }, [])

  const fullName = [
    userData?.user_profiles?.firstname,
    userData?.user_profiles?.lastName
  ].filter(Boolean).join(" ");

  const latestSupportComment = messagesData.filter(
    item =>
      item?.user?._id == supportData?.user_id
  ).sort((a, b) => a - b)?.[0];
 
  return (
    <>
      <div className='supportTicketinner jbrTransfer '>
        <div className='chartsOuter jbrHeight mt-0'>
          <div className='shipprofileHead_'>
            <h2 className='Innerhead' onClick={() => history.push("/mySupport")}>
              <img className='arrowicon_' src={Images.leftArrowColored} alt='baxk' />
              #{supportData?.track_number}
            </h2>
            <div className='right_shippingbtn_'>
              <button className='discardBtn_' type='button' onClick={
                () => supportData?.status_id !== 3 ? "" : closeTicket()
              }>
                {supportData?.status_id !== 3 ? t("closeTicket") : t("closeTicket")}

              </button>
              <button
                style={{
                  opacity: supportData?.status_id !== 3 ? "0.4" : ""
                }}
                className='addNew_'
                type='button' onClick={() => {
                  if (supportData?.status_id !== 3) return "";
                  handleOpenModal("replyticketModal")
                  setKey(Math.random());
                }}>{t('replyTicket')}</button>
            </div>
          </div>
          <div className='innerTicketcontent mt-4'>
            <div className='row'>
              <div className='col-md-8'>
                <div className='ticketListsupport_'>
                  {messagesData?.length > 0 ? messagesData?.map(item => {
                    const isUserMessage = item?.user?._id != supportData?.user_id;
                    const profile_photo = isUserMessage ? seller_details?.profile_photo : userData?.user_profiles.profile_photo;
                    const full_name = isUserMessage ? seller_details?.fullName : fullName;
                    const isMe = isUserMessage ? "" : "Support";
                    return (
                      <div className='supportQuery_'>
                        <div className='infoaboutPerson_ mb-4'>
                          <img className='' src={
                            profile_photo || Images.userImg_
                          } alt='user' />
                          <article>
                            <h4 className='headingblue14_'>{full_name}</h4>
                            <h5 className='lighttext14 mb-0 mt-1'>{isMe}</h5>
                          </article>
                        </div>
                        <h5 className='contentbalck_'>{item?.text}</h5>
                        <h5 className='datetimeText_'>
                          {moment(item?.createdAt).format("DD MMM, YYYY | HH:MM a")}
                        </h5>
                      </div>
                      // </div>
                    )
                  }) : t('noConversation')
                  }
                </div>
              </div>
              <div className='col-md-4'>
                <div className='ticketsupportRight_'>
                  <div className='infoaboutPerson_ mb-3'>
                    <img
                      className='b-circle'
                      width={48}
                      height={48}
                      src={userData?.user_profiles?.profile_photo || Images.userImg_}
                      alt='user-2' />
                    <article>
                      <h4 className='headingblue14_'>
                        {fullName}
                      </h4>
                    </article>
                  </div>
                  <div className='personalInfouser_'>
                    <h5 className='personalinfoHead'><img className='' src={Images.darkbluePhoneIcon} alt='phone number' />
                      {userData?.user_profiles?.full_phone_number}
                    </h5>
                    <h5 className='personalinfoHead'><img className='' src={Images.emailLogo} alt='email icon' />
                      {userData?.email}
                    </h5>
                    <h5 className='personalinfoHead'><img className='' src={Images.darkblueLocationIcon} alt='Locations' />
                      {userData?.user_profiles?.current_address?.format_address}
                    </h5>
                  </div>
                  <div className='totalTicket_ mt-4'>
                    <h5 className='personalinfoHead'>{t('totalTicket')}: {statisticsData?.total_tickets}</h5>
                    <div className='innertotalCount'>
                      <div className='solveresponse'>
                        <h5 className='headingblue14_'>{t('solved')}: {statisticsData?.solved_tickets}</h5>
                        <h5 className='headingblue14_'>{t('cancelled')}: {statisticsData?.cancel_tickets}</h5>
                      </div>
                      <div className='solveresponse'>
                        <h5 className='headingblue14_'>{t('open')}: {statisticsData?.open_tickets}</h5>
                        <h5 className='headingblue14_'>{t('unRespond')}: {statisticsData?.unrespond_tickets}</h5>
                      </div>
                    </div>
                  </div>
                  {/* <div className='assignedAgent_ mt-4'>
                    <h4 className='expectedHeading mb-3'>Assigned Agent:</h4>
                    <div className='infoaboutPerson_ mb-3'>
                      <img className='' src={Images.supportuser2} />
                      <article>
                        <h4 className='headingblue14_'>Jessica Méndez</h4>
                        <h5 className='lighttext14 mb-0 mt-1'>13 Jun, 2022 | 12: 25 pm</h5>
                      </article>
                    </div>

                    <h4 className='expectedHeading mt-4 mb-3'>Also on this ticket:</h4>
                    <div className='infoaboutPerson_ mb-3'>
                      <img className='' src={Images.supportuser2} />
                      <article>
                        <h4 className='headingblue14_'>Jessica Méndez</h4>
                        <h5 className='lighttext14 mb-0 mt-1'>13 Jun, 2022 | 12: 25 pm</h5>
                      </article>
                    </div>
                    <div className='infoaboutPerson_ mb-3'>
                      <img className='' src={Images.supportuser2} />
                      <article>
                        <h4 className='headingblue14_'>Jessica Méndez</h4>
                        <h5 className='lighttext14 mb-0 mt-1'>13 Jun, 2022 | 12: 25 pm</h5>
                      </article>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "replyticketModal"
            ? "replyticketModal"

            : ""
        }
        child={
          modalDetail.flag === "replyticketModal" ? (
            <ReplyticketModal
              userData={userData}
              comment={comment}
              setComment={setComment}
              onSend={onSend}
              createTicketComment={createTicketComment}
              latestSupportComment={{
                ...latestSupportComment,
                profile_photo:
                  latestSupportComment?.user_id == seller_id
                    ? seller_details.profile_photo
                    : userData?.user_profiles?.profile_photo,
                full_name: latestSupportComment?.user_id == seller_id
                  ? seller_details.fullName : fullName
              }}
              close={() => handleOnCloseModal()}
            />
          ) :
            (
              <></>
            )
        }
        header={
          <>
            {modalDetail.flag === "replyticketModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-2 ">
                      {t('replyTo')} #XD1256P67
                    </h3>

                  </div>
                  <img
                    className='modalcross'
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            )
              : (
                ""
              )}
          </>
        }

        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default SupportTicketinner