import React, { useEffect, useRef, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import AddOptionsModal from "../product/addOptionsModal";
import CreateVariationsModal from "../product/createVariationsModal";
import BulkVarientionModal from "../product/bulkVarientionModal";
import CustomModal from "../../components/shared/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { toast } from "react-toastify";
import { editProductApiAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import AddProductHeader from "../product/AddProductHeader";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import { Dropdown } from "react-bootstrap";
import ChartImageUploader from "../service/chartImageUploader";
import EditProductDetail from "./editProductDetail";
import ProductImageAdd from "./productImageAdd";
import ConfirmationModal from "../../components/UI/Product/confirmationModal";
import EditVariantionModal from "../editProducts/editVariantionModal";
import { formValidator } from "./formValidator";
import { getColorName } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";

const EditProduct = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const toastId = useRef(null);
  const location = useLocation();
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();

  const selectedProductDtails = location?.state;
  const allImages = selectedProductDtails?.product_images ?? [];
  const productName = selectedProductDtails?.name;
  const backRoute = localStorage.getItem("backButtonRoute");
  const [isAdult, setIsAdult] = useState(
    selectedProductDtails?.is_adult || false
  );

  const [productId, setProductId] = useState(selectedProductDtails?.id);
  const [productDetails, setProductDetails] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [storeCheck, setStoreCheck] = useState(true);
  const [marketCheck, setMarketCheck] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [costPrice, setcostPrice] = useState("");
  const [productDescription, setProductDescription] = useState(
    selectedProductDtails?.description || ""
  );
  console.log(selectedProductDtails, "productDetails");
  const [imageDetails, setImageDetails] = useState({});
  const [sku, setSku] = useState(
    selectedProductDtails?.supplies[0]?.supply_variants[0].sku || ""
  );
  const [allAddress, setAllAddress] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [chartImage, setChartImage] = useState("");
  const [dimensions, setDimensions] = useState({
    weight:
      selectedProductDtails?.supplies[0]?.supply_variants[0]?.weight || "",
    length: selectedProductDtails?.length || "",
    breadth: selectedProductDtails?.breadth || "",
    height: selectedProductDtails?.height || "",
  });
  const [type] = useState("physical");
  const [unitType, setUnitType] = useState(
    selectedProductDtails?.supplies[0]?.quantity_unit || "per_unit"
  );
  const [barCode, setBarCode] = useState(
    selectedProductDtails?.supplies[0]?.supply_variants[0].barcode || ""
  );
  const [loading1, setLoading1] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);

  const [isTax, setIsTax] = useState(
    selectedProductDtails?.is_taxable || false
  );
  const [saleSame, setSaleSame] = useState(false);
  const [differentSale, setDifferentSale] = useState(false);
  const [sellerAddressIds, setSellerAddressIds] = useState([]);

  const [selectedVariants, setSelectedVariants] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [quantityReorder, setQuantityReorder] = useState("");
  const [prices, setPrices] = useState([
    {
      app_name: "pos",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
    {
      app_name: "b2c",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
  ]);
  console.log(prices, "prices");

  const [posPrice, setPosPrice] = useState(null);
  const [marketplacePrice, setMarketplacePrice] = useState(null);
  const [posProfit, setPosProfit] = useState(null);
  const [posMargin, setPosMargin] = useState(null);
  const [marketplaceProfit, setMarketplaceProfit] = useState(null);
  const [marketplaceMargin, setMarketplaceMargin] = useState(null);
  const [structureVariants, setStructureVariants] = useState([]);

  console.log(structureVariants, "structureVariants");

  const [rowData, setRowData] = useState([
    {
      id: 1,
      activePlan: "list",
      displayName: "",
      productTags: [],
    },
  ]);

  const [tempRowData, setTempRowData] = useState([
    {
      id: 1,
      activePlan: "list",
      displayName: "",
      productTags: [],
    },
  ]);
  const [attributes, setAttributes] = useState([]);

  const [selectedCombinations, setSelectedCombinations] = useState([]); // State to store selected combinations
  console.log(selectedCombinations, "selectedCombinations");
  const [newCombinations, setNewCombinations] = useState([]);
  const [attributeShow, setAttributeShow] = useState(false);
  const [deletedAttributeIds, setDeletedAttributeIds] = useState([]);
  const [variants, setVariants] = useState([]);

  // Mapping for delivery option codes
  const deliveryOptionMap = {
    1: "delivery",
    2: "reservation",
    3: "pickup",
    4: "shipping",
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleInputChange = (groupIdx, variantIdx, field, value) => {
    setStructureVariants((prevVariants) => {
      return prevVariants.map((variantGroup, gIdx) => {
        if (gIdx !== groupIdx) return variantGroup; // Keep other groups unchanged

        return {
          ...variantGroup,
          variants: variantGroup.variants.map((variant, vIdx) => {
            if (vIdx !== variantIdx) return variant; // Keep other variants unchanged

            // Helper function to ensure price object exists
            const ensurePriceObject = (prices, appName) => {
              const existingPrice = prices.find((p) => p.app_name === appName);
              if (!existingPrice) {
                return [
                  ...prices,
                  {
                    app_name: appName,
                    selling_price: "",
                    margin_percentage: "",
                  },
                ];
              }
              return prices;
            };

            // Helper to calculate margin
            const calculateMargin = (sellingPrice, costPrice) => {
              if (costPrice > 0) {
                return (((sellingPrice - costPrice) / costPrice) * 100).toFixed(
                  2
                );
              }
              return "0.00";
            };

            if (field === "pos_price" || field === "marketplace_price") {
              const appName = field === "pos_price" ? "pos" : "b2c";

              const updatedPrices = ensurePriceObject(
                variant.prices,
                appName
              ).map((price) =>
                price.app_name === appName
                  ? {
                      ...price,
                      selling_price: value,
                      margin_percentage: calculateMargin(
                        parseFloat(value),
                        parseFloat(variant.cost_price || 0)
                      ),
                    }
                  : price
              );

              return { ...variant, prices: updatedPrices };
            }

            return { ...variant, [field]: value };
          }),
        };
      });
    });
  };

  const handleRowsChange = (rows) => {
    // setRowData(rows);
    setTempRowData(rows);
    handleAttributesChange(rows);
  };

  const handleCombination = (rows) => {
    // setSelectedCombinations(rows);
    setNewCombinations(rows);
  };

  // Handle the attribute update from the child
  const handleAttributesChange = (rows) => {
    // Map the rows to the required attributes format
    const updatedAttributes = rows.map((row) => ({
      name: row.displayName,
      values: row.productTags,
      is_group_by: row.productTags.length > 1, // Example logic: group by if more than 1 product tag
    }));

    // Update the parent attributes state
    setAttributes(updatedAttributes);
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleUnitChange = (e) => {
    setUnitType(e.target.value);
  };

  // handle detail data
  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else if (flag == "dimensions") {
      setDimensions(e);
    } else if (flag == "barCode") {
      setBarCode(e);
    } else if (flag == "sku") {
      setSku(e);
    } else {
      setProductDetails(e);
    }
  };

  // toggle
  const handletaxToggle = (event) => {
    const isChecked = event.target.checked; // Get the checkbox state
    setIsTax(isChecked); // Update local state
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        // Add option if checked
        return [...prevOptions, option];
      } else {
        // Remove option if unchecked
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };

  const handlePriceChange = (e, platform) => {
    const newPrice = parseFloat(e.target.value);

    if (newPrice === null || newPrice < 0 || newPrice === "" || !newPrice) {
      resetPlatformPriceAndProfit(platform);
      return;
    }

    if (platform === "pos") {
      setPosPrice(newPrice);
      calculatePosProfitAndMargin(newPrice, costPrice);

      if (!differentSale) {
        setMarketplacePrice(newPrice);
        calculateMarketplaceProfitAndMargin(newPrice, costPrice);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      calculateMarketplaceProfitAndMargin(newPrice, costPrice);
    }

    updatePriceArray(platform, newPrice);
  };

  const updatePriceArray = (platform, newPrice) => {
    setPrices((prevPrices) => {
      return prevPrices.map((price) => {
        if (price.app_name === platform) {
          if (!newPrice) {
            // Only reset selling_price and margin_percentage when the price is null or empty
            return {
              ...price,
              selling_price: "",
              margin_percentage: "",
            };
          } else {
            // Update selling_price and margin_percentage for the given platform
            const margin = calculateMargin(newPrice);
            return {
              ...price,
              selling_price: newPrice.toString(),
              margin_percentage: margin.toFixed(2),
            };
          }
        }
        return price; // Keep other platforms as is
      });
    });
  };

  const resetPlatformPriceAndProfit = (platform) => {
    if (platform === "pos") {
      setPosPrice(null);
      setPosProfit(null);
      setPosMargin(null);
      updatePriceArray("pos", null);

      if (!differentSale) {
        setMarketplacePrice(null);
        setMarketplaceProfit(null);
        setMarketplaceMargin(null);
        updatePriceArray("b2c", null);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(null);
      setMarketplaceProfit(null);
      setMarketplaceMargin(null);
      updatePriceArray("b2c", null);
    }
  };

  const handleChangeCostPrice = (e) => {
    const newCostPrice = parseFloat(e.target.value); // Ensure valid number
    setcostPrice(newCostPrice);
  };

  const calculatePosProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setPosProfit(profit.toFixed(2)); // Update profit
      setPosMargin(margin.toFixed(2)); // Update margin
    } else {
      setPosProfit(null); // Reset profit if invalid
      setPosMargin(null); // Reset margin if invalid
    }
  };

  const calculateMarketplaceProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setMarketplaceProfit(profit.toFixed(2)); // Update profit
      setMarketplaceMargin(margin.toFixed(2)); // Update margin
    } else {
      setMarketplaceProfit(null); // Reset profit if invalid
      setMarketplaceMargin(null); // Reset margin if invalid
    }
  };

  const calculateMargin = (price) => {
    let margin = 0; // Default value for margin
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      margin = (profit / costPrice) * 100; // Formula to calculate margin
    }
    return margin; // Return the calculated margin
  };

  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Edit-Products";
  }, []);

  const handleSave = () => {
    const sanitizedAddressesVariants = structureVariants.map((variantGroup) => {
      let sanitizedGroup = {
        seller_address_id: variantGroup.seller_address_id, // Ensure this key exists
        isNew: variantGroup.isNew || false,
        variants: variantGroup.variants.map((variant) => ({
          ...Object.fromEntries(
            Object.entries(variant).filter(
              ([_, value]) =>
                value !== null &&
                value !== undefined &&
                String(value).trim() !== ""
            )
          ),
          prices: variant.prices
            ?.map((price) => ({
              app_name: price.app_name,
              price_type: price.price_type || "fixed",
              selling_price: price.selling_price || 0,
              margin_percentage: price.margin_percentage || 0,
            }))
            .filter((price) => price.selling_price !== 0), // Remove empty price entries
        })),
      };

      // Only include supplyId if it's not null or undefined
      if (variantGroup.supplyId) {
        sanitizedGroup.supplyId = variantGroup.supplyId;
      }

      return sanitizedGroup;
    });

    // Check for negative margin percentages
    const hasNegativeMargin = sanitizedAddressesVariants.some((variantGroup) =>
      variantGroup.variants.some((variant) =>
        variant.prices?.some((price) => Number(price.margin_percentage) < 0)
      )
    );

    if (hasNegativeMargin) {
      setModalDetail({ show: true, flag: "confirmModal" });
      setKey(Math.random());
      return;
    }

    proceedWithSave(sanitizedAddressesVariants);
  };

  const proceedWithSave = (sanitizedVariants) => {
    var imagesToDelete = allImages.filter(
      (item) => !imageDetails.images.includes(item.url)
    );

    imagesToDelete = imagesToDelete.map((item) => item.id);

    // Get all seller address IDs from supplies
    const supplySellerIds = selectedProductDtails.supplies.map(
      (supply) => supply.seller_address_id
    );

    // Filter supply seller IDs that are NOT in seller addresses
    const missingSellerIds = supplySellerIds.filter(
      (id) => !sellerAddressIds.includes(id)
    );

    let product_attribute = {
      id: productId,
      name: productDetails?.productName || "",
      description: productDescription || "",
      image: imageDetails?.image || "",
      images: imageDetails?.images || [],
      service_id: productDetails?.service_id || "",
      category_id: productDetails?.category_id?.toString() || "",
      sub_category_id: productDetails?.sub_category_id?.toString() || "",
      brand_id: productDetails?.brand_id
        ? productDetails.brand_id.toString()
        : "",
      is_adult: isAdult, //static
      cost_price: costPrice || "",
      barcode: barCode.toString(),
      is_returnable: true, //static
      weight_unit: "lb", //static
      type: "physical",
      country_of_origin: "US",
      quantity_unit: unitType,
      delivery_options: deliveryOptions,
      addresses_variants: sanitizedVariants,
    };
    // console.log(product_attribute, "product_attributeRRRR");
    if (deletedAttributeIds.length > 0) {
      product_attribute = {
        ...product_attribute,
        deleted_product_attribute_ids: deletedAttributeIds,
      };
    }
    if (imagesToDelete.length > 0) {
      product_attribute.deleted_image_ids = imagesToDelete;
    }
    if (deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")) {
      product_attribute = { ...product_attribute, dimensions: dimensions };
    }
    if (attributes.length > 0) {
      product_attribute = { ...product_attribute, attributes: attributes };
    }
    if (missingSellerIds.length > 0) {
      product_attribute = {
        ...product_attribute,
        delete_seller_address_ids: missingSellerIds,
      };
    }
    if (chartImage) {
      product_attribute = {
        ...product_attribute,
        size_chart_image: chartImage.toString(),
      };
    }

    let result = formValidator(product_attribute, sellerAddressIds, sku);
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };
      dispatch(editProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success(obj?.msg);
          history.push(`/productList`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };
  const handleConfirmNegativeMargin = () => {
    handleOnCloseModal();

    // Ensure structureVariants is mapped correctly to addresses_variants
    const sanitizedAddressesVariants = structureVariants.map((variantGroup) => {
      let sanitizedGroup = {
        seller_address_id: variantGroup.seller_address_id, // Ensure this key exists
        isNew: variantGroup.isNew || false,
        variants: variantGroup.variants.map((variant) => ({
          ...Object.fromEntries(
            Object.entries(variant).filter(
              ([_, value]) =>
                value !== null &&
                value !== undefined &&
                String(value).trim() !== ""
            )
          ),
          prices: variant.prices
            ?.map((price) => ({
              app_name: price.app_name,
              price_type: price.price_type || "fixed",
              selling_price: price.selling_price || 0,
              margin_percentage: price.margin_percentage || 0,
            }))
            .filter((price) => price.selling_price !== 0), // Remove empty price entries
        })),
      };

      // Only include supplyId if it's not null or undefined
      if (variantGroup.supplyId) {
        sanitizedGroup.supplyId = variantGroup.supplyId;
      }

      return sanitizedGroup;
    });

    proceedWithSave(sanitizedAddressesVariants);
  };

  const handleModalChange = (flag) => {
    setModalDetail({ show: true, flag: flag });
    setKey(Math.random());
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];

        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;

    if (type === "saleSame") {
      setSaleSame(isChecked);
      if (isChecked) {
        if (differentSale) {
          setDifferentSale(false); // Ensure only one can be selected at a time
          setPosPrice(null);
          setMarketplacePrice(null);
          setPosProfit(null);
          setMarketplaceProfit(null);
          setPosMargin(null);
          setMarketplaceMargin(null);
          setPrices([
            {
              app_name: "pos",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
            {
              app_name: "b2c",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
          ]);
        }
      }
    } else if (type === "differentSale") {
      setDifferentSale(isChecked);
      if (isChecked) {
        setSaleSame(false); // Ensure only one can be selected at a time
      }
    }
    // setDiscountValue("");
    if (type === "differentSale") {
      setPosPrice(null);
      setMarketplacePrice(null);
      setPosProfit(null);
      setMarketplaceProfit(null);
      setPosMargin(null);
      setMarketplaceMargin(null);
      setPrices([
        {
          app_name: "pos",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
        {
          app_name: "b2c",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
      ]);
    }
  };

  const handleOpenVariation = () => {
    let isValid = true;

    // Validate displayName is not empty
    if (
      tempRowData.some((row) => {
        if (!row.displayName.trim()) {
          showToast("Display name is required");
          return true; // Stop validation on first empty displayName
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    if (
      tempRowData.some((row, idx) => {
        const isDuplicate = tempRowData.some(
          (existingRow, i) =>
            existingRow.displayName === row.displayName && i !== idx
        );
        if (isDuplicate) {
          showToast("Display name must be unique");
          return true; // Stop validation on first duplicate
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    // Validate productTags are not empty
    if (
      tempRowData.some((row) => {
        if (row.productTags.length === 0) {
          showToast("Please add at least one option.");
          return true; // Stop validation on first empty productTags
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    if (!isValid) return;
    // setAttributeShow(true);

    if (modalDetail?.flag == "EditOption") {
      handleOnCloseModal();
      setRowData(tempRowData);
      setTempRowData([]);
    } else {
      setModalDetail({
        show: true,
        flag: "createVariations",
      });
      setKey(Math.random());
    }
  };

  const handleDeleteOption = (displayName) => {
    // Find the attribute to delete by matching displayName with attribute.name
    const deletedAttribute = attributes.find(
      (attr) => attr.name === displayName
    );

    // Store the deleted attribute ID if it exists
    if (deletedAttribute?.id) {
      setDeletedAttributeIds((prevIds) => [...prevIds, deletedAttribute.id]);
    }

    // Update rowData to exclude the row that matches the displayName
    const updatedRowData = rowData.filter(
      (item) => item.displayName !== displayName
    );
    setRowData(updatedRowData);
    // Update attributes after deletion
    handleAttributesChange(updatedRowData);

    // Update selectedCombinations to delete the displayName key from combinations
    const updatedCombinations = selectedCombinations.map((combination) => {
      // If the displayName exists as a key, delete it from the combination
      const updatedCombination = { ...combination };
      if (updatedCombination.hasOwnProperty(displayName)) {
        delete updatedCombination[displayName];
      }
      return updatedCombination;
    });

    // Remove duplicate combinations
    const uniqueCombinations = Array.from(
      new Set(updatedCombinations.map(JSON.stringify))
    ).map(JSON.parse);

    setSelectedCombinations(uniqueCombinations);

    // Check if rowData is empty after deletion and set a default row if it is
    if (updatedRowData.length === 0) {
      setRowData([
        {
          id: 1,
          activePlan: "list",
          displayName: "",
          productTags: [],
        },
      ]);
      setAttributeShow(false);
      setSelectedCombinations([]);
      setAttributes([]);
    }
  };

  const toggleVariantSelection = (idx) => {
    setSelectedVariants((prevSelected) => {
      if (prevSelected.includes(idx)) {
        return prevSelected.filter((id) => id !== idx); // Remove if already selected
      } else {
        return [...prevSelected, idx]; // Add if not selected
      }
    });
  };
  const handleSelectAllChange = () => {
    // If all variants are selected, unselect them, otherwise select all
    if (selectedVariants.length === variants.length) {
      setSelectedVariants([]); // Deselect all
    } else {
      const allVariantIndexes = variants.map((_, idx) => idx); // Create an array of all variant indexes
      setSelectedVariants(allVariantIndexes); // Select all variants
    }
  };

  const handleBulkUpdate = (field, values) => {
    if (selectedVariants.length <= 0) {
      showToast("please select variants");
      return;
    }

    const updatedVariants = variants.map((variant, idx) => {
      const isSelected = selectedVariants.includes(idx);

      if (isSelected) {
        const costPrice = parseFloat(variant.cost_price || 0);

        // Calculate margin percentage based on cost and selling price
        const calculateMargin = (costPrice, sellingPrice) => {
          if (!costPrice || !sellingPrice) return "";
          return (((sellingPrice - costPrice) / costPrice) * 100).toFixed(2);
        };

        // Update prices if the field is "price"
        const updatedPrices = variant.prices.map((price) => {
          if (price.app_name === "pos") {
            return {
              ...price,
              selling_price: values.posPrice || price.selling_price,
              margin_percentage: calculateMargin(
                costPrice,
                parseFloat(values.posPrice || price.selling_price)
              ),
            };
          }

          if (price.app_name === "b2c") {
            return {
              ...price,
              selling_price: values.b2cPrice || price.selling_price,
              margin_percentage: calculateMargin(
                costPrice,
                parseFloat(values.b2cPrice || price.selling_price)
              ),
            };
          }

          return price; // Return price unchanged if it's not being updated
        });

        // Return the updated variant object
        return {
          ...variant,
          ...(field === "quantity" && {
            quantity: values.quantity || variant.quantity,
          }),
          ...(field === "quantity" && {
            quantity_reorder_point:
              values.quantity_reorder_point || variant.quantity_reorder_point,
          }),
          ...(field === "weight" && {
            weight: values.weight || variant.weight,
          }),
          ...(field === "pos_price" && { prices: updatedPrices }),
          ...(field === "b2c_price" && { prices: updatedPrices }),
        };
      }

      return variant; // If not selected, return the variant unchanged
    });

    setVariants(updatedVariants);
  };

  const handleDeleteVariant = (indexToDelete, groupIndex) => {
    const updatedStructureVariants = [...structureVariants];

    const hasGroupByAttribute = attributes.length > 0;

    const groupVariants = updatedStructureVariants[groupIndex].variants;

    if (hasGroupByAttribute && groupVariants.length === 1) {
      showToast(
        "At least one variant must exist when a 'Group By' attribute is present."
      );
      return; // Prevent deletion
    }

    // Remove the variant from the specified group
    updatedStructureVariants[groupIndex].variants = updatedStructureVariants[
      groupIndex
    ].variants.filter((_, idx) => idx !== indexToDelete);

    // Update the state
    setStructureVariants(updatedStructureVariants);
  };

  //  Automatically remove combinations if a productTag is removed

  useEffect(() => {
    if (!rowData.length) return;

    // Step 1: Create a Set of all valid productTags
    const validTags = new Set(rowData.flatMap((item) => item.productTags));

    // Step 2: Update selectedCombinations
    setSelectedCombinations((prevCombinations) => {
      const updatedCombinations = prevCombinations
        .map((combination) => {
          if (!combination) return null;

          // Filter only valid attributes
          const updatedCombination = Object.fromEntries(
            Object.entries(combination).filter(([key, value]) =>
              validTags.has(value)
            )
          );

          return Object.keys(updatedCombination).length > 0
            ? updatedCombination
            : null; // If all attributes are removed, return null
        })
        .filter(Boolean); // Remove null values

      return updatedCombinations.length > 0 ? updatedCombinations : []; // Return [] if empty
    });
  }, [rowData]); // Runs whenever rowData changes

  useEffect(() => {
    if (selectedCombinations?.length > 0 || selectedProductDtails) {
      setStructureVariants((prevVariants) => {
        const supplies = selectedProductDtails?.supplies || [];

        const parseImages = (imageData) => {
          if (!imageData) return [];
          try {
            if (Array.isArray(imageData)) return imageData;
            if (typeof imageData === "string" && imageData.startsWith("[")) {
              return JSON.parse(imageData);
            }
            return [imageData]; // Single image URL case
          } catch (error) {
            return [];
          }
        };

        const isEqual = (obj1, obj2) =>
          JSON.stringify(obj1) === JSON.stringify(obj2);

        const structuredVariants = sellerAddressIds
          .map((sellerAddressId) => {
            const sellerSupplies = supplies.filter(
              (supply) => supply.seller_address_id === sellerAddressId
            );

            if (sellerSupplies.length > 0) {
              return sellerSupplies.map((supply) => {
                const uniqueVariants = new Set();

                const variants = selectedCombinations
                  .map((combination, index) => {
                    const isEmptyCombination =
                      !combination ||
                      (typeof combination === "object" &&
                        Object.keys(combination || {}).length === 0);

                    const validCombination = isEmptyCombination
                      ? null
                      : combination;

                    const matchingVariant = supply.supply_variants.find(
                      (variant) => {
                        const variantAttributes = JSON.parse(
                          variant.attributes
                        );
                        return isEqual(variantAttributes, validCombination);
                      }
                    );

                    const prevVariant = prevVariants
                      ?.find((v) => v.seller_address_id === sellerAddressId)
                      ?.variants?.find((v) =>
                        isEqual(v.attributes, validCombination)
                      );

                    const variantPrices = matchingVariant
                      ? supply.supply_prices.filter(
                          (price) =>
                            price.supply_variant_id === matchingVariant?.id
                        )
                      : [];

                    const variantObject = {
                      attributes: validCombination,
                      cost_price: costPrice || "",
                      prices:
                        prevVariant?.prices?.map((price) => ({
                          ...price,
                          selling_price: price.selling_price || "",
                        })) ||
                        (validCombination === null
                          ? prices || [
                              {
                                app_name: "pos",
                                price_type: "fixed",
                                selling_price: "",
                                margin_percentage: "",
                              },
                              {
                                app_name: "b2c",
                                price_type: "fixed",
                                selling_price: "",
                                margin_percentage: "",
                              },
                            ]
                          : variantPrices.length > 0
                          ? variantPrices.map((price) => ({
                              app_name: price.app_name,
                              price_type: price.price_type || "fixed",
                              selling_price: price.selling_price || "",
                              margin_percentage: price.margin_percentage || "",
                            }))
                          : [
                              {
                                app_name: "pos",
                                price_type: "fixed",
                                selling_price: "",
                                margin_percentage: "",
                              },
                              {
                                app_name: "b2c",
                                price_type: "fixed",
                                selling_price: "",
                                margin_percentage: "",
                              },
                            ]),
                      quantity:
                        prevVariant?.quantity ||
                        (validCombination === null
                          ? quantity || ""
                          : matchingVariant?.total_quantity || quantity || ""),
                      quantity_reorder_point:
                        prevVariant?.quantity_reorder_point ||
                        (validCombination === null
                          ? quantityReorder || ""
                          : matchingVariant?.quantity_reorder_point || ""),
                      weight:
                        prevVariant?.weight ||
                        (validCombination === null
                          ? dimensions?.weight || ""
                          : matchingVariant?.weight ||
                            dimensions?.weight ||
                            ""),
                      barcode:
                        prevVariant?.barcode ||
                        (validCombination === null
                          ? barCode || String(Number(barCode) + index) || ""
                          : matchingVariant?.barcode ||
                            String(Number(barCode) + index) ||
                            ""),
                      image:
                        prevVariant?.image ||
                        (validCombination === null
                          ? imageDetails?.image || ""
                          : selectedCombinations.length === 0 ||
                            !matchingVariant
                          ? imageDetails?.image || ""
                          : matchingVariant?.image || ""),
                      images:
                        prevVariant?.images ||
                        (validCombination === null
                          ? parseImages(imageDetails?.images)
                          : selectedCombinations.length === 0 ||
                            !matchingVariant
                          ? parseImages(imageDetails?.images)
                          : parseImages(matchingVariant?.images)),
                      seller_address_ids: [sellerAddressId],
                      sku: sku || "",
                    };

                    const variantKey = JSON.stringify(variantObject.attributes);

                    if (!uniqueVariants.has(variantKey)) {
                      uniqueVariants.add(variantKey);
                      return variantObject;
                    }
                    return null;
                  })
                  .filter(Boolean);

                return {
                  seller_address_id: sellerAddressId,
                  isNew: false,
                  supplyId: supply.id,
                  variants:
                    variants.length > 0
                      ? variants
                      : [
                          {
                            attributes: null,
                            cost_price: costPrice || "",
                            prices: prices || [
                              {
                                app_name: "pos",
                                price_type: "fixed",
                                selling_price: "",
                                margin_percentage: "",
                              },
                              {
                                app_name: "b2c",
                                price_type: "fixed",
                                selling_price: "",
                                margin_percentage: "",
                              },
                            ],
                            quantity: quantity || "",
                            quantity_reorder_point: quantityReorder || "",
                            weight: dimensions?.weight || "",
                            barcode: barCode || "",
                            image: imageDetails?.image || "",
                            images: parseImages(imageDetails?.images),
                            seller_address_ids: [sellerAddressId],
                            sku: sku || "",
                          },
                        ],
                };
              });
            }

            return {
              seller_address_id: sellerAddressId,
              isNew: true,
              supplyId: null,
              variants:
                selectedCombinations.length > 0
                  ? selectedCombinations.map((combination, index) => ({
                      attributes:
                        combination && typeof combination === "object"
                          ? Object.keys(combination || {}).length === 0
                            ? null
                            : combination
                          : null,
                      cost_price: costPrice || "",
                      prices: prices || [
                        {
                          app_name: "pos",
                          price_type: "fixed",
                          selling_price: "",
                          margin_percentage: "",
                        },
                        {
                          app_name: "b2c",
                          price_type: "fixed",
                          selling_price: "",
                          margin_percentage: "",
                        },
                      ],
                      quantity: quantity || "",
                      quantity_reorder_point: quantityReorder || "",
                      weight: dimensions?.weight || "",
                      barcode: barCode || "",
                      image: imageDetails?.image || "",
                      images: parseImages(imageDetails?.images),
                      seller_address_ids: [sellerAddressId],
                      sku: sku || "",
                    }))
                  : [
                      {
                        attributes: null,
                        cost_price: costPrice || "",
                        prices: prices || [
                          {
                            app_name: "pos",
                            price_type: "fixed",
                            selling_price: "",
                            margin_percentage: "",
                          },
                          {
                            app_name: "b2c",
                            price_type: "fixed",
                            selling_price: "",
                            margin_percentage: "",
                          },
                        ],
                        quantity: quantity || "",
                        quantity_reorder_point: quantityReorder || "",
                        weight: dimensions?.weight || "",
                        barcode: barCode || "",
                        image: imageDetails?.image || "",
                        images: parseImages(imageDetails?.images),
                        seller_address_ids: [sellerAddressId],
                        sku: sku || "",
                      },
                    ],
            };
          })
          .flat();

        return structuredVariants;
      });
    }
  }, [
    selectedCombinations,
    selectedProductDtails,
    sellerAddressIds,
    costPrice,
    prices,
    imageDetails,
    barCode,
    dimensions,
    quantity,
    quantityReorder,
    sku,
  ]);

  useEffect(() => {
    if (costPrice > 0) {
      // Update POS profit and margin
      if (posPrice > 0) {
        calculatePosProfitAndMargin(posPrice, costPrice);
      }

      // Update Marketplace profit and margin
      if (marketplacePrice > 0) {
        calculateMarketplaceProfitAndMargin(marketplacePrice, costPrice);
      }

      //  Update structureVariants directly
      setStructureVariants((prevStructureVariants) =>
        prevStructureVariants.map((seller) => ({
          ...seller,
          variants: seller.variants.map((variant) => ({
            ...variant,
            prices: variant.prices.map((price) => ({
              ...price,
              margin_percentage:
                price.selling_price > 0
                  ? calculateMargin(
                      parseFloat(price.selling_price),
                      costPrice
                    ).toFixed(2)
                  : price.margin_percentage,
            })),
          })),
        }))
      );
    } else {
      // Reset profits and margins when costPrice is invalid
      setPosProfit("");
      setPosMargin("");
      setMarketplaceProfit("");
      setMarketplaceMargin("");

      //  Reset margin in structureVariants
      setStructureVariants((prevStructureVariants) =>
        prevStructureVariants.map((seller) => ({
          ...seller,
          variants: seller.variants.map((variant) => ({
            ...variant,
            prices: variant.prices.map((price) => ({
              ...price,
              margin_percentage: "",
            })),
          })),
        }))
      );
    }
  }, [costPrice]);

  // useEffect(() => {
  //   if (selectedProductDtails) {
  //     setProductDetails({
  //       productName: selectedProductDtails?.name || "",
  //       category_id: selectedProductDtails?.category_id?.toString() || "",
  //       sub_category_id:
  //         selectedProductDtails?.sub_category_id?.toString() || "",
  //       brand_id: selectedProductDtails?.brand_id?.toString() || "",
  //       is_adult: false, // default value
  //       service_id: selectedProductDtails?.service_id?.toString() || "",
  //     });

  //     setImageDetails({
  //       image: selectedProductDtails?.image || "",
  //       images:
  //         selectedProductDtails?.product_images?.map((img) => img.url) || [],
  //     });
  //     setcostPrice(selectedProductDtails?.price || "");

  //     const sellerAddressIds =
  //       selectedProductDtails?.supplies?.map(
  //         (supply) => supply.seller_address_id
  //       ) || [];

  //     if (sellerAddressIds.length > 0) {
  //       setSellerAddressIds(sellerAddressIds); // Set all seller_address_id values
  //     }

  //     const deliveryOptionCodes = selectedProductDtails?.supplies[0]
  //       ?.delivery_options
  //       ? selectedProductDtails?.supplies[0].delivery_options
  //           .split(",")
  //           .map(Number) // Convert to an array of numbers
  //       : [];
  //     const parsedOptions = deliveryOptionCodes.map(
  //       (code) => deliveryOptionMap[code]
  //     );
  //     setDeliveryOptions(parsedOptions);
  //     setChartImage(selectedProductDtails?.size_chart_image);
  //     // Transform product attributes
  //     const transformedAttributes =
  //       selectedProductDtails?.product_attributes?.map((attr) => ({
  //         name: attr.attribute, // Rename 'attribute' to 'name'
  //         values: JSON.parse(attr.values), // Parse the 'values' string to an array
  //         is_group_by: attr.is_group_by, // Keep the 'is_group_by' flag
  //         id: attr?.id,
  //       }));

  //     // Set the transformed attributes
  //     setAttributes(transformedAttributes || []);

  //     if (selectedProductDtails?.product_attributes?.length > 0) {
  //       const newRowData = selectedProductDtails?.product_attributes.map(
  //         (attr, index) => ({
  //           id: index + 1,
  //           activePlan: attr.attribute === "color" ? "color" : "list",
  //           displayName: attr.attribute,
  //           productTags: JSON.parse(attr.values), // Convert stringified JSON to array
  //         })
  //       );

  //       setRowData(newRowData); // Update rowData based on attributes
  //       setTempRowData(newRowData);
  //       setAttributeShow(true);
  //     }

  //     const allAttributesNull = selectedProductDtails?.supplies?.every(
  //       (supply) =>
  //         supply?.supply_variants?.every((variant) => !variant.attributes)
  //     );

  //     if (selectedProductDtails?.supplies?.length > 0) {
  //       let allCombinations = [];
  //       let totalQuantity = 0;
  //       let totalReorderPoint = 0;

  //       let posPriceData = null;
  //       let marketplacePriceData = null;
  //       let isSaleSame = true;

  //       // Loop through all supplies
  //       selectedProductDtails.supplies.forEach((supply) => {
  //         const supplyVariants = supply?.supply_variants || [];

  //         // Extract attributes from all variants safely
  //         const combinations = supplyVariants
  //           .map((variant) => {
  //             try {
  //               return JSON.parse(variant.attributes);
  //             } catch (error) {
  //               console.error("Error parsing variant attributes:", error);
  //               return null; // Ensure faulty data doesn't break the logic
  //             }
  //           })
  //           .filter(Boolean); // Remove null values

  //         allCombinations.push(...combinations);

  //         if (allAttributesNull) {

  //           totalQuantity = supplyVariants[0]?.total_quantity || 0;
  //           totalReorderPoint = supplyVariants[0]?.quantity_reorder_point || 0;

  //           // Get price data for POS and Marketplace
  //           const currentPosPrice = supply?.supply_prices?.find(
  //             (item) => item.app_name === "pos"
  //           );
  //           const currentMarketplacePrice = supply?.supply_prices?.find(
  //             (item) => item.app_name === "b2c"
  //           );

  //           if (!posPriceData && currentPosPrice)
  //             posPriceData = { ...currentPosPrice };
  //           if (!marketplacePriceData && currentMarketplacePrice)
  //             marketplacePriceData = { ...currentMarketplacePrice };
  //           console.log(marketplacePriceData, "marketplacePriceData");

  //           // Check if prices are the same across supplies
  //           if (
  //             currentPosPrice?.selling_price &&
  //             currentMarketplacePrice?.selling_price &&
  //             currentPosPrice.selling_price !==
  //               currentMarketplacePrice.selling_price
  //           ) {
  //             isSaleSame = false;
  //           }
  //         }

  //       });

  //       // Set state with combined values
  //       setSelectedCombinations(allCombinations);
  //       setQuantity(totalQuantity);
  //       setQuantityReorder(totalReorderPoint);

  //       if (posPriceData && marketplacePriceData) {
  //         setSaleSame(isSaleSame);
  //         setDifferentSale(!isSaleSame);
  //         setPosPrice(posPriceData?.selling_price || "");
  //         setMarketplacePrice(marketplacePriceData?.selling_price || "");

  //         setPrices([
  //           {
  //             app_name: "pos",
  //             price_type: posPriceData?.price_type || "fixed",
  //             selling_price: posPriceData?.selling_price || "",
  //             margin_percentage: posPriceData?.margin_percentage || "",
  //           },
  //           {
  //             app_name: "b2c",
  //             price_type: marketplacePriceData?.price_type || "fixed",
  //             selling_price: marketplacePriceData?.selling_price || "",
  //             margin_percentage: marketplacePriceData?.margin_percentage || "",
  //           },
  //         ]);
  //       } else if (!marketplacePriceData) {
  //         setPosPrice(posPriceData?.selling_price || "");
  //         setPrices([
  //           {
  //             app_name: "pos",
  //             price_type: posPriceData?.price_type || "fixed",
  //             selling_price: posPriceData?.selling_price || "",
  //             margin_percentage: posPriceData?.margin_percentage || "",
  //           },
  //         ]);
  //         setDifferentSale(true);
  //         setMarketCheck(false);
  //       }

  //     }
  //   }
  // }, [location]);

  useEffect(() => {
    if (selectedProductDtails) {
      setProductDetails({
        productName: selectedProductDtails?.name || "",
        category_id: selectedProductDtails?.category_id?.toString() || "",
        sub_category_id:
          selectedProductDtails?.sub_category_id?.toString() || "",
        brand_id: selectedProductDtails?.brand_id?.toString() || "",
        is_adult: false,
        service_id: selectedProductDtails?.service_id?.toString() || "",
      });

      setImageDetails({
        image: selectedProductDtails?.image || "",
        images:
          selectedProductDtails?.product_images?.map((img) => img.url) || [],
      });

      setcostPrice(selectedProductDtails?.price || "");

      const sellerAddressIds =
        selectedProductDtails?.supplies?.map(
          (supply) => supply.seller_address_id
        ) || [];

      if (sellerAddressIds.length > 0) {
        setSellerAddressIds(sellerAddressIds);
      }

      const deliveryOptionCodes = selectedProductDtails?.supplies[0]
        ?.delivery_options
        ? selectedProductDtails?.supplies[0].delivery_options
            .split(",")
            .map(Number)
        : [];
      setDeliveryOptions(
        deliveryOptionCodes.map((code) => deliveryOptionMap[code])
      );

      setChartImage(selectedProductDtails?.size_chart_image);

      const transformedAttributes =
        selectedProductDtails?.product_attributes?.map((attr) => ({
          name: attr.attribute,
          values: JSON.parse(attr.values),
          is_group_by: attr.is_group_by,
          id: attr?.id,
        })) || [];

      setAttributes(transformedAttributes);

      if (selectedProductDtails?.product_attributes?.length > 0) {
        const newRowData = selectedProductDtails?.product_attributes.map(
          (attr, index) => ({
            id: index + 1,
            activePlan: attr.attribute === "color" ? "color" : "list",
            displayName: attr.attribute,
            productTags: JSON.parse(attr.values),
          })
        );

        setRowData(newRowData);
        setTempRowData(newRowData);
        setAttributeShow(true);
      }

      const allAttributesNull = selectedProductDtails?.supplies?.every(
        (supply) =>
          supply?.supply_variants?.every((variant) => !variant.attributes)
      );

      if (selectedProductDtails?.supplies?.length > 0) {
        let allCombinations = [];
        let totalQuantity = 0;
        let totalReorderPoint = 0;

        let posPriceData = null;
        let marketplacePriceData = null;
        let isSaleSame = true;

        let marketCheckFlag = false; // Track if any "b2c" price exists

        selectedProductDtails.supplies.forEach((supply) => {
          const supplyVariants = supply?.supply_variants || [];

          const combinations = supplyVariants
            .map((variant) => {
              try {
                return JSON.parse(variant.attributes);
              } catch (error) {
                console.error("Error parsing variant attributes:", error);
                return null;
              }
            })
            .filter(Boolean);

          allCombinations.push(...combinations);

          // Only check for "b2c" price if attributes exist
          if (
            supply?.supply_prices?.some((price) => price.app_name === "b2c")
          ) {
            marketCheckFlag = true;
          }

          if (allAttributesNull) {
            totalQuantity = supplyVariants[0]?.total_quantity || 0;
            totalReorderPoint = supplyVariants[0]?.quantity_reorder_point || 0;

            const currentPosPrice = supply?.supply_prices?.find(
              (item) => item.app_name === "pos"
            );
            const currentMarketplacePrice = supply?.supply_prices?.find(
              (item) => item.app_name === "b2c"
            );

            if (!posPriceData && currentPosPrice)
              posPriceData = { ...currentPosPrice };
            if (!marketplacePriceData && currentMarketplacePrice)
              marketplacePriceData = { ...currentMarketplacePrice };

            if (
              currentPosPrice?.selling_price &&
              currentMarketplacePrice?.selling_price &&
              currentPosPrice.selling_price !==
                currentMarketplacePrice.selling_price
            ) {
              isSaleSame = false;
            }
          }
        });

        setSelectedCombinations(allCombinations);
        setQuantity(totalQuantity);
        setQuantityReorder(totalReorderPoint);
        console.log(marketCheckFlag, "marketplacePriceData");

        if (posPriceData && marketplacePriceData) {
          setSaleSame(isSaleSame);
          setDifferentSale(!isSaleSame);
          setPosPrice(posPriceData?.selling_price || "");
          setMarketplacePrice(marketplacePriceData?.selling_price || "");

          setPrices([
            {
              app_name: "pos",
              price_type: posPriceData?.price_type || "fixed",
              selling_price: posPriceData?.selling_price || "",
              margin_percentage: posPriceData?.margin_percentage || "",
            },
            {
              app_name: "b2c",
              price_type: marketplacePriceData?.price_type || "fixed",
              selling_price: marketplacePriceData?.selling_price || "",
              margin_percentage: marketplacePriceData?.margin_percentage || "",
            },
          ]);
        } else if (!marketplacePriceData) {
          setPosPrice(posPriceData?.selling_price || "");
          setPrices([
            {
              app_name: "pos",
              price_type: posPriceData?.price_type || "fixed",
              selling_price: posPriceData?.selling_price || "",
              margin_percentage: posPriceData?.margin_percentage || "",
            },
          ]);
          setDifferentSale(true);
        }

        setMarketCheck(marketCheckFlag); // Set market check only when attributes exist
      }
    }
  }, [location]);
  console.log(prices, "price");

  const handleProfitChange = (e, platform) => {
    let newProfit = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newProfit)) {
      newProfit = 0; // Reset to 0 if invalid
    }

    newProfit = parseFloat(newProfit.toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));
    const newMargin =
      cost > 0 ? parseFloat(((newProfit / cost) * 100).toFixed(2)) : 0;

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  const handleMarginChange = (e, platform) => {
    let newMargin = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newMargin)) {
      newMargin = 0;
    }

    newMargin = parseFloat(newMargin.toFixed(2));
    const newProfit = parseFloat(((newMargin / 100) * cost).toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setStructureVariants((prevStructureVariants) =>
        prevStructureVariants.map((product) => ({
          ...product,
          variants: product.variants.map((variant) => {
            if (!marketCheck) {
              // If marketCheck is false, remove B2C price
              return {
                ...variant,
                prices: variant.prices.filter(
                  (price) => price.app_name !== "b2c"
                ),
              };
            }

            const existingB2CPrice = variant.prices.find(
              (price) => price.app_name === "b2c"
            );

            return {
              ...variant,
              prices: existingB2CPrice
                ? variant.prices // Keep the existing B2C price
                : [
                    ...variant.prices,
                    {
                      app_name: "b2c",
                      price_type: "fixed",
                      selling_price: "", // Empty if B2C price does not exist
                      margin_percentage: "",
                    },
                  ],
            };
          }),
        }))
      );
      setPrices((prevPrices) =>
        marketCheck
          ? prevPrices.some((price) => price.app_name === "b2c")
            ? prevPrices.map((price) =>
                price.app_name === "b2c" && !differentSale
                  ? {
                      ...price,
                      selling_price: prevPrices.find((p) => p.app_name === "pos")?.selling_price || "",
                      margin_percentage: prevPrices.find((p) => p.app_name === "pos")?.margin_percentage || "",
                    }
                  : price
              )
            : [
                ...prevPrices,
                {
                  app_name: "b2c",
                  price_type: "fixed",
                  selling_price: !differentSale
                    ? prevPrices.find((p) => p.app_name === "pos")?.selling_price || ""
                    : "",
                  margin_percentage: !differentSale
                    ? prevPrices.find((p) => p.app_name === "pos")?.margin_percentage || ""
                    : "",
                },
              ]
          : prevPrices.filter((price) => price.app_name !== "b2c") // **Ensure removal of b2c**
      );

      if (!marketCheck) {
        setDeliveryOptions((prevOptions) =>
          prevOptions.includes("pickup") ? prevOptions : ["pickup"]
        );
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [marketCheck]);

  const handleMarketCheckChange = () => {
    setMarketCheck((prev) => !prev);
    setMarketplacePrice(null);
    setDeliveryOptions([]);
  };

  return (
    <div className="addProductManually editProductSec">
      {/* Start FixedTopHeader */}
      <AddProductHeader
        flag={backRoute}
        save={(e) => handleSave(e)}
        successLoader={successLoader}
        productName={productName}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper productViewPage">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 p-0">
              <div className="productRightContainer">
                {/* Start Uploaded ProductImg Area */}

                <ProductImageAdd
                  imageData={(e) => handleImageDetails(e)}
                  updateImageDetails={setImageDetails}
                  oldImages={imageDetails}
                />

                <EditProductDetail
                  setSelectedService={setSelectedService}
                  setServiceIdforAttributes={setServiceId}
                  productDetails={(e, flag) => handleProductDetails(e, flag)}
                  productData={selectedProductDtails}
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="form_group">
                      <label className="fontSize16 txtDarkblue mb-2 ps-4">
                        {t("quantity")}
                        {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput"
                          placeholder="quantity"
                          name="barcode"
                          type="number"
                          value={quantity}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form_group">
                      <label className="fontSize16 txtDarkblue mb-2 ps-4">
                        {t("quantityReOrder")}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput"
                          placeholder=" Quantity Re-order"
                          name="barcode"
                          type="number"
                          value={quantityReorder}
                          onChange={(e) => {
                            setQuantityReorder(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* End Uploaded ProductImg Area */}

                {/* Start CostPrice Area */}
                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("")}
                    </h2>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          {t("costOfGoodSold")}
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="Cost Price"
                            name="text"
                            type="number"
                            value={costPrice}
                            onChange={(e) => handleChangeCostPrice(e)}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_group"></div>
                      <div className="reactSelectParent commonSelectParent w-100">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4">
                          {t("unit")}
                        </label>
                        <ReactSelect
                          value={
                            unitType
                              ? {
                                  value: unitType,
                                  label: unitType
                                    .replace("_", " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    ),
                                }
                              : null
                          }
                          options={[
                            // { value: "per_item", label: "Per Item" },
                            { value: "per_unit", label: "Per Unit" },
                            { value: "per_box", label: "Per Box" },
                            { value: "per_bag", label: "Per Bag" },
                            { value: "per_pound", label: "Per Pound" },
                            { value: "per_feet", label: "Per Feet" },
                            { value: "per_gallon", label: "Per Gallon" },
                          ]}
                          onChange={(selectedOption) =>
                            handleUnitChange({
                              target: { value: selectedOption?.value },
                            })
                          }
                          isSearchable={true}
                          placeholder="Select one"
                          classNamePrefix="react-select"
                          className="react-select-container anlyticSelect"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "100% !important",
                              width: "100%",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              border: "1px solid #D7DEFF",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              paddingLeft: "unset !important",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#263682"
                                : state.isFocused
                                ? "#f5f6fc"
                                : "#fff",

                              color: state.isSelected ? "#fff" : "#263682",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#636E9F",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }),
                          }}
                          components={{
                            DropdownIndicator: () => (
                              <img
                                src={Images.selectImg}
                                width={24}
                                height={24}
                                alt="drop_icon"
                                className="mt-1"
                              />
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("productSalePrice")}
                    </h2>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={differentSale}
                          onChange={handleSaleToggle("differentSale")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t("useDifferentSalePrice")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price for B2B, Marketplace & POS
                          </h3>
                        </div>
                      </div>
                      {differentSale && (
                        <>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              POS Pricing
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("salePrice")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="posPrice"
                                      type="number"
                                      value={posPrice}
                                      onChange={(e) =>
                                        handlePriceChange(e, "pos")
                                      }
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("profit")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput costPriceInput"
                                      value={
                                        posProfit !== null &&
                                        posProfit !== undefined
                                          ? posProfit
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleProfitChange(e, "pos")
                                      }
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("margin")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput"
                                      value={
                                        posMargin !== null &&
                                        posMargin !== undefined
                                          ? posMargin
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleMarginChange(e, "pos")
                                      }
                                      placeholder="Margin"
                                    />

                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {marketCheck && (
                            <div className="productSalePriceBox">
                              <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                                Marketplace Pricing
                              </h3>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("salePrice")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        min="1"
                                        onKeyDown={(e) => blockInvalidChar(e)}
                                        className="customInput costPriceInput"
                                        placeholder="1,000.00"
                                        name="marketplacePrice"
                                        type="number"
                                        value={marketplacePrice}
                                        onChange={(e) =>
                                          handlePriceChange(e, "b2c")
                                        }
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("profit")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput costPriceInput"
                                        value={
                                          marketplaceProfit !== null &&
                                          marketplaceProfit !== undefined
                                            ? marketplaceProfit
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleProfitChange(e, "b2c")
                                        }
                                        placeholder="Profit"
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("margin")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput"
                                        value={
                                          marketplaceMargin !== null &&
                                          marketplaceMargin !== undefined
                                            ? marketplaceMargin
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleMarginChange(e, "b2c")
                                        }
                                        placeholder="Margin"
                                      />
                                      <span className="dollrsign1_ usdTxt">
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {/* <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={saleSame}
                          onChange={handleSaleToggle("saleSame")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t('onSale')}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t('salePriceCaption')}
                          </h3>
                        </div>
                      </div>
                    </div> */}
                    {!differentSale && (
                      <>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("salePrice")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="posPrice"
                                type="number"
                                value={posPrice}
                                onChange={(e) => handlePriceChange(e, "pos")}
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("profit")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput costPriceInput"
                                value={
                                  posProfit !== null && posProfit !== undefined
                                    ? posProfit
                                    : ""
                                }
                                onChange={(e) => handleProfitChange(e, "pos")}
                                placeholder="Profit"
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("margin")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput"
                                value={
                                  posMargin !== null && posMargin !== undefined
                                    ? posMargin
                                    : ""
                                }
                                placeholder="Margin"
                                onChange={(e) => handleMarginChange(e, "pos")}
                              />

                              <span className="dollrsign1_ usdTxt">%</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </form>

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div
                        className={`productSale_Parent mt-0 w-100 ${
                          rowData[0].displayName !== "" &&
                          rowData.length > 0 &&
                          attributeShow &&
                          "active"
                        }`}
                      >
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            {t("productOption")}
                          </h3>
                          <p className="fontSize14 fw500 txtDarkblue mb-0">
                            {t("productOptionCaption")}
                          </p>
                          {rowData &&
                            attributeShow &&
                            rowData.length > 0 &&
                            rowData[0].displayName !== "" &&
                            rowData.map((value, idx) => (
                              <div className="productOptionInner">
                                <img src={Images.barsLogo} alt="logo" />
                                <div className="productOption_InnerBox">
                                  <div className="row align-items-center">
                                    <div className="col-3">
                                      <h3 className="fontSize18 txtDarkblue mb-0 textCapital">
                                        {value?.displayName}
                                      </h3>
                                    </div>
                                    <div className="col-9">
                                      <div className="d-flex align-items-center justify-content-between w-100">
                                        <h3 className="fontSize18 txtDarkblue mb-0">
                                          {/* {value?.productTags?.join(", ")} */}
                                          {value?.displayName.toLowerCase() ===
                                          "color"
                                            ? value?.productTags
                                                .map((color) =>
                                                  getColorName(color)
                                                )
                                                .join(", ")
                                            : value?.productTags.join(", ")}
                                        </h3>

                                        <div className="d-flex align-items-center ms-3">
                                          <img
                                            src={Images.newEditIcon}
                                            alt="logo"
                                            className="me-2"
                                            onClick={() => {
                                              setModalDetail({
                                                show: true,
                                                flag: "EditOption",
                                                data: value,
                                                rowIndex: idx,
                                              });
                                              setKey(Math.random());
                                            }}
                                          />
                                          <img
                                            src={Images.modalCross}
                                            alt="logo"
                                            onClick={() =>
                                              handleDeleteOption(
                                                value.displayName
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <button
                            className="commonBtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "AddOptions",
                              });
                              setKey(Math.random());
                              // setAttributeShow(false);
                              if (
                                rowData.length >= 1 &&
                                rowData.displayName !== ""
                              ) {
                                setTempRowData([]);
                              }
                            }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            {t("addOption")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {structureVariants?.length > 0 &&
                    attributeShow &&
                    structureVariants.some(
                      (variantGroup) => variantGroup.variants.length > 0
                    ) && (
                      <div className="col-md-12">
                        <div className="productDetailsContainer">
                          <h2 className="fontSize18 fw500 txtDarkblue">
                            {t("productVariant")}
                          </h2>
                          <p className="fontSize14 txtDarkblue mb-0">
                            {t("productVariantCaption")}
                          </p>

                          {structureVariants.map((variantGroup, groupIdx) => {
                            const filteredVariants =
                              variantGroup.variants.filter(
                                (variant) =>
                                  variant.attributes &&
                                  Object.keys(variant.attributes).length > 0
                              );

                            // If no valid variants exist, return null (hides the table)
                            if (filteredVariants.length === 0) return null;

                            return (
                              <div
                                key={groupIdx}
                                className="row mt-3 align-items-center"
                              >
                                <div className="productVariantsTable table-responsive">
                                  <table>
                                    <thead>
                                      <tr>
                                        {/* <th></th> */}
                                        <th>{t("variation")}</th>
                                        <th>{t("upc")}</th>
                                        <th>POS Price</th>
                                        <th>Marketplace Price</th>
                                        <th>{t("weight")}</th>
                                        <th>{t("quantity")}</th>
                                        <th>{t("quantityReOrder")}</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {variantGroup.variants.map(
                                        (variant, idx) => (
                                          <tr key={idx}>
                                            {/* <td>
                                            <div className="productAvailabilityChild variationsParent">
                                              <div className="form-group">
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox-${groupIdx}-${idx}`}
                                                  checked={selectedVariants.includes(
                                                    idx
                                                  )}
                                                  onChange={() =>
                                                    toggleVariantSelection(idx)
                                                  }
                                                />
                                                <label
                                                  htmlFor={`checkbox-${groupIdx}-${idx}`}
                                                  className="me-0"
                                                ></label>
                                              </div>
                                            </div>
                                          </td> */}
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <img
                                                  src={
                                                    variant?.image
                                                      ? variant.image
                                                      : Images.uploadVariantLogo
                                                  }
                                                  alt="logo"
                                                  className="me-3 teamMemberImg"
                                                />
                                                <div>
                                                  {variant.attributes &&
                                                  Object.keys(
                                                    variant.attributes
                                                  ).length > 0 ? (
                                                    Object.entries(
                                                      variant.attributes
                                                    ).map(
                                                      ([key, val], index) => (
                                                        <span
                                                          className="varintText textCapital"
                                                          key={index}
                                                        >
                                                          {/* {val} */}
                                                          {val.startsWith("#")
                                                            ? getColorName(val)
                                                            : val}
                                                          {index <
                                                            Object.entries(
                                                              variant.attributes
                                                            ).length -
                                                              1 && " / "}
                                                        </span>
                                                      )
                                                    )
                                                  ) : (
                                                    <span className="varintText">
                                                      Default Variant
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input
                                                className="productVariant_Input"
                                                name="barcode"
                                                type="number"
                                                value={variant.barcode || ""}
                                                onChange={(e) => {
                                                  const inputValue =
                                                    e.target.value;
                                                  if (inputValue.length <= 14) {
                                                    handleInputChange(
                                                      groupIdx,
                                                      idx,
                                                      "barcode",
                                                      e.target.value
                                                    );
                                                  }
                                                }}
                                                // onChange={(e) =>
                                                //   handleInputChange(
                                                //     groupIdx,
                                                //     idx,
                                                //     "barcode",
                                                //     e.target.value
                                                //   )
                                                // }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="productVariant_Input"
                                                name="pos_price"
                                                type="number"
                                                value={
                                                  variant.prices.find(
                                                    (p) => p.app_name === "pos"
                                                  )?.selling_price || ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    groupIdx,
                                                    idx,
                                                    "pos_price",
                                                    e.target.value
                                                  )
                                                }
                                                disabled={
                                                  !variant.prices.some(
                                                    (price) =>
                                                      price.app_name === "pos"
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="productVariant_Input"
                                                name="marketplace_price"
                                                type="number"
                                                value={
                                                  variant.prices.find(
                                                    (p) => p.app_name === "b2c"
                                                  )?.selling_price || ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    groupIdx,
                                                    idx,
                                                    "marketplace_price",
                                                    e.target.value
                                                  )
                                                }
                                                disabled={
                                                  !variant.prices.some(
                                                    (price) =>
                                                      price.app_name === "b2c"
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="productVariant_Input"
                                                name="weight"
                                                type="number"
                                                value={variant.weight || ""}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    groupIdx,
                                                    idx,
                                                    "weight",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="productVariant_Input"
                                                name="quantity"
                                                type="number"
                                                value={variant.quantity || ""}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    groupIdx,
                                                    idx,
                                                    "quantity",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="productVariant_Input"
                                                name="quantity_reorder_point"
                                                type="number"
                                                value={
                                                  variant.quantity_reorder_point ||
                                                  ""
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    groupIdx,
                                                    idx,
                                                    "quantity_reorder_point",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  variant="success"
                                                  id={`dropdown-basic-${groupIdx}-${idx}`}
                                                  className="variantsTable_Dropdown"
                                                >
                                                  <img
                                                    src={
                                                      Images.moreVerticalLogo
                                                    }
                                                    alt="logo"
                                                  />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="variantsDropdownMenu">
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      setModalDetail({
                                                        show: true,
                                                        flag: "editVariantModal",
                                                        index: idx,
                                                        groupIndex: groupIdx,
                                                        variationname:
                                                          variant.attributes &&
                                                          Object.entries(
                                                            variant.attributes
                                                          )
                                                            .map(([key, val]) =>
                                                              val.startsWith(
                                                                "#"
                                                              )
                                                                ? getColorName(
                                                                    val
                                                                  )
                                                                : val
                                                            )
                                                            .join(" / "),
                                                      });
                                                      setKey(Math.random());
                                                    }}
                                                  >
                                                    {t("edit")}
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      handleDeleteVariant(
                                                        idx,
                                                        groupIdx
                                                      )
                                                    }
                                                  >
                                                    {t("delete")}
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {/* End ProductDetails Area */}
            <div className="col-xl-4 px-0 px-md-3">
              {/* Start ProductAvailability Area */}
              <div className="productLeftContainer">
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    {t("productAvailability")}
                  </h3>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.physicalStoreIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            {t("physicalStore")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            JOBR POS
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="productAvailOption_Wrapper">
                      {allAddress.map((store, idx) => (
                        <div
                          key={store.id}
                          className="productAvailabilityChild mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={`store-${store.id}`}
                              checked={sellerAddressIds.includes(store.id)}
                              onChange={handleStoreToggle(store.id)} // Correct usage
                            />
                            <label htmlFor={`store-${store.id}`}></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                              Store {idx + 1}
                              {/* Store {store.id} - {store.format_address} */}
                            </h3>
                            <p className="fontSize12 txtLightBlue mb-0">
                              {store.street_address}, {store.city},{" "}
                              {store.state} {store.zipcode}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="productAvail_Second ">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.jobrMarketplaceIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input
                            type="checkbox"
                            checked={marketCheck}
                            onChange={handleMarketCheckChange}
                          />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            JOBR Marketplace
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Allow customers to book this Product on JOBR
                            Marketplace app.
                          </h3>
                        </div>
                      </div>
                    </div>
                    {marketCheck && (
                      <div className="productAvailOption_Wrapper">
                        <div className="productAvailabilityChild mt-3 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="pickup"
                              checked={deliveryOptions.includes("pickup")}
                              onChange={handleDeliveryOptionToggle("pickup")}
                            />
                            <label for="pickup"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              {t("pickup")}
                            </h3>
                          </div>
                        </div>
                        <div className="productAvailabilityChild mt-3 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="delivery"
                              checked={deliveryOptions.includes("delivery")}
                              onChange={handleDeliveryOptionToggle("delivery")}
                            />
                            <label for="delivery"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              {t("delivery")}
                            </h3>
                          </div>
                        </div>
                        <div className="productAvailabilityChild mt-3 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="shipping"
                              checked={deliveryOptions.includes("shipping")}
                              onChange={handleDeliveryOptionToggle("shipping")}
                            />
                            <label for="shipping"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              {t("shipping")}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="productAvail_Second ">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.adultIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                        style={{
                          width: "21px",
                          height: "21px",
                        }}
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input
                            type="checkbox"
                            checked={isAdult}
                            onChange={(e) => {
                              setIsAdult(e.target.checked);
                            }}
                          />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            {t("isAdultProduct")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("adultProductCaption")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="chartUploadBox mb-2">
                  <h2 className="fontSize18 fw500 txtDarkblue ">
                    {t("productChart")}
                  </h2>
                  <ChartImageUploader
                    chartImage={chartImage}
                    setChartImage={setChartImage}
                  />
                </div>
              </div>
            </div>

            {/* End ProductDetails Area */}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "AddOptions" || modalDetail.flag === "EditOption"
            ? "addProductOptions"
            : modalDetail.flag === "createVariations"
            ? "createVariations"
            : modalDetail.flag === "AddAttributesModal"
            ? "addAttributesModal"
            : modalDetail.flag === "saleTax"
            ? "saleModalTax"
            : modalDetail.flag === "deleteVariation"
            ? "addProductOptions"
            : modalDetail.flag === "editVariantModal"
            ? "editVariantModal"
            : modalDetail.flag === "addAttributeModal"
            ? "addProductOptions"
            : modalDetail.flag === "bulkVariation"
            ? "addProductOptions"
            : modalDetail.flag === "confirmModal"
            ? "confrimPriceOption"
            : ""
        }
        size={modalDetail.flag === "AddOptions" ? "xl" : "lg"}
        child={
          modalDetail.flag === "AddOptions" ||
          modalDetail.flag === "EditOption" ? (
            <AddOptionsModal
              id={serviceId}
              close={() => handleOnCloseModal()}
              onRowsChange={handleRowsChange}
              // rowData={tempRowData.length > 1 ? tempRowData : rowData}
              rowData={
                modalDetail.flag === "EditOption"
                  ? rowData
                  : tempRowData.length > 1
                  ? tempRowData
                  : rowData
              }
              optionModalDetail={modalDetail}
            />
          ) : modalDetail.flag === "createVariations" ? (
            <CreateVariationsModal
              close={() => handleOnCloseModal()}
              rowData={tempRowData}
              costPrice={costPrice}
              onCombinationChange={handleCombination}
              selectedCombinations={selectedCombinations}
            />
          ) : modalDetail.flag === "bulkVariation" ? (
            <BulkVarientionModal
              title={modalDetail.title}
              field={modalDetail.field}
              onSave={(value) => {
                handleBulkUpdate(modalDetail.field, value);
              }}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "editVariantModal" ? (
            <EditVariantionModal
              closeModal={() => handleOnCloseModal()}
              modalDetail={modalDetail}
              allAddress={allAddress}
              structureVariants={structureVariants}
              setStructureVariants={setStructureVariants}
            />
          ) : modalDetail.flag === "confirmModal" ? (
            <ConfirmationModal
              onConfirm={handleConfirmNegativeMargin}
              close={() => handleOnCloseModal()}
            />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "AddOptions" ||
            modalDetail.flag === "EditOption" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {modalDetail.flag === "EditOption"
                    ? "Edit Options"
                    : "Add Options"}
                </h3>
                <div className="d-flex align-items-center">
                  <button
                    className="modalDiscardBtn"
                    onClick={() => {
                      handleOnCloseModal();
                      if (
                        rowData.length >= 1 &&
                        rowData[0].displayName !== "" &&
                        selectedCombinations.length > 0
                      ) {
                        setAttributeShow(true);
                      } else {
                        setRowData([
                          {
                            id: 1,
                            activePlan: "list", // Default active plan
                            displayName: "", // Reset display name
                            productTags: [], // Empty productTags array
                          },
                        ]);
                        setAttributes(false);
                      }
                    }}
                  >
                    {t("discard")}
                  </button>
                  <button
                    className="modalNextBtn modalDiscardBtn active"
                    onClick={handleOpenVariation}
                  >
                    {modalDetail?.flag == "EditOption" ? "Save" : "Next"}
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "createVariations" ? (
              <>
                <div className="d-flex align-items-center">
                  <button
                    className="modalBackBtn"
                    onClick={() => {
                      handleOnCloseModal();
                      // setSelectedCombinations([]);
                      setModalDetail({
                        show: true,
                        flag: "AddOptions",
                      });
                      // setAttributeShow(false);
                    }}
                  >
                    <img src={Images.blueBackArrow} alt="logo" />
                  </button>
                  <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                    Create variations
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className="modalNextBtn modalDiscardBtn active createVariationBtn"
                    onClick={() => {
                      if (newCombinations.length <= 0) {
                        showToast("please select variation");
                        return;
                      }
                      handleOnCloseModal();
                      setAttributeShow(true);
                      setSelectedCombinations(newCombinations);
                      setRowData(tempRowData);
                      setTempRowData([]);
                    }}
                  >
                    Create {newCombinations.length} variations
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "bulkVariation" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {modalDetail.title}
                </h3>
                <button
                  className="modalNextBtn modalDiscardBtn active"
                  onClick={handleOnCloseModal}
                >
                  close
                </button>
              </>
            ) : modalDetail.flag === "confirmModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-center w-100">
                  <img
                    src={Images?.confirmImg}
                    alt="confirm image"
                    className="img-fluid"
                  />
                </div>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer confirmCancel"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : (
              <></>
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default EditProduct;
