import React, { useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getLeastSellingApiAsync,
  getProductServiceListApiAsync,
  getRecentProductApiAsync,
  getTopSellingApiAsync,
} from "./productSlice";
import SelectMenu from "../../components/UI/Orders/SelectMenu";
import ProductSubTable from "./productSubTable";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const ProductList = (props) => {
  const { t } = useTranslation();
  const { seller_id } = { ...props };
  const location = useLocation();
  const productListType = location?.state?.category;
  const [productList, setProductList] = useState([]);

  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [subcategoryData, setSubCategoryData] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [searchBrandValue, setSearchBrandValue] = useState();
  const [brand, setBrand] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    isOpen: false,
    isBrandOpen: false,
    isSubCategory: false,
  });

  const [brandIds, setBrandIds] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [selectedOption, setSelectedOption] = useState(productListType);

  const [selectPageSize, setSelectPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);

  const auth = useSelector(selectLoginAuth);
  const containerRef = useRef(null);
  const brandRef = useRef(null);
  const containerSubRef = useRef(null);
  const dispatch = useDispatch();

  const resultSelectOption = [
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
  ];

  const productTypeOption = [
    { value: null, label: "None" },
    { value: "top", label: "Top Selling" },
    { value: "least", label: "Least Selling" },
    // { value: "recent", label: "Recent Selling" },
  ];

  const handleProductType = (selected) => {
    setSelectedOption(selected.value);
  };

  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      const newPage = prev + value;
      if (newPage >= 1 && newPage <= totalPages) {
        return newPage;
      }
      return prev;
    });
  };
  const handleCategoryChange = (categoryId) => {
    let updatedCategoryIds = [...selectedCategoryIds];
    if (updatedCategoryIds.includes(categoryId)) {
      updatedCategoryIds = updatedCategoryIds.filter((id) => id !== categoryId);
    } else {
      updatedCategoryIds.push(categoryId);
    }
    setSelectedCategoryIds(updatedCategoryIds);
  };

  const handleSubcategoryChange = (subCategoryId) => {
    let filterData = [...subCategoryIds];
    if (filterData?.includes(subCategoryId)) {
      filterData = filterData?.filter((val) => val != subCategoryId);
      setSubCategoryIds(filterData);
    } else {
      filterData = [...subCategoryIds, subCategoryId];
      setSubCategoryIds(filterData);
    }
  };

  const fetchAllCategories = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        // with_subcategories: true,
        service_type: "product",
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
    };
    if (searchCategory) {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          ...data.postData,
          search: searchCategory,
          // check_product_existance: false,
        },
      };
    }
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const fetchAllSubCategories = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        with_subcategories: true,
        // need_subcategory:true,
        service_type: "product",
        category_ids: selectedCategoryIds?.join(",") || [],
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
    };
    if (searchSubCategory) {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          ...data.postData,
          // search: searchSubCategory,
          search_subcategories: searchSubCategory,
          // check_product_existance: false,
        },
      };
    }
    // setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        // setLoading(false);
        setSubCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const fetchAllBrand = () => {
    let brandData;
    if (searchBrandValue && !subCategoryIds?.length > 0) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          search: searchBrandValue,
          check_product_existance: true,
        },
      };
    } else if (subCategoryIds?.length > 0 && searchBrandValue) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          search: searchBrandValue,
          category_id: subCategoryIds.toString(),
          check_product_existance: true,
        },
      };
    } else if (subCategoryIds?.length > 0 && !searchBrandValue) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          category_id: subCategoryIds.toString(),
          check_product_existance: true,
        },
      };
    } else {
      brandData = {
        tokenData: auth?.payload?.token,
        check_product_existance: true,
      };
    }
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setBrand(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllBrand();
  }, [searchBrandValue, subCategoryIds]);

  useEffect(() => {
    fetchAllCategories();
  }, [searchCategory]);

  useEffect(() => {
    fetchAllSubCategories();
  }, [searchSubCategory, selectedCategoryIds]);

  const handleBrandChange = (brandId) => {
    let filterData = [...brandIds];
    if (filterData?.includes(brandId)) {
      filterData = filterData?.filter((val) => val != brandId);
      setBrandIds(filterData);
    } else {
      filterData = [...brandIds, brandId];
      setBrandIds(filterData);
    }
  };

  const handleToggle = (type) => {
    setDropdownState((prevState) => {
      const newstate = { ...prevState };
      if (type == "isOpen") {
        newstate.isOpen = !prevState.isOpen;
        newstate.isBrandOpen = false;
        newstate.isSubCategory = false;
      } else if (type == "isBrandOpen") {
        newstate.isOpen = false;
        newstate.isBrandOpen = !prevState.isBrandOpen;
        newstate.isSubCategory = false;
      } else if (type == "isSubCategory") {
        newstate.isOpen = false;
        newstate.isBrandOpen = false;
        newstate.isSubCategory = !prevState.isSubCategory;
      }
      return newstate;
    });
  };

  const getProductList = (page) => {
    setProductLoading(true);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "physical",
        app_name: "merchant",
        lat: 32.5534,
        long: 76.1258,
        category_ids: selectedCategoryIds?.join(",") || [],
        sub_category_ids: subCategoryIds.toString() || [],
        brand_id: brandIds.toString() || [],
        page: currentPage,
        limit: selectPageSize,
        search: keyword || undefined,
      },
    };
    dispatch(getProductServiceListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setProductLoading(false);
        setProductList(obj?.payload?.data);
        setTotalProduct(obj?.payload?.total);
        // setSelectPageSize(obj?.payload?.per_page || selectPageSize); // Use API response if available
        // setTotalPages(Math.ceil(obj?.payload?.total / selectPageSize));
        setTotalPages(
          Math.ceil(
            obj?.payload?.total / (obj?.payload?.per_page || selectPageSize)
          )
        );
      })
      .catch((obj) => {
        setProductLoading(false);
      });
  };

  const getTopSellingProduct = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "product",
      category_ids: selectedCategoryIds?.join(",") || [],
      sub_category_ids: subCategoryIds.toString() || [],
      brand_id: brandIds.toString() || [],
      page: currentPage,
      limit: selectPageSize,
      search: keyword || undefined,
    };

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setProductLoading(true);
    dispatch(getTopSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setProductLoading(false);
        setProductList(
          Array.isArray(obj?.payload?.data)
            ? obj.payload.data.map((value) => value?.product_details)
            : [obj?.payload?.data?.product_details] // Wrap in array for consistency
        );
        setTotalProduct(obj?.payload?.total);
        setTotalPages(
          Math.ceil(
            obj?.payload?.total / (obj?.payload?.per_page || selectPageSize)
          )
        );
      })
      .catch((obj) => {
        setProductLoading(false);
      });
  };

  const getLeastSellingProduct = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "product",
      category_ids: selectedCategoryIds?.join(",") || [],
      sub_category_ids: subCategoryIds.toString() || [],
      brand_id: brandIds.toString() || [],
      page: currentPage,
      limit: selectPageSize,
      search: keyword || undefined,
    };
    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setProductLoading(true);
    dispatch(getLeastSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setProductLoading(false);
        setProductList(
          Array.isArray(obj?.payload?.data)
            ? obj.payload.data.map((value) => value?.product_details)
            : [obj?.payload?.data?.product_details] // Wrap in array for consistency
        );

        setTotalProduct(obj?.payload?.total);
        setTotalPages(
          Math.ceil(
            obj?.payload?.total / (obj?.payload?.per_page || selectPageSize)
          )
        );
      })
      .catch((obj) => {
        setProductLoading(false);
      });
  };

  const getRecentList = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "product",
      app_name: "merchant",
      need_new_releases: true,
      category_ids: selectedCategoryIds?.join(",") || [],
      sub_category_ids: subCategoryIds.toString() || [],
      brand_id: brandIds.toString() || [],
      page: currentPage,
      limit: selectPageSize,
      search: keyword || undefined,
    };

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };
    setProductLoading(true);
    dispatch(getRecentProductApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setProductLoading(false);
        setProductList(obj?.payload?.data);
        setTotalProduct(obj?.payload?.total);
        setTotalPages(
          Math.ceil(
            obj?.payload?.total / (obj?.payload?.per_page || selectPageSize)
          )
        );
      })
      .catch((obj) => {
        setProductLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedOption || selectedOption === null) {
      getProductList();
      return;
    }

    switch (selectedOption) {
      case "top":
        getTopSellingProduct();
        break;
      case "least":
        getLeastSellingProduct();
        break;
      case "recent":
        getRecentList();
        break;
      default:
        getProductList();
    }
  }, [
    productListType,
    currentPage,
    selectPageSize,
    selectedCategoryIds,
    subCategoryIds,
    brandIds,
    keyword,
    selectedOption,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the specific dropdown if the click is outside of it
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        dropdownState.isOpen
      ) {
        setDropdownState((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      }

      if (
        containerSubRef.current &&
        !containerSubRef.current.contains(event.target) &&
        dropdownState.isSubCategory
      ) {
        setDropdownState((prevState) => ({
          ...prevState,
          isSubCategory: false,
        }));
      }

      if (
        brandRef.current &&
        !brandRef.current.contains(event.target) &&
        dropdownState.isBrandOpen
      ) {
        setDropdownState((prevState) => ({
          ...prevState,
          isBrandOpen: false,
        }));
      }
    };

    // Add event listener for mousedown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownState]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Product";
  }, []);

  return (
    <>
      <DashboardNavbar
        title={t("products")}
        backShow={false}
        setKeyword={setKeyword}
      />
      <div className="walletStats h-100">
        <div className="productOfferHead_Container productListHeader">
          <div className="row align-items-center">
            <div className="col-xxl-7 mb-3 mb-xxl-0">
              <div className="row">
                <div className="col-sm-3 ps-sm-0 mb-2 mb-sm-0 ">
                  <div className="productMainCategory">
                    <div className="productMainbox_" ref={containerRef}>
                      <button
                        className="filterTab_"
                        onClick={() => handleToggle("isOpen")}
                      >
                        {t("category")}
                        <span>
                          <i
                            className="fal fa-chevron-down dropDownArrow"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {dropdownState.isOpen ? (
                        <div className="filterDropdown_">
                          <div className="d-flex align-items-center justify-content-between pt-2 pb-4">
                            <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                              {t("category")}: {categoryData?.length || 0}
                            </h3>
                            <h3
                              className="fontSize16 fw500 txtLightBlue mb-0 categoryDropClearTxt cursorPoint"
                              onClick={() => {
                                setSelectedCategoryIds([]);
                              }}
                            >
                              {t("clear")}
                            </h3>
                          </div>
                          <div className="search_group_parent">
                            <div className="input-group search_group mb-2">
                              <img
                                src={Images.SearchIcon}
                                alt=""
                                className="img-fluid searchIcon"
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("searchHere")}
                                value={searchCategory}
                                onChange={(e) =>
                                  setSearchCategory(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                {categoryData?.length > 0 ? (
                                  categoryData?.map((val, index) => {
                                    return (
                                      <div
                                        className="listProductbody_ mt-3"
                                        key={index}
                                      >
                                        {/* Category selection with checkbox */}
                                        <div className="productAvailabilityChild align-items-center">
                                          <div className="form-group">
                                            <input
                                              type="checkbox"
                                              id={`category-${index}`}
                                              checked={selectedCategoryIds?.includes(
                                                val?.id
                                              )}
                                              onChange={() =>
                                                handleCategoryChange(val?.id)
                                              }
                                            />
                                            <label
                                              htmlFor={`category-${index}`}
                                              className="me-0 mainboldHead"
                                            >
                                              {val?.name}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="text-center txtDarkblue pt-3">
                                    {t("noCategoriesFound")}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 ps-sm-0 mb-2 mb-sm-0">
                  <div className="productMainCategory">
                    <div className="productMainbox_" ref={containerSubRef}>
                      <button
                        className="filterTab_"
                        onClick={() => handleToggle("isSubCategory")}
                      >
                        {t("subCategory")}
                        <span>
                          <i
                            className="fal fa-chevron-down dropDownArrow"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {dropdownState.isSubCategory ? (
                        <div className="filterDropdown_">
                          <div className="d-flex align-items-center justify-content-between pt-2 pb-4">
                            <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                              {t("subCategory")}
                            </h3>
                            <h3
                              className="fontSize16 fw500 txtLightBlue mb-0 categoryDropClearTxt cursorPoint"
                              onClick={() => {
                                setSubCategoryIds([]);
                              }}
                            >
                              {t("clear")}
                            </h3>
                          </div>
                          <div className="search_group_parent">
                            <div className="input-group search_group mb-2">
                              <img
                                src={Images.SearchIcon}
                                alt=""
                                className="img-fluid searchIcon"
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("searchHere")}
                                value={searchSubCategory}
                                onChange={(e) =>
                                  setSearchSubCategory(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                {subcategoryData?.length > 0 ? (
                                  subcategoryData?.map((val, index) => {
                                    return (
                                      <div
                                        className="listProductbody_ mt-3"
                                        key={index}
                                      >
                                        <h3 className="mainboldHead">
                                          {val?.name}
                                        </h3>
                                        <ul className="listItemsprodct_">
                                          {val?.sub_catgories?.map(
                                            (data, subIndex) => {
                                              return (
                                                <li
                                                  className={`textParagh ${
                                                    subCategoryIds?.includes(
                                                      data?.id
                                                    )
                                                      ? "selectProduct"
                                                      : ""
                                                  } `}
                                                  key={`${index}-${subIndex}`}
                                                >
                                                  <div className="productAvailabilityChild align-items-center">
                                                    <div className="form-group">
                                                      {/* Ensure unique ID for each checkbox */}
                                                      <input
                                                        type="checkbox"
                                                        id={`subCategory-${data?.id}`}
                                                        checked={subCategoryIds.includes(
                                                          data?.id
                                                        )} // Reflect correct state
                                                        onChange={() =>
                                                          handleSubcategoryChange(
                                                            data?.id
                                                          )
                                                        } // Bind change handler
                                                      />
                                                      <label
                                                        htmlFor={`subCategory-${data?.id}`}
                                                        className="me-0"
                                                      ></label>
                                                    </div>
                                                  </div>
                                                  {/* Display subcategory name */}
                                                  {data?.name?.slice(0, 30)}
                                                  {data?.name?.length >= 30
                                                    ? "..."
                                                    : ""}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="text-center txtDarkblue pt-3">
                                    {t("noCategoriesFound")}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 ps-sm-0">
                  <div
                    className="productMainbox_ productBrandSelect"
                    ref={brandRef}
                  >
                    <button
                      className="filterTab_"
                      onClick={() => handleToggle("isBrandOpen")}
                    >
                      {t("brand")}
                      <span>
                        <i
                          className="fal fa-chevron-down dropDownArrow"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </button>
                    {dropdownState.isBrandOpen ? (
                      <div className="filterDropdown_">
                        <div className="d-flex align-items-center justify-content-between pt-2 pb-4">
                          <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                            {t("brand")}
                          </h3>
                          <h3
                            className="fontSize16 fw500 txtLightBlue mb-0 categoryDropClearTxt cursorPoint"
                            onClick={() => {
                              setBrandIds([]);
                            }}
                          >
                            {t("clear")}
                          </h3>
                        </div>
                        <div className="search_group_parent">
                          <div className="input-group search_group mb-2">
                            <img
                              src={Images.SearchIcon}
                              alt=""
                              className="img-fluid searchIcon"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("searchHere")}
                              value={searchBrandValue}
                              onChange={(e) =>
                                setSearchBrandValue(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="listdropfilterList_">
                          {loading ? (
                            <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                          ) : (
                            <>
                              {brand?.length > 0 ? (
                                <div className="listProductbody_ mt-3">
                                  <ul className="listItemsprodct_">
                                    {brand?.map((data, index) => {
                                      return (
                                        <li
                                          className="textParagh mt-2 mb-2"
                                          key={index}
                                        >
                                          <div className="productAvailabilityChild align-items-center">
                                            <div className="form-group">
                                              <input
                                                type="checkbox"
                                                id={`brand-${data?.id}`}
                                                checked={brandIds?.includes(
                                                  data?.id
                                                )} // Ensure checked state is accurate
                                                onChange={() =>
                                                  handleBrandChange(data?.id)
                                                } // Change handler for brand selection
                                              />
                                              <label
                                                htmlFor={`brand-${data?.id}`}
                                                className="me-0 mainboldHead"
                                              >
                                                {data?.name?.slice(0, 30)}
                                                {data?.name?.length >= 30
                                                  ? "..."
                                                  : ""}
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              ) : (
                                <div className="text-center txtDarkblue pt-3">
                                  {t("noBrandFound")}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-sm-3 ps-sm-0">
                  <div className="reactSelectParent w-100 productSelectFilter">
                    <SelectMenu
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxWidth: "100% !important",
                        }),
                      }}
                      options={productTypeOption}
                      placeholder={t("selectType")}
                      value={productTypeOption.find(
                        (opt) => opt.value === selectedOption
                      )}
                      onChange={handleProductType}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-5">
              <div className="d-flex justify-content-end gap-3 flex-wrap">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {t("showingResult")}
                  </h3>
                  <div className="reactSelectParent offerHeaderSelect">
                    <SelectMenu
                      options={resultSelectOption}
                      placeholder="10"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSelectPageSize(Number(e.value)); // Ensure value is a number
                      }}
                    />
                  </div>
                </div>

                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 ? "disablePrevbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(-1)}
                      disabled={currentPage <= 1}
                    >
                      <img src={Images.arrowDoubleLeft} alt="arrowDoubleLeft" />
                    </div>
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 ? "disablePrevbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(-1)}
                      disabled={currentPage <= 1}
                    >
                      <img src={Images.lightArrowLeft} alt="arrowLeft" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {/* {(currentPage - 1) * selectPageSize + 1}-
                    {Math.min(currentPage * selectPageSize, totalProduct)} of{" "}
                    {totalProduct} */}
                    {currentPage ? (currentPage - 1) * selectPageSize + 1 : 1}-
                    {Math.min(
                      currentPage && selectPageSize
                        ? currentPage * selectPageSize
                        : 0,
                      totalProduct || 0
                    )}{" "}
                    of {totalProduct || 0}
                  </h6>
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages ? "disableNextbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(1)}
                      disabled={currentPage === totalPages}
                    >
                      <img src={Images.blueArrowRight} alt="arrowRight" />
                    </div>
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages ? "disableNextbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(1)}
                      disabled={currentPage === totalPages}
                    >
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProductSubTable
          productList={productList}
          productLoading={productLoading}
          getProductList={getProductList}
        />
      </div>
    </>
  );
};

export default ProductList;
