import React, { useState } from "react";
import * as Images from "../../../utilities/images";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteRoleApiAsync,
  getRoleApiAsync,
  selectLoginAuth,
} from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DeleteModal = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center">
        <img src={Images.crossCircle} alt="cancleLogo" />
        <div className="stopOfferModalContainer">
          <h3 className="stopOfferTxt mb-0">
            You are going to delete this {" "}
            {props?.flag === "deletePosUser"
              ? "Pos User"
              : props?.flag === "deleteSupport"
              ? "Support"
              : "role"}
            !
          </h3>
        </div>

        <div className="d-flex align-items-center editVariantModal_Footer">
          <button
            className="modalDiscardBtn w-100"
            onClick={() => {
              props?.close();
            }}
          >
            {t('cancel')}
          </button>
          <button
            className="modalNextBtn modalDiscardBtn modalStopBtn w-100"
            onClick={
              props?.flag === "deletePosUser"
                ? () => props?.deletePosUserHandle(props?.id)
                : props?.flag === "deleteSupport"
                ? () => props?.deleteSupport(props?.selectedProduct?.id)
                : () => props?.deleteHandle(props?.selectedProduct?.id)
            }
            disabled={props?.loadingState}
          >
            {props?.loadingState && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <img src={Images.newDeleteIcon} alt="stopIcon" className="me-2" />
            {t('delete')}
          </button>
        </div>
      </div>

      {/* <div className="addingNewiventory_">
        <h2 className="mainBoldHeading">
          <span className="blacksemiHead_">
            You are going to delete this
            {props?.flag === "deletePosUser"
              ? "Pos User"
              : props?.flag === "deleteSupport"
              ? "Support"
              : "role"}
            !
          </span>
        </h2>
        <p className="subheadingBlack_">Are You Sure?</p>
        <div className="inventButton mt-5">
          <button onClick={() => props.close()} className="declineBtn me-3">
            No
          </button>
          <button
            onClick={
              props?.flag === "deletePosUser"
                ? () => props?.deletePosUserHandle(props?.id)
                : props?.flag === "deleteSupport"
                ? () => props?.deleteSupport(props?.selectedProduct?.id)
                : () => props?.deleteHandle(props?.selectedProduct?.id)
            }
            className="acceptBtn_"
            disabled={props?.loadingState}
          >
            {props?.loadingState && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            <span>Yes</span>
          </button>
        </div>
      </div> */}
    </>
  );
};

export default DeleteModal;
