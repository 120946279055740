import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getSocket } from "../../../components/shared/socketHandle";
import {
  BusinessOptions,
  CustomerOptions,
  QuickMessageTemplte,
  socketUrl,
} from "../../../constants/messageBox/inbox.constant";
import {
  acceptIncomingRequestApiAsync,
  deleteChatAsync,
  deleteSingleMessageApiAsync,
  getMessageheadsAsync,
  getMessageListAsync,
  removeNotificationApiAsync,
  updateUserParticipatnsApiAsync,
} from "../messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { createMultiChatHeadsApiAsync } from "../../dashboard/dashboardSlice";
import * as Images from "../../../../utilities/images";
import moment from "moment-timezone";
import Loader from "../../../components/UI/Loader/Loader";
import AddMultiConsumers from "./AddMultiConsumers";
import { Dropdown } from "react-bootstrap";
import CustomModal from "../../../components/shared/CustomModal";
import UploadphotoModal from "./uploadphotoModal";
import VideocallModal from "./videocallModal";
import VideoscheduleModal from "./videoscheduleModal";
import FileuploadModal from "./fileuploadModal";
import BusinesscardModal from "./BusinesscardModal";
import VoicerecordModal from "./voicerecordModal";
import ShippingAddressModal from "./shippingaddressModal";
import TranslatorModal from "./translatorModal";
import SuccessfullAddCustomerModal from "./addCustomerModal";
import DeleteCustomerModal from "../../../components/shared/modals/deleteCustomerModal";
import UploadFileModal from "./UploadFileModal";
import AcceptChatRequest from "./AcceptChatRequest";
import PromotionalOffer from "./PromotionalOffers";
import CatalogModal from "./Catalog";
import {
  OfferCard,
  ShowAddress,
  ShowAudio,
  ShowBusinessCard,
  ShowCatalogs,
  ShowChat,
  ShowChat2,
  ShowImages,
} from "./Helper";
import UsersChatList from "./UsersChatList";
import CurrentChatHeader from "./CurrentChatHeader";
import ChatContainer from "./ChatContainer";
import ChatFooter from "./ChatFooter";
import CustomModalHeader from "./CustomModalHeader";

const DirectMessage = () => {
  // socket
  const [socketInstance, setSocketInstance] = useState(null);
  const socketRef = useRef(null);
  const [getScrollHeight, setScrollHeight] = useState("");

  //chat
  const [totalChatPageLimit, setTotalChatPageLimit] = useState(20);
  const [currentChatPage, setCurrentChatPage] = useState(1);
  const [totalChatPage, setTotalChatPage] = useState(1);
  // users
  const [userId, setUserId] = useState(null);
  const [recieverId, setRecieverId] = useState();
  // users List
  const [usersList, setUsersList] = useState([]);
  const [totalUserPageLimit, setTotalUserPageLimit] = useState(10);
  const [currentUserPage, setCurrentUserPage] = useState(1);
  const [isLoadingOldChat, setisLoadingOldChat] = useState(false);
  const [totalUsersPage, setTotalUsersPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  //current users chat
  const [chatList, setChatList] = useState({});
  const [chatId, setChatId] = useState(null);
  //recipient Details
  const [currentRecipientDetails, setcurrentRecipientDetails] = useState(null);
  const [recipientStatus, setrecipientStatus] = useState(null);
  const [recipientStatusRef, setrecipientStatusRef] = useState(null);
  // search users
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchkeywordTimeout, setSearchkeywordTimeout] = useState(null);
  const [searchUserList, setSearchUserList] = useState([]);
  //toggle tabs
  const [isB2b, setisB2b] = useState("true");
  const [isInvitingUsers, setIsInvitingUsers] = useState(false);
  //send message
  const [message, setMessage] = useState("");
  const [issending, setIssending] = useState(false);
  const [quickMess, setQuickMess] = useState(false);
  const [DialogOptions, setDialogOptions] = useState(BusinessOptions);
  const [isStartSearching, setisStartSearching] = useState(false);
  const [allUsersChatId, setallUsersChatId] = useState([]);
  const [isToggled, setisToggled] = useState(false);
  // const [isToggled, setisToggled] = useState(second)
  const [isUploadingAudio, setisUploadingAudio] = useState(false)
  //ref
  const toastId = React.useRef(null);
  const chatRef = React.useRef();
  const usersListRef = React.useRef(usersList);
  const chatListRef = useRef(chatList);
  const chatIdRef = useRef(chatId);
  const isFetching = useRef(false); // Prevent multiple fetch calls
  const isChatFetching = useRef(false); // Prevent multiple fetch calls
  const [hasMoreChats, setHasMoreChats] = useState(true);
  const usersRef = useRef();

  const newUserRef = React.useRef(null);
  //user auth
  const auth = useSelector(selectLoginAuth);
  const sellerType = auth?.payload?.user?.user_profiles?.seller_type;
  const dispatch = useDispatch();

  //loading
  const [loadingUserList, setloadingUserList] = useState(false);
  const [loadingChatList, setloadingChatList] = useState(false);
  //invite multiple Users
  const [inviteMultipleUsersList, setinviteMultipleUsersList] = useState([]);
  const [isInviting, setisInviting] = useState(false);
  const [firstLoad, setfirstLoad] = useState(false);
  //let user participants details
  const [userParticipantDetails, setuserParticipantDetails] = useState({});
  const [loadNewUser, setLoadNewUser] = useState(null);

  //remove user notification
  const [isCurrentUserNotification, setIsCurrentUserNotification] =
    useState(false);
  // custom modals
  const [key, setKey] = useState(Math.random());
  const [key2, setKey2] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [modalDetail2, setModalDetail2] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleOnCloseOfferModal = () => {
    setModalDetail2({
      show: false,
      title: "",
      flag: "",
    });
    setKey2(Math.random());
  };
  const handleOnOpenOfferModal = (flag) => {
    setModalDetail2({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey2(Math.random());
  };
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  useEffect(()=>{
    let isSuperAdmin=auth?.payload?.user?.user_roles?.some(
      (e) => e?.role?.slug === "admin"
    );
    if(isSuperAdmin){
      setIsAdmin(true);
    }else{
      
    }
  },[auth?.payload])
  //get user list
  const getUsersList = async (id,customParams = {}) => {
    if (isFetching.current ||!userId) return;
    try {
      isFetching.current = true;
      setloadingUserList(true);
      let params = {
        postData: {
          user_id: userId,
          page: currentUserPage,
          limit: totalUserPageLimit,
          search: searchKeyword.length > 0 ? searchKeyword : undefined,
          consumer_type: isB2b == "true" ? "b2b" : "b2c",
          headtype: "directchat",
          ...((chatId==null&&isAdmin==false)&&{
           recipient_id: "b169ed4d-be27-44eb-9a08-74f997bc6a2e",

          }),
          ...customParams,
        },
        tokenData: auth?.payload?.token,
      };
      const result = await dispatch(getMessageheadsAsync(params)).then(
        unwrapResult
      );
      //
      if (customParams?.recipient_id) {
        handleNewUserData(result?.payload?.data);
      } else {
        handleUsersListData(
          result?.payload?.data,
          result?.payload?.total_pages
        );
      }
    } catch (error) {
      toast.error("Failed to load users");
    } finally {
      isFetching.current = false;
      setloadingUserList(false);
    }
  };
  const handleNewUserData = (data) => {
    if (usersListRef.current.length == 0) {
      handleUsersListData(data, 1);
      return;
    }
    let chatHeadId = data?.[0]?.chats?.chathead_id;
    let isExist = allUsersChatId?.find((id) => id === loadNewUser);
    newUserRef.current = null;
    if (!isExist) {
      let findIndex = findLastPin();
      if (findIndex > -1) {
        //add user after this index  from users list
        usersList.splice(findIndex + 1, 0, ...(data || []));
        allUsersChatId.splice(findIndex + 1, 0, chatHeadId);
      } else {
        setUsersList((prev) => [...prev, ...(data || [])]);
        setallUsersChatId((prev) => [...prev, chatHeadId]);
      }
    } else {
      // update chat message only
      updateUsersList(chatHeadId, { chats: data.chats }, false);
    }
  };
  const handleUsersListData = (data, totalPages) => {
    let users = data || [];
    let totalUsers = totalPages;
    let ids = users?.map((user) => user.chats?.chathead_id);
    if (currentUserPage > 1) {
      setallUsersChatId((prev) => [...prev, ...ids]);
      setUsersList((prev) => [...prev, ...users]);
    } else {
      setallUsersChatId(ids);
      setUsersList(users);
    }
    setTotalUsersPage(totalUsers);
    //current user chat
    if (users?.length > 0 && !chatId) {
      getCurrentUserDetails(users[0], null);
    }
  };
    useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Inbox Message";
  }, []);
  //get users chat
  const getUsersChatList = async () => {
    if (isChatFetching.current) return;
    isChatFetching.current = true;
    let params = {
      postData: {
        id: chatId,
        idtype: "chathead",
      },
      queryData: {
        page: currentChatPage,
        limit: totalChatPageLimit,
      },
      tokenData: auth?.payload?.token,
    };
    const chatContainer = chatRef.current;
    const scrollTopBeforeLoad = chatContainer?.scrollTop;
    if (currentChatPage == 1) setloadingChatList(true);
    dispatch(getMessageListAsync(params))
      .then(unwrapResult)
      .then((res) => {
        let chats = res?.payload?.data || [];
        let totalChats = res?.payload?.total_pages || 1;
        setChatList(filterChatsByTime(chats, currentChatPage > 1));
        setTotalChatPage(totalChats);
        setloadingChatList(false);
        isChatFetching.current = false;
        //  if(scrollTopBeforeLoad){
        const newScrollHeight = chatContainer.scrollHeight;
        if (newScrollHeight) {
          setScrollHeight(newScrollHeight);
        }
        const pageHeight = newScrollHeight / currentChatPage; // Divide the total height by currentPage

        // Adjust scrollTop to keep the same relative position after loading new chats
        // chatContainer.scrollTop = scrollTopBeforeLoad + pageHeight;
        if (getScrollHeight?.toString().length) {
          chatContainer.scrollTop = Number(Math.max(getScrollHeight,newScrollHeight));
        } else {
          chatContainer.scrollTop = pageHeight;
        }
        if (currentChatPage > 1) {
          setisLoadingOldChat(true);
        }
      })
      .catch((err) => {
        isChatFetching.current = false;
        setloadingChatList(false);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Unable to find user chat");
        }
      });
  };
  const checkIfRecipientActive = (id) => {
    if (!id) return;
    socketInstance?.emit("is_recipient_active", { recipientId: id });
  };
  const getCurrentUserDetails = (currentRecipientUser, id) => {
    //current reciver
    setcurrentRecipientDetails(currentRecipientUser);
    let currentUser = userId ? userId : id;
    let sender = currentRecipientUser?.sender_id;
    let recieverId = currentRecipientUser?.recipient_id;
    let recipientId = sender == currentUser ? recieverId : sender;
    setRecieverId(recipientId);
    setrecipientStatus(currentRecipientUser.recipient_status);
    // recipientStatusRef.current=currentRecipientUser.recipient_status;
    //chat head id
    let chatHeadId = currentRecipientUser?.chats?.chathead_id;

    if (chatId) {
      leaveRoom(chatId);
    }
    checkIfRecipientActive(recipientId);
    setChatList({});
    setChatId(chatHeadId);
    //set participants
    getParticipantsDetails(currentRecipientUser?.participants);
    //check notifications
    let userNotifications =
      currentRecipientUser?.chats?.participants?.[currentUser]
        ?.notificationCount;
    if (userNotifications) {
      setIsCurrentUserNotification(true);
      setuserParticipantDetails((prev) => ({
        ...prev,
        notification: userNotifications,
      }));
    }
  };
  // Scroll Event Listener
  useEffect(() => {
    const chatContainer = chatRef.current;
    if (!chatContainer) return;
    const handleScroll = () => {
      if (chatContainer.scrollTop == 0) {
        if (totalChatPage > currentChatPage && !isChatFetching.current)
          setCurrentChatPage((prev) => prev + 1);
      }
    };

    chatContainer.addEventListener("scroll", handleScroll);
    return () => chatContainer.removeEventListener("scroll", handleScroll);
  }, [chatRef, totalChatPage, currentChatPage]);

  // Scroll event handler
  useEffect(() => {
    const usersContainer = usersRef.current;
    if (!usersContainer) return;

    const handleScroll = () => {
      if (
        usersContainer.scrollTop + usersContainer.clientHeight >=
        usersContainer.scrollHeight - 10
      ) {
        if (totalUsersPage > currentUserPage && !isFetching.current)
          setCurrentUserPage((prev) => prev + 1);
      }
    };
    usersContainer.addEventListener("scroll", handleScroll);
    return () => usersContainer.removeEventListener("scroll", handleScroll);
  }, [usersRef, totalUsersPage, currentUserPage]);

  useEffect(() => {
    if (totalUsersPage >= currentUserPage && currentUserPage > 1) {
      getUsersList();
    }
  }, [currentUserPage]);
  // chats
  useEffect(() => {
    if (totalChatPage >= currentChatPage && currentChatPage > 1) {
      getUsersChatList();
    }
  }, [currentChatPage]);
  const getParticipantsDetails = (participants) => {
    let users = participants?.[userId] || {};
    setuserParticipantDetails((prev) => {
      let data = { ...prev, ...users };
      return data;
    });
  };

  const updateUsersList = (
    chatHeadId,
    updatedPart,
    changeHead = true,
    updateIndex = false
  ) => {
    if (!chatHeadId) return;
    let arr = usersListRef?.current || usersList;
    let index = arr?.findIndex(
      (user) => user?.chats?.chathead_id == chatHeadId
    );
    if (index > -1) {
      let users = [...arr];
      let user = { ...users[index], ...updatedPart };
      let pinIndex = findLastPin();
      let isPinnedUser = user?.participants?.[userId]?.isPin;
      if (pinIndex > -1 && updateIndex && !isPinnedUser) {
        users.splice(index, 1);
        //add this user after that
        users.splice(pinIndex + 1, 0, user);
      } else {
        users[index] = user;
      }
      if (changeHead) setcurrentRecipientDetails(user);
      setUsersList(users);
    }
  };
  const findLastPin = () => {
    const users = usersListRef.current || [];

    for (let i = users.length - 1; i >= 0; i--) {
      if (users[i]?.participants?.[userId]?.isPin === true) {
        return i;
      }
    }

    return -1; // No pinned user found
  };
  // useEffect(() => {
  //   if (firstLoad) return;
  //   if (searchkeywordTimeout) {
  //     clearTimeout(searchkeywordTimeout);
  //   }
  //   let searchTimer = setTimeout(() => {
  //     setChatList({});
  //     if(chatId){
  //       leaveRoom(chatId)
  //     }
  //     setChatId(null);
  //     setcurrentRecipientDetails(null);
  //     //call your api here
  //     getUsersList();
  //   }, 1000);
  //   setSearchkeywordTimeout(searchTimer);
  //   return () => {
  //     clearTimeout(searchTimer);
  //   };
  // }, [searchKeyword]);

  // refactored
  useEffect(() => {
    let id = auth?.payload?.user?.unique_uuid || auth?.payload?.uniqe_id;
    setUserId(id);
  }, [auth]);
  useEffect(() => {
    if (userId) {
     let socket= initializeSocket();
     if(socket){
      if(isAdmin){
        getUsersList();
      } 
     else fetchAdminDetails()
     }
      
    }
    return () => {
      // if (socketRef.current) {
      //   socketRef.current.disconnect();
      //   socketRef.current = null;
      
      // }
    };
  }, [userId]);
  const initializeSocket = async (reInitialize) => {
    if (!userId || socketRef.current) return;
    let reinitializing = reInitialize ? "yes " : "no";

    try {
      const socket = await getSocket(userId, true);
      if (!socket) return;

      socket.on("connect", () => {
        socket.emit("personal_room", { userId });
      });

      socket.on("connect_error", (error) => {
        // Implement reconnection after delay
      });
      
     

      socket.on("disconnect", () => {
        socketRef.current = null;
      });

      socketRef.current = socket;
      setSocketInstance(socket);
      return socket;
    } catch (error) {
      toast.error("Unable to connect to the server");
      return null;
    }
  };
  useEffect(() => {
    if (chatId) {
      setMessage("");
      setCurrentChatPage(1);
      setTotalChatPage(1);
      getUsersChatList();
    }
  }, [chatId]);
const fetchAdminDetails=()=>{
  let params = {
    user_id: userId,
    consumer_type: isB2b ? "b2b" : "b2c",
  };
  getUsersList(null, params);
}
  const handleIncomingMessage = (res) => {
    let data = res?.data;
    if (!data) return;

    let senderId = data?.sender_id;
    let isExist = checkIndex(senderId);

    if (isExist > -1) {
      updateExistingUserChat(isExist, data);
    } else {
      fetchNewUserChat(senderId);
    }
  };
  const fetchNewUserChat = (senderId) => {
    //fetch single user details with recipient and chatroom id
    let params = {
      user_id: userId,
      recipient_id: senderId,
      consumer_type: isB2b ? "b2b" : "b2c",
    };
    getUsersList(null, params);
  };
  const updateExistingUserChat = (index, data) => {
    let chats = usersListRef?.current?.[index];
    if (!chats) return;
    let user = {
      chats: {
        ...chats?.chats,
        content: data?.content,
        participants: {
          ...chats?.chats?.participants,
          [userId]: {
            notificationCount: data?.participants?.[userId]?.notificationCount,
          },
        },
      },
    };
    //update
    updateUsersList(data?.chathead_id, user, false, true);
  };
  const handleError = (data) => {
    toast.error(data?.message || "An error occurred");
    setIssending(false);
  };
  const handleNewUser = (data) => {
    const chatRoomId = data?.chatRoomId;
    let senderId = data?.senderId;
    let consumer_type = data?.consumer_type || "b2b";
    if (chatRoomId && senderId) {
      //fetch single user details with recipient and chatroom id
      let params = {
        user_id: userId,
        recipient_id: senderId,
        consumer_type,
      };
      if (newUserRef.current != chatRoomId) {
        newUserRef.current = chatRoomId;
        getUsersList(null, params);
      }
    }
  };
  const handleNewMessage = (data) => {
    let currentChatId = chatIdRef.current;
    if (!data?.data || !currentChatId) return;
    setIssending(false);
    updateChatList(data.data);
    updateUsersList(currentChatId, { chats: data?.data }, true, true);
  };
  const updateChatList = (data) => {
    let chatData = chatListRef.current || {};
    if (!chatData["today"]) {
      chatData["today"] = [];
    }
    let todayArr = chatData["today"] || [];
    setChatList((prev) => ({ ...chatData, today: [...todayArr, data] }));
  };
  const handleMyMessage = async (data) => {
    if(chatId==null){
      fetchAdminDetails();
      return;
    }
    let currentChatId = chatIdRef.current;

    if (!data?.data || !currentChatId) return;
    setIssending(false);
    updateChatList(data.data);
    updateUsersList(currentChatId, { chats: data?.data }, true, true);
  };
  const handleReceiverStatus = (data) => {
    //change user current head
    setrecipientStatus(data);
    // recipientStatusRef.current=data;
    setcurrentRecipientDetails((prev) => ({ ...prev, recipient_status: data }));
  };
  const handleChatRoom = (data) => {};
  const handleRoom = (data) => {};
  useEffect(() => {
    if (!socketInstance) return;
    socketInstance?.on("receive_error", handleError);
    socketInstance.on(`new_user_${userId}_`, handleNewUser);
    socketInstance.on(`new_message_${userId}_${chatId}`, handleNewMessage);
    socketInstance.on("recieve_message", handleMyMessage);
    socketInstance.on("is_recipient_active", handleReceiverStatus);
    socketInstance.on(`jobrUSer_${chatId}_room`, handleChatRoom);
    socketInstance.on(`join_room_user`, handleRoom);
    socketInstance.on("incoming_message", handleIncomingMessage);
    socketInstance.on("offline", (data) => {
      setrecipientStatusRef(data);
    });
    socketInstance.on("online", (data) => {
      setrecipientStatusRef(data);
    });
    return () => {
      socketInstance.off("incoming_message");
      socketInstance.off(`new_message_${userId}_${chatId}`);
      socketInstance.off("recieve_message");
      socketInstance.off("receive_error");
      socketInstance.off("is_recipient_active");
      socketInstance.off(`jobrUSer_${chatId}_room`);
      socketInstance.off(`join_room_user`);
      socketInstance.off(`new_user_${userId}_`);
    };
  }, [socketInstance, userId, chatId]);
  useEffect(() => {
    if (chatId) {
      socketInstance?.emit("join_room_user", {
        chathead_id: chatId,
      });
    }
  }, [chatId]);
  useEffect(() => {
    let status = recipientStatusRef;
    if (status && status?.senderId == recipientStatus?.user_id) {
      setrecipientStatus((prev) => ({
        ...prev,
        isActive: status.isActive,
        last_seen: new Date(),
      }));
    }
  }, [recipientStatusRef]);
  // useEffect(()=>{

  //   if(socketInstance){
  //     socketInstance?.on("incoming_message",handleIncomingMessage)

  //   }

  // },[socketInstance])
  useEffect(() => {
    usersListRef.current = usersList;
  }, [usersList]);
  useEffect(() => {
    chatListRef.current = chatList;
  }, [chatList]);
  useEffect(() => {
    chatIdRef.current = chatId;
  }, [chatId]);

  const checkIndex = (senderId) => {
    let index = usersListRef?.current?.findIndex(
      (user) => user.sender_id == senderId || user.recipient_id == senderId
    );
    return index;
  };
  //remove Notifications from userChat
  const removeNotification = async () => {
    //read last message
    //dispatch call here
    setIsCurrentUserNotification(false);
    let params = {
      postData: {
        user_id: userId,
        chat_head_id: chatId,
        recipientId: recieverId,
        chatId: currentRecipientDetails?.chats?._id,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(removeNotificationApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        let user = {
          chats: {
            ...currentRecipientDetails?.chats,
            participants: {
              ...currentRecipientDetails?.chats?.participants,
              [userId]: {
                notificationCount: 0,
              },
            },
          },
        };
        //update
        updateUsersList(chatId, user);
        //update users List
      })
      .catch((err) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Unable to remove notification");
        }
      });
  };
  //delete chat
  const deleteChat = async (chatId, key) => {
    //delete message from chat
    const params = {
      id: chatId,
      tokenData: auth?.payload?.token,
    };
    dispatch(deleteSingleMessageApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        //remove from chat list
        deleteChatFromList(chatId, key);
      })
      .catch((err) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Unable to delete chat");
        }
      });
  };
  const deleteChatFromList = (chatId, key) => {
    if (!chatId) return;
    let index = chatList?.[key]?.findIndex((chat) => chat._id === chatId);
    if (index > -1) {
      let chats = chatList?.[key];
      chats.splice(index, 1);
      let chatsData = { ...chatList, [key]: chats };
      setChatList({
        ...chatsData,
      });
    } else {
    }
  };
  //delete user from chat list
  const deleteUserFromChatList = async (chatHeadId) => {
    const params = {
      id: chatHeadId,
      tokenData: auth?.payload?.token,
    };
    dispatch(deleteChatAsync(params))
      .then(unwrapResult)
      .then((res) => {
        deleteUserFromList(chatHeadId);
      })
      .catch((err) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Unable to delete user from chat");
        }
      });
  };
  const deleteUserFromList = (chatHeadId) => {
    if (!chatHeadId) return;
    let index = usersList.findIndex(
      (user) => user?.chats?.chathead_id == chatHeadId
    );
    if (index > -1) {
      let users = [...usersList];
      users.splice(index, 1);
      if (users.length > 0) {
        getCurrentUserDetails(users[0]);
      }
      setUsersList([...users]);
      if (users.length == 0) {
        setcurrentRecipientDetails(null);
        if (chatId) {
          leaveRoom(chatId);
        }
        setChatList({});
        setChatId(null);
      }
    } else {
    }
  };
  useEffect(() => {
    if (chatRef.current && !isLoadingOldChat) {
      // chatRef.current?.scrollIntoView({ behavior: "smooth" });
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    } else {
      setisLoadingOldChat(false);
    }
  }, [chatList]);
  let leaveRoom = (chatid) => {
    if (chatid && socketInstance) {
      socketInstance.emit("leave_room_user", { chathead_id: chatid });
    }
  };
  //pin user or mute user
  const manageParticipants = async (chatHeadId, key) => {
    const params = {
      postData: {
        head_id: chatHeadId,
        user_id: userId,
        value: !userParticipantDetails?.[key],
        field_type: key,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(updateUserParticipatnsApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        let makePinUser =
          key == "isPin" ? !userParticipantDetails?.[key] == true : false;
        updateUserParticipantsDetails(chatHeadId, key, false, makePinUser);
        setuserParticipantDetails((prev) => ({
          ...prev,
          [key]: !userParticipantDetails?.[key],
        }));
      })
      .catch((err) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Unable to pin user");
        }
      });
  };
  const updateUserParticipantsDetails = (
    chatHeadId,
    key,
    updateHead,
    makePinUser
  ) => {
    if (!chatHeadId) return;
    let users = {
      participants: {
        ...currentRecipientDetails?.participants,
        [userId]: {
          ...userParticipantDetails,
          [key]: !userParticipantDetails?.[key],
        },
      },
    };
    updateUsersList(chatHeadId, users, true, makePinUser);
  };
  //invite multiple users
  const inviteMultipleUSers = async (restParams) => {
    if (inviteMultipleUsersList.length == 0) {
      // give toast message
      setisInviting(false);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select at least one user");
      }
      return;
    }
    const params = {
      postData: {
        userId: userId,
        recipientIds: inviteMultipleUsersList,
        ...restParams,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(createMultiChatHeadsApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        setIssending(false);
        setisInviting(false);
        setMessage("");
        setinviteMultipleUsersList([]);
        if (res?.payload?.length >= 1) {
          let resData = res?.payload || [];
          // get all users list
          let recipientsList = inviteMultipleUsersList.map(
            (recipientId, index) => {
              return {
                roomId: resData[index]?.chathead_id,
                id: recipientId,
              };
            }
          );
          getUsersList();
          const params = {
            userId: userId,
            consumer_type: restParams.consumer_type,
            recipients: recipientsList,
          };
          if (socketInstance) {
            socketInstance.emit("new_connection_request", params);
          }
        }
      })
      .catch((err) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Unable to invite users");
        }
      });
  };
  const handleSearch = (event) => {
    if (!isStartSearching) {
      setisStartSearching(true);
    }
    let value = event.target.value;
    setSearchKeyword(value);
  };
  const handleTabChange = (value) => {
    if (!isToggled) {
      setisToggled(true);
    }
    setisB2b(value);
  };
  const handleMultipleUserIds = (userIds) => {
    setinviteMultipleUsersList(userIds);
  };
  const handleInviteUsers = () => {
    setisInviting((prev) => !prev);
  };
  const [QuickMessageOptions, setQuickMessageOptions] = useState([]);
  // use tab change here
  useEffect(() => {
    if (isB2b == "true") {
      setDialogOptions(BusinessOptions);
    } else {
      setDialogOptions(CustomerOptions);
    }
    // if (!firstLoad) {
    setUsersList([]);
    setChatList({});
    if (chatId) {
      leaveRoom(chatId);
    }
    setChatId(null);
    setcurrentRecipientDetails(null);
    getUsersList();
    setCurrentUserPage(1);
    // }
    setisToggled(false);
  }, [isB2b]);
  const handleOptions = (key) => {
    switch (key) {
      case "quick_message":
        return handleQuickMessage();
        break;
      case "promotions_offer":
        return handlePromotion();
        break;
      case "share_shipping_address":
        return handleShippingAddressDetails();
        break;
      case "share_card":
        return handleCard();
        break;
      case "catalog":
        return handleCatalogs();
        break;
    }
  };

  // send custom message
  const handleQuickMessage = () => {
    const userName =
      currentRecipientDetails?.chatPartner?.firstname +
        " " +
        currentRecipientDetails?.chatPartner?.lastname || "Customer Name";
    setQuickMessageOptions(QuickMessageTemplte(userName));
    //  quickMess
    setQuickMess(true);
  };
  // handle promotions
  const handlePromotion = () => {
    handleOnOpenOfferModal("MakeOffer");
  };
  const handleCard = () => {
    handleOpenModal("BusinessCard");
  };
  const handleCatalogs = () => {
    handleOpenModal("Catalog");
  };
  const handleShippingAddressDetails = () => {
    handleOpenModal("ShippingAddress");
  };
  //remove notification
  useEffect(() => {
    if (isCurrentUserNotification) {
      removeNotification();
    }
  }, [isCurrentUserNotification]);
  const handleSubmit = (data, flag) => {
    const params = {
      content: message === "" ? flag : message,
      chatHeadType: "directchat",
      consumer_type: isB2b === "true" ? "b2b" : "b2c",
      media_type: flag,
    };

    if (flag == "text") {
      if (!message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please enter text message");
        }
        return;
      }
    } else if (flag == "image" || flag == "doc" || flag == "audio") {
      params["media_url"] = data;
    } else if (flag == "business_card") {
      params["business_card"] = data;
    } else if (flag == "shipping_address") {
      params["shipping_address"] = data;
    } else if (flag == "offer") {
      params["offer_details"] = data;
    } else if (flag == "catalog") {
      params["catalogFiles"] = [data];
    }
    if (isInviting) {
      inviteMultipleUSers(params);
      setMessage("");
      return;
    }
    if (recieverId == userId) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Cannot send message to yourself");
      }
      return;
    }
    params["recipient_id"] = recieverId;
    params["sender_id"] = userId;
    params["chatCreatedBy"] = userId;
    checkIfRecipientActive(recieverId);
    // create chat with user
    socketInstance?.emit("send_message_to_reciever", params);
    setMessage("");
  };
   const handleAudio = async (audioBlob) => {
      // const formData = new FormData();
      // formData.append('file', audioBlob,"recording.wav");
      // const params={
      //   postData:formData,
      //   tokenData: auth?.payload?.token,
      // }
      // setLoading(true);
      //  dispatch(uploadAudioApiAsync(params))
      //  .then(unwrapResult)
      //  .then(res=>{
      //   setLoading(false);
      //   console.log("Audio uploaded",res);
      //   setaudioUrls(res?.payload)
      //   props?.audio(res?.payload,"audio")
      //  })
      //  .catch(err=>{
      //   setLoading(false);
      //   console.log("Unable to upload audio",err);
      //  })
  
      
    };


  const handleCurrentUser = (user) => {
    getCurrentUserDetails(user);
  };
  const handleIncomingRequest = () => {
    const params = {
      postData: {
        head_id: chatId,
        recipient_id: recieverId,
        sender_id: userId,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(acceptIncomingRequestApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        //update user
        let acceptRequest = {
          requestAccepted: true,
        };
        updateUsersList(chatId, acceptRequest);
      })
      .catch((err) => {
        //handle with toast
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Unable to accept  current user");
        }
      });
  };

  const filterChatsByTime = (chats, isOldChats) => {
    try {
      if (!Array.isArray(chats)) return {};

      let groupedChats = { ...chatList }; // Preserve existing chat groups
      let filterChat = isOldChats
        ? chats
        : chats?.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );

      filterChat.forEach((chat) => {
        const createdAt = moment(chat.createdAt);
        const now = moment(); // Keep now unchanged
        const yesterday = moment().subtract(1, "day");

        if (createdAt.isSame(now, "day")) {
          if (!groupedChats["today"]) groupedChats["today"] = [];
          groupedChats.today[isOldChats ? "unshift" : "push"](chat);
        } else if (createdAt.isSame(yesterday, "day")) {
          if (!groupedChats["yesterday"]) groupedChats["yesterday"] = [];
          groupedChats.yesterday[isOldChats ? "unshift" : "push"](chat);
        } else {
          const formattedDate = createdAt.format("D MMM YYYY");
          if (!groupedChats[formattedDate]) groupedChats[formattedDate] = [];
          groupedChats[formattedDate][isOldChats ? "unshift" : "push"](chat);
        }
      });

      return groupedChats;
    } catch (error) {
      return {};
    }
  };

  return (
    <>
      <div className="messagechatBox">
        {
         isAdmin&& <UsersChatList
          handleInviteUsers={handleInviteUsers}
          handleTabChange={handleTabChange}
          isB2b={isB2b}
          usersList={usersList}
          loadingUserList={loadingUserList}
          handleCurrentUser={handleCurrentUser}
          userId={userId}
          chatId={chatId}
          isChatFetching={isChatFetching}
          sellerType={sellerType}
          usersRef={usersRef}
        />
        }
        {/* right side data */}
        <div 
        // className="
        className={` ${isAdmin?'rightChatBoxContainer':"w-100"}`}>
          {isInviting ? (
            <AddMultiConsumers 
            isB2b={isB2b}
            setgetMultipleUser={handleMultipleUserIds} />
          ) : (
            (isAdmin?usersList.length:Object.keys(chatList)?.length >=0)  ? (
              <>
                {/* chat header */}
                <CurrentChatHeader
                  isSuperAdmin={isAdmin}
                  currentRecipientDetails={currentRecipientDetails}
                  manageParticipants={manageParticipants}
                  chatId={chatId}
                  handleOpenModal={handleOpenModal}
                  userId={userId}
                  recipientStatus={recipientStatus}
                />
              </>
            ):<></>
          )}

          {/* chat container */}
          {isInviting ? (
            <>
              <div
                style={{
                  height:inviteMultipleUsersList?.length?"calc(-259px + 100vh)": `calc(100vh - 224px)`,
                  // height: ,
                }}
                className="chatMessContainer"
              ></div>
            </>
          ) : (
            (isAdmin?usersList.length:Object.keys(chatList)?.length >=0) ? (
              <>
                <div
                  ref={chatRef}
                  className="chatMessContainer position-relative"
                >
                  <ChatContainer
                    loadingChatList={loadingChatList}
                    currentRecipientDetails={currentRecipientDetails}
                    userId={userId}
                    chatList={chatList}
                    deleteChat={deleteChat}
                    recipientStatus={recipientStatus}

                  />
                </div>
              </>
            ):<></>
          )}
          {/* chat container */}

          {/* show options */}
          {isInviting || (isAdmin?usersList.length:Object.keys(chatList)?.length >=0) ? (
            <ChatFooter
              DialogOptions={DialogOptions}
              handleOptions={handleOptions}
              quickMess={quickMess}
              QuickMessageOptions={QuickMessageOptions}
              setMessage={setMessage}
              setQuickMess={setQuickMess}
              message={message}
              handleSubmit={handleSubmit}
              handleOpenModal={handleOpenModal}
              handclo
            />
          ) : (
            <>
              {!loadingChatList && (
                <div className="chatMessContainer" style={{height:"100vh"}}>
                  <div className="noConversation mt-4">
                    <img
                      style={{ height: "200px" }}
                      src={Images.nodata}
                      alt="img"
                      className="w-100"
                    />
                    <div className="fontSize18 p-5 text-center">
                      No conversations yet
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {/* show options */}
      </div>
      {/* right side data */}
      {/* custom modal */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "UploadPhoto"
            ? "commonWidth customContent"
            : "VideoCall"
            ? "commonWidth customContent"
            : "ScheduleCall"
            ? "commonWidth customContent"
            : "MakeOffer"
            ? "w-100"
            : "FileUpload"
            ? "commonWidth customContent"
            : modalDetail.flag == "MakeOffer"
            ? ""
            : modalDetail.flag === "shippingAddressModal"
            ? "shippingAddressContainer"
            : ""
        }
        ids={
          modalDetail.flag === "UploadPhoto"
            ? "uploadPhoto"
            : modalDetail.flag === "FileUpload"
            ? "fileUpload"
            : modalDetail.flag === "VideoCall"
            ? "videoCall"
            : modalDetail.flag === "ScheduleCall"
            ? "scheduleCall"
            : modalDetail.flag === "BusinessCard"
            ? "businesscardModal"
            : modalDetail.flag === "VoiceRecord"
            ? "voiceRecord"
            : modalDetail.flag === "ShippingAddress"
            ? "shippingAddressModal"
            : modalDetail.flag === "translator"
            ? "translator"
            : modalDetail.flag === "addCustomerModal"
            ? "addCustomerModal"
            : modalDetail.flag === "deleteCustomerModal"
            ? "stopOfferModal"
            : modalDetail.flag === "uploadFiles"
            ? "uploadFileModal"
            : modalDetail.flag === "AcceptChatRequestModal"
            ? "AcceptChatRequestModal"
            : modalDetail.flag === "Catalog"
            ? "catalogModal"
            : ""
        }
        child={
          modalDetail.flag === "UploadPhoto" ? (
            <UploadphotoModal
              flag={"doc"}
              close={() => handleOnCloseModal()}
              file={(data, flag) => handleSubmit(data, flag)}
            />
          ) : modalDetail.flag === "VideoCall" ? (
            <VideocallModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "ScheduleCall" ? (
            <VideoscheduleModal close={() => handleOnCloseModal()} />
          ) : // : modalDetail.flag === "MakeOffer" ? (
          //   <PromotionalOffer
          //     close={() => handleOnCloseModal()}
          //     offer={(data, flag) => handleOffer(data, flag)}
          //   />
          // )
          modalDetail.flag === "FileUpload" ? (
            <FileuploadModal
              flag={"message"}
              close={() => handleOnCloseModal()}
              file={(data, flag) => handleSubmit(data, flag)}
            />
          ) : modalDetail.flag === "BusinessCard" ? (
            <BusinesscardModal
              close={() => handleOnCloseModal()}
              businessCard={(data, flag) => handleSubmit(data, flag)}
            />
          ) : modalDetail.flag === "VoiceRecord" ? (
            <VoicerecordModal
              close={() => handleOnCloseModal()}
              audio={(data, flag) => handleSubmit(data, flag)}
            />
          ) : modalDetail.flag === "ShippingAddress" ? (
            <ShippingAddressModal
              close={() => handleOnCloseModal()}
              shippingAddress={(data, flag) => handleSubmit(data, flag)}
            />
          ) : modalDetail.flag === "translator" ? (
            <TranslatorModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "addCustomerModal" ? (
            <SuccessfullAddCustomerModal
              userData={currentRecipientDetails}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "deleteCustomerModal" ? (
            <DeleteCustomerModal
              userData={currentRecipientDetails}
              delete={(user) => {
                deleteUserFromChatList(chatId);
              }}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "uploadFiles" ? (
            <UploadFileModal
              flag={"image"}
              close={() => handleOnCloseModal()}
              file={(data, flag) => handleSubmit(data, flag)}
            />
          ) : modalDetail.flag === "AcceptChatRequestModal" ? (
            <AcceptChatRequest
              userData={currentRecipientDetails}
              accept={(value) => {
                handleIncomingRequest();
              }}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "Catalog" ? (
            <>
              <CatalogModal
                close={() => handleOnCloseModal()}
                getCatalog={(catalog, flag) => handleSubmit(catalog, flag)}
              />
            </>
          ) : (
            ""
          )
        }
        header={
          <CustomModalHeader
            modalDetail={modalDetail}
            handleOnCloseModal={handleOnCloseModal}
          />
        }
        onCloseModal={() => handleOnCloseModal()}
      />
      <CustomModal
        key={key2}
        show={modalDetail2.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={"w-100 custom_offer_width"}
        ids={modalDetail2.flag === "MakeOffer" ? "MakeOffer" : ""}
        child={
          modalDetail2.flag === "MakeOffer" ? (
            <PromotionalOffer
              close={() => handleOnCloseOfferModal()}
              offer={(data, flag) => {
                handleSubmit(data, flag);
                handleOnCloseOfferModal();
              }}
            />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseOfferModal()}
      />
    </>
  );
};

export default DirectMessage;
