import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { modalCancel } from "../../../utilities/images";
import ProductDetails from "./ProductDetails";
import ProductImageAdd from "./productImageAdd";
import AddProductHeader from "./AddProductHeader";
import AddOptionsModal from "./addOptionsModal";
import CustomModal from "../../components/shared/CustomModal";
import AddAttributesModal from "./addAttributesModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../message/messageSlice";
import { toast } from "react-toastify";
import { formValidator } from "./formValidator";
import { createProductApiAsync } from "../dashboard/dashboardSlice";
import SaleTaxModal from "./saleTaxModal";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CreateOptionModal from "./createOptionModal";
import * as Images from "../../../utilities/images";
import CreateVariationsModal from "./createVariationsModal";
import DeleteVariationModal from "./DeleteVariationModal";
import { Dropdown } from "react-bootstrap";
import EditVariantModal from "./EditVariantModal";
import BulkVarientionModal from "./bulkVarientionModal";
import ReactSelect from "react-select";
import ChartImageUploader from "../service/chartImageUploader";
import ConfirmationModal from "../../components/UI/Product/confirmationModal";
import CreateCouponModal from "./createCouponModal";
import { getColorName } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";

const AddProduct = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const toastId = useRef(null);
  const backRoute = localStorage.getItem("backButtonRoute");
  const [productDetails, setProductDetails] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [costPrice, setcostPrice] = useState("");
  const { flag } = useParams();
  const [productDescription, setProductDescription] = useState("");
  const [imageDetails, setImageDetails] = useState({});
  const [inventoryReorderPoint, setInventoryReorderPoint] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  console.log(deliveryOptions, "deliveryOptions");
  const [chartImage, setChartImage] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityReorder, setQuantityReorder] = useState("");
  // const [storeCheck, setStoreCheck] = useState(false);
  const [marketCheck, setMarketCheck] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const [couponData, setCouponData] = useState({});

  const [dimensions, setDimensions] = useState({
    weight: "",
    length: "",
    breadth: "",
    height: "",
  });
  const [type] = useState("physical");
  const [unitType, setUnitType] = useState("per_unit");
  const [barCode, setBarCode] = useState("");
  const [sku, setSku] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [loading1, setLoading1] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);

  const [isTax, setIsTax] = useState(false);
  const [saleSame, setSaleSame] = useState(false);
  const [differentSale, setDifferentSale] = useState(false);
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [prices, setPrices] = useState([
    {
      app_name: "pos",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
    {
      app_name: "b2c",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
  ]);
  console.log(prices, "prices");
  const [posPrice, setPosPrice] = useState(null);

  const [marketplacePrice, setMarketplacePrice] = useState(null);
  const [posProfit, setPosProfit] = useState(null);
  const [posMargin, setPosMargin] = useState(null);
  const [marketplaceProfit, setMarketplaceProfit] = useState(null);
  const [marketplaceMargin, setMarketplaceMargin] = useState(null);

  const [rowData, setRowData] = useState([
    {
      id: 1,
      activePlan: "list",
      displayName: "",
      productTags: [],
    },
  ]);

  const [tempRowData, setTempRowData] = useState([
    {
      id: 1,
      activePlan: "list",
      displayName: "",
      productTags: [],
    },
  ]);
  const [attributes, setAttributes] = useState([]);
  const [selectedCombinations, setSelectedCombinations] = useState([]); // State to store selected combinations
  const [newCombinations, setNewCombinations] = useState([]);
  const [attributeShow, setAttributeShow] = useState(false);
  const [variants, setVariants] = useState([]);
  const [discountOption, setDiscountOption] = useState("amount");
  const [discountValue, setDiscountValue] = useState("");
  const [salePrice, setSalePrice] = useState("");
  console.log(variants, "variants");

  const [offer, setOffer] = useState({
    price_flag: discountOption,
    actual_price: posPrice,
    offer_price: discountValue,
  });

  const handleDiscountOption = (options) => {
    setDiscountOption(options);
  };
  const handleDiscountValue = (e) => {
    setDiscountValue(e.target.value);
  };

  // Calculate Sale Price & Update Offer State
  useEffect(() => {
    if (!posPrice || !discountValue) {
      setSalePrice(posPrice);
      setOffer((prev) => ({
        ...prev,
        price_flag: discountOption,
        actual_price: posPrice,
        offer_price: discountValue, // Keep showing entered discount value
      }));
      return;
    }

    let calculatedSalePrice = 0;

    if (discountOption === "amount") {
      calculatedSalePrice = Math.max(0, posPrice - discountValue);
    } else if (discountOption === "percentage") {
      const discountAmount = (posPrice * parseFloat(discountValue)) / 100;
      calculatedSalePrice = Math.max(0, posPrice - discountAmount);
    }

    setSalePrice(calculatedSalePrice.toFixed(2));
    setOffer({
      price_flag: discountOption,
      actual_price: posPrice,
      offer_price: discountValue, // Keep showing the input value
    });
  }, [posPrice, discountValue, discountOption]);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleInputChange = (idx, field, value) => {
    const updatedVariants = [...variants];

    // Helper to ensure price object exists
    const ensurePriceObject = (variant, appName) => {
      if (!variant.prices) variant.prices = [];
      const existingPrice = variant.prices.find((p) => p.app_name === appName);
      if (!existingPrice) {
        variant.prices.push({
          app_name: appName,
          selling_price: "",
          margin_percentage: "",
        });
      }
    };

    // Helper to calculate margin
    const calculateMargin = (sellingPrice, costPrice) => {
      if (costPrice > 0) {
        return (((sellingPrice - costPrice) / costPrice) * 100).toFixed(2);
      }
      return "0.00";
    };

    if (field === "pos_price" || field === "marketplace_price") {
      const appName = field === "pos_price" ? "pos" : "b2c";

      // Ensure the price object exists for the given appName
      ensurePriceObject(updatedVariants[idx], appName);

      // Find and update the price object
      updatedVariants[idx].prices = updatedVariants[idx].prices.map((price) => {
        if (price.app_name === appName) {
          const costPrice = parseFloat(updatedVariants[idx].cost_price || 0);
          const sellingPrice = parseFloat(value);

          return {
            ...price,
            selling_price: value,
            margin_percentage: calculateMargin(sellingPrice, costPrice),
          };
        }
        return price;
      });
    } else {
      // Update other top-level fields
      updatedVariants[idx][field] = value;
    }

    setVariants(updatedVariants);
  };

  const handleRowsChange = (rows) => {
    // setRowData(rows);
    setTempRowData(rows);
    handleAttributesChange(rows);
  };

  const handleCombination = (rows) => {
    // setSelectedCombinations(rows);
    setNewCombinations(rows);
  };

  // Handle the attribute update from the child
  const handleAttributesChange = (rows) => {
    // Map the rows to the required attributes format
    const updatedAttributes = rows.map((row) => ({
      name: row.displayName,
      values: row.productTags,
      is_group_by: row.productTags.length > 1, // Example logic: group by if more than 1 product tag
    }));

    // Update the parent attributes state
    setAttributes(updatedAttributes);
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleUnitChange = (e) => {
    setUnitType(e.target.value);
  };

  // handle detail data
  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else if (flag == "dimensions") {
      setDimensions(e);
    } else if (flag == "barCode") {
      setBarCode(e);
    } else if (flag == "sku") {
      setSku(e);
    } else {
      setProductDetails(e);
    }
  };

  // toggle
  const handletaxToggle = (event) => {
    const isChecked = event.target.checked; // Get the checkbox state
    setIsTax(isChecked); // Update local state
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        // Add option if checked
        return [...prevOptions, option];
      } else {
        // Remove option if unchecked
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };

  const handlePriceChange = (e, platform) => {
    const newPrice = parseFloat(e.target.value);

    if (newPrice === null || newPrice < 0 || newPrice === "" || !newPrice) {
      resetPlatformPriceAndProfit(platform);
      return;
    }

    if (platform === "pos") {
      setPosPrice(newPrice);
      calculatePosProfitAndMargin(newPrice, costPrice);

      if (!differentSale) {
        setMarketplacePrice(newPrice);
        calculateMarketplaceProfitAndMargin(newPrice, costPrice);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      calculateMarketplaceProfitAndMargin(newPrice, costPrice);
    }

    updatePriceArray(platform, newPrice);
  };

  const updatePriceArray = (platform, newPrice) => {
    setPrices((prevPrices) => {
      return prevPrices.map((price) => {
        if (price.app_name === platform) {
          if (!newPrice) {
            // Only reset selling_price and margin_percentage when the price is null or empty
            return {
              ...price,
              selling_price: "",
              margin_percentage: "",
            };
          } else {
            // Update selling_price and margin_percentage for the given platform
            const margin = calculateMargin(newPrice);
            return {
              ...price,
              selling_price: newPrice.toString(),
              margin_percentage: margin.toFixed(2),
            };
          }
        }
        return price; // Keep other platforms as is
      });
    });
  };

  const resetPlatformPriceAndProfit = (platform) => {
    if (platform === "pos") {
      setPosPrice(null);
      setPosProfit(null);
      setPosMargin(null);
      updatePriceArray("pos", null);

      if (!differentSale) {
        setMarketplacePrice(null);
        setMarketplaceProfit(null);
        setMarketplaceMargin(null);
        updatePriceArray("b2c", null);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(null);
      setMarketplaceProfit(null);
      setMarketplaceMargin(null);
      updatePriceArray("b2c", null);
    }
  };

  const handleChangeCostPrice = (e) => {
    const newCostPrice = parseFloat(e.target.value); // Ensure valid number
    setcostPrice(newCostPrice);
  };

  const calculatePosProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setPosProfit(profit.toFixed(2)); // Update profit
      setPosMargin(margin.toFixed(2)); // Update margin
    } else {
      setPosProfit(null); // Reset profit if invalid
      setPosMargin(null); // Reset margin if invalid
    }
  };

  const calculateMarketplaceProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setMarketplaceProfit(profit.toFixed(2)); // Update profit
      setMarketplaceMargin(margin.toFixed(2)); // Update margin
    } else {
      setMarketplaceProfit(null); // Reset profit if invalid
      setMarketplaceMargin(null); // Reset margin if invalid
    }
  };

  const calculateMargin = (price) => {
    let margin = 0; // Default value for margin
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      margin = (profit / costPrice) * 100; // Formula to calculate margin
    }
    return margin; // Return the calculated margin
  };

  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleAttributes = (e) => {
    setIngredients(e);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-Products";
  }, []);

  const handleSave = () => {
    // Remove empty keys from each variant
    const sanitizedVariants = variants.map((variant) => {
      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (
          value !== null &&
          value !== undefined &&
          String(value).trim() !== ""
        ) {
          acc[key] = value;
        }
        return acc;
      }, {});
    });

    // Check for negative margin percentages
    const hasNegativeMargin = sanitizedVariants.some((variant) =>
      variant.prices?.some((price) => Number(price.margin_percentage) < 0)
    );
    if (hasNegativeMargin) {
      setModalDetail({
        show: true,
        flag: "confirmModal",
      });
      setKey(Math.random());
      return;
    }

    proceedWithSave();
  };

  const proceedWithSave = () => {
    // Remove empty keys from each variant
    const sanitizedVariants = variants.map((variant) => {
      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (
          value !== null &&
          value !== undefined &&
          String(value).trim() !== ""
        ) {
          acc[key] = value;
        }
        return acc;
      }, {});
    });

    let product_attribute = {
      name: productDetails?.productName || "",
      description: productDescription || "",
      image: imageDetails?.image || "",
      images: imageDetails?.images || [],
      service_id: productDetails?.service_id || "",
      category_id: productDetails?.category_id?.toString() || "",
      sub_category_id: productDetails?.sub_category_id?.toString() || "",
      brand_id: productDetails?.brand_id
        ? productDetails.brand_id.toString()
        : "",
      is_adult: isAdult, //static
      // is_taxable: isTax,
      cost_price: costPrice || "",
      barcode: barCode.toString(),
      is_returnable: true, //static
      weight_unit: "lb", //static
      type: "physical",
      country_of_origin: "US",
      quantity_unit: unitType,
      delivery_options: deliveryOptions,
      variants: sanitizedVariants,
    };
    // console.log(product_attribute, "product_attributeRRRR");
    if (saleSame) {
      product_attribute = { ...product_attribute, offer: offer };
    }
    if (deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")) {
      product_attribute = { ...product_attribute, dimensions: dimensions };
    }
    if (attributes.length > 0) {
      product_attribute = { ...product_attribute, attributes: attributes };
    }
    if (Object.keys(couponData).length > 0) {
      product_attribute = {
        ...product_attribute,
        coupon: couponData,
      };
    }
    if (inventoryReorderPoint) {
      product_attribute = {
        ...product_attribute,
        quantity_reorder_point: inventoryReorderPoint,
      };
    }
    if (ingredients?.length > 0) {
      product_attribute = { ...product_attribute, ingredients: ingredients };
    }
    if (optionsList?.length > 0) {
      product_attribute = { ...product_attribute, attributes: optionsList };
    }
    if (sellerAddressIds?.length > 0) {
      product_attribute = {
        ...product_attribute,
        seller_address_ids: sellerAddressIds,
      };
    }
    if (chartImage) {
      product_attribute = {
        ...product_attribute,
        size_chart_image: chartImage.toString(),
      };
    }

    let result = formValidator(product_attribute);
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };
      dispatch(createProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success(obj?.msg);
          history.push(`/productList`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };

  // Handle confirmation modal actions
  const handleConfirmNegativeMargin = () => {
    handleOnCloseModal();
    proceedWithSave();
  };

  const handleModalChange = (flag) => {
    setModalDetail({ show: true, flag: flag });
    setKey(Math.random());
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];
        // select default seller address if only one address
        let addressList = obj?.payload;
        setAllAddress(addressList || []);
        if (addressList?.length == 1) {
          // setStoreCheck(true);
          setSellerAddressIds([addressList[0]?.id]);
        }
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;

    if (type === "saleSame") {
      setSaleSame(isChecked);
      if (isChecked) {
        if (differentSale) {
          setDifferentSale(false); // Ensure only one can be selected at a time
          setPosPrice(null);
          setMarketplacePrice(null);
          setPosProfit(null);
          setMarketplaceProfit(null);
          setPosMargin(null);
          setMarketplaceMargin(null);
          setPrices([
            {
              app_name: "pos",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
            {
              app_name: "b2c",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
          ]);
        }
      }
    } else if (type === "differentSale") {
      setDifferentSale(isChecked);
      if (isChecked) {
        setSaleSame(false); // Ensure only one can be selected at a time
      }
    }
    setDiscountValue("");
    if (type === "differentSale") {
      setPosPrice(null);
      setMarketplacePrice(null);
      setPosProfit(null);
      setMarketplaceProfit(null);
      setPosMargin(null);
      setMarketplaceMargin(null);
      setPrices([
        {
          app_name: "pos",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
        {
          app_name: "b2c",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
      ]);
    }
  };

  const handleOpenVariation = () => {
    let isValid = true;

    // Validate displayName is not empty
    if (
      tempRowData.some((row) => {
        if (!row.displayName.trim()) {
          showToast("Display name is required");
          return true; // Stop validation on first empty displayName
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    if (
      tempRowData.some((row, idx) => {
        const isDuplicate = tempRowData.some(
          (existingRow, i) =>
            existingRow.displayName === row.displayName && i !== idx
        );
        if (isDuplicate) {
          showToast("Display name must be unique");
          return true; // Stop validation on first duplicate
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    // Validate productTags are not empty
    if (
      tempRowData.some((row) => {
        if (row.productTags.length === 0) {
          showToast("Please add at least one option.");
          return true; // Stop validation on first empty productTags
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    if (!isValid) return;
    // setAttributeShow(true);

    if (modalDetail?.flag == "EditOption") {
      handleOnCloseModal();
      setRowData(tempRowData);
      setTempRowData([]);
    } else {
      setModalDetail({
        show: true,
        flag: "createVariations",
      });
      setKey(Math.random());
    }
  };

  const handleDeleteOption = (displayName) => {
    // Update rowData to exclude the row that matches the displayName
    const updatedRowData = rowData.filter(
      (item) => item.displayName !== displayName
    );
    setRowData(updatedRowData);
    // Update attributes after deletion
    handleAttributesChange(updatedRowData);

    // Update selectedCombinations to delete the displayName key from combinations
    const updatedCombinations = selectedCombinations.map((combination) => {
      // If the displayName exists as a key, delete it from the combination
      const updatedCombination = { ...combination };
      if (updatedCombination.hasOwnProperty(displayName)) {
        delete updatedCombination[displayName];
      }
      return updatedCombination;
    });

    // Remove duplicate combinations
    const uniqueCombinations = Array.from(
      new Set(updatedCombinations.map(JSON.stringify))
    ).map(JSON.parse);

    setSelectedCombinations(uniqueCombinations);

    // Check if rowData is empty after deletion and set a default row if it is
    if (updatedRowData.length === 0) {
      setRowData([
        {
          id: 1,
          activePlan: "list",
          displayName: "",
          productTags: [],
        },
      ]);
      setAttributeShow(false);
      setSelectedCombinations([]);
      setAttributes([]);
    }
  };

  const toggleVariantSelection = (idx) => {
    setSelectedVariants((prevSelected) => {
      if (prevSelected.includes(idx)) {
        return prevSelected.filter((id) => id !== idx); // Remove if already selected
      } else {
        return [...prevSelected, idx]; // Add if not selected
      }
    });
  };
  const handleSelectAllChange = () => {
    // If all variants are selected, unselect them, otherwise select all
    if (selectedVariants.length === variants.length) {
      setSelectedVariants([]); // Deselect all
    } else {
      const allVariantIndexes = variants.map((_, idx) => idx); // Create an array of all variant indexes
      setSelectedVariants(allVariantIndexes); // Select all variants
    }
  };

  const handleBulkUpdate = (field, values) => {
    if (selectedVariants.length <= 0) {
      showToast("please select variants");
      return;
    }

    const updatedVariants = variants.map((variant, idx) => {
      const isSelected = selectedVariants.includes(idx);

      if (isSelected) {
        const costPrice = parseFloat(variant.cost_price || 0);

        // Calculate margin percentage based on cost and selling price
        const calculateMargin = (costPrice, sellingPrice) => {
          if (!costPrice || !sellingPrice) return "";
          return (((sellingPrice - costPrice) / costPrice) * 100).toFixed(2);
        };

        // Update prices if the field is "price"
        const updatedPrices = variant.prices.map((price) => {
          if (price.app_name === "pos") {
            return {
              ...price,
              selling_price: values.posPrice || price.selling_price,
              margin_percentage: calculateMargin(
                costPrice,
                parseFloat(values.posPrice || price.selling_price)
              ),
            };
          }

          if (price.app_name === "b2c") {
            return {
              ...price,
              selling_price: values.b2cPrice || price.selling_price,
              margin_percentage: calculateMargin(
                costPrice,
                parseFloat(values.b2cPrice || price.selling_price)
              ),
            };
          }

          return price; // Return price unchanged if it's not being updated
        });

        // Return the updated variant object
        return {
          ...variant,
          ...(field === "quantity" && {
            quantity: values.quantity || variant.quantity,
          }),
          ...(field === "quantity" && {
            quantity_reorder_point:
              values.quantity_reorder_point || variant.quantity_reorder_point,
          }),
          ...(field === "weight" && {
            weight: values.weight || variant.weight,
          }),
          ...(field === "pos_price" && { prices: updatedPrices }),
          ...(field === "b2c_price" && { prices: updatedPrices }),
          ...(field === "image" && { image: values.image || variant.image }),
          ...(field === "image" && { images: values.images || variant.images }),
        };
      }

      return variant; // If not selected, return the variant unchanged
    });

    setVariants(updatedVariants);
  };

  const handleDeleteVariant = (indexToDelete) => {
    const updatedVariants = variants.filter((_, idx) => idx !== indexToDelete);
    setVariants(updatedVariants);
  };

  //  Automatically remove combinations if a productTag is removed
  useEffect(() => {
    if (!rowData.length) return;

    // Step 1: Create a Set of all valid productTags
    const validTags = new Set(rowData.flatMap((item) => item.productTags));

    // Step 2: Filter out combinations that contain removed tags
    setSelectedCombinations((prevCombinations) =>
      prevCombinations.filter((combination) =>
        Object.values(combination).every((tag) => validTags.has(tag))
      )
    );
  }, [rowData]); // Runs whenever rowData changes

  useEffect(() => {
    if (selectedCombinations && selectedCombinations.length > 0) {
      setVariants((prevVariants) =>
        selectedCombinations.map((combination, index) => ({
          ...(prevVariants[index] || {}),
          attributes: { ...combination },
          cost_price: costPrice || prevVariants[index]?.cost_price || "", // Preserve existing value
          prices: prevVariants[index]?.prices ||
            prices || [
              {
                app_name: "pos",
                price_type: "fixed",
                selling_price: "",
                margin_percentage: "",
              },
              {
                app_name: "b2c",
                price_type: "fixed",
                selling_price: "",
                margin_percentage: "",
              },
            ],
          quantity: prevVariants[index]?.quantity || quantity || "",
          quantity_reorder_point:
            prevVariants[index]?.quantity_reorder_point ||
            quantityReorder ||
            "",
          weight: dimensions?.weight || prevVariants[index]?.weight || "",
          barcode: String(
            Number(barCode) + index || prevVariants?.[index]?.barcode || ""
          ),
          image: prevVariants[index]?.image || imageDetails?.image || "",
          images: prevVariants[index]?.images || imageDetails?.images || [],
          // seller_address_ids: prevVariants[index]?.seller_address_ids || [],
          sku: sku || "",
        }))
      );
    } else {
      setVariants((prevVariants) => [
        {
          cost_price: costPrice || "",
          prices: prices || [
            {
              app_name: "pos",
              price_type: "fixed",
              selling_price: "",
              compare_at_price: "",
              margin_percentage: "",
            },
            {
              app_name: "b2c",
              price_type: "fixed",
              selling_price: "",
              compare_at_price: "",
              margin_percentage: "",
            },
          ],
          weight: dimensions?.weight || "",
          barcode: barCode || "",
          image: imageDetails?.image || "",
          images: imageDetails?.images || [],
          quantity: quantity || "",
          quantity_reorder_point: quantityReorder || "",
          sku: sku || "",
        },
      ]);
    }
  }, [
    selectedCombinations,
    costPrice,
    prices,
    imageDetails,
    barCode,
    dimensions,
    quantity,
    quantityReorder,
    sku,
  ]);

  useEffect(() => {
    if (costPrice > 0) {
      // Update POS profit and margin
      if (posPrice > 0) {
        calculatePosProfitAndMargin(posPrice, costPrice);
      }

      // Update Marketplace profit and margin
      if (marketplacePrice > 0) {
        calculateMarketplaceProfitAndMargin(marketplacePrice, costPrice);
      }

      // Update margin in prices array for variants
      setPrices((prevPrices) =>
        prevPrices.map((price) => {
          const updatedMargin =
            price.selling_price > 0
              ? calculateMargin(
                  parseFloat(price.selling_price),
                  costPrice
                ).toFixed(2)
              : price.margin_percentage;

          return {
            ...price,
            margin_percentage: updatedMargin,
          };
        })
      );

      // Update variants
      setVariants((prevVariants) =>
        prevVariants.map((variant) => {
          const updatedPrices = variant.prices.map((price) => {
            const updatedMargin =
              price.selling_price > 0
                ? calculateMargin(
                    parseFloat(price.selling_price),
                    costPrice
                  ).toFixed(2)
                : price.margin_percentage;

            return {
              ...price,
              margin_percentage: updatedMargin,
            };
          });

          return {
            ...variant,
            prices: updatedPrices,
          };
        })
      );
    } else {
      // Reset profits and margins when costPrice is invalid
      setPosProfit("");
      setPosMargin("");
      setMarketplaceProfit("");
      setMarketplaceMargin("");

      // Reset margin in prices array for variants
      setPrices((prevPrices) =>
        prevPrices.map((price) => ({
          ...price,
          margin_percentage: "",
        }))
      );

      // Reset variants
      setVariants((prevVariants) =>
        prevVariants.map((variant) => ({
          ...variant,
          prices: variant.prices.map((price) => ({
            ...price,
            margin_percentage: "",
          })),
        }))
      );
    }
  }, [costPrice]);

  const handleProfitChange = (e, platform) => {
    let newProfit = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newProfit)) {
      newProfit = 0; // Reset to 0 if invalid
    }

    newProfit = parseFloat(newProfit.toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));
    const newMargin =
      cost > 0 ? parseFloat(((newProfit / cost) * 100).toFixed(2)) : 0;

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  const handleMarginChange = (e, platform) => {
    let newMargin = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newMargin)) {
      newMargin = 0;
    }

    newMargin = parseFloat(newMargin.toFixed(2));
    const newProfit = parseFloat(((newMargin / 100) * cost).toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setVariants((prevVariants) =>
  //       prevVariants.map((variant) => {
  //         const hasB2C = variant.prices.some(
  //           (price) => price.app_name === "b2c"
  //         );

  //         return {
  //           ...variant,
  //           prices: marketCheck
  //             ? hasB2C
  //               ? variant.prices // Keep if already exists
  //               : [
  //                   ...variant.prices,
  //                   {
  //                     app_name: "b2c",
  //                     price_type: "fixed",
  //                     selling_price: "",
  //                     margin_percentage: "",
  //                   },
  //                 ] // Add "b2c" if missing
  //             : variant.prices.filter((price) => price.app_name !== "b2c"), // Remove "b2c" when unchecked
  //         };
  //       })
  //     );
  //     // setPrices((prevPrices) => {
  //     //   const hasB2C = prevPrices.some((price) => price.app_name === "b2c");
  //     //   const posPrice = prevPrices.find((price) => price.app_name === "pos");

  //     //   return marketCheck
  //     //     ? hasB2C
  //     //       ? prevPrices // Keep if already exists
  //     //       : [
  //     //           ...prevPrices,
  //     //           {
  //     //             app_name: "b2c",
  //     //             price_type: posPrice?.price_type || "fixed",
  //     //             selling_price: posPrice?.selling_price || "",
  //     //             margin_percentage: posPrice?.margin_percentage || "",
  //     //           },
  //     //         ] // Add "b2c" with same values as "pos"
  //     //     : prevPrices.filter((price) => price.app_name !== "b2c"); // Remove "b2c" when unchecked
  //     // });

  //     setPrices((prevPrices) => {
  //       const hasB2C = prevPrices.some((price) => price.app_name === "b2c");

  //       return marketCheck
  //         ? hasB2C
  //           ? prevPrices // Keep if already exists
  //           : [
  //               ...prevPrices,
  //               {
  //                 app_name: "b2c",
  //                 price_type: "fixed",
  //                 selling_price: "",
  //                 margin_percentage: "",
  //               },
  //             ] // Add "b2c"
  //         : prevPrices.filter((price) => price.app_name !== "b2c"); // Remove "b2c" when unchecked
  //     });

  //     if (!marketCheck) {
  //       setDeliveryOptions((prevOptions) =>
  //         prevOptions.includes("pickup") ? prevOptions : ["pickup"]
  //       );
  //     }
  //   }, 1000); // 2-second delay
  //   return () => clearTimeout(timer); // Cleanup function to prevent multiple timeouts
  // }, [marketCheck]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setVariants((prevVariants) =>
        prevVariants.map((variant) => ({
          ...variant,
          prices: marketCheck
            ? variant.prices.some((price) => price.app_name === "b2c")
              ? variant.prices.map((price) =>
                  price.app_name === "b2c" && !differentSale
                    ? {
                        ...price,
                        selling_price: variant.prices.find((p) => p.app_name === "pos")?.selling_price || "",
                        margin_percentage: variant.prices.find((p) => p.app_name === "pos")?.margin_percentage || "",
                      }
                    : price
                )
              : [
                  ...variant.prices,
                  {
                    app_name: "b2c",
                    price_type: "fixed",
                    selling_price: !differentSale
                      ? variant.prices.find((p) => p.app_name === "pos")?.selling_price || ""
                      : "",
                    margin_percentage: !differentSale
                      ? variant.prices.find((p) => p.app_name === "pos")?.margin_percentage || ""
                      : "",
                  },
                ]
            : variant.prices.filter((price) => price.app_name !== "b2c"), // **Ensure removal of b2c**
        }))
      );
  
      setPrices((prevPrices) =>
        marketCheck
          ? prevPrices.some((price) => price.app_name === "b2c")
            ? prevPrices.map((price) =>
                price.app_name === "b2c" && !differentSale
                  ? {
                      ...price,
                      selling_price: prevPrices.find((p) => p.app_name === "pos")?.selling_price || "",
                      margin_percentage: prevPrices.find((p) => p.app_name === "pos")?.margin_percentage || "",
                    }
                  : price
              )
            : [
                ...prevPrices,
                {
                  app_name: "b2c",
                  price_type: "fixed",
                  selling_price: !differentSale
                    ? prevPrices.find((p) => p.app_name === "pos")?.selling_price || ""
                    : "",
                  margin_percentage: !differentSale
                    ? prevPrices.find((p) => p.app_name === "pos")?.margin_percentage || ""
                    : "",
                },
              ]
          : prevPrices.filter((price) => price.app_name !== "b2c") // **Ensure removal of b2c**
      );
  
      if (!marketCheck) {
        setDeliveryOptions((prevOptions) =>
          prevOptions.includes("pickup") ? prevOptions : ["pickup"]
        );
      }
    }, 2000);
  
    return () => clearTimeout(timer);
  }, [marketCheck]);

  
  const handleMarketCheckChange = () => {
    setMarketCheck((prev) => !prev);
    setMarketplacePrice(null);
    setDeliveryOptions([]);
  };

  return (
    <div className="addProductManually">
      {/* Start FixedTopHeader */}
      <AddProductHeader
        flag={backRoute}
        save={(e) => handleSave(e)}
        successLoader={successLoader}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper addProductRightWrap">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 p-0">
              <div className="productRightContainer">
                {/* Start Uploaded ProductImg Area */}

                <ProductImageAdd imageData={(e) => handleImageDetails(e)} />

                <ProductDetails
                  setSelectedService={setSelectedService}
                  setServiceIdforAttributes={setServiceId}
                  productDetails={(e, flag) => handleProductDetails(e, flag)}
                />

                <div className="row">
                  <div className="col-md-6">
                    <div className="form_group">
                      <label className="fontSize16 txtDarkblue mb-2 ps-4">
                        {t("quantity")}
                        {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput"
                          placeholder="quantity"
                          name="barcode"
                          type="number"
                          value={quantity}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form_group">
                      <label className="fontSize16 txtDarkblue mb-2 ps-4">
                        {t("quantityReOrder")}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput"
                          placeholder=" Quantity Re-order"
                          name="barcode"
                          type="number"
                          value={quantityReorder}
                          onChange={(e) => {
                            setQuantityReorder(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* End Uploaded ProductImg Area */}

                {/* Start CostPrice Area */}
                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("productCostPrice")}
                    </h2>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          {t("costOfGoodSold")}
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="Cost Price"
                            name="text"
                            type="number"
                            value={costPrice}
                            onChange={(e) => handleChangeCostPrice(e)}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_group"></div>
                      <div className="reactSelectParent commonSelectParent w-100">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4">
                          {t("unit")}
                        </label>
                        <ReactSelect
                          value={
                            unitType
                              ? {
                                  value: unitType,
                                  label: unitType
                                    .replace("_", " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    ),
                                }
                              : null
                          }
                          options={[
                            // { value: "per_item", label: "Per Item" },
                            { value: "per_unit", label: "Per Unit" },
                            { value: "per_box", label: "Per Box" },
                            { value: "per_bag", label: "Per Bag" },
                            { value: "per_pound", label: "Per Pound" },
                            { value: "per_feet", label: "Per Feet" },
                            { value: "per_gallon", label: "Per Gallon" },
                          ]}
                          onChange={(selectedOption) =>
                            handleUnitChange({
                              target: { value: selectedOption?.value },
                            })
                          }
                          isSearchable={true}
                          placeholder="Select one"
                          classNamePrefix="react-select"
                          className="react-select-container anlyticSelect"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "100% !important",
                              width: "100%",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              border: "1px solid #D7DEFF",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              paddingLeft: "unset !important",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#263682"
                                : state.isFocused
                                ? "#f5f6fc"
                                : "#fff",

                              color: state.isSelected ? "#fff" : "#263682",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#636E9F",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }),
                          }}
                          components={{
                            DropdownIndicator: () => (
                              <img
                                src={Images.selectImg}
                                width={24}
                                height={24}
                                alt="drop_icon"
                                className="mt-1"
                              />
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("productSalePrice")}
                    </h2>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={differentSale}
                          onChange={handleSaleToggle("differentSale")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t("useDifferentSalePrice")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price for B2B, Marketplace & POS
                          </h3>
                        </div>
                      </div>
                      {differentSale && (
                        <>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              POS Pricing
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("salePrice")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="posPrice"
                                      type="number"
                                      value={posPrice}
                                      onChange={(e) =>
                                        handlePriceChange(e, "pos")
                                      }
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("profit")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput costPriceInput"
                                      value={
                                        posProfit !== null &&
                                        posProfit !== undefined
                                          ? posProfit
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleProfitChange(e, "pos")
                                      }
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("margin")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput"
                                      value={
                                        posMargin !== null &&
                                        posMargin !== undefined
                                          ? posMargin
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleMarginChange(e, "pos")
                                      }
                                      placeholder="Margin"
                                    />

                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {marketCheck && (
                            <div className="productSalePriceBox">
                              <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                                {t("marketingPrice")}
                              </h3>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("salePrice")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        min="1"
                                        onKeyDown={(e) => blockInvalidChar(e)}
                                        className="customInput costPriceInput"
                                        placeholder="1,000.00"
                                        name="marketplacePrice"
                                        type="number"
                                        value={marketplacePrice}
                                        onChange={(e) =>
                                          handlePriceChange(e, "b2c")
                                        }
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("profit")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput costPriceInput"
                                        value={
                                          marketplaceProfit !== null &&
                                          marketplaceProfit !== undefined
                                            ? marketplaceProfit
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleProfitChange(e, "b2c")
                                        }
                                        placeholder="Profit"
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t('margin')}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput"
                                        value={
                                          marketplaceMargin !== null &&
                                          marketplaceMargin !== undefined
                                            ? marketplaceMargin
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleMarginChange(e, "b2c")
                                        }
                                        placeholder="Margin"
                                      />
                                      <span className="dollrsign1_ usdTxt">
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={saleSame}
                          onChange={handleSaleToggle("saleSame")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t("onSale")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("salePriceCaption")}
                          </h3>
                        </div>
                      </div>
                    </div>
                    {!differentSale && (
                      <>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("salePrice")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="posPrice"
                                type="number"
                                value={posPrice}
                                onChange={(e) => handlePriceChange(e, "pos")}
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("profit")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput costPriceInput"
                                value={
                                  posProfit !== null && posProfit !== undefined
                                    ? posProfit
                                    : ""
                                }
                                onChange={(e) => handleProfitChange(e, "pos")}
                                placeholder="Profit"
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("margin")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput"
                                value={
                                  posMargin !== null && posMargin !== undefined
                                    ? posMargin
                                    : ""
                                }
                                placeholder="Margin"
                                onChange={(e) => handleMarginChange(e, "pos")}
                              />

                              <span className="dollrsign1_ usdTxt">%</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {saleSame && (
                      <>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              {t("discount")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="100"
                                name="text"
                                type="number"
                                value={discountValue}
                                onChange={handleDiscountValue}
                              />
                              <span className="dollrsign1_">
                                {discountOption === "amount" ? "$" : "%"}
                              </span>

                              <ul className="discountTab m-0">
                                <li
                                  className={`discountTabList ${
                                    discountOption === "amount" ? "active" : ""
                                  }`}
                                  onClick={() => handleDiscountOption("amount")}
                                >
                                  $
                                </li>
                                <li
                                  className={`discountTabList discountTabListTwo ${
                                    discountOption === "percentage"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleDiscountOption("percentage")
                                  }
                                >
                                  %
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              {t("salePrice")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="text"
                                type="number"
                                value={salePrice}
                                readOnly
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </form>

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div
                        className={`productSale_Parent mt-0 w-100 ${
                          rowData[0].displayName !== "" &&
                          rowData.length > 0 &&
                          attributeShow &&
                          "active"
                        }`}
                      >
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            {t("productOption")}
                          </h3>
                          <p className="fontSize14 fw500 txtDarkblue mb-0">
                           {t("differentProduct")}.
                          </p>
                          {rowData &&
                            attributeShow &&
                            rowData.length > 0 &&
                            rowData[0].displayName !== "" &&
                            rowData.map((value, idx) => (
                              <div className="productOptionInner">
                                <img src={Images.barsLogo} alt="logo" />
                                <div className="productOption_InnerBox">
                                  <div className="row align-items-center">
                                    <div className="col-3">
                                      <h3 className="fontSize18 txtDarkblue mb-0 textCapital">
                                        {value?.displayName}
                                      </h3>
                                    </div>
                                    <div className="col-9">
                                      <div className="d-flex align-items-center justify-content-between w-100">
                                        <h3 className="fontSize18 txtDarkblue mb-0">
                                          {/* {value?.productTags?.join(", ")} */}
                                          {value?.displayName.toLowerCase() ===
                                          "color"
                                            ? value?.productTags
                                                .map((color) =>
                                                  getColorName(color)
                                                )
                                                .join(", ")
                                            : value?.productTags.join(", ")}
                                        </h3>

                                        <div className="d-flex align-items-center ms-3">
                                          <img
                                            src={Images.newEditIcon}
                                            alt="logo"
                                            className="me-2"
                                            onClick={() => {
                                              setModalDetail({
                                                show: true,
                                                flag: "EditOption",
                                                data: value,
                                                rowIndex: idx,
                                              });
                                              setKey(Math.random());
                                            }}
                                          />
                                          <img
                                            src={Images.modalCross}
                                            alt="logo"
                                            onClick={() =>
                                              handleDeleteOption(
                                                value.displayName
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <button
                            className="commonBtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "AddOptions",
                              });
                              setKey(Math.random());
                              // setAttributeShow(false);
                              if (
                                rowData.length >= 1 &&
                                rowData.displayName !== ""
                              ) {
                                setTempRowData([]);
                              }
                            }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            {t("addOption")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {variants[0]?.attributes &&
                    attributeShow &&
                    Object.keys(variants[0]?.attributes).length > 0 && (
                      <div className="col-md-12">
                        <div className="productDetailsContainer">
                          <h2 className="fontSize18 fw500 txtDarkblue">
                            {t("productVariant")}
                          </h2>
                          <p className="fontSize14 txtDarkblue mb-0">
                            {t("diffeProductAdd")}.
                          </p>
                          <div className="row mt-3 align-items-center">
                            <div className="col-6">
                              <div className="productAvailabilityChild variationsParent align-items-center ps-3">
                                <div class="form-group">
                                  <input
                                    type="checkbox"
                                    id="selectAllCheckbox"
                                    checked={
                                      selectedVariants.length ===
                                      variants.length
                                    }
                                    onChange={handleSelectAllChange}
                                  />
                                  <label for="selectAllCheckbox"></label>
                                </div>
                                <div>
                                  <h3 className="fontSize16 txtDarkblue fw500 mb-0 pt-1 ps-2">
                                    {selectedCombinations.length} {t("variations")}
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-end">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                  className="variantsTable_Dropdown"
                                >
                                  <img
                                    src={Images.moreVerticalLogo}
                                    alt="logo"
                                    className="plusDropImg"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="variantsDropdownMenu">
                                  <Dropdown.Item
                                    onClick={() => {
                                      if (selectedVariants.length <= 0) {
                                        showToast("please select variants");
                                        return;
                                      }
                                      setModalDetail({
                                        show: true,
                                        flag: "bulkVariation",
                                        title: "Update Quantity",
                                        field: "quantity",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    {t("updateQuantity")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      if (selectedVariants.length <= 0) {
                                        showToast("please select variants");
                                        return;
                                      }
                                      setModalDetail({
                                        show: true,
                                        flag: "bulkVariation",
                                        title: "Update Price",
                                        field: "pos_price",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    {t("updatePRice")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      if (selectedVariants.length <= 0) {
                                        showToast("please select variants");
                                        return;
                                      }
                                      setModalDetail({
                                        show: true,
                                        flag: "bulkVariation",
                                        title: "Update Weight",
                                        field: "weight",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    {t("updateWeight")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      if (selectedVariants.length <= 0) {
                                        showToast("please select variants");
                                        return;
                                      }
                                      setModalDetail({
                                        show: true,
                                        flag: "bulkVariation",
                                        title: "Upload Image",
                                        field: "image",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    {t("updateImage")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="productVariantsTable table-responsive mt-2">
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>{t("variations")}</th>
                                  <th>{t("upc")}</th>
                                  <th>POS Price</th>
                                  <th>{t("marketPlace")}</th>
                                  <th>{t("weight")}</th>
                                  <th>{t("quantity")}</th>
                                  <th>{t("quantityReOrder")}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {variants.map((variant, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      <div className="productAvailabilityChild variationsParent">
                                        <div className="form-group">
                                          <input
                                            type="checkbox"
                                            id={`checkbox-${idx}`}
                                            checked={selectedVariants.includes(
                                              idx
                                            )} // Check if this variant is selected
                                            onChange={() =>
                                              toggleVariantSelection(idx)
                                            } // Toggle selection on change
                                          />
                                          <label
                                            htmlFor={`checkbox-${idx}`}
                                            className="me-0"
                                          ></label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={
                                            variant?.image
                                              ? variant?.image
                                              : Images.uploadVariantLogo
                                          }
                                          alt="logo"
                                          className="me-3 teamMemberImg"
                                        />
                                        <div>
                                          {variant.attributes
                                            ? Object.entries(
                                                variant.attributes
                                              ).map(([key, val], index) => (
                                                <span
                                                  className="varintText textCapital"
                                                  key={index}
                                                >
                                                  {/* {val} */}
                                                  {val.startsWith("#")
                                                    ? getColorName(val)
                                                    : val}
                                                  {index <
                                                    Object.entries(
                                                      variant.attributes
                                                    ).length -
                                                      1 && " / "}
                                                </span>
                                              ))
                                            : "Default Variant"}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        // min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="barcode"
                                        type="number"
                                        value={variant.barcode || ""}
                                        maxLength={14}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 14) {
                                            handleInputChange(
                                              idx,
                                              "barcode",
                                              inputValue
                                            );
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="pos_price"
                                        type="number"
                                        value={
                                          variant.prices.find(
                                            (p) => p.app_name === "pos"
                                          )?.selling_price || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            idx,
                                            "pos_price",
                                            e.target.value
                                          )
                                        }
                                        disabled={
                                          !variant.prices.some(
                                            (price) => price.app_name === "pos"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="marketplace_price"
                                        type="number"
                                        value={
                                          variant.prices.find(
                                            (p) => p.app_name === "b2c"
                                          )?.selling_price || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            idx,
                                            "marketplace_price",
                                            e.target.value
                                          )
                                        }
                                        disabled={
                                          !variant.prices.some(
                                            (price) => price.app_name === "b2c"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="weight"
                                        type="number"
                                        value={variant.weight || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            idx,
                                            "weight",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        // min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="quantity"
                                        type="number"
                                        value={variant.quantity || ""}
                                        maxLength={14}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          handleInputChange(
                                            idx,
                                            "quantity",
                                            inputValue
                                          );
                                        }}
                                      />
                                    </td>{" "}
                                    <td>
                                      <input
                                        // min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="quantity_reorder_point"
                                        type="number"
                                        value={
                                          variant.quantity_reorder_point || ""
                                        }
                                        maxLength={14}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          handleInputChange(
                                            idx,
                                            "quantity_reorder_point",
                                            inputValue
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                          className="variantsTable_Dropdown"
                                        >
                                          <img
                                            src={Images.moreVerticalLogo}
                                            alt="logo"
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="variantsDropdownMenu">
                                          <Dropdown.Item
                                            onClick={() => {
                                              setModalDetail({
                                                show: true,
                                                flag: "editVariantModal",
                                                index: idx,
                                                variationname:
                                                  variant.attributes
                                                    ? Object.entries(
                                                        variant.attributes
                                                      )
                                                        .map(
                                                          (
                                                            [key, val],
                                                            index,
                                                            array
                                                          ) => {
                                                            // Concatenate values, ensuring proper formatting
                                                            const displayValue = `${
                                                              val.startsWith(
                                                                "#"
                                                              )
                                                                ? getColorName(
                                                                    val
                                                                  )
                                                                : val
                                                            }${
                                                              index <
                                                              array.length - 1
                                                                ? " / "
                                                                : ""
                                                            }`;
                                                            return displayValue;
                                                          }
                                                        )
                                                        .join("") // Join the array of strings into a single string
                                                    : "Default Variant", // Default text when no attributes exist
                                              });
                                              setKey(Math.random());
                                            }}
                                          >
                                            {t("edit")}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleDeleteVariant(idx)
                                            }
                                          >
                                            {t("delete")}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {/* End ProductDetails Area */}
            <div className="col-xl-4 px-md-2 p-0">
              {/* Start ProductAvailability Area */}
              <div className="productLeftContainer">
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    {t("productAvailability")}
                  </h3>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.physicalStoreIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            {t("physicalStore")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            JOBR POS
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="productAvailOption_Wrapper">
                      {allAddress.map((store, idx) => (
                        <div
                          key={store.id}
                          className="productAvailabilityChild mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={`store-${store.id}`}
                              checked={sellerAddressIds.includes(store.id)}
                              onChange={handleStoreToggle(store.id)} // Correct usage
                            />
                            <label htmlFor={`store-${store.id}`}></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                              Store {idx + 1}
                              {/* {store.id} - {store.format_address} */}
                            </h3>
                            <p className="fontSize12 txtLightBlue mb-0">
                              {store.street_address}, {store.city},{" "}
                              {store.state} {store.zipcode}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="productAvail_Second ">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.jobrMarketplaceIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input
                            type="checkbox"
                            checked={marketCheck}
                            // onChange={() => {
                            //   setMarketCheck(!marketCheck);
                            //   setDeliveryOptions([]);
                            // }}
                            onChange={handleMarketCheckChange}
                          />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            JOBR Marketplace
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("allowCustomer")}.
                          </h3>
                        </div>
                      </div>
                    </div>
                    {marketCheck && (
                      <div className="productAvailOption_Wrapper">
                        <div className="productAvailabilityChild mt-3 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="pickup"
                              checked={deliveryOptions.includes("pickup")}
                              onChange={handleDeliveryOptionToggle("pickup")}
                            />
                            <label for="pickup"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              {t("pickup")}
                            </h3>
                          </div>
                        </div>
                        <div className="productAvailabilityChild mt-3 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="delivery"
                              checked={deliveryOptions.includes("delivery")}
                              onChange={handleDeliveryOptionToggle("delivery")}
                            />
                            <label for="delivery"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              {t("delivery")}
                            </h3>
                          </div>
                        </div>
                        <div className="productAvailabilityChild mt-3 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="shipping"
                              checked={deliveryOptions.includes("shipping")}
                              onChange={handleDeliveryOptionToggle("shipping")}
                            />
                            <label for="shipping"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              {t("shipping")}
                            </h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="productAvail_Second ">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.adultIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                        style={{ width: "21px", height: "21px" }}
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input
                            type="checkbox"
                            checked={isAdult}
                            onChange={(e) => {
                              setIsAdult(e.target.checked);
                            }}
                          />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            {t("isAdultProduct")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("enableAdultProduct")}.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    {t("marketOption")}
                  </h3>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      {t("createCoupon")}
                    </h3>
                    <img
                      src={Images.upArrow}
                      alt="logo"
                      className="cursorPoint"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "createCoupon",
                        });
                        setKey(Math.random());
                      }}
                    />
                  </div>
                </div>
                <div className="chartUploadBox mb-2">
                  <h2 className="fontSize18 fw500 txtDarkblue ">
                    {t("productChart")}
                  </h2>
                  <ChartImageUploader
                    chartImage={chartImage}
                    setChartImage={setChartImage}
                  />
                </div>
              </div>
            </div>

            {/* End ProductDetails Area */}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "AddOptions" || modalDetail.flag === "EditOption"
            ? "addProductOptions"
            : modalDetail.flag === "createVariations"
            ? "createVariations"
            : modalDetail.flag === "AddAttributesModal"
            ? "addAttributesModal"
            : modalDetail.flag === "saleTax"
            ? "saleModalTax"
            : modalDetail.flag === "deleteVariation"
            ? "addProductOptions"
            : modalDetail.flag === "editVariantModal"
            ? "editVariantModal"
            : modalDetail.flag === "addAttributeModal"
            ? "addProductOptions"
            : modalDetail.flag === "bulkVariation"
            ? "addProductOptions"
            : modalDetail.flag === "confirmModal"
            ? "confrimPriceOption"
            : modalDetail.flag === "createCoupon"
            ? "createCouponModal"
            : ""
        }
        size={modalDetail.flag === "AddOptions" ? "xl" : "lg"}
        child={
          modalDetail.flag === "AddOptions" ||
          modalDetail.flag === "EditOption" ? (
            <AddOptionsModal
              id={serviceId}
              close={() => handleOnCloseModal()}
              onRowsChange={handleRowsChange}
              // rowData={tempRowData.length > 1 ? tempRowData : rowData}
              rowData={
                modalDetail.flag === "EditOption"
                  ? rowData
                  : tempRowData.length > 1
                  ? tempRowData
                  : rowData
              }
              optionModalDetail={modalDetail}
            />
          ) : modalDetail.flag === "createVariations" ? (
            <CreateVariationsModal
              close={() => handleOnCloseModal()}
              rowData={tempRowData}
              costPrice={costPrice}
              onCombinationChange={handleCombination}
              selectedCombinations={selectedCombinations}
            />
          ) : modalDetail.flag === "bulkVariation" ? (
            <BulkVarientionModal
              title={modalDetail.title}
              field={modalDetail.field}
              onSave={(value) => {
                handleBulkUpdate(modalDetail.field, value);
              }}
              costPrice={costPrice}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "AddAttributesModal" ? (
            <AddAttributesModal
              close={() => handleOnCloseModal()}
              attributes={(e) => handleAttributes(e)}
              value={ingredients}
            />
          ) : modalDetail.flag === "saleTax" ? (
            <SaleTaxModal
              close={() => handleOnCloseModal()}
              attributes={(e) => handleAttributes(e)}
              value={ingredients}
            />
          ) : modalDetail.flag === "createVariant" ? (
            <CreateOptionModal
              selectedService={selectedService}
              serviceId={serviceId}
              close={() => handleOnCloseModal()}
              handleModalChange={(e) => handleModalChange(e)}
            />
          ) : modalDetail.flag === "deleteVariation" ? (
            <DeleteVariationModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editVariantModal" ? (
            <EditVariantModal
              closeModal={() => handleOnCloseModal()}
              modalDetail={modalDetail}
              allAddress={allAddress}
              variants={variants}
              setVariants={setVariants}
            />
          ) : modalDetail.flag === "addAttributeModal" ? (
            <AddAttributesModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "confirmModal" ? (
            <ConfirmationModal
              onConfirm={handleConfirmNegativeMargin}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "createCoupon" ? (
            <CreateCouponModal
              close={() => handleOnCloseModal()}
              setCouponData={setCouponData}
              couponData={couponData}
            />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "AddOptions" ||
            modalDetail.flag === "EditOption" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {modalDetail.flag === "EditOption"
                    ? t("editOptions")
                    : t("addOptions")}
                </h3>
                <div className="d-flex align-items-center">
                  <button
                    className="modalDiscardBtn"
                    onClick={() => {
                      handleOnCloseModal();
                      if (
                        rowData.length >= 1 &&
                        rowData[0].displayName !== "" &&
                        selectedCombinations.length > 0
                      ) {
                        setAttributeShow(true);
                      } else {
                        setRowData([
                          {
                            id: 1,
                            activePlan: "list", // Default active plan
                            displayName: "", // Reset display name
                            productTags: [], // Empty productTags array
                          },
                        ]);
                        setAttributes(false);
                      }
                    }}
                  >
                    {t("discard")}
                  </button>
                  <button
                    className="modalNextBtn modalDiscardBtn active"
                    onClick={handleOpenVariation}
                  >
                    {modalDetail?.flag == "EditOption" ? t("save") : t("next")}
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "createVariations" ? (
              <>
                <div className="d-flex align-items-center">
                  <button
                    className="modalBackBtn"
                    onClick={() => {
                      handleOnCloseModal();
                      // setSelectedCombinations([]);
                      setModalDetail({
                        show: true,
                        flag: "AddOptions",
                      });
                      // setAttributeShow(false);
                    }}
                  >
                    <img src={Images.blueBackArrow} alt="logo" />
                  </button>
                  <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                    {t("createVariation")}
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className="modalNextBtn modalDiscardBtn active createVariationBtn"
                    onClick={() => {
                      if (newCombinations.length <= 0) {
                        showToast("please select variation");
                        return;
                      }
                      handleOnCloseModal();
                      setAttributeShow(true);
                      setSelectedCombinations(newCombinations);
                      setRowData(tempRowData);
                      setTempRowData([]);
                    }}
                  >
                    {t("create")} {newCombinations.length} {t("variations")}
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "saleTax" ? (
              <>
                <h4 className="modalHeading_">{t("salesTax")}</h4>
                <p onClick={handleOnCloseModal} className="modal_cancel">
                  <img
                    src={modalCancel}
                    className="ModalCancel"
                    alt="modalcancelImg"
                  />
                </p>
              </>
            ) : modalDetail.flag === "bulkVariation" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {modalDetail.title}
                </h3>
                <button
                  className="modalNextBtn modalDiscardBtn active"
                  onClick={handleOnCloseModal}
                >
                  {t("close")}
                </button>
              </>
            ) : modalDetail.flag === "deleteVariation" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {t("removeOption")}
                </h3>

                <div className="d-flex align-items-center">
                  <button
                    className="modalDiscardBtn w-auto"
                    onClick={() => handleOnCloseModal()}
                  >
                    {t("discard")}
                  </button>
                  <button className="deleteVariationBtn modalDiscardBtn active">
                    {t("deleteVariation")}
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "editVariantModal" ? (
              <>
                <div></div>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : modalDetail.flag === "addAttributeModal" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {t("addAttributes")}
                </h3>

                <div className="d-flex align-items-center">
                  <button className="modalDiscardBtn">{t("discard")}</button>
                  <button
                    className="modalNextBtn modalDiscardBtn"
                    onClick={() => {
                      setModalDetail({
                        show: true,
                        flag: "createVariations",
                      });
                      setKey(Math.random());
                    }}
                  >
                    {t("save")}
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "confirmModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-center w-100">
                  <img
                    src={Images?.confirmImg}
                    alt="confirm image"
                    className="img-fluid"
                  />
                </div>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer confirmCancel"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : modalDetail.flag === "createCoupon" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {t("createCoupon")}
                </h3>

                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer confirmCancel"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : (
              <>
                <h4 className="modalHeading_">{t("addCustomAttribute")}</h4>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  className="modal_cancel"
                >
                  <img
                    src={modalCancel}
                    className="ModalCancel"
                    alt="modalcancelImg"
                  />
                </p>
              </>
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default AddProduct;
