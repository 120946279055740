import calendarize from 'calendarize';
import dayjs from 'dayjs';
import { OVERLAP_PADDING } from '../commonStyles';
export var DAY_MINUTES = 1440;
export function getDatesInMonth(date, locale) {
    if (date === void 0) { date = new Date(); }
    if (locale === void 0) { locale = 'en'; }
    var subject = dayjs(date);
    var days = Array(subject.daysInMonth() - 1)
        .fill(0)
        .map(function (_, i) {
        return subject.date(i + 1).locale(locale);
    });
    return days;
}
export function getDatesInWeek(date, weekStartsOn, locale) {
    if (date === void 0) { date = new Date(); }
    if (weekStartsOn === void 0) { weekStartsOn = 0; }
    if (locale === void 0) { locale = 'en'; }
    var subject = dayjs(date);
    var subjectDOW = subject.day();
    var days = Array(7)
        .fill(0)
        .map(function (_, i) {
        return subject
            .add(i - (subjectDOW < weekStartsOn ? 7 + subjectDOW : subjectDOW) + weekStartsOn, 'day')
            .locale(locale);
    });
    return days;
}
export function getDatesInNextThreeDays(date, locale) {
    if (date === void 0) { date = new Date(); }
    if (locale === void 0) { locale = 'en'; }
    var subject = dayjs(date).locale(locale);
    var days = Array(3)
        .fill(0)
        .map(function (_, i) {
        return subject.add(i, 'day');
    });
    return days;
}
export function getDatesInNextOneDay(date, locale) {
    if (date === void 0) { date = new Date(); }
    if (locale === void 0) { locale = 'en'; }
    var subject = dayjs(date).locale(locale);
    var days = Array(1)
        .fill(0)
        .map(function (_, i) {
        return subject.add(i, 'day');
    });
    return days;
}
export var hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];
export function formatHour(hour, ampm) {
    if (ampm === void 0) { ampm = false; }
    if (ampm) {
        if (hour === 0) {
            return '';
        }
        if (hour === 12) {
            return "12 PM";
        }
        if (hour > 12) {
            return "".concat(hour - 12, " PM");
        }
        return "".concat(hour, " AM");
    }
    return "".concat(hour, ":00");
}
export function isToday(date) {
    var today = dayjs();
    return today.isSame(date, 'day');
}
export function getRelativeTopInDay(date) {
    return (100 * (date.hour() * 60 + date.minute())) / DAY_MINUTES;
}
export function todayInMinutes() {
    var today = dayjs();
    return today.diff(dayjs().startOf('day'), 'minute');
}
export function modeToNum(mode, current) {
    if (mode === 'month') {
        if (!current) {
            throw new Error('You must specify current date if mode is month');
        }
        if (current instanceof Date) {
            current = dayjs(current);
        }
        return current.daysInMonth() - current.date() + 1;
    }
    switch (mode) {
        case 'day':
            return 1;
        case '3days':
            return 3;
        case 'week':
        case 'custom':
            return 7;
        default:
            throw new Error('undefined mode');
    }
}
export function formatStartEnd(start, end, format) {
    return "".concat(dayjs(start).format(format), " - ").concat(dayjs(end).format(format));
}
export function isAllDayEvent(start, end) {
    var _start = dayjs(start);
    var _end = dayjs(end);
    return _start.hour() === 0 && _start.minute() === 0 && _end.hour() === 0 && _end.minute() === 0;
}
export function getCountOfEventsAtEvent(event, eventList) {
    return eventList.filter(function (e) {
        return dayjs(event.start).isBetween(e.start, e.end, 'minute', '[)') ||
            dayjs(e.start).isBetween(event.start, event.end, 'minute', '[)');
    }).length;
}
export function getOrderOfEvent(event, eventList) {
    var events = eventList
        .filter(function (e) {
        return dayjs(event.start).isBetween(e.start, e.end, 'minute', '[)') ||
            dayjs(e.start).isBetween(event.start, event.end, 'minute', '[)');
    })
        .sort(function (a, b) {
        if (dayjs(a.start).isSame(b.start)) {
            return dayjs(a.start).diff(a.end) < dayjs(b.start).diff(b.end) ? -1 : 1;
        }
        else {
            return dayjs(a.start).isBefore(b.start) ? -1 : 1;
        }
    });
    var index = events.indexOf(event);
    return index === -1 ? 0 : index;
}
export function getStyleForOverlappingEvent(eventPosition, overlapOffset, palettes) {
    var overlapStyle = {};
    var offset = overlapOffset;
    var start = eventPosition * offset;
    var zIndex = 100 + eventPosition;
    var bgColors = palettes.map(function (p) { return p.main; });
    overlapStyle = {
        start: start + OVERLAP_PADDING,
        end: OVERLAP_PADDING,
        backgroundColor: bgColors[eventPosition % bgColors.length] || bgColors[0],
        zIndex: zIndex,
    };
    return overlapStyle;
}
export function getDatesInNextCustomDays(date, weekStartsOn, weekEndsOn, locale) {
    if (date === void 0) { date = new Date(); }
    if (weekStartsOn === void 0) { weekStartsOn = 0; }
    if (weekEndsOn === void 0) { weekEndsOn = 6; }
    if (locale === void 0) { locale = 'en'; }
    var subject = dayjs(date);
    var subjectDOW = subject.day();
    var days = Array(weekDaysCount(weekStartsOn, weekEndsOn))
        .fill(0)
        .map(function (_, i) {
        return subject.add(i - subjectDOW + weekStartsOn, 'day').locale(locale);
    });
    return days;
}
// TODO: This method should be unit-tested
function weekDaysCount(weekStartsOn, weekEndsOn) {
    // handle reverse week
    if (weekEndsOn < weekStartsOn) {
        var daysCount = 1;
        var i = weekStartsOn;
        while (i !== weekEndsOn) {
            ++i;
            ++daysCount;
            if (i > 6) {
                i = 0;
            }
            // fallback for infinite
            if (daysCount > 7) {
                break;
            }
        }
        return daysCount;
    }
    // normal week
    if (weekEndsOn > weekStartsOn) {
        return weekEndsOn - weekStartsOn + 1;
    }
    // default
    return 1;
}
export function getEventSpanningInfo(event, date, dayOfTheWeek, calendarWidth, showAdjacentMonths) {
    var dayWidth = calendarWidth / 7;
    // adding + 1 because durations start at 0
    var eventDuration = Math.floor(dayjs.duration(dayjs(event.end).diff(dayjs(event.start))).asDays()) + 1;
    var eventDaysLeft = Math.floor(dayjs.duration(dayjs(event.end).diff(date)).asDays()) + 1;
    var weekDaysLeft = 7 - dayOfTheWeek;
    var monthDaysLeft = date.endOf('month').date() - date.date();
    var isMultipleDays = eventDuration > 1;
    // This is to determine how many days from the event to show during a week
    var eventWeekDuration = !showAdjacentMonths && monthDaysLeft < 7 && monthDaysLeft < eventDuration
        ? monthDaysLeft + 1
        : eventDaysLeft > weekDaysLeft
            ? weekDaysLeft
            : eventDaysLeft < eventDuration
                ? eventDaysLeft
                : eventDuration;
    var isMultipleDaysStart = isMultipleDays &&
        (date.isSame(event.start, 'day') ||
            (dayOfTheWeek === 0 && date.isAfter(event.start)) ||
            (!showAdjacentMonths && date.get('date') === 1));
    // - 6 to take in account the padding
    var eventWidth = dayWidth * eventWeekDuration - 6;
    return { eventWidth: eventWidth, isMultipleDays: isMultipleDays, isMultipleDaysStart: isMultipleDaysStart, eventWeekDuration: eventWeekDuration };
}
export function getWeeksWithAdjacentMonths(targetDate, weekStartsOn) {
    var weeks = calendarize(targetDate.toDate(), weekStartsOn);
    var firstDayIndex = weeks[0].findIndex(function (d) { return d === 1; });
    var lastDay = targetDate.endOf('month').date();
    var lastDayIndex = weeks[weeks.length - 1].findIndex(function (d) { return d === lastDay; });
    weeks = weeks.map(function (week, iw) {
        return week.map(function (d, id) {
            if (d !== 0) {
                return d;
            }
            else if (iw === 0) {
                return d - (firstDayIndex - id - 1);
            }
            else {
                return lastDay + (id - lastDayIndex);
            }
        });
    });
    return weeks;
}
