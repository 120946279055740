import * as React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useTheme } from '../theme/ThemeContext';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

var _HourGuideCell = function (_a) {
    var cellHeight = _a.cellHeight, onPress = _a.onPress, date = _a.date, hour = _a.hour, index = _a.index, calendarCellStyle = _a.calendarCellStyle;
    var theme = useTheme();
    var getCalendarCellStyle = React.useMemo(function () { return (typeof calendarCellStyle === 'function' ? calendarCellStyle : function () { return calendarCellStyle; }); }, [calendarCellStyle]);
    return (React.createElement(TouchableWithoutFeedback, { style: { marginTop: 10 }, onPress: function () { return onPress(date.hour(hour).minute(0)); } },
        React.createElement(View, { style: [
                // u['border-l'],
                // u['border-b'],
                { height: cellHeight + 12, borderColor: '#E4E6F2', borderWidth: 1, borderRadius: 10 },
                __assign({}, getCalendarCellStyle(date.toDate(), index)),
            ] }, [1, 2, 3, 4].map(function (item) { return (React.createElement(View, { key: item, style: [
                // u['border-l'],
                // u['border-b'],
                // u['border-r'],
                {
                    height: cellHeight / 4,
                    marginHorizontal: 3,
                    marginTop: 3,
                    borderStyle: 'dotted',
                    borderRadius: 1,
                    borderWidth: 1,
                    borderColor: theme.palette.gray['300'],
                },
                __assign({}, getCalendarCellStyle(date.toDate(), index)),
            ] })); }))));
};
export var HourGuideCell = React.memo(_HourGuideCell);
