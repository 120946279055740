import React, { useEffect, useRef, useState } from "react";
import { selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getOrderDetailByOrderIdAsync, getOrderDetailsAsync } from "../../dashboard/dashboardSlice";
import GoogleMapView from "../../../components/UI/Orders/GoogleMapView";
import { Card, Col, Row } from "react-bootstrap";
import { unwrapResult } from "@reduxjs/toolkit";
import OrderTrackingDetails from "./OrderTrackingDetails";
import OrderInvoiceDetails from "../orders/OrderInvoiceDetails";
import OrderList from "./OrderList";
import {

  NotificationIcon,
  SearchIcon,
} from "../../../../utilities/images";
import OrderTrackStatusCard from "../orders/OrderTrackStatusCard";
import Loader from "../../../components/UI/Loader/Loader";
import OrderDetails from "./OrderDetails";
import { handleOrderSatus } from "../../../constants/orders/orders";
import NotificationButton from "../../../components/NotificationButton";
import { useTranslation } from "react-i18next";
import { addPadStart } from "../../../../utilities/helpers";
const OrderTracking = () => {
  const { t } = useTranslation();
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState("activeData");
  const [searchKeyword, setsearchKeyword] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [isSearching, setisSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [orderIndex, setorderIndex] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [openOrderDetails, setopenOrderDetails] = useState(false);
  const [showOrderInvoice, setshowOrderInvoice] = useState(true);
  const [isFetchingNextPage, setisFetchingNextPage] = useState(false);
  const listRef=useRef();
    const isFetching = useRef(false); // Prevent multiple fetch calls

  //get order details
  const [totalData, settotalData] = useState(20)
  const getAllOrderDetails = async () => {
    setLoading(true);
    let params = {
      postData: {
        seller_id: 
        auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
        limit:pageLimit,
        page:currentPage,
        order_type:"product",
        delivery_option:"1,4",
        // [tableData=="activeData"?"status":"need_returned"]:tableData=="activeData"?"4":true,
        status:tableData=="activeData"?"4":"9",
        need_returned:false,
        ...(searchKeyword.length > 0 && {
          search: searchKeyword,
        }),
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getOrderDetailsAsync(params))
      .then(unwrapResult)
      .then((res) => {
        let data=res?.payload?.data;
        let currentPageData=res?.payload?.per_page||0;
        let pages=res?.payload?.total_pages||1;
        if(isFetchingNextPage){
          setOrderDetails(prev=>([...prev,...data]));
          settotalData(prev=>prev+currentPageData);

        }
        else{
          setOrderDetails(res?.payload?.data);
          if (data) {
            setorderIndex(0);
          }
          settotalData(currentPageData);
          setTotalPages(pages);
        }
        setisFetchingNextPage(false);
        //convert payload according to our need
        setLoading(false);
        isFetching.current=false;
      })
      .catch((e) => {
        setLoading(false);
        isFetching.current=false;
        setisFetchingNextPage(false);
      });
  };
  useEffect(() => {
    getAllOrderDetails();
    resetFields()
  }, [tableData]);
  const resetFields = () => {
    setCurrentPage(1);
    setorderIndex(null);
    setCurrentOrder(null);
    settotalData(10);
  }
  const handleToggleData = (status) => {
    setTableData(status);
  };
  useEffect(()=>{
    if(orderIndex!==null){
      setCurrentOrder(orderDetails[orderIndex]);
    }
  },[orderIndex])
  // search
  useEffect(() => {
    if(!isSearching)return;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    let searchTimer = setTimeout(() => {
      setisSearching(false);
      resetFields();
      getAllOrderDetails();
      
    }, 1000);
    setsearchTimeout(searchTimer);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKeyword]);
const getOrderDataById = () => {
  let id=currentOrder.id;
  if(!id)return;
    let data = {
      id: id,
      token: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getOrderDetailByOrderIdAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        let orderDetails = obj?.payload;
        setCurrentOrder(orderDetails);
        setLoading(false);

      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const handleCurrentPage=()=>{
    if(isFetching.current)return;
    setCurrentPage(prev=>{
      if(prev+1<=totalPages){
        setisFetchingNextPage(true);
        isFetching.current=true;
        return prev+1;}
      return prev
    })
  }
  useEffect(()=>{
    if(isFetching.current){
      getAllOrderDetails();
    }
  },[currentPage]);
  useEffect(()=>{
    let container=listRef.current;
    if(!container)return;
    const handleScroll=()=>{
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - 10
      ) {
        handleCurrentPage();
      }
    }
    container.addEventListener("scroll", handleScroll);
    return ()=>{
      container.removeEventListener("scroll",handleScroll);
    }
  },[listRef,currentPage,totalPages])
  return (
    <>
      <div className="d-flex position-relative">
        <>
          <div className="orderTrackSection position-absolute w-100 h-100">
            <div className="container-fluid">
              <Row>
                <Col
                  // xs={!showOrderInvoice ? 4 : 8}
                  xs={6}
                  className="position-relative"
                >
                  {loading && <Loader />}{" "}
                  {showOrderInvoice?  <div ref={listRef} className="orderTrackLeft">
                      <div className="orderTrackHead">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <h3 className="fontSize24 fw500 txtDarkblue">
                            {t('orderTracking')}
                            <span className="fontSize16 fw400 allproductCount">
                              ({
                              addPadStart(totalData??0)
                              // totalData
                              })
                            </span>
                          </h3>
                          <div className="d-flex align-items-center gap-3">
                          <NotificationButton />
                              {/* <img src={SearchIcon} alt="img" />
                               */}
                               <div style={{width:"150px"}} className="input-group search_group">
                                <img
                                  src={SearchIcon}
                                  alt=""
                                  className="img-fluid searchIcon"
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>{
                                    setisSearching(true);
                                    setsearchKeyword(e.target.value)}}
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                              </div>
                            </div>
                          </div>

                        <div className="orderTrackHeaderToggle d-flex align-items-center gap-2">
                          <button
                            className={`activeProductBtn ${
                              tableData == "activeData" ? "active" : ""
                            }`}
                            onClick={() => handleToggleData("activeData")}
                          >
                            {t('active')}{" "}
                            <span className="activeProductofferItem"></span>
                          </button>
                          <button
                            className={`activeProductBtn ${
                              tableData == "completeData" ? "active" : ""
                            }`}
                            onClick={() => handleToggleData("completeData")}
                          >
                            {t('returning')}
                            <span className="activeProductofferItem ps-1"></span>
                          </button>
                        </div>
                      </div>
                      <OrderList
                        showOrderInvoice={showOrderInvoice}
                        orderDetails={orderDetails}
                        setorderIndex={(value) => setorderIndex(value)}
                        setshowOrderInvoice={(value) =>
                          setshowOrderInvoice(value)
                        }
                        handleCurrentPage={handleCurrentPage}
                        orderIndex={orderIndex}
                        setopenOrderDetails={(value) =>
                          setshowOrderInvoice(value)
                        }
                      />
                    </div>
                    :
                  (
                      <OrderInvoiceDetails
                        orderDetails={currentOrder}
                        setopenOrderDetails={(value) =>
                          setshowOrderInvoice(true)
                        }
                      status={handleOrderSatus(currentOrder?.delivery_option, currentOrder?.status)}
                      />
                    )}
                </Col>
                <Col 
                xs={6}
                // xs={!showOrderInvoice ? 6 : 6}
                >
                 {currentOrder!==null&& <OrderDetails
                  setshowOrderInvoice={(val)=>setshowOrderInvoice(val)}
                  orderDetails={currentOrder}
                  getAllOrderDetails={getOrderDataById}
                  getallOrders={getAllOrderDetails}
                  />}
                 
                </Col>
              </Row>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default OrderTracking;
