import React from "react";
import * as Images from "../../../utilities/images";
const CheckedInModal = ({ title, subtitle }) => {
  return (
    <>
      <div className="checkedInSec text-center">
        <img
          src={Images.successfullyLogo}
          alt="checkedImage"
          className="img-fluid "
        />

        <h1 className="checkedInTxt stopOfferTxt mb-3 mt-3">Checked In</h1>
        <h6 className="fontSize18 txtLightBlue mb-0">Successfully Confirmed</h6>
      </div>
    </>
  );
};

export default CheckedInModal;
