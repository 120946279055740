import React from 'react'
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ItemandInventory = () => {
    const history = useHistory();
    return (
        <div className='itemInventory_ '>
            <div className='chartsOuter jbrHeight mt-0'>
                <div className='inventoryHead_'>
                    <h2 className='Innerhead mb-2'><Link to="/support"><img className='arrowicon_ me-2' src={Images.leftArrowColored} /></Link> Items and inventory </h2>
                    <p className='bankaccNumber_ ps-4'>Build your catalog of items and sync to your point-of-sale devices and online store. Learn how to track inventory and manage vendors.</p>
                </div>
                <div className='innerInventory_ mt-5 ps-5 pe-5 mb-3'>
                    <div className='subInventory_'>
                        <h4 className='subhaedHeading mb-3'>Item creation and import</h4>
                        <div className='listinvent_'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit items</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} onClick={() => history.push("/supportInnertopic")} />
                        </div>
                        <div className='listinvent_'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Bulk import items in JOBR Dashboard</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Manage item subscription plans</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_ pb-0'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit bundles with JOBR for Retail</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                    </div>

                    <div className='subInventory_ mt-4'>
                        <h4 className='subhaedHeading mb-3'>Item customization</h4>
                        <div className='listinvent_'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit item modifiers</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit item variations and options</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create non-sellable variations</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_ '>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit custom item attributes</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_ '>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit item categories</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_ '>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit discounts</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_ '>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Create and edit item unit types</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_ '>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Upload and manage item images</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                        <div className='listinvent_ pb-0'>
                            <article className='inventleft'>
                                <h5 className='mainboldHead'>Bulk edit items</h5>
                                <p>Items are sellable goods and services that make up your item catalog. As you add your business details to JOBR, you can create . . . </p>
                            </article>
                            <img className='arrowicon_ me-2' src={Images.upArrow} />
                        </div>
                    </div>
                    <div className='supportContact_ mt-4'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h3 className='appointMain'>Need help? we’ve got your back</h3>
                                <p className='greyPara_'>Perhaps you can find the answer in our collections </p>
                            </div>
                            <div className='col-md-12'>
                                <div className='conatctUS_'>
                                    <div className='contactTop_ mb-4'>
                                        <figure className='chatOut mb-0'> <img src={Images.chaticon_} alt='' className='' /></figure>
                                        <Link to="#" className="contactArrow_"><img src={Images.ArrowRight} alt='Arrow' className='' /></Link>
                                    </div>

                                    <h5 className='heading20Black_ mb-1'>Contact us</h5>
                                    <p className='darkgreyPar_'>Get help from our support team</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ItemandInventory