import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import DeleteProductModal from "./DeleteProductModal";
import DeleteModal from "./DeleteModal";
import EditLocationModal from "./EditLocationModal";
import EditSalePriceModal from "./EditSalePriceModal";
import {
  deleteProductApiAsync,
  editProductLocationApiAsync,
  editProductPriceApiAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { addMoreColumnsSettings, selectPagesColumnData } from "../../pagesColumnReducer";

const ProductSubTable = (props) => {
  const { t } = useTranslation();
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  const toastId = useRef(null);

  const [allAddress, setAllAddress] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const { productList, productLoading } = props;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [locationLoading, setLoactionLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const pageColumn=useSelector(selectPagesColumnData);
  const disPatch = useDispatch();
  // Default column visibility state
  const defaultColumns = {
    category: true,
    subCategory: true,
    costPrice: true,
    posPrice: true,
    b2cPrice: true,
    upc: false,
    weight: false,
    stock: false,
    availableSale: false,
  };

  const [columns, setColumns] = useState(
    JSON.parse(JSON.stringify(
      pageColumn?.product|| defaultColumns))
    );

  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    productId: null,
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      productId: null,
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag, productId) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
      productId: productId,
    });
    setKey(Math.random());
  };

  // End Modal

  const anyInactiveSelected = ["upc", "weight", "stock", "availableSale"].some(
    (col) => columns[col]
  );

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Toggle function for specific columns
  const toggleColumn = (column) => {
      disPatch(addMoreColumnsSettings({
        key:"product",
        data:{
          ...columns,
          [column]:!columns[column]
        }
    }))

    setColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleCheckboxChange = (index) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const resetColumns = () => {
    setColumns(defaultColumns);
    disPatch(addMoreColumnsSettings({
      key:"product",
      data:defaultColumns
    }))
  };

  // Download selected data as CSV
  const downloadCSV = () => {
    if (!Array.isArray(productList) || productList.length === 0) {
      showToast("No data available to download.");
      return;
    }

    // Filter selected products
    const selectedData = selectedProducts.map((index) => productList[index]);

    if (selectedData.length === 0) {
      showToast("Please select products to download.");
      return;
    }

    // Prepare CSV headers and rows
    const headers = [
      "Product Name",
      "Product Image",
      "Category",
      "Sub-Category",
      "Brand",
      "Cost Price",
      "POS Price",
      "B2C Price",
      "UPC",
      "Weight",
      "Stock",
      "Available Sale",
    ];

    const rows = selectedData.map((product) => [
      product?.name || "",
      product?.image || "",
      product?.category?.name || "",
      product?.sub_category?.name || "",
      product?.brand?.name || "",
      product?.price || "",
      product?.supplies?.[0]?.supply_prices?.find((v) => v?.app_name === "pos")
        ?.selling_price || "",
      product?.supplies?.[0]?.supply_prices?.find((v) => v?.app_name === "b2c")
        ?.selling_price || "",
      "", // UPC placeholder (if available)
      "", // Weight placeholder (if available)
      "", // Stock placeholder (if available)
      "", // Available Sale placeholder (if available)
    ]);

    // Convert to CSV string
    const csvContent = [
      headers.join(","), // Add headers
      ...rows.map((row) => row.map((value) => `"${value}"`).join(",")), // Add rows
    ].join("\n");

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "selected_product_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDeleteProduct = () => {
    // if (selectedProducts.length === 0 && modalDetail.productId === null) {
    //   showToast("Please select at least one product to delete.");
    //   return;
    // }
    setDeleteLoading(true);

    // Prepare params for the delete request
    let params = {};

    // Handle individual product deletion
    if (modalDetail.productId !== null) {
      const productToDelete = modalDetail.productId;
      params = {
        postData: { product_ids: [productToDelete] },
        tokenData: auth?.payload?.token,
      };
    } else {
      // Handle bulk deletion
      const productIds = selectedProducts.map((index) => productList[index].id);
      params = {
        postData: { product_ids: productIds },
        tokenData: auth?.payload?.token,
      };
    }

    // Dispatch delete action
    dispatch(deleteProductApiAsync(params))
      .then(unwrapResult)
      .then(() => {
        // Open delete modal and close after 5 seconds
        handleOpenModal("deleteModal", null);

        setTimeout(() => {
          handleOnCloseModal(); // Ensure modal closes after 5 seconds
          props.getProductList(); // Fetch updated product list only once
        }, 2000); // Adjusted timeout to 5 seconds
        setSelectedProducts([]);
        setDeleteLoading(false);
      })
      .catch(() => {
        showToast("Error deleting products.");
        setDeleteLoading(false);
      });
  };

  const handleDeleteClick = () => {
    if (!selectedProducts.length) {
      showToast("Please select at least one product to delete.");
      return;
    }
    handleOpenModal("deleteProductModal", null);
  };

  const handleBulkSale = () => {
    if (!selectedProducts.length) {
      showToast("Please select at least one product ");
      return;
    }
    handleOpenModal("editSalePriceModal", null);
  };
  const handleLocation = () => {
    if (!selectedProducts.length) {
      showToast("Please select at least one product ");
      return;
    }
    handleOpenModal("editLocationModal", null);
  };

  const handleEditLocation = (deliveryOption, id) => {
    const productIds = selectedProducts.map((index) => productList[index].id);
    const params = {
      postData: {
        product_ids: productIds,
        seller_address_ids: id,
        delivery_options: deliveryOption,
      },
      tokenData: auth?.payload?.token,
    };
    setLoactionLoading(true);
    dispatch(editProductLocationApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        handleOnCloseModal();
        props.getProductList();
        setLoactionLoading(false);
        setSelectedProducts([]);
      })
      .catch((obj) => {
        setLoactionLoading(false);
      });
  };

  const handleEditPrice = (id, price, appName) => {
    const productIds = selectedProducts.map((index) => productList[index].id);
    const params = {
      postData: {
        product_ids: productIds,
        seller_address_ids: id,
        selling_price: price,
        app_names: appName,
      },
      tokenData: auth?.payload?.token,
    };
    setPriceLoading(true);
    dispatch(editProductPriceApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        handleOnCloseModal();
        props.getProductList();
        setPriceLoading(false);
        setSelectedProducts([]);
      })
      .catch((obj) => {
        setPriceLoading(false);
      });
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];

        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  return (
    <>
      <div className="commonTable productTable table-responsive mt-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>
                <div className="productAvailabilityChild align-items-center">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="selectAllCheckbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedProducts(productList.map((_, idx) => idx));
                        } else {
                          setSelectedProducts([]);
                        }
                      }}
                      checked={
                        productList?.length > 0 &&
                        selectedProducts.length === productList.length
                      }
                    />
                    <label htmlFor="selectAllCheckbox" className="me-0"></label>
                  </div>
                  <h3 className="fontSize12 mb-0">{t("product")}</h3>
                </div>
              </th>

              {columns.category && <th>{t("category")}</th>}
              {columns.subCategory && <th>{t("subCategory")}</th>}
              {columns.costPrice && <th>{t("costPrice")}</th>}
              {columns.posPrice && <th>POS Price</th>}
              {columns.b2cPrice && <th>B2C Price</th>}
              {columns.upc && <th>{t("upc")}</th>}
              {columns.weight && <th>{t("weight")}</th>}
              {columns.stock && <th>{t("stock")}</th>}
              {columns.availableSale && <th>{t("availableSale")}</th>}

              <th>
                <div className="productTableLastChild d-flex align-items-center gap-1 justify-content-end">
                  <img
                    src={Images.downloadLogo}
                    alt="logo"
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadCSV();
                    }}
                    height={17}
                    width={17}
                    className="cursorPoint"
                  />
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.plusIconWithBg} alt="icon" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu variantDropSelect">
                        <Dropdown.Item>
                          <h3
                            className={`dropdownResetTxt ${
                              anyInactiveSelected ? "active " : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent dropdown from closing on "Reset" click
                              resetColumns();
                            }}
                          >
                            {t("reset")}
                          </h3>
                        </Dropdown.Item>

                        {Object.keys(columns).map((col, index) => (
                          <Dropdown.Item key={col}>
                            <div
                              className="productAvailabilityChild align-items-center"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id={`id3-${index}`}
                                  checked={columns[col]}
                                  onChange={() => toggleColumn(col)}
                                />
                                <label
                                  htmlFor={`id3-${index}`}
                                  className="me-0"
                                ></label>
                              </div>
                              <h3 className="fontSize14 mb-0 textCapital">
                                {t(col).replace(/([A-Z])/g, " $1")}
                              </h3>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                        disabled={
                          !Array.isArray(selectedProducts) ||
                          selectedProducts.length === 0
                        }
                      >
                        <img
                          src={Images.moreHorizontalLogo}
                          alt="logo"
                          style={{
                            filter:
                              Array.isArray(selectedProducts) &&
                              selectedProducts.length > 0
                                ? "brightness(0) saturate(100%) invert(14%) sepia(63%) saturate(2662%) hue-rotate(221deg) brightness(94%) contrast(89%)"
                                : "",
                          }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu ">
                        <Dropdown.Item>
                          <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                            {t("bulkEditing")}
                          </h3>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLocation}>
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.locationLogo} alt="logo" />
                            <h3 className="fontSize14 mb-0">Edit Location</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleBulkSale}>
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.moneyPaperIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">
                              {t("editSalesPricing")}
                            </h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleDeleteClick}>
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              {t("delete")}
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {productLoading ? (
              <>
                <tr>
                  <td colSpan={11}>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="spinner-border spinner-border-md spinnerDark"></span>
                    </div>
                  </td>
                </tr>
              </>
            ) : Array.isArray(productList) && productList.length > 0 ? (
              productList.map((product, index) => (
                <tr key={index}>
                  <td>
                    <div className="productAvailabilityChild align-items-center">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id={`product-checkbox-${index}`}
                          checked={selectedProducts.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label
                          htmlFor={`product-checkbox-${index}`}
                          className="me-0"
                        ></label>
                      </div>

                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={product?.image}
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left textCapital">
                            {product?.name}
                          </h3>
                          <div className="d-flex align-items-center gap-2">
                            {/* <span className="skyblueDot"></span> */}
                            <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                              SKU{" "}
                              {product?.supplies?.[0]?.supply_variants?.[0]
                                ?.sku || ""}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                  {columns.category && (
                    <td className="fontSize12 txtLightBlue">
                      {product?.category?.name}
                    </td>
                  )}
                  {columns?.subCategory && (
                    <td className="fontSize12 txtLightBlue">
                      {product?.sub_category?.name}
                    </td>
                  )}
                  {columns?.costPrice && (
                    <td className="fontSize12 txtLightBlue">
                      {" "}
                      {"$"} {product?.price}
                    </td>
                  )}

                  {columns?.posPrice && (
                    <td className="fontSize12 txtLightBlue">
                      {"$"}{" "}
                      {
                        product?.supplies?.[0]?.supply_prices?.find(
                          (v) => v?.app_name === "pos"
                        )?.selling_price
                      }
                    </td>
                  )}
                  {columns?.b2cPrice && (
                    <td className="fontSize12 txtLightBlue">
                      {"$"}{" "}
                      {
                        product?.supplies?.[0]?.supply_prices?.find(
                          (v) => v?.app_name === "b2c"
                        )?.selling_price
                      }
                    </td>
                  )}
                  {columns.upc && (
                    <td className="fontSize12 txtLightBlue">
                      {product?.supplies?.[0]?.supply_variants?.[0]?.barcode}
                    </td>
                  )}
                  {columns.weight && (
                    <td className="fontSize12 txtLightBlue">
                      {product?.supplies?.[0]?.supply_variants?.[0]?.weight}
                    </td>
                  )}
                  {columns.stock && (
                    <td className="fontSize12 txtLightBlue">
                      {
                        product?.supplies?.[0]?.supply_variants?.[0]
                          ?.total_quantity
                      }
                    </td>
                  )}
                  {columns.availableSale && (
                    <td className="fontSize12 txtLightBlue">
                      {
                        product?.supplies?.[0]?.supply_variants?.[0]
                          ?.rest_quantity
                      }
                    </td>
                  )}

                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.moreVerticalLogo} alt="logo" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                        <Dropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: `/editProducts`,
                              state: product,
                            })
                          }
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.darkBlueEyeIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">{t("view")}</h3>
                          </div>
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#/action-2">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.editIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">Edit Variants</h3>
                          </div>
                        </Dropdown.Item> */}
                        <Dropdown.Item
                          onClick={() =>
                            handleOpenModal("deleteProductModal", product?.id)
                          }
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              {t("delete")}
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="fontSize14 txtLightBlue" colSpan={11}>
                  {t("noRecordFound")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "deleteProductModal"
            ? "stopOfferModal"
            : modalDetail.flag === "deleteModal"
            ? "deleteModal"
            : modalDetail.flag === "editLocationModal"
            ? "editLocationModal"
            : modalDetail.flag === "editSalePriceModal"
            ? "editLocationModal"
            : ""
        }
        child={
          modalDetail.flag === "deleteProductModal" ? (
            <DeleteProductModal
              close={() => handleOnCloseModal()}
              handleDeleteProduct={handleDeleteProduct}
              deleteLoading={deleteLoading}
            />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editLocationModal" ? (
            <EditLocationModal
              close={() => handleOnCloseModal()}
              handleUpdate={(deliveryOption, id) =>
                handleEditLocation(deliveryOption, id)
              }
              loading={locationLoading}
              allAddress={allAddress}
            />
          ) : modalDetail.flag === "editSalePriceModal" ? (
            <EditSalePriceModal
              close={() => handleOnCloseModal()}
              allAddress={allAddress}
              handleUpdate={(id, price, appName) =>
                handleEditPrice(id, price, appName)
              }
              loading={priceLoading}
            />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "editLocationModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      {selectedProducts?.length} items selected
                    </h6>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                      Update visibility
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    className="cursorPointer"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : modalDetail.flag === "editSalePriceModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      {selectedProducts?.length} items selected
                    </h6>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                      Update sale price
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    className="cursorPointer"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ProductSubTable;
