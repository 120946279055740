import { toast } from "react-toastify";
import { getColorName } from "../../../utilities/helpers";

function formValidator(obj, sellerAddressIds, sku) {
  console.log(obj, "objyyyyy");

  if (!obj?.image) {
    toast.error("Please select product images");
    return false;
  }
  if (!obj?.name) {
    toast.error("Please enter product name");
    return false;
  }
  if (!obj?.service_id) {
    toast.error("Please select master category");
    return false;
  }
  if (!obj?.category_id) {
    toast.error("Please select category");
    return false;
  }
  if (!obj?.sub_category_id) {
    toast.error("Please select sub-category");
    return false;
  }
  if (!obj?.brand_id) {
    toast.error("Please select brand");
    return false;
  }
  if (!obj?.description) {
    toast.error("Please enter product description");
    return false;
  }

  if (!obj?.type) {
    toast.error("Please enter product type");
    return false;
  }
  if (!obj?.barcode) {
    toast.error("Please enter barcode");
    return false;
  }

  if (
    (obj?.barcode && obj?.barcode?.length < 11) ||
    (obj?.barcode && obj?.barcode?.length > 14)
  ) {
    toast.error("Please enter valid barcode");
    return false;
  }

  if (!sku) {
    toast.error("Please enter SKU");
    return false;
  }

  if ((sku && sku?.length < 11) || (sku && sku?.length > 14)) {
    toast.error("Please enter valid SKU");
    return false;
  }

  if (!obj?.cost_price) {
    toast.error("Please enter cost price");
    return false;
  }

  if (!obj?.delivery_options || obj?.delivery_options.length === 0) {
    toast.error("Please select delivery options");
    return false;
  }

  if (obj?.delivery_options.includes("shipping") > 0) {
    const { weight, length, breadth, height } = obj?.dimensions || {};

    if (!weight) {
      toast.error("Please enter product weight");
      return false;
    }

    if (!length) {
      toast.error("Please enter product length");
      return false;
    }

    if (!breadth) {
      toast.error("Please enter product breadth");
      return false;
    }

    if (!height) {
      toast.error("Please enter product height");
      return false;
    }
  }

  if (!sellerAddressIds || sellerAddressIds.length === 0) {
    toast.error("Please select store");
    return false;
  }

  // Address-based variant validation
  if (
    !Array.isArray(obj.addresses_variants) ||
    obj.addresses_variants.length === 0
  ) {
    toast.error("Please select at least one store with variants");
    return false;
  }

  if (!Array.isArray(obj.attributes) || obj.attributes.length <= 0) {
    for (const address of obj.addresses_variants) {
      if (!address.seller_address_id) {
        toast.error("Seller address ID is missing in addresses_variants");
        return false;
      }

      if (!Array.isArray(address.variants) || address.variants.length === 0) {
        toast.error(
          `Variants are missing for seller address ID ${address.seller_address_id}`
        );
        return false;
      }

      const upcSet = new Set();

      for (const variant of address.variants) {
        if (!variant.barcode || variant.barcode.trim() === "") {
          toast.error("Please enter Barcode");
          return false;
        }

        // Validate UPC length (11-14 characters)
        if (variant.barcode.length < 11 || variant.barcode.length > 14) {
          toast.error("Please enter valid barcode");
          return false;
        }

        if (!Array.isArray(variant?.prices) || variant.prices.length === 0) {
          toast.error("Please enter selling price for POS and  B2C");
          return false;
        }

        // Check each price object for missing selling_price
        for (const price of variant.prices) {
          if (
            price?.app_name &&
            (!price?.selling_price || String(price.selling_price).trim() === "")
          ) {
            toast.error(`Selling price is missing for ${price.app_name}`);
            return false;
          }
        }

        // Validate Quantity and Reorder Point
        const quantity = parseFloat(variant.quantity);
        const reorderPoint = parseFloat(variant.quantity_reorder_point);

        if (!quantity) {
          toast.error("Please enter quantity");
          return false;
        }

        if (!reorderPoint) {
          toast.error("Please enter quantity Re-order point");
          return false;
        }

        if (reorderPoint > quantity) {
          toast.error(
            "Quantity Reorder Point cannot be greater than Quantity for variant"
          );
          return false;
        }

        // Validate variant image
        if (!variant.image || variant.image.trim() === "") {
          toast.error("Please upload an image");
          return false;
        }
      }
    }
  } else {
    for (const address of obj.addresses_variants) {
      if (!address.seller_address_id) {
        toast.error("Seller address ID is missing in addresses_variants");
        return false;
      }

      if (!Array.isArray(address.variants) || address.variants.length === 0) {
        toast.error(
          `Variants are missing for seller address ID ${address.seller_address_id}`
        );
        return false;
      }

      const upcSet = new Set();

      for (const variant of address.variants) {
        const formattedAttributes = Object.entries(variant.attributes)
          .map(([key, val]) =>
            val.startsWith("#") &&
            /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(val)
              ? getColorName(val) // Convert hex color to name
              : val
          )
          .join(" / ");
        if (!variant.barcode || variant.barcode.trim() === "") {
          toast.error(`Please enter UPC for variant: ${formattedAttributes}`);
          return false;
        }

        // Validate UPC length (11-14 characters)
        if (variant.barcode.length < 11 || variant.barcode.length > 14) {
          toast.error(
            `Please enter a valid UPC for variant: ${formattedAttributes}`
          );
          return false;
        }

        // Check for duplicate UPCs within the same group
        if (upcSet.has(variant.barcode)) {
          toast.error(
            `Duplicate UPC found within the same group: ${variant.barcode}`
          );
          return false;
        }

        // Validate Selling Price
        if (!Array.isArray(variant.prices) || variant.prices.length === 0) {
          toast.error(
            `At least one price entry is required for variant: ${formattedAttributes}`
          );
          return false;
        }

        const hasSellingPrice = variant.prices.some(
          (price) =>
            price.selling_price && String(price.selling_price).trim() !== ""
        );

        if (!hasSellingPrice) {
          toast.error(
            `At least one selling price is required for variant: ${formattedAttributes}`
          );
          return false;
        }

        // Validate Quantity and Reorder Point
        const quantity = parseFloat(variant.quantity);
        const reorderPoint = parseFloat(variant.quantity_reorder_point);

        if (!quantity) {
          toast.error(
            `Please enter quantity for variant: ${formattedAttributes}`
          );
          return false;
        }

        if (!reorderPoint) {
          toast.error(
            `Please enter quantity Re-order point for variant: ${formattedAttributes}`
          );
          return false;
        }

        if (reorderPoint > quantity) {
          toast.error(
            `Quantity Reorder Point cannot be greater than Quantity for variant: ${formattedAttributes}`
          );
          return false;
        }

        // Validate variant image
        if (!variant.image || variant.image.trim() === "") {
          toast.error(
            `Please upload an image for variant: ${formattedAttributes}`
          );
          return false;
        }

        // Add UPC to set to check for duplicates within the group
        upcSet.add(variant.barcode);
      }
    }
  }

  return true;
}
export { formValidator };
