import { toast } from "react-toastify";

function formValidator(obj) {
  console.log(obj, "obj responseeeee");

  if (!obj?.image) {
    toast.error("Please select service images");
    return false;
  }
  if (!obj?.name) {
    toast.error("Please enter service name");
    return false;
  }
  if (obj?.service_id == "Select Services" || !obj?.service_id) {
    toast.error("Please select service");
    return false;
  }
  if (obj?.category_id == "Select Category" || !obj?.category_id) {
    toast.error("Please select catgeory");
    return false;
  }
  if (obj?.sub_category_id == "Select Sub-Category" || !obj?.sub_category_id) {
    toast.error("Please select sub-category");
    return false;
  }

  if (!obj?.description) {
    toast.error("Please enter service description");
    return false;
  }
  if (!obj?.cost_price) {
    toast.error("Please enter service Cost price");
    return false;
  }
  if (!obj?.delivery_options || obj?.delivery_options.length === 0) {
    toast.error("Please select delivery options");
    return false;
  }

  if (!obj?.seller_address_ids || obj.seller_address_ids.length === 0) {
    toast.error("Please select store");
    return false;
  }

  if (obj?.offer) {
    const { price_flag, actual_price, offer_price } = obj.offer;

    if (price_flag === "percentage") {
      const offerValue = parseFloat(offer_price);
      if (isNaN(offerValue) || offerValue < 1 || offerValue > 100) {
        toast.error("Percentage discount must be between 1 and 100");
        return false;
      }
    } else if (price_flag === "amount") {
      if (parseFloat(actual_price) < parseFloat(offer_price)) {
        toast.error("Discount amount cannot be greater than the actual price");
        return false;
      }
    }
  }

  // Check each price object for missing selling_price
  for (const price of obj?.variants[0].prices || []) {
    console.log(
      "Selling price:",
      price.selling_price,
      "Type:",
      typeof price.selling_price
    );
    if (
      price.app_name &&
      (!price.selling_price || String(price.selling_price).trim() === "")
    ) {
      toast.error(`Selling price is missing for ${price.app_name} `);
      return false;
    }
  }

  if (!obj?.approx_service_time) {
    toast.error("Please enter Service Estimated Time");
    return false;
  }
  if (!obj?.type) {
    toast.error("Please enter service type");
    return false;
  }
  if (obj?.pos_staff_ids?.length <= 0) {
    toast.error("Please select an Assigned team members");
    return false;
  }
  if (obj?.min_capacity == "Min") {
    toast.error("Please select minimum capacity");
    return false;
  }
  if (obj?.max_capacity == "Max") {
    toast.error("Please select maximum capacity");
    return false;
  }
  if (Number(obj?.min_capacity) > Number(obj?.max_capacity)) {
    toast.error("Minimum capacity cannot be greater than maximum capacity");
    return false;
  }
  // if(obj?.pos_staff_ids?.length <=0){
  //     toast.error("Please select an Pos user")
  //     return false
  // }
  return true;
}

export { formValidator };
