import React from "react";
import {

} from "../../../../utilities/images";
import * as Images from "../../../../utilities/images";
import moment from "moment-timezone";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatMilliseconds, parseStatusDesc } from "../../../../utilities/globalMethods";
const OrderList = ({
  showOrderInvoice,
  orderDetails,
  setorderIndex,
  setshowOrderInvoice,
  orderIndex,
  setopenOrderDetails,
  handleCurrentPage
}) => {
  const { t } = useTranslation();
  

  return (
    <>
      <div className="commonTable table-responsive orderTrackTable">
        <table className="w-100">
          <thead>
            <tr>
              <th
                onClick={() => {
                  // handleOpenModal("returnToInventoryModal");
                }}
              >
                #
              </th>
              <th>{t('clientItems')}</th>
              <th>{t('driverTiming')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orderDetails?.length > 0 ? (
              orderDetails?.map((order, index) => {
                let userDetails=order?.user_details||order?.invitation_details;
                let accepetdDate = parseStatusDesc(order?.status_desc)?.accepetdDate;
                let pickedupDate = parseStatusDesc(order?.status_desc)?.pickedupDate;
                const arrivesIn =
                  new Date(pickedupDate ?? 0).getTime() -
                  new Date(accepetdDate ?? 0).getTime();
              return  <tr
                  className={`${index === orderIndex ? "order_border" : ""}`}
                  onClick={() => {
                    setorderIndex(index);
                  }}
                  style={{
                    background:orderIndex===index?"#f5f5f5":"white"
                  }}
                >
                  <td className="orderTrackTableTxt">#{order?.return_detail
                    ? order?.return_detail?.invoices?.invoice_number
                    : order?.invoices?.invoice_number ?? "-"}</td>
                  <td>
                    <div>
                      <h3 className="orderTrackTableTxt">
                        {`${userDetails?.firstname??"---"} ${userDetails?.lastname??"---"}`}
                      </h3>
                      <h6 className="orderTrackLocation fontSize12 fw500 mb-0">
                        <img
                          src={Images.locateOrder}
                          alt="img"
                          className="location"
                        />
                        {order?.distance ? `${order.distance} ${"miles"}` : "0"}
                      </h6>
                    </div>
                    <div className="pt-3">
                      <h3 className="orderTrackTableTxt">
                        {order?.order_details?.length||order?.return_detail?.return_details?.length} items
                      </h3>
                      <h6 className="orderMoney orderTrackLocation fontSize12 fw500 mb-0">
                        <img
                          src={Images.moneyIcon_}
                          alt="img"
                          className="location"
                        />
                        ${Number(order?.payable_amount || 0).toFixed(2)}
                      </h6>
                    </div>
                  </td>
                  <td>
                   
                    {order?.delivery_option==1?
                    <div >
                    <div className="d-flex align-items-center gap-2 mb-1">
                      <img
                      style={{maxWidth:"30px",alignSelf:"baseline"}}
                        src={order?.driver_details?.profile_photo ||order?.shipping_details?.image || Images.userImg_}
                        alt="img"
                        className="orderTrackUserImg"
                      />
                      <div>

                      <h3 className="orderTrackTableTxt mb-0">
                        {order?.delivery_option==1? `${order?.driver_details?.firstname|| "__"} ${order?.driver_details?.lastname|| "__"}`:
                        order?.service_code??"__"
                      }
                      </h3>
                    <h6 className={`orderTrackLocation ${order?.delivery_option==1? "orderTrackingTime":"fedExSaver"} fontSize12 fw500 mb-0`}>
                      <img
                        src={order?.delivery_option==1? Images.clockBlue:
                          Images.fastLogo
                        }
                        alt="img"
                        className="location"
                      />
                      {order?.delivery_option==1?(order?.delivery_details?.title||order?.delivery_details?.
                          description|| "Immediately"):(
                            order?.shipping_details?.title??"___"
                          )}
                    </h6>
                    <div className="pt-3">
                    <h3 className="orderTrackTableTxt">
                      {order?.delivery_option==1? "Driver Arrives in:":"Shipped"}
                    </h3>
                    <h6 className={`orderTrackLocation ${order?.delivery_option==1? "orderTrackingTime":"fedExSaver"} fontSize12 fw500 mb-0`}>
                      <img
                        src={Images.clockBlue
                          }
                        alt="img"
                        className="location"
                      />
                   {order?.delivery_option==1? formatMilliseconds(arrivesIn || 0):"29 oct,2024 ||10:30am"}
                    </h6>
                  </div>
                      </div>
                    </div>
                  </div>
                  :<div>
                  <div className="flexBox gap-2">
                      <img style={{maxWidth:"30px",alignSelf:"baseline"}} src={order?.shipping_details?.image||Images.fedex} alt="img" className="fed" />
                      <div>
                        <h3 className="orderTrackTableTxt mb-1">
                          {order?.shipping_details?.title??"Shipping"}
                        </h3>
                        <h6 className="orderTrackLocation fontSize12 fw500 fedExSaver mb-0">
                          <img src={Images.fastLogo} alt="img" />{
                            "3 days Shipping"
                          }
                        </h6>
                        <div className="pt-3">
                    <h3 className="orderTrackTableTxt">
                      {order?.delivery_option==1? "Driver Arrives in:": "Shipped"}
                    </h3>
                    <h6 className={`orderTrackLocation ${order?.delivery_option == 1 ? "orderTrackingTime" : "fedExSaver"} fontSize12 fw500 mb-0`}>
                    <img src={Images.clockBlue} alt="img" className="location" />
                    {moment(order?.date).format("DD MMM, YYYY")} {" || "}
                    {moment(order?.date).format("hh:mm a")}
                  </h6>
                  </div>
                      </div>
                      
                    </div>
                   
                    </div>}
                  
                  
                    
                  </td>
                  <td>
                    <img
                      onClick={() => {
                        setshowOrderInvoice(false);
                    }}
                      src={Images.blueArrowTopRight}
                      alt="img"
                      width={16}
                      height={16}
                      className="cursorPointer"
                    />
                  </td>
                </tr>
})
            ) : (
              <tr>
                <td className="fontSize14 txtLightBlue text-center" colSpan={11}>
                  {t('noRecordfound')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default OrderList;