import React, { useState, useEffect } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { useHistory } from "react-router";
import { check } from "../../../../utilities/images";
import { toast } from "react-toastify";
import * as Images from "../../../../utilities/images";

const CreateAccountSetUp = () => {
  const toastId = React.useRef(null);
  const history = useHistory();
  const [consent, setConsent] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: localStorage.getItem("email_info") || "",
    password: "",
    confirmEmail: localStorage.getItem("email_info") || ""
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const [errors, setErrors] = useState({});
  const [strength, setStrength] = useState(null); // Track password strength
  const { firstname, lastname, email, password, confirmEmail } = formData;
  const phoneData = localStorage.getItem("phone_info")
    ? JSON.parse(localStorage.getItem("phone_info"))
    : "";
  // Function to handle form field change
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle password strength change
  const handlePasswordStrengthChange = (strengthLevel) => {
    setStrength(strengthLevel); // Set password strength level (0 to 4)
  };

  const validateForm = () => {
    const errors = {};
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
  
    if (!firstname) errors.firstname = "First name is required";
    if (!lastname) errors.lastname = "Last name is required";
    if (!email) errors.email = "Email is required";
  
    var resultemail = pattern.test(email);
    if (resultemail === false) errors.email = "Please enter valid email";
  
    if (email !== confirmEmail) errors.confirmEmail = "Emails do not match";
    // if (!password) errors.password = "Password is required";
    // if (password.length < 8)
    //   errors.password = "Password must be at least 8 characters";
    // if (password.startsWith(" "))
    //   errors.password = "Password should not start with a space";
  
    // // Check if the password contains at least 1 uppercase letter, 1 number, and 1 special character
    // const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/;
    // if (!passwordPattern.test(password)) {
    //   errors.password = "Password must contain at least one uppercase letter, one number, and one special character.";
    // }
  
    if (!consent) errors.consent = "You must agree to the terms and conditions";
  
    return errors;
  };
  

  // Handling form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const signUpData = JSON.stringify({ ...formData, consent });
      localStorage.setItem("sign_up_data", signUpData);
      // console.log('Form submitted successfully');
      history.push({ pathname: "onBoarding", state: signUpData });
    } else {
      setErrors(validationErrors);
    }
  };

  // Clear errors on input change
  useEffect(() => {
    setErrors({});
  }, [formData]);

  // Determine password strength text based on strength level
  const getPasswordStrengthText = () => {
    if (password.length > 2) return ""; // No strength text if password is less than 8 characters
    switch (strength) {
      case 0:
        return "Very Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="commonLogin createAccountCommonLogin">
        <div className="loginForm createAccountForm">
          <div className="authFormHead">
            <h1 className="fontSize36 txtDarkblue">
              Let’s create your account.
            </h1>
            <h2 className="fontSize24 fw500 pt-sm-3 pt-2 txtDarkblue">
              Signing up for JOBR is fast and easy.
            </h2>
          </div>
          {/* form section */}
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group text-start">
                  <div className="position-relative">
                    <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                      First name
                    </label>
                    <input
                      type="text"
                      className="customform-control"
                      name="firstname"
                      autoComplete="new-password"
                      placeholder="First name"
                      value={firstname}
                      onChange={handleOnChange}
                    />
                    <img
                      src={Images.authUserLogo}
                      alt="img"
                      className="createAccInputLogo"
                    />
                  </div>
                  {errors.firstname && (
                    <div className="errorTxt ps-4 pt-2">{errors.firstname}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group text-start">
                  <div className="position-relative">
                    <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                      Last name
                    </label>
                    <input
                      type="text"
                      className="customform-control"
                      name="lastname"
                      autoComplete="new-password"
                      placeholder="Last name"
                      value={lastname}
                      onChange={handleOnChange}
                    />
                    <img
                      src={Images.authUserLogo}
                      alt="img"
                      className="createAccInputLogo"
                    />
                  </div>
                  {errors.lastname && (
                    <div className="errorTxt ps-4 pt-2">{errors.lastname}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group text-start">
                <div className="position-relative">
                  <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="customform-control"
                    name="email"
                    autoComplete="new-password"
                    placeholder="Email"
                    value={email}
                    autoCorrect="off"
                    autocomplete="off"
                    onChange={handleOnChange}
                    disabled={email ? true : false}
                  />
                  <img
                    src={Images.authEmailLogo}
                    alt="img"
                    className="createAccInputLogo"
                  />
                </div>
                {email ? (
                  <img
                    className="newCheckPosition"
                    src={Images.newCheck}
                    alt=""
                  />
                ) : (
                  <></>
                )}
                {errors.email && (
                  <div className="errorTxt ps-4 pt-2">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group text-start">
                <div className="position-relative">
                  <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                    Phone Number
                  </label>
                  <input
                    type="email"
                    className="customform-control"
                    name="email"
                    autoComplete="new-password"
                    placeholder="Email"
                    value={phoneData.phone_code + "  " + phoneData.phone_no}
                    autoCorrect="off"
                    autocomplete="off"
                    onChange={handleOnChange}
                    disabled={phoneData.phone_no ? true : false}
                  />
                  <img
                    src={Images.phoneLogoBlue}
                    alt="img"
                    className="createAccInputLogo mt-1"
                    width={19}
                  />
                </div>
                {phoneData.phone_code && phoneData.phone_no ? (
                  <img
                    className="newCheckPosition"
                    src={Images.newCheck}
                    alt=""
                  />
                ) : (
                  <></>
                )}
                {errors.email && (
                  <div className="errorTxt ps-4 pt-2">{errors.email}</div>
                )}
              </div>
            </div>
            {/* <div className="col-12">
              <div className="form-group text-start">
                <div className="position-relative">
                  <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                    Confirm Email Address
                  </label>
                  <input
                    type="email"
                    className="customform-control"
                    name="confirmEmail"
                    autoComplete="new-password"
                    placeholder="Confirm Email address"
                    value={confirmEmail}
                    onChange={handleOnChange}
                    disabled={true}
                  />
                  <img
                    src={Images.authEmailLogo}
                    alt="img"
                    className="createAccInputLogo"
                  />
                </div>
                {email || phoneData?.user_email ? (
                  <img
                    className="newCheckPosition"
                    src={Images.newCheck}
                    alt=""
                  />
                ) : (
                  <></>
                )}
                {errors.confirmEmail && (
                  <div className="errorTxt ps-4 pt-2">
                    {errors.confirmEmail}
                  </div>
                )}
              </div>
            </div> */}
            {/* <div className="col-12">
              <div className="form-group text-start">
                <div className="position-relative">
                  <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                    Password
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="customform-control"
                    name="password"
                    autoComplete="new-password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={handleOnChange}
                    // maxLength={8}
                    min={8}
                  />
                  <img
                    src={Images.authLockLogo}
                    alt="img"
                    className="createAccInputLogo"
                  />
                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      setPasswordShown((prev) => !prev);
                    }}
                  >
                    <span
                      className={
                        passwordShown
                          ? "show-icon togglePassword"
                          : "hide-icon togglePassword"
                      }
                    ></span>
                  </span>
                </div>
                {errors.password && (
                  <div className="errorTxt ps-4 pt-2">{errors.password}</div>
                )}
              </div>
              {/* <div style={{
                padding: "5px",
                margin: "5px"
              }}>
                {!password && <p className='ms-2' style={{
                  fontSize: "11px"
                }}>Your password must be at least 8 characters, and can’t begin or end with a space.</p>}
                {password && (
                  <>
                    <p>Password strength: {getPasswordStrengthText()}</p>
                    <PasswordStrengthBar
                      className="react-password-strength"
                      // minLength={8}
                      password={password}
                      onChangeScore={handlePasswordStrengthChange} // Track strength
                    />
                  </>
                )}
              </div> */}
            {/* </div>  */}
            <div className="col-12">
              <div className="productAvailabilityChild flexBox">
                <div className="form-group d-flex pb-0">
                  <input
                    type="checkbox"
                    id="register"
                    name="consent"
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                  />
                  <label htmlFor="register"></label>
                </div>
                <h3 className="fontSize18 txtDarkblue mb-0">
                  I agree to JOBR's
                  <span className="fw600"> Terms, Privacy Policy, </span>and 
                  <span className="fw600">E-Sign Consent.</span>
                </h3>
              </div>
              {errors.consent && (
                <div className="errorTxt pt-2 text-start">{errors.consent}</div>
              )}
            </div>
            <div className="col-md-12 ">
              <div className="modalfooterBtn">
                <button className="loginBtnSign w-100" type="submit">
                  Continue
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="copyRight">
          <p>All Rights Reserved by JOBR LLC | 2022</p>
        </div>
      </div>
    </>
  );
};

export default CreateAccountSetUp;
