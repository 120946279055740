import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ImageUploader from "./imageUploader";
import { useTranslation } from "react-i18next";

const BulkVarientionModal = ({ onSave, title, close, field , costPrice }) => {
  const { t } = useTranslation();
  const toastId = useRef(null);
  const [fileList, setFileList] = useState([]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const [values, setValues] = useState({
    posPrice: "",
    b2cPrice: "",
    quantity: "",
    weight: "",
    quantity_reorder_point: "",
  });

  const handleChange = (key, value) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate inputs based on the field type
    // if (field === "pos_price" && (!values.posPrice || !values.b2cPrice)) {
    //   showToast("Please enter valid values for POS and B2C prices.");
    //   return;
    // }

    if (field === "pos_price") {
      if (!values.posPrice || !values.b2cPrice) {
        showToast("Please enter valid values for POS and B2C prices.");
        return;
      }
      if (parseFloat(values.posPrice) < parseFloat(costPrice)) {
        showToast("POS Price cannot be less than Cost Price.");
        return;
      }
      if (parseFloat(values.b2cPrice) < parseFloat(costPrice)) {
        showToast("B2C Price cannot be less than Cost Price.");
        return;
      }
    }

    if (
      field === "quantity" &&
      (!values.quantity || !values.quantity_reorder_point)
    ) {
      showToast(
        "Please enter valid values for Quantity and Quantity Re-order Point prices."
      );
      return;
    }
    if ((field === "quantity" || field === "weight") && !values[field]) {
      showToast(`Please enter a valid value for ${field}.`);
      return;
    }

    if (
      field === "quantity" &&
      parseFloat(values.quantity_reorder_point) > parseFloat(values.quantity)
    ) {
      showToast("Quantity Re-order Point should be less than Quantity.");
      return;
    }
    if (field === "image" && fileList?.length === 0) {
      showToast("please upload an image");
      return;
    }

    if (onSave) {
      onSave(values); // Pass all values
    }
    close();
  };

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      image: fileList[0] || "", 
      images: fileList,         
    }));
  }, [fileList]);
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="form_group">
        {/* <label className="fontSize16 txtDarkblue mb-2 ps-4">{title}</label> */}
        {field === "image" ? (
          <>
            <ImageUploader fileList={fileList} setFileList={setFileList} />
          </>
        ) : field === "pos_price" ? (
          <>
            <div className="inputGroup">
              <input
              onKeyDown={blockInvalidChar}
                min="1"
                step="0.01"
                className="customInput costPriceInput"
                placeholder="POS Price"
                type="number"
                value={values.posPrice}
                onChange={(e) => handleChange("posPrice", e.target.value)}
              />
            </div>
            <div className="inputGroup mt-3">
              <input
              onKeyDown={blockInvalidChar}
                min="1"
                step="0.01"
                className="customInput costPriceInput"
                placeholder="B2C Price"
                type="number"
                value={values.b2cPrice}
                onChange={(e) => handleChange("b2cPrice", e.target.value)}
              />
            </div>
          </>
        ) : field === "quantity" ? (
          <>
            <div className="inputGroup">
              <input
                min="1"
                step="0.01"
                className="customInput costPriceInput"
                placeholder="Quantity"
                type="number"
                value={values.quantity}
                onChange={(e) => handleChange("quantity", e.target.value)}
              />
            </div>
            <div className="inputGroup mt-3">
              <input
                min="1"
                step="0.01"
                className="customInput costPriceInput"
                placeholder="Quantity Re-order Point"
                type="number"
                value={values.quantity_reorder_point}
                onChange={(e) =>
                  handleChange("quantity_reorder_point", e.target.value)
                }
              />
            </div>
          </>
        ) : (
          <div className="inputGroup">
            <input
              min="1"
              step="0.01"
              className="customInput"
              placeholder={title}
              type="number"
              value={values[field]}
              onChange={(e) => handleChange(field, e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <button className="modalNextBtn modalDiscardBtn active" type="submit">
          {t('save')}
        </button>
      </div>
    </form>
  );
};

export default BulkVarientionModal;
