import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import ReactSelect from "react-select";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getOrderProcessingdataApiAsync, getUserSettingsApiAsync, updateOrderProcessingdataApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import Loader from '../../../components/UI/Loader/Loader';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const OrderProcessing = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [debounceTimeout, setDebounceTimeout] = useState(null)
    const auth = useSelector(selectLoginAuth);
    const resultSelectOption2 = [
        { value: 0, label: "0" },
        { value: 5, label: "5" },
        { value: 10, label: "10" },
        { value: 15, label: "15" },
        { value: 20, label: "20" },
        { value: 25, label: "25" },
        { value: 30, label: "30" },
        { value: 35, label: "35" },
    ];
    const resultSelectOption = [
        { value: "0", label: "Manually" },
        { value: "1", label: "Automatically" },
    ];
    const [loading, setLoading] = useState(false);
    const [orderProcessingData, setOrderProcessingData] = useState(null);
    const getOrderProcessingData = async () => {
        let prams = {
            postData: {
                app_name: "merchant",
                seller_id: auth?.payload?.uniqe_id
                    ? auth?.payload?.uniqe_id
                    : auth?.payload?.user?.unique_uuid,
            },
            tokenData: auth?.payload?.token,
        }
        setLoading(true);
        dispatch(getUserSettingsApiAsync(prams))
            .then(unwrapResult)
            .then((res) => {
                setOrderProcessingData(res?.payload);
                setLoading(false);
            })
            .catch((res) => {
                setLoading(false);
            })
    }
    useEffect(() => {
        getOrderProcessingData();
    }, [])
    const updateOrderProcessingData = async (data) => {
        let prams = {
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
        setLoading(true);
        dispatch(updateUserSettingsApiAsync(prams))
            .then(unwrapResult)
            .then((res) => {
                setLoading(false);
                // setOrderProcessingData(res.data);
            })
            .catch((res) => {
                setLoading(false);
            })
    }
    const handleOrderProcessing = (key, value) => {
        setOrderProcessingData(prev => ({
            ...prev, [key]: value
        }));
        let obj={ [key]: value };
        if(key=="large_order_setting"){
            obj["large_order_quantity_limit"]=orderProcessingData?.large_order_quantity_limit||1;
        }
        updateOrderProcessingData(obj);
    }

    return (
        <>
            {/* Start Order Processing */}

            {loading &&
                <Loader />
            }
            <div className='orderProcessing_ '>
                <div className='processignOrder'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("orderSettings")}</h3>
                        <p className='textInnerHead2'>{t("orderSettingtitle")}</p>
                    </div>
                    <div className='datalimitstatus_ mt-4'>
                        <div className="row">
                            {[{ 
                                title: "Default online order status",
                                languageTitle:"defaultOnlineOrder",
                            placeholder:"Select online order status",
                            options: resultSelectOption, key: "default_online_order_status" },
                            {
                                title: "Daily online order limit",
                                languageTitle:"dailyOrderLimit",
                                options: resultSelectOption2, key: "daily_online_order_limit",
                                placeholder:"Select online order limit"
                            },
                            ].map((data, index) => (
                                <div key={index} className="col-md-6">
                                    <div className="reactSelectParent commonSelectParent w-100">
                                        <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                            {t(data.languageTitle)}
                                            <img src={Images.labelImg} alt="logo" className="ms-2" />
                                        </label>
                                        <ReactSelect
                                            options={data.options}
                                            value={data.options?.filter(res => res.value == orderProcessingData?.[data?.key])}
                                            onChange={(event) => {
                                                handleOrderProcessing(data.key, event?.value);
                                            }}
                                            placeholder={data.placeholder}
                                            classNamePrefix="react-select"
                                            className="react-select-container anlyticSelect"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    maxWidth: "100% !important",
                                                    width: "100%",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    border: "1px solid #D7DEFF",
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    paddingLeft: "unset !important",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                        ? "#263682"
                                                        : state.isFocused
                                                            ? "#f5f6fc"
                                                            : "#fff",

                                                    color: state.isSelected ? "#fff" : "#263682",
                                                    fontSize: "18px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "#636E9F",
                                                    fontSize: "18px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator: () => (
                                                    <img
                                                        src={Images.selectImg}
                                                        width={24}
                                                        height={24}
                                                        alt="drop_icon"
                                                        className="mt-1"
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                        <div className="row mt-4">
                            {
                                [
                                    { title: "Order Cancellation", subTitle: "Allow customer cancellations", key: "order_canceled_status",
                                        languageTitle:"orderCancellation",languageSubtitle:"orderCancellationTitle"
                                     },
                                    { title: "Order Returns", subTitle: "If returns are enabled, set a time limit for customers to initiate a return.", key: "is_order_return_enable" ,
                                        languageTitle:"orderReturns",languageSubtitle:"orderReturnTitle"
                                    },
                                    // {title:"On-POS arrival alerts", subTitle:"Notify POS staff product pickup arrival",key:"push_notification_status"},
                                    {
                                        title: "Enable 24/7 Online Order", subTitle: "Clients can place orders 24/7", key: "enable_24_7_online_orders",
                                        languageTitle:"enable24/7",languageSubtitle:"enableTitle"
                                    },

                                ].map((data, index) => (
                                    <div key={index} className="col-12">
                                        <div className={`serviceSettingBoxes d-flex flex-column align-items-start  gap-3 ${index >= 1 ? 'mt-3' : ''}`}>
                                        <div className=" d-flex align-items-start  gap-3">
                                        <input type="checkbox" checked={orderProcessingData?.[data?.key]}
                                                onChange={(event) => {
                                                    handleOrderProcessing(data.key, event.target.checked);
                                                }}
                                            />
                                            <div>
                                                <h3 className="fontSize14 txtDarkblue">
                                                    {t(data.languageTitle)}
                                                </h3>
                                                <h3 className="fontSize12 txtLightBlue mb-0">
                                                    {t(data.languageSubtitle)}
                                                </h3>
                                            </div>
                                        </div>
                                        {
                                            (data.title=="Order Returns"&&orderProcessingData?.is_order_return_enable)&&<div className='amountvalue_ mt-3'>
                                            <input type="text"
                                                value={orderProcessingData?.order_return_time_limit}
                                                 onChange={(event)=>{
                                                    if (debounceTimeout) {
                                                        clearTimeout(debounceTimeout);
                                                      }
                                                      setOrderProcessingData(prev=>({
                                                         ...prev,
                                                         order_return_time_limit: event.target.value,

                                                      }))

                                                      // Set a new timeout to call handleUserSetting after the user stops typing for 500ms (debounce delay)
                                                      const timeoutId = setTimeout(() => {
                                                          setDebounceTimeout(null);
                                                        if(event.target.value<=0){
                                                            toast.error("order return limit should be greater than 0");
                                                            return;
                                                        }
                                                        handleOrderProcessing("order_return_time_limit", event.target.value);
                                                      }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)
                                                      setDebounceTimeout(timeoutId);

                                                 }}
                                                className='customform-control' placeholder='100' />
                                            <span className='mindollr' style={{
                                                left:"unset",
                                                right:"18px",
                                            }}>{orderProcessingData?.order_return_time_limit==1 ?"Day" :"Days"}</span>
                                        </div>
                                        }
                                           
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='processignOrder'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>{t("advance")}</h3>
                        <p className='textInnerHead2'>{t("advanceSettingTitle")}</p>
                    </div>

                    <div className="row mt-4">
                        {
                            [{
                                title: "Large order settings", subTitle: "Set a quantity limit to prevent unexpected large pickup or delivery orders. Include a custom message explaining how to place orders that are over the quantity limit.", key: "large_order_setting",
                                languageTitle:"",languageSubtitle:"largeOrderTitle"


                            },
                            ].map((data, index) => (
                                <div key={index} className="col-12">
                                    <div className={`serviceSettingBoxes d-flex flex-column align-items-start  gap-3 ${index >= 1 ? 'mt-3' : ''}`}>
                                        <div className=" d-flex align-items-start  gap-3">
                                            <input checked={orderProcessingData?.[data?.key]}
                                                onChange={(event) => {
                                                    handleOrderProcessing(data.key, event.target.checked);
                                                }}
                                                type="checkbox" />
                                            <div>
                                                <h3 className="fontSize14 txtDarkblue">
                                                    {t(data.languageTitle)}
                                                </h3>
                                                <h3 className="fontSize12 txtLightBlue mb-0">
                                                    {t(data.languageSubtitle)}
                                                </h3>
                                            </div>
                                        </div>
                                        {orderProcessingData?.large_order_setting&&<div className='amountvalue_ mt-3'>
                                            <input type="text"
                                                 value={orderProcessingData?.large_order_quantity_limit}
                                                 disabled={!(orderProcessingData?.large_order_setting)}
                                                 onChange={(event)=>{
                                                    if (debounceTimeout) {
                                                        clearTimeout(debounceTimeout);
                                                      }
                                                      setOrderProcessingData(prev=>({
                                                         ...prev,
                                                         large_order_quantity_limit: event.target.value,

                                                      }))

                                                      // Set a new timeout to call handleUserSetting after the user stops typing for 500ms (debounce delay)
                                                      const timeoutId = setTimeout(() => {
                                                          setDebounceTimeout(null);
                                                        if(event.target.value<=0){
                                                            toast.error("large order quantity limit should be greater than 0");
                                                            return;
                                                        }
                                                        handleOrderProcessing("large_order_quantity_limit", event.target.value);
                                                      }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)
                                                      setDebounceTimeout(timeoutId);

                                                 }}
                                                className='customform-control' placeholder='100' />
                                            {/* <span className='mindollr'>$</span> */}
                                        </div>}
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

            {/* End Order Processing */}
        </>

    )
}


export default OrderProcessing