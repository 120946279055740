import React, { useEffect, useState } from 'react'
import {
    totalordericon,
    totalOrderFrequency,
    averageOrderValue,
    totalvolumeicon
} from '../../../utilities/images';
import { useHistory } from 'react-router-dom';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { getAnalyticOrdersApiAsync } from '../dashboard/dashboardSlice';
import { addThousandSeparator } from '../../../utilities/helpers';
import AnalyticsHeader from './AnalyticsHeader';
import PaginationHeader from './PaginationHeader';
import { useTranslation } from 'react-i18next';

const TotalShippingOrders = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [analyticsOrderData, setAnalyticsOrderData] = useState("")
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [calanderDate, setCalanderDate] = useState(new Date())

    const handelPerPageResult = (e) => {
        setPerPage(e?.value)
    }

    const orderAnalyticsHandle = () => {
        let params = {
            "postData": {
                page: currentPage,
                limit: perPage,
                filter: filterValue,
                channel: channelFilter,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAnalyticOrdersApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAnalyticsOrderData(obj?.payload)
                setLoading(false)
                console.log("obj", obj)
                setTotalRows(obj?.payload?.pos_graph?.ordersListData?.length || 0)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }


    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        orderAnalyticsHandle()
    }, [filterValue, channelFilter, endDate, perPage, currentPage])
    return (
        <>
            <DashboardNavbar title={t("analytics")} backShow="/analytics" />
            <div className='analyticShipping analyticGross chartsOuter'>
                <AnalyticsHeader
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onTimeSpanSelect={setFilterValue}
                    calanderDate={calanderDate}
                    setCalanderDate={setCalanderDate}
                    filterValue={filterValue}
                    setChannelFilter={setChannelFilter}
                    channelFilter={channelFilter}
                    subhead="vs. Same day last week"
                    onDateChange={handleDateChange}
                    timeSpan={filterValue}
                    header={t('totalShippingOrders')}
                />
                <div className='grossProfitData'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6  pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalordericon} className='img-fluid grossImages' alt='totalorderImage' />
                                <p className='groSub mt-4 mb-0'>{t('totalOrders')}</p>
                                <h4 className='groMain'>{addThousandSeparator(analyticsOrderData?.shipping_graph?.ordersOverView?.total_orders) || 0}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalOrderFrequency} className='img-fluid grossImages' alt='orderFrequencyImage' />
                                <p className='groSub mt-4 mb-0'>{t('orderFrequency')}</p>
                                <h4 className='groMain'>{addThousandSeparator(analyticsOrderData?.shipping_graph?.ordersOverView?.order_frequency) || 0}/Hour</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={averageOrderValue} className='img-fluid grossImages' alt='analysisimage' />
                                <p className='groSub mt-4 mb-0'>{t('averageOrderValue')}</p>
                                <h4 className='groMain'>{addThousandSeparator((analyticsOrderData?.delivery_graph?.ordersOverView?.averageValue || analyticsOrderData?.delivery_graph?.ordersOverView?.averageValue == 0) ? `$${(analyticsOrderData?.shipping_graph?.ordersOverView?.averageValue).toFixed(2)}` : "")}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 '>
                            <div className='grossTotalorder'>
                                <img src={totalvolumeicon} className='img-fluid grossImages' alt='totalSaleImage' />
                                <p className='groSub mt-4 mb-0'>{t('totalRevenue')}</p>
                                <h4 className='groMain'>${addThousandSeparator(analyticsOrderData?.shipping_graph?.ordersOverView?.total_sales_or_actual_amount?.toFixed(2))}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <PaginationHeader
                    perPage={perPage}
                    handelPerPageResult={handelPerPageResult}
                    currentPage={currentPage}
                    totalPage={totalRows}
                    setCurrentPage={setCurrentPage}
                    totalLength={analyticsOrderData?.shipping_graph?.ordersListData?.length}
                />
                {
                    loading ? (
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                    ) : (
                        <div className="commonTable productTable table-responsive mt-3">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('date')}</th>
                                        <th>{t('totalShippingOrder')}</th>
                                        <th>{t('averageOrderValue')}</th>
                                        <th>{t('orderFrequency')}</th>
                                        <th>{t('totalRevenue')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {analyticsOrderData?.shipping_graph?.ordersListData?.length > 0 && (
                                        analyticsOrderData?.shipping_graph?.ordersListData.map(
                                            (row, idx) => (
                                                <tr>
                                                    <td className="fontSize12 txtLightBlue">
                                                    {currentPage > 1 ? (
                                                        (currentPage - 1) * perPage + idx + 1
                                                        ) : idx + 1}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {moment(row?.order_date).format('MM/DD/YYYY')}
                                                    </td>

                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.count}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.averageValue).toFixed(2))}
                                                    </td>

                                                    <td className="fontSize12 grosshead_">
                                                        {row?.order_frequency} Per Hour
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        <b>${addThousandSeparator(row?.amount?.toFixed(2))}</b>
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        <button onClick={
                                                            () =>
                                                                history.push({
                                                                    pathname: `/transections`,
                                                                    state: {
                                                                        "date": moment(`${row?.order_date}T00:00:00`)?.toDate(),
                                                                        "from": "analytics",
                                                                        "deliveryOption": 3
                                                                    }
                                                                })}
                                                            className="secondaryOuterbtn_"
                                                            type="button">
                                                            {t('review')}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default TotalShippingOrders
