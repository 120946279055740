export const campaignTypeOptions=[
    {value:"store",label:"Store Promotion on App",img:"storepromotionIcon_",languageLabel:"storePromotion"},
    {value:"product",label:"Product Promotion",img:"productpromo",languageLabel:"productPromotion"},
    {value:"notification",label:"Notification Promotion",img:"notifcationPromo",languageLabel:"notificationPromotion"},
    {value:"sms",label:"SMS Campaign",img:"chatcampaign",languageLabel:"smsCampaign"},
]
export const contentTypeOptions=[
    {value:"product",label:"Product",languageLabel:"product"},
    {value:"service",label:"Service",languageLabel:"service"},
]
export const CategoryOptions=[
    {
        value:"service_id",label:"Master Category",
        langaugeLabel:"masterCategory"
    },
    {
        value:"category_id",label:"Category",
        langaugeLabel:"category"
    },
    {
        value:"sub_category_id",label:"Subcategory",
        langaugeLabel:"subCategory"
    },
    {
        value:"brand_id",label:"Brand",
        langaugeLabel:"brands"
    },
]
export const promotionTypeOptions = [
    { value: "top_banner", label: "Top Banner",languageLabel:"topBanner" },
    { value: "feature", label: "Features",languageLabel:"features" },
    { value: "under_category", label: "Under Category",languageLabel:"underCategory" },
]