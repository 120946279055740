import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSystemConfigApi,
  updateUserSetting,
  getTaxApi,
  getCountriesApi,
  getStateApi,
  createTaxApi,
  getQrCodeApi,
  getAllUserApi,
  userConfigLocationApi,
  configureQrCodeApi,
  getTermsConditionsPoliciesApi,
  createPolicyApi,
  updatePolicyApi,
  deletePolicyApi,
  activeInactivePolicyApi,
  getUserSettingsApi,
  getLanguagesApi,
  getLanguagesListApi,
  updateUserStatus,
  getAllUsersProfilesApi,
  archivePolicy,
} from "./systemConfigApi";

const initialState = {
  status: "idle",
  // walletProfile: null,
};

export const systemConfigAsync = createAsyncThunk(
  "systemConfig/getSystemConfigApi",
  async (data) => {
    const response = await getSystemConfigApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const UserStatusAsync = createAsyncThunk(
  "systemConfig/updateUserStatus",
  async (data) => {
    const response = await updateUserStatus(data);
    return response.data;
  }
);

export const updateUserSettingAsync = createAsyncThunk(
  "systemConfig/updateUserSetting",
  async (data) => {
    const response = await updateUserSetting(
      data.postData,
      data.tokenData,
      data?.queryData
    );
    return response.data;
  }
);

export const getTaxAsync = createAsyncThunk(
  "systemConfig/getTaxApi",
  async (data) => {
    const response = await getTaxApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const getCountriesAsync = createAsyncThunk(
  "systemConfig/getCountriesApi",
  async (data) => {
    const response = await getCountriesApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const getStateAsync = createAsyncThunk(
  "systemConfig/getStateApi",
  async (data) => {
    const response = await getStateApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const createTaxAsync = createAsyncThunk(
  "systemConfig/createTaxApi",
  async (data) => {
    const response = await createTaxApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const getQrCodeAsync = createAsyncThunk(
  "systemConfig/getQrCodeApi",
  async (data) => {
    const response = await getQrCodeApi(data.tokenData);
    return response.data;
  }
);

export const configureQrCodeApiAsync = createAsyncThunk(
  "systemConfig/configureQrCodeApi",
  async (data) => {
    const response = await configureQrCodeApi(data.postData, data.tokenData);
    return response.data;
  }
);

export const getAllUserAsync = createAsyncThunk(
  "systemConfig/getAllUserApi",
  async (data) => {
    const response = await getAllUserApi(data.tokenData);
    return response.data;
  }
);

export const getAllUserProfileAsync = createAsyncThunk(
  "systemConfig/getAllUsersProfilesApi",
  async (data) => {
    const response = await getAllUsersProfilesApi(data.postData, data.tokenData);
    return response.data;
  }
);


export const getAllStuffsAsync = createAsyncThunk(
  "systemConfig/getAllStuffData",
  async (data) => {
    const response = await getAllUserApi(data.postData,data.tokenData);
    return response.data;
  }
);


export const userConfigLocationAsync = createAsyncThunk(
  "systemConfig/userConfigLocationApi",
  async (data) => {
    const response = await userConfigLocationApi(data.tokenData);
    return response.data;
  }
);
export const getTermsConditionsPoliciesApiAsync = createAsyncThunk(
  "systemConfig/getTermsConditionsPoliciesApiApi",
  async (data) => {
    const response = await getTermsConditionsPoliciesApi(data);
    return response.data;
  }
);
export const archivePolicyApiAsync = createAsyncThunk(
  "systemConfig/getTermsConditionsPoliciesApiApi",
  async (data) => {
    const response = await archivePolicy(data.postData,data.tokenData);
    return response.data;
  }
);

export const createPolicyApiAsync = createAsyncThunk(
  "systemConfig/createPolicyApiApi",
  async (data) => {
    const response = await createPolicyApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const updatePolicyApiAsync = createAsyncThunk(
  "systemConfig/updatePolicyApiApi",
  async (data) => {
    const response = await updatePolicyApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const deletePolicyApiAsync = createAsyncThunk(
  "systemConfig/deletePolicyApiApi",
  async (data) => {
    const response = await deletePolicyApi(data.id, data.token);
    return response.data;
  }
);
export const activeInactivePolicyApiAsync = createAsyncThunk(
  "systemConfig/activeInactivePolicyApiApi",
  async (data) => {
    const response = await activeInactivePolicyApi(
      data.postData,
      data.tokenData
    );
    return response.data;
  }
);
export const getUserSettingsApiAsync = createAsyncThunk(
  "systemConfig/getUserSettingsApi",
  async (data) => {
    const response = await getUserSettingsApi(data.postData, data.tokenData);
    return response.data;
  }
);
export const getLanguagesApiAsync = createAsyncThunk(
  "systemConfig/getLanguagesApi",
  async (data) => {
    const response = await getLanguagesApi(data);
    return response.data;
  }
);
export const getLanguagesListApiAsync = createAsyncThunk(
  "systemConfig/getLanguagesListApi",
  async (data) => {
    const response = await getLanguagesListApi(data.postData, data.tokenData);
    return response.data;
  }
);
