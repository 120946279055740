import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordAsync, verifyPasswordAsync } from "./authSlice";
import {
  resetrightimg,
  modalCancel,
  modalCross,
} from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import SuccessfulCreated from "../auth/successfulModal/successfulCreated";
import * as Images from "../../../utilities/images";

const ResetPassword = () => {
  const history = useHistory();
  const { id } = useParams();
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyLoader, setVerifyLoader] = useState(false);
  const [password, setPassword] = useState("");
  const toastId = React.useRef(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificaionCode, setVerificationCode] = useState("");
  const dispatch = useDispatch();
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter new password");
      }
      return false;
    }
    if (
      !(
        password.length >= 8 &&
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)
      )
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password should be strong");
      }
      return false;
    }
    if (password !== confirmPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Password and confirm password should be same"
        );
      }
      return false;
    }
    let data = {
      verification_id: id,
      new_password: password,
      confirm_password: confirmPassword,
    };
    setLoading(true);
    await dispatch(resetPasswordAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.success(obj?.msg)
        // }
        // history.push('/')
        setModalDetail({
          show: true,
          flag: "successcreate",
        });
        setKey(Math.random());
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  return (
    <>
      <div className="commonLogin resetPassForm commonResetForm">
        <div className="loginForm" style={{ maxWidth: "500px" }}>
          <div className="loginhead">
            <h4 className="fontSize36 txtDarkblue">Create a New Password</h4>
            <p className="fontSize18 txtLightBlue mb-0 text-center pt-2">
              Your new password must be different from your previous password.
            </p>
          </div>
          <form
            autoComplete="nope"
            className="form_horizontal w-100"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className=" form-group">
              <label className="form-label ps-4">New Password</label>
              <input
                className="customform-control id_password"
                placeholder="Password"
                name={generateRandomName()}
                autoComplete="new-password"
                type={passwordShown1 ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                // maxLength={4}
              />
              <img
                src={Images.passIcon}
                alt="passIcon"
                className="passIcon resetFormPassIcon"
              />
              <span
                className="toggle_password_ info_icon"
                onClick={() => {
                  setPasswordShown1((prev) => !prev);
                }}
              >
                <span
                  className={
                    passwordShown1
                      ? "show-icon togglePassword"
                      : "hide-icon togglePassword"
                  }
                ></span>
              </span>
              {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
            </div>
            <p className="fontSize14 txtLightBlue text-left ps-4 pt-2 mb-4">
              Your password must be at least 8 characters, and can’t begin or
              end with a space.
            </p>
            <div className="mb-1 form-group">
              <label className="form-label ps-4">Confirm New Password </label>
              <input
                className="customform-control id_password"
                placeholder="Password"
                name={generateRandomName()}
                autoComplete="new-password"
                type={passwordShown2 ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                // maxLength={4}
              />
              <img
                src={Images.passIcon}
                alt="passIcon"
                className="passIcon resetFormPassIcon"
              />
              <span
                className="toggle_password_ info_icon"
                onClick={() => {
                  setPasswordShown2((prev) => !prev);
                }}
              >
                <span
                  className={
                    passwordShown2
                      ? "show-icon togglePassword"
                      : "hide-icon togglePassword"
                  }
                ></span>
              </span>
              {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
            </div>
            {/* <div className="mb-1 form-group">
                    <label className="form-label">Verification Code </label>
                    <input
                      className="form-control id_password"
                      placeholder="verification code"
                      name={generateRandomName()}
                      autoComplete="new-password"
                      type="password"
                      value={verificaionCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      maxLength={5}
                    />
                    <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span>
                  </div> */}
            <div className="d-grid gap-2 col-12 mx-auto">
              <button
                className="loginBtnSign mt-4"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Create Password{" "}
                <img src={Images.upArrow} alt="logo" className="ms-2" />
              </button>
            </div>
          </form>
        </div>
        <div className="copyRight">
          <p>All Rights Reserved by JOBR LLC | 2022</p>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "successcreate"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "successcreate" ? "successfullyCreatedModal" : ""
        }
        child={
          modalDetail.flag === "successcreate" ? (
            <SuccessfulCreated close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "successcreate" ? (
            <>
              <p
                onClick={() => history.push("/")}
                className="modal_cancel ms-auto"
              >
                <img
                  src={modalCross}
                  className="img-fluid"
                  alt="modalcancelImage"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ResetPassword;
