import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { useEffect } from "react";
import {
  getSinglePosUserAsync,
  updateCustomerApiAsync,
  updateInvitedCustomerApiAsync,
} from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import { toast } from "react-toastify";
import * as Images from "../../../../utilities/images";
import { useTranslation } from "react-i18next";

const EditConsumerModal = (props) => {
  const { t } = useTranslation();
  const toastId = useRef(null);
  const { userInvitationData } = { ...props };
  const { userData } = { ...props };
  const [hide, setHide] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [data, setData] = useState([])
  const [Loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstname: userData?.firstname,
    lastname: userData?.lastname,
    phone_number: userData?.phone_number,
    phone_code: userData?.phone_code,
    email: userData?.email || userData?.email,
  });
  const [currentAddress, setCurrentAddress] = useState({
    address_type: userData?.current_address?.address_type,
    city: userData?.current_address?.city,
    country: userData?.current_address?.country,
    country_code: userData?.current_address?.country_code,
    latitude: userData?.current_address?.latitude,
    longitude: userData?.current_address?.longitude,
    state: userData?.current_address?.state,
    state_code: userData?.current_address?.state,
    street_address: userData?.current_address?.street_address,
    zipcode: userData?.current_address?.zipcode || userData?.zipcode,
    apt: userData?.current_address?.apt,
  });
  
   // function for change the number and save number is state...............................
   const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setFormData((prev) => ({...prev, phone_code: phoneCode, phone_number: phoneNumber}))
  };

  const autoCompleteHandleChange = (address) => {
    // setAddress(address);
    setCurrentAddress((prev) => ({...prev, street_address: address}));
    geocodeByAddress(address)
      .then((results) => {
        setCurrentAddress((prev) => ({
          ...prev,
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng()
        }))
      })
      .catch((error) => { });
  };
  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        addressComponentMap(
          results[0]?.address_components,
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng(),
          results[0]?.formatted_address
        )
        setDisplayValue(results[0]?.formatted_address?.split(",")[0]);
        setHide(true);
      })
      .catch((error) => { });
  };

  function addressComponentMap(addressDetails, lat, long, street_address) {
    let updatedAddress = { ...currentAddress };
    if (lat && long) {
      updatedAddress.latitude = lat;
      updatedAddress.longitude = lat;
    }
    if (!addressDetails || addressDetails.length === 0) return;
    if (street_address) {
      updatedAddress.street_address = street_address;
    }
    for (var i = 0; i < addressDetails.length; i++) {
      if (addressDetails[i].types[0] === "country") {
        updatedAddress.country = addressDetails[i].long_name;
        updatedAddress.country_code = addressDetails[i].short_name;
      }

      if (addressDetails[i].types[0] === "postal_code") {
        updatedAddress.zipcode = addressDetails[i].long_name;
      }

      if (addressDetails[i].types[0] === "administrative_area_level_1") {
        updatedAddress.state = addressDetails[i].long_name;
        updatedAddress.state_code = addressDetails[i].short_name;
      }

      if (
        addressDetails[i].types[0] === "administrative_area_level_2" ||
        addressDetails[i].types[0] === "administrative_area_level_3" ||
        addressDetails[i].types[0] === "locality"
      ) {
        updatedAddress.city = addressDetails[i].long_name;
      }
    }
    // Update the state once with all the changes
    setCurrentAddress(updatedAddress);
  }

  const getSingleUserApiAsyncHandler = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        id: userData?.id,
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(getSinglePosUserAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setData(obj?.payload);
        setCurrentAddress((prev) => ({...prev, street_address: obj?.payload?.current_location?.formatted_address}));
        setLoading(false);
      })
      .catch((obj) => { });
  };
  const handleSave = () => {
    if (userData && !currentAddress.street_address) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your address");
      }
      return;
    }
    if (userData && !currentAddress?.country) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your country");
      }
      return;
    }
    if (userData && !currentAddress?.state) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your state");
      }
      return;
    }
    if (userData && !currentAddress?.city) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your city");
      }
      return;
    }
    if (userData && !currentAddress?.zipcode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your zipcode");
      }
      return;
    }
    if (userData && !currentAddress?.latitude && !currentAddress?.longitude) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Incorrect Address");
      }
      return;
    }

    let params = {
      postData: {
        id: userData?.id ? userData?.id : userInvitationData?.id,
        address_id: userData?.current_address?.address_id,
        email: formData?.email,
        firstname: formData?.firstname,
        lastname: formData?.lastname,
        // phone_code: phoneCode,
        // phone_no: phoneNumber,
      },
      tokenData: auth?.payload?.token,
    };

    if (
      userData?.user_profiles?.is_phone_verified == false ||
      userInvitationData?.is_phone_verified == false
    ) {
      params = {
        postData: {
          ...params.postData,
          phone_code: formData?.phone_code,
          phone_no: formData?.phone_no,
          firstname: formData?.firstname,
          lastname: formData?.lastname,
          email: formData?.email,
        },
        tokenData: auth?.payload?.token,
      };
    }

    if (currentAddress) {
      params = {
        postData: {
          ...params.postData,
          current_address: {
            ...currentAddress
          },
        },
        tokenData: auth?.payload?.token,
      };
    }

    if (currentAddress?.apt) {
      params = {
        postData: {
          ...params.postData,
          current_address: {
            ...params.postData.current_address,
            apt: currentAddress?.apt,
          },
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (userData) {
      dispatch(updateCustomerApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          // if(userData){
          props.getSingleUserApiAsyncHandler();
          // }
          // else{
          //     props.getSingleInvitationUserApiAsyncHandler()
          // }
          // props?.setUserData((prev)=>({
          //   user_details: {
          //     ...prev.user_details,
          //     current_address:obj?.payload?.current_address,
          //   }
          // }))
          props?.close();
        });
    } else {
      dispatch(updateInvitedCustomerApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          // if(userData){
          //     props.getSingleUserApiAsyncHandler();
          // }
          // else{
          props.getSingleInvitationUserApiAsyncHandler();
          // }
          // props?.setUserData((prev)=>({
          //   user_details: {
          //     ...prev.user_details,
          //     current_address:obj?.payload?.current_address,
          //   }
          // }))
          props?.close();
        });
    }
  };
  useEffect(() => {
    if (userData) {
      getSingleUserApiAsyncHandler();
    }
  }, []);

  return (
    <>
      <div className="editConsumerContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="editConsumerModalLeft">
                <div className="text-center">
                  <img
                    src={data?.user_profiles?.profile_photo || Images.userImg_}
                    alt="img"
                    className="consumerImg"
                  />
                  <h2 className="fontSize24 fw500 txtDarkblue mt-2 mb-0">
                    {
                      [
                        userData?.firstname,
                        userData?.lastname
                      ].filter(Boolean).join(" ")
                    }
                  </h2>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2">
                        {t('firstNameSin')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="Marie"
                          name="firstname"
                          type="text"
                          value={formData?.firstname}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2">
                        {t('lastNameSin')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="Jenkins"
                          name="lastname"
                          type="text"
                          value={formData?.lastname}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="phone-numbpart">
                  <div className="country-plugin">
                    <label className="fontSize14 txtDarkblue fw500 mb-2">
                      {t('phoneNumber')}
                    </label>
                    <div id="result" className="phoneNumberInput countryCodePickerParent">
                      <PhoneInput
                        country="us"
                        value={`${formData?.phone_code}${formData?.phone_number}`}
                        // enableSearch={true}
                        // name={generateRandomName}
                        disabled
                        placeholder="Phone no."
                        autoComplete="off"
                      onChange={(value, data, event, formattedValue) =>
                        onChangePhoneNumber(
                          value,
                          data,
                          event,
                          formattedValue
                        )
                      }
                      />
                    </div>
                  </div>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2">
                    {t('emailAddress')}
                  </label>
                  <div className="inputGroup">
                    <input
                      className="customInput modalInput"
                      placeholder="hello@email.com"
                      name="email"
                      type="text"
                      value={formData?.email}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center pt-3">
                  <button
                    className="modalDiscardBtn w-100"
                    onClick={() => props?.close()}
                  >
                    {t('cancel')}
                  </button>
                  <button 
                   onClick={handleSave}
                   className="modalNextBtn modalDiscardBtn active w-100">
                    {t('save')}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="editConsumerModalRight">
                <label className="fontSize14 txtDarkblue fw500 mb-2">
                  {t('streetAdd')}
                </label>
                <div className="inputGroup">
                  <PlacesAutocomplete
                    className=""
                    autoComplete="off"
                    value={currentAddress?.street_address}
                    onChange={autoCompleteHandleChange}
                    onSelect={autoCompleteHandleSelect}
                    searchOptions={{
                      componentRestrictions: {
                        country: ["us", "ind"],
                      },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Street Address",
                            className:
                              "location-search-input customform-control countrytags_",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                backgroundColor: "#41b6e6",
                                cursor: "pointer",
                              }
                              : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={index}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2">
                    {t('apartmentSuite')}
                  </label>
                  <div className="inputGroup">
                    <input
                      className="customInput modalInput"
                      placeholder="Building, Interior"
                      name="apt"
                      type="text"
                      value={currentAddress?.apt}
                    />
                  </div>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2">
                    {t('country')}
                  </label>
                  <div className="inputGroup">
                    <input
                      className="customInput modalInput"
                      placeholder="United States"
                      name="country"
                      type="text"
                      value={currentAddress?.country}
                    />
                  </div>
                </div>
                <div className="form_group">
                  <label className="fontSize14 txtDarkblue fw500 mb-2">
                    {t('state')}
                  </label>
                  <div className="inputGroup">
                    <input
                      className="customInput modalInput"
                      placeholder="Alabama"
                      name="state"
                      type="text"
                      value={currentAddress?.state}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2">
                        {t('city')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="City"
                          name="city"
                          type="text"
                          value={currentAddress?.city}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue fw500 mb-2">
                        {t('zipCode')}
                      </label>
                      <div className="inputGroup">
                        <input
                          className="customInput modalInput"
                          placeholder="Zip Code"
                          name="zipcode"
                          type="text"
                          value={currentAddress?.zipcode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditConsumerModal;
