import React from "react";
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";
const SelectMenu = ({ ...param }) => {
  return (
    <>
      <ReactSelect
        {...param}
        classNamePrefix="react-select"
        className="react-select-container anlyticSelect"
        styles={{
          control: (provided) => ({
            ...provided,
            maxWidth: "100% !important",
            width: "100%",
          }),
          menu: (provided) => ({
            ...provided,
            border: "1px solid #D7DEFF",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "#263682"
              : state.isFocused
              ? "#f5f6fc"
              : "#fff",

            color: state.isSelected ? "#fff" : "#636E9F",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#636E9F",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }),
        }}
        components={{
          DropdownIndicator: () => (
            <img
              src={Images.blueDownArrow}
              width={24}
              height={24}
              alt="drop_icon"
            />
          ),
        }}
      />
    </>
  );
};

export default SelectMenu;
