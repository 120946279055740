import { TextField } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import * as Images from "../../../../utilities/images";
import ReactSelect from "react-select";
import CustomModal from '../../../components/shared/CustomModal';
import BankdetailModal from '../walletSetting/bankdetailModal';
import WithdrawPreviewmodal from './withdrawpreviewModal';

const JbrTransfer = () => {
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            flag: flag,
            title: flag,
        });
        setKey(Math.random());
    };
    return (
        <>
            <div className='jbrTransfer '>
                <div className='chartsOuter jbrHeight mt-0'>
                    <div className='shipprofileHead_'>
                        <h2 className='Innerhead'>JBR Transfer</h2>
                        <div className='right_shippingbtn_'>
                            <Link to="/orderSettings?page=shipping">
                                <button className='discardBtn_' type='button'>Discard</button></Link>
                            <button className='addNew_' type='button' onClick={() => {
                        setModalDetail({
                            show: true,
                            flag: "WithdrawPreviewmodal",
                        });
                        setKey(Math.random());
                    }}>Withdrawal</button>
                        </div>
                    </div>
                    <div className='jbrtransferContact_ mt-5'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='jbrtransferLeft_'>
                                    <div className="col-md-12 ">
                                        <div className="form_group">
                                            <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                                Enter Amount
                                            </label>
                                            <div className="inputGroup">
                                                <input
                                                    className="customInput"
                                                    placeholder="JBR"
                                                    name="text"
                                                    type="text"
                                                />
                                            </div>
                                            <p className='messageTxt ps-4'>Note: Min amount $30 USD. Max amount $10000.00 USD</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 ">
                                        <div className="form_group">
                                            <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                                Note
                                            </label>
                                            <div className="inputGroup">
                                                <textarea className='formtextarea_' placeholder="Cash cut-off before rush hour checked by Eugenia. It’s important to make inventories .">Cash cut-off before rush hour checked by Eugenia. It’s important to make inventories .</textarea>
                                                <img className='walletmsgicon_' src={Images.walletmessageIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-4'>
                                        <div className='transferSelect_'>
                                            <div className="reactSelectParent commonSelectParent w-100">
                                                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                                    Category
                                                </label>
                                                <ReactSelect
                                                    placeholder={
                                                        "Select one"
                                                    }
                                                    classNamePrefix="react-select"
                                                    className="react-select-container anlyticSelect"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            maxWidth: "100% !important",
                                                            width: "100%",
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            border: "1px solid #D7DEFF",
                                                        }),
                                                        valueContainer: (provided) => ({
                                                            ...provided,
                                                            paddingLeft: "unset !important",
                                                        }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            backgroundColor: state.isSelected
                                                                ? "#263682"
                                                                : state.isFocused
                                                                    ? "#f5f6fc"
                                                                    : "#fff",

                                                            color: state.isSelected ? "#fff" : "#263682",
                                                            fontSize: "18px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                            lineHeight: "normal",
                                                        }),
                                                        placeholder: (provided) => ({
                                                            ...provided,
                                                            color: "#636E9F",
                                                            fontSize: "18px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                            lineHeight: "normal",
                                                        }),
                                                    }}
                                                    components={{
                                                        DropdownIndicator: () => (
                                                            <img
                                                                src={Images.selectImg}
                                                                width={24}
                                                                height={24}
                                                                alt="drop_icon"
                                                                className="mt-1"
                                                            />
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='jbrBalnceavailable_'>
                                    <h5 className='headblue18'>Balance available </h5>
                                    <h2 className='lightHead30_'>JBR 6900.00</h2>
                                    <div className='availbalance_'>
                                        <h3 className='skyblueHead48'>JBR 0.00</h3>
                                        <h5 className='receivehead_ mt-2'>Will Receive</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                   modalDetail.flag === "WithdrawPreviewmodal"
                            ? "withdrawPreviewmodal"

                            : ""
                }
                child={
                        modalDetail.flag === "WithdrawPreviewmodal" ? (
                            <WithdrawPreviewmodal close={() => handleOnCloseModal()} />
                        ) :
                            (
                                <></>
                            )
                }
                header={
                    <>
                        {modalDetail.flag === "WithdrawPreviewmodal" ? (
                            <>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div>
                                        <h3 className="fontSize24 fw500 txtDarkblue mb-2 ">
                                        Withdrawal Preview
                                        </h3>

                                    </div>
                                    <img
                                        src={Images.modalCross}
                                        className='modalcross'
                                        alt="logo"
                                        onClick={() => handleOnCloseModal()}
                                    />
                                </div>
                            </>
                        )
                            : (
                                ""
                            )}
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default JbrTransfer