import React, { useState } from "react";
import * as Images from "../../../../utilities/images";
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth, verifyUserPasswordApiAsync } from "../../../containers/auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

const AccountPassModal = (props) => {
  const { t } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);
  const auth =useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const dispatch=useDispatch();
  // Validation schema using Yup
  const validationSchema = Yup.object({
    currentPassword: Yup.string()
      .required('Please enter current password')
  });

  // Handle form submission
  const handleConfirm = (values) => {
    const params={
      postData:{
        id:auth?.payload?.id+'',
        password:values.currentPassword
      },
      tokenData:auth?.payload?.token
    }
    setLoading(true);
    dispatch(verifyUserPasswordApiAsync(params))
    .then(unwrapResult)
    .then(res=>{
      toast.success("Password verified successfully");
      setLoading(false);
      
      props?.setCurrentPassword(values.currentPassword);
    props?.open();
    })
    .catch(err=>{
      setLoading(false);
  
    })
    
  };

  return (
    <div className="accountPassParent">
      <h1 className="fontSize36 fw500 txtBlue text-center mb-0">
        {t('accountPasswordRequired')}
      </h1>

      {/* Formik form */}
      <Formik
        initialValues={{
          currentPassword: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleConfirm}
      >
        {({ setFieldValue, values, errors, touched, handleChange }) => (
          <Form>
            <div className="passInputParent">
              <Field
                name="currentPassword"
                className="customform-control id_password"
                placeholder="Password"
                type={passwordShown ? "text" : "password"}
                value={values.currentPassword}
                onChange={handleChange}
              />
              <img src={Images.passIcon} alt="passIcon" className="passIcon" />
        <span
          className="toggle_password_ info_icon"
          onClick={() => {
            setPasswordShown((prev) => !prev);
          }}
        >
          <span
            className={
              passwordShown
                ? "show-icon togglePassword"
                : "hide-icon togglePassword"
            }
          ></span>
        </span>
            <ErrorMessage
              name="currentPassword"
              component="div"
              className="error-message"
            />
            </div>
            <p className="fontSize16 txtDarkblue">
              {t('passwordTitle')}
            </p>

            <div className="modalfooterBtn align-items-center">
              <button
                className="modalDiscardBtn modalPreviousBtn w-100 flexBox gap-2 justify-content-center"
                type="button"
              disabled={loading}

                onClick={() => {
                  props?.close();
                }}
              >
                <img src={Images.previousBtnImg} alt="img" />
                {t('back')}
              </button>
              <button
              disabled={loading}
                type="submit"
                className={`modalNextBtn modalDiscardBtn w-100 flexBox gap-2 justify-content-center ${values.currentPassword?.length&&'active'}`}
              >
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                {t('confirm')}
                <img src={Images.arrowTopRight} alt="img" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountPassModal;
