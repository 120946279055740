export const customerNotificationOptions=[
    {
        title:"In-App Notification",
        languageTitle:"inAppNotification",
        key:"app_notifications",
        options:[
            { title:"Order Confirmation",
                languageTitle:"orderConfirmation", key: "order_accepted_status", value: false },
            { title:"Order out for local Delivery",
                languageTitle:"orderLocal", key: "order_out_for_delivery_status", value: false },
            { title:"Delivery Confirmation",
                languageTitle:"deliveryConfirm", key: "delivery_update_status", value: false },
            { title:"Order locally delivered",
                languageTitle:"orderLocally", key: "order_delivered_status", value: false },
            { title:"Order missed local delivery",
                languageTitle:"orderMissed", key: "order_missed_local_delivery", value: false },
            { title:"Order edited",
                languageTitle:"orderEdited", key: "order_edited", value: false },
            { title:"Order cancelled",
                languageTitle:"orderCancelled", key: "order_canceled_status", value: false },
            { title:"Shipping Confirmation",
                languageTitle:"shippingConfirmation", key: "shipping_notification_status", value: false },
            { title:"Shipping update",
                languageTitle:"shippingUpdate", key: "shipping_update", value: false },
            { title:"Shipping out for delivery",
                languageTitle:"shippingOut", key: "shipping_out_for_delivery", value: false },
            { title:"Shipping delivered",
                languageTitle:"shippingDelivered", key: "shipping_delivered", value: false },
            { title:"Ready for local pickup",
                languageTitle:"readyForlocalPickup", key: "order_ready_for_pickup_status", value: false },
            { title:"POS and mobile receipt",
                languageTitle:"posMobileReceipt", key: "pos_mobile_receipt", value: false },
            { title:"POS email to customer",
                languageTitle:"posEmail", key: "pos_email_to_customer", value: false },
          ]
    },
   
    {
        title:"SMS Notification",
        languageTitle:"smsNotification",
        key:"sms_notifications",
        options:[
            { title:"Order Confirmation",
                languageTitle:"orderConfirmation", key: "order_accepted_status", value: false },
            { title:"Order out for local Delivery",
                languageTitle:"orderLocal", key: "order_out_for_delivery_status", value: false },
            { title:"Delivery Confirmation",
                languageTitle:"deliveryConfirm", key: "delivery_update_status", value: false },
            { title:"Order locally delivered",
                languageTitle:"orderLocally", key: "order_delivered_status", value: false },
            { title:"Order missed local delivery",
                languageTitle:"orderMissed", key: "order_missed_local_delivery", value: false },
            { title:"Order edited",
                languageTitle:"orderEdited", key: "order_edited", value: false },
            { title:"Order cancelled",
                languageTitle:"orderCancelled", key: "order_canceled_status", value: false },
            { title:"Shipping Confirmation",
                languageTitle:"shippingConfirmation", key: "shipping_notification_status", value: false },
            { title:"Shipping update",
                languageTitle:"shippingUpdate", key: "shipping_update", value: false },
            { title:"Shipping out for delivery",
                languageTitle:"shippingOut", key: "shipping_out_for_delivery", value: false },
            { title:"Shipping delivered",
                languageTitle:"shippingDelivered", key: "shipping_delivered", value: false },
            { title:"Ready for local pickup",
                languageTitle:"readyForlocalPickup", key: "order_ready_for_pickup_status", value: false },
            { title:"POS and mobile receipt",
                languageTitle:"posMobileReceipt", key: "pos_mobile_receipt", value: false },
            { title:"POS email to customer",
                languageTitle:"posEmail", key: "pos_email_to_customer", value: false },
          ]
    },
    {
        title:"Email Notification",
        languageTitle:"emailNotification",
        key:"email_notifications",
        options:[
            { title:"Order Confirmation",
                languageTitle:"orderConfirmation", key: "order_accepted_status", value: false },
            { title:"Order out for local Delivery",
                languageTitle:"orderLocal", key: "order_out_for_delivery_status", value: false },
            { title:"Delivery Confirmation",
                languageTitle:"deliveryConfirm", key: "delivery_update_status", value: false },
            { title:"Order locally delivered",
                languageTitle:"orderLocally", key: "order_delivered_status", value: false },
            { title:"Order missed local delivery",
                languageTitle:"orderMissed", key: "order_missed_local_delivery", value: false },
            { title:"Order edited",
                languageTitle:"orderEdited", key: "order_edited", value: false },
            { title:"Order cancelled",
                languageTitle:"orderCancelled", key: "order_canceled_status", value: false },
            { title:"Shipping Confirmation",
                languageTitle:"shippingConfirmation", key: "shipping_notification_status", value: false },
            { title:"Shipping update",
                languageTitle:"shippingUpdate", key: "shipping_update", value: false },
            { title:"Shipping out for delivery",
                languageTitle:"shippingOut", key: "shipping_out_for_delivery", value: false },
            { title:"Shipping delivered",
                languageTitle:"shippingDelivered", key: "shipping_delivered", value: false },
            { title:"Ready for local pickup",
                languageTitle:"readyForlocalPickup", key: "order_ready_for_pickup_status", value: false },
            { title:"POS and mobile receipt",
                languageTitle:"posMobileReceipt", key: "pos_mobile_receipt", value: false },
            { title:"POS email to customer",
                languageTitle:"posEmail", key: "pos_email_to_customer", value: false },
          ]
    },
]
export const staffNotificationOptions=[
    {
        title:"In-App Notification",
        languageTitle:"inAppNotification",
        key:"app_notifications",
        options:[
            { title:"Staff Pos Login",languageTitle:"posStaffLogin", key: "employee_logins", value: false },
            { title:"Staff Admin Login",languageTitle:"adminStaffLogin", key: "staff_admin_login", value: false },
            { title:"Staff Admin Log out",languageTitle:"adminLogout", key: "staff_admin_logout", value: false },
            { title:"Staff Pos Log out",languageTitle:"posLogout", key: "staff_pos_logout", value: false },
            { title:"Add Cash to drawer",languageTitle:"addCashtDrawer", key: "add_cash_to_drawer", value: false },
            { title:"Remove Cash from drawer",languageTitle:"removeCashfromDrawer", key: "remove_cash_from_drawer", value: false },
            { title:"Tips amount",languageTitle:"tipAmount", key: "show_tips_option", value: false },
            { title:"Staff on leave",languageTitle:"staffLeave", key: "staff_on_leave", value: false },
            { title:"Staff Salary alert",languageTitle:"staffAlert", key: "staff_salary_alert", value: false },
        ]
    },
   
    {
        title:"SMS Notification",
        languageTitle:"smsNotification",
        key:"sms_notifications",
        options:[
            { title:"Staff Pos Login",languageTitle:"posStaffLogin", key: "employee_logins", value: false },
            { title:"Staff Admin Login",languageTitle:"adminStaffLogin", key: "staff_admin_login", value: false },
            { title:"Staff Admin Log out",languageTitle:"adminLogout", key: "staff_admin_logout", value: false },
            { title:"Staff Pos Log out",languageTitle:"posLogout", key: "staff_pos_logout", value: false },
            { title:"Add Cash to drawer",languageTitle:"addCashtDrawer", key: "add_cash_to_drawer", value: false },
            { title:"Remove Cash from drawer",languageTitle:"removeCashfromDrawer", key: "remove_cash_from_drawer", value: false },
            { title:"Tips amount",languageTitle:"tipAmount", key: "show_tips_option", value: false },
            { title:"Staff on leave",languageTitle:"staffLeave", key: "staff_on_leave", value: false },
            { title:"Staff Salary alert",languageTitle:"staffAlert", key: "staff_salary_alert", value: false },
        ]
    },
    {
        title:"Email Notification",
        languageTitle:"emailNotification",
        key:"email_notifications",
        options:[
            { title:"Staff Pos Login",languageTitle:"posStaffLogin", key: "employee_logins", value: false },
            { title:"Staff Admin Login",languageTitle:"adminStaffLogin", key: "staff_admin_login", value: false },
            { title:"Staff Admin Log out",languageTitle:"adminLogout", key: "staff_admin_logout", value: false },
            { title:"Staff Pos Log out",languageTitle:"posLogout", key: "staff_pos_logout", value: false },
            { title:"Add Cash to drawer",languageTitle:"addCashtDrawer", key: "add_cash_to_drawer", value: false },
            { title:"Remove Cash from drawer",languageTitle:"removeCashfromDrawer", key: "remove_cash_from_drawer", value: false },
            { title:"Tips amount",languageTitle:"tipAmount", key: "show_tips_option", value: false },
            { title:"Staff on leave",languageTitle:"staffLeave", key: "staff_on_leave", value: false },
            { title:"Staff Salary alert",languageTitle:"staffAlert", key: "staff_salary_alert", value: false },
        ]
    },
]
export const newProductOrFeatureOptions=[
    {
        title:"In-App Notification",
        languageTitle:"inAppNotification",
        key:"app_notifications",
        options:[
            { title:"New Product updated",languageTitle:"productUpdated", key: "new_product_update", value: false },
            { title:"Programs and events",languageTitle:"programEvent", key: "programs_and_events", value: false },
            { title:"JOBR announcements",languageTitle:"annoucement", key: "jobr_announcement", value: false },
            { title:"Government programs",languageTitle:"govProgramms", key: "government_programs", value: false },
        ]
    },
   
    {
        title:"SMS Notification",
        languageTitle:"smsNotification",
        key:"sms_notifications",
        options:[
            { title:"New Product updated",languageTitle:"productUpdated", key: "new_product_update", value: false },
            { title:"Programs and events",languageTitle:"programEvent", key: "programs_and_events", value: false },
            { title:"JOBR announcements",languageTitle:"annoucement", key: "jobr_announcement", value: false },
            { title:"Government programs",languageTitle:"govProgramms", key: "government_programs", value: false },
        ]
    },
    {
        title:"Email Notification",
        languageTitle:"emailNotification",
        key:"email_notifications",
        options:[
            { title:"New Product updated",languageTitle:"productUpdated", key: "new_product_update", value: false },
            { title:"Programs and events",languageTitle:"programEvent", key: "programs_and_events", value: false },
            { title:"JOBR announcements",languageTitle:"annoucement", key: "jobr_announcement", value: false },
            { title:"Government programs",languageTitle:"govProgramms", key: "government_programs", value: false },
        ]
    },
]
export const salesNotificaitionsOptions=[
    {
        title:"In-App Notification",
        languageTitle:"inAppNotification",
        key:"app_notifications",
        options:[
            { title:"Daily sales summary",languageTitle:"salesSummary", key: "daily_sales_summary", value: false },
            { title:"Monthly sales summaries",languageTitle:"monthSalesSummary", key: "monthly_sales_summaries", value: false },
            { title:"Annual sales summaries",languageTitle:"annualSalesSummary", key: "annual_sales_summaries", value: false },
        ]
    },
   
    {
        title:"SMS Notification",
        languageTitle:"smsNotification",
        key:"sms_notifications",
        options:[
            { title:"Daily sales summary",languageTitle:"salesSummary", key: "daily_sales_summary", value: false },
            { title:"Monthly sales summaries",languageTitle:"monthSalesSummary", key: "monthly_sales_summaries", value: false },
            { title:"Annual sales summaries",languageTitle:"annualSalesSummary", key: "annual_sales_summaries", value: false },
        ]
    },
    {
        title:"Email Notification",
        languageTitle:"emailNotification",
        key:"email_notifications",
        options:[
            { title:"Daily sales summary",languageTitle:"salesSummary", key: "daily_sales_summary", value: false },
            { title:"Monthly sales summaries",languageTitle:"monthSalesSummary", key: "monthly_sales_summaries", value: false },
            { title:"Annual sales summaries",languageTitle:"annualSalesSummary", key: "annual_sales_summaries", value: false },
        ]
    },
]