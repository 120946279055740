import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import OtpTimer from "otp-timer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import PhoneInput from "react-phone-input-2";
import { sendOtpEmailApiAsync, sendOtpPhoneApiAsync, validateEmailApiAsync, validatePhoneApiAsync, verifyEmailOtpAsync } from "../auth/authSlice";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../utilities/helpers";
import * as Images from "../../../utilities/images";


const VerifyEmailPhone = (props) => {
    const { t } = useTranslation();
    const modalDetail = props.modalDetail;
    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
    };
    const [otp, setOtp] = useState("");
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [phoneId, setPhoneId] = useState("")
    const [emailId, setEmailId] = useState("")
    const [email, setEmail] = useState(modalDetail?.value ?? "");
    const [phoneNo, setPhoneNo] = useState(modalDetail?.value ?? "");
    const [phoneCode, SetPhoneCode] = useState(modalDetail?.phone_code ?? "");
    const [loading, setLoading] = useState(false);
    const [sendOtploading, setSendOtpLoading] = useState(false);
    const [isShowPin, setShowPin] = useState(false);
    const debounceOtp = useDebounce(otp, 900);

    const onChangePhoneNumber = (value, data) => {
        let phoneCode = data.dialCode;
        let phoneNumber = value.slice(data.dialCode.length);
        setPhoneNo(phoneNumber);
        SetPhoneCode(phoneCode);
        props?.setPhoneCode(phoneCode)
        props?.setPhoneNumber(phoneNumber)
    };

    const onComplete = (code) => {
        setOtp(code);
    };

    useEffect(() => {
        if(debounceOtp?.length >= 5 && modalDetail?.type == "Email"){
            verifyOtpForEmail()
        }
        if(debounceOtp?.length >= 5 && modalDetail?.type == "Phone"){
            verifyOtpForPhone()
        }
    }, [debounceOtp]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    const generateOtpForEmail = async () => {
        if (!email) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter email");
            }
            return false;
        } else {
            setSendOtpLoading(true);
            const data = {
                "type": "email",
                "email": email,
            };
            //   const res = await dispatch(updateUserEmailApi(data, tokenData));
            //   if (res) {
            //     setSendOtpLoading(false);
            //     if (res?.data?.status_code == 200) {
            //       setShowPin(true);
            //     }
            //   }

            dispatch(sendOtpEmailApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg);
                    }
                    console.log(obj, "email send")
                    setEmailId(obj?.payload?.id)
                    setShowPin(true);
                    setSendOtpLoading(false);
                })
                .catch((obj) => {
                    if (obj?.message == "Request failed with status code 409") {
                        props?.setIsEmailVerified(true)
                        props?.close()
                    }

                    setSendOtpLoading(false);
                });

        }
    };

    const verifyOtpForEmail = async () => {
        if (!otp) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter otp");
            }
            return false;
        } else if (otp < 4) {
            toastId.current = toast.error("Please enter all field");
        } else {
            setLoading(true);
            const data = {
                "type": "email",
                "otp": otp,
                "role_id": 7,
                "id": emailId
            };
            //   const res = await dispatch(verifyUpdateUserEmailApi(data, tokenData));
            //   if (res) {
            //     setLoading(false);
            //     if (res?.data?.status_code == 200) {
            //       props?.getUserData();
            //       props?.close();
            //     }
            //   }

            dispatch(validateEmailApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg);
                    }
                    props?.setIsEmailVerified(true)
                    props?.close();
                    setLoading(false);
                })
                .catch((obj) => {
                    setLoading(false);
                });

        }
    };

    const generateOtpForPhone = async () => {
        if (!email) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter email");
            }
            return false;
        } else {
            setSendOtpLoading(true);
            const data = {
                phone_code: phoneCode.startsWith("+") ? phoneCode : "+" + phoneCode,
                phone_no: phoneNo,
            };
            //   console.log("Sdasd", data);
            //   const res = await dispatch(updateUserPhoneApi(data, tokenData));
            //   if (res) {
            //     setSendOtpLoading(false);
            //     if (res?.data?.status_code == 200) {
            //       setShowPin(true);
            //     }
            //   }

            dispatch(sendOtpPhoneApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg);
                    }
                    console.log(obj, 'objjjjjjjjjj');
                    if (obj?.payload?.is_phone_exits) {
                        props?.setIsPhoneVerified(true)
                        props?.close();
                    } else {
                        setPhoneId(obj?.payload?.id)
                        setShowPin(true);
                        setSendOtpLoading(false);
                    }
                })
                .catch((obj) => {
                    setSendOtpLoading(false);
                });
        }
    };

    const verifyOtpForPhone = async () => {
        if (!otp) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter otp");
            }
            return false;
        } else if (otp < 4) {
            toastId.current = toast.error("Please enter all field");
        } else {
            setLoading(true);
            const data = {
                "id": phoneId,
                "otp": otp
            };
            //   const res = await dispatch(verifyUpdateUserPhonelApi(data, tokenData));
            //   if (res) {
            //     setLoading(false);
            //     if (res?.data?.status_code == 200) {
            //       props?.getUserData();
            //       props?.close();
            //     }
            //   }

            dispatch(validatePhoneApiAsync(data))
                .then(unwrapResult)
                .then((obj) => {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(obj?.msg);
                    }
                    console.log(obj, "phone verified")
                    props?.setIsPhoneVerified(true)
                    props?.close();
                    setLoading(false);
                })
                .catch((obj) => {
                    setLoading(false);
                });
        }
    };
    const onCloseModal = () => {
        setOtp("");
        props?.close();
    };
    return (
        <>
            <div className="editEmailrVerify_">
                {isShowPin && (
                    <div>
                        <h4 className="modalHeading_New d-flex align-items-center txtDarkblue">
                            <span
                                onClick={() => {
                                    setOtp("");
                                    setShowPin(false);
                                }}
                                className="d-flex"
                            >
                                <i className="las la-angle-left me-2 backArrowImg_"></i>{" "}
                            </span>
                            <label className="fontSize20 fw500">
                                {modalDetail?.type == "Email"
                                    ? email
                                    : "+" + phoneCode + phoneNo}
                            </label>
                        </h4>
                    </div>
                )}
                <span className="crossIconNew" onClick={() => onCloseModal()}>
                    {/* <i className="las la-times" aria-hidden="true"></i> */}
                    <img src={Images.modalCross} alt="img"/>
                </span>

                {!isShowPin ? (
                    <form
                        autoComplete="nope"
                        className="form_horizontal"
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <div className="mb-4 form-group">
                            {modalDetail?.type == "Phone" ? (
                                <div className="mb-4 otp__ ">
                                    <div className="phone-numbpart">
                                        <div className="country-plugin">
                                            <label className="form-label">{t('phNum')}</label>
                                            <div id="result" className="countryCodePickerParent">
                                                <PhoneInput
                                                    country="us"
                                                    value={modalDetail?.phone_code + modalDetail?.value}
                                                    // enableSearch={true}
                                                    onlyCountries={["us", "in"]}
                                                    name={generateRandomName()}
                                                    //   disabled
                                                    placeholder="Phone no."
                                                    onChange={(value, data, event, formattedValue) =>
                                                        onChangePhoneNumber(
                                                            value,
                                                            data,
                                                            event,
                                                            formattedValue
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="mb-4 form-group col-md-12">
                                    <label className="deatilsHead mb-3">{t('emailAddress')}</label>
                                    <div style={{ display: "flex" }}>
                                        <input
                                            type="email"
                                            className="customform-control"
                                            name={generateRandomName()}
                                            autoComplete="new-password"
                                            placeholder="Email"
                                            value={email}
                                            //   disabled
                                            onChange={(e) => { setEmail(e.target.value); props?.setEmail(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-12 mt-5">
                            <button
                                className={"loginBtnSign w-100 buttonActive"}
                                type="submit"
                                onClick={() => {
                                    modalDetail?.type == "Email"
                                        ? generateOtpForEmail()
                                        : generateOtpForPhone();
                                }}
                            >
                                {sendOtploading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>{t('sendOtp')}</span>
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="verify-part mt-3">
                        <div className="verify-box text-center">
                            {/* <label className="form-label">Enter OTP</label> */}
                            <div className="pin-box d-flex justify-content-center">
                                <OTPInput
                                    numInputs={5}
                                    className="input_digits_"
                                    value={otp}
                                    data-cy="pin-field"
                                    placeholder="00000"
                                    name={generateRandomName()}
                                    autoComplete="new-password"
                                    isInputNum={true}
                                    // isInputSecure={true}
                                    onChange={onComplete}
                                />
                            </div>
                            <div className="col-md-12 mt-5">
                                <button
                                    className={"loginBtnSign w-100 buttonActive"}
                                    type="submit"
                                    onClick={() =>
                                        modalDetail?.type == "Email"
                                            ? verifyOtpForEmail()
                                            : verifyOtpForPhone()
                                    }
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>{t('submit')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default VerifyEmailPhone;
