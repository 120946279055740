import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import LocationAndStockModal from "./LocationAndStockModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getProductListByIdAsync } from "./productSlice";
import { toast } from "react-toastify";
import { getColorName } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";

const MyProductDetails = () => {
  const toastId = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const selectedProductDtails = location?.state?.selectedItem;
  const addProduct = location?.state?.allProducts || [];

  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [productData, setProductData] = useState(selectedProductDtails || []);

  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [allAddress, setAllAddress] = useState([]);

  const allSupplyVariants =
    productData?.supplies?.flatMap((supply) => supply.supply_variants || []) ||
    [];

  const uniqueVariants = new Map();

  allSupplyVariants.forEach((variant) => {
    const attributes =
      typeof variant?.attributes === "string"
        ? JSON.parse(variant?.attributes)
        : variant?.attributes || {};

    // Convert attributes object to a string for uniqueness
    const attrString = JSON.stringify(attributes);

    // Store only the first occurrence of each unique attribute combination
    if (!uniqueVariants.has(attrString)) {
      uniqueVariants.set(attrString, variant);
    }
  });

  // Convert Map back to an array
  const filteredVariants = Array.from(uniqueVariants.values());

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());
  };

  const handleVariantSelect = (event) => {
    const variantId = Number(event.target.id.replace("idcheck3 ", ""));
    // Extract the ID from the checkbox

    if (event.target.checked) {
      // Add to selected variants if the checkbox is checked
      setSelectedVariants((prevState) => [...prevState, variantId]);
    } else {
      // Remove from selected variants if the checkbox is unchecked
      setSelectedVariants((prevState) =>
        prevState.filter((id) => id !== variantId)
      );
    }
  };

  // const handelAllVariantsSelect = (event) => {
  //   const allVariantsId = allSupplyVariants.map((variant) => variant.id);
  //   if (event.target.checked) {
  //     if (allVariantsId.every((id) => selectedVariants.includes(id))) {
  //       setSelectedVariants([]); // Uncheck all if already selected
  //     } else {
  //       setSelectedVariants(allVariantsId);
  //     }
  //   } else {
  //     setSelectedVariants([]);
  //   }
  // };

  const handelAllVariantsSelect = (event) => {
    const allVariantsId = filteredVariants.map((variant) => variant.id);
    if (event.target.checked) {
      if (allVariantsId.every((id) => selectedVariants.includes(id))) {
        setSelectedVariants([]); // Uncheck all if already selected
      } else {
        setSelectedVariants(allVariantsId);
      }
    } else {
      setSelectedVariants([]);
    }
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };

    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];
        // select default seller address if only one address
        let addressList = obj?.payload;
        setAllAddress(addressList || []);
        if (addressList?.length == 1) {
          setSellerAddressIds([addressList[0]?.id]);
        }
      })
      .catch((obj) => {});
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    if (filteredVariants?.length === 1) {
      setSelectedVariants([filteredVariants[0]?.id]); // Correctly adding the single variant's ID
    }
  }, []);

  return (
    <div className="productDetailsWrapper myProductDetailSection">
      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <div className="col-md-4">
            <div className="walletStats h-100 productDetailSidebar">
              <div className="d-flex align-items-start gap-3">
                <img
                  src={Images.backArrow}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => {
                    history.push({
                      pathname: `/products`,
                      state: { products: addProduct }, // Pass product details in state
                    });
                  }}
                />
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  {productData?.name}
                </h2>
              </div>
              <div className="productPreviewBox text-center">
                <img
                  src={productData?.image}
                  alt="bagImg"
                  className="offerProductImg"
                />
                <h4 className="fontSize20 fw500 txtDarkblue mb-0 pt-4">
                  {productData?.name}
                </h4>
                {/* <div className="subCategoryTxtBox">
                  <span className="greyDot"></span>
                  <h6 className="greyDotLabel mb-0">
                    SKU {productData?.supplies[0].supply_variants[0]?.sku}
                  </h6>
                </div> */}
                <h5 className="fontSize16 fw500 txtDarkblue">
                  ${productData?.price?.toFixed(2)}
                </h5>
              </div>
              <div className="productSidebar_Bottom ">
                <button
                  className="modalNextBtn modalDiscardBtn active w-100 m-0 d-flex align-items-center justify-content-center gap-2"
                  onClick={() => {
                    if (selectedVariants.length === 0) {
                      showToast("please select at least one variants");
                      return;
                    }
                    handleOpenModal("locationAndStockModal");
                  }}
                >
                  <img src={Images.plusIcon} alt="plusIcon" />
                  {t("addProduct")}
                </button>

                {filteredVariants?.length > 1 && (
                  <p className="fontSize14 txtBlue d-flex align-items-center justify-content-center gap-2 mb-0 pt-2">
                    <img src={Images.infoDetailIcon} alt="product image- h" />
                    {selectedVariants?.length} of{" "}
                    {productData?.supplies[0]?.supply_variants.length} {t("variantSelected")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-8 ps-0">
            <div className="walletStats productDetailsLeft">
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                {t("productDet")}
              </h2>
              <div className="productDetailDataBox">
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">{t("category")}</h5>
                  <h5>{productData?.category?.name}</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">{t("details")}</h5>
                  <h5>
                    {productData?.description
                      ?.replace("<p>", "")
                      .replace("</p>", "")}
                  </h5>
                </div>
                {/* <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">SKU</h5>
                  <h5>{productData?.supplies[0].supply_variants[0]?.sku}</h5>
                </div> */}
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">{t("barcode")}</h5>
                  <h5>
                    {productData?.supplies[0].supply_variants[0]?.barcode}
                  </h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">{t('unitWeight')}</h5>
                  <h5>
                    {productData?.weight
                      ? `${productData.weight} ${productData.weight_unit || ""}`
                      : ""}
                  </h5>
                </div>
              </div>

              <div className="productDetailDataBox mt-4  mb-3">
                <h3 className="fontSize18 fw500 txtBlue">{t('pricing')}</h3>
                <div className="pricingBorderBox mt-3">
                  <div className="productDetailRight_Inner">
                    <h5 className="productCategoryTxt">{t('sellingPrice')}</h5>
                    <h5>${productData?.price?.toFixed(2)}</h5>
                  </div>
                </div>
              </div>

              {filteredVariants?.length > 1 && (
                <div className="myProduct_VariantsContainer">
                  <h3 className="fontSize18 fw500 txtBlue">{t('variant')}</h3>
                  <div className="pricingBorderBox mt-3">
                    <div className="productAvailabilityChild productVariantsCount align-items-start ">
                      <div className="form-group d-flex">
                        <input
                          type="checkbox"
                          checked={
                            selectedVariants.length ===
                            productData?.supplies[0]?.supply_variants.length
                          }
                          id="idcheck1"
                          onClick={handelAllVariantsSelect}
                        />
                        <label htmlFor="idcheck1" className="me-0"></label>
                      </div>
                      <h3 className="fontSize18 fw500 txtBlue mb-0">
                        {filteredVariants?.length} {t('variations')}
                      </h3>
                    </div>
                    <div className="commonTable sellingProductTable myProductVariantTable table-responsive">
                      <table className="w-100">
                        <tbody>
                          {filteredVariants.length > 0 &&
                            filteredVariants.map((item) => (
                              <tr key={item?.id}>
                                <td>
                                  <div className="productAvailabilityChild">
                                    <div className="form-group">
                                      <input
                                        type="checkbox"
                                        checked={selectedVariants.includes(
                                          Number(item?.id)
                                        )}
                                        onChange={handleVariantSelect}
                                        id={"idcheck3 " + item?.id}
                                      />
                                      <label
                                        htmlFor={"idcheck3 " + item?.id}
                                        className="me-0"
                                      ></label>
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                      <img
                                        src={item?.image}
                                        alt="img"
                                        className="productItemImg"
                                      />
                                      <div>
                                        <h3 className="topSellProductHead mb-0 pb-1">
                                          {productData?.name}
                                        </h3>
                                        {(() => {
                                          const attributes =
                                            typeof item?.attributes === "string"
                                              ? JSON.parse(item?.attributes)
                                              : item?.attributes || {};

                                          const color =
                                            attributes?.color ||
                                            attributes?.Color;

                                          return (
                                            <span className="d-inline-flex align-items-center">
                                              {color && (
                                                <span
                                                  className="dot mx-1"
                                                  style={{
                                                    backgroundColor: color,
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    display: "inline-block",
                                                  }}
                                                ></span>
                                              )}
                                              <h6 className="fontSize12 fw400 txtLightBlue mb-0">
                                                {/* {Object.values(attributes).join(
                                                " / "
                                              )} */}
                                                {Object.values(attributes)
                                                  .map((val) =>
                                                    val.startsWith("#") &&
                                                    /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(
                                                      val
                                                    )
                                                      ? getColorName(val) // Convert color hex codes to names
                                                      : val
                                                  )
                                                  .join(" / ")}
                                              </h6>
                                            </span>
                                          );
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <h5 className="fontSize12 fw400 mb-0 txtBlack">
                                    ${item?.cost_price.toFixed(2)}
                                  </h5>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "locationAndStockModal" ? "editVariantModal" : ""
        }
        child={
          modalDetail.flag === "locationAndStockModal" ? (
            <LocationAndStockModal
              type="product"
              close={() => handleOnCloseModal()}
              allAddress={allAddress}
              setSellerAddressIds={setSellerAddressIds}
              sellerAddressIds={sellerAddressIds}
              productId={productData?.id} // Pass product ID
              variantIds={selectedVariants} // Pass selected variant IDs
              addProduct={addProduct}
              filteredVariants={filteredVariants}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "locationAndStockModal" ? (
            <>
              <div></div>
              <img
                src={Images.modalCross}
                alt="logo"
                className="cursorPointer"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default MyProductDetails;
