import { useSelector } from "react-redux";
import io from "socket.io-client";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import {REACT_APP_CHAT_URL} from "../../config"
let socket;

export const initializeSocket = (id) => {
  if (!socket) {
    socket = io(`${REACT_APP_CHAT_URL}?userId=${id}`, {
      path: "/api/v1/connect",
    });
    // Optionally handle socket events or authentication here
  }
  return socket;
};

export const getSocket = (userId,newConnection=false) => {
  if (!socket||newConnection) {
    const socket = initializeSocket(userId);
    return socket;
  }
  return socket;
};

export const getSocketInstance=(url,path)=>{
   let socket=null;
   return ()=>{
    if(socket)return socket;
    socket=io(url,{path});
    return socket;
   }
}
export const closeSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};