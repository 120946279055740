import ColorNamer from "color-namer";
import moment from "moment-timezone";

import { useEffect, useState } from "react";

export default function useDebounce(search, delay) {
  const [value, setValue] = useState("");

  useEffect(() => {
    const timerId = setTimeout(() => {
      setValue(search);
    }, delay);
    return () => {
      clearTimeout(timerId);
    };
  }, [search, delay]);
  return value;
}


export const addPadStart = (count) => {
  if(!count) return "0";
  return count >=  10 ? count : String(count)?.padStart(2, "0") 
}
// MMMM Do YYYY, h:mm:ss a
export const getFormatedDate = (
  date,
  format = "MM-DD-YYYY, h:mm:ss A",
  timeZone = ""
) => {
  if (date && moment(date).isValid()) {
    if (timeZone) {
      return moment(date).format(format).tz(timeZone);
    }
    return moment(date).format(format);
  }
  return false;
};

export function addThousandSeparator(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const options = [
  { id: 1, label: "Monday" },
  { id: 2, label: "Tuesday" },
  { id: 3, label: "Wednesday" },
  { id: 4, label: "Thursday" },
  { id: 5, label: "Friday" },
  { id: 6, label: "Saturday" },
  { id: 7, label: "Sunday" },
];

export const dayList = [
  { id: 1, value: "Monday", day: "monday" },
  { id: 2, value: "Tuesday", day: "tuesday" },
  { id: 3, value: "Wednesday", day: "wednesday" },
  { id: 4, value: "Thursday", day: "thursday" },
  { id: 5, value: "Friday", day: "friday" },
  { id: 6, value: "Saturday", day: "saturday" },
  { id: 7, value: "Sunday", day: "sunday" },
];
export const getDateTimeStampConvertor = (timestamp) => {
  let date = new Date(timestamp);
  let formattedDate = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return formattedDate.split(",");
  //["10am","01/23/25"]
};
export const languageList = [
  {
    name: "United States of America",
    image: "https://flagcdn.com/w320/us.png",
    status: "true",
  },
  {
    name: "Russia",
    image: "https://flagcdn.com/w320/ru.png",
    status: "true",
  },
  {
    name: "Portugal",
    image: "https://flagcdn.com/w320/pt.png",
    status: "true",
  },
  {
    name: "Spanish",
    image: "https://flagcdn.com/w320/es.png",
    status: "true",
  },
  {
    name: "Italian",
    image: "https://flagcdn.com/w320/it.png",
    status: "true",
  },
];

export const shortenAddress = (address = "", index = 4) => {
  if (!address || address.length <= index * 2) return address;
  return `${address.slice(0, index)}...${address.slice(-index)}`;
};

export const getCurrentMonthDetails = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentMonth, 1);
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentMonth + 1,
    0
  );

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[currentMonth];
  const year = currentDate.getFullYear();

  const dayOfMonth = String(firstDayOfMonth.getDate()).padStart(2, "0");
  const lastDay = String(lastDayOfMonth.getDate()).padStart(2, "0");

  return {
    startOfMonth: dayOfMonth,
    endOfMonth: lastDay,
    monthName: monthName,
    year: year,
  };
};

// get colorname
export const getColorName = (color) => {
  // Check if color is a valid hex code (3 or 6 characters after #)
  if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(color)) {
    console.warn(`Invalid color code detected: ${color}`);
    return color; // Fallback text
  }

  const result = ColorNamer(color);
  return result?.ntc[0]?.name || color;
};
