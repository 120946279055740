import React from "react";
import * as Images from "../../../../utilities/images";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const ChatFooter = ({
  DialogOptions,
  handleOptions,
  quickMess,
  QuickMessageOptions,
  setMessage,
  setQuickMess,
  message,
  handleSubmit,
  handleOpenModal,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="messSendContainer flexBox gap-2 justify-content-between">
        {/* <div className="position-relative">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="variantsTable_Dropdown"
              disabled={quickMess}
              // onClick={() => setQuickMess(quickMess === true ? false : "")}
            >
              <img
                src={Images.plusCircleOutline}
                alt="img"
                width={24}
                height={24}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu className="variantsDropdownMenu quickMessDropdownMenu">
              {DialogOptions?.map((option, index) => (
                <Dropdown.Item key={index} href={`#/action-${index}`}>
                  <div className="d-flex gap-2 align-items-center">
                    <img src={Images?.[option.img]} alt="logo" />
                    <div>
                      <h3
                        className="fontSize16 mb-0"
                        onClick={() => {
                          handleOptions(option.key);
                        }}
                      >
                        {option.title}
                      </h3>
                      {option.subTitle && (
                        <p className="fontSize12 txtLightBlue">
                          {option.subTitle}
                        </p>
                      )}
                    </div>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {quickMess && (
            <div className="quickMessContainer">
              {QuickMessageOptions?.map((quickMessage, index) => (
                <div
                  key={index}
                  className="quickMessParent"
                  onClick={() => {
                    // if (isMessaging) return;

                    setMessage(quickMessage);
                    setQuickMess(false);
                  }}
                >
                  <h3 className="quickMessleft">{index}</h3>
                  <p className="fontSize18 txtDarkblue">{quickMessage}</p>
                </div>
              ))}
            </div>
          )}
        </div> */}

        <input
          className="customInput chatMessInput"
          placeholder={t('writeMessage')}
          name="text"
          type="text"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          // add on key enter
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(null, "text");
            }
          }}
        />
        <div className="flexBox gap-2 cursorPoint">
          <img
            onClick={() => {
              handleOpenModal("VoiceRecord");
            }}
            src={Images.microphoneLogo}
            alt="img"
          />
          <img
            src={Images.messCameraLogo}
            alt="img"
            onClick={() => {
              handleOpenModal("uploadFiles");
            }}
          />
          <img
            onClick={() => {
              handleSubmit(null, "text");
            }}
            src={Images.messSenderLogo}
            alt="img"
          />
        </div>
      </div>
    </>
  );
};

export default ChatFooter;