import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Stepper } from "react-form-stepper";
import * as Images from "../../../utilities/images";
import CreateOfferDetails from "./CreateOfferDetails";
import CreateOfferLocation from "./CreateOfferLocation";
import CreateOfferPublish from "./CreateOfferPublish";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { getProductServiceListApiAsync } from "./productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { createProductOfferApiAsync } from "../dashboard/dashboardSlice";
import moment from "moment-timezone";
import { getColorName } from "../../../utilities/helpers";

const CreateOffer = () => {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const auth = useSelector(selectLoginAuth);
  const [activeStep, setActiveStep] = useState(1);
  const history = useHistory();
  const [searchData, setSearchData] = useState("");
  const [showProduct, setShowProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedproduct, setSelectedproduct] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [validVariants, setValidVariants] = useState([]);
  const [offerPrices, setOfferPrices] = useState({});

  const [allAddress, setAllAddress] = useState([]);

  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [selectType, setSelectType] = useState({
    value: "percentage",
    label: "Percentage",
  });

  const [priceType, setPriceType] = useState({
    value: "same",
    label: "Same Offer Price",
  });

  const allSupplyVariants =
    selectedproduct?.supplies?.flatMap(
      (supply) => supply.supply_variants || []
    ) || [];

  const uniqueVariants = new Map();

  allSupplyVariants.forEach((variant) => {
    const attributes =
      typeof variant?.attributes === "string"
        ? JSON.parse(variant?.attributes)
        : variant?.attributes || {};

    // Convert attributes object to a string for uniqueness
    const attrString = JSON.stringify(attributes);

    // Store only the first occurrence of each unique attribute combination
    if (!uniqueVariants.has(attrString)) {
      uniqueVariants.set(attrString, variant);
    }
  });

  // Convert Map back to an array
  const filteredVariants = Array.from(uniqueVariants.values());

  const uniqueSellerAddressIds = [
    ...new Set(
      selectedproduct?.supplies?.map((item) => item.seller_address_id)
    ),
  ];

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const validateStepOne = () => {
    if (!selectedproduct) {
      showToast("Please search and select a product.");
      return false;
    }

    if (!selectType?.value) {
      showToast("Please select a type.");
      return false;
    }

    if (!startDate) {
      showToast("Start date is required.");
      return false;
    }

    if (!endDate) {
      showToast("End date is required.");
      return false;
    }

    if (new Date(endDate) < new Date(startDate)) {
      showToast("End date cannot be before start date.");
      return false;
    }

    if (!Array.isArray(formattedVariants) || formattedVariants.length === 0) {
      showToast("Invalid variant data.");
      return false;
    }

    /**  Check if all formattedVariants have null/empty attributes */
    const allAttributesNull = formattedVariants.every(
      (variant) =>
        !variant.attributes || Object.keys(variant.attributes).length === 0
    );

    /**  If all attributes are null/empty, validate using formattedVariants */
    if (allAttributesNull) {
      for (const variant of formattedVariants) {
        for (const price of variant.prices || []) {
          const actualPrice = Number(price.actual_price);
          const offerPrice = Number(price.offer_price);

          if (offerPrice === 0) {
            const message =
              priceType?.value === "same"
                ? "The offer price cannot be zero."
                : `The offer price for ${
                    price.app_name == "b2c" ? "MarketPlace" : "POS"
                  } cannot be zero.`;
            showToast(message);
            return false;
          }

          if (
            selectType?.value === "amount" &&
            offerPrice < selectedproduct?.price
          ) {
            showToast("offer price wont be less than cost price.");
            return false;
          }

          if (
            selectType?.value === "percentage" &&
            (offerPrice < 1 || offerPrice >= 100)
          ) {
            showToast("Offer price percentage must be between 1 and 100.");
            return false;
          }

          if (
            selectType?.value !== "percentage" &&
            (offerPrice === actualPrice || offerPrice > actualPrice)
          ) {
            showToast(
              `The offer price cannot be higher or equal than the actual price ${actualPrice}`
            );
            return false;
          }
        }
      }
    } else {
      /**  If at least one variant has attributes, validate using validVariants */
      if (selectedVariants.length === 0) {
        showToast("Please select at least one variant.");
        return false;
      }
      const selectedVariantData = formattedVariants.filter((variant) =>
        selectedVariants.includes(String(variant.supply_variant_id))
      );

      if (selectedVariantData.length === 0) {
        showToast("Selected variants do not match available variants.");
        return false;
      }

      for (const variant of selectedVariantData) {
        for (const price of variant.prices || []) {
          const actualPrice = Number(price.actual_price);
          const offerPrice = Number(price.offer_price);
          const formattedAttributes = Object.entries(variant.attributes)
            .map(([key, val]) =>
              val.startsWith("#") &&
              /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(val)
                ? getColorName(val) // Convert hex color to name
                : val
            )
            .join(" / ");

          if (offerPrice === 0) {
            const message =
              priceType?.value === "same"
                ? `The offer price cannot be zero of ${formattedAttributes}`
                : `The offer price for ${
                    price.app_name == "b2c" ? "MarketPlace" : "POS"
                  } cannot be zero of ${formattedAttributes}`;
            showToast(message);

            return false;
          }

          if (
            selectType?.value === "amount" &&
            offerPrice < selectedproduct?.price
          ) {
            showToast("offer price wont be less than cost price.");
            return false;
          }

          if (
            selectType?.value === "percentage" &&
            (offerPrice < 1 || offerPrice >= 100)
          ) {
            showToast("Offer price percentage must be between 1 and 100.");
            return false;
          }

          if (
            selectType?.value !== "percentage" &&
            (offerPrice === actualPrice || offerPrice > actualPrice)
          ) {
            showToast(
              `The offer price cannot be higher or equal than the actual price ${actualPrice} of ${formattedAttributes}.`
            );
            return false;
          }
        }
      }

      setValidVariants(selectedVariantData);
    }

    return true;
  };

  const validateStepTwo = () => {
    if (sellerAddressIds.length === 0) {
      showToast("Please select at least one seller address.");
      return false;
    }

    // if (deliveryOptions.length === 0) {
    //   showToast("Please select at least one delivery option.");
    //   return false;
    // }

    return true;
  };

  const handleNext = () => {
    if (activeStep === 1) {
      if (!validateStepOne()) return; // Stop if validation fails
    }

    if (activeStep === 2) {
      if (!validateStepTwo()) return; // Stop if validation fails
    }
    if (activeStep === 3) {
      createOfferProduct();
      return;
    }
    setActiveStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const createOfferProduct = () => {
    let variantsData = formattedVariants.every(
      (variant) =>
        variant.attributes && Object.keys(variant.attributes).length > 0
    )
      ? validVariants
      : formattedVariants;

    // **Remove `attributes` field**
    variantsData = variantsData.map(({ attributes, ...variant }) => variant);
    //additional field for offer price option
    let additionalField = {
      offer_price_option: "different_price",
    };
    if (priceType?.value == "same") {
      additionalField["offer_price_option"] = "same_price";
    }
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        product_id: String(selectedproduct?.id),
        offer_validity: moment(endDate).format("YYYY-MM-DD"),
        offer_start_from: moment(startDate).format("YYYY-MM-DD"),
        price_flag: selectType.value,
        seller_address_ids: sellerAddressIds,
        // delivery_options: deliveryOptions,
        variants: variantsData,
        type: "product",
        category_id: String(selectedproduct?.category_id),
        sub_category_id: String(selectedproduct?.sub_category_id),
        ...additionalField,
      },
    };
    setButtonLoading(true);

    dispatch(createProductOfferApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setButtonLoading(false);
        toast.success(obj?.msg);
        history.push(`/productOffer`);
      })
      .catch((obj) => {
        setButtonLoading(false);
      });
  };
  // Handle step changes
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const formattedVariants = useMemo(() => {
    if (!filteredVariants || filteredVariants.length === 0) {
      return [];
    }

    return filteredVariants.map((variant) => ({
      supply_variant_id: variant?.id?.toString() || "",
      attributes: variant?.attributes
        ? typeof variant.attributes === "string"
          ? JSON.parse(variant.attributes)
          : variant.attributes
        : {},
      prices: ["b2c", "pos"].map((appName) => {
        const priceEntry = (selectedproduct?.supplies || [])
          .flatMap((supply) => supply?.supply_prices || [])
          .find(
            (price) =>
              price?.app_name === appName &&
              price?.supply_variant_id === variant?.id
          );

        return {
          app_name: appName,
          actual_price: priceEntry?.selling_price?.toString() || "0",
          offer_price: offerPrices?.[variant.id]?.[appName] || "0",
        };
      }),
    }));
  }, [filteredVariants, offerPrices, selectedproduct]);

  console.log(formattedVariants, "formattedVariants");

  const renderStepComponent = () => {
    switch (activeStep) {
      case 1:
        return (
          <CreateOfferDetails
            searchData={searchData}
            setSearchData={setSearchData}
            showProduct={showProduct}
            productList={productList}
            loading={loading}
            selectedproduct={selectedproduct}
            setSelectedproduct={setSelectedproduct}
            setShowProduct={setShowProduct}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectType={selectType}
            setSelectType={setSelectType}
            formattedVariants={formattedVariants}
            offerPrices={offerPrices}
            setOfferPrices={setOfferPrices}
            selectedVariants={selectedVariants}
            setSelectedVariants={setSelectedVariants}
            allAddress={allAddress}
            setPriceType={setPriceType}
            priceType={priceType}
          />
        );
      case 2:
        return (
          <CreateOfferLocation
            allAddress={allAddress}
            sellerAddressIds={sellerAddressIds}
            setSellerAddressIds={setSellerAddressIds}
            deliveryOptions={deliveryOptions}
            setDeliveryOptions={setDeliveryOptions}
            formattedVariants={formattedVariants}
            validVariants={validVariants}
            uniqueSellerAddressIds={uniqueSellerAddressIds}
          />
        );
      case 3:
        return (
          <CreateOfferPublish
            selectedproduct={selectedproduct}
            formattedVariants={formattedVariants}
            startDate={startDate}
            endDate={endDate}
            selectType={selectType}
            validVariants={validVariants}
            allAddress={allAddress}
            priceType={priceType}
            offerPrices={offerPrices}
          />
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  const getProductList = () => {
    if (!searchData) {
      setProductList([]);
      setShowProduct(false);
      return;
    }

    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "physical",
        app_name: "merchant",
        search: searchData,
        filter_deleted: true,
        lat: 32.5534,
        long: 76.1258,
      },
    };
    setShowProduct(true);
    setLoading(true);
    dispatch(getProductServiceListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setProductList(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };

    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];
        // select default seller address if only one address
        let addressList = obj?.payload;
        setAllAddress(addressList || []);
        if (addressList?.length == 1) {
          // setSellerAddressIds([addressList[0]?.id]);
        }
      })
      .catch((obj) => {});
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    if (searchData.length >= 3 || searchData == "") {
      getProductList();
    }
  }, [searchData]);

  useEffect(() => {
    setSellerAddressIds((prevIds) =>
      prevIds.filter((id) => uniqueSellerAddressIds.includes(id))
    );
  }, [selectedproduct]);

  useEffect(() => {
    if (selectedproduct?.supplies) {
      const initialPrices = {};
      selectedproduct.supplies.forEach((supply) => {
        supply?.supply_variants?.forEach((variant) => {
          const variantId = variant?.id?.toString() || "";
          initialPrices[variantId] = {};
          ["b2c", "pos"].forEach((appName) => {
            const priceObj = supply?.supply_prices?.find(
              (price) => price?.app_name === appName
            );
            initialPrices[variantId][appName] = "";
            // priceObj?.selling_price?.toString() || "0";
          });
        });
      });
      setOfferPrices(initialPrices);
    }
  }, [selectedproduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Product";
  }, []);

  return (
    <div className="productDetailsWrapper createOfferSection createOffBox">
      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <div className="col-md-12">
            <div className="walletStats">
              <div className="createOfferHeader">
                <div className="row align-items-center">
                  <div className="col-10">
                    <h3 className="fontSize20 fw500 txtDarkblue">
                      Create Offer
                    </h3>
                    <p className="fontSize16 fw500 txtDarkblue mb-0">
                      Select one product and offer price wont be less than cost
                      price.
                    </p>
                  </div>
                  <div className="col-2 text-end">
                    <button
                      className="createOfferCloseBtn"
                      onClick={() => history.goBack()}
                    >
                      Close
                      <img
                        src={Images.lightCrossIcon}
                        alt="logo"
                        className="closeCrossIcon ms-1"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="createOfferContainer">
                <div
                  className={
                    activeStep == 3
                      ? "row h-100 justify-content-center"
                      : "row h-100"
                  }
                >
                  <div
                    className={
                      activeStep == 3 ? "col-xl-8 col-lg-10 col-12" : "col-md-8"
                    }
                  >
                    <div className="createOfferLeft">
                      <div className="stepperParent">
                        <Stepper
                          activeStep={activeStep}
                          onStepChange={handleStepChange}
                          steps={[
                            {
                              label: "Product & Pricing ",
                            },
                            { label: "Locations" },
                            { label: "Publish" },
                          ]}
                          disabledColor="#E4EBF1"
                          activeColor="red !important"
                        />
                      </div>

                      <div className="offerDetailBox createOffer_DetailBox">
                        {renderStepComponent()}

                        <div className="createOfferBtnsParent editVariantModal_Footer">
                          <button
                            className="modalDiscardBtn w-auto fontSize14"
                            onClick={handleBack}
                          >
                            <img
                              src={Images.backArrow}
                              alt="logo"
                              className="me-2"
                            />
                            Discard
                          </button>
                          <button
                            className="modalNextBtn modalDiscardBtn w-auto"
                            onClick={handleNext}
                            disabled={buttonLoading}
                          >
                            {buttonLoading && (
                              <span className="spinner-border spinner-border-sm spinnerLight"></span>
                            )}{" "}
                            {activeStep == 3 ? "Publish" : "Next"}
                            <img
                              src={Images.arrowTopRight}
                              alt="logo"
                              className="ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {activeStep !== 3 && (
                    <div className="col-md-4">
                      <div className="createOfferRight">
                        <h3 className="fontSize24 fw500 txtBlue mb-0">
                          Product Preview
                        </h3>
                        {selectedproduct && (
                          <>
                            <div className="productPreviewBox text-center">
                              <img
                                src={selectedproduct?.image}
                                alt="img"
                                className="offerProductImg"
                              />
                              <h4 className="fontSize20 fw500 txtDarkblue mb-0 pt-4">
                                {selectedproduct.name}
                              </h4>

                              <div className="d-flex align-items-center justify-content-center gap-2 pt-3">
                                <span className="greyDot"></span>
                                <h6 className="greyDotLabel mb-0">
                                  SKU{" "}
                                  {
                                    selectedproduct?.supplies[0]
                                      .supply_variants[0]?.sku
                                  }
                                </h6>
                              </div>
                            </div>

                            <div>
                              <h3 className="fontSize16 fw500 txtDarkblue">
                                Product details
                              </h3>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Category
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {selectedproduct?.category?.name}
                                  </h3>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Sub-category
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {selectedproduct?.sub_category?.name}
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    SKU
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {
                                      selectedproduct?.supplies[0]
                                        .supply_variants[0]?.sku
                                    }
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Barcode
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {
                                      selectedproduct?.supplies[0]
                                        .supply_variants[0]?.barcode
                                    }
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt">
                                    Unit Weight
                                  </h3>
                                </div>
                                <div className="col-6">
                                  <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                    {selectedproduct?.weight
                                      ? `${selectedproduct.weight} ${
                                          selectedproduct.weight_unit || ""
                                        }`
                                      : ""}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOffer;
