import React, { useState } from "react";
import Sidebar from "./Sidebar";
import DashboardNavbar from "../../containers/dashboard/DashboardNavbar";
import { selectMerchantStatus } from "../../containers/dashboard/dashboardSlice";
import { useSelector } from "react-redux";
import { selectLoginAuth } from "../../containers/auth/authSlice";

const HideSideBar = [
  '/plans'
]

const SUPPORT_ROUTES = [
  "/payment",
  "/getting-started",
  "/account-setting",
  "/orders-topic",
  "/reports",
  "/staff-and-payroll",
  "/faq"
]
const DashboardLayout = (props) => {
  // console.log(window.location.pathname, "pathname");
  const location = window.location.pathname;

  // console.log(window.location.pathname, "pathname");
  const [hideShowDetails, setHideShowDetails] = useState("");
  const merchantStatus = useSelector(selectMerchantStatus);
  const auth = useSelector(selectLoginAuth);
  let isSuperAdmin=auth?.payload?.user?.user_roles?.some(
    (e) => e?.role?.slug === "admin"
  );


  const planActive = merchantStatus?.payload?.subscription_status;
  const productAdded = merchantStatus?.payload?.product_status;

  const hideShow = (flag) => {
    setHideShowDetails(flag);
  };
  return (
    <main>
      <div className="wrapper">
      { location?.includes(HideSideBar) ? "" : <Sidebar /> }
        {/* <DashboardNavbar hideDetails={hideShow}/> */}
        <div className="rightdashboard_" style={{
           width: ((!planActive || !productAdded)&&!isSuperAdmin) ? "100%" : "",
           marginLeft: ((!planActive || !productAdded)&&!isSuperAdmin) ? "0px" : "",
           height: ((!planActive || !productAdded)&&!isSuperAdmin) ? "100%" : ""
        }}>
          <div className="rightWrapper">
            <div className="container-fluid px-0 px-sm-2">
              <div
                className={
                  window.location.pathname === "/directMessage" ||
                  window.location.pathname === "/inquaries" ||
                  window.location.pathname === "/rfqMessage" ||
                  window.location.pathname === "/productOffer" ||
                  window.location.pathname === "/serviceOffer" ||
                  window.location.pathname === "/allOrder" ||
                  window.location.pathname === "/orderTrack" ||
                  window.location.pathname === "/orderTrack"||
                  window.location.pathname === "/all-bookings" ||
                  window.location.pathname === "/customer" ||
                  window.location.pathname === "/transections"||
                  window.location.pathname === "/consumerProfile"||
                  window.location.pathname === "/userRewardsPoint"||
                  window.location.pathname === "/calendar"||
                  window.location.pathname === "/campaignIndex"||
                  window.location.pathname === "/couponsListing" ||
                  window.location.pathname === "/mySupport" ||
                  window.location.pathname === "/team-members"||
                  window.location.pathname === "/team-members" ||
                  window.location.pathname === "/moresettingIndex" ||
                  window.location.pathname === "/employee-details" ||
                  window.location.pathname === "/users/age-verification" ||
                  window.location.pathname === "/users/document-verification" ||
                   window.location.pathname === "/folder-document"
                    ? "content_areas message"
                    : // : window.location.pathname === "/allOrder" ||
                      //   window.location.pathname === "/orderTrack"
                      // ? "content_areas allOrders"
                      // : window.location.pathname.startsWith("/orderTrack/")
                      // ? "content_areas orderTracking_"
                      "content_areas"
                }
                style={{
                  marginTop: ((!planActive || !productAdded)&&!isSuperAdmin) ? "0px": ""
                }}
              >
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardLayout;
