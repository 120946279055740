export const options1 = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            align: "start",
            labels: {
                boxWidth: 10,
                boxHeight: 10,
                pointStyle: "circle",
                usePointStyle: true,
            },
        },
    },
    scales: {
        y: {
            beginAtZero: true,
        },
    },
};
export const HeaderStatisticsArr=[
    {
        heading:"Total Orders",
        key:"totalCount",
        img:"totalOrdes",
        className:"totalProductBox",
        languageText:"totalOrders"

    },
    {
        heading:"Orders Completed",
        key:"completedCount",
        img:"orderCompleted",
        className:"totalBrandBox ",
        languageText:"orderCompleted"

    },
    {
        heading:"Pending Orders",
        key:"pendingCount",
        img:"orderAnalytics",
        className:"transectionsBox",
        languageText:"pendingOrders"

    },
    {
        heading:"Cancelled Orders",
        key:"cancelCount",
        img:"orderCancelled",
        className:"totalCategoryBox",
        languageText:"cancelledOrders"

    },
    
]