import React, { useEffect, useRef } from "react";
import * as Images from "../../../../utilities/images";
import Loader from "../../../components/UI/Loader/Loader";
import { Dropdown } from "react-bootstrap";
import {
  OfferCard,
  ShowAddress,
  ShowAudio,
  ShowBusinessCard,
  ShowCatalogs,
  ShowChat,
  ShowChat2,
  ShowImages,
} from "./Helper";

const ChatContainer = ({
  loadingChatList,
  currentRecipientDetails,
  userId,
  chatList,
  deleteChat,
  recipientStatus
}) => {
  const chatRef=useRef(null);
  useEffect(()=>{
    console.log("chatRef",chatRef);
  },[chatRef])
  const ChatRenderOptions = (chat, userId,deleteChat) => {
    switch (chat.media_type) {
      case "image":
        return (
          <>
            <ShowImages deleteChat={deleteChat} images={chat?.media_url} userId={userId} chat={chat} />
          </>
        );
      case "offer":
        return (
          <>
            <OfferCard
            deleteChat={deleteChat}
              userId={userId}
              chat={chat}
              offer_details={chat?.offer_details}
            />
          </>
        );
      case "audio":
        return (
          <>
            {chat?.media_url?.map((media, index) => (
              <ShowAudio
              deleteChat={deleteChat}
                key={index}
                userId={userId}
                chat={chat}
                url={media?.filePath}
                chatRef={chatRef}
              />
            ))}
          </>
        );
      case "catalog":
        return (
          <>
            {chat?.catalogFiles?.map((file, indexs) => (
              <ShowCatalogs
              deleteChat={deleteChat}
                file={file}
                userId={userId}
                chat={chat}
                key={indexs}
              />
            ))}
          </>
        );
      case "business_card":
        return (
          <>
            <ShowBusinessCard
            deleteChat={deleteChat}
              business_card={chat?.business_card}
              userId={userId}
              chat={chat}
            />
          </>
        );

      case "shipping_address":
        return (
          <>
            <ShowAddress
            deleteChat={deleteChat}
              shipping_address={chat?.shipping_address}
              userId={userId}
              chat={chat}
            />
          </>
        );

      case "text":
        return (
          <>
            {chat?.sender_id === userId ? (
              <ShowChat2 deleteChat={deleteChat} chat={chat} userId={userId} />
            ) : (
              <ShowChat deleteChat={deleteChat} chat={chat} userId={userId} />
            )}
          </>
        );
    }
  };
  useEffect(()=>{},[recipientStatus])
  return (
    <>
      {loadingChatList && <Loader />}
      {currentRecipientDetails?.participants?.[userId]?.isMuted && (
        <p className="mutedConversation">You have muted this conversation</p>
      )}
      {
        //  !addMultiConsumers&& Object.keys(messageData)?
        Object.keys(chatList).map((key, index) => (
          <>
            <div key={index} className="chatDateBox">{key}</div>
            {chatList?.[key]?.map((chat, indexes) => {
          let chatDetails={...chat,is_seen:recipientStatus?.isActive?true:chat?.is_seen}
             return <div
              key={indexes}
              className={`${
                userId === chat?.sender_id ? "adminChatMess" : ""
                } position-relative`}
                >
                

                {ChatRenderOptions(chatDetails, userId,()=>{deleteChat(chat._id,key)})}
              </div>
              }
            )}
          </>
        ))
      }
    </>
  );
};

export default ChatContainer;