import React from 'react'
import { DeliveryOptionsDetails } from '../../../constants/orders/orders'; 
import * as Images from "../../../../utilities/images";

const DeliveryOptions = ({deliveryOption}) => {
  if(isNaN(deliveryOption))return;  
  const deliveryOptionDetail =DeliveryOptionsDetails[parseInt((deliveryOption),10)-1];
  return <>
    <div>
    <figure className={`deliveryStatusOptions m-0 ${deliveryOptionDetail?.img+"btn"} `}>
                                <img src={Images[deliveryOptionDetail?.img]} alt="store" className={`storeimg me-1`} />
                                <span>{
                                    deliveryOptionDetail?.title
                                    }</span>
                            </figure>
    </div>
  </>
  }

export default DeliveryOptions