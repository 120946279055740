import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import ProductInnerHeader from "./productInnerHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getCountApiAsync,
  getLeastSellingApiAsync,
  getOfferProductApiAsync,
  getRecentProductApiAsync,
  getTopSellingApiAsync,
} from "./productSlice";
import ProductCard from "./productCard";
import moment from "moment-timezone";
import TopSellingProduct from "./topSellingProduct";
import LeastSellingProduct from "./leastSellingProduct";
import OfferProducts from "./offerProducts";
import RecentProduct from "./recentProduct";
import HomeHeader from "../../components/UI/Orders/MainHeader";
import { useTranslation } from "react-i18next";
import { getAddedToInventoryProductsAsync } from "../dashboard/dashboardSlice";

const MyProduct = () => {
  const { t } = useTranslation();
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [filterValue, setFilterValue] = useState("week");
  const [allAddress, setAllAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [countData, setCountData] = useState({});

  const [topSellingProduct, setTopSellingProduct] = useState([]);
  const [leastSellingProduct, setLeastSellingProduct] = useState([]);
  const [offerProduct, setOfferProduct] = useState([]);
  const [recentProduct, setRecentProduct] = useState([]);

  const [topLoading, setTopLoading] = useState(false);
  const [leastLoading, setLeastLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [recentLoading, setRecentLoading] = useState(false);

  const [topLimit, setTopLimit] = useState(5);
  const [leastLimit, setLeastLimit] = useState(5);
  const [offerLimit, setOfferLimit] = useState(5);
  const [recentLimit, setRecentLimit] = useState(5);

  const addressOptions = [
    { value: null, label: "Location :All" }, // Add this for the "No Select" option
    ...allAddress.map((address) => ({
      value: address.id,
      label: address.format_address,
    })),
  ];
  const order = ["Product", "Category", "Sub Category", "Brand"];

  const statisticsMap = {
    Product: Images.totalProductIcon,
    Category: Images.productCategory,
    "Sub Category": Images.productSubcategory,
    Brand: Images.brand,
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {});
  };

  const countDataHandle = (data) => {
    let countData = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // filter: filterValue,
        service_type: "product",
        ...(filterValue && { filter: filterValue})
      },
    };
    if (startDate && endDate) {
      let newData = {
        ...countData.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      countData = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (selectedAddress !== null) {
      let newData = {
        ...countData.postData,
        seller_address_id: Array.isArray(selectedAddress)
          ? selectedAddress.join(",")
          : selectedAddress.toString(),
      };
      countData = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(getCountApiAsync(countData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCountData(obj?.payload?.result);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getTopSellingProduct = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "product",
      // filter: filterValue,
      ...(filterValue && { filter: filterValue})
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedAddress) {
      postData.seller_address_id = Array.isArray(selectedAddress)
        ? selectedAddress.join(",")
        : selectedAddress.toString();
    }

    if (topLimit) {
      postData.limit = topLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setTopLoading(true);
    dispatch(getTopSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setTopLoading(false);
        setTopSellingProduct(obj?.payload?.data);
      })
      .catch((obj) => {
        setTopLoading(false);
      });
  };

  const getLeastSellingProduct = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "product",
      // filter: filterValue,
      ...(filterValue && { filter: filterValue})
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedAddress) {
      postData.seller_address_id = Array.isArray(selectedAddress)
        ? selectedAddress.join(",")
        : selectedAddress.toString();
    }

    if (leastLimit) {
      postData.limit = leastLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setLeastLoading(true);
    dispatch(getLeastSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLeastLoading(false);
        setLeastSellingProduct(obj?.payload?.data);
      })
      .catch((obj) => {
        setLeastLoading(false);
      });
  };

  const getOfferList = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "product",
      // filter: filterValue,
      ...(filterValue && { filter: filterValue}),
      app_name: "merchant",
      // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18"
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedAddress) {
      postData.seller_address_id = Array.isArray(selectedAddress)
        ? selectedAddress.join(",")
        : selectedAddress.toString();
    }

    if (offerLimit) {
      postData.limit = offerLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };
    setOfferLoading(true);
    dispatch(getOfferProductApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOfferLoading(false);
        console.log(obj , "offer")
        setOfferProduct(obj?.payload?.data);
      })
      .catch((obj) => {
        setOfferLoading(false);
      });
  };

  const getRecentList = () => {
    let postData = {
      // seller_id: auth?.payload?.uniqe_id
      //   ? auth?.payload?.uniqe_id
      //   : auth?.payload?.user?.unique_uuid,
      //   service_type: "product",
      // // filter: filterValue,
      // app_name: "merchant",
      // // need_new_releases: true,
      ...(filterValue && { filter: filterValue}),
      need_new_releases:true,
      type: "physical",
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    // if (selectedAddress) {
    //   postData.seller_address_id = Array.isArray(selectedAddress)
    //     ? selectedAddress.join(",")
    //     : selectedAddress.toString();
    // }

    if (recentLimit) {
      postData.limit = recentLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };
    setRecentLoading(true);
    dispatch(getAddedToInventoryProductsAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setRecentLoading(false);
        setRecentProduct(obj?.payload?.data);
      })
      .catch((obj) => {
        setRecentLoading(false);
      });
  };

  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    countDataHandle();
  }, [selectedAddress, filterValue, startDate, endDate]);

  useEffect(
    () => getTopSellingProduct(),
    [topLimit, selectedAddress, filterValue, startDate, endDate]
  );
  useEffect(
    () => getLeastSellingProduct(),
    [leastLimit, selectedAddress, filterValue, startDate, endDate]
  );
  useEffect(
    () => getOfferList(),
    [selectedAddress,  offerLimit , filterValue, startDate, endDate]
  );
  useEffect(
    () => getRecentList(),
    [ recentLimit , filterValue, startDate, endDate]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Product";
  }, []);
  // added common date picker in 
  const handleDateValue = (values) => {
    setFilterValue(null);
    if (values.startDate == values.endDate) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };

  const handleFilterValue = (value) => {
    setEndDate(null);
    setStartDate(null);
    setFilterValue(value);
  };

  const handleSellerAddress = (value) => {
      setSelectedAddress(value);
  };

  return (
    <>
      <DashboardNavbar title={t("productOverview")} backShow={false} />
      <div className="myProductPage homePage">
        {/* common component */}
        {/* <ProductInnerHeader
            subhead="vs. Same day last week"
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            allAddress={allAddress}
            addressOptions={addressOptions}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          /> */}
        <HomeHeader
          filterValue={filterValue}
          handleFilter={handleFilterValue}
          handleDate={handleDateValue}
          seller_id={
            auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid
          }
          tokenData={auth?.payload?.token}
          handleLocation={handleSellerAddress}
          // subhead="vs. Same day last week"
          productHead={true}
          selectText={t('locationAll')}
        />

        <div className="container-fluid p-0">
          <div className="commonMainHeaderParent">
            <div className="row">
              {loading ? (
                <>
                  <div className="col-12">
                    <div className="d-flex align-item-center justify-content-center">
                      <span className="spinner-border spinner-border-md spinnerDark mt-3 mb-3"></span>
                    </div>
                  </div>
                </>
              ) : (
                countData.length > 0 &&
                countData
                  .slice() // Create a copy to avoid mutating the state
                  .sort(
                    (a, b) => order.indexOf(a.title) - order.indexOf(b.title)
                  ) // Sort dynamically
                  .map((item, index) => (
                    <ProductCard
                      key={index}
                      className={`total${item.title.replace(/\s/g, "")}Box`}
                      img={statisticsMap[item.title]}
                      value={item.count}
                      heading={t(`Total ${item.title}`)}
                    />
                  ))
              )}
            </div>
          </div>

          <div className="row mt-3">
            <TopSellingProduct
              topSellingProduct={topSellingProduct}
              topLoading={topLoading}
              setTopLimit={setTopLimit}
            />
            <LeastSellingProduct
              leastSellingProduct={leastSellingProduct}
              leastLoading={leastLoading}
              setLeastLimit={setLeastLimit}
            />
          </div>
          <div className="row mt-3">
            <OfferProducts
              offerProduct={offerProduct}
              offerLoading={offerLoading}
              setOfferLimit={setOfferLimit}
            />
            <RecentProduct
              recentProduct={recentProduct}
              recentLoading={recentLoading}
              setRecentLimit={setRecentLimit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProduct;
