import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSessionHistoryApi } from "./myPosApi";

export const getSessionHistoryAsync = createAsyncThunk('myPos/getSessionHistoryApi', async (data) => {
    const response = await getSessionHistoryApi(data.postData, data.tokenData);
    return response.data;
})


export const myPosSlice = createSlice({
    name: 'myPos',
    initialState: {
        sessionData: null,
        loading: false
    },
    reducers: {
        setSessionData: (state, payload) => {
            state.sessionData = payload?.payload
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(getSessionHistoryAsync.pending, (state) => {
            state.loading = true;
          })
          .addCase(getSessionHistoryAsync.fulfilled, (state, { payload }) => {
            state.sessionData = payload
            state.loading = false;
          })
          .addCase(getSessionHistoryAsync.rejected, (state, { error }) => {
            state.loading = false;
            state.sessionData = null
          })
      }
})



export const { setSessionData } = myPosSlice.actions;
export const selectSessionData = (state) => {
    return state.getSession
} ;

export default myPosSlice.reducer;