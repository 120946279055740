import React, { useState } from "react";
import { clickImg, rightArrow } from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import * as Images from "../../../utilities/images";

const AnnualBilling = (props) => {
    const [planId, setPlanId] = useState(null)
  const handlePlanData = (id) => {
    const data = {
      flag: "paymentCardInfo",
      id: id,
    };
    props?.cardInfoflag(data);
  };

  return (
    <div className="annualBilling">
      <div className="row">
        {props?.data?.prices
          ?.sort((a, b) => a.unit_amount - b.unit_amount)
          .map((val, index) => {
            return (
              <div className="col-lg-4 mb-3 mb-lg-0" key={index}>
                <div
                  className={`${props?.selectedPlan?.length > 0 &&
                    props?.selectedPlan[0].price_id?.id === val?.id
                    ? "active"
                    : ""
                    } ${val?.metadata?.name === "Basic"
                      ? " planCommon_ planCommon_   "
                      : val?.metadata?.name === "Standard"
                        ? " planCommon_ professionEnter"
                        : " planCommon_ bussinessEnter"
                    } ${planId === val?.id ? 'active monthEnter' : ''}`}
                    onClick={() => {
                      setPlanId(val?.id)
                    }}
                >
                  <div>
                    <h4 className="entreText">{val?.metadata?.name}</h4>
                    <p className="orderPara">{val?.nickname}</p>

                    <div className={`appIncludedArea`}>
                      <h6 className="settingText my-2">
                        Apps Included:
                      </h6>
                      <div className="appIncludedValues">
                        <div className="planTickBox">
                          {/* <input
                            type="radio"
                            id={`${val?.id}JOBRB2C`}
                            name={`${val?.id}JOBRB2C`}
                            value="JOBR B2C"
                            className="customRadio"
                            checked={true}
                            disabled
                          /> */}
                          <img
                            src={Images.cyanCircleTick}
                            alt="img"
                            className="img-fluid"
                          />
                          {/* <label htmlFor={`${val?.id}JOBRB2C`} className="">
                            JOBR B2C
                          </label> */}
                          <h4 className="planCyan text-uppercase mb-0">
                            JOBR B2C
                          </h4>
                        </div>
                        {/* <div className="appIncludedInputs">
                        <input
                          type="radio"
                          id={`${val?.id}JOBRWallet`}
                          name={`${val?.id}JOBRWallet`}
                          value="JOBR Wallet"
                          className="customRadio"
                          checked={true}
                          disabled
                        />
                        <label htmlFor={`${val?.id}JOBRWallet`}>
                          JOBR Wallet
                        </label>
                      </div> */}
                        <div className="planTickBox">
                          {/* <input
                            type="radio"
                            id={`${val?.id}JOBRPOS`}
                            name={`${val?.id}JOBRPOS`}
                            value="JOBR POS"
                            className="customRadio"
                            checked={
                              val?.metadata?.name != "Basic" ? true : false
                            }
                            disabled
                          /> */}
                          <img
                            src={val?.metadata?.name != "Basic" ? Images.cyanCircleTick : Images?.lightCircleTick}
                            alt="img"
                            className="img-fluid"
                          />
                          {/* <label htmlFor={`${val?.id}JOBRPOS`}>JOBR POS</label> */}
                          <h4 className="planCyan text-uppercase mb-0">
                            JOBR POS
                          </h4>
                        </div>
                        <div className="planTickBox">
                          {/* <input
                            type="radio"
                            id={`${val?.id}JOBRDriver`}
                            name={`${val?.id}JOBRDriver`}
                            value="JOBRDriver"
                            className="customRadio"
                            checked={
                              val?.metadata?.name != "Basic" ? true : false
                            }
                            disabled
                          /> */}
                          <img
                            src={val?.metadata?.name != "Basic" ? Images.cyanCircleTick : Images?.lightCircleTick}
                            alt="img"
                            className="img-fluid"
                          />
                          <h4 className="planCyan text-uppercase mb-0">
                            JOBR Driver
                          </h4>
                        </div>
                        <div className="planTickBox">
                          {/* <input
                            type="radio"
                            id={`${val?.id}JOBRB2B`}
                            name={`${val?.id}JOBRB2B`}
                            value="JOBRB2B"
                            className="customRadio"
                            checked={
                              val?.metadata?.name === "Premium" ? true : false
                            }
                            disabled
                          /> */}
                          <img
                            src={val?.metadata?.name === "Premium" ? Images.cyanCircleTick : Images?.lightCircleTick}
                            alt="img"
                            className="img-fluid"
                          />
                         <h4 className="planCyan text-uppercase mb-0">
                          JOBR B2B
                        </h4>
                          <br />
                        </div>

                      </div>
                      <hr className="dottedDivide" />
                      <ul className="otherMainus">
                        {/* <ul className="otherMainus"> */}
                        <li className="otherItems" key={index}>
                          {/* <img src={clickImg} className="" alt="" /> */}
                          <img
                            src={Images.simpleCheck}
                            alt="simpleCheck image"
                            className="img-fluid"
                          />
                          <span className="planCyan">Online store</span>
                        </li>
                        <li className="otherItems" key={index}>
                          {/* <img src={clickImg} className="" alt="" /> */}
                          <img
                            src={Images.simpleCheck}
                            alt="simpleCheck image"
                            className="img-fluid"
                          />
                          <span className="planCyan">Shareable products</span>
                        </li>
                        <li className="otherItems" key={index}>
                          {/* <img src={clickImg} className="" alt="" /> */}
                          <img
                            src={Images.simpleCheck}
                            alt="simpleCheck image"
                            className="img-fluid"
                          />
                          <span className="planCyan">Unlimited products</span>
                        </li>
                        {(val?.metadata?.name === "Standard" ||
                          val?.metadata?.name === "Premium") && (
                            <li className="otherItems" key={index}>
                              {/* <img src={clickImg} className="" alt="" /> */}
                              <img
                                src={Images.simpleCheck}
                                alt="simpleCheck image"
                                className="img-fluid"
                              />
                              <span className="planCyan">24/7 support</span>
                            </li>
                          )}
                        {val?.metadata?.name === "Premium" && (
                          <>
                            <li className="otherItems" key={index}>
                              {/* <img src={clickImg} className="" alt="" /> */}
                              <img
                                src={Images.simpleCheck}
                                alt="simpleCheck image"
                                className="img-fluid"
                              />
                              <span className="planCyan">Abandoned cart recovery</span>
                            </li>
                            <li className="otherItems" key={index}>
                              {/* <img src={clickImg} className="" alt="" /> */}
                              <img
                                src={Images.simpleCheck}
                                alt="simpleCheck image"
                                className="img-fluid"
                              />
                              <span className="planCyan">Advanced report builder</span>
                            </li>
                          </>
                        )}
                        {/* </ul> */}
                      </ul>
                    </div>
                  </div>
                  <div className="subscribeRightn my-3 text-center">
                    <span className="subscribeText">${val?.unit_amount / 100}</span>
                    <span className="SubscribePara">/Per Year</span>
                  </div>
                  <button
                    onClick={() => {
                      props?.selectedPlan?.length > 0 &&
                        props?.selectedPlan[0].price_id?.id === val?.id
                        ? void 0
                        : handlePlanData(val?.id);
                    }}
                    className="changeBtn"
                  // disabled={loading}
                  >
                    {/* {loading && val?.id == newSelectedId && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )} */}
                    &nbsp;&nbsp;
                    {props?.selectedPlan?.length > 0 &&
                      props?.selectedPlan[0].price_id?.id === val?.id
                      ? "Active Plan"
                      : "Get Started"}
                    {props?.selectedPlan?.length > 0 &&
                      props?.selectedPlan[0].price_id?.id === val?.id ? (
                      <></>
                    ) : (
                      <img
                        src={Images.ArrowRight}
                        alt="lightCircleTick image"
                        className="img-fluid ms-1"
                      />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AnnualBilling;
