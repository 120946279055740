import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { brandLogo, shops, stepHalf } from "../../../../utilities/images";
import { sendPhoneOtpApiAsync } from "../authSlice";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import * as Images from "../../../../utilities/images.js";

const Verify = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [phoneCode, SetPhoneCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
    if (phoneNumber && phoneNumber.length <= 9) {
      setButtonActive(false);
    } else {
      setButtonActive(true);
    }
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter phone number");
      }
      return;
    } else if (phoneNo && phoneNo.length <= 9) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Phone number length should be of 10 digits"
        );
      }

      return;
    } else if (phoneNo && !phoneCode) {
      toastId.current = toast.error("Please select your phone code");
      return;
    }
    let params = {
      phone_code: `+${phoneCode}`,
      phone_no: phoneNo,
      type: "phone",
    };
    setLoading(true);
    dispatch(sendPhoneOtpApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          if (!obj?.payload?.user_id) {
            toastId.current = toast.success(obj?.msg);
          }
        }
        localStorage.setItem("phone_details", JSON.stringify(obj?.payload));
        localStorage.setItem("phone_info", JSON.stringify(params));
        if (!obj?.payload?.user_id) {
          history.push({
            pathname: "/verifyOtp",
            state: { phoneNo: `${phoneCode}${phoneNo}` }
          });
        } 
        // else if (
        //   obj?.payload?.is_email_verified === false ||
        //   !obj?.payload?.user_email
        // ) {
        //   history.push("/verifyEmail");
        // } 
        // else {
        //   history.push({ pathname: "onboarding", state: JSON.stringify(obj?.payload?.user_profile)})
        // }
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  return (
    <div className="commonLogin">
      <div className="loginForm verifyPhoneNoForm authFormCommonWidth">
        <form
          autoComplete="nope"
        
          onSubmit={(e) => handleSendOtp(e)}
        >
          <div className="authFormHead">
            <h2 className="fontSize30 txtDarkblue mb-0">
              Verify your phone <br /> number.
            </h2>
            <h5 className="fontSize18 txtLightBlue mb-0 pt-3">
              Enter your phone number to get started.
            </h5>
          </div>

          <div className="phone-numbpart">
            <div className="country-plugin">
              <div id="result" className="countryCodePickerParent">
                <PhoneInput
                  country="us"
                  // value={phoneNo}
                  name={generateRandomName()}
                  onlyCountries={['us', 'in']} 
                  autoComplete="new-password"
                  // enableSearch={true}
                  placeholder="Phone no."
                  onChange={(value, data, event, formattedValue) => {
                    onChangePhoneNumber(value, data, event, formattedValue);
                  }}
                />
                <span id="valid-msg" className="hide">
                  ✓ Valid
                </span>
                <span id="error-msg" className="hide"></span>
              </div>
            </div>
          </div>

          <div className="d-flex gap-5 mx-auto">
            <button
              className="forgetPass_BackBtn w-100 d-flex align-items-center justify-content-center"
              onClick={() => {
                history.push("/");
              }}
            >
              <img
                src={Images.leftArrowColored}
                alt="logo"
                className="me-2"
                width={"12px"}
                height={"12px"}
              />
              Back
            </button>
            <button
              className="w-100 loginBtnSign "
              disabled={loading}
              type="submit"
            >
              {loading === true && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Next <img src={Images.upArrow} alt="logo" className="ms-2 mb-1" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Verify;
