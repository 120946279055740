import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { getAnalyticStatDataApiAsync } from '../dashboard/dashboardSlice';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import moment from 'moment-timezone';
import * as Images from "../../../utilities/images";
import AnalyticsHeader from './AnalyticsHeader';
import { addThousandSeparator } from '../../../utilities/helpers';
import PaginationHeader from './PaginationHeader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';


const GrossProfits = () => {
    const { t } = useTranslation(); 
    const dispatch = useDispatch();
    const history = useHistory()
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [analyticsProfitData, setAnalyticsProfitsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [calanderDate,setCalanderDate] = useState(new Date())

    const handelPerPageResult = (e) => {
        setPerPage(e?.value)
    }
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };
      
    const handlePageChange = (code) => {
        if (code === "+") {
            // setPerPage((prev => prev > 0 ? prev + 10 : prev))
            setCurrentPage((prev => prev >= 1 ? prev + 1 : prev))
        } else {
            // setPerPage((prev => prev > 10 ? prev - 10 : prev))
            setCurrentPage((prev => prev > 1 ? prev - 1 : prev))
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Analytics";
    }, []);

    const newUserDataHandle = () => {
        let params = {
            "postData": {
                // is_admin: true,
                filter: filterValue,
                channel: channelFilter,
                page: currentPage,
                limit: perPage,
                // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852"
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid

            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAnalyticStatDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAnalyticsProfitsData(obj?.payload)
                setLoading(false)
                setTotalRows(obj?.payload?.orderData?.total)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        newUserDataHandle()
    }, [filterValue, channelFilter, endDate, currentPage, perPage])

    return (
        <>
            <DashboardNavbar title="Analytics" backShow="/analytics" />
            <div className='analyticGross chartsOuter'>
                <AnalyticsHeader
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onTimeSpanSelect={setFilterValue}
                    calanderDate={calanderDate}
                    setCalanderDate={setCalanderDate}
                    filterValue={filterValue}
                    setChannelFilter={setChannelFilter}
                    channelFilter={channelFilter}
                    header={t("grossProfit")}
                    subhead="vs. Same day last week"
                    onDateChange={handleDateChange}
                    timeSpan={filterValue}
                />
                <div className='grossProfitData'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6  pe-2'>
                            <div className='grossTotalorder'>
                                <img src={Images.totalordericon} className='img-fluid grossImages' alt='totalorderImage' />
                                <p className='groSub mt-3 mb-1'>{t('totalOrders')}</p>
                                <h4 className='groMain'>{addThousandSeparator(analyticsProfitData?.overView?.total_orders) || "0"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6  ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={Images.totalvolumeicon} className='img-fluid grossImages' alt='volumeImage' />
                                <p className='groSub mt-3 mb-1'>{t('totalVolume')}</p>
                                <h4 className='groMain'>${addThousandSeparator(analyticsProfitData?.overView?.transaction ? analyticsProfitData?.overView?.transaction?.toFixed(2) : "0.00")}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={Images.averageOrder} className='img-fluid grossImages' alt='analysisImage' />
                                <p className='groSub mt-3 mb-1'>{t('averageOrderValue')}</p>
                                <h4 className='groMain'>{analyticsProfitData?.overView?.average_value ? `$${addThousandSeparator((analyticsProfitData?.overView?.average_value).toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 '>
                            <div className='grossTotalorder'>
                                <img src={Images.grossgreenIcon} className='img-fluid grossImages' alt='grossprofitimage' />
                                <p className='groSub mt-3 mb-1'>{t('grossProfit')}</p>
                                <h4 className='groMain'>{analyticsProfitData?.overView?.profit_sum ? `$${addThousandSeparator((analyticsProfitData?.overView?.profit_sum).toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <PaginationHeader
                    perPage={perPage}
                    handelPerPageResult={handelPerPageResult}
                    currentPage={currentPage}
                    totalPage={totalRows}
                    setCurrentPage={setCurrentPage}
                    totalLength={analyticsProfitData?.orderData?.data?.length}
                />
                {
                    loading ? (
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                    ) : (
                        <div className="commonTable productTable table-responsive mt-3">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('date')}</th>
                                        <th>{t('totalOrders')}</th>
                                        <th>{t('transactionVolume')}</th>
                                        <th>{t('averageOrderValue')}</th>
                                        <th>{t('totalCost')}</th>
                                        <th>{t('margin')}</th>
                                        <th>{t('grossProfit')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {analyticsProfitData?.orderData?.data?.length > 0 && (
                                        analyticsProfitData?.orderData?.data.map(
                                            (row, idx) => (
                                                <tr>
                                                    <td className="fontSize12 txtLightBlue">
                                                    {currentPage > 1 ? (
                                                        (currentPage - 1) * perPage + idx + 1
                                                        ) : idx + 1}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {moment(row?.order_date).format("MM/DD/YYYY")}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.total_orders}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.transaction).toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.average_value).toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.cost_sum).toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {`${Math.round(row?.margin)}%`}
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        <b>${addThousandSeparator((row?.profit_sum).toFixed(2))}</b>
                                                    </td>
                                                   
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default GrossProfits