import React, { useState } from "react";
import { profileImg1 } from "../../../../utilities/images";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useEffect } from "react";
import { getSinglePosUserAsync } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import reactSelect from "react-select";
import { toast } from "react-toastify";
import { createCustomerApiAsync } from "../../dashboard/dashboardSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddCustomerModal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [data, setData] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");

  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!firstName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your first name");
      }
      return;
    }
    if (!lastName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your last name");
      }
      return;
    }
    if (!email) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter Email Address");
      }
      return;
    }
    if (!phoneCode || !phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter Phone Number");
      }
      return;
    }
    let data = {
      postData: {
        firstname: firstName,
        lastname: lastName,
        email: email,
        full_phone_number: `+${phoneCode + phoneNo}`,
        phone_code: phoneCode,
        phone_no: phoneNo,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(createCustomerApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNo("");
        SetPhoneCode("");
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        history.push("/customer", { customerType: "invited_customers" });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="editModaluser_">
        <div className="row">
          <div className="col-md-12">
            {/* <div className='consumerProfileEdit'>
                            <img src={profileImg1} alt="images" className='editImg_ m-0' />
                        </div> */}
            <form className="form_horizontal row">
              <div className="mb-4 form-group col-md-12">
                <label className="form-label textInner_">First Name</label>
                <input
                  className="customform-control"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                ></input>
              </div>
              <div className="mb-4 form-group col-md-12">
                <label className="form-label textInner_">Last Name</label>
                <input
                  className="customform-control"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                ></input>
              </div>
              <div className="phone-numbpart">
                <div className="country-plugin">
                  <label className="form-label textInner_"> Phone</label>
                  <div id="result" className="countryCodePickerParent">
                    <PhoneInput
                      // enableSearch={true}
                      country="us"
                      onlyCountries={["us", "in"]}
                      value={phoneCode + phoneNo}
                      placeholder="Phone no."
                      onChange={(value, data, event, formattedValue) => {
                        onChangePhoneNumber(value, data, event, formattedValue);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4 form-group col-md-12">
                <label className="form-label textInner_ mb-3">
                  Email Address
                </label>
                <input
                  className="customform-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="email"
                ></input>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="editConsumerModal">
        <div className="consumerBtns_">
          <button
            className="discardBtn"
            onClick={() => {
              props?.close();
            }}
          >
            Discard
          </button>
          <button
            className="saveBtn"
            onClick={(e) => {
              handleSave(e);
            }}
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Save</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCustomerModal;
