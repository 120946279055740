import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import * as Images from "../../../../utilities/images";

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Breaks from './Breaks';
import OverTime from './OverTime';
import Payment from './Payment';
import General from './General';
const PayrollSetting = () => {
    const [payroll, setpayroll] = useState("General")
    const location = useLocation();
  
    // Extract query parameters from location.search
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page'); 
   
      /*
      get prams from page and check
      */
  useEffect(()=>{
      if(page){
        setpayroll("Shipping");
      }
  },[])

    return (
        <div className='payroll_'>
            <DashboardNavbar title="Payroll Settings" backShow={false} />
            <div className='row'>
                <div className='col-md-3'>
                    <div className='orderleft'>
                        {/* <payrollSidebar /> */}
                        <div className="leftsidebarOrder">
                            <ul className='payrollmenu_'>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${payroll === "General" ? "active" : ""}`} onClick={() => { setpayroll("General") }}>
                                        <img className='payroll' src={Images.pickupsetting} /> General
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${payroll === "Payment" ? "active" : ""}`}
                                        onClick={() => { setpayroll("Payment") }}>
                                        <img className='payroll' src={Images.shippingOrder} /> Payment
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${payroll === "OverTime" ? "active" : ""}`}
                                        onClick={() => { setpayroll("OverTime") }}>
                                        <img className='payroll' src={Images.orderring} /> OverTime
                                    </a>
                                </li>
                                <li className='listinner_'>
                                    <a href='#' className={`listinner_a ${payroll === "Breaks" ? "active" : ""}`}
                                        onClick={() => { setpayroll("Breaks") }}>
                                        <img className='payroll' src={Images.detailorder} /> Breaks
                                    </a>
                                </li>

                            </ul>


                        </div>
                    </div>

                </div>
                <div className='col-md-9'>
                    <div className={`position-relative ${payroll === "General" ? 'additionalsetmain_ rightContentorder_': "Payment" ? 'additionalsetmain_ rightContentorder_' : 'rightContentorder_ '}`}>
                        {(payroll === "General") && <General />}
                        {(payroll === "Payment") && <Payment />}
                        {(payroll === "OverTime") && <OverTime />}
                        {(payroll === "Breaks") && <Breaks />}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PayrollSetting