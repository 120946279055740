import { profileImg } from '../../../utilities/images'
import React, { useEffect, useState } from 'react'
import { profileUpload_ } from '../../../utilities/images'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { getAddressApiAsync, selectLoginAuth } from '../auth/authSlice'
import { toast } from 'react-toastify'
import { importFileApiAsync } from '../dashboard/dashboardSlice'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import CustomModal from '../../components/shared/CustomModal'
import file from '../../public/file/Product_Details_mini.xlsx'
import Multiselect from 'multiselect-react-dropdown'
import { useTranslation } from 'react-i18next'

const UploadproductModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const history = useHistory()
  const toastId = React.useRef(null)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false);
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);
  const [allSellerAddressIds, setAllSellerAddressIds] = useState([])
  const auth = useSelector(selectLoginAuth);

  const handleChange = (file) => {
    uploadFile(file)
  };

  const handleSelect = (data) => {
    let allIds = data?.map(v => v?.id)
    setAllAddressIds(allIds);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
    }
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('file', file);

      let params = {
        postData: {
          seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
          "seller_address_ids": allAddressIds?.length > 0 ? allAddressIds.toString(): allSellerAddressIds.toString()
        },
        file: formData,
        tokenData: auth?.payload?.token
      }

      setLoading(true)
      dispatch(importFileApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
          props.close()
          props.previousModalClose()
          setLoading(false)
        }
        )
        .catch((obj) => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Invalid file format please check demo file")
          }
          setLoading(false)
        })
    }
  }


  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token
    }
    setLoading1(true)
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false)
        const uniqueAddress = Object.values(obj.payload.reduce((acc, obj) => {
          if (!acc[obj.format_address]) {
            acc[obj.format_address] = obj;
          }
          return acc;
        }, {}));
        let uniqueAddressIds = uniqueAddress?.map(v => v?.id)
        setAllSellerAddressIds(uniqueAddressIds)
        console.log(uniqueAddress,"unique address")

        setAllAddress(uniqueAddress)
      })
      .catch((obj) => {
        setLoading1(false)
      })
  }
  useEffect(() => {
    getAllAddress()
  }, [])

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener('keydown', handleKeyDown);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {
        loading === true ?
          <div className="pageLoader d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div className='uploadproductMOdal'>
            <div className='productDetailsContainer m-2 ps-3 pe-3'>
              <form className='row costPrice'>
                <div className='form-group w-100 mb-4'>
                  <label className="subheaderHeading mb-2">{t("selectAddress")}
                  </label>
                  <Multiselect
                    className='brandFilter customform-control'
                    displayValue="format_address"
                    options={allAddress}
                    hidePlaceholder={false}
                    placeholder={t('selectAddress')}
                    onSelect={handleSelect}
                    onRemove={handleSelect}
                    showCheckbox

                  />
                </div>
              </form>
            </div>
            <img className='uploadprof mt-5' src={profileUpload_} />
            <h4 className='innerHeadingSky mt-3 mb-2'>{t("uploadFileHere")}</h4>
            <p className='textinner_ mb-4'>{("uploadFileTitlehere")},<br /> {("uploadFileWarning")}</p>
            <button className='file_upload_'>
              <label className="uploadmodalBtn" htmlFor='input-file'>
                {t('upload')} <i className="fas fa-arrow-right"></i>
              </label>
              <input id='input-file' type='file' onChange={(e) => handleChange(e)} />
            </button>
            <h5 className='textinner_ mt-3'>{t('check')} <a href={file} download>{t('demo')}
            </a> {t('fileIno')}</h5>
          </div>
      }
    </>
  )
}

export default UploadproductModal