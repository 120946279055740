import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import CustomModal from "../../../components/shared/CustomModal";
import EditConsumerModal from "./editConsumerModal";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getSingleInvitationPosUserAsync,
  getSinglePosUserAsync,
  selectLoginAuth,
} from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAllOrdersApiAsync,
  getMarketStatusApiAsync,
  getServicesAsync,
} from "../../dashboard/dashboardSlice";
import moment from "moment-timezone";
import { Dropdown } from "react-bootstrap";
import { amountFormat } from "../../bookings/BookingCalendar/GlobalMethods";
import { DELIVERY_MODE } from "../../../constants/bookings/allbooking";
import PaginationHeader from "../../../components/PaginationHeader";
import UsersHeader from "./UsersHeader";
import { useTranslation } from "react-i18next";

const ConsumerProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const auth = useSelector(selectLoginAuth);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const userDatas = location.state;
  const [userData, setUserData] = useState("");
  const [userInvitationData, setInvitationData] = useState("");
  const [key, setKey] = useState(Math.random());
  const [marketStatus, setMarketStatus] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState("");
  const [loading, setLoading] = useState(false);
  const [sellerAddress, setSellerAddress] = useState("")
  const [selectedCustomers, setSelectedCustomer] = useState([])

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const history = useHistory();
  const handleRowClick = (row) => {
    // Change Order Id
    history.push(`/orderReview/${row.id}`);
  };
  const userDetails = history?.location?.state;

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const getAllOrdersHandle = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: currentPage,
        limit: perPage,
        need_returned: false,
        ...(sellerAddress && {
          seller_address_id: sellerAddress
        })
      },
      tokenData: auth?.payload?.token,
    };

    if (servicesId) {
      params = {
        postData: {
          ...params.postData,
          service_id: servicesId,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (userDatas?.user_id) {
      params = {
        postData: {
          ...params.postData,
          user_uid: userDatas?.user_id ? userDatas?.user_id : "null",
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (userDatas?.invitation_id) {
      params = {
        postData: {
          ...params.postData,
          invitation_id: userDatas?.invitation_id,
        },
        tokenData: auth?.payload?.token,
      };
    }

    if (startDate != null) {
      let newData = {
        ...params.postData,
        "date": moment(startDate).format('YYYY-MM-DD'),
      }
      params = {
        tokenData: auth?.payload?.token,
        postData: newData
      }
    }
    setLoading(true);
    dispatch(getAllOrdersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOrderData(obj?.payload);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getSingleUserApiAsyncHandler = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        id: userDatas?.user_details?.id,
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(getSinglePosUserAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setUserData(obj?.payload);
      })
      .catch((obj) => { });
  };

  const getSingleInvitationUserApiAsyncHandler = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        id: userDatas?.invitation_details?.id,
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(getSingleInvitationPosUserAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setInvitationData(obj?.payload);
      })
      .catch((obj) => { });
  };

  const getMarketingStatus = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        user_id: userDatas?.user_details?.id,
      },
    };
    dispatch(getMarketStatusApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setMarketStatus(obj?.payload);
      })
      .catch((obj) => { });
  };

  useEffect(() => {
    getAllOrdersHandle();
    if (!userDatas?.invitation_details) {
      getSingleUserApiAsyncHandler();
    }
    getSingleInvitationUserApiAsyncHandler();
  }, [perPage, servicesId, sellerAddress, startDate, currentPage]);

  useEffect(() => {
    if (userDatas?.user_details?.id) {
      getMarketingStatus();
    }
    getServices();
  }, []);

  return (
    <div className="walletStats h-100">
      <div className="flexBox justify-content-between">
        <div className="flexBox gap-2">
          <img
            src={Images.backArrow}
            alt="img"
            className="cursorPointer"
            onClick={() => {
              history.goBack();
            }}
          />
          <h3 className="fontSize24 fw500 mb-0 txtDarkblue">{t('userProfile')}</h3>
        </div>
        <button
          className="editProfileBtn"
          onClick={() => {
            setModalDetail({
              show: true,
              flag: "editConsumer",
              userData: userData,
            });
            setKey(Math.random());
          }}
        >
          {t("editProfile")}
          <img src={Images.editIcon} alt="img" width={16} height={16} />
        </button>
      </div>
      <div className="mainCatalog">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 ps-0">
              <div className="profileDetails profileUser_ flexBox gap-3">
                <img
                  src={userDetails?.profile_photo ?? Images.userImg_}
                  className="img-fluid consumerUser"
                  alt="consumer iage"
                  style={{
                    borderRadius: "50%"
                  }}
                />
                <div>
                  <h4 className="fontSize24 fw500 txtDarkblue mb-1">
                    {
                      [userDetails?.firstname, userDetails?.lastname]
                        .filter(Boolean)
                        .join(" ")
                    }
                  </h4>
                  <div className="flexBox gap-2">
                    <img
                      src={Images.darkblueLocationIcon}
                      alt="img"
                      className="location"
                    />
                    <h6 className="fontSize14 txtDarkblue mb-0">
                      {
                        [
                          userDetails?.current_address?.city,
                          userDetails?.current_address?.state,
                          userDetails?.current_address?.country,
                          userDetails?.current_address?.zipcode
                        ].filter(Boolean).join(" ,")
                      }
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  <img
                    src={Images.darkbluePhoneIcon}
                    alt="img"
                    className="me-1"
                  />
                  {userDetails?.phone_number}
                </h3>
                <h3 className="fontSize14 txtDarkblue mb-0">
                  <img src={Images.emailLogo} alt="img" className="me-2" />
                  {userDetails?.email || "____"}
                </h3>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-end pe-0">
              <div>
                <button
                  className="rewardsBtn ms-auto"
                  onClick={() => history.push("/userRewardsPoint")}
                >
                  <img src={Images.rewardsImg} alt="img" />
                  {userDetails?.total_pending_points} Points
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="row align-items-center mt-3">

        <UsersHeader
          setSellerAddress={setSellerAddress}
          startDate={startDate}
          setStartDate={setStartDate}
          headerClass="col-xxl-4 col-xl-6"
        />
        <PaginationHeader
          currentPage={currentPage}
          totalPage={totalRows}
          perPage={perPage}
          setCurrentPage={setCurrentPage}
          setPerPage={setPerPage}
          headerClass="col-xxl-8 col-xl-6"
          dataLength={totalRows}
        />
      </div>

      <div className="commonTable consumerTable table-responsive mt-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>
                <div className="productAvailabilityChild d-block">
                  <div className="form-group">
                    <input 
                    onChange={(e) => {
                      let selectedData = []
                      if(e?.target?.checked) {
                        selectedData = orderData?.data?.map((e) => ({id: e?.id, status: true}));
                      }
                      setSelectedCustomer(selectedData)
                    }}
                    type="checkbox" id="id3" />
                    <label htmlFor="id3" className="me-0"></label>
                  </div>
                </div>
              </th>
              <th>#</th>
              <th>{t("invoiceId")}</th>
              <th>{t('date')}</th>
              <th>{t('location')}</th>
              <th className="textLeft">{t('staff')}</th>
              <th>{t('noOfitem')}</th>
              <th>{t('amount')}</th>
              <th>{t('saletype')}</th>
              <th>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="variantsTable_Dropdown"
                  >
                    <img src={Images.moreHorizontalLogo} alt="logo" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                    <Dropdown.Item href="#/action-1">
                      <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                        {t('bulkEditing')}
                      </h3>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.locationLogo} alt="logo" />
                        <h3 className="fontSize14 mb-0">{t('editLocation')}</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.moneyPaperIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">{t('editSalesPricing')}</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.newDeleteIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                          {t('deleteAll')}
                        </h3>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </th>
            </tr>
          </thead>
          <tbody>
            {orderData?.data?.map((item, idx) => {
              const { user_details } = item;
              const fullAddress = [
                user_details?.current_address?.city,
                user_details?.current_address?.state,
                user_details?.current_address?.country,
                user_details?.current_address?.zipcode
              ].filter(Boolean).join(" ,");

              return (
                <tr key={idx} >
                  <td>
                    <div className="productAvailabilityChild d-block">
                      <div className="form-group">
                        <input 
                        checked={selectedCustomers?.find(e => e.id === item?.id)?.status || false}
                        type="checkbox"
                        id={idx}
                        onChange={(e) => {
                          if(e?.target?.checked) {
                            setSelectedCustomer((prev)=> [...prev, {id: item?.id, status: e?.target?.checked}])
                          } else {
                            const filterEd = selectedCustomers.filter(i => i.id !== item?.id);
                            setSelectedCustomer(filterEd)
                          }
                        }}/>
                        <label htmlFor={idx} className="me-0"></label>
                      </div>
                    </div>
                  </td>
                  <td className="fontSize12 txtLightBlue" onClick={() => handleRowClick(item)}>
                    {currentPage > 1 ? (
                      (currentPage - 1) * perPage + idx + 1
                    ) : idx + 1}
                  </td>
                  <td className="aboutProduct fw500">{item?.id}</td>
                  <td className="aboutProduct fw500">
                    {moment(item?.created_at).format("DD-MM-YYYY")}
                  </td>
                  <td className="aboutProduct fw500">
                    {fullAddress || "____"}
                  </td>
                  <td>
                    <div className="productAvailabilityChild align-items-center">
                      <div className="d-flex align-items-center gap-3 ">
                        <img
                          src={
                            item?.delivery_option == 4 &&
                              item?.shipping_details?.image
                              ? item?.shipping_details?.image
                              : (item?.delivery_option == 3 ||
                                item?.delivery_option == 2) &&
                                item?.pos_user_details?.profile_photo
                                ? item?.pos_user_details?.profile_photo
                                : item?.delivery_option == 1 &&
                                  item?.driver_details?.profile_photo
                                  ? item?.driver_details?.profile_photo : Images.userImg_}
                          alt="img"
                          className="userInnerImg"
                        />
                        <div>
                          <h3 className="aboutProduct fw500 mb-1 text-start">
                            {(item?.delivery_option == 4
                              ? item?.shipping_details?.title
                              : item?.delivery_option == 3 ||
                                item?.delivery_option == 2
                                ? item?.pos_user_details?.firstname
                                : item?.driver_details?.firstname) || "___"}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="aboutProduct fw500">{item?.total_items} Items</td>
                  <td className="aboutProduct fw500">
                    {amountFormat(item?.payable_amount)}
                  </td>
                  <td className="aboutProduct fw500">
                    <h3 className="shippingbox" style={{
                      display: "inline-block",
                      padding: "5px 10px",
                      borderRadius: "30px",
                      color:
                        DELIVERY_MODE[item?.delivery_option] ===
                          "Delivery"
                          ? "#7233C2"
                          : DELIVERY_MODE[item?.delivery_option] ===
                            "Shipping"
                            ? "#308CAD"
                            : "#4659B5",
                      backgroundColor:
                        DELIVERY_MODE[item?.delivery_option] ===
                          "Delivery"
                          ? "#F5EDFF"
                          : DELIVERY_MODE[item?.delivery_option] ===
                            "Shipping"
                            ? "#BFEEFF99"
                            : "#E4E6F299",
                    }}>
                      {DELIVERY_MODE[item?.delivery_option]}
                    </h3>
                  </td>
                  <td className="aboutProduct fw500">
                    <img
                      src={Images.webPosArrow}
                      alt="img"
                      className="cursorPointer"
                      onClick={() => handleRowClick(item)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "editConsumer" ? "editConsumerModal" : ""}
        size={modalDetail.flag === "editConsumer" ? "md" : "lg"}
        child={
          modalDetail.flag === "editConsumer" ? (
            <EditConsumerModal
              getSingleUserApiAsyncHandler={() =>
                getSingleUserApiAsyncHandler()
              }
              getSingleInvitationUserApiAsyncHandler={() =>
                getSingleInvitationUserApiAsyncHandler()
              }
              userData={userDetails}
              userInvitationData={userInvitationData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ConsumerProfile;
