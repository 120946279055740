import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import { getIdCardPhoto } from "../dashboard/dashboardApi";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../message/messageSlice";
import VerifyUserModal from "./verifyUserModal";
import CustomModal from "../../components/shared/CustomModal";
import {
  createPosUserApiAsync,
  getRoleApiAsync,
  selectLoginAuth,
} from "../auth/authSlice";
import PhoneInput from "react-phone-input-2";
import CustomLoader from "../../components/shared/CustomLoader";
import { ChromePicker } from "react-color";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { getProductServiceListApiAsync } from "../myProducts/productSlice";
import { dayList, options } from "../../../utilities/helpers";
import TimePicker from "react-time-picker";
import { Dropdown, Form } from "react-bootstrap";
import moment from "moment-timezone";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import VerifyEmailPhone from "./VerifyEmailPhone";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

const CreateUser = () => {
  const { t } = useTranslation();
  let selectedOptionNew = [];
  const toastId = React.useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [paymentCycle, setPaymentCycle] = useState("Select Payment Cycle");
  const [billingCycle, setBillingCycle] = useState("Select Billing Cycle");
  const [employeeType, setEmployeeType] = useState("Select Employee Type");
  const [roleUser, setRoleUser] = useState([]);
  const [active, setActive] = useState(false);
  const [hourlyRate, setHourlyRate] = useState("");
  const [overtimeRate, setOvertimeRate] = useState("");
  const [userData, setUserData] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [document, setDocument] = useState("");
  const [posRole, setPosRole] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [securityPin, setSecurityPin] = useState("");
  const [startMondayTime, setStartMondayTime] = useState("09:00am");
  const [endMondayTime, setEndMondayTime] = useState("23:00pm");
  const [startTuesddayTime, setStartTuesdayTime] = useState("09:00am");
  const [endTuesdayTime, setEndTuesdayTime] = useState("23:00pm");
  const [startWednesdayTime, setStartWednesdayTime] = useState("09:00am");
  const [endWednesdayTime, setEndWednesdayTime] = useState("23:00pm");
  const [startThursdayTime, setStartThursdayTime] = useState("09:00am");
  const [endThursdayTime, setEndThursdayTime] = useState("23:00pm");
  const [startFridayTime, setStartFridayTime] = useState("09:00am");
  const [endFridayTime, setEndFridayTime] = useState("23:00pm");
  const [startSaturdayTime, setStartSaturdayTime] = useState("09:00am");
  const [endSaturdayTime, setEndSaturdayTime] = useState("23:00pm");
  const [startSundayTime, setStartSundayTime] = useState("09:00am");
  const [endSundayTime, setEndSundayTime] = useState("23:00pm");
  const [businessHours, setBusinessHours] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [isPhoneVerified, setIsPhoneVerified] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState("");
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const auth = useSelector(selectLoginAuth);
  const [key, setKey] = useState(Math.random());

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    type: "",
    value: "",
    phone_code: "",
  });
  const [color, setColor] = useState("#FF0000");
  const handleColorChange = (newColor) => {
    if (newColor.hex !== "#FFFFFF") {
      setColor(newColor.hex);
    }
  };

  const sendData = (e) => {
    setUserData(e);
    setIsEmailVerified(e?.user_profiles?.is_email_verified);
    setIsPhoneVerified(e?.user_profiles?.is_phone_verified);
    setFirstName(
      e?.user_profiles?.firstname ? e?.user_profiles?.firstname : ""
    );
    setLastName(e?.user_profiles?.lastname ? e?.user_profiles?.lastname : "");
    setEmail(e?.email ? e?.email : "");
    setPhoneNumber(
      e?.user_profiles?.phone_no ? e?.user_profiles?.phone_no : e?.phoneNumber
    );
    setSecurityPin(
      e?.user_profiles?.security_pin ? e?.user_profiles?.security_pin : ""
    );
    setPhoneCode(
      e?.user_profiles?.phone_code ? e?.user_profiles?.phone_code : e?.codePhone
    );
  };

  console.log(userData, "userdataaaaaaaaaaaaaaaaaaaaa");

  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneCode(phoneCode);
    setPhoneNumber(phoneNumber);
  };

  let imageFile;
  const handleChange = (e) => {
    e.preventDefault();
    imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    // setDataLoading(true)
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setDataLoading(false);
        setDocument(obj?.payload[0]?.filePath);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
      })
      .catch((obj) => {
        setDataLoading(false);
      });
  };

  const submitHandle = (e) => {
    e.preventDefault();
    if (!firstName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your first name");
      }
      return;
    }
    if (!lastName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your last name");
      }
      return;
    }
    if (posRole.length <= 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select atleast one role");
      }
      return;
    }
    if (!securityPin) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter Security Pin");
      }
      return;
    }
    if (!hourlyRate) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter hourly rate");
      }
      return;
    }
    if (!overtimeRate) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter overtime rate");
      }
      return;
    }
    if (paymentCycle == "Select Payment Cycle") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select payment cycle");
      }
      return;
    }
    if (billingCycle == "Select Biling Cycle") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select billing cycle");
      }
      return;
    }
    if (paymentCycle == "Select Employee Type") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select employee type");
      }
      return;
    }
    if (
      (businessHours?.includes("monday") && !startMondayTime) ||
      (businessHours?.includes("tuesday") && !startTuesddayTime) ||
      (businessHours?.includes("wednesday") && !startWednesdayTime) ||
      (businessHours?.includes("thursday") && !startThursdayTime) ||
      (businessHours?.includes("friday") && !startFridayTime) ||
      (businessHours?.includes("saturday") && !startSaturdayTime) ||
      (businessHours?.includes("sunday") && !startSundayTime)
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select correct start time");
      }
      return false;
    }
    if (
      (businessHours?.includes("monday") && !endMondayTime) ||
      (businessHours?.includes("tuesday") && !endTuesdayTime) ||
      (businessHours?.includes("wednesday") && !endWednesdayTime) ||
      (businessHours?.includes("thursday") && !endThursdayTime) ||
      (businessHours?.includes("friday") && !endFridayTime) ||
      (businessHours?.includes("saturday") && !endSaturdayTime) ||
      (businessHours?.includes("sunday") && !endSundayTime)
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select correct end time");
      }
      return false;
    }

    if (color == "#ffffff") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select color other then white");
      }
      return false;
    }

    if (!isPhoneVerified) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please verify your phone");
      }
      return false;
    }
    if (!isEmailVerified) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please verify your email");
      }
      return false;
    }

    let data = {
      postData: {
        firstname: firstName,
        lastname: lastName,
        pos_security_pin: securityPin,
        role_ids: posRole,
        color_code: color,
        is_staff_member: active,
        hourly_rate: hourlyRate,
        overtime_rate: overtimeRate,
        payment_cycle: paymentCycle,
        billing_type: billingCycle,
        employment_type: employeeType,
      },
      tokenData: auth?.payload?.token,
    };
    if (email) {
      data = {
        postData: {
          ...data.postData,
          email: email,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (document) {
      data = {
        postData: {
          ...data.postData,
          profile_photo: document,
        },
        tokenData: auth?.payload?.token,
      };
    }

    if (phoneNumber && phoneNumber.length === 10) {
      data = {
        postData: {
          ...data.postData,
          phone_no: phoneNumber,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (phoneCode) {
      data = {
        postData: {
          ...data.postData,
          phone_code: `+${phoneCode}`,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (serviceId.length > 0) {
      data = {
        postData: {
          ...data.postData,
          product_ids: serviceId,
        },
        tokenData: auth?.payload?.token,
      };
    }

    if (businessHours && businessHours?.length > 0) {
      data = {
        postData: {
          ...data.postData,
          business_working_days: businessHours,
          pos_user_availability: [
            {
              day: "1",
              opening_time: businessHours?.includes("monday")
                ? moment(startMondayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("monday")
                ? moment(endMondayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "2",
              opening_time: businessHours?.includes("tuesday")
                ? moment(startTuesddayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("tuesday")
                ? moment(endTuesdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "3",
              opening_time: businessHours?.includes("wednesday")
                ? moment(startWednesdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("wednesday")
                ? moment(endWednesdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "4",
              opening_time: businessHours?.includes("thursday")
                ? moment(startThursdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("thursday")
                ? moment(endThursdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "5",
              opening_time: businessHours?.includes("friday")
                ? moment(startFridayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("friday")
                ? moment(endFridayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "6",
              opening_time: businessHours?.includes("saturday")
                ? moment(startSaturdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("saturday")
                ? moment(endSaturdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "7",
              opening_time: businessHours?.includes("sunday")
                ? moment(startSundayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("sunday")
                ? moment(endSundayTime, "h:mm a").format("h:mm a")
                : "",
            },
          ],
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(createPosUserApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setFirstName("");
        setLastName("");
        setSecurityPin("");
        setEmail("");
        setPhoneCode("");
        setPhoneNumber("");
        setPosRole("");
        setDocument("");
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        history.push("/posUser");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleOptionValue = (e) => {
    return <>{e}</>;
  };

  const handleSelect = (e) => {
    let allIds = e.map((v) => {
      return v.id;
    });
    setPosRole(allIds);
  };

  useEffect(() => {
    let data = {
      user_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      // page: currentPage,
      // limit: perPage
    };
    dispatch(getRoleApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (obj) {
          // setModalDetail({ show: true, flag: "VerifyUserModal" });
          setKey(Math.random());
          const modifiedData = obj.payload.roles.map((item) => ({
            value: item.name,
            id: item.id,
          }));
          setRoleUser(modifiedData);
        } else {
          history.push(`/createRole`);
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Please Create a role First");
          }
        }
      })
      .catch((obj) => {});
  }, []);

  const serviceListDataHandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "service",
        filter_deleted: true,
        app_name: "merchant",
      },
    };
    setLoading(true);
    dispatch(getProductServiceListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        const modifiedData = obj.payload.data.map((item) => ({
          value: item?.name,
          id: item.id,
        }));
        setServiceData(modifiedData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleOptionValueService = (e) => {
    return <>{e}</>;
  };

  const handleSelectService = (e) => {
    let allIds = e.map((v) => {
      return v.id;
    });
    setServiceId(allIds);
  };

  const handleStartTimeChange = (newStartTime, flag) => {
    if (flag == "monday") {
      setStartMondayTime(newStartTime);
    }
    if (flag == "tuesday") {
      setStartTuesdayTime(newStartTime);
    }
    if (flag == "wednesday") {
      setStartWednesdayTime(newStartTime);
    }
    if (flag == "thursday") {
      setStartThursdayTime(newStartTime);
    }
    if (flag == "friday") {
      setStartFridayTime(newStartTime);
    }
    if (flag == "saturday") {
      setStartSaturdayTime(newStartTime);
    }
    if (flag == "sunday") {
      setStartSundayTime(newStartTime);
    }
  };

  const handleEndTimeChange = (newEndTime, flag) => {
    if (flag == "monday") {
      setEndMondayTime(newEndTime);
    }
    if (flag == "tuesday") {
      setEndTuesdayTime(newEndTime);
    }
    if (flag == "wednesday") {
      setEndWednesdayTime(newEndTime);
    }
    if (flag == "thursday") {
      setEndThursdayTime(newEndTime);
    }
    if (flag == "friday") {
      setEndFridayTime(newEndTime);
    }
    if (flag == "saturday") {
      setEndSaturdayTime(newEndTime);
    }
    if (flag == "sunday") {
      setEndSundayTime(newEndTime);
    }
  };

  const handleCheckboxChange2 = (option, flag) => {
    if (selectedOptions.find((val) => val?.id == option?.id)) {
      selectedOptionNew = selectedOptions.filter(
        (item) => item?.id !== option?.id
      );
      setSelectedOptions(selectedOptionNew);
    } else {
      selectedOptionNew = [...selectedOptions, option];
      setSelectedOptions([...selectedOptions, option]);
    }

    let startTime =
      flag == 1
        ? startMondayTime
        : flag == 2
        ? startTuesddayTime
        : flag == 3
        ? startWednesdayTime
        : flag == 4
        ? startThursdayTime
        : flag == 5
        ? startFridayTime
        : flag == 6
        ? startSaturdayTime
        : startSundayTime;

    let endTime =
      flag == 1
        ? endMondayTime
        : flag == 2
        ? endTuesdayTime
        : flag == 3
        ? endWednesdayTime
        : flag == 4
        ? endThursdayTime
        : flag == 5
        ? endFridayTime
        : flag == 6
        ? endSaturdayTime
        : endSundayTime;

    if (selectedOptionNew.find((day) => day.id == 1)) {
      setStartMondayTime(startTime);
      setEndMondayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 2)) {
      setStartTuesdayTime(startTime);
      setEndTuesdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 3)) {
      setStartWednesdayTime(startTime);
      setEndWednesdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 4)) {
      setStartThursdayTime(startTime);
      setEndThursdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 5)) {
      setStartFridayTime(startTime);
      setEndFridayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 6)) {
      setStartSaturdayTime(startTime);
      setEndSaturdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 7)) {
      setStartSundayTime(startTime);
      setEndSundayTime(endTime);
    }
  };
  const handleCopy = (flag) => {
    setSelectedOptions([]);
  };
  const handleCheckboxChange1 = (event) => {
    var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
    if (event.target.checked) {
      updatedList =
        businessHours?.length > 0
          ? [...businessHours, event.target.value]
          : [event.target.value];
    } else {
      updatedList.splice(businessHours?.indexOf(event.target.value), 1);
    }
    setBusinessHours(updatedList);
  };

  const handleEmailVerified = () => {
    if (!isEmailVerified) {
      setModalDetail({
        show: true,
        flag: "ScheduleModal",
        type: "Email",
        value: email,
      });
      setKey(Math.random());
    }
  };
  const handlePhoneVerified = () => {
    if (!isPhoneVerified) {
      setModalDetail({
        show: true,
        flag: "ScheduleModal",
        type: "Phone",
        value: phoneNumber,
        phone_code: phoneCode,
      });
      setKey(Math.random());
    }
  };

  useEffect(() => {
    serviceListDataHandle();
    setIsEmailVerified(false);
    setIsPhoneVerified(false);
  }, []);

  const paymentCycleSelectOption = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];
  const billingTypeSelectOption = [
    { value: "automatic", label: "Automatic" },
    { value: "manual", label: "Manual" },
  ];
  const employeeTypeSelectOption = [
    { value: "full_time", label: "Full time" },
    { value: "part_time", label: "Part time" },
  ];

  return (
    <>
      <DashboardNavbar title={t("createUser")} show={true} />

      <div className="createUserpos_">
        {/* <div className='topHeader_'>
                    <div className="topStickyHeader_ mb-3">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="mainHeading">
                                    <span>Create User</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>

      {dataLoading === true ? (
        // <CustomLoader />
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <>
          <div className="createUserStaffSection">
            <form
              autoComplete="nope"
              onSubmit={(e) => submitHandle(e)}
              className="addStaffForm"
            >
              <div className="row">
                <div className="text-start">
                  <h3 className="fontSize24 fw500 txtDarkblue  mb-0">
                    {t('personalInfo')}
                  </h3>

                  <div className="uploadStaffImg">
                    <div className="showImg_Parent">
                      {document && (
                        <img src={document} className="staff_Profile_Img" />
                      )}

                      <label htmlFor="file-upload" className="uploadFileLabel">
                        <img
                          src={Images.uploadImgCamera}
                          alt="img"
                          className="uploadStaffCameraImg"
                        />
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/jpeg, image/png"
                        name="profile_image"
                        className="d-none"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form_group pt-0">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      Pos Role
                    </label>

                    <Multiselect
                      className="selectRoles customform-control multiSelect_"
                      displayValue="value"
                      options={roleUser}
                      loading={loading}
                      isObject={true}
                      placeholder={t('selectRoles')}
                      hidePlaceholder={false}
                      selectionLimit={1}
                      onSelect={handleSelect}
                      onRemove={handleSelect}
                      optionValueDecorator={(e) => handleOptionValue(e)}
                      selectedValueDecorator={(e) => handleOptionValue(e)}
                      avoidHighlightFirstOption={true}
                      showCheckbox
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('firstNameSin')}
                    </label>
                    <input
                      type="text"
                      name="text"
                      className="customform-control"
                      placeholder={t('namePlaceholderText')}
                      autoComplete="off"
                      value={firstName}
                      disabled={userData?.user_profiles?.firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('lastNameSin')}
                    </label>
                    <input
                      type="text"
                      name="text"
                      className="customform-control"
                      placeholder={t('lastNamePlaceholderText')}
                      autoComplete="off"
                      value={lastName}
                      disabled={userData?.user_profiles?.lastname}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('posSecurityPin')}
                    </label>
                    <input
                      type="password"
                      className="customform-control"
                      placeholder={t("enterSecurityPin")}
                      name="securityPin"
                      autoComplete="new-password"
                      maxLength={4}
                      value={securityPin}
                      disabled={userData?.user_profiles?.security_pin}
                      onChange={(e) => setSecurityPin(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group  emailbox_">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('email')}
                    </label>
                    <div className="flexBox gap-2">
                      <input
                        type="email"
                        name="email"
                        className="customform-control"
                        placeholder={t('enterEmail')}
                        autoComplete="off"
                        value={email}
                        disabled={userData?.email && isEmailVerified}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setIsEmailVerified(false);
                        }}
                      />
                      <div className="verifyBtnParent">
                        <button
                          type="button"
                          className={
                            isEmailVerified ? "staffPaidBtn" : "staffUnpaidBtn"
                          }
                          onClick={() => handleEmailVerified()}
                        >
                          {isEmailVerified ? t("verified") : t("unverified")}
                        </button>
                        {/* <button type="submit" className='unverified_btn'>Un-verified</button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group phonenumberBox_">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('phoneNumber')}
                    </label>
                    <div id="result" className="flexBox gap-2">
                      <div className="countryCodePickerParent">
                        <PhoneInput
                          className="customform-control p-0"
                          country="us"
                          // value={phoneCode + " " + phoneNumber}
                          value={
                            phoneNumber ? phoneCode + " " + phoneNumber : ""
                          }
                          onlyCountries={["us", "in"]}
                          // enableSearch={true}
                          autoComplete="off"
                          disabled={
                            userData?.user_profiles?.phone_no && isPhoneVerified
                          }
                          placeholder="Enter the Number"
                          onChange={(value, data, event, formattedValue) => {
                            onChangePhoneNumber(
                              value,
                              data,
                              event,
                              formattedValue
                            );
                            setIsPhoneVerified(false);
                          }}
                        />
                      </div>
                      <div className="verifyBtnParent">
                        <button
                          type="button"
                          className={
                            isPhoneVerified ? "staffPaidBtn" : "staffUnpaidBtn"
                          }
                          onClick={() => handlePhoneVerified()}
                        >
                          {isPhoneVerified ? t("verified") : t("unverified")}
                        </button>
                        {/* <button type="submit" className='unverified_btn'>Un-verified</button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('selectService')}
                    </label>

                    <Multiselect
                      className="selectRoles customform-control multiSelect_"
                      displayValue="value"
                      options={serviceData}
                      loading={loading}
                      isObject={true}
                      placeholder={t("selectServices")}
                      hidePlaceholder={false}
                      // selectionLimit={1}
                      onSelect={handleSelectService}
                      onRemove={handleSelectService}
                      optionValueDecorator={(e) => handleOptionValueService(e)}
                      selectedValueDecorator={(e) =>
                        handleOptionValueService(e)
                      }
                      avoidHighlightFirstOption={true}
                      showCheckbox
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('hourlyRate')}
                    </label>
                    <input
                      type="number"
                      name="hourly_Rate"
                      className="customform-control"
                      placeholder={t("enterHourRate")}
                      autoComplete="off"
                      onKeyDown={blockInvalidChar}
                      value={hourlyRate}
                      onChange={(e) => setHourlyRate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group ">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('overtimeRate')}
                    </label>
                    <input
                      type="number"
                      name="overtime_rate"
                      className="customform-control"
                      placeholder={t()}
                      autoComplete="off"
                      onKeyDown={blockInvalidChar}
                      value={overtimeRate}
                      onChange={(e) => setOvertimeRate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group ">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('paymentCycle')}
                    </label>
                    {/* <select
                      className="select customform-control"
                      defaultValue={paymentCycle}
                      name="payment_cycle"
                      id="payment_cycle"
                      onChange={(e) => setPaymentCycle(e.target.value)}
                    >
                      <option value="Select Payment Cycle" disabled>
                        Select Payment Cycle
                      </option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select> */}
                    <div className="reactSelectParent commonSelectParent w-100">
                      <ReactSelect
                        options={paymentCycleSelectOption}
                        isSearchable={true}
                        onChange={(selectedOption) => {
                          setPaymentCycle(selectedOption.value);
                        }}
                        placeholder="Select one"
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            maxWidth: "100% !important",
                            width: "100%",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            paddingLeft: "unset !important",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682"
                              : state.isFocused
                              ? "#f5f6fc"
                              : "#fff",

                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <img
                              src={Images.selectImg}
                              width={24}
                              height={24}
                              alt="drop_icon"
                              className="mt-1"
                            />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group ">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('billingType')}
                    </label>
                    {/* <select
                      className="select customform-control"
                      defaultValue={billingCycle}
                      name="billing_cycle"
                      id="billing_cycle"
                      onChange={(e) => setBillingCycle(e.target.value)}
                    >
                      <option value="Select Billing Cycle" disabled>
                        Select Billing Cycle
                      </option>
                      <option value="automatic">Automatic</option>
                      <option value="manual">Manual</option>
                    </select> */}

                    <div className="reactSelectParent commonSelectParent w-100">
                      <ReactSelect
                        options={billingTypeSelectOption}
                        isSearchable={true}
                        onChange={(selectedOption) => {
                          setBillingCycle(selectedOption.value);
                        }}
                        placeholder={t('selectOne')}
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            maxWidth: "100% !important",
                            width: "100%",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            paddingLeft: "unset !important",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682"
                              : state.isFocused
                              ? "#f5f6fc"
                              : "#fff",

                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <img
                              src={Images.selectImg}
                              width={24}
                              height={24}
                              alt="drop_icon"
                              className="mt-1"
                            />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group ">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('employeeType')}
                    </label>
                    {/* <select
                      className="select customform-control"
                      defaultValue={employeeType}
                      name="employee_type"
                      id="employee_type"
                      onChange={(e) => setEmployeeType(e.target.value)}
                    >
                      <option value="Select Employee Type" disabled>
                        Select Employee Type
                      </option>
                      <option value="full_time">Full time</option>
                      <option value="part_time">Part time</option>
                    </select> */}
                    <div className="reactSelectParent commonSelectParent w-100">
                      <ReactSelect
                        options={employeeTypeSelectOption}
                        isSearchable={true}
                        onChange={(selectedOption) => {
                          setEmployeeType(selectedOption.value);
                        }}
                        placeholder={t('selectOne')}
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            maxWidth: "100% !important",
                            width: "100%",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            paddingLeft: "unset !important",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682"
                              : state.isFocused
                              ? "#f5f6fc"
                              : "#fff",

                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <img
                              src={Images.selectImg}
                              width={24}
                              height={24}
                              alt="drop_icon"
                              className="mt-1"
                            />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group ps-md-0 ps-4">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2"
                      htmlFor="amount"
                    >
                      {t('isStaffMember')}
                    </label>
                    {/* <div className="form-check form-switch"> */}
                    {/* <input
                        type="checkbox"
                        checked={active}
                        onChange={() => {
                          setActive((prev) => !prev);
                        }}
                      /> */}
                    <div className="productSale_Parent p-0 m-0 staffCheckBoxParent">
                      <input
                        type="checkbox"
                        checked={active}
                        onChange={() => {
                          setActive((prev) => !prev);
                        }}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('availablIty')}
                    </label>
                    <div className="checkboxGroup businessChekbox d-block staffAvailability">
                      {dayList.map((item, index) => {
                        return (
                          <div
                            className="row align-items-center mb-3"
                            key={index}
                          >
                            <div className="col-md-3">
                              <div className="flexBox">
                                <input
                                  type="checkbox"
                                  id={item.day}
                                  className="checkBox"
                                  name={item.day}
                                  value={item.day}
                                  onChange={(e) => handleCheckboxChange1(e)}
                                  checked={businessHours?.includes(item.day)}
                                />
                                <label
                                  htmlFor={item.day}
                                  className="checkBoxTxt"
                                ></label>
                                <h3 className="fontSize18 businessUserName mb-0">
                                  {item.value}
                                </h3>
                              </div>
                            </div>
                            <div className="col-md-9">
                              {businessHours?.includes(item.day) && (
                                <>
                                  <div className="flexBox justify-content-md-end justify-content-start gap-md-4 gap-3 flex-wrap mt-md-0 mt-3">
                                    <TimePicker
                                      onChange={(e) =>
                                        handleStartTimeChange(e, item.day)
                                      }
                                      value={
                                        item.id == 1
                                          ? startMondayTime
                                          : item.id == 2
                                          ? startTuesddayTime
                                          : item.id == 3
                                          ? startWednesdayTime
                                          : item.id == 4
                                          ? startThursdayTime
                                          : item.id == 5
                                          ? startFridayTime
                                          : item.id == 6
                                          ? startSaturdayTime
                                          : startSundayTime
                                      }
                                      format="h:mm a"
                                      showLeadingZeros={false}
                                      amPmAriaLabel="Select AM/PM"
                                      className="custom-time-picker customPicker"
                                    />

                                    <TimePicker
                                      onChange={(e) =>
                                        handleEndTimeChange(e, item.day)
                                      }
                                      value={
                                        item.id == 1
                                          ? endMondayTime
                                          : item.id == 2
                                          ? endTuesdayTime
                                          : item.id == 3
                                          ? endWednesdayTime
                                          : item.id == 4
                                          ? endThursdayTime
                                          : item.id == 5
                                          ? endFridayTime
                                          : item.id == 6
                                          ? endSaturdayTime
                                          : endSundayTime
                                      } // Use the 12-hour formatted time string directly
                                      format="h:mm a" // Specify the format as 12-hour with AM/PM
                                      className="custom-time-picker customPicker"
                                    />

                                    <Dropdown
                                      onClick={() => {
                                        handleCopy(item.id);
                                      }}
                                      className="dropdownToggleBtn"
                                    >
                                      <Dropdown.Toggle
                                        variant="primary"
                                        id="dropdown-basic"
                                      >
                                        <label className="iconcopy_ mb-0">
                                          <i className="fa-solid fa-copy"></i>
                                        </label>
                                      </Dropdown.Toggle>
                                      <div className="staffAvailDropdown">
                                        <Dropdown.Menu>
                                          <Form className="formCheckParent">
                                            {options.map((option) => (
                                              <Form.Check
                                                key={option.id}
                                                type="checkbox"
                                                label={option.label}
                                                id={`option-${option.id}`}
                                                checked={selectedOptions.find(
                                                  (val) => val?.id == option?.id
                                                )}
                                                onChange={() =>
                                                  handleCheckboxChange2(
                                                    option,
                                                    item.id
                                                  )
                                                }
                                              />
                                            ))}
                                          </Form>
                                        </Dropdown.Menu>
                                      </div>
                                    </Dropdown>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group">
                    <label
                      className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
                      htmlFor="amount"
                    >
                      {t('selectColor')}:
                    </label>
                    <div className="staffColorPickerParent">
                      <ChromePicker
                        color={color}
                        onChange={handleColorChange}
                      />
                      <div
                        style={{
                          marginTop: "20px",
                          width: "50px",
                          height: "50px",
                          borderRadius: "12px",
                          background: color,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="footerSec mt-4 text-center">
                  {/* <button to='' className='secoundaryBtn me-4'>Cancel</button> */}
                  <button
                    className="commonBlueBtn active w-100"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>{t('create')}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            className={
              modalDetail.flag === "VerifyUserModal"
                ? "commonWidth customContent"
                : ""
            }
            ids={
              modalDetail.flag === "ScheduleModal" ? "verifyUserModal" : ""
            }
            child={
              modalDetail.flag === "VerifyUserModal" ? (
                <VerifyUserModal
                  sendData={sendData}
                  close={() => handleOnCloseModal()}
                />
              ) : modalDetail.flag === "ScheduleModal" ? (
                <VerifyEmailPhone
                  modalDetail={modalDetail}
                  close={() => handleOnCloseModal()}
                  setIsPhoneVerified={setIsPhoneVerified}
                  setIsEmailVerified={setIsEmailVerified}
                  setPhoneNumber={setPhoneNumber}
                  setPhoneCode={setPhoneCode}
                  setEmail={setEmail}
                />
              ) : (
                ""
              )
            }
            header={
              modalDetail.flag === "ScheduleModal" ? (
                <></>
              ) : (
                <>
                  <div className="modalHeader_">
                    {modalDetail.flag === "VerifyUserModal" ? (
                      <div className="flexBox">
                        <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                          {t('verifyNumber')}
                        </h2>
                        <img
                          src={Images.modalCross}
                          alt="img"
                          className="ms-auto cursorPointer"
                          onClick={() => history.push("/myPos")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <button className="closeButton">
                        <i
                          className="las la-times"
                          onClick={() => history.push("/myPos")}
                        ></i>
                      </button> */}
                  </div>
                </>
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </>
      )}
    </>
  );
};

export default CreateUser;
