import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAppointmentStatusApiAsync,
  getAppointemntApiAsync,
  getCategoriesAsync,
  getServicesAsync,
} from "../../dashboard/dashboardSlice";
import { getPosUsersAsync, selectLoginAuth } from "../../auth/authSlice";
import { TimePeriodNav } from "../../../constants/timeperiod.constant";
import DatePickerCustomComponent from "../../../components/UI/Orders/DatePickerCustomComponent";
import ReactDatePicker from "react-datepicker";
import { Link } from "@mui/material";
import { Link as Href } from "react-router-dom";

import * as Images from "../../../../utilities/images";
import {
  addPadStart,
  addThousandSeparator,
  getDateTimeStampConvertor,
  getFormatedDate,
} from "../../../../utilities/helpers";
import {
  AllStatus,
  BookingColumns,
} from "../../../constants/bookings/allbooking";
import { resultSelectOption } from "../../../constants/common.constant";
import SelectMenu from "../../../components/UI/Orders/SelectMenu";
import { Dropdown } from "react-bootstrap";
import DeliveryOptions from "../../../components/UI/Orders/DeliveryOptions";
import DeliveryStatus from "../../../components/UI/Orders/DeliveryStatus";
import BookingStatus from "../../../components/UI/Bookings/BookingStatus";
import { setGetStaffUsers } from "../bookingSlice";
import RequestModal from "../BookingCalendar/Modals/BookingRequestModal";
import AppointmentDetailModal from "../BookingCalendar/Modals/AppointmentDetailModal";
import ConfirmCompleteModal from "../ConfirmCompleteModal";
import CheckedInModal from "../CheckedInModal";
import CheckinModal from "../checkinModal";
import BookingSchedule from "../bookingSchedule";
import CustomModal from "../../../components/shared/CustomModal";
import BookingAppointmentStatus from "../BookingStatusAndModal";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import NotificationButton from "../../../components/NotificationButton";
import { useTranslation } from "react-i18next";
import { addMoreColumnsSettings, selectPagesColumnData } from "../../../pagesColumnReducer";
const AllBookings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [Bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [sellerAddress, setsellerAddress] = useState("");
  const [filterValue, setFilterValue] = useState("week");
  // time period
  const toastId = useRef(null);
const pageColumn=useSelector(selectPagesColumnData);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalBookings, settotalBookings] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsNames, setColumnNames] = useState(JSON.parse(JSON.stringify( pageColumn?.bookings||BookingColumns)));
  const [singleOrderData, setSingleOrderData] = useState(null);
  const [searchKeyword, setsearchKeyword] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [allUserServices, setallUserServices] = useState([
    { value: "", label: "All Services" },
  ]);
  const [staffUserListDetails, setstaffUserListDetails] = useState([]);
  const [isCurrentPage, setisCurrentPage] = useState(false);

  const [allStatus, setAllStatus] = useState(null);
  const [allServices, setAllServices] = useState(null);
  const [allStuffs, setAllStuffs] = useState(null);
  const [totalData, settotalData] = useState(0);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [staffUserList, setstaffUserList] = useState([
    { value: "0", label: "All Staffs" },
  ]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [checkInDetails, setcheckInDetails] = useState({
    title: "",
    subtitle: "",
  });
  const [key, setKey] = useState(Math.random());
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());

    if (flag == "checkedIn") {
      setTimeout(() => {
        handleOnCloseModal();
      }, 3000);
    }
  };
  const [filterOptions, setfilterOptions] = useState([
    {
      title: "All Staffs",
      languageTitle:"allStaffs",
      data: staffUserList,
    },
    {
      title: "All Services",
      languageTitle:"allService",
      data: allUserServices,
    },
    {
      title: "All Status",
      languageTitle:"allStatus",
      data: AllStatus,
    },
  ]);

  const auth = useSelector(selectLoginAuth);
  const resetFilters = () => {
    setBookings(null);
  };
  const changeStatusOfAppointmentHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(changeAppointmentStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        //call appointment method
        getAllBookings();
      })
      .catch((obj) => {
        // setLoading1(false);
        // setLoading2(false);
      });
  };
  const getAllBookings = () => {
    setLoading(true);
    resetFilters();
    const prams = {
      postData: {
        page: currentPage,
        limit: selectPageSize,
        // ...(endDate == null && { filter_by: filterValue }),
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,

        ...(allStatus && { status: allStatus }),

        ...(endDate && {
          start_date: getFormatedDate(startDate, "YYYY-MM-DD"),
          end_date: getFormatedDate(endDate, "YYYY-MM-DD"),
        }),
        ...(allStuffs && allStuffs.length > 0 && { pos_user_id: allStuffs }),
        ...(((!isNaN(allServices))&&allServices) && { category_ids: allServices }),
        ...(searchKeyword.length > 0 && {
          search: searchKeyword,
        }),
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getAppointemntApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setisCurrentPage(false);
        setBookings(res?.payload?.data);
        setTotalPages(res?.payload?.total_pages);
        settotalData(res?.payload?.total||0);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleAppointmentStatus = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(changeAppointmentStatusApiAsync(orderStatus))
      .then((res) => {
        setLoading(false);
        getAllBookings();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleFilterOptions = (value, key) => {
    if (key == "All Staffs") {
      setAllStuffs(value);
    } else if (key == "All Status") setAllStatus(value);
    else setAllServices(value);
  };
  const getUserPosDetails = async () => {
    const prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getPosUsersAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setGetStaffUsers(res));
        setstaffUserListDetails(res?.payload?.pos_staff || []);
        let arr =
          res?.payload?.pos_staff?.map((staff) => {
            return {
              value: staff?.user?.unique_uuid,
              label:
                staff?.user?.user_profiles?.firstname +
                " " +
                staff?.user?.user_profiles?.lastname,
            };
          }) || [];
        let options = filterOptions;
        options[0].data = [{ value: "", label: "All Staffs" }, ...arr];
        setfilterOptions(options);
        setstaffUserList([{ value: "", label: "All Staffs" }, ...arr]);
      })
      .catch((err) => {});
  };
  const getAllServices = async () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // auth?.payload?.uniqe_id
        //   ? auth?.payload?.uniqe_id
        //   : auth?.payload?.user?.unique_uuid,
        service_type: "service",
        main_category:true,
        // is_adult:true,
        // check_product_existance:true,
        page: 1,
        limit: 10,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getCategoriesAsync(params))
      .then(unwrapResult)
      .then((res) => {
        let arr = res?.payload.data?.map((service) => {
          return {
            value: service.id,
            label: service.name,
          };
        });
        let options = filterOptions;
        options[1].data = [{ value: "", label: "All Services" }, ...arr];
        setfilterOptions(options);
        setallUserServices([{ value: "", label: "All Services" }, ...arr]);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getUserPosDetails();
    getAllServices();
  }, []);
  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      if (prev + value <= totalPages && prev + value >= 1){ 
        setisCurrentPage(true);
        return prev + value;
      }
   

      return prev;
    });
  };
  useEffect(() => {
    if (isCurrentPage) getAllBookings();
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    getAllBookings();
  }, [endDate, pageLimit, allServices, allStatus, allStuffs]);
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    let searchTimer = setTimeout(() => {
      getAllBookings();
    }, 1000);
    setsearchTimeout(searchTimer);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKeyword]);
  // Download selected data as CSV
  const downloadCSV = () => {
    if (!Array.isArray(Bookings) || Bookings?.length === 0) {
      showToast("No data available to download.");
      return;
    }

    // Prepare CSV headers and
    const headers = [
      "id",
      "customer",
      "profile_img",
      "address",
      "time",
      "invoice_number",
      "staff",
      "services",
      "status",
      "payment_method",
      "total_amount",
      "location",
      "tips",
      "taxes",
    ];

    const rows = Bookings.map((booking, index) =>{
      let userDetails=booking?.user_details||booking?.invitation_details;
      return [
      index,
      userDetails?.firstname +
        " " +
        userDetails?.lastname || "",
        userDetails?.profile_photo || "",
        userDetails?.current_address?.city ||
        userDetails?.current_address?.state ||
        "",
      booking?.created_at,
      booking?.invoices?.invoice_number || "",
      booking?.pos_user_details?.user?.user_profiles?.firstname +
        " " +
        booking?.pos_user_details?.user?.user_profiles?.lastname || "",
      booking?.product_name || "",
      booking?.status || 1,
      booking?.mode_of_payment || "",
      booking?.price || "",
      userDetails?.current_address?.state || "",
      booking?.tips || "",
      booking?.taxes || "",
    ]});

    // Convert to CSV string
    const csvContent = [
      headers.join(","), // Add headers
      ...rows.map((row) => row.map((value) => `"${value}"`).join(",")), // Add rows
    ].join("\n");

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "selected_product_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  return (
    <>
      <div className="walletStats h-100">
        <div className="productOfferHead_Container">
          {/* header */}
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <h3 className="fontSize24 txtDarkblue fw500 mb-0">
                {t('allBookings')}<span className="countLight_">(
                  
                  {
                    addPadStart(totalData??0)

                    // totalData? String(totalData).padStart(2, '0'):totalData
                    })

                </span>
              </h3>
              <div className="allbokkingcaleder">
                <ReactDatePicker
                  onChange={(dates) => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  customInput={
                    <DatePickerCustomComponent startDate={startDate} />
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-4">
              {/* <ul className="weekNav">
                {TimePeriodNav.map((data, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setFilterValue(data.value);
                      setCurrentPage(1);
                      setEndDate(null);
                      setStartDate(null);
                    }}
                  >
                    <Link
                      className={`weekMenu ${
                        filterValue == data.value ? "active" : ""
                      }`}
                      to="#"
                    >
                      {data.label}
                    </Link>
                  </li>
                ))}
              </ul> */}
              <NotificationButton />
              <div className="input-group search_group">
                <img
                  src={Images.SearchIcon}
                  alt=""
                  className="img-fluid searchIcon"
                />
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setsearchKeyword(e.target.value)}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
              </div>
            </div>
          </div>
          {/* header */}

          {/* menu */}
          <div className="row align-items-center mt-4">
            <div className="col-lg-6">
              <div className=" d-flex align-items-center gap-2">
                {filterOptions.map((filter, index) => (
                  <div className="pe-0">
                    <div className="reactSelectParent offerHeaderSelect gapDiffer_">
                      <SelectMenu
                        options={filter.data}
                        placeholder={filter.languageTitle}
                        onChange={(event) => {
                          handleFilterOptions(event.value, filter.title);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-end gap-3">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {t('showingResults')}
                  </h3>
                  <div className="reactSelectParent offerHeaderSelect">
                    <SelectMenu
                      options={resultSelectOption}
                      placeholder="10"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageLimit(e.value);
                        setSelectPageSize(e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 && "disablePrevbtn"
                      } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleLeft}
                        alt="arrowDoubleRight"
                      />
                    </div>
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 && "disablePrevbtn"
                      } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {(currentPage - 1) * selectPageSize + 1}-
                    {currentPage * pageLimit > totalData
                      ? totalData
                      : currentPage * selectPageSize}{" "}
                    of {totalData}
                  </h6>
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages && "disableNextbtn"
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                    </div>
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages && "disableNextbtn"
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* menu */}
        </div>
        {/* table */}
        <div className="commonTable allBookingTable table-responsive mt-3">
          <table className="w-100 allorderTable_">
            <thead>
              <tr>
                <th>#</th>
                {columnsNames.map((column, index) => (
                  <th key={index} className={!column.isVisible && "d-none"}>
                    {t(column.languageText)}
                  </th>
                ))}

                <th>
                  <div className="d-flex align-items-center gap-1 justify-content-end">
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadCSV();
                      }}
                      src={Images.downloadOrder_}
                      alt="icon"
                    />
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="variantsTable_Dropdown"
                        >
                          <img src={Images.plusIconWithBg} alt="icon" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu   className="variantsDropdownMenu categoryDropdownMenu ">
                          <div className="resetText_" onClick={(e) => {
                              setColumnNames(JSON.parse(JSON.stringify(BookingColumns)))
                              dispatch(addMoreColumnsSettings({
                                key: "bookings",
                                data: BookingColumns
                              }));
                            }}>
                            {t('reset')}
                          </div>
                          {columnsNames.map((column, index) => (
                            <Dropdown.Item
                              onClick={(e) => {
                                e.stopPropagation();
                                if(column.isDefault)return;
                                try {
                                  // Create a shallow copy of the columns array
                                let updatedColumns = [...columnsNames];

                                // Create a new object for the specific column to avoid direct mutation
                                let updatedColumn = { ...updatedColumns[index], isVisible: !column.isVisible };

                                // Update the column in the array
                                updatedColumns[index] = updatedColumn;

                                // Dispatch the updated columns
                                dispatch(addMoreColumnsSettings({
                                  key: "bookings",
                                  data: updatedColumns
                                }));
                                // Update local state
                                setColumnNames([...updatedColumns]);
                                } catch (error) {
                                  
                                }
                              }}
                            >
                              <div className="productAvailabilityChild align-items-center">
                                <div className={`form-group ${column.isDefault?'disable_form':''}`}>
                                  <input
                                    type="checkbox"
                                    checked={column.isVisible}
                                    disabled={column.isDefault}
                                  />
                                  <label
                                    htmlFor="check3"
                                    className="me-0"
                                  ></label>
                                </div>
                                <h3 className="fontSize14 mb-0 mt-1">
                                {t(column.languageText)}
                                </h3>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {/* <img src={Images.moreHorizontalLogo} alt="icon" /> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr className="text-center border-none">
                  {/* Full-width spinner row */}
                  <td colSpan="100%">
                    <div className="d-flex">
                      <span className="spinner-border inner-spin spinner-border-sm"></span>
                    </div>
                  </td>
                </tr>
              ) : Bookings?.length > 0 ? (
                Bookings?.map((booking, index) => {
                  let userDetails=booking?.user_details||booking?.invitation_details;
                  let paymentMode=booking?.payment_method?booking?.payment_method=="apple_pay"?"Apple pay":"klarna":booking?.mode_of_payment;
                return  <tr key={index}>
                    <td className="orderTrackTableTxt">
                      {(currentPage - 1) * pageLimit + index + 1}
                    </td>
                    {/* customer profile */}
                    <td className={`${!columnsNames[0].isVisible && "d-none"}`}>
                      <div className="d-flex align-items-center gap-3 ">
                        <img
                          src={
                            userDetails?.profile_photo ||
                            Images.userImg_
                          }
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="orderTrackTableTxt mb-0 pb-1 text-start">
                            {(userDetails?.firstname||"-") +
                              " " +
                              (userDetails?.lastname||"-")}
                          </h3>
                        {(userDetails?.current_address||booking?.order?.address)&&  <div className="d-flex align-items-center gap-2">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            <h6 className="locationText fontSize12 fw500 mb-0">
                              {
                              (userDetails?.current_address?(userDetails?.current_address?.state||
                                userDetails?.current_address?.city||""):booking?.address?.length>15?booking?.address?.substr(0,15):booking?.order?.address)
                              }
                            </h6>
                          </div>}
                        </div>
                      </div>
                    </td>
                    {/* time */}
                    <td
                      className={`orderTrackTableTxt ${
                        !columnsNames[1].isVisible && "d-none"
                      }`}
                    >
                      <h3 className="orderTrackTableTxt mb-1">
                        {moment(booking?.date).format("DD.MM.YYYY")}
                      </h3>

                      <div className="orderclock_   gap-1">
                        <img src={Images.Clockorder} alt="img" className="" />
                        <h3 className="orderTrackTableTxt mb-0">
                          {booking?.start_time}-
                          {
                            booking?.end_time
                          }
                        </h3>
                      </div>
                    </td>
                    {/* invoice */}
                    <td
                      className={`orderTrackTableTxt ${
                        !columnsNames[2].isVisible && "d-none"
                      }`}
                    >
                      # {
                        booking?.is_returned_order
                        ? booking?.return_detail?.invoices?.invoice_number
                        : booking?.invoices?.invoice_number
                      }
                    </td>
                    {/* staff */}
                    <td
                      className={`orderTrackTableTxt ${
                        !columnsNames[3].isVisible && "d-none"
                      }`}
                    >
                      {booking?.pos_user_details?.user?.user_profiles
                        ?.firstname +
                        " " +
                        booking?.pos_user_details?.user?.user_profiles
                          ?.lastname}
                    </td>
                    {/* services */}
                    <td
                      className={`orderTrackTableTxt ${
                        !columnsNames[4].isVisible && "d-none"
                      }`}
                    >
                      {booking?.product_name}
                    </td>
                    {/* services */}

                    {/* Status */}
                    {/* <td
                      className={`fontSize12 darkblack ${
                        !columnsNames[5].isVisible && "d-none"
                      }`}
                    >
                      <div className='d-flex justify-content-center'>
                      <div className={`deliveryStatus_  ${AllStatus[(isNaN(booking?.status)?5:booking?.status+1)].className+"Status"}`}>
                      <span>
                        {AllStatus[(isNaN(booking?.status)?5:booking?.status+1)].label}
                      </span>
                      </div>
                      </div>
                    </td> */}
                    {/* Payment */}
                    <td
                      className={`fontSize12 darkblack ${
                        !columnsNames[5].isVisible && "d-none"
                      }`}
                    >
                      {" "}
                      {(booking?.order?.paid_by_reward
                ? "reward"
                : booking?.order?.partially_paid_by_reward
                  ?paymentMode + "/" + "reward"
                  : paymentMode)}
                    </td>
                    {/* totalAmount */}
                    <td className={`${!columnsNames[6].isVisible && "d-none"}`}>
                      <div className="deliverTableBx">
                        <img
                          src={Images.moneyImg}
                          alt="location Image"
                          className="img-fluid ms-1"
                        />
                        {/* {
                          booking?.return_id
                          ? Number(booking?.payable_amount).toFixed(2) ?? "00.00"
                          : Number(
                            booking?.payable_amount -
                            booking?.redeemed_reward_points / 100
                            ).toFixed(2) ?? "0"} */}
                        <span className="locateDistance">{
                        // booking?.is_returned_order
                          addThousandSeparator(
                        Number(
                          booking?.order?.payable_amount -
                          booking?.order?.redeemed_reward_points / 100
                        )?.toFixed(2)
                      ) ?? "0"
                          }</span>
                      </div>
                    </td>

                    {/* Location */}
                    <td className={`${!columnsNames[7].isVisible && "d-none"}`}>
                      <span style={{maxWidth:"150px"}} className="text-truncate d-flex">
                        {
                        userDetails?.current_address?(userDetails?.current_address?.state):
                        booking?.order?.address??"---"
                        
                        }
                      </span>
                    </td>
                    {/* Tips */}
                    <td className={`${!columnsNames[8].isVisible && "d-none"}`}>
                      <span>{booking?.tips || 0.0}</span>
                    </td>
                    {/* taxes */}
                    <td
                      className={`${!columnsNames[9].isVisible && "d-none"}`}
                    >
                      <span>{booking?.taxes || 0.0}</span>
                    </td>
                    <td
                    // className={`d-flex justify-content-end ${
                    //   !columnsNames[11].isVisible && "d-none"
                    // }`}
                    >
                      <div className={`flexBox gap-2 justify-content-end `}>
                        <BookingAppointmentStatus
                          appointment={booking}
                          status={booking?.status}
                          handleOpenModal={(value) => handleOpenModal(value)}
                          handlerAppointmentStatus={handleAppointmentStatus}
                          setSingleOrderData={(value) =>
                            setSingleOrderData(value)
                          }
                        />
                      </div>
                    </td>

                    <td className="text-end">
                      {/* <Href
                          to={
                            booking?.status === 3
                              ? `/orderReview/${booking?.status}`
                              : "#"
                          }
                        >
                          {booking?.status == 3 && (
                            <img src={Images.closeTag} alt="logo" />
                          )}
                        </Href> */}
                    </td>
                  </tr>
})
              ) : (
                <tr>
                  <td className="fontSize14 txtLightBlue" colSpan={11}>
                    {t('noRecordfound')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={
            modalDetail.flag === "rescheduleModal"
              ? "rescheduleModal"
              : modalDetail.flag === "confirmCompleteModal"
              ? "ConfirmCompleteModal"
              : modalDetail.flag === "checkInModal"
              ? "checkInModal"
              : modalDetail.flag === "checkedIn"
              ? "successfullyCreatedModal"
              : modalDetail.flag === "appointmentDetailModal"
              ? "appointmentDetailModal"
              : modalDetail.flag === "bookingRequestModal"
              ? "bookingRequestModal"
              : ""
          }
          className={
            modalDetail.flag === "rescheduleModal"
              ? "commonWidth customContent"
              : ""
          }
          child={
            modalDetail.flag === "rescheduleModal" ? (
              <BookingSchedule
                handleAllAppointmentData={() => getAllBookings()}
                changeStatusOfAppointment={(id, status) =>
                  changeStatusOfAppointmentHandle(id, status)
                }
                data={singleOrderData}
                staff={setstaffUserListDetails}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "checkInModal" ? (
              <CheckinModal
                changeStatusOfAppointment={(id, status) =>
                  changeStatusOfAppointmentHandle(id, status)
                }
                data={singleOrderData}
                loading2={false}
                close={() => handleOnCloseModal()}
                open={() => {
                  setcheckInDetails({
                    title: "Checked In",
                    subtitle: "Successfully confirmed",
                  });
                  handleOpenModal("checkedIn");
                }}
              />
            ) : modalDetail.flag === "checkedIn" ? (
              <CheckedInModal
                title={checkInDetails.title}
                subtitle={checkInDetails.subtitle}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "confirmCompleteModal" ? (
              <ConfirmCompleteModal
                changeStatusOfAppointment={(id, status) =>
                  changeStatusOfAppointmentHandle(id, status)
                }
                data={singleOrderData}
                loading2={false}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "appointmentDetailModal" ? (
              <AppointmentDetailModal
                data={singleOrderData}
                // options={options}
                onAppointmentStatusUpdate={(id, status) =>
                  changeStatusOfAppointmentHandle(id, status)
                }
                reschedule={(data) => {
                  setSingleOrderData(data);
                  handleOpenModal("rescheduleModal");
                }}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "bookingRequestModal" ? (
              <RequestModal close={() => handleOnCloseModal()} />
            ) : (
              ""
            )
          }
          header={
            modalDetail.flag === "rescheduleModal" ? (
              <>
                <div className="flexBox gap-3">
                  <img src={Images.customCalendar} alt="img" />
                  <h4 className="fontSize18 txtDarkblue mb-0">
                    Edit Appointment #
                    <span className="fw500">{` # ${singleOrderData?.id}`}</span>
                  </h4>
                </div>
                <img
                  src={Images.modalCross}
                  className="ModalCancel cursorPointer"
                  onClick={handleOnCloseModal}
                />
              </>
            ) : modalDetail.flag === "appointmentDetailModal" ? (
              <>
                <div className="flexBox gap-3">
                  <img src={Images.customCalendar} alt="img" />
                  <h4 className="fontSize24 txtDarkblue mb-0">
                    Appointment Details
                    <span className="fw500">{` # ${singleOrderData?.id}`}</span>
                  </h4>
                </div>
                <img
                  src={Images.modalCross}
                  className="ModalCancel cursorPointer"
                  onClick={handleOnCloseModal}
                />
              </>
            ) : modalDetail.flag === "checkedIn" ? (
              <>
                <div className="flexBox gap-2">
                  <img src={Images.customCalendar} alt="img" />
                  <h4 className="fontSize18 txtDarkblue mb-0">
                    Updated Status
                    <span className="fw500">{` # ${singleOrderData?.id}`}</span>
                  </h4>
                </div>
                <img
                  src={Images.modalCross}
                  className="ModalCancel cursorPointer"
                  onClick={handleOnCloseModal}
                />
              </>
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default AllBookings;
