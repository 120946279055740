import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRewardUsersListAsync,
} from "../../dashboard/dashboardSlice";
import * as Images from "../../../../utilities/images";
import { resultSelectOption } from "../../../constants/common.constant";
import SelectMenu from "../../../components/UI/Orders/SelectMenu";
import { RewardColumns } from "../../../constants/rewards/allrewards.constant";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import { selectLoginAuth } from "../../auth/authSlice";
import { addThousandSeparator, getFormatedDate } from "../../../../utilities/helpers";
import MainHeader from "../../../components/UI/Orders/MainHeader";
import { useTranslation } from "react-i18next";


const AllRewards = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [RewardUserList, setRewardUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sellerAddress, setsellerAddress] = useState("");
  // time period
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsNames, setColumnNames] = useState(RewardColumns);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchTimeOut, setsearchTimeOut] = useState(null);
  const [filterValue, setFilterValue] = useState("week");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [allStatus, setAllStatus] = useState(null);
  const [allServices, setAllServices] = useState(null);
  const [allStuffs, setAllStuffs] = useState(null);
  const [totalData, settotalData] = useState(0);
  const [selectPageSize, setSelectPageSize] = useState(10);

  const auth = useSelector(selectLoginAuth);
  const resetFilters = () => {
    setRewardUserList(null);
  };

  const getAllRewardUserList = () => {
    setLoading(true);
    resetFilters();
    const prams = {
      postData: {
        page: currentPage,
        limit: selectPageSize,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,    
        ...(endDate && {
          start_date: getFormatedDate(startDate, "YYYY-MM-DD"),
          end_date: getFormatedDate(endDate, "YYYY-MM-DD"),
        }),
        search: searchKeyword === '' ? undefined : searchKeyword,
        ...(selectedAddress && { seller_address_id: selectedAddress }),
        ...(filterValue && { filter_by: filterValue}),

      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getRewardUsersListAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setRewardUserList(res?.payload?.data);
        setTotalPages(res?.payload?.total_pages);
        settotalData(res?.payload?.total);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      if (prev + value <= totalPages && prev + value >= 1) return prev + value;
      return prev;
    });
  };

  useEffect(() => {
    getAllRewardUserList();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getAllRewardUserList();
  }, [
    filterValue,
    endDate,
    sellerAddress,
    pageLimit,
    allServices,
    allStatus,
    allStuffs,
    selectedAddress
  ]);

  useEffect(() => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    let searchTimer = setTimeout(() => {
      getAllRewardUserList();
    }, 1000)
    setsearchTimeOut(searchTimer)

    return () => {
      clearTimeout(searchTimer);
    }
  }, [searchKeyword]);
  const handleFilterValue = (value) => {
    setEndDate(null);
    setStartDate(null);
    setFilterValue(value);
  };
  const handleDateValue = (values) => {
    setFilterValue(null);
    if (values.startDate == values.endDate) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };
  const handleSellerAddress = (value) => {
    setSelectedAddress(value);
};
  return (
    <>
      <DashboardNavbar title={t("rewards")} backShow={false} setKeyword={(value) => {
        setSearchKeyword(value)
      }} />
      <div className="walletStats w-100">
        <div className="productOfferHead_Container">
          <MainHeader
           filterValue={filterValue}
           handleFilter={handleFilterValue}
           handleDate={handleDateValue}
           seller_id={
             auth?.payload?.uniqe_id
               ? auth?.payload?.uniqe_id
               : auth?.payload?.user?.unique_uuid
           }
           tokenData={auth?.payload?.token}
           handleLocation={handleSellerAddress}
           
          />

          {/* menu */}
          <div className="row align-items-center mt-5">
            <div className="col-xl-6">
              
            </div>
            <div className="col-xl-6">
              <div className="d-flex justify-content-end gap-3">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fontSize14 txtDarkblue mb-0">
                    {t('showingResult')}
                  </h3>
                  <div className="reactSelectParent offerHeaderSelect">
                    <SelectMenu
                      options={resultSelectOption}
                      placeholder="10"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setPageLimit(e.value);
                        setSelectPageSize(e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`prevPagebtn ${currentPage <= 1 && "disablePrevbtn"
                        } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleLeft}
                        alt="arrowDoubleRight"
                      />
                    </div>
                    <div
                      className={`prevPagebtn ${currentPage <= 1 && "disablePrevbtn"
                        } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0">
                    {(currentPage - 1) * selectPageSize + 1}-
                    {currentPage * pageLimit > totalData
                      ? totalData
                      : currentPage * selectPageSize}{" "}
                    of {totalData}
                  </h6>
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`nextPagebtn ${currentPage === totalPages && "disableNextbtn"
                        }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                    </div>
                    <div
                      className={`nextPagebtn ${currentPage === totalPages && "disableNextbtn"
                        }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* menu */}
        </div>
        {/* table */}
        <div className="commonTable   rewardsTable table-responsive mt-3">
          <table className="w-100 ">
            <thead>
              <tr>
                <th>#</th>
                {columnsNames?.map((column, index) => (
                  <th key={index} className={!column.isVisible && "d-none"}>
                    {t(column.title)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr className="text-center border-none">
                  {/* Full-width spinner row */}
                  <td colSpan="100%">
                    <div className="d-flex">
                      <span className="spinner-border inner-spin spinner-border-sm"></span>
                    </div>
                  </td>
                </tr>
              ) : (
                RewardUserList?.length >0?
                RewardUserList?.map((reward, index) => (
                  <tr key={index}>
                    <td className="orderTrackTableTxt">
                      {(currentPage - 1) * pageLimit + index + 1}
                    </td>
                    {/* customer profile */}
                    <td className={`${!columnsNames[0].isVisible && "d-none"}`}>
                      <div className="d-flex align-items-center gap-3 ">
                        <img
                          src={
                            reward?.user_details?.profile_photo ||
                            Images.userImg_
                          }
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="darkblack mb-0 pb-1 text-start">
                            {reward?.user_details?.firstname +
                              " " +
                              reward?.user_details?.lastname}
                          </h3>
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            <h6 className="locationText fontSize14 fw500 mb-0">
                              {reward?.user_details?.current_address?reward?.user_details?.current_address?.street_address:
                              reward?.address}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='orderTrackTableTxt' >
                      <span>${addThousandSeparator(reward?.total_spent?.toFixed(2))}</span>
                    </td>
                    <td className='orderTrackTableTxt'>
                      <span>{addThousandSeparator(reward?.total_points)}</span>
                    </td>

                    <td className='orderTrackTableTxt'>
                      {addThousandSeparator(reward?.total_orders) || 0}
                    </td>
                  </tr>
                )):<tr>
                  <td colSpan="100%" className='text-center'>{t("noResultFound")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AllRewards;
