import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";

const OfferLocation = (props) => {
  const {
    allAddress,
    sellerAddressIds,
    setSellerAddressIds,
    deliveryOptions,
    setDeliveryOptions,
    formattedVariants,
    validVariants,
    OfferDetails,
    isProduct,
    uniqueSellerAddressIds
  } = props;
// console.log(OfferDetails)
  /**  Check if all formattedVariants have null/empty attributes */
  const allAttributesNull = formattedVariants.every(
    (variant) =>
      !variant.attributes || Object.keys(variant.attributes).length === 0
  );

  // useEffect(() => {
  //   let addressIds;

  //   if (allAttributesNull) {
  //     // Get address_id from formattedVariants
  //     addressIds = formattedVariants.map((variant) => variant.address_id);
  //   } else {
  //     // Extract address_id from validVariants
  //     addressIds = validVariants.map((variant) => variant.address_id);
  //   }
  //   console.log(addressIds,"ids",[...new Set(addressIds.filter(Boolean))])

  //   // Remove duplicates & filter out undefined/null values
  //   setSellerAddressIds([...new Set(addressIds.filter(Boolean))]);
  // }, [formattedVariants, validVariants]);


  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        return [...prevOptions, option];
      } else {
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };
  // useEffect(()=>{
  //   setDeliveryOptions(JSON.parse(OfferDetails?.delivery_options||[]));
  // },[OfferDetails])
  return (
    <section className="offerLocation_Section">
      <h2 className="fontSize24 fw500 txtBlue mb-4">Locations & Channels</h2>
      <div className="outerArea EditVariation_ModalBox ">
        <h3 className="fontSize18 fw500 txtDarkblue">Offer availability</h3>

        <div className="productAvail_Second">
          <div className="d-flex align-items-center">
            <img
              src={Images.physicalStoreIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">Physical store</h3>
                <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            {allAddress
             .filter((store) => uniqueSellerAddressIds.includes(store.id))
            .map((store, idx) => (
              <div key={store.id} className="productAvailabilityChild mt-3">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={`store-${store.id}`}
                    checked={sellerAddressIds.includes(store.id)}
                    onChange={handleStoreToggle(store.id)} 
                    // Correct usage
                  />
                  <label htmlFor={`store-${store.id}`}></label>
                </div>
                <div>
                  <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                    Store {idx + 1}
                    {/* {store.id} - {store.format_address} */}
                  </h3>
                  <p className="fontSize12 txtLightBlue mb-0">
                    {store.street_address}, {store.city}, {store.state}{" "}
                    {store.zipcode}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="jobrMarketBox">
          <div className=" d-flex align-items-center">
            <img
              src={Images.jobrMarketplaceIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  JOBR Marketplace
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Online fulfillment methods
                </h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            
            {
              OfferDetails?.type==="service"?<div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="Reservation"
                  checked={deliveryOptions.includes("reservation")}
                  onChange={handleDeliveryOptionToggle("reservation")}
                />
                <label for="Reservation"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Online Booking</h3>
              </div>
            </div>:<>
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="pickup"
                  checked={deliveryOptions.includes("pickup")}
                  onChange={handleDeliveryOptionToggle("pickup")}
                />
                <label for="pickup"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Pickup</h3>
              </div>
            </div>
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="delivery"
                  checked={deliveryOptions.includes("delivery")}
                  onChange={handleDeliveryOptionToggle("delivery")}
                />
                <label for="delivery"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Delivery</h3>
              </div>
            </div>
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="shipping"
                  checked={deliveryOptions.includes("shipping")}
                  onChange={handleDeliveryOptionToggle("shipping")}
                />
                <label for="shipping"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Shipping</h3>
              </div>
            </div>
            </>
            }
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default OfferLocation;
