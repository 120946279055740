import React, { forwardRef, useEffect, useRef } from "react";
import { modalCancel } from "../../../utilities/images";
import { useState } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import {
  getAllOrdersTransTypeAsync,
  getOrderDetailsAsync,
  getSellerAddressesApiAsync,
} from "../dashboard/dashboardSlice";
import CustomModal from "../../components/shared/CustomModal";
import WalletPurhaseModal from "./walletPurhaseModal";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import { Dropdown } from "react-bootstrap";
import ReactSelect from "react-select";
import {
  AllStatus,
  filterOptions,
  TransactionsColoums,
} from "../../constants/orders/orders";
import useDebounce, { addPadStart, addThousandSeparator } from "../../../utilities/helpers";
import PaginationHeader from "../../components/PaginationHeader";
import ReactDatePicker from "react-datepicker";
import SelectMenu from "../../components/UI/Orders/SelectMenu";
import { toast } from "react-toastify";
import NotificationButton from "../../components/NotificationButton";
import { useTranslation } from "react-i18next";

const renderOrderStatusButtons = (status, isReturned, deliveryOption) => {
  switch (status) {
    case 0: //Review
      return <button className="transDeliver transReview">{"Review"}</button>;
    case 1: //Accepted
      return <button className="transDeliver transAccept">{"Accepted"}</button>;
    case 2: //Prepare
      return <button className="transDeliver transPrepare">{"Prepare"}</button>;
    case 3: //Ready Pickup
      return (
        <button className="transDeliver transpickReady">
          {"Ready For Pickup"}
        </button>
      );
    case 4: //Pickup
      return <button className="transDeliver transPickup">{"Pickup"}</button>;
    case 5: //Delivered
      return (
        // <button >
        isReturned ? (
          <button className="transDeliver transcancel">{"Returned"}</button>
        ) : deliveryOption == 3 ? (
          <button className="transDeliver">{"Completed"}</button>
        ) : (
          <button className="transDeliver">{"Delivered"}</button>
        )

        // </button>
      );
    case 6: //Picked by Customer
      return (
        <button className="transDeliver transPickCustmr">
          {"Pickup By Customer"}
        </button>
      );
    case 7: //Cancelled
      return (
        <button className="transDeliver transcancel">{"Cancelled"}</button>
      );
    case 8: //Rejected
      return <button className="transDeliver transReject">{"Rejected"}</button>;
    case 9: //Refunded
      return <button className="transDeliver transRefund">{"Refunded"}</button>;
    default:
      return "";
  }
};

const Transections = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const auth = useSelector(selectLoginAuth);
  const [sellerAddressesDetails, setsellerAddressesDetails] = useState([]);
  const queryParams = new URLSearchParams(history?.location?.search);
  const [tableData, setTableData] = useState(
    queryParams.get("transType") || "all"
  );
  const query = new URLSearchParams(window.location.search);

  const handleToggleData = (status) => {
    setTableData(status);
  };
  const [columnsNames, setColumnNames] = useState(TransactionsColoums);
  const [startDate, setStartDate] = useState(
    location?.state?.from === "analytics" ? location?.state?.date : null
  );
  const [endDate, setEndDate] = useState(
    location?.state?.from === "analytics" ? location?.state?.date : null
  );

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [key, setKey] = useState(Math.random());
  const [orderDetails, setOrderDetails] = useState([]);
  const [transactionType, setTransactionType] = useState("all");
  const hasLoggedOnceRef = useRef(false);
  const [rowData, setRowData] = useState({});
  const [transCount, setTranscount] = useState();
  const [filter, setFilter] = useState(query.get("filter") || "week");
  const [sellerAddressId, setSellerAddressId] = useState("");
  const [orderType, setOrderType] = useState("");
  const [statusType, setStatusType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [selectedCustomers, setSelectedCustomer] = useState([]);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleRowClick = (row) => {
    setRowData(row);
    history.push(`/orderReview/${row?.id}`);
  };

  const getOrderDetailsAsyncHandler = () => {
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        page: currentPage,
        limit: perPage,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        transaction_type: tableData,
        ...(statusType !== "" && { status: statusType }),
        ...(orderType !== "" && { delivery_option: orderType }),
        search: keyword || undefined,
        ...(startDate !== null && {
          date: moment(startDate).format("YYYY-MM-DD"),
        }),
        ...(filter && {
          filter_by: filter
        }),
        ...(sellerAddressId && { seller_address_id: sellerAddressId }),
      },
    };

    setLoading(true);
    dispatch(getOrderDetailsAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOrderDetails(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setTotalPages(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getTransactionTypeCount = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id,
        ...(filter && {
          filter: filter || "week",
        }),
        ...(startDate !== null && {
          date: moment(startDate).format("YYYY-MM-DD"),
        }),
        ...(sellerAddressId && { seller_address_id: sellerAddressId }),
      },
    };

    dispatch(getAllOrdersTransTypeAsync(data))
      .then(unwrapResult)
      .then((res) => {
        setTranscount(res?.payload);
      })
      .catch((e) => {
      });
  };

  useEffect(() => {
    getTransactionTypeCount();
  }, [startDate, endDate, sellerAddressId]);

  const transactionTypeNav = [
    {
      label: "All",
      languageTitle:"all",
      count: transCount?.[0]?.count || 0,
      mode_of_payment: transCount?.[0]?.mode_of_payment || 0,
    },
    {
      label: "Cash",
      languageTitle:"cash",
      count: transCount?.[3]?.count || 0,
      mode_of_payment: transCount?.[3]?.mode_of_payment || 0,
    },
    {
      label: "Card",
      languageTitle:"card",
      count: transCount?.[2]?.count || 0,
      mode_of_payment: transCount?.[2]?.mode_of_payment || 0,
    },
    {
      label: "Reward",
      languageTitle:"reward",
      count: transCount?.[4]?.count || 0,
      mode_of_payment: transCount?.[4]?.mode_of_payment || 0,
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const transType = params.get("transType");
    if (transType && !hasLoggedOnceRef.current) {
      setTransactionType(transType);
      hasLoggedOnceRef.current = true;
    }
    getOrderDetailsAsyncHandler();
  }, [
    startDate,
    perPage,
    currentPage,
    transactionType,
    tableData,
    sellerAddressId,
    statusType,
    paymentType,
    orderType,
  ]);
  // search debounce effect
  const searchDebounce = useDebounce(keyword, 900);

  useEffect(() => {
    if (searchDebounce == "" || searchDebounce?.length > 2) {
      getOrderDetailsAsyncHandler();
    }
  }, [searchDebounce]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Transactions";
  }, []);

  const getSellerAddressDetails = async () => {
    const prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getSellerAddressesApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        if (res?.payload) {
          let addreses = res?.payload?.map((address) => ({
            value: address?.id,
            label: address?.format_address,
          }));
          setsellerAddressesDetails([
            { value: null, label: "All locations" },
            ...addreses,
          ]);
        }
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getSellerAddressDetails();
  }, []);

  const resetFilters = () => {
    setOrderDetails(null);
    setCurrentPage(0);
  };

  const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => {
    const startDate = moment(value).format("DD MMMM YYYY");
    return (
      <div className="date-picker-container">
        <p className="date-text" onClick={onClick}>
          {`${startDate}`}
        </p>
      </div>
    );
  });
  const TransactionTypeText = (option) => {
    switch (Number(option)) {
      case 1:
        return t("delivery");
      case 2:
        return t("reservation");
      case 3:
        return t("inStore");
      case 4:
        return t("shipping");
      default:
        return t("unknown"); // Handle unexpected values
    }
  };
  
  const renderTransactionType = {
    //Delivery
    1: (
      <>
        <figure className="deliverBtn_">
          <img
            src={Images.driverImage}
            alt="store"
            className="shipImg me-1 img-fluid"
          />
          <span>{t("delivery")}</span>
        </figure>
      </>
    ),
    //Reservation
    2: (
      <>
        <figure className="ReserveBtn">
          <img
            src={Images.reserveImage}
            alt="store"
            className="shipImg me-1 img-fluid reserveImg"
          />
          <span>{t("reservation")}</span>
        </figure>
      </>
    ),
    //In-Store
    3: (
      <>
        <figure className="inStoreBtn">
          <img src={Images.walkinBox} alt="store" className="storeimg me-1" />
          <span>{t("inStore")}</span>
        </figure>
      </>
    ),
    //Shipping
    4: (
      <>
        <figure className="onlineBtn">
          <img src={Images.shippingBox} alt="store" className="shipImg me-1 img-fluid" />
          <span>{t("shipping")}</span>
        </figure>
      </>
    ),
  };

  const getOrderStatus = (item) => {
    if (item?.is_returned_order) {
      return "Returned"
    }
    if (item?.delivery_option == 3 && item?.status == 5) {
      return "Completed"
    }
    const status = AllStatus.find(e => e.value === String(item?.status)).label;
    return status
  }

  const handleFilterOptions = (value, key) => {
    if (key == "All Orders") {
      setOrderType(value);
    } else if (key == "All Status") setStatusType(value);
    else setTableData(value);
  };

  // Download selected data as CSV
  const downloadCSV = () => {
    if (!Array.isArray(orderDetails) || orderDetails?.length === 0) {
      toast.error("No data available to download.");
      return;
    }

    // Prepare CSV headers and
    const headers = columnsNames?.map(e => e.title);
    // add id coloum to the headers array
    headers.unshift("id")

    const rows = orderDetails.map((item) => [
      item?.id,
      moment(item?.created_at).format("HH:MM:ss"),
      item?.return_detail?.invoices?.id || item?.invoices?.id || "",
      item.created_at
        ? moment(item.created_at).format("h:mm A")
        : t("dateNotFound"),
      
      TransactionTypeText(item?.delivery_option),
      item?.paid_by_reward
        ? "Reward"
        : item?.partially_paid_by_reward
          ? item?.mode_of_payment + "/" + "reward"
          : item?.mode_of_payment || "",
      addThousandSeparator(
        Number(
          item?.payable_amount -
          item?.redeemed_reward_points / 100
        )?.toFixed(2)
      ) || "",
      addThousandSeparator(
        item?.refunded_amount?.toFixed(2)
      ) || "",
      getOrderStatus(item)
    ]);
    // Convert to CSV string
    const csvContent = [
      headers.join(","), // Add headers
      ...rows.map((row) => row.map((value) => `"${value}"`).join(",")), // Add rows
    ].join("\n");

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "selected_transaction_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <>
      <div className="transections chartsOuter mt-0">
        <div className="d-flex align-items-center justify-content-between">
          <div className="transactionhead_">
            <h3 className="fontSize24 txtDarkblue fw500 mb-0">
              {t('totalTransactions')}{" "}
              <span className="skyTextinn_" style={{
                fontSize: "24px"
              }}>
                ( $
                {addThousandSeparator(
                  transCount?.[0]?.total_sale_amount?.toFixed(2)
                )}
                )
              </span>
            </h3>
            <div className="bookingLeftTwo">
              <ReactDatePicker
                onChange={(date) => {
                  setStartDate(date);
                }}
                selected={startDate || new Date()}
                startDate={startDate}
                maxDate={new Date()}
                customInput={<DatePickerCustomComponent />}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-4">
            <NotificationButton />
            <div className="input-group search_group">
              <img
                src={Images.SearchIcon}
                alt="img"
                className="img-fluid searchIcon"
              />
              <input value={keyword} onChange={(e) => setKeyword(e?.target?.value)} type="text" className="form-control" />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <div className="d-flex align-items-center commonfilterHead_ ">
              {filterOptions.map((filter, index) => (
                <div className="reactSelectParent offerHeaderSelect gapDiffer_" key={index}>
                  <SelectMenu
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "156px",
                        maxWidth: "156px !important",
                      }),
                    }}
                    options={filter.data}
                    placeholder={t(filter.languageTitle)}
                    onChange={(event) => {
                      handleFilterOptions(event.value, filter.title);
                    }}
                  />
                </div>
              ))}
              <div className="reactSelectParent offerHeaderSelect gapDiffer_">
                <ReactSelect
                  placeholder="All Location"
                  classNamePrefix="react-select"
                  options={sellerAddressesDetails}
                  onChange={(selected) => setSellerAddressId(selected?.value)}
                  className="react-select-container selectwidth_"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                      color: state.isSelected ? "#fff" : "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.blueDownArrow}
                        width={24}
                        height={24}
                        alt="drop_icon"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-xl-5 col-xxl-6">
            <div className="d-flex align-items-center gap-2">
              {transactionTypeNav.map((item, idx) => {
                return (
                  <button
                    key={idx}
                    className={`activeProductBtn ${tableData === item?.mode_of_payment ? "active" : ""
                      }`}
                    onClick={() => handleToggleData(item?.mode_of_payment)}
                  >
                    {t(item?.languageTitle)}
                    <span className="activeProductofferItem">
                      ({addPadStart(item?.count)})
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
          <PaginationHeader
            currentPage={currentPage}
            totalPage={totalPages}
            perPage={perPage}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            headerClass="col-xl-7 col-xxl-6"
            dataLength={totalPages}
          />
        </div>

        <div className="commonTable consumerTable table-responsive mt-3">
          <table className="w-100">
            <thead>
              <tr>
                <th>
                  <div className="productAvailabilityChild d-block">
                    <div className="form-group">
                      <input
                      onChange={(e) => {
                        let selectData =[];
                        if(e?.target?.checked) {
                          selectData = orderDetails?.map(item => ({id: item?.id, status: true}))
                        } 
                        setSelectedCustomer(selectData)
                      }}
                      type="checkbox" id="id3" />
                      <label htmlFor="id3" className="me-0"></label>
                    </div>
                  </div>
                </th>
                <th>#</th>
                {columnsNames.map((column, index) => (
                  <th key={index} className={!column.isVisible && "d-none"}>
                    {t(column.languageText)}
                  </th>
                ))}

                <th>
                  <div className="d-flex align-items-center gap-1 justify-content-end" >
                    <img src={Images.downloadOrder_} alt="icon" onClick={downloadCSV} />
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="variantsTable_Dropdown"
                        >
                          <img src={Images.plusIconWithBg} alt="icon" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                          <div className="resetText_" onClick={resetFilters}>
                            {t('reset')}
                          </div>
                          {columnsNames.map((column, index) => (
                            <Dropdown.Item
                              onClick={() => {
                                let columns = columnsNames;
                                columns[index].isVisible = !column.isVisible;
                                setColumnNames([...columns]);
                              }}
                            >
                              <div className="productAvailabilityChild align-items-center">
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    checked={column.isVisible}
                                  />
                                  <label
                                    htmlFor="check3"
                                    className="me-0"
                                  ></label>
                                </div>
                                <h3 className="fontSize14 mb-0 mt-1">
                                  {t(column.languageText)}
                                </h3>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <img src={Images.moreHorizontalLogo} alt="icon" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {orderDetails?.length > 0 &&
                orderDetails.map((item, idx) => (
                  <tr key={idx}  style={{
                    cursor: "pointer"
                  }}>
                    <td>
                      <div className="productAvailabilityChild d-block">
                        <div className="form-group">
                          <input
                          checked={selectedCustomers?.find(e => e.id === item?.id)?.status || false}
                          onChange={(e) => {                     
                            if(e?.target?.checked) {
                              setSelectedCustomer((prev) => [...prev, { id: item?.id, status: e?.target?.checked}])
                            } else {
                              setSelectedCustomer(selectedCustomers?.filter(i => i?.id !== item?.id))
                            }
                          }}
                           type="checkbox"
                           id={idx} />
                          <label htmlFor={idx} className="me-0"></label>
                        </div>
                      </div>
                    </td>
                    <React.Fragment onClick={() => handleRowClick(item)}>
                    <td className="fontSize12 txtLightBlue">
                      {currentPage > 1
                        ? (currentPage - 1) * perPage + idx + 1
                        : idx + 1}
                    </td>
                    <td
                      className={`fontSize12 txtLightBlue ${!columnsNames[0]?.isVisible ? "d-none" : ""
                        }`}
                    >
                      {item?.created_at
                        ? moment(item.created_at).format("ll")
                        : "Date Not Found"}
                    </td>
                    <td
                      className={`aboutProduct fw500 ${!columnsNames[2]?.isVisible ? "d-none" : ""
                        }`}
                    >
                      {item?.is_returned_order
                        ? item?.return_detail?.invoices?.invoice_number
                        : item?.invoices?.invoice_number}
                    </td>
                    <td
                      className={`aboutProduct fw500 ${!columnsNames[1]?.isVisible ? "d-none" : ""
                        }`}
                    >
                      {item.created_at
                        ? moment(item.created_at).format("h:mm A")
                        : t("dateNotFound")}
                    </td>
                    <td
                      className={`aboutProduct fw500 ${!columnsNames[3]?.isVisible ? "d-none" : ""
                        }`}
                    >
                      <span className="typeOnline_">
                        {renderTransactionType[item?.delivery_option]}
                      </span>

                    </td>
                    <td
                      className={`aboutProduct fw500 ${!columnsNames[4]?.isVisible ? "d-none" : ""
                        }`}
                      style={{
                        textTransform: "capitalize"
                      }}
                    >
                      {item?.paid_by_reward
                        ? "Reward"
                        : item?.partially_paid_by_reward
                          ? item?.mode_of_payment + "/" + "reward"
                          : item?.mode_of_payment}
                    </td>
                    <td
                      className={`aboutProduct fw500 ${!columnsNames[5]?.isVisible ? "d-none" : ""
                        }`}
                    >
                      ${addThousandSeparator(
                        Number(
                          item?.payable_amount -
                          item?.redeemed_reward_points / 100
                        )?.toFixed(2)
                      ) ?? "0"}
                    </td>
                    <td
                      className={`aboutProduct fw500 ${!columnsNames[6]?.isVisible ? "d-none" : ""
                        }`}
                    >
                      {Number(item?.redeemed_reward_points)}
                    </td>
                    <td
                      className={`aboutProduct fw500 ${!columnsNames[7]?.isVisible ? "d-none" : ""
                        }`}
                    >
                      {renderOrderStatusButtons(
                        item.status,
                        item?.is_returned_order,
                        item?.delivery_option
                      )}
                    </td>
                    <td className={`aboutProduct fw500`}>
                      <img
                        src={Images.webPosArrow}
                        alt="img"
                        onClick={() => handleRowClick(item)}
                      />
                    </td>
                    </React.Fragment>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="false"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "walletpurchase" ? "walletPurchaseModal" : ""}
        className={
          modalDetail.flag === "walletpurchase"
            ? "commonWidth customContent"
            : ""
        }
        child={
          modalDetail.flag === "walletpurchase" ? (
            <WalletPurhaseModal
              data={rowData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "walletpurchase" ? (
            <>
              <p
                style={{ cursor: "pointer" }}
                onClick={handleOnCloseModal}
                className="modal_cancel"
              >
                <img
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Transections;
