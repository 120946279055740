import React, { useEffect, useState } from 'react'
import SelectMenu from '../../../components/UI/Orders/SelectMenu'
import { unwrapResult } from '@reduxjs/toolkit';
import { getBrandOfCategoryAsync, getCategoriesAsync, getServicesAsync } from '../../dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { CategoryOptions } from './helper';
import { useTranslation } from 'react-i18next';

const CategoryOption = ({
    formData,
    handleFormData,
    id
}) => {
  const { t } = useTranslation();
    const [services, setServices] = useState([]);
    const auth = useSelector(selectLoginAuth);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [brand, setBrand] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getServices = (options) => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                type:formData.serviceType?(formData.campaign_for=="store"?"service":formData.serviceType):"product",
                check_product_existance: false,
            },
        };
        setLoading(true);
        dispatch(getServicesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name,
                    };
                });
                setServices(filterResult||[]);
            })
            .catch((obj) => {
                setLoading(false);
            });
    };
    const getCategories=async(fn,options,handler)=>{
        try {
           let data=await fn(options); 
           if(data){
            handler(data);
           }
        } catch (error) {
            
        }finally{
            setLoading(false);
        }
    }
    const tempOptions = [
        { value: "", label: "Temp" }
    ]
    const showOptions = (option, callFun) => {
        switch (option) {
            case "service_id": return services;
            case "category_id": return  category;
            case "sub_category_id": return subCategory;
            case "brand_id": return  brand;
            default: return tempOptions;
        }
    }
    useEffect(() => {
        getServices();
    }, [formData.serviceType])
    useEffect(()=>{
      let id=formData.service_id;
      if(id){
        callCategory(id);
      }
    },[formData.service_id]);
    const callCategory=(id)=>{
      let data = {
        tokenData: auth?.payload?.token,
        postData: {
          service_id:id,
          main_category: true,
          check_product_existance: false,
        },
      };
      setLoading(true);
      dispatch(getCategoriesAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          let filterResult = obj?.payload?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
            };
          });
          setCategory(filterResult||[]);
        })
        .catch((obj) => {
          setLoading(false);
        });
    }
    useEffect(()=>{
      let id=formData.category_id;
      if(id){
        callSubCategory(id)
      }
    },[formData.category_id]);
    const callSubCategory=(id)=>{
      let data = {
        tokenData: auth?.payload?.token,
        postData: {
          category_id: id,
          // main_category: true,
          need_subcategory: true,
          check_product_existance: false,
        },
      };
      setLoading(true);
      dispatch(getCategoriesAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          let filterResult = obj?.payload?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
            };
          });
          setSubCategory(filterResult||[]);
          // productResponse = {
          //   productName: productName,
          //   service_id: servicesId,
          //   category_id: e.value,
          //   sub_category_id: subCategoryId,
          //   brand_id: brandId,
          // };
          // props.productDetails(productResponse, "");
        })
        .catch((obj) => {
          setLoading(false);
        });
    }
    useEffect(()=>{
      let id=formData.sub_category_id;
      if(id){
        callBrand(id);
      }
    },[formData.sub_category_id]);
    const callBrand=(id)=>{
      let brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          category_id: id,
          check_product_existance: false,
        },
      };
      dispatch(getBrandOfCategoryAsync(brandData))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          let filterResult = obj?.payload?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
            };
          });
          setBrand(filterResult||[]);
          // productResponse = {
          //   productName: productName,
          //   service_id: servicesId,
          //   category_id: categoryId,
          //   sub_category_id: e.value,
          //   brand_id: brandId,
          // };
          // props.productDetails(productResponse, "");
        })
        .catch((obj) => {
          setLoading(false);
        });
    }
      const handleServicesChange = (e) => {
    handleFormData(null,{service_id:e.value});
    setCategory([]);
    setSubCategory([]);
    setBrand([]);
   
  };

  const handleCategoryChange = (e) => {
    handleFormData(null,{category_id:e.value});
    setSubCategory([]);
    setBrand([]);
    
  };

  const handleSubCategoryChange = (e) => {
    handleFormData(null,{sub_category_id:e.value});
    setBrand([]);
    
  };

  const handleBrandChange = (e) => {
    // setSelectedBrand(e);
    // setBrandId(e.value);
    handleFormData(null,{brand_id:e.value});
  };
  const showHandler=(option)=>{
    switch (option) {
        case "service_id": return handleServicesChange;
        case "category_id": return handleCategoryChange;
        case "sub_category_id": return handleSubCategoryChange;
        case "brand_id": return  handleBrandChange;
        default: return ()=>{};
    }
  }
    return<>
    {
        CategoryOptions.map((option, index) => (
            (formData.campaign_for == "product"||index!=3 )&& <div
                key={index}
                className={`${formData.campaign_for == "store" ? "col-md-4" : "col-md-6"}`}
            >
                <div className="form_group">
        <div className="w-100 commonSelectParent reactSelectParent">
            <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                {t(option.langaugeLabel)}
            </label>
            <SelectMenu
                      disabled={id?true:false}
                options={showOptions(option.value, false)}
                onChange={showHandler(option.value)}
                value={showOptions(option.value, false).filter(item=>item.value==formData[option.value])}
                isSearchable={true}
                placeholder={
                    t('selectOne')
                }
                classNamePrefix="react-select"
                className="anlyticSelect react-select-container"
                styles={{
                    control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                    }),
                }}
            />
        </div>
    </div>
            </div>
        ))
    }
    </>
    
}

export default CategoryOption