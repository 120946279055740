import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import moment from "moment-timezone";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CurrentChatHeader = ({
  currentRecipientDetails,
  manageParticipants,
  chatId,
  handleOpenModal,
  userId,
  recipientStatus,
  isSuperAdmin
  
}) => {
  const { t } = useTranslation();
  const [friendStatus, setfriendStatus] = useState(null);
  useEffect(() => {
    setfriendStatus(recipientStatus);
  }, [recipientStatus]);
  return (
    <>
      <div className="rightMessBoxHeader">
        <div className="row  align-items-center">
          <div className="col-md-9">
            <div className="flexBox gap-3">
              <figure className="chatSenderImg active m-0">
                <img src={Images.userImg_} alt="img" className="img-fluid" />
                {friendStatus?.isActive && (
                  <img
                    src={Images.onlineIndicator}
                    alt="img"
                    className="onlineIndicator"
                  />
                )}
              </figure>
              <div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                    {(currentRecipientDetails?.chatPartner?.firstname||'Super') +
                      " " +
                      (currentRecipientDetails?.chatPartner?.lastname||'Admin')}
                      {/* Admin */}
                  </h3>
                  <p className="userChatActiveTxt">
                    {friendStatus?.last_seen &&
                      !friendStatus?.isActive &&
                       `(${moment(friendStatus?.last_seen).fromNow()})`} 
                  </p>
                </div>
                {/* <h6 className="orderTrackLocation fontSize12 fw500 mb-0">
                  <img
                    src={Images.locateOrder}
                    alt="img"
                    className="location"
                  />
                  {currentRecipientDetails?.chatPartner?.address?.[0]?.city}
                </h6> */}
                  {/* India */}
              </div>
            </div>
          </div>
          {/* pin and delete options */}
          {
            isSuperAdmin&&
          <div className="col-md-3">
            <div className="flexBox gap-3 justify-content-end">
              {/* <div
                onClick={() => {
                  manageParticipants(chatId, "isPin");
                }}
              >
                <img
                  src={
                    currentRecipientDetails?.participants?.[userId]?.isPin
                      ? Images.activePinChat
                      : Images.pinLogo
                  }
                  alt="img"
                  className="cursorPointer"
                />
              </div> */}

              {/* {currentRecipientDetails?.sender_id !== userId &&
                !currentRecipientDetails?.requestAccepted && (
                  <div className="position-relative">
                    <img
                      src={Images.addUserLogo}
                      alt="img"
                      className="cursorPointer"
                      onClick={() => {
                        // if(isMessaging)return;
                        handleOpenModal("AcceptChatRequestModal");
                      }}
                    />
                  </div>
                )} */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="variantsTable_Dropdown"
                >
                  <img src={Images.LightMoreVerticalImg} alt="logo" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="variantsDropdownMenu chatHeaderDropDown">
                  {/* <Dropdown.Item
                    href="#/action-1"
                    onClick={() => {
                      manageParticipants(chatId, "isMuted");
                    }}
                  >
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        src={
                          currentRecipientDetails?.participants?.[userId]
                            ?.isMuted
                            ? Images.newCheck
                            : Images.muteLogo
                        }
                        alt="logo"
                      />
                      <h3 className="fontSize14 mb-0">
                        {false ? "Unmute" : "Mute"}
                      </h3>
                    </div>
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    href="#/action-2"
                    onClick={() => {
                      handleOpenModal("deleteCustomerModal");
                    }}
                  >
                    <div className="d-flex gap-2 align-items-center">
                      <img src={Images.blueDeleteLogo} alt="logo" />
                      <h3 className="fontSize14 mb-0">{t('delete')}</h3>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div> 
          }

        </div>
      </div>
    </>
  );
};

export default CurrentChatHeader;