import React, { useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const EditLocationModal = (props) => {
  const { allAddress } = props;
  const toastId = useRef(null);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        return [...prevOptions, option];
      } else {
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };
  const handleSave = () => {
    if (!sellerAddressIds.length) {
      showToast("Please select store");
      return;
    }
    if (!deliveryOptions.length) {
      showToast("Please select Delivery options");
      return;
    }
    props.handleUpdate(deliveryOptions, sellerAddressIds);
  };

  return (
    <div>
      <div className="outerArea EditVariation_ModalBox mb-0">
        <h3 className="fontSize18 fw500 txtDarkblue">Product availability</h3>

        <div className="productAvail_Second">
          <div className="d-flex align-items-center">
            <img
              src={Images.physicalStoreIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">Physical store</h3>
                <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            {allAddress.map((store, idx) => (
              <div className="productAvailabilityChild mt-3" key={store.id}>
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={`store-${store.id}`}
                    checked={sellerAddressIds.includes(store.id)}
                    onChange={handleStoreToggle(store.id)} // Correct usage
                  />
                  <label htmlFor={`store-${store.id}`}></label>
                </div>
                <div>
                  <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                    Store {idx + 1}
                  </h3>
                  <p className="fontSize12 txtLightBlue mb-0">
                    {store.street_address}, {store.city}, {store.state}{" "}
                    {store.zipcode}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="jobrMarketBox">
          <div className=" d-flex align-items-center">
            <img
              src={Images.jobrMarketplaceIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  JOBR Marketplace
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Online fulfillment methods
                </h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="pickup"
                  checked={deliveryOptions.includes("pickup")}
                  onChange={handleDeliveryOptionToggle("pickup")}
                />
                <label for="pickup"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Pickup</h3>
              </div>
            </div>
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="delivery"
                  checked={deliveryOptions.includes("delivery")}
                  onChange={handleDeliveryOptionToggle("delivery")}
                />
                <label for="delivery"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Delivery</h3>
              </div>
            </div>
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="shipping"
                  checked={deliveryOptions.includes("shipping")}
                  onChange={handleDeliveryOptionToggle("shipping")}
                />
                <label for="shipping"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Shipping</h3>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="jobrMarketBox">
          <div className=" d-flex align-items-center">
            <img
              src={Images.jobrB2bIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 uncheckTxt">JOBR B2B</h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Online fulfillment methods
                </h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="shipping"
                  checked={deliveryOptions.includes("shipping")}
                  onChange={handleDeliveryOptionToggle("shipping")}
                />
                <label for="shipping"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Shipping</h3>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => props?.close()}
        >
          Discard
        </button>
        <button
          className="modalNextBtn modalDiscardBtn active w-100"
          type="button"
          onClick={handleSave}
          disabled={props.loading}
        >
          {props.loading && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}
          Update
        </button>
      </div>
    </div>
  );
};

export default EditLocationModal;
