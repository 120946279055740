import dayjs from 'dayjs';
import * as React from 'react';
import { OVERLAP_OFFSET, u } from '../commonStyles';
import { useCalendarTouchableOpacityProps } from '../hooks/useCalendarTouchableOpacityProps';
import { useTheme } from '../theme/ThemeContext';
import { DAY_MINUTES, getRelativeTopInDay, getStyleForOverlappingEvent } from '../utils/datetime';
import { typedMemo } from '../utils/react';
import { DefaultCalendarEventRenderer } from './DefaultCalendarEventRenderer';
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getEventCellPositionStyle = function (start, end) {
    var relativeHeight = 100 * (1 / DAY_MINUTES) * dayjs(end).diff(start, 'minute');
    var relativeTop = getRelativeTopInDay(dayjs(start));
    return {
        height: "".concat(relativeHeight, "%"),
        top: "".concat(relativeTop, "%"),
    };
};
function _CalendarEvent(_a) {
    var allEvents = _a.allEvents, event = _a.event, onPressEvent = _a.onPressEvent, eventCellStyle = _a.eventCellStyle, showTime = _a.showTime, _b = _a.eventCount, eventCount = _b === void 0 ? 1 : _b, _c = _a.eventOrder, eventOrder = _c === void 0 ? 0 : _c, _d = _a.overlapOffset, overlapOffset = _d === void 0 ? OVERLAP_OFFSET : _d, renderEvent = _a.renderEvent, ampm = _a.ampm;
    var theme = useTheme();
    var palettes = React.useMemo(function () { return __spreadArray([theme.palette.primary], theme.eventCellOverlappings, true); }, [theme]);
    var touchableOpacityProps = useCalendarTouchableOpacityProps({
        allEvents: allEvents,
        event: event,
        eventCellStyle: eventCellStyle,
        onPressEvent: onPressEvent,
        injectedStyles: [
            getEventCellPositionStyle(event.start, event.end),
            getStyleForOverlappingEvent(eventOrder, overlapOffset, palettes),
            u['absolute'],
            u['mt-2'],
            u['mx-3'],
        ],
    });
    var textColor = React.useMemo(function () {
        var fgColors = palettes.map(function (p) { return p.contrastText; });
        return fgColors[eventCount % fgColors.length] || fgColors[0];
    }, [eventCount, palettes]);
    if (renderEvent) {
        return renderEvent(event, touchableOpacityProps, allEvents);
    }
    return (React.createElement(DefaultCalendarEventRenderer, { event: event, showTime: showTime, ampm: ampm, touchableOpacityProps: touchableOpacityProps, textColor: textColor }));
}
export var CalendarEvent = typedMemo(_CalendarEvent);
