import axios from 'axios';
import { getCurrentTimeZone } from './../../../utilities/timezone'

import {REACT_APP_USER_SERVICES } from "../../config"
const queryString = require('query-string');
const timeZone = getCurrentTimeZone();


export const getSessionHistoryApi  = (data, token) => {
    // `${USER_API_URL_V1}drawer_management/drawer-session/history?${stringifiedQueryParams}`
    let query = queryString.stringify(data)
    return new Promise((resolve, reject) =>
      axios
        .get(`${REACT_APP_USER_SERVICES}api/v1/drawer_management/drawer-session/history?${query}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'merchant',
            'timezone': timeZone
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error)
        })
    )
}