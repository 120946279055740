import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import CustomModal from '../../../components/shared/CustomModal';
import LinkbankwalletModal from './linkbankwalletModal';
import BankdetailModal from './bankdetailModal';
import { useDispatch, useSelector } from 'react-redux';
import { getconnectAccountApiAsync, selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

const BankaccountWallet = () => {
    const { t } = useTranslation();
    const auth = useSelector(selectLoginAuth);
    const [loading, setLoading] = useState(false)
    const [userConnectDetails, setUserConnectDetails] = useState([]);
    const [selectedBank, setSelectedBank] = useState("")
    const dispatch = useDispatch()
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    useEffect(() => {
        let params = {
            tokenData: auth?.payload?.token
                ? auth?.payload?.token
                : auth?.payload?.user?.token,
        };
        setLoading(true)
        dispatch(getconnectAccountApiAsync(params))
            .then(unwrapResult)
            .then((res) => {
                setLoading(false);
                if (res?.payload?.data && res?.payload?.data?.length > 0) {
                    setUserConnectDetails(res?.payload?.data);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <div className='bankAccountmain_'>
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">{t('bankAccount')}</h3>
                    <p className="textInnerHead2">{t('bankAccountTitle')}</p>
                </div>
                <div className='linkaccount_ mt-4'>
                    <button className='linkaccountBtn_' type='submit' onClick={() => {
                        setModalDetail({
                            show: true,
                            flag: "linkbankwalletModal",
                        });
                        setKey(Math.random());
                    }}> {t('linkAccount')} <img src={Images.webPosArrow} alt="img" /></button>
                </div>
                {loading && <span className="spinner-border inner-spin spinner-border-sm"></span>}
                {userConnectDetails.length > 0 && userConnectDetails.map((item, idx) => {
                    return (
                        <div className='linkedAccountdetail_ mt-4' key={idx}>
                            <h3 className="normalHeading_ mt-4 mb-3">{t('linkAccount')}</h3>
                            <div className='banknamesingo_ mb-3' onClick={() => {
                                setSelectedBank(item)
                                setModalDetail({
                                    show: true,
                                    flag: "bankdetailModal",
                                });
                                setKey(Math.random());
                            }}>
                                <div className='banknameImg_'>
                                    <figure className='linkedbankimg'><img src={item?.bank_image || Images.bankIcon_} alt="img" /></figure>
                                    <article className='banknameInfo_'>
                                        <h4 className='stateList_head'>{item?.bank_name}</h4>
                                        <p className='bankaccNumber_'>
                                            <span className='fontdiff_'>  &#8226;&#8226;&#8226;&#8226;  &#8226;&#8226;&#8226;&#8226;  &#8226;&#8226;&#8226;&#8226; {item?.last4 | "____"}</span>
                                        </p>
                                        <p className='bankaccNumber_'>{selectedBank?.account_holder_name || "____"}</p>
                                    </article>
                                </div>
                                <div className='eyeicon_'><img src={Images.eyeslash} alt="img" /></div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "linkbankwalletModal"
                        ? "linkbankwalletModal"

                        : modalDetail.flag === "bankdetailModal"
                            ? "bankdetailModal"

                            : ""
                }
                child={
                    modalDetail.flag === "linkbankwalletModal" ? (
                        <LinkbankwalletModal bankExist={userConnectDetails?.length} close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "bankdetailModal" ? (
                            <BankdetailModal
                                selectedBank={selectedBank}
                                close={() => handleOnCloseModal()}
                            />
                        ) :
                            (
                                <></>
                            )
                }
                header={
                    <>
                        {modalDetail.flag === "bankdetailModal" ? (
                            <>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div>
                                        <h3 className="fontSize24 fw500 txtDarkblue mb-2 ">
                                            Bank Details
                                        </h3>

                                    </div>
                                    <img
                                        src={Images.modalCross}
                                        alt="logo"
                                        onClick={() => handleOnCloseModal()}
                                    />
                                </div>
                            </>
                        )
                            : (
                                ""
                            )}
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default BankaccountWallet