import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Images from "../../../utilities/images";

import {
  add_plus,
  profileImg1,
  userHeader,
  serviceHead,
  staffHead,
  driverHead,
  locateHead,
  modalCancel,
  webPosArrow,
  addButtonImg,
  SearchIcon,
  NotificationIcon,
} from "../../../utilities/images";
import { logout, selectLoginAuth } from "../auth/authSlice";
import { toast } from "react-toastify";
import {
  dashboardEmpty,
  getMerchantStepStatusAsync,
  getWalletBallanceApiAsync,
  selectMerchantStatus,
  selectNotificationData,
} from "./dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "../../components/shared/CustomModal";
import FindProductModal from "../scalePlan/findProductModal";
import AddaddressModal from "../systemConfig/locations/addaddressModal";
import DefaultaddressModal from "../systemConfig/locations/defaultaddressModal";
import PlansModal from "../scalePlan/plansModal";
import { getUserSettingsApiAsync } from "../systemConfig/systemConfigSlice";
import AddCustomerModal from "../userList/consumer/AddCustomerModal";
import AddCardModal from "../scalePlan/addCardModal";
import PaymentDetail from "../scalePlan/paymentDetails";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  brandLogo,
  logoutImg,
  home,
  purchase,
  setting,
  support,
  products,
  chat,
  service,
  orders,
  pos,
  trackings,
  wallet,
  users,
  campaign,
  analytics,
  catalog,
  analyticsicon,
  SidebarLogo,
  PositiveIcon,
  productsIcon,
  serviceIcon,
  ordersIcon,
  bookings,
  userDirectory,
  walletIcon,
  analyticsIcon,
  settings,
  supportIcon,
  coupons,
  logOut,
  homeIcon,
  sideArrow,
  marketing,
  catalogs,
  POS,
  awardover_,
  allordericon,
  trackDeliveryicon,
  sideBarSettingIcon,
  cashDraweIcon,
  payrollAndStaffLogo,
} from "../../../utilities/images";
import { productEmpty } from "../myProducts/productSlice";
import { useTranslation } from "react-i18next";
import NotificationButton from "../../components/NotificationButton";
import { MarketingEmpty } from "../marketing/marketingSlice";
import { bookingEmpty } from "../bookings/bookingSlice";


const HOME_ROUTE = ["/wallet"];
const MESSAGES_ROUTES = ["/directMessage", "/inquaries", "/rfqMessage"];
const PRODUCT_ROUTES = [
  "/myProducts",
  "/productList",
  "/productOffer",
  "/categoryListview",
  "/product-subcategory",
  "/product-brands",
];
const SERVICE_ROUTES = [
  "/myService",
  "/service-list",
  "/serviceOffer",
  "/service-setting",
];
const BOOKIN_ROUTES = [
  "/bookings",
  "/all-bookings",
  "/calendar",
  "/BookingSetting",
];
const POS_ROUTES = [
  "/myPos",
  "/posSales",
  "/createUser",
  "/createRole",
  "/posUser",
];
const TRANSACTION_ROUTES = [
  "/WalletMain",
  "/transections",
  "/jbrwallet",
  "/localcurrencywallet",
  "/withdrawal",
  "/walletPayment",
  "/walletRefund",
  "/WalletSetting",
];
const USER_ROUTES = ["/userList", "/customer", "/users/age-verification"];
const ORDERS_ROUTES = [
  "/myOrders",
  "/newOrder",
  "/processingOrders",
  "/completeOrders",
  "/cancelOrders",
  "/allOrder",
  "/orderSettings",
  "/orderTrack",
  "/refundOrders",
];
const ANALYTICS_ROUTES = [
  "/analytics",
  "/stats",
  "/Gross-Profits",
  "/Total-Revenue",
  "/Total-Costs",
  "/Total-POS-Orders",
  "/Total-Delivery-Orders",
  "/Total-Shipping-Orders",
  "/Total-Orders",
  "/Total-Inventory",
  "/Total-Product-Sold",
];
const SUPPORT_ROUTES = [
  "/support",
  "/supportTicket",
  "/supportCall",
  "/supportChat",
  "/supportEmail",
  "/mySupport",
];
const MARKETING_ROUTES = [
  "/marketingIndex",
  "/couponsListing",
  "/campaignIndex",
];
const STAFFANDPAYROLL_ROUTES = [
  "/myPos",
  "/posSales",
  "/createUser",
  "/posUser",
  "/staffPayment",
];
const REWARDS_ROUTES = ["/rewards", "/all-rewards"];
const CATEGORY_ROTES = ["/manage-categories"];
const GIFTCARD_ROTES = ["/giftcard"];
const CASHDRAWER_ROUTES = ["/cashDrawer"];

function DashboardNavbar(props) {
  const { t } = useTranslation();
  // console.log(props, "Propssssssss");
  const scriptAddedRef = useRef(false);
  const pathname = window.location.pathname;
  const [showBanner, setShowBanner] = useState(true);
  const history = useHistory();
  const toastId = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [key, setKey] = useState(Math.random());
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [activeSidebar, setActiveSidebar] = useState(true);
  const [activeData, setActiveData] = useState("Dashboard");
  const userData = auth?.payload?.user;
  const [open, setOpen] = useState(false);

  const merchantStatus = useSelector(selectMerchantStatus);
  const pathName = window.location.pathname;
  const sellerType = auth?.payload?.user?.user_profiles?.seller_type;
  const openActions = () => {
    document.body.setAttribute("style", "overflow:hidden");
  };
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [Shown, setShown] = useState(false);
  const hideDetails = props?.hideDetails;
  useEffect(() => {
    if (typeof hideDetails === "function") {
      // Call the function only if it's a function
      hideDetails(Shown);
    } else {
      console.error("hideDetails is not a function", hideDetails);
    }
  }, [Shown, hideDetails]);

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const SUPPER_ADMIN = () => {
    const isSuperAdmin = auth?.payload?.user?.user_roles?.some(
      // check for super admin
      (e) => e?.role?.slug === "admin"
      // (e) => e?.role?.slug === "seller"
    );
    return isSuperAdmin;
  };
  const isActive = (routes, check = 0) => {
    if(check === 2) return routes.includes(activeData) || routes.includes(pathName);
    return routes.includes(activeData)
  }
  //  use for open modal
  const handleOpenModal = (flags) => {
    setSelectedPlanId(flags?.id ? flags?.id : selectedPlanId);
    const flag = flags?.flag || flags;
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const pickPlanButtonHandle = () => {
    // if (
    //   merchantStatus?.payload?.wallet_status === false &&
    //   merchantStatus?.payload?.subscription_status === false
    // ) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Create Wallet First");
    //   }
    //   history.push("/plans");
    // }
    if (
      // merchantStatus?.payload?.wallet_status === true &&
      merchantStatus?.payload?.subscription_status === false
    ) {
      setModalDetail({ show: true, flag: "PickPlan" });
      setKey(Math.random());
    }
  };

  const details = (flag) => {
    setModalDetail({ show: true, flag: flag });
    setKey(Math.random());
  };

  const getMerchantStepStatus = () => {
    dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        obj?.payload?.wallet_status === true
          ? setLoading(true)
          : setLoading(false);
        if (obj?.payload?.wallet_status === true) {
          let data = {
            tokenData: auth?.payload?.token,
          };
          dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setWalletBalance(obj?.payload?.sila_balance);
              setLoading(false);
            })
            .catch((obj) => {
              setLoading(false);
            });
        }
        obj?.payload?.wallet_status === true
          ? setLoading(true)
          : setLoading(false);
        if (obj?.payload?.wallet_status === true) {
          let data = {
            tokenData: auth?.payload?.token,
          };
          dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setWalletBalance(obj?.payload?.sila_balance);
              setLoading(false);
            })
            .catch((obj) => {
              setLoading(false);
            });
        }
      })
      .catch((obj) => {});
  };

  useEffect(() => {
    getMerchantStepStatus();
  }, []);

  // useEffect(() => {
  //   if (!scriptAddedRef.current) {
  //     const getLanguagesData = () => {
  //       let data = {
  //         postData: {
  //           seller_id: auth?.payload?.uniqe_id
  //             ? auth?.payload?.uniqe_id
  //             : auth?.payload?.user?.unique_uuid,
  //           app_name: "pos",
  //         },
  //         tokenData: auth?.payload?.token,
  //       };
  //       dispatch(getUserSettingsApiAsync(data))
  //         .then(unwrapResult)
  //         .then((obj) => {
  //           // setLanguagesData(obj?.payload?.languages);
  //           const languageCode = obj?.payload?.languages
  //             ?.filter((item) => item?.status === 1)
  //             ?.map((item) => item?.lang_code)
  //             ?.filter((val) => val != null);
  //           // Use Set to remove duplicates
  //           const formattedLanguageCode = Array.from(new Set(languageCode));
  //           // setLanguageCode(formattedLanguageCode);
  //           const includedLanguagesString = formattedLanguageCode.join(",");
  //           if (formattedLanguageCode.length > 0) {
  //             const googleTranslateElementInit = () => {
  //               new window.google.translate.TranslateElement(
  //                 {
  //                   pageLanguage: "en",
  //                   autoDisplay: false,
  //                   includedLanguages: includedLanguagesString,
  //                   layout:
  //                     window.google.translate.TranslateElement.InlineLayout
  //                       .SIMPLE,
  //                   multilanguagePage: true,
  //                 },
  //                 "google_translate_element"
  //               );
  //             };

  //             const addScript = document.createElement("script");
  //             addScript.setAttribute(
  //               "src",
  //               "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //             );
  //             document.body.appendChild(addScript);
  //             scriptAddedRef.current = true;
  //             window.googleTranslateElementInit = googleTranslateElementInit;
  //           }
  //         })
  //         .catch((obj) => {
  //           setLoading(false);
  //         });
  //     };
  //     getLanguagesData();
  //   }
  // }, []);

  const handleGoToNotificationPage = () => {
    history.push("/notifications");
  };

  const handleGoToWebPos = () => {
    window.open("https://webpos-staging.jobr.com/", "_blank");
  };
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const userLogout = async () => {
    await dispatch(logout());
    await dispatch(productEmpty());
    await dispatch(dashboardEmpty());
    await dispatch(bookingEmpty());
    await dispatch(MarketingEmpty());
    window.localStorage.clear();
    toast.success("Successfully Logout");
    await history.push("/");
  };
  const ADMIN = () => {
    const admin = auth?.payload?.user?.user_roles?.filter(
      (item) => item?.role?.slug == "admin"
    );
    return admin;
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" className="mobileSidebar">
      <>
        <aside className="vertical-tab">
          <div
            onClick={() => setActiveSidebar((prev) => !prev)}
            className="ToggleIcon"
          >
            <img
              src={Images.homeIcon}
              alt="side"
              className="img-fluid sideToggle"
              
            />
          </div>
          <div className="text-center">
            <Link to="/wallet" className="brandLogo">
              <img src={SidebarLogo} className="img-fluid" alt="" />
            </Link>
            <Link to="/moresettingIndex" className="sidebarProfileParent">
              <figure className="sidebarProfileImg">
                <img
                  src={
                    userData?.user_profiles?.profile_photo
                      ? userData.user_profiles.profile_photo
                      : Images.adminImg
                  }
                  alt="img"
                  className="img-fluid"
                />
              </figure>
              <div className="text-start">
                <h3 className="fontSize18 fw500 txtDarkblue mb-1">
                  {userData?.user_profiles?.firstname +
                    " " +
                    userData?.user_profiles?.lastname}
                </h3>
                <h5 className="fontSize14 fw500 txtLightBlue mb-0">{t('admin')}</h5>
              </div>
            </Link>
          </div>
          <ul className="sidebarMenus navbar_overlay_content_">
            {/* home routes */}
            <li
              className={
                isActive(HOME_ROUTE, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="/wallet"
                onClick={
                  (() =>
                    isActive(HOME_ROUTE)
                      ? setActiveData("")
                      : setActiveData("/wallet"),
                  toggleDrawer(false))
                }
                className={
                  isActive(HOME_ROUTE)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                id="sidebarDropdown"
              >
                <img
              src={
                isActive(HOME_ROUTE, 2)
                  ? homeIcon
                  : Images.homenewIcon
              }
              className="img-fluid showImg"
              alt=""
            />
                <span className="menusTxt">{t('home')}</span>
                {/* <i className="fa-solid fa-caret-down caretIcon"
                            ></i> */}
              </Link>
            </li>
            {ADMIN()?.length > 0 && (
              <>
                <li
                  className={
                    activeData == "/subscriber"
                      ? "sidebarItems dropdown active"
                      : "sidebarItems dropdown"
                  }
                  onClick={toggleDrawer(false)}
                >
                  <Link
                    to="/subscriber"
                    onClick={() =>
                      activeData === "/subscriber"
                        ? setActiveData("")
                        : setActiveData("/subscriber")
                    }
                    className={
                      activeData === "/subscriber"
                        ? "sidebarLinks dropdown-toggle show"
                        : "sidebarLinks dropdown-toggle"
                    }
                    id="sidebarDropdown"
                  >
                    <img src={users} className="img-fluid showImg" alt="" />
                    <span className="menusTxt">{t('subscriber')}</span>
                  </Link>
                </li>
                <li
                  className={
                    activeData == "/googleAnalytics"
                      ? "sidebarItems dropdown active"
                      : "sidebarItems dropdown"
                  }
                  onClick={toggleDrawer(false)}
                >
                  <Link
                    to="/googleAnalytics"
                    onClick={() =>
                      activeData === "/googleAnalytics"
                        ? setActiveData("")
                        : setActiveData("/googleAnalytics")
                    }
                    className={
                      activeData === "/googleAnalytics"
                        ? "sidebarLinks dropdown-toggle show"
                        : "sidebarLinks dropdown-toggle"
                    }
                    id="sidebarDropdown"
                  >
                    <img src={users} className="img-fluid showImg" alt="" />
                    <span className="menusTxt">{t('googleAnalytics')}</span>
                  </Link>
                </li>
              </>
            )}
            <li
              className={
                isActive(MESSAGES_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(MESSAGES_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/directMessage")
                  isActive(MESSAGES_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/directMessage")
                }}
                aria-expanded="false"
                id="sidebarDropdown"
              >
                <img src={
                  isActive(MESSAGES_ROUTES, 2) ?  Images.inboxFill
                  : PositiveIcon
                } className="img-fluid showImg" alt="" />
                <span className="menusTxt">{t('inbox')}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              {/* Start SidebarInnerMenus */}
              <ul
                className={
                  activeData === "/directMessage" ||
                  activeData == "/inquaries" ||
                  activeData == "/rfqMessage"
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/directMessage"
                    className={
                      pathName === "/directMessage"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t("directMessage")}
                  </Link>
                </li>
              </ul>
              {/* End SidebarInnerMenus */}
            </li>
            {/* product routes */}
            <li
              className={
                isActive(PRODUCT_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(PRODUCT_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() =>{
                  history.push("/myProducts");
                  isActive(PRODUCT_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/myProducts")
                }}
                aria-expanded="false"
                id="sidebarDropdown"
              >
                <img src={isActive(PRODUCT_ROUTES, 2) ? Images.productFill : productsIcon} className="img-fluid showImg" alt="" />
                {/* <img src={shippingOrderDark} className="img-fluid hoverImg" alt="" /> */}
                <span className="menusTxt">{t('products')}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              {/* Start SidebarInnerMenus */}
              <ul
                className={
                  activeData === "/myProducts"
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/myProducts"
                    className={
                      pathName == "/myProducts"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={awardover_} className="submenuImg_"></img>
                    {t('overview')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/productList"
                    className={
                      pathName == "/productList"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={Images.productbox_} className="submenuImg_"></img>
                    {t('products')}
                  </Link>
                </li>

                <li className="sidebarInnerItems">
                  <Link
                    to="/productOffer"
                    className={
                      pathName == "/productOffer"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={Images.offericon_} className="submenuImg_"></img>
                    {t('offers')}
                  </Link>
                </li>
              </ul>
              {/* End SidebarInnerMenus */}
            </li>
            {/* service routes */}
            {sellerType == "manufacturer" || sellerType == "whole_seller" ? (
              ""
            ) : (
              <li
                className={
                  isActive(SERVICE_ROUTES, 2)
                    ? "sidebarItems dropdown active"
                    : "sidebarItems dropdown"
                }
              >
                <Link
                  to="#"
                  className={
                    isActive(SERVICE_ROUTES)
                      ? "sidebarLinks dropdown-toggle show"
                      : "sidebarLinks dropdown-toggle"
                  }
                  onClick={() => {
                    history.push("/myService")
                    isActive(SERVICE_ROUTES)
                      ? setActiveData("")
                      : setActiveData("/myService")
                  }}
                  id="sidebarDropdown"
                >
                  <img src={
                    isActive(SERVICE_ROUTES, 2) ? Images.serviceFill : serviceIcon 
                  } className="img-fluid showImg" alt="" />
                  <span className="menusTxt">{t('services')}</span>
                  <i class="caretIcon">
                    <img src={sideArrow} className="sideArrowImg"></img>
                  </i>
                </Link>
                {/* Start SidebarInnerMenus */}
                <ul
                  className={
                    activeData === "/myService"
                      ? "dropdown-menu sidebarDropMenus show"
                      : "dropdown-menu sidebarDropMenus"
                  }
                  aria-labelledby="sidebarDropdown"
                >
                  <li className="sidebarInnerItems">
                    <Link
                      to="/myService"
                      className={
                        pathName == "/myService"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img src={awardover_} className="submenuImg_"></img>
                      {t('overview')}
                    </Link>
                  </li>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/service-list"
                      className={
                        pathName == "/service-list"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img
                        src={Images.serviceIcon}
                        className="submenuImg_"
                      ></img>
                      {t('services')}
                    </Link>
                  </li>

                  <li className="sidebarInnerItems">
                    <Link
                      to="/serviceOffer"
                      className={
                        pathName == "/serviceOffer"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img
                        src={Images.offericon_}
                        className="submenuImg_"
                      ></img>
                      {t('offers')}
                    </Link>
                  </li>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/service-setting"
                      className={
                        pathName == "/service-setting"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img
                        src={sideBarSettingIcon}
                        alt="sideBarSettingIcon"
                        className="me-3"
                      />
                     {t('serviceSettings')}
                    </Link>
                  </li>
                </ul>
                {/* End SidebarInnerMenus */}
              </li>
            )}
            {/* manage category */}
            {SUPPER_ADMIN() && <li
              className={
                isActive(CATEGORY_ROTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="/manage-categories"
                onClick={() => {
                  history.push("/manage-categories")
                  isActive(CATEGORY_ROTES)
                    ? setActiveData("")
                    : setActiveData("/manage-categories")
                }}
                className={
                  isActive(CATEGORY_ROTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                id="sidebarDropdown"
              >
                <img src={coupons} alt="manage-categories"></img>

                <span className="menusTxt coupons">{t('manageCategory')}</span>
              </Link>
            </li>}
            {/* orders routes */}
            <li
              className={
                isActive(ORDERS_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(ORDERS_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/myOrders")
                  isActive(ORDERS_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/myOrders")
                }}
                id="sidebarDropdown"
              >
                <img src={
                 isActive(ORDERS_ROUTES, 2) ? Images.orderFill : ordersIcon
                } className="img-fluid showImg" alt="" />
                {/* <img src={fiatTockenActive} className="img-fluid hoverImg" alt="" /> */}
                <span className="menusTxt">{t('orders')}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              {/* Start SidebarInnerMenus */}
              <ul
                className={
                  isActive(ORDERS_ROUTES)
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/myOrders"
                    className={
                      pathName == "/myOrders"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={awardover_} className="submenuImg_"></img>
                    {t('overview')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/allOrder"
                    className={
                      pathName == "/allOrder" ||
                      pathName.split("/")[1] === "orderReview"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={allordericon} className="submenuImg_"></img>
                    {t('allorders')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/orderTrack"
                    className={
                      pathName.split("/")[1] === "orderTrack"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={trackDeliveryicon} className="submenuImg_"></img>
                    {t('orderTracking')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/orderSettings"
                    className={
                      pathName == "/orderSettings"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={settings} className="submenuImg_"></img>
                    {t("orderSetting")}
                  </Link>
                </li>
              </ul>
              {/* End SidebarInnerMenus */}
            </li>
            {/* booking routes */}
            {sellerType == "manufacturer" || sellerType == "whole_seller" ? (
              <li
                className={
                  isActive(BOOKIN_ROUTES, 2)
                    ? "sidebarItems dropdown active"
                    : "sidebarItems dropdown"
                }
              >
                <Link
                  to="#"
                  className={
                    isActive(BOOKIN_ROUTES)
                      ? "sidebarLinks dropdown-toggle show"
                      : "sidebarLinks dropdown-toggle"
                  }
                  onClick={() => {
                    history.push("/bookings")
                    isActive(BOOKIN_ROUTES)
                      ? setActiveData("")
                      : setActiveData("/bookings")
                  }}
                  id="sidebarDropdown"
                >
                  <img src={
                    isActive(BOOKIN_ROUTES, 2) ? Images.BookingFill : bookings
                  } className="img-fluid" alt="" />
                  <span className="menusTxt">{t("bookings")} </span>
                  <i class="caretIcon">
                    <img src={sideArrow} className="sideArrowImg"></img>
                  </i>
                </Link>
                <ul
                  className={
                    isActive(BOOKIN_ROUTES)
                      ? "dropdown-menu sidebarDropMenus show"
                      : "dropdown-menu sidebarDropMenus"
                  }
                  aria-labelledby="sidebarDropdown"
                >
                  <li className="sidebarInnerItems">
                    <Link
                      to="/bookings"
                      className={
                        pathName == "/bookings"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="3"
                          cy="3"
                          r="3"
                          fill="#8D99D2"
                          className="circledot"
                        />
                      </svg>
                      {t('overview')}
                    </Link>
                  </li>

                  <li className="sidebarInnerItems">
                    <Link
                      to="/all-bookings"
                      className={
                        pathName == "/all-bookings"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="3"
                          cy="3"
                          r="3"
                          fill="#8D99D2"
                          className="circledot"
                        />
                      </svg>
                      {t('allBookings')}
                    </Link>
                  </li>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/calendar"
                      className={
                        pathName == "/calendar"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="3"
                          cy="3"
                          r="3"
                          fill="#8D99D2"
                          className="circledot"
                        />
                      </svg>
                      {t('calendar')}
                    </Link>
                  </li>

                  <li className="sidebarInnerItems">
                    <Link
                      to="/bookingSettings"
                      className={
                        pathName == "/bookingSettings"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="3"
                          cy="3"
                          r="3"
                          fill="#8D99D2"
                          className="circledot"
                        />
                      </svg>
                      {t("bookingSettings")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              <li
                className={
                  isActive(BOOKIN_ROUTES, 2)
                    ? "sidebarItems dropdown active"
                    : "sidebarItems dropdown"
                }
              >
                <Link
                  to="#"
                  className={
                    isActive(BOOKIN_ROUTES)
                      ? "sidebarLinks dropdown-toggle show"
                      : "sidebarLinks dropdown-toggle"
                  }
                  onClick={() => {
                    history.push("/bookings")
                    isActive(BOOKIN_ROUTES)
                      ? setActiveData("")
                      : setActiveData("/bookings")
                  }}
                  id="sidebarDropdown"
                >
                  <img src={
                    isActive(BOOKIN_ROUTES, 2) ? Images.BookingFill : bookings
                  } className="img-fluid" alt="" />
                  <span className="menusTxt">{t("bookings")} </span>
                  <i class="caretIcon">
                    <img src={sideArrow} className="sideArrowImg"></img>
                  </i>
                </Link>
                <ul
                  className={
                    isActive(BOOKIN_ROUTES)
                      ? "dropdown-menu sidebarDropMenus show"
                      : "dropdown-menu sidebarDropMenus"
                  }
                  aria-labelledby="sidebarDropdown"
                >
                  <li className="sidebarInnerItems">
                    <Link
                      to="/bookings"
                      className={
                        pathName == "/bookings"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img src={awardover_} className="submenuImg_"></img>
                      {t('overview')}
                    </Link>
                  </li>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/all-bookings"
                      className={
                        pathName == "/completed-bookings"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img
                        src={Images.allbookingsicon}
                        className="submenuImg_"
                      ></img>
                      {t('allBookings')}
                    </Link>
                  </li>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/calendar"
                      className={
                        pathName == "/calendar"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img
                        src={Images.CalendarOutline}
                        className="submenuImg_"
                      ></img>
                      {t('calendar')}
                    </Link>
                  </li>

                  <li className="sidebarInnerItems">
                    <Link
                      to="/BookingSetting"
                      className={
                        pathName == "/BookingSetting"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                      onClick={toggleDrawer(false)}
                    >
                      <img src={Images.settings} className="submenuImg_"></img>
                      {t('bookingSettings')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            
            {/* analytics routes */}
            <li
              className={
                isActive(ANALYTICS_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(ANALYTICS_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/analytics")
                  isActive(ANALYTICS_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/analytics")
                }}
                id="sidebarDropdown"
              >
                <img src={
                  isActive(ANALYTICS_ROUTES, 2) ? Images.analyticsFill  : analyticsIcon
                } className="img-fluid" alt="" />
                <span className="menusTxt">{t("analytics")} </span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              <ul
                className={
                 isActive(ANALYTICS_ROUTES)
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/analytics"
                    className={
                      pathName == "/analytics"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={awardover_} className="submenuImg_"></img>
                    {t("overview")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Gross-Profits"
                    className={
                      pathName == "/Gross-Profits"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.bluegrossicon_}
                      className="submenuImg_"
                    ></img>
                    {t("grossProfits")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-Revenue"
                    className={
                      pathName == "/Total-Revenue"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={Images.blueRevenue} className="submenuImg_"></img>
                    {t("totalRevenue")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-Costs"
                    className={
                      pathName == "/Total-Costs"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={Images.blueCost} className="submenuImg_"></img>
                    {t("totalCosts")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-POS-Orders"
                    className={
                      pathName == "/Total-POS-Orders"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.posorderBlue}
                      className="submenuImg_"
                    ></img>
                    {t("totalPostOrder")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-Delivery-Orders"
                    className={
                      pathName == "/Total-Delivery-Orders"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.deliverorderBlue}
                      className="submenuImg_"
                    ></img>
                    {t("totalDeliveryOrder")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-Shipping-Orders"
                    className={
                      pathName == "/Total-Shipping-Orders"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.shippingorderBlue}
                      className="submenuImg_"
                    ></img>
                    {t("totalShippingOrder")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-Orders"
                    className={
                      pathName == "/Total-Orders"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.totalOrderblue}
                      className="submenuImg_"
                    ></img>
                    {t("totalOrders")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-Inventory"
                    className={
                      pathName == "/Total-Inventory"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.inventoryBlue}
                      className="submenuImg_"
                    ></img>
                    {t("totalInventory")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/Total-Product-Sold"
                    className={
                      pathName == "/Total-Product-Sold"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.productsoldBlue}
                      className="submenuImg_"
                    ></img>
                    {t("totalProductSold")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* user directory */}
            <li
              className={
                isActive(USER_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(USER_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/userList")
                  isActive(USER_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/userList")
                }}
                id="sidebarDropdown"
              >
                <img src={
                  isActive(USER_ROUTES, 2) ? Images.userFill  : userDirectory
                } className="img-fluid" alt="" />
                <span className="menusTxt">{t("userDirectory")}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              <ul
                className={
                  isActive(USER_ROUTES)
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/userList"
                    className={
                      pathName === "/userList" ||
                      pathName === "/consumerProfile" ||
                      pathName === "/consumerOrder"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t('overview')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/customer"
                    className={
                      pathName === "/customer" ||
                      pathName === "/consumerProfile" ||
                      pathName === "/consumerOrder"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t('customers')}
                  </Link>
                </li>
                {SUPPER_ADMIN() && <li className="sidebarInnerItems">
                  <Link
                    to="/users/age-verification"
                    className={
                      pathName === "/users/age-verification" ||
                      pathName === "/consumerProfile" ||
                      pathName === "/consumerOrder"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t('ageVerification')}
                  </Link>
                </li>}
                {ADMIN()?.length > 0 && (
                  <>
                    <li className="sidebarInnerItems">
                      <Link
                        to="/user-list/manufacturer"
                        className={
                          pathName.split("/")[2] == "manufacturer" ||
                          pathName.split("/")[2] === "manufacturer"
                            ? "dropdown-item sidebarInnerLink active"
                            : "dropdown-item sidebarInnerLink"
                        }
                        onClick={toggleDrawer(false)}
                      >
                        <svg
                          width="6"
                          height="6"
                          viewBox="0 0 6 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="3"
                            cy="3"
                            r="3"
                            fill="#8D99D2"
                            className="circledot"
                          />
                        </svg>
                        {t('manufacturers')}
                      </Link>
                    </li>
                    <li className="sidebarInnerItems">
                      <Link
                        to="/user-list/whole_seller"
                        className={
                          pathName.split("/")[2] == "whole_seller" ||
                          pathName.split("/")[2] === "whole_seller"
                            ? "dropdown-item sidebarInnerLink active"
                            : "dropdown-item sidebarInnerLink"
                        }
                        onClick={toggleDrawer(false)}
                      >
                        <svg
                          width="6"
                          height="6"
                          viewBox="0 0 6 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="3"
                            cy="3"
                            r="3"
                            fill="#8D99D2"
                            className="circledot"
                          />
                        </svg>
                        {t('wholeSalers')}
                      </Link>
                    </li>
                    <li className="sidebarInnerItems">
                      <Link
                        to="/user-list/retailer"
                        className={
                          pathName.split("/")[2] == "retailer" ||
                          pathName.split("/")[2] === "retailer"
                            ? "dropdown-item sidebarInnerLink active"
                            : "dropdown-item sidebarInnerLink"
                        }
                        onClick={toggleDrawer(false)}
                      >
                        <svg
                          width="6"
                          height="6"
                          viewBox="0 0 6 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="3"
                            cy="3"
                            r="3"
                            fill="#8D99D2"
                            className="circledot"
                          />
                        </svg>
                        {t('retailers')}
                      </Link>
                    </li>
                  </>
                )}
            
              </ul>
            </li>
            {/* Transactions routes */}
            <li
              className={
                isActive(TRANSACTION_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(TRANSACTION_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/WalletMain")
                  isActive(TRANSACTION_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/WalletMain")
                }}
                id="sidebarDropdown"
              >
                <img src={
                  isActive(TRANSACTION_ROUTES, 2)? Images.walletFill : walletIcon
                } className="img-fluid" alt="" />
                <span className="menusTxt">{t("transactions")}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              <ul
                className={
                  isActive(TRANSACTION_ROUTES)
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/WalletMain"
                    className={
                      pathName == "/WalletMain"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={awardover_} className="submenuImg_"></img>
                    {t('overview')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/transections"
                    className={
                      pathName == "/transections"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.alltransactionicon_}
                      className="submenuImg_"
                    ></img>
                   {t("allTransactions")}
                  </Link>
                </li>
                {/* <li className="sidebarInnerItems">
                  <Link
                    to="/withdrawal"
                    className={
                      pathName == "/withdrawal" ||
                      pathName == "/instantbankTransfer"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={Images.cashoutIcon} className="submenuImg_"></img>
                    Withdrawals
                  </Link>
                </li> */}
                <li className="sidebarInnerItems">
                  <Link
                    to="WalletSetting"
                    className={
                      pathName == "/WalletSetting" ||
                      pathName == "/instantbankTransfer"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={Images.settings} className="submenuImg_"></img>
                    {t("transactionSettings")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* payroll and staff routes */}
            <li
              className={
                isActive(POS_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                 isActive(POS_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/myPos")
                  isActive(POS_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/myPos")
                }}
                id="sidebarDropdown"
              >
                <img src={
                  isActive(POS_ROUTES, 2) ? Images.staffActiveIcon  : Images.staffInactiveIcon
                } className="img-fluid" alt="" />
                <span className="menusTxt">{t("payroll&Staff")}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              <ul
                className={
                  isActive(POS_ROUTES)
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/myPos"
                    className={
                      pathName == "/myPos"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={awardover_} className="submenuImg_"></img>
                    {t('overview')}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/posSales"
                    className={
                      pathName == "/posSales"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.totalOrderblue}
                      className="submenuImg_"
                    ></img>
                    {t("staffSales")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/createUser"
                    className={
                      pathName == "/createUser"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.addNewUserImg}
                      className="submenuImg_"
                    ></img>
                    {t("createUser")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/createRole"
                    className={
                      pathName == "/createRole"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={Images.createRoles} className="submenuImg_"></img>
                    {t("createRole")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/posUser"
                    className={
                      pathName == "/posUser"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.authUserLogo}
                      className="submenuImg_"
                    ></img>
                    {t("teamMembers")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* rewards */}
            <li
              className={
                isActive(REWARDS_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(REWARDS_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/rewards")
                  isActive(REWARDS_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/rewards")
                }}
                aria-expanded="false"
                id="sidebarDropdown"
              >
                <img src={
                  isActive(REWARDS_ROUTES, 2) ? Images.rewardsActiveIcon : Images.rewardsInactiveIcon
                } className="img-fluid showImg" alt="" />
                <span className="menusTxt">{t("reward")}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              {/* Start SidebarInnerMenus */}
              <ul
                className={
                  isActive(REWARDS_ROUTES)
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/rewards"
                    className={
                      pathName === "/rewards"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t("overview")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/all-rewards"
                    className={
                      pathName === "/all-rewards"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#8D99D2"
                        className="circledot"
                      />
                    </svg>
                    {t("allRewards")}
                  </Link>
                </li>
              </ul>
              {/* End SidebarInnerMenus */}
            </li>
            
            {/* <li
              className={
                activeData == "/catalogs"
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
              onClick={toggleDrawer(false)}
            >
              <Link
                to="/catalogs"
                className={
                  activeData === "/catalogs"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() =>
                  activeData === "/catalogs"
                    ? setActiveData("")
                    : setActiveData("/catalogs")
                }
                id="sidebarDropdown"
              >
                <img src={catalogs} className="img-fluid" alt="" />
                <span className="menusTxt">Catalogs </span>
              </Link>
            </li> */}
            {/* giftcard */}
            {SUPPER_ADMIN() && <li
              className={
                activeData == "/giftcard"
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
              onClick={toggleDrawer(false)}
            >
              <Link
                to="/giftcard"
                className={
                  activeData === "/giftcard"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() =>
                  activeData === "/giftcard"
                    ? setActiveData("")
                    : setActiveData("/giftcard")
                }
                id="sidebarDropdown"
              >
                <img src={coupons} className="img-fluid" alt="" />
                <span className="menusTxt">{t("giftCard")} </span>
              </Link>
            </li>}
            {/* marketing routes */}
            <li
              className={
                isActive(MARKETING_ROUTES, 2)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  isActive(MARKETING_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() => {
                  history.push("/marketingIndex")
                  isActive(MARKETING_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/marketingIndex")
                }}
                id="sidebarDropdown"
              >
                <img src={
                  isActive(MARKETING_ROUTES, 2) ? Images.marketingFill : marketing
                } className="img-fluid" alt="" />
                <span className="menusTxt">{t("marketing")}</span>
                <i class="caretIcon">
                  <img src={sideArrow} className="sideArrowImg"></img>
                </i>
              </Link>
              <ul
                className={
                  isActive(MARKETING_ROUTES)
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/marketingIndex"
                    className={
                      pathName == "/marketingIndex"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img src={awardover_} className="submenuImg_"></img>
                    {t("overview")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/couponsListing"
                    className={
                      pathName == "/couponsListing"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.discountTicket}
                      className="submenuImg_"
                    ></img>
                    {t("coupons&Promotion")}
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/campaignIndex"
                    className={
                      pathName == "/campaignIndex"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                    onClick={toggleDrawer(false)}
                  >
                    <img
                      src={Images.campaignIcon}
                      className="submenuImg_"
                    ></img>
                    {t("campaign")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* more settings */}
            <li
              className={
                activeData == "/moresettingIndex"
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
              onClick={toggleDrawer(false)}
            >
              <Link
                to="/moresettingIndex"
                className={
                  activeData === "/moresettingIndex"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() =>
                  activeData === "/moresettingIndex"
                    ? setActiveData("")
                    : setActiveData("/moresettingIndex")
                }
                id="sidebarDropdown"
              >
                <img src={settings} className="img-fluid" alt="" />
                <span className="menusTxt">{t("moreSettings")}</span>
              </Link>
            </li>
            {/* support */}
            <li
              className={
                isActive(SUPPORT_ROUTES)
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
              onClick={toggleDrawer(false)}
            >
              <Link
                to="/support"
                className={
                  isActive(SUPPORT_ROUTES)
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() =>
                  isActive(SUPPORT_ROUTES)
                    ? setActiveData("")
                    : setActiveData("/support")
                }
                id="sidebarDropdown"
              >
                <img src={supportIcon} className="img-fluid" alt="" />
                <span className="menusTxt">{t("support")}</span>
              </Link>
            </li>            
            {/* cash drawer */}
            {/* <li
              className={
                activeData == "/cashDrawer"
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
              onClick={toggleDrawer(false)}
            >
              <Link
                to="/cashDrawer"
                onClick={() =>
                  activeData === "/cashDrawer"
                    ? setActiveData("")
                    : setActiveData("/cashDrawer")
                }
                className={
                  activeData === "/cashDrawer"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                id="sidebarDropdown"
              >
                <img src={cashDraweIcon} alt="cashDrawer"></img>

                <span className="menusTxt coupons">Cash Drawer</span>
              </Link>
            </li> */}
          </ul>

          <div className="sidbarfixedMenus">
            <li
              className="sidebarItems"
              onClick={() => {
                userLogout();
              }}
            >
              <Link
                to="#"
                className="sidebarLinks"
                onClick={() => setActiveData("power")}
              >
                <img src={logOut} className="img-fluid" alt="" />
                <span className="menusTxt LogoutOption fw500">{t("logout")}</span>
              </Link>
            </li>
            <li></li>
          </div>
        </aside>
      </>
    </Box>
  );

  return (
    <>
      {/* <div className="languageselect_"><div id="google_translate_element"></div></div> */}
      {!showBanner ||
      merchantStatus?.payload?.subscription_status === true ||
      pathname === "/create-wallet" ||
      pathname === "/wallet" ||
      pathname === "/bank-details" ||
      pathname === "/request-kyc" ||
      pathname === "/IDVerification" ||
      pathname === "/addBankAccount" ||
      pathname === "/businessRegistration" ||
      pathname === "/requestKYB" ||
      pathname === "/business-id-verification" ||
      pathname === "/planSubscription" ? (
        <></>
      ) : (
        <div className="pickPlanContainer mb-4">
          <div className="row align-items-center">
            <div className="col-md-9">
              <p className="pickPlanDes mb-0">
                {t("planTagline")}
              </p>
            </div>
            <div className="col-md-3 text-end">
              <div className="d-flex align-items-center justify-content-end">
                <button
                  className="pickBtn me-4"
                  onClick={() => pickPlanButtonHandle()}
                >
                  {t("pickPlan")}
                </button>
                <i
                  className="las la-times"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowBanner(false)}
                ></i>
              </div>
            </div>
          </div>
        </div>
      )}

      <nav className="mainHeader">
        <div className="row">
          {/* <span
            onClick={() => {
              // props.openNav(;
              openActions();
            }}
            className="col-1 col-sm-1 col-md-1 openbtn2 d-none"
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </span> */}
          <div className="headcontentNav">
            <div className="headerToggleParent flexBox">
              <div
                className="headerToggleBtn"
                // onClick={() =>
                //   Shown == false ? setShown(true) : setShown(false)
                // }
                onClick={toggleDrawer(true)}
              >
                <i className="fa-solid fa-bars fa-lg"></i>
              </div>
              <h2 className="headerHeading_ mb-0">
                {props?.backShow && (
                  <span
                    className="headArrow"
                    style={{ cursor: "pointer" }}
                    onClick={
                      props?.flag === "goBack"
                        ? () => history.goBack()
                        : props?.flag === "customer"
                        ? () => {
                            history.push(props?.backShow, {
                              customerType: "all_customers",
                            });
                          }
                        : () => history.push(props?.backShow)
                    }
                  >
                    <i class="fa-solid fa-chevron-left"></i>
                  </span>
                )}
                {props?.title}
              </h2>
              <Link to="/notifications" className="mobileNotificationLink ms-auto">
                <li className="rightNavItems">
                  <img src={NotificationIcon} alt="" />
                </li>
              </Link>
              <Drawer
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                  zIndex: 1055,
                }}
              >
                {DrawerList}
              </Drawer>
            </div>
            <ul className="rightNav">
              <div className="rightNavNotification flexBox">
                  <li className="rightNavItems">
                    <NotificationButton />
                  </li>
                <li className="rightNavItems">
                  {pathname === "/create-wallet" ||
                  pathname === "/bank-details" ||
                  pathname === "/request-kyc" ||
                  pathname === "/IDVerification" ||
                  pathname === "/addBankAccount" ||
                  pathname === "/businessRegistration" ||
                  pathname === "/requestKYB" ||
                  pathname === "/business-id-verification" ||
                  pathname === "/productPlugin" ||
                  pathname === "/withdrawal" ||
                  pathname === "/instantbankTransfer" ||
                  pathname?.split("/")[1] === `supportticketDetail` ||
                  pathname === `/products` ||
                  pathname?.split("/")[1] === `orderTrack` ||
                  pathname?.split("/")[1] === `orderReview` ||
                  pathname === `/productUploadFile` ||
                  pathname === `/purchaseReport` ? (
                    <></>
                  ) : 
                    pathname === "/wallet" || pathname === "/productList" || pathname === "/service-list"
                    ? (
                    <div className="input-group search_group">
                      <img
                        src={SearchIcon}
                        alt=""
                        className="img-fluid searchIcon"
                      />
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => props?.setKeyword(e.target.value)}
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div className="rightNavBtnsParent">
                <li className="rightNavItems">
                  <button
                    className="webPosBtn"
                    type="button"
                    onClick={() => handleGoToWebPos()}
                  >
                    WebPos
                    <img
                      src={webPosArrow}
                      alt="posArrow image"
                      className="img-fluid"
                    />
                  </button>
                </li>

                {/* <li className="rightNavItems">
                  <button
                    className="webPosBtn"
                    type="button"
                    onClick={() => handleGoToWebPos()}
                  >
                    Take Payment
                    <img
                      src={webPosArrow}
                      alt="posArrow image"
                      className="img-fluid"
                    />
                  </button>
                </li> */}

                {pathname === "/plans" ||
                pathname === "/create-wallet" ||
                pathname === "/products" ||
                pathname === "/bank-details" ||
                pathname === "/request-kyc" ||
                pathname === "/IDVerification" ||
                pathname === "/addBankAccount" ||
                pathname === "/businessRegistration" ||
                pathname === "/requestKYB" ||
                pathname === "/business-id-verification" ||
                pathname === "/locations" ||
                pathname === "/productPlugin" ||
                pathname === "/withdrawal" ||
                pathname === "/Gross-Profits" ||
                pathname === "/Total-Revenue" ||
                pathname === "/Total-Costs" ||
                pathname === "/Total-POS-Orders" ||
                pathname === "/Total-Delivery-Orders" ||
                pathname === "/Total-Shipping-Orders" ||
                pathname === "/customer" ||
                pathname === "/instantbankTransfer" ||
                pathname === `/supportTicket` ||
                pathname?.split("/")[1] === `supportticketDetail` ||
                pathname?.split("/")[1] === `orderTrack` ||
                pathname?.split("/")[1] === `orderReview` ||
                pathname === `/productUploadFile` ||
                pathname === `/purchaseReport` ? (
                  <></>
                ) : (
                  // <li className="rightNavItems ">
                  //   <Link to="#" className="addNew_ takepaymnt_btn">
                  //     <span> Take Payment </span>
                  //   </Link>
                  // </li>
                  <></>
                )}

                {/* <li className="rightNavItems dropdown tableDropdown ">
                <Link
                  to="#"
                  className="addNew_ dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="barMenus"
                >
                  <span> Create New </span>{" "}
                  <img src={add_plus} className="icons_plus" alt="ChatImg" />
                </Link>
                <div
                  className="dropdown-menu tableDropdownMenus "
                  aria-labelledby="barMenus"
                >
                  <li
                    className="dropdown-item"
                    onClick={() => history.push("/campaign")}
                  >
                    {" "}
                    <img
                      src={add_plus}
                      className="img-fluid me-2"
                      alt="ChatImg"
                    />
                    Campaign
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => history.push("/myPurchases")}
                  >
                    {" "}
                    <img
                      src={purchaseImg}
                      className="img-fluid  me-2"
                      alt="ChatImg"
                    />
                    Purchase{" "}
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => history.push("/invoice")}
                  >
                    <img
                      src={invoiceHeader}
                      className="img-fluid  me-2"
                      alt="ChatImg"
                    />
                    Invoice
                  </li>
                </div>
              </li> */}
                {/* <li className='rightNavItems d-none'>
                <Link to='#'>
                  <img src={chat} className='icons_' alt='ChatImg' />
                </Link>
              </li> */}
                {pathname === "/products" ||
                pathname === "/productPlugin" ||
                pathname === "/withdrawal" ||
                pathname === "/Gross-Profits" ||
                pathname === "/Total-Revenue" ||
                pathname === "/Total-Costs" ||
                pathname === "/Total-POS-Orders" ||
                pathname === "/Total-Delivery-Orders" ||
                pathname === "/Total-Shipping-Orders" ||
                pathname === "/customer" ||
                pathname?.split("/")[1] === `orderTrack` ||
                pathname?.split("/")[1] === `orderReview` ||
                pathname === "/instantbankTransfer" ||
                pathname === `/supportTicket` ||
                pathname?.split("/")[1] === `supportticketDetail` ||
                pathname === `/productUploadFile` ||
                pathname === `/purchaseReport` ? (
                  <></>
                ) : (
                  <li className="rightNavItems dropdown tableDropdown me-0">
                    <Link
                      to="#"
                      className="addNew_ dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="barMenus"
                    >
                      <span className="addNewTxt">{t("newAdd")}</span>
                      <img src={addButtonImg} alt="addNew_Image"></img>
                    </Link>
                    <div
                      className="dropdown-menu tableDropdownMenus "
                      aria-labelledby="barMenus"
                    >
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          history.push("/find-product");
                          // setModalDetail({ show: true, flag: "FindProduct" });
                          // setKey(Math.random());
                        }}
                      >
                        {" "}
                        <img
                          src={Images.iconBox_}
                          className="img-fluid me-2"
                          alt="ChatImg"
                        />
                        {t("product")}
                      </li>
                      {/* <li
                        className="dropdown-item"
                        onClick={() => {
                          setModalDetail({
                            show: true,
                            flag: "AddNewCustomer",
                          });
                          setKey(Math.random());
                        }}
                      >
                        {" "}
                        <img
                          src={Images.UserIcon}
                          className="img-fluid  me-2"
                          alt="ChatImg"
                        />
                        Customer{" "}
                      </li> */}
                      {sellerType == "manufacturer" ||
                      sellerType == "whole_seller" ? (
                        ""
                      ) : (
                        <li
                          className="dropdown-item"
                          onClick={() => history.push("/add-service")}
                        >
                          <img
                            src={Images.serviceicon}
                            className="img-fluid  me-2"
                            alt="ChatImg"
                          />
                          {t("service")}
                        </li>
                      )}
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          history.push("/moresettingIndex?addLocation=1")
                          // setModalDetail({
                          //   show: true,
                          //   flag: "AddaddressModal",
                          // });
                          // setKey(Math.random());
                        }}
                      >
                        {" "}
                        <img
                          src={Images.locateicon_}
                          className="img-fluid me-2"
                          alt="ChatImg"
                        />
                        {t("address")}
                      </li>
                      {/* <li
                        className="dropdown-item"
                        onClick={() => history.push("/shippingPickup")}
                      >
                        {" "}
                        <img
                          src={Images.deliver_icon}
                          className="img-fluid  me-2"
                          alt="ChatImg"
                        />
                        Driver{" "}
                      </li> */}
                      <li
                        className="dropdown-item"
                        onClick={() => history.push("/createUser")}
                      >
                        <img
                          src={Images.staffimg}
                          className="img-fluid  me-2"
                          alt="ChatImg"
                        />
                        {t("staff")}
                      </li>
                    </div>
                  </li>
                )}
                <li className="rightNavItems  d-none">
                  <Link to="#" className="reviewBtn_">
                    <span>{t("orderToReview")}</span>
                  </Link>
                </li>
                {pathname?.split("/")[1] === `supportticketDetail` ||
                pathname === `/purchaseReport` ? (
                  <></>
                ) : (
                  // <li className="rightNavItems">
                  //   <Link to="#" onClick={() => handleGoToNotificationPage()}>
                  //     <img src={bell} className="icons_" alt="BellImg" />
                  //   </Link>
                  // </li>
                  <></>
                )}
              </div>
              {/* {pathname?.split("/")[1] === `supportticketDetail` ||
              pathname === `/purchaseReport` ? (
                <></>
              ) : (
                <li className="rightNavItems">
                  <div className="dropdown adminDropdown">
                    <Link
                      to="#"
                      id="dropdownAdminButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="nameUser">{`${auth?.payload?.user?.user_profiles?.firstname} ${auth?.payload?.user?.user_profiles?.lastname}`}</span>
                      <span className="userProfile">
                        <img
                          src={
                            auth?.payload?.user?.user_profiles?.profile_photo
                              ? auth?.payload?.user?.user_profiles
                                  ?.profile_photo
                              : profileImg1
                          }
                          className="userImg me-2"
                          alt="UserImage"
                        />
                        <i className="fal fa-chevron-down"></i>
                      </span>
                    </Link>
                    <ul
                      className="dropdown-menu dropdownAdminMenus"
                      aria-labelledby="dropdownAdminButton"
                    >
                      <li className="dropdown-item">
                        <Link to="/myProfile" className="dropdown-link">
                          Profile
                        </Link>
                      </li>
                    
                    </ul>
                  </div>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </nav>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop={true}
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "PickPlan"
            ? "pickPlan"
            : modalDetail.flag === "FindProduct"
            ? "findProduct"
            : modalDetail.flag === "MakeOffer"
            ? "createOffer"
            : modalDetail.flag === "AddaddressModal"
            ? "addaddressModal"
            : modalDetail.flag === "DefaultaddressModal"
            ? "defaultaddressModal"
            : modalDetail.flag === "AddNewCustomer"
            ? "addNewCustomer"
            : ""
        }
        size={
          modalDetail.flag === "FindProduct" ||
          modalDetail.flag === "MakeOffer" ||
          modalDetail.flag === "PickPlan"
            ? "xl"
            : modalDetail.flag === "AddaddressModal" ||
              modalDetail.flag === "AddaddressModal"
            ? "md"
            : modalDetail.flag === "paymentCardInfo" ||
              modalDetail.flag === "AddCard"
            ? "lg"
            : "md"
        }
        child={
          modalDetail.flag === "PickPlan" ? (
            <PlansModal
              details={(e) => details(e)}
              walletBalance={walletBalance}
              close={() => handleOnCloseModal()}
              openModalflag={(flag) => handleOpenModal(flag)}
            />
          ) : modalDetail.flag === "FindProduct" ? (
            <FindProductModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "AddaddressModal" ? (
            <AddaddressModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "DefaultaddressModal" ? (
            <DefaultaddressModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "AddNewCustomer" ? (
            <AddCustomerModal
              flag={pathname}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "paymentCardInfo" ? (
            <AddCardModal
              details={(e) => details(e)}
              close={() => handleOnCloseModal()}
              openModalflag={(flag) => handleOpenModal(flag)}
              selectedPlanId={selectedPlanId}
            />
          ) : modalDetail.flag === "AddCard" ? (
            <PaymentDetail
              selectedPlanId={selectedPlanId}
              setSelectedPlanId={setSelectedPlanId}
              close={() => handleOnCloseModal()}
              openModalflag={(flag) => handleOpenModal(flag)}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "PickPlan" ? (
            <>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3 className="heading_ mb-2">{t("planHeading")}</h3>
                  <p>{t("simplePricing")}</p>
                  <span
                    className="crossIcon"
                    onClick={() => handleOnCloseModal()}
                  >
                    <i className="las la-times" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </>
          ) : modalDetail.flag === "MakeOffer" ? (
            <div className="modalHeader_">
              <div className="common_">
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          ) : modalDetail.flag === "FindProduct" ? (
            <>
              <h4 className="modalHeading_">{t("addProducts")}</h4>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : modalDetail.flag === "DefaultaddressModal" ? (
            <>
              <div className="addheadLeft_">
                <h4 className="modalHeading_">{t("defaultAddress")} </h4>
              </div>
              <div className="addheadRight">
                <button className="addressdiscardBtn me-2" type="submit">
                  {t("discard")}
                </button>
                <button className="addresssaveBtn" type="submit">
                  {t("save")}
                </button>
              </div>
            </>
          ) : modalDetail.flag === "paymentCardInfo" ? (
            <>
              <h3 className="subheading_ mb-2">{t("cardDetails")}</h3>
              <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                <i className="las la-times" aria-hidden="true"></i>
              </span>
            </>
          ) : modalDetail.flag === "AddCard" ? (
            <>
              <h3 className="subheading_ mb-2">{t("addCard")}</h3>
              <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                <i className="las la-times" aria-hidden="true"></i>
              </span>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
}

// const mapStateToProps = state => ({
//   auth: state.auth
// });
// const mapDispatchToProps = dispatch => ({
//   openNav: () => dispatch(openNav()),

// });

export default DashboardNavbar;
