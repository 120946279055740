import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectWalletData } from '../wallet.slice';
import Loader from '../../../components/UI/Loader/Loader';


const WalletNotification = ({ updateSetting }) => {
    const { t } = useTranslation();
    const setting = useSelector(selectWalletData)?.walletSetting;
    const [loading, setLoading] = useState(false)

    const notifications = [
        { name: "refund", label: t("refund") },
        { name: "add_cash_to_drawer", label: t("addCashToDrawer") },
        { name: "remove_cash_from_drawer", label: t("removeCashDrawer") }
    ]

    const handleNotificationSetting = (e, type) => {
        const { checked, name } = e?.target;
        const data = { [type]: {} }
        data[type][name] = checked;
        setLoading(true)
        updateSetting(data);
        setTimeout(() => setLoading(false), 1500)
    }

    const appNotification = setting?.app_notifications;
    const emailNotifications = setting?.email_notifications;
    const smsNotification = setting?.sms_notifications;

    return (
        <div className='walletnotification_'>
            <div class="processignOrder p-0">
                { loading && <Loader />}
                <div class="allNotofication_  ">
                    <h3 class="headblue18">{t('inAppNotification')}</h3>
                    <div class="notification_">
                        {notifications.map((item, idx) => {
                            return (
                                <div class="toggleNotification_ mb-2" key={idx}>
                                    <h5 class="headingblue14_ text-capitalize">{item?.label}</h5>
                                    <input
                                        className='cursorPointer'
                                        checked={appNotification?.[item?.name]}
                                        onChange={(e) => handleNotificationSetting(e, "app_notifications")}
                                        type="checkbox" id="toggle" name={item.name} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div class="allNotofication_  mt-5">
                    <h3 class="headblue18">{t('smsNotification')}</h3>
                    <div class="notification_">
                        {notifications.map((item, idx) => {
                            return (
                                <div class="toggleNotification_ mb-2" key={idx}>
                                    <h5 class="headingblue14_ text-capitalize">{item?.label}</h5>
                                    <input
                                        className='cursorPointer'
                                        checked={smsNotification?.[item?.name]}
                                        onChange={(e) => handleNotificationSetting(e, "sms_notifications")}
                                        type="checkbox" id="toggle" name={item.name} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div class="allNotofication_  mt-5">
                    <h3 class="headblue18">{t('emaliNotification')}</h3>
                    <div class="notification_">
                        {notifications.map((item, idx) => {
                            return (
                                <div class="toggleNotification_ mb-2" key={idx}>
                                    <h5 class="headingblue14_ text-capitalize">{item?.label}</h5>
                                    <input
                                        className='cursorPointer'
                                        checked={emailNotifications?.[item?.name]}
                                        onChange={(e) => handleNotificationSetting(e, "email_notifications")}
                                        type="checkbox" id="toggle" name={item.name} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletNotification