import React from "react";
import * as Images from "../../../../utilities/images";
import { useTranslation } from "react-i18next";

const DeleteModal = (props) => {
  const { t } = useTranslation();
  const currentUser = props?.userData;
  const handleProductDeleteModal = () => {
    props?.delete(currentUser);
  };
  return (
    <div className="text-center">
      <img src={Images.crossCircle} alt="cancleLogo" />
      <div className="stopOfferModalContainer pt-4 pb-2">
        <h3 className="stopOfferTxt mb-0">{props?.title}</h3>
      </div>
      <p className="deleteModalActionTxt fontSize18">
        This action won’t able to undo
      </p>

      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
          disabled={props?.isDeleting}
        >
          {t('cancel')}
        </button>
        <button
          disabled={props?.isDeleting}
          className="modalNextBtn modalDiscardBtn modalStopBtn w-100"
          onClick={() => handleProductDeleteModal()}
        >
          <img src={Images.newDeleteIcon} alt="stopIcon" className="me-2" />
          {/* show loader*/}
          {props?.deleteLoading && (
            <span className="spinner-border spinner-border-sm spinnerRed"></span>
          )}
          {t('delete')}
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
