import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify";
import ImageUploader from "../product/imageUploader";

const EditVariantionModal = (props) => {
  const { modalDetail, closeModal, structureVariants, setStructureVariants } =
    props;

  const { index, variationname, groupIndex } = modalDetail;
  const toastId = useRef(null);

  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const [activeTab, setActiveTab] = useState("details");
  const [fileList, setFileList] = useState(
    structureVariants[groupIndex]?.variants[index]?.images || []
  );
  const [updateVariants, setUpdateVariants] = useState([...structureVariants]);
  const [sellerAddressIds, setSellerAddressIds] = useState(
    structureVariants[groupIndex]?.variants[index]?.seller_address_ids || []
  );
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleToggleTab = (plan) => setActiveTab(plan);

  const handleInputChange = (field, value) => {
    setUpdateVariants((prevVariants) => {
      const updatedVariants = JSON.parse(JSON.stringify(prevVariants)); // Deep copy
  
      const variant = updatedVariants[groupIndex]?.variants[index];
      if (!variant) return prevVariants;
  
      if (field === "pos" || field === "b2c") {
        if (!variant.prices) variant.prices = [];
  
        let priceObj = variant.prices.find((price) => price.app_name === field);
        if (!priceObj) {
          priceObj = { app_name: field, selling_price: "", margin_percentage: "" };
          variant.prices.push(priceObj);
        }
  
        const costPrice = parseFloat(variant.cost_price || 0);
        const sellingPrice = parseFloat(value);
        priceObj.selling_price = value;
        priceObj.margin_percentage = sellingPrice
          ? ((sellingPrice - costPrice) / sellingPrice) * 100
          : 0;
      } else {
        variant[field] = value;
      }
  
      return updatedVariants;
    });
  };
  

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;
    setSellerAddressIds((prevIds) => {
      const updatedIds = isChecked
        ? [...prevIds, storeId]
        : prevIds.filter((id) => id !== storeId);

      const updatedVariants = [...updateVariants];
      updatedVariants[groupIndex].variants[index] = {
        ...updatedVariants[groupIndex].variants[index],
        seller_address_ids: updatedIds,
      };

      setUpdateVariants(updatedVariants);
      return updatedIds;
    });
  };

  const handlePriceToggle = (appName, event) => {
    const isChecked = event.target.checked;
  
    setUpdateVariants((prevVariants) => {
      return prevVariants.map((group, gIdx) => {
        if (gIdx !== groupIndex) return group; // Keep other groups unchanged
  
        return {
          ...group,
          variants: group.variants.map((variant, vIdx) => {
            if (vIdx !== index) return variant; // Keep other variants unchanged
  
            let updatedPrices = variant.prices ? [...variant.prices] : [];
  
            if (isChecked) {
              updatedPrices.push({
                app_name: appName,
                selling_price: "",
                margin_percentage: "",
              });
            } else {
              updatedPrices = updatedPrices.filter(
                (price) => price.app_name !== appName
              );
            }
  
            return { ...variant, prices: updatedPrices };
          }),
        };
      });
    });
  };
  

  const handleSave = () => {
    const currentVariant = updateVariants[groupIndex]?.variants[index];
  
    if (!currentVariant) {
      showToast("Invalid variant selection.");
      return;
    }
  
    if (!currentVariant.barcode || currentVariant.barcode.length < 14) {
      showToast("UPC must be 14 characters long and cannot be empty.");
      return;
    }
  
    // const barCodes = updateVariants.flatMap((group) =>
    //   group.variants.map((variant) => variant.barcode)
    // );
  
    // if (barCodes.filter((barcode) => barcode === currentVariant.barcode).length > 1) {
    //   showToast("UPC must be unique across all variants.");
    //   return;
    // }
  
    if (
      parseInt(currentVariant.quantity_reorder_point || 0) >
      parseInt(currentVariant.quantity || 0)
    ) {
      showToast("Quantity Re-order Point cannot be greater than Quantity.");
      return;
    }
  
    setStructureVariants([...updateVariants]); 
    closeModal();
  };
  
  useEffect(() => {
    if (!modalDetail || !structureVariants[groupIndex]?.variants[index]) return;
  
    setUpdateVariants((prevVariants) => {
      const updatedVariants = [...prevVariants];
  
      if (updatedVariants[groupIndex]?.variants[index]) {
        updatedVariants[groupIndex].variants[index] = {
          ...updatedVariants[groupIndex].variants[index],
          image: fileList.length > 0 ? fileList[0] : "",
          images: [...fileList],
        };
      }
  
      return updatedVariants;
    });
  }, [fileList, groupIndex, index, structureVariants]);
  

  // useEffect(() => {
  //   if (!modalDetail || !structureVariants[groupIndex]?.variants[index]) return;
  
  //   setUpdateVariants((prevVariants) => {
  //     const updatedVariants = [...prevVariants];
  
  //     if (updatedVariants[groupIndex]?.variants[index]) {
  //       updatedVariants[groupIndex].variants[index] = {
  //         ...updatedVariants[groupIndex].variants[index],
  //         image: fileList.length > 0 ? fileList[0] : "",
  //         images: [...fileList],
  //       };
  //     }
  
  //     return updatedVariants;
  //   });
  // }, [fileList]); // Only track fileList, remove other dependencies
  
  return (
    <>
      <h3 className="fontSize24 fw500 txtDarkblue mb-0">Variant Detail</h3>

      {activeTab == "details" && (
        <>
          <div className="detailTab">
            <div className="form_group mt-3 mb-5">
              <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                Variation name
              </label>
              <div className="inputGroup">
                <input
                  className="customInput editVariantInput textCapital"
                  placeholder="Blue/L"
                  name="text"
                  type="text"
                  value={variationname}
                />
              </div>
            </div>

            <div className="outerArea EditVariation_ModalBox">
              <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                Product availability
              </h3>

              <div className="d-flex align-items-start mt-0">
                <img
                  src={Images.physicalStoreIcon}
                  alt="logo"
                  className="physicalStoreIcon me-3"
                />
                <div>
                  <div className="innnerParent">
                    <div className="productSale_Parent p-0 m-0">
                      <input
                        type="checkbox"
                        checked={updateVariants[groupIndex]?.variants[
                          index
                        ]?.prices?.some((price) => price.app_name === "pos")}
                        onChange={(e) => handlePriceToggle("pos", e)}
                      />
                    </div>
                    <div>
                      <h3 className="fontSize14 fw500 txtDarkblue">
                        Physical stores
                      </h3>
                      <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
                    </div>
                  </div>
                  {/* {allAddress.map((store, idx) => (
                    <div
                      key={store.id}
                      className="productAvailabilityChild mt-4"
                    >
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id={`store${store.id}`}
                          checked={sellerAddressIds.includes(store.id)}
                          onChange={handleStoreToggle(store.id)} // Correct usage
                        />
                        <label htmlFor={`store${store.id}`}></label>
                      </div>
                      <div>
                        <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                          Store {idx + 1}
                          
                        </h3>
                        <p className="fontSize12 txtLightBlue mb-0">
                          {store.street_address}, {store.city}, {store.state}{" "}
                          {store.zipcode}
                        </p>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>

              <div className="mt-5 d-flex align-items-start">
                <img
                  src={Images.jobrMarketplaceIcon}
                  alt="logo"
                  className="physicalStoreIcon me-3"
                />
                <div>
                  <div className="innnerParent">
                    <div className="productSale_Parent p-0 m-0">
                      <input
                        type="checkbox"
                        checked={updateVariants[groupIndex]?.variants[
                          index
                        ]?.prices?.some((price) => price.app_name === "b2c")}
                        onChange={(e) => handlePriceToggle("b2c", e)}
                      />
                    </div>
                    <div>
                      <h3 className="fontSize14 fw500 txtDarkblue">
                        JOBR Marketplace
                      </h3>
                      <h3 className="fontSize12 txtLightBlue mb-0">
                        Allow customers to book this service on JOBR Marketplace
                        app.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="editVariant_SecondBox">
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">UPC</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="888 1235647890"
                          name="barcode"
                          type="number"
                          maxLength={14}
                          value={
                            updateVariants[groupIndex]?.variants[index]
                              ?.barcode || ""
                          }
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue.length <= 14) {
                              handleInputChange("barcode", inputValue);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">POS Price</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="$0"
                          type="number"
                          value={
                            Array.isArray(
                              updateVariants[groupIndex]?.variants[index]
                                ?.prices
                            )
                              ? updateVariants[groupIndex]?.variants[
                                  index
                                ]?.prices.find((p) => p.app_name === "pos")
                                  ?.selling_price || ""
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange("pos", e.target.value)
                          }
                          disabled={
                            !Array.isArray(
                              updateVariants[groupIndex]?.variants[index]
                                ?.prices
                            ) ||
                            !updateVariants[groupIndex]?.variants[
                              index
                            ].prices.some((price) => price.app_name === "pos")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Marketplace Price</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="$0"
                          type="number"
                          value={
                            Array.isArray(
                              updateVariants[groupIndex]?.variants[index]
                                ?.prices
                            )
                              ? updateVariants[groupIndex]?.variants[
                                  index
                                ]?.prices.find((p) => p.app_name === "b2c")
                                  ?.selling_price || ""
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange("b2c", e.target.value)
                          }
                          disabled={
                            !Array.isArray(
                              updateVariants[groupIndex]?.variants[index]
                                ?.prices
                            ) ||
                            !updateVariants[groupIndex]?.variants[
                              index
                            ].prices.some((price) => price.app_name === "b2c")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Quantity</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="Quantity"
                          name="quantity"
                          type="number"
                          maxLength={14}
                          value={
                            updateVariants[groupIndex]?.variants[index]
                              ?.quantity || ""
                          }
                          onChange={(e) => {
                            handleInputChange("quantity", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">
                        Quantity Re-order Point
                      </h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="Quantity Re-order Point"
                          name="quantity_reorder_point"
                          type="number"
                          maxLength={14}
                          value={
                            updateVariants[groupIndex]?.variants[index]
                              ?.quantity_reorder_point || ""
                          }
                          onChange={(e) => {
                            handleInputChange(
                              "quantity_reorder_point",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Weight</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox position-relative">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="0"
                          type="number"
                          value={
                            updateVariants[groupIndex]?.variants[index]
                              ?.weight || ""
                          }
                          onChange={(e) =>
                            handleInputChange("weight", e.target.value)
                          }
                        />
                      </div>
                      <span className="weightMeasurementTxt">lb</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ImageUploader fileList={fileList} setFileList={setFileList} />
            <div className="d-flex align-items-center editVariantModal_Footer">
              <button
                className="modalDiscardBtn w-100"
                onClick={() => closeModal()}
              >
                Discard
              </button>
              <button
                className="modalNextBtn modalDiscardBtn active w-100"
                onClick={handleSave}
                type="button"
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditVariantionModal;
