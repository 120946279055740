import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLoginAuth, selectVerifyTwoFactor } from '../containers/auth/authSlice';

const Authenticator = (props) => {
  const history = useHistory();
  const auth = useSelector(selectLoginAuth)
  const twoFactorVerify = useSelector(selectVerifyTwoFactor)
  const pathName = window.location.pathname;
  const signupPaths = ["/", "/authenticate", "/twoAuthenticationModal", "/walletLogin"];

  const authenticateUser = (isAuthenticationRequired) => {
    let isSuperAdmin=auth?.payload?.user?.user_roles?.some(
      (e) => e?.role?.slug === "admin"
    );

    if(auth?.payload?.user?.user_profiles?.is_stripe_account_connected === false) {
      history.push('/connect-bank-account')
    } else 
    if ((auth?.payload?.token && auth?.payload?.user?.user_profiles?.is_two_fa_enabled == false) || (auth?.payload?.token && auth?.payload?.user?.user_profiles?.is_two_fa_enabled && twoFactorVerify)) {
      if(isSuperAdmin){
        history.push("/walletSetting");
        return;
      }
      if (signupPaths.includes(pathName)) {
        history.push('/new-user');
      }
    }
    else if (isAuthenticationRequired) {
      history.push('/');
    }
}

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, [])

  return (
    <>
      {props.children}
    </>
  )
}

export default Authenticator;