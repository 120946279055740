import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getPosUsersAsync,
  getRoleApiAsync,
  selectLoginAuth,
  updatPosUserApiAsync,
} from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Multiselect from "multiselect-react-dropdown";
import { getProductServiceListApiAsync } from "../myProducts/productSlice";
import TimePicker from "react-time-picker";
import { Dropdown, Form } from "react-bootstrap";
import { dayList, options } from "../../../utilities/helpers";
import * as Images from "../../../utilities/images";
import moment from "moment-timezone";
import { ChromePicker } from "react-color";
import { imageUploadAsync } from "../message/messageSlice";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

const UpdatePosUserModal = (props) => {
  const { t } = useTranslation();
  console.log(props, "propssssssssssss");
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const [firstName, setFirstName] = useState(
    props?.selectedRow?.user?.user_profiles?.firstname
  );
  const [document, setDocument] = useState(
    props?.selectedRow?.user?.user_profiles?.profile_photo
  );
  const [lastName, setLastName] = useState(
    props?.selectedRow?.user?.user_profiles?.lastname
  );
  const [email, setEmail] = useState(props?.selectedRow?.user?.email);
  const toastId = React.useRef(null);
  let selectedRoles = props?.selectedRow?.user?.user_roles?.map(
    (v) => v?.role?.id
  );
  const [posRole] = useState(selectedRoles);
  const [posDeletedRole, setPosDeletedRole] = useState([]);
  const [hourlyRate, setHourlyRate] = useState("");
  const [overtimeRate, setOvertimeRate] = useState("");
  const [paymentCycle, setPaymentCycle] = useState("Select Payment Cycle");
  const [billingCycle, setBillingCycle] = useState("Select Billing Cycle");
  const [employeeType, setEmployeeType] = useState("Select Employee Type");
  const [posNewRole, setPosNewRole] = useState(selectedRoles);
  const [oldServiceIds, setOldServiceIds] = useState([]);
  const [deletedServiceIds, setDeletedServiceIds] = useState([]);
  const [serviceIds, setServiceIds] = useState([]);
  const [allServiceData, setAllServiceData] = useState([]);
  const [selectedServiceData, setSelectedServiceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  console.log(loading, "loading1234");
  const [startMondayTime, setStartMondayTime] = useState("");
  const [endMondayTime, setEndMondayTime] = useState("");
  const [startTuesddayTime, setStartTuesdayTime] = useState("");
  const [endTuesdayTime, setEndTuesdayTime] = useState("");
  const [startWednesdayTime, setStartWednesdayTime] = useState("");
  const [endWednesdayTime, setEndWednesdayTime] = useState("");
  const [startThursdayTime, setStartThursdayTime] = useState("");
  const [endThursdayTime, setEndThursdayTime] = useState("");
  const [startFridayTime, setStartFridayTime] = useState("");
  const [endFridayTime, setEndFridayTime] = useState("");
  const [startSaturdayTime, setStartSaturdayTime] = useState("");
  const [endSaturdayTime, setEndSaturdayTime] = useState("");
  const [startSundayTime, setStartSundayTime] = useState("");
  const [endSundayTime, setEndSundayTime] = useState("");
  const [businessHours, setBusinessHours] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [overTimeAllowed, setOvertimeAllowed] = useState(
    props?.selectedRow?.is_over_time_allowed
  );
  const [tipsAllowed, setTipsAllowed] = useState(
    props?.selectedRow?.is_tips_allowed
  );
  const [color, setColor] = useState(props?.selectedRow?.color_code);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };
  let selectedOptionNew = [];

  function convertTo24HourFormat(time12Hour) {
    // Split the input time string into hours and minutes
    const [time, period] = time12Hour.split(" ");
    const [hours, minutes] = time.split(":");

    // Convert hours to an integer
    let hours24Hour = parseInt(hours, 10);

    // Adjust hours based on AM or PM
    if (period === "pm" && hours24Hour !== 12) {
      hours24Hour += 12;
    } else if (period === "am" && hours24Hour === 12) {
      hours24Hour = 0;
    }

    // Format hours and minutes with leading zeros
    const formattedHours = hours24Hour.toString().padStart(2, "0");
    const formattedMinutes = minutes.padStart(2, "0");

    // Combine the hours and minutes into the 24-hour format
    const time24Hour = `${formattedHours}:${formattedMinutes}`;

    console.log(time24Hour, time12Hour, "both time");

    return time24Hour;
  }

  const handleStartTimeChange = (newStartTime, flag) => {
    if (flag == "monday") {
      setStartMondayTime(newStartTime);
    }
    if (flag == "tuesday") {
      setStartTuesdayTime(newStartTime);
    }
    if (flag == "wednesday") {
      setStartWednesdayTime(newStartTime);
    }
    if (flag == "thursday") {
      setStartThursdayTime(newStartTime);
    }
    if (flag == "friday") {
      setStartFridayTime(newStartTime);
    }
    if (flag == "saturday") {
      setStartSaturdayTime(newStartTime);
    }
    if (flag == "sunday") {
      setStartSundayTime(newStartTime);
    }
  };

  const handleEndTimeChange = (newEndTime, flag) => {
    if (flag == "monday") {
      setEndMondayTime(newEndTime);
    }
    if (flag == "tuesday") {
      setEndTuesdayTime(newEndTime);
    }
    if (flag == "wednesday") {
      setEndWednesdayTime(newEndTime);
    }
    if (flag == "thursday") {
      setEndThursdayTime(newEndTime);
    }
    if (flag == "friday") {
      setEndFridayTime(newEndTime);
    }
    if (flag == "saturday") {
      setEndSaturdayTime(newEndTime);
    }
    if (flag == "sunday") {
      setEndSundayTime(newEndTime);
    }
  };

  const handleCheckboxChange1 = (option, flag) => {
    if (selectedOptions.find((val) => val?.id == option?.id)) {
      selectedOptionNew = selectedOptions.filter(
        (item) => item?.id !== option?.id
      );
      setSelectedOptions(selectedOptionNew);
    } else {
      selectedOptionNew = [...selectedOptions, option];
      setSelectedOptions([...selectedOptions, option]);
    }

    let startTime =
      flag == 1
        ? startMondayTime
        : flag == 2
        ? startTuesddayTime
        : flag == 3
        ? startWednesdayTime
        : flag == 4
        ? startThursdayTime
        : flag == 5
        ? startFridayTime
        : flag == 6
        ? startSaturdayTime
        : startSundayTime;

    let endTime =
      flag == 1
        ? endMondayTime
        : flag == 2
        ? endTuesdayTime
        : flag == 3
        ? endWednesdayTime
        : flag == 4
        ? endThursdayTime
        : flag == 5
        ? endFridayTime
        : flag == 6
        ? endSaturdayTime
        : endSundayTime;

    if (selectedOptionNew.find((day) => day.id == 1)) {
      setStartMondayTime(startTime);
      setEndMondayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 2)) {
      setStartTuesdayTime(startTime);
      setEndTuesdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 3)) {
      setStartWednesdayTime(startTime);
      setEndWednesdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 4)) {
      setStartThursdayTime(startTime);
      setEndThursdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 5)) {
      setStartFridayTime(startTime);
      setEndFridayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 6)) {
      setStartSaturdayTime(startTime);
      setEndSaturdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 7)) {
      setStartSundayTime(startTime);
      setEndSundayTime(endTime);
    }
  };
  const handleCopy = (flag) => {
    setSelectedOptions([]);
  };
  const handleCheckboxChange = (event) => {
    var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
    if (event.target.checked) {
      updatedList =
        businessHours?.length > 0
          ? [...businessHours, event.target.value]
          : [event.target.value];
    } else {
      updatedList.splice(businessHours?.indexOf(event.target.value), 1);
    }
    setBusinessHours(updatedList);
  };

  const filterData = () => {
    setStartMondayTime(
      props?.selectedRow?.pos_user_availability[0]?.opening_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[0]?.opening_time
          )
        : props?.selectedRow?.pos_user_availability[0]?.opening_time
    );
    setEndMondayTime(
      props?.selectedRow?.pos_user_availability[0]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[0]?.closing_time
          )
        : props?.selectedRow?.pos_user_availability[0]?.closing_time
    );
    setStartTuesdayTime(
      props?.selectedRow?.pos_user_availability[1]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[1]?.opening_time
          )
        : props?.selectedRow?.pos_user_availability[1]?.closing_time
    );
    setEndTuesdayTime(
      props?.selectedRow?.pos_user_availability[1]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[1]?.closing_time
          )
        : props?.selectedRow?.pos_user_availability[1]?.closing_time
    );
    setStartWednesdayTime(
      props?.selectedRow?.pos_user_availability[2]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[2]?.opening_time
          )
        : props?.selectedRow?.pos_user_availability[2]?.closing_time
    );
    setEndWednesdayTime(
      props?.selectedRow?.pos_user_availability[2]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[2]?.closing_time
          )
        : props?.selectedRow?.pos_user_availability[2]?.closing_time
    );
    setStartThursdayTime(
      props?.selectedRow?.pos_user_availability[3]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[3]?.opening_time
          )
        : props?.selectedRow?.pos_user_availability[3]?.closing_time
    );
    setEndThursdayTime(
      props?.selectedRow?.pos_user_availability[4]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[3]?.closing_time
          )
        : props?.selectedRow?.pos_user_availability[3]?.closing_time
    );
    setStartFridayTime(
      props?.selectedRow?.pos_user_availability[4]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[4]?.opening_time
          )
        : props?.selectedRow?.pos_user_availability[4]?.closing_time
    );
    setEndFridayTime(
      props?.selectedRow?.pos_user_availability[4]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[4]?.closing_time
          )
        : props?.selectedRow?.pos_user_availability[4]?.closing_time
    );
    setStartSaturdayTime(
      props?.selectedRow?.pos_user_availability[5]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[5]?.opening_time
          )
        : props?.selectedRow?.pos_user_availability[5]?.closing_time
    );
    setEndSaturdayTime(
      props?.selectedRow?.pos_user_availability[5]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[5]?.closing_time
          )
        : props?.selectedRow?.pos_user_availability[5]?.closing_time
    );
    setStartSundayTime(
      props?.selectedRow?.pos_user_availability[6]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[6]?.opening_time
          )
        : props?.selectedRow?.pos_user_availability[6]?.closing_time
    );
    setEndSundayTime(
      props?.selectedRow?.pos_user_availability[6]?.closing_time
        ? convertTo24HourFormat(
            props?.selectedRow?.pos_user_availability[6]?.closing_time
          )
        : props?.selectedRow?.pos_user_availability[6]?.closing_time
    );
    setBusinessHours(props?.selectedRow?.business_working_days);
    setHourlyRate(props?.selectedRow?.hourly_rate);
    setOvertimeRate(props?.selectedRow?.overtime_rate);
    setEmployeeType(
      props?.selectedRow?.employment_type
        ? props?.selectedRow?.employment_type
        : "Select Employee Type"
    );
    setPaymentCycle(
      props?.selectedRow?.payment_cycle
        ? props?.selectedRow?.payment_cycle
        : "Select Payment Cycle"
    );
    setBillingCycle(
      props?.selectedRow?.billing_type
        ? props?.selectedRow?.billing_type
        : "Select Billing Cycle"
    );
  };

  const dispatch = useDispatch();
  let selectedRoleUser = props?.selectedRow?.user?.user_roles?.map((item) => ({
    value: item?.role?.name,
    id: item?.role?.id,
  }));

  let selectedProductIds = props?.selectedRow?.pos_staff_products?.map(
    (item) => ({
      value: item?.product_id,
      id: item?.product_id,
    })
  );
  const [roleUser, setRoleUser] = useState([]);
  const auth = useSelector(selectLoginAuth);

  const handleClick = () => {
    if (!firstName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your first name");
      }
      return;
    }
    if (!lastName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your last name");
      }
      return;
    }
    if (!email) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your email");
      }
      return;
    }
    if (posNewRole?.length <= 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select atleast one role");
      }
      return;
    }
    let params = {
      postData: {
        pos_staff_id: props?.selectedRow?.id,
        firstname: firstName,
        lastname: lastName,
        email: email,
        color_code: color,
        is_over_time_allowed: overTimeAllowed,
        is_tips_allowed: tipsAllowed,
      },
      tokenData: auth?.payload?.token,
    };
    if (serviceIds?.length > 0 && deletedServiceIds?.length > 0) {
      params = {
        postData: {
          ...params.postData,
          product_ids: serviceIds,
          deleted_product_ids: deletedServiceIds,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (serviceIds?.length > 0) {
      params = {
        postData: {
          ...params.postData,
          product_ids: serviceIds,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (posNewRole?.length > 0) {
      params = {
        postData: {
          ...params.postData,
          role_ids: posNewRole,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (hourlyRate) {
      params = {
        postData: {
          ...params.postData,
          hourly_rate: hourlyRate,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (overtimeRate) {
      params = {
        postData: {
          ...params.postData,
          overtime_rate: overtimeRate,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (paymentCycle != "Select Payment Cycle") {
      params = {
        postData: {
          ...params.postData,
          payment_cycle: paymentCycle,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (billingCycle != "Select Billing Cycle") {
      params = {
        postData: {
          ...params.postData,
          billing_type: billingCycle,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (employeeType != "Select Employee Type") {
      params = {
        postData: {
          ...params.postData,
          employment_type: employeeType,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (posNewRole?.length > 0 && posDeletedRole?.length > 0) {
      params = {
        postData: {
          ...params.postData,
          role_ids: posNewRole,
          deleted_role_ids: posDeletedRole,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (document) {
      params = {
        postData: {
          ...params.postData,
          profile_photo: document,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (businessHours && businessHours?.length > 0) {
      params = {
        postData: {
          ...params.postData,
          business_working_days: businessHours,
          pos_user_availability: [
            {
              day: "1",
              opening_time: businessHours?.includes("monday")
                ? moment(startMondayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("monday")
                ? moment(endMondayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "2",
              opening_time: businessHours?.includes("tuesday")
                ? moment(startTuesddayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("tuesday")
                ? moment(endTuesdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "3",
              opening_time: businessHours?.includes("wednesday")
                ? moment(startWednesdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("wednesday")
                ? moment(endWednesdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "4",
              opening_time: businessHours?.includes("thursday")
                ? moment(startThursdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("thursday")
                ? moment(endThursdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "5",
              opening_time: businessHours?.includes("friday")
                ? moment(startFridayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("friday")
                ? moment(endFridayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "6",
              opening_time: businessHours?.includes("saturday")
                ? moment(startSaturdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("saturday")
                ? moment(endSaturdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "7",
              opening_time: businessHours?.includes("sunday")
                ? moment(startSundayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("sunday")
                ? moment(endSundayTime, "h:mm a").format("h:mm a")
                : "",
            },
          ],
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(updatPosUserApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            seller_id: auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
            // page: 1,
            // limit: 10
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getPosUsersAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            props?.close();
            if (props?.personalDetail) {
              let filterResult = obj?.payload?.pos_staff?.find(
                (val) =>
                  val?.user?.unique_uuid ==
                  (auth?.payload?.uniqe_id
                    ? auth?.payload?.uniqe_id
                    : auth?.payload?.user?.unique_uuid)
              );
              props.setPosList(filterResult);
            } else {
              props.setPosList(obj?.payload?.pos_staff);
            }
            let serviceSelected = allServiceData
              ?.filter((value) => serviceIds?.includes(value?.id))
              ?.map((v, i) => {
                return v?.value;
              });
            props?.assignedService(serviceSelected);
            setLoading(false);
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleOptionValue = (e) => {
    return <>{e}</>;
  };

  const handleSelect = (e) => {
    let allIds = e?.map((v) => {
      return v?.id;
    });
    setPosNewRole(allIds);
    let removedPosIds = posRole?.filter((item) => !allIds?.includes(item));
    setPosDeletedRole(removedPosIds);
  };

  const handleServiceSelect = (e) => {
    let allIds = e?.map((v) => {
      return v?.id;
    });
    setServiceIds(allIds);
    let removedElementsIds = oldServiceIds?.filter(
      (item) => !allIds?.includes(item)
    );
    setDeletedServiceIds(removedElementsIds);
    // let newData = oldServiceIds?.filter()
  };

  const serviceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "digital",
        filter_deleted: true,
        app_name: "merchant",
      },
    };
    dispatch(getProductServiceListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        const allServiceData = obj?.payload?.data?.map((item) => ({
          value: item?.name,
          id: item?.id,
        }));
        setAllServiceData(allServiceData);
        let oldServiceIds = props?.selectedRow?.pos_staff_products?.map(
          (v) => v?.product_id
        );
        setOldServiceIds(oldServiceIds);
        setServiceIds(oldServiceIds);
        let filteredSelectedServices = obj?.payload?.data
          ?.filter((item) => oldServiceIds?.includes(item.id))
          ?.map((item) => ({
            value: item?.name,
            id: item.id,
          }));
        setSelectedServiceData(filteredSelectedServices);
        // let selectedProductFilter = props?.selectedRow?.pos_staff_products?.filter((val => ))
        // let selectedProductFilter= props?.selectedRow?.pos_staff_products.filter(item => newArr.includes(item));
        // props?.selectedRow?.pos_staff_products
        // setSelectedServiceData()
      })
      .catch((err) => {});
  };

  useEffect(() => {
    serviceHandle();
    let data = {
      user_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
    };
    dispatch(getRoleApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        const modifiedData = obj.payload.roles.map((item) => ({
          value: item.name,
          id: item.id,
        }));
        setRoleUser(modifiedData);
      })
      .catch((obj) => {});
  }, []);

  useEffect(() => {
    if (props?.selectedRow?.pos_user_availability?.length > 0) {
      filterData();
    }
  }, []);

  let imageFile;
  const handleChange = (e) => {
    e.preventDefault();
    let loadingToast = toast.loading("Image Upload Progress");
    imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    setImageLoading(true);
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setImageLoading(false);
        toast.dismiss();
        setDocument(obj?.payload[0]?.filePath);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
      })
      .catch((obj) => {
        setImageLoading(false);
        toast.dismiss();
      });
  };

  const paymentCycleSelectOption = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];
  const billingTypeSelectOption = [
    { value: "automatic", label: "Automatic" },
    { value: "manual", label: "Manual" },
  ];
  const employeeTypeSelectOption = [
    { value: "full_time", label: "Full time" },
    { value: "part_time", label: "Part time" },
  ];

  return (
    <>
      {imageLoading ? (
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <div className="uploadStaffImg m-0 mb-2">
          <div className="showImg_Parent">
            {document && <img src={document} className="staff_Profile_Img" />}
            <label htmlFor="file-upload" className="uploadFileLabel">
              <img
                src={Images.uploadImgCamera}
                alt="img"
                className="uploadStaffCameraImg"
              />
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/jpeg, image/png"
              name="profile_image"
              className="d-none"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-6">
          <div className="form_group">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('firstNameSin')}
            </label>
            <input
              type="text"
              className="customform-control"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('lastNameSin')}
            </label>
            <input
              type="text"
              className="customform-control"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('email')}
            </label>
            <input
              type="text"
              className="customform-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              Pos Role
            </label>
            <Multiselect
              className=" signupSelect customform-control multiSelect_"
              displayValue="value"
              options={roleUser}
              loading={loading}
              isObject={true}
              placeholder="Select Roles"
              hidePlaceholder={false}
              selectedValues={selectedRoleUser}
              // selectionLimit={1}
              onSelect={handleSelect}
              onRemove={handleSelect}
              optionValueDecorator={(e) => handleOptionValue(e)}
              selectedValueDecorator={(e) => handleOptionValue(e)}
              avoidHighlightFirstOption={true}
              showCheckbox
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('selectService')}
            </label>
            <Multiselect
              className="signupSelect customform-control multiSelect_"
              displayValue="value"
              options={allServiceData}
              loading={loading}
              isObject={true}
              placeholder="Select Service"
              hidePlaceholder={false}
              selectedValues={selectedServiceData}
              // selectionLimit={1}
              onSelect={handleServiceSelect}
              onRemove={handleServiceSelect}
              optionValueDecorator={(e) => handleOptionValue(e)}
              selectedValueDecorator={(e) => handleOptionValue(e)}
              avoidHighlightFirstOption={true}
              showCheckbox
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('hourlyRate')}
            </label>
            <input
              type="number"
              name="hourly_Rate"
              className="customform-control"
              placeholder="Please Enter the hourly rate"
              autoComplete="off"
              onKeyDown={blockInvalidChar}
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('overtimeRate')}
            </label>
            <input
              type="number"
              name="overtime_rate"
              className="customform-control"
              placeholder="Please Enter the overtime rate"
              autoComplete="off"
              onKeyDown={blockInvalidChar}
              value={overtimeRate}
              onChange={(e) => setOvertimeRate(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('paymentCycle')}
            </label>
            {/* <select
              className="select customform-control"
              value={paymentCycle}
              name="payment_cycle"
              id="payment_cycle"
              onChange={(e) => setPaymentCycle(e.target.value)}
            >
              <option value="Select Payment Cycle" disabled>
                Select Payment Cycle
              </option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select> */}
            <div className="reactSelectParent commonSelectParent w-100">
              <ReactSelect
                options={paymentCycleSelectOption}
                isSearchable={true}
                onChange={(selectedOption) => {
                  setPaymentCycle(selectedOption.value);
                }}
                placeholder="Select one"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('billingType')}
            </label>
            {/* <select
              className="select customform-control"
              value={billingCycle}
              name="billing_cycle"
              id="billing_cycle"
              onChange={(e) => setBillingCycle(e.target.value)}
            >
              <option value="Select Billing Cycle" disabled>
                Select Billing Cycle
              </option>
              <option value="automatic">Automatic</option>
              <option value="manual">Manual</option>
            </select> */}
            <div className="reactSelectParent commonSelectParent w-100">
              <ReactSelect
                options={billingTypeSelectOption}
                isSearchable={true}
                onChange={(selectedOption) => {
                  setBillingCycle(selectedOption.value);
                }}
                placeholder="Select one"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2 ps-4"
              htmlFor="amount"
            >
              {t('employeeType')}
            </label>
            {/* <select
              className="select customform-control"
              value={employeeType}
              name="employee_type"
              id="employee_type"
              onChange={(e) => setEmployeeType(e.target.value)}
            >
              <option value="Select Employee Type" disabled>
                Select Employee Type
              </option>
              <option value="full_time">Full time</option>
              <option value="part_time">Part time</option>
            </select> */}
            <div className="reactSelectParent commonSelectParent w-100">
              <ReactSelect
                options={employeeTypeSelectOption}
                isSearchable={true}
                onChange={(selectedOption) => {
                  setEmployeeType(selectedOption.value);
                }}
                placeholder="Select one"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    paddingLeft: "unset !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#263682",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group flexBox gap-2">
            <label
              className="fontSize14 fw500 txtDarkblue ps-4"
              htmlFor="amount"
            >
              {t('overTimeAllowed')}
            </label>
            <div className="productSale_Parent p-0 m-0 staffCheckBoxParent">
              <input
                type="checkbox"
                checked={overTimeAllowed}
                onChange={() => {
                  setOvertimeAllowed((prev) => !prev);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group flexBox gap-2">
            <label
              className="fontSize14 fw500 txtDarkblue ps-4"
              htmlFor="amount"
            >
              {t('tipsAllowed')}
            </label>
            <div className="productSale_Parent p-0 m-0 staffCheckBoxParent">
              <input
                type="checkbox"
                checked={tipsAllowed}
                onChange={() => {
                  setTipsAllowed((prev) => !prev);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue ps-4 mb-2"
              htmlFor="amount"
            >
              {t('selectColor')}:
            </label>
            <div className="staffColorPickerParent">
              <ChromePicker color={color} onChange={handleColorChange} />
              <div
                style={{
                  marginLeft: "24px",
                  width: "50px",
                  height: "50px",
                  borderRadius: "12px",
                  background: color,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form_group ">
            <label
              className="fontSize14 fw500 txtDarkblue mb-2"
              htmlFor="amount"
            >
              {t('availability')}
            </label>
            <div className="checkboxGroup businessChekbox d-block staffAvailability">
              {dayList.map((item, index) => {
                return (
                  <div className="row align-items-center mb-3" key={index}>
                    <div className="col-md-3">
                      <div className="flexBox">
                        <input
                          type="checkbox"
                          id={item.day}
                          className="checkBox"
                          name={item.day}
                          value={item.day}
                          onChange={(e) => handleCheckboxChange(e)}
                          checked={businessHours?.includes(item.day)}
                        />
                        <label
                          htmlFor={item.day}
                          className="checkBoxTxt"
                        ></label>
                        <h3 className="fontSize18 businessUserName mb-0">
                          {item.value}
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-9">
                      {businessHours?.includes(item.day) && (
                        <>
                          <div className="flexBox justify-content-end gap-4">
                            <TimePicker
                              onChange={(e) =>
                                handleStartTimeChange(e, item.day)
                              }
                              value={
                                item.id == 1
                                  ? startMondayTime
                                  : item.id == 2
                                  ? startTuesddayTime
                                  : item.id == 3
                                  ? startWednesdayTime
                                  : item.id == 4
                                  ? startThursdayTime
                                  : item.id == 5
                                  ? startFridayTime
                                  : item.id == 6
                                  ? startSaturdayTime
                                  : startSundayTime
                              }
                              format="h:mm a"
                              showLeadingZeros={false}
                              amPmAriaLabel="Select AM/PM"
                              className="custom-time-picker customPicker"
                            />

                            <TimePicker
                              onChange={(e) => handleEndTimeChange(e, item.day)}
                              value={
                                item.id == 1
                                  ? endMondayTime
                                  : item.id == 2
                                  ? endTuesdayTime
                                  : item.id == 3
                                  ? endWednesdayTime
                                  : item.id == 4
                                  ? endThursdayTime
                                  : item.id == 5
                                  ? endFridayTime
                                  : item.id == 6
                                  ? endSaturdayTime
                                  : endSundayTime
                              } // Use the 12-hour formatted time string directly
                              format="hh:mm a" // Specify the format as 12-hour with AM/PM
                              className="custom-time-picker customPicker"
                            />

                            <Dropdown
                              onClick={() => {
                                handleCopy(item.id);
                              }}
                              className="dropdownToggleBtn"
                            >
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                                
                              >
                                <label className="iconcopy_ mb-0">
                                  <i className="fa-solid fa-copy"></i>
                                </label>
                              </Dropdown.Toggle>
                              <div className="staffAvailDropdown">
                                <Dropdown.Menu>
                                  <Form className="formCheckParent">
                                    {options.map((option) => (
                                      <Form.Check
                                        key={option.id}
                                        type="checkbox"
                                        label={option.label}
                                        id={`option-${option.id}`}
                                        checked={selectedOptions.find(
                                          (val) => val?.id == option?.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange1(option, item.id)
                                        }
                                      />
                                    ))}
                                  </Form>
                                </Dropdown.Menu>
                              </div>
                            </Dropdown>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="footerSec mt-3">
          <button className="closebtnModal me-4" onClick={() => props.close()}>
            Cancel
          </button>
          <button
            className="appointBtn"
            onClick={() => handleClick()}
            disabled={loading === true}
          >
            {loading === true && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp; Update
          </button>
        </div> */}
      </div>
      <div className="modalfooterBtn align-items-center">
        <button type="button" className={`modalDiscardBtn w-100`} onClick={()=>props?.close()}>
          {t('cancel')}
        </button>
        <button
          type="button"
          className={`modalNextBtn modalDiscardBtn w-100 active`}
          onClick={() => handleClick()}
          disabled={loading === true}
        >
          {loading === true && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp; {t('update')}
        </button>
      </div>
    </>
  );
};

export default UpdatePosUserModal;
