import React, { forwardRef, useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  deleteGiftCardApiAsync,
  getAllGiftCardAsync,
  getGiftCategoriesAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectLoginAuth } from "../auth/authSlice";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import CustomModal from "../../components/shared/CustomModal";
import DataTable from "react-data-table-component";
import * as Images from "../../../utilities/images";
import DatePicker from "react-datepicker";
import GiftDetails from "./GiftDetails";
import CreateGift from "./CreateGift";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import PaginationHeader from "../../components/PaginationHeader";
import DeleteGiftcardModal from "./DeleteGiftcardModal";
import { useTranslation } from "react-i18next";

const GiftCards = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [giftList, setGiftList] = useState([]);
  const [selectedGift, setSelectedGift] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(Math.random());
  // const [startDate, setStartDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [giftCategories, setgiftCategories] = useState([]);
  const [giftId, setGiftId] = useState("");

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const getGiftCardList = (page) => {
    setLoading(true);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        page: page ? page : currentPage,
        limit: perPage,
        ...(startDate && {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(startDate).format("YYYY-MM-DD")
        })
      },
    };
    dispatch(getAllGiftCardAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setGiftList(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const deleteHandle = () => {
    // Swal.fire({
    //   title: "Do you want to delete this gift card?",
    //   showCancelButton: true,
    //   confirmButtonText: "Delete",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    let params = {
      postData: {
        id: giftId,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(deleteGiftCardApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        getGiftCardList();
        toast.success(obj?.msg);
        handleOnCloseModal()
      })
      .catch((obj) => {});
    //   }
    // });
  };

  useEffect(() => {
    getGiftCardList();
  }, [currentPage, perPage, startDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Gift Cards";
  }, []);
  
  const getGiftCategories = () => {
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        limit: 10,
        page: 1,
      },
    };
    dispatch(getGiftCategoriesAsync(params))
      .then(unwrapResult)
      .then((res) => {
        let categoryList = res.payload?.data || [];
        let categories = categoryList?.map((category) => {
          return { value: category.id, label: category.title };
        });
        setgiftCategories(categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getGiftCategories();
    return () => {
      setgiftCategories([]);
    };
  }, []);

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <div className="d-flex align-items-center gap-2">
          <img
            src={Images.lightCalenderLogo}
            alt="img"
            width={24}
            height={24}
          />
          {value}
        </div>
        <img
          src={Images.blueDownArrow}
          width={24}
          height={24}
          alt="drop_icon"
        />
      </button>
    )
  );

  return (
    <>
      <DashboardNavbar title={t('giftCard')} backShow={false} />

      <div className="walletStats h-100">
        <div className="">
          <div className="giftBoxInnerHeader">
            {/* <div className="col-3"> */}
            {/* <div className="calenderIcon"> */}
            {/* <img src={calendar} alt="calender" />
                <DatePicker
                  className="cateDate"
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Date"
                /> */}
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<ExampleCustomInput className="datePickerInput" />}
            />
            {/* </div> */}
            {/* </div> */}
            {/* <div className="col-9"> */}
            <div className="flexBox gap-3 flex-wrap justify-content-end">
              <PaginationHeader
                currentPage={currentPage}
                totalPage={totalRows}
                perPage={perPage}
                setCurrentPage={setCurrentPage}
                setPerPage={setPerPage}
                dataLength={giftList?.length}
                headerClass="giftPagination"
              />
              {/* <div className="col-4"> */}
              <button
                className="commonBlueBtn active"
                type="button"
                onClick={() => {
                  setModalDetail({ show: true, flag: "MakeGift" });
                  setKey(Math.random());
                }}
              >
                {t("createGiftCard")}
              </button>
              {/* </div> */}
            </div>
            {/* </div> */}
          </div>
        </div>
        <div className="commonTable table-responsive mt-3">
          <table className="w-100">
            <thead>
              <tr>
                <th className="text-start">#</th>
                <th className="text-start">{t("name")}</th>
                <th>{t("giftAmount")}</th>
                <th>{t("giftValidityDate")}</th>
                <th>{t("updatedAt")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr className="text-center border-none">
                  <td colSpan="100%">
                    <div className="d-flex">
                      <span className="spinner-border inner-spin spinner-border-sm"></span>
                    </div>
                  </td>
                </tr>
              ) : giftList?.length > 0 ? (
                giftList.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td className="orderTrackTableTxt text-start">
                        {(index + 1) + (perPage * (currentPage - 1))}
                      </td>
                      <td>
                        <div className="flexBox gap-2">
                          <img
                            src={value?.image ||  Images.userImg_}
                            alt="img"
                            className="userInnerImg"
                          />
                          <h3 className="orderTrackTableTxt mb-0">
                            {value?.title?.length > 15
                              ? value?.title?.substring(0, 15) + "..."
                              : value?.title}
                          </h3>
                        </div>
                      </td>
                      <td className="orderTrackTableTxt">
                        {addThousandSeparator(value?.gift_amount) ? "$" : ""}
                        {addThousandSeparator(value?.gift_amount)}
                      </td>
                      <td className="orderTrackTableTxt">
                        {moment?.utc(value?.end_date)?.format("MMM DD, YYYY")}
                      </td>

                      <td className="orderTrackTableTxt">
                        {moment?.utc(value?.updated_at)?.format("MMM DD, YYYY")}
                      </td>

                      <td className="text-end">
                        <Dropdown className="createRoleTableDropDown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="variantsTable_Dropdown"
                          >
                            <img src={Images.moreVerticalLogo} alt="logo" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedGift(value);
                                setModalDetail({
                                  show: true,
                                  flag: "viewGift",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <div className="d-flex gap-2 align-items-center">
                                <img src={Images.darkBlueEyeIcon} alt="logo" />
                                <h3 className="fontSize14 mb-0">
                                  View Details
                                </h3>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => {
                                setSelectedGift(value);
                                setModalDetail({
                                  show: true,
                                  flag: "editGift",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <div className="d-flex gap-2 align-items-center">
                                <img src={Images.darkblueEditIcon} alt="logo" />
                                <h3 className="fontSize14 mb-0">Edit</h3>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => {
                                setGiftId(value?.id);
                                setModalDetail({
                                  show: true,
                                  flag: "deleteGiftcarModal",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <div className="d-flex gap-2 align-items-center">
                                <img src={Images.newDeleteIcon} alt="logo" />
                                <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                  Delete
                                </h3>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center border-none">
                  <td colSpan="100%">
                    <div className="d-flex">
                      <p className="text-center w-100">{t("noDataFound")}</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop={true}
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={
            modalDetail.flag === "viewGift"
              ? "viewGiftModal"
              : modalDetail.flag === "MakeGift"
              ? "createGiftModal"
              : modalDetail.flag === "editGift"
              ? "createGiftModal"
              : modalDetail.flag === "deleteGiftcarModal"
              ? "stopOfferModal"
              : ""
          }
          size={
            modalDetail.flag === "viewGift" || modalDetail.flag === "MakeGift"
              ? "xl"
              : "md" ||
                modalDetail.flag === "editGift" ||
                modalDetail.flag === "MakeGift"
              ? "xl"
              : "md"
          }
          child={
            modalDetail.flag === "MakeGift" ? (
              <CreateGift
                close={() => handleOnCloseModal()}
                getGiftCardList={() => getGiftCardList()}
                giftCategories={giftCategories}
              />
            ) : modalDetail.flag === "viewGift" ? (
              <GiftDetails
                selectedGift={selectedGift}
                close={() => handleOnCloseModal()}
              />
            ) : modalDetail.flag === "editGift" ? (
              <CreateGift
                close={() => handleOnCloseModal()}
                getGiftCardList={() => getGiftCardList()}
                giftCategories={giftCategories}
                selectedGift={selectedGift}
                editMode={true}
              />
            ) : modalDetail.flag === "deleteGiftcarModal" ? (
              <DeleteGiftcardModal
                selectedGift={selectedGift}
                close={() => handleOnCloseModal()}
                handleDelete={() => deleteHandle()}
              />
            ) : (
              ""
            )
          }
          header={
            <>
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                {modalDetail.flag === "MakeGift"
                  ? "Create Gift Card"
                  : "" || modalDetail.flag === "editGift"
                  ? "Update Gift Card"
                  : "" || modalDetail.flag === "viewGift"
                  ? "Gift Card Details"
                  : ""}
              </h2>
              {modalDetail.flag === "MakeGift" ||
              modalDetail.flag === "editGift" ||
              modalDetail.flag === "viewGift" ? (
                <img
                  src={Images.modalCross}
                  alt="img"
                  onClick={() => handleOnCloseModal()}
                  className="cursorPointer"
                />
              ) : (
                ""
              )}
            </>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default GiftCards;
