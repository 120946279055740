import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { selectedPosUserData, selectedPosUserDetails, selectLoginAuth } from "../../auth/authSlice";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import {
  CountrySelect,
  StateSelect,  
  CitySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PhoneInput from "react-phone-input-2";
import ReactSelect from "react-select";
import { dayList } from "../../../../utilities/helpers";
import TimePicker from "react-time-picker";
import {
  daysName,
  weekOptions,
} from "../../../constants/moreSettings/moreSettings.constansts";
import { toast } from "react-toastify";
import {
  setupAddressApiAsync,
  updateSellerInfoApiAsync,
} from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
const AddLocation = ({ setmoreSetting,getSellerAddressDetails }) => {
  const { t } = useTranslation();
  const [locationDetail, setlocationDetail] = useState({
    business_name: "",
    address_type: "",
    street_address: "",
    apt: "",
    country: "",
    country_code: "",
    zipcode: "",
    state: "",
    state_code: "",
    phone_no:"",
    phone_code:"",
    city: "",
    latitude: 0,
    longitude: 0,
    business_details:"",
    product_availability: {
      availability: [],
    },
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [hide, setHide] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const [formattedAddress, setFormattedAddress] = useState("");
  const userDetails = useSelector(selectedPosUserDetails);
  useEffect(() => {
    if (userDetails) {
      let org_name = userDetails?.payload?.user?.user_profiles?.organization_name;
      // if (org_name) {
      //   setlocationDetail({ ...locationDetail, business_name: org_name });
      // }
    }
  }, [userDetails]);
  const checkIfFieldEmpty = (obj) => {
    let keyName = null;
    for (let key in obj) {
      if (obj[key] instanceof Object) continue; // Skip nested objects
      if (obj[key] == null || obj[key] === "") {
        keyName = key;
        break;
      }
    }
    return keyName;
  };

  const createLocation = (params) => {
    dispatch(setupAddressApiAsync(params))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false);
        setmoreSetting("businessLocation");
        // getSellerAddressDetails();
      })
      .catch((err) => {
        setLoading(false);
        // toast.error("Failed to create address");
      });
  };
  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setDisplayValue(results[0]?.formatted_address);

        fillAddressDetails(results);
        handleLocationDetails(null, {
          name: "latitude",
          value: results[0].geometry.location.lat(),
        });
        handleLocationDetails(null, {
          name: "longitude",
          value: results[0].geometry.location.lng(),
        });
        setHide(true);
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };
  const fillAddressDetails = (results) => {
    handleLocationDetails(null, {
      name: "street_address",
      value: results[0].formatted_address,
    });
    handleLocationDetails(null, {
      name: "format_address",
      value: results[0].formatted_address,
    });

    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        handleLocationDetails(null, {
          name: "zipcode",
          value: results[0].address_components[j].short_name,
        });
      } else if (results[0].address_components[j].types[0] == "locality") {
        handleLocationDetails(null, {
          name: "city",
          value: results[0].address_components[j].long_name,
        });
      } else if (
        results[0].address_components[j].types[0] ==
          "administrative_area_level_1" ||
        results[0].address_components[j].types[0] ===
          "administrative_area_level_3" ||
        results[0].address_components[j].types[0] === "locality"
      ) {
        handleLocationDetails(null, {
          name: "state",
          value: results[0].address_components[j].long_name,
        });
        handleLocationDetails(null, {
          name: "state_code",
          value: results[0].address_components[j]?.short_name,
        });
      } else if (results[0].address_components[j].types[0] == "country") {
        handleLocationDetails(null, {
          name: "country",
          value: results[0].address_components[j].long_name,
        });
        handleLocationDetails(null, {
          name: "country_code",
          value: results[0].address_components[j].short_name,
        });
      } else if (results[0].address_components[j].types[0] == "street_number") {
        handleLocationDetails(null, {
          name: "apt",
          value: results[0].address_components[j].long_name,
        });
      } else if (results[0].address_components[j].types[0] == "route") {
        handleLocationDetails(null, {
          name: "street_address",
          value: results[0].address_components[j].long_name,
        });
      }
    }
  };

  const autoCompleteHandleChange = (address) => {
    setFormattedAddress(address);
  };
  const [businessHours, setBusinessHours] = useState([]);
  const [timeData, setTimeData] = useState({
    monday: { start: "09:00", end: "23:00" },
    tuesday: { start: "09:00", end: "23:00" },
    wednesday: { start: "09:00", end: "23:00" },
    thursday: { start: "09:00", end: "23:00" },
    friday: { start: "09:00", end: "23:00" },
    saturday: { start: "09:00", end: "23:00" },
    sunday: { start: "09:00", end: "23:00" },
  });

  const handleStartTimeChange = (newStartTime, flag) => {
    handleTimeChange(newStartTime, flag, "start");
  };
  const handleTimeChange = (newTime, flag, type) => {
    console.log(newTime,flag,type,"Check once")
    if(newTime){
      let [hours, minutes] = newTime?.split(":").map(Number);

    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${period}`;
    console.log(timeData,"check it")

    setTimeData((prevData) => ({
      ...prevData,
      [flag]: {
        ...prevData[flag],
        [type]: formattedTime,
      },
    }));
    }else{
      setTimeData((prevData) => ({
        ...prevData,
        [flag]: {
          ...prevData[flag],
          [type]: "",
        },
      }));
    }
   
  };

  const handleEndTimeChange = (newEndTime, flag) => {
    handleTimeChange(newEndTime, flag, "end");
  };

  const handleCheckboxChange1 = (event) => {
    var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
    if (event.target.checked) {
      updatedList =
        businessHours?.length > 0
          ? [...businessHours, event.target.value]
          : [event.target.value];
    } else {
      updatedList.splice(businessHours?.indexOf(event.target.value), 1);
    }
    setBusinessHours(updatedList);
  };
  const handleSubmit = () => {
    if (loading) return;
    let isFiledEmpty = checkIfFieldEmpty(locationDetail);
    if (isFiledEmpty || businessHours.length == 0) {
      if (businessHours.length == 0) {
        toast.error("Select at least one business hour");
        return;
      } else toast.error(`${isFiledEmpty} is required`);

      return;
    }
    if(locationDetail.phone_no?.length!=10){
      toast.error("Phone digit should be 10");
      return;
    }
    let bussinessHourDetails = convertBusinessHours();
    let params = {
      postData: {
        ...locationDetail,
        product_availability: {
          availability: bussinessHourDetails,
        },
        service_availability:{
          availability: bussinessHourDetails,
        }
      },
      tokenData: auth.payload?.token,
    };
    setLoading(true);

    //create new location
    createLocation(params);
  };
  const convertBusinessHours = () => {
    let bussinessHourDetails = [];

    for (let day of businessHours) {
      if (timeData[day].start != "" && timeData[day].end != "") {
        let index = daysName.findIndex((val) => val == day);
        bussinessHourDetails.push({
          day: index + 1,
          opening_time: timeData[day].start,
          closing_time: timeData[day].end,
        });
      }
    }
    return bussinessHourDetails;
  };
  const handleLocationDetails = (event, data) => {
    let name = "";
    let value = "";
    if (event) {
      name = event?.target?.name;
      value = event?.target?.value;
    } else if (data) {
      name = data?.name;
      value = data?.value;
    }
    setlocationDetail((prev) => ({ ...prev, [name]: value }));
  };

  const addressOptions = [
    { value: "store", label: "Store" },
    { value: "ware_house", label: "Ware House" },
  ];
  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    handleLocationDetails(null,{name:"phone_no",value:phoneNumber});
    handleLocationDetails(null,{name:"phone_code",value:phoneCode});
  };
  return (
    <>
      <div className="featureNotication additionalsetmain_ p-0 addEditBusLoc position-relative">
        <div className="d-flex align-items-center justify-content-between">
          <h3
            onClick={() => {
              if (loading) return;
              setmoreSetting("businessLocation");
            }}
            className="fontSize24 fw500 txtdarkblue mb-0"
          >
            <img
              className="ordersetting me-2"
              src={Images.leftarrow_}
              alt="arrow"
            />
            {t("addLocation")}
          </h3>
          <button
            disabled={loading}
            onClick={handleSubmit}
            className="editbtn_"
          >
            {/* show loader */}
            {loading && (
              <div
                className="spinner-border text-primary"
                role="status"
                aria-hidden="true"
              ></div>
            )}
            {t("create")}
          </button>
        </div>
        {/*form fields */}
        {/*form fields */}
        <div className="addStaffForm staffPersonalInfo">
          <h3 className="fontSize16 fw500 txtDarkblue  mb-0">
            {t("locationDetails")}
          </h3>
          <div className="row">
            {/* business name */}
            <div className="col-md-12 mb-2">
              <div className="form_group">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t('storeName')}
                </label>
                <div className="inputGroup ">
                  <input
                    className="customInput businNameInput"
                    type="text"
                    name="business_name"
                    value={locationDetail.business_name}
                    onChange={handleLocationDetails}
                  />
                </div>
              </div>
              <p className="ps-4 fontSize14 fw400 mb-0 mt-1 staffPositionTxt">
              {t("businessTitle")}
              </p>
            </div>
             {/* description */}
             <div className="col-md-12">
                        <div className="form_group">
                            <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                                {t("description")}
                            </label>
                            <div className="inputGroup">
                                <textarea
                                    className="customInput textArea"
                                    placeholder="Cash cut-off before rush hour checked by Eugenia. It’s important to make inventories ."
                                   
                                    type="text"
                                    name="business_details"
                                    value={locationDetail.business_details}
                                    onChange={handleLocationDetails}
                                    rows={3}
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
            {/* description */}
            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t("storeType")}
                </label>
                <div className="reactSelectParent commonSelectParent">
                  <ReactSelect
                    options={addressOptions}
                    value={addressOptions.find(
                      (option) => option.value === locationDetail?.address_type
                    )}
                    onChange={(event) => {
                      handleLocationDetails(null, {
                        name: "address_type",
                        value: event?.value,
                      });
                    }}
                    placeholder="Select Store Type"
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            {/* state details */}
            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize14 fw500 txtDarkblue ps-4 mb-2">
                  {t("streetAddress")}
                </label>
                <div className="inputGroup">
                  {hide ? (
                    <span
                      className="customform-control w-100"
                      onClick={() => setHide(!hide)}
                    >
                      {displayValue}
                    </span>
                  ) : (
                    <PlacesAutocomplete
                      className=""
                      autoComplete="off"
                      value={formattedAddress}
                      onChange={autoCompleteHandleChange}
                      onSelect={autoCompleteHandleSelect}
                      searchOptions={{
                        componentRestrictions: {
                          country: ["us", "ind"],
                        },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="position-relative ">
                          <input
                            {...getInputProps({
                              placeholder: "Street, Ave, West...",
                              className:
                                "location-search-input customform-control apartmentInput",
                            })}
                          />
                          <img
                            src={Images.loctionicn}
                            alt="img"
                            className="apartmentimg"
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#41b6e6",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={index}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  )}
                </div>
              </div>
            </div>
            {/* apartment */}
            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t("apartmentSuite")}
                </label>
                <div className="position-relative">
                  <input
                    className="customform-control apartmentInput"
                    placeholder="Building, Interior"
                    autoComplete="new-password"
                    type="text"
                    name="apt"
                    value={locationDetail.apt}
                    onChange={handleLocationDetails}
                  />
                  <img
                    src={Images.apartmentLogo}
                    alt="img"
                    className="apartmentimg"
                  />
                </div>
              </div>
            </div>
            {/* phone */}
            <div className="col-md-6">
                        <div className="phone-numbpart">
                            <div className="country-plugin">
                                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                                    {t("phoneNumber")}
                                </label>
                                {/* <div id="result" className="countryCodePickerParent">
                                    <PhoneInput
                                        country="us"
                                        value={locationDetail.phone_code+""+locationDetail.phone_no}
                                        // enableSearch={true}
                                        onlyCountries={["us", "in"]}
                                        name={"phone_no"}
                                        placeholder="Phone no."
                                        autoComplete="off"
                                        onChange={onChangePhoneNumber}
                                        
                //                         onChange={(value, data, event, formattedValue) =>
                //     // onChangePhoneNumber(value, data, event, formattedValue)
                //   }
                                    />
                                </div> */}
                  <FormControl fullWidth>
                    <Select
                      labelId="token-type-label"
                      className="customSelect mui_Select"
                      name="type"
                      value={locationDetail.phone_code}
                      onChange={(event)=>{
                        let value=event.target.value;
                        console.log(value)
                        handleLocationDetails(null,{
                          name:"phone_code",
                          value:value
                        })
                      }}
                      displayEmpty
                      sx={{
                        fontFamily: "sans-serif",
                        ".MuiSvgIcon-root": {
                          display: "none" // Customize the icon color
                        },
                        fontStyle: "normal",
                        // color: selectedType === "" ? "#263682" : "#FFF",
                        color: "#000",
                        border: "1px solid #ffffff1a",
                        borderRadius: "10px",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "5px",
                            border: "1px solid #FFFFFF4D",
                            background: "#FFF",
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: "500",
                            marginTop: "5px",
                            border:"1px solid #d7deff",
                            padding : "0 0 10px",
                            "& .MuiMenuItem-root": {
                              color: "#263682",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "inherit",
                              
                              "&.Mui-selected": {
                                backgroundColor: "#f5f6fc",
                              },
                              "&:hover": {
                                backgroundColor: "#f5f6fc",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#f5f6fc",
                              },
                            },
                          },
                        },
                      }}
                      
                    >
                      <MenuItem value="" disabled>
                        Select Country
                      </MenuItem>
                      <MenuItem value="1"><img src={Images.usaFlag} alt="img" className="pe-2"/>USA</MenuItem>
                      <MenuItem value="91"><img src={Images.indiaFlag} alt="img" className="pe-2"/>India</MenuItem>
                    </Select>
                    <input
                      type="hidden"
                      // value={selectedType}
                    />
                  </FormControl>
                            </div>
                        </div>
                    </div>
            {/* state */}
            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t("state")}
                </label>
                <input
                  className="customform-control customInput id_password"
                  placeholder="City"
                  autoComplete="new-password"
                  type="text"
                  name="state"
                  value={locationDetail.state}
                  onChange={handleLocationDetails}
                />
                {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
              </div>
            </div>
            {/* city */}
            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t("city")}
                </label>
                <input
                  className="customform-control customInput id_password"
                  placeholder="City"
                  autoComplete="new-password"
                  type="text"
                  name="city"
                  value={locationDetail.city}
                  onChange={handleLocationDetails}
                />
                {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
              </div>
            </div>
            {/* zip code */}
            <div className="col-md-6">
              <div className="form_group ">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t("zipCode")}
                </label>
                <input
                  type="number"
                  className="customform-control customInput"
                  autoComplete="new-password"
                  placeholder="Zip code"
                  name="zipcode"
                  value={locationDetail.zipcode}
                  onChange={handleLocationDetails}
                />
              </div>
            </div>
            <div className="col-md-12">
                        <h3 className="fontSize16 fw500 txtDarkblue mt-3 mb-0">
                            {t("contactInformation")}
                        </h3>
                    </div>
            {/* email */}
            <div className="col-md-6">
                        <div className="form_group">
                            <div className="staffEmailInput position-relative">
                                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                                    {t("emailAddress")}
                                </label>
                                <input
                                    type="email"
                                    className="customInput"                                
                                    placeholder="hello@email.com"
                                    
                                    name="email"
                                value={locationDetail.email}
                                onChange={handleLocationDetails}
                                />
                                <img
                                    src={Images.authEmailLogo}
                                    alt="img"
                                    className="createAccInputLogo"
                                />
                            </div>
                        </div>
                    </div>
            {/* phone */}
            <div className="col-md-6">
                        <div className="form_group">
                            <div className="staffEmailInput position-relative">
                                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                                    {t("phoneNumber")}
                                </label>
                                <input
                                    type="text"
                                    className="customInput"
                                    placeholder="1234567089"
                                    name="phone_no"
                                    maxLength="10"
                                value={locationDetail.phone_no}
                                onChange={(e)=>{
                                  const { name, value } = e.target;
                                  if (/^\d{0,10}$/.test(value)) {
                                      handleLocationDetails(e);
                                  }
                                }}
                                />
                                <img
                                    src={Images.editBusLocPhnImg}
                                    alt="img"
                                    className="createAccInputLogo"
                                />
                            </div>
                        </div>
                    </div>
            {/*  */}
            <div className="col-md-12">
              <h3 className="fontSize16 fw500 txtDarkblue mt-5 mb-0">
                {t("businessHours")}
              </h3>
            </div>
            {/* time zone */}
            {/* <div className="col-md-12">
                        <div className="form_group">
                            <div className="reactSelectParent commonSelectParent w-100">
                                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                                    TimeZone
                                </label>
                                <ReactSelect
                                    options={selectOption}
                                    isSearchable={true}
                                    placeholder="Select one"
                                    classNamePrefix="react-select"
                                    className="react-select-container anlyticSelect"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            maxWidth: "100% !important",
                                            width: "100%",
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            border: "1px solid #D7DEFF",
                                        }),
                                        valueContainer: (provided) => ({
                                            ...provided,
                                            paddingLeft: "unset !important",
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected
                                                ? "#263682"
                                                : state.isFocused
                                                    ? "#f5f6fc"
                                                    : "#fff",

                                            color: state.isSelected ? "#fff" : "#263682",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            color: "#636E9F",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                        }),
                                    }}
                                    components={{
                                        DropdownIndicator: () => (
                                            <img
                                                src={Images.blueDownArrow}
                                                width={24}
                                                height={24}
                                                alt="drop_icon"
                                                className="mt-0"
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}
            {/* working hours */}
            <div className="col-12">
              <div className="checkboxGroup businessChekbox d-block">
                {dayList?.map((item, index) => {
                  return (
                    <div className="checkboxInputs" key={index}>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <input
                            type="checkbox"
                            id={item.day}
                            className="checkBox"
                            name={item.day}
                            value={item.day}
                            onChange={(e) => handleCheckboxChange1(e)}
                            checked={businessHours?.includes(item.day)}
                          />
                          <label htmlFor={item.day} className="checkBoxTxt">
                            {item.value}
                          </label>
                        </div>
                        <div className="col-8 pe-0">
                          {businessHours?.includes(item.day) && (
                            <>
                              <div className="row">
                                <div className="col-6 pe-0">
                                  {/* <div style={{ display: "flex" }} className='timerBox_'> */}
                                  <div className="innertimedate_">
                                    {/* <p className='timehead_ me-2'>Start Time : </p> */}
                                    <TimePicker
                                      onChange={(e) =>
                                        handleStartTimeChange(e, item.day)
                                      }
                                      value={
                                        timeData[
                                          weekOptions[item.id - 1]?.value
                                        ]?.start
                                      }
                                      format="h:mm a"
                                      clearIcon={null}
                                      clockIcon={null}
                                      showLeadingZeros={false}
                                      amPmAriaLabel="Select AM/PM"
                                      className="custom-time-picker customPicker"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 pe-0">
                                  <div className="timerBox_">
                                    {/* <p className='timehead_ me-2'>End Time : </p> */}
                                    <TimePicker
                                      onChange={(e) =>
                                        handleEndTimeChange(e, item.day)
                                      }
                                      value={
                                        timeData[
                                          weekOptions[item.id - 1]?.value
                                        ]?.end
                                      } // Use the 12-hour formatted time string directly
                                      format="h:mm a" // Specify the format as 12-hour with AM/PM
                                      clearIcon={null}
                                      clockIcon={null}
                                      className="custom-time-picker customPicker"
                                    />
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default AddEditLocation
export default AddLocation;