import React, { useCallback, useState } from "react";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import * as Images from "../../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../bookings/BookingCalendar/CustomModal";
import AddFolderModal from "./Modal/AddFolderModal";
import UploadImageModal from "./Modal/UploadImageModal";


const Catalogs = () => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState("");
    const [fileError, setFileError] = useState("");
    const [key, setKey] = useState(Math.random());

    const history = useHistory();

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            title: "",
            flag: flag,
        });
        setKey(Math.random());
    };

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles, "acceptdedd");
        const uploadedFile = acceptedFiles?.[0];
        const validTypes = ["image/jpeg", "image/png"];
        const maxSize = 5 * 1024 * 1024;

        if (!uploadedFile) {
            setFileError("Please upload a file.");
            toast.error("No file uploaded.");
            return;
        }

        if (!validTypes.some((type) => uploadedFile.type === type)) {
            setFileError("Invalid file type.");
            toast.error("Invalid file type. Only JPEG and PNG are allowed.");
            return;
        }

        if (uploadedFile.size > maxSize) {
            setFileError("File size exceeds the 5MB limit.");
            toast.error("File size exceeds the 5MB limit.");
            return;
        }

        setFileError("");
        setFile(uploadedFile);

        const filePreview = URL.createObjectURL(uploadedFile);
        setPreview(filePreview);
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
    });
    return (
        <>
            <DashboardNavbar title="My Catalogs " backShow={false} />
            <section className='catalogSection'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4">
                                Upload Images <img className="ms-2" src={Images.labelImg} />
                            </label>
                            <div className="showToken_Img mt-0 ">
                                <div {...getRootProps({ className: "" })}>
                                    <input name="image" type="file" {...getInputProps()} />
                                    <div className="uploadImg_Parent">
                                        {file ? (
                                            <div className="showImg_Parent">
                                                <img
                                                    src={preview}
                                                    alt="Preview"
                                                    className="detailsImg img-fluid h-100"
                                                />
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={Images.UploadSquareImg}
                                                    alt="Camera"
                                                    style={{ cursor: "pointer" }}
                                                />
                                                <p className="staffPositionTxt fontSize12 fw400 ms-2 text-center">
                                                    Click to browse or <br /> drag and drop your files
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {fileError && <p className="error-message">{fileError}</p>}
                            </div>
                            <p className="fontSize14 fw400 staffPositionTxt mb-2">Image size and px by px</p>
                        </div>
                        <div className="col-12 p-0">
                            <div className="catFolconatiner">
                                <div className="d-flex align-items-end">
                                    <h4 className="fw500 fontSize16 txtDarkblue mb-0 ps-4">Folders</h4>
                                    <button className="commonBlueBtn active ms-auto" onClick={() => handleOpenModal("addFolderModal")}>Add Folder <img className="" src={Images.labelImg} /></button>
                                </div>
                                <div className="catFolParent">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="catFolderBox cursorPointer" onClick={() => history.push("folder-document")}>
                                                <div className="catFolBoxHead">
                                                    <div className="d-flex align-items-center">
                                                        <img className="me-2" src={Images.fileIconsCat} alt="" />
                                                        <h4 className="fontSize16 fw500 verifyControl mb-0">  Documents</h4>
                                                    </div>
                                                    <img className="ms-auto" src={Images.catFolBoxMenuImg} alt="" />
                                                </div>
                                                <h6 className='fontSize16 fw400 businessUserName'>4 documents</h6>
                                                <h4 className="fontSize16 fw600 txtDarkblue">100 GB</h4>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="catFolderBox">
                                                <div className="catFolBoxHead">
                                                    <div className="d-flex align-items-center">
                                                        <img className="me-2" src={Images.fileIconsCat} alt="" />
                                                        <h4 className="fontSize16 fw500 verifyControl mb-0">  Images</h4>
                                                    </div>
                                                    <img className="ms-auto" src={Images.catFolBoxMenuImg} alt="" />
                                                </div>
                                                <h6 className='fontSize16 fw400 businessUserName'>150 images</h6>
                                                <h4 className="fontSize16 fw600 txtDarkblue">95mb</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 p-0">
                            <div className="catFolconatiner">
                                <div className="d-flex align-items-end">
                                    <h4 className="fw500 fontSize16 txtDarkblue mb-0 ps-4">Received Catalogs Folders</h4>
                                </div>
                                <div className="catFolParent">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="catFolderBox" >
                                                <div className="catFolBoxHead">
                                                    <div className="d-flex align-items-center">
                                                        <img className="me-2" src={Images.fileIconsCat} alt="" />
                                                        <h4 className="fontSize16 fw500 verifyControl mb-0">  Documents</h4>
                                                    </div>
                                                    <img className="ms-auto" src={Images.catFolBoxMenuImg} alt="" />
                                                </div>
                                                <h6 className='fontSize16 fw400 businessUserName'>4 documents</h6>
                                                <h4 className="fontSize16 fw600 txtDarkblue">100 GB</h4>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="catFolderBox">
                                                <div className="catFolBoxHead">
                                                    <div className="d-flex align-items-center">
                                                        <img className="me-2" src={Images.fileIconsCat} alt="" />
                                                        <h4 className="fontSize16 fw500 verifyControl mb-0">  Images</h4>
                                                    </div>
                                                    <img className="ms-auto" src={Images.catFolBoxMenuImg} alt="" />
                                                </div>
                                                <h6 className='fontSize16 fw400 businessUserName'>150 images</h6>
                                                <h4 className="fontSize16 fw600 txtDarkblue">95mb</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 p-0">
                            <h5 className="fontSize16 fw500 txtDarkblue ps-4 mb-0 mt-4">Recent Uploads</h5>
                            <div className="commonTable table-responsive catalRecentTable">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            <th className="text-start fw600 ps-4">File Name</th>
                                            <th className="fw600">Folder</th>
                                            <th className="fw600">Last Modify</th>
                                            <th className="fw600"> File Size</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr className="text-center border-none">
                    <td colSpan="100%">
                      <div className="d-flex">
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                      </div>
                    </td>
                  </tr>
                   */}
                                        <tr>
                                            <td className="orderTrackTableTxt text-start ps-4">
                                                Jobr sales doc
                                            </td>
                                            <td className="orderTrackTableTxt">Documents</td>
                                            <td className="orderTrackTableTxt">21 Jun, 2025 | Henderson</td>
                                            <td className="orderTrackTableTxt">236kb</td>
                                            <td
                                                className="orderTrackTableTxt cursorPointer"
                                            >
                                                <img src={Images.catFolBoxMenuImg} alt="img" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="orderTrackTableTxt text-start ps-4">
                                                Jobr sales doc
                                            </td>
                                            <td className="orderTrackTableTxt">Documents</td>
                                            <td className="orderTrackTableTxt">21 Jun, 2025 | Henderson</td>
                                            <td className="orderTrackTableTxt">236kb</td>
                                            <td
                                                className="orderTrackTableTxt cursorPointer"
                                            >
                                                <img src={Images.catFolBoxMenuImg} alt="img" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="orderTrackTableTxt text-start ps-4">
                                                Jobr sales doc
                                            </td>
                                            <td className="orderTrackTableTxt">Documents</td>
                                            <td className="orderTrackTableTxt">21 Jun, 2025 | Henderson</td>
                                            <td className="orderTrackTableTxt">236kb</td>
                                            <td
                                                className="orderTrackTableTxt cursorPointer"
                                            >
                                                <img src={Images.catFolBoxMenuImg} alt="img" />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "addFolderModal" ? "addFolderModal" :""
                }
                child={
                    modalDetail.flag === "addFolderModal" ? (
                        <AddFolderModal
                            close={() => handleOnCloseModal()}
                        />
                    ) :  (
                        ""
                    )
                }
                header={
                    modalDetail.flag === "addFolderModal" ? (
                        <>
                            <div>
                                <h4 className="fonstSize24 fw500 txtDarkblue">Create New Folder</h4>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0">Create a new folder.</h6>

                            </div>
                            <img className="ms-auto cursorPointer" src={Images.modalCross} alt="img" onClick={() => handleOnCloseModal()} />

                        </>
                    ) : 
                     (
                        ""
                    )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>

    )
}

export default Catalogs