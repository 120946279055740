import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Images from "../../../utilities/images";
import {
  getSellerAddressesApiAsync,
  getTotalOrdersGraphAsync,
  getTotalOrdersGraphOverviewAsync,
  getTotalOrdersGraphStatusAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import ChartArea from "../dashboard/wallet/ChartArea";
import HomePageInnerHeader from "../dashboard/HomePageInnerHeader";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import {
  HeaderStatisticsArr,
  options1,
} from "../../constants/orders/dashboard";
import OrderHeader from "../../components/UI/Orders/MainHeader";
import { useTranslation } from "react-i18next";

const MyOrders = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading1, setLoading1] = useState(false);
  // time period
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // time period

  const [sellerAddress, setsellerAddress] = useState("");
  const [shippingDatasets, setShippingDatasets] = useState([]);
  const [filterValue, setFilterValue] = useState("week");
  const [graphOverviewStatics, setGraphOverviewStatics] = useState({
    pendingCount: 0,
    completedCount: 0,
    cancelCount: 0,
    totalCount: 0,
  });
  const [ordergraphStatus, setOrdergraphStatus] = useState(null);
  const [ordergraphs, setOrdergraphs] = useState(null);
  const [deliveryDatasets, setDeliveryDatasets] = useState([]);
  const [DeliveryChecked, setDeliveryChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  const [shippingChecked, setShippingChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  let [dataSets, setDataSets] = useState([]);
  const [checked, setChecked] = useState({
    pos: true,
    delivery: true,
    shipping: true,
  });
  const [POSdataSets, setPOSDataSets] = useState([]);
  const [POSchecked, setPOSChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  const auth = useSelector(selectLoginAuth);
  const handleFilterValue = (value) => {
    setEndDate(null);
    setStartDate(null);
    setFilterValue(value);
  };

  const handleSellerAddress = (value) => {
    setsellerAddress(value);
  };

  const handelPOSDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setPOSDataSets([
        ...POSdataSets,
        {
          fill: true,
          label: value,
          data: ordergraphStatus?.pos_graph?.graph_data?.datasets[num]?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setPOSDataSets(POSdataSets.filter((item) => item.label !== value));
    }
  };
  const handelDeliveryDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDeliveryDatasets([
        ...deliveryDatasets,
        {
          fill: true,
          label: value,
          data: ordergraphStatus?.delivery_graph?.graph_data?.datasets[num]
            ?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setDeliveryDatasets(
        deliveryDatasets.filter((item) => item.label !== value)
      );
    }
  };
  const handelShippingDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setShippingDatasets([
        ...shippingDatasets,
        {
          fill: true,
          label: value,
          data: ordergraphStatus?.shipping_graph?.graph_data?.datasets[num]
            ?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setShippingDatasets(
        shippingDatasets.filter((item) => item.label !== value)
      );
    }
  };
  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: ordergraphs?.graphData?.datasets
            ? ordergraphs.graphData.datasets[num]?.data
            : "",
          borderColor: color,
          backgroundColor: "#FFFFFF00",
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
  };

  const getAllGraphOverviewStatics = async () => {
    let prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
        // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18",
        ...(sellerAddress&&{seller_address_id:sellerAddress}),
        filter: filterValue,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getTotalOrdersGraphOverviewAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setLoading1(false);
        setGraphOverviewStatics(res?.payload);
      })
      .catch((err) => {
        setLoading1(false);
      });
  };
  const getAllOrdersGraph = async () => {
    setLoading1(true);
    let prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
        // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18",
        ...(startDate == null && { filter: filterValue }),
        ...(startDate && { start_date: startDate, end_date: endDate }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getTotalOrdersGraphAsync(prams))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        setOrdergraphs(obj?.payload);
        setDataSets(handleDataSet(obj.payload?.graphData?.datasets) || []);
      })
      .catch((err) => {
        setLoading1(false);
      });
  };
  const handleDataSet = (datasets) => {
    const colors = ["#4659B5", "#F0C01A", "#58C2E8"];
    let labels = ["POS Orders", "Delivery Orders", "Shipping Orders"];
    return datasets?.map((data, index) => ({
      fill: true,
      label: labels[index],
      data: data?.data,
      borderColor: colors[index],
      backgroundColor: "#FFFFFF00",
      cubicInterpolationMode: "monotone",
    }));
  };
  const handleORderDeliveryStatus = (dataSets) => {
    let colors = ["#32D583", "#914BEB", "#F04438"];
    let labels = ["Delivered", "Returned", "Cancelled"];
    return dataSets?.map((data, index) => ({
      fill: true,
      label: labels[index],
      data: data?.data,
      borderColor: colors[index],
      backgroundColor: "#FFFFFF00",
      cubicInterpolationMode: "monotone",
    }));
  };
  const getAllOrdersGraphStatus = async () => {
    setLoading1(true);
    let prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
        // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18",
        ...(startDate == null && { filter: filterValue }),
        ...(startDate && { start_date: startDate, end_date: endDate }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(getTotalOrdersGraphStatusAsync(prams))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        setOrdergraphStatus(obj?.payload);
        setPOSDataSets(
          handleORderDeliveryStatus(
            obj?.payload?.pos_graph?.graph_data?.datasets
          ) || []
        );
        setDeliveryDatasets(
          handleORderDeliveryStatus(
            obj?.payload?.delivery_graph?.graph_data?.datasets
          ) || []
        );
        setShippingDatasets(
          handleORderDeliveryStatus(
            obj?.payload?.shipping_graph?.graph_data?.datasets
          ) || []
        );
      })
      .catch((err) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllGraphOverviewStatics();
    getAllOrdersGraph();
    getAllOrdersGraphStatus();
  }, [filterValue, endDate, sellerAddress]);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Orders";
  }, []);
  const TotalOrderAnalytics = {
    labels: ordergraphs?.graphData?.labels,
    datasets: dataSets,
  };
  console.log(TotalOrderAnalytics,"TotalMarketingAnalytics")

  const PosOrderAnalytics = {
    labels: ordergraphStatus?.pos_graph?.graph_data?.labels,
    datasets: POSdataSets,
  };

  const DeliveryOrderAnalytics = {
    labels: ordergraphStatus?.delivery_graph?.graph_data?.labels,
    datasets: deliveryDatasets,
  };

  const ShippingOrderAnalytics = {
    labels: ordergraphStatus?.shipping_graph?.graph_data?.labels,
    datasets: shippingDatasets,
  };
  const handleDateValue = (values) => {
    if (values.startDate == values.endDate) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };
  return (
    <>
      <DashboardNavbar title={t("orderOverview")} backShow={false} />
      <div className="myOrdersmain myOrders_ chartsOuter">
        <OrderHeader
          handleFilter={handleFilterValue}
          handleDate={handleDateValue}
          seller_id={    auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid}
          tokenData={auth?.payload?.token}
          handleLocation={handleSellerAddress}
          subhead={`${t('gatheringData')} ${filterValue}`}
        />

        <div className="verificationTotal">
          <div className="row mt-4">
            {HeaderStatisticsArr?.map((headerStatic, index) => (
              <HeaderStatistics
                className={headerStatic?.className}
                img={Images?.[headerStatic?.img]}
                value={graphOverviewStatics?.[headerStatic?.key]}
                heading={t(headerStatic?.languageText)}
              />
            ))}
          </div>
        </div>
        {loading1 ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="tradesValue mt-4 mb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="totalOrders_ mt-4 mb-3">
                    <div className="chartHeader_">
                      <div className="leftorderValue_">
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/Total-Orders")}
                          className="textInnerHead m-0"
                        >
                          {t('totalOrders')}
                        </h3>
                        <h3 className="Innerhead ">
                          {ordergraphs?.totalAmount
                            ? `$${addThousandSeparator(
                                ordergraphs?.totalAmount?.toFixed(2)
                              )}`
                            : "$0.00"}
                        </h3>
                        <Link to="/allOrder">
                          <button className="viewAllbtn_" type="submit">
                            {t('viewAll')}
                          </button>
                        </Link>
                      </div>

                      <div className="graphChecked_ mt-3 mb-2 text-end">
                        <div className="graphBox_ me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll2"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "POS Orders",
                                0,
                                "#4659B5"
                              );
                              setChecked({
                                ...checked,
                                pos: checked.pos === true ? false : true,
                              });
                            }}
                            checked={checked.pos}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll2"
                            className="checkBoxTxt masterChecked"
                          >
                            {t("posOrders")}
                          </label>
                        </div>
                        <div className="checkYellow me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll3"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            checked={checked.delivery}
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "Delivery Orders",
                                1,
                                "#F0C01A"
                              );
                              setChecked({
                                ...checked,
                                delivery:
                                  checked.delivery === true ? false : true,
                              });
                            }}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll3"
                            className="checkBoxTxt masterChecked"
                          >
                            {t('deliveryOrders')}
                          </label>
                        </div>
                        <div className="checkSkyblue me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll4"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            checked={checked.shipping}
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "Shipping Orders",
                                2,
                                "#58C2E8"
                              );
                              setChecked({
                                ...checked,
                                shipping:
                                  checked.shipping === true ? false : true,
                              });
                            }}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll4"
                            className="checkBoxTxt masterChecked"
                          >
                            {t('shippingOrders')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 mb-4 align-items-end">
                      <ChartArea
                        classes="col-sm-12 col-md-12 col-lg-12 mt-3 mb-3"
                        header="Sales Overview"
                        options={options1}
                        data={TotalOrderAnalytics}
                        chartType="Line"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="chartsOuter m-0">
                  <div className="posHeader_">
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/Total-POS-Orders")}
                      className="fontSize16 fw500 txtDarkblue "
                    >
                      {t("totalPosOrders")}
                    </h4>
                    <h4 className="successMain text-start">
                      {addThousandSeparator(
                        ordergraphs?.pos_graph?.total_count
                      )}
                    </h4>
                  </div>
                  <div className="graphChecked_ orderOverviewChartParent">
                    <div className="checkGreen ">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll2POS"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Delivered", 0, "#32D583");
                          setPOSChecked({
                            ...POSchecked,
                            Delivered:
                              POSchecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={POSchecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll2POS"
                        className="checkBoxTxt "
                      >
                        {t('delivered')}
                      </label>
                    </div>
                    <div className="graphBox_ ">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll3POS"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={POSchecked.Returned}
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Returned", 1, "#914BEB");
                          setPOSChecked({
                            ...POSchecked,
                            Returned:
                              POSchecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll3POS"
                        className="checkBoxTxt "
                      >
                        {t('returned')}
                      </label>
                    </div>
                    <div className="checkRed checkred-status">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll4POS"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={POSchecked.Cancelled}
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Cancelled", 2, "#F04438");
                          setPOSChecked({
                            ...POSchecked,
                            Cancelled:
                              POSchecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll4POS"
                        className="checkBoxTxt "
                      >
                        {t('cancelled')}
                      </label>
                    </div>
                    {/* <div className="d-flex align-items-center gap-2 justify-content-end">
                    <div className="chartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">JBR</h3>
                    </div>
                    <div className="chartDetailData cashChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">Cash</h3>
                    </div>
                    <div className="chartDetailData newChartDetailData">
                      <span className="dot"></span>
                      <h3 className="chartDetailTxt">Card</h3>
                    </div>
                  </div> */}
                  </div>

                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={PosOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="chartsOuter m-0">
                  <div className="posHeader_">
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/Total-Delivery-Orders")}
                      className="fontSize16 fw500 txtDarkblue "
                    >
                      {t("totalDeliveryOrders")}
                    </h4>
                    <h4 className="successMain text-start">
                      {" "}
                      {addThousandSeparator(
                        ordergraphs?.delivery_graph?.total_count
                      )}
                    </h4>
                  </div>

                  <div className="graphChecked_ orderOverviewChartParent">
                    <div className="checkGreen ">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll23Delivery"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Delivered",
                            0,
                            "#32D583"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Delivered:
                              DeliveryChecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={DeliveryChecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll23Delivery"
                        className="checkBoxTxt delivery-status"
                      >
                        {t('delivered')}
                      </label>
                    </div>
                    <div className="graphBox_">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll34Delivery"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={DeliveryChecked.Returned}
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Returned",
                            1,
                            "#914BEB"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Returned:
                              DeliveryChecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll34Delivery"
                        className="checkBoxTxt "
                      >
                        {/* Total DElivery oRDERS */}
                        {t('returned')}
                      </label>
                    </div>
                    <div className="checkRed checkred-status">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll43Delivery"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={DeliveryChecked.Cancelled}
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Cancelled",
                            2,
                            "#F04438"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Cancelled:
                              DeliveryChecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll43Delivery"
                        className="checkBoxTxt "
                      >
                        {t('cancelled')}
                      </label>
                    </div>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={DeliveryOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="chartsOuter m-0">
                  <div className="posHeader_">
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/Total-Shipping-Orders")}
                      className="fontSize16 fw500 txtDarkblue "
                    >
                      {t("totalShippingOrders")}
                    </h4>
                    <h4 className="successMain text-start">
                      {" "}
                      {addThousandSeparator(
                        ordergraphs?.shipping_graph?.total_count
                      )}
                    </h4>
                  </div>
                  <div className="graphChecked_ orderOverviewChartParent">
                    <div className="checkGreen ">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll231Shipping"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Delivered",
                            0,
                            "#32D583"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Delivered:
                              shippingChecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={shippingChecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll231Shipping"
                        className="checkBoxTxt "
                      >
                        {t('delivered')}
                      </label>
                    </div>
                    <div className="graphBox_ ">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll341Shipping"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={shippingChecked.Returned}
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Returned",
                            1,
                            "#914BEB"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Returned:
                              shippingChecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll341Shipping"
                        className="checkBoxTxt "
                      >
                        {/* Total DElivery oRDERS */}
                        {t('returned')}
                      </label>
                    </div>
                    <div className="checkRed checkred-status">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll431Shipping"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={shippingChecked.Cancelled}
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Cancelled",
                            2,
                            "#F04438"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Cancelled:
                              shippingChecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll431Shipping"
                        className="checkBoxTxt "
                      >
                        {t('cancelled')}
                      </label>
                    </div>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={ShippingOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyOrders;
