import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationListAsync, readNotificationsAsync } from "./dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import { profileImg1 } from "../../../utilities/images";
import DashboardNavbar from "./DashboardNavbar";
import Loader from "../../components/UI/Loader/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const NotificationList = () => {
  const { t } = useTranslation();
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [notifications, setNotifications] = useState([]);
  console.log(notifications, "notifications");

  const showNotifications = () => {
    let params = {
      tokenData: auth?.payload?.token,
    };
    setLoading(true);

    dispatch(notificationListAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setNotifications(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showNotifications();
  }, []);
  
  const markAsReadNotification = (type, notificationId) => {
    let params = {
      postData:{

        notification_id: ""
      },
      tokenData:auth?.payload?.token
    }
    if (type !== "mark_all_read") {
      params.postData.notification_id = notificationId;
    }
    dispatch(
      readNotificationsAsync(params)
    )
    .then(unwrapResult)
    .then(res=>{
      showNotifications();
    })
    .catch(err=>{
      console.log(err)
    })
  }
  return (
    <>
      <DashboardNavbar title={t("notification")} backShow={false} notifications={notifications}/>
      <div className="notifySection transectionCommon position-relative">
        {/* <div className="flexTable mb-4">
          <h4 className="appointMain text-start m-0">Notification</h4>
        </div> */}

        {
          loading&&<Loader/>
        }
          <>
          <div className="mb-2 d-flex justify-content-end">
            <button
              onClick={() => markAsReadNotification("mark_all_read")}
              className="editbtn_ px-3 py-2"
            >
              {t("markAsRead")}
            </button>
          </div>
            {notifications?.map((data, idx) => {
              return (
                <div className="notificationArea" key={idx}>
                  <div
                  onClick={
                    () => {
                    if(!data?.is_read)  markAsReadNotification(
                      "",
                        data?.notification_id
                      )
                      history.push("/orderReview/" + data?.order_info?.id)
                    }
                  }
                  className="NotificationSubArea">
                    <div className="taxCountryMain" style={{
                      backgroundColor: `${data?.is_read ? "" : "#F5F6FC"}`
                    }}>
                      <div className="operatingCountry">
                        {/* <img
                          src={
                            data?.notification?.product_images
                              ? data?.notification?.product_images
                              : profileImg1
                          }
                          className="countryImg"
                          alt="countryImage"
                        /> */}
                        <div className="countryText">
                          <h4 className="cancelOrderText">
                            {" "}
                            {data?.notification?.title}
                          </h4>
                          <p className="settingText mt-1">
                            {" "}
                            {data?.notification?.description.length > 70
                              ? `${data?.notification?.description?.slice(
                                0,
                                70
                              )}...`
                              : data?.notification?.description}
                          </p>
                        </div>
                      </div>
                      <button className="notifyBtn" type="button">
                        {" "}
                        {moment(data?.notification?.created_at).fromNow()}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
      </div>
    </>
  );
};
export default NotificationList;
