export const AllOrders=[
    { value: "", label: "All Orders" ,
        languageText:"allOrders"

    },
        { value: "1", label: "Delivery" ,
            languageText:"delivery"


        },
        { value: "3", label: "Walk-In" ,
            languageText:"store"

        },
        // { value: "3", label: "Pickup Delivery" },
        { value: "4", label: "Shipping" ,
            languageText:"shipping"

        },
]
export const AllPayments=[
    {value:"all",label:"All Payments",
        languageText:"allPayment"
    },
    {value:"cash",label:"Cash",
        languageText:"cash"
    },
    {value:"card",label:"Card",
        languageText:"card"
    },
    // {value:"jbr",label:"Gift Cards"},
    {value:"reward",label:"Rewards",
        languageText:"rewards"
    },
]
export const AllStatus=[
    { value: "", label: "All Status",
        languageText:"allStatus"
     },
    {value:"0",label:"Order to Review",
        languageText:"orderToReview"
    },
    {value:"1",label:"Accepted",
        languageText:"accepted"
    },
    {value:"2",label:"Order Prepairing",
        languageText:"prepairing"
    },
    {value:"3",label:"Ready for Pickup",
        languageText:"readyForPickup"
    },
    {value:"3",label:"Printing Label",
        languageText:"Printing Label"
      },
      {value:"4",label:"Shipped",
        languageText:"Shipped"
      },
    {value:"4",label:"Pickup",
        languageText:"pickUp"
    },
    {value:"5",label:"Delieverd",
        languageText:"delivered"
    },
    {value:"6",label:"Pickup by Customer",
        languageText:"pickupByCustomer"
    },
    {value:"7",label:"Cancelled",
        languageText:"cancelled"
    },
    {value:"8",label:"Rejected",
        languageText:"rejected"
    },
    {value:"9",label:"Returned",
        languageText:"returned"
    },
]

export const DeliveryOptionsDetails=[
    {
        title:"Delivery",
        img:"driverSolid",
        languageText:"delivery"
    },
    {
        title:"Store",
        img:"storeImg",
        languageText:"store"
    },
    {
        title:"Walk-In",
        img:"pickupCart",
        languageText:"inStores"
    },
    {
        title:"Shipping",
        img:"shippingstatus",
        languageText:"shipping"
    },
]

export const DeliveryStatusOptions=[
    {
        className:"revieworder",
        label:"Review Order",
        languageText:"reviewOrder"
    },
    {
        className:"accepted",
        label:"Accepted",
        languageText:"accepted"
    },
    {
        className:"prepariging",
        label:"Order Prepared",
        languageText:"Order Prepared"
    },
    {
        className:"driverAssigned",
        label:"Assign Driver",
        languageText:"assignDriver"
    },
    {
        className:"pickup",
        label:"Picked Up",
        languageText:"Picked Up"
    },
    {
        className:"completed",
        label:"Completed",
        languageText:"completed"
    },
    {
        className:"completed",
        label:"Pickup by Customer",
        languageText:"pickupByCustomer"
    },
    {

        className:"cancelled",
        label:"Cancelled",
        languageText:"cancelled"
    },
    {
        className:"rejected",
        label:"Rejected",
        languageText:"rejected"
    },
    {
        className:"returned",
        label:"Returned",
        languageText:"returned"
    }
    
]
export const ShippingStatusOptions=[
    {
        className:"revieworder",
        label:"Review Order",
        languageText:"reviewOrder"
    },
    {
        className:"accepted",
        label:"Accepted",
        languageText:"accepted"
    },
    {
        className:"accepted",
        label:"Accepted",
        languageText:"accepted"
    },
    {
        className:"prepariging",
        label:"Printing Label",
        languageText:"Printing Label"
    },
    {
        className:"driverAssigned",
        label:"Shipped",
        languageText:"Shipped"
    },
    {
        className:"completed",
        label:"Completed",
        languageText:"completed"
    },
    {
        className:"completed",
        label:"Pickup by Customer",
        languageText:"pickupByCustomer"
    },
    {

        className:"cancelled",
        label:"Cancelled",
        languageText:"cancelled"
    },
    {
        className:"rejected",
        label:"Rejected",
        languageText:"rejected"
    },
    {
        className:"returned",
        label:"Returned",
        languageText:"returned"
    }
    
]
// order columns field
export const OrderColumns = [
    {
        isVisible: true,
        title: "Customer",
        isDefault:true,
        languageText:"customer"
    },
    {
        isVisible: true,
        title: "Time",
        isDefault:true,
        languageText:"time"
    },
    {
        isVisible: true,
        title: "Invoice Track",
        isDefault:true,
        languageText:"invoiceTrack"
    },
    {
        isVisible: true,
        title: "Delivery",
        isDefault:true,
        languageText:"delivery"
    },
    {
        isVisible: true,
        title: "Payment Method",
        isDefault:true,
        languageText:"payMethod"
    },
    {
        isVisible: true,
        title: "Total Amount",
        isDefault:true,
        languageText:"totalAmount"
    },
    {
        isVisible: true,
        title: "Status",
        isDefault:true,
        languageText:"status"

    },
    {
        isVisible: false,
        title: "Location",
        isDefault:false,
        languageText:"location"
    },
    {
        isVisible: false,
        title: "Tips",
        isDefault:false,
        languageText:"tips"
    },
    {
        isVisible: false,
        title: "Taxes",
        isDefault:false,
        languageText:"taxes"
    },
]

// order columns field
export const TransactionsColoums = [
    {
        isVisible: true,
        title: "Date",
        languageText:"date"
    },
    {
        isVisible: true,
        title: "Invoice Id",
        languageText:"invoiceId"
    },
    {
        isVisible: true,
        title: "Starts",
        languageText:"starts"
    },
    {
        isVisible: true,
        title: "Transaction Type",
        languageText:"transactionType"
    },
    {
        isVisible: true,
        title: "Payment Method",
        languageText:"paymentMethod"
    },
    {
        isVisible: true,
        title: "Amount",
        languageText:"amount"
    },
    {
        isVisible: true,
        title: "Reward",
        languageText:"reward"
    },
    {
        isVisible: true,
        title: "Status",
        languageText:"status"
    },
]

export const deliveryStatus={
    "1":["Accept","Prepairing","Ready to PcikUp","Assigning Driver","Picked up","Completed","Pick up by customer","Cancelled","Rejected","Returned"],
    "2":["Servie to review","Accepted","In Progress","In Progress","In Progress","Completed"],
    "3":["Accpted","Prepairing","Ready to Pickup","Completed","","","","Cancelled","Rejected","Returned"],
    "4":["Accepted","","Print Label","Shipped","Completed","Cancelled","Rejected","Returned"],
}
export const filterOptions = [
    {
        title: "All Orders",
        languageTitle:"allOrders",
        data: AllOrders
    },
    {
        title: "All Status",
        languageTitle:"allStatus",
        data: AllStatus
    },
    {
        title: "All Payment",
        languageTitle:"allPayment",
        data: AllPayments
    },
]
export const handleOrderSatus = (deliveryCode, statusCode) => {
    let status=isNaN(statusCode);
   if(status||isNaN(deliveryCode)) return "";
   else status=Number(statusCode);
   let deliveryOption=Number(deliveryCode);
    if (deliveryOption == 1) {
      switch (status) {
        case 9: return 'Returned';
        case 8: return 'Rejected';
        case 7: return 'Cancelled';
        case 6: return 'Pickup by Customer';
        case 5: return 'Order Delivered';
        case 4: return 'Picked Up';
        case 3: return 'Assign Driver';
        case 2: return 'Order Prepared';
        case 1: return 'Order Accepted';
        case 0: return 'Review Order';

      }
    }
    else if (deliveryOption == 2) {
      switch (status) {
        case 9: return 'Order Returned';
        case 8: return 'Order Rejected';
        case 7: return 'Order Cancelled';
        case 5: return 'Order Delieverd';
        case 4: return 'In Progress';
        case 3: return 'In Progress';
        case 2: return 'In Progress';
        case 1: return 'Order Accepted';
        case 0: return 'Review Service';

      }
    }
    else if (deliveryOption == 3) {
      switch (status) {
        case 9: return 'Order Returned';
        case 8: return 'Order Rejected';
        case 7: return 'Order Cancelled';
        case 5: return 'Order Delieverd';
        case 4: return 'Order Pickuped';
        case 3: return 'Completed';
        case 2: return 'Order Prepared';
        case 1: return 'Order Accepted';
        case 0: return 'Review Order';

      }
    }
    else if (deliveryOption == 4) {
      switch (status) {
        case 9: return 'Order Returned';
        case 8: return 'Order Rejected';
        case 7: return 'Order Cancelled';
        case 5: return 'Order Delieverd';
        case 4: return 'Order Shipped';
        case 3: return 'Order Print Label';
        case 2: return 'Order Prepairing';
        case 1: return 'Order Accepted';
        case 0: return 'Review Order ';

      }
    }
  }