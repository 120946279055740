export let OfferLanguageColumns={
      product:"product",
      validity:"validity",
      offer_type:"offer_type",
      status:"status",
      "Pos Price":"Pos Price",
      "B2c Price":"B2c Price",
      // "B2b Price":true,
      "Category":"category",
      "Subcategory":"subCategory",
      "Cost Price":"costPrice"
    }