import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeastSellingApiAsync,
  getOfferProductApiAsync,
  getServiceCountDataApiAsync,
  getTopSellingApiAsync,
  getTopStaffApiAsync,
} from "../myProducts/productSlice";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import ServiceInnerHeader from "./serviceInnerHeader";
import ServiceCard from "./serviceCard";
import moment from "moment-timezone";
import TopSellingService from "./topSellingService";
import LeastSellingService from "./leastSellingService";
import OfferService from "./offerService";
import TopStaff from "./topStaff";
import HomeHeader from "../../components/UI/Orders/MainHeader";
import { useTranslation } from "react-i18next";

const MyService = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [filterValue, setFilterValue] = useState("week");
  const [allAddress, setAllAddress] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [topSellingService, setTopSellingService] = useState([]);
  const [leastSellingService, setLeastSellingService] = useState([]);
  const [offerProduct, setOfferProduct] = useState([]);
  const [topStaff, setTopStaff] = useState([]);

  const [topLoading, setTopLoading] = useState(false);
  const [leastLoading, setLeastLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [staffLoading, setStaffLoading] = useState(false);

  const [topLimit, setTopLimit] = useState(5);
  const [leastLimit, setLeastLimit] = useState(5);
  const [offerLimit, setOfferLimit] = useState(5);
  const [staffLimit, setStaffLimit] = useState(5);

  const addressOptions = [
    { value: null, label: "Location :All" }, // Add this for the "No Select" option
    ...allAddress.map((address) => ({
      value: address.id,
      label: address.format_address,
    })),
  ];
  const order = ["Product", "Category", "Sub Category", "ServiceTime"];

  const serviceCountDataHandle = () => {
    let countData = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // filter: filterValue,
        ...(filterValue && { filter: filterValue }),
        service_type: "service",
      },
    };
    if (startDate && endDate) {
      let newData = {
        ...countData.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      countData = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    if (selectedAddress !== null) {
      let newData = {
        ...countData.postData,
        seller_address_id: Array.isArray(selectedAddress)
          ? selectedAddress.join(",")
          : selectedAddress.toString(),
      };
      countData = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(getServiceCountDataApiAsync(countData))
      .then(unwrapResult)
      .then((obj) => {
        setCountData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getAllAddress = () => {
    setLoading(true);
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const gettopSellingService = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "service",
      // filter: filterValue,
      ...(filterValue && { filter: filterValue }),
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedAddress) {
      postData.seller_address_id = Array.isArray(selectedAddress)
        ? selectedAddress.join(",")
        : selectedAddress.toString();
    }

    if (topLimit) {
      postData.limit = topLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setTopLoading(true);
    dispatch(getTopSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setTopLoading(false);
        setTopSellingService(obj?.payload?.data);
      })
      .catch((obj) => {
        setTopLoading(false);
      });
  };

  const getleastSellingService = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "service",
      // filter: filterValue,
      ...(filterValue && { filter: filterValue }),
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedAddress) {
      postData.seller_address_id = Array.isArray(selectedAddress)
        ? selectedAddress.join(",")
        : selectedAddress.toString();
    }

    if (leastLimit) {
      postData.limit = leastLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setLeastLoading(true);
    dispatch(getLeastSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLeastLoading(false);
        setLeastSellingService(obj?.payload?.data);
      })
      .catch((obj) => {
        setLeastLoading(false);
      });
  };

  const getOfferList = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "service",
      // filter: filterValue,
      app_name: "merchant",
      ...(filterValue && { filter: filterValue }),
      // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18"
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedAddress) {
      postData.seller_address_id = Array.isArray(selectedAddress)
        ? selectedAddress.join(",")
        : selectedAddress.toString();
    }

    if (offerLimit) {
      postData.limit = offerLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };
    setOfferLoading(true);
    dispatch(getOfferProductApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setOfferLoading(false);
        setOfferProduct(obj?.payload?.data);
      })
      .catch((obj) => {
        setOfferLoading(false);
      });
  };

  const getTopStaff = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      // filter: filterValue,
      ...(filterValue && { filter: filterValue }),
      // seller_id: "2db22768-195d-4b64-b0bf-060d980bba18",
    };

    if (startDate && endDate) {
      postData.start_date = moment(startDate).format("YYYY-MM-DD");
      postData.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedAddress) {
      postData.seller_address_id = Array.isArray(selectedAddress)
        ? selectedAddress.join(",")
        : selectedAddress.toString();
    }

    if (staffLimit) {
      postData.limit = staffLimit;
    }

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setStaffLoading(true);
    dispatch(getTopStaffApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setStaffLoading(false);
        setTopStaff(obj?.payload?.data);
      })
      .catch((obj) => {
        setStaffLoading(false);
      });
  };

  useEffect(
    () => getleastSellingService(),
    [leastLimit, selectedAddress, filterValue, startDate, endDate]
  );
  useEffect(
    () => getOfferList(),
    [selectedAddress,  offerLimit , filterValue, startDate, endDate]
  );

  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(
    () => gettopSellingService(),
    [topLimit, selectedAddress, filterValue, startDate, endDate]
  );

  useEffect(
    () => getTopStaff(),
    [staffLimit, selectedAddress, filterValue, startDate, endDate]
  );

  useEffect(() => {
    serviceCountDataHandle();
  }, [selectedAddress, filterValue, startDate, endDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Service";
  }, []);
  // added common date picker in
  const handleDateValue = (values) => {
    setFilterValue(null);
    if (values.startDate == values.endDate) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };

  const handleFilterValue = (value) => {
    setEndDate(null);
    setStartDate(null);
    setFilterValue(value);
  };

  const handleSellerAddress = (value) => {
    setSelectedAddress(value);
  };
  return (
    <>
      <DashboardNavbar title={t("serviceOverview")} backShow={false} />
      <div className="myProductPage homePage">
        {/* <div className="commonMainHeaderParent"> */}
        {/* <ServiceInnerHeader
            subhead="vs. Same day last week"
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            allAddress={allAddress}
            addressOptions={addressOptions}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          /> */}
        <HomeHeader
          filterValue={filterValue}
          handleFilter={handleFilterValue}
          handleDate={handleDateValue}
          seller_id={
            auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid
          }
          tokenData={auth?.payload?.token}
          handleLocation={handleSellerAddress}
          // subhead="vs. Same day last week"
          productHead={true}
          selectText={t('locationAll')}
        />
        {/* </div> */}
        <div className="commonMainHeaderParent">
          <div className="container-fluid p-0">
            {loading ? (
              <span className="spinner-border inner-spin spinner-border-sm"></span>
            ) : (
              <div className="row">
                {countData?.result?.length > 0
                  ? countData?.result
                      .filter((item) => item?.title !== "Brand")
                      .slice() // Create a copy to avoid mutating the state
                      .sort(
                        (a, b) =>
                          order.indexOf(a.title) - order.indexOf(b.title)
                      ) // Sort dynamically
                      .map((items, index) => {
                        return (
                          <>
                            <ServiceCard
                              className={
                                items?.title == "Product"
                                  ? "totalProductBox"
                                  : items?.title == "Category"
                                  ? "totalCategoryBox"
                                  : items?.title == "Sub Category"
                                  ? "totalSubCategoryBox"
                                  : items?.title == "ServiceTime"
                                  ? "totalBrandBox"
                                  : ""
                              }
                              img={
                                items?.title == "Product"
                                  ? Images.totalService
                                  : items?.title == "Category"
                                  ? Images.productCategory
                                  : items?.title == "Sub Category"
                                  ? Images.productSubcategory
                                  : items?.title == "ServiceTime"
                                  ? Images?.serviceHour
                                  : ""
                              }
                              value={
                                items.title === "ServiceTime"
                                  ? `${Math.floor(items.count / 60)} h` // Convert minutes to hours
                                  : items.count
                              }
                              heading={t(`Total ${
                                items.title == "Product"
                                  ? "Service"
                                  : items.title == "ServiceTime"
                                  ? "Service Hours"
                                  : items.title
                              }`)}
                            />
                          </>
                        );
                      })
                  : ""}
              </div>
            )}
          </div>
        </div>
        <div className="row mt-md-3 mt-0">
          <TopSellingService
            topSellingService={topSellingService}
            topLoading={topLoading}
            setTopLimit={setTopLimit}
          />
          <LeastSellingService
            leastSellingService={leastSellingService}
            leastLoading={leastLoading}
            setLeastLimit={setLeastLimit}
          />
        </div>
        <div className="row mt-md-3 mt-0">
          <OfferService
            offerProduct={offerProduct}
            offerLoading={offerLoading}
            setOfferLimit={setOfferLimit}
          />
          <TopStaff
            topStaff={topStaff}
            staffLoading={staffLoading}
            setStaffLimit={setStaffLimit}
          />
        </div>
      </div>
    </>
  );
};

export default MyService;
