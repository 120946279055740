import React from 'react'
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import { useTranslation } from 'react-i18next';

const PaginationHeader = ({ 
    perPage, 
    handelPerPageResult, 
    currentPage, 
    totalLength,
    setCurrentPage,
    totalPage
 }) => {
    const { t } = useTranslation(); 
    console.log({ perPage, currentPage, totalLength, totalPage})
    const resultSelectOption = [
        { value: "10", label: "10" },
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
    ];
    const handleNext = () => {
        setCurrentPage((prev) => {
            // Calculate the total number of pages based on totalRows and perPage
            const totalPages = Math.ceil(totalPage / perPage);
            return prev < totalPages ? prev + 1 : prev;
        });
    };
    
    const handlePrev = () => {
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    };
    
    const startIndex = (currentPage - 1) * parseInt(perPage, 10) + 1;
    const endIdx = totalLength
        ? Math.min(startIndex + parseInt(totalLength, 10) - 1, totalPage)
        : 0;
  return (
    <div className="productOfferHead_Container">
    <div className="row align-items-center">
        <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-end gap-3">
                <div className="d-flex align-items-center gap-2">
                    <h3 className="fontSize14 txtDarkblue mb-0">
                        {t('showingResult')}
                    </h3>
                    <div className="reactSelectParent offerHeaderSelect">
                        <ReactSelect
                            options={resultSelectOption}
                            value={perPage}
                            placeholder={perPage}
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            onChange={handelPerPageResult}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minWidth: "75px",
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    border: "1px solid #D7DEFF",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? "#263682"
                                        : state.isFocused
                                            ? "#f5f6fc"
                                            : "#fff",

                                    color: state.isSelected ? "#fff" : "#636E9F",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: "#636E9F",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }),
                            }}
                            components={{
                                DropdownIndicator: () => (
                                    <img
                                        src={Images.blueDownArrow}
                                        width={24}
                                        height={24}
                                        alt="drop_icon"
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className="paginationParent">
                    <div className="d-flex align-items-center gap-1" onClick={
                        handlePrev
                    }>
                        <div className="backPageBox ">
                            <img
                                src={Images.arrowDoubleLeft}
                                alt="arrowDoubleRight"
                            />
                        </div>
                        <div className="backPageBox backPageBox2">
                            <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                        </div>
                    </div>
                    <h6 className="fontSize14 txtDarkblue mb-0">
                    {endIdx > 0
                            ? `${startIndex}-${endIdx} of ${totalPage}`
                            : "0 of 0"}
                    </h6>
                    <div className="d-flex align-items-center gap-1" onClick={
                        handleNext
                    }>
                        <div className="nextPageBox nextPageBox2" >
                            <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                        </div>
                        <div className="nextPageBox ">
                            <img
                                src={Images.arrowDoubleRight}
                                alt="arrowDoubleRight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default PaginationHeader
