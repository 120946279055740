import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import UploadproductModal from "../scalePlan/ uploadproductModal";
import CustomModal from "../../components/shared/CustomModal";

import * as Images from "../../../utilities/images";
import file from "../../public/file/Product_Details_mini.xlsx";
import AddNewProductModal from "./productAddingOption";
import { useTranslation } from "react-i18next";

const FindProduct = (props) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [businessType, setBusinesType] = useState("");
  const [masterCategoryId, setMasterCategoryId] = useState("");
  const [masterCategoryName, setMasterCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [masterCategoryData, setMasterCategoryData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const handleMasterCategoryChange = (e) => {
    setMasterCategoryId(e.target.value);
    let name = masterCategoryData?.find((v) => v?.id == e.target.value)?.name;
    setMasterCategoryName(name);
    let filteredData = serviceList?.filter(
      (val) => val?.type === e.target.value
    );
    setFilteredList(filteredData);
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // main_category: true,
        service_id: e.target.value,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleBusinessChange = (e) => {
    setBusinesType(e.target.value);
    setCategoryId(e.target.value);
    let filteredData = serviceList?.filter(
      (val) => val?.type === e.target.value
    );
    setFilteredList(filteredData);
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // main_category: true,
        category_id: e.target.value,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setSubCategory(obj?.payload?.data ? obj?.payload?.data : []);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSubcategoryDataChange = (e) => {
    setSubCategoryId(e.target.value);
    let brandData = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.target.value,
        check_product_existance: false,
      },
    };
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setBrand(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleBrandChange = (e) => {
    setBrandId(e.target.value);
  };

  const handleFindProducts = () => {
    // if (!categoryId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Select Category")
    //   }
    // }
    // else if (!subCategoryId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Select Sub-Category")
    //   }
    // }
    // else if (!brandId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please Select Brand")
    //   }
    // }
    // else {
    // }
    history.push(`products`);
  };

  const getMasterCategoriesList = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setMasterCategoryData(obj?.payload?.data ? obj?.payload?.data : []);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMasterCategoriesList();
    localStorage.setItem("backButtonRoute", props?.flag);
  }, []);

  return (
    <>
      <div className="productPluginSection productAddingOption_Section d-flex align-items-center justify-content-center">
        <button
          className="createOfferCloseBtn d-flex previousPageBtn "
          onClick={() => history.push("/new-user")}
        >
          <img
            src={Images.backArrow}
            alt="logo"
            className="closeCrossIcon me-1 "
          />
          {t('back')}
        </button>

        <div className="productPluginsSection text-center">
          <div className="container">
            <div className="row pb-3">
              <div className="col-12">
                <h1 className="fontSize36 fw400 txtBlue">
                  {t('addProduct')}
                  {/* or Services{" "} */}
                </h1>
                <p className="fontSize20 fw400 txtBlue">
                  {t('productTagline')}
                </p>
              </div>
            </div>
            <div className="optionBoxesParent">
              <div className="row justify-content-center">
                <div className="col-md-6 d-flex justify-content-end">
                  <div className="addingOptionBox">
                    <figure className="bigTickSection">
                      <img
                        src={Images.jobrDatabase}
                        alt="databaseImage"
                        className="img-fluid"
                      />
                    </figure>
                    <h4 className="fontSize24 fw500 txtBlue">JOBR {t('database')}</h4>
                    <p className="fontSize14 fw400 txtBlue pt-1">
                      JOBR {t('databaseTitle')}
                    </p>

                    <button
                      className="commonBlueBtn active gap-2 mt-3 w-100"
                      type="button"
                      onClick={() => history.push(`/products`)}
                    >
                      <img src={Images.skyblueSearchIcon} alt="plusIcon" />
                      {t('findProduct')}
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="addingOptionBox">
                    <figure className="bigTickSection">
                      <img
                        src={Images.uploadFileOption}
                        alt="databaseImage"
                        className="img-fluid"
                      />
                    </figure>
                    <h4 className="fontSize24 fw500 txtBlue">{t('uploaddbFile')}</h4>
                    <p className="fontSize14 fw400 txtBlue pt-1">
                      {t('dbfileDetails')}
                    </p>

                    <button
                      className="commonBorderBtn gap-2 mt-3 w-100"
                      type="button"
                      onClick={() => history.push(`/productUploadFile`)}
                    >
                      <img src={Images.plusIcon} alt="plusIcon" />
                      {t('upload')}
                    </button>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <div className="addingOptionBox">
                    <figure className="bigTickSection">
                      <img
                        src={Images.pluginsOption}
                        alt="databaseImage"
                        className="img-fluid"
                      />
                    </figure>
                    <h4 className="fontSize24 fw500 txtBlue">{t('plugin')}</h4>
                    <p className="fontSize14 fw400 txtBlue pt-1">
                      JOBR {t('pluginBuild')}
                    </p>

                    <button
                      className="commonBorderBtn gap-2 mt-3 w-100"
                      type="button"
                      onClick={() => history.push(`/productPlugin`)}
                    >
                      {t('plugin')}
                      <img src={Images.upArrow} alt="plusIcon" />
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="addingOptionBox">
                    <figure className="bigTickSection">
                      <img
                        src={Images.uploadFileOption}
                        alt="databaseImage"
                        className="img-fluid"
                      />
                    </figure>
                    <h4 className="fontSize24 fw500 txtBlue">{t('inputManually')}</h4>
                    <p className="fontSize14 fw400 txtBlue pt-1">
                      JOBR {t("inputTitle")}
                    </p>

                    <button
                      className="commonBorderBtn gap-2 mt-3 w-100"
                      type="button"
                      // onClick={() => history.push(`/addingproduct-option`)}
                      onClick={() => history.push(`/add-product/myProducts`)}
                    >
                      <img src={Images.lightBlueEditIcon} alt="plusIcon" />
                      {t('addManually')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="false"
        showCloseBtn={true}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "UploadproductModal" ? "uploadproductModal" : ""
        }
        size={modalDetail.flag === "UploadproductModal" ? "lg" : ""}
        child={
          modalDetail.flag === "UploadproductModal" ? (
            <UploadproductModal
              previousModalClose={() => props.close()}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
      />
    </>
  );
};

export default FindProduct;