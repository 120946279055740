import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Images from "../../../../utilities/images";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import ReactAudioPlayer from "react-audio-player";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { uploadAudioApi } from "../MessageApi";
import { selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { uploadAudioApiAsync } from "../messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
const fileTypes = ["webm"];

const VoicerecordModal = (props) => {
  const { t } = useTranslation();
  const [audioUrl, setAudioUrl] = useState("");
  const recorderControls = useAudioRecorder();
  const [loading, setLoading] = useState(false)
  const [audioBlob, setaudioBlob] = useState(null);
  const auth = useSelector(selectLoginAuth);
   const dispatch=useDispatch();
  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    setaudioBlob(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    setAudioUrl(url);
  };


  const [audioUrls, setaudioUrls] = useState(null)
  const sendAudio = async (e, a) => {
    e.preventDefault();
    handleSend();
  };
  const handleSend = async () => {
    if (!audioBlob) return;
    if(audioUrls){
      console.log(audioUrls)
      props?.audio(audioUrls,"audio");
    }
    const formData = new FormData();
    formData.append('file', audioBlob,"recording.wav");
    const params={
      postData:formData,
      tokenData: auth?.payload?.token,
    }
    setLoading(true);
     dispatch(uploadAudioApiAsync(params))
     .then(unwrapResult)
     .then(res=>{
      setLoading(false);
      console.log("Audio uploaded",res);
      setaudioUrls(res?.payload)
      props?.audio(res?.payload,"audio");
      props?.close();
     })
     .catch(err=>{
      setLoading(false);
      console.log("Unable to upload audio",err);
     })

    
  };
  const handleChange = (file) => {
    let objUrl = URL.createObjectURL(file[0]);
    setAudioUrl(objUrl);
  };
const handleError=(e)=>{
  console.log(e,"errors")
  toast.error("Audio device not found");
}
  return (
    <div className="uploadVoiceModal text-center">
      <form
        className="horizontalForms"
        onSubmit={(e) => {
          sendAudio(e, audioUrl);
        }}
      >
        <div className="row">
          <div className="form-group col-md-12 text-start">
            <div className="flexBox justify-content-center">
              <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                onNotAllowedOrFound={
                  handleError
                }
              />
            </div>
            {
              recorderControls?.isRecording&&<div className="flexBox justify-content-center pt-3">
              <input
                type="button"
                onClick={recorderControls.stopRecording}
                value="Stop"
                className="modalNextBtn modalDiscardBtn modalStopBtn"
              />
              {/* <input type="button" onClick={recorderControls.startRecording} value="start" />  */}
              <input
                type="button"
                onClick={recorderControls.togglePauseResume}
                value={recorderControls?.isPaused?"Resume": "Pause"}
                className="activeProductBtn pauseOfferModalBtn"
              />
            </div>
            }
            {/* <div className="mt-3">
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="audio"
                types={fileTypes}
              />
            </div> */}
            {
              audioUrl&&<div className="mt-3 text-center">
              <ReactAudioPlayer
                src={audioUrl}
                autoPlay={false}
                controls={true}
              />
            </div>
            }

            <div className="modalfooterBtn">
              <button
              disabled={loading}
                className="modalDiscardBtn w-100 "
                onClick={() => props?.close()}
              >
                {t('discard')}
              </button>
              <button
              disabled={loading||!audioBlob}
                type="submit"
                className={`modalNextBtn modalDiscardBtn ${audioBlob?'active':''} w-100 flexBox gap-2 justify-content-center`}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                {t('send')}
                <img src={Images.closeTag} alt="img" width={24} height={24} />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VoicerecordModal;
