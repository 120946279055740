import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TopSellingService = (props) => {
  const { t } = useTranslation();
  const { topSellingService, topLoading, setTopLimit } = props;
  const history = useHistory();

  return (
    <div className="col-md-6  mb-4 mb-md-0">
      <div className="sellingProductContainer">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="fontSize20 txtBlue fw500 mb-0">
            {t('topSellingService')}
          </h2>
          {Array.isArray(topSellingService) &&
            topSellingService?.length > 0 && (
              <button
                className="viewAllBtn"
                onClick={() => {
                  history.push({
                    pathname: "/service-list",
                    state: { category: "top" },
                  });
                }}
              >
                {t('viewAll')}
              </button>
            )}
        </div>

        <div className="commonTable sellingProductTable table-responsive mt-3">
          <table className="w-100">
            <tbody>
              {topLoading ? (
                <tr>
                  <td colSpan={2}>
                    <div className="d-flex align-item-center justify-content-center">
                      <span className="spinner-border spinner-border-md spinnerDark "></span>
                    </div>
                  </td>
                </tr>
              ) : topSellingService?.length > 0 ? (
                topSellingService.map((value, idx) => (
                  <tr key={idx}>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={value?.product_details?.image}
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="topSellProductHead mb-0 pb-1">
                            {value?.product_details?.name}
                          </h3>
                          {/* <div className="d-flex align-items-center">
                          <span className="dot"></span>
                          <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                            Yellow / M
                          </h6>
                        </div> */}
                        </div>
                      </div>
                    </td>
                    <td className="text-end">
                      <h5 className="fontSize12 fw400 mb-0 txtBlack">
                        {value?.qty_sum}
                      </h5>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex align-iten-center justify-content-center">
                        <h4 className="fontSize14 fw400 txtLightBlue mb-0 ps-1">
                          {t('noRecordfound')}
                        </h4>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TopSellingService;
