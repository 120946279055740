import * as React from 'react';
import { Text, View } from 'react-native';
import { u } from '../commonStyles';
import { useTheme } from '../theme/ThemeContext';
import { formatHour } from '../utils/datetime';
import { objHasContent } from '../utils/object';
var _HourGuideColumn = function (_a) {
    var cellHeight = _a.cellHeight, hour = _a.hour, ampm = _a.ampm, _b = _a.hourStyle, hourStyle = _b === void 0 ? {} : _b, hourComponent = _a.hourComponent;
    var theme = useTheme();
    var textStyle = React.useMemo(function () { return ({
        color: theme.palette.gray[500],
        fontSize: theme.typography.xs.fontSize,
    }); }, [theme]);
    var formattedHour = formatHour(hour, ampm);
    var renderDefaultComponent = function () { return (React.createElement(View, { style: { height: cellHeight } },
        React.createElement(Text, { style: [objHasContent(hourStyle) ? hourStyle : textStyle, u['text-center']] }, formattedHour))); };
    return (React.createElement(React.Fragment, null, hourComponent ? (React.createElement(View, { style: { height: cellHeight + 12 } }, hourComponent(formattedHour))) : (renderDefaultComponent())));
};
export var HourGuideColumn = React.memo(_HourGuideColumn, function () { return true; });
