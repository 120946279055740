import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePosUserApiAsync,
  getPosUsersAsync,
  selectLoginAuth,
} from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomModal from "../../components/shared/CustomModal";
import PosDetailsModal from "./posDetailsModal";
import UpdatePosUserModal from "./UpdatePosUserModal";
import DeleteModal from "./deleteModal";
import { toast } from "react-toastify";
import { getProductServiceListApiAsync } from "../myProducts/productSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import * as Images from "../../../utilities/images";
import PaginationHeader from "../../components/PaginationHeader";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PosUser = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [posList, setPosList] = useState([]);
  const [allServiceData, setAllServiceData] = useState([]);
  const toastId = React.useRef(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  let sellerId=auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid;
  const [key, setKey] = useState(Math.random());

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const deletePosUserHandle = (id) => {
    let params = {
      postData: {
        id: id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(deletePosUserApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        let data = {
          postData: {
            seller_id: auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
            page: currentPage,
            limit: perPage,
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getPosUsersAsync(data))
          .then(unwrapResult)
          .then((obj) => {
            setPosList(obj?.payload?.pos_staff);
            setTotalRows(obj?.payload?.total);
            setLoading1(false);
            handleOnCloseModal();
          })
          .catch((obj) => {
            setLoading1(false);
          });
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Payroll & Staff";
  }, []);

  const serviceHandle = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "service",
        filter_deleted: true,
        app_name: "merchant",
      },
    };
    setLoading(true);
    dispatch(getProductServiceListApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setAllServiceData(obj?.payload?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    serviceHandle();
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        page: currentPage,
        limit: perPage,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setPosList(obj?.payload?.pos_staff);
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  }, [perPage, currentPage]);

  return (
    <>
      <DashboardNavbar title={t('teamMembers')} backShow={false} />
      <section className="walletStats h-100">
        <div>

          <PaginationHeader
            currentPage={currentPage}
            totalPage={totalRows}
            perPage={perPage}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            dataLength={totalRows}
            headerClass={"posSalePagination"}
          />
        </div>
        <div className="commonTable table-responsive mt-3">
          <table className="w-100">
            <thead>
              <tr>
                <th className="text-start">#</th>
                <th className="text-start">{t('teamMemeber')}</th>
                <th>{t('role')}</th>
                <th>{t('services')}</th>
                <th>{t('email')}</th>
                <th>{t('mobileNo')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr className="text-center border-none">
                  <td colSpan="100%">
                    <div className="d-flex">
                      <span className="spinner-border inner-spin spinner-border-sm"></span>
                    </div>
                  </td>
                </tr>
              ) : posList?.length > 0 ? (
                posList.map((users, index) => {
                  const roleData =
                    users?.user?.user_roles?.map((v) => v?.role?.name) || [];

                  let selectedServicdIds = users?.pos_staff_products?.map(
                    (v) => v?.product_id
                  );
                  let serviceSelected = allServiceData
                    ?.filter((value) =>
                      selectedServicdIds?.includes(value?.id)
                    )
                    ?.map((v, i) => {
                      return v?.name;
                    });
                    let uniqe_id=users?.user?.unique_uuid;
                  return (
                    <tr key={index}>
                      <td className="orderTrackTableTxt text-start">
                        {
                          (currentPage > 1 ? (
                            (currentPage - 1) * perPage + index + 1
                          ) : index + 1)
                        }
                      </td>
                      <td>
                        <div className="flexBox gap-2">
                          <img
                            src={
                              users?.user?.user_profiles?.profile_photo
                                ? users?.user?.user_profiles?.profile_photo
                                : Images.userImg_
                            }
                            alt="img"
                            className="userInnerImg"
                          />
                          <h3 className="orderTrackTableTxt mb-0">
                            {users?.user?.user_profiles?.firstname
                              ? users?.user?.user_profiles?.firstname
                              : "" +
                                " " +
                                users?.user?.user_profiles?.lastname
                                ? users?.user?.user_profiles?.lastname
                                : ""}
                          </h3>
                        </div>
                      </td>
                      <td className="orderTrackTableTxt">
                        {roleData?.toString()?.length > 25
                          ? roleData?.toString()?.substring(0, 25) + "..."
                          : roleData?.toString()}
                      </td>
                      <td className="orderTrackTableTxt">
                        {serviceSelected?.toString()?.length > 50
                          ? serviceSelected?.toString()?.substring(0, 50) +
                          "..."
                          : serviceSelected?.toString()}
                      </td>
                      <td className="orderTrackTableTxt">
                        {users?.user?.email?.length > 20
                          ? users?.user?.email?.substring(0, 20) + "..."
                          : users?.user?.email}
                      </td>
                      <td className="orderTrackTableTxt">
                        {users?.user?.user_profiles?.full_phone_number}
                      </td>
                      <td className="text-end">
                        <Dropdown className="createRoleTableDropDown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="variantsTable_Dropdown"
                          >
                            <img src={Images.moreVerticalLogo} alt="logo" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedId(users?.user_id);
                                setSelectedRow(users);
                                setModalDetail({
                                  show: true,
                                  flag: "posModal",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <div className="d-flex gap-2 align-items-center">
                                <img
                                  src={Images.darkBlueEyeIcon}
                                  alt="logo"
                                />
                                <h3 className="fontSize14 mb-0">
                                  {" "}
                                  {t('viewDetails')}
                                </h3>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => {
                                setSelectedId(users?.user_id);
                                setSelectedRow(users);
                                setModalDetail({
                                  show: true,
                                  flag: "updatePosModal",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <div className="d-flex gap-2 align-items-center">
                                <img
                                  src={Images.darkblueEditIcon}
                                  alt="logo"
                                />
                                <h3 className="fontSize14 mb-0">{t('edit')}</h3>
                              </div>
                            </Dropdown.Item>

                          {(uniqe_id!=sellerId)&&  <Dropdown.Item
                              onClick={() => {
                                setSelectedId(users?.user_id);
                                setModalDetail({
                                  show: true,
                                  flag: "deleteRoleModal",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <div className="d-flex gap-2 align-items-center">
                                <img src={Images.newDeleteIcon} alt="logo" />
                                <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                  {t('delete')}
                                </h3>
                              </div>
                            </Dropdown.Item>}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center border-none">
                  <td colSpan="100%">
                    <div className="d-flex">
                      <p className="text-center w-100">{t('noDataFound')}</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop={true}
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        size={modalDetail.flag === "updatePosModal" ? "lg" : ""}
        ids={
          modalDetail.flag === "deleteRoleModal"
            ? "stopOfferModal"
            : modalDetail.flag === "updatePosModal"
              ? "updatePosUsersModal"
              : modalDetail.flag === "posModal"
                ? "posUserDetailsModal"
                : ""
        }
        child={
          modalDetail.flag === "deleteRoleModal" ? (
            <DeleteModal
              loadingState={loading1}
              flag="deletePosUser"
              id={selectedId}
              deletePosUserHandle={(e) => deletePosUserHandle(e)}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "updatePosModal" ? (
            <UpdatePosUserModal
              selectedRow={selectedRow}
              setPosList={setPosList}
              id={selectedId}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "posModal" ? (
            <PosDetailsModal
              selectedRow={selectedRow}
              id={selectedId}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          <>
            {modalDetail.flag === "updatePosModal" ? (
              <>
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  Update Pos User
                </h2>
                <img
                  src={Images.modalCross}
                  alt="img"
                  onClick={() => {
                    handleOnCloseModal();
                  }}
                  className="cursorPointer"
                />
              </>
            ) : modalDetail.flag === "posModal" ? (
              <>
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  POS details
                </h2>
                <img
                  src={Images.modalCross}
                  alt="img"
                  onClick={() => {
                    handleOnCloseModal();
                  }}
                  className="cursorPointer"
                />
              </>
            ) : (
              ""
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default PosUser;
