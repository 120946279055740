import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../../auth/authSlice";
import Loader from "../../../components/UI/Loader/Loader";
import { unwrapResult } from "@reduxjs/toolkit";

const ShippingAddressModal = (props) => {
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState();
  const [activeTab, setActiveTab] = useState("")
  const dispatch = useDispatch()

  const sendSeppingAddress = (address) => {
  let sendAddress = {
      firstName: auth?.payload?.user?.user_profiles?.firstname,
      lastName: auth?.payload?.user?.user_profiles?.lastname,
      company_name: auth?.payload?.user?.user_profiles?.organization_name,
      email: auth?.payload?.user?.email ? auth?.payload?.user?.email : auth?.payload?.email,
      mobile: auth?.payload?.user?.user_profiles?.full_phone_number,
      full_address: address?.street_address
    }
    props.shippingAddress(sendAddress, "shipping_address");
    props.close();

  }

  const getShippingAddressList = () => {
    setLoading(true)
    let params = {
      token: auth?.payload?.token,
      sellerId:
      auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
    }
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setShippingAddress(obj?.payload)
        setLoading(false);
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getShippingAddressList();
  }, [])
  return (
    <>
      <div className="shippingModalParent ">
        {
          loading?<Loader/>:
          shippingAddress?.length > 0 ?
            <>
              {shippingAddress?.map((item, index) =>(
                 <div className={`shippingBoxes ${activeTab === `shapping${item?.id}` ? 'active' : ''}`}
                 onClick={() => { setSelectedShippingAddress(item); setActiveTab(`shapping${item?.id}`) }}
                 >
                 <figure className="shippingImgParent">
                   <img src={Images.storeImg} alt="img" width={24} height={24} />
                 </figure>
                 <div>
                   <h3 className="fontSize18 fw500 txtDarkblue">{
                    item?.address_type
                   }</h3>
                   <p className="fontSize14 txtBlack">
                   {item?.format_address}
                   </p>
                 </div>
               </div>
              ))}
              </>
              :<>
              <div className="text-center">No data found</div>
              </>
        }
        
      </div>
      <button className="commonBlueBtn w-100 active"
      disabled={!activeTab} onClick={() => sendSeppingAddress(selectedShippingAddress)}
      >Share</button>
    </>
  );
};

export default ShippingAddressModal;
