import React, { useEffect } from 'react'
import { exchangeTokenApiAsync } from '../authSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Cookies from "universal-cookie";
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const VerifyPage = () => {
    const history = useHistory()
    const cookies = new Cookies();
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(exchangeTokenApiAsync(cookies.get('userLogin')))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success("Login Successfully")
                }
                if (obj?.payload?.is_google_auth_enable) {
                    history.push('/authenticate')
                } else {
                    history.push('/new-user')
                }
            }
            )
            .catch((obj) => {
            })
    }, [])
    return (
        <div className="loading-area">
            <div className="loader1">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default VerifyPage