import React, { useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import * as Images from "../../../utilities/images";
import TimePicker from "react-time-picker";
import { Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { updateSellerInfoApiAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getUserSettingsApiAsync,
  updateUserSettingAsync,
} from "../systemConfig/systemConfigSlice";
import SelectMenu from "../../components/UI/Product/SelectMenu";
import { options, dayList } from "../../../utilities/helpers";
import { useTranslation } from "react-i18next";

const ServiceSetting = () => {
  const { t } = useTranslation();
  const [toggleState, setToggleState] = useState({
    toggle1: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
    toggle5: false,
    toggle6: false,
    toggle7: false,
    toggle8: false,
  });

  const handleToggleState = (name) => {
    setToggleState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const resultSelectOption = [
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" },
  ];

  let selectedOptionNew = [];
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userSettingData, setUserSettingData] = useState("");
  const [serviceAvailability, setServiceAvailability] = useState("");
  const [startMondayTime, setStartMondayTime] = useState("");

  const [endMondayTime, setEndMondayTime] = useState("");

  const [startTuesddayTime, setStartTuesdayTime] = useState("");
  const [endTuesdayTime, setEndTuesdayTime] = useState("");
  const [startWednesdayTime, setStartWednesdayTime] = useState("");
  const [endWednesdayTime, setEndWednesdayTime] = useState("");
  const [startThursdayTime, setStartThursdayTime] = useState("");
  const [endThursdayTime, setEndThursdayTime] = useState("");
  const [startFridayTime, setStartFridayTime] = useState("");
  const [endFridayTime, setEndFridayTime] = useState("");
  const [startSaturdayTime, setStartSaturdayTime] = useState("");
  const [endSaturdayTime, setEndSaturdayTime] = useState("");
  const [startSundayTime, setStartSundayTime] = useState("");
  const [endSundayTime, setEndSundayTime] = useState("");
  const [businessHours, setBusinessHours] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  console.log(selectedOptions, "selectedOptions");

  function convertTo24HourFormat(time12Hour) {
    if (!time12Hour) {
      return;
    }
    // Split the input time string into hours and minutes
    const [time, period] = time12Hour?.split(" ");
    const [hours, minutes] = time?.split(":");

    // Convert hours to an integer
    let hours24Hour = parseInt(hours, 10);
    // Adjust hours based on AM or PM
    if ((period == "pm" || period == "PM") && hours24Hour != 12) {
      hours24Hour = hours24Hour + 12;
    } else if ((period == "am" || period == "AM") && hours24Hour == 12) {
      hours24Hour = 0;
    }

    // Format hours and minutes with leading zeros
    const formattedHours = hours24Hour.toString().padStart(2, "0");
    const formattedMinutes = minutes.padStart(2, "0");

    // Combine the hours and minutes into the 24-hour format
    const time24Hour = `${formattedHours}:${formattedMinutes}`;
    return time24Hour;
  }

  const handleDefaultOnlineOrder = (e) => {
    let data = {
      daily_booking_limit: e.value,
      app_name: "pos",
    };
    updateUserSettingshandle(data);
  };

  const handleDefaultTimeSlots = (e) => {
    let data = {
      default_time_slot_step: e.value,
      app_name: "pos",
    };
    updateUserSettingshandle(data);
  };
  const handleMinimumBooking = (e) => {
    let data = {
      min_time_required_before_booking: e.value,
      app_name: "pos",
    };
    updateUserSettingshandle(data);
  };
  const handleMinimumCancelling = (e) => {
    let data = {
      min_time_required_before_cancelling: e.value,
      app_name: "pos",
    };
    updateUserSettingshandle(data);
  };
  const handleMinimumResheduling = (e) => {
    let data = {
      min_time_required_before_rescheduling: e.value,
      app_name: "pos",
    };
    updateUserSettingshandle(data);
  };
  const handlePeriodBeforBooking = (e) => {
    let data = {
      advance_period_before_booking: e.value,
      app_name: "pos",
    };
    updateUserSettingshandle(data);
  };
  const handleCheckBox = (e, flag) => {
    let data = {};
    if (flag === "serviceDuration") {
      data = {
        use_service_duration_for_booking: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "bufferSlots") {
      data = {
        use_service_buffer_in_slots: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "maxCapacity") {
      data = {
        allow_above_max_capacity_booking: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "minCapacity") {
      data = {
        allow_below_min_capacity_booking: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "employeeSelection") {
      data = {
        employee_selection: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "usaHolidays") {
      data = {
        usa_holidays_as_days_off: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "clientCancel") {
      data = {
        client_cancel_own_booking: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "removeStaff") {
      data = {
        remove_staff_from_booking: e.target.checked,
        app_name: "pos",
      };
    }
    if (flag === "waitlist") {
      data = {
        enable_waiting_list: e.target.checked,
        app_name: "pos",
      };
    }
    updateUserSettingshandle(data);
  };

  const handleStartTimeChange = (newStartTime, flag) => {
    let timeM = endMondayTime
      ? new Date("1970-01-01T" + convertTo24HourFormat(endMondayTime) + ":00")
      : "";
    let timeT = endTuesdayTime
      ? new Date("1970-01-01T" + convertTo24HourFormat(endTuesdayTime) + ":00")
      : "";
    let timeW = endWednesdayTime
      ? new Date(
          "1970-01-01T" + convertTo24HourFormat(endWednesdayTime) + ":00"
        )
      : "";
    let timeTh = endThursdayTime
      ? new Date("1970-01-01T" + convertTo24HourFormat(endThursdayTime) + ":00")
      : "";
    let timeF = endFridayTime
      ? new Date("1970-01-01T" + convertTo24HourFormat(endFridayTime) + ":00")
      : "";
    let timeS = endSaturdayTime
      ? new Date("1970-01-01T" + convertTo24HourFormat(endSaturdayTime) + ":00")
      : "";
    let timeSu = endSundayTime
      ? new Date("1970-01-01T" + convertTo24HourFormat(endSundayTime) + ":00")
      : "";
    let convertTimeNew = convertTo24HourFormat(newStartTime);
    let timeNew = new Date("1970-01-01T" + convertTimeNew + ":00");

    if (flag == "monday") {
      setStartMondayTime(newStartTime);
    }
    if (flag == "tuesday") {
      setStartTuesdayTime(newStartTime);
    }
    if (flag == "wednesday") {
      setStartWednesdayTime(newStartTime);
    }
    if (flag == "thursday") {
      setStartThursdayTime(newStartTime);
    }
    if (flag == "friday") {
      setStartFridayTime(newStartTime);
    }
    if (flag == "saturday") {
      setStartSaturdayTime(newStartTime);
    }
    if (flag == "sunday") {
      setStartSundayTime(newStartTime);
    }
  };

  const handleEndTimeChange = (newEndTime, flag) => {
    if (flag == "monday") {
      setEndMondayTime(newEndTime);
    }
    if (flag == "tuesday") {
      setEndTuesdayTime(newEndTime);
    }
    if (flag == "wednesday") {
      setEndWednesdayTime(newEndTime);
    }
    if (flag == "thursday") {
      setEndThursdayTime(newEndTime);
    }
    if (flag == "friday") {
      setEndFridayTime(newEndTime);
    }
    if (flag == "saturday") {
      setEndSaturdayTime(newEndTime);
    }
    if (flag == "sunday") {
      setEndSundayTime(newEndTime);
    }
  };

  const handleCheckboxChange2 = (option, flag) => {
    if (selectedOptions.find((val) => val?.id == option?.id)) {
      selectedOptionNew = selectedOptions.filter(
        (item) => item?.id !== option?.id
      );
      setSelectedOptions(selectedOptionNew);
    } else {
      selectedOptionNew = [...selectedOptions, option];
      setSelectedOptions([...selectedOptions, option]);
    }

    let startTime =
      flag == 1
        ? startMondayTime
        : flag == 2
        ? startTuesddayTime
        : flag == 3
        ? startWednesdayTime
        : flag == 4
        ? startThursdayTime
        : flag == 5
        ? startFridayTime
        : flag == 6
        ? startSaturdayTime
        : startSundayTime;

    let endTime =
      flag == 1
        ? endMondayTime
        : flag == 2
        ? endTuesdayTime
        : flag == 3
        ? endWednesdayTime
        : flag == 4
        ? endThursdayTime
        : flag == 5
        ? endFridayTime
        : flag == 6
        ? endSaturdayTime
        : endSundayTime;

    if (selectedOptionNew.find((day) => day.id == 1)) {
      setStartMondayTime(startTime);
      setEndMondayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 2)) {
      setStartTuesdayTime(startTime);
      setEndTuesdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 3)) {
      setStartWednesdayTime(startTime);
      setEndWednesdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 4)) {
      setStartThursdayTime(startTime);
      setEndThursdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 5)) {
      setStartFridayTime(startTime);
      setEndFridayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 6)) {
      setStartSaturdayTime(startTime);
      setEndSaturdayTime(endTime);
    }
    if (selectedOptionNew.find((day) => day.id == 7)) {
      setStartSundayTime(startTime);
      setEndSundayTime(endTime);
    }
  };
  const handleCopy = (flag) => {
    setSelectedOptions([]);
  };
  const handleCheckboxChange1 = (event) => {
    var updatedList = businessHours?.length > 0 ? [...businessHours] : [];
    if (event.target.checked) {
      updatedList =
        businessHours?.length > 0
          ? [...businessHours, event.target.value]
          : [event.target.value];
    } else {
      updatedList.splice(businessHours?.indexOf(event.target.value), 1);
    }
    setBusinessHours(updatedList);
  };

  const handleUpdate = () => {
    if (
      (businessHours?.includes("monday") && !startMondayTime) ||
      (businessHours?.includes("tuesday") && !startTuesddayTime) ||
      (businessHours?.includes("wednesday") && !startWednesdayTime) ||
      (businessHours?.includes("thursday") && !startThursdayTime) ||
      (businessHours?.includes("friday") && !startFridayTime) ||
      (businessHours?.includes("saturday") && !startSaturdayTime) ||
      (businessHours?.includes("sunday") && !startSundayTime)
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select correct start time");
      }
      return false;
    }
    if (
      (businessHours?.includes("monday") && !endMondayTime) ||
      (businessHours?.includes("tuesday") && !endTuesdayTime) ||
      (businessHours?.includes("wednesday") && !endWednesdayTime) ||
      (businessHours?.includes("thursday") && !endThursdayTime) ||
      (businessHours?.includes("friday") && !endFridayTime) ||
      (businessHours?.includes("saturday") && !endSaturdayTime) ||
      (businessHours?.includes("sunday") && !endSundayTime)
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select correct end time");
      }
      return false;
    }
    const paramsData = {
      postData: {
        id: serviceAvailability?.id,
        service_availability: {
          availability: [
            {
              day: "1",
              opening_time: businessHours?.includes("monday")
                ? moment(startMondayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("monday")
                ? moment(endMondayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "2",
              opening_time: businessHours?.includes("tuesday")
                ? moment(startTuesddayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("tuesday")
                ? moment(endTuesdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "3",
              opening_time: businessHours?.includes("wednesday")
                ? moment(startWednesdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("wednesday")
                ? moment(endWednesdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "4",
              opening_time: businessHours?.includes("thursday")
                ? moment(startThursdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("thursday")
                ? moment(endThursdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "5",
              opening_time: businessHours?.includes("friday")
                ? moment(startFridayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("friday")
                ? moment(endFridayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "6",
              opening_time: businessHours?.includes("saturday")
                ? moment(startSaturdayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("saturday")
                ? moment(endSaturdayTime, "h:mm a").format("h:mm a")
                : "",
            },
            {
              day: "7",
              opening_time: businessHours?.includes("sunday")
                ? moment(startSundayTime, "h:mm a").format("h:mm a")
                : "",
              closing_time: businessHours?.includes("sunday")
                ? moment(endSundayTime, "h:mm a").format("h:mm a")
                : "",
            },
          ],
        },
      },
      tokenData: auth?.payload?.token,
    };
    setButtonLoading(true);
    dispatch(updateSellerInfoApiAsync(paramsData))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          token: auth?.payload?.token,
          sellerId: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
        };
        dispatch(getAddressApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            toast.success("Service availabilty updated successfully");
            // setUserSettingData(obj?.payload)
            setButtonLoading(false);
          })
          .catch((obj) => {
            setButtonLoading(false);
          });
      })
      .catch((obj) => {
        setButtonLoading(false);
      });
  };

  const updateUserSettingshandle = (data) => {
    let paramData = {
      tokenData: auth?.payload?.token,
      postData: data,
    };
    data?.default_online_order_status ? setLoading(true) : void 0;
    dispatch(updateUserSettingAsync(paramData))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            seller_id: auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid,
            app_name: "pos",
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getUserSettingsApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setUserSettingData(obj?.payload);
            setLoading(false);
          })
          .catch((obj) => {
            setLoading(false);
          });
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getUserSettingHandle = () => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        app_name: "pos",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserSettingsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setUserSettingData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const productAvailabilityData = () => {
    let params = {
      token: auth?.payload?.token,
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
    };
    setLoading(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setServiceAvailability(obj?.payload ? obj?.payload[0] : []);
        if (
          obj?.payload[0]?.service_availability?.availability?.find(
            (day) => day.day == 1
          )
        ) {
          let startTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 1
            )?.opening_time;
          let endTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 1
            )?.closing_time;
          setStartMondayTime(convertTo24HourFormat(startTime));
          setEndMondayTime(convertTo24HourFormat(endTime));
          if (startTime != "") {
            businessHours[0] = "monday";
          }
        }
        if (
          obj?.payload[0]?.service_availability?.availability?.find(
            (day) => day.day == 2
          )
        ) {
          let startTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 2
            )?.opening_time;
          let endTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 2
            )?.closing_time;
          setStartTuesdayTime(convertTo24HourFormat(startTime));
          setEndTuesdayTime(convertTo24HourFormat(endTime));
          if (startTime != "") {
            businessHours[1] = "tuesday";
          }
        }
        if (
          obj?.payload[0]?.service_availability?.availability?.find(
            (day) => day.day == 3
          )
        ) {
          let startTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 3
            )?.opening_time;
          let endTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 3
            )?.closing_time;
          setStartWednesdayTime(convertTo24HourFormat(startTime));
          setEndWednesdayTime(convertTo24HourFormat(endTime));
          if (startTime != "") {
            businessHours[2] = "wednesday";
          }
        }
        if (
          obj?.payload[0]?.service_availability?.availability?.find(
            (day) => day.day == 4
          )
        ) {
          let startTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 4
            )?.opening_time;
          let endTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 4
            )?.closing_time;
          setStartThursdayTime(convertTo24HourFormat(startTime));
          setEndThursdayTime(convertTo24HourFormat(endTime));
          if (startTime != "") {
            businessHours[3] = "thursday";
          }
        }
        if (
          obj?.payload[0]?.service_availability?.availability?.find(
            (day) => day.day == 5
          )
        ) {
          let startTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 5
            )?.opening_time;
          let endTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 5
            )?.closing_time;
          setStartFridayTime(convertTo24HourFormat(startTime));
          setEndFridayTime(convertTo24HourFormat(endTime));
          if (startTime != "") {
            businessHours[4] = "friday";
          }
        }
        if (
          obj?.payload[0]?.service_availability?.availability?.find(
            (day) => day.day == 6
          )
        ) {
          let startTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 6
            )?.opening_time;
          let endTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 6
            )?.closing_time;
          setStartSaturdayTime(convertTo24HourFormat(startTime));
          setEndSaturdayTime(convertTo24HourFormat(endTime));
          if (startTime != "") {
            businessHours[5] = "saturday";
          }
        }
        if (
          obj?.payload[0]?.service_availability?.availability?.find(
            (day) => day.day == 7
          )
        ) {
          let startTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 7
            )?.opening_time;
          let endTime =
            obj?.payload[0]?.service_availability?.availability?.find(
              (day) => day.day == 7
            )?.closing_time;
          setStartSundayTime(convertTo24HourFormat(startTime));
          setEndSundayTime(convertTo24HourFormat(endTime));
          if (startTime != "") {
            businessHours[6] = "sunday";
          }
        }
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserSettingHandle();
    productAvailabilityData();
  }, []);

  return (
    <>
      <DashboardNavbar title={t('serviceSettings')} backShow={false} />
      <div className="serviceSettingWrapper">
        <h3 className="fontSize18 txtBlue">{t('general')}</h3>
        <p className="homeHeader_Subheading mb-0">
          {t("generalSettingText")}
        </p>
        <div className="commonPadding">
          <div className="row">
            <div className="col-md-6">
              <div className="w-100 commonSelectParent reactSelectParent">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t('defaultTimeSlot')}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <SelectMenu
                  // options={Array.from({ length: 100 }, (_, index) => ({
                  //   value: index + 1,
                  //   label: index + 1,
                  // }))}
                  options={[
                    // { value: 0, label: "0" },
                    { value: 15, label: "15" },
                    { value: 30, label: "30" },
                    { value: 45, label: "45" },
                  ]}
                  placeholder="Select"
                  value={
                    userSettingData?.default_time_slot_step !== null
                      ? {
                          value: userSettingData.default_time_slot_step,
                          label: userSettingData.default_time_slot_step,
                        }
                      : null
                  }
                  onChange={(e) => handleDefaultTimeSlots(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="w-100 commonSelectParent reactSelectParent">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t("minTimeRequired")}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <SelectMenu
                  // options={Array.from({ length: 100 }, (_, index) => ({
                  //   value: index,
                  //   label: index,
                  // }))}
                  options={Array.from({ length: 12 }, (_, index) => ({
                    value: (index + 1) * 5, // Generates 5, 10, 15, ..., 100
                    label: (index + 1) * 5,
                  }))}
                  placeholder="Select"
                  value={
                    userSettingData?.min_time_required_before_booking !== null
                      ? {
                          value:
                            userSettingData.min_time_required_before_booking,
                          label:
                            userSettingData.min_time_required_before_booking,
                        }
                      : null
                  }
                  onChange={(e) => handleMinimumBooking(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="w-100 commonSelectParent mt-4 reactSelectParent">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t("dailyAppointmentLimit")}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <SelectMenu
                  // options={[
                  //   { value: "1", label: "Automatic" },
                  //   { value: "0", label: "Manual" },
                  // ]}

                  options={Array.from({ length: 11 }, (_, index) => ({
                    value: (index+1 ) * 10, // Generates 5, 10, 15, ..., 100
                    label: (index+1 ) * 10,
                  }))}

                  placeholder="Select Status"
                  // value={
                  //   userSettingData?.default_appointment_status !== undefined
                  //     ? {
                  //         value: String(
                  //           userSettingData.default_appointment_status
                  //         ),
                  //         label:
                  //           String(
                  //             userSettingData.default_appointment_status
                  //           ) === "1"
                  //             ? "Automatic"
                  //             : "Manual",
                  //       }
                  //     : null
                  // }
                  value={
                    userSettingData?.daily_booking_limit !== null
                      ? {
                          value:
                            userSettingData.daily_booking_limit,
                          label:
                            userSettingData.daily_booking_limit,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleDefaultOnlineOrder(selectedOption)
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="w-100 commonSelectParent mt-4 reactSelectParent">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t("minTimeRequiredCancell")}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <SelectMenu
                  // options={Array.from({ length: 100 }, (_, index) => ({
                  //   value: index,
                  //   label: index,
                  // }))}
                  options={Array.from({ length: 12 }, (_, index) => ({
                    value: (index + 1) * 5, // Generates 5, 10, 15, ..., 100
                    label: (index + 1) * 5,
                  }))}
                  placeholder="Select"
                  value={
                    userSettingData?.min_time_required_before_cancelling !==
                    null
                      ? {
                          value:
                            userSettingData.min_time_required_before_cancelling,
                          label:
                            userSettingData.min_time_required_before_cancelling,
                        }
                      : null
                  }
                  onChange={(e) => handleMinimumCancelling(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="w-100 commonSelectParent mt-4 reactSelectParent">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t("periodAvailable")}{" "}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <SelectMenu
                  options={Array.from({ length: 100 }, (_, index) => ({
                    value: index+1,
                    label: index+1,
                  }))}
                  placeholder="Select"
                  value={
                    userSettingData?.advance_period_before_booking !== null
                      ? {
                          value: userSettingData.advance_period_before_booking,
                          label: userSettingData.advance_period_before_booking,
                        }
                      : null
                  }
                  onChange={(e) => handlePeriodBeforBooking(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="w-100 commonSelectParent mt-4 reactSelectParent">
                <label className="fontSize16 mb-2 ps-4 txtDarkblue">
                  {t("minTimeReschedule")}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <SelectMenu
                  // options={Array.from({ length: 100 }, (_, index) => ({
                  //   value: index,
                  //   label: index,
                  // }))}
                  options={Array.from({ length: 12 }, (_, index) => ({
                    value: (index + 1) * 5, // Generates 5, 10, 15, ..., 100
                    label: (index + 1) * 5,
                  }))}
                  placeholder="Select"
                  value={
                    userSettingData?.min_time_required_before_rescheduling !==
                    null
                      ? {
                          value:
                            userSettingData.min_time_required_before_rescheduling,
                          label:
                            userSettingData.min_time_required_before_rescheduling,
                        }
                      : null
                  }
                  onChange={(e) => handleMinimumResheduling(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 ${
                toggleState.toggle1 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={userSettingData?.use_service_duration_for_booking}
                onChange={(e) => handleCheckBox(e, "serviceDuration")}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  Use service duration for booking a time slot
                </h3>
                <h3 className="fontSize12 mb-0 txtLightBlue">
                  When a customer books a service, the system will automatically
                  block off the right amount of time on your calendar.
                </h3>
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-4 ${
                toggleState.toggle2 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={userSettingData?.use_service_buffer_in_slots}
                onChange={(e) => handleCheckBox(e, "bufferSlots")}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  {t('bufferTimeSlots')}
                </h3>
                <h3 className="fontSize12 mb-0 txtLightBlue">
                  {t('extraTime')}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-4 ${
                toggleState.toggle3 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={userSettingData?.allow_above_max_capacity_booking}
                onChange={(e) => handleCheckBox(e, "maxCapacity")}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  {t("allowBookingMaxCapacity")}
                </h3>
                <h3 className="fontSize12 mb-0 txtLightBlue">
                  {t("turnAllow")}
                </h3>
              </div>
            </div>
          </div>
          {/* <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-4 ${
                toggleState.toggle4 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={userSettingData?.allow_below_min_capacity_booking}
                onChange={(e) => handleCheckBox(e, "minCapacity")}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  Allow booking below minimum capacity
                </h3>
                <h3 className="fontSize12 mb-0 txtLightBlue">
                  Turn this ON if you're willing to run services even if you
                  don't have a full group.
                </h3>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="mt-4 serviceSettingWrapper">
        <h3 className="fontSize18 txtBlue">{t("advance")}</h3>
        <p className="homeHeader_Subheading mb-0">
          {t("advanceSettingTitle")}
        </p>
        <div className="commonPadding pb-0">
          <div className="row">
            <div className="col-12">
              <div
                className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-3 ${
                  toggleState.toggle6 ? "active" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={userSettingData?.client_cancel_own_booking}
                  onChange={(e) => handleCheckBox(e, "clientCancel")}
                />
                <div>
                  <h3 className="fontSize14 txtDarkblue">
                    {t("client_cancel_own_booking")}
                  </h3>
                  <h3 className="fontSize12 mb-0 txtLightBlue">
                    {t("client_cancel_own_booking_title")}
                  </h3>
                </div>
              </div>
            </div>
            {/* <div className="col-12">
              <div
                className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-3 ${
                  toggleState.toggle7 ? "active" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={userSettingData?.enable_waiting_list}
                  onChange={(e) => handleCheckBox(e, "waitlist")}
                />
                <div>
                  <h3 className="fontSize14 txtDarkblue">
                    Enable waitlist on your online booking site
                  </h3>
                  <h3 className="fontSize12 mb-0 txtLightBlue">
                    Turn this ON for extra time between appointments.
                  </h3>
                </div>
              </div>
            </div> */}
            <div className="col-12">
              <div
                className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-3 ${
                  toggleState.toggle8 ? "active" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={userSettingData?.remove_staff_from_booking}
                  onChange={(e) => handleCheckBox(e, "removeStaff")}
                />
                <div>
                  <h3 className="fontSize14 txtDarkblue">
                    {t("removeStaff")}
                  </h3>
                  <h3 className="fontSize12 mb-0 txtLightBlue">
                  {t("removeStaffTitle")}
                    
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 serviceSettingWrapper">
        <div className="row align-items-center justify-content-space-around">
          <div className="col md-6">
            <h4 className="fontSize18 txtBlue">
              {t("workingDays")}
            </h4>
          </div>
          <div className="col d-flex align-item-end justify-content-end text-end md-6">
            <button
              type="submit"
              className="addNew_"
              onClick={() => handleUpdate()}
              disabled={buttonLoading}
            >
              {buttonLoading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>{t("update")}</span>
            </button>
          </div>
        </div>

        <div className="d-block businessChekbox checkboxGroup">
          {dayList?.map((item, index) => {
            return (
              <div className="checkboxInputs" key={index}>
                <input
                  type="checkbox"
                  id={item.day}
                  className="checkBox"
                  name={item.day}
                  value={item.day}
                  onChange={(e) => handleCheckboxChange1(e)}
                  checked={businessHours?.includes(item.day)}
                />
                <label htmlFor={item.day} className="checkBoxTxt fontSize14">
                  {item.value}
                </label>
                {businessHours?.includes(item.day) && (
                  <>
                    <div style={{ display: "flex" }} className="timerBox_">
                      <div
                        style={{ display: "flex" }}
                        className="innertimedate_"
                      >
                        <p className="fontSize14 me-2 txtDarkblue">
                          {t("startTime")} :
                        </p>
                        <TimePicker
                          onChange={(e) => handleStartTimeChange(e, item.day)}
                          value={
                            item.id == 1
                              ? startMondayTime
                              : item.id == 2
                              ? startTuesddayTime
                              : item.id == 3
                              ? startWednesdayTime
                              : item.id == 4
                              ? startThursdayTime
                              : item.id == 5
                              ? startFridayTime
                              : item.id == 6
                              ? startSaturdayTime
                              : startSundayTime
                          }
                          format="h:mm a"
                          showLeadingZeros={false}
                          amPmAriaLabel="Select AM/PM"
                          className="custom-time-picker customPickerNew"
                        />
                      </div>
                      <div style={{ display: "flex" }} className="timerBox_">
                        <p className="fontSize14 me-2 txtDarkblue">
                          {t("endTime")} :
                        </p>
                        <TimePicker
                          onChange={(e) => handleEndTimeChange(e, item.day)}
                          value={
                            item.id == 1
                              ? endMondayTime
                              : item.id == 2
                              ? endTuesdayTime
                              : item.id == 3
                              ? endWednesdayTime
                              : item.id == 4
                              ? endThursdayTime
                              : item.id == 5
                              ? endFridayTime
                              : item.id == 6
                              ? endSaturdayTime
                              : endSundayTime
                          } // Use the 12-hour formatted time string directly
                          format="h:mm a" // Specify the format as 12-hour with AM/PM
                          className="custom-time-picker customPickerNew"
                        />
                      </div>
                    </div>
                    <Dropdown
                      onClick={() => {
                        handleCopy(item.id);
                      }}
                    >
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        className="bgDarkBtn"
                      >
                        <label className="iconcopy_ mb-0">
                          {" "}
                          <i className="fa-copy fa-solid"></i>{" "}
                        </label>
                      </Dropdown.Toggle>
                      <div className="dayDrop timedatecoprBox_">
                        <Dropdown.Menu>
                          <Form>
                            {options.map((option) => (
                              <Form.Check
                                key={option.id}
                                type="checkbox"
                                label={option.label}
                                id={`option-${option.id}`}
                                checked={selectedOptions.find(
                                  (val) => val?.id == option?.id
                                )}
                                onChange={() =>
                                  handleCheckboxChange2(option, item.id)
                                }
                              />
                            ))}
                          </Form>
                        </Dropdown.Menu>
                      </div>
                    </Dropdown>
                  </>
                )}
              </div>
            );
          })}
        </div>
        {/* <div className="col-12">
          <div
            className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-3 ${
              toggleState.toggle8 ? "active" : ""
            }`}
          >
            <input
              type="checkbox"
              checked={userSettingData?.usa_holidays_as_days_off}
              onChange={(e) => handleCheckBox(e, "usaHolidays")}
            />
            <div className="d-flex align-items-start gap-1">
              <h3 className="fontSize14 txtDarkblue">
                All USA Holidays are include as Days off
              </h3>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ServiceSetting;
