import React from 'react'

const AddFolderModal = (props) => {
    return (
        <>
            <label className="fontSize16 txtDarkblue fontSize14 fw500 mb-2 ps-4">
                Folder Name
            </label>
            <input type="text" className='customInput' placeholder='Enter Folder Name'/>
             <div className="d-flex align-items-center editVariantModal_Footer">
                    <button
                      className="modalDiscardBtn w-100"
                      onClick={() => {
                        props?.close();
                      }}
                      disabled={props?.isDeleting}
                    >
                      Discard
                    </button>
                    <button
                      disabled={props?.isDeleting}
                    
                    className="modalNextBtn modalDiscardBtn active w-100" >
                    
                    Create Folder
                    </button>
                  </div>
        </>
    )
}

export default AddFolderModal