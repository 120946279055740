import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectAccountApiAsync,
  selectLoginAuth,
  selectSignUpAuth,
} from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { bankImage } from "../../../../utilities/images";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddBankDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const signupAuth = useSelector(selectSignUpAuth);
  
  // add bank details
  const handleAddBankBankDetails = (e) => {
    e.preventDefault();
    let params = {
      tokenData: auth?.payload?.token ? auth?.payload?.token :
      auth?.payload?.user?.token
        ? auth?.payload?.user?.token
        : signupAuth?.payload?.token,
      is_user_login: pathname === "/payout-details" ? 1 : 0,
    };
    setLoading(true);
    dispatch(connectAccountApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
          // window.location.href = obj.payload?.url;
          window.open(obj.payload?.url, "_blank")
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  return (
    <div className="protectionRight">
      <div
        className={
          pathname === "/payout-details"
            ? "payout-outer-main"
            : "loginhorrizontal-form"
        }
      >
        <h4 className="subheading_">Connect Bank Account</h4>
        <div
          className={
            pathname === "/payout-details" ? "no-payout-outer" : "no-bank-outer"
          }
        >
          <img src={bankImage} alt="no-bank" />
        </div>
      </div>
      <div
        className={
          pathname === "/payout-details"
            ? "col-md-12 payout-btn-outer"
            : "col-md-12 protectionBtn"
        }
      >
        <button
          onClick={(e) => handleAddBankBankDetails(e)}
          className={
            pathname === "/payout-details"
              ? "payout-btn-inner"
              : "loginBtnSign w-100 buttonActive"
          }
          disabled={loading}
        >
          Add Bank Account
          {loading && (
            <span className="spinner-border spinner-border-sm ms-1"></span>
          )}
        </button>
      </div>
    </div>
  );
};

export default AddBankDetails;
