import React, { useEffect, useState } from 'react'
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Images from "../../../../utilities/images";
import { createPolicyApiAsync, updatePolicyApiAsync } from '../../systemConfig/systemConfigSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { CKEditor } from 'ckeditor4-react';
import SelectMenu from '../../../components/UI/Orders/SelectMenu';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
const CreateUpdateLegal = ({isNew,setmoreSetting,policy}) => {
  const { t } = useTranslation();
  console.log(policy) 
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [content, setContent] = useState(policy?.content||"")
    const dispatch = useDispatch();
    // const [isActive, setIsActive] = useState(true)
    const [policyDetails, setPolicyDetails] = useState({
      content:'',
      title:'',
      flag:'',
    })
    const auth = useSelector(selectLoginAuth);
    const createPolciy=(params)=>{
        dispatch(createPolicyApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
        //   props.getpoliciesDataHandle();
        //   props.close();
        setmoreSetting()

          setLoading(false);
      setLoading2(false);
        })
        .catch((onj) => {
          setLoading(false);
       setLoading2(false);
          toast.error("Unable to create policies");
        });
    }
    const updatePolicy=(params)=>{
      console.log(params)
          dispatch(updatePolicyApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
        //   props.getpoliciesDataHandle();
        //   props.close();
         setLoading(false);
        setLoading2(false);
          setmoreSetting()
        })
        .catch((onj) => {
        setLoading(false);
      setLoading2(false);
          toast.error("Unable to update policies");
        });
    }
    const handleSubmit=(isActive)=>{
      if(loading)return;
      if(!policyDetails.flag)return toast.error("Please select legal name");
      console.log(content)
      if(content==""||!content)return toast.error("Please enter content");
      const params={
        postData: {
          ...policy,
          title: policyDetails.flag,
          content: content,
          flag: policyDetails.flag,
          is_active:isActive
        },
        tokenData: auth?.payload?.token,
      }
      if(!isActive){
        setLoading2(true);
      }else{
        setLoading(true);
      }
        if(isNew){
            createPolciy(params)
        }else{
            updatePolicy(params)
        }
    }
    useEffect(()=>{
      setPolicyDetails(prev=>({...prev,...policy}));
      setContent(policy?.content)
    },[policy])
  const legalOptions=[
    {
      value:"privacy_policy",
      label:"Privacy Policy"
    },
    {
      value:"terms_and_conditons",
      label:"Terms & Conditions"
    },
    {
      value:"discount_coupon_policy",
      label:"Discount Coupon Policy"
    },
    {
      value:"return_policy",
      label:"Return Policy"
    },
    {
      value:"shipping_policy",
      label:"Shipping Policy"
    },
    // {
    //   value:"legal_policy",
    //   label:"Legal Policy"
    // },
    {
      value:"cancellation_policy",
      label:"Cancellation Policy"
    }
  ]
  return <>
  <div className='editPolicy_'>
            <div className="legalHead_ d-flex align-items-center justify-content-between">
            <div className='legalleft_'>
                <h3 onClick={() => {
                    if(loading)return;
                    setmoreSetting()
                }} className='appointMain'><img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' /> {
                        isNew ? t("create") : t("update")
                    } </h3>
                </div>
                <div className='legalrightBtn_ d-flex align-items-center'>
                    <button
                    disabled={loading ||loading2}
                    onClick={()=>{
                      
                      setmoreSetting()
                    }}
                    className='redborder_btn me-2' type='submit'><img className='ordersetting me-2' src={Images.redcross_} alt='cross' /> {t("discard")}</button>
                    {
                      (policy?.is_active)&&<button
                      disabled={loading ||loading2}
                      onClick={()=>{
                        handleSubmit(false);
                      }}
                      className='greyBtn' type='submit'>
                      {loading2 && <div style={{width:"18px",height:"18px"}} className="spinner-border text-primary" role="status" aria-hidden="true"></div>}
                        {t('draft')}
                      </button>
                    }
                    <button
                    disabled={loading ||loading2}
                    onClick={()=>{
                      handleSubmit(true);
                    }}
                    className='editbtn_' type='submit'>
                    {loading && <div style={{width:"18px",height:"18px"}} className="spinner-border text-primary" role="status" aria-hidden="true"></div>}
                  
                      {t('publish')}
                        <img className="batteryfull_" src={Images.upArrow}
                        />
                    </button>
                {/* <button
                disabled={loading}
                onClick={handleSubmit}
                className="editbtn_">
                    {loading && <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>}
                    {loading&& (
            <span className="spinner-border spinner-border-sm spinnerRed"></span>
          )}

                    {
                    isNew ? "Create" : "Update"
                    }

                </button> */}
                </div>
            </div>
           <div className="row p-2 mt-4">
           <div className="col-md-12 mb-5">
              <div className="form_group">
                <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                  {t("legalName")}
                </label>
                <div className=" reactSelectParent commonSelectParent inputGroup">
                 
                <ReactSelect
                    options={legalOptions}
                    placeholder={"Legal Name"}
                    onChange={(event) => {
                      // handleFilterOptions(event.value, filter.title);
                      setPolicyDetails(prev=>({...prev,flag:event.value}));
                    }}
                    value={legalOptions.filter(option=>option.value==policyDetails.flag)}
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            maxWidth: "100% !important",
                            width: "100%",
                        }),
                        menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            paddingLeft: "unset !important",
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                                ? "#263682"
                                : state.isFocused
                                    ? "#f5f6fc"
                                    : "#fff",

                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }),
                    }}
                    components={{
                        DropdownIndicator: () => (
                            <img
                                src={Images.selectImg}
                                width={24}
                                height={24}
                                alt="drop_icon"
                                className="mt-1"
                            />
                        ),
                    }}
                  />
                  {/* <input
                    className="customInput businNameInput"
                    placeholder="legal"
                    type="text"
                    value={policyDetails.flag}
                    onChange={(event)=>{
                      setPolicyDetails(prev=>({...prev,flag:event.target.value}));
                    }}
                  /> */}
                </div>
              </div>
            </div>
            {/* End ProductCategory Area */}
        {/* Start CK EditorArea */}
        <div>
          <label className="fontSize16 txtDarkblue ps-4 ">{t('description')}</label>
          <div className="productDetailsSpc">
            <CKEditor
              initData={content}
              config={{
                versionCheck: false,
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                  "image",
                ],
              }}
              name="editor"
              readOnly={false}
              onChange={(event, editor) => {
                const data = event.editor.getData();
                setContent(data
              );
              }}
              type="classic"
            />
            {/* <button className="ckEditorBtn">
              <img src={Images.ckBtnLogo} alt="logo" /> Generate AI Text{" "}
            </button> */}
          </div>
        </div>
        {/* End CK EditorArea */}
           </div>
    </div>
  </>
}

export default CreateUpdateLegal