import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createCoupanApi, deleteCampaignApi, deleteCouponApi, getCampaignDataApi, getCoupnsDataApi, updateCampaignApi } from "./marketingApi";

export const getCoupnsDataAsync = createAsyncThunk("marketing/getCoupnsDataApi", async (data) => {
    console.log(data)
    const response = await getCoupnsDataApi(data.postData, data.tokenData)
    return response.data
});
  
export const getCampaignDataApiAsync = createAsyncThunk("marketing/getCampaignDataApi", async (data) => {
    console.log(data)
    const response = await getCampaignDataApi(data.postData, data.tokenData)
    return response.data
});

export const updateCampaignApiAsync = createAsyncThunk("marketing/updateCampaignApi", async (data) => {
    console.log(data)
    const response = await updateCampaignApi(data.postData, data.tokenData)
    return response.data
});
export const deleteCampaignApiAsync = createAsyncThunk("marketing/deleteCampaignApi", async (data) => {
    const response = await deleteCampaignApi(data.postData, data.tokenData)
    return {...response.data,id:response?.id}
});


export const createCoupanDataAsync = createAsyncThunk("marketing/createCoupanApi", async(data) => {
    const response = await createCoupanApi(data.postData, data.tokenData)
    return response.data
});

export const deleteCouponAsync = createAsyncThunk("marketing/deleteCouponApi", async (data) => {
    const response = await deleteCouponApi(data.postData, data.tokenData);
    return response.data
})
const initialState={
    loading: false,
    couponData: null,
    campaignData:null,
}
const marketingSlice = createSlice({
    name: "marketing",
    initialState: initialState,
    reducers: {
        MarketingEmpty: (state) => {
          state.campaignData=null;
          state.campaignData=null;
        },
      },
    extraReducers: (builder) => {
        builder
        .addCase(getCoupnsDataAsync.pending, (state) => {
            state.loading = true
        })
        .addCase(getCoupnsDataAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.couponData = payload?.payload;
        })
        .addCase(getCampaignDataApiAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.campaignData = payload?.payload;
        })
        .addCase(deleteCampaignApiAsync.fulfilled, (state, { payload }) => {
            let id=payload?.id;
            if(id){
                state.campaignData = state.couponData?.filter(campaign=>campaign?.id!=id);
            }
        })
        .addCase(getCoupnsDataAsync.rejected, (state) => {
            state.loading = false
        })
        .addCase(createCoupanDataAsync.pending, (state) => {
            state.loading = true
        })
        .addCase(createCoupanDataAsync.fulfilled, (state) => {
            state.loading = false
        })
        .addCase(createCoupanDataAsync.rejected, (state) => {
            state.loading = false
        })
    }
})

export const {MarketingEmpty}=marketingSlice.actions;
export const selectMarketingData = (state) => state.marketing;

export default marketingSlice.reducer