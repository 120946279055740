import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    getServicesAsync,
} from "../../dashboard/dashboardSlice";
import PaginationHeader from "../../../components/PaginationHeader";
import CustomModal from "../../bookings/BookingCalendar/CustomModal";
import AgeVerificationModal from "./AgeVerificationModal";
import {  getAllUserProfileAsync } from "../../systemConfig/systemConfigSlice";
import { useTranslation } from "react-i18next";

const AgeVerification = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [key, setKey] = useState(Math.random());
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [allAddress, setAllAddress] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const [consumerData, setConsumerData] = useState([]);
    const [total_page, setTotalPage] = useState(1)
    const [userData, setUserData] = useState("")

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            flag: flag,
            userData: userData,
        });
        setKey(Math.random());
    }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const getAllusers = () => {
        const data = {
            tokenData: auth?.payload?.token,
            postData: {
                limit: perPage,
                page: currentPage,
                need_to_age_verification: true
            }
        }
        dispatch(getAllUserProfileAsync(data))
            .then(unwrapResult)
            .then(res => {
                setConsumerData(res?.payload?.users)
                setTotalPage(res?.payload?.users?.total_pages)
                setTotalRows(res?.payload?.users?.total);
                setLoading(false);
            }).catch(e => {
                console.log(e)
            })
    }

    const getAllAddress = () => {
        const params = {
            sellerId: auth?.payload?.uniqe_id
                ? auth?.payload?.uniqe_id
                : auth?.payload?.user?.unique_uuid,
            token: auth?.payload?.token,
        };
        dispatch(getAddressApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAllAddress(obj?.payload);
            })
            .catch((obj) => { });
    };

    const getServices = () => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                // type: "product",
                check_product_existance: false,
            },
        };
        setLoading(true);
        dispatch(getServicesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false);
                let filterResult = obj?.payload?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: item?.name,
                    };
                });
                setServices(filterResult);
            })
            .catch((obj) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getServices();
        getAllAddress();

    }, []);

    useEffect(() => {
        getAllusers()

    }, [modalDetail?.show, perPage, currentPage]);
    
    return (
        <>
            <div className="walletStats h-100">
                <div className="productOfferHead_Container">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="fontSize24 txtDarkblue fw500 mb-0">
                            {t("ageVerification")} <span className="countLight_">
                            </span>
                        </h3>
                        <div className="d-flex align-items-center gap-4">
                            <img src={Images.NotificationIcon} alt="NotificationIcon" />

                            <div className="input-group search_group">
                                <img
                                    src={Images.SearchIcon}
                                    alt="img"
                                    className="img-fluid searchIcon"
                                />
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-3">
                        <div className=" d-flex flex-row-reverse">
                            <PaginationHeader
                                currentPage={currentPage}
                                totalPage={consumerData?.total_pages}
                                perPage={perPage}
                                setCurrentPage={setCurrentPage}
                                setPerPage={setPerPage}
                                headerClass="col-md-8"
                                dataLength={consumerData?.users?.length}
                            />
                        </div>

                    </div>
                </div>

                <div className="commonTable consumerTable table-responsive mt-3">
                    <table className="w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th className="textLeft">{t("name")}</th>
                                <th>{t("registerPhone")}</th>
                                <th>{t("location")}</th>
                                <th>{t("status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                consumerData?.users?.length > 0 &&
                                consumerData?.users?.map((val, idx) => {
                                    const { user_profiles } = val
                                    const fullname =
                                        [user_profiles?.firstname, user_profiles?.lastname]
                                            .filter(Boolean).join(" ");
                                    const fullAddress = [
                                        user_profiles?.current_address?.city,
                                        user_profiles?.current_address?.state,
                                        user_profiles?.current_address?.country,
                                        user_profiles?.current_address?.zipcode
                                    ].filter(Boolean).join(" ,");
                                    return (
                                        <tr key={idx}>
                                            <td className="fontSize12 txtLightBlue">
                                                {currentPage > 1 ? (
                                                    (currentPage - 1) * totalRows + idx + 1
                                                ) : idx + 1}
                                            </td>
                                            <td>
                                                <div className="productAvailabilityChild align-items-center">
                                                    <div className="d-flex align-items-center gap-3 ">
                                                        <img
                                                            src={user_profiles?.profile_photo || Images.profileImg1}
                                                            alt="img"
                                                            className="userInnerImg"
                                                        />
                                                        <div>
                                                            <h3 className="aboutProduct fw500 mb-1 text-start">
                                                                {fullname}
                                                            </h3>

                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="aboutProduct fw500">{user_profiles?.full_phone_number}</td>
                                            <td className="aboutProduct fw500">
                                                <div className="flexBox gap-2">
                                                    <img
                                                        src={Images.locateOrder}
                                                        alt="img"
                                                        className="location"
                                                    />
                                                    <h6 className=" fontSize12 fw500 mb-0 locationText">
                                                        {fullAddress}
                                                    </h6>
                                                </div>
                                            </td>
                                            <td
                                                className="aboutProduct fw500"
                                                onClick={() => {
                                                    handleOpenModal("editConsumer")
                                                    setUserData(val)
                                                }}>
                                                <span
                                                    style={{
                                                        background: "#263682",
                                                        color: "#ffff",
                                                        padding: "8px 15px 8px 15px",
                                                        borderRadius: "30px",
                                                        cursor: "pointer"
                                                    }}>
                                                    {t("verify")}
                                                    <img
                                                        src={Images.webPosArrow}
                                                        alt="img"
                                                        className="cursorPointer"
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })}

                        </tbody>
                    </table>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "editConsumer" ? "editConsumerModal" : ""}
                size={modalDetail.flag === "editConsumer" ? "md" : "lg"}
                child={
                    modalDetail.flag === "editConsumer" ? (
                        <AgeVerificationModal
                            userData={userData}
                            close={() => handleOnCloseModal()}
                        />
                    ) : (
                        ""
                    )
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    );
};

export default AgeVerification;
