import React, { useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  profileImg1,
} from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import ChartArea from "../dashboard/wallet/ChartArea";
import {
  getRewardUsersListAsync,
  getRewardsAnalyticsAsync,
} from "../dashboard/dashboardSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import MainHeader from "../../components/UI/Orders/MainHeader";
import {
  options1,
} from "../../constants/bookings/overview";
import { useTranslation } from "react-i18next";

const RewardsOverview = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [appointmentGraphData, setAppointmentGraphData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sellerAddress, setsellerAddress] = useState("");
  const [filterValue, setFilterValue] = useState("week");
  const [endDate, setEndDate] = useState(null);
  const auth = useSelector(selectLoginAuth);

  let [dataSets, setDataSets] = useState([]);

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const lineData = {
    labels: appointmentGraphData?.graph?.labels,
    datasets: dataSets,
  };

  const handleDataSet = (datasets) => {
    const colors = ["#32D583", "#F0C01A"];

    let rewardData = datasets?.map((data, index) => ({
      borderWidth: 1,
      label: data?.label,
      data: data?.data,
      borderColor: colors[index],
      backgroundColor: colors[index],
    }));
    return rewardData;
  };
  const appointmentGraphHandle = () => {
    let params = {
      postData: {
        filter_by: filterValue,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getRewardsAnalyticsAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentGraphData(obj?.payload);
        setDataSets(handleDataSet(obj?.payload?.graph?.datasets));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleAppointmentData = () => {
    let params = {
      postData: {
        limit: 5,
        page: 1,
        filter_by: filterValue,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getRewardUsersListAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentData(obj?.payload?.data || []);
        setTotalAmount(obj?.payload?.total_points || 0);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    appointmentGraphHandle();
    handleAppointmentData();
  }, [filterValue, endDate, sellerAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Rewards";
  }, []);

  const handleFilterValue = (value) => {
    setFilterValue(value);
  };

  const floor = (amount) => {
    return Math.floor(amount).toLocaleString("en-US");
  };

  return (
    <>
      <DashboardNavbar title={t("rewards")} show={false} />
      <div
        style={{
          padding: "20px 0",
          background: "white",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        <MainHeader
          handleFilter={handleFilterValue}
          filterValue={filterValue}
          enableFutureDate={true}
          subhead={t("reward")}
          head={t("rewards")}
        />
      </div>
      <div className=" bookingsOverview_">
        {loading ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="tradesValue">
              <div className="row">
                <div className="col-md-6">
                  <div className="totalOrders_">
                    <div
                      className="totalReward_Parent"

                    >
                      <h3 className="totalRewardTxt">{t('totalRewards')}</h3>
                      <div className="d-flex align-items-center">
                        <img
                          src={Images.jobbrLogo}
                          alt="logo"
                          className="rewardCoinLogo"
                          style={{ width: "60px", height: "60px" }}
                        />
                        <h3 className="rewardPrice ms-2">
                          {floor(totalAmount)}
                        </h3>
                      </div>
                    </div>
                    <div
                      className="row align-items-center"
                      style={{
                        backgroundClip: "#fff",
                        padding: "16px 16px 0",
                        borderRadius: "20px",
                      }}
                    >
                      <div className="col-md-12 reward_card">
                        <ChartArea
                          className="img-fluid"
                          header=""
                          options={options1}
                          data={lineData}
                          chartType="Bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-container-reward  customerSection ms-lg-0 winnerContainer mb-lg-0 me-0">
                    {appointmentData?.length == 0 && (
                      <div className="text-center">
                        <h3 className="totalRewardTxt">
                          {t('rewardPositionNotfound')}
                        </h3>
                      </div>
                    )}
                    <div className="row align-items-end h-100">
                      {appointmentData?.length > 1 && (
                        <div className="col-md-4 h-100 d-flex align-items-end">
                          <div className="winnerParent position-relative">
                            <div className="winnerInner">
                              <img
                                src={
                                  appointmentData?.[1]?.user_details
                                    ?.profile_photo !== null
                                    ? appointmentData?.[1]?.user_details
                                      ?.profile_photo
                                    : Images.userImg_
                                }
                                alt="logo"
                                className="rewardWinner_PersonImg "
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                              <h3 className="positionHead">2nd</h3>
                              <h3 className="winnerName">
                                {appointmentData?.[1]?.user_details?.firstname}{" "}
                                <br />{" "}
                                {appointmentData?.[1]?.user_details?.lastname}
                              </h3>
                            </div>
                            <button className="winnerBtn">
                              <img
                                src={Images.rewardImg}
                                alt="logo"
                                className=""
                              />
                              {floor(
                                appointmentData?.[1]?.total_points,
                                "notSign"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {appointmentData?.length != 0 && (
                        <div className="col-md-4 h-100">
                          <div className="winnerParent position-relative winnerFirstParent ">
                            <div className="winnerInner">
                              <img
                                src={
                                  appointmentData?.[0]?.user_details
                                    ?.profile_photo !== null
                                    ? appointmentData?.[0]?.user_details
                                      ?.profile_photo
                                    : Images.userImg_
                                }
                                alt="logo"
                                className="rewardWinner_PersonImg"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                              <h3 className="positionHead">1st</h3>
                              <h3 className="winnerName">
                                {appointmentData?.[0]?.user_details?.firstname}{" "}
                                <br />{" "}
                                {appointmentData?.[0]?.user_details?.lastname}
                              </h3>
                            </div>
                            <button className="winnerBtn">
                              <img
                                src={Images.rewardImg}
                                alt="logo"
                                className=""
                              />
                              {floor(
                                appointmentData?.[0]?.total_points,
                                "notSign"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {appointmentData?.length > 2 && (
                        <div className="col-md-4 h-100 d-flex align-items-end">
                          <div className="winnerParent position-relative winnerThirdParent">
                            <div className="winnerInner">
                              <img
                                src={
                                  appointmentData?.[2]?.user_details
                                    ?.profile_photo !== null
                                    ? appointmentData?.[2]?.user_details
                                      ?.profile_photo
                                    : Images.userImg_
                                }
                                alt="logo"
                                className="rewardWinner_PersonImg"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                              <h3 className="positionHead">3rd</h3>
                              <h3 className="winnerName">
                                {appointmentData?.[2]?.user_details?.firstname}{" "}
                                <br />{" "}
                                {appointmentData?.[2]?.user_details?.lastname}
                                {/* dev */}
                              </h3>
                            </div>
                            <button className="winnerBtn">
                              <img
                                src={Images.rewardImg}
                                alt="logo"
                                className=""
                              />
                              {floor(
                                appointmentData?.[2]?.total_points,
                                "notSign"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <section className="recentTransections table-responsive chartsOuter recentBookingTableBox h-auto">
        <div className="leftorderValue_">
          <h3 className="fontSize16 fw500 txtDarkblue mb-0">{t('topRewards')}</h3>
          <button
            className="viewAllbtn_ ms-auto fw500"
            type="submit"
            onClick={() => history.push("/all-rewards")}
          >
            {t('viewAll')}
          </button>
        </div>
        <div className="commonTable recentBookingTable rewardsTable  table-responsive mt-3">
          <table className="w-100 allorderTable_">
            <thead>
              <tr>
                <th className="text-start ps-4">#</th>
                <th className="text-start">{t('name')}</th>
                <th>{t('totalSpent')}</th>
                <th>{t('JBRPointsEarned')}</th>
                <th>{t('totalOrders')}</th>
              </tr>
            </thead>
            <tbody>
              {appointmentData?.length > 0 ? (
                appointmentData?.map((appointment, index) => (
                  <tr key={index}>
                    <td className="fontSize12 darkblack text-start ps-4">{index + 1}</td>
                    <td>
                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={
                            appointment?.user_details?.profile_photo ||
                            profileImg1
                          }
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="darkblack mb-0 pb-1 text-left">
                            {appointment?.user_details?.firstname +
                              " " +
                              appointment?.user_details?.lastname}
                          </h3>
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location "
                            />
                            <h6 className=" fontSize12 fw500 mb-0 locationText">
                              {appointment?.user_details?.current_address?.street_address?.slice(
                                0,
                                10
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="fontSize12 darkblack">
                      {addThousandSeparator(appointment?.total_spent?.toFixed(2))}
                    </td>
                    <td className="fontSize12 darkblack">
                      {addThousandSeparator(appointment?.total_points)}
                    </td>
                    <td className="fontSize12 darkblack fw500">
                      {addThousandSeparator(appointment?.total_orders) || 0}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="fontSize14 text-center">
                    {t('noDataFound')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default RewardsOverview;
