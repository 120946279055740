import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import * as Images from "../../../../../utilities/images";

const UploadImageModal = () => {
        const [file, setFile] = useState(null);
        const [preview, setPreview] = useState("");
        const [fileError, setFileError] = useState("");

        const onDrop = useCallback((acceptedFiles) => {
                console.log(acceptedFiles, "acceptdedd");
                const uploadedFile = acceptedFiles?.[0];
                const validTypes = ["image/jpeg", "image/png"];
                const maxSize = 5 * 1024 * 1024;
        
                if (!uploadedFile) {
                    setFileError("Please upload a file.");
                    toast.error("No file uploaded.");
                    return;
                }
        
                if (!validTypes.some((type) => uploadedFile.type === type)) {
                    setFileError("Invalid file type.");
                    toast.error("Invalid file type. Only JPEG and PNG are allowed.");
                    return;
                }
        
                if (uploadedFile.size > maxSize) {
                    setFileError("File size exceeds the 5MB limit.");
                    toast.error("File size exceeds the 5MB limit.");
                    return;
                }
        
                setFileError("");
                setFile(uploadedFile);
        
                const filePreview = URL.createObjectURL(uploadedFile);
                setPreview(filePreview);
            }, []);
        
            const { getRootProps, getInputProps, open } = useDropzone({
                onDrop,
            });
  return (
    <>
     <div className="showToken_Img mt-0 ">
                                    <div {...getRootProps({ className: "" })}>
                                        <input name="image" type="file" {...getInputProps()} />
                                        <div className="uploadImg_Parent">
                                            {file ? (
                                                <div className="showImg_Parent">
                                                    <img
                                                        src={preview}
                                                        alt="Preview"
                                                        className="detailsImg img-fluid h-100"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={Images.UploadSquareImg}
                                                        alt="Camera"
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                    <p className="staffPositionTxt fontSize12 fw400 ms-2 text-center">
                                                        Click to browse or <br /> drag and drop your files
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {fileError && <p className="error-message">{fileError}</p>}
                                </div>
    </>
  )
}

export default UploadImageModal