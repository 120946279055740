import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { unwrapResult } from '@reduxjs/toolkit';
import { getUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../bookings/BookingCalendar/CustomModal';
import LanguageModal from './LanguageModal';

const Languages = ({setmoreSetting,setLanguage}) => {
  const [first, setfirst] = useState(false);
  const [loading, setLoading] = useState(false)
const [langaugeList, setLangaugeList] = useState([{
  name: 'English',
  flag: Images.usa,
  country: 'United States',
    lang_code: 'en',
    selected: true,
}])
const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  // Start Modal
const [key, setKey] = useState(Math.random());
const [modalDetail, setModalDetail] = useState({
  show: false,
  title: "",
  flag: "",
});

const handleOnCloseModal = () => {
  setModalDetail({
    show: false,
    title: "",
    flag: "",
  });
  setKey(Math.random());
};

const handleOpenModal = (flag) => {
  setModalDetail({
    show: true,
    flag: flag,
    title: flag,
  });

  setKey(Math.random());
};
// End Modal  
const getLanguagesData = () => {
  let data = {
    postData: {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      app_name: "merchant",
    },
    tokenData: auth?.payload?.token,
  };
  setLoading(true);
  dispatch(getUserSettingsApiAsync(data))
    .then(unwrapResult)
    .then((obj) => {
      setLangaugeList(obj?.payload?.languages);
      setLoading(false);
    })
    .catch((obj) => {
      setLoading(false);
    });
};
const handleCheckboxChange = (index, status) => {
  // languagesData[index]["status"] = status;
  // console.log(languagesData, "language data");
  // const newData = languagesData?.map((item) => {
  //   const { lang_code, ...rest } = item;
  //   return rest;
  // });
  // let params = {
  //   tokenData: auth?.payload?.token,
  //   postData: {
  //     app_name: "pos",
  //     languages: newData,
  //   },
  // };
  // setButtonLoading(true);
  // dispatch(updateUserSettingAsync(params))
  //   .then(unwrapResult)
  //   .then((obj) => {
  //     setButtonLoading(false);
  //     if (!toast.isActive(toastId.current)) {
  //       toastId.current = toast.success(obj?.msg);
  //     }
  //     handleOnCloseModal();
  //     getLanguagesData();
  //   })
  //   .catch((obj) => {
  //     setButtonLoading(false);
  //   });
};
useEffect(()=>{
  // getLanguagesData()
},[])
  return <>
    <div className='customerNotiication_ additionalsetmain_ p-0'>
      <div className='mb-5'>
      <h3 onClick={() => {
        setmoreSetting("Personal Details")
      }} className='headblue18 '>
        <img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' /> 
        Languages 
      </h3>
      <p>Active in the markets they have been  added  to and visible to customers</p>
      </div>
      {/* language list */}
      <div className="row gap-2">

      {
        langaugeList?.map((language,index)=>(
          <div key={index} className="col-12">
                                    <div className={`serviceSettingBoxes d-flex align-items-start justify-content-between  gap-3 ${index>=1?'mt-3':''}`}>
                                      <div className='d-flex align-items-center gap-2'>

                                        <img src={Images.language} alt="" />
                                        <div>
                                            <h3 className="fontSize14 txtDarkblue">
                                                {language?.name}
                                            </h3>
                                            <h3 className="fontSize12 txtLightBlue mb-0">
                                                {language?.country}
                                            </h3>
                                        </div>
                                      </div>
                                      <input type="checkbox" checked="true"
                                        onChange={()=>handleCheckboxChange(language,index)}
                                        />
                                    </div>
                                </div>
        ))
      }
      {/* language list */}
      </div>
{/* add  button*/}
<div onClick={()=>{
  handleOpenModal("LanguageModal")
}} className="serviceSettingBoxes d-flex gap-2 mt-3">
  <img src={Images.addIcon} alt="" />
  <h3 className="fontSize14 txtDarkblue">
    Add a new language
  </h3>

</div>
{/* add  button*/}
      {/* custom modal */}
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
           modalDetail.flag === "LanguageModal"
            ? "LanguageModal"
            :""
        }
        child={
           modalDetail.flag === "LanguageModal" ? (
            <LanguageModal
              close={() => handleOnCloseModal()}
              
            />): (
            <></>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
      {/* custom modal */}
    </div>
  </>
}

export default Languages