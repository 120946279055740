import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

const AssignedTeamMemberModal = (props) => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState("");
  const [posList, setPosList] = useState([]);
  console.log(posList, "posList");
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);

  const toggleItem = (data) => {
    const exists = selectedId.find(
      (val) => val?.pos_staff_details_id === data?.id || val?.id === data?.id
    );

    const updatedItems = exists
      ? selectedId.filter((item) => item.id !== data.id)
      : [...selectedId, data];

    setSelectedId(updatedItems);

    // Dynamically update "Select All" state
    if (updatedItems.length === posList.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      // Deselect all
      setSelectedId([]);
      setSelectAll(false);
    } else {
      // Select all
      setSelectedId(posList);
      setSelectAll(true);
    }
  };

  const searchPosUser = () => {
    let params = {
      postData: {
        // seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "b169ed4d-be27-44eb-9a08-74f997bc6a2j"
      },
      tokenData: auth?.payload?.token,
    };
    if (searchData) {
      params = {
        postData: {
          ...params.postData,
          search: searchData,
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchData == "" || searchData.length >= 3) {
      searchPosUser();
    }
  }, [searchData]);

  useEffect(() => {
    setSelectedId(props?.posIds ? [...props?.posIds] : []);
  }, []);

  useEffect(() => {
    if (selectedId.length === posList.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedId, posList.length]);

  return (
    <div>
      <p className="fontSize16 txtDarkblue">
        {t('teamMememberCaption')}{" "}
        <span className="fw700">{t('settings')} {">"} {t('staff')}.</span>
      </p>

      <div className="modalOptionBox deleteVariation_ModalBox">
        <div className="position-relative">
          <input
            type="search"
            className="teamsearch"
            name="teamsearch"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
            s
            placeholder={t("searchTeamMemeber")}
          />
          <img
            src={Images.SearchIcon}
            alt="logo"
            className="teamsearch_Searchicon"
          />
        </div>
        {loading === true ? (
          <>
            <div className="d-flex align-items-center justify-content-center m-3">
              <span className="spinner-border spinner-border-sm spinnerDark"></span>
            </div>
          </>
        ) : posList?.length > 0 ? (
          <>
            <div className="productAvailabilityChild mt-4 allteamBox_Parent align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="selectAll"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
                <label htmlFor="selectAll"></label>
              </div>
              <p className="fontSize16 fw500 txtDarkblue mb-0">
                {" "}
                {t('allTeamMember')}
              </p>
            </div>
            {posList.map((member) => (
              <div className="deleteVariation_innerBox" key={member.id}>
                <div className="productAvailabilityChild align-items-center">
                  <div className="form-group mb-1">
                    <input
                      type="checkbox"
                      id={`store-${member.id}`}
                      checked={selectedId.some((item) => item.id === member.id)}
                      onChange={() => toggleItem(member)}
                    />
                    <label htmlFor={`store-${member.id}`}></label>
                  </div>
                  <div className="assignedTeamModalBox">
                    <img
                      src={
                        member?.user?.user_profiles?.profile_photo
                          ? member?.user?.user_profiles?.profile_photo
                          : Images.profileImg1
                      }
                      alt={member.user.user_profiles?.firstname}
                      className="teamMemberImg rounded-5"
                    />
                    <div>
                      <h3 className="fontSize12 mb-0 pb-1">{`${member.user.user_profiles?.firstname} ${member.user.user_profiles?.lastname}`}</h3>
                      <p className="fontSize12 txtLightBlue mb-0">
                        {member.user.user_roles?.[0]?.role?.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="deleteVariation_innerBox justify-content-center">
            <h4 className="fontSize16 txtDarkblue mt-2">{t('noResultFound')}</h4>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          {t('discard')}
        </button>
        <button
          className="modalNextBtn modalDiscardBtn active w-100"
          onClick={() => {
            props?.getIdsHandle(selectedId);
            props?.handleSelect(selectedId);
          }}
        >
          {t('save')}
        </button>
      </div>
    </div>
  );
};

export default AssignedTeamMemberModal;
