import React, { useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  shareMap,
  profileImg1,
  locationBlue,
  modalCancel,
  lossArrow,
} from "../../../utilities/images";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import ChartArea from "../dashboard/wallet/ChartArea";
import {
  changeAppointmentStatusApiAsync,
  getAppointemntApiAsync,
  getAppointmentCountApiAsync,
  getAppointmentGraphApiAsync,
} from "../dashboard/dashboardSlice";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import CustomModal from "../../components/shared/CustomModal";
import BookingSchedule from "./bookingSchedule";
import CheckinModal from "./checkinModal";
import ConfirmCompleteModal from "./ConfirmCompleteModal";
import HomePageInnerHeader from "../dashboard/HomePageInnerHeader";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import * as Images from "../../../utilities/images";
import MainHeader from "../../components/UI/Orders/MainHeader";
import {
  HeaderStatisticsArr,
  options1,
} from "../../constants/bookings/overview";
import CheckedInModal from "./CheckedInModal";
import AppointmentDetailModal from "./BookingCalendar/Modals/AppointmentDetailModal";
import RequestModal from "./BookingCalendar/Modals/BookingRequestModal";
import { setGetStaffUsers } from "./bookingSlice";
import BookingAppointmentStatus from "./BookingStatusAndModal";
import { useTranslation } from "react-i18next";

const Bookings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [appointmentCountData, setAppointmentCountData] = useState({});
  const [appointmentGraphData, setAppointmentGraphData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sellerAddress, setsellerAddress] = useState("");
  const [filterValue, setFilterValue] = useState("week");
    const [staffUserList, setstaffUserList] = useState([]);
  // time period
  const [checkInDetails, setcheckInDetails] = useState({
    title:"",
    subtitle:""
  })
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [singleOrderData, setSingleOrderData] = useState("");
  const auth = useSelector(selectLoginAuth);
  const [checked, setChecked] = useState({
    appointment: true,
    completed: true,
  });
  let [dataSets, setDataSets] = useState([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [key, setKey] = useState(Math.random());
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());

    if (flag == "checkedIn") {
      setTimeout(() => {
        handleOnCloseModal();
      }, 3000);
    }
  };

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
 const getUserPosDetails = async () => {
    const prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getPosUsersAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setGetStaffUsers(res));
        setstaffUserList(res?.payload?.pos_staff||[]);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getUserPosDetails();
  }, []);
  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };



  

  const lineData = {
    labels: appointmentGraphData?.labels,
    datasets: dataSets,
  };
  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: appointmentGraphData?.datasets
            ? appointmentGraphData?.datasets[num]?.data
            : "",
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
  };
  const changeStatusOfAppointmentHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };
    status === 4 ? setLoading1(true) : setLoading2(true);
    dispatch(changeAppointmentStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        handleAppointmentData();
        handleOnCloseModal();
      })
      .catch((obj) => {
        setLoading1(false);
        setLoading2(false);
      });
  };
  const appointmentCountHandle = () => {
    let params = {
      postData: {
        ...(filterValue&&{filter_by:filterValue,}),
        ...(startDate && { start_date: startDate, end_date: endDate }),
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAppointmentCountApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentCountData(filterCountData(obj));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  
  const filterCountData = (obj) => {
    try {
      let countData = obj?.payload?.map((data, index) => data?.value) || [];
      let keys = ["total_booking", "completed", "rescheduled", "cancelled"];
      let data = {};
      let i = 0;
      countData.map((obj, index) => {
        if (index != 1) {
          data[keys[i++]] = obj;
        }
      });
      return data;
    } catch (error) {
    }
  };

  const handleDataSet = (datasets) => {
    const colors = ["#263682", "#039855"];
    let labels = ["Booking", "Fulfillment"];
    return datasets?.map((data, index) => ({
      fill: true,
      label: labels[index],
      data: data?.data,
      borderColor: colors[index],
      backgroundColor: "#FFFFFF00",
      cubicInterpolationMode: "monotone",
    }));
  };
  const appointmentGraphHandle = () => {
    let params = {
      postData: {
        cancelled_nd_completed: true,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
          ...(filterValue&&{filter_by:filterValue,}),
          ...(startDate && { start_date: startDate, end_date: endDate }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAppointmentGraphApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentGraphData(obj?.payload);
        setDataSets(handleDataSet(obj?.payload?.datasets));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleAppointmentData = () => {
    let params = {
      postData: {
        limit:5,
        page:1,
        seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
        ...(sellerAddress && { seller_address_id: sellerAddress }),
        ...(filterValue&&{filter_by:filterValue,}),
        ...(startDate && { start_date: startDate, end_date: endDate }),
       
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAppointemntApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentData(obj?.payload?.data);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    appointmentCountHandle();
    appointmentGraphHandle();
    handleAppointmentData();
  }, [filterValue, endDate, sellerAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Bookings";
  }, []);

  const handleFilterValue = (value) => {
    setFilterValue(value);
  };
  const handleDateValue = (values) => {
    if(values.startDate==values.endDate){
      setStartDate(null);
      setEndDate(null);
      setFilterValue("today");
    }else{
      setStartDate(values.startDate);
      setEndDate(values.endDate);
      setFilterValue(null);
    }
  };
  const handleSellerAddress = (value) => {
    setsellerAddress(value);
  };
  const options=(status,appointment,isOpen=true)=>{
    switch(status){
      case 0:return <AcceptOrReject isOpen={isOpen} appointment={appointment}/>;
      case 1 :return <AccpetedBySeller isOpen={isOpen} appointment={appointment}/>;
      case 2 :return <MarkAsDone isOpen={isOpen} appointment={appointment}/>;
      case 3:return <Completed isOpen={isOpen} appointment={appointment}/>;
      case 4: return <RejectBySeller/>;
      case 5: return <CancelledByCusomter/>;
    }
  }
  const Completed=({appointment,isOpen})=>{
    return <>
    <button
    onClick={()=>{
      if(!isOpen)return;
      handleOpenModal("appointmentDetailModal");
      setSingleOrderData(appointment)
    }}
                        
                        className="checkInBtn tableClockParent"
                      >
                        Completed
                      </button></>
  }
  const MarkAsDone=({appointment,isOpen})=>{
    return <>
    <button
                        onClick={() => {
                          if(!isOpen)return;
                          changeStatusOfAppointmentHandle(appointment.id,3);
                          setcheckInDetails(prev=>({
                            ...prev,
                            title:"Completed"
                          }))
                          setModalDetail({ show: true, flag: "checkedIn" });
                          setKey(Math.random());
                        }}
                        className="checkInBtn tableClockParent"
                      >
                        Mark as Done
                      </button>
    </>
  }
  const AcceptOrReject=({appointment,isOpen})=>{
    return <>

                      
                       <div className="flexBox gap-2 justify-content-end">
                            <button
                              className="cancleAppointmentBtn tableDeclineBtn mr-6"
                              type="submit"
                              onClick={async () => {
                                if(!isOpen)return;
                                changeStatusOfAppointmentHandle(appointment.id,4);

                              }}
                            >
                              Decline
                            </button>
                      
                            <button
                              className="commonBlueBtn tableAcceptBtn"
                              type="submit"
                              onClick={async () => {
                                if(!isOpen)return

                                changeStatusOfAppointmentHandle(appointment.id,1);

                              }}
                            >
                              Accept
                            </button>
                          </div>
                      
    </>
  }
  const CancelledByCusomter=()=>{
    return <><button
                        
    className="checkInBtn tableClockParent"
  >
    Cancelled
  </button></>
  }
  const RejectBySeller=()=>{
    return <>
    <button
                        
                        className="checkInBtn tableClockParent"
                      >
                        Rejected
                      </button>
    </>
  }
  const AccpetedBySeller=({appointment,isOpen})=>{
    return <>
                      <div className="flexBox gap-2 justify-content-end">
                            <button
                              className="checkInBtn tableClockParent"
                              onClick={() => {
                                handleOpenModal("checkInModal");
      setSingleOrderData(appointment)
                              }}
                            >
                              Check-in
                              <img src={Images.checkInImg} alt="img" />
                            </button>
                      
                           
                          </div>
    </>
  }
  return (
    <>
      <DashboardNavbar title={t('bookingOverview')} show={false} />
      <div className="walletStats bookingsOverview_">
        <MainHeader
          handleFilter={handleFilterValue}
          filterValue={filterValue}
          handleDate={handleDateValue}
          seller_id={auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid}
          tokenData={auth?.payload?.token}
          enableFutureDate={true}
          handleLocation={handleSellerAddress}
          subhead="vs. Same day last week"
        />
        {loading ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="verificationTotal">
              <div className="row mt-3">
                {HeaderStatisticsArr?.map((headerStatic, index) => (
                  <HeaderStatistics
                    className={headerStatic?.className}
                    img={Images?.[headerStatic?.img]}
                    value={appointmentCountData?.[headerStatic?.key]}
                    heading={t(headerStatic?.languageText)}
                  />
                ))}
              </div>
            </div>
            {/*  */}

            <div className="tradesValue">
              <div className="row">
                <div className="col-md-12">
                  <div className="totalOrders_">
                    <div className="chartHeader_ chartHeaderBox">
                      <div className="leftorderValue_">
                        <h3 className="textInnerHead mb-0">{t('bookings')}</h3>
                        <h3 className="Innerhead">{

                        }</h3>
                        <button
                          className="viewAllbtn_"
                          type="submit"
                          onClick={() => history.push("/all-bookings")}
                        >
                          {t('viewAll')}
                        </button>
                      </div>
                      <div className="graphChecked_">
                        <div className="graphBox_ me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll2"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            onChange={(e) => {
                              handelDataSetChange(e, "Booking", 0, "#263682");
                              setChecked({
                                ...checked,
                                appointment:
                                  checked.appointment === true ? false : true,
                              });
                            }}
                            checked={checked.appointment}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll2"
                            className="checkBoxTxt masterChecked"
                          >
                            {t('bookings')}
                          </label>
                        </div>
                        <div className="checkGreen me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll4"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            checked={checked.completed}
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "Fulfillment",
                                1,
                                "#039855"
                              );
                              setChecked({
                                ...checked,
                                completed:
                                  checked.completed === true ? false : true,
                              });
                            }}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll4"
                            className="checkBoxTxt masterChecked"
                          >
                            {t('fullfilment')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <ChartArea
                          className="img-fluid"
                          header=""
                          options={options1}
                          data={lineData}
                          chartType="Line"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <section className="recentTransections table-responsive chartsOuter recentBookingTableBox h-auto">
        <div className="leftorderValue_">
          <h3 className="fontSize16 fw500 txtDarkblue mb-0">{t('recentBooking')}</h3>
          <button
            className="viewAllbtn_"
            type="submit"
            onClick={() => history.push("/all-bookings")}
          >
           {t("viewAll")}
          </button>
        </div>
        <div className="commonTable recentBookingTable  table-responsive mt-3">
          <table className="w-100 allorderTable_">
            <thead>
              <tr>
                <th className="text-left">{t('client')}</th>
                <th>{t('staff')}</th>
                <th>{t('services')}</th>
                <th>{t('time')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {appointmentData?.length? appointmentData?.map((appointment, index) => {
                let userDetails=appointment?.user_details||appointment?.invitation_details;
                return  <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={
                          userDetails?.profile_photo ||
                          profileImg1
                        }
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="darkblack mb-0 pb-1 text-left">
                          {(userDetails?.firstname||"-") +
                            " " +
                           ( userDetails?.lastname||"-")}
                        </h3>
                       {userDetails?.current_address && <div className="d-flex align-items-center gap-2">
                          <h6 className="orederLoc_ fontSize14 fw500 mb-0">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            {userDetails?.current_address?.street_address?.slice(
                              0,
                              10
                            )}
                          </h6>
                        </div>}
                      </div>
                    </div>
                  </td>
                  <td className="fontSize12 darkblack">
                    {appointment?.pos_user_details?.user?.user_profiles
                      ?.firstname +
                      " " +
                      appointment?.pos_user_details?.user?.user_profiles
                        ?.lastname}
                  </td>
                  <td className="fontSize12 darkblack">
                    {appointment?.product_name}
                  </td>
                  <td className="fontSize12 darkblack">
                    <div className="tableClockParent">
                      <img src={Images.clockIcon} alt="img" />
                      <h3 className="timeTxt">
                        {" "}
                        {appointment?.start_time}- {appointment?.end_time}
                      </h3>
                    </div>
                  </td>
                  <td>
                    <div className="tableClockParent justify-content-end gap-3">
                      {/* { */}
                        <BookingAppointmentStatus
                        appointment={appointment}
                        status={appointment?.status}
                        handleOpenModal={(value)=>handleOpenModal(value)}
                        handlerAppointmentStatus={(id,status)=>{
                          changeStatusOfAppointmentHandle(id,status)
                        }}
                        setSingleOrderData={(value)=>setSingleOrderData(value)}

                        />
                    </div>
                  </td>
                        </tr>
              }):
              <tr>
                <td className="fontSize14 txtLightBlue text-center" colSpan={11}>
                  {t('noRecordfound')}
                </td>
              </tr>
              
              }
            </tbody>
          </table>

        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "rescheduleModal"
            ? "rescheduleModal"
            : modalDetail.flag === "confirmCompleteModal"
            ? "ConfirmCompleteModal"
            : modalDetail.flag === "checkInModal"
            ? "checkInModal"
            : modalDetail.flag === "checkedIn"
            ? "successfullyCheckedIn"
            : modalDetail.flag === "appointmentDetailModal"
            ? "appointmentDetailModal"
            : modalDetail.flag === "bookingRequestModal"
            ? "bookingRequestModal"
            : ""
        }
        className={
          modalDetail.flag === "rescheduleModal"
            ? "commonWidth customContent"
            : ""
        }
        child={
          modalDetail.flag === "rescheduleModal" ? (
            <BookingSchedule
              handleAllAppointmentData={() => handleAppointmentData()}
              changeStatusOfAppointment={(id, status) =>
                changeStatusOfAppointmentHandle(id, status)
              }
              data={singleOrderData}
              staff={staffUserList}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "checkInModal" ? (
            <CheckinModal
              changeStatusOfAppointment={(id, status) =>
                changeStatusOfAppointmentHandle(id, status)
              }
              data={singleOrderData}
              loading2={loading2}
              close={() => handleOnCloseModal()}
              open={() =>{
                setcheckInDetails({
                  title:"Checked In",
                  subtitle:"Successfully confirmed"
                })
                handleOpenModal("checkedIn")}}
            />
          ) : modalDetail.flag === "checkedIn" ? (
            <CheckedInModal
            title={checkInDetails.title}
            subtitle={checkInDetails.subtitle}
            close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "confirmCompleteModal" ? (
            <ConfirmCompleteModal
              changeStatusOfAppointment={(id, status) =>
                changeStatusOfAppointmentHandle(id, status)
              }
              data={singleOrderData}
              loading2={loading2}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "appointmentDetailModal" ? (
            <AppointmentDetailModal
            data={singleOrderData}
            // options={options}
            onAppointmentStatusUpdate={(id, status) =>
              changeStatusOfAppointmentHandle(id, status)}
              reschedule={(value)=>{
                setSingleOrderData(value);
                  handleOpenModal("rescheduleModal");
              }}
            close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "bookingRequestModal" ? (
            <RequestModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "rescheduleModal" ? (
            <>
              <div className="flexBox gap-3">
                <img src={Images.customCalendar} alt="img" />
                <h4 className="fontSize18 txtDarkblue mb-0">
                  {t('editAppt')} #
                  <span className="fw500">{` ${singleOrderData?.id}`}</span>
                </h4>
              </div>
              <img
                src={Images.modalCross}
                className="ModalCancel cursorPointer"
                onClick={handleOnCloseModal}
              />
            </>
          ) : modalDetail.flag === "appointmentDetailModal" ? (
            <>
              <div className="flexBox gap-3">
                <img src={Images.customCalendar} alt="img" />
                <h4 className="fontSize24 txtDarkblue mb-0">
                  {t('appointMentDetail')}
                  <span className="fw500">{` # ${singleOrderData?.id}`}</span>
                </h4>
              </div>
              <img
                src={Images.modalCross}
                className="ModalCancel cursorPointer"
                onClick={handleOnCloseModal}
              />
            </>
          ) :modalDetail.flag === "checkedIn"? (
            <>
            <div className="flexBox gap-3">
              <img src={Images.customCalendar} alt="img" />
              <h4 className="fontSize18 txtDarkblue mb-0">
                {t('updateStatus')}
                <span className="fw500">{` # ${singleOrderData?.id}`}</span>
              </h4>
            </div>
            <img
              src={Images.modalCross}
              className="ModalCancel cursorPointer"
              onClick={handleOnCloseModal}
            />
          </>
          ):("")
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Bookings;
