import React, { forwardRef, useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import moment from 'moment-timezone';
import ReactSelect from "react-select";
import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { getSellerAddressesApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';


const UsersHeader = ({ startDate, setSellerAddress, setStartDate, headerClass }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const [location, setLocation] = useState([
        { value: "", label: "All" }
    ])

    const getSellerAddressDetails = async () => {
        const prams = {
            postData: {
                seller_id: auth?.payload?.uniqe_id,
            },
            tokenData: auth?.payload?.token,
        };
        dispatch(getSellerAddressesApiAsync(prams))
            .then(unwrapResult)
            .then((res) => {
                if (res?.payload) {
                    const adddres = res?.payload?.map(i => (
                        { value: i?.id, label: i?.format_address }
                    ))
                    setLocation((prev) => ([...prev, ...adddres]))
                }
            })
            .catch((err) => { });
    };

    const ExampleCustomInput = forwardRef(
        ({ value, onClick, className }, ref) => (
            <button className={className} onClick={onClick} ref={ref}>
                <div className="d-flex align-items-center gap-1" style={{
                    minWidth: "125px"
                }}>
                    <img
                        src={Images.lightCalenderLogo}
                        alt="img"
                        width={24}
                        height={24}
                    />
                    {value || moment().format("DD MMM, YYYY")}
                </div>
                <img
                    src={Images.blueDownArrow}
                    width={24}
                    height={24}
                    alt="drop_icon"
                />
            </button>
        )
    );

    useEffect(() => {
        if (setSellerAddress) {
            getSellerAddressDetails()
        }
    }, []);

    return (
        <div className={headerClass || "col-xxl-4 col-xl-6"}>
            <div className="row">
                <div className="col-6">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        customInput={
                            <ExampleCustomInput className="datePickerInput commonfildate" />
                        }
                    />
                </div>
                <div className="col-6">
                    <div className="reactSelectParent offerHeaderSelect consumerHeaderSelect">
                        <ReactSelect
                            options={location}
                            placeholder={t("locationAll")}
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            onChange={(selected) => setSellerAddress(selected?.value)}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minWidth: "120px",
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    border: "1px solid #D7DEFF",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? "#263682"
                                        : state.isFocused
                                            ? "#f5f6fc"
                                            : "#fff",
                                    color: state.isSelected ? "#fff" : "#636E9F",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: "#636E9F",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }),
                            }}
                            components={{
                                DropdownIndicator: () => (
                                    <img
                                        src={Images.blueDownArrow}
                                        width={24}
                                        height={24}
                                        alt="drop_icon"
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersHeader
