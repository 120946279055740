import {REACT_APP_CHAT_URL} from "../../config"
// export const socketUrl="https://staging-apichat.jobr.com:8056";
export const socketUrl=REACT_APP_CHAT_URL;
export const socketPath="/api/v1/connect";
export const chats = [
    {
      sender_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
      recipient_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
      media_type: "text",
      content: "hlo",
      media_url: "",
      shipping_address: "",
      business_card: "",
      offer_id: "",
      inquiry_id: "",
      catalogFiles: "",
      chatHeadType: "directchat",
      ticket_id: "",isUser:false
    },
    {
      sender_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
      recipient_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
      media_type: "text",
      content: "Hey there!",
      media_url: "",
      shipping_address: "",
      business_card: "",
      offer_id: "",
      inquiry_id: "",
      catalogFiles: "",
      chatHeadType: "directchat",
      ticket_id: "",isUser:true
    },
    // {
    //   sender_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   recipient_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   media_type: "text",
    //   content: "How’s it going?",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:false
    // },
    // {
    //   sender_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   recipient_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   media_type: "text",
    //   content: "I’m good, just working on a project!",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:true
    // },
    // {
    //   sender_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   recipient_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   media_type: "text",
    //   content: "Nice! What kind of project?",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:false
    // },
    // {
    //   sender_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   recipient_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   media_type: "text",
    //   content: "It’s a web development project for a client.",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:true
    // },
    // {
    //   sender_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   recipient_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   media_type: "text",
    //   content: "That sounds exciting! How far along are you?",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:false
    // },
    // {
    //   sender_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   recipient_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   media_type: "text",
    //   content: "I’m in the final stages of the design.",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:true
    // },
    // {
    //   sender_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   recipient_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   media_type: "text",
    //   content: "That’s awesome! Are you using any frameworks?",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:false
    // },
    // {
    //   sender_id: "a74f3d08-b46d-4686-8899-dc965c7e599a",
    //   recipient_id: "617743c0-ccb0-4c12-b68b-e498d016fa45",
    //   media_type: "text",
    //   content: "Yeah, I’m using React for the front end!",
    //   media_url: "",
    //   shipping_address: "",
    //   business_card: "",
    //   offer_id: "",
    //   inquiry_id: "",
    //   catalogFiles: "",
    //   chatHeadType: "directchat",
    //   ticket_id: "",isUser:true
    // }
  ];
export const users = [
    {
      id: 0,
      user_details: {
        firstName: "John",
        lastName: "Doe"
      },
      lastMessage: "Hey, how are you?",
      date: new Date(),
      notifications: 2
    },
    {
      id: 1,
      user_details: {
        firstName: "Jane",
        lastName: "Smith"
      },
      lastMessage: "Let's catch up soon!",
      date: new Date(),
      notifications: 5
    },
    {
      id: 2,
      user_details: {
        firstName: "Michael",
        lastName: "Johnson"
      },
      lastMessage: "Meeting at 3 PM?",
      date: new Date(),
      notifications: 0
    },
    {
      id: 3,
      user_details: {
        firstName: "Emily",
        lastName: "Davis"
      },
      lastMessage: "Sent the report, please review.",
      date: new Date(),
      notifications: 1
    },
    {
      id: 4,
      user_details: {
        firstName: "Chris",
        lastName: "Brown"
      },
      lastMessage: "Got your message, will reply later.",
      date: new Date(),
      notifications: 3
    }
  ];
export const BusinessOptions=[
  {
    title:"Quick Messages",
    subTitle:"",
    key:"quick_message",
    img:"blueFastLogo"
  },
  {
    title:"Promotions/Offers",
    subTitle:"",
    key:"promotions_offer",
    img:"blueDiscountLogo"
  },
  {
    title:"Share Shipping Address",
    subTitle:"",
    key:"share_shipping_address",
    img:"blueDiscountLogo"
  },
  {
    title:"Share Business Card",
    subTitle:"",
    key:"share_card",
    img:"blueDiscountLogo"
  },
  {
    title:"Catalog",
    subTitle:"",
    key:"catalog",
    img:"blueDiscountLogo"
  },
  
]
export const CustomerOptions=[
  {
    title:"Quick Messages",
    subTitle:"Type / input box",
    key:"quick_message",
    img:"blueFastLogo"
  },
  {
    title:"Offer/Promotion",
    subTitle:"",
    key:"promotions_offer",
    img:"blueDiscountLogo"
  },
  
]

export const QuickMessageTemplte=(customerName='Customer Name')=>{
  let templates=[
    `Hi ${customerName}, how can I help you today? Please
    let me know if you have any questions or concerns.`,
    `Hi ${customerName}, how can I help you today? Please
    let me know if you have any questions or concerns.`,
    `
    Hi ${customerName}, how can I help you today? Please
                          let me know if you have any questions or concerns.
    `,
    `Hi ${customerName}, how can I help you today? Please
    let me know if you have any questions or concerns.`
  ]
  return templates;
  
}
export const handleCatalogSize=(sizeInBytes)=>{
  let sizeInMB=sizeInBytes/(1024*1024);
  return `${sizeInMB.toFixed(2)} MB`;
}