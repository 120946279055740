import React, { useState } from "react";
import "react-color-palette/css";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";

const AddAttributesModal = () => {
  const { t } = useTranslation();
  const [activePos, setActivePos] = useState(false);

  return (
    <>
      <p className="fontSize16 txtDarkblue">
        {t("addAttribute")}
      </p>
      <div className="addAttribute_Container">
        <div className="editVariant_SecondBox">
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">{t("attName")}</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      placeholder="Attribute name"
                      name="text"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">{t("addStock")}</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      placeholder="Value"
                      name="text"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent ">
            <div className="row">
              <div className="col-12">
                <div className="upcBox upcRightBox showAttribute_Box">
                  <div className="innnerParent align-items-center">
                    <div className="productSale_Parent p-0 m-0">
                      <input
                        type="checkbox"
                        onChange={() => setActivePos(!activePos)}
                      />
                    </div>
                    <div>
                      <h3 className="fontSize16 txtDarkblue">{t("show")}</h3>
                      <h3 className="fontSize12 txtLightBlue mb-0">
                        {t("showTitle")}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="editVariant_SecondBox mt-4">
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">{t("attName")}</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      placeholder="Attribute name"
                      name="text"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">{t("addStock")}</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      placeholder="Value"
                      name="text"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent ">
            <div className="row">
              <div className="col-12">
                <div className="upcBox upcRightBox showAttribute_Box">
                  <div className="innnerParent align-items-center">
                    <div className="productSale_Parent p-0 m-0">
                      <input
                        type="checkbox"
                        onChange={() => setActivePos(!activePos)}
                      />
                    </div>
                    <div>
                      <h3 className="fontSize16 txtDarkblue">Don’t Show</h3>
                      <h3 className="fontSize12 txtLightBlue mb-0">
                        {t("showTitle")}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button className="addOptionSetBtn w-100">
            <i className="fa-regular fa-plus addOptionPlusIcon me-2"></i>{t("addAttribut")}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddAttributesModal;
