export const weekOptions=[
    {
        title: "Monday",
        value: "monday",
        color: "#FFC300"
    },
    {
        title: "Tuesday",
        value: "tuesday",
        color: "#FF9F40"
    },
    {
        title: "Wednesday",
        value: "wednesday",
        color: "#FF6347"
    },
    {
        title: "Thursday",
        value: "thursday",
        color: "#FF3333"
    },
    {
        title: "Friday",
        value: "friday",
        color: "#FF0000"
    },
    {
        title: "Saturday",
        value: "saturday",
        color: "#800080"
    },
    {
        title: "Sunday",
        value: "sunday",
        color: "#0000FF" // Blue for Sunday
    }
    
]
export const daysName=[
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
]