import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import {
  getMerchantTotalCustomerGraphAsync,
} from "../../containers/dashboard/dashboardSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import ChartArea from "../dashboard/wallet/ChartArea";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { addThousandSeparator } from "../../../utilities/helpers";
import HomeHeader from "../../components/UI/Orders/MainHeader";
import { useTranslation } from "react-i18next";

const UserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [filterValue, setFilterValue] = useState("week");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [analyticsData, setAnalyticsData] = useState();
  const [dataSets, setDataSets] = useState([]);
  const [sellerAddress, setSellerAddress] = useState("")
  
  const [checked, setChecked] = useState({
    walkingCustomers: true,
    onlineCustomers: true,
    newCustomers: true,
  });
  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: analyticsData.graphData?.datasets[num]?.data ?? [],
          borderColor: color,
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
  };
  const history = useHistory();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          dash: [2, 2],
          display: false,
          color: "rgba(180, 190, 235, 1)",
        }
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: "#7E8AC1",
        },
      },
    }
  };
  
  const serviceSalesData = {
    labels: analyticsData?.graphData?.labels,
    datasets: dataSets,
  };

  const getMerchantTotalCustomerGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter: filterValue || "week",
        ...(sellerAddress && {
          seller_address_id: sellerAddress
        })
      },
    };
    if (startDate != null && endDate != null) {
      delete data.postData.filter;
      data.postData.start_date = moment(startDate).format("YYYY-MM-DD")
      data.postData.end_date = moment(endDate).format("YYYY-MM-DD")
    }
    setLoading(true);
    dispatch(getMerchantTotalCustomerGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setAnalyticsData(obj.payload);
        setDataSets([
          {
            label: "Walking Customers",
            data: obj.payload?.graphData?.datasets
              ? obj.payload?.graphData?.datasets[0]?.data
              : "",
            borderColor: "#1FB3FF",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0.2
          },
          {
            label: "Online Customers",
            data: obj.payload?.graphData?.datasets
              ? obj.payload?.graphData?.datasets[1]?.data
              : "",
            borderColor: "#039855",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0.2
          },
          {
            label: "New Customers",
            data: obj.payload?.graphData?.datasets
              ? obj.payload?.graphData?.datasets[2]?.data
              : "",
            borderColor: "#FCBA30",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0.2
          },
        ]);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filterValue || (startDate != null && endDate != null)) {
      getMerchantTotalCustomerGraph();
    }
  }, [currentPage, perPage, filterValue, endDate, sellerAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "User Directory";
  }, []);

  const UserHeaderStatisticsArr = [
    {
      heading: t("newCustomer"),
      key: "rescheduled",
      img: "newCustomerLogo",
      className: "transectionsBox",
      value: analyticsData?.total_customers?.newCustomer || 0
    },
    {
      heading: t("returningCus"),
      key: "total_booking",
      img: "returningCustomerLogo",
      className: "totalProductBox",
      value: analyticsData?.total_customers?.returningCustomer || 0
    },
    {
      heading: t('onlineCustomer'),
      key: "fulfillment",
      img: "onlineCustomerLogo",
      className: "totalBrandBox ",
      value: analyticsData?.total_customers?.onlineCustomers || 0
    },

    {
      heading: t('walkinCus'),
      key: "cancelled",
      img: "walkInCustomerLogo",
      className: "totalSubCategoryBox",
      value: analyticsData?.total_customers?.walkingCustomers || 0
    },
  ];

  const handleFilterValue = (value) => {
    setEndDate(null);
    setStartDate(null);
    setFilterValue(value);
  };
  const handleDateValue = (values) => {
    setFilterValue(null);
    if (values.startDate == values.endDate) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };
  const handleSellerAddress = (value) => {
    setSellerAddress(value);
  };
  return (
    <>
      <DashboardNavbar title={t('userOverview')} backShow={false} />
      <div className="walletStats userList_">
        <HomeHeader
          filterValue={filterValue}
          handleFilter={handleFilterValue}
          handleDate={handleDateValue}
          seller_id={
            auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid
          }
          tokenData={auth?.payload?.token}
          handleLocation={handleSellerAddress}
          subhead="vs. Same day last week"
        />
        {loading ? <span className="spinner-border inner-spin spinner-border-sm mt-5"></span> : (
          <>
            <div className="verificationTotal">
              <div className="row mt-3">
                {UserHeaderStatisticsArr?.map((headerStatic, index) => (
                  <HeaderStatistics
                    className={headerStatic?.className}
                    img={Images?.[headerStatic?.img]}
                    value={headerStatic?.value}
                    heading={headerStatic?.heading}
                  />
                ))}
              </div>
            </div>
            <div className="totalCustomer">
              <div className="row">
                <div className="col-md-12">
                  <div className="totalHead mt-4">
                    <div className="chartHeader_">
                      <div className="leftorderValue_">
                        <h3
                          style={{ cursor: "pointer" }}
                          className="textInnerHead m-0"
                        >
                          {t('totalCustomers')}
                        </h3>
                        <h3 className="Innerhead ">
                          {
                            addThousandSeparator(analyticsData?.total_customers?.totalCustomer || 0)
                          }
                        </h3>
                        <Link to="/customer">
                          <button className="viewAllbtn_" type="submit" onClick={() => {
                            history.push("/customer", {
                              customerType: "all_customers",
                            },
                            );
                          }}>
                            {t('viewAll')}
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="graphChecked_ mt-3  text-end">
                      <div className="checkYellow me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll3"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.newCustomers}
                          onChange={(e) => {
                            handelDataSetChange(
                              e,
                              "New Customers",
                              2,
                              "#FCBA30"
                            );
                            setChecked({
                              ...checked,
                              newCustomers:
                                checked.newCustomers === true ? false : true,
                            });
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll3"
                          className="checkBoxTxt masterChecked"
                        >
                          {t('newCustomer')}
                        </label>
                      </div>
                      <div className="checkGreen me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll4"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.onlineCustomers}
                          onChange={(e) => {
                            handelDataSetChange(
                              e,
                              "Online Customers",
                              1,
                              "#275AFF"
                            );
                            setChecked({
                              ...checked,
                              onlineCustomers:
                                checked.onlineCustomers === true ? false : true,
                            });
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll4"
                          className="checkBoxTxt masterChecked"
                        >
                          {t('onlineCustomer')}
                        </label>
                      </div>

                      <div className="checkSkyblue me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll5"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          onChange={(e) => {
                            handelDataSetChange(
                              e,
                              "Walking Customers",
                              0,
                              "#1FB3FF"
                            );
                            setChecked({
                              ...checked,
                              walkingCustomers:
                                checked.walkingCustomers === true
                                  ? false
                                  : true,
                            });
                          }}
                          checked={checked.walkingCustomers}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll5"
                          className="checkBoxTxt masterChecked"
                        >
                          {t('walkinCus')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tradesrowue">
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <div className="row mt-4 mb-4 align-items-center">
                      <div className="col-md-12">
                        <ChartArea
                          classes="productSales mt-2 mb-2"
                          header="Services Sales"
                          options={options}
                          data={serviceSalesData}
                          chartType="Line"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserList;
