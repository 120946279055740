import React, { useState } from "react";
import * as Images from "../../../../../utilities/images";
import moment from "moment-timezone";
import { isHideCheckInButton } from "../../../../constants/bookings/allbooking";
import { getCalendarActionButtonTitle } from "../../../../../utilities/globalMethods";
import { useTranslation } from "react-i18next";

const AppointmentDetailModal = ({
  data,
  close,
  onAppointmentStatusUpdate,
  reschedule,
  handleOpen
}) => {
  const { t } = useTranslation();
  const [isHideCheckInButtons, setisHideCheckInButtons] = useState(
    isHideCheckInButton(data?.start_date_time, data?.approx_service_time)
  );
  let userDetails=data?.user_details||data?.invitation_details;
  return (
    <div className="appointmentDetailContainer">
      <div className="checkInCustomerDetail mt-0">
        <div className="InnercheckIn">
          <h5 className="fontSize14 fw500 txtDarkblue">{t('customer')}:</h5>
          <div className="flexBox gap-2">
            <img
              src={userDetails?.profile_photo || Images.userImg_}
              className="userImgMid"
              alt="img"
            />
            <div>
              <h3 className="fontSize16 fw500 txtDarkblue">
                {(userDetails?.firstname||"") +
                  " " +
                  (userDetails?.lastname||"")}
              </h3>
            { userDetails?.current_address&& <h6 className="flexBox fontSize14 fw500 mb-0 gap-1 locationText">
                <img src={Images.locateOrder} alt="img" className="location" />
                {userDetails?.current_address?.city},
                {userDetails?.current_address?.country}
              </h6>}
            </div>
          </div>
          <div className="flexBox gap-2 justify-content-end pt-3 pb-3">
            <div>
              <h3 className="fontSize16 fw500 txtDarkblue">
                {(data?.pos_user_details?.user?.user_profiles?.firstname||"") +
                  " " +
                  (data?.pos_user_details?.user?.user_profiles?.lastname||"")}
              </h3>
             {data?.pos_user_details?.user?.user_profiles?.current_address&& <h6 className="flexBox fontSize14 fw500 mb-0 gap-1 locationText">
                <img src={Images.locateOrder} alt="img" className="location" />
                {(data?.pos_user_details?.user?.user_profiles?.current_address
                  ?.city||"---") +
                  ", " +
                  (data?.pos_user_details?.user?.user_profiles?.current_address
                    ?.country||"---")}
              </h6>}
            </div>
            <img
              src={
                data?.pos_user_details?.user?.user_profiles?.profile_photo ||
                Images.userImg_
              }
              className="userImgMid"
              alt="img"
            />
          </div>
        </div>
        {/* <div className="startConversationbox">+ Start a conversation?</div> */}
      </div>

      <div className="flexBox justify-content-between mb-4">
        <h5 className="fontSize14 fw500 txtDarkblue mb-0">
          {t('serviceRequested')}:
        </h5>
        <div className="flexBox flex-wrap gap-2">
          <h5 className="checkInServiceRequest">{data?.product_name}</h5>
        </div>
      </div>

      <div className="checkInServiceParent flexBox justify-content-between pb-3">
        <h5 className="fontSize14 fw500 txtDarkblue mb-0">{t('serviceTime')}:</h5>
        <div className="flexBox gap-3">
          <div className="appointmentDateParent">
            <img
              className="img-fluid clockimg_ me-2"
              alt="clock"
              src={Images.customCalendar}
              width={16}
              height={16}
            />
            <span>{moment.utc(data?.date).format("dddd")},</span>{" "}
            <span> {moment.utc(data?.date).format("MMMM DD, YYYY")} </span>
          </div>
          <div className="appointmentDateParent">
            <img src={Images.blueClockImg} alt="img" />
            <span className="fontSize12 fw500 txtDarkblue">
              {`(
                ${data?.start_time}-${data?.end_time}
              )`}
            </span>
          </div>
        </div>
      </div>

      <div className="appointmentPriceBox">
        <div className="appointmentPriceInner_">
          <h3 className="fontSize14 txtLightBlue">{t('taxes')}</h3>
          <h3 className="fontSize14 txtDarkblue">
            ${Number(data?.tax || 0).toFixed(2)}
          </h3>
        </div>
        <div className="appointmentPriceInner_">
          <h3 className="fontSize14 txtLightBlue">{t('delivery')}</h3>
          <h3 className="fontSize14 txtDarkblue">
            ${Number(data?.delivery_charge || 0).toFixed(2)}
          </h3>
        </div>
        <div className="appointmentPriceInner_">
          <h3 className="fontSize14 txtLightBlue">{t('promotDiscount')}</h3>
          <h3 className="fontSize14 txtDarkblue">
            ${Number(data?.discount || 0).toFixed(2)}
          </h3>
        </div>
        <div className="appointmentPriceInner_">
          <h3 className="fontSize14 txtLightBlue">{t('subTotal')}</h3>
          <h3 className="fontSize14 txtDarkblue">
            ${Number(data?.actual_price || 0).toFixed(2)}
          </h3>
        </div>
        <div className="appointmentPriceInner_">
          <h3 className="fontSize14 txtLightBlue">{t('tips')}</h3>
          <h3 className="fontSize14 txtDarkblue">
            ${Number(data?.tips || 0).toFixed(2)}
          </h3>
        </div>
        <div className="appointmentPriceInner_">
          <h3 className="fontSize14 txtLightBlue fw600">{t('total')}</h3>
          <h3 className="fontSize14 txtDarkblue fw600">
            ${Number(data?.price || 0).toFixed(2)}
          </h3>
        </div>
        <div className="appointmentPriceInner_">
          <h3 className="appointmentInvoiceTxt fontSize12 fw500 mb-0">
            {t('invoice')}
          </h3>
          <h3 className="appointmentInvoiceTxt fontSize12 fw500 mb-0">
            # {data?.invoices?.id}
          </h3>
        </div>
      </div>

      <div className="modalfooterBtn d-flex justify-content-center gap-4">
        {data?.status == 1 && (
          <>
            <button
              className="modifyBtn_ checkInBtn"
              onClick={() => {
                reschedule(data);
                close();
              }}
            >
              {t('modify')}
            </button>
          </>
        )}
        {isHideCheckInButtons? (
          ""
        ) : (
          <>
            <button
              className="tableDeclineBtn checkInBtn"
              onClick={() => {
                if (data?.status == 1) {
                  // onAppointmentStatusUpdate(data?.id, 3);
                  handleOpen("checkIn")
                } else if (data?.status == 2) {
                }
                close();
              }}
            >
              {data?.status && getCalendarActionButtonTitle(data?.status)}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AppointmentDetailModal;
const RenderButton = (status, close) => {
  const { t } = useTranslation();
  const renderCompletedStatus = () => (
    <button
      className="cancleAppointmentBtn tableDeclineBtn"
      style={{ background: "green", color: "white" }}
      type="submit"
      onClick={() => close()}
    >
      {t('completed')}
    </button>
  );

  const renderCancelledByCustomerStatus = () => (
    <button
      className="cancleAppointmentBtn tableDeclineBtn"
      type="submit"
      onClick={() => close()}
    >
      {t('cancelled')}
    </button>
  );

  const renderRejectedBySellerStatus = () => (
    <button
      className="cancleAppointmentBtn tableDeclineBtn"
      type="submit"
      onClick={() => close()}
    >
      {t('rejected')}
    </button>
  );
  switch (status) {
    case 3:
      return renderCompletedStatus();
    case 4:
      return renderCancelledByCustomerStatus();
    case 5:
      return renderRejectedBySellerStatus();
    default:
      return null;
  }
};
