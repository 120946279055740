import React, { useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment-timezone";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { bookingsDetails, setGetStaffUsers } from "./bookingSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";

import {
  getBookingTimeSlotsApiAsync,
  resheduleBookingApiAsync,
} from "../dashboard/dashboardSlice";
import { getDaysAndDates } from "./BookingCalendar/GlobalMethods";
import { useTranslation } from "react-i18next";

const BookingSchedule = ({
  data,
  changeStatusOfAppointment,
  close,
  handleAllAppointmentData,
  staff = [],
}) => {
  const { t } = useTranslation();
  const [staffUserList, setstaffUserList] = useState(staff);
  const auth = useSelector(selectLoginAuth);
  const [selectedTimeSlotIndex, setselectedTimeSlotIndex] = useState(null);
  const [monthDays, setmonthDays] = useState([]);
  const appointmentsDetails = useSelector(bookingsDetails);

  const [timeSlots, settimeSlots] = useState([]);
  const [timeSlot, settimeSlot] = useState(null);
  const [selectDate, setselectDate] = useState(null);
  const scrollContainerRef = useRef(null);
  const [posUser, setposUser] = useState(
    data?.pos_user_details?.user?.unique_uuid
  );
  const dispatch = useDispatch();
  const [selectedDate, setselectedDate] = useState(
    // moment(data?.date).utc().format("YYYY-MM-DD")
  );
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 4000,

    customPaging: (i) => (
      <div
        style={{
          width: "8px",
          height: "8px",
          background: "#E4E6F2",
          borderRadius: "50%",
          marginTop: "20px",
        }}
        className="custom-dot"
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [isLoadingTimeSlot, setisLoadingTimeSlot] = useState(false);
  const selectedDay = (val) => {
    // setDate(moment(val).format("YYYY-MM-DD"));
    let slotData = {
      postData: {
        date: val,
        product_id: data?.product_id,
        pos_user_id: posUser,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    setisLoadingTimeSlot(true);
    // setLoading1(true);
    dispatch(getBookingTimeSlotsApiAsync(slotData))
      .then(unwrapResult)
      .then((res) => {
        if (res?.payload?.slots) {
          let availableSlots = res?.payload?.slots.filter(
            (slot) => slot?.is_available
          );
          settimeSlots(availableSlots || []);
        } else {
          settimeSlots([]);
        }
        setisLoadingTimeSlot(false);
        // setLoading1(false)
      })
      .catch((obj) => {
        // setLoading1(false)
        setisLoadingTimeSlot(false);
      });
  };
  //new flow
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState({
    value: currentYear,
    label: currentYear.toString(),
  });
  const [selectedMonth, setSelectedMonth] = useState({
    value: currentMonth,
    label: moment().format("MMM"),
  });

  const futureYears = Array.from(
    { length: 10 },
    (_, index) => currentYear + index
  );
  const yearOptions = futureYears.map((year) => ({ value: year, label: year }));

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const [selectedTimeSlotData, setSelectedTimeSlotData] = useState("");
  const [selectedMonthData, setselectedMonthData] = useState(null);
  const [selectedYearData, setselectedYearData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);

    // date and slot clear select
    setselectedDate(null);
    setselectedTimeSlotIndex(null);
    setSelectedTimeSlotData("");
  };
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 200; // Adjust the scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 200; // Adjust the scroll amount as needed
    }
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);

    // date and slot clear select
    setselectedDate(null);
    setselectedTimeSlotIndex(null);
    setSelectedTimeSlotData("");
  };
  useEffect(() => {
    if (selectedDate) {
      //check if date is less than to current date

      selectedDay(selectedDate);
    }
  }, [selectedDate, posUser]);
  const handletimeSlot = (slot) => {
    settimeSlot(slot);
  };
  useEffect(() => {
    const daysArray = getDaysAndDates(
      selectedYear?.value,
      selectedMonth?.value
    );
    if(daysArray.length){
      setselectedDate(daysArray[0]?.completeDate)
    }
    if(daysArray.length<=4){
      let anotherMonth=getDaysAndDates(
        selectedYear?.value,
        selectedMonth?.value + 1
      );
      setmonthDays([...daysArray,...anotherMonth]);
    }
   else setmonthDays(daysArray);
   
   
  }, [selectedMonth, selectedYear]);
  useEffect(() => {
    setstaffUserList(appointmentsDetails?.staffUsers || []);
  }, [appointmentsDetails]);
  const resheduleBookingHandle = (id) => {
    if (!selectedTimeSlotData) {
      alert("Please select a time slot to rechedule the appointment");
      return;
    }
    let params = {
      postData: {
        id: data?.id,
        date: selectedDate,
        start_time: selectedTimeSlotData?.start_time,
        end_time: selectedTimeSlotData?.end_time,
        pos_user_id: posUser,
      },
      tokenData: auth?.payload?.token,
    };
    // setLoadingNew(true);
    setLoading(true);
    dispatch(resheduleBookingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        close();
        setLoading(false);
        handleAllAppointmentData();
        // setLoadingNew(false)
      })
      .catch((obj) => {
        setLoading(false);

        // setLoadingNew(false)
      });
  };
  let userDetails=data?.user_details||data?.invitation_details;
  return (
    <div className="bookingScheduleContainer">
      <div className="checkInCustomerDetail mt-0">
        <div className="appointmentCustomer">
          <div>
            <h5 className="fontSize14 fw500 txtDarkblue">{t('customer')}:</h5>
            <div className="flexBox gap-2">
              <img
                src={userDetails?.profile_photo || Images.userImg_}
                className="userImgMid"
                alt="img"
              />
              <div>
                <h3 className="fontSize16 fw500 txtDarkblue">
                  {userDetails?.firstname +
                    " " +
                    userDetails?.lastname}
                </h3>
                {userDetails?.current_address&&<h6 className="flexBox fontSize14 fw500 mb-0 gap-1 locationText">
                  <img
                    src={Images.locateOrder}
                    alt="img"
                    className="location"
                  />
                  {userDetails?.current_address?.city},
                  {userDetails?.current_address?.country}
                </h6>}
                {/* {`${userDetails?.phone_number ?? "-"}`} */}
              </div>
            </div>
          </div>
          <h5 className="onlineBtn flexBox gap-1 mb-0">
            {" "}
            <img src={Images.clockBlue} alt="img" />
            {moment(data?.date).format("DD.MM.YYYY")}
            {" "+data?.start_time}-
            {data?.end_time}
            {/* 29/10/2023 | 10-11 hrs */}
          </h5>
        </div>

        <div className="slider-container">
          <Slider {...settings}>
            {staffUserList?.map((staff, index) => (
              <div>
                <div
                  onClick={() => {
                    setposUser(staff?.user?.unique_uuid);
                  }}
                  className={`sliderBox ${
                    posUser && posUser == staff?.user?.unique_uuid
                      ? "active"
                      : ""
                  } `}
                >
                  <img
                    src={
                      staff?.user?.user_profiles?.profile_photo ||
                      Images.teamMemberImg
                    }
                    alt="logo"
                    className="sliderImg"
                  />
                  <div>
                    <h3 className="fontSize12 fw500 txtDarkblue mb-0">
                      {(staff?.user?.user_profiles?.firstname || "-") +
                        " " +
                        (staff?.user?.user_profiles?.lastname || "-")}
                    </h3>
                    <h3 className="fontSize12 fw500 staffPositionTxt mb-0 pt-1">
                      Pos Admin
                    </h3>
                  </div>
                </div>
              </div>
            ))}

            {/* );
            })} */}
          </Slider>
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: " 0 0 15px"
        }}
      >
        <div className="serviceYear me-3">
          <ReactSelect
            options={yearOptions}
            value={selectedYear}
            onChange={handleYearChange}
            placeholder="Select a year"
            isSearchable={false}
          />
        </div>
        <div className=" serviceYear">
          <ReactSelect
            options={monthOptions}
            value={selectedMonth}
            onChange={handleMonthChange}
            placeholder="Select a month"
            isSearchable={false}
          />
        </div>
      </div> */}
      <div className="scrollContainer" style={{ width: "100%", marginBottom: "24px" }}>
        <button className="scrollButton left" onClick={scrollLeft} style={{backgroundColor: "transparent"}}>
          <img src={Images.leftArrow} alt="img"/>
        </button>
        <div
          ref={scrollContainerRef}
          style={{
            display: "flex",
            overflowX: "auto",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
           
            // justifyContent: "center",
          }}
        >
          {monthDays?.map((item, index) => (
            <div
              // onClick={() => {
              //   setselectDate(`2025-02-${day}`);
              // }}
              key={index}
              className={
                item?.completeDate === selectedDate
                  ? "serviceDate mx-3 active pointHand"
                  : "serviceDate mx-3 pointHand"
              }
              onClick={() => {
                setselectedDate(item?.completeDate);
                //Clear previous day selected time slot values
                setselectedTimeSlotIndex(null);
                setSelectedTimeSlotData("");
              }}
            >
              <h4 className="appointmentDayTxt mb-1">{item?.completeDate === moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : item?.day}  </h4>
              <h4 className="appointmentDateTxt mb-0">
                { item?.date}
              </h4>
            </div>
          ))}
        </div>
        <button className="scrollButton right" onClick={scrollRight} style={{backgroundColor: "transparent"}}>
        <img src={Images.newRightArrow} alt="img"/>
        </button>
      </div>

      <div className="px-3">
        <div className="row">
          {/* <div className=" col-lg-3 col-md-6 col-sm-3 mt-2 ps-0 pe-2">
            <img
              src={Images.scheduleTimeImg}
              alt="scheduleTimeImg"
              className="newScheduleTimeImg"
            />
          </div> */}
          {isLoadingTimeSlot ? (
            <>
              <div className="text-center mt-3">
                <span className="spinner-border spinner-border-sm "></span>
              </div>
            </>
          ) : timeSlots?.length == 0 ? (
            <div className="mx-1 text-center mt-3">
              <p className="fontSize20 fw500 txtDarkblue ">{"No Slot"}</p>
            </div>
          ) : (
            <>
              {selectedDate !== null ? (
                <>
                  {/* <div style={{ borderColor: "#027547" }} className="row"> */}
                  {timeSlots?.map((item, index) => {
                    return (
                      <>
                        <div
                          className=" col-lg-3 col-md-6 col-sm-3 mb-2 pe-2 editAppoinmentTimeParent ps-0"
                          key={index}
                          onClick={() => {
                            setselectedTimeSlotIndex(index);
                            setSelectedTimeSlotData(item);
                          }}
                        >
                          {item?.is_available ? (
                            <div
                              className={
                                selectedTimeSlotIndex == index
                                  ? "scheduleTime active"
                                  : "scheduleTime"
                              }
                            >
                              <h3 className="addServicePara m-0 pointHand">
                                {item?.start_time + " - " + item?.end_time}
                              </h3>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={
                                  Images.scheduleTimeImg

                                  // Images.scheduleTimeImg
                                }
                                alt="scheduleTimeImg"
                                className="newScheduleTimeImg"
                              />
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                  {/*  </div> */}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {/* <div className=" col-lg-3 col-md-6 col-sm-3 mt-3">
            <img
              src={Images.scheduleTimeImg}
              alt="scheduleTimeImg"
              className="newScheduleTimeImg"
            />
          </div>
          <div className=" col-lg-3 col-md-6 col-sm-3 mt-3">
            <div className="scheduleTime active">
              <h3 className="addServicePara cursorPointer mb-0">
                11:00 — 11:30
              </h3>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6 col-sm-3 mt-3">
            <div className="scheduleTime active">
              <h3 className="addServicePara cursorPointer mb-0">
                11:00 — 11:30
              </h3>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6 col-sm-3 mt-3">
            <div className="scheduleTime active">
              <h3 className="addServicePara cursorPointer mb-0">
                11:00 — 11:30
              </h3>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6 col-sm-3 mt-3">
            <div className="scheduleTime active">
              <h3 className="addServicePara cursorPointer mb-0">
                11:00 — 11:30
              </h3>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6 col-sm-3 mt-3">
            <div className="scheduleTime active">
              <h3 className="addServicePara cursorPointer mb-0">
                11:00 — 11:30
              </h3>
            </div>
          </div>
          <div className=" col-lg-3 col-md-6 col-sm-3 mt-3">
            <div className="scheduleTime active">
              <h3 className="addServicePara cursorPointer mb-0">
                11:00 — 11:30
              </h3>
            </div>
          </div> */}
        </div>
      </div>

      <div className="flexBox justify-content-between gap-3 mt-3">
        <button
          disabled={loading}
          onClick={() => {
            changeStatusOfAppointment(data?.id, 4);
            close();
          }}
          className="cancleAppointmentBtn w-100"
        >
          {t('cancelAppointment')}
        </button>
        <button
          disabled={!selectedTimeSlotData || loading}
          onClick={() => {
            resheduleBookingHandle(data?.id);
          }}
          className={`commonBlueBtn active gap-1 w-100`}
        >
          {loading && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}
          {t('acceptChanges')}
          <img src={Images.closeTag} alt="img" />
        </button>
      </div>
    </div>
  );
};

export default BookingSchedule;
