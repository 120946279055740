import React from 'react'
import { DeliveryStatusOptions, ShippingStatusOptions } from '../../../constants/orders/orders';

const DeliveryStatus = ({statusOption,delivery}) => {
  if(statusOption===''){
    return   <div className='d-flex justify-content-center'></div>
  }
    let statusDetails=null;
    if(delivery==4)statusDetails= ShippingStatusOptions[parseInt(statusOption,10)];
    else statusDetails= DeliveryStatusOptions[parseInt(statusOption,10)];
  return <>
  <div className='d-flex justify-content-center'>
  <div className={`deliveryStatus_  ${statusDetails.className+"Status"}`}>
  <span>
    {(delivery==3&&statusOption==3)?'Ready for pickup': statusDetails.label}
  </span>
  </div>
  </div>
  </>
}

export default DeliveryStatus