import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { profileImg1 } from "../../../utilities/images";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getAddressApiAsync,
  getPosUsersAsync,
  selectLoginAuth,
} from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formValidator } from "./formValidator";
import {
  createProductApiAsync,
  editProductApiAsync,
} from "../dashboard/dashboardSlice";
import CustomModal from "../../components/shared/CustomModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import ProductDetails from "../service/ProductDetails";
import ProductImageAdd from "../service/productImageAdd";
import AddServiceHeader from "../service/AddServiceHeader";
import AssignedTeamMemberModal from "../service/AssignedTeamMemberModal";
import ChartImageUploader from "../service/chartImageUploader";
import EditServiceHeader from "./EditServiceHeader";
import EditServiseImageAdd from "./serviceImageAdd";
import ServiceEditDetail from "./serviceEditDetail";
import ConfirmationModal from "../../components/UI/Product/confirmationModal";
import { useTranslation } from "react-i18next";
const EditService = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const userData = location?.state;
  console.log(userData, "userData");
  const allImages = userData?.product_images ?? [];
  const history = useHistory();
  const [serviceId, setServiceId] = useState(userData?.id);
  const [storeCheck, setStoreCheck] = useState(true);
  const [marketCheck, setMarketCheck] = useState(true);
  const [maximumValue, setMaximumValue] = useState(
    userData?.supplies[0]?.max_capacity
      ? userData?.supplies[0]?.max_capacity
      : "Max"
  );
  const [minimumValue, setMinimumValue] = useState(
    userData?.supplies[0]?.min_capacity
      ? userData?.supplies[0]?.min_capacity
      : "Min"
  );
  const [productDetails, setProductDetails] = useState({
    productName: userData?.name,
    service_id: userData?.service_id,
    category_id: userData?.category_id,
    sub_category_id: userData?.sub_category_id,
    approx_service_time: userData?.supplies[0]?.approx_service_time,
    buffer_time_before: userData?.supplies[0]?.buffer_time_before,
    buffer_time_after: userData?.supplies[0]?.buffer_time_after,
  });
  const [posList, setPosList] = useState([]);
  const [costPrice, setcostPrice] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [imageDetails, setImageDetails] = useState({});
  const [chartImage, setChartImage] = useState("");
  const [cancelFee, setCancelFee] = useState(userData?.cancellation_fee || "");

  const [prices, setPrices] = useState([
    {
      app_name: "pos",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
    {
      app_name: "b2c",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
  ]);


  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saleSame, setSaleSame] = useState(false);
  const [differentSale, setDifferentSale] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(["reservation"]);
  const [type, setType] = useState("service");
  const [posUserId, setPosUserId] = useState([]);
  const [skipPosDetails, setSkipPosDetails] = useState(false);
  const [syncGoogleCalendar, setSyncGoogleCalendar] = useState(false);
  const [selectedPosId, setSelectedPosId] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);

  const [posPrice, setPosPrice] = useState(null);

  const [marketplacePrice, setMarketplacePrice] = useState(null);
  const [posProfit, setPosProfit] = useState(null);
  const [posMargin, setPosMargin] = useState(null);
  const [marketplaceProfit, setMarketplaceProfit] = useState(null);
  const [marketplaceMargin, setMarketplaceMargin] = useState(null);
  const [variants, setVariants] = useState([]);
  const [isTax, setIsTax] = useState(userData?.is_taxable || false);
  const [oldSelectedPosId, setOldSelectedPosId] = useState([]);
  const [structureVariants, setStructureVariants] = useState([]);

  // Mapping for delivery option codes
  const deliveryOptionMap = {
    1: "delivery",
    2: "reservation",
    3: "pickup",
    4: "shipping",
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  // Handle changes for POS and Marketplace Sale Prices
  const handlePriceChange = (e, platform) => {
    const newPrice = parseFloat(e.target.value);

    if (newPrice === null || newPrice < 0 || newPrice === "" || !newPrice) {
      resetPlatformPriceAndProfit(platform);
      return;
    }

    if (platform === "pos") {
      setPosPrice(newPrice);
      calculatePosProfitAndMargin(newPrice, costPrice);

      if (!differentSale) {
        setMarketplacePrice(newPrice);
        calculateMarketplaceProfitAndMargin(newPrice, costPrice);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      calculateMarketplaceProfitAndMargin(newPrice, costPrice);
    }

    updatePriceArray(platform, newPrice);
  };

  const calculatePosProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setPosProfit(profit.toFixed(2)); // Update profit
      setPosMargin(margin.toFixed(2)); // Update margin
    } else {
      setPosProfit(null); // Reset profit if invalid
      setPosMargin(null); // Reset margin if invalid
    }
  };

  const calculateMarketplaceProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setMarketplaceProfit(profit.toFixed(2)); // Update profit
      setMarketplaceMargin(margin.toFixed(2)); // Update margin
    } else {
      setMarketplaceProfit(null); // Reset profit if invalid
      setMarketplaceMargin(null); // Reset margin if invalid
    }
  };

  const calculateMargin = (price) => {
    let margin = 0; // Default value for margin
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      margin = (profit / costPrice) * 100; // Formula to calculate margin
    }
    return margin; // Return the calculated margin
  };

  const resetPlatformPriceAndProfit = (platform) => {
    if (platform === "pos") {
      setPosPrice(null);
      setPosProfit(null);
      setPosMargin(null);
      updatePriceArray("pos", null);

      if (!differentSale) {
        setMarketplacePrice(null);
        setMarketplaceProfit(null);
        setMarketplaceMargin(null);
        updatePriceArray("b2c", null);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(null);
      setMarketplaceProfit(null);
      setMarketplaceMargin(null);
      updatePriceArray("b2c", null);
    }
  };

  const updatePriceArray = (platform, newPrice) => {
    setPrices((prevPrices) => {
      return prevPrices.map((price) => {
        if (price.app_name === platform) {
          if (!newPrice) {
            // Only reset selling_price and margin_percentage when the price is null or empty
            return {
              ...price,
              selling_price: "",
              margin_percentage: "",
            };
          } else {
            // Update selling_price and margin_percentage for the given platform
            const margin = calculateMargin(newPrice);
            return {
              ...price,
              selling_price: newPrice.toString(),
              margin_percentage: margin.toFixed(2),
            };
          }
        }
        return price; // Keep other platforms as is
      });
    });
  };

  const getIdsHandle = (data) => {
    setSelectedPosId(data);
    handleOnCloseModal();
  };

  const handleSyncGoogleCalendar = (e) => {
    setSyncGoogleCalendar(e.target.checked);
  };

  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else if (flag == "maximum") {
      setMaximumValue(e);
    } else if (flag == "minimum") {
      setMinimumValue(e);
    } else {
      setProductDetails(e);
    }
  };
  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleSave = () => {
    const sanitizedAddressesVariants = structureVariants.map((variantGroup) => {
      let sanitizedGroup = {
        seller_address_id: variantGroup.seller_address_id, // Ensure this key exists
        isNew: variantGroup.isNew || false,
        variants: variantGroup.variants.map((variant) => ({
          ...Object.fromEntries(
            Object.entries(variant).filter(
              ([_, value]) =>
                value !== null &&
                value !== undefined &&
                String(value).trim() !== ""
            )
          ),
          prices: variant.prices
            ?.map((price) => ({
              app_name: price.app_name,
              price_type: price.price_type || "fixed",
              selling_price: price.selling_price || 0,
              margin_percentage: price.margin_percentage || 0,
            }))
            .filter((price) => price.selling_price !== 0), // Remove empty price entries
        })),
      };

      // Only include supplyId if it's not null or undefined
      if (variantGroup.supplyId) {
        sanitizedGroup.supplyId = variantGroup.supplyId;
      }

      return sanitizedGroup;
    });

    // Check for negative margin percentages
    const hasNegativeMargin = sanitizedAddressesVariants.some((variantGroup) =>
      variantGroup.variants.some((variant) =>
        variant.prices?.some((price) => Number(price.margin_percentage) < 0)
      )
    );

    if (hasNegativeMargin) {
      setModalDetail({ show: true, flag: "confirmModal" });
      setKey(Math.random());
      return;
    }

    proceedWithSave(sanitizedAddressesVariants);
  };

  const proceedWithSave = (sanitizedAddressesVariants) => {
    var imagesToDelete = allImages.filter(
      (item) => !imageDetails.images.includes(item.url)
    );

    imagesToDelete = imagesToDelete.map((item) => item.id);

    // Get all seller address IDs from supplies
    const supplySellerIds = userData.supplies.map(
      (supply) => supply.seller_address_id
    );

    // Filter supply seller IDs that are NOT in seller addresses
    const missingSellerIds = supplySellerIds.filter(
      (id) => !sellerAddressIds.includes(id)
    );

    let product_attribute = {
      id: serviceId,
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      name: productDetails?.productName,
      description: productDescription,
      images: imageDetails?.images,
      image: imageDetails?.image,
      service_id: productDetails?.service_id,
      category_id: productDetails?.category_id?.toString(),
      sub_category_id: productDetails?.sub_category_id?.toString(),
      cost_price: costPrice,
      type: type,
      // delivery_options: deliveryOptions,
      addresses_variants: sanitizedAddressesVariants,
      approx_service_time: productDetails?.approx_service_time,
      pos_staff_ids: posUserId,
      sync_google_calendar: syncGoogleCalendar,
      min_capacity: minimumValue,
      max_capacity: maximumValue,
      buffer_time_before: productDetails?.buffer_time_before,
      buffer_time_after: productDetails?.buffer_time_after,
      // size_chart_image: chartImage.toString(),
    };
    // console.log(product_attribute, "product_attribute");
    if (missingSellerIds.length > 0) {
      product_attribute = {
        ...product_attribute,
        delete_seller_address_ids: missingSellerIds,
      };
    }
    if (deliveryOptions.length > 0) {
      product_attribute = {
        ...product_attribute,
        delivery_options: deliveryOptions,
      };
    }
    if (cancelFee !== "" && cancelFee !== null && cancelFee !== undefined) {
      product_attribute = {
        ...product_attribute,
        cancellation_fee: cancelFee,
      };
    }

    if (imagesToDelete.length > 0) {
      product_attribute.deleted_image_ids = imagesToDelete;
    }
    if (
      oldSelectedPosId.filter(
        (itemA) => !selectedPosId.some((itemB) => itemB.id === itemA.id)
      )?.length > 0
    ) {
      product_attribute = {
        ...product_attribute,
        deleted_pos_staff_ids: oldSelectedPosId
          .filter(
            (itemA) => !selectedPosId.some((itemB) => itemB.id === itemA.id)
          )
          ?.map((val) =>
            val?.pos_staff_details ? val?.pos_staff_details?.id : val?.id
          ),
      };
    }
    if (chartImage) {
      product_attribute = {
        ...product_attribute,
        size_chart_image: chartImage.toString(),
      };
    }
    // return;
    let result = formValidator(product_attribute, sellerAddressIds, variants);
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };
      dispatch(editProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success("Service Created Successfully");
          history.push(`/service-list`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };

  // Handle confirmation modal actions
  const handleConfirmNegativeMargin = () => {
    handleOnCloseModal();

    // Ensure structureVariants is mapped correctly to addresses_variants
    const sanitizedAddressesVariants = structureVariants.map((variantGroup) => {
      let sanitizedGroup = {
        seller_address_id: variantGroup.seller_address_id, // Ensure this key exists
        isNew: variantGroup.isNew || false,
        variants: variantGroup.variants.map((variant) => ({
          ...Object.fromEntries(
            Object.entries(variant).filter(
              ([_, value]) =>
                value !== null &&
                value !== undefined &&
                String(value).trim() !== ""
            )
          ),
          prices: variant.prices
            ?.map((price) => ({
              app_name: price.app_name,
              price_type: price.price_type || "fixed",
              selling_price: price.selling_price || 0,
              margin_percentage: price.margin_percentage || 0,
            }))
            .filter((price) => price.selling_price !== 0), // Remove empty price entries
        })),
      };

      // Only include supplyId if it's not null or undefined
      if (variantGroup.supplyId) {
        sanitizedGroup.supplyId = variantGroup.supplyId;
      }

      return sanitizedGroup;
    });

    proceedWithSave(sanitizedAddressesVariants);
  };

  const handleSelect = (e) => {
    let allIds = e.map((v) => {
      return v.pos_staff_details_id ? v.pos_staff_details_id : v?.id;
    });
    setPosUserId(allIds);
  };
  const setInitialData = () => {
    setMaximumValue(userData?.supplies[0]?.max_capacity);
    setMinimumValue(userData?.supplies[0]?.min_capacity);
    setImageDetails({
      image: userData?.image ?? "",
      images: userData?.product_images?.map((item) => {
        return item?.url ?? " ";
      }),
    });
    setSkipPosDetails(userData?.supplies[0]?.skip_detail_screen_in_pos);
    setSyncGoogleCalendar(userData?.supplies[0]?.sync_google_calendar);
    setProductDescription(userData?.description);
    // setPrices(userData?.supplies[0].supply_prices ?? []);
    setPosUserId(
      userData?.pos_staff?.map((item) => item?.pos_staff_details?.id)
    );
    const sellerAddressId = userData?.supplies[0]?.seller_address_id;

    if (sellerAddressId) {
      setSellerAddressIds([sellerAddressId]); // Set the seller_address_id
    }
    const deliveryOptionCodes = userData?.supplies[0]?.delivery_options
      ? userData?.supplies[0].delivery_options.split(",").map(Number) // Convert to an array of numbers
      : [];
    const parsedOptions = deliveryOptionCodes.map(
      (code) => deliveryOptionMap[code]
    );
    setDeliveryOptions(parsedOptions);
    setChartImage(userData?.size_chart_image);
    // Setting Variants with Proper Price Formatting
    // Function to safely parse images
    const parseImages = (imageData) => {
      if (!imageData) return [];
      try {
        if (Array.isArray(imageData)) return imageData;
        if (typeof imageData === "string" && imageData.startsWith("[")) {
          return JSON.parse(imageData);
        }
        return [imageData]; // Single image URL case
      } catch (error) {
        return [];
      }
    };
    const sellerAddressIds =
      userData?.supplies?.map((supply) => supply.seller_address_id) || [];

    if (sellerAddressIds.length > 0) {
      setSellerAddressIds(sellerAddressIds); // Set all seller_address_id values
    }

    const supplyVariants =
      userData?.supplies[0]?.supply_variants?.map((variant) => ({
        cost_price: variant?.cost_price,
        prices:
          userData?.supplies[0]?.supply_prices?.map((price) => ({
            app_name: price?.app_name,
            price_type: price?.price_type || "fixed",
            selling_price: price?.selling_price || "",
            margin_percentage: price?.margin_percentage || "",
            min_qty: price?.min_qty || 0,
            max_qty: price?.max_qty || 0,
          })) ?? [],
        image: variant?.image,
        images: parseImages(variant?.images),
      })) ?? [];

    setVariants(supplyVariants);

    const posPriceData = userData?.supplies[0]?.supply_prices.find(
      (item) => item.app_name === "pos"
    );
    const marketplacePriceData = userData?.supplies[0]?.supply_prices.find(
      (item) => item.app_name === "b2c"
    );
    // Set default prices if the price data exists
    if (posPriceData?.selling_price === marketplacePriceData?.selling_price) {
      setSaleSame(true);
    } else {
      setDifferentSale(true);
    }

    if (posPriceData && marketplacePriceData) {
      setPosPrice(posPriceData?.selling_price || "");
      setMarketplacePrice(marketplacePriceData?.selling_price || "");

      setPrices([
        {
          app_name: "pos",
          price_type: posPriceData?.price_type || "fixed",
          selling_price: posPriceData?.selling_price || "",
          margin_percentage: posPriceData?.margin_percentage || "",
        },
        {
          app_name: "b2c",
          price_type: marketplacePriceData?.price_type || "fixed",
          selling_price: marketplacePriceData?.selling_price || "",
          margin_percentage: marketplacePriceData?.margin_percentage || "",
        },
      ]);
    }
    else if (!marketplacePriceData) {
      setPosPrice(posPriceData?.selling_price || "");
      setPrices([
        {
          app_name: "pos",
          price_type: posPriceData?.price_type || "fixed",
          selling_price: posPriceData?.selling_price || "",
          margin_percentage: posPriceData?.margin_percentage || "",
        },
      ]);
      setDifferentSale(true);
      setMarketCheck(false)
    }

    // Set individual prices if you need to show them separately
    setPosPrice(posPriceData ? posPriceData.selling_price : null);
    setMarketplacePrice(
      marketplacePriceData ? marketplacePriceData.selling_price : null
    );
    setcostPrice(userData?.price);
  };

  useEffect(() => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
      },
      tokenData: auth?.payload?.token,
    };

    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        // const modifiedData = obj.payload.pos_staff.map((item) => ({
        //   value: `${item.user.user_profiles.firstname} ${item.user.user_profiles.lastname}`,
        //   id: item.id,
        // }));
        if (userData) {
          let staffIds = userData?.pos_staff?.map(
            (item) => item?.pos_staff_details_id
          );
          setSelectedPosId(
            obj?.payload?.pos_staff?.filter((value) =>
              staffIds?.includes(value?.id)
            )
          );
          setOldSelectedPosId(
            obj?.payload?.pos_staff?.filter((value) =>
              staffIds?.includes(value?.id)
            )
          );
        }
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
    // if (userData?.from === "duplicateService") {
    //   setInitialData();
    // }
    setInitialData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Edit-Services";
  }, []);

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;

    if (type === "saleSame") {
      setSaleSame(isChecked);
      if (isChecked) {
        if (differentSale) {
          setDifferentSale(false); // Ensure only one can be selected at a time
          setPosPrice(null);
          setMarketplacePrice(null);
          setPosProfit(null);
          setMarketplaceProfit(null);
          setPosMargin(null);
          setMarketplaceMargin(null);
          setPrices([
            {
              app_name: "pos",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
            {
              app_name: "b2c",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
          ]);
        }
      }
    } else if (type === "differentSale") {
      setDifferentSale(isChecked);
      if (isChecked) {
        setSaleSame(false); // Ensure only one can be selected at a time
      }
    }
    // setDiscountValue("");
    if (type === "differentSale") {
      setPosPrice(null);
      setMarketplacePrice(null);
      setPosProfit(null);
      setMarketplaceProfit(null);
      setPosMargin(null);
      setMarketplaceMargin(null);
      setPrices([
        {
          app_name: "pos",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
        {
          app_name: "b2c",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
      ]);
    }
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  // toggle
  const handletaxToggle = (event) => {
    const isChecked = event.target.checked; // Get the checkbox state
    setIsTax(isChecked); // Update local state
  };
  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        // Add option if checked
        return [...prevOptions, option];
      } else {
        // Remove option if unchecked
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };
  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];

        setAllAddress(obj?.payload);
      })
      .catch((obj) => {});
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    if (costPrice > 0) {
      // Update POS profit and margin
      if (posPrice > 0) {
        calculatePosProfitAndMargin(posPrice, costPrice);
      }

      // Update Marketplace profit and margin
      if (marketplacePrice > 0) {
        calculateMarketplaceProfitAndMargin(marketplacePrice, costPrice);
      }

      // Update margin in prices array for variants
      setPrices((prevPrices) =>
        prevPrices.map((price) => {
          const updatedMargin =
            price.selling_price > 0
              ? calculateMargin(
                  parseFloat(price.selling_price),
                  costPrice
                ).toFixed(2)
              : price.margin_percentage;

          return {
            ...price,
            margin_percentage: updatedMargin,
          };
        })
      );
    } else {
      // Reset profits and margins when costPrice is invalid
      setPosProfit("");
      setPosMargin("");
      setMarketplaceProfit("");
      setMarketplaceMargin("");

      setPrices((prevPrices) =>
        prevPrices.map((price) => ({
          ...price,
          margin_percentage: "",
        }))
      );
    }
  }, [costPrice]);

  useEffect(() => {
    setStructureVariants((prevStructureVariants) => {
      const supplies = userData?.supplies || [];

      // Create a map of existing seller_address_id for quick lookup
      const existingVariantsMap = new Map(
        prevStructureVariants.map((variant) => [
          variant.seller_address_id,
          variant,
        ])
      );

      // Create the new structure based on the latest sellerAddressIds
      const updatedStructureVariants = sellerAddressIds.map(
        (sellerAddressId) => {
          const supply = supplies.find(
            (s) => s.seller_address_id === sellerAddressId
          );
          const isNewCheck = !supply; // If supply exists, it's NOT new
          const supplyId = supply ? supply.id : null;

          // 🔹 If supply exists, get its variants; otherwise, create default variants
          const newVariants = supply
            ? supply.supply_variants.map((variant) => ({
                attribute: variant.attributes || null,
                cost_price: costPrice || "",
                prices: prices || [
                  {
                    app_name: "pos",
                    price_type: "fixed",
                    selling_price: "",
                    margin_percentage: "",
                  },
                  {
                    app_name: "b2c",
                    price_type: "fixed",
                    selling_price: "",
                    margin_percentage: "",
                  },
                ],
                image: imageDetails?.image || "",
                images: imageDetails?.images || [],
              }))
            : [
                {
                  attribute: null,
                  cost_price: costPrice || "",
                  prices: prices || [
                    {
                      app_name: "pos",
                      price_type: "fixed",
                      selling_price: "",
                      margin_percentage: "",
                    },
                    {
                      app_name: "b2c",
                      price_type: "fixed",
                      selling_price: "",
                      margin_percentage: "",
                    },
                  ],
                  image: imageDetails?.image || "",
                  images: imageDetails?.images || [],
                },
              ]; // 🔹 Ensure variants exist for new seller addresses

          return {
            seller_address_id: sellerAddressId,
            supplyId,
            isNew: isNewCheck,
            variants: newVariants, // 🔹 Now, new addresses will always have variants
          };
        }
      );

      return updatedStructureVariants;
    });
  }, [sellerAddressIds, userData, costPrice, prices, imageDetails]);

  console.log(structureVariants, "structurevraiant");

  useEffect(() => {
    setVariants([
      {
        cost_price: costPrice,
        prices: prices,
        images: imageDetails?.images,
        image: imageDetails?.image,
      },
    ]);
  }, [costPrice, imageDetails, prices]);

  const handleProfitChange = (e, platform) => {
    let newProfit = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newProfit)) {
      newProfit = 0; // Reset to 0 if invalid
    }

    newProfit = parseFloat(newProfit.toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));
    const newMargin =
      cost > 0 ? parseFloat(((newProfit / cost) * 100).toFixed(2)) : 0;

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  const handleMarginChange = (e, platform) => {
    let newMargin = parseFloat(e.target.value);
    let cost = parseFloat(costPrice) || 0; // Ensure costPrice is a number

    if (isNaN(newMargin)) {
      newMargin = 0;
    }

    newMargin = parseFloat(newMargin.toFixed(2));
    const newProfit = parseFloat(((newMargin / 100) * cost).toFixed(2));
    const newPrice = parseFloat((cost + newProfit).toFixed(2));

    if (platform === "pos") {
      setPosPrice(newPrice);
      setPosProfit(newProfit);
      setPosMargin(newMargin);
      if (!differentSale) {
        setMarketplacePrice(newPrice);
        setMarketplaceProfit(newProfit);
        setMarketplaceMargin(newMargin);
        updatePriceArray("b2c", newPrice);
      }
    } else if (platform === "b2c") {
      setMarketplacePrice(newPrice);
      setMarketplaceProfit(newProfit);
      setMarketplaceMargin(newMargin);
    }

    updatePriceArray(platform, newPrice);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setStructureVariants((prevStructureVariants) =>
        prevStructureVariants.map((product) => ({
          ...product,
          variants: product.variants.map((variant) => {
            if (!marketCheck) {
              // If marketCheck is false, remove B2C price
              return {
                ...variant,
                prices: variant.prices.filter(
                  (price) => price.app_name !== "b2c"
                ),
              };
            }

            const existingB2CPrice = variant.prices.find(
              (price) => price.app_name === "b2c"
            );

            return {
              ...variant,
              prices: existingB2CPrice
                ? variant.prices // Keep the existing B2C price
                : [
                    ...variant.prices,
                    {
                      app_name: "b2c",
                      price_type: "fixed",
                      selling_price: "", // Empty if B2C price does not exist
                      margin_percentage: "",
                    },
                  ],
            };
          }),
        }))
      );
      setPrices(
        (prevPrices) =>
          marketCheck
            ? prevPrices.some((price) => price.app_name === "b2c")
              ? prevPrices.map((price) =>
                  price.app_name === "b2c" && !differentSale
                    ? {
                        ...price,
                        selling_price:
                          prevPrices.find((p) => p.app_name === "pos")
                            ?.selling_price || "",
                        margin_percentage:
                          prevPrices.find((p) => p.app_name === "pos")
                            ?.margin_percentage || "",
                      }
                    : price
                )
              : [
                  ...prevPrices,
                  {
                    app_name: "b2c",
                    price_type: "fixed",
                    selling_price: !differentSale
                      ? prevPrices.find((p) => p.app_name === "pos")
                          ?.selling_price || ""
                      : "",
                    margin_percentage: !differentSale
                      ? prevPrices.find((p) => p.app_name === "pos")
                          ?.margin_percentage || ""
                      : "",
                  },
                ]
            : prevPrices.filter((price) => price.app_name !== "b2c") // **Ensure removal of b2c**
      );
    }, 2000);

    return () => clearTimeout(timer);
  }, [marketCheck , posPrice]); 

  const handleMarketCheckChange = () => {
    setMarketCheck((prev) => !prev);
    setMarketplacePrice(null);
  };

  return (
    <div className="addProductManually addserviceManually">
      {/* Start FixedTopHeader */}
      <EditServiceHeader
        save={(e) => handleSave(e)}
        successLoader={successLoader}
        serviceName={userData?.name}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-8 p-0">
              <div className="productRightContainer">
                {/* Start Uploaded ProductImg Area */}
                <EditServiseImageAdd
                  imageData={(e) => handleImageDetails(e)}
                  updateImageDetails={setImageDetails}
                  oldImages={imageDetails}
                />
                {/* End Uploaded ProductImg Area */}

                {/* Start ProductDetails Area */}

                <ServiceEditDetail
                  productDetails={(e, flag) => handleProductDetails(e, flag)}
                />
                {/* Start CostPrice Area */}
                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("serviceCostPrice")}
                    </h2>
                    <div className="col-md-12">
                      <div className="form_group">
                        <label className="fontSize16 fw500 txtDarkblue mb-2 ps-4">
                          {t("costOfGoodSold")}
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="Cost Price"
                            name="text"
                            type="number"
                            value={costPrice}
                            onChange={(e) => setcostPrice(e.target.value)}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* End CostPrice Area */}

                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      {t("productSalePrice")}
                    </h2>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={differentSale}
                          onChange={handleSaleToggle("differentSale")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t("useDifferentSalePrice")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price for B2B, Marketplace & POS
                          </h3>
                        </div>
                      </div>
                      {differentSale && (
                        <>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              POS {t("pricing")}
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("salePrice")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="posPrice"
                                      type="number"
                                      value={posPrice}
                                      onChange={(e) =>
                                        handlePriceChange(e, "pos")
                                      }
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("profit")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput costPriceInput"
                                      value={
                                        posProfit !== null &&
                                        posProfit !== undefined
                                          ? posProfit
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleProfitChange(e, "pos")
                                      }
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    {t("margin")}
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      // readOnly
                                      className="customInput"
                                      value={
                                        posMargin !== null &&
                                        posMargin !== undefined
                                          ? posMargin
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleMarginChange(e, "pos")
                                      }
                                      placeholder="Margin"
                                    />

                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {marketCheck && (
                            <div className="productSalePriceBox">
                              <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                                Marketplace Pricing
                              </h3>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("salePrice")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        min="1"
                                        onKeyDown={(e) => blockInvalidChar(e)}
                                        className="customInput costPriceInput"
                                        placeholder="1,000.00"
                                        name="marketplacePrice"
                                        type="number"
                                        value={marketplacePrice}
                                        onChange={(e) =>
                                          handlePriceChange(e, "b2c")
                                        }
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("profit")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput costPriceInput"
                                        value={
                                          marketplaceProfit !== null &&
                                          marketplaceProfit !== undefined
                                            ? marketplaceProfit
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleProfitChange(e, "b2c")
                                        }
                                        placeholder="Profit"
                                      />
                                      <span className="dollrsign1_">$</span>
                                      <span className="dollrsign1_ usdTxt">
                                        USD
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form_group">
                                    <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                      {t("margin")}
                                      <img
                                        src={Images.labelImg}
                                        alt="logo"
                                        className="ms-2"
                                      />
                                    </label>
                                    <div className="inputGroup">
                                      <input
                                        // readOnly
                                        className="customInput"
                                        value={
                                          marketplaceMargin !== null &&
                                          marketplaceMargin !== undefined
                                            ? marketplaceMargin
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleMarginChange(e, "b2c")
                                        }
                                        placeholder="Margin"
                                      />
                                      <span className="dollrsign1_ usdTxt">
                                        %
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      {/* <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={saleSame}
                          onChange={handleSaleToggle("saleSame")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            {t('onSale')}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t('salePriceCaption')}
                          </h3>
                        </div>
                      </div> */}
                    </div>
                    {!differentSale && (
                      <>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("salePrice")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="posPrice"
                                type="number"
                                value={posPrice}
                                onChange={(e) => handlePriceChange(e, "pos")}
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("profit")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput costPriceInput"
                                value={
                                  posProfit !== null && posProfit !== undefined
                                    ? posProfit
                                    : ""
                                }
                                onChange={(e) => handleProfitChange(e, "pos")}
                                placeholder="Profit"
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              {t("margin")}
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                // readOnly
                                className="customInput"
                                value={
                                  posMargin !== null && posMargin !== undefined
                                    ? posMargin
                                    : ""
                                }
                                placeholder="Margin"
                                onChange={(e) => handleMarginChange(e, "pos")}
                              />

                              <span className="dollrsign1_ usdTxt">%</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-12 mt-2">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                          {t("cancellationFee")}
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="1,000.00"
                            name="posPrice"
                            type="number"
                            value={cancelFee}
                            onChange={(e) => {
                              setCancelFee(e.target.value);
                            }}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                        <p className="messageTxt ps-2">{t("paymentCaption")}</p>
                      </div>
                    </div>
                  </form>
                  <div className="col-md-12">
                    {/* <div className="productDetailsContainer productSale_Parent">
                      <input
                        type="checkbox"
                        checked={isTax} // Bind to state
                        onChange={handletaxToggle} // Use the updated handler
                      />
                      <div>
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Service Tax
                          </h3>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            No Service tax setup yet!
                          </h3>
                        </div>
                      </div>
                    </div> */}

                    <div className="productDetailsContainer">
                      <div className="productSale_Parent serviceSale_Parent  mt-0 d-block">
                        <h3 className="fontSize16 fw500 txtDarkblue mb-3">
                          {t("assignedTeamMemeber")}
                        </h3>

                        {selectedPosId?.length === 0 && (
                          <div
                            className="assignedMember_Btn cursorPointer"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "assignedTeamMember",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </div>
                        )}

                        {selectedPosId?.length > 0 ? (
                          <div className="d-flex align-items-center mt-2">
                            <div className="teamMemberImgContainer">
                              {selectedPosId.slice(0, 4)?.map((val, index) => {
                                const profilePhoto =
                                  val?.pos_staff_details?.user?.user_profiles
                                    ?.profile_photo ||
                                  val?.user?.user_profiles?.profile_photo ||
                                  profileImg1;

                                return (
                                  <img
                                    key={index}
                                    className="posUserImg"
                                    src={profilePhoto}
                                    alt="pos_img"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                );
                              })}
                              {selectedPosId?.length > 4 && (
                                <div className="moreTeamMemberCount">
                                  {" "}
                                  +{selectedPosId.length - 4}
                                </div>
                              )}
                            </div>
                            <div
                              className="assignedMember_Btn addTeamMemberBtn cursorPointer mt-0 ms-2"
                              onClick={() => {
                                setModalDetail({
                                  show: true,
                                  flag: "assignedTeamMember",
                                });
                                setKey(Math.random());
                              }}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    {/* <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0 justify-content-between align-items-start">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            {t("syncGoogleCalendar")}
                          </h3>
                          <h3 className="turnOnShowTxt fw500 mb-0">
                            {t("googleCalendarTitle")}
                          </h3>
                        </div>
                        <input
                          type="checkbox"
                          checked={syncGoogleCalendar}
                          onChange={(e) => {
                            handleSyncGoogleCalendar(e);
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              {/* Start ProductAvailability Area */}
              <div className="productLeftContainer">
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    {t("productAvailability")}
                  </h3>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.physicalStoreIcon}
                        alt="logo"
                        className="physicalStoreIcon me-2"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            {t("physicalStore")}
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            JOBR POS
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="productAvailOption_Wrapper">
                      {allAddress.map((store, idx) => (
                        <div
                          key={store.id}
                          className="productAvailabilityChild mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={`store-${store.id}`}
                              checked={sellerAddressIds.includes(store.id)}
                              onChange={handleStoreToggle(store.id)} // Correct usage
                            />
                            <label htmlFor={`store-${store.id}`}></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                              Store {idx + 1}
                              {/* Store {store.id} - {store.format_address} */}
                            </h3>
                            <p className="fontSize12 txtLightBlue mb-0">
                              {store.street_address}, {store.city},{" "}
                              {store.state} {store.zipcode}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="productAvail_Second">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.jobrMarketplaceIcon}
                        alt="logo"
                        className="physicalStoreIcon me-2"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input
                            type="checkbox"
                            checked={marketCheck}
                            onChange={handleMarketCheckChange}
                          />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            JOBR Marketplace
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            {t("marketPlaceTitle")}
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* {marketCheck && (
                      <ul className="productAvailabilityList">
                        <li className="productAvailabilityChild mt-4 align-items-center">
                          <div class="form-group">
                            <input
                              type="checkbox"
                              id="reservation"
                              checked={deliveryOptions.includes("reservation")}
                              onChange={handleDeliveryOptionToggle(
                                "reservation"
                              )}
                            />
                            <label for="reservation"></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 ">
                              Online Booking
                            </h3>
                          </div>
                        </li>
                      </ul>
                    )} */}
                  </div>
                </div>

                {/* <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    Marketing options
                  </h3>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      Create coupon
                    </h3>
                    <img src={Images.upArrow} alt="logo" />
                  </div>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      Promote this service
                    </h3>
                    <img src={Images.upArrow} alt="logo" />
                  </div>
                </div> */}
                {/* <div className="chartUploadBox mb-2">
                  <h2 className="fontSize18 fw500 txtDarkblue ">
                    Service Size Chart
                  </h2>
                  <ChartImageUploader
                    chartImage={chartImage}
                    setChartImage={setChartImage}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
        ids={
          modalDetail.flag === "assignedTeamMember"
            ? "addProductOptions"
            : modalDetail.flag === "confirmModal"
            ? "confrimPriceOption"
            : ""
        }
        size={modalDetail.flag === "addStaff" ? "lg" : "md"}
        child={
          modalDetail.flag === "assignedTeamMember" ? (
            <AssignedTeamMemberModal
              posIds={selectedPosId}
              close={() => handleOnCloseModal()}
              getIdsHandle={(e) => getIdsHandle(e)}
              handleSelect={(e) => handleSelect(e)}
            />
          ) : modalDetail.flag === "confirmModal" ? (
            <ConfirmationModal
              onConfirm={handleConfirmNegativeMargin}
              close={() => handleOnCloseModal()}
            />
          ) : (
            <></>
          )
        }
        header={
          modalDetail.flag === "assignedTeamMember" ? (
            <h3 className="fontSize24 fw500 txtDarkblue mb-0">
              {t("assignedTeamMemeber")}
            </h3>
          ) : modalDetail.flag === "confirmModal" ? (
            <>
              <div className="d-flex align-items-center justify-content-center w-100">
                <img
                  src={Images?.confirmImg}
                  alt="confirm image"
                  className="img-fluid"
                />
              </div>
              <img
                src={Images.modalCross}
                alt="logo"
                className="cursorPointer confirmCancel"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : (
            <div className="modalHeader_">
              <div className="common_">
                <h2 className="modalHeading">{t("staffs")}</h2>
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default EditService;
