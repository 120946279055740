import React, { useState } from 'react'
import * as Images from "../../../../utilities/images";
import { Link } from 'react-router-dom';
import TwostepscannerModal from './twostepscannerModal';
import CustomModal from '../../../components/shared/CustomModal';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { getQrCodeAsync } from '../../systemConfig/systemConfigSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


const TwostepsecurityModal = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
     const [key, setKey] = useState(Math.random());
        const [modalDetail, setModalDetail] = useState({
            show: false,
            title: "",
            flag: "",
        });
    
        const handleOnCloseModal = () => {
            setModalDetail({
                show: false,
                title: "",
                flag: "",
            });
            setKey(Math.random());
        };
    
        const handleOpenModal = (flag) => {
            setModalDetail({
                show: true,
                flag: flag,
                title: flag,
            });
            setKey(Math.random());
        };
        const auth = useSelector(selectLoginAuth);
        const [qrCode, setQrCode] = useState(null)
        const dispatch = useDispatch()
        const getQrData = () => {
          let data = {
              tokenData: auth?.payload?.token,
          }
          setLoading(true)
          dispatch(getQrCodeAsync(data))
              .then(unwrapResult)
              .then((obj) => {
                handleOpenModal("twostepscannerModal");
                  setLoading(false)
                  setQrCode(obj?.payload ? obj?.payload : {})
              }
              )
              .catch((obj) => {
                  setLoading(false)
                  toast.error("Unable to generate Qr Code");
              })
      }
    return (
        <>
            <div className='twostepsecurityModal_'>
                <div className="enableSecurity">
                    <img className='ordersetting mb-4' src={Images.googleauthenticator} alt='pay methode' />
                    <h3 className="appointMain mb-2">{t("twostepSecurity")}</h3>
                    <p className="lightpara18_">
                    {t("downloadGoogleAuth")}{" "}
                        <Link to="#" className="applinktext">
                        {t("googlePlayStore")}
                        </Link>{" "}
                        or the iOS{" "}
                        <Link to="#" className="applinktext">
                        {t("appStore")}
                        </Link>
                        .
                    </p>
                    <div className="boxauthenticator">
                        <Link
                            to="#"
                            target="_blank" // Opens the link in a new tab/window
                            rel="noopener noreferrer" // Security best practice when using target="_blank"
                        >
                            <div className="dowlnloadathen_">
                                <img className='ordersetting' src={Images.clouddownload} alt='pay methode' />
                                <h4 className="smallblueHeading_auth">
                                {t("downloadgoogleAuthicator")}
                                </h4>
                            </div>
                        </Link>
                    </div>
                    <div
                        style={{
                            justifyContent: "space-between",
                            display: "flex",
                            marginTop: "30px",
                        }}
                        className=""
                    >
                        <button onClick={()=>{
                            props?.close();
                        }} className="getQRCode  w-100" >
                            {t("iWillDoLater")}
                        </button>

                        <button
                            className="nextverifyBtn w-100"
                            type="button" onClick={() => {
                                getQrData()
                            }}>

          {loading && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}

{t("Activateit")}
                            <img className='ordersetting ps-2' src={Images.QRcode} alt='pay methode' />
                        </button>

                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "twostepscannerModal"
                        ? "twostepscannerModal"

                        : ""
                }
                child={
                    modalDetail.flag === "twostepscannerModal" ? (
                        <TwostepscannerModal
                        closeParentModal={()=>{
                            props?.close();
                            props?.getUserPosDetails();

                        }}
                        qrCode={qrCode}
                        close={() => handleOnCloseModal()} />
                    ) :

                        (
                            <></>
                        )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default TwostepsecurityModal