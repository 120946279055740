export const chooseLanguage = [
    {
      name: "English",
      image: "https://flagcdn.com/w320/us.png",
      status: true,
      language_code: "en",
    },
    {
      name: "Russia",
      image: "https://flagcdn.com/w320/ru.png",
      status: false,
      language_code: "ru",
    },
    {
      name: "Portugal",
      image: "https://flagcdn.com/w320/pt.png",
      status: false,
      language_code: "pt",
    },
    {
      name: "Spanish",
      image: "https://flagcdn.com/w320/es.png",
      status: false,
      language_code: "es",
    },
    {
      name: "French",
      image: "https://flagcdn.com/w320/fr.png",
      status: false,
      language_code: "fr",
    },
  ];