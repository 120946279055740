import React from 'react'
import { assignDriverLogo, readyToPickLogo } from '../../../utilities/images';
import { useTranslation } from 'react-i18next';

const OrderButton = ({
    selected,
    orderData,
    trackHandler,
    isLoading,
    declineLoading,
    pickedUpPinButton,
    setPrintingUrl,
    changeStatusOfOrderHandle,
    setModalDetail,
    setKey,
    getallOrders

}) => {
  const { t } = useTranslation();
  let deliveryOption=orderData?.delivery_option;
    let orderStatus = {
        "Accepted": 1,
        "Prepared": 2,
        "AssignDriver": 3,
        "Pickedup": 4,
        "Cancelled": 7,
        "Rejected": 8,
        "Returned": 9,
    };
    const AcceptOrReject = () => {
        return <div className="reviewbotBtn_ mt-2">
            <button
                onClick={() =>
                    changeStatusOfOrderHandle(
                        orderData?.id,
                        orderStatus.Rejected
            )
                }
                className="declineBtn"
                type="submit"
                disabled={declineLoading}
            >
                {declineLoading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                <span>{t('decline')}</span>
            </button>
            <button
                onClick={() =>
                    changeStatusOfOrderHandle(
                        orderData?.id,
                        deliveryOption==4?3:  orderStatus.Accepted
                    )
                }
                className="active commonBlueBtn w-100"
                type="submit"
                disabled={isLoading}
            >
                {isLoading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                <span>{t('accept')}</span>
            </button>
        </div>
    }
    const OrderPrepared = () => {
        return <div className="reviewbotBtn_ mt-2">
            <button
                onClick={() =>
                    changeStatusOfOrderHandle(
                        orderData?.id,
                        orderStatus.Prepared
                    )
                }
                className="acceptBtn_ me-2"
                type="submit"
                disabled={isLoading}
            >
                {isLoading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                <span>{t('Order Prepared')}</span>
            </button>
        </div>
    }
    const ReadyToPickup = () => {
        return <div className="reviewbotBtn_ mt-2">
            <button
                onClick={() =>
                    changeStatusOfOrderHandle(
                        orderData?.id,
                        orderStatus.AssignDriver
                    )
                }
                className="acceptBtn_ flexBox gap-2 align-items-center justify-content-center"
                type="submit"
                disabled={isLoading}
                style={{ padding: "13px 20px" }}
            >
                {isLoading && (
                    <>
                        <span className="spinner-border spinner-border-sm"></span>
                        &nbsp;&nbsp;
                    </>
                )}
                {/* {t('assignDriver')} */}
                {"Ready to Pickup"}
                <img src={assignDriverLogo} alt="img" />
            </button>
        </div>
    }
    const OrderPickup = () => {
        return <div className="reviewbotBtn_ mt-2">
            <button
                className="acceptBtn_ flexBox justify-content-between"
                style={{
                    backgroundColor: "#914BEB",
                    padding: "13px 20px",
                }}
                type="submit"
                disabled={isLoading}
            >
                {isLoading && (
                    <>
                        <span className="spinner-border spinner-border-sm"></span>
                        &nbsp;&nbsp;
                    </>
                )}
                {(deliveryOption==3&&selected==3)?t('readyToPickup'):t('assignDriver')}
                <img src={assignDriverLogo} alt="img" />
            </button>
        </div>
    }
    const Cancelled = () => { 
        return <div className="reviewbotBtn_ mt-2">
        <button
          className="commonbtndesign_ cancelbtn_"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>
            {orderData?.is_returned_order
              ? t("refunded")
              : t("cancelled")}
          </span>
        </button>
      </div>
    }
    const Rejct = () => { 
        return  <div className="reviewbotBtn_ mt-2">
        <button
          className="commonbtndesign_ cancelbtn_"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>{t('rejected')}</span>
        </button>
      </div>
    }
    const Returned = () => {
        return  <div className="reviewbotBtn_ mt-2">
        <button
          className="acceptBtn_ me-2"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>{t('returned')}</span>
        </button>
      </div>
     }
     const InProgress=()=>{
        return  <div className="reviewbotBtn_ mt-2">
        <button
          className="acceptBtn_ me-2"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>{t('inProgress')}</span>
        </button>
      </div>
     }
     const Completed=()=>{
        return  <div className="reviewbotBtn_ mt-2">
        <button
          className="acceptBtn_ me-2"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>{t('completed')}</span>
        </button>
      </div>
     }
     const PrintLabel=()=>{
        return  <div className="reviewbotBtn_ mt-2">
        <button
          onClick={() => {
            setPrintingUrl(orderData?.label_url);
            setModalDetail({
              show: true,
              flag: "printLabel",
            });
            setKey(Math.random());
            changeStatusOfOrderHandle(
              orderData?.id,
              orderStatus.Pickedup
            );
            if(getallOrders){
              getallOrders();
            }
          }}
          className="acceptBtn_ me-2"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>{t('printLabel')}</span>
        </button>
      </div>
     }
     const Shipped=()=>{
        return <div className="reviewbotBtn_ mt-2">
             <button
          onClick={() => {
            setPrintingUrl(orderData?.label_url);
            setModalDetail({
              show: true,
              flag: "printLabel",
            });
            setKey(Math.random());
          }}
          className="acceptBtn_ me-2"
          type="submit"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>{t('printLabel')}</span>
        </button>
        <button
          className="acceptBtn_ me-2"
          type="submit"
          onClick={() =>
            window.open(
              orderData?.tracking_url,
              "_blank"
            )
          }
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          <span>{"Track"}</span>
        </button>
      </div>
     }
     const ReadyForPickupforWalIn=()=>{
      return <div className="reviewbotBtn_ mt-2">
      <button
        onClick={() => {
          setModalDetail({
            show: true,
            flag: "verifyOtp",
          });
          setKey(Math.random());
        }}
        className="acceptBtn_ me-2"
        type="submit"
        disabled={isLoading}
      >
        {isLoading && (
          <span className="spinner-border spinner-border-sm"></span>
        )}
        &nbsp;&nbsp;
        <span>
          {/* {t('readyToPickup')} */}
          Order PickedUp
          </span>
      </button>
    </div>
     }

     if((orderData?.order_delivery &&
        orderData?.delivery_option == "1" &&
        orderData?.order_delivery?.has_returned) ||
        orderData?.is_returned_order)
        return Returned();
    else if((deliveryOption == 3 &&
      selected === 2)||((deliveryOption==1)&&selected==3) )return ReadyForPickupforWalIn()
      else if(deliveryOption == 3 &&
        selected === 3 )return Completed()
    else if((orderData?.delivery_option == 2 &&
        orderData?.status === 2) ||
        (orderData?.delivery_option == 2 &&
          orderData?.status === 3) ||
        (orderData?.delivery_option == 2 &&
          orderData?.status === 4))
        return InProgress();
    else if(orderData?.delivery_option == 4 &&
        (orderData?.status === 3 ||(orderData?.status==1||orderData?.status==2)))
        return PrintLabel();
    else if(orderData?.delivery_option == 4 &&
        orderData?.status === 4 )
        return Shipped();
    switch (selected) {
        case 0: return AcceptOrReject();
        case 1: return OrderPrepared();
        case 2: return ReadyToPickup();
        case 3: return OrderPickup();
        case 5: return Completed();
        case 7: return Cancelled();
        case 8: return Rejct();
        case 9: return Returned();
    }
}

export default OrderButton