import React, { useState } from 'react'
import * as Images from "../../../../utilities/images";
import { weekOptions } from '../../../constants/moreSettings/moreSettings.constansts';

const WorkOptions = ({options}) => {
    const [weeks, setweeks] = useState(weekOptions)
    const [showHours, setshowHours] = useState(false);
    const handleToggle = () => {
        setshowHours(!showHours);
    }
    return <div className="businessHourParent">
        <div className="d-flex align-items-center justify-content-between w-100">
            <h3 className="appoinMain fontSize12 fw500 txtDarkblue mb-0">Business Hours</h3>
            <button onClick={handleToggle} className="upDownBtn">
              <img src={
                showHours
                 ? Images.accordionUpArrow
                  : Images.accordionDownArrow
              } alt="" />
            </button>
        </div>
        {
            Array.isArray(options)&&<div className="d-flex flex-column gap-1  w-100">
            {
                options.slice(0,(showHours?options.length:1))?.map((option,index)=>(
            <div key={index} className="d-flex align-items-center justify-content-between">
              <p className='d-flex gap-1 '>
                <img src={Images.busLocTimeImg} alt="" />
                <span className='fontSize12 fw500 uncheckTxt'>{weeks?.[Math.max(Number(option?.day||0)-1,0)]?.title}</span>
              </p>
              <p>
                <span className='fontSize12 fw500 uncheckTxt pe-4'>
                    {
                        option?.opening_time+" - "+                    
                        option?.closing_time
                        
                    }
                </span>
                <span>

                </span>
              </p>
            </div>

                ))
            }
        </div>
        }
    </div>
}

export default WorkOptions