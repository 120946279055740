import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import { useDispatch, useSelector } from "react-redux";
import {
  getMerchantStepStatusAsync,
  selectMerchantStatus,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";

const Onboarding = (props) => {
  const history = useHistory();
  const merchantStatus = useSelector(selectMerchantStatus);
  const planActive = merchantStatus?.payload?.subscription_status;
  const productAdded = merchantStatus?.payload?.product_status;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);

  const getMerchantStepStatus = () => {
    setLoading(true);
    dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMerchantStepStatus();
  }, []);

  useEffect(() => {
    if(planActive && productAdded) {
      history.push("/wallet")
    }
  }, [planActive, productAdded]);

  if (loading) return "";

  return (
    <>
      <div className="productPluginSection productAddingOption_Section newUserScreenParent d-flex align-items-center justify-content-center">
        <button
          className="createOfferCloseBtn previousPageBtn backCloseBtn  d-flex gap-2"
          onClick={() => {
            // if (!productAdded || !planActive) {
            //   if (!productAdded) {
            //     toast.error("Please add product");
            //   } else {
            //     toast.error("Please choose a plan");
            //   }
            // } else {
            //   history.push("/wallet");
            // }
            localStorage.clear()
            history.push("/")
          }}
        >
          <img src={Images.modalCross} alt="logo" className="" />
        </button>

        <div className="productPluginsSection newWelcomePage text-center w-100">
          <div className="">
            <div className="row pb-3">
              <div className="col-12">
                <h1 className="fontSize36 fw400 txtBlue">
                  Welcome to JOBR, Your new{" "}
                  <span className="fw700">
                    Business <br /> Solutions Platform
                  </span>
                </h1>
                <p className="fontSize20 txtBlue">
                  To get the best experience, we recommend setting up following
                  steps. This is necessary!
                </p>
              </div>
            </div>
            <div className="optionBoxesParent">
              <div className="row justify-content-center">
                <div className="col-lg-10 d-flex justify-content-end p-0">
                  <Accordion className="commonAccordion w-100" flush>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100 accordionBoxParent">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.lightCalenderLogo} alt="jobbr" />
                            </figure>
                            <div>
                              <h3 className="accordionItemHead">
                                Choose Your Plan
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn">
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button
                              onClick={() =>
                                planActive ? "" : history.push("/plans")
                              }
                              className={`commonBlueBtn  gap-2 ${
                                planActive ? "" : "active"
                              }`}
                            >
                              {planActive ? "Added" : "Choose Plan"}
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100 accordionBoxParent">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.addProductServiceLogo}  alt="jobbr" width={32} height={33}/>
                            </figure>
                            <div>
                              <h3 className="accordionItemHead">
                                Add Products or Services
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn">
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button
                            onClick={() => planActive ? !productAdded ?  history.push("/addingproduct-option") : "" : ""}
                            className={`commonBlueBtn gap-2 ${productAdded ? "" : "active"}`}>
                              {productAdded ? "Added" : "Add Product/ Service"}
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.deliverLogo} alt="driver"/>
                            </figure>
                            <div>
                              <h3 className="accordionItemHead">
                                Setup Delivery, Shipping, Pickup
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn">
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button className="commonBlueBtn gap-2">
                              Configure Now
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item> */}
                    {/* <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.walletLogo} alt="jobbr"/>
                            </figure>
                            <div>
                              <h3 className="accordionItemHead">
                                Configure JBR Business Wallet
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn">
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button className="commonBlueBtn gap-2">
                              Configure Now
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </div>
              </div>
            </div>
            <Link to="/support" className="commonBorderBtn helpCenterBtn gap-2">
              Help Center <img src={Images.upArrow} alt="img" />
            </Link>
            <p className="allRightTxt mb-0">
              All Rights Reserved by JOBR LLC | 2022
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
