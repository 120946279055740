import React from 'react'
import { useTranslation } from 'react-i18next';

const RoleDetailsModal = (props) => {
    const { t } = useTranslation();
    return (
        <>
                <form className='horizontalForms'>
                    <div className='row'>
                        <div className='form-group col-md-6  mb-4'>
                            <label className='labelTxt mb-2 fw500 txtDarkblue fontSize18' htmlFor='amount'>{t('roleName')}:</label>
                            <p className='fontSize16 txtLightBlue'>{props?.selectedProduct?.name}</p>
                        </div>
                        <div className='form-group col-md-6 mb-4'>
                            <label className='labelTxt mb-2 fw500 txtDarkblue fontSize18' htmlFor='amount'>{t('selectPrivilege')}:</label>
                            <span className='txtLightBlue fontSize16'>{props?.selectedProduct?.role_privileges?.map((v, i) => {
                                return (
                                    <React.Fragment key={i}>
                                    {v?.privileges?.name + (i === props?.selectedProduct?.role_privileges?.length - 1 ? "" : ",")}
                                    </React.Fragment>
                                )
                            })}</span>
                        </div>
                        <div className='form-group col-md-12 mb-4'>
                            <label className='labelTxt mb-2 fw500 txtDarkblue fontSize18' htmlFor='amount'>{t('description')}:</label>
                            <p className='txtLightBlue fontSize16'>{props?.selectedProduct?.description}</p>
                        </div>

                    </div>
                </form>
        </>
    )
}

export default RoleDetailsModal
