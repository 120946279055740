import React, { forwardRef, useEffect, useRef, useState } from "react";
import * as Images from "../../../../../utilities/images";
import { Link } from "@mui/material";
import { Link as Href } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Dropdown } from "react-bootstrap";
import moment from "moment-timezone";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsAsync } from "../../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth, selectUserSettings } from "../../../auth/authSlice";
import {
  addPadStart,
  getDateTimeStampConvertor,
  getFormatedDate,
} from "../../../../../utilities/helpers";
import { toast } from "react-toastify";
import ".././AllOrders.css";
import DeliveryOptions from "../../../../components/UI/Orders/DeliveryOptions";
import DeliveryStatus from "../../../../components/UI/Orders/DeliveryStatus";
import DatePickerCustomComponent from "../../../../components/UI/Orders/DatePickerCustomComponent";
import SelectMenu from "../../../../components/UI/Orders/SelectMenu";
import { filterOrderDetails } from "./helper";
import {
  AllOrders,
  AllPayments,
  AllStatus,
  OrderColumns,
  filterOptions,
} from "../../../../constants/orders/orders";
import { TimePeriodNav } from "../../../../constants/timeperiod.constant";
import { resultSelectOption } from "../../../../constants/common.constant";
import NotificationButton from "../../../../components/NotificationButton";
import { BookingColumns } from "../../../../constants/bookings/allbooking";
import { useTranslation } from "react-i18next";
import { addMoreColumnsSettings, selectPagesColumnData } from "../../../../pagesColumnReducer";

const AllOrder = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //user authnetication
  const auth = useSelector(selectLoginAuth);
  const userSetting = useSelector(selectUserSettings);
  const pageColumn=useSelector(selectPagesColumnData);
  const toastId = useRef(null);

  //ordersDetails
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState(0);
  const [orderType, setOrderType] = useState("");
  const [totalOrders, settotalOrders] = useState(0);
  const [statusType, setStatusType] = useState("");
  const [paymentType, setPaymentType] = useState("all");
  const [totalPages, setTotalPages] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [filterOptions, setfilterOptions] = useState( [
    {
        title: "All Orders",
        data: AllOrders
    },
    {
        title: "All Status",
        data: AllStatus
    },
    {
        title: "All Payment",
        data: AllPayments
    },
])
  //load orders
  const [loading, setLoading] = useState(false);
  //use dispatch
  const disPatch = useDispatch();
  const [currentPageOrders, setcurrentPageOrders] = useState([]);
  const [filterValue, setFilterValue] = useState("week");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [searchKeyword, setsearchKeyword] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [isSearching, setisSearching] = useState(false);

  let shippingOptions=[
    { value: "", label: "All Status",
      languageText:"allStatus"
   },
  {value:"0",label:"Order to Review",
      languageText:"orderToReview"
  },
//   {value:"1",label:"Accepted",
//     languageText:"accepted"
// },
{value:"3",label:"Printing Label",
  languageText:"Printing Label"
},
{value:"4",label:"Shipped",
  languageText:"Shipped"
},
{value:"5",label:"Delieverd",
  languageText:"delivered"
},
{value:"7",label:"Cancelled",
  languageText:"cancelled"
},
{value:"8",label:"Rejected",
  languageText:"rejected"
},
{value:"9",label:"Returned",
  languageText:"returned"
},
  ];
  let deliveryOptions=[
    { value: "", label: "All Status",
      languageText:"allStatus"
   },
  {value:"0",label:"Order to Review",
      languageText:"orderToReview"
  },
  {value:"1",label:"Accepted",
      languageText:"accepted"
  },
  {value:"2",label:"Order Prepared",
      languageText:"prepairing"
  },
  {value:"3",label:"Assign Driver",
      languageText:"readyForPickup"
  },
  {value:"4",label:"Picked Up",
      languageText:"pickUp"
  },
  {value:"5",label:"Delieverd",
      languageText:"delivered"
  },
  // {value:"6",label:"Pickup by Customer",
  //     languageText:"pickupByCustomer"
  // },
  {value:"7",label:"Cancelled",
      languageText:"cancelled"
  },
  {value:"8",label:"Rejected",
      languageText:"rejected"
  },
  {value:"9",label:"Returned",
      languageText:"returned"
  },
  ];
  let pickupOptions=[
    { value: "", label: "All Status",
      languageText:"allStatus"
   },
  {value:"0",label:"Order to Review",
      languageText:"orderToReview"
  },
  {value:"1",label:"Accepted",
      languageText:"accepted"
  },
  {value:"2",label:"Order Prepared",
      languageText:"prepairing"
  },
  // {value:"3",label:"Assign Driver",
  //     languageText:"readyForPickup"
  // },
  // {value:"4",label:"Picked Up",
  //     languageText:"pickUp"
  // },
  {value:"5",label:"Delieverd",
      languageText:"delivered"
  },
  // {value:"6",label:"Pickup by Customer",
  //     languageText:"pickupByCustomer"
  // },
  {value:"7",label:"Cancelled",
      languageText:"cancelled"
  },
  {value:"8",label:"Rejected",
      languageText:"rejected"
  },
  {value:"9",label:"Returned",
      languageText:"returned"
  },
  ]
  //columns name
  const [columnsNames, setColumnNames] = useState(JSON.parse(JSON.stringify(
    pageColumn?.orders|| OrderColumns)));
  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      if (prev + value <= totalPages && prev + value >= 1) return prev + value;
      return prev;
    });
  };
  const handleCurrentPageData = (value) => {
    setCurrentPageData((prev) => {
      if (prev + value <= Math.ceil(selectPageSize / 10) && prev + value >= 1)
        return prev + value;
      return prev;
    });
  };
  const resetFilters = () => {
    setOrderDetails(null);
    setcurrentPageOrders([]);
    setCurrentPageData(0);
  };
  //get order details
  const getAllOrderDetails = async () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        ...(endDate && {
          start_date:
            (startDate &&
              getFormatedDate(startDate, "YYYY-MM-DD", undefined)) ||
            undefined,
          end_date:
            (endDate && getFormatedDate(endDate, "YYYY-MM-DD", undefined)) ||
            undefined,
        }),
        order_type: "product",
        need_returned:false,
        page: currentPage,
        ...(endDate == null && { filter_by: filterValue }),
        ...(statusType !== "" && { status: statusType }),
        ...(orderType !== "" && { delivery_option: orderType }),
        transaction_type: paymentType,
        limit: selectPageSize,
        ...(searchKeyword.length > 0 && {
          search: searchKeyword,
        }),
      },
      tokenData: auth?.payload?.token,
    };
    resetFilters();
    setLoading(true);
    disPatch(getOrderDetailsAsync(params))
      .then(unwrapResult)
      .then((res) => {
        //convert payload according to our need
        const filter = filterOrderDetails(
          res?.payload?.data,
          getDateTimeStampConvertor
        );
        setOrderDetails(filter || []);
        settotalOrders(res?.payload?.total);
        if (filter.length > 0) {
          // setSelectPageSize(res?.payload?.per_page);
        }
        if (filter.length > 0) setCurrentPageData(1);

        setTotalPages(res?.payload?.total_pages || 1);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleCurrentPageOrderData = (orderDetails) => {
    // const startIndex = (currentPageData - 1) * 10;
    // const endIndex = startIndex + 10;
    // const currentPageOrders = orderDetails.slice(startIndex, endIndex>orderDetails.length ?orderDetails.length : endIndex );
    // console.log(currentPageOrders)
    setcurrentPageOrders(orderDetails);
  };
  useEffect(() => {
    if (currentPageData >= 1) handleCurrentPageOrderData(orderDetails);
  }, [currentPageData]);

  const handleFilterOptions = (value, key) => {
    if (key == "All Orders") {
      let filterDetails=filterOptions;
      if(value=='4'){
        filterDetails[1].data=shippingOptions;
      }else if(value==""){
        filterDetails[1].data=AllStatus;
      }else if(value==1){
        filterDetails[1].data=deliveryOptions;
        
      }else{
        filterDetails[1].data=pickupOptions;
      }
      setStatusType("");
      setfilterOptions([...filterDetails])
      setOrderType(value);
    } else if (key == "All Status") {
      

      setStatusType(value);
}    else setPaymentType(value);
  };
  //load orders
  useEffect(() => {
    getAllOrderDetails();
  }, [
    filterValue,
    endDate,
    selectPageSize,
    paymentType,
    orderType,
    statusType,
    currentPage,
  ]);
  // Download selected data as CSV
  // Download selected data as CSV
  const downloadCSV = () => {
    if (!Array.isArray(currentPageOrders) || currentPageOrders?.length === 0) {
      showToast("No data available to download.");
      return;
    }

    // Prepare CSV headers and
    const headers = [
      "id",
      "Name",
      "profile_img",
      "address",
      "time",
      "order_id",
      "invoice_number",
      "delivery_option",
      "payment_method",
      "status",
      "total_amount",
      "location",
      "tips",
      "taxes",
    ];

    const rows = currentPageOrders.map((product) => [
      product?.id,
      product?.customerProfile?.name || "",
      product?.profileImg || "",
      product?.customerProfile?.address || "",
      product?.timedetails?.fullDate ||
        "" + "," + product?.timedetails?.time ||
        "",
      product?.orderId || "",
      product?.invoiceNumber || "",
      product?.delivery || "",
      product?.paymentMethod || "",
      product?.status || "",
      product?.totalAmount || "",
      product?.location || "",
      product?.tips || "",
      product?.taxes || "",
    ]);

    // Convert to CSV string
    const csvContent = [
      headers.join(","), // Add headers
      ...rows.map((row) => row.map((value) => `"${value}"`).join(",")), // Add rows
    ].join("\n");

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "selected_product_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  // search
  // search
  useEffect(() => {
    if (!isSearching) return;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    let searchTimer = setTimeout(() => {
      setisSearching(false);
      getAllOrderDetails();
    }, 1000);
    setsearchTimeout(searchTimer);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKeyword]);
  return (
    <div className="walletStats h-100 allOrderSection">
      <div className="productOfferHead_Container">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fontSize24 txtDarkblue fw500 mb-0">
            {t('allOrders')} <span className="countLight_">(
              {
              // totalOrders? String(totalOrders).padStart(2, '0'):totalOrders
              addPadStart(totalOrders??0)
              }
              )</span>
          </h3>
          <div className="d-flex align-items-center gap-xxl-4 gap-3">
            <ReactDatePicker
              onChange={(dates) => {
                const [start, end] = dates;
                if (start == end) {
                  setStartDate(null);
                  setEndDate(null);
                  return;
                }
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              customInput={<DatePickerCustomComponent startDate={startDate} />}
            />
            <ul className="weekNav">
              {TimePeriodNav.map((data, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setEndDate(null);
                    setStartDate(null);
                    setCurrentPage(1);
                    setFilterValue(data.value);
                  }}
                >
                  <Link
                    className={`weekMenu ${
                      filterValue == data.value ? "active" : ""
                    }`}
                    to="#"
                  >
                    {t(data.languageLabel)}
                  </Link>
                </li>
              ))}
            </ul>
            <NotificationButton />
            {/* search */}
            <div className="input-group search_group">
              <img
                src={Images.SearchIcon}
                alt=""
                className="img-fluid searchIcon"
              />
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setisSearching(true);
                  setsearchKeyword(e.target.value);
                }}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
            </div>
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <div className="col-md-6">
            <div className="d-flex align-items-center gapDefault_">
              {filterOptions.map((filter, index) => (
                <div className="reactSelectParent offerHeaderSelect gapDiffer_">
                  <SelectMenu
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "156px",
                        maxWidth : "156px !important"
                      }),
                    }}
                    value={filter.data.filter(data=>{
                      let comp=filter.title=="All Orders"?orderType:filter.title=="All Status"?statusType:paymentType;
                      return comp==data.value;
                    })}
                    options={filter.data}
                    placeholder={t(filter.languageLabel)}
                    onChange={(event) => {
                      handleFilterOptions(event.value, filter.title);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end gap-3">
              <div className="d-flex align-items-center gap-2">
                <h3 className="fontSize14 txtDarkblue mb-0">{t('showingResult')}</h3>
                <div className="reactSelectParent offerHeaderSelect">
                  <SelectMenu
                    options={resultSelectOption}
                    placeholder="10"
                    onChange={(e) => {
                      setCurrentPage(1);
                      setSelectPageSize(e.value);
                    }}
                  />
                </div>
              </div>
              {currentPageOrders.length > 0 && (
                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 && "disablePrevbtn"
                      } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleLeft}
                        alt="arrowDoubleRight"
                      />
                    </div>
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 && "disablePrevbtn"
                      } `}
                      onClick={() => {
                        if (currentPage <= 1) return;
                        handleCurrentPage(-1);
                      }}
                    >
                      <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0">
                    {(currentPage - 1) * selectPageSize + 1}-
                    {currentPage * selectPageSize > totalOrders
                      ? totalOrders
                      : currentPage * selectPageSize}{" "}
                    of {totalOrders}
                  </h6>
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages && "disableNextbtn"
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                    </div>
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages && "disableNextbtn"
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}
                    >
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="commonTable sellingProductTable table-responsive mt-3">
        <table className="w-100 allorderTable_">
          <thead>
            <tr>
              {/* 
                                                
                            checkbox for order
                            <th onClick={() => {
                                if (!orderDetails || orderDetails?.length === 0) return;
                                let isChecked = !isAllChecked;
                                setIsAllChecked(isChecked);
                                const updatedOrderDetails = [...orderDetails].map(order => {
                                    order.isChecked = isChecked;
                                    return order;
                                });
                                setOrderDetails(updatedOrderDetails);
                            }}>
                                <div className="productAvailabilityChild align-items-center">
                                    <div className="form-group">
                                        <input type="checkbox" checked={isAllChecked} id="check1" />
                                        <label htmlFor="check2" className="me-0"></label>
                                    </div>

                                </div>
                            </th> */}
              <th>#</th>
              {columnsNames.map((column, index) => (
                <th key={index} className={!column.isVisible && "d-none"}>
                  {t(column.languageText)}
                </th>
              ))}

              <th>
                <div className="d-flex align-items-center gap-1 justify-content-end">
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadCSV();
                    }}
                    src={Images.downloadOrder_}
                    alt="icon"
                  />
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.plusIconWithBg} alt="icon" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                        <div className="resetText_" onClick={(e) => {
                              setColumnNames(JSON.parse(JSON.stringify(OrderColumns)))
                              disPatch(addMoreColumnsSettings({
                                key:"orders",
                                data:OrderColumns

                              }))
                            }}>
                            {t('reset')}
                          </div>
                        {columnsNames.map((column, index) => (
                          <Dropdown.Item
                            onClick={(e) => {
                              e.stopPropagation(); 
                              if(column?.isDefault)return;
                              try {
                                let updatedColumns = [...columnsNames];
                                let updatedColumn = { ...updatedColumns[index], isVisible: !column.isVisible };
                            // Update the column in the array
                            updatedColumns[index] = updatedColumn;
                              disPatch(addMoreColumnsSettings({
                                key:"orders",
                                data:updatedColumns

                              }))
                              setColumnNames([...updatedColumns]);
                              } catch (error) {
                                
                              }
                            }}
                          >
                            <div className="productAvailabilityChild align-items-center">
                              <div className={` ${column.isDefault?'disable_form':''} form-group`}>
                                <input
                                  type="checkbox"
                                  checked={column.isVisible}
                                  disabled={column.isDefault}
                                />
                                <label
                                  htmlFor="check3"
                                  className="me-0"
                                  style={{color:"#D7DEFF"}}
                                ></label>
                              </div>
                              <h3 className="fontSize14 mb-0 mt-1">
                              {t(column.languageText)}
                              </h3>
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {/* <img src={Images.moreHorizontalLogo} alt="icon" /> */}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="text-center border-none">
                {/* Full-width spinner row */}
                <td colSpan="100%">
                  <div className="d-flex">
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                  </div>
                </td>
              </tr>
            ) : currentPageOrders.length > 0 ? (
              currentPageOrders?.map((orderDetail, index) => (
                <tr key={orderDetail.id}>
                  {/* 
                                        Checkbox for order
                                        <td>
                                            <div className="productAvailabilityChild align-items-center">
                                                <div onClick={() => {

                                                    const updatedOrderDetails = [...orderDetails];
                                                    updatedOrderDetails[index].isChecked = !orderDetail.isChecked;
                                                    console.log(updatedOrderDetails, index);
                                                    setOrderDetails(updatedOrderDetails);
                                                }} className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        checked={orderDetail.isChecked}

                                                    />
                                                    <label htmlFor="check2" className="me-0"></label>
                                                </div>
                                            </div>
                                        </td> */}
                  <td>{(currentPage - 1) * selectPageSize + index + 1}</td>
                  {/* customer profile */}
                  <td className={`${!columnsNames[0].isVisible && "d-none"}`}>
                    <div className="d-flex align-items-center gap-3 ">
                      <div className="sidebarProfileImg" style={{width:"40px",height:"40px",minHeight:"40px"}}>
                      <img
                        src={orderDetail?.profileImg || Images.userImg_}
                        alt="img"
                        className="img-fluid"
                      />
                      </div>
                      <div>
                        <h3 className="darkblack mb-0 pb-1 text-start">
                          {orderDetail?.customerProfile?.name||''}
                        </h3>
                        {orderDetail?.customerProfile?.address && (
                          <div className="orederLoc_ d-flex align-items-center gap-2">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            <h6
                              className=" fontSize14 fw500 mb-0 text-truncate"
                              style={{ maxWidth: "150px" }}
                            >
                              {orderDetail?.customerProfile?.address}
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  {/* time */}
                  <td
                    className={`fontSize12 darkblack ${
                      !columnsNames[1].isVisible && "d-none"
                    }`}
                  >
                    {orderDetail?.timedetails?.fullDate}
                    <br />
                    <span className="orderclock_">
                      <img src={Images.Clockorder} alt="img" className="" />
                      <span>{orderDetail?.timedetails?.time}</span>
                    </span>
                  </td>
                  {/* invoice */}
                  <td
                    className={`fontSize12 darkblack ${
                      !columnsNames[2].isVisible && "d-none"
                    }`}
                  >
                    # {orderDetail?.invoiceNumber || orderDetail?.return_invoice}
                  </td>
                  {/* Delivery */}
                  <td
                    className={`fontSize12 ${
                      orderDetail?.delivery ? "txtLightBlue" : "darkblack"
                    } ${!columnsNames[3].isVisible && "d-none"}`}
                  >
                    <DeliveryOptions deliveryOption={orderDetail.delivery} />
                  </td>
                  {/* Payment */}
                  <td
                    className={`fontSize12 darkblack ${
                      !columnsNames[4].isVisible && "d-none"
                    }`}
                  >
                    {" "}
                    {orderDetail?.paymentMethod}
                  </td>

                  {/* totalAmount */}
                  <td className={`${!columnsNames[5].isVisible && "d-none"}`}>
                    <div className="deliverTableBx">
                      <img
                        src={Images.moneyImg}
                        alt="location Image"
                        className="img-fluid ms-1"
                      />
                      <span className="locateDistance">
                       $ {orderDetail?.totalAmount}
                      </span>
                    </div>
                  </td>
                  {/* Status */}
                  <td
                    className={`fontSize12 darkblack ${
                      !columnsNames[6].isVisible && "d-none"
                    }`}
                  >
                    <DeliveryStatus statusOption={orderDetail.status} 
                    delivery={orderDetail?.delivery}
                    />
                  </td>
                  {/* Location */}
                  <td className={`${!columnsNames[7].isVisible && "d-none"}`}>
                    <span style={{maxWidth:"150px"}} className="text-truncate d-flex">{orderDetail.location}</span>
                  </td>
                  {/* Tips */}
                  <td className={`${!columnsNames[8].isVisible && "d-none"}`}>
                    <span>${" "}{orderDetail.tips}</span>
                  </td>
                  {/* taxes */}
                  <td className={`${!columnsNames[9].isVisible && "d-none"}`}>
                    <span>${" "}{orderDetail.taxes}</span>
                  </td>
                  <td className="text-center">
                    <Href to={orderDetail?.orderId?`/orderReview/${orderDetail.orderId}`:"#"}>
                      <img src={Images.arrow_next} alt="logo" />
                    </Href>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center border-none">
                {/* Full-width spinner row */}
                <td colSpan="100%">
                  <div className="d-flex">
                    <p className="text-center w-100">{t('noDataFound')}</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllOrder;
