import React, { useEffect, useState } from 'react'
import {
    viewEye_, calendar, totalOrder, volume, analysis, orderFrequency,
    totalsale,
    totalordericon,
    totalOrderFrequency,
    totalvolumeicon,
    averageOrderValue
} from '../../../utilities/images';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import products from '../../db/category.json'
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { getAnalyticOrdersApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsHeader from './AnalyticsHeader';
import PaginationHeader from './PaginationHeader';
import { addThousandSeparator } from '../../../utilities/helpers';
import { useTranslation } from 'react-i18next';

const TotalDeliveryOrders = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const dispatch = useDispatch();
    const today = new Date();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [analyticsOrderData, setAnalyticsOrderData] = useState("")
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [calanderDate,setCalanderDate] = useState(new Date())

    const handelPerPageResult = (e) => {
        setPerPage(e?.value)
    }

    const orderAnalyticsHandle = () => {
        let params = {
            "postData": {
                page: currentPage,
                limit: perPage,
                filter: filterValue,
                channel: channelFilter,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAnalyticOrdersApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAnalyticsOrderData(obj?.payload)
                setLoading(false)
                setTotalRows(obj?.payload?.pos_graph?.ordersListData?.length || 0)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        orderAnalyticsHandle()
    }, [filterValue, channelFilter, endDate])

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Analytics";
    }, []);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <>
            <DashboardNavbar title={t('analytics')} backShow="/analytics" />
            <div className='analyticGross chartsOuter'>
                <AnalyticsHeader
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onTimeSpanSelect={setFilterValue}
                    calanderDate={calanderDate}
                    setCalanderDate={setCalanderDate}
                    filterValue={filterValue}
                    setChannelFilter={setChannelFilter}
                    channelFilter={channelFilter}
                    subhead="vs. Same day last week"
                    onDateChange={handleDateChange}
                    timeSpan={filterValue}
                    header={t('totalDeliveryOrders')}
                />
                <div className='grossProfitData'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6  pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalordericon} className='img-fluid grossImages' alt='totalorder' />
                                <p className='groSub mt-4 mb-0'>{t('totalOrders')}</p>
                                <h4 className='groMain'>{addThousandSeparator(analyticsOrderData?.delivery_graph?.ordersOverView?.total_orders) || 0}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalOrderFrequency} className='img-fluid grossImages' alt='orderFrequency' />
                                <p className='groSub mt-4 mb-0'>{t('orderFrequency')}</p>
                                <h4 className='groMain'>{addThousandSeparator(analyticsOrderData?.delivery_graph?.ordersOverView?.order_frequency) || 0}/Hour</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={averageOrderValue} className='img-fluid grossImages' alt='analysis' />
                                <p className='groSub mt-4 mb-0'>{t('averageOrderValue')}</p>
                                <h4 className='groMain'>{addThousandSeparator((analyticsOrderData?.delivery_graph?.ordersOverView?.averageValue || analyticsOrderData?.delivery_graph?.ordersOverView?.averageValue == 0) ? `$${(analyticsOrderData?.delivery_graph?.ordersOverView?.averageValue).toFixed(2)}` : "")}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 '>
                            <div className='grossTotalorder'>
                                <img src={totalvolumeicon} className='img-fluid grossImages' alt='totalsale' />
                                <p className='groSub mt-4 mb-0'>{t('totalRevenue')}</p>
                                <h4 className='groMain'>${addThousandSeparator(analyticsOrderData?.delivery_graph?.ordersOverView?.total_sales_or_actual_amount?.toFixed(2))}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <PaginationHeader
                    perPage={perPage}
                    handelPerPageResult={handelPerPageResult}
                    currentPage={currentPage}
                    totalPage={totalRows}
                    setCurrentPage={setCurrentPage}
                    totalLength={analyticsOrderData?.delivery_graph?.ordersListData.length}
                />
                {
                    loading ? (
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                    ) : (
                        <div className="commonTable productTable table-responsive mt-3">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('date')}</th>
                                        <th>{t('totalDeliveryOrder')}</th>
                                        <th>{t('averageOrderValue')}</th>
                                        <th>{t('orderFrequency')}</th>
                                        <th>{t('totalRevenue')}</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {analyticsOrderData?.delivery_graph?.ordersListData?.length > 0 && (
                                        analyticsOrderData?.delivery_graph?.ordersListData.map(
                                            (row, idx) => (
                                                <tr>
                                                    <td className="fontSize12 txtLightBlue">
                                                    {currentPage > 1 ? (
                                                        (currentPage - 1) * perPage + idx + 1
                                                        ) : idx + 1}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {moment(row?.order_date).format("MM/DD/YYYY")}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.count}
                                                    </td>
                                            
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.averageValue).toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.order_frequency} Per Hour
                                                    </td>
                                                 
                                                    <td className="fontSize12 grosshead_">
                                                        <b>${addThousandSeparator(row?.amount?.toFixed(2))}</b>
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        <button onClick={
                                                            () =>
                                                                history.push({
                                                                    pathname: `/transections`,
                                                                    state: {
                                                                        "date": moment(`${row?.order_date}T00:00:00`)?.toDate(),
                                                                        "from": "analytics",
                                                                        "deliveryOption": 3
                                                                    }
                                                                })} 
                                                                className="secondaryOuterbtn_" 
                                                                type="button">
                                                            {t('review')}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default TotalDeliveryOrders