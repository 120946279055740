export const HeaderStatisticsArr=[
    {
        heading:"Total Appointments",
        key:"total_booking",
        img:"totalOrdes",
        className:"totalProductBox",
        languageText:"totalAppointment"

    },
    {
        heading:"Fulfilled",
        key:"completed",
        img:"orderCompleted",
        className:"totalBrandBox ",
        languageText:"completed"

    },
    {
        heading:"Rescheduled",
        key:"rescheduled",
        img:"orderAnalytics",
        className:"transectionsBox",
        languageText:"rescheduled"

    },
    {
        heading:"Cancelled",
        key:"cancelled",
        img:"orderCancelled",
        className:"totalCategoryBox",
        languageText:"cancelled"

    },
    
]
export   const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          pointStyle: "circle",
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          dash: [2, 2],
          display: false,
          color: "rgba(180, 190, 235, 1)",
        }
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: "#7E8AC1",
        },
      },
    }
  };