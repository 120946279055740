import React, { forwardRef, useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomModal from "../../components/shared/CustomModal";
import EditOfferModal from "../myProducts/EditOfferModal";
import StopOfferModal from "../myProducts/StopOfferModal";
import PauseOfferModal from "../myProducts/PauseOfferModal";
import { useDispatch, useSelector } from "react-redux";
import { changeProductStatusAsync,  getSingleOfferByIdAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectAddressList, selectLoginAuth } from "../auth/authSlice";
import { addThousandSeparator } from "../../../utilities/helpers";
import Loader from "../../components/UI/Loader/Loader";
import { useTranslation } from "react-i18next";

const ServiceOfferView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [isStatus, setisStatus] = useState(false)
  const dispatch = useDispatch()
  const [offerDetails, setOfferDetails] = useState("")
  const auth = useSelector(selectLoginAuth);
  const sellerAddressList=useSelector(selectAddressList);
  const [sellerAddressDetailss, setSellerAddressDetails] = useState([]);
  const [SupplyList, setSupplyList] = useState([])
const [loading, setLoading] = useState(false);
  const [offerStatus, setOfferStatus] = useState('1');

  const searchParams = new URLSearchParams(window.location.search);

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const getOfferDetails = (productID) => {
    const data = {
      tokenData: auth?.payload?.token,
      postData: {
        id: productID,
        app_name: "merchant"
      }
    }
    setLoading(true)
    dispatch(getSingleOfferByIdAsync(data))
    .then(unwrapResult)
    .then(
      res => {
        setLoading(false);
        setOfferDetails(res?.payload || {})
        if(res?.payload?.seller_address_ids){
          let selectedIds=JSON.parse(res?.payload?.seller_address_ids||[]);
          if(sellerAddressList?.payload &&selectedIds){
            processSellerAddressDetails(selectedIds,sellerAddressList?.payload);
          }else if(selectedIds){
            getSellerAddresses(selectedIds)
          }

        }
        combineSupplyVariants(res?.payload||{});
      }
    ).catch(e => {
      history.push("/serviceOffer");
    })
  }
  const getSellerAddresses=(selectedIds)=>{
     const params = {
          sellerId: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          token: auth?.payload?.token,
        };
    dispatch(getAddressApiAsync(params))
    .then(unwrapResult)
    .then(res=>{
      let data=res?.payload;
      processSellerAddressDetails(selectedIds,data);
    })
    .catch(e=>{})
  }
  useEffect(() => {
    const productID = searchParams.get("service")
    if(productID) {
      getOfferDetails(productID)
      // getOfferList(productID)
    }
  } , [])
  const processSellerAddressDetails=(sellerAddressIds,sellerAddressDetails)=>{
    if(!Array.isArray(sellerAddressIds))return;
    let sellerDetails=[];
    sellerAddressDetails?.map((sellerAddress=>{
      let id=sellerAddress?.id;
      if(sellerAddressIds.includes(id)){
        sellerDetails.push({
          id:sellerAddress?.id,
          address:(sellerAddress?.city||"-")+","+(sellerAddress?.state||"-")
          ,
        })
      }
      setSellerAddressDetails(sellerDetails);


    }))
  }

  const changeProductStatus = (status) => {
    const data = {
      tokenData: auth?.payload?.token,
      postData: {
        id: searchParams.get("service"),
        status: status?.toString()
      }
    }
    setisStatus(true)
    dispatch(changeProductStatusAsync(data))
    .then(unwrapResult)
    .then(res => {
      setOfferDetails(prev=>({
        ...prev,status:status
      }))
      setisStatus(false);
      handleOnCloseModal()
    }).catch(e => {
      setisStatus(false);
    })
  }


  
  const combineSupplyVariants=(data)=>{
    if(!data)return;
    const uniqueVariants = new Map();
    let supplyList=[];
   let uniqeStores= data?.supply_offers?.map(supply=>{
    let variant=supply?.supply_variant;
    let id=supply?.supply_id;
    const attributes =
      typeof variant?.attributes === "string"
        ? JSON.parse(variant?.attributes)
        : variant?.attributes || {};

    // Convert attributes object to a string for uniqueness
    const attrString = JSON.stringify(attributes);

    // Store only the first occurrence of each unique attribute combination
    if (!uniqueVariants.has(attrString)) {
      let {supply_variant,...rest}=supply;
      uniqueVariants.set(attrString, variant);
      supplyList.push({
        id,
        supply_variants:[supply_variant],...rest
      });
    }
     
    });
    setSupplyList(supplyList)
    

  }

  return (
    <div className="position-relative productDetailsWrapper">
      {
        loading&&<Loader/>
      }
      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <div className="col-md-4">
            <div className="h-100 productDetailSidebar walletStats">
              <div className="d-flex align-items-start gap-3">
                <img
                  src={Images.backArrow}
                  alt="logo"
                  onClick={() => history.push("/serviceOffer")}
                />
                <h2 className="fontSize24 fw500 mb-0 txtDarkblue">
                  {offerDetails?.product?.name || ""}
                </h2>
              </div>
              <div className="text-center productPreviewBox">
                <img
                  src={offerDetails?.product?.image 
                    // || Images.productItemImg
                  }
                  alt="bagImg"
                  className="offerProductImg"
                />
                <h4 className="fontSize20 fw500 mb-0 pt-4 txtDarkblue">
                {offerDetails?.product?.name || ""}
                </h4>

               {/* {
                offerDetails?.supply_offers?.[0]?.supply_variant?.sku&&
                <div className="subCategoryTxtBox">
                  <span className="greyDot"></span>
                  <h6 className="greyDotLabel mb-0">
                    SKU {offerDetails?.supply_offers?.[0]?.supply_variant?.sku}
                  </h6>
                </div>} */}
                <div
                dangerouslySetInnerHTML={
                  {
                    __html: offerDetails?.product?.description || "",
                  }
                }
                />
              </div>

              <div className="d-flex align-items-center gap-2 productSidebar_Bottom">
               {

offerDetails?.status!=2&& <button
                 className="headerDiscardBtn"
                 onClick={() => {
                   setModalDetail({
                     show: true,
                     flag: "stopOfferModal",
                    });
                    setKey(Math.random());
                  }}
                >
                  <img src={Images.stopIcon} alt="stopIcon" className="me-2" />
                  {t('stop')}
                </button>
                  }
               {offerDetails?.status==0? <button
                  className="activeProductBtn"
                  onClick={() => {
                    setOfferStatus('1')
                    setModalDetail({
                      show: true,
                      flag: "pauseOfferModal",
                    });
                    setKey(Math.random());
                  }}
                >
                  <img src={Images.pauseIcon} alt="stopIcon" className="me-2" />
                  {t('pause')}
                </button>:
                offerDetails?.status==1?
                 <button
                 className="activeProductBtn"
                 onClick={() => {
                  setOfferStatus('0')
                   setModalDetail({
                     show: true,
                     flag: "pauseOfferModal",
                   });
                   setKey(Math.random());
                 }}
               >
                 <img src={Images.pauseIcon} alt="stopIcon" className="me-2" />
                 {t('resumeOffer')}
               </button>:<></>
                }
              </div>
            </div>
          </div>
          <div className="col-md-8 ps-0">
            <div className="productDetailsLeft walletStats">
              <h2 className="fontSize24 fw500 mb-0 txtDarkblue">
                {t('serviceDetails')}
              </h2>

              <div className="productDetailDataBox">
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">{t('category')}</h5>
                  <h5>{offerDetails?.categories?.name}</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">{t('details')}</h5>
                  <h5
                dangerouslySetInnerHTML={
                  {
                    __html: offerDetails?.product?.description || "",
                  }
                }
                />
                </div>
                {/* <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">SKU</h5>
                  <h5>
                  {offerDetails?.supplies?.[0]?.supply_variants[0]?.sku}
                  </h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Barcode</h5>
                  <h5>
                    {offerDetails?.supplies?.[0]?.supply_variants[0]?.barcode}
                  </h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Unit weight</h5>
                  <h5>{offerDetails?.product?.weight}{offerDetails?.product?.weight_unit}</h5>
                </div>
                 */}
                  {/* <div className="productDetailRight_Inner">
                    <h5 className="productCategoryTxt">{t('unitType')}</h5>
                    <h5>{
                      offerDetails?.product?.weight_unit}</h5>
                  </div>  */}
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">{t('costPrice')}</h5>
                  <h5>
                    ${addThousandSeparator(offerDetails?.product?.price?.toFixed(2))}
                  </h5>
                </div>
                {/* <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">POS price</h5>
                  <h5>$90.00</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Marketplace price</h5>
                  <h5>$92.00</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">B2B price</h5>
                  <h5>$85.00</h5>
                </div> */}
              </div>

              <div className="offerDetailBox">
                <h2 className="fontSize24 fw500 mb-0 txtBlue">{t('offerDetails')}</h2>

                <div className="mt-3 offerPeriodBox">
                  <h5 className="fontSize18 fw500 mb-0 txtDarkblue">{t('period')}</h5>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="datePicker_Parent">
                        <label className="fontSize14 fw500 pb-2 ps-4 txtDarkblue">
                          {t('startFrom')}
                        </label>
                        <DatePicker
                          showIcon
                          disabled={true}

                          selected={new Date(offerDetails?.offer_start_from ||new Date())}
                          customInput={
                            <ExampleCustomInput className="example-custom-input" />
                          }
                        />
                        <p className="mb-0 pressEnterTxt ps-4 pt-2">
                          {t('starts')} at 00:00 AM
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="datePicker_Parent">
                        <label className="fontSize14 fw500 pb-2 ps-4 txtDarkblue">
                          {t('endFrom')}
                        </label>
                        <DatePicker
                          showIcon
                          disabled={true}
                          selected={new Date(offerDetails?.offer_validity ||new Date())}
                          customInput={
                            <ExampleCustomInput className="example-custom-input" />
                          }
                        />
                        <p className="mb-0 pressEnterTxt ps-4 pt-2">
                          {t('ends')} at 11:59 PM
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 mt-3">
                  <h5 className="fontSize18 fw500 mb-0 txtDarkblue">{t('pricing')}</h5>
                    {
                      SupplyList?.map((supply,index)=>{
                        let offerType=offerDetails?.price_flag;
                        let offer_price_option=offerDetails?.offer_price_option;
                        let pricesDetails={};
                        supply?.supply_variants?.[0]?.supply_offers?.map((offer)=>{
                          let name=offer?.app_name;
                         let offerPrice=offer?.offer_price;
                         let variantPrice=offer?.actual_price;
                          pricesDetails[name]={
                            [name]:offerPrice,
                            actualPrice:Number((variantPrice||0))?.toFixed(2),
                            price:Number((offerType=='amount'?variantPrice-offerPrice:variantPrice-
                              (offerPrice*variantPrice)/100)||0)?.toFixed(2)
                          }
                        });
    
                    return  <div className="mt-3">   
                    
                    <div
                      key={index}
                      className="mt-4 staffDetailtable supplyBox"
                    >
                      {/* sellerAddressDetailss?.length>index?sellerAddressDetailss?.[index]?.address : */}
                    {/* <h6 className="fontSize16 fw500 mb-2 txtDarkblue">
                     { 
                     `Store: ${index+1}`}
                    </h6> */}
                    <div className="upcBoxParent">
                          <div className="row">
                            <div className="col-4">
                              <div className="upcBox">
                                <h3 className="fontSize18 mb-0 txtDarkblue">
                                  {t("offerType")}
                                </h3>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="upcBox upcRightBox">
                                <h3 className="text-capitalize fontSize18 mb-0 pressEnterTxt">
                                  {offerType}
                                </h3>
                              </div>
                            </div>
                          </div>
                    </div>
                    <div className="upcBoxParent">
                          <div className="row">
                            <div className="col-4">
                              <div className="upcBox">
                                <h3 className="fontSize18 mb-0 txtDarkblue">
                                  {"Offer price option"}
                                </h3>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="upcBox upcRightBox">
                                <h3 className="text-capitalize fontSize18 mb-0 pressEnterTxt">
                                  {offer_price_option=="same_price"?"Same offer price":"Different offer price"}
                                </h3>
                              </div>
                            </div>
                          </div>
                    </div>
                    <div className="upcBoxParent">
                          <div className="row">
                            <div className="col-4">
                              <div className="upcBox">
                                <h3 className="fontSize18 mb-0 txtDarkblue">
                                  POS price
                                </h3>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="upcBox upcRightBox">
                                <h3 className="fontSize18 mb-0 pressEnterTxt">
                                  ${Number(Math.max(0,pricesDetails?.["pos"]?.price||0))?.toFixed(2)}
                                </h3>
                              </div>
                            </div>
                          </div>
                    </div>
    
                        <div className="upcBoxParent">
                          <div className="row">
                            <div className="col-4">
                              <div className="upcBox">
                                <h3 className="fontSize18 mb-0 txtDarkblue">
                                  Marketplace price
                                </h3>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="upcBox upcRightBox">
                                <h3 className="fontSize18 mb-0 pressEnterTxt">
                                  ${
                                    Number(Math.max(0,pricesDetails?.["b2c"]?.price||0))?.toFixed(2)
                                  }
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                    })
    
                    }
                </div>
              
                <Link to={`/editOffer/${offerDetails?.id}`}>
                <button
                  className="commonBtn mt-4"
                  onClick={() => {
                    setModalDetail({
                      show: true,
                      flag: "editOfferModal",
                    });
                    setKey(Math.random());
                  }}
                >
                  <img
                    src={Images.lightBlueEditIcon}
                    alt="lightBlueEditIcon"
                    className="me-2"
                  />
                  {t("editOffer")}
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "editOfferModal"
            ? "editVariantModal"
            : modalDetail.flag === "stopOfferModal"
            ? "stopOfferModal"
            : modalDetail.flag === "pauseOfferModal"
            ? "stopOfferModal"
            : ""
        }
        child={
          modalDetail.flag === "editOfferModal" ? (
            <EditOfferModal
            
            close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "stopOfferModal" ? (
            <StopOfferModal
            offerData={offerDetails}
            isStatusChage={isStatus}
            productStopFunc={changeProductStatus}
            close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "pauseOfferModal" ? (
            <PauseOfferModal
            offerStatus={offerStatus}
            offerData={offerDetails}
            isStatusChage={isStatus}
            productPauseFunc={changeProductStatus}
            close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "editOfferModal" ? (
              <>
                <h2 className="fontSize24 fw500 mb-0 txtDarkblue">
                  {" "}
                  Edit Product Offer
                </h2>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : (
              ""
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ServiceOfferView;
