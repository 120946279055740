import axios from "axios";
import { REACT_APP_PRODUCT_URL, REACT_APP_USER_SERVICES } from "../../config"
import { toast } from "react-toastify";
// import { getCurrentTimeZone } from '../../../utilities/timezone'
const queryString = require('query-string');


export function getCoupnsDataApi(data, token) {
  console.log("jskhdgshgdhgsgvdh")
    const  query = "?" +  queryString.stringify(data)
    console.log({ REACT_APP_PRODUCT_URL, query })
    return new Promise((resolve, reject) =>
      axios
        .get(`${REACT_APP_PRODUCT_URL}api/v1/coupons` + query, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'merchant',
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error)
        })
    )
}
//remove
export function removeMultipleCoupnsApi(data, token) {
    return new Promise((resolve, reject) =>
      axios
        .delete(`${REACT_APP_PRODUCT_URL}api/v1/coupons/remove`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'merchant',
          },
          data
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error)
        })
    )
}
//get campaign list
export function getCampaignDataApi(data, token) {
    const  query = "?" +  queryString.stringify(data)
    console.log({ REACT_APP_PRODUCT_URL, query })
    return new Promise((resolve, reject) =>
      axios
        .get(`${REACT_APP_USER_SERVICES}api/v1/campaigns` + query, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'merchant',
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error)
        })
    )
}
//update campaign 
export function updateCampaignApi(data, token) {
  let id=data.id;
  delete data.id;
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/campaigns/${id}`,data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        },
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
        reject(error)
      })
  )
}
//delete campaign 
export function deleteCampaignApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_USER_SERVICES}api/v1/campaigns/remove`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        },
        data
      })
      .then(function (response) {
        resolve({...response,id:data?.id});
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function createCoupanApi(data, token) {
    return new Promise((resolve, reject) =>
      axios
        .post(`${REACT_APP_PRODUCT_URL}api/v1/coupons`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'app-name': 'merchant',
          }
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
          reject(error)
        })
    )
}

export function deleteCouponApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_PRODUCT_URL}api/v1/coupons/` + data.id , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}