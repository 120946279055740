import React, { forwardRef, useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { Link } from '@mui/material';
import {Link as Href} from "react-router-dom"
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Dropdown } from "react-bootstrap";
import moment from 'moment-timezone';
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetailsAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../../auth/authSlice';
import { getDateTimeStampConvertor, getFormatedDate } from '../../../../utilities/helpers';
import { AllOrders, AllPayments, AllStatus, OrderColumns} from '../../../constants/orders/orders';
import "./AllOrders.css"
import DeliveryOptions from '../../../components/UI/Orders/DeliveryOptions';
import DeliveryStatus from '../../../components/UI/Orders/DeliveryStatus';
import DatePickerCustomComponent from '../../../components/UI/Orders/DatePickerCustomComponent';
import { TimePeriodNav } from '../../../constants/timeperiod.constant';
import { resultSelectOption } from '../../../constants/common.constant';


const AllOrder = () => {
    const history = useHistory();
    //user authnetication
    const auth = useSelector(selectLoginAuth);
    //ordersDetails
    const [orderDetails, setOrderDetails] = useState(null);
    const [selectPageSize, setSelectPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState(0);
    const [orderType, setOrderType] = useState("");
    const [totalOrders, settotalOrders] = useState(0)
    const [statusType, setStatusType] = useState("");
    const [paymentType, setPaymentType] = useState("all");
    const [totalPages, setTotalPages] = useState(0)
    const [isAllChecked, setIsAllChecked] = useState(false);
    //load orders
    const [loading, setLoading] = useState(false);
    //use dispatch      
    const disPatch = useDispatch();
  const [currentPageOrders, setcurrentPageOrders] = useState([]);
    const [filterValue, setFilterValue] = useState("week");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
   
    const filterOptions = [
        {
            title: "All Orders",
            data: AllOrders
        },
        {
            title: "All Status",
            data: AllStatus
        },
        {
            title: "All Payment",
            data: AllPayments
        },
    ]
    
    //columns name
    const [columnsNames, setColumnNames] = useState(OrderColumns)
    const handleCurrentPage = (value) => {
        setCurrentPage(prev => {
            if (prev + value <= totalPages && prev + value >= 1) return prev + value;
            return prev;
        })
    }
    const handleCurrentPageData = (value) => {
        setCurrentPageData(prev => {
            if (prev + value <= Math.ceil(selectPageSize / 10) && prev + value >= 1) return prev + value;
            return prev;
        })
    }
    const resetFilters = () => { 
        setOrderDetails(null);
        setcurrentPageOrders([])
        setCurrentPageData(0)
    }
    //get order details
    const getAllOrderDetails = async () => {
        resetFilters();
        setLoading(true);
        let prams = {
            postData: {
                seller_id: "29d0e7bc-af42-4505-bcea-3e54817fcb2d",
                ...((startDate&&endDate)&&{
                    start_date: startDate&& getFormatedDate(startDate, "YYYY-MM-DD", undefined)||undefined,
                    end_date: endDate&& getFormatedDate(endDate, "YYYY-MM-DD", undefined)||undefined,
                }),
                // order_type: "service",
                page: currentPage,
                filter_by: filterValue,
                ...(statusType !== '' && { status: statusType }),
                ...(orderType !== '' && { delivery_option: orderType }),
                transaction_type: paymentType,
                limit: selectPageSize,
            },
            tokenData: auth?.payload?.token,
        }
        disPatch(getOrderDetailsAsync(prams))
            .then(unwrapResult)
            .then((res) => {
                //convert payload according to our need
                // console.log(res?.payload, "Check data");
                const filter = filterOrderDetails(res?.payload?.data);
                setOrderDetails(filter || []);
                if(filter.length>0){
                    settotalOrders(res?.payload?.total);
                    setSelectPageSize(res?.payload?.per_page)
                }
                // console.log(filter.length,"Filter length")
               if(filter.length>0) setCurrentPageData(1);

                setTotalPages(res?.payload?.
                    total_pages || 1);


                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })


    }
    const handleCurrentPageOrderData=(orderDetails)=>{
        // const startIndex = (currentPageData - 1) * 10;
        // const endIndex = startIndex + 10;
        // const currentPageOrders = orderDetails.slice(startIndex, endIndex>orderDetails.length ?orderDetails.length : endIndex );
        // console.log(currentPageOrders)
        setcurrentPageOrders(orderDetails);
    }
    
    useEffect(()=>{
        getAllOrderDetails();
      if(currentPageData>=1)  handleCurrentPageOrderData(orderDetails);
    },[currentPageData])
    const filterOrderDetails = (orders) => {
       try {
        return orders?.map((order) => {
            let timeDetails = order?.date ? getDateTimeStampConvertor(order?.date) : ["", ""];
            // console.log(order?.date,timeDetails,"check date")
            return {
                id: order?.id || "",
                orderId:order?.order_details[0]?.order_id,

                profileImg: order?.user_details?.profile_photo ||null,
                customerProfile: {
                    name: `${order?.user_details?.firstname || ""} ${order?.user_details?.lastname || ""}`,
                    address: order?.user_details?.current_address?.street_address || "",
                },
                timedetails: {
                    fullDate: timeDetails[0],
                    time: timeDetails[1],
                },
                isChecked: false,
                invoiceNumber: order?.invoices?.invoice_number || "",
                delivery: order?.delivery_option || "",
                paymentMethod: order?.mode_of_payment || "",
                totalAmount: order?.payable_amount || 0,
                status: order?.status,
                location: order?.user_details?.current_address?.state || "",
                tips: order?.tips || 0,
                taxes: order?.tax || 0,
            };
        });
       } catch (error) {
        console.log(error)
       }
    }
    
    const handleFilterOptions = (value, key) => {
        if (key == "All Orders"){
            console.log("all orders",value)
            setOrderType(value);}
        else if (key == "All Status") setStatusType(value);
        else setPaymentType(value);

    }
    //load orders
        useEffect(() => {
            
            console.log(orderType,(orderType !== '' && { delivery_option: orderType }))
        
        getAllOrderDetails();

    },
        [filterValue, endDate, selectPageSize, paymentType, orderType, statusType, currentPage]

    )
    
    return (
        <div className="walletStats ">
            <div className="productOfferHead_Container">
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className="fontSize24 txtDarkblue fw500 mb-0">All Orders <span className='countLight_'>(1200)</span></h3>
                    <div className="d-flex align-items-center gap-4">
                        <ReactDatePicker
                            onChange={(dates) => {
                                const [start, end] = dates;
                                if(start==end){
                                    setStartDate(null);
                                    setEndDate(null);
                                    return;
                                }
                                setStartDate(start);
                                setEndDate(end);
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            maxDate={new Date()}
                            customInput={<DatePickerCustomComponent startDate={startDate} />}
                        />
                        <ul className="weekNav">
                            {
                                TimePeriodNav.map((data, index) => (
                                    <li key={index} onClick={() => setFilterValue(data.value)}>
                                        <Link
                                            className={`weekMenu ${filterValue == data.value ? "active" : ""
                                                }`}
                                            to="#"
                                        >
                                            {data.label}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                        <img src={Images.NotificationIcon} alt="NotificationIcon" />
                        <img src={Images.SearchIcon} alt="SearchIcon" />
                    </div>
                </div>

                <div className="row align-items-center mt-4">

                    <div className="col-xl-6">
                        <div className="d-flex align-items-center gap-3">
                            {
                                filterOptions.map((filter, index) => (
                                    <div className="reactSelectParent offerHeaderSelect gapDiffer_">
                                        <ReactSelect
                                            options={filter.data}
                                            placeholder={filter.title}
                                            onChange={(event) => { handleFilterOptions(event.value, filter.title) }}
                                            classNamePrefix="react-select"
                                            className="react-select-container anlyticSelect"
                                            styles={{
                                                menu: (provided) => ({
                                                    ...provided,
                                                    border: "1px solid #D7DEFF",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                        ? "#263682"
                                                        : state.isFocused
                                                            ? "#f5f6fc"
                                                            : "#fff",

                                                    color: state.isSelected ? "#fff" : "#636E9F",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "#636E9F",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator: () => (
                                                    <img
                                                        src={Images.blueDownArrow}
                                                        width={24}
                                                        height={24}
                                                        alt="drop_icon"
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                ))
                            }

                            

                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <div className='d-flex justify-content-end gap-3'>
                            <div className="d-flex align-items-center gap-2">
                                <h3 className="fontSize14 txtDarkblue mb-0">Showing Results</h3>
                                <div className="reactSelectParent offerHeaderSelect">
                                    <ReactSelect
                                        options={resultSelectOption}
                                        placeholder="10"
                                        onChange={(e) => {
                                            setCurrentPage(1);
                                            setSelectPageSize(e.value);
                                        }}
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",

                                                color: state.isSelected ? "#fff" : "#636E9F",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.blueDownArrow}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="paginationParent">
                                <div className="d-flex align-items-center gap-1">
                                    <div className={`prevPagebtn ${currentPage <= 1 && "disablePrevbtn"} `} onClick={() => {
                                        if (currentPage <=1) return;
                                        handleCurrentPage(-1);
                                    }}>
                                        <img src={Images.arrowDoubleLeft} alt="arrowDoubleRight" />
                                    </div>
                                    <div className={`prevPagebtn ${currentPage <= 1 && "disablePrevbtn"} `} onClick={() => {
                                        if (currentPage <=1) return;
                                        handleCurrentPage(-1);
                                    }}>
                                        <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                                    </div>
                                </div>
                                <h6 className="fontSize14 txtDarkblue mb-0">{(((currentPage-1)*selectPageSize)+1)>orderDetails?.total?orderDetails?.total:(((currentPage-1)*selectPageSize)+1)}-{(currentPage*selectPageSize)>orderDetails?.total==true?orderDetails?.total:(currentPage*selectPageSize)} of {totalOrders}</h6>
                                <div className="d-flex align-items-center gap-1">
                                    <div className={`nextPagebtn ${currentPage === totalPages && "disableNextbtn"}`} onClick={() => {
                                        if (currentPage === totalPages) return;
                                        handleCurrentPage(1);
                                    }}>
                                        <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                                    </div>
                                    <div className={`nextPagebtn ${currentPage === totalPages && "disableNextbtn"}`} onClick={() => {
                                        if (currentPage === totalPages) return;
                                        handleCurrentPage(1);
                                    }}>
                                        <img src={Images.arrowDoubleRight} alt="arrowDoubleRight" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="commonTable sellingProductTable table-responsive mt-3">
                <table className="w-100 allorderTable_">
                    <thead>
                        <tr>
                            {/* 
                                                
                            checkbox for order
                            <th onClick={() => {
                                if (!orderDetails || orderDetails?.length === 0) return;
                                let isChecked = !isAllChecked;
                                setIsAllChecked(isChecked);
                                const updatedOrderDetails = [...orderDetails].map(order => {
                                    order.isChecked = isChecked;
                                    return order;
                                });
                                setOrderDetails(updatedOrderDetails);
                            }}>
                                <div className="productAvailabilityChild align-items-center">
                                    <div className="form-group">
                                        <input type="checkbox" checked={isAllChecked} id="check1" />
                                        <label htmlFor="check2" className="me-0"></label>
                                    </div>

                                </div>
                            </th> */}
                            <th>#</th>
                            {
                                columnsNames.map((column, index) => (
                                    <th
                                        key={index}
                                        className={!column.isVisible && "d-none"}
                                    >
                                        {column.title}
                                    </th>
                                ))
                            }

                            <th>
                                <div className="d-flex align-items-center gap-1 justify-content-end">
                                    <img src={Images.downloadOrder_} alt="icon" />
                                    <div>

                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                                className="variantsTable_Dropdown"
                                            >
                                                <img src={Images.plusIconWithBg} alt="icon" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu allOrderTableHeaderDropdown">
                                                <Link to="#" className='resetText_'>Reset</Link>
                                                {
                                                    columnsNames.map((column, index) => (

                                                        <Dropdown.Item onClick={() => {
                                                            let columns = columnsNames;
                                                            columns[index].isVisible = !column.isVisible;
                                                            setColumnNames([...columns]);
                                                        }}>
                                                            <div className="productAvailabilityChild align-items-center">
                                                                <div className="form-group">
                                                                    <input type="checkbox" checked={column.isVisible} />
                                                                    <label htmlFor="check3" className="me-0"></label>
                                                                </div>
                                                                <h3 className="fontSize14 mb-0 mt-1">{column.title}</h3>
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <img src={Images.moreHorizontalLogo} alt="icon" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <tr className='text-center border-none' >
                                    {/* Full-width spinner row */}
                                    <td colSpan="100%" >
                                        <div className='d-flex'>
                                            <span className="spinner-border inner-spin spinner-border-sm"></span>
                                        </div>
                                    </td>
                                </tr>
                            ) :
                            currentPageOrders && currentPageOrders.map((orderDetail, index) => (
                                    <tr key={orderDetail.id}>
                                        {/* 
                                        Checkbox for order
                                        <td>
                                            <div className="productAvailabilityChild align-items-center">
                                                <div onClick={() => {

                                                    const updatedOrderDetails = [...orderDetails];
                                                    updatedOrderDetails[index].isChecked = !orderDetail.isChecked;
                                                    console.log(updatedOrderDetails, index);
                                                    setOrderDetails(updatedOrderDetails);
                                                }} className="form-group">
                                                    <input
                                                        type="checkbox"
                                                        checked={orderDetail.isChecked}

                                                    />
                                                    <label htmlFor="check2" className="me-0"></label>
                                                </div>
                                            </div>
                                        </td> */}
                                        <td>{((currentPage-1)*selectPageSize)+ index + 1}</td>
                                        {/* customer profile */}
                                        <td className={`${!columnsNames[0].isVisible && 'd-none'}`}>
                                            <div className="d-flex align-items-center gap-3 ">
                                                <img
                                                    src={orderDetail?.profileImg ||
                                                        Images.orderProfile}
                                                    alt="img"
                                                    className="productItemImg"
                                                />
                                                <div>
                                                    <h3 className="darkblack mb-0 pb-1 text-start">
                                                        {
                                                            orderDetail?.customerProfile?.name
                                                        }
                                                    </h3>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <h6 className="orederLoc_ fontSize14 fw500 mb-0 text-start">
                                                            <img
                                                                src={Images.locateOrder}
                                                                alt="img"
                                                                className="location"
                                                            />
                                                            {
                                                                orderDetail?.customerProfile?.address

                                                            }
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        {/* time */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[1].isVisible && 'd-none'}`}>
                                            {orderDetail?.timedetails.fullDate}
                                            <br />
                                            <span className='orderclock_'><img src={Images.Clockorder} alt="img" className="" />
                                                {orderDetail?.timedetails.time}
                                            </span>
                                        </td>
                                        {/* invoice */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[2].isVisible && 'd-none'}`}># {orderDetail?.invoiceNumber}</td>
                                        {/* Delivery */}
                                        <td className={`fontSize12 ${orderDetail?.delivery ? "txtLightBlue" : "darkblack"} ${!columnsNames[3].isVisible && 'd-none'}`}>
                                            <DeliveryOptions deliveryOption={orderDetail.delivery} />
                                        </td>
                                        {/* Payment */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[4].isVisible && 'd-none'}`}> {
                                            orderDetail?.paymentMethod

                                        }
                                        </td>

                                        {/* totalAmount */}
                                        <td className={`${!columnsNames[5].isVisible && 'd-none'}`}>

                                            <div className="deliverTableBx">
                                                <img
                                                    src={Images.moneyImg}
                                                    alt="location Image"
                                                    className="img-fluid ms-1"
                                                />
                                                <span className="locateDistance">{
                                                    orderDetail?.totalAmount
                                                }</span>
                                            </div>
                                        </td>
                                        {/* Status */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[6].isVisible && 'd-none'}`}>
                                            <DeliveryStatus statusOption={orderDetail.status} />
                                        </td>
                                        {/* Location */}
                                        <td className={`${!columnsNames[7].isVisible && 'd-none'}`}>
                                            <span>{orderDetail.location}</span>
                                        </td>
                                        {/* Tips */}
                                        <td className={`${!columnsNames[8].isVisible && 'd-none'}`}>
                                            <span>{orderDetail.tips}</span>
                                        </td>
                                        {/* taxes */}
                                        <td className={`${!columnsNames[9].isVisible && 'd-none'}`}>
                                            <span>{orderDetail.taxes}</span>
                                        </td>
                                        <td className="text-end">
                                        <Href to={`/orderReview/${orderDetail.orderId}`}>
                                            <img src={Images.arrow_next} alt="logo" />
                                            </Href>
                                        </td>
                                    </tr>
                                ))
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AllOrder