import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  imageUploadApi,
  getChatUserListApi,
  sendMessageApi,
  getMessageheadsApi,
  getMessageListApi,
  endOfferApi,
  deleteChatApi,
  markAsReadChatApi,
  deleteSingleMessageApi,
  uploadAudioApi,
  removeNotificationApi,
  updateUserParticipatnsApi,
  acceptIncomingRequestApi
} from "./MessageApi";


const initialState = {
  status: "idle",
  walletProfile: null,
};

export const imageUploadAsync = createAsyncThunk("message/imageUploadApi", async (data) => {
  const response = await imageUploadApi(data.file, data.token);
  return response.data;
});


export const getChatUserListAsync = createAsyncThunk("message/getChatUserListApi", async (data) => {
  const response = await getChatUserListApi(data);
  return response.data;
});


export const sendMessageAsync = createAsyncThunk("message/sendMessageApi", async (data) => {
  const response = await sendMessageApi(data.messageData, data.tokenData);
  return response.data;
});


export const getMessageheadsAsync = createAsyncThunk("message/getMessageheadsApi", async (data) => {
  const response = await getMessageheadsApi(data.postData, data.tokenData);
  return response.data;
});


export const getMessageListAsync = createAsyncThunk("message/getMessageListApi", async (data) => {
  const response = await getMessageListApi(data.postData, data.queryData, data.tokenData);
  return response.data;
});


export const sendOfferAsync = createAsyncThunk("offer/sendOfferApi", async (data) => {
  const response = await endOfferApi(data.postData, data.tokenData);
  return response.data;
});


export const removeNotificationApiAsync = createAsyncThunk("offer/removeNotificationApi", async (data) => {
  const response = await removeNotificationApi(data.postData, data.tokenData);
  return response.data;
});

export const deleteChatAsync = createAsyncThunk("message/deleteChatApi", async (data) => {
  const response = await deleteChatApi(data.id, data.tokenData);
  return response.data;
});

export const acceptIncomingRequestApiAsync = createAsyncThunk("message/acceptIncomingRequest", async (data) => {
  const response = await acceptIncomingRequestApi(data.postData, data.tokenData);
  return response.data;
});

export const markAsReadChatApiAsync = createAsyncThunk("message/markAsReadChatApi", async (data) => {
  const response = await markAsReadChatApi(data.postData, data.tokenData);
  return response.data;
});

export const updateUserParticipatnsApiAsync = createAsyncThunk("message/userParticipantApi", async (data) => {
  const response = await updateUserParticipatnsApi(data.postData, data.tokenData);
  return response.data;
});

export const deleteSingleMessageApiAsync = createAsyncThunk("message/deleteSingleMessageApi", async (data) => {
  const response = await deleteSingleMessageApi(data.id, data.tokenData);
  return response.data;
});

export const uploadAudioApiAsync = createAsyncThunk("message/uploadAudioApi", async (data) => {
  const response = await uploadAudioApi(data.postData, data.tokenData);
  return response.data;
});