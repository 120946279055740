import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
} from "../dashboard/dashboardSlice";
import ServiceSubTable from "./serviceSubTable";
import {
  getLeastSellingApiAsync,
  getProductServiceListApiAsync,
  getTopSellingApiAsync,
} from "../myProducts/productSlice";
import SelectMenu from "../../components/UI/Orders/SelectMenu";
import { useTranslation } from "react-i18next";
const ServiceList = (props) => {
  const { t } = useTranslation();
  const { seller_id } = { ...props };
  const location = useLocation();
  const productListType = location?.state?.category;

  const [keyword, setKeyword] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceLoading, setServiceLoading] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  console.log(serviceData, "serviceData");
  const [selectedOption, setSelectedOption] = useState(productListType);

  const [dropdownState, setDropdownState] = useState({
    isOpen: false,
    isSubCategory: false,
  });

  const [selectPageSize, setSelectPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalService, setTotalService] = useState(0);

  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [subcategoryData, setSubCategoryData] = useState([]);
  const [searchSubCategory, setSearchSubCategory] = useState("");

  const auth = useSelector(selectLoginAuth);
  const containerRef = useRef(null);
  const containerSubRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const resultSelectOption = [
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
  ];

  const productTypeOption = [
    { value: null, label: "None" },
    { value: "top", label: "Top Selling" },
    { value: "least", label: "Least Selling" },
  ];
  const handleProductType = (selected) => {
    setSelectedOption(selected.value);
  };

  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      const newPage = prev + value;
      if (newPage >= 1 && newPage <= totalPages) {
        return newPage;
      }
      return prev;
    });
  };

  const handleCategoryChange = (categoryId) => {
    let updatedCategoryIds = [...selectedCategoryIds];
    if (updatedCategoryIds.includes(categoryId)) {
      updatedCategoryIds = updatedCategoryIds.filter((id) => id !== categoryId);
    } else {
      updatedCategoryIds.push(categoryId);
    }
    setSelectedCategoryIds(updatedCategoryIds);
  };

  const handleSubcategoryChange = (subCategoryId) => {
    let filterData = [...subCategoryIds];
    if (filterData?.includes(subCategoryId)) {
      filterData = filterData?.filter((val) => val != subCategoryId);
      setSubCategoryIds(filterData);
    } else {
      filterData = [...subCategoryIds, subCategoryId];
      setSubCategoryIds(filterData);
    }
  };

  const fetchAllCategories = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        // with_subcategories: true,
        service_type: "service",
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
    };
    if (searchCategory) {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          ...data.postData,
          search: searchCategory,
          // check_product_existance: false,
        },
      };
    }
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const fetchAllSubCategories = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        with_subcategories: true,
        service_type: "service",
        category_ids: selectedCategoryIds?.join(",") || [],
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
    };
    if (searchSubCategory) {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          ...data.postData,
          // search: searchSubCategory,
          search_subcategories: searchSubCategory,
          // check_product_existance: false,
        },
      };
    }
    // setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        // setLoading(false);
        setSubCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllCategories();
  }, [searchCategory]);

  useEffect(() => {
    fetchAllSubCategories();
  }, [searchSubCategory, selectedCategoryIds]);

  const handleToggle = (type) => {
    setDropdownState((prevState) => {
      const newstate = { ...prevState };
      if (type == "isOpen") {
        newstate.isOpen = !prevState.isOpen;
        newstate.isBrandOpen = false;
        newstate.isSubCategory = false;
      } else if (type == "isSubCategory") {
        newstate.isOpen = false;
        newstate.isBrandOpen = false;
        newstate.isSubCategory = !prevState.isSubCategory;
      }
      return newstate;
    });
  };

  const serviceListDataHandle = () => {
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        type: "service",
        app_name: "merchant",
        lat: 32.5534,
        long: 76.1258,
        search: keyword || undefined,
        category_ids: selectedCategoryIds?.join(",") || [],
        sub_category_ids: subCategoryIds.toString() || [],
        page: currentPage,
        limit: selectPageSize,
        need_pos_users: true,
        filter_deleted: true,
      },
    };
    setServiceLoading(true);
    dispatch(getProductServiceListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setServiceData(obj?.payload?.data);
        setTotalService(obj?.payload?.total);
        setTotalPages(
          Math.ceil(
            obj?.payload?.total / (obj?.payload?.per_page || selectPageSize)
          )
        );
        setServiceLoading(false);
      })
      .catch((err) => {
        setServiceLoading(false);
      });
  };

  const gettopSellingService = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "service",
      category_ids: selectedCategoryIds?.join(",") || [],
      sub_category_ids: subCategoryIds.toString() || [],

      page: currentPage,
      limit: selectPageSize,
      search: keyword || undefined,
    };

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setServiceLoading(true);
    dispatch(getTopSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setServiceLoading(false);
        setTotalService(obj?.payload?.total);
        setServiceData(
          Array.isArray(obj?.payload?.data)
            ? obj.payload.data.map((value) => value?.product_details)
            : [obj?.payload?.data?.product_details]
        );
        setTotalPages(
          Math.ceil(
            obj?.payload?.total / (obj?.payload?.per_page || selectPageSize)
          )
        );
      })
      .catch((obj) => {
        setServiceLoading(false);
      });
  };

  const getleastSellingService = () => {
    let postData = {
      seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      type: "service",
      category_ids: selectedCategoryIds?.join(",") || [],
      sub_category_ids: subCategoryIds.toString() || [],
      page: currentPage,
      limit: selectPageSize,
      search: keyword || undefined,
    };

    const params = {
      tokenData: auth?.payload?.token,
      postData,
    };

    setServiceLoading(true);
    dispatch(getLeastSellingApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setServiceLoading(false);
        setTotalService(obj?.payload?.total);
        setServiceData(
          Array.isArray(obj?.payload?.data)
            ? obj.payload.data.map((value) => value?.product_details)
            : [obj?.payload?.data?.product_details]
        );
        setTotalPages(
          Math.ceil(
            obj?.payload?.total / (obj?.payload?.per_page || selectPageSize)
          )
        );
      })
      .catch((obj) => {
        setServiceLoading(false);
      });
  };

  // useEffect(() => {
  //   serviceListDataHandle();
  // }, [
  //   keyword,
  //   currentPage,
  //   selectPageSize,
  //   selectedCategoryIds,
  //   subCategoryIds,
  // ]);

  useEffect(() => {
    if (!selectedOption || selectedOption === null) {
      serviceListDataHandle();
      return;
    }

    switch (selectedOption) {
      case "top":
        gettopSellingService();
        break;
      case "least":
        getleastSellingService();
        break;
      default:
        serviceListDataHandle();
    }
  }, [
    productListType,
    currentPage,
    selectPageSize,
    selectedCategoryIds,
    subCategoryIds,
    keyword,
    selectedOption,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the specific dropdown if the click is outside of it
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        dropdownState.isOpen
      ) {
        setDropdownState((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      }

      if (
        containerSubRef.current &&
        !containerSubRef.current.contains(event.target) &&
        dropdownState.isSubCategory
      ) {
        setDropdownState((prevState) => ({
          ...prevState,
          isSubCategory: false,
        }));
      }
    };

    // Add event listener for mousedown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownState]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Service";
  }, []);

  return (
    <>
      <DashboardNavbar
        title="Services"
        backShow={false}
        setKeyword={setKeyword}
      />
      <div className="walletStats h-100">
        <div className="productOfferHead_Container serviceListHeaderParent">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="d-flex align-items-center gap-2">
                <div className="ps-0">
                  <div className="productMainCategory">
                    <div className="productMainbox_" ref={containerRef}>
                      <button
                        className="filterTab_"
                        onClick={() => handleToggle("isOpen")}
                      >
                        {t('category')}
                        <span>
                          <i
                            className="fal fa-chevron-down dropDownArrow"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {dropdownState.isOpen ? (
                        <div className="filterDropdown_">
                          <div className="d-flex align-items-center justify-content-between pt-2 pb-4">
                            <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                            {t('category')}: {categoryData?.length || 0}
                            </h3>
                            <h3
                              className="fontSize16 fw500 txtLightBlue mb-0 categoryDropClearTxt cursorPoint"
                              onClick={() => {
                                setSelectedCategoryIds([]);
                              }}
                            >
                              {t('clear')}
                            </h3>
                          </div>
                          <div className="search_group_parent">
                            <div className="input-group search_group mb-2">
                              <img
                                src={Images.SearchIcon}
                                alt=""
                                className="img-fluid searchIcon"
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("searchHere")}
                                value={searchCategory}
                                onChange={(e) =>
                                  setSearchCategory(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                {categoryData?.length > 0 ? (
                                  categoryData?.map((val, index) => {
                                    return (
                                      <div
                                        className="listProductbody_ mt-3"
                                        key={index}
                                      >
                                        {/* Category selection with checkbox */}
                                        <div className="productAvailabilityChild align-items-center">
                                          <div className="form-group">
                                            <input
                                              type="checkbox"
                                              id={`category-${index}`}
                                              checked={selectedCategoryIds?.includes(
                                                val?.id
                                              )}
                                              onChange={() =>
                                                handleCategoryChange(val?.id)
                                              }
                                            />
                                            <label
                                              htmlFor={`category-${index}`}
                                              className="me-0 mainboldHead"
                                            >
                                              {val?.name}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="text-center txtDarkblue pt-3">
                                    {t('noCategoriesFound')}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="ps-0">
                  <div className="productMainCategory">
                    <div className="productMainbox_" ref={containerSubRef}>
                      <button
                        className="filterTab_"
                        onClick={() => handleToggle("isSubCategory")}
                      >
                        {t('subCategory')}
                        <span>
                          <i
                            className="fal fa-chevron-down dropDownArrow"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {dropdownState.isSubCategory ? (
                        <div className="filterDropdown_">
                          <div className="d-flex align-items-center justify-content-between pt-2 pb-4">
                            <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                            {t('subCategory')}
                            </h3>
                            <h3
                              className="fontSize16 fw500 txtLightBlue mb-0 categoryDropClearTxt cursorPoint"
                              onClick={() => {
                                setSubCategoryIds([]);
                              }}
                            >
                              {t('clear')}
                            </h3>
                          </div>
                          <div className="search_group_parent">
                            <div className="input-group search_group mb-2">
                              <img
                                src={Images.SearchIcon}
                                alt=""
                                className="img-fluid searchIcon"
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("searchHere")}
                                value={searchSubCategory}
                                onChange={(e) =>
                                  setSearchSubCategory(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                {subcategoryData?.length > 0 ? (
                                  subcategoryData?.map((val, index) => {
                                    return (
                                      <div
                                        className="listProductbody_ mt-3"
                                        key={index}
                                      >
                                        <h3 className="mainboldHead">
                                          {val?.name}
                                        </h3>
                                        <ul className="listItemsprodct_">
                                          {val?.sub_catgories?.map(
                                            (data, subIndex) => {
                                              return (
                                                <li
                                                  className={`textParagh ${
                                                    subCategoryIds?.includes(
                                                      data?.id
                                                    )
                                                      ? "selectProduct"
                                                      : ""
                                                  } `}
                                                  key={`${index}-${subIndex}`}
                                                >
                                                  <div className="productAvailabilityChild align-items-center">
                                                    <div className="form-group">
                                                      {/* Ensure unique ID for each checkbox */}
                                                      <input
                                                        type="checkbox"
                                                        id={`subCategory-${data?.id}`}
                                                        checked={subCategoryIds.includes(
                                                          data?.id
                                                        )} // Reflect correct state
                                                        onChange={() =>
                                                          handleSubcategoryChange(
                                                            data?.id
                                                          )
                                                        } // Bind change handler
                                                      />
                                                      <label
                                                        htmlFor={`subCategory-${data?.id}`}
                                                        className="me-0"
                                                      ></label>
                                                    </div>
                                                  </div>
                                                  {/* Display subcategory name */}
                                                  {data?.name?.slice(0, 30)}
                                                  {data?.name?.length >= 30
                                                    ? "..."
                                                    : ""}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="text-center txtDarkblue pt-3">
                                    {t('noCategoriesFound')}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="ps-sm-0">
                  <div className="reactSelectParent w-100 productSelectFilter serviceTypeSelect">
                    <SelectMenu
                      options={productTypeOption}
                      placeholder={t("selectType")}
                      value={productTypeOption.find(
                        (opt) => opt.value === selectedOption
                      )}
                      onChange={handleProductType}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex justify-content-end gap-3 commonSelectTableData">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {t('showingResult')}
                  </h3>
                  <div className="reactSelectParent offerHeaderSelect">
                    <SelectMenu
                      options={resultSelectOption}
                      placeholder="10"
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSelectPageSize(Number(e.value)); // Ensure value is a number
                      }}
                    />
                  </div>
                </div>

                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 ? "disablePrevbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(-1)}
                      disabled={currentPage <= 1}
                    >
                      <img src={Images.arrowDoubleLeft} alt="arrowDoubleLeft" />
                    </div>
                    <div
                      className={`prevPagebtn ${
                        currentPage <= 1 ? "disablePrevbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(-1)}
                      disabled={currentPage <= 1}
                    >
                      <img src={Images.lightArrowLeft} alt="arrowLeft" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0 showingResultsTxt">
                    {/* {(currentPage - 1) * selectPageSize + 1}-
                    {Math.min(currentPage * selectPageSize, totalService)} of{" "}
                    {totalService} */}
                    {currentPage ? (currentPage - 1) * selectPageSize + 1 : 1}-
                    {Math.min(
                      currentPage && selectPageSize
                        ? currentPage * selectPageSize
                        : 0,
                      totalService || 0
                    )}{" "}
                    of {totalService || 0}

                  </h6>
                  <div className="d-flex align-items-center gap-1">
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages ? "disableNextbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(1)}
                      disabled={currentPage === totalPages}
                    >
                      <img src={Images.blueArrowRight} alt="arrowRight" />
                    </div>
                    <div
                      className={`nextPagebtn ${
                        currentPage === totalPages ? "disableNextbtn" : ""
                      }`}
                      onClick={() => handleCurrentPage(1)}
                      disabled={currentPage === totalPages}
                    >
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ServiceSubTable
          serviceData={serviceData}
          serviceLoading={serviceLoading}
          serviceListDataHandle={serviceListDataHandle}
        />
      </div>
    </>
  );
};

export default ServiceList;
