import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import * as Images from "../../../../utilities/images";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCustomersApiAsync,
  getMerchantTotalCustomerGraphAsync,
  getServicesAsync,
} from "../../dashboard/dashboardSlice";
import { Dropdown } from "react-bootstrap";
import { amountFormat } from "../../bookings/BookingCalendar/GlobalMethods";
import PaginationHeader from "../../../components/PaginationHeader";
import UsersHeader from "./UsersHeader";
import useDebounce, { addPadStart } from "../../../../utilities/helpers";
import { useTranslation } from "react-i18next";
import NotificationButton from "../../../components/NotificationButton";

const Consumer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState("all_customers");
  const history = useHistory();
  // const [startDate, setStartDate] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("year");
  const auth = useSelector(selectLoginAuth);
  const [consumerData, setConsumerData] = useState([]);
  const [customerType, setCustomerType] = useState(
    location?.state?.customerType ?? ""
  );
  const [analyticsData, setAnalyticsData] = useState();
  const [sellerAddress, setSellerAddress] = useState("")
  const [startDate, setStartDate] = useState(null);
  const [total_page, setTotalPage] = useState(1)
  const [selectedCustomers, setSelectedCustomer] = useState([])
  // function to delay api calls
  const searchDebounce = useDebounce(keyword, 900);

  const getMerchantTotalCustomerGraph = (value) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
        filter: filterValue || "week",
        type: tableData,
        ...(sellerAddress && {
          seller_address_id: sellerAddress
        })
      },
    };
    if (startDate != null && endDate != null) {
      delete data.postData.filter;
      data.postData.start_date = moment(startDate).format("YYYY-MM-DD")
      data.postData.end_date = moment(endDate).format("YYYY-MM-DD")
    }
    setLoading(true);
    dispatch(getMerchantTotalCustomerGraphAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        // console.log(obj?.payload, "customer graph response");
        setAnalyticsData(obj.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getConsumerData = () => {
    let params;
    if (filterValue) {
      params = {
        postData: {
          page: currentPage,
          limit: perPage,
          search: keyword || undefined,
          filter: filterValue || "week",
          // filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
          seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          type: customerType || "all_customers",
          ...(startDate && {
            date: moment(startDate).format("YYYY-MM-DD"),
          }),
          // channel: app_name
          ...(sellerAddress && {
            seller_address_id: sellerAddress
          }),
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (servicesId) {
      params = {
        postData: {
          ...params.postData,
          service_id: servicesId,
        },
        tokenData: auth?.payload?.token,
      };
    }

    setLoading(true);
    dispatch(getAllCustomersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setConsumerData(obj?.payload?.data);
        setTotalPage(obj?.payload?.total_pages)
        setTotalRows(obj?.payload?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    // if (customerType) {
    getConsumerData();
    // }
  }, [
    currentPage,
    perPage,
    filterValue,
    endDate,
    customerType,
    servicesId,
    perPage,
    startDate,
    sellerAddress,
  ]);

  useEffect(() => {
    if (searchDebounce?.length > 2 || searchDebounce === "") {
      getConsumerData();
    }
  }, [searchDebounce]);

  const handleToggleData = (status) => {
    setTableData(status);
    setCustomerType(status)
  };

  useEffect(() => {
    // getDriverCount();
    if (filterValue || (startDate != null && endDate != null)) {
      getMerchantTotalCustomerGraph();
    }
  }, [currentPage, perPage, filterValue, endDate, sellerAddress]);


  return (
    <div className="walletStats h-100">
      <div className="productOfferHead_Container">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fontSize24 txtDarkblue fw500 mb-0">
            {t('customers')} <span className="countLight_">
              ({addPadStart(analyticsData?.total_customers?.totalCustomer)})
            </span>
          </h3>
          <div className="d-flex align-items-center gap-4">
            <NotificationButton />
            <div className="input-group search_group">
              <img
                src={Images.SearchIcon}
                alt="img"
                className="img-fluid searchIcon"
              />
              <input
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                type="text"
                className="form-control" />
            </div>
          </div>
        </div>
        <div className="row align-items-center mt-3">

          <UsersHeader
            setSellerAddress={setSellerAddress}
            // selectOption={selectOption}
            startDate={startDate}
            setStartDate={setStartDate}
            headerClass="col-xxl-4 col-xl-6"
          />
          <PaginationHeader
            currentPage={currentPage}
            totalPage={totalRows}
            perPage={perPage}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            headerClass="col-xxl-8 col-xl-6"
            dataLength={totalRows}
          />
        </div>
        <div className="verificationTotal mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center gap-2">
                <button
                  className={`activeProductBtn ${tableData == "all_customers" ? "active" : ""
                    }`}
                  onClick={() => handleToggleData("all_customers")}
                >
                  {t('all')} <span className="activeProductofferItem">
                    ({addPadStart(analyticsData?.total_customers?.totalCustomer)})
                  </span>
                </button>
                <button
                  className={`activeProductBtn ${tableData == "new_customers" ? "active" : ""
                    }`}
                  onClick={() => handleToggleData("new_customers")}
                >
                  {t('newCustomer')}
                  <span className="activeProductofferItem">
                    ({addPadStart(analyticsData?.total_customers?.newCustomer)})
                  </span>
                </button>
                <button
                  className={`activeProductBtn ${tableData == "returning_customers" ? "active" : ""
                    }`}
                  onClick={() => handleToggleData("returning_customers")}
                >
                  {t('returningCus')}
                  <span className="activeProductofferItem">
                    ({addPadStart(analyticsData?.total_customers?.returningCustomer)})
                  </span>
                </button>
                <button
                  className={`activeProductBtn ${tableData == "online_customers" ? "active" : ""
                    }`}
                  onClick={() => handleToggleData("online_customers")}
                >
                  {t('onlineCustomer')}
                  <span className="activeProductofferItem">
                    ({addPadStart(analyticsData?.total_customers?.onlineCustomers)})
                  </span>
                </button>
                <button
                  className={`activeProductBtn ${tableData == "walkin_customers" ? "active" : ""
                    }`}
                  onClick={() => handleToggleData("walkin_customers")}
                >
                  {t('walkinCus')}
                  <span className="activeProductofferItem">
                    ({addPadStart(analyticsData?.total_customers?.walkingCustomers)})
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="commonTable consumerTable table-responsive mt-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>
                <div className="productAvailabilityChild d-block">
                  <div className="form-group">
                    <input 
                    onChange={(e) => {
                      let selectData =[];
                      if(e?.target?.checked) {
                        selectData = consumerData?.map(item => ({id: item?.["invoices.invoice_number"], status: true}))
                      } 
                      setSelectedCustomer(selectData)
                    }}
                    type="checkbox" id="id3" />
                    <label htmlFor="id3" className="me-0"></label>
                  </div>
                </div>
              </th>
              <th>#</th>
              <th className="textLeft">{t('name')}</th>
              <th>{t('totalOrders')}</th>
              <th>{t('totalProducts')}</th>
              <th>{t('lifetimeSpent')}</th>

              <th>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="variantsTable_Dropdown"
                    style={{backgroundColor:selectedCustomers.length?'blue!important':''}}
                    disabled={selectedCustomers.length==0}
                  >
                    <img src={Images.moreHorizontalLogo}
                    style={{
                      filter:
                      selectedCustomers.length
                          ? "brightness(0) saturate(100%) invert(14%) sepia(63%) saturate(2662%) hue-rotate(221deg) brightness(94%) contrast(89%)"
                          : "",
                    }}
                    alt="logo" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                    <Dropdown.Item href="#/action-1">
                      <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                        {t('bulkEditing')}
                      </h3>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-1">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.locationLogo} alt="logo" />
                        <h3 className="fontSize14 mb-0">{t('editLocation')}</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.moneyPaperIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">{t('editSalesPricing')}</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.newDeleteIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                          {t('deleteAll')}
                        </h3>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </th>
            </tr>
          </thead>
          <tbody>
            {consumerData?.length > 0 && consumerData.map((val, idx) => {
              const { user_details } = val
              const fullname =
                [user_details?.firstname, user_details?.lastname]
                  .filter(Boolean).join(" ");
              const fullAddress = [
                user_details?.current_address?.city,
                user_details?.current_address?.state,
                user_details?.current_address?.country,
                user_details?.current_address?.zipcode
              ].filter(Boolean).join(" ,");
              const invoiceId = val?.["invoices.invoice_number"];
              return (
                <tr
                  style={{
                    cursor: "pointer"
                  }}
                  key={idx}>
                  <td>
                    <div className="productAvailabilityChild d-block">
                      <div className="form-group">
                        <input 
                        checked={selectedCustomers?.find(e => e.id === invoiceId)?.status || false}
                        onChange={(e) => {                     
                          if(e?.target?.checked) {
                            setSelectedCustomer((prev) => [...prev, { id: invoiceId, status: e?.target?.checked}])
                          } else {
                            setSelectedCustomer(selectedCustomers?.filter(i => i?.id !== invoiceId))
                          }
                        }}
                        type="checkbox"
                        id={idx} />
                        <label htmlFor={idx} className="me-0"></label>
                      </div>
                    </div>
                  </td>
                  <td className="fontSize12 txtLightBlue" onClick={() => history.push({
                    pathname: "consumerProfile",
                    state: user_details
                  })}>
                    {currentPage > 1 ? (
                      (currentPage - 1) * perPage + idx + 1
                    ) : idx + 1}
                  </td>
                  <td>
                    <div className="productAvailabilityChild align-items-center">
                      <div className="d-flex align-items-center gap-3 ">
                        <img
                          src={user_details?.profile_photo || Images.userImg_}
                          alt="img"
                          className="userInnerImg"
                        />
                        <div>
                          <h3 className="aboutProduct fw500 mb-1 text-start">
                            {fullname}
                          </h3>
                          <div className="flexBox gap-2">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            <h6 className=" fontSize12 fw500 mb-0 locationText">
                              {fullAddress}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="aboutProduct fw500">{val?.total_orders}</td>
                  <td className="aboutProduct fw500">{val?.total_products}</td>
                  <td className="aboutProduct fw500">{amountFormat(val?.life_time_spent) || "00.00"}</td>
                  <td className="aboutProduct fw500">
                    <img
                      src={Images.webPosArrow}
                      alt="img"
                      className="cursorPointer"
                      onClick={() => history.push({
                        pathname: "consumerProfile",
                        state: user_details
                      })}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Consumer;
