import moment from "moment-timezone";

export const filterOrderDetails = (orders,getDateTimeStampConvertor) => {

    try {
     return orders?.map((order) => {
         let timeDetails = order?.date ? getDateTimeStampConvertor(order?.date) : ["", ""];
         let userDetails=order?.user_details||order?.invitation_details;
         let paymentMode=order?.payment_method?order?.payment_method=="apple_pay"?"Apple pay":"klarna":order?.mode_of_payment;
         return {
             id: order?.id || "",
             orderId:order?.order_details[0]?.order_id||order?.id,

             profileImg: userDetails?.profile_photo ||null,
             customerProfile: {
                 name: `${userDetails?.firstname?(userDetails?.firstname+" "+userDetails?.lastname):"Unknown Customer" }`,
                 address: 
                 
                 (order?.address),
             },
             timedetails: {
                 fullDate: moment(order?.created_at).format('DD.MM.YYYY'),
                 //show time with am or pm
                 time: moment(order?.created_at).format('hh:mm A')

                 
                 ,
             },
             return_invoice: order?.return_detail?.invoices?.invoice_number || "___",
             isChecked: false,
             invoiceNumber: (order?.is_returned_order
                ? order?.return_detail?.invoices?.invoice_number
                : order?.invoices?.invoice_number),
             delivery: order?.delivery_option || "",
             paymentMethod: (order?.paid_by_reward
                ? "reward"
                : order?.partially_paid_by_reward
                  ?(paymentMode) + "/" + "reward"
                  : (paymentMode)) ,
             totalAmount: ( (Number(
                order?.payable_amount -
                order?.redeemed_reward_points / 100
              )?.toFixed(2)
            ) ?? "0"),
             status: order?.status,
             location: 
             (order?.address),
             tips: order?.tips || 0,
             taxes: order?.tax || 0,
         };
     });
    } catch (error) {
     return [];
    }
 } 