import React from "react";
import * as Images from "../../../../utilities/images";

const AcceptChatRequest = (props) => {
    const currentUser = props?.userData
  const AcceptRequest = () =>{
    props?.close();
    props?.accept(currentUser);
  }
  return (
    <div className="text-center">
      <img src={Images.crossCircle} alt="cancleLogo" />
      <div className="stopOfferModalContainer">
        <h3 className="stopOfferTxt mb-0">Are you sure you want to  Accept { 
            currentUser?.chatPartner?.firstname+" "+
            currentUser?.chatPartner?.lastname 
        }  Chat Request?</h3>
      </div>
     <p className="deleteModalActionTxt fontSize18">This action won’t able to undo</p>

      <div className="d-flex align-items-center editVariantModal_Footer mb-5">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          Cancel
        </button>
        <button className="modalNextBtn modalDiscardBtn modalStopBtn w-100" onClick={()=>AcceptRequest()}>
          <img src={Images.newDeleteIcon} alt="stopIcon" className="me-2" />
          Accept Request
        </button>
      </div>
    </div>
  );
};

export default AcceptChatRequest;
