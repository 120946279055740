import React, { useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import file from "../../public/file/Product_Details_mini.xlsx";
import { importFileApiAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import * as Images from "../../../utilities/images";
import { useTranslation } from "react-i18next";

const ProductUploadFile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const toastId = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);
  const [allSellerAddressIds, setAllSellerAddressIds] = useState([]);
  const auth = useSelector(selectLoginAuth);

  const handleChange = (file) => {
    uploadFiles(file);
  };

  const handleSelect = (data) => {
    let allIds = data?.map((v) => v?.id);
    setAllAddressIds(allIds);
  };

  const uploadFiles = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", file);

      let params = {
        postData: {
          seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          seller_address_ids:
            allAddressIds?.length > 0
              ? allAddressIds.toString()
              : allSellerAddressIds.toString(),
        },
        file: formData,
        tokenData: auth?.payload?.token,
      };
      setLoading(true);
      dispatch(importFileApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg);
          }
          history.push(`/productList`);
          setLoading(false);
        })
        .catch((obj) => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(
              "Invalid file format please check demo file"
            );
          }
          setLoading(false);
        });
    }
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        const uniqueAddress = Object.values(
          obj.payload.reduce((acc, obj) => {
            if (!acc[obj.format_address]) {
              acc[obj.format_address] = obj;
            }
            return acc;
          }, {})
        );
        let uniqueAddressIds = uniqueAddress?.map((v) => v?.id);
        setAllSellerAddressIds(uniqueAddressIds);
        console.log(uniqueAddress, "unique address");

        setAllAddress(uniqueAddress);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  return (
    <>
      <div className="fileUploadbackground rightWrapper headerWrap">
        <div className="fileUploadSection text-center">
          <div className="text-end">
            <button
              className="createOfferCloseBtn"
              onClick={() => history.push("/wallet")}
            >
              {('close')}
              <img
                src={Images.lightCrossIcon}
                alt="logo"
                className="closeCrossIcon ms-1"
              />
            </button>
          </div>
          {/* <div className="productDetailsContainer selectaddressuplod_ mb-4 ps-3 pe-3">
            <form className="costPrice">
              <div className="form-group w-100 mb-0">
                <label className="subheaderHeading mb-2">Select Address</label>
                <Multiselect
                  className="brandFilter customform-control"
                  displayValue="format_address"
                  options={allAddress}
                  hidePlaceholder={false}
                  placeholder="Select Address"
                  onSelect={handleSelect}
                  onRemove={handleSelect}
                  showCheckbox
                />
              </div>
            </form>
          </div> */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="fileUploaderParent">
                  <div className="productsubSections productSecondSub">
                    <div className="d-flex align-items-center gap-2">
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <>
                          <button disabled={loading} className="file_upload_">
                            <label
                              className="uploadmodalBtn"
                              htmlFor="input-file"
                            >
                              {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <img
                                src={Images.fileUploadLogo}
                                alt="uploadfileImage"
                                className="img-fluid cursorPointer"
                              />
                            </label>
                            <input
                              id="input-file"
                              type="file"
                              onChange={(e) => handleChange(e)}
                            />
                          </button>
                          <h3 className="fontSize16 fw500 staffPositionTxt text-left mb-0">
                            {t("uploadFile")} <br />
                            {t('dragFile')}
                          </h3>
                        </>
                      )}
                    </div>
                    {/* <button disabled={loading} className="file_upload_">
              <label className="uploadmodalBtn" htmlFor="input-file">
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Upload
              </label>
              <input
                id="input-file"
                type="file"
                onChange={(e) => handleChange(e)}
              />
            </button> */}
                    {/* <div className="bigTickSection mt-4">
              <a href={file} download className="storeText mt-4">
                Check <span className="linkText">demo</span> file formation
              </a>
            </div> */}
                  </div>

                  <p className="fontSize18 fw500 txtDarkblue pt-3">
                    {t('uploadExcel')}
                  </p>
                  <a href={file} download className="checkDemoFileParent">
                    <h3 className="fontSize12 fw500 txtDarkblue mb-0">
                      {t('checkDemoFile')}
                    </h3>
                    <img src={Images.webPosArrow} alt="image" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductUploadFile;
