import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { CKEditor } from "ckeditor4-react";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import Select from "react-select";
import ReactSelect from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomModal from "../../components/shared/CustomModal";
import { useTranslation } from "react-i18next";

const EditProductDetail = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let productResponse;
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [productName, setProductName] = useState(
    props?.productData?.name || ""
  );
  const [productDescription, setProductDescription] = useState(
    props?.productData?.description || ""
  );
  const [typedValue, setTypedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [services, setServices] = useState([]);

  const [sku, setSku] = useState(
    props?.productData?.supplies[0]?.supply_variants[0]?.sku || ""
  );

  const [servicesId, setServicesId] = useState(
    props?.productData?.service_id || ""
  );
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");

  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryId, setSubcategoryId] = useState(
    props?.productData?.sub_category_id || ""
  );
  const [brand, setBrand] = useState([]);
  const [brandId, setBrandId] = useState(props?.productData?.brand_id || "");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);
  const [barCode, setBarCode] = useState(
    props?.productData?.supplies[0]?.supply_variants[0]?.barcode || ""
  );
  const [upc, setUpc] = useState("");

  const [dimensions, setDimensions] = useState({
    weight: props?.productData?.supplies[0]?.supply_variants[0]?.weight || "",
    length: props?.productData?.length || "",
    breadth: props?.productData?.breadth || "",
    height: props?.productData?.height || "",
  });

  const handlePaste = (field, setter) => (e) => {
    e.preventDefault(); // Stop default paste behavior
    let pastedData = e.clipboardData.getData("text"); // Get pasted text
    // pastedData = pastedData.replace(/\D/g, "").slice(0, 14); // Remove non-numeric characters & limit to 14
    if (field === "sku") {
      pastedData = pastedData.replace(/[^a-zA-Z0-9-]/g, "").slice(0, 14); // Allow letters, numbers, and hyphens
    } else {
      pastedData = pastedData.replace(/\D/g, "").slice(0, 14); // Only numbers
    }
    setter(pastedData); // Update state with clean value
    props.productDetails(pastedData, field);
  };

  // const handlePaste = (field, setter) => (e) => {
  //   e.preventDefault(); // Stop default paste behavior
  //   let pastedData = e.clipboardData.getData("text").replace(/\D/g, ""); // Remove non-numeric characters

  //   setter((prevValue) => {
  //     const newValue = (prevValue + pastedData).slice(0, 14); // Append and limit to 14 chars
  //     props.productDetails(newValue, field);
  //     return newValue;
  //   });
  // };

  const handleDimensions = (e) => {
    const { name, value } = e.target;

    // Update the dimensions object
    const updatedDimensions = {
      ...dimensions,
      [name]: value,
    };

    setDimensions(updatedDimensions);

    // Pass the updated dimensions to the parent component
    props.productDetails(updatedDimensions, "dimensions");
  };

  const handleServicesChange = (e) => {
    setCategoryLoading(true);
    setSelectedService(e);
    setServicesId(e.value);
    props?.setServiceIdforAttributes(e.value);
    setSelectedSubCategory("");
    setSelectedBrand("");
    setSelectedCategory("");
    setCategory([]);
    setCategoryId("");
    setSubCategory([]);
    setSubcategoryId("");
    setBrand([]);
    setBrandId("");
    setSelectedBrand("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: e.value,
        main_category: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategory(filterResult);
        setCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: e.value,
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (e) => {
    setSubCategoryLoading(true);
    setSelectedCategory(e);
    setCategoryId(e.value);
    setSubCategory([]);
    setSubcategoryId("");
    setSelectedSubCategory("");
    setSelectedBrand("");
    setBrand([]);
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        // main_category: true,
        need_subcategory: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSubCategory(filterResult);
        setSubCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: e.value,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSubCategoryChange = (e) => {
    setBrandLoading(true);
    setSelectedSubCategory(e);
    setSubcategoryId(e.value);
    setBrand([]);
    setBrandId("");
    setSelectedBrand("");
    let brandData = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        check_product_existance: false,
      },
    };
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setBrand(filterResult);
        setBrandLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: categoryId,
          sub_category_id: e.value,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleBrandChange = (e) => {
    setBrandLoading(true);
    setSelectedBrand(e);
    productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: e.value,
    };
    props.productDetails(productResponse, "");
    setBrandId(e.value);
  };

  const handleProductName = (e) => {
    setProductName(e.target.value);
    productResponse = {
      productName: e.target.value,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: brandId,
    };
    props.productDetails(productResponse, "");
  };
  const handleProductDescription = (e) => {
    props.productDetails(e, "description");
    setProductDescription(e);
  };

  const handleChange = (field, setter) => (event) => {
    const value = event.target.value.slice(0, 14); // Ensures max length is respected
    setter(value);
    props.productDetails(value, field);
  };

  const handleBarcodeChange = handleChange("barCode", setBarCode);
  const handleSkuChange = handleChange("sku", setSku);

  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = brand?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (brand?.length > 0) {
            setBrand([...brand, newOption]);
          } else {
            setBrand([newOption]);
          }
        }
      }
    }
  };

  const handleKeyDownSubcategory = (event) => {
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = subCategory?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (subCategory?.length > 0) {
            setSubCategory([...subCategory, newOption]);
          } else {
            setSubCategory([newOption]);
          }
        }
      }
    }
  };

  useEffect(() => {
    getServices();
  }, []);
  useEffect(() => {
    setSelectedService(selectedService);
  }, [selectedService]);

  useEffect(() => {
    const preloadData = async () => {
      if (!props?.productData) return; // Exit if no product data is available

      setLoading(true);

      try {
        // Preload Service
        const initialService = services.find(
          (service) => service.value === props?.productData?.service_id
        );
        if (initialService) {
          setSelectedService(initialService);
          setServicesId(initialService.value);
          props?.setServiceIdforAttributes(initialService.value);
        }

        // Fetch Categories
        const categoryResponse = await dispatch(
          getCategoriesAsync({
            tokenData: auth?.payload?.token,
            postData: {
              service_id: props?.productData?.service_id,
              main_category: true,
              check_product_existance: false,
            },
          })
        ).then(unwrapResult);

        const categoryList = categoryResponse?.payload?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setCategory(categoryList);

        // Preload Selected Category
        const selectedCategory = {
          value: props?.productData?.category_id,
          label: props?.productData?.category?.name,
        };
        setSelectedCategory(selectedCategory);
        setCategoryId(selectedCategory.value);

        // Fetch Subcategories
        const subCategoryResponse = await dispatch(
          getCategoriesAsync({
            tokenData: auth?.payload?.token,
            postData: {
              category_id: props?.productData?.category_id,
              need_subcategory: true,
              check_product_existance: false,
            },
          })
        ).then(unwrapResult);

        const subCategoryList = subCategoryResponse?.payload?.data?.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setSubCategory(subCategoryList);

        // Preload Selected Subcategory
        const selectedSubCategory = {
          value: props?.productData?.sub_category_id,
          label: props?.productData?.sub_category?.name,
        };
        setSelectedSubCategory(selectedSubCategory);
        setSubcategoryId(selectedSubCategory.value);

        // Fetch Brands
        const brandResponse = await dispatch(
          getBrandOfCategoryAsync({
            tokenData: auth?.payload?.token,
            postData: {
              category_id: props?.productData?.sub_category_id,
              check_product_existance: false,
            },
          })
        ).then(unwrapResult);

        const brandList = brandResponse?.payload?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setBrand(brandList);

        // Preload Selected Brand
        const selectedBrand = {
          value: props?.productData?.brand_id,
          label: props?.productData?.brand?.name,
        };
        setSelectedBrand(selectedBrand);
        setBrandId(selectedBrand.value);
      } catch (error) {
        // console.error("Error preloading data:", error);
      } finally {
        setLoading(false);
      }
    };

    preloadData();
  }, [props?.productData, services]); // Dependency array for reloading on changes to `props?.productData` or `services`

  return (
    <>
      <div className="productDetailsContainer">
        {/* Start ProductName Area */}
        <h2 className="fontSize18 fw500 txtDarkblue">{t("productDetails")}</h2>
        <form className="productName">
          <div className="row">
            <div className="col-md-12 ">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("productName")}
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Product name"
                    name="text"
                    type="text"
                    value={productName}
                    onChange={(e) => handleProductName(e)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("barocode")} (ISBN, UPC, GTIN, etc.)
                  {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                </label>
                <div className="inputGroup">
                  <input
                    min="1"
                    onPaste={handlePaste("barCode", setBarCode)}
                    className="customInput"
                    maxLength={14}
                    placeholder="888 1235647890"
                    name="barcode"
                    type="number"
                    value={barCode}
                    onChange={handleBarcodeChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("sku")} (Stock Keeping Unit)
                  {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                </label>
                <div className="inputGroup">
                  <input
                    min="1"
                    onPaste={handlePaste("sku", setSku)}
                    className="customInput"
                    placeholder="GO-123"
                    maxLength={14}
                    name="sku"
                    type="text"
                    value={sku}
                    onChange={handleSkuChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("productWeight")}
                  {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                </label>

                <div className="inputGroup">
                  <input
                    className="customInput placeRight"
                    // placeholder="lb"
                    name="weight"
                    type="number"
                    value={dimensions?.weight}
                    onChange={(e) => handleDimensions(e)}
                  />
                  <span className="dollrsign1_ usdTxt">lb</span>
                </div>
                <p className="messageTxt ps-4">{t("shippingInformation")}</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("productDimensions")}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <ul className="dimensionsGroup justify-content-around dimensionGrpProduct">
                  <li className="dimensionsGroupItems ">
                    {/* <label className="fontSize14 txtDarkblue mb-2 mb-1">
                      Length
                    </label> */}
                    <input
                      type="number"
                      className="form-control "
                      placeholder="length"
                      name="length"
                      aria-describedby="button-addon1"
                      value={dimensions.length}
                      onChange={handleDimensions}
                    />
                  </li>
                  <li className="dimensionsGroupItems">
                    {/* <label className="fontSize14 txtDarkblue mb-2 mb-1">
                      Height
                    </label> */}
                    <input
                      type="number"
                      className="form-control"
                      placeholder="height"
                      name="height"
                      aria-describedby="button-addon1"
                      value={dimensions.height}
                      onChange={handleDimensions}
                    />
                  </li>
                  <li className="dimensionsGroupItems ">
                    {/* <label className="fontSize14 txtDarkblue mb-2 mb-1">
                      breadth
                    </label> */}
                    <input
                      type="number"
                      className="form-control "
                      placeholder="width"
                      name="breadth"
                      aria-describedby="button-addon1"
                      value={dimensions.breadth}
                      onChange={handleDimensions}
                    />
                  </li>
                  <li className="dimensionsGroupItems">
                    <span className="linkText">in</span>
                  </li>
                </ul>
                <p className="messageTxt ps-4">{t("shippingInformation")}</p>
              </div>
            </div>
          </div>
        </form>
        {/* End ProductName Area */}
        {/* Start ProductCategory Area */}
        <form className="ProductCategory mt-4 mb-4">
          <h2 className="fontSize18 fw500 txtDarkblue mb-0">
            {t("productCategory")}
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div className="form_group">
                {/* <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                Master Category
              </label>

              <Select
                value={selectedService}
                className="customSelect"
                onChange={handleServicesChange}
                options={services}
                isSearchable={true}
                placeholder="Select one"
              /> */}

                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("masterCategory")}
                  </label>
                  <ReactSelect
                    value={selectedService}
                    options={services}
                    onChange={handleServicesChange}
                    isSearchable={true}
                    placeholder="Select one"
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                {/* <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                Category
              </label>

              <Select
                value={selectedCategory}
                className="customSelect"
                onChange={handleCategoryChange}
                options={category}
                isDisabled={!servicesId}
                isSearchable={true}
                placeholder={
                  category && categoryLoading
                    ? "Loading Categories..."
                    : "Select one"
                }
              /> */}

                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("category")}
                  </label>
                  <ReactSelect
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={category}
                    isDisabled={!servicesId}
                    isSearchable={true}
                    placeholder={
                      category && categoryLoading
                        ? "Loading Categories..."
                        : "Select one"
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                {/* <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                Subcategory
              </label>

              <Select
                value={selectedSubCategory}
                className="customSelect"
                onChange={handleSubCategoryChange}
                onKeyDown={handleKeyDownSubcategory}
                options={subCategory}
                isDisabled={!categoryId}
                isSearchable={true}
                placeholder={
                  subCategory && subCategoryLoading
                    ? "Loading Categories..."
                    : "Select one"
                }
              /> */}

                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("subCategory")}
                  </label>
                  <ReactSelect
                    value={selectedSubCategory}
                    onChange={handleSubCategoryChange}
                    onKeyDown={handleKeyDownSubcategory}
                    options={subCategory}
                    isDisabled={!categoryId}
                    isSearchable={true}
                    placeholder={
                      subCategory && subCategoryLoading
                        ? "Loading Categories..."
                        : "Select one"
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />

                  <p className="messageTxt ps-4">{t("subCategoryCaption")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_group">
                {/* <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                Brand
              </label>
              <Select
                value={selectedBrand}
                className="customSelect"
                onChange={handleBrandChange}
                onKeyDown={handleKeyDown}
                options={brand}
                isDisabled={!subCategoryId}
                isSearchable={true}
                placeholder={
                  brand && brandLoading ? "Loading Brand..." : "Select one"
                }
              /> */}
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    {t("brand")}
                  </label>
                  <ReactSelect
                    value={selectedBrand}
                    onChange={handleBrandChange}
                    onKeyDown={handleKeyDown}
                    options={brand}
                    isDisabled={!subCategoryId}
                    isSearchable={true}
                    placeholder={
                      brand && brandLoading ? "Loading Brand..." : "Select one"
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                  {!brand && subCategoryId && (
                    <p className="messageTxt ps-4">{t("noBrandAvailable")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* End ProductCategory Area */}
        {/* Start CK EditorArea */}
        <div>
          <label className="fontSize16 txtDarkblue ps-4 ">
            {t("description")}
          </label>
          <div className="productDetailsSpc">
            <CKEditor
              initData={productDescription}
              config={{
                versionCheck: false,
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                  "image",
                ],
              }}
              name="editor"
              readOnly={false}
              onChange={(event, editor) => {
                const data = event.editor.getData();
                handleProductDescription(data);
              }}
              type="classic"
            />
            {/* <button className="ckEditorBtn">
              <img src={Images.ckBtnLogo} alt="logo" /> Generate AI Text{" "}
            </button> */}
          </div>
        </div>
        {/* End CK EditorArea */}
      </div>
    </>
  );
};

export default EditProductDetail;
