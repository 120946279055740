import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Images from "../../../utilities/images";
import { getColorName } from "../../../utilities/helpers";

const CreateOfferPublish = (props) => {
  const {
    selectedproduct,
    formattedVariants,
    startDate,
    endDate,
    selectType,
    validVariants,
    allAddress,
    priceType,
    offerPrices,
  } = props;
  console.log(formattedVariants, "formattedVariants");

  const allAttributesNull = formattedVariants.every(
    (variant) =>
      !variant.attributes || Object.keys(variant.attributes).length === 0
  );

  const WithoutVariantPrice =
    allAttributesNull &&
    (!formattedVariants[0].attributes ||
      Object.keys(formattedVariants[0].attributes).length === 0)
      ? formattedVariants[0].prices
      : [];

  console.log(validVariants, "WithoutVariantPrice");

  const priceLabels = {
    pos: "POS price",
    b2c: "Marketplace price",
    b2b: "B2B price",
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );

  const formatText = (text) => {
    return text
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  };

  return (
    <section className="offerPublish_Section publishCreateOff">
      <div className="productPreviewBox text-center">
        <img
          src={selectedproduct?.image}
          alt="img"
          className="offerProductImg"
        />
        <h4 className="fontSize20 fw500 txtDarkblue mb-0 pt-4">
          {selectedproduct.name}
        </h4>

        <div className="d-flex align-items-center justify-content-center gap-2 pt-3">
          <span className="greyDot"></span>
          <h6 className="greyDotLabel mb-0">
            {" "}
            SKU {selectedproduct?.supplies[0].supply_variants[0]?.sku}
          </h6>
        </div>
      </div>

      <div className="mt-2">
        <h2 className="fontSize24 fw500 txtDarkblue mb-0">Product details</h2>

        <div className="productDetailDataBox">
          <div className="productDetailRight_Inner">
            <h5 className="productCategoryTxt">Category</h5>
            <h5>{selectedproduct?.category?.name}</h5>
          </div>
          <div className="productDetailRight_Inner">
            <h5 className="productCategoryTxt">Details</h5>
            <h5>
              {
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedproduct?.description,
                  }}
                />
              }
            </h5>
          </div>
          <div className="productDetailRight_Inner">
            <h5 className="productCategoryTxt">SKU</h5>
            <h5>{selectedproduct?.supplies[0].supply_variants[0]?.sku}</h5>
          </div>
          <div className="productDetailRight_Inner">
            <h5 className="productCategoryTxt">Barcode</h5>
            <h5>{selectedproduct?.supplies[0].supply_variants[0]?.barcode}</h5>
          </div>
          <div className="productDetailRight_Inner">
            <h5 className="productCategoryTxt">Unit weight</h5>
            <h5>
              {selectedproduct?.weight
                ? `${selectedproduct.weight} ${
                    selectedproduct.weight_unit || ""
                  }`
                : ""}
            </h5>
          </div>
          <div className="productDetailRight_Inner">
            <h5 className="productCategoryTxt">Unit</h5>
            <h5>
              {formatText(selectedproduct?.supplies[0]?.quantity_unit || "")}
            </h5>
          </div>
          <div className="productDetailRight_Inner">
            <h5 className="productCategoryTxt">Cost</h5>
            <h5>
              {"$"}
              {selectedproduct?.price}
            </h5>
          </div>
          {WithoutVariantPrice.length > 0 && (
            <>
              {[
                { key: "pos", label: "POS price" },
                { key: "b2c", label: "Marketplace price" },
              ].map((item, index) => {
                const priceData = WithoutVariantPrice.find(
                  (p) => p.app_name === item.key
                );
                return (
                  <div className="productDetailRight_Inner" key={index}>
                    <h5 className="productCategoryTxt">{item.label}</h5>
                    <h5>${priceData ? priceData.actual_price : "N/A"}</h5>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>

      <div className="offerDetailBox offerPublishDetailBox">
        <h2 className="fontSize24 fw500 txtBlue mb-0">Offer Details</h2>

        <div className="offerPeriodBox mt-3">
          <h5 className="fontSize18 fw500 txtDarkblue mb-0">Period</h5>

          <div className="row">
            <div className="col-md-6">
              <div className="datePicker_Parent">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  Start from
                </label>
                <DatePicker
                  disabled={true}
                  showIcon
                  selected={startDate}
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">
                  Starts at 00:00 AM
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="datePicker_Parent">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  End on
                </label>
                <DatePicker
                  disabled={true}
                  showIcon
                  selected={endDate}
                  onFocus={(e) => e.preventDefault()}
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">Ends at 11:59 PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <h5 className="fontSize18 fw500 txtDarkblue mb-0">Pricing</h5>
          {WithoutVariantPrice.length > 0 ? (
            <>
              <div className="editVariant_SecondBox editPricingBox mt-3">
                <div className="upcBoxParent">
                  <div className="row">
                    <div className="col-4">
                      <div className="upcBox">
                        <h3 className="fontSize18 mb-0 txtDarkblue">
                          Offer price option
                        </h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="upcBox upcRightBox">
                        <h3 className="pressEnterTxt fontSize18 mb-0">
                          {priceType?.label}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="upcBoxParent">
                  <div className="row">
                    <div className="col-4">
                      <div className="upcBox">
                        <h3 className="fontSize18 mb-0 txtDarkblue">
                          Offer type
                        </h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="upcBox upcRightBox">
                        <h3 className="pressEnterTxt fontSize18 mb-0">
                          {selectType?.label}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                {priceType?.value === "same" ? (
                  <div className="upcBoxParent">
                    <div className="row">
                      <div className="col-4">
                        <div className="upcBox">
                          <h3 className="fontSize18 mb-0 txtDarkblue">
                            Offer price
                          </h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="upcBox upcRightBox">
                          <h3 className="pressEnterTxt fontSize18 mb-0">
                            {selectType?.value === "amount" ? "$" : ""}
                            {offerPrices[
                              formattedVariants[0]?.supply_variant_id
                            ]?.pos || ""}
                            {selectType?.value === "percentage" ? "%" : ""}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0 txtDarkblue">
                              POS price
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox">
                            <h3 className="pressEnterTxt fontSize18 mb-0">
                              {selectType?.value === "amount" ? "$" : ""}
                              {offerPrices[
                                formattedVariants[0]?.supply_variant_id
                              ]?.pos || ""}
                              {selectType?.value === "percentage" ? "%" : ""}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0 txtDarkblue">
                              Marketplace price
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox">
                            <h3 className="pressEnterTxt fontSize18 mb-0">
                              {selectType?.value === "amount" ? "$" : ""}
                              {offerPrices[
                                formattedVariants[0]?.supply_variant_id
                              ]?.b2c || ""}
                              {selectType?.value === "percentage" ? "%" : ""}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form_group">
                    <label className="fon Offer TypetSize16 txtDarkblue mb-2 ps-4">
                      Offer price option
                    </label>
                    <div className="inputGroup">
                      <input
                        className="customInput"
                        placeholder="Product name"
                        name="text"
                        type="text"
                        value={priceType?.label}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="form_group">
                    <label className="fontSize16 txtDarkblue mb-2 ps-4">
                      Offer Type
                    </label>
                    <div className="inputGroup">
                      <input
                        className="customInput"
                        placeholder="Product name"
                        name="text"
                        type="text"
                        value={selectType?.label}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="productVariantsTable table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Attributes</th>
                      <th>POS (Actual / Offer)</th>
                      {/* <th>B2B (Actual / Offer)</th> */}
                      <th>Marketplace (Actual / Offer)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validVariants.length > 0 ? (
                      validVariants.map((variant, idx) => (
                        <tr key={idx}>
                          {/* Attributes Column */}
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="variantText">
                                <div>
                                  {variant.attributes
                                    ? Object.entries(variant.attributes).map(
                                        ([key, val], index) => (
                                          <span
                                            className="varintText textCapital"
                                            key={index}
                                          >
                                            {val.startsWith("#")
                                              ? getColorName(val)
                                              : val}
                                            {index <
                                              Object.entries(variant.attributes)
                                                .length -
                                                1 && " / "}
                                          </span>
                                        )
                                      )
                                    : "Default Variant"}
                                </div>
                              </span>
                            </div>
                          </td>

                          {/* Price Columns: POS, B2B, B2C */}
                          {["pos", "b2c"].map((appName) => {
                            const priceData = variant.prices.find(
                              (p) => p.app_name === appName
                            );
                            return (
                              <td key={appName}>
                                {priceData ? (
                                  <div className="priceWrapper">
                                    <span className="fontSize12 txtDarkblue">
                                      ${priceData.actual_price}
                                    </span>{" "}
                                    /{" "}
                                    <span className="fontSize12 txtDarkblue">
                                      {selectType?.value === "amount"
                                        ? "$"
                                        : ""}
                                      {priceData.offer_price}
                                      {selectType?.value === "percentage"
                                        ? "%"
                                        : ""}
                                    </span>
                                  </div>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No Variants Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default CreateOfferPublish;
