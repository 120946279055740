import React from 'react'
import * as Images from "../../../utilities/images";
import { useTranslation } from 'react-i18next';


const SuccessfullyAddProductModal = ({selectedProducts}) => {
  const { t } = useTranslation();
  return (
    <div>
        <img src={Images.successfullyLogo} alt='image'/>
      <h3 className='successfullyProductTxt mb-0'>{t("successFullyAdded")} {selectedProducts} {t("products")}</h3>
    </div>
  )
}

export default SuccessfullyAddProductModal;
