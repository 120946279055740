import dayjs from 'dayjs';
import * as React from 'react';
import { Text, View } from 'react-native';
import { u } from '../commonStyles';
import { useTheme } from '../theme/ThemeContext';
import { getDatesInWeek } from '../utils/datetime';
import { typedMemo } from '../utils/react';
function _CalendarHeaderForMonthView(_a) {
    var locale = _a.locale, weekStartsOn = _a.weekStartsOn, style = _a.style;
    var dates = getDatesInWeek(new Date(), weekStartsOn, locale);
    var todayWeekNum = dayjs().day();
    var theme = useTheme();
    return (React.createElement(View, { style: [
            u['border-b'],
            { borderColor: theme.palette.gray['100'] },
            theme.isRTL ? u['flex-row-reverse'] : u['flex-row'],
            style,
        ] }, dates.map(function (date) { return (React.createElement(View, { style: { flex: 1, paddingTop: 10,paddingBottom:10 }, key: date.toISOString() },
        React.createElement(View, { style: { height: 30 } },
            React.createElement(Text, { style: [
                    u['text-center'],
                    {
                        // fontFamily: Fonts.SemiBold,
                        color: todayWeekNum === date.day()
                            ? theme.palette.primary.main
                            : theme.palette.gray['800'],
                    },
                ] }, date.format('ddd'))))); })));
}
export var CalendarHeaderForMonthView = typedMemo(_CalendarHeaderForMonthView);
