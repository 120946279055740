import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import CustomModal from '../../../components/shared/CustomModal';
import LinkbankwalletModal from '../../walletMain/walletSetting/linkbankwalletModal';
import AddlanguageModal from './addlanguageModal';
import { selectLoginAuth, selectUserSettings } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSettingsApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { updateUserSettingAsync } from '../../systemConfig/systemConfigSlice';
import { toast } from 'react-toastify';
import Loader from '../../../components/UI/Loader/Loader';
import { chooseLanguage } from '../../../constants/language.constant';
import { useTranslation } from 'react-i18next';

const Languageindex = ({setLanguage}) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [langaugeList, setLangaugeList] = useState([chooseLanguage[0]])
  const [AllLanguages, setAllLanguages] = useState(chooseLanguage)
   const userSettingDetails = useSelector(selectUserSettings);
  const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };
  const getLanguagesData = () => {
    if (userSettingDetails) {
      const languages=userSettingDetails?.payload?.language||[];
      if(languages?.length)setLangaugeList(languages);
      return;
    }
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        app_name: "merchant",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserSettingsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        const languages=obj?.payload?.language||[];
        if(languages?.length)setLangaugeList(languages);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const handleCheckboxChange = (index, language) => {
     let languages = [...langaugeList];
     if(languages.length==1){
       toast.error("You can't remove the only language")
       return;
     }
     let filterLanaguages=langaugeList.filter(lang=>lang.name!=language?.name);

    setLangaugeList(filterLanaguages);
    updateUserSettingLanguage(filterLanaguages);
  };
  useEffect(()=>{
    getLanguagesData()
  },[]);
  useEffect(()=>{
    if(langaugeList.length){
      let currentLang=langaugeList.filter(lang=>lang.status);
      if(currentLang){
        const langCode = currentLang?.[0]?.language_code||"en";
        setLanguage(currentLang?.[0]?.name||"English");
        i18n.changeLanguage(langCode);
      }
    }
  },[langaugeList])
  const toggleLanguage=(index,lang)=>{
    let languages=[...langaugeList];
    let updatedLanguages=languages.map((item)=>item.name==lang.name?{...item,status:true}:{...item,status:false});
    setLangaugeList(updatedLanguages);
    updateUserSettingLanguage(updatedLanguages);
  }
  const updateUserSettingLanguage=(languages)=>{
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        app_name: "merchant",
        language: languages,
      },
    };
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setLangaugeList(obj?.payload?.language);
      })
      .catch((obj) => {
        setLoading(false);
        toast.error("Unable to load languages")
      });
  }
  return (
    <>
      <div className='languageMain_ position-relative'>
        {
          loading&&<Loader/>
        }
        <h3 className='appointMain'><img className='ordersetting me-2' src={Images.leftarrow_} alt='arrow' />{t("publishLanguage")} </h3>
        <p className='lighttext14 ps-4'>{t("activeCustomerLan")}</p>



        {
          langaugeList?.map((language,index)=>(
            <div
            
            key={index} className={`bussinessSub mt-4 ms-4 ${language?.status?'active':''}`}>
          <div 
          onClick={()=>{toggleLanguage(index,language)}}
          className="locationHead">
            <img
              src={language?.image|| Images.countryflag}
              alt="staffLocate image"
              className="img-fluid"
              style={{width:"50px"}}
            />
            <div className="bussinessHeading">
              <h4 className="expectedHeading">
              {language?.name} 
              </h4>
              {
                language?.status&&<p className="countNumber_">{t("default")}</p>
              }
            </div>
          </div>
          <div className="roundCheck mb-0">
            <input
              type="checkbox"
              checked={true}
              onChange={()=>{
                if((language?.name?.toLocaleLowerCase()!='english')){
                  handleCheckboxChange(index,language)
                }
              }}
            />
            <label className="amountText d-none"></label>
          </div>
        </div>
          ))
        }
        
        
        <div className='addLanguage_ mt-4 ms-4'>
          <h3 className='expectedHeading mb-0' onClick={() => {
            setModalDetail({
              show: true,
              flag: "AddlanguageModal",
            });
            setKey(Math.random());
          }}> <img
              src={Images.pluscircleOutline}
              alt="staffLocate image"
              className="img-fluid"
            /> {t("addLangauge")}</h3>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "AddlanguageModal"
            ? "addlanguageModal"

            : ""
        }
        child={
          modalDetail.flag === "AddlanguageModal" ? (
            <AddlanguageModal close={() => handleOnCloseModal()} 
            setLanguagesData={(language)=>{setLangaugeList(language)}}
            langaugeList={AllLanguages}
            selectedLanguages={langaugeList}
            
            
            />
          ) :
            (
              <></>
            )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default Languageindex