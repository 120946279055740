import React, { useState } from "react";
import * as Images from "../../../../utilities/images";
import { useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";

const BusinesscardModal = (props) => {
  const auth = useSelector(selectLoginAuth)
  const [businessCard, setBusinessCard] = useState({
      firstName: auth?.payload?.user?.user_profiles?.firstname,
      lastName: auth?.payload?.user?.user_profiles?.lastname,
      company_name: auth?.payload?.user?.user_profiles?.organization_name,
      email: auth?.payload?.email ? auth?.payload?.email : auth?.payload?.user?.email ? auth?.payload?.user?.email : auth?.payload?.emaill,
      mobile: auth?.payload?.user?.user_profiles?.full_phone_number
  });


  const sendBusinessCard = (businessCard) => {
      props.businessCard(businessCard, "business_card");
      props.close();
  }

  return (
    <>
      <div className="shippingModalParent">
        <div className="businessCardBox ">
          <div className="flexBox gap-3">
            <img
              src={Images.bankslogo1}
              alt="img"
              className="businessCardBoxImg"
            />
            <h3 className="fontSize18 fw500 txtDarkblue">{businessCard?.company_name}</h3>
          </div>
          <div className="container-fluid p-0 mt-3">
            <div className="row">
              <div className="col-4">
                <h3 className="fontSize16 fw500 businessUserName">
                {businessCard?.firstName + " " + businessCard?.lastName}
                </h3>
                <h3 className="fontSize14 fw500 txtLightBlue">Sales Manager</h3>
                <h3 className="fontSize10  txtLightBlue mb-0">Apparel Unit</h3>
              </div>
              <div className="col-8">
                <h3 className="fontSize14 txtLightBlue flexBox gap-1">
                  <img src={Images.phoneLogoBlue} alt="img" />
                  {businessCard?.mobile}
                </h3>
                <h3 className="fontSize14 txtLightBlue  flexBox gap-1">
                  <img src={Images.emailLogoBlue} alt="img" />
                  {businessCard?.email}
                </h3>
                <h3 className="fontSize14 txtLightBlue  flexBox gap-1 mb-0">
                  <img src={Images.locationLogoBlue} alt="img" />
                  4554 Adamsville Road, TX.
                </h3>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <button
      onClick={() => sendBusinessCard(businessCard)} className={`commonBlueBtn w-100  active`}>Share</button>
    </>
  );
};

export default BusinesscardModal;
