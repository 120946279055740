import React, { useEffect, useState } from "react";
import {
  Logomvoice,
  OrderDown,
  barCodeScanImg,
  cancleorder,
  pickuporder,
  returnlogo,
  unselectedOrder,
  verifyorder
} from "../../../../utilities/images";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";
import { getOrderDetailByIdAsync } from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressFromCoordinates } from "../../auth/formCordinates";


const ConsumerInvoice = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [tableData, setTableData] = useState("activeData");
    const [map, setMap] = useState(/** @type google.maps.Map */ (null));
    const auth = useSelector(selectLoginAuth);
    const [driverLoc, setDriverLoc] = useState("");
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [driverDistance, setDriverDistance] = useState("");
    const [driverDuration, setDriverDuration] = useState("");
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState("");
    const locationData = orderData;
    const [mapStatus, setMapStatus] = useState(false);
    console.log(orderData, "orderData");
    const history = useHistory();
  
    const [modalDetail, setModalDetail] = useState({
      show: false,
      title: "",
      flag: "",
    });
    const [key, setKey] = useState(Math.random());
    const handleOnCloseModal = () => {
      setModalDetail({
        show: false,
        title: "",
        flag: "",
      });
      setKey(Math.random());
    };
    const handleOpenModal = (flag) => {
      setModalDetail({
        show: true,
        flag: flag,
        type: flag,
      });
      setKey(Math.random());
    };
    const pickupLatitude = parseFloat(
      orderData?.order_delivery?.order_pickup_latitude ?? 0.0
    );
    const pickupLongitude = parseFloat(
      orderData?.order_delivery?.order_pickup_longitude ?? 0.0
    );
    const deliverLatitude = parseFloat(
      orderData?.order_delivery?.order_delivery_latitude ?? 0.0
    );
    const deliverLongitude = parseFloat(
      orderData?.order_delivery?.order_delivery_longitude ?? 0.0
    );
    const center = { lat: pickupLatitude, lng: pickupLongitude };
    const origin = { lat: pickupLatitude, lng: pickupLongitude };
    const Destination = { lat: deliverLatitude, lng: deliverLongitude };
  
    const directionsOptions = {
      destination: Destination,
      origin: origin,
      travelMode: "DRIVING",
    };
    const directionsCallback = (response) => {
      if (response !== null && map !== null) {
        if (response.status === "OK" && mapStatus == false) {
          setMapStatus(true);
          setDirectionsResponse(response);
          console.log("called");
        } else {
          console.error("Directions request failed:", response);
          setMapStatus(true);
        }
      }
    };
  
    const geocodeHandler = () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        getAddressFromCoordinates(
          position.coords.latitude,
          position.coords.longitude
        )
          .then((data) => {
            setDriverLoc(data?.formatted_address);
          })
          .catch((err) => console.log("error getting address", err));
      });
    };
    async function calculateDriverRoute() {
      if (driverLoc === "" || locationData?.address_2 === "") {
        return;
      }
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: driverLoc,
        destination: locationData?.address_2,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirectionsResponse(results);
      setDriverDistance(results.routes[0].legs[0].distance.text);
      setDriverDuration(results.routes[0].legs[0].duration.text);
    }
    const getOrderDataById = () => {
      let data = {
        id: id,
        token: auth?.payload?.token,
      };
      setLoading(true);
      dispatch(getOrderDetailByIdAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          console.log("objjjjjjjjjjjj", obj?.payload);
          setOrderData(obj?.payload);
        })
        .catch((obj) => {
          setLoading(false);
        });
    };
  
    useEffect(() => {
      getOrderDataById();
      geocodeHandler();
      calculateDriverRoute();
    }, []);
    
  return (
    <div>
      <div className="orderTrack">
        {loading ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <div className="consumerOrder">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="invoiceMain_">
                    <div className="invoiceidHead_">
                      <h4 className="blueHeading_"># 9836-1238</h4>
                      <h6 className="bluesmall_">Ready to Pickup</h6>
                    </div>
                    <div className="mt-3 me-0 mapleLeft ">
                      <div className="confirmRightSub confirmAddress">
                        <h2 className="mapleHeading text-center">Maple Inc.</h2>
                        <h4 className="mapleAddress text-center">
                          500 Rideau St. Ottawa, ON 5Z2 K1L
                        </h4>
                        <h4 className="mapleAddress text-center p-0">
                          +1 (438) 459-0226
                        </h4>
                      </div>
                      <div className="mainscrollinvoice_">
                        <div className="mapleProductDetails confirmRightSub">
                          <div className="flexBox mapleProductDetailsBox">
                            <div className="flexbase">
                              <p className="mapleProductcount">× 1</p>
                              <article className="ms-2">
                                <p className="mapleProductHeading">
                                  Lightweight Stylish Casual Daypack
                                  <span>Yellow / M</span>
                                </p>
                              </article>
                            </div>
                            <article>
                              <p className="mapleProductPrice">$90.00</p>
                            </article>
                          </div>
                        </div>
                      </div>
                      <div className="commonInvoice_ mt-auto">
                        <div className="flexBox mapleInvoiceBox confirmRightSub">
                          <article>
                            <p className="mapleProductPrice">Payment Option</p>
                            <p className="mapleProductHeading">Cash</p>
                            <p className="mapleProductPrice">Invoice</p>
                            <p className="mapleProductHeading"># 9836-1238</p>
                          </article>
                          <article>
                            <p className="mapleProductPrice">Date</p>
                            <p className="mapleProductHeading">Wed 10/10/23</p>
                            <p className="mapleProductPrice">POS No.</p>
                            <p className="mapleProductHeading">#Front-CC03</p>
                          </article>
                          <article>
                            <p className="mapleProductPrice">Mode</p>
                            <p className="mapleProductHeading">Walk-In</p>
                            <p className="mapleProductPrice">User UD</p>
                            <p className="mapleProductHeading">****331</p>
                          </article>
                        </div>
                        <div className="flexBox maplePriceBox">
                          <article>
                            <p className="productName">Subtotal</p>
                            <p className="productName">Discount</p>
                            <p className="productName">Shipping</p>
                            <p className="productName">Other Fees</p>
                            <p className="productName">Taxes</p>

                            <p className="productName fw-bold">Total</p>
                          </article>
                          <article>
                            <p className="productName">$933.50</p>
                            <p className="productName">15% ($13.50)</p>
                            <p className="productName">$29.00</p>
                            <p className="productName">$933.50</p>
                            <p className="productName">$933.50</p>

                            <p className="totalBtn">$304.75</p>
                          </article>
                        </div>
                        <div className="confirmFooter">
                          <img
                            src={Logomvoice}
                            alt="logo"
                            className="img-fluid logo"
                          />
                          <img
                            src={barCodeScanImg}
                            alt="barCodeScanImg"
                            className="img-fluid barCodeScanImg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {orderData?.delivery_option == 3 ||
                orderData?.delivery_option == 4 ? (
                  <div className="col-lg-7 col-md-6 pe-0">
                    <div className="consumerMap mt-0">
                      <div className="detailTrackingright_">
                        <div className="MapOrders">
                          <div className="MapOrders_inner">
                            <GoogleMap
                              center={center}
                              zoom={15}
                              mapContainerStyle={{
                                width: "100%",
                                height: "calc(100vh - 60px)",
                              }}
                              options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                              }}
                              onLoad={(map) => setMap(map)}
                            >
                              <DirectionsService
                                options={directionsOptions}
                                callback={directionsCallback}
                              />
                              {directionsResponse && (
                                <DirectionsRenderer
                                  directions={directionsResponse}
                                />
                              )}
                            </GoogleMap>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="deliveryStatus">
                      <div className="statusHead stausDown">
                        <div className="statusLeft">
                          <h3>Order status</h3>
                        </div>
                        <figure className="m-0">
                          <img src={OrderDown} alt="downSelect" />
                        </figure>
                      </div>
                      <div className="orderStatus ">
                        {(orderData?.status <= 5 &&
                          orderData?.delivery_option == 1) ||
                        (orderData?.status <= 5 &&
                          orderData?.delivery_option == 3) ? (
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.status == 5
                                    ? pickuporder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Delivered</h3>
                              {orderData?.status_desc?.status_5_updated_at ? (
                                <h6>
                                  {moment(
                                    orderData?.status_desc?.status_5_updated_at
                                  ).format("DD MMM, YYYY | hh:mm a")}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </article>
                          </div>
                        ) : (
                          <></>
                        )}
                        {orderData?.status <= 4 &&
                        orderData?.delivery_option == 4 ? (
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.status == 4
                                    ? pickuporder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Shipped</h3>
                              {orderData?.status_desc?.status_4_updated_at ? (
                                <h6>
                                  {moment(
                                    orderData?.status_desc?.status_4_updated_at
                                  ).format("DD MMM, YYYY | hh:mm a")}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </article>
                          </div>
                        ) : (
                          <></>
                        )}
                        {orderData?.status === 8 ? (
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.status == 8
                                    ? cancleorder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Rejected</h3>
                              <h6>
                                {moment(
                                  orderData?.status_desc?.status_8_updated_at
                                ).format("DD MMM, YYYY | hh:mm a")}
                              </h6>
                            </article>
                          </div>
                        ) : (
                          <></>
                        )}
                        {orderData?.status === 7 || orderData?.status === 9 ? (
                          <>
                            <div className="readyPickupstatus">
                              <figure>
                                <img
                                  src={
                                    orderData?.status === 9
                                      ? pickuporder
                                      : unselectedOrder
                                  }
                                  alt="pickup"
                                />
                              </figure>
                              <article>
                                <h3>Return to Shop</h3>
                                <div className="reviewuserinfo_">
                                  <img
                                    style={{ width: "50px" }}
                                    className="invoveuser me-3"
                                    src={
                                      orderData?.seller_details?.banner_image
                                        ? orderData?.seller_details
                                            ?.banner_image
                                        : returnlogo
                                    }
                                  />
                                  <article className="ivoiceuserDetail_">
                                    <h4 className="monshead14">
                                      {
                                        orderData?.seller_details
                                          ?.organization_name
                                      }
                                    </h4>
                                    <p className="monshead12">
                                      {
                                        orderData?.seller_details
                                          ?.current_address?.street_address
                                      }
                                    </p>
                                  </article>
                                </div>
                              </article>
                            </div>
                            <div className="readyPickupstatus">
                              <figure>
                                <img
                                  src={
                                    orderData?.status === 9
                                      ? cancleorder
                                      : unselectedOrder
                                  }
                                  alt="pickup"
                                />
                              </figure>
                              <article>
                                <h3>Canceled</h3>
                                <h6>
                                  {moment(
                                    orderData?.status_desc?.status_8_updated_at
                                  ).format("DD MMM, YYYY | hh:mm a")}
                                </h6>
                              </article>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {orderData?.status >= 4 && orderData?.status != 8 ? (
                          <div className="readyPickupstatus pickupBtnDetails">
                            <div className="pickupBtnDetails">
                              <figure>
                                <img
                                  src={
                                    orderData?.status >= 4
                                      ? verifyorder
                                      : unselectedOrder
                                  }
                                  alt="verifyorder"
                                />
                              </figure>
                              <article>
                                <h3>Product Pickup</h3>
                                {orderData?.status_desc?.status_4_updated_at ? (
                                  <h6>
                                    {moment(
                                      orderData?.status_desc
                                        ?.status_4_updated_at
                                    ).format("DD MMM, YYYY | hh:mm a")}
                                  </h6>
                                ) : (
                                  <></>
                                )}
                              </article>
                            </div>
                            {orderData?.order_delivery?.seller_otp ? (
                              <div className="pickupBtn">
                                <button className="countbtn">
                                  {orderData?.order_delivery?.seller_otp}
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {orderData?.delivery_option == 1 ? (
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.driver_details
                                    ? pickuporder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Assign Driver</h3>
                            </article>
                          </div>
                        ) : (
                          <></>
                        )}
                        {orderData?.status != 8 ? (
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.status >= 3
                                    ? pickuporder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Ready to pickup</h3>
                              {orderData?.status_desc?.status_3_updated_at ? (
                                <h6>
                                  {moment(
                                    orderData?.status_desc?.status_3_updated_at
                                  ).format("DD MMM, YYYY | hh:mm a")}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </article>
                          </div>
                        ) : (
                          <></>
                        )}
                        {orderData?.status != 8 ? (
                          <div className="readyPickupstatus">
                            <figure>
                              <img
                                src={
                                  orderData?.status >= 1
                                    ? pickuporder
                                    : unselectedOrder
                                }
                                alt="pickup"
                              />
                            </figure>
                            <article>
                              <h3>Order accepted</h3>
                              {orderData?.status_desc?.status_1_updated_at ? (
                                <h6>
                                  {moment(
                                    orderData?.status_desc?.status_1_updated_at
                                  ).format("DD MMM, YYYY | hh:mm a")}
                                </h6>
                              ) : (
                                <></>
                              )}
                            </article>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsumerInvoice;
