import moment from 'moment-timezone'
import React from 'react'
import { useTranslation } from 'react-i18next';

const CouponModal = ({ couponData }) => {
    const { t } = useTranslation();
    return (
        <div className='coupomodalmain_ mt-4'>
            <div className='detailinfomation_'>
                <h4 className='headblue18 mb-2'>{t('details')}</h4>
                <h5 className='headlight16_ bglightColor_ m-0'><span className='nametitle_' style={{
                    textTransform: "capitalize"
                }}>{t('couponType')}:</span> <span>{couponData?.type}, {couponData?.type=="flat"?`${couponData?.discount_amount} off`:`${couponData?.discount_percentage}%`} </span></h5>
                <h5 className='headlight16_ bglightColor_ bgtrans_ m-0'><span className='nametitle_'>Applies to:</span> <span>{couponData?.category_id?"Category":couponData?.product_id?"Item": "Entire Sale"}</span></h5>
                <h5 className='headlight16_ bglightColor_ m-0'><span className='nametitle_'>
{t("createdDate")}:
                    {t("")}:</span> <span>
                    {moment(couponData?.created_at).format("DD-MM-YYYY")}
                    </span>
                </h5>
                <h5 className='headlight16_ bglightColor_ bgtrans_ m-0'><span className='nametitle_'>
                    {t('activeDate')}:</span> <span>{moment(couponData?.start_time).format("DD-MM-YYYY")} - {moment(couponData?.end_time).format("DD-MM-YYYY")}</span>
                </h5>
                <h5 className='headlight16_ bglightColor_  m-0'><span className='nametitle_'>
                     {t('daysLive')}:</span> <span>{moment(couponData?.end_time).diff(couponData?.start_time, "days")}{t('days')}</span>
                </h5>
            </div>
            <div className='detailinfomation_ mt-4'>
                <h4 className='headblue18 mb-2'>{t('conditions')}</h4>
                {/* <h5 className='headlight16_ bglightColor_ m-0'>
                    <span className='nametitle_'>{t('couponCanUse')}:</span> <span>{couponData?.max_total_usage}</span>
                </h5> */}
                <h5 className='headlight16_ bglightColor_ bgtrans_ m-0'><span className='nametitle_'>
                    {t('couponReedem')}:</span> <span>{couponData?.max_total_usage}</span>
                </h5>
                <h5 className='headlight16_ bglightColor_ m-0'><span className='nametitle_'>{t('minPurchase')}:</span> <span>${couponData?.minimum_order_amount}</span></h5>

            </div>
            <div className='detailinfomation_ mt-4'>
                <h4 className='headblue18 mb-2'>{t('status')}</h4>
                <h5 className='headlight16_ bglightColor_ m-0'><span className='nametitle_'>{t('redeemed')}</span> <span>{
                    Number(couponData?.total_usage??0)
                    }</span></h5>
                <h5 className='headlight16_ bglightColor_ bgtrans_ m-0'><span className='nametitle_'>{t('netSales')}</span> <span>${
                    Number(couponData?.net_sales??0)?.toFixed(2)}</span></h5>
            </div>
        </div>
    )
}

export default CouponModal