import React from "react";
import * as Images from "../../../utilities/images";
import { isHideCheckInButton } from "../../constants/bookings/allbooking";
import { useTranslation } from "react-i18next";
const BookingAppointmentStatus = ({
  appointment,
  handlerAppointmentStatus,
  status,
  handleOpenModal,
  setSingleOrderData,
}) => {
  const { t } = useTranslation();
  const Completed = () => {
    return (
      <>
        <button
          onClick={() => {
            // if (!isOpen) return;
            setSingleOrderData(appointment);
            // handleOpenModal("appointmentDetailModal");
          }}
          className="completedBtn"
        >
          {t('completed')}
        </button>
      </>
    );
  };
  const MarkAsDone = () => {
    return (
      <>
        <button
          onClick={() => {
            // if (!isOpen) return;
            handlerAppointmentStatus(appointment.id, 3);
            // handleOpenModal("checkedIn");
          }}
          className="checkInBtn tableClockParent"
        >
          {t('markDone')}
        </button>
      </>
    );
  };
  const AcceptOrReject = () => {
    return (
      <>
        <div className="flexBox gap-2 justify-content-end">
          <button
            className="cancleAppointmentBtn tableDeclineBtn mr-6"
            type="submit"
            onClick={async () => {
              //   if (!isOpen) return;
              handlerAppointmentStatus(appointment.id, 4);
            }}
          >
            {t('decline')}
          </button>

          <button
            className="commonBlueBtn tableAcceptBtn"
            type="submit"
            onClick={async () => {
              //   if (!isOpen) return;

              handlerAppointmentStatus(appointment.id, 1);
            }}
          >
            {t('accept')}
          </button>
        </div>
      </>
    );
  };
  const CancelledByCusomter = () => {
    return (
      <>
        <button
          onClick={() => {
            // if (!isOpen) return;
            // handleOpenModal("appointmentDetailModal");
            setSingleOrderData(appointment);
          }}
          className="cancleAppointmentBtn tableDeclineBtn"
        >
          {t('cancelled')}
        </button>
      </>
    );
  };
  const RejectBySeller = () => {
    return (
      <>
        <button
          onClick={() => {
            // if (!isOpen) return;
            // handleOpenModal("appointmentDetailModal");
            setSingleOrderData(appointment);
          }}
          className="cancleAppointmentBtn tableDeclineBtn"
        >
          {t('rejected')}
        </button>
      </>
    );
  };
  const AccpetedBySeller = () => {
    const hidecheckInButton = isHideCheckInButton(appointment?.start_date_time,
      appointment?.approx_service_time);
    return (
      <>{
        hidecheckInButton?<></>:<div className="flexBox gap-2 justify-content-end">
        <button
          className="checkInBtn tableClockParent"
          onClick={() => {
            //   if (!isOpen) return;
            handleOpenModal("checkInModal");
            setSingleOrderData(appointment);
          }}
        >
          {t('checkIn')}
          <img src={Images.checkInImg} alt="img" />
        </button>
      </div>

      }
        
        <div className="flexBox gap-2 justify-content-end">
          <button
            className="checkInBtn tableClockParent"
            onClick={() => {
              handleOpenModal("rescheduleModal");
              setSingleOrderData(appointment);
            }}
          >
            {t('modify')}
            <img style={{width:"14px"}} src={Images.editIcon} alt="img" />
          </button>
        </div>
      </>
    );
  };
  switch (status) {
    case 0:
      return AcceptOrReject();
    case 1:
      return AccpetedBySeller();
    case 2:
      return MarkAsDone();
    case 3:
      return Completed();
    case 4:
      return CancelledByCusomter();
    case 5:
      return RejectBySeller();
    default:
      return <span>{t('statusUnknown')}</span>;
  }
};

export default BookingAppointmentStatus;
