import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  calendar,
  shareMap,
  productList1,
  productList2,
  productList3,
  productList4,
  downlight,
  lossArrow,
} from "../../../../utilities/images";
import DatePicker from "react-datepicker";
import ManufacturList from "./manufacturList";
import ManufactureView from "./manufactureView";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getManufactureDataApiAsync,
  getManufactureRetailerWholeSellerApiAsync,
  getServicesAsync,
} from "../../dashboard/dashboardSlice";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import DashboardNavbar from "../../dashboard/DashboardNavbar";
import { addThousandSeparator } from "../../../../utilities/thousandCommaSeparated";
import { useTranslation } from "react-i18next";

const Manufacture = () => {
  const { t } = useTranslation();
  const [enterprisesDate, setEnterprisesDate] = useState("");
  const [activeTab, setActiveTab] = useState("MonthlyBilling");
  const { flag } = useParams();
  const [monthlyPlan, setMonthlyPlan] = useState([]);
  const [yearlyPlan, setYearlyPlan] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [servicesId, setServicesId] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("week");
  const auth = useSelector(selectLoginAuth);
  const [CountData, setCountData] = useState([]);
  const [manufactureData, setManufactureData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(CountData, "count dtaa");
  const handleDateChange = (dates) => {
    setFilterValue("");
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleFilterValue = (value) => {
    setStartDate(null);
    setEndDate(null);
    setFilterValue(value);
  };
  const getManufacturesData = () => {
    let params;
    if (filterValue) {
      params = {
        postData: {
          seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          page: currentPage,
          limit: perPage,
          filter: filterValue,
          type: flag,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (servicesId) {
      params = {
        postData: {
          ...params.postData,
          service_id: servicesId,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (addressId) {
      params = {
        postData: {
          ...params.postData,
          seller_address_id: addressId,
        },
        tokenData: auth?.payload?.token,
      };
    }
    if (startDate != null && endDate != null) {
      params = {
        postData: {
          seller_id: auth?.payload?.uniqe_id
            ? auth?.payload?.uniqe_id
            : auth?.payload?.user?.unique_uuid,
          page: currentPage,
          limit: perPage,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          type: flag,
        },
        tokenData: auth?.payload?.token,
      };
    }
    setLoading(true);
    dispatch(getManufactureRetailerWholeSellerApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setManufactureData(obj?.payload?.users_list?.data);
        setCountData(obj?.payload?.data);
        setTotalRows(obj?.payload?.manufacturer_list?.total);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {});
  };
  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        // type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getManufacturesData();
  }, [currentPage, perPage, filterValue, flag, endDate, addressId, servicesId]);

  useEffect(() => {
    getServices();
    getAllAddress();
  }, []);
  return (
    <>
      <DashboardNavbar
        title={
          flag === "manufacturer"
            ? t("manufacturers")
            : flag === "whole_seller"
            ? t('wholeSalers')
            : flag === "retailer"
            ? t("retailers")
            : ""
        }
        backShow={false}
      />
      <div className="userList_">
        <div className="bookingsOverview_">
          <div className="verificationTotal">
            <div className="row mt-4">
              <div className="col-lg-12 d-flex justify-content-end">
                <ul className="weekNav">
                  <li
                    onClick={() => {
                      setFilterValue("today");
                    }}
                  >
                    <Link
                      className={`weekMenu ${
                        filterValue === "today" ? "active" : ""
                      }`}
                    >
                      {t("today")}
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setFilterValue("week");
                    }}
                  >
                    <Link
                      className={`weekMenu ${
                        filterValue === "week" || filterValue === "This week"
                          ? "active"
                          : ""
                      }`}
                    >
                      {t("weekly")}
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setFilterValue("month");
                    }}
                  >
                    <Link
                      className={`weekMenu ${
                        filterValue === "month" ? "active" : ""
                      }`}
                    >
                      {t("monthly")}
                    </Link>
                  </li>
                  {/* <li><Link className={`weekMenu ${filterValue==="today" ? "active": ""}`}>Quaterly</Link></li> */}
                  <li
                    onClick={() => {
                      setFilterValue("year");
                    }}
                  >
                    <Link
                      className={`weekMenu ${
                        filterValue === "year" ? "active" : ""
                      }`}
                    >
                      {t("yearly")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="verificationTotal_">
          <div className="row">
            {CountData?.length > 0 ? (
              CountData?.map((v, i) => {
                return (
                  <div
                    key={i}
                    className="col-sm-6 col-md-3 col-lg-3 customSpce"
                  >
                    <div className="percentageContainer justify-content-between align-items-center">
                      <div className="percentageValues">
                        <h3 className="mainHeadingsub_ ">
                          {v?.type === "Total Payment"
                            ?`$${addThousandSeparator((v?.total).toFixed(2))}`   
                            : v?.total}
                        </h3>
                        <p className="textinner_">{t(v?.type)}</p>
                      </div>
                      <div
                        className={`percentageRating ${
                          v?.analytics >= 0 ? "" : "percentageBg"
                        }`}
                      >
                        <img
                          style={{ width: "30px" }}
                          src={v?.analytics >= 0 ? shareMap : lossArrow}
                          className=""
                          alt="ShareMap"
                        />
                        <p
                          className={
                            v?.analytics >= 0
                              ? "percentageRate"
                              : "minusPercentageRate"
                          }
                        >
                          {v?.analytics?.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="tableSectionBackground">
          <div className="tableFihandleBrandDataChangelters tableFilters  mt-4 mb-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="productMainCategory">
                    <div className="categroyBox">
                      <select
                        className="cateSelectdFilter"
                        value={servicesId}
                        onChange={(e) => setServicesId(e.target.value)}
                      >
                        <option value="" selected>
                          {t("allCategory")}
                        </option>
                        {services?.map((val, index) => {
                          return (
                            <option value={val?.value} key={index}>
                              {val?.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="categroyBox">
                      <select
                        autoComplete="new-password"
                        className="customform-control  selectControl form-select"
                        value={addressId}
                        onChange={(e) => setAddressId(e.target.value)}
                      >
                        <option value="" selected>
                          {t("allLocation")}
                        </option>
                        {allAddress?.map((val, index) => {
                          return (
                            <option value={val?.id} key={index}>
                              {val?.format_address}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="productListTab productListBtn">
                    <button
                      className={`plansBtns ${
                        activeTab === "MonthlyBilling" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("MonthlyBilling")}
                    >
                      <img
                        src={productList1}
                        alt="image"
                        className="showImage"
                      />
                      <img
                        src={productList2}
                        alt="image"
                        className="hideImage"
                      />
                    </button>
                    <button
                      className={`plansBtns ${
                        activeTab === "AnnuallyBilling" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("AnnuallyBilling")}
                    >
                      <img
                        src={productList3}
                        alt="image"
                        className="hideImage"
                      />
                      <img
                        src={productList4}
                        alt="image"
                        className="showImage"
                      />
                    </button>
                  </div>
                </div>

                {/* <div className='col-md-12'>
                        <div className="calenderIcon">
                            <img src={calendar} alt="calender" />
                           <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> 
                            <DatePicker
                                className='cateDate'
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                placeholderText="Date"
                            />
                        </div>
                    </div>  */}
                {/*                 
                  <div className='col-md-4'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                             Category
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>
                    <div className='col-md-4'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                            All Location 
                            </option>
                            <option>location1</option>
                            <option>location2</option>
                        </select>
                    </div> */}
                {/* <div className='col-md-3'>
                        <select className="cateSelectdFilter" required>
                            <option value="" selected>
                                Id type
                            </option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                    </div>   */}
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="plansAreasContainer ">
              {activeTab == "MonthlyBilling" ? (
                <ManufacturList
                  loading={loading}
                  data={manufactureData}
                  totalRows={totalRows}
                  perPage={perPage}
                  setCurrentPage={setCurrentPage}
                  setPerPage={setPerPage}
                />
              ) : activeTab == "AnnuallyBilling" ? (
                <ManufactureView
                  loading={loading}
                  data={manufactureData}
                  totalRows={totalRows}
                  perPage={perPage}
                  setCurrentPage={setCurrentPage}
                  setPerPage={setPerPage}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manufacture;
