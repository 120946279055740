import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import Loader from "../../../components/UI/Loader/Loader";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

const UsersChatList = ({
  handleInviteUsers,
  handleTabChange,
  isB2b,
  loadingUserList,
  usersList,
  handleCurrentUser,
  userId,
  chatId,
  isChatFetching,
  sellerType,
  usersRef

}) => {
  const { t } = useTranslation();
    const [searchKeyword, setsearchKeyword] = useState("");
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [searchTimeout, setsearchTimeout] = useState(null);
    const [isSearching, setisSearching] = useState(false);
  const [usersConversation, setUsersConversation] = useState([]);
  useEffect(() => {
    setUsersConversation(usersList);
    setSearchedUsers(usersList);
  }, [usersList]);
  const [selectedUser, setSelectedUser] = useState();
  useEffect(() => {
    setSelectedUser(chatId);
  }, [chatId]);
   // search
    useEffect(() => {
      if(!isSearching)return;
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      let searchTimer = setTimeout(() => {
        setisSearching(false);
        const filterUser=usersConversation.filter(user=>{
          let firstname=user?.chatPartner?.firstname?.toLowerCase()||'';
          let lastname= user?.chatPartner?.lastname?.toLowerCase()||'';

          return firstname?.includes(searchKeyword.toLowerCase())||lastname?.includes(searchKeyword.toLowerCase())
        });
        setSearchedUsers(filterUser);
      }, 1000);
      setsearchTimeout(searchTimer);
      return () => {
        clearTimeout(searchTimeout);
      };
    }, [searchKeyword]);
  return (
    <>
      {/* left side user's list */}
      {/* <div className="col-md-4 pe-0"> */}
        <div className="chat_inbox Inbox">
          <div className="chatLeftHeader">
            <div className="chatLeftHeaderInner flexBox justify-content-between gap-2">
              <h3 className="fontSize24 fw500 txtBlue mb-0">{t("inbox")}</h3>
              {/* <div className="d-flex align-items-center gap-3">
                <div className="searchBox_ mt-2 p-0">
                <div style={{width:"150px"}} className="input-group search_group">
                                <img
                                  src={Images.SearchIcon}
                                  alt=""
                                  className="img-fluid searchIcon"
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>{
                                    setisSearching(true);
                                    setsearchKeyword(e.target.value)}}
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                 </div>
                </div>
                <div onClick={handleInviteUsers}>
                  <img
                    src={Images.darkblueEditIcon}
                    alt="img"
                    width={24}
                    height={24}
                  />
                </div>
              </div> */}
            </div>
            {/* <ul className="planTabs_ editVariantTab chatHeaderToggle ms-0">
            {
              ["whole_seller", "retailer" ,"manufacturer"].includes(sellerType)&&
            <li
                className={`planttabslist ${isB2b === "true" ? "active" : ""}`}
                onClick={() => handleTabChange("true")}
              >
                Business <span className="activeProductofferItem"></span>
              </li>
            }  
              
            {["whole_seller"].includes(sellerType)&&  <li
                className={`planttabslist ${isB2b === "false" ? "active" : ""}`}
                onClick={() => handleTabChange("false")}
              >
                Consumer <span className="activeProductofferItem"></span>
              </li>}
            </ul> */}
          </div>
          <div ref={usersRef} className="chatList position-relative">
            {loadingUserList ? (
              <Loader />
            ) : (
              searchedUsers?.length>0? searchedUsers?.map((user, index) => (
                <div
                  onClick={() => {
                    if(isChatFetching?.current||selectedUser==user.chats?.chathead_id)return;
                    handleCurrentUser(user);
                    setSelectedUser(user.chats?.chathead_id);
                  }}
                  key={index}
                  className={`chatListBox ${user?.notifications && "pending"} ${
                    selectedUser &&
                    selectedUser == user?.chats?.chathead_id &&
                    "active"
                  } cursorPointer`}
                >
                  <div className="flexBox justify-content-between">
                    <h3 className="chatSenderName fontSize12 fw500">
                      {
                      `${user?.chatPartner?.firstname} ${user?.chatPartner?.lastname}`
                      }
                    </h3>
                    <h6 className="fontSize10 fw500 txtDarkblue">
                      {
                        // user?.chats?.updatedAt
                        moment(user?.chats?.updatedAt).format("hh:mm A")
                      }
                    </h6>
                  </div>
                  <div className="flexBox justify-content-between">
                    <h6 className="fontSize10 chatInboxMess fw500 mb-0">
                      {user?.chats?.content}
                    </h6>
                    <div className="flexBox gap-1">
                      {user?.chats?.participants?.[userId]?.notificationCount >
                        0 && (
                        <h6 className="pendingMessCount">
                          {
                            user?.chats?.participants?.[userId]
                              ?.notificationCount
                          }
                        </h6>
                      )}

                      {user?.participants?.[userId]?.isPin && (
                        <img
                          src={Images.activePinChat}
                          alt="img"
                          className="cursorPointer"
                          width={16}
                          height={16}
                        />
                      )}

                      {user?.participants?.[userId]?.isMuted && (
                        <img
                          src={Images.muteLogo}
                          alt="img"
                          className="cursorPointer"
                          width={16}
                          height={16}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )):<>
              <div className="text-center pt-5">No User found</div>
              </>
            )}
          </div>
        </div>
      {/* </div> */}
      {/* left side user's list */}
    </>
  );
};

export default UsersChatList;