import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Images from "../../../utilities/images";
import ChartArea from "../dashboard/wallet/ChartArea";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getPosCountAsync,
  getPosGraphApiAsync,
  getSalesPerPosApiAsync,
} from "../../containers/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPosUsersAsync, selectLoginAuth } from "../../containers/auth/authSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import MainHeader from "../../components/UI/Orders/MainHeader";
import { MyPosStaticsArray } from "../../constants/payrollAndStaff/overview";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import { useTranslation } from "react-i18next";
import { onErrorStopLoad, setGetStaffUsers } from "../bookings/bookingSlice";

const MyPos = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [filterSalesValue, setFilterSalesValue] = useState("week");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [countData, setCountData] = useState([]);
  const [salesPerPosData, setSalesPerPosData] = useState([]);
  const [graphData, setGraphData] = useState("");
  const [filterValue, setFilterValue] = useState("week")
    // get staff users list
  const [staffUserListDetails, setstaffUserListDetails] = useState([]);

  const handleFilterValue = (value) => {
    setFilterValue(value);
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          dash: [2, 2],
          display: false,
          color: "rgba(180, 190, 235, 1)",
        }
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: "#7E8AC1",
        },
      },
    }
  };
  const lineData = {
    labels: graphData?.labels,
    datasets: [
      {
        label: "sales",
        data: graphData?.datasets ? graphData?.datasets[0]?.data : "",
        borderColor: "#275AFF",
        backgroundColor: "#275AFF",
      },
    ],
  };

  const data = {
    labels: salesPerPosData?.map(
      (v) => v?.pos_user_details?.user_profiles?.firstname
    ),
    // labels: ['pos1', 'pos2', 'pos3', 'pos4', 'pos5'],
    datasets: [
      {
        label: "Sales",
        data: salesPerPosData?.map((v) => v?.pos_user_sales_count),
        // data: [50, 45, 25, 48, 36],
        borderColor: "#275AFF",
        backgroundColor: "#275AFF",
      },
      {
        label: "Returned",
        data: salesPerPosData?.map((v) => v?.pos_user_returned_count),
        // data: [8, 5, 9, 8, 7],
        borderColor: "#FB466C",
        backgroundColor: "#FB466C",
      },
    ],
  };

  const getPosOrderCount = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter_by: filterValue
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosCountAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setCountData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };



  const getPosGraphData = () => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        filter_by: filterValue
        // filterSalesValue === "This week"
        //   ? "week"
        //   : filterSalesValue?.toLowerCase(),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getPosGraphApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setGraphData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const salesPerPosHandle = () => {
    let params = {
      postData: {
        // seller_id: auth?.payload?.id ? auth?.payload?.id : auth?.payload?.user?.id,
        filter: filterValue,
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getSalesPerPosApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setSalesPerPosData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getUserPosDetails = async () => {
    const prams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        limit: 5,
        page: 1,
        ...(filterValue && { filter: filterValue})
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getPosUsersAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setGetStaffUsers(res));
        setstaffUserListDetails(res?.payload?.pos_staff || []);

      })
      .catch((err) => {
        dispatch(onErrorStopLoad());
      });
  };
  useEffect(() => {
    getUserPosDetails();
  }, [filterValue])

  useEffect(() => {
    getPosOrderCount();
    getPosGraphData();
    salesPerPosHandle();
  }, [filterSalesValue, filterValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Payroll & Staff";
  }, []);

  return (
    <div className="myOrdersmain myPos">
      <DashboardNavbar title={t("overview")} show={true} />
      <MainHeader
        handleFilterValue={handleFilterValue}
        filterValue={filterValue}
        enableFutureDate={true}
      />
      {loading ? (
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <>
          <DashboardNavbar title={t("overview")} show={true} />
          <div className="verificationTotal commonMainHeaderParent">
            <div className="row">
              {/* {countData?.map((val, index) => {
                return (
                  <div
                    onClick={() =>
                      history.push({
                        pathname:
                          val?.name === "Total Sales"
                            ? "/posSales"
                            : "/transections",
                        state:
                          val?.name === "Total Sales"
                            ? {
                                isWalkIn: true,
                                filter: filterSalesValue,
                                isNeedAllPickup: true,
                                isNeedReturnFalse: true,
                              }
                            : val?.name === "Order Delivered"
                            ? {
                                status: "5",
                                isWalkIn: true,
                                filter: filterSalesValue,
                                isNeedAllPickup: false,
                                isNeedReturnFalse: true,
                              }
                            : val?.name === "Order Picked Up"
                            ? {
                                status: "6",
                                filter: filterSalesValue,
                                appName: "b2c",
                                isNeedReturnFalse: true,
                              }
                            : val?.name === "Delivery Returned"
                            ? { status: "9", filter: filterSalesValue }
                            : "",
                      })
                    }
                    className="col-sm-6 col-md-3 col-lg-3 customSpce"
                    key={index}
                  >
                    <div className="percentageContainer justify-content-between align-items-center">
                      <div className="percentageValues">
                        <h3 className="mainHeadingsub_ ">{val?.value}</h3>
                        <p className="textinner_">{val?.name}</p>
                      </div>
                      <div
                        className={`percentageRating ${
                          val?.analytics >= 0 ? "" : "percentageBg"
                        }`}
                      >
                        <img
                          style={{ width: "30px" }}
                          src={val?.analytics >= 0 ? shareMap : lossArrow}
                          className=""
                          alt="ShareMap"
                        />
                        <p
                          className={
                            val?.analytics >= 0
                              ? "percentageRate"
                              : "minusPercentageRate"
                          }
                        >
                          {val?.analytics?.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })} */}
              {MyPosStaticsArray?.map((headerStatic, index) => (
                <HeaderStatistics
                  className={headerStatic?.className}
                  img={Images?.[headerStatic?.img]}
                  value={headerStatic?.heading === countData?.[index]?.name ? countData?.[index]?.value : 0}
                  heading={t(headerStatic?.headingText)}
                />
              ))}
            </div>
          </div>
          <div className="tradesValue">
            <div className="row">
              <div className="col-md-12">
                <div
                  onClick={() => history.push("/posSales")}
                  className="chartsOuter"
                >
                  <h3 className="textInnerHead">{t('sales')}</h3>
                  {/* <div className="processStatus mt-3">
                    <p className="statusbox_">
                      <span className="complete_"></span> {t('sales')}
                    </p>
                  </div> */}
                  {/* <div className='row mt-4 mb-4 align-items-center'>

                                <div className='col-md-12'>
                                    <img src='' alt="Graph Image" className='img-fluid' />
                                </div>
                            </div> */}
                  <ChartArea
                    classes="productSales mt-2 mb-2"
                    header=""
                    options={options1}
                    data={lineData}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* top staff details */}
          <div className="sellingProductContainer payrollOverviewTableParent mt-3 h-auto">
            <div className="flexBox gap-2">
              <h2 className="fontSize20 txtBlue fw500 mb-0">{t("topStaffs")}</h2>
              <Link to="/posUser">
                <button className="viewAllbtn_" type="submit">
                  {t("viewAll")}
                </button>
              </Link>
            </div>

            <div className="commonTable sellingProductTable table-responsive mt-3">
              <table className="w-100">
                <tbody>
                  {
                    staffUserListDetails?.length > 0 ? staffUserListDetails.map((staff, index) => {
                      let userDetails = staff?.user?.user_profiles;
                      return <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={userDetails?.profile_photo || Images.userImg_}
                              alt="img"
                              className="staffImg"
                            />
                            <div className="text-start">
                              <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1">
                                {
                                  userDetails?.firstname
                                }
                              </h3>
                              <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                                {userDetails?.lastname}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">{
                            staff?.weekly_tracking_limit
                          }</h5>
                          <h6 className="fontSize10 fw500 txtLightBlue mb-0">{t("hours")}</h6>
                        </td>
                      </tr>
                    }) : <tr>
                      <td colSpan="2" className="text-center">{t("noDataAvailable")}</td>
                    </tr>
                  }

                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default MyPos;
