import React from 'react'
import OrderTrackingDetails from './OrderTrackingDetails';
import GoogleMapView from '../../../components/UI/Orders/GoogleMapView';
import OrderTrackStatusCard from '../orders/OrderTrackStatusCard';

const OrderDetails = ({orderDetails,getAllOrderDetails,setshowOrderInvoice,getallOrders}) => {
 let status=orderDetails?.status;
 let deliveryOption=orderDetails?.delivery_option;
 let deliveryStatus=orderDetails?.status;
 const pickupLatitude = parseFloat(
  (deliveryOption==1&&deliveryStatus>=4&&orderDetails?.order_delivery)?
  orderDetails?.order_delivery?.order_pickup_latitude:
  orderDetails?.coordinates?.[0]
);
const pickupLongitude = parseFloat(
  (deliveryOption==1&&deliveryStatus>=4&&orderDetails?.order_delivery)?
  orderDetails?.order_delivery?.order_pickup_longitude:
  orderDetails?.coordinates?.[1]
);
const deliverLatitude = parseFloat(
 (deliveryOption==1&&deliveryStatus>=4&&orderDetails?.order_delivery)? orderDetails?.order_delivery?.order_delivery_latitude:
 orderDetails?.seller_details?.seller_location?.[1]
);
const deliverLongitude = parseFloat(
  (deliveryOption==1&&deliveryStatus>=4&&orderDetails?.order_delivery)? orderDetails?.order_delivery?.order_delivery_longitude:
  orderDetails?.seller_details?.seller_location?.[0]
);
  return    <div
  className={`position-relative radius20 ${
    !false
      ? "bg-white p-2"
      : "w-100 d-flex justify-content-end align-items-end h-100"
  }`}
  style={{ height: "100%" }}
>
  {( status === 0 ||
      status === 1 ||
      status === 2 ||
      status === 8 ||
      (status === 7 && orderDetails?.is_delivery_dispute === false) ||
      // pickedUpPinButton ||
      status === 3 ||orderDetails?.delivery_option==4) ? (
    <OrderTrackingDetails
    getAllOrderDetails={getAllOrderDetails}
    getallOrders={getallOrders}
      orderDetails={orderDetails}
      setshowOrderInvoice={(value) => {
        setshowOrderInvoice(value);
      }}
    />
  ) : (
    <>
        <GoogleMapView
          latitude={pickupLatitude ||0}
          longitude={pickupLongitude||0}
          destination={{
            lat: deliverLatitude||0,
            lng: deliverLongitude||0,
          }}
        />
    <OrderTrackStatusCard
      orderDetails={orderDetails}
      getallOrders={getallOrders}
    />
    </>
  )}
</div>
}

export default OrderDetails