import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

export function Spacer({ space, horizontal, backgroundColor, style }) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        spacerStyle: {
          [horizontal ? 'width' : 'height']: space,
          backgroundColor: backgroundColor || 'transparent',
          style: style,
        },
      }),
    [horizontal, space, backgroundColor, style]
  );

  return <View style={[styles.spacerStyle]} />;
}
