import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";

const CreateServiceOfferLocation = (props) => {
  const {
    allAddress,
    sellerAddressIds,
    setSellerAddressIds,
    deliveryOptions,
    setDeliveryOptions,
    formattedVariants,
    uniqueSellerAddressIds,
  } = props;

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        return [...prevOptions, option];
      } else {
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };
  return (
    <section className="offerLocation_Section">
      <h2 className="fontSize24 fw500 txtBlue mb-4">Locations & Channels</h2>
      <div className="outerArea EditVariation_ModalBox ">
        <h3 className="fontSize18 fw500 txtDarkblue">Offer availability</h3>

        <div className="productAvail_Second">
          <div className="d-flex align-items-center">
            <img
              src={Images.physicalStoreIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">Physical store</h3>
                <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            {allAddress
              .filter((store) => uniqueSellerAddressIds.includes(store.id))
              .map((store, idx) => (
                <div key={store.id} className="productAvailabilityChild mt-3">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id={`store-${store.id}`}
                      checked={sellerAddressIds.includes(store.id)}
                      onChange={handleStoreToggle(store.id)} // Correct usage
                    />
                    <label htmlFor={`store-${store.id}`}></label>
                  </div>
                  <div>
                    <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                      Store {idx + 1}
                      {/* {store.id} - {store.format_address} */}
                    </h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      {store.street_address}, {store.city}, {store.state}{" "}
                      {store.zipcode}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* <div className="jobrMarketBox">
          <div className=" d-flex align-items-center">
            <img
              src={Images.jobrMarketplaceIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  JOBR Marketplace
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Online fulfillment methods
                </h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="reservation"
                  checked={deliveryOptions.includes("reservation")}
                  onChange={handleDeliveryOptionToggle("reservation")}
                />
                <label for="reservation"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Online Booking</h3>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default CreateServiceOfferLocation;
