import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { getAllCatalogsApiAsync } from "../../dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleCatalogSize } from "../../../constants/messageBox/inbox.constant";
import { selectLoginAuth } from "../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";

const CatalogModal = ({close,getCatalog}) => {
  const [allCatalogs, setallCatalogs] = useState([]);
  const [selectCatalog, setselectCatalog] = useState(-1);
const auth = useSelector(selectLoginAuth);

 const dispatch= useDispatch();
  const getCatalogs=async()=>{
      console.log("Check token",auth?.payload)
      if(auth?.payload==null||auth?.payload==undefined)return;
      let params = {
          postData: {
            filter_by: "my",
            page: 1,
            limit: 10,
          },
          tokenData: auth?.payload?.token,
        };
      dispatch(getAllCatalogsApiAsync(params))
      .then(unwrapResult)
      .then(res=>{
          setallCatalogs(res?.payload?.data||[]);
      })
      .catch(err=>{

      })
  }
  
  useEffect(()=>{
      getCatalogs();
      return ()=>{
          setallCatalogs([])
         setselectCatalog(-1); 
      }
  },[]);
  const handleCatalog=(index)=>{
      setselectCatalog(index);
  }
  return (
    <>
      <div className="shippingModalParent">
      {
      allCatalogs.length>1?  allCatalogs?.map((catalog,index)=>(
          <div key={index} className={`shippingBoxes ${selectCatalog===index?"active":''}`}>
          <figure className="shippingImgParent p-0">
            <img
              src={catalog?.url||Images.catalogModalImg}
              alt="img"
              className="h-100 w-100"
            />
          </figure>
          <div>
            <h3 className="fontSize18  txtDarkblue">{catalog?.caption
}</h3>
            <h5 className="fontSize14 txtLightBlue mb-1">{catalog?.mime_type
} | {handleCatalogSize(catalog?.size||10000)}</h5>
            <h5 className="fontSize14 txtLightBlue flexBox gap-1 mb-0">
              <img src={Images.catalogCheck} alt="img" />
              Last Update: {
                            moment(catalog?.updated_at).format('YYYY-MM-DD')
                            }
            </h5>
          </div>
        </div>
        )):<>
        <p>
          No catalog found. Please upload a catalog to share. 
        </p>
        
        </>
        
        }
      </div>
      <button 
      disabled={selectCatalog==-1}
      onClick={()=>{
                getCatalog(allCatalogs[selectCatalog],"catalog");
                close();
            }}  className="commonBlueBtn w-100 active">Share</button>
    </>
  );
};

export default CatalogModal;
