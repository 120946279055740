import React, { useState } from "react";
import Loader from "../../../components/UI/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth, selectUserSettings } from "../../auth/authSlice";
import {
  getUserSettingsApiAsync,
  updateUserSettingsApiAsync,
} from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CustomerNotification = () => {
  const { t } = useTranslation();
  const options = {
    confirm_booking: false,
    booking_reminder: false,
    cancelled_booking: true,
    rescheduling_booking: false,
    staff_assignment: true,
  };
  const [notification, setNotification] = useState([
    {
      title: "In-app notifications",
      key: "app_notifications",
      options: { ...options },
      languageTitle:"inAppNotification"
    },
    {
      title: "SMS Notification",
      options: { ...options },
      key: "sms_notifications",
      languageTitle:"smsNotification"
    },
    {
      title: "Email Notification",
      options: { ...options },
      key: "email_notifications",
      languageTitle:"emaliNotification"
    },
  ]);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [userSettings, setUserSettings] = useState(null);
      const userSettingRedux=useSelector(selectUserSettings);
  
  const [loading, setLoading] = useState(false);
  const getUserSettings = async () => {
    let prams = {
      postData: {
        app_name: "merchant",
        // seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setUserSettings(res?.payload);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserSettings();
  }, []);
  const updateUserSettings = async (data) => {
    let prams = {
      postData: {
        ...data,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false);
        // setUserSettings(res.data);
      })
      .catch((res) => {
        setLoading(false);
      });
  };
  const handleUserSetting = (parentKey, key, value, index) => {
    let newCustomNotification = [...notification];
    newCustomNotification[index].options[key] = value;
    setNotification(newCustomNotification);
    setUserSettings((prev) => ({
      ...prev,
      [parentKey]: {
        ...userSettings[parentKey],
        [key]: value,
      },
    }));
    setUserSettings((prev) => ({
      ...prev,
      [key]: value,
    }));
    updateUserSettings({
      [parentKey]: {
        [key]: value,
      },
    });
  };
  return (
    <>
      {loading && <Loader />}
      <div className="customerNotification_">
        <div className="processignOrder featureseting rightContentorder_">
          {notification?.map((notify, index) => (
            <div
              className={`allNotofication_  ${index >= 1 ? "mt-5" : ""}`}
              key={index}
            >
              <h3 className="headblue18">{t(notify.languageTitle)}</h3>
              <div className="notification_">
                {Object.entries(notify?.options).map((option, indexs) => (
                  <div className="toggleNotification_ mb-2" key={indexs}>
                    <h5 className="headingblue14_ text-capitalize">{t(option[0])}</h5>
                    <input
                      type="checkbox"
                      checked={userSettings?.[notify?.key]?.[option[0]]}
                      onChange={(event) => {
                        let checked = event.target.checked;
                        handleUserSetting(
                          notify.key,
                          option[0],
                          checked,
                          index
                        );
                      }}
                      id="toggle"
                      name="toggle"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CustomerNotification;
