import React from "react";
import * as Images from "../../../../utilities/images";
import { useTranslation } from "react-i18next";
const CustomModalHeader = ({ modalDetail, handleOnCloseModal }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modalHeader_">
        <div className="common_">
          {modalDetail.flag === "UploadPhoto" ? (
            <h2 className="modalHeading">Image upload</h2>
          ) : modalDetail.flag === "VideoCall" ? (
            <h2 className="modalHeading">Schedule for Video call</h2>
          ) : modalDetail.flag === "MakeOffer" ? (
            <h2 className="modalHeading">Make an Offer</h2>
          ) : modalDetail.flag === "FileUpload" ? (
            <h2 className="modalHeading">File Upload</h2>
          ) : modalDetail.flag === "BusinessCard" ? (
            <>
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                Business Card
              </h2>
              <img
                src={Images.modalCross}
                alt="img"
                className="cursorPointer"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : modalDetail.flag === "VoiceRecord" ? (
            <h2 className="fontSize24 fw500 txtDarkblue mb-0">{t('recordAudio')}</h2>
          ) : modalDetail.flag === "ShippingAddress" ? (
            <>
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                Shipping Address
              </h2>
              <img
                src={Images.modalCross}
                alt="img"
                className="cursorPointer"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : modalDetail.flag === "uploadFiles" ? (
            <>
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                {t('uploadImage')}
              </h2>
              <div className="cursorPoint"
                onClick={() => {
                  handleOnCloseModal();
                }}
              >
                <img src={Images.modalCross} alt="img" />
              </div>
            </>
          ) : modalDetail.flag === "Catalog" ? (
            <>
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">Catalog</h2>
              <img
                src={Images.modalCross}
                alt="img"
                className="cursorPointer"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default CustomModalHeader;   