import React, { useCallback, useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify";
import ImageUploader from "./imageUploader";
import { getColorName } from "../../../utilities/helpers";

const EditVariantModal = ({
  modalDetail,
  allAddress,
  variants,
  setVariants,
  closeModal,
}) => {
  const toastId = useRef(null);
  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };
  const { index, variationname } = modalDetail;
  const [activeTab, setActiveTab] = useState("details");
  const [fileList, setFileList] = useState(variants[index]?.images || []);
  const [activePos, setActivePos] = useState(true);
  const [updateVarient, setUpdateVarient] = useState([...variants]);
  const [sellerAddressIds, setSellerAddressIds] = useState(
    variants[index]?.seller_address_ids || []
  );

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleToggleTab = (plan) => {
    setActiveTab(plan);
  };

  const handleInputChange = (field, value) => {
    const updatedVariants = [...updateVarient];

    if (field === "pos" || field === "b2c") {
      const appName = field; // Use the field name as app_name
      const variant = updatedVariants[index];

      // Ensure prices array exists
      if (!variant.prices) variant.prices = [];

      // Check if the price object with the app_name exists; if not, create it
      const priceObj = variant.prices.find(
        (price) => price.app_name === appName
      );
      if (!priceObj) {
        variant.prices.push({
          app_name: appName,
          selling_price: "",
          margin_percentage: "",
        });
      }

      // Calculate margin percentage if cost price is available
      const costPrice = parseFloat(variant.cost_price || 0);
      const sellingPrice = parseFloat(value);

      let marginPercentage = 0;
      if (costPrice && sellingPrice) {
        marginPercentage = ((sellingPrice - costPrice) / sellingPrice) * 100;
      }

      // Update the price object for the given app_name
      variant.prices = variant.prices.map((price) => {
        if (price.app_name === appName) {
          return {
            ...price,
            selling_price: value,
            margin_percentage: marginPercentage.toFixed(2), // Keep two decimal points
          };
        }
        return price;
      });
    } else {
      updatedVariants[index][field] = value; // Update other fields directly
    }

    setUpdateVarient(updatedVariants); // Update the state
  };

  // Handle unit change
  const handleUnitChange = (e) => {
    handleInputChange("unit", e.target.value); // Update unit field in the variant
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    // Update sellerAddressIds
    setSellerAddressIds((prevIds) => {
      const updatedIds = isChecked
        ? [...prevIds, storeId]
        : prevIds.filter((id) => id !== storeId); // Remove the storeId if unchecked

      // Update the variants array with the new sellerIds
      const updatedVariants = [...variants];
      updatedVariants[index] = {
        ...updatedVariants[index],
        seller_address_ids: updatedIds, // Assign updated sellerIds to the variant
      };

      setUpdateVarient(updatedVariants); // Update the variants state with the updated variant
      return updatedIds; // Return the updated sellerAddressIds
    });
  };

  const handlePriceToggle = (priceName, e) => {
    const isChecked = e.target.checked;

    setUpdateVarient((prevVariants) => {
      const updatedVariants = [...prevVariants];
      const currentVariant = { ...updatedVariants[index] }; // Create a shallow copy of the variant

      if (!currentVariant.prices) {
        currentVariant.prices = []; // Initialize if undefined
      } else {
        currentVariant.prices = [...currentVariant.prices]; // Create a shallow copy of prices
      }

      if (isChecked) {
        // Add the price object if not already present
        const priceExists = currentVariant.prices.some(
          (price) => price.app_name === priceName
        );
        if (!priceExists) {
          currentVariant.prices.push({
            app_name: priceName,
            price_type: "fixed",
            selling_price: "",
            margin_percentage: "",
          });
        }
      } else {
        // Remove the price object if unchecked
        currentVariant.prices = currentVariant.prices.filter(
          (price) => price.app_name !== priceName
        );
      }

      updatedVariants[index] = currentVariant; // Replace the updated variant in the array
      return updatedVariants;
    });
  };

  const handleSave = () => {
    const currentVariant = updateVarient[index];
    // Check if UPC exists and is at least 14 characters long
    if (!currentVariant?.barcode || currentVariant?.barcode?.length < 14) {
      showToast("UPC must be 14 characters long and cannot be empty.");
      return;
    }

    const barCodes = updateVarient.map((variant) => variant.barcode);
    const isDuplicate =
      barCodes.filter((barcode) => barcode === updateVarient[index].barcode)
        .length > 1;

    if (isDuplicate) {
      showToast("UPC must be unique across all variants.");
      return;
    }

    // Validate Quantity and Quantity Re-order Point
    const quantity = parseInt(currentVariant.quantity || 0);
    const reorderPoint = parseInt(currentVariant.quantity_reorder_point || 0);

    if (reorderPoint > quantity) {
      showToast("Quantity Re-order Point cannot be greater than Quantity.");
      return;
    }

    setVariants(updateVarient);
    closeModal();
  };

  useEffect(() => {
    if (variants && variants[index]) {
      const updatedVariants = [...variants];

      if (fileList && fileList.length > 0) {
        updatedVariants[index] = {
          ...updatedVariants[index],
          image: fileList[0],
          images: [...fileList],
        };
      } else {
        updatedVariants[index] = {
          ...updatedVariants[index],
          image: "",
          images: [],
        };
      }

      setUpdateVarient(updatedVariants);
    }
  }, [fileList]); // Run this effect whenever fileList changes

  return (
    <>
      {/* <ul className="planTabs_ editVariantTab m-0">
        <li
          className={`planttabslist ${activeTab === "details" ? "active" : ""}`}
          onClick={() => handleToggleTab("details")}
        >
          Details
        </li>
        <li
          className={`planttabslist ${
            activeTab === "manageStock" ? "active" : ""
          }`}
          onClick={() => handleToggleTab("manageStock")}
        >
          Manage Stocks
        </li>
        <li
          className={`planttabslist ${
            activeTab === "attributes" ? "active" : ""
          }`}
          onClick={() => handleToggleTab("attributes")}
        >
          Custom Attributes
        </li>
      </ul> */}
      <h3 className="fontSize24 fw500 txtDarkblue mb-0">Variant Detail</h3>

      {activeTab == "details" && (
        <>
          <div className="detailTab">
            <div className="form_group mt-3 mb-5">
              <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                Variation name
              </label>
              <div className="inputGroup">
                <input
                  className="customInput editVariantInput textCapital"
                  placeholder="Blue/L"
                  name="text"
                  type="text"
                  // value={variationname}
                  value={
                    variationname.startsWith("#")
                      ? getColorName(variationname)
                      : variationname
                  }
                />
              </div>
            </div>

            <div className="outerArea EditVariation_ModalBox">
              <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                Product availability
              </h3>

              <div className="d-flex align-items-start mt-0">
                <img
                  src={Images.physicalStoreIcon}
                  alt="logo"
                  className="physicalStoreIcon me-3"
                />
                <div>
                  <div className="innnerParent">
                    <div className="productSale_Parent p-0 m-0">
                      <input
                        type="checkbox"
                        checked={updateVarient[index]?.prices?.some(
                          (price) => price.app_name === "pos"
                        )}
                        onChange={(e) => handlePriceToggle("pos", e)}
                      />
                    </div>
                    <div>
                      <h3 className="fontSize14 fw500 txtDarkblue">
                        Physical stores
                      </h3>
                      <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
                    </div>
                  </div>
                  {/* {allAddress.map((store, idx) => (
                    <div
                      key={store.id}
                      className="productAvailabilityChild mt-4"
                    >
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id={`store${store.id}`}
                          checked={sellerAddressIds.includes(store.id)}
                          onChange={handleStoreToggle(store.id)} // Correct usage
                        />
                        <label htmlFor={`store${store.id}`}></label>
                      </div>
                      <div>
                        <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                          Store {idx + 1}
                          
                        </h3>
                        <p className="fontSize12 txtLightBlue mb-0">
                          {store.street_address}, {store.city}, {store.state}{" "}
                          {store.zipcode}
                        </p>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>

              <div className="mt-5 d-flex align-items-start">
                <img
                  src={Images.jobrMarketplaceIcon}
                  alt="logo"
                  className="physicalStoreIcon me-3"
                />
                <div>
                  <div className="innnerParent">
                    <div className="productSale_Parent p-0 m-0">
                      <input
                        type="checkbox"
                        checked={updateVarient[index]?.prices?.some(
                          (price) => price.app_name === "b2c"
                        )}
                        onChange={(e) => handlePriceToggle("b2c", e)}
                      />
                    </div>
                    <div>
                      <h3 className="fontSize14 fw500 txtDarkblue">
                        JOBR Marketplace
                      </h3>
                      <h3 className="fontSize12 txtLightBlue mb-0">
                        Allow customers to book this service on JOBR Marketplace
                        app.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="editVariant_SecondBox">
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">UPC</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="888 1235647890"
                          name="barcode"
                          type="number"
                          maxLength={14}
                          value={updateVarient[index]?.barcode || ""}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue.length <= 14) {
                              handleInputChange("barcode", inputValue);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Unit</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <select
                        className="uptBoxSelect"
                        value={updateVarient[index]?.unit || ""}
                        onChange={handleUnitChange}
                      >
                        <option value="per_item">Per Item</option>
                        <option value="per_unit">Per Unit</option>
                        <option value="per_box">Per Box</option>
                        <option value="per_bag">Per Bag</option>
                        <option value="per_pound">Per Pound</option>
                        <option value="per_feet">Per Feet</option>
                        <option value="per_gallon">Per Gallon</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">POS Price</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="$0"
                          type="number"
                          value={
                            Array.isArray(updateVarient[index]?.prices)
                              ? updateVarient[index]?.prices.find(
                                  (p) => p.app_name === "pos"
                                )?.selling_price || ""
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange("pos", e.target.value)
                          }
                          disabled={
                            !Array.isArray(updateVarient[index]?.prices) ||
                            !updateVarient[index].prices.some(
                              (price) => price.app_name === "pos"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Marketplace Price</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="$0"
                          type="number"
                          value={
                            Array.isArray(updateVarient[index]?.prices)
                              ? updateVarient[index]?.prices.find(
                                  (p) => p.app_name === "b2c"
                                )?.selling_price || ""
                              : ""
                          }
                          onChange={(e) =>
                            handleInputChange("b2c", e.target.value)
                          }
                          disabled={
                            !Array.isArray(updateVarient[index]?.prices) ||
                            !updateVarient[index].prices.some(
                              (price) => price.app_name === "b2c"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Quantity</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="Quantity"
                          name="quantity"
                          type="number"
                          maxLength={14}
                          value={updateVarient[index]?.quantity || ""}
                          onChange={(e) => {
                            handleInputChange("quantity", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">
                        Quantity Re-order Point
                      </h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="Quantity Re-order Point"
                          name="quantity_reorder_point"
                          type="number"
                          maxLength={14}
                          value={
                            updateVarient[index]?.quantity_reorder_point || ""
                          }
                          onChange={(e) => {
                            handleInputChange(
                              "quantity_reorder_point",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Weight</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox position-relative">
                      <div className="inputGroup">
                        <input
                          onKeyDown={blockInvalidChar}
                          className="upcBox_Input"
                          placeholder="0"
                          type="number"
                          value={updateVarient[index]?.weight || ""}
                          onChange={(e) =>
                            handleInputChange("weight", e.target.value)
                          }
                        />
                      </div>
                      <span className="weightMeasurementTxt">lb</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ImageUploader fileList={fileList} setFileList={setFileList} />
            <div className="d-flex align-items-center editVariantModal_Footer">
              <button
                className="modalDiscardBtn w-100"
                onClick={() => closeModal()}
              >
                Discard
              </button>
              <button
                className="modalNextBtn modalDiscardBtn active w-100"
                onClick={handleSave}
                type="button"
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}

      {activeTab == "manageStock" && (
        <>
          <h3 className="fontSize24 fw500 txtDarkblue pt-4">
            Locations and stocks
          </h3>

          <div className="modalOptionBox">
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input
                  type="checkbox"
                  onChange={() => setActivePos(!activePos)}
                />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  Daugherty LLC 01
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  9 Indrio Blvd, Satellite Beach, Florida 32937, USA
                </h3>
              </div>
            </div>
            <div className="editVariant_SecondBox mt-3">
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Stock action</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <select
                        name="cars"
                        id="cars"
                        className="uptBoxSelect fw400"
                      >
                        <option value="">Select reason</option>
                        <option value="saab">Per Item</option>
                        <option value="mercedes">Per Item</option>
                        <option value="audi">Per Item</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Add stock</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          placeholder="0"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent totalStockContainer">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Total stock</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="innnerParent mt-3 align-items-center">
              <div className="productSale_Parent p-0 m-0">
                <input
                  type="checkbox"
                  onChange={() => setActivePos(!activePos)}
                />
              </div>

              <h3 className="fontSize14 fw500 txtDarkblue mb-0">
                Inventory-Reorder Point
              </h3>
            </div>
          </div>

          <div className="modalOptionBox">
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input
                  type="checkbox"
                  onChange={() => setActivePos(!activePos)}
                />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  Daugherty LLC 01
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  9 Indrio Blvd, Satellite Beach, Florida 32937, USA
                </h3>
              </div>
            </div>
            <div className="editVariant_SecondBox mt-3">
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Stock action</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <select
                        name="cars"
                        id="cars"
                        className="uptBoxSelect fw400"
                      >
                        <option value="">Select reason</option>
                        <option value="saab">Per Item</option>
                        <option value="mercedes">Per Item</option>
                        <option value="audi">Per Item</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Add stock</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          placeholder="0"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent totalStockContainer">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Total stock</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="innnerParent mt-3 align-items-center">
              <div className="productSale_Parent p-0 m-0">
                <input
                  type="checkbox"
                  onChange={() => setActivePos(!activePos)}
                />
              </div>

              <h3 className="fontSize14 fw500 txtDarkblue mb-0">
                Inventory-Reorder Point
              </h3>
            </div>
          </div>

          <div className="d-flex align-items-center editVariantModal_Footer">
            <button
              className="modalDiscardBtn w-100"
              onClick={() => closeModal()}
            >
              Discard
            </button>
            <button className="modalNextBtn modalDiscardBtn active w-100">
              Save
            </button>
          </div>
        </>
      )}

      {activeTab == "attributes" && (
        <>
          <div className="attributesTab_Parent">
            <div className="editVariant_SecondBox">
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Custom Attribute</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          placeholder="Attribute name"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Value</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          name="text"
                          type="text"
                          placeholder="Value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button className="addOptionSetBtn w-100">
                <i className="fa-regular fa-plus addOptionPlusIcon me-2"></i>
                Add New Attribute
              </button>
            </div>
          </div>

          <div className="d-flex align-items-center editVariantModal_Footer">
            <button
              className="modalDiscardBtn w-100"
              onClick={() => closeModal()}
            >
              Discard
            </button>
            <button className="modalNextBtn modalDiscardBtn w-100">Save</button>
          </div>
        </>
      )}
    </>
  );
};

export default EditVariantModal;
