import React, { useEffect, useState } from "react";
import ChartArea from "../dashboard/wallet/ChartArea";
import { getUserByIdApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getCashFlowStatisticsApiAsync,
  getOrderWalletTransactionsAsync,
  getTransactionStatisticsApiAsync,
  getWalletBallanceApiAsync,
  selectMerchantStatus,
} from "../dashboard/dashboardSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import moment from "moment-timezone";
import HomeHeader from "../../components/UI/Orders/MainHeader";
import { useTranslation } from "react-i18next";


const WalletMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState({
    Reward: true,
    Card: true,
    Cash: true
  })
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const merchantStatus = useSelector(selectMerchantStatus);
  const [cashFlowData, setCashFlowData] = useState("");
  const [transactionStatistics, setTransactionStatistics] = useState([]);
  const [orderWalletTransactions, setOrderWalletTransactions] = useState();
  const [filterValue, setFilterValue] = useState("week");
  const [calanderDate, setCalanderDate] = useState(new Date())
  const history = useHistory();
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [sellerAddress, setsellerAddress] = useState("");

  const handleFilterValue = (value) => {
    setEndDate(null);
    setStartDate(null);
    setFilterValue(value);
  };
  
  const handleDateValue = (values) => {
    setFilterValue(null);
    if (values.startDate == values.endDate) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };

  function addThousandSeparator(number) {
    if(!number) return "00.00";
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          dash: [2, 2],
          display: false,
          color: "rgba(180, 190, 235, 1)",
        }
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          color: "#7E8AC1",
        },
      },
    }
  };

  let [dataSets, setDataSets] = useState([
    {
      fill: true,
      label: "Reward",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[0]
        : "",
      borderColor: "#4659B5",
      backgroundColor: "#4659B5",
      borderRadius: 12,
    },
    
    {
      fill: true,
      label: "Cash",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[1]
        : "",
      borderColor: "#32D583",
      backgroundColor: "#32D583",
      borderRadius: 12,
    },
    {
      fill: true,
      label: "Card",
      data: orderWalletTransactions?.graphData?.datasets
        ? orderWalletTransactions.graphData.datasets[2]
        : "",
      borderColor: "#58C2E8",
      backgroundColor: "#58C2E8",
      borderRadius: 12,
    },
  ]);

  const getUserDataByIdHandle = () => {
    let data = {
      postData: {
        id: auth?.payload?.id
          ? auth?.payload?.id
          : auth?.payload?.user?.user_profiles?.id,
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getUserByIdApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (merchantStatus?.payload?.wallet_status === true) {
          let data = {
            tokenData: auth?.payload?.token,
          };
          dispatch(getWalletBallanceApiAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setWalletBalance(obj?.payload?.sila_balance);
              setLoading(false);
            })
            .catch((obj) => {
              setLoading(false);
            });
        }
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const cashFlowStatisticsHandle = () => {
    let data = {
      postData: {
        filter:
          filterValue === "This week" ? "week" : filterValue?.toLowerCase(),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getCashFlowStatisticsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setCashFlowData(obj?.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: orderWalletTransactions?.graphData?.datasets
            ? orderWalletTransactions.graphData.datasets[num]
            : "",
          borderColor: color,
          backgroundColor: color,
          borderRadius: 12,
        },
      ]);
    } else {
      setDataSets(
        dataSets.filter((item) => item.label !== value))
    }
  };

  const getTransactionStatisticsApiAsyncHandle = () => {
    let data = {
      postData: {
          ...(startDate == null && { filter: filterValue }),
        ...(startDate && { start_date: startDate, end_date: endDate }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getTransactionStatisticsApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setTransactionStatistics(obj?.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getOrderWalletTransactionsAsyncHandler = () => {
    let data = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        ...(startDate === null && endDate === null && {
          filter: filterValue
        }),
        ...(sellerAddress && {
          seller_address_id: sellerAddress
        }),
        ...(startDate && endDate && {
           start_date : moment(startDate).format("YYYY-MM-DD"),
           end_date : moment(endDate).format("YYYY-MM-DD"),
        })
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getOrderWalletTransactionsAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setOrderWalletTransactions(obj?.payload);
        setDataSets([
          {
            fill: true,
            label: "Reward",
            data: obj?.payload?.graphData?.datasets
              ? obj?.payload.graphData.datasets[0]
              : "",
            borderColor: "#4659B5",
            backgroundColor: "#4659B5",
            borderRadius: 12,
          },
          {
            fill: true,
            label: "Cash",
            data: obj?.payload?.graphData?.datasets
              ? obj?.payload.graphData.datasets[1]
              : "",
            borderColor: "#32D583",
            backgroundColor: "#32D583",
            borderRadius: 12,
          },
          {
            fill: true,
            label: "Card",
            data: obj?.payload?.graphData?.datasets
              ? obj?.payload.graphData.datasets[2]
              : "",
            borderColor: "#58C2E8",
            backgroundColor: "#58C2E8",
            borderRadius: 12,
          },
        ])
        setChecked({
          Reward: true,
          Card: true,
          Cash: true
        })
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserDataByIdHandle();
    cashFlowStatisticsHandle();
    getTransactionStatisticsApiAsyncHandle();
    getOrderWalletTransactionsAsyncHandler();
    
  }, [filterValue, endDate, sellerAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Transactions";
  }, []);

  const handleSellerAddress = (value) => {
    setsellerAddress(value);
  };

  return (
    <>
      <DashboardNavbar title={t("transactionOverView")} backShow={false} />
      <div className="walletMain chartsOuter">
        <HomeHeader
          filterValue={filterValue}
          handleFilter={handleFilterValue}
          handleDate={handleDateValue}
          seller_id={
            auth?.payload?.uniqe_id
              ? auth?.payload?.uniqe_id
              : auth?.payload?.user?.unique_uuid
          }
          tokenData={auth?.payload?.token}
          handleLocation={handleSellerAddress}
          productHead={true}
        />
        {loading === true ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="walletOverviewData mt-3">
              <div className="row">
                <div
                  className="col-xl-3 col-lg-6 col-md-6  pe-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=all&filter=${filterValue}`);
                  }}
                >
                  <div className="grossTotalorder yellowboxColor_">
                    <img
                      src={Images.totalanaicon}
                      className="img-fluid"
                      alt="transactionIcon"
                    />
                    <p className="textInner mt-4 mb-0">{t('total')}</p>
                    <h4 className="heading_ ">
                    ${
                      addThousandSeparator(
                        orderWalletTransactions?.data?.total?.toFixed(2)
                      )
                    }
                    </h4>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6  ps-2 pe-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=reward&filter=${filterValue}`);
                  }}
                >
                  <div className="grossTotalorder totalProductBox">
                    <img
                      src={Images.jobbrLogo}
                      className="img-fluid"
                      alt="moneyBagImage"
                      width={3}
                      height={30}
                    />
                    <p className="textInner mt-4 mb-0">{t('reward')}</p>
                    <h4 className="heading_">
                    J {
                      addThousandSeparator(orderWalletTransactions?.data?.reward_points)
                    }
                    </h4>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 ps-2 pe-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=cash&filter=${filterValue}`);
                  }}
                >
                  <div className="grossTotalorder totalBrandBox">
                    <img
                      src={Images.moneycashIcon}
                      className="img-fluid"
                      alt="walletCircleImage"
                    />
                    <p className="textInner mt-4 mb-0">{t('cash')}</p>
                    <h4 className="heading_">
                    ${
                      addThousandSeparator(orderWalletTransactions?.data?.cash?.toFixed(2))
                    }
                    </h4>
                  </div>
                </div>
               
                
                <div
                  className="col-xl-3 col-lg-6 col-md-6 ps-2 mt-3"
                  onClick={() => {
                    history.push(`/transections?transType=card&filter=${filterValue}`);
                  }}
                >
                  <div className="grossTotalorder totalSubCategoryBox">
                    <img
                      src={Images.visaicon}
                      className="img-fluid"
                      alt="walletcardImg"
                    />
                    <p className="textInner mt-4 mb-0">{t('card')}</p>
                    <h4 className="heading_">
                      ${addThousandSeparator(orderWalletTransactions?.data?.card?.toFixed(2))}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="tradesValue mt-0 mb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="chartsOuter">
                  <div className="leftorderValue_">
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/Total-Orders")}
                          className="textInnerHead m-0"
                        >
                           {t('totalTransactions')}
                        </h3>
                        <h3 className="Innerhead ">
                            ${
                               addThousandSeparator(orderWalletTransactions?.data?.total?.toFixed(2))
                            }
                        </h3>
                        <Link to={`/transections?transType=all&filter=${filterValue}`}>
                          <button className="viewAllbtn_" type="submit">
                            {t('viewAll')}
                          </button>
                        </Link>
                      </div>
                    <div className="graphChecked_ mt-3 mb-2 ">
                      <div className="graphBox_ me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll2"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          onChange={(e) => {
                            handelDataSetChange(e, "Reward", 0, "#4659B5");
                            setChecked({...checked, Reward: checked.Reward===true ? false: true})
                          }}
                          checked={checked.Reward}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll2"
                          className="checkBoxTxt masterChecked"
                        >
                          {t('reward')}
                        </label>
                      </div>
                      <div className="checkGreen me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll3"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.Cash}
                          onChange={(e) => {
                            handelDataSetChange(e, "Cash", 1, "#32D583");
                            setChecked({...checked,Cash: checked.Cash===true ? false: true})
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll3"
                          className="checkBoxTxt masterChecked"
                        >
                          {t('cash')}
                        </label>
                      </div>
                      <div className="checkSkyblue me-3">
                        <input
                          type="checkbox"
                          id="custom-checkbox-selectAll4"
                          className="checkBox "
                          name="custom-checkbox-selectAll"
                          value="custom-checkbox-selectAll"
                          checked={checked.Card}
                          onChange={(e) => {
                            handelDataSetChange(e, "Card", 2, "#58C2E8");
                            setChecked({...checked, Card: checked.Card===true ? false: true})
                          }}
                        />
                        <label
                          htmlFor="custom-checkbox-selectAll4"
                          className="checkBoxTxt masterChecked"
                        >
                          {t('creditCard')}
                        </label>
                      </div>
                    </div>
                    <div className="row mt-4 mb-4 align-items-center">
                      <div className="col-md-12">
                        <ChartArea
                          classes="productSales mt-2 mb-2"
                          header="Services Sales"
                          push={() => history.push('/transections')}
                          options={options1}
                          data={{ labels: orderWalletTransactions?.graphData?.labels, datasets: dataSets }}
                          chartType="Bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WalletMain;
