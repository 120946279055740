import React from 'react'
import "./Loader.css"
const Loader = () => {
  return<div className="loading-overlay">
  <div className="spinner-border text-light" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
</div>
}

export default Loader