import React, { useState } from 'react'
import { OrderDown,
    cancleorder,
    incoiceuser_,
    modalCancel,
    pickuporder,
    profileImg1,
    returnlogo,
    serviceuser_,
    unselectedOrder,
    drivefast_,
    upArrow,
    locateOrder,
    clockBlue,
    moneyImg,
    mapimg,
    deliveryMap,warningicon,
    fillradio_,
    verifyorder,
    orderDriver,done,cancelledstep,
    readystep,
    acceptstep,
    openTag,
    closeTag} from '../../../../utilities/images';
    import * as Images from '../../../../utilities/images';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { changeStatusApiAsync } from '../../dashboard/dashboardSlice';
import VerifyOrderModal from '../orders/VerifyOrderModal';
import PrintLabelModal from '../orders/PrintLabelModal';
import CustomModal from '../../../components/shared/CustomModal';
import { unwrapResult } from '@reduxjs/toolkit';
import { amountFormat, returnModeOfPayment } from '../../../../utilities/globalMethods';
import BillDetails from '../BillDetails';
import { VarintColorSize } from '../orders/VariantColorSize';
import OrderButton from '../OrderButton';
import { handleOrderSatus } from '../../../constants/orders/orders';
import { useTranslation } from 'react-i18next';
const OrderTrackingDetails = ({orderDetails,setshowOrderInvoice,getAllOrderDetails,getallOrders}) => {
  const { t } = useTranslation();
    const auth = useSelector(selectLoginAuth);
    const dispatch = useDispatch();
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
     const [hideOrderStatus, setHideOrderStatus] = useState(true);
      const [printingUrl, setPrintingUrl] = useState("");
      const [key, setKey] = useState(Math.random());
      const [isBackgroundVisible, setIsBackgroundVisible] = useState(true);
      const [showMap, setShowMap] = useState(false);
      const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
      });
    const handleOnCloseModal = () => {
      setModalDetail({
        show: false,
        title: "",
        flag: "",
      });
      setKey(Math.random());
    };
  
   const changeStatusOfOrderHandle = (id, status) => {
      let orderStatus = {
        postData: {
          id: id,
          status: status,
        },
        tokenData: auth?.payload?.token,
      };
  
      status === 8 ? setLoading1(true) : setLoading2(true);
      dispatch(changeStatusApiAsync(orderStatus))
        .then(unwrapResult)
        .then((obj) => {
          getAllOrderDetails();
          setLoading1(false);
          setLoading2(false);
        })
        .catch((obj) => {
          setLoading1(false);
          setLoading2(false);
        });
    };
    let userDetails=orderDetails?.user_details||orderDetails?.invitation_details;
  return   <>
<div className="orderTrackLeft orderTrackRight d-flex flex-column">
                <div>
                  <h3 className="fontSize24 fw500 txtDarkblue"># {
                    orderDetails?.invoices?.id
                  }</h3>
                  <h6 className="fontSize12 fw500 activeProductofferItem">
                    {
                      handleOrderSatus(
                        orderDetails?.delivery_option,
                        orderDetails?.status
                      )
                    }
                  </h6>

                  <div className="fedexContainer flexBox justify-content-between">
                    <div className="flexBox gap-2">
                      <img
                        src={userDetails?.profile_photo|| Images.avatar}
                        alt="logo"
                        className="avatarLogo"
                      />
                      <div>
                        <h3 className="fontSize16 txtDarkblue fw500 mb-1">
                        {`${userDetails?.firstname??"---"} ${userDetails?.lastname??"---"}`
            }
                        </h3>
                        <p className="fontSize10 allproductCount">
                        {
                
                orderDetails?.address
              }
                        </p>
                      </div>
                    </div>
                    <div className="flexBox gap-2">
                     {orderDetails?.delivery_option==4&& <img
                     style={{maxWidth:"40px"}}
                     src={orderDetails?.shipping_details?.image||Images.fedex} al  t="img" className="fed" />}
                      <div>
                        {orderDetails?.delivery_option==1&& <h3 className="orderTrackTableTxt mb-1">
                        {
                          orderDetails?.delivery_details?.title||orderDetails?.delivery_details?.description ||"Delivery"
                        }
                        </h3>}
                        {orderDetails?.delivery_option==1&&<h6 className="orderTrackLocation fontSize12 fw500 fedExSaver mb-0">
                          <img src={orderDetails?.delivery_option==4?orderDetails?.shipping_details?.image:Images.fastLogo} alt="img" />
                          {
                            orderDetails?.delivery_details?.
                            description
                            
                          }
                        </h6>}
                        {orderDetails?.shipping_details?
                          <>
                          <p className="fontSize14">{orderDetails?.shipping_details?.
                            title??"__"}</p>
                          <h6 className="orderTrackLocation fontSize12 fw500 fedExSaver mb-0">
                          <img src={Images.fastLogo}
                          style={{maxWidth:"30px"}}
                          alt="img" />
                            {moment(orderDetails?.date).format("DD MMM, YYYY")} {" || "}
                    {moment(orderDetails?.date).format("hh:mm a")}
                            
                        </h6>
                          </>:<>
                          <p className="fontSize14">{orderDetails?.service_code?? "Shipping"}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="commonTable sellingProductTable table-responsive mt-2 mb-3">
                    <table className="w-100">
                      <tbody>
                        {
                          orderDetails?.order_details?.length > 0
                          ? orderDetails?.order_details?.map((val,i)=>(
<tr>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={val?.product_image||Images.productItemImg}
                                alt="img"
                                className="productItemImg"
                              />
                              <div>
                                <h3 className="topSellProductHead mb-0 pb-1">
                                {
                val?.product_name
               }
                                </h3>
                                <div className="mapleProductHeading">
                                  {VarintColorSize(val?.variant)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            $ {Number(val?.actual_price ||0)?.toFixed(2)}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                          {val?.qty ||0}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            ${`${(Number(val?.actual_price||0) * Number(val?.qty|| 0))?.toFixed(2)}`}
                          </td>
                        </tr>
                          )):orderDetails?.return_detail?.return_details?.map((val,i)=>(
                            <tr>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={val?.order_details?.product_image||Images.productItemImg}
                                alt="img"
                                className="productItemImg"
                              />
                              <div>
                                <h3 className="topSellProductHead mb-0 pb-1">
                                {val?.order_details?.product_name}
                                </h3>
                                <div className="mapleProductHeading">
                                  {VarintColorSize(val?.variant)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            ${Number(val?.order_details?.price)?.toFixed(2)}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                          {val?.order_details?.qty || 0}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            ${Number(
                  val?.order_details?.qty *
                  val?.order_details?.price
                )?.toFixed(2)}
                          </td>
                        </tr>
                          )) 
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-auto">
                  <div className="col-md-4 text-start pe-0">
                    <div className="orderinvoive">
                      <h5 className="lightgreyHeading_">{t('totalItems')}</h5>
                      <h4 className="monshead22">{orderDetails?.total_items||0}</h4>
                      <div className="orderTrackBorderLine"></div>
                      <h5 className="lightgreyHeading_">{t('orderDate')}</h5>
                      <h4 className="monshead22"> {moment(orderDetails?.created_at).format("MM/DD/YYYY")}</h4>
                      <div className="orderTrackBorderLine"></div>
                      <h5 className="lightgreyHeading_">{t('orderId')} #</h5>
                      <h4 className="monshead22">{orderDetails?.invoices?.order_id}</h4>
                      <div className="orderTrackBorderLine"></div>
                      <h5 className="lightgreyHeading_">{t('payMethod')}</h5>
          <h4 className="monshead22">
            <div className="deliverTableBx">
              <img
                src={moneyImg}
                alt="location Image"
                className="img-fluid ms-1"
              />
              <span className="locateDistance">{
                returnModeOfPayment(
                                          orderDetails?.mode_of_payment,
                                          orderDetails?.paid_by_reward,
                                          orderDetails?.partially_paid_by_reward
                                        )
              }</span>
            </div>
          </h4>
                    </div>
                  </div>
                  <div className="col-md-8 text-start">
                    <div className="orderBill_">
                      <div className="Customertotal">
                       <BillDetails
                       orderDetails={orderDetails}
                       />
                      

                        <OrderButton
                          selected={orderDetails?.status}
                          orderData={orderDetails}
                          isLoading={loading2}
                          declineLoading={loading1}
                          setPrintingUrl={setPrintingUrl}
                          getallOrders={getallOrders}
                          setKey={setKey}
                          setModalDetail={setModalDetail}
                          changeStatusOfOrderHandle={changeStatusOfOrderHandle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CustomModal
            key={key}
            show={modalDetail.show}
            // backdrop="static"
            showCloseBtn={false}
            isRightSideModal={false}
            mediumWidth={false}
            ids={
              modalDetail.flag === "verifyOtp"
                ? "VerifyOtp"
                : modalDetail.flag === "printLabel"
                  ? "PrintLabel"
                  : ""
            }
            size={
              modalDetail.flag === "verifyOtp" ||
                modalDetail.flag === "printLabel"
                ? "md"
                : "xl"
            }
            child={
              modalDetail.flag === "printLabel" ? (
                <PrintLabelModal
                  handleChangeStatus={(id, status) =>
                    changeStatusOfOrderHandle(id, status)
                  }
                  orderData={orderDetails}
                  printingUrl={printingUrl}
                  close={() => handleOnCloseModal()}
                />
              ) : modalDetail.flag === "verifyOtp" ? (
                <VerifyOrderModal
                  handleChangeStatus={(id, status) =>
                    changeStatusOfOrderHandle(id, status)
                  }
                  getOrderDataById={getAllOrderDetails}
                  orderData={orderDetails}
                  close={() => handleOnCloseModal()}
                />
              ) : (
                ""
              )
            }
            header={
              modalDetail.flag === "verifyOtp" ? (
                <>
                  <div className="headerLeft">
                    <h4 className="modalHeading_ me-3">{t('verifyOtp')}</h4>
                  </div>

                  <p
                    style={{ cursor: "pointer" }}
                    onClick={handleOnCloseModal}
                    className="modal_cancel"
                  >
                    <img
                      src={modalCancel}
                      className="ModalCancel"
                      alt="modalcancelImg"
                    />
                  </p>
                </>
              ) : modalDetail.flag === "printLabel" ? (
                <>
                  <div className="headerLeft">
                    <h4 className="modalHeading_ me-3">{t('printLabel')}</h4>
                  </div>

                  <p
                    style={{ cursor: "pointer" }}
                    onClick={handleOnCloseModal}
                    className="modal_cancel"
                  >
                    <img
                      src={modalCancel}
                      className="ModalCancel"
                      alt="modalcancelImg"
                    />
                  </p>
                </>
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
</>
}

export default OrderTrackingDetails