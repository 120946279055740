import React from 'react'
import * as Images from "../../utilities/images";
import ReactSelect from "react-select";
import { resultSelectOption } from '../constants/common.constant';
import { useTranslation } from 'react-i18next';

const PaginationHeader = ({
    totalPage,
    setCurrentPage,
    setPerPage,
    currentPage,
    perPage,
    headerClass,
    dataLength = []
}) => {
  const { t } = useTranslation();  
    const handleNext = () => {
        setCurrentPage((prev) => {
            // Calculate the total number of pages based on totalRows and perPage
            const totalPages = Math.ceil(totalPage / perPage);
            return prev < totalPages ? prev + 1 : prev;
        });
    };
    
    const handlePrev = () => {
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    };
    
    const startIndex = (currentPage - 1) * parseInt(perPage, 10) + 1;
    const endIdx = dataLength
        ? Math.min(startIndex + parseInt(dataLength, 10) - 1, totalPage)
        : 0;
        
    return (
        <div className={headerClass || "col-md-7"}>
            <div className="d-flex align-items-center justify-content-end gap-3">
                <div className="d-flex align-items-center gap-2">
                    <h3 className="fontSize14 txtDarkblue mb-0">{t('showingResult')}</h3>
                    <div className="reactSelectParent offerHeaderSelect">
                        <ReactSelect
                            options={resultSelectOption}
                            placeholder="10"
                            classNamePrefix="react-select"
                            className="react-select-container anlyticSelect"
                            onChange={selected => setPerPage(selected?.value)}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minWidth: "75px",
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    border: "1px solid #D7DEFF",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? "#263682"
                                        : state.isFocused
                                            ? "#f5f6fc"
                                            : "#fff",

                                    color: state.isSelected ? "#fff" : "#636E9F",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: "#636E9F",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }),
                            }}
                            components={{
                                DropdownIndicator: () => (
                                    <img
                                        src={Images.blueDownArrow}
                                        width={24}
                                        height={24}
                                        alt="drop_icon"
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className="paginationParent">
                    <div className="d-flex align-items-center gap-1" onClick={
                        handlePrev
                    }>
                        <div className="backPageBox ">
                            <img src={Images.arrowDoubleLeft} alt="arrowDoubleRight" />
                        </div>
                        <div className="backPageBox backPageBox2">
                            <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                        </div>
                    </div>
                    <h6 className="fontSize14 txtDarkblue mb-0">
                        {/* {currentPage}-{totalPage} of {totalPage || 1} */}
                        {endIdx > 0
                            ? `${startIndex}-${endIdx} of ${dataLength}`
                            : "0 of 0"}
                    </h6>
                    <div className="d-flex align-items-center gap-1" onClick={
                        handleNext
                    }>
                        <div className="nextPageBox nextPageBox2">
                            <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                        </div>
                        <div className="nextPageBox ">
                            <img src={Images.arrowDoubleRight} alt="arrowDoubleRight" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaginationHeader
