import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Image as ReactNativeImage,
  FlatList,
  TextInput,
  ActivityIndicator,
} from "react-native";
import * as Images from "../../../../utilities/images";
import { debounce } from "lodash";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { Calendar } from 'react-native-big-calendar'
import CalendarHeaderWithOptions from "./CalendarHeaderWithOptions";
import { Calendar } from "./CustomCalendar";
// import CustomModal from '../../../customModal/CustomModal';
import CustomModal from "./CustomModal";
import CustomHoursCell from "./CustomHourseCell";
import CustomEventCell from "./CustomEventCell";
import ReScheduleDetailModal from "./ReScheduleDetailModal";
import CalendarSettingModal from "./CalendarSettingModal";
import {
  CALENDAR_MODES,
  CALENDAR_VIEW_MODES,
  APPOINTMENT_STATUS,
} from "../../../constants/bookings/allbooking";
import moment from "moment-timezone";
import { Spacer } from "./Spacer";
import CheckinModal from "./CheckinModal";
import EventDetailModal from "./EventDetailModal";
import {
  getAppointments,
  bookingsDetails,
  updateAppointmentStatus,
  setGetAppointments,
  getStaffUsers,
  setGetStaffUsers,
  setUpdateAppointmentStatus,
  onErrorStopLoad,
} from "../bookingSlice";
import {
  calculateTimeDuration,
  getCalendarActionButtonTitle,
} from "./GlobalMethods";
import { useHistory } from "react-router-dom";
import {
  getPosUsersAsync,
  selectLoginAuth,
  selectUserSettings,
} from "../../auth/authSlice";
import {
  changeAppointmentStatusApiAsync,
  getAppointemntApiAsync,
  getPendingOrderCountApiAsync,
  getUserSettingsApiAsync,
  updateUserSettingsApiAsync,
} from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { setPendingOrderCount } from "../delivery.slice";
import {
  getAllStuffsAsync,
  getAllUserAsync,
} from "../../systemConfig/systemConfigSlice";
import "./Calendar.css";
import RequestModal from "./Modals/BookingRequestModal";
import BookingSchedule from "../bookingSchedule";
import AppointmentDetailModal from "./Modals/AppointmentDetailModal";
import { useTranslation } from "react-i18next";
const Booking = () => {
  const { t } = useTranslation();
  const router = useHistory();
  const dispatch = useDispatch();
  //default value
  const [key, setKey] = useState(Math.random());
  const [key1, setKey1] = useState(Math.random());
  const [key2, setKey2] = useState(Math.random());
  const [bookingsView, setBookingsView] = useState("listview");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [updateBooking, setupdateBooking] = useState(false);
  const [renderButtonIndex, setRenderButtonIndex] = useState(-1);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  //null
  const [BookingDetails, setBookingDetails] = useState(null);
  //dispatch
  const settingData = useSelector(selectUserSettings);
  const appointmentsDetails = useSelector(bookingsDetails);
  const authData = useSelector(selectLoginAuth);
  const defaultSettingsForCalendar = settingData?.payload;
  const [searchedAppointments, setSearchedAppointments] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [week, setWeek] = useState(true);
  const [month, setMonth] = useState(false);
  const [day, setDay] = useState(false);
  const [monthDays, setMonthDays] = useState([]);
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const [isAMPM, setisAMPM] = useState(
    defaultSettingsForCalendar?.time_format === "12" ?? true
  );
  const [eventData, setEventData] = useState({});
  const [showMiniCalendar, setshowMiniCalendar] = useState(false);
  const [calendarViewMode, setCalendarViewMode] = useState(
    CALENDAR_VIEW_MODES.CALENDAR_VIEW
  );
  const [isLoadingSearchAppoinment, setIsLoadingSearchAppoinment] =
    useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const searchAppoinmentInputRef = useRef(null);
  const [isCalendarSettingModalVisible, setisCalendarSettingModalVisible] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const [getAppointmentByStaffIdList, setgetAppointmentByStaffIdList] =
    useState(appointmentsDetails?.geAppointmentById);
  const ADMIN = () => {
    const admin = authData?.payload?.user_roles?.filter(
      (item) => item?.role?.slug == "pos_admin"
    );
    return admin;
  };
  const [staffUsersList, setstaffUsersList] = useState(
    appointmentsDetails?.staffUsers
      ?.map((item) => {
        // Check if the user is an admin
        if (ADMIN()?.length <= 0) {
          // If the user is an admin and the user_id matches, set selected to true for that user
          if (item?.user_id == authData?.posUserLoginDetails?.payload?.id) {
            return {
              ...item,
              selected: true,
            };
          }
        } else {
          // For non-admins, set selected to true for all users
          return {
            ...item,
            selected: true,
          };
        }
      })
      ?.filter((i) => i !== undefined) || []
  );
  const getAppointmentList = appointmentsDetails?.getAppointment;
  const getAppointmentList2 = getAppointmentList?.filter(
    (item) => item.status !== 3
  );

  const [staffUsers, setStaffUsers] = useState(staffUsersList);
  const [formattedTime, setFormattedTime] = useState(false);
  // Only show appointments on calendar which are approved/Check-In/Completed/CancelledByCustomer
  const getApprovedAppointments = getAppointmentList?.filter(
    (item) => item.status === 1 || item.status === 2 || item.status === 3
  );
  // Will be used to show list of all unaccepted appointments
  const futureAppointments = getAppointmentList2?.filter(
    (item) => item.status === 0
  );
  const currentDateTime = moment();
  const appointmentListArr = futureAppointments?.filter((appointment) => {
    const endDateTime = moment(
      `${appointment?.date} ${appointment?.end_time}`,
      "YYYY-MM-DD hh:mm A"
    );
    return currentDateTime.isBefore(endDateTime);
  });

  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showRequestsView, setshowRequestsView] = useState(false);
  const [selectedStaffData, setSelectedStaffData] = useState(null);
  const [employeeHeaderLayouts, setEmployeeHeaderLayouts] = useState([]);
  const [showEmployeeHeader, setshowEmployeeHeader] = useState(false);
  const [shouldShowCalendarModeOptions, setshouldShowCalendarModeOptions] =
    useState(true);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [calendarMode, setCalendarMode] = useState(
    defaultSettingsForCalendar?.calender_view ?? CALENDAR_MODES.WEEK
  );
  const [staffSelectedFrom, setSelectedStaffFrom] = useState(null);
  const [selectedStaffIdArray, setSelectedStaffIdArray] = useState([]);

  const nextMonth = () =>
    setCalendarDate(
      calendarDate
        .clone()
        .add(
          1,
          calendarViewMode === CALENDAR_VIEW_MODES.CALENDAR_VIEW
            ? calendarMode
            : CALENDAR_MODES.DAY
        )
    );
  const prevMonth = () =>
    setCalendarDate(
      calendarDate
        .clone()
        .subtract(
          1,
          calendarViewMode === CALENDAR_VIEW_MODES.CALENDAR_VIEW
            ? calendarMode
            : CALENDAR_MODES.DAY
        )
    );
  const [extractedAppointment, setExtractedAppointment] = useState([]);
  const [showRescheduleTimeModal, setshowRescheduleTimeModal] = useState(false);
  const [showEventDetailModal, setshowEventDetailModal] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const changeStatusOfAppointmentHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: authData?.payload?.token,
    };
    status === 4 ? setLoading1(true) : setLoading2(true);
    dispatch(changeAppointmentStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        getAllBookingDetails();
      })
      .catch((obj) => {});
  };
  const UniqueId = authData?.payload?.uniqe_id
    ? authData?.payload?.uniqe_id
    : "";
  //done
  useEffect(() => {
    getUserPosDetails();
  }, []);
  useEffect(() => {
    getAllBookingDetails();
  }, []);
  const getAllBookingDetails = async () => {
    dispatch(getAppointments());
    const prams = {
      postData: {
        page: 1,
        limit: 10,
        seller_id: 
        authData?.payload?.uniqe_id
          ? authData?.payload?.uniqe_id
          : authData?.payload?.user?.unique_uuid,
      },
      tokenData: authData?.payload?.token,
    };
    dispatch(getAppointemntApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setGetAppointments(res));
        // setAppointmentsDetails(res?.payload);
        // setLoading(false);
        setBookingDetails(res?.payload);
      })
      .catch((err) => {
        dispatch(onErrorStopLoad());
      });
  };

  const getUserPosDetails = async () => {
    const prams = {
      postData: {
        seller_id:
        authData?.payload?.uniqe_id
          ? authData?.payload?.uniqe_id
          : authData?.payload?.user?.unique_uuid,
      },
      tokenData: authData?.payload?.token,
    };
    dispatch(getPosUsersAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setGetStaffUsers(res));
        setstaffUsersList(res?.payload?.pos_staff?.map((item) => {
          // Check if the user is an admin
          if (ADMIN()?.length <= 0) {
            // If the user is an admin and the user_id matches, set selected to true for that user
            if (item?.user_id == authData?.posUserLoginDetails?.payload?.id) {
              return {
                ...item,
                selected: true,
              };
            }
          } else {
            // For non-admins, set selected to true for all users
            return {
              ...item,
              selected: true,
            };
          }
        }) ?.filter((i) => i !== undefined) || []);
      })
      .catch((err) => {
        dispatch(onErrorStopLoad());
      });
  };
  useEffect(() => {
    setStaffUsers(staffUsersList);
  }, [appointmentsDetails?.staffUsers]);

  useEffect(() => {
    let prams = {
      postData: {
        seller_id: authData?.payload?.uniqe_id
        ? authData?.payload?.uniqe_id
        : authData?.payload?.user?.unique_uuid,
        need_staff_member: true,
        page: pageNumber,
        limit: 10,
      },
      tokenDate: authData?.payload?.token,
    };
    // dispatch(
    //   getAllStuffsAsync(prams)
    //   .then(unwrapResult)
    //   .then(res=>{
    //     setStaffUsers(res?.payload);
    //   })
    // );

    getUserSettings();
    // onPressListViewMode();
    onPressCalendarViewMode()
  }, []);

  useEffect(() => {
    // if (calendarMode === CALENDAR_VIEW_MODES.CALENDAR_VIEW) {
    if (defaultSettingsForCalendar?.calender_view === CALENDAR_MODES.DAY) {
      dayHandler();
    } else if (
      defaultSettingsForCalendar?.calender_view === CALENDAR_MODES.WEEK
    ) {
      weekHandler();
    } else if (
      defaultSettingsForCalendar?.calender_view === CALENDAR_MODES.MONTH
    ) {
      monthHandler();
    }
    // }
  }, [defaultSettingsForCalendar]);

  const getUserSettings = () => {
    // let params = {
    //   app_name: "pos",
    //   seller_id: UniqueId,
    // };
    // dispatch(
    //   getSecuritySettingInfo({
    //     ...params,
    //     cb(res) {
    //       if (res.status) {
    //         setGetSelectedLanguages(res?.data?.payload?.languages)
    //       }
    //     },
    //   })
    // );
    let prams = {
      postData: {
        app_name: "merchant",
        seller_id: authData?.payload?.uniqe_id
        ? authData?.payload?.uniqe_id
        : authData?.payload?.user?.unique_uuid,
      },
      tokenData: authData?.token,
    };
    dispatch(getUserSettingsApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
      })
      .catch((err) => {
        dispatch(onErrorStopLoad());
      });
  };

  const updateUserSettings = (data) => {
    let prams = {
      postData: {
        ...data,
      },
      tokenData: authData?.payload?.token,
    };
    dispatch(updateAppointmentStatus());
    dispatch(updateUserSettingsApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setUpdateAppointmentStatus());
      })
      .catch((res) => {
        dispatch(onErrorStopLoad());
      });
  };

  useEffect(() => {
    getAllBookingDetails();
    getCurrentMonthDays();
  }, [pageNumber, calendarDate]);

  const updateBookingStatus = (appointmentId, status) => {
    let orderStatus = {
      postData: {
        id: appointmentId,
        status: status,
      },
      tokenData: authData?.payload?.token,
    };
    setupdateBooking(true);
    dispatch(changeAppointmentStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((res) => {
    setupdateBooking(false);

        // if (res.status) {
          getAllBookingDetails();
          handleOnCloseModal();
          setRenderButtonIndex('')


          if (
            status == APPOINTMENT_STATUS.ACCEPTED_BY_SELLER ||
            status == APPOINTMENT_STATUS.REJECTED_BY_SELLER
          ) {
            getPendingAppointmentCount();
          }
        // }
      })
      .catch((err) => {
    setupdateBooking(false);
      });
  };

  const getPendingAppointmentCount = () => {
    const orderListParam = {
      postData: {
        seller_id: authData?.payload?.uniqe_id
        ? authData?.payload?.uniqe_id
        : authData?.payload?.user?.unique_uuid,
        status: APPOINTMENT_STATUS.ACCEPTED_BY_SELLER,
        page: 1,
        limit: 10,
      },
      tokenData: authData?.payload?.token,
    };
    dispatch(getPendingOrderCountApiAsync(orderListParam))
      .then(unwrapResult)
      .then((res) => {
        dispatch(setPendingOrderCount(res?.payload));
      })
      .catch((err) => {
        dispatch(onErrorStopLoad());
      });
  };

  const onSearchAppoinment = (searchText) => {
    if (searchText == "") {
      setSearchedAppointments([]);
      setIsLoadingSearchAppoinment(false);
      return;
    }

    let prams = {
      postData: {
        seller_id:authData?.payload?.uniqe_id
        ? authData?.payload?.uniqe_id
        : authData?.payload?.user?.unique_uuid,
        page: pageNumber,
        limit: 10,
      },
      tokenData: authData?.payload?.token,
    };
    if (searchText) {
      prams.postData.search = searchText;
    }
    dispatch(getAppointemntApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        if (res?.payload) {
          const searchData = res?.payload;
          if (searchData === null) {
            setSearchedAppointments([]);
          } else {
            setSearchedAppointments(searchData?.data);
          }
        }
        setIsLoadingSearchAppoinment(false);
      })
      .catch((err) => {});
  };

  const debouncedSearchAppointment = useCallback(
    debounce(onSearchAppoinment, 500),
    []
  );

  const getAppointmentsForSelectedStaff = () => {
    const filteredAppointments = getApprovedAppointments?.filter(
      (appointments) => selectedStaffIdArray.includes(appointments?.pos_user_id)
    );

    return filteredAppointments;
  };

  useEffect(() => {
    if (getApprovedAppointments) {
      const checkIsAllStaffUnSelected = staffUsers?.every(
        (item) => !item.selected
      ); // Checks if all user is unselected from staff header
      const approvedAppointmentsFor =
        (showEmployeeHeader && checkIsAllStaffUnSelected) ||
        selectedStaffIdArray?.length > 0
          ? getAppointmentsForSelectedStaff()
          : getApprovedAppointments;

      const extractedAppointmentEvents = approvedAppointmentsFor.map(
        (booking) => {
          const startDateTime = new Date(booking.start_date_time);
          const endDateTime = new Date(booking.end_date_time);
          return {
            title: booking?.product_name || "NULL",
            start: startDateTime,
            end: endDateTime,
            completeData: booking,
          };
        }
      );
      setExtractedAppointment(extractedAppointmentEvents);
    }
  }, [getAppointmentList, staffUsers, selectedStaffIdArray]);
  const [appointmentModal, setappointmentModal] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const [keyAppointment, setkeyAppointment] = useState(Math.random());

  const handleOnCloseAppointmentModal = () => {
    setappointmentModal({
      show: false,
      title: "",
      flag: "",
    });
    setkeyAppointment(Math.random());
  };
  const handleOnOpenAppointmentModal = (flag) => {
    setappointmentModal({
      show: true,
      title: "",
      flag: flag,
    });
    setkeyAppointment(Math.random());
  };
  const weekHandler = () => {
    setCalendarMode(CALENDAR_MODES.WEEK);
    setWeek(true);
    setMonth(false);
    setDay(false);
  };
  const monthHandler = () => {
    setCalendarMode(CALENDAR_MODES.MONTH);
    setMonth(true);
    setWeek(false);
    setDay(false);
  };
  const dayHandler = () => {
    setCalendarMode(CALENDAR_MODES.DAY);
    setDay(true);
    setMonth(false);
    setWeek(false);
  };

  const getCurrentMonthDays = (calendarDate) => {
    // Use moment to parse the calendar date, ensuring UTC mode for consistency
    const date = moment.utc(calendarDate);
    const year = date.year();
    const month = date.month(); // Note: moment's month is 0-indexed like JavaScript's Date

    // Get the total number of days in the month
    const daysInMonth = date.daysInMonth();

    const monthDays = [];

    for (let day = 1; day <= daysInMonth; day++) {
      // Construct the full date for the day in UTC mode
      const fullDateFortheDay = moment.utc([year, month, day]);
      const dayName = fullDateFortheDay.format("dddd"); // Format day name

      // Construct the object for the day
      const objDay = {
        fullDateFortheDay: fullDateFortheDay.format(), // Format or use as a Moment object
        day,
        dayName,
      };

      monthDays.push(objDay);
    }
    setMonthDays(monthDays);
  };

  const onPressCalendarViewMode = () => {
    setCalendarViewMode(CALENDAR_VIEW_MODES.CALENDAR_VIEW);
    setshouldShowCalendarModeOptions(true);
    // weekHandler();
  };
  const onPressListViewMode = () => {
    setCalendarViewMode(CALENDAR_VIEW_MODES.LIST_VIEW);
    setshouldShowCalendarModeOptions(false);
    setshowEmployeeHeader(false);
  };

  const getFormattedHeaderDate = () => {
    return calendarDate.format("MMM YYYY");
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  // Open Modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());
  };

  const closeRescheduleModal = () => {
    setshowRescheduleTimeModal(false);
    setKey1(Math.random());
  };

  const closeSearchModal = () => {
    setShowSearchModal(false);
    setSearchedText("");
    setIsLoadingSearchAppoinment(false);
    setKey2(Math.random());
  };

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const getAppointmentsByDate = useMemo(() => {
    const filteredAppointmentsByDate = getAppointmentList?.filter(
      (appointment) =>
        moment.utc(appointment?.date).format("L") ==
        moment.utc(calendarDate).format("L")
    );

    return filteredAppointmentsByDate;
  }, [calendarDate, appointmentsDetails]);

  const renderMonthItem = ({ item, index }) => {
    const isSelected = moment
      .utc(calendarDate.date())
      .isSame(moment.utc(item.day));

    const itemStyle = {
      width: (windowWidth * 0.84 - monthDays.length * 3) / monthDays.length,
      borderRadius: "4px",
      borderWidth: "2px",
      borderColor: "#F5F6FC",
      borderStyle: "solid",
      padding: "8px",
      marginRight: "2px",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: isSelected ? "#263682" : "transparent",
      color: isSelected ? "#F5F6FC" : "#8D99D2",
      fontSize: "12px",
      display: "flex", // Added for CSS Flexbox layout
      cursor: "pointer", // To show it's clickable
    };

    return (
      <div
        style={itemStyle}
        onClick={() => setCalendarDate(moment.utc(item.fullDateFortheDay))}
        key={index}
      >
        {item.day}
      </div>
    );
  };

  function updateStaffSelectionAndSelectedIds(posUserId) {
    // Toggle the selected property for the staff users
    setStaffUsers((prev) => {
      // Map through previous staff users to toggle the selected state
      const updatedStaffUsers = prev.map((staff) =>
        staff?.user?.unique_uuid === posUserId
          ? { ...staff, selected: !staff.selected }
          : staff
      );

      // After updating staffUsers, derive the new selectedStaffIds
      const selectedStaffIds = updatedStaffUsers
        .filter((item) => item.selected)
        .map((item) => item?.user?.unique_uuid);

      // Update the selectedStaffIdArray with the newly selected IDs
      setSelectedStaffIdArray(selectedStaffIds);

      // Return the updated staff users to update the state
      return updatedStaffUsers;
    });
  }

  const employeeHeader = () => {
    const _staffUsers =
      staffSelectedFrom === "singleStaff"
        ? [selectedStaffData]
        // : ADMIN()?.length > 0
        // ? 
       : staffUsersList
        // : staffUsersList.filter(
        //     (e) => e.user_id == authData?.posUserLoginDetails?.payload?.id
        //   );
    return (
      <View>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            backgroundColor: "#fff",
            flexGrow: 1,
            marginTop : 15,
            paddingLeft: calendarMode === CALENDAR_MODES.MONTH ? 0 : 50,
          }}
        >
          {_staffUsers?.map((item, index) => {
            const userProfile = item?.user?.user_profiles;
            const userRoles = item?.user?.user_roles[0]?.role?.name;
            const posUserId = item?.user?.unique_uuid;
            return (
              <TouchableOpacity
                onPress={() => {
                  if (staffSelectedFrom === "singleStaff") {
                    setSelectedStaffIdArray([posUserId]);
                  } else {
                    updateStaffSelectionAndSelectedIds(posUserId);
                  }
                }}
                style={{
                  padding: 5,
                  marginHorizontal: 1,
                  alignItems: "center",
                  flexDirection: "row",
                  // justifyContent: "center",
                  borderWidth: 1,
                  borderRadius: 20,
                  paddingHorizontal: 5,
                  width: Dimensions.get("window").width * 0.11,
                  borderColor: item?.selected ? item?.color_code : "#F7F9FF",
                }}
                key={index}
                onLayout={(event) => {
                  const { x, y, width, height } = event.nativeEvent.layout;
                  if (!employeeHeaderLayouts[index]?.width) {
                    const array = [...employeeHeaderLayouts];
                    array[index] = {
                      ...event.nativeEvent.layout,
                      user_id: userProfile?.user_id,
                      firstname: userProfile?.firstname ?? "",
                    };
                    setEmployeeHeaderLayouts(array);
                  }
                }}
              >
                <ReactNativeImage
                  source={{
                    uri: userProfile?.profile_photo || Images.userImg_,
                  }}
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 20,
                    borderWidth: 2,
                    borderColor: "teal",
                    borderColor: item?.color_code,
                  }}
                />
                <View style={{ marginLeft: 5 }}>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#000",
                    }}
                  >
                    {(userProfile?.firstname ?? "") +
                      " " +
                      (userProfile?.lastname ?? "")}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#000",
                    }}
                  >
                    {userRoles}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    );
  };

  const onPressSaveCalendarSettings = (calendarPreferences) => {
    if (calendarPreferences?.defaultCalendarMode === CALENDAR_MODES.DAY) {
      dayHandler();
    } else if (
      calendarPreferences?.defaultCalendarMode === CALENDAR_MODES.WEEK
    ) {
      weekHandler();
    } else if (
      calendarPreferences?.defaultCalendarMode === CALENDAR_MODES.MONTH
    ) {
      monthHandler();
    }
    setisAMPM(calendarPreferences?.defaultTimeFormat);

    const data = {
      calender_view: calendarPreferences?.defaultCalendarMode,
      time_format: calendarPreferences?.defaultTimeFormat ? "12" : "24",
      accept_appointment_request:
        calendarPreferences?.defaultAppointmentRequestMode,
      employee_color_set: calendarPreferences?.defaultEmployeesColorSet,
    };
    updateUserSettings(data);
  };

  const handleBookingsView = (bookingsView) => {
    setBookingsView(bookingsView);
  };
  const isHideCheckInButton = (item) => {
    const formatedDate = moment(item?.start_date_time);
    const currentDateTime = moment();
    const durationInMinutes = currentDateTime.diff(formatedDate, "minutes");

    // Hide the button if the start date is in the future
    // or if the duration in minutes is greater than approx_service_time (50)
    return (
      formatedDate.isAfter(currentDateTime) ||
      durationInMinutes > item?.approx_service_time
    );
  };

  const renderButtons = (item,index) => {
    const appointmentID = item?.id;
    const hidecheckInButton = isHideCheckInButton(item);

    return {
      [APPOINTMENT_STATUS.REVIEWING]: (
        <div className="checkinBg d-flex">
          <button
            className="tableDeclineBtn cancleAppointmentBtn me-2"
            type="submit"
            disabled={updateBooking}
            onClick={async () => {
              setRenderButtonIndex(index+"decline")
              updateBookingStatus(
                appointmentID,
                APPOINTMENT_STATUS.REJECTED_BY_SELLER
              );
              onSearchAppoinment(searchedText);
            }}
          >
              {updateBooking &&renderButtonIndex==index+"decline" && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}


            {t('decline')}
          </button>

          <button
            className="acceptBtn"
            type="submit"
            disabled={updateBooking}
            onClick={async () => {
              setRenderButtonIndex(index+"accept")
              updateBookingStatus(
                appointmentID,
                APPOINTMENT_STATUS.ACCEPTED_BY_SELLER
              );
              onSearchAppoinment(searchedText);
            }}
          >
             {updateBooking&&renderButtonIndex==index+"accept" && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}
            {t('accept')}
          </button>
        </div>
      ),
      [APPOINTMENT_STATUS.ACCEPTED_BY_SELLER]: (
        <div className="checkinBg d-flex">
          {/* <button
              className="greyBtn w-100 mr-6"
              // type="submit"
              onClick={() => {
                debugger;
                calculateTimeDuration(item);
                setSelectedBooking(item);
                handleUserProfile("checkIn");
              }}
            >
              {"CheckIn"}
              <img
                src={
                    Images?.drivefast_
                    // || Images?.checkImg
                }
                alt="money"
                className="moneyImg ml-6"
              />
            </button> */}
          {hidecheckInButton ? (
            ""
          ) : (
            <button
              className="checkInBtn tableClockParent"
              // type="submit"
              onClick={() => {
                calculateTimeDuration(item);
                setSelectedBooking(item);
                handleUserProfile("checkIn");
              }}
            >
              {t("checkIn")}
              <img src={Images.checkInImg} alt="img" />
            </button>
          )}
          <button
            className="tableEditBtn"
            onClick={() => {
              setSelectedBooking(item);
              setKey1(Math.random());
              setshowRescheduleTimeModal(true);
            }}
          >
            <img
              src={Images?.inputManually}
              alt="editImg"
              className="editImg"
            />
          </button>
        </div>
      ),
      [APPOINTMENT_STATUS.CHECKED_IN]: (
        <button
          className="greyBtn w-100 checkInBtn tableClockParent"
          type="submit"
          onClick={() => {
            setRenderButtonIndex(index)
            updateBookingStatus(appointmentID, APPOINTMENT_STATUS.COMPLETED);
          }}
        >
          {getCalendarActionButtonTitle(item?.status)}
        </button>
      ),
      [APPOINTMENT_STATUS.COMPLETED]: (
        <button
          className="commonBlueBtn tableGreenBtn gap-2 w-100"
          type="submit"
        >
          {t('completed')}
          <img src={Images?.checkCircleImg} alt="complete" />
        </button>
      ),
      [APPOINTMENT_STATUS.CANCELLED_BY_CUSTOMER]: (
        <button style={{background:"gray"}} className="acceptBtn " type="submit">
          {getCalendarActionButtonTitle(item?.status)}
        </button>
      ),
      [APPOINTMENT_STATUS.REJECTED_BY_SELLER]: (
        <button style={{background:"gray"}} className="acceptBtn " type="submit">
          {getCalendarActionButtonTitle(item?.status)}
        </button>
      ),
    };
  };

  const CommonSideBar = () => {
    return (
      <>
        <div
          className={`sidebarRightBooking ms-0 ${
            sidebarOpen ? "full" : "hide"
          }`}
        >
          <ListGroup className="w-100">
            <ListGroupItem
              className="SidebarRightItems text-center"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <div className="openRightSidebarToggle cursorPointer">
                <img
                  src={Images?.sidebarBackArrow}
                  alt="image"
                  className="img-fluid arrowBack sidebarIcons"
                />
              </div>
              <div className="collapseToggle">
                <div className="d-flex align-items-center gap-3">
                  <img src={Images.modalCross} alt="img" />
                  <h5 className="textInnerHead2 fw500">{t('collapse')}</h5>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem
              className="SidebarRightItems  text-center sidebarRequest"
              onClick={() => {
                // handleOpenModal("appointmentDetailModal");
                handleOpenModal("bookingRequestModal");
                // onClick={() => }
                // setshowRequestsView((prev) => !prev)
              }}
            >
              <div className="sidebarRightInnerItems requestRightSidebarItem">
                <img
                  src={Images?.customCalendar}
                  alt="image"
                  className="img-fluid  sideBarImg"
                />
                <span className="bottomDots ">
                  {appointmentListArr?.length ?? 0}
                </span>
              </div>
              <div className="innerItemUsersName">
                <h3 className="fontSize14 fw500 txtDarkblue mb-0">
                  {t('newBookings')}
                </h3>
              </div>
            </ListGroupItem>
            <div className="bookingUsers mt-3 bookingSidebarUsers">
              {staffUsersList?.map((item, index) => {
                const userProfile = item?.user?.user_profiles;
                const posUserId = item?.user?.unique_uuid;
                const imageUrl = item?.user?.user_profiles?.profile_photo;
                return (
                  <ListGroupItem
                    onClick={() => {
                      setCalendarViewMode(CALENDAR_VIEW_MODES.CALENDAR_VIEW);
                      if (selectedStaffIdArray.includes(posUserId)) {
                        setshowEmployeeHeader(false);
                        setSelectedStaffIdArray([]);
                      } else {
                        setSelectedStaffIdArray([posUserId]);
                        setshowEmployeeHeader(true);
                      }

                      setSelectedStaffData(item);

                      setSelectedStaffFrom("singleStaff");
                    }}
                    className="SidebarRightItems active"
                  >
                    <div className="sidebarRightInnerItems"
                    
                    >
                      <img
                        src={imageUrl || Images?.userImg_}
                        alt="image"
                        width={36}
                        style={{
                          border:`1px solid ${item?.color_code??"#dee2e6"}`
                        }}
                        height={36}
                        className="bookUserImg"
                      />
                      <span className="bookUserDot">
                        {item?.appointment_counts}
                      </span>
                    </div>
                    <div className="innerItemUsersName">
                      <h3 className="fontSize14 fw500 txtDarkblue mb-1">
                        {
                          item?.user?.user_profiles?.firstname+" "+
                          item?.user?.user_profiles?.lastname
                        }
                      </h3>

                      <h2 className="bookingUserCount mb-0">
                        <span className="greenDot"></span>
                        {
                          item?.appointment_counts  
                        }
                      </h2>
                    </div>
                  </ListGroupItem>
                );
              })}
            </div>
          </ListGroup>
          <div className="bookSideDown mt-auto w-100 text-center">
            <div
              className="userSideBar mb-3"
              onClick={() => {
                setSelectedStaffFrom("allStaff");
                setCalendarViewMode(CALENDAR_VIEW_MODES.CALENDAR_VIEW);
                setshouldShowCalendarModeOptions(true);
                setSelectedStaffIdArray([]); //clears any single staff user selection

                if (selectedStaffIdArray.length > 0) {
                  setshowEmployeeHeader(true);
                } else {
                  setshowEmployeeHeader(!showEmployeeHeader);
                }
              }}
            >
              <Link className="userBook SidebarRightItems" to="#">
                <div className="sidebarRightInnerItems">
                  <img
                    src={
                      Images?.sidebarUserImg
                      // || Images?.usersImages
                    }
                    alt="image"
                    className="img-fluid  sidebarUserImg"
                  />
                  <span className="bookUserDot">
                    {staffUsersList?.length || "0"}
                  </span>
                </div>
                <div className="innerItemUsersName">
                  <h3 className="fontSize14 fw500 txtDarkblue mb-1">
                    {t('allStaffs')}
                  </h3>

                  <h2 className="bookingUserCount mb-0">
                    <span className="greenDot"></span>
                    {staffUsersList?.length || "0"}
                    
                  </h2>
                </div>
              </Link>
            </div>
            {/* <img
              onClick={() => setisCalendarSettingModalVisible(true)}
              src={
                Images?.settingBlue
                // || Images?.settingBlue
              }
              alt="image"
              className="img-fluid "
            /> */}
          </div>
        </div>

        {showRequestsView ? (
          <div className="addBucket AddtoCart">
            <div className="bucket_">
              <div className="addBucketInfo">
                <img
                  src={
                    Images?.drivefast_
                    // || Images?.calendarDark
                  }
                  alt="calendar"
                  className="img-fluid"
                />
                <span className="countNumber">
                  {" "}
                  {selectedStaffIdArray.length > 0
                    ? getAppointmentByStaffIdList?.length ?? 0
                    : appointmentListArr?.length ?? 0}
                </span>
                <span className="fontEighteen ms-2"> {t('requests')}</span>
              </div>
              <img
                onClick={() => setshowRequestsView((prev) => !prev)}
                src={
                  Images?.drivefast_
                  // || Images?.crossBlue
                }
                alt="crossBlue"
                className="img-fluid  text-end"
              />
            </div>
            <Spacer space={16} />
            <ScrollView style={{ height: "90%" }}>
              {selectedStaffIdArray.length > 0
                ? getAppointmentByStaffIdList
                : appointmentListArr?.map((item, index) => {
                    const userDetails = item?.user_details;
                    const invitedUserDetails = item?.invitation_details;
                    const userId = item?.user_id;
                    const customerDetails =
                      userId != null ? userDetails : invitedUserDetails;
                    const userAddress = userDetails?.current_address;
                    const posUserDetails =
                      item?.pos_user_details?.user?.user_profiles;
                    const appointmentID = item?.id;
                    return (
                      <div
                        className={
                          item?.mode_of_payment == "cash"
                            ? "bg-skygrey border-lightpurple" +
                              " bookingRequest"
                            : "bg-green-50 border-green" + " bookingRequest"
                        }
                      >
                        <div className="checkUser">
                          <div className="userCheckin unpaidDetails">
                            <h6 className="userText">{t('customer')}:</h6>

                            <div className="checkinBg">
                              <div className="paymentMode">
                                <span
                                  className={
                                    "textPaymentMode " +
                                      item?.mode_of_payment ==
                                    "cash"
                                      ? "textNeavyBlue"
                                      : "textWhite" + " mr-6"
                                  }
                                >
                                  {"Paid"}
                                  {/* {item?.mode_of_payment == "cash"
                                    ? "Unpaid"
                                    : "Paid"} */}
                                </span>
                                <img
                                  src={
                                    Images?.drivefast_
                                    // || Images?.complete
                                  }
                                  alt="complete"
                                  className="completeimg"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="customerCheck d-flex mt-2">
                            <figure className="profileImage">
                              <img
                                src={
                                  customerDetails?.profile_photo ??
                                  Images?.drivefast_
                                  //|| Images?.defaultUser
                                }
                                alt="customerImg"
                                width={50}
                                height={50}
                                className="img-fluid userImg40"
                              />
                            </figure>
                            <div className="">
                              <span className="innerHeading">
                                {(customerDetails?.firstname ?? "") +
                                  " " +
                                  (customerDetails?.lastname ?? "")}
                              </span>
                              <div className="">
                                <img
                                  src={
                                    Images?.drivefast_
                                    // || Images?.locatePurple
                                  }
                                  alt="locate"
                                  className="locate me-2"
                                />
                                <span className="purpleText">
                                  {userAddress?.street_address ?? "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="userCheckin  mt-4">
                            <h6 className="textSmall fw-600">
                              {t('serviceReq')}:
                            </h6>
                            <div className="userService">
                              <span className="orderTrackTableTxt me-2">
                                {item?.product_name}
                              </span>
                              {/* <span className="orderTrackTableTxt">Pet Bathing</span> */}
                            </div>
                          </div>
                          <div className="ServiceText mt-4 mb-4">
                            <h6 className="textSmall">{t('serviceTime')}</h6>
                            <div className="d-flex mt-3">
                              <div className="serviceDate">
                                <img
                                  src={
                                    Images?.drivefast_
                                    // || Images?.calendarDark
                                  }
                                  alt="calendarImg"
                                  className="calendaerImg me-2"
                                />
                                <span className="purpleText fw-600">
                                  {moment
                                    .utc(item?.start_date_time)
                                    .format("dddd, DD/MM/YYYY")}
                                </span>
                              </div>
                              <div className="serviceDate">
                                <img
                                  src={
                                    Images?.drivefast_
                                    // || Images?.timeImg
                                  }
                                  alt="timeIcon"
                                  className="timeImage me-2"
                                />
                                <span className="purpleText fw-600">
                                  {calculateTimeDuration(item)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="borderDashed"></div>
                          <div className="bookingsAmountView mt-4 mb-2">
                            <h6 className="textBookingAmountmr-6">Total</h6>
                            <h6 className="textBookingAmount">
                              {item?.mode_of_payment?.toUpperCase() === "JBR"
                                ? item?.mode_of_payment?.toUpperCase() + " "
                                : "$"}

                              {`${parseFloat(item?.price).toFixed(2)}`}
                            </h6>

                            <div className="checkinBg ml-16">
                              <button
                                className="rejectBtn mr-6"
                                type="submit"
                                onClick={async () => {
                                  setshowRequestsView((prev) => !prev);
                                  updateBookingStatus(
                                    appointmentID,
                                    APPOINTMENT_STATUS.REJECTED_BY_SELLER
                                  );
                                }}
                              >
                                {t('decline')}
                              </button>
                              <button
                                className="acceptBtn"
                                type="submit"
                                onClick={async () => {
                                  setshowRequestsView((prev) => !prev);
                                  updateBookingStatus(
                                    appointmentID,
                                    APPOINTMENT_STATUS.ACCEPTED_BY_SELLER
                                  );
                                }}
                              >
                                {t('confirm')}
                              </button>
                              {/* <div className="confirmbtn">
                                Confirm
                                <img
                                  src={Images?.drivefast_|| Images?.ArrowRight}
                                  alt="greenRight"
                                  className="img-fluid "
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </ScrollView>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const DisplayBookings = ({ appointments }) => {
    return (
      <div className="commonTable table-responsive bookingCalendarViewTable">
        <table className="w-100">
          <thead className="invoiceHeadingBox">
            <tr>
              <th className="text-start">{t('client')}</th>
              <th>{t('staff')}</th>
              <th>{t('service')}</th>
              <th>{t('time')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {appointments?.length > 0 ? (
              <>
                {appointments?.map((item, index) => {
                  const userDetails = item?.user_details;
                  const invitedUserDetails = item?.invitation_details;
                  const userId = item?.user_id;
                  const customerDetails =
                    userId != null ? userDetails : invitedUserDetails;
                  const userAddress = userDetails?.current_address;
                  const posUserDetails =
                    item?.pos_user_details?.user?.user_profiles;
                  const appointmentID = item?.id;

                  return (
                    <tr>
                      <td>
                        <div className="flexBox gap-2">
                          <img
                            src={
                              customerDetails?.profile_photo ?? Images?.userImg_
                            }
                            alt="avtar"
                            className="userInnerImg"
                          />

                          <div className="text-start">
                            <h3 className="orderTrackTableTxt mb-1">
                              {(customerDetails?.firstname ?? "") +
                                " " +
                                (customerDetails?.lastname ?? "")}
                            </h3>
                            <div className="flexBox gap-1">
                              <img src={Images.locateOrder} alt="img" />
                              <span className="fontSize12 fw500 locationText">
                                {userId !== null
                                  ? customerDetails?.phone_number
                                  : customerDetails?.phone_code +
                                    customerDetails?.phone_no}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="orderTrackTableTxt">
                          {(posUserDetails?.firstname ?? "") +
                            " " +
                            (posUserDetails?.lastname ?? "")}
                        </span>
                      </td>

                      <td>
                        <span className="orderTrackTableTxt">
                          {item?.product_name}
                        </span>
                      </td>
                      <td>
                        <div className="flexBox justify-content-center gap-2">
                          <img src={Images?.clockIcon} alt="clock" />
                          <span className="orderTrackTableTxt">
                            {`${item?.start_time}-${item?.end_time}`}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="flexBox justify-content-end">
                          {renderButtons(item,index)[item?.status]}
                          {/* <figure
                            className="checkinBox me-2 "
                            onClick={() => {
                              handleUserProfile("checkIn");
                            }}
                          >
                            <span className="textSmall me-2">
                              {item?.status}
                            </span>
                            <img
                              src={Images?.drivefast_|| Images?.checkImg}
                              alt="money"
                              className="moneyImg"
                            />
                          </figure>
                          <button className="editBtn">
                            <img
                              src={Images?.drivefast_|| Images?.editImg}
                              alt="editImg"
                              className="editImg"
                            />
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={8} className="noRecordTxt">
                  {t('noRecordfound')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className="bookingCalenderSecion flexBox h-100">
        <div className="mainBooking">
          <CalendarHeaderWithOptions
            {...{
              prevMonth,
              getFormattedHeaderDate,
              nextMonth,
              day,
              dayHandler,
              week,
              weekHandler,
              month,
              monthHandler,
              calendarViewMode,
              shouldShowCalendarModeOptions,
              calendarDate,
              setCalendarDate,
            }}
            onPressCalendarIcon={() => {
              setshowMiniCalendar(!showMiniCalendar);
            }}
            onPressNotification={() => {
              // router.push(
              //   "/transactions/notification",
              //   `/customers/notification`
              // );
              // navigate(NAVIGATION.notificationsList, {
              //   screen: NAVIGATION.calender,
              // });
            }}
            onPressSearch={() => {
              setKey2(Math.random());
              setShowSearchModal(true);
              setSearchedAppointments([]);
              setSearchedText("");
              // setTimeout(() => {
              //   searchAppoinmentInputRef.current.focus();
              // }, 300);
            }}
            onPressCalendarViewMode={onPressCalendarViewMode}
            onPressListViewMode={onPressListViewMode}
          />

          {/* Second Navbar */}
          <div className="bookingCalendar newBookingCalander" >
            {appointmentsDetails?.loading ? (
              <>
                <tbody>
                  <div className="loaderOuter">
                    <div className="spinner-grow loaderSpinner text-center my-5"></div>
                  </div>
                </tbody>
              </>
            ) : (
              <>
                {calendarViewMode === CALENDAR_VIEW_MODES.CALENDAR_VIEW ? (
                  <div>
                    {showEmployeeHeader && employeeHeader()}
                    <Calendar
                      ampm={isAMPM}
                      swipeEnabled={false}
                      date={calendarDate}
                      mode={calendarMode}
                      events={extractedAppointment}
                      hourRowHeight={windowHeight * 0.1}
                      // height={windowHeight * 1.065}
                      height={windowHeight * 0.91}
                      // height={
                      //   windowHeight *
                      //   (calendarMode === CALENDAR_MODES.MONTH
                      //     ? showEmployeeHeader
                      //       ? 0.7
                      //       : 0.75
                      //     : 0.96)
                      // }
                      // {...(showEmployeeHeader
                      //   ? {
                      //       renderHeader: () => employeeHeader(),
                      //       renderHeaderForMonthView: () => employeeHeader(),
                      //     }
                      //   : {})}
                      isEventOrderingEnabled={false}
                      headerContainerStyle={{
                        height:
                          calendarMode === CALENDAR_MODES.MONTH ? "auto" : 70,
                        backgroundColor: "#fff",
                        paddingTop:12,
                      }}
                      dayHeaderHighlightColor={"rgb(66, 133, 244)"}
                      hourComponent={CustomHoursCell}
                      onPressEvent={(event) => {
                        setEventData(event);
                        if (calendarMode === CALENDAR_MODES.MONTH) {
                          dayHandler();
                          setCalendarDate(moment.utc(event.start));
                        } else {
                          // setshowEventDetailModal(true);
                          handleOnOpenAppointmentModal(
                            "appointmentDetailModal"
                          );
                        }
                      }}
                      renderEvent={(event, touchableOpacityProps, allEvents) =>
                        CustomEventCell(
                          event,
                          touchableOpacityProps,
                          allEvents,
                          calendarMode,
                          employeeHeaderLayouts,
                          showEmployeeHeader
                        )
                      }
                    />
                  </div>
                ) : (
                  <div className="col-lg-12">
                    <Spacer space={16} />

                    <div
                      style={{ backgroundColor: "#fff", paddingHorizontal: 10 }}
                    >
                      {/* To show Header horizontal dates in selected month */}
                      <div
                        className="bookingdateList_"
                        style={{
                          display: "flex",
                          overflowX: "scroll",
                        }}
                      >
                        {monthDays.map((item, index) =>
                          renderMonthItem({ item, index })
                        )}
                      </div>

                      <Spacer space={8} />

                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "baseline",
                          marginVertical: 10,
                        }}
                      >
                        <Text style={{ fontSize: 24, color: "#263682" }}>
                          {moment.utc(calendarDate).format("dddd")}
                        </Text>
                        <Spacer space={5} horizontal />
                        <Text style={{ fontSize: 14, color: "#8D99D2" }}>
                          {moment.utc(calendarDate).format("Do") ??
                            moment.utc().format("Do")}
                        </Text>
                      </View>
                    </div>
                    <DisplayBookings
                      appointments={
                        // ADMIN()?.length>0?
                        getAppointmentsByDate
                        // :getAppointmentsByDate?.filter(i=>i?.pos_user_details?.user_id===authData?.payload.id)
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <CommonSideBar />
      </div>

      <CustomModal
        key={key1}
        show={showRescheduleTimeModal}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={"commonWidth customContent"}
        ids={"appointmentDetailModal"}
        child={
          <BookingSchedule
            showRecheduleModal={showRescheduleTimeModal}
            data={selectedBooking}
            handleAllAppointmentData={() => {
              getAllBookingDetails();
            }}
            changeStatusOfAppointment={(id, status) =>
              changeStatusOfAppointmentHandle(id, status)
            }
            setshowEventDetailModal={() => {
              handleOnOpenAppointmentModal("appointmentDetailModal");
            }}
            close={closeRescheduleModal}
          />
        }
        header={
          <>
            <div className="flexBox gap-3">
              <img src={Images.customCalendar} alt="img" />
              <h4 className="fontSize24 txtDarkblue mb-0">
                {t('editDetails')}
                <span className="fw500"> {`# ${selectedBooking?.id}`}</span>
              </h4>
            </div>
            <img
              src={Images.modalCross}
              className="ModalCancel cursorPointer"
              onClick={closeRescheduleModal}
            />
          </>
        }
        onCloseModal={closeRescheduleModal}
      />

      <CustomModal
        key={key2}
        show={showSearchModal}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={"checkIn"}
        ids={"bookingSearchModal"}
        child={
          <div>
            <div
              onClick={closeSearchModal}
              className="text-end mb-3 cursorPointer"
            >
              <img src={Images.modalCross} alt="Close" width={24} height={24} />
            </div>

            <div className="input-group search_group inboxSearchInput">
              <img src={Images.SearchIcon} alt="Scan" />
              <input
                ref={searchAppoinmentInputRef}
                className="form-control ps-3"
                placeholder="Search appointments with service name, customer name or phone number"
                placeholderTextColor="#626262"
                value={searchedText}
                onChange={(e) => {
                  setIsLoadingSearchAppoinment(true);
                  setSearchedText(e.target.value);
                  debouncedSearchAppointment(e.target.value);
                }}
              />
            </div>

            <div style={{ marginTop: "8px" }} />

            {isLoadingSearchAppoinment ? (
              <ActivityIndicator size="large" color="#0000ff" />
            ) : (
              <DisplayBookings appointments={searchedAppointments} />
            )}
          </div>
        }
        onCloseModal={closeSearchModal}
      />

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={
          modalDetail.flag === "checkIn" ? "commonWidth customContent" : ""
        }
        ids={
          modalDetail.flag === "checkIn"
            ? "checkInModal"
            : modalDetail.flag === "bookingRequestModal"
            ? "bookingRequestModal"
            : ""
        }
        child={
          modalDetail.flag === "checkIn" ? (
            <CheckinModal
              bookingDetails={selectedBooking}
              close={() => handleOnCloseModal()}
              formattedTime={formattedTime}
              loading={updateBooking}
              onConfirmPress={() => {
                handleOnCloseModal();
                updateBookingStatus(
                  selectedBooking?.id,
                  APPOINTMENT_STATUS.CHECKED_IN
                );
                onSearchAppoinment(searchedText);
              }}
              onDeclinePress={()=>{
                handleOnCloseModal();
                updateBookingStatus(
                  selectedBooking?.id,
                  APPOINTMENT_STATUS.REJECTED_BY_SELLER
                );
                onSearchAppoinment(searchedText);
              }}
            />
          ) : modalDetail.flag === "bookingRequestModal" ? (
            <RequestModal
            loading={updateBooking}
            selectedStaffIdArray={selectedStaffIdArray}
            getAppointmentByStaffIdList={getAppointmentByStaffIdList}
            appointmentListArr={appointmentListArr}
            updateBookingStatus={updateBookingStatus}
            close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        // header={
        //   modalDetail.flag === "checkIn" ? (
        //     <>
        //       <div className="trackingSub headerModal ">
        //         <figure className="profileImage ">
        //           <img
        //             src={
        //               Images?.drivefast_
        //               // || Images?.checkinSky
        //             }
        //             alt="check"
        //             className="img-fluid "
        //           />
        //         </figure>
        //         <h4 className="loginheading mt-2">Check In</h4>
        //         <h4 className="trackingHeading">
        //           Confirm the details of your appointment.
        //         </h4>
        //         <p onClick={handleOnCloseModal} className="crossModal">
        //           <img
        //             src={
        //               Images?.drivefast_
        //               // || Images?.modalCross
        //             }
        //             alt="modalCross"
        //             className="img-fluid"
        //           />
        //         </p>
        //       </div>
        //     </>
        //   ) : (
        //     ""
        //   )
        // }
        onCloseModal={() => handleOnCloseModal()}
      />

      {/* {showEventDetailModal && (
        <div className="addBucket AddtoCart">
          <ScrollView style={{ height: "100%" }}>
            <EventDetailModal
              {...{ eventData, setshowEventDetailModal }}
              onAppointmentStatusUpdate={(appointmentId, appointmentStatus) => {
                updateBookingStatus(appointmentId, appointmentStatus);
              }}
              onModifyAppointmentPress={(selectedBooking) => {
                setSelectedBooking(selectedBooking);
                setKey1(Math.random());
                setshowRescheduleTimeModal(true);
              }}
            />
          </ScrollView>
        </div>
      )} */}

      <CustomModal
        key={keyAppointment}
        show={appointmentModal.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          appointmentModal.flag === "appointmentDetailModal"
            ? "appointmentDetailModal"
            : ""
        }
        className={
          appointmentModal.flag === "rescheduleModal"
            ? "commonWidth customContent"
            : ""
        }
        child={
          appointmentModal.flag === "appointmentDetailModal" ? (
            <AppointmentDetailModal
              data={eventData?.completeData}
              // options={options}
              onAppointmentStatusUpdate={(appointmentId, appointmentStatus) => {
                updateBookingStatus(appointmentId, appointmentStatus);
              }}
              handleOpen={handleOpenModal}
              reschedule={(selectedBooking) => {
                setSelectedBooking(selectedBooking);
                setKey1(Math.random());
                setshowRescheduleTimeModal(true);
              }}
              close={() => handleOnCloseAppointmentModal()}
            />
          ) : (
            ""
          )
        }
        header={
          appointmentModal.flag === "appointmentDetailModal" ? (
            <>
              <div className="flexBox gap-3">
                <img src={Images.customCalendar} alt="img" />
                <h4 className="fontSize24 txtDarkblue mb-0">
                  {t('appointMentDetail')}
                  {/* <span className="fw500">{event.?.id}</span> */}
                </h4>
              </div>
              <img
                src={Images.modalCross}
                className="ModalCancel cursorPointer"
                onClick={handleOnCloseAppointmentModal}
              />
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseAppointmentModal()}
      />
      {isCalendarSettingModalVisible && (
        <div className="addBucket AddtoCart">
          <CalendarSettingModal
            isVisible={isCalendarSettingModalVisible}
            setIsVisible={setisCalendarSettingModalVisible}
            currentCalendarMode={calendarMode}
            currentTimeFormat={isAMPM}
            defaultSettingsForCalendar={defaultSettingsForCalendar}
            onPressSave={(calendarPreferences) => {
              onPressSaveCalendarSettings(calendarPreferences);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Booking;
