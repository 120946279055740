import React, { useState, useCallback, useEffect } from "react";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { imageUploadAsync } from "../message/messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import * as Images from "../../../utilities/images";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const CreateCouponModal = (props) => {
  const { t } = useTranslation();
  const { couponData } = props;

  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [document, setDocument] = useState("");
  const [selectType, setSelectType] = useState({
    value: "percentage",
    label: "Percentage",
  });

  const toastId = React.useRef(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [minOrderAmount, setminOrderAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [minDiscount, setminDiscount] = useState("");
  const [maxDiscount, setMaxDiscount] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [description, setDiscription] = useState("");
  const [maxTotalUsage, setMaxTotalUsage] = useState("");


  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const validTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB max size

      const file = acceptedFiles[0];
      if (!validTypes.includes(file.type)) {
        showToast("Invalid file type. Only JPEG and PNG are allowed.");
        return;
      }

      if (file.size > maxSize) {
        showToast("File size exceeds the 5MB limit.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      const params = {
        file: formData,
        token: auth?.payload?.token,
      };
      setLoading(true);
      dispatch(imageUploadAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setDocument(obj?.payload[0]?.filePath); // Update the single image
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [dispatch, setDocument, auth]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png", // Restrict file types
    multiple: false, // Allow only one file
  });

  const deleteImage = () => {
    setDocument(""); // Clear the uploaded image
    toast.success("Image removed successfully.");
  };

  const validateForm = () => {
    if (!document) return "Please select the image";
    if (!name.trim()) return "Please enter name";
    if (!code.trim()) return "Please enter coupon code";
    // if (!/^[a-zA-Z0-9]+$/.test(code)) return "Coupon code must be alphanumeric";
    if (!startDate) return "Please select start date";
    if (!endDate) return "Please select end date";
    if (moment(endDate).isBefore(moment(startDate)))
      return "End date must be after start date";

    if (selectType.value === "percentage") {
      if (discountPercentage < 1 || discountPercentage > 100)
        return "Please enter discount percentage between 1 and 100";
    } else {
      if (!discount || discount <= 0)
        return "Please enter a valid discount amount";
    }

    if (maxDiscount < 1 || maxDiscount > 100)
      return "Please enter max discount between 1 and 100";

    if (minDiscount < 1 || minDiscount > 100)
      return "Please enter min discount between 1 and 100";

    if (!minOrderAmount || minOrderAmount <= 0)
      return "Please enter minimum order amount";

    if (!maxTotalUsage || maxTotalUsage <= 0)
      return "Please enter Maximum Total Usage";

    if (Number(maxDiscount) < Number(minDiscount)) {
      return "Max discount should be greater than or equal to min discount";
    }

    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(validationError);
      }
      return;
    }

    const couponData = {
      name: name,
      code: code,
      discount_amount: selectType.value === "percentage" ? undefined : discount,
      minimum_order_amount: minOrderAmount || undefined,
      max_discount: maxDiscount || undefined,
      min_discount: minDiscount || undefined,
      max_total_usage: maxTotalUsage || undefined,
      image: document,
      start_time: moment(startDate).format("YYYY-MM-DD"),
      end_time: moment(endDate).format("YYYY-MM-DD"),
      type: selectType.value,
      discount_percentage:
        selectType.value === "percentage" ? discountPercentage : undefined,
      description: description || undefined,
    };
    props.setCouponData(couponData);
    props.close();
  };

  const options = [
    { value: "flat", label: "Flat" },
    { value: "percentage", label: "Percentage" },
  ];

  const handleTypeChange = (selectedOption) => {
    setSelectType(selectedOption);
  };

  // Prefill form data when couponData is available
  useEffect(() => {
    if (couponData) {
      setName(couponData.name || "");
      setCode(couponData.code || "");
      setDocument(couponData.image || "");
      setStartDate(
        couponData.start_time ? new Date(couponData.start_time) : ""
      );
      setEndDate(couponData.end_time ? new Date(couponData.end_time) : "");
      setSelectType(
        couponData.type === "flat"
          ? { value: "flat", label: "Flat" }
          : { value: "percentage", label: "Percentage" }
      );
      setDiscount(couponData.discount_amount || "");
      setDiscountPercentage(couponData.discount_percentage || "");
      setMaxDiscount(couponData.max_discount || "");
      setminDiscount(couponData.min_discount || "");
      setminOrderAmount(couponData.minimum_order_amount || "");
      setMaxTotalUsage(couponData.max_total_usage || "");
      setDiscription(couponData.description || "");
    }
  }, [couponData]);

  return (
    <>
      <div className="productCoupon mt-2 createPageInner_ p-0">
        <form className="ProductCategory row coupons">
          <div className="form-group col-lg-12 col-md-12 col-sm-12 mb-4 text-start">
            <h3 className="headblue18 m-0 mb-3">{t("uploadPhoto")}</h3>
            <div className="showToken_Img chartUploadSection">
              {document ? (
                // Show uploaded image with delete option
                <div className="uploadedImgContainer position-relative">
                  <img
                    className="uploadedChart"
                    src={document}
                    alt="Uploaded file"
                  />
                  <button className="optionDelete" onClick={deleteImage}>
                    <img
                      src={Images.Delete_Outline}
                      alt="Delete image"
                      className="img-fluid"
                    />
                  </button>
                </div>
              ) : (
                // Show placeholder with camera logo
                <div {...getRootProps({ className: "" })}>
                  <input
                    name="image"
                    type="file"
                    {...getInputProps()}
                    accept="image/jpeg, image/png" // Restrict file types
                  />
                  <div className="uploadImg_Parent">
                    <div className="d-flex align-items-center">
                      {loading ? (
                        <div className="spinner-container">
                          <span className="spinner-border spinner-border-md spinnerDark"></span>
                        </div>
                      ) : (
                        <>
                          <img
                            src={Images.CameraLogo}
                            alt="Camera"
                            style={{ cursor: "pointer" }}
                          />
                          <p className="dragAndDrop_Txt ms-2">
                            {t("uploadFile")} <br /> {t("dragFile")}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
              <p className="supportImgTxt">{t("supportFormat")}: png, jpg</p>
              <p className="supportImgTxt">{t("maxImageSize")}: 5 MB</p>
            </div>
          </div>

          <div className="mb-3 form-group col-md-6">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("couponName")}
            </label>
            <div className="inputGroup">
              <input
                placeholder="Enter the Name"
                className="customInput"
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
          </div>

          <div className="mb-3 form-group col-md-6">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("couponCode")}
            </label>
            <div className="inputGroup">
              <input
                placeholder="Enter the Code"
                className="customInput"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          </div>

          {/* date */}
          <div className="col-12 mb-3">
            <h3 className="headblue18 m-0 mb-3">{t("date")}</h3>
            <div className="row">
              <div className="mb-3 form-group col-md-6">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("startFrom")}
                </label>
                <div className="customer-calendar-cnt">
                  <img className="calenderIcon_" src={Images.calenderLogo} />
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    minDate={moment().toDate()}
                    placeholderText="Start Date"
                  />
                </div>
              </div>

              <div className="mb-3 form-group col-md-6">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("endOn")}
                </label>

                <div className="customer-calendar-cnt">
                  <img src={Images.calenderLogo} alt="calender" />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    // minDate={moment().toDate()}
                    disabled={!startDate}
                    minDate={startDate}
                    placeholderText="End Date"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* type */}

          <div className="mb-3 form-group col-md-12 ">
            <div className="reactSelectParent commonSelectParent w-100">
              <label className="fontSize16 txtDarkblue mb-2 ps-4">
                {t("selectType")}
              </label>
              <ReactSelect
                value={selectType}
                onChange={(selectedOption) => handleTypeChange(selectedOption)}
                options={options}
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                isSearchable={true}
                placeholder="select the type"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100% !important",
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    border: "1px solid #D7DEFF",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682"
                      : state.isFocused
                      ? "#f5f6fc"
                      : "#fff",

                    color: state.isSelected ? "#fff" : "#636E9F",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#636E9F",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
              />
            </div>
          </div>

          {selectType.value === "percentage" ? (
            <>
              {/* discount percentage */}
              <div className="mb-3 form-group col-md-6">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("discountPercentage")}
                </label>

                <div className="inputGroup">
                  <span className="dollrsign1_">%</span>

                  <input
                    placeholder="Enter the Discount percentage"
                    className="customInput costPriceInput"
                    type="number"
                    onKeyDown={blockInvalidChar}
                    value={discountPercentage}
                    onChange={(e) => {
                      let value = e.target.value;

                      if (value >= 0 && value <= 100) {
                        setDiscountPercentage(value);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="mb-3 form-group col-md-6">
              <label className="fontSize16 txtDarkblue mb-2 ps-4">
                {t("discountAmount")}
              </label>
              <div className="inputGroup">
                <span className="dollrsign1_">$</span>
                <input
                  placeholder="Enter the Discount Amount"
                  className="customInput costPriceInput"
                  onKeyDown={blockInvalidChar}
                  type="number"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
            </div>
          )}

          {/* set maximum discount */}
          <div className="mb-3 form-group col-md-6">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("maxDiscount")}
            </label>

            <div className="inputGroup">
              <span className="dollrsign1_">$</span>
              <input
                placeholder="Enter the Maximum Discount"
                className="customInput costPriceInput"
                type="number"
                onKeyDown={blockInvalidChar}
                value={maxDiscount}
                onChange={(e) => setMaxDiscount(e.target.value)}
              />
            </div>
          </div>

          {/* minimum discount */}
          <div className="mb-3 form-group col-md-6">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("minDiscount")}
            </label>

            <div className="inputGroup">
              <span className="dollrsign1_">$</span>
              <input
                placeholder="Enter the Minimum Discount"
                className="customInput costPriceInput"
                type="number"
                onKeyDown={blockInvalidChar}
                value={minDiscount}
                onChange={(e) => setminDiscount(e.target.value)}
              />
            </div>
          </div>

          {/* minimum Order Amount */}
          <div className="mb-3 form-group col-md-6">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("minOrderAmount")}
            </label>

            <div className="inputGroup">
              <span className="dollrsign1_ ">$</span>
              <input
                placeholder="Enter the Minimum Order Amount"
                className="customInput costPriceInput"
                type="number"
                onKeyDown={blockInvalidChar}
                value={minOrderAmount}
                onChange={(e) => setminOrderAmount(e.target.value)}
              />
            </div>
          </div>

          {/* maximum total usage */}
          <div className="mb-3 form-group col-md-6">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("maximumTotalUsage")}
            </label>
            <div className="inputGroup">
              <input
                placeholder="Enter the total Maximum usage"
                className="customInput"
                type="number"
                onKeyDown={blockInvalidChar}
                value={maxTotalUsage}
                onChange={(e) => setMaxTotalUsage(e.target.value)}
              />
            </div>
          </div>

          {/* discount percentage */}
          <div className="mb-3 form-group col-md-6">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("description")}
            </label>
            <div className="inputGroup">
              <input
                placeholder="Enter the Description"
                className="customInput"
                type="text"
                value={description}
                onChange={(e) => setDiscription(e.target.value)}
              />
            </div>
          </div>
        </form>

        <div className="d-flex align-items-center editVariantModal_Footer">
          <button
            className="modalDiscardBtn w-100"
            onClick={() => props?.close()}
          >
            {t("discard")}
          </button>
          <button
            className="modalNextBtn modalDiscardBtn active w-100"
            type="button"
            onClick={handleSubmit}
          >
            {t("update")}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateCouponModal;
