import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Images from "../../../utilities/images";
import UploadImage from "./uploadImage";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { getServicesAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { createCashAsync } from "../cashDrawer/cashDrawSlice";
import { createCategoryAsync, updateCategoryAsync } from "../myProducts/productSlice";
import { useTranslation } from "react-i18next";

const CreateCategoryModal = (props) => {
  const { t } = useTranslation();
  const { services, close, title, selectedCategory , getAllCategory} = props;
  console.log(selectedCategory, "selectedCategory");
  const toastId = useRef(null);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [file, setFile] = useState(selectedCategory?.image || null);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  console.log(selectedService, "selectedService");
  const [servicesId, setServicesId] = useState(
    selectedCategory?.service_id || ""
  );
  const [categoryName, setCategoryName] = useState(
    selectedCategory?.name || ""
  );
  const [categoryDesc, setCategoryDesc] = useState(
    selectedCategory?.description || ""
  );

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleServicesChange = (e) => {
    setSelectedService(e);
    setServicesId(e.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!categoryName || !categoryDesc || !servicesId || !file) {
      showToast("All fields are required. Please fill in all details.");
      return;
    }
  
    setLoading(true);
  
    let data = {
      name: categoryName.trim(),
      description: categoryDesc.trim(),
      service_id: servicesId,
      image: file,
    };
  
    // Add id to data if updating an existing category
    if (title) {
      data = { ...data, id: selectedCategory?.id }; // Spread data and add id
    }
  
    let params = {
      postData: data,
      tokenData: auth?.payload?.token,
    };
  
    console.log(data, "data");
  
    // Determine API call based on title presence (update if title exists, otherwise create)
    const apiCall = title ? updateCategoryAsync : createCategoryAsync;
  
    dispatch(apiCall(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        close();
        getAllCategory();
      })
      .catch((err) => {
        console.error(err, "API Error");
        setLoading(false);
      });
  };
  

  useEffect(() => {
    if (selectedCategory?.service_id && services.length > 0) {
      const matchedService = services.find(
        (service) => service.value === selectedCategory.service_id
      );
      setSelectedService(matchedService || null);
    }
  }, [selectedCategory, services]);

  return (
    <form className="categoryModalSec" onSubmit={(e) => handleSubmit(e)}>
      <div className="categoryModalInner">
        <div className="row">
          <div className="col-md-12 ">
            <label className="fontSize16 txtDarkblue mb-2 ps-4">
              {t("uploadImages")}
            </label>
            <UploadImage file={file} setFile={setFile} />
          </div>

          <div className="col-md-6 ">
            <div className="form_group">
              <div className="reactSelectParent commonSelectParent w-100">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  {t("masterCategory")}
                </label>
                <ReactSelect
                  value={selectedService}
                  options={services}
                  onChange={handleServicesChange}
                  isSearchable={true}
                  placeholder={t("selectOne")}
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form_group">
              <label className="fontSize16 txtDarkblue mb-2 ps-4">{t("name")}</label>
              <div className="inputGroup">
                <input
                  className="customInput"
                  placeholder="Name"
                  name="text"
                  type="text"
                  value={categoryName}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 ">
            <div className="form_group">
              <label className="fontSize16 txtDarkblue mb-2 ps-4">
                {t("description")}
              </label>
              <div className="inputGroup">
                <textarea
                  className="customInput customTextarea"
                  placeholder="Text here"
                  id="floatingTextarea"
                  rows="5"
                  value={categoryDesc}
                  onChange={(e) => {
                    setCategoryDesc(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-3">
        <button
          className="modalDiscardBtn"
          type="button"
          onClick={() => {
            close();
          }}
        >
          {t('discard')}
        </button>
        <button
          className="modalNextBtn modalDiscardBtn active"
          type="submit"
          disabled={loading}
        >
          {loading && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}{" "}
          {title ? t("update") : t("save")}
        </button>
      </div>
    </form>
  );
};

export default CreateCategoryModal;
