import React, { useState } from "react";
import OTPInput from "react-otp-input";

const GoogleAuthenticode = () => {
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState("");
    const onComplete = code => {
        setOtp(code);

    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(false)
    }

    return (
        <>
            <div className="commonLogin">
                <div className="container-fluid">
                    <div className="row horizontalLogin">
                        <div className="col-lg-6">
                            <div className="loginForm">
                                <div className="loginhead">
                                    <h4 className="heading">Login</h4>
                                    <p className="text">Hey! Enter your login details.</p>
                                </div>
                                <form autoComplete="off" className="form_horizontal" onSubmit={(e) => { handleSubmit(e) }}>
                                    <div className="googleAurhentication">
                                        <h5>Google  Authentication Code</h5>
                                        {/* <ul className="authboxContent">
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul> */}
                                        <div className="mb-5 otp__">
                                            {" "}
                                            <OTPInput
                                                numInputs={5}
                                                placeholder="00000"
                                                className="input_digits_"
                                                value={otp}
                                                // isInputSecure={true}
                                                data-cy="pin-field"
                                                isInputNum={true}
                                                onChange={onComplete}
                                                autoComplete={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 col-12 mx-auto">
                                        <button className="loginBtn" type="submit" disabled={loading}>
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Login</span>
                                        </button>
                                    </div>
                                </form>
                                <div className="copyRight">
                                    <p>All Rights Reserved by JOBR LLC | 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="loginhorrizontal-form ">
                                <div className="login_left">
                                    <img
                                        src=''
                                        className="d-none d-md-block img-fluid"
                                        alt="img"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default GoogleAuthenticode;