import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_USER_SERVICES } from "../../config";
import { REACT_APP_WALLET_URL } from "../../config";
const queryString = require('query-string');

export function getSystemConfigApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/user_settings` + "?" + query, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getUserSettingsApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_settings` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function updateUserSetting(data, token, querData) {
  let query = queryString.stringify(querData)
  return new Promise((resolve, reject) =>
    axios
      .put(query ? `${REACT_APP_USER_SERVICES}api/v1/user_settings` + "?" + query : `${REACT_APP_USER_SERVICES}api/v1/user_settings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getTaxApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/tax?${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getCountriesApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_locations/countries`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getStateApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_locations/states?${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// create tax Api
export function createTaxApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/tax`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function getQrCodeApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/users/2fa/generate-qr-code`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}


export function configureQrCodeApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/users/2fa/configure-qr-code`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)

      })
  )
}

export function getAllUserApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/users/merchant/pos-user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getAllUsersProfilesApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/users/?page=${data.page}&limit=${data.limit}&need_to_age_verification=${data?.need_to_age_verification}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getAllStuffs(data,token) {
  let query = queryString.stringify(data);

  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/users/merchant/pos-user?${query}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function userConfigLocationApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_locations/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getTermsConditionsPoliciesApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_policies`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function archivePolicy(data,token) {
  let id=data.id;
  delete data.id;
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/user_policies/archive/${id}`,data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function createPolicyApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/user_policies`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function updatePolicyApi(data, token) {
  let id = data.id;
  delete data.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/user_policies/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function deletePolicyApi(id, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_USER_SERVICES}api/v1/user_policies/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function activeInactivePolicyApi(data, token) {
  let id = data.id;
  delete data.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/user_policies/active-policy/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getLanguagesApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/languages`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getLanguagesListApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/languages`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function updateUserStatus(data,token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
    .put(`${REACT_APP_USER_SERVICES}api/v1/users/${id}`,data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'merchant',
          'Authorization': token,
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) { 
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

