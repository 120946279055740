import React from "react";
import * as Images from "../../../utilities/images";

const DeleteGiftcardModal = (props) => {
  return (
    <div>
      <div className="text-center">
        <img src={Images.crossCircle} alt="cancleLogo" />
        <div className="stopOfferModalContainer">
          <h3 className="stopOfferTxt mb-0">
            Do you want to delete this gift card?
          </h3>
        </div>

        <div className="d-flex align-items-center editVariantModal_Footer">
          <button
            className="modalDiscardBtn w-100"
            onClick={() => {
              props?.close();
            }}
          >
            Cancel
          </button>
          <button
            className="modalNextBtn modalDiscardBtn modalStopBtn w-100"
            onClick={() => props?.handleDelete()}
          >
            {/* <span className="spinner-border spinner-border-sm"></span> */}
            <img src={Images.newDeleteIcon} alt="stopIcon" className="me-2" />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteGiftcardModal;
