import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { calendar } from "../../../utilities/images";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { imageUploadAsync } from "../message/messageSlice";
import { calendar_light, rewardImg } from "../../../utilities/images";

import {
  getCategoriesAsync,
  getServicesAsync,
  updateProductCouponApiAsync,
} from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { createCouponAsync } from "../myProducts/productSlice";
import { toast } from "react-toastify";
import moment from "moment-timezone";

function CreateCoupon(props) {
  const [dataLoading, setDataLoading] = useState(false);
  const [document, setDocument] = useState("");

  const [productName, setProductName] = useState("");
  const [selectType, setSelectType] = useState({ value: "percentage", label: "Percentage" });
  const [selectedService, setSelectedService] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [servicesId, setServicesId] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [subCategoryId, setSubcategoryId] = useState("");
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const toastId = React.useRef(null);
  const [couponFlag, setCouponFlag] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [minOrderAmount, setminOrderAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [minDiscount, setminDiscount] = useState("");
  const [maxDiscount, setmaxDiscount] = useState("");
  const [couponList, setCouponList] = useState("");
  const [type, setType] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [description, setDiscription] = useState("");

  const [maxTotalUsage, setMaxTotalUsage] = useState("");

  const today = new Date();

  // const [productName, setProductName] = useState(
  //   selectedProductDtails?.name ? selectedProductDtails?.name : ""
  // );

  const dispatch = useDispatch();

  let productResponse;

  const auth = useSelector(selectLoginAuth);

  const sellerType = auth?.payload?.user?.user_profiles?.seller_type;

  console.log(sellerType, "dsssssssssssssssssssss");

  let imageFile;
  const handleChange = (e) => {
    e.preventDefault();
    imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("file", imageFile);
    let params = {
      file: formData,
      token: auth?.payload?.token,
    };
    setDataLoading(true);
    dispatch(imageUploadAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setDataLoading(false);
        setDocument(obj?.payload[0]?.filePath);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
      })
      .catch((obj) => {
        setDataLoading(false);
      });
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleServicesChange = (e) => {
    setCategoryLoading(true);
    setSelectedService(e);
    setServicesId(e.value);
    // props?.setServiceIdforAttributes(e.value);
    setSelectedSubCategory("");
    setSelectedBrand("");
    setSelectedCategory("");
    setCategory([]);
    setCategoryId("");
    setSubCategory([]);
    setSubcategoryId("");
    setBrand([]);
    setBrandId("");
    setSelectedBrand("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: e.value,
        main_category: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategory(filterResult);
        setCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: e.value,
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getServices = () => {
    let data 
    if(sellerType == "manufacturer" || sellerType == "whole_seller"){
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          type: "product",
          check_product_existance: false,
        },
      };
    }
    else{
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          check_product_existance: false,
        },
      };
    }
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (props?.isEdit) {
        } else {
          setLoading(false);
        }
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
        let selectedService = filterResult.find(
          (item) => item.value == props?.selectedProduct?.service_id
        );
        setSelectedService(selectedService ? selectedService : null);

        if (props?.isEdit) {
          let data = {
            tokenData: auth?.payload?.token,
            postData: {
              service_id: props?.selectedProduct?.service_id,
              main_category: true,
              check_product_existance: false,
            },
          };
          dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
              setLoading(false);
              let filterResult = obj?.payload?.data?.map((item) => {
                return {
                  value: item?.id,
                  label: item?.name,
                };
              });

              let categoryResult = filterResult.find(
                (item) => item.value == props?.selectedProduct?.category_id
              );
              setSelectedCategory(categoryResult ? categoryResult : null);

              setCategory(filterResult);
            })
            .catch((obj) => {
              setLoading(false);
            });
        }
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  // let inputFileRef = useRef(null);
  //  const [selectedFile,setselectedFile] = useState("")
  // const handleFileChange = (event, flag) => {
  //   // const selectedFile = event.target.files[0];
  //   inputFileRef = event.target.files[0];
  //   const formData = new FormData();
  //   formData.append("file", inputFileRef);

  //   const params = {
  //     file: formData,
  //     token: auth?.payload?.token,
  //   };

  //   dispatch(imageUploadAsync(params))
  //     .then(unwrapResult)
  //     .then((obj) => {
  //       {
  //         console.log("hello");
  //         setImageUrl(obj?.payload[0]?.filePath);
  //       }
  //       // handleSubmit();
  //     })
  //     .catch((error) => {
  //       console.error("Error uploading image:", error);
  //     });
  // };

  const handleCategoryChange = (e) => {
    setSubCategoryLoading(true);
    setSelectedCategory(e);
    setCategoryId(e.value);
    setSubCategory([]);
    setSubcategoryId("");
    setSelectedSubCategory("");
    setSelectedBrand("");
    setBrand([]);
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        // main_category: true,
        need_subcategory: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSubCategory(filterResult);
        setSubCategoryLoading(filterResult ? false : true);
        productResponse = {
          // productName: productName,
          service_id: servicesId,
          category_id: e.value,
          // sub_category_id: subCategoryId,
          // brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  console.log(discount, "dsssssssssssssssss");
  const handleSubmit = (e) => {
    // e.preventDefault();

    if (!document) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select the image ");
      }
      return;
    }
    if (!name) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter name");
      }
      return;
    }
    if (!code) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter coupon code");
      }
      return;
    }
    if (!startDate) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select start date");
      }
      return;
    }
    if (!endDate) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select end date");
      }
      return;
    }
    if (discountPercentage < 1 || discountPercentage > 100) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Please enter discount percentage between 1 and 100"
        );
      }
      return;
    }
    if (maxDiscount < 1 || maxDiscount > 100) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Please enter max discount between 1 and 100"
        );
      }
      return;
    }
    // if (minDiscount < 1 || minDiscount > 100) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error(
    //       "Please enter min discount between 1 and 100"
    //     );
    //   }
    //   return;
    // }
    if (!minOrderAmount) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Please enter minimum order amount"
        );
      }
      return;
    }
    // if (!servicesId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error(
    //       "Please select master category"
    //     );
    //   }
    //   return;
    // }
    // if (!categoryId) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error(
    //       "Please select category"
    //     );
    //   }
    //   return;
    // }

    // Check if any required field is empty
    // if (!name || !code || !minOrderAmount || !discount || !selectType) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please fill in all required fields");
    //   }
    //   return;
    // }

    let params = {
      postData: {
        name: name,
        code: code,
        discount_amount:
          selectType.value === "percentage" ? undefined : discount,
        // discount_amount: couponFlag === "percentage" ? undefined : discount,

        // minimum_order_amount: minOrderAmount,
        minimum_order_amount: minOrderAmount || undefined,
        // min_discount: minDiscount || undefined,
        max_discount: maxDiscount || undefined,
        max_total_usage: maxTotalUsage || undefined,
        // image: imageUrl,
        image: document,
        start_time: moment(startDate).format("YYYY-MM-DD"),
        end_time: moment(endDate).format("YYYY-MM-DD"),
        // service_id: servicesId,
        // category_id: categoryId,
        type: selectType.value,
        // type: couponFlag,
        discount_percentage:
          selectType.value === "percentage" ? discountPercentage : undefined,
        // discount_percentage: couponFlag === "percentage" ? discount : undefined,
        description: description || undefined,
      },
      tokenData: auth?.payload?.token,
    };
    // console.log(params.postData +"createcoupandat");

    if (props?.isEdit) {
      let params = {
        postData: {
          id: props?.selectedProduct?.id,
          name: name,
          code: code,
          discount_amount:
            selectType.value === "percentage" ? undefined : discount,
          // discount_amount: couponFlag === "percentage" ? undefined : discount,

          // minimum_order_amount: minOrderAmount,
          minimum_order_amount: minOrderAmount || undefined,
          // min_discount: minDiscount || undefined,
          max_discount: maxDiscount || undefined,
          max_total_usage: maxTotalUsage || undefined,
          // image: imageUrl,
          image: document,
          start_time: moment(startDate).format("YYYY-MM-DD"),
          end_time: moment(endDate).format("YYYY-MM-DD"),
          // service_id: servicesId,
          // category_id: categoryId,
          type: selectType.value,
          // type: couponFlag,
          discount_percentage:
            selectType.value === "percentage" ? discountPercentage : undefined,
          // discount_percentage: couponFlag === "percentage" ? discount : undefined,

          description: description || undefined,
        },

        tokenData: auth?.payload?.token,
      };
      setButtonLoading(true);
      dispatch(updateProductCouponApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setButtonLoading(false);
          console.log(obj, "Coupon response");
          toast.success(obj?.msg);
          props?.getCouponList();
          props.close();

          // history.push(`/productOffer`)
        })
        .catch((obj) => {
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(true);
      dispatch(createCouponAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setButtonLoading(false);
          props?.getCouponList();
          // window.location.reload(false);
          props?.close();
          console.log("heloo22");
        })
        .catch((obj) => {
          setButtonLoading(false);
          console.log("error22");
        });
    }
  };

  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     width: "300px", // Set the width as desired
  //   }),
  // };

  const options = [
    { value: "flat", label: "Flat" },
    { value: "percentage", label: "Percentage" },
  ];

  const handleTypeChange = (selectedOption) => {
    setSelectType(selectedOption);
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    setName(props?.selectedProduct?.name ? props?.selectedProduct?.name : "");
    setCode(props?.selectedProduct?.code ? props?.selectedProduct?.code : "");
    setminOrderAmount(
      props?.selectedProduct?.minimum_order_amount
        ? props?.selectedProduct?.minimum_order_amount
        : ""
    );
    setDiscount(
      props?.selectedProduct?.discount_amount
        ? props?.selectedProduct?.discount_amount
        : ""
    );
    setminDiscount(
      props?.selectedProduct?.min_discount
        ? props?.selectedProduct?.min_discount
        : ""
    );
    setmaxDiscount(
      props?.selectedProduct?.max_discount
        ? props?.selectedProduct?.max_discount
        : ""
    );
    setDiscountPercentage(
      props?.selectedProduct?.discount_percentage
        ? props?.selectedProduct?.discount_percentage
        : ""
    );
    setDiscription(
      props?.selectedProduct?.description
        ? props?.selectedProduct?.description
        : ""
    );
    setMaxTotalUsage(
      props?.selectedProduct?.max_total_usage
        ? props?.selectedProduct?.max_total_usage
        : ""
    );
    // setStartDate(props?.selectedProduct?.start_time);
    // setEndDate(props?.selectedProduct?.end_time);
    // setServicesId(
    //   props?.selectedProduct?.service_id
    //     ? props?.selectedProduct?.service_id
    //     : ""
    // );
    // setCategoryId(
    //   props?.selectedProduct?.category_id
    //     ? props?.selectedProduct?.category_id
    //     : ""
    // );
    // setImageUrl(props?.selectedProduct.image)
    setDocument(props?.selectedProduct?.image ? props?.selectedProduct?.image : '')

    if (props?.selectedProduct?.type == "flat") {
      setSelectType({ value: "flat", label: "Flat" })
    }
    if (props?.selectedProduct?.type == "flat") {
      setSelectType({ value: "percentage", label: "Percentage" })
    }

    if (props?.selectedProduct?.start_time) {
      let result = moment(props?.selectedProduct?.start_time?.split('T')[0], "YYYY-MM-DD").toDate();
      setStartDate(result);
    }
    if (props?.selectedProduct?.end_time) {
      let result = moment(props?.selectedProduct?.end_time?.split('T')[0], "YYYY-MM-DD").toDate();
      setEndDate(result);
    }
  }, []);

  return (
    <>

      <div>
        <form className="ProductCategory row coupons">
          {/* upload icons */}
          {/* <div className="mb-3 form-group ">
            <input type="file" onChange={handleFileChange} ref={inputFileRef} />
          </div> */}
          <div className="form-group col-lg-12 col-md-12 col-sm-12 mb-4 text-start">
            <div>
              <label className="deatilsHead fw-bold mb-3" htmlFor="amount">
                Upload Photo <span className="subtextSmall_"></span>
              </label>
            </div>

            <div className="edit_profile mb-4">
              {dataLoading ? (
                <span className="spinner-border inner-spin spinner-border-sm"></span>
              ) : (
                <div className="imgUser">
                  <img
                    id="profileImagePreview"
                    src={document ? document : rewardImg}
                    className="img-fluid profile_modal"
                    alt="coupon"
                  />

                  <label htmlFor="file-upload" className="custom-file-upload">
                    <i className="fas fa-camera"></i>
                  </label>

                  <input
                    id="file-upload"
                    type="file"
                    accept="image/jpeg, image/png"
                    name="profile_image"
                    className="d-none"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">Name</label>

            <input
              placeholder="Enter the Name"
              className="cateDate"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
            // onChange={handleInputChange}
            />
          </div>
          <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">Code</label>

            <input
              placeholder="Enter the Code"
              className="cateDate"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            // onChange={handleInputChange}
            />
          </div>

          {/* date */}

          <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">Date</label>

            <div className="dateChannel">
              <div className="calenderIcon">
                <img src={calendar} alt="calender" />
                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                <DatePicker
                  className="cateDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  placeholderText="Start Date"
                />
              </div>

              <div className="calenderIcon">
                <img src={calendar} alt="calender" />
                {/* <DatePicker className='cateDate' selected={enterprisesDate} onChange={(date) => setEnterprisesDate(date)}
                                dateFormat="MM/dd/yyyy" minDate={moment().toDate()} placeholderText="Date" /> */}
                <DatePicker
                  className="cateDate"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  // minDate={moment().toDate()}
                  disabled={!startDate}
                  minDate={startDate}
                  placeholderText="End Date"
                />
              </div>
            </div>
          </div>

          {/* type */}

          {/* <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">Select Type</label>

            <Select
              value={selectType}
              onChange={(selectedOption) => handleTypeChange(selectedOption)}
              options={options}
              // isDisabled={!servicesId}
              isSearchable={true}
              placeholder="select the type"
            />
          </div>

          {/* minimum Order Amount */}
          <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">
              Minimum Order Amount
            </label>
            <div className="position-relative">
              <span className="dollarSign sign">$</span>

              <input
                placeholder="Enter the Minimum Order Amount"
                className="cateDate"
                type="number"
                onKeyDown={blockInvalidChar}
                value={minOrderAmount}
                // onChange={(e) => setminOrderAmount(e.target.value)}
                onChange={(e) =>
                  setminOrderAmount(
                    e.target.value
                  )
                }
              // onChange={handleInputChange}
              />
            </div>
          </div>

          {selectType.value === "percentage" ? (
            <>
              {/* set maximum discount */}
              <div className="mb-3 form-group col-md-6">
                <label className="deatilsHead mb-3 fw-normal">
                  Maximum Discount
                </label>
                <div className="position-relative">
                  <span className="dollarSign sign">$</span>

                  <input
                    placeholder="Enter the Maximum Discount"
                    className="cateDate"
                    type="number"
                    onKeyDown={blockInvalidChar}
                    value={maxDiscount}
                    onChange={(e) => setmaxDiscount(e.target.value)}
                  />
                </div>
              </div>
              {/* minimum discount */}
              {/* <div className="mb-3 form-group col-md-6">
                <label className="deatilsHead mb-3 fw-normal">
                  Minimum Discount
                </label>
                <div className="position-relative">
                  <span className="dollarSign sign">$</span>

                  <input
                    placeholder="Enter the Minimum Discount"
                    className="cateDate"
                    type="number"
                    onKeyDown={blockInvalidChar}
                    value={minDiscount}
                    onChange={(e) => setminDiscount(e.target.value)}
                  />
                </div>
              </div> */}

              {/* discount percentage */}
              <div className="mb-3 form-group col-md-6">
                <label className="deatilsHead mb-3 fw-normal">
                  Discount percentage
                </label>
                <div className="position-relative">
                  <span className="dollarSign percent">%</span>

                  <input
                    placeholder="Enter the Discount percentage"
                    className="cateDate"
                    type="number"
                    onKeyDown={blockInvalidChar}
                    value={discountPercentage}
                    // onChange={(e) => setDiscountPercentage(e.target.value)}
                    onChange={(e) => {
                      let value = e.target.value;

                      if (value >= 0 && value <= 100) {
                        setDiscountPercentage(value);
                      } else {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="mb-3 form-group col-md-6">
              <label className="deatilsHead mb-3 fw-normal">
                Discount Amount
              </label>
              <div className="position-relative">
                <span className="dollarSign sign">$</span>
                <input
                  placeholder="Enter the Discount Amount"
                  className="cateDate"
                  onKeyDown={blockInvalidChar}
                  type="number"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
            </div>
          )}

          {/* maximum total usage */}
          <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">
              Maximum Total Usage
            </label>



            <input
              placeholder="Enter the total Maximum usage"
              className="cateDate"
              type="number"
              onKeyDown={blockInvalidChar}
              value={maxTotalUsage}
              onChange={(e) => setMaxTotalUsage(e.target.value)}
            // onChange={handleInputChange}
            />
          </div>


          {/* discount percentage */}
          <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">Description</label>

            <input
              placeholder="Enter the Description"
              className="cateDate"
              type="text"
              value={description}
              onChange={(e) => setDiscription(e.target.value)}
            // onChange={handleInputChange}
            />
          </div>

          {/* <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">
              Select Master Category
            </label>

            <Select
              value={selectedService}
              onChange={handleServicesChange}
              options={services}
              isSearchable={true}
              placeholder="Select Master Category"
            />
          </div>
          <div className="mb-3 form-group col-md-6">
            <label className="deatilsHead mb-3 fw-normal">
              Select Category
            </label>

            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              options={category}
              isDisabled={!servicesId}
              isSearchable={true}
              placeholder={
                category && categoryLoading
                  ? "Loading Categories..."
                  : "Select Category"
              }
            />
          </div> */}
        </form>

        <div className="d-flex justify-content-center mt-4">
          <button
            className="backgroundFillbtn "
            onClick={handleSubmit}
            type="button"
            disabled={buttonLoading}
          >
            {buttonLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp; submit
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateCoupon;
