import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { deleteIcon, imgGallery1, sendIcon } from '../../../../utilities/images';
import { imageUploadAsync } from '../messageSlice';
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { selectLoginAuth } from '../../auth/authSlice';
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const UploadphotoModal = (props) => {
    const history = useHistory()
    const [image, setImage] = useState([]);
    const dispatch = useDispatch()
    const toastId = React.useRef(null);
    const auth = useSelector(selectLoginAuth)
    const [loading, setLoading] = useState(false);

    const handleChange = (file) => {
        uploadImage(file)
      };
   

    const uploadImage = (image) => {
        
        let formData = new FormData();

        Object.values(image).forEach((val,index) => {
            formData.append(`file`, val);            
        });

        let params = {
            file: formData,
            token: auth?.payload?.token
        }
        setLoading(true)
        dispatch(imageUploadAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setImage(obj?.payload)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    const deleteImage = (item) => {
        const filterImage = image.filter(image => image.filePath != item);
        setImage(filterImage)
    }

    const sendPhoto =(images) => {
        console.log(images);
        
        props.images(images, "image");
        props.close();
        
    }
   
    return (
        <div className='uploadphotoModal commonBody'>
            <form className='emplyoeeDetails horizontalForms'>
                <div className='row'>
                    <div className='form-group col-md-12 mb-4 text-start'>
                        <div id='dropzone'>
                          <FileUploader 
                          multiple={true}
                          handleChange={handleChange} 
                          name="file" 
                          types={fileTypes}
                          children={
                            <div >
                            <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { handleChange(e) }} />
                            <label htmlFor="file">
                                <span className="file-button uploadImages_">
                                    <img src={imgGallery1} className="img-fluid editProfileImg" alt="user img" />
                                    <div className='dropText ms-3'>
                                        <span className='textinner_'>Drop your files here or</span>
                                        <Link to='#' className='linkTxt ms-1'>Browse</Link>
                                    </div>
                                </span>
                            </label>
                        </div>
                        }
                           />
                                                   </div>
                            {/* <div id='dropzone'>
                                <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { uploadImage(e) }} />
                                <label for="file">
                                    <span className="file-button">
                                        <img src={imgGallery1} className="img-fluid editProfileImg" alt="user img" />
                                        <div className='dropText ms-3'>
                                            <span className='textinner_'>Drop your files here or</span>
                                            <Link to='#' className='linkTxt ms-1'>Browse</Link>
                                        </div>
                                    </span>
                                </label>
                            </div> */}
                            {image && image.length > 0 ?
                            <label className='uploadContent mt-2 mb-3' htmlFor='amount'>
                                For bulk image upload: <span className='subtextSmall_'><i>Name your images like</i><Link to='#' className='linkText ms-1'>main image</Link></span>
                            </label>
                            : ""}
                            {loading ? 
                            <>
                            <span className="spinner-border spinner-border-sm"></span>
                            </> : <>
                            {image && image?.length > 0 ?
                                <>
                                    {image?.map((item, index) => {
                                        return (
                                            <ul className='uploadProfile_' key={index}>
                                                <img className="w3-round" src={item.filePath} ></img>
                                                <div className='profileDetail_'>
                                                    <h5 className='profileboldHead'>{item.originalname}</h5>
                                                    <p className='profileboldgraph'> { (item.size / (1024*1024)).toFixed(2)} MB </p>
                                                </div>
                                                <img className='deleteIconn' src={deleteIcon} onClick={(e) => deleteImage(item.filePath)} />
                                            </ul>
                                        )
                                    })}
                                     <button type='button' className='submitBtn' onClick={()=>sendPhoto(image)}>Send <img className='sendFill' src={sendIcon} /></button>
                                </> 
                                : 
                                <></>
                             }
                             </>}


                    </div>

                </div>
            </form>
        </div>
    )
}

export default UploadphotoModal