    import dayjs from 'dayjs';
    import * as React from 'react';
    import { Platform, ScrollView, StyleSheet, View } from 'react-native';
    import { u } from '../commonStyles';
    import { useNow } from '../hooks/useNow';
    import { usePanResponder } from '../hooks/usePanResponder';
    import { useTheme } from '../theme/ThemeContext';
    import { getCountOfEventsAtEvent, getOrderOfEvent, getRelativeTopInDay, hours, isToday, } from '../utils/datetime';
    import { typedMemo } from '../utils/react';
    import { CalendarEvent } from './CalendarEvent';
    import { HourGuideCell } from './HourGuideCell';
    import { HourGuideColumn } from './HourGuideColumn';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var styles = StyleSheet.create({
    nowIndicator: {
        position: 'absolute',
        zIndex: 10000,
        height: 2,
        width: '100%',
    },
});
function _CalendarBody(_a) {
    var containerHeight = _a.containerHeight, cellHeight = _a.cellHeight, dateRange = _a.dateRange, style = _a.style, onPressCell = _a.onPressCell, events = _a.events, onPressEvent = _a.onPressEvent, eventCellStyle = _a.eventCellStyle, calendarCellStyle = _a.calendarCellStyle, ampm = _a.ampm, showTime = _a.showTime, scrollOffsetMinutes = _a.scrollOffsetMinutes, onSwipeHorizontal = _a.onSwipeHorizontal, hideNowIndicator = _a.hideNowIndicator, overlapOffset = _a.overlapOffset, renderEvent = _a.renderEvent, _b = _a.headerComponent, headerComponent = _b === void 0 ? null : _b, _c = _a.headerComponentStyle, headerComponentStyle = _c === void 0 ? {} : _c, _d = _a.hourStyle, hourStyle = _d === void 0 ? {} : _d, hourComponent = _a.hourComponent, _e = _a.hideHours, hideHours = _e === void 0 ? false : _e, _f = _a.isEventOrderingEnabled, isEventOrderingEnabled = _f === void 0 ? true : _f;
    var scrollView = React.useRef(null);
    var now = useNow(!hideNowIndicator).now;
    React.useEffect(function () {
        var timeout;
        if (scrollView.current && scrollOffsetMinutes && Platform.OS !== 'ios') {
            // We add delay here to work correct on React Native
            // see: https://stackoverflow.com/questions/33208477/react-native-android-scrollview-scrollto-not-working
            timeout = setTimeout(function () {
                if (scrollView && scrollView.current) {
                    scrollView.current.scrollTo({
                        y: (cellHeight * scrollOffsetMinutes) / 60,
                        animated: false,
                    });
                }
            }, Platform.OS === 'web' ? 0 : 10);
        }
        return function () {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [scrollView, scrollOffsetMinutes, cellHeight]);
    var panResponder = usePanResponder({
        onSwipeHorizontal: onSwipeHorizontal,
    });
    var _onPressCell = React.useCallback(function (date) {
        onPressCell && onPressCell(date.toDate());
    }, [onPressCell]);
    var _renderMappedEvent = React.useCallback(function (event, index) {
        var eventsListWithSameStartEndTime = events.filter(function (e) {
            return dayjs(event.start).isBetween(e.start, e.end, 'minute', '[)') ||
                dayjs(e.start).isBetween(event.start, event.end, 'minute', '[)');
        });
        return (React.createElement(CalendarEvent, { key: "".concat(index).concat(event.start).concat(event.title).concat(event.end), allEvents: eventsListWithSameStartEndTime, event: event, onPressEvent: onPressEvent, eventCellStyle: eventCellStyle, showTime: showTime, eventCount: isEventOrderingEnabled ? getCountOfEventsAtEvent(event, events) : undefined, eventOrder: isEventOrderingEnabled ? getOrderOfEvent(event, events) : undefined, overlapOffset: overlapOffset, renderEvent: renderEvent, ampm: ampm }));
    }, [
        ampm,
        eventCellStyle,
        events,
        isEventOrderingEnabled,
        onPressEvent,
        overlapOffset,
        renderEvent,
        showTime,
    ]);
    var theme = useTheme();
    return (React.createElement(React.Fragment, null,
        headerComponent != null ? React.createElement(View, { style: headerComponentStyle }, headerComponent) : null,
        React.createElement(ScrollView, __assign({ style: [
                {
                    height: containerHeight - cellHeight * 3,
                    marginTop: 16,
                },
                style,
            ], ref: scrollView, scrollEventThrottle: 32 }, (Platform.OS !== 'web' ? panResponder.panHandlers : {}), { showsVerticalScrollIndicator: false, nestedScrollEnabled: true, contentOffset: Platform.OS === 'ios' ? { x: 0, y: scrollOffsetMinutes } : { x: 0, y: 0 } }),
            React.createElement(View, __assign({ style: [u['flex-1'], theme.isRTL ? u['flex-row-reverse'] : u['flex-row']] }, (Platform.OS === 'web' ? panResponder.panHandlers : {})),
                !hideHours && (React.createElement(View, { style: [u['z-20'], u['w-50']] }, hours.map(function (hour) { return (React.createElement(HourGuideColumn, { key: hour, cellHeight: cellHeight, hour: hour, ampm: ampm, hourStyle: hourStyle, hourComponent: hourComponent })); }))),
                dateRange.map(function (date) { return (React.createElement(View, { style: [u['flex-1'], u['overflow-hidden']], key: date.toString() },
                    hours.map(function (hour, index) { return (React.createElement(HourGuideCell, { key: hour, cellHeight: cellHeight, date: date, hour: hour, onPress: _onPressCell, index: index, calendarCellStyle: calendarCellStyle })); }),
                    events
                        .filter(function (_a) {
                        var start = _a.start;
                        return dayjs(start).isBetween(date.startOf('day'), date.endOf('day'), null, '[)');
                    })
                        .map(_renderMappedEvent),
                    events
                        .filter(function (_a) {
                        var start = _a.start, end = _a.end;
                        return dayjs(start).isBefore(date.startOf('day')) &&
                            dayjs(end).isBetween(date.startOf('day'), date.endOf('day'), null, '[)');
                    })
                        .map(function (event) { return (__assign(__assign({}, event), { start: dayjs(event.end).startOf('day') })); })
                        .map(_renderMappedEvent),
                    events
                        .filter(function (_a) {
                        var start = _a.start, end = _a.end;
                        return dayjs(start).isBefore(date.startOf('day')) &&
                            dayjs(end).isAfter(date.endOf('day'));
                    })
                        .map(function (event) { return (__assign(__assign({}, event), { start: dayjs(event.end).startOf('day'), end: dayjs(event.end).endOf('day') })); })
                        .map(_renderMappedEvent),
                    isToday(date) && !hideNowIndicator && (React.createElement(View, { style: [
                            styles.nowIndicator,
                            { backgroundColor: theme.palette.nowIndicator },
                            { top: "".concat(getRelativeTopInDay(now), "%") },
                        ] })))); })))));
}
export var CalendarBody = typedMemo(_CalendarBody);
