import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { u } from '../commonStyles';
import { useCalendarTouchableOpacityProps } from '../hooks/useCalendarTouchableOpacityProps';
import { useTheme } from '../theme/ThemeContext';
import { getEventSpanningInfo } from '../utils/datetime';
import { typedMemo } from '../utils/react'; 
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

function _CalendarEventForMonthView(_a) {
    var allEvents = _a.allEvents, event = _a.event, onPressEvent = _a.onPressEvent, eventCellStyle = _a.eventCellStyle, renderEvent = _a.renderEvent, date = _a.date, dayOfTheWeek = _a.dayOfTheWeek, calendarWidth = _a.calendarWidth, isRTL = _a.isRTL, eventMinHeightForMonthView = _a.eventMinHeightForMonthView, showAdjacentMonths = _a.showAdjacentMonths;
    var theme = useTheme();
    var _b = React.useMemo(function () { return getEventSpanningInfo(event, date, dayOfTheWeek, calendarWidth, showAdjacentMonths); }, [date, dayOfTheWeek, event, calendarWidth, showAdjacentMonths]), eventWidth = _b.eventWidth, isMultipleDays = _b.isMultipleDays, isMultipleDaysStart = _b.isMultipleDaysStart, eventWeekDuration = _b.eventWeekDuration;
    var touchableOpacityProps = useCalendarTouchableOpacityProps({
        allEvents: allEvents,
        event: event,
        eventCellStyle: eventCellStyle,
        onPressEvent: onPressEvent,
        injectedStyles: [
            { backgroundColor: theme.palette.primary.main },
            isMultipleDaysStart && eventWeekDuration > 1
                ? {
                    position: 'absolute',
                    width: eventWidth,
                    zIndex: 10000,
                }
                : {},
            isRTL ? { right: 0 } : { left: 0 },
            u['mt-2'],
            { paddingVertical: 28 },
        ],
    });
    return (React.createElement(TouchableOpacity, { style: { minHeight: eventMinHeightForMonthView }, onPress: function () { return onPressEvent === null || onPressEvent === void 0 ? void 0 : onPressEvent(event); } }, (!isMultipleDays && date.isSame(event.start, 'day')) ||
        (isMultipleDays && isMultipleDaysStart) ? (renderEvent ? (renderEvent(event, touchableOpacityProps, allEvents)) : (React.createElement(View, __assign({}, touchableOpacityProps),
        React.createElement(Text, { style: [
                { color: theme.palette.primary.contrastText },
                theme.typography.xs,
                u['truncate'],
                isRTL && { textAlign: 'right' },
            ], numberOfLines: 1 }, event.title)))) : null));
}
export var CalendarEventForMonthView = typedMemo(_CalendarEventForMonthView);
