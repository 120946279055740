import React, { useEffect, forwardRef, useState } from "react";
import * as Images from "../../../utilities/images";
import Accordion from "react-bootstrap/Accordion";
import ReactSelect from "react-select";
import PaginationHeader from "../../components/PaginationHeader";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../components/shared/CustomModal";
import AddLeaveModal from "./Modals/AddLeaveModal";
import { getPosUserdetailsAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getFormatedDate } from "../../../utilities/helpers";
import LeaveDetailsModal from "./Modals/LeaveDetailsModal";
import AccountPassModal from "../../components/shared/modals/AccountPassModal";
import CreateNewPassModal from "../../components/shared/modals/CreateNewPassModal";
import StoreUpdatePassword from "../../components/shared/modals/StoreUpdatePassword"
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";

const EmployeeDetails = ({
  filterValue,
  setFilterValue,
  calendarDate,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const [tableData, setTableData] = useState("jobrPay");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [expandedRow, setExpandedRow] = useState(false);

  const history = useHistory();
 let param=useParams();
 const [userId, setuserId] = useState(param?.id);
  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });

    setKey(Math.random());
  };
  // End Modal

  const handleToggleData = (status) => {
    setTableData(status);
  };
  const [userPosDetails, setUserPosDetails] = useState(null)
 const getUserPosDetails = ()=>{
  if(!userId)return;
  const params={
    postData:{
      seller_id:auth?.payload?.uniqe_id
      ? auth?.payload?.uniqe_id
      : auth?.payload?.user?.unique_uuid,
      pos_user_id:userId,
      seller_uuid:auth?.payload?.uniqe_id
      ? auth?.payload?.uniqe_id
      : auth?.payload?.user?.unique_uuid,
    },
    tokenData:auth?.payload?.token
  }
  dispatch(getPosUserdetailsAsync(params))
  .then(unwrapResult)
  .then(res=>{
    console.log(res,"User Pos Details");
    setUserPosDetails(res?.payload);
  })
  .catch(err=>{});
}
useEffect(()=>{
   console.log(auth?.payload,"user details")
  getUserPosDetails();
 },[userId])
  const selectOption = [
    { value: "all", label: "All Channels" },
    { value: "pos", label: "Checkout" },
    { value: "b2c", label: "Market" },
    { value: "b2b", label: "Trade" },
  ];
  const TIME_SPANS = [
    { value: "today", label: "Today", id: 1 },
    { value: "week", label: "Weekly", id: 2 },
    { value: "month", label: "Monthly", id: 3 },
    { value: "year", label: "Yearly", id: 4 },
  ];

  const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => (
    <div className="date-picker-container employeeDatePicker" onClick={onClick}>
      <img className="calendar-icon" src={Images.leftArrow} alt="Calendar" />
      <p className="date-text">
        {moment.utc(calendarDate).format("MMMM YYYY")}
      </p>
      <img
        className="arrow-icon"
        src={Images.newRightArrow}
        alt="Dropdown Arrow"
      />
    </div>
  ));

  const handleRequestClick = () => {
    setExpandedRow(!expandedRow);
  };

  return (
    <div className="walletStats h-100 employeeDetailsSection">
      <div className="productOfferHead_Container flexBox gap-3">
        <img
          src={Images.leftArrowColored}
          alt="img"
          width={14}
          height={14}
          className="cursorPointer"
          onClick={()=>history.goBack()}
        />
        <h3 className="fontSize24 txtDarkblue fw500 mb-0 ">Employee Details</h3>
      </div>
      <div className="employeeDetailsBox mt-3">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-8">
              <div className="employeeDetailsRight d-flex align-items-start gap-2">
                <img src={userPosDetails?.user?.user_profiles?.profile_photo|| Images.userImg_} alt="img" className="employeeImg" />
                <div>
                  <h3 className="fontSize24 txtDarkblue fw500">
                    {
                    userPosDetails?.user?.user_profiles?.firstname+" "+
                    userPosDetails?.user?.user_profiles?.lastname
                    }{" "}
                    <span className="staffPositionTxt fontSize18 fw500">
                      (He/she)
                    </span>{" "}
                    Cat
                  </h3>
                  <div className="ps-2">
                    <h3 className="fontSize14 txtDarkblue flexBox gap-1">
                      <img src={
                        
                        Images.employeeRoleImg} alt="img" /> Admin
                    </h3>
                    <h3 className="fontSize14 txtDarkblue flexBox gap-1">
                      <img src={Images.userIdLogo} alt="img" /> {
                        userId
                      }
                    </h3>
                    <h3 className="fontSize14 txtDarkblue flexBox gap-1">
                      <img src={Images.phoneicoN} alt="img" /> {
                        userPosDetails?.user?.user_profiles?.full_phone_number
                      }
                    </h3>
                    <h3 className="fontSize14 txtDarkblue flexBox gap-1">
                      <img src={Images.emailLightLogo} alt="img" />
                      {
                        userPosDetails?.user?.email
                      }
                    </h3>
                    <h3 className="fontSize14 txtDarkblue flexBox gap-1 mb-0">
                      <img src={Images.locationIcon_} alt="img" /> {
                        userPosDetails?.user?.user_profiles?.current_address?.format_address
                      }
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <div className="employeeDetailsLeft">
                <div className="flexBox gap-4">
                  <h3 className="staffPositionTxt fontSize14 fw500 mb-0">
                    Emergency Contact
                  </h3>
                  <img
                    src={Images.blueEditIcon}
                    alt="img"
                    className="cursorPointer"
                  />
                </div>
                <div className="ps-2 pt-2">
                  <h3 className="staffPositionTxt fontSize14">Iturbide</h3>
                  <h3 className="staffPositionTxt fontSize14">
                    +1 991 012 0998
                  </h3>
                  <h3 className="staffPositionTxt fontSize14">Brother</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flexBox justify-content-between">
        <div className="employeeDetailsToogle flexBox gap-2">
          <button
            className={`activeProductBtn ${
              tableData == "jobrPay" ? "active" : ""
            }`}
            onClick={() => handleToggleData("jobrPay")}
          >
            Job & Pay
          </button>
          <button
            className={`activeProductBtn ${
              tableData == "permissions" ? "active" : ""
            }`}
            onClick={() => handleToggleData("permissions")}
          >
            Permissions
          </button>
          <button
            className={`activeProductBtn ${
              tableData == "leave" ? "active" : ""
            }`}
            onClick={() => handleToggleData("leave")}
          >
            Leave
          </button>
          <button
            className={`activeProductBtn ${
              tableData == "apps" ? "active" : ""
            }`}
            onClick={() => handleToggleData("apps")}
          >
            Password & PIN
          </button>
          <button
            className={`activeProductBtn ${
              tableData == "workLog" ? "active" : ""
            }`}
            onClick={() => handleToggleData("workLog")}
          >
            Work log
          </button>
        </div>
        {tableData == "leave" && (
          <buton
            className="activeProductBtn active flexBox gap-1"
            onClick={() => {
              handleOpenModal("addLeaveModal");
            }}
          >
            <img src={Images.addButtonImg} alt="img" />
            Add Leave
          </buton>
        )}
      </div>

      {tableData == "jobrPay" && (
        <>
          <div className="employeeDetailInnerBoxes mt-0">
            <div className="flexBox justify-content-between pt-2 pb-2">
              <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                Employment Details
              </h3>
              <img
                src={Images.blueEditIcon}
                alt="img"
                className="cursorPointer"
              />
            </div>
            <div className="employeeDescParent mt-2">
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Department</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">POS Admin</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Employee Id</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    // userPosDetails
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Manager</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">None</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Start Date</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    getFormatedDate(userPosDetails?.created_at,"mm-dd-yyyy")
                  }</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Color</h3>
                </div>
                <div className="col-md-9">
                  <div
                    className="employeeColorBox"
                    style={{ backgroundColor:userPosDetails?.color_code|| "#12B76A" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="employeeDetailInnerBoxes">
            <div className="flexBox justify-content-between pt-2 pb-2">
              <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                Manager role
              </h3>
              <img
                src={Images.blueEditIcon}
                alt="img"
                className="cursorPointer"
              />
            </div>
            <div className="employeeDescParent mt-2">
              <div className="row">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500 mb-0">
                    Manager role
                  </h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 mb-0">
                    Andrea Gonzalez-Iturbide is not a manager
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="employeeDetailInnerBoxes">
            <div className="flexBox justify-content-between pt-2 pb-2">
              <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                Work Address
              </h3>
              <img
                src={Images.blueEditIcon}
                alt="img"
                className="cursorPointer"
              />
            </div>
            <div className="employeeDescParent mt-2">
              <div className="row">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500 mb-0">
                    Current Address
                  </h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 mb-0">
                    {
                      userPosDetails?.user_profile?.format_address
                    }
                    <br />
                    San Jose, CA 95113
                    <br />
                    Phone: 239-555-6028
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="employeeDetailInnerBoxes">
            <div className="flexBox justify-content-between pt-2 pb-2">
              <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                Compensation
              </h3>
              <img
                src={Images.blueEditIcon}
                alt="img"
                className="cursorPointer"
              />
            </div>
            <div className="employeeDescParent mt-2">
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">
                    Employment Status
                  </h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">
                    {
                      userPosDetails?.status?'Active':'Not Active'
                    } ({userPosDetails?.weekly_tracking_limit})
                  </h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Employee type</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    userPosDetails?.employment_type
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Job title</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    "Pos Manager"
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Pay frequency</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    userPosDetails?.payment_cycle
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Wage</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">$20 per hour</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Default hours</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">${
                    userPosDetails?.hourly_rate
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Wage</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    "40"
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Leave</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    userPosDetails?.leave
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Overtime</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                    userPosDetails?.is_over_time_allowed
                  }</h3>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Overtime wage</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">${
                    userPosDetails?.overtime_rate
                  }</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">Tips</h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500 ">{
                userPosDetails?.is_tips_allowed
                }</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="employeeDetailInnerBoxes">
            <div className="flexBox justify-content-between pt-2 pb-2">
              <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                Payment Methods
              </h3>
              <img
                src={Images.blueEditIcon}
                alt="img"
                className="cursorPointer"
              />
            </div>
            <div className="employeeDescParent mt-2">
              <div className="row mb-1">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500">
                    Payment Method
                  </h3>
                </div>
                <div className="col-md-9">
                  <h3 className="uncheckTxt fontSize12 fw500">
                    Direct Deposit (Automatic)
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <h3 className="uncheckTxt fontSize12 fw500 mb-0">
                    Wallet ID
                  </h3>
                </div>
                <div className="col-md-9">
                  <div className="flexBox gap-2">
                    <img src={Images.mastercardicon} alt="imt" />
                    <h3 className="uncheckTxt fontSize10  mb-0">
                      ●●●● ●●●● ●●●● <span className="fontSize14">7224</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {tableData == "permissions" && (
        <>
          <Accordion>
            <Accordion.Item eventKey="0" className="employeeDetail_Accordion">
              <Accordion.Header>
                <div className="d-flex align-items-start gap-3">
                  <div className="productSale_Parent p-0 m-0">
                    <input type="checkbox" checked={true} />
                  </div>
                  <div className="flexBox gap-2">
                    <img src={Images.accodionImg1} alt="img" />
                    <div>
                      <h3 className="fontSize14 txtDarkblue">Admin Panel</h3>
                      <p className="fontSize12 txtLightBlue">
                        Full access to all features and data.
                      </p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion defaultActiveKey="1">
                  <Accordion.Item
                    eventKey="0"
                    className="employee_InnerAccordion"
                  >
                    <Accordion.Header>
                      <div className="d-flex align-items-start gap-3">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue">
                            Orders Management
                          </h3>
                          <p className="fontSize12 txtLightBlue">
                            Full access: view, edit, cancel, refund, ship, and
                            manage all order-related activities.
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="productAvailabilityList mb-0 pb-2">
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Reservation
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Order accept
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Order edit
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Order cancel
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Order refund
                          </h3>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="2">
                  <Accordion.Item
                    eventKey="0"
                    className="employee_InnerAccordion pt-2"
                  >
                    <Accordion.Header>
                      <div className="d-flex align-items-start gap-3">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue">
                            Product & Service Management
                          </h3>
                          <p className="fontSize12 txtLightBlue">
                            Full access: create, edit, delete, and manage
                            product/service catalogs.
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="productAvailabilityList mb-0 pb-2">
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            Product create
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Product edit
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Product delete
                          </h3>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="3">
                  <Accordion.Item
                    eventKey="0"
                    className="employee_InnerAccordion pt-2"
                  >
                    <Accordion.Header>
                      <div className="d-flex align-items-start gap-3">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue">
                            Campaign Management
                          </h3>
                          <p className="fontSize12 txtLightBlue">
                            Full access: create, edit, and manage campaigns.
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="productAvailabilityList mb-0 pb-2">
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            Product create
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Product edit
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Product delete
                          </h3>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="4">
                  <Accordion.Item
                    eventKey="0"
                    className="employee_InnerAccordion pt-2"
                  >
                    <Accordion.Header>
                      <div className="d-flex align-items-start gap-3">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue">
                            Staff Management
                          </h3>
                          <p className="fontSize12 txtLightBlue">
                            Full access: create, edit, and manage campaigns.
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className="productAvailabilityList mb-0 pb-2">
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            Product create
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Product edit
                          </h3>
                        </li>
                        <li className="productAvailabilityChild align-items-baseline">
                          <div class="form-group d-flex">
                            <input
                              type="checkbox"
                              id="reservation"
                              //   checked=
                            />
                            <label for="reservation"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Product delete
                          </h3>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}
      {tableData == "leave" && (
        <>
          <div className="row align-items-center mt-3">
            <div className="col-md-3">
              <div className="reactSelectParent offerHeaderSelect consumerHeaderSelect">
                <ReactSelect
                  options={selectOption}
                  placeholder="Leave type: All"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  // onChange={(selected) => setAppName(selected?.value)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: "120px",
                      maxWidth: "175px !important",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",
                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.blueDownArrow}
                        width={24}
                        height={24}
                        alt="drop_icon"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <PaginationHeader
              currentPage={0}
              totalPage={10}
              perPage={10}
              setCurrentPage={10}
              setPerPage={10}
              headerClass="col-md-9"
            />
          </div>
          <div className="commonTable table-responsive leaveTabTable mt-3">
            <table className="w-100">
              <thead>
                <tr>
                  <th className="text-start fw600">Leave type</th>
                  <th className="fw600">Start date</th>
                  <th className="fw600">End date</th>
                  <th className="fw600">Days used</th>
                  <th className="fw600">Days remain</th>
                  <th className="fw600">Leave reason</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="text-center border-none">
                    <td colSpan="100%">
                      <div className="d-flex">
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className="orderTrackTableTxt text-start">
                        Vacation
                      </td>
                      <td className="orderTrackTableTxt">20-11-2024</td>
                      <td className="orderTrackTableTxt">23-11-2024</td>
                      <td className="orderTrackTableTxt">5</td>
                      <td className="orderTrackTableTxt">15</td>
                      <td className="orderTrackTableTxt">Personal Trip</td>
                      <td
                        className="orderTrackTableTxt cursorPointer"
                        onClick={() => {
                          handleOpenModal("leaveDetailsModal");
                        }}
                      >
                        <img src={Images.webPosArrow} alt="img" />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
      {tableData == "apps" && (
        <div className="employeePassPin">
          <h3 className="fontSize18 fw600 txtDarkblue ps-4 mb-3">Sign in</h3>
          <div className="employeeDetailPass">
            <div
              className="innerEmployeePassPin"
              onClick={() => handleOpenModal("accountPassModal")}
            >
              <div className="d-flex align-items-start gap-2">
                <img src={Images.accodionImg1} alt="img" />
                <div>
                  <h3 className="fontSize16 fw500 txtDarkblue mb-1">
                    Merchant Admin Login Password
                  </h3>
                  <h5 className="fontSize12 fw500 uncheckTxt mb-1">
                    Last changed Aug 18, 2023
                  </h5>
                  <span className="fontSize12 fw500 staffPositionTxt">
                    Owner at Store LLC
                  </span>
                </div>
              </div>
              <img src={Images.webPosArrow} alt="img" className="pt-2" />
            </div>
            <div
              className="innerEmployeePassPin"
              onClick={() => handleOpenModal("storeUpdatePassword")}
            >
              <div className="d-flex align-items-start gap-2">
                <img src={Images.checkoutImg} alt="img" />
                <div>
                  <h3 className="fontSize16 fw500 txtDarkblue mb-1">
                    Checkout Passcode for Store LLC
                  </h3>
                  <h5 className="fontSize12 fw500 uncheckTxt mb-1">
                    Your personal Checkout passcode is used to log in and clock
                    in on the Store LLC point of sale. Please don't share this
                    passcode with anyone.
                  </h5>
                  <span className="fontSize12 fw500 staffPositionTxt">
                    Owner at Store LLC
                  </span>
                </div>
              </div>
              <img src={Images.webPosArrow} alt="img" className="pt-2" />
            </div>
          </div>
        </div>
      )}
      {tableData == "workLog" && (
        <>
          <div className="workLogBox">
            <div className="flexBox gap-3">
              <ReactDatePicker
                onChange={(date) => {
                  const [start, end] = date;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                // selectsRange
                maxDate={new Date()}
                customInput={<DatePickerCustomComponent />}
              />
              <div className="reactSelectParent workLogSelect">
                <ReactSelect
                  options={selectOption}
                  placeholder="Location"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: "143px !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",
                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#263682",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>

            <div className="staffDetailtable ">
              <div className="staffTableHeading">
                <div className="staffBoxData text-start">
                  <h4 className="fontSize12 fw500 staffPositionTxt mb-0 p-0">
                    Date
                  </h4>
                </div>
                <div className="staffBoxData">
                  <h4 className="fontSize12 fw500 staffPositionTxt mb-0 p-0">
                    Duration
                  </h4>
                </div>
                <div className="staffBoxData">
                  <h4 className="fontSize12 fw500 staffPositionTxt mb-0 p-0">
                    Amount
                  </h4>
                </div>
                <div className="staffBoxData">
                  <h4 className="fontSize12 fw500 staffPositionTxt mb-0 p-0">
                    Status
                  </h4>
                </div>
                <div className="staffBoxData staffLastBoxData">
                  <h4 className="fontSize12 fw500 staffPositionTxt mb-0 p-0">
                    Action
                  </h4>
                </div>
              </div>
              <div
                className={`staffTableOuter ${
                  expandedRow === true ? "active" : ""
                }`}
                onClick={() => handleRequestClick()}
              >
                <div className="staffTableData">
                  <div className="staffBoxData text-start">
                    <h4 className="staffTableText">
                      May 29, 2023 - Jun 4, 2023
                    </h4>
                  </div>
                  <div className="staffBoxData">
                    <h4 className="staffTableText">4 h 20 m</h4>
                  </div>
                  <div className="staffBoxData">
                    <h4 className="staffTableText">$ 1,500</h4>
                  </div>
                  <div className="staffBoxData">
                    <button className="staffUnpaidBtn " type="button">
                      Unpaid
                    </button>
                    {/* <button className="staffPaidBtn " type="button">
                      Paid
                    </button> */}
                  </div>
                  <div className="staffBoxData">
                    <button className="staffInvoiceBtn " type="button">
                      Invoice
                    </button>
                  </div>

                  <div>
                    {true && (
                      <img
                        src={Images.arrowDown}
                        alt="calendarimage"
                        className="img-fluid"
                      />
                    )}
                  </div>
                </div>

                {expandedRow && (
                  <>
                    <div
                      style={{ marginTop: true ? 15 : 0 }} // condition => index == 0 ? 15 : 0
                      className="staffTableSub"
                    >
                      <div className="staffSecond">
                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">May 1, 2022</h4>
                        </div>

                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">20/25/20</h4>
                        </div>
                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">20/25/20</h4>
                        </div>
                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">20/25/20</h4>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: false ? 15 : 0 }} // condition => index == 0 ? 15 : 0
                      className="staffTableSub"
                    >
                      <div className="staffSecond">
                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">May 1, 2022</h4>
                        </div>

                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">20/25/20</h4>
                        </div>
                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">20/25/20</h4>
                        </div>
                        <div className="staffBoxData text-start">
                          <h4 className="staffTableText">20/25/20</h4>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className={`staffTableOuter ${
                  expandedRow === true ? "active" : ""
                }`}
              >
                <div className="staffTableData">
                  <div className="staffBoxData text-start">
                    <h4 className="staffTableText">
                      May 29, 2023 - Jun 4, 2023
                    </h4>
                  </div>
                  <div className="staffBoxData">
                    <h4 className="staffTableText">4 h 20 m</h4>
                  </div>
                  <div className="staffBoxData">
                    <h4 className="staffTableText">$ 1,500</h4>
                  </div>
                  <div className="staffBoxData">
                    <button className="staffUnpaidBtn " type="button">
                      Unpaid
                    </button>
                    {/* <button className="staffPaidBtn " type="button">
                      Paid
                    </button> */}
                  </div>
                  <div className="staffBoxData">
                    <button className="staffInvoiceBtn " type="button">
                      Invoice
                    </button>
                  </div>

                  <div>
                    {true && (
                      <img
                        src={Images.arrowDown}
                        alt="calendarimage"
                        className="img-fluid"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "addLeaveModal"
            ? "addLeaveModal"
            : modalDetail.flag === "leaveDetailsModal"
            ? "leaveDetailsModal"
            : modalDetail.flag === "accountPassModal"
            ? "accountPassModal"
            : modalDetail.flag === "createNewPassModal"
            ? "createNewPassModal"
            : modalDetail.flag === "storeUpdatePassword"
            ? "storeUpdatePassword"
            : ""
        }
        child={
          modalDetail.flag === "addLeaveModal" ? (
            <AddLeaveModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "leaveDetailsModal" ? (
            <LeaveDetailsModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "accountPassModal" ? (
            <AccountPassModal
              close={() => handleOnCloseModal()}
              open={() => handleOpenModal("createNewPassModal")}
            />
          ) : modalDetail.flag === "createNewPassModal" ? (
            <CreateNewPassModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "storeUpdatePassword" ? (
            <StoreUpdatePassword close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          modalDetail.flag === "addLeaveModal" ? (
            <>
              <div>
                <h2 className="fontSize24 fw500 txtDarkblue">Add leave</h2>
                <p className="fontSize16 staffPositionTxt">
                  for Andrea Gonzalez-Iturbide, will notify by email/ sms.
                </p>
              </div>
              <img
                src={Images.modalCross}
                alt="img"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : modalDetail.flag === "leaveDetailsModal" ? (
            <>
              <h2 className="fontSize24 fw500 txtDarkblue">Leave details</h2>
              <img
                src={Images.modalCross}
                alt="img"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default EmployeeDetails;
