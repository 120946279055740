import React, { useState, useEffect } from "react";
import { checkPosUserAsync, selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const VerifyUserModal = (props) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(Math.random());
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");
  const [userData, setUserData] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };

  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select your phone code");
      }
      return false;
    }
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your phone number");
      }
      return false;
    }
    setLoading(true);
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        phone_code: `+${phoneCode}`,
        phone_no: phoneNo,
      },
    };
    await dispatch(checkPosUserAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        let phoneData = {
          codePhone: phoneCode,
          phoneNumber: phoneNo,
        };
        setLoading(false);
        props?.sendData(obj?.payload ? obj?.payload : phoneData);
        props?.close();
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="pt-3">
        <form
          autoComplete="nope"
          className="form_horizontal"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="form-group">
            <div className="otp__ ">
              <div className="phone-numbpart">
                <div className="country-plugin">
                  <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">{t('phNum')}</label>
                  <div id="result" className="countryCodePickerParent">
                    <PhoneInput
                      country="us"
                      // value={phoneCode + phoneNo}
                      // enableSearch={true}
                      onlyCountries={["us", "in"]}
                      name={generateRandomName()}
                      placeholder="Phone no."
                      onChange={(value, data, event, formattedValue) =>
                        onChangePhoneNumber(value, data, event, formattedValue)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modalfooterBtn">
            <button
              className="modalNextBtn modalDiscardBtn w-100 active"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>{t('verifyUser')}!</span>
            </button>
            <div className="modalfooterBtn align-items-center"></div>
          </div>
        </form>
      </div>
    </>
  );
};

export default VerifyUserModal;
