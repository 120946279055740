import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import ReactDatePicker from "react-datepicker";
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { createCoupanDataAsync, selectMarketingData } from '../marketingSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { imageUploadAsync } from '../../message/messageSlice';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getCategoriesAsync, getServicesAsync } from '../../dashboard/dashboardSlice';
import SelectMenu from '../../../components/UI/Orders/SelectMenu';
import useDebounce from '../../../../utilities/helpers';
import { getProductServiceListApiAsync } from '../../myProducts/productSlice';
import { useTranslation } from 'react-i18next';
const CreateCoupons = () => {
    const { t } = useTranslation();
    const marketingData = useSelector(selectMarketingData);
    const couponList = marketingData?.couponData;
    const { id } = useParams();
    const auth = useSelector(selectLoginAuth)
    const dispatch = useDispatch()
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [items, setItems] = useState([]);
    const [searchCategory, setsearchCategory] = useState("");
    const [searchItem, setsearchItem] = useState("");
    const [isImageUploaded, setisImageUploaded] = useState(false)
    const searchDebounceCategory = useDebounce(searchCategory, 900);
    const searchDebounceItem = useDebounce(searchItem, 900);
    let campaign = couponList?.data?.filter(coupon => coupon?.id == id)?.[0];
    console.log(campaign,"campaign")
    const [selectConditions, setSelectConditions] = useState({
        customerLimit: Number(campaign?.max_total_usage) > 0 ? true : false,
        couponRedeem: Number(campaign?.max_total_usage||0) > 0 ? true : false,
        minPurchase: Number(campaign?.minimum_order_amount||0) > 0 ? true : false,
    })
    // Initializing a single state object to hold all form values
    const [formData, setFormData] = useState({
        name: campaign?.name ?? "",
        startDate: campaign?.start_time ? new Date(campaign?.start_time) : null,
        endDate: campaign?.end_time ? new Date(campaign?.end_time) : null,
        description: campaign?.description ?? '',
        discount_amount: campaign?.discount_amount ?? 0,
        image: campaign?.image ?? "",
        type: campaign?.type ?? "flat",
        applies_to: campaign?.category_id?"Category":campaign?.product_id?"item" : "Sale",
        discount_amount: campaign?.discount_amount ?? 0,
        max_discount: campaign?.max_discount ?? 0,
        discount_percentage: campaign?.discount_percentage ?? 0,
        minimum_order_amount: campaign?.minimum_order_amount ?? 0,
        max_total_usage: campaign?.max_total_usage ?? 0,
        product_id: campaign?.product_id ?? null,
        category_id: campaign?.category_id ?? null
    });
    useEffect(()=>{
        if(searchDebounceItem.length){
            getItemList(searchDebounceItem);
        }
    },[searchDebounceItem])
    useEffect(()=>{
        if(searchDebounceCategory.length){
            getServices(searchDebounceCategory);
        }
    },[searchDebounceCategory])
    const handelApplyTo = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            applies_to: value,
        }));
    }
    const handleSelectConditions = (e, data) => {
        if (data) {
            setSelectConditions(prev => ({
                ...prev,
                ...data
            }))
        } else {
            let name = e.target.name;
            let value = e.target.checked;
            setSelectConditions(prev => ({
                ...prev, [name]: value
            }))
        }
    }

    // Handler to update individual field values in the formData state
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handler to update date fields in the formData state
    const handleDateChange = (date, field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: date,
        }));
    };



    const handelCreateCoupon = async () => {
        let isFieldEmpy = validateFields();
        if (isFieldEmpy) return;
        setLoading(true)
        let url = (!isImageUploaded&&id) ? campaign?.image : await uploadImage(formData.image);
        if (!url) {
            setLoading(false);
            toast.error("Unable to upload coupon image");
            return;
        }
        if (formData.applies_to != "item") delete formData.product_id;
        if (formData.applies_to != "Category") delete formData.category_id;
        if(formData.type=="percentage") delete formData.discount_amount;
        if(formData.type=="flat"){
            delete formData.max_discount;
            delete formData.discount_percentage;
        }
        let params = {
            postData: {
                ...formData,
                code: formData?.name,
                start_time: moment(formData?.startDate).format("YYYY-MM-DD"),
                end_time: moment(formData?.endDate).format("YYYY-MM-DD"),
                image: url
            },
            tokenData: auth?.payload?.token,
        };

        dispatch(createCoupanDataAsync(params))
            .then(unwrapResult)
            .then(res => {
                toast.success(res?.msg);
                history.goBack()
            }).catch(e => {
                setLoading(false);
                console.log(e)
            })
    }
    const validateFields = () => {
        if (!formData.startDate || !formData.endDate) {
            toast.error("valid Date is required");
            return true;
        }
        else if (!formData.description) {
            toast.error("Description is required");
            return true;
        }
        else if (!formData.name) {
            toast.error("Coupon code is required");
            return true;
        }
        else if (selectConditions.minPurchase && !formData.minimum_order_amount) {
            toast.error("Minimum purchase amount is required");
            return true;
        }
        else if (selectConditions.couponRedeem && !formData.max_total_usage) {
            toast.error("Limit number of times customers can use coupon is required");
            return true;
        }
        // else if (selectConditions.customerLimit && !formData.max_total_usage) {
        //     toast.error("Limit number of times customers can use coupon is required");
        //     return true;
        // }
        else if (!formData.applies_to) {
            toast.error("Applies to is required");
            return true;
        } else if (!formData.image) {
            toast.error("Image is required");
            return true;
        }
        else if (formData.applies_to == "" && !formData.product_id) {
            toast.error("Select item");
            return true;
        }
        else if (formData.applies_to == "" && !formData.category_id) {
            toast.error("Select category");
            return true;
        }
        else if (formData.type == "percentage" && formData.discount_percentage == 0) {
            toast.error("discount percentage is required");
            return true;
        }
        
        else if (formData.type == "flat" && formData.discount_amount == 0) {
            toast.error("discount amount is required");
            return true;
        }
        return false;
    }

    const uploadImage = async (imageFile) => {
        let formData = new FormData();
        formData.append("file", imageFile);
        let params = {
            file: formData,
            token: auth?.payload?.token,
        };
        try {
            let data = await dispatch(imageUploadAsync(params))
                .then(unwrapResult);
            return data?.payload[0]?.filePath ?? null;

        } catch (error) {
            return null;
        }

    };
    const getServices = (search,id) => {
        let data = {
            tokenData: auth?.payload?.token,
            postData: {
                // type: "both",
                check_product_existance: false,
                limit:5,
                ...(id&&{
                    category_ids:[id]
                }),
                ...(search&&{search})
            },
        };
        dispatch(getCategoriesAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                let filterResult =getSelectItemOptions(obj?.payload?.data)
                setServices(filterResult || []);
            })
            .catch((obj) => {
            });
    };
    const getSelectItemOptions=(data)=>{
        let filterResult=data?.map((item) => {
            return {
                value: item?.id,
                label: item?.name,
            };
        });
        return filterResult||[];
    }
    const getItemList=(search,id)=>{
        let params = {
            tokenData: auth?.payload?.token,
            postData: {
              seller_id: auth?.payload?.uniqe_id
                ? auth?.payload?.uniqe_id
                : auth?.payload?.user?.unique_uuid,
            //   type: "both",
              limit:5,
              app_name: "merchant",
              ...(id&&{
                sort_product_ids:[id]
              }),
              ...(search&&{search}),
              filter_deleted: true,
            },
        };
        dispatch(getProductServiceListApiAsync(params))
              .then(unwrapResult)
              .then((obj) => {
                let filterResult =getSelectItemOptions(obj?.payload?.data)
                setItems(filterResult);
              })
              .catch((obj) => {
              });
    }
    useEffect(()=>{
      if(campaign?.product_id)  getItemList(null,campaign?.product_id);
      if(campaign?.category_id)  getServices(null,campaign?.category_id)
    },[campaign])
    return (
        <div className='createCoupon_ jbrTransfer '>
            <div className='chartsOuter jbrHeight mt-0'>
                <div className='shipprofileHead_'>
                    <h2 className='Innerhead'>
                        <img className='arrowicon_' alt="back" src={Images.leftArrowColored} onClick={() => history.goBack()} /> {id ? t('dupCoupon') : t("crCoupon")} 
                    </h2>
                    <div className='right_shippingbtn_'>
                        <button
                            disabled={loading}
                            onClick={() => history.goBack()}
                            className='discardBtn_'
                            type='button'>{t('discard')}</button>
                        <button className='addNew_'
                            disabled={loading}
                            type='button' onClick={handelCreateCoupon}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm spinnerLight"></span>
                            )} {t('activate')}
                        </button>
                    </div>
                </div>
                <div className='createPageInner_'>
                    <div className='row'>
                        <div className="form-group col-lg-12 d-flex justify-content-center col-md-12 col-sm-12 mb-3 mt-4 ">
                            <div className="edit_profile mb-4">
                                <div className="imgUser">
                                    <img
                                        id="profileImagePreview"
                                        src={formData?.image ?  isImageUploaded ? URL.createObjectURL(formData?.image) : formData.image: Images.rewardImg}
                                        className="img-fluid profile_modal"
                                        alt='sidx'
                                    />
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <i className="fas fa-camera"></i>
                                    </label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        name="profile_image"
                                        className="d-none"
                                        onChange={(e) => {
                                            setisImageUploaded(true);
                                            setFormData(prev => ({
                                                ...prev,
                                                image: e.target.files[0]
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <div className="form_group">
                                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                    {t('couponCode')}
                                </label>
                                <div className="inputGroup">
                                    <input
                                        value={formData?.name}
                                        className="customInput"
                                        placeholder="ANNUALSALE2024"
                                        name="name"
                                        type="text"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <p className='messageTxt ps-4'>{t('uniqueCoupon')}</p>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <div className="form_group">
                                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                    {t('description')}
                                </label>
                                <div className="inputGroup">
                                    <input
                                        value={formData?.description}
                                        className="customInput"
                                        placeholder="Get 100% off the next time you visit!"
                                        name="description"
                                        type="text"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="calandermain_view_ p-3 pt-2 ">
                                <h3 className="headblue18 m-0 mb-3">{t('couponType')}</h3>
                                <div className='calenderViewchoose_'>
                                    <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="discount"
                                            name="type"
                                            value={"flat"}
                                            checked={formData.type == "flat"}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                        />
                                        <label htmlFor="discount">{t('fixedDiscount')}</label>
                                        {formData.type == "flat" && <div className="inputGroup ps-4 mt-2 mb-2">
                                            <input
                                                value={formData?.discount_amount}
                                                className="customInput"
                                                placeholder="$ 10.00"
                                                name="discount_amount"
                                                type="number"
                                                onChange={handleInputChange}
                                            />
                                        </div>}
                                    </div>
                                    <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="percentage"
                                            name="type"
                                            value={"percentage"}
                                            checked={formData.type == "percentage"}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                        />
                                        <label htmlFor="percentage">{t('percentageOff')}</label>
                                        {formData.type == "percentage" && <>
                                            <div className="inputGroup ps-4 mt-2 mb-2">
                                                <input
                                                    value={formData?.discount_percentage}
                                                    className="customInput"
                                                    placeholder="1%"
                                                    name="discount_percentage"
                                                    type="number"
                                                    onChange={(e)=>{
                                                        let value=e.target.value;
                                                        if(value<100){
                                                            handleInputChange(e);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <label className='messageTxt ps-4 pt-0'>{t("maxValue")} </label>
                                            <div className="inputGroup ps-4 mt-2 mb-2">
                                                <input
                                                    value={formData?.max_discount}
                                                    className="customInput"
                                                    placeholder="$ 10.00"
                                                    name="max_discount"
                                                    type="number"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </>}
                                    </div>
                                    {/* <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="delivery"
                                            name="couponType"
                                            checked={formData?.selectType?.value === "delivery"}
                                            onChange={() => handleCouponTypeChange("delivery")}
                                        />
                                        <label htmlFor="delivery">Free delivery</label>
                                    </div>
                                    <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="shipping"
                                            name="couponType"
                                            checked={formData?.selectType?.value === "shipping"}
                                            onChange={() => handleCouponTypeChange("shipping")}
                                        />
                                        <label htmlFor="shipping">Free shipping</label>
                                    </div>
                                    <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="free"
                                            name="couponType"
                                            checked={formData?.selectType?.value === "free"}
                                            onChange={() => handleCouponTypeChange("free")}
                                        />
                                        <label htmlFor="free">Free</label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="calandermain_view_ p-3 pt-2 ">
                                <h3 className="headblue18 m-0 mb-3">{t('appliesTo')}</h3>
                                <div className='calenderViewchoose_'>
                                    <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="Sale"
                                            name="appliesTo"
                                            checked={formData?.applies_to === "Sale"}
                                            onChange={() => handelApplyTo("Sale")}
                                        />
                                        <label htmlFor="Sale">{t('entireSale')}</label>
                                    </div>
                                    <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="Category"
                                            name="appliesTo"
                                            checked={formData?.applies_to === "Category"}
                                            onChange={() => {
                                                if(services.length==0){
                                                    getServices();
                                                }
                                                handelApplyTo("Category")
                                            }}
                                        />
                                        <label htmlFor="Category">{t('category')}</label>
                                        {
                                            formData?.applies_to === "Category" && <>
                                                <div className="w-100 commonSelectParent reactSelectParent">
                                                    <SelectMenu
                                                        options={services}
                                                        onChange={(e) => {
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                               category_id : Number(e.value)
                                                            }));
                                                        }}
                                                        value={services.filter(item => item.value == formData.category_id)}
                                                        isSearchable={true}
                                                        placeholder="search category"
                                                        classNamePrefix="react-select"
                                                        className="anlyticSelect react-select-container"
                                                        onInputChange={(inputValue, actionMeta) => {
                                                            if (actionMeta.action === "input-change") {
                                                                console.log("User is searching:", inputValue);
                                                                setsearchCategory(inputValue);
                                                            }
                                                        }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                maxWidth: "100% !important",
                                                                width: "100%",
                                                            }),
                                                        }}
                                                    />

                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="calenderViewradio">
                                        <input
                                            type="radio"
                                            id="item"
                                            name="appliesTo"
                                            checked={formData?.applies_to === "item"}
                                            onChange={() => {
                                                if(items.length==0){
                                                    getItemList();
                                                }
                                                handelApplyTo("item")}}
                                        />
                                        <label htmlFor="item">{t('specificItem')}</label>
                                        {
                                            formData?.applies_to === "item" && <>
                                               <div className="w-100 commonSelectParent reactSelectParent">
                                                    <SelectMenu
                                                        options={items}
                                                        onChange={(e) => {
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                                product_id: Number(e.value)
                                                            }));
                                                        }}
                                                        value={items.filter(item => item.value == formData.product_id)}
                                                        isSearchable={true}
                                                        placeholder="search item"
                                                        classNamePrefix="react-select"
                                                        className="anlyticSelect react-select-container"
                                                        onInputChange={(inputValue, actionMeta) => {
                                                            if (actionMeta.action === "input-change") {
                                                                setsearchItem(inputValue);
                                                            }
                                                        }}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                maxWidth: "100% !important",
                                                                width: "100%",
                                                            }),
                                                        }}
                                                    />

                                                </div>

                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="calandermain_view_ p-3 pt-2 ">
                                <h3 className="headblue18 m-0 mb-3">{t("conditions")}</h3>
                                <div className='calenderViewchoose_'>
                                    {/* <div>
                                        <div className="productAvailabilityChild align-items-center mb-2">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="product-checkbox"
                                                    name='customerLimit'
                                                    onChange={handleSelectConditions}
                                                    checked={selectConditions.customerLimit}
                                                />
                                                <label
                                                    htmlFor="product-checkbox"
                                                    className="me-0"
                                                >{t('couponCanUse')}</label>
                                            </div>
                                        </div>
                                        {selectConditions.customerLimit && <div className="inputGroup ps-4 mt-2 mb-2">
                                            <input
                                                value={formData?.max_total_usage}
                                                className="customInput"
                                                placeholder="1"
                                                name="max_total_usage"
                                                type="number"
                                                onChange={handleInputChange}
                                            />
                                        </div>}
                                    </div> */}
                                    <div>
                                        <div className="productAvailabilityChild align-items-center mb-2">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="product-checkbox2"
                                                    name='couponRedeem'
                                                    onChange={handleSelectConditions}
                                                    checked={selectConditions.couponRedeem}
                                                />
                                                <label
                                                    htmlFor="product-checkbox2"
                                                    className="me-0"
                                                >{t('couponReedem')}</label>
                                            </div>
                                        </div>
                                        {selectConditions.couponRedeem && <div className="inputGroup ps-4 mt-2 mb-2">
                                            <input
                                                value={formData?.max_total_usage}
                                                className="customInput"
                                                placeholder="1"
                                                name="max_total_usage"
                                                type="number"
                                                onChange={handleInputChange}
                                            />
                                        </div>}
                                    </div>
                                    <div>
                                        <div className="productAvailabilityChild align-items-center">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="product-checkbox3"
                                                    name='minPurchase'
                                                    onChange={handleSelectConditions}
                                                    checked={selectConditions.minPurchase}
                                                />
                                                <label
                                                    htmlFor="product-checkbox3"
                                                    className="me-0"
                                                >{t('minPurchase')}</label>
                                            </div>
                                        </div>
                                        {selectConditions.minPurchase && <div className="inputGroup ps-4 mt-2 mb-2">
                                            <input
                                                value={formData?.minimum_order_amount}
                                                className="customInput"
                                                placeholder="1"
                                                name="minimum_order_amount"
                                                type="number"
                                                onChange={handleInputChange}
                                            />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-4'>
                            <div className='form-group'>
                                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                    {t('startDate')}
                                </label>
                                <div className="customer-calendar-cnt" >
                                    <img className='calenderIcon_' src={Images.calenderLogo} />
                                    <ReactDatePicker
                                        selected={formData?.startDate}
                                        onChange={(date) => {
                                            let startOfDay = new Date(date);
                                            startOfDay.setHours(0, 0, 0, 0);
                                            if (formData.endDate < date) {
                                                let endOfDay = new Date(startOfDay);
                                                endOfDay.setHours(23, 59, 59, 999);
                                                handleDateChange(endOfDay, "endDate")
                                            }
                                            handleDateChange(startOfDay, "startDate")
                                        }}
                                        dateFormat="MMMM d, yyyy"
                                        placeholderText="10/02/2024"
                                        minDate={new Date()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mt-4'>
                            <div className='form-group'>
                                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                    {t('endDate')}
                                </label>
                                <div className="customer-calendar-cnt" >
                                    <img className='calenderIcon_' src={Images.calenderLogo} />
                                    <ReactDatePicker
                                        selected={formData?.endDate}
                                        onChange={(date) => {
                                            let endOfDay = new Date(date);
                                            endOfDay.setHours(23, 59, 59, 999);
                                            handleDateChange(endOfDay, "endDate")
                                        }}
                                        dateFormat="MMMM d, yyyy"
                                        placeholderText="10/02/2024"
                                        minDate={formData.startDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateCoupons