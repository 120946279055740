import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/UI/Loader/Loader';
import { getLanguagesListApiAsync, updateUserSettingAsync } from '../../systemConfig/systemConfigSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';

const AddlanguageModal = ({setLanguagesData,close,langaugeList,selectedLanguages}) => {
      const { t } = useTranslation();
    
    const [allLanguageList, setAllLanguageList] = useState(langaugeList);
    const [selectLanguage, setSelectLanguage] = useState([...selectedLanguages]);
    const [currentLanguages, setcurrentLanguages] = useState([])
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false)
    const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
    const handleLanguage = (language,index) => {
        if (isLanguageSelected(language.name)) {
            setSelectLanguage(selectLanguage.filter((val,i) => val.name !== language.name));
        }
        else setSelectLanguage([...selectLanguage, language]);
    }
   
      const handleAddLanguage=()=>{
        // i have selected languages
        setLoading2(true);
      let params = {
        tokenData: auth?.payload?.token,
        postData: {
          app_name: "merchant",
          language: selectLanguage,
        },
      };
  
      dispatch(updateUserSettingsApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          if (obj.status_code === 200) {
            setLoading2(false);
            setLanguagesData(obj?.payload?.language)
            close();
          }
        })
        .catch((obj) => {
            setLoading2(false);
        });
  


      }
      useEffect(()=>{
        let languages=selectedLanguages?.map((lang=>lang.name));
        setcurrentLanguages(languages);
      },[selectedLanguages]);
      const isLanguageSelected=(language)=>{
        //find
        return selectLanguage?.find(lang=>lang.name==language);
      }
    return (
        <div>
            <div className="addlanguageContent mt-3 position-relative">
                {
                    loading&&<Loader/>
                }
                <div className='languageHeader'>'
                    <img
                        src={Images.settingslanguages}
                        alt="staffLocate image"
                        className="img-fluid"
                    />
                    <h3 className='appointMain'>{t("addLangauge")}</h3>
                    <p className='lightpara18_'>{t("selectMultiLanguage")}</p>
                </div>
                <div className="addStoreForm">
                    <div className="addlanguagebox">
                        {
                            allLanguageList?.map((language,index)=>(
                            <div onClick={()=>{
                             if(!currentLanguages?.includes(language?.name)) handleLanguage(language, index)}} key={index} className={`countryLanguage_ ${isLanguageSelected(language?.name) ? 'active select-none':''} mb-3`}  >
                                <img
                                    src={language?.image||  Images.frenchicon}
                                    alt="langImg"
                                    className="img-fluid rightImg me-3"
                                    style={{width:"50px"}}
                                />
                                <span className="smalblueText_">{language?.name} </span>
                            </div>
                            ))
                        }

                    </div>
                    <div className="addCustomerBtn mt-4">
                        <button
                        disabled={loading ||loading2}
                            className="serviceCancel w-100 "
                            type="button"
                            onClick={()=>{
                                close()
                            }}

                        >
                            {t("cancel")}
                        </button>
                        <button
                        disabled={loading ||loading2}
                            className="nextverifyBtn w-100 d-flex text-align-center"
                            type="button"
                            onClick={handleAddLanguage}
                        >
                            {loading2 && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}


                            {t("add")}
                            <img
                                src={Images.upArrow}
                                alt="rightArrow"
                                className="img-fluid rightImg ms-3"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddlanguageModal