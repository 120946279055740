import moment from "moment-timezone";
import { toast } from "react-toastify";
import namer from "color-namer";
import { useTranslation } from "react-i18next";

var pSBCr = null;

export const createFullAddress = (address) => {
  if (address) {
    return `${address?.current_address?.custom_address ?? ""}
    ${address?.current_address?.city ?? ""}
    ${address?.current_address?.state ?? ""},
    ${address?.current_address?.state_code ?? ""}
    ${address?.current_address?.country ?? ""},
    ${address?.current_address?.zipcode ?? ""}`;
  }
  return "";
  // if (address) {
  //   return `${} ${address?.current_address?.street_address || " "} ${
  //     address?.current_address?.city || " "
  //   }${
  //     address?.current_address?.state_code ||
  //     address?.current_address?.state ||
  //     " "
  //   } ${address?.current_address?.zipcode || " "}`;
  // }
};

export const getCurrentTimeZone = () => {
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Replace "Asia/Calcutta" with "Asia/Kolkata"
  if (timeZone === "Asia/Calcutta") {
    timeZone = "Asia/Kolkata";
  }

  return timeZone;
};

export const formattedReturnPrice = (price) => {
  // Convert price to a number, defaulting to 0 if it's falsy or not a number
  const numericPrice = parseFloat(price) || 0;

  // Format the numeric price with 2 decimal places
  const formattedPrice = numericPrice.toFixed(2);

  // Determine the sign and prepend accordingly
  const sign = numericPrice == 0 ? "" : "-";

  return `${sign}$${formattedPrice}`;
};
export const amountFormat = (price, notSign) => {
  const stringCheckAmount = typeof price === "string" ? Number(price) : price;

  // Check if the price is negative
  const isNegative = stringCheckAmount < 0;

  // Remove the negative sign temporarily for formatting
  const formattedAmount = Math.abs(stringCheckAmount).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );

  const dollarSign = notSign ? "" : "$";

  // Add the dollar sign before the formatted amount
  const amountWithSign = `${
    isNegative ? "-" : ""
  }${dollarSign}${formattedAmount}`;

  return amountWithSign || "$0.00";
};

// export const getProductPrice = (
//   supply_offers,
//   selling_price,
//   productQty = 1
// ) => {
//   console.log("supply_offers", supply_offers);
//   console.log("selling_price", selling_price);
//   console.log("productQty", productQty);

//   if (!productQty || productQty == 0) {
//     productQty = 1;
//   }

//   var productOfferPrice = selling_price;

//   if (supply_offers?.length > 0) {
//     var applicableOffer = null;
//     const supplyOffers = supply_offers;
//     for (let index = 0; index < supplyOffers.length; index++) {
//       const offer = supplyOffers[index];

//       if (productQty >= offer?.offer?.quantity) {
//         applicableOffer = offer;
//       }
//     }

//     if (applicableOffer) {
//       if (applicableOffer.offer.price_flag == "percentage") {
//         productOfferPrice = Number(
//           productOfferPrice -
//             (productOfferPrice * applicableOffer.offer.offer_price) / 100
//         );
//       } else {
//         productOfferPrice = applicableOffer.offer.offer_price;
//       }

//       if (applicableOffer.offer.offer_flag == "per_box") {
//         const _isOfferApplicable = isDivisible(
//           productQty,
//           applicableOffer.offer.quantity
//         );
//         productOfferPrice = _isOfferApplicable
//           ? productOfferPrice / applicableOffer.offer.quantity
//           : selling_price;
//       }
//     }
//   }
//   return productOfferPrice;
// };

export const getProductPrice = (
  supply_offers,
  selling_price,
  productQty = 1
) => {
  if (!productQty || productQty == 0) {
    productQty = 1;
  }

  let productOfferPrice = selling_price;

  if (supply_offers?.length > 0) {
    if (supply_offers[0]?.offer?.offer_flag == "per_pack") {
      const offerPrice = supply_offers[0]?.offer?.offer_price;
      productOfferPrice = productQty * offerPrice;
    } else {
      let applicableOffer = null;
      const supplyOffers = supply_offers;
      for (let index = 0; index < supplyOffers.length; index++) {
        const offer = supplyOffers[index];

        if (productQty >= offer?.offer?.quantity) {
          applicableOffer = offer;
        }
      }
      if (applicableOffer) {
        if (applicableOffer.offer.price_flag == "percentage") {
          productOfferPrice = Number(
            productOfferPrice -
              (productOfferPrice * applicableOffer.offer.offer_price) / 100
          );
        } else {
          productOfferPrice = applicableOffer.offer.offer_price;
        }
        if (applicableOffer.offer.offer_flag == "per_box") {
          const offerQuantity = applicableOffer.offer.quantity;
          const offerPrice = applicableOffer.offer.offer_price;
          const offerCount = Math.floor(productQty / offerQuantity);
          const remainingQuantity = productQty % offerQuantity;
          productOfferPrice = offerCount * offerPrice;
          productOfferPrice += remainingQuantity * selling_price;
        }
      } else {
        productOfferPrice = productQty * selling_price;
      }
    }
  } else {
    productOfferPrice = productQty * selling_price;
  }
  return productOfferPrice;
};
export const getProductFinalPrice = (item) => {
  var productPrice = 0;
  const supplyOffers = item.product_details?.supply?.supply_offers;
 
  const sellingPrice =
    supplyOffers?.length > 0
      ? item.product_details?.supply?.supply_prices?.actual_price
      : item.product_details?.supply?.supply_prices?.selling_price;

  productPrice = getProductPrice(supplyOffers, sellingPrice, item?.qty);

  var attributePrice = 0;

  const supplyVariants = item.product_details?.supply?.supply_prices;

  if (supplyVariants) {
    if (Array.isArray(supplyVariants)) {
      attributePrice = supplyVariants.reduce(function (a, b) {
        return a + b?.price;
      }, 0);
    } else if (typeof supplyVariants === "object" && supplyVariants !== null) {
      // attributePrice = supplyVariants?.price;
      attributePrice = supplyVariants?.selling_price;
    }
  }

  // productPrice = productPrice + attributePrice;

  return productPrice; // * item?.qty;
};

export const formattedReturnPriceWithoutSign = (price) => {
  // Convert price to a number, defaulting to 0 if it's falsy or not a number
  const numericPrice =
    typeof price === "string" ? parseFloat(price) : price || 0;

  // Format the numeric price with 2 decimal places
  const formattedPrice = numericPrice?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const amountTwoDecimal = Number(formattedPrice)?.toFixed(2);

  // Determine the sign and prepend accordingly
  const sign = numericPrice == 0 ? "" : "-";

  return `${sign}${amountTwoDecimal}`;
};

export const noCartFun = () => {
  toast.error("No Cart Found");
};

export const formattedPrice = (price) => {
  // Convert price to a number, defaulting to 0 if it's falsy or not a number
  const numericPrice = parseFloat(price) || 0;

  // Format the numeric price with 2 decimal places
  //  const formattedPrice = numericPrice.toFixed(2);

  const formattedPrice = Math.abs(numericPrice).toFixed(2);

  // Determine the sign and prepend accordingly
  const sign = numericPrice >= 0 ? "" : "-";

  return `${sign}$${formattedPrice}`;
};

export const getDaysAndDates = (
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1
) => {
  const timezone = getCurrentTimeZone();
  const currentDate = moment().tz(timezone);
  const daysInMonth = currentDate.daysInMonth();

  const daysAndDates = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const date = moment({ year, month: month - 1, day }).tz(timezone);

    if (date.isSameOrAfter(currentDate, "day")) {
      const dayOfWeek = date.format("ddd").toUpperCase();
      const completeDate = date.format("YYYY-MM-DD");
      daysAndDates.push({
        day: dayOfWeek,
        date: date.date(),
        completeDate: completeDate,
      });
    }
  }

  return daysAndDates;
};
export const getStartEndFormattedDate = (date) => {
  return `${moment(date).format("hh:mm A")}`;
};

export const calculateTimeDuration = (item) => {
  const startMoment = moment(item?.start_date_time);
  const endMoment = moment(item?.end_date_time);
  const duration = moment.duration(endMoment.diff(startMoment));

  const startFormattedTime = startMoment.format("h:mm A");
  const endFormattedTime = moment(item?.end_date_time).format("h:mm A");

  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;

  const newFormattedTime = `${startFormattedTime} - ${endFormattedTime} (${hours} hrs ${minutes} mins)`;
  return newFormattedTime;
};

export const pSBC = (p, c0, c1, l) => {
  let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof c1 == "string";
  
  if (
    typeof p !== "number" ||
    p < -1 ||
    p > 1 ||
    typeof c0 !== "string" ||
    (c0[0] !== "r" && c0[0] !== "#") ||
    (c1 && !a)
  ) {
    return null;
  }

  // Define the helper function pSBCr if not already defined
  if (!pSBCr) {
    pSBCr = (d) => {
      let n = d.length, x = {};
      if (n > 9) {
        // Parsing rgba values
        [r, g, b, a] = d = d.split(",");
        n = d.length;
        if (n < 3 || n > 4) return null;
        x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4));
        x.g = i(g);
        x.b = i(b);
        x.a = a ? parseFloat(a) : -1;
      } else {
        // Parsing hex color
        if (n === 8 || n === 6 || n < 4) return null;
        if (n < 6)
          d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
        d = i(d.slice(1), 16);
        if (n === 9 || n === 5) {
          x.r = (d >> 24) & 255;
          x.g = (d >> 16) & 255;
          x.b = (d >> 8) & 255;
          x.a = m((d & 255) / 0.255) / 1000;
        } else {
          x.r = d >> 16;
          x.g = (d >> 8) & 255;
          x.b = d & 255;
          x.a = -1;
        }
      }
      return x;
    };
  }

  h = c0.length > 9;
  h = a ? (c1.length > 9 ? true : c1 === "c" ? !h : false) : h;

  f = pSBCr(c0);
  P = p < 0;
  t = c1 && c1 !== "c"
    ? pSBCr(c1)
    : P
    ? { r: 0, g: 0, b: 0, a: -1 }
    : { r: 255, g: 255, b: 255, a: -1 };
  
  p = P ? p * -1 : p;
  P = 1 - p;

  if (!f || !t) return null;

  // Correct the calculation of r, g, b, and a
  if (l) {
    r = m(P * f.r + p * t.r);
    g = m(P * f.g + p * t.g);
    b = m(P * f.b + p * t.b);
  } else {
    r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
    g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
    b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  }

  a = f.a;
  t = t.a;
  f = a >= 0 || t >= 0;
  a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0;

  // Return result as a valid color string
  if (h) {
    return (
      "rgb" +
      (f ? "a(" : "(") +
      r +
      "," +
      g +
      "," +
      b +
      (f ? "," + m(a * 1000) / 1000 : "") +
      ")"
    );
  } else {
    return (
      "#" +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
  }
};

export const getCalendarActionButtonTitle = (status) => {
  // const { t } = useTranslation();
  switch (status) {
    case 1:
      return ("CheckIn");
    case 2:
      return ("Mark Completed");
    case 3:
      return ("Completed");
    case 4:
      return ("Declined");
    case 5:
      return ("Cancelled");

    default:
      return ("Status Unknown");
  }
};
export function replaceDeliveryStatus(str) {
  const replacements = {
    "Orders to review": "orderReviewDeliver",
    "Order Accepted": "orderAcceptDeliver",
    "Orders Prepared": "orderPrepareDeliver",
    "Assign to Driver": "orderAssignDeliver",
    "Picked up": "orderPickupDeliver",
    Delivered: "deliverOrderTable",
    "Rejected/Cancelled": "cancelDeliver",
    Returned: "returnDeliver",
  };

  return replacements[str] || str;
}

export const getDateLabel = (dateString) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  const date = new Date(dateString);
  const todayIndex = today.getDay();
  const dayIndex = date.getDay();
  const diff = date - today;

  // Check if the date is today
  if (date.toDateString() === today.toDateString()) {
    // Same day as today
    return "Today";
  }

  // Check if the date is tomorrow
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (date.toDateString() === tomorrow.toDateString()) {
    // Same day as tomorrow
    return "Tomorrow";
  }

  // Check if the date is within the next week
  if (diff < 604800000 && diff >= 0) {
    // Less than 7 days ahead and positive
    return days[dayIndex];
  }

  // Check if the date is next week on the same day as today
  const nextWeekSameDay = new Date(today);
  nextWeekSameDay.setDate(
    nextWeekSameDay.getDate() + ((7 - todayIndex + dayIndex) % 7)
  );
  if (date.toDateString() === nextWeekSameDay.toDateString()) {
    // Next week on the same day as today
    return `${("0" + date.getDate()).slice(-2)}/${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}/${String(date.getFullYear()).slice(-2)}`;
  }

  // Return the date in dd/mm/yy format
  return `${("0" + date.getDate()).slice(-2)}/${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}/${String(date.getFullYear()).slice(-2)}`;
};

// export const getWeeklyDateLabel = (dateString) => {
//   if (!dateString) return false;

//   const timezone = getCurrentTimeZone();

//   const givenDate = moment(dateString).tz(timezone);
//   const today = moment(moment().format("YYYY-MM-DD")).tz(timezone);
//   const oneWeekLater = today.add(7, "days"); // Add 7 days to current date

//   if (givenDate.isSameOrAfter(oneWeekLater)) {
//     // After one week, show the givenDate in regular format
//     return givenDate.format("L");
//   }

//   const diff = givenDate.diff(today, "days");

//   if (diff === 0) return "Today";
//   if (diff === 1) return "Tomorrow";

//   return givenDate.format("dddd");
// };

export const getWeeklyDateLabel = (dateString) => {
  if (!dateString) return false;

  const timezone = getCurrentTimeZone();
  const currentTime = moment().tz(timezone);
  const givenDate = moment(dateString).tz(timezone);

  // Check if the given date is within the next week
  const nextWeek = moment().add(7, "days");
  const withinNextWeek = givenDate.isBefore(nextWeek);

  // If the given date is within the next week
  if (withinNextWeek) {
    const today = currentTime.clone().startOf("day");
    const tomorrow = today.clone().add(1, "day");

    // Check if the given date is today or tomorrow
    if (givenDate.isSame(today, "day")) {
      return "Today";
    } else if (givenDate.isSame(tomorrow, "day")) {
      return "Tomorrow";
    } else {
      // Otherwise, return the day of the week
      return givenDate.format("dddd");
    }
  } else {
    // If the given date is beyond the next week, return in regular format
    return givenDate.format("L [at] LT");
  }
};

export function calculatePercentageValue(value, percentage) {
  if (percentage == "") {
    return 0;
  }
  const percentageValue = (percentage / 100) * parseFloat(value);
  return percentageValue.toFixed(2) ?? 0.0;
}

export const productPercetage = (actualAmount, offerAmount) => {
  const selliing = parseFloat(actualAmount) || 0;
  const offer = parseFloat(offerAmount) || 0;
  const amount = ((selliing - offer) / selliing) * 100;
  return amount?.toFixed(2) + "%";
};

export const backgroundColorCheck = (dateString) => {
  if (!dateString) return "orange"; // Default color if dateString is empty

  const timezone = getCurrentTimeZone();
  const currentTime = moment().tz(timezone);
  const givenDate = moment(dateString).tz(timezone);

  // Check if the given date is within the next week
  const nextWeek = moment().add(7, "days");
  const withinNextWeek = givenDate.isBefore(nextWeek);

  // Check if the given date is today
  if (givenDate.isSame(currentTime, "day")) {
    return "#D1FADF"; // Today's color
  }
  // Check if the given date is tomorrow
  else if (givenDate.isSame(currentTime.clone().add(1, "day"), "day")) {
    return "#EFFBFF"; // Tomorrow's color
  }
  // Check if the given date is within the next week
  else if (withinNextWeek) {
    return "#F5EDFF"; // Color for dates within the next week
  } else {
    return "#FFF7D7"; // Default color for dates beyond the next week
  }
};

export const textColorCheck = (dateString) => {
  if (!dateString) return "orange"; // Default color if dateString is empty

  const timezone = getCurrentTimeZone();
  const currentTime = moment().tz(timezone);
  const givenDate = moment(dateString).tz(timezone);

  // Check if the given date is within the next week
  const nextWeek = moment().add(7, "days");
  const withinNextWeek = givenDate.isBefore(nextWeek);

  // Check if the given date is today
  if (givenDate.isSame(currentTime, "day")) {
    return "#039855"; // Today's color
  }
  // Check if the given date is tomorrow
  else if (givenDate.isSame(currentTime.clone().add(1, "day"), "day")) {
    return "#47B0D6"; // Tomorrow's color
  }
  // Check if the given date is within the next week
  else if (withinNextWeek) {
    return "#7233C2"; // Color for dates within the next week
  } else {
    return "#DC6803"; // Default color for dates beyond the next week
  }
};

export const imageColorCheck = (dateString) => {
  if (!dateString) return "orange"; // Default color if dateString is empty

  const timezone = getCurrentTimeZone();
  const currentTime = moment().tz(timezone);
  const givenDate = moment(dateString).tz(timezone);

  // Check if the given date is within the next week
  const nextWeek = moment().add(7, "days");
  const withinNextWeek = givenDate.isBefore(nextWeek);

  // Check if the given date is today
  if (givenDate.isSame(currentTime, "day")) {
    return "hue-rotate(92deg)"; // Today's color
  }
  // Check if the given date is tomorrow
  else if (givenDate.isSame(currentTime.clone().add(1, "day"), "day")) {
    return "hue-rotate(137deg)"; // Tomorrow's color
  }
  // Check if the given date is within the next week
  else if (withinNextWeek) {
    return "hue-rotate(226deg)"; // Color for dates within the next week
  } else {
    return "#DC6803"; // Default color for dates beyond the next week
  }
};
export function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getColorName = (input) => {
  if (!input) return "Unknown Color";

  const color = input.trim().toLowerCase();

  if (/^[a-zA-Z]+$/.test(color)) {
    return color.charAt(0).toUpperCase() + color.slice(1);
  }

  if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(color)) {
    const nameData = namer(color).basic[0];
    return nameData
      ? nameData.name.charAt(0).toUpperCase() + nameData.name.slice(1)
      : color;
  }

  return "Unknown Color";
};

export const getInvertedColor = (hexColor) => {
  // Remove the hash if it exists
  const color = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;

  // Parse the RGB values
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Calculate the brightness using the formula: (0.299*R + 0.587*G + 0.114*B)
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

  // If brightness is high, use black text; otherwise, use white text
  return brightness > 128 ? "#000000" : "#FFFFFF";
};

export const FORPICKUP = (data) => {
  // data = '3,1,4'
  const deliveryOptions = data?.split(",").map(Number);
  return deliveryOptions.includes(3); // Check if 3 is included
};
