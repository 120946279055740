import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../components/shared/CustomModal";
import EditOfferModal from "../myProducts/EditOfferModal";
import StopOfferModal from "../myProducts/StopOfferModal";
import PauseOfferModal from "../myProducts/PauseOfferModal";
import DeleteProductModal from "../myProducts/DeleteProductModal";
import DeleteModal from "../myPos/deleteModal";
import EditLocationModal from "../myProducts/EditLocationModal";
import EditSalePriceModal from "../myProducts/EditSalePriceModal";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { changeMultipleOfferStatusApiAsync, changeProductStatusAsync, deleteMultipleOffersApiAsync, getProductOfferListApiAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { addThousandSeparator } from "../../../utilities/helpers";
import { resultSelectOption } from "../../constants/common.constant";
import { toast } from "react-toastify";
import NotificationButton from "../../components/NotificationButton";
import { useTranslation } from "react-i18next";
import { OrderColumns } from "../../constants/orders/orders";
import { OfferLanguageColumns } from "../../constants/offer";
import { addMoreColumnsSettings, selectPagesColumnData } from "../../pagesColumnReducer";

const ServiceOffer = () => {
  const { t } = useTranslation();
  // Start Modal
  const [loading, setLoading] = useState(false);
  const [isStatusChage, setIsStatusChage] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch()
  const [offerList, setOfferList] = useState([]);
  const [offerFlag, setOfferFlag] = useState('')
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [totalRows, setTotalRows] = useState(0)
  const [key, setKey] = useState(Math.random());
  const [selectedProductId, setSelectedProductId] = useState("")
  const [IsSelectedAll, setIsSelectedAll] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isToggle, setIsToggle] = useState(false);
  const pageColumn=useSelector(selectPagesColumnData);
  const disPatch = useDispatch();
  const [offerStatus, setOfferStatus] = useState('1');
    let defaultColumns={
      product:true,
      validity:true,
      offer_type:true,
      status:true,
      "Pos Price":true,
      "B2c Price":true,
      // "B2b Price":true,
      "Category":false,
      "Subcategory":false,
      "Cost Price":false
    }
    
    const [columns, setColumns] = useState(
      JSON.parse(JSON.stringify(
          pageColumn?.serviceOffer|| defaultColumns))
      )
  
  // search
  const [searchKeyword, setsearchKeyword] = useState("");
  const [searchTimeout, setsearchTimeout] = useState(null);
  const [isSearching, setisSearching] = useState(false);
  // search

  // pagination
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageData, setTotalPageData] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  // pagination
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleColumn=(key)=>{
    disPatch(addMoreColumnsSettings({
    key:"serviceOffer",
    data:{
      ...columns,
      [key]:!columns[key]
    }
    }))
    setColumns({
     ...columns,
      [key]:!columns[key]
    })
  }
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };
  // End Modal
  const history = useHistory();
  const selectOption = [
    { value: "", label: "All Type" },
    { value: "percentage", label: "Percentage" },
    { value: "amount", label: "Amount" },
    // { value: "b2b", label: "Location 3" },
  ];


  const getOfferList = (page) => {
    setLoading(true);
    setIsSelectedAll(false);
    let params = {
      tokenData: auth?.payload?.token,
      postData: {
        'seller_id': auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        'page': currentPage,
        'limit': selectPageSize,
        'type': "service",
        // filter: "completed",
        need_completed: activeTab==2,
        need_in_progress : activeTab==0,
        ...(offerFlag.length&&{
          offer_flag:offerFlag
        }),
        ...(searchKeyword.length > 0 && {
          search: searchKeyword,
        }),
      }
    }

    dispatch(getProductOfferListApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setOfferList(obj?.payload?.data||[]);
        setTotalRows(obj?.payload?.total);
        setTotalPages(obj?.payload?.total_pages);
      })
      .catch((obj) => {
        setLoading(false);
      })
  }
  useEffect(() => {
    getOfferList()
  }, [currentPage,selectPageSize,offerFlag])


  const changeProductStatus = (status,ids) => {
    setIsStatusChage(true);
    let offers_ids=ids||[selectedProductId.id];
  if(offers_ids==null ||offers_ids?.length==0){
    toast.error("Please select product(s) to change status")
    return;
  }
    const data = {
      tokenData: auth?.payload?.token,
      postData: {
        ids:  offers_ids,
        status: status?.toString()
      }
    }
    setLoading(true);
    dispatch(changeMultipleOfferStatusApiAsync(data))
    .then(unwrapResult)
    .then(res => {
      setActiveTab(status==2?status:0)
      setIsStatusChage(false);
      getOfferList()
      handleOnCloseModal();
      setSelectedOffers([]);

    }).catch(e => {
      setIsStatusChage(false);
    })
  }
  // handle pagination
  const handleCurrentPage = (value) => {
    setCurrentPage((prev) => {
      if (prev + value <= totalPages && prev + value >= 1) return prev + value;
      return prev;
    });
  };
  // 
  // search
  useEffect(() => {
    if(!isSearching)return;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    let searchTimer = setTimeout(() => {
      setisSearching(false);
      getOfferList();
    }, 1000);
    setsearchTimeout(searchTimer);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchKeyword]);
  //search

  // hadel selected offer
  const handleSelectedOffers=(productId)=>{
    if(selectedOffers.includes(productId)){
      setSelectedOffers(selectedOffers.filter(id=>id!==productId))
      setIsSelectedAll(false);
    }
    else {
      setIsSelectedAll(selectedOffers.length===offerList.length-1);
      setSelectedOffers(prev=>([...prev,productId]));
    }
  }
  const handleAllSelectedOffers=(value)=>{
    if(value){
      setSelectedOffers(offerList.map(item=>item.id))
    }
    else{
      setSelectedOffers([])
    }
  }
  //delete all offers
  const handleDeleteProduct=()=>{
    const params={
      postData:{
        seller_id:
        auth?.payload?.uniqe_id? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
        offers_ids:selectedOffers,
        "app-name":"merchant"
      },
      tokenData:auth?.payload?.token
    }
    setIsStatusChage(true);
    dispatch(deleteMultipleOffersApiAsync(params))
    .then(unwrapResult)
    .then(res=>{
      setIsStatusChage(false);
      //
      setSelectedOffers([])
      resetFields();
      getOfferList();
      handleOnCloseModal()
    })
    .catch(e=>{
      setIsStatusChage(false);
    })
  }
  //toggle tab
  const handleTab=(status)=>{
    setActiveTab(status);
    setIsToggle(true);
  }
  useEffect(()=>{
    if(!isToggle)return;
    setIsToggle(false);
    resetFields()
    setIsSelectedAll(false);


    getOfferList()
  },[activeTab])
  const resetFields=()=>{
    setSelectPageSize(10);
    setCurrentPage(1);
    setOfferFlag('');
    setOfferList([])
  }
  return (
    <div className="h-100 walletStats">
      <div className="productOfferHead_Container">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fontSize24 fw500 mb-0 txtDarkblue">{t('serviceOffers')}</h3>
          <div className="d-flex align-items-center gap-4">
            <NotificationButton />
            <div className="input-group search_group">
                  <img
                    src={Images.SearchIcon}
                    alt=""
                    className="img-fluid searchIcon"
                    />
                    <input
                     type="text"
                     className="form-control"
                     onChange={(e) =>{
                     setisSearching(true);
                     setsearchKeyword(e.target.value)}}
                     aria-label="Recipient's username"
                     aria-describedby="basic-addon2"
                    />
            </div>
                               
            <Link
              to="/createServiceOffer"
              className="dropdown-toggle addNew_ headerSaveBtn"
            >
              <span className="addNewTxt">{t('createOffer')}</span>
              <img src={Images.addButtonImg} alt="addNew_Image" />
            </Link>
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <div className="col-md-4">
            <div className="d-flex align-items-center gap-2">
              <button onClick={()=>{
                handleTab(0);
              }} className={`activeProductBtn ${activeTab==0?'active':''}`}>
                {t('active')} <span className="activeProductofferItem"></span>
              </button>
              <button onClick={()=>{
                handleTab(2);
              }} className={`activeProductBtn ${activeTab==2?'active':''}`}>
                {t('completed')} <span className="activeProductofferItem"></span>
              </button>
            </div>
          </div>

          <div className="col-md-8">
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="offerHeaderSelect reactSelectParent">
                <ReactSelect
                  options={selectOption}
                  onChange={(event)=>{
                    setOfferFlag(event.value);
                  }}
                  placeholder={t("offerType")}
                  classNamePrefix="react-select"
                  className="anlyticSelect react-select-container"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: "120px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                      color: state.isSelected ? "#fff" : "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.blueDownArrow}
                        width={24}
                        height={24}
                        alt="drop_icon"
                      />
                    ),
                  }}
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fontSize14 mb-0 txtDarkblue">{t('showingResult')}</h3>
                <div className="offerHeaderSelect reactSelectParent">
                  <ReactSelect
                    options={resultSelectOption}
                    placeholder="10"
                    classNamePrefix="react-select"
                    className="anlyticSelect react-select-container"
                    onChange={(e) => {
                      setCurrentPage(1);
                      setSelectPageSize(e.value);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "75px",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                            ? "#f5f6fc"
                            : "#fff",

                        color: state.isSelected ? "#fff" : "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.blueDownArrow}
                          width={24}
                          height={24}
                          alt="drop_icon"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
              {
                offerList.length>0&&
              <div className="paginationParent">
                <div className="d-flex align-items-center gap-1">
                  <div
                   className={`prevPagebtn ${
                    currentPage <= 1 && "disablePrevbtn"
                  } `}
                  onClick={() => {
                    if (currentPage <= 1) return;
                    handleCurrentPage(-1);
                  }}
                  >
                    <img src={Images.arrowDoubleLeft} alt="arrowDoubleRight" />
                  </div>
                  <div 
                  className={`prevPagebtn ${
                    currentPage <= 1 && "disablePrevbtn"
                  } `}
                  onClick={() => {
                    if (currentPage <= 1) return;
                    handleCurrentPage(-1);
                  }}
                  >
                    <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                  </div>
                </div>
                <h6 className="fontSize14 mb-0 txtDarkblue"> {(currentPage - 1) * selectPageSize + 1}-
                {currentPage * selectPageSize > totalRows
                      ? totalRows
                      : currentPage * selectPageSize}{" "} of {
                  totalRows
                }</h6>
                <div className="d-flex align-items-center gap-1">
                  <div
                  className={`nextPagebtn ${
                    currentPage === totalPages && "disableNextbtn"
                  }`}
                  onClick={() => {
                    if (currentPage === totalPages) return;
                    handleCurrentPage(1);
                  }}
                  >
                    <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                  </div>
                  <div className={`nextPagebtn ${
                        currentPage === totalPages && "disableNextbtn"
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages) return;
                        handleCurrentPage(1);
                      }}>
                    <img src={Images.arrowDoubleRight} alt="arrowDoubleRight" />
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive commonTable productOfferTable">
        <table className="w-100">
          <thead>
            <tr>
           { Object.keys(columns)?.map((key,index)=>(
                  <th key={index} className={`${columns[key]?'':'d-none'}`}>{
                    key=='product'? <div className="align-items-center productAvailabilityChild">
                    <div className="form-group">
                      <input
                        checked={IsSelectedAll}
                        onChange={() => {
                          handleAllSelectedOffers(!IsSelectedAll)
                          setIsSelectedAll(prev => !prev);
  
                        }}
                        type="checkbox" id="id1" />
                      <label htmlFor="id1" className="me-0"></label>
                    </div>
                    <h3 className="fontSize12 mb-0">{t('product')}</h3>
                  </div>:t(OfferLanguageColumns[`${key}`])
                  }</th>
                ))
              }
              {/* <th>Validity</th>
              <th>Offer Type</th>
              <th>POS Price</th>
              <th>B2C Price</th>
              <th>B2B Price</th> */}
            
              <th>
                <div className="d-flex align-items-center justify-content-end gap-1">
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.plusIconWithBg} alt="icon" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="categoryDropdownMenu variantsDropdownMenu">
                         <div className="resetText_" onClick={(e) => {
                              setColumns(defaultColumns)
                              disPatch(addMoreColumnsSettings({
                                key:"productOffer",
                                data:columns
                              }))
                            }}>
                            {t('reset')}
                          </div>
                         {
                          Object.keys(columns)?.map((key,index)=>(
                        <Dropdown.Item
                        onClick={(e)=>{
                          e.stopPropagation(); 
                          handleColumn(key)}}
                        >
                          <div className="align-items-center productAvailabilityChild">
                            <div className="form-group">
                              <input type="checkbox"
                              checked={columns[key]}
                             
                              id="id2" />
                              <label htmlFor="id2" className="me-0"></label>
                            </div>
                            <h3 className="fontSize14 mb-0 mt-1">
                              {
                                t(OfferLanguageColumns[`${key}`])
                              }
                            </h3>
                          </div>
                        </Dropdown.Item>

                          ))
                        }
                       
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                        disabled={selectedOffers.length==0}
                      >
                        <img src={Images.moreHorizontalLogo}
                        style={{
                          filter:
                          selectedOffers.length
                              ? "brightness(0) saturate(100%) invert(14%) sepia(63%) saturate(2662%) hue-rotate(221deg) brightness(94%) contrast(89%)"
                              : "",
                        }}
                        alt="logo" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="categoryDropdownMenu variantsDropdownMenu">
                        <Dropdown.Item
                        onClick={(e)=>{
                          e.stopPropagation(); 
                        }}
                        href="#/action-1">
                          <h3 className="fontSize16 fw500 mb-0 txtDarkblue">
                            {t('bulkEditing')}
                          </h3>
                          </Dropdown.Item>
                       {
                        activeTab==0&&<>
                        <Dropdown.Item
                          onClick={() => {
                            changeProductStatus(1,selectedOffers);

                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.locationLogo} alt="logo" />
                            <h3 className="fontSize14 mb-0">{t('pause')}</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-2"
                          onClick={() =>{
                            changeProductStatus(2,selectedOffers);

                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.moneyPaperIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">
                              {t('stop')}
                            </h3>
                          </div>
                        </Dropdown.Item>
                        </>
                       }
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            if(selectedOffers.length<=0){
                              toast.error("Please select a list of offers");
                             
                              return;
                            }
                            setModalDetail({
                              show: true,
                              flag: "deleteProductModal",
                            });
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              {t('deleteAll')}
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              loading ? (
                <tr className="border-none text-center">
                  {/* Full-width spinner row */}
                  <td colSpan="100%">
                    <div className="d-flex">
                      <span className="inner-spin spinner-border spinner-border-sm"></span>
                    </div>
                  </td>
                </tr>
              ):
            offerList?.length > 0 ? offerList
            // .filter(item => item?.status == 0)
            .map((item, idx) => {
              let actualPrice=Number(item?.supply_offers?.[0]?.actual_price||item?.actual_price||0);
              let offers=item?.supply_offers?.[0]?.supply_variant?.supply_offers;
              let priceFlag=item?.price_flag;
                    let itemStatus=item?.status;
                    let pricesDetails={};
                    offers?.map((offer)=>{
                      let name=offer?.app_name;
                      let offerPrice=offer?.offer_price;
                      let variantPrice=offer?.actual_price;
                      pricesDetails[name]={
                        name,
                        actualPrice:Number(variantPrice||0)?.toFixed(2),
                        price:Number((priceFlag=='amount'?variantPrice- offerPrice:
                          //find percentage of actual price
                          (variantPrice-(offerPrice*variantPrice)/100))||0)?.toFixed(2)
                      };

                    })
              return (
                <tr>
                  {
                    columns.product&&<td>
                    <div className="align-items-center productAvailabilityChild">
                      <div className="form-group">
                        <input
                        type="checkbox" id={idx}
                        checked={selectedOffers.includes(item?.id)}
                        onChange={()=>{
                          handleSelectedOffers(item?.id);
                        }}
                        />
                        <label htmlFor={idx} className="me-0"></label>
                      </div>

                      <div className="d-flex align-items-center gap-3">
                        <img
                          src={item?.product?.image 
                            // || Images.productItemImg
                          }
                          alt="img"
                          className="productItemImg"
                        />
                        <div>
                          <h3 className="fontSize12 fw500 mb-0 pb-1 txtDarkblue">
                            {item?.product?.name}
                          </h3>

                          {/* <div className="d-flex align-items-center gap-2">
                            <span className="skyblueDot"></span>
                            <h6 className="fontSize12 fw400 mb-0 staffPositionTxt">
                              SKU {item?.supply_offers?.[0]?.supply_variant?.sku || "____"}
                            </h6>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </td>
                  }
                  {
                    columns.validity&&<td className="fontSize12 txtLightBlue">
                    {moment(item?.offer_validity).format('DD/MM/YYYY')}
                  </td>
                  }
                  {
                    columns.offer_type&&<td className="fontSize12 txtLightBlue">{
                      item?.price_flag}</td>
                  }
                  {
                          columns.offer_type&&<td className="fontSize12 txtLightBlue">{
                            itemStatus==0?'Active':itemStatus==1?'Pause':'Stop'}</td>
                        }
                        {
                          columns["Pos Price"]&&<td className="fontSize12 txtLightBlue">
                          <h6 className="fontSize12 marketPrice">$
                            {
                              addThousandSeparator(pricesDetails?.["pos"]?.actualPrice||actualPrice?.toFixed(2)  )
                            }
                          </h6>
                          <h6 className="discountPrice fontSize12 mb-0">
                            ${
                              addThousandSeparator(Number(Math.max(0,pricesDetails?.["pos"]?.price || actualPrice))?.toFixed(2)
                              )
                            }
                          </h6>
                        </td>
                        }
                        {
                          columns["B2c Price"]&&<td>
                          <h6 className="fontSize12 marketPrice">$
                            {
                              addThousandSeparator(pricesDetails?.["b2c"]?.actualPrice||actualPrice?.toFixed(2))
                            }
                          </h6>
                          <h6 className="discountPrice fontSize12 mb-0">
                            ${
                              addThousandSeparator(Number(Math.max(0,pricesDetails?.["b2c"]?.price || actualPrice))?.toFixed(2))
                            }
                          </h6>
                        </td>
                        }
                  
                  {/* {
                    columns["B2c Price"]&&<td>
                    <h6 className="fontSize12 marketPrice">$
                      {
                        addThousandSeparator(actualPrice?.toFixed(2))
                      }</h6>
                    <h6 className="discountPrice fontSize12 mb-0">
                      ${
                        addThousandSeparator(
                          offers?.[4]?.
                          offer_price||actualPrice?.toFixed(2)
                        )
                      }
                    </h6>
                  </td>

                  } */}
                  {
                    columns.Category&&<td className="fontSize12 txtLightBlue">{
                      item?.categories?.name??"__"
                    }</td>
                  }
                  {
                   columns.Subcategory&&<td className="fontSize12 txtLightBlue">{
                    item?.subcategories?.name??"__"
                   }</td>
                  }
                  {
                   columns["Cost Price"]&&<td className="fontSize12 txtLightBlue">${Number(actualPrice||0)?.toFixed(2)}</td>
                  }
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.moreVerticalLogo} alt="logo" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="categoryDropdownMenu variantsDropdownMenu">
                        <Dropdown.Item
                          href="#/action-1"
                          onClick={() => history.push(`/ServiceOfferView?service=${item?.id}`)}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.darkBlueEyeIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">{t('view')}</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {                           
                            history.push(`/editOffer/${item.id}`)
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.darkblueEditIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">{t('edit')}</h3>
                          </div>
                        </Dropdown.Item>
                        {
                          itemStatus==0?<>
                          <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setOfferStatus('1');

                            setModalDetail({
                              show: true,
                              flag: "pauseOfferModal",
                            });
                            setSelectedProductId(item)
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.pauseIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">{t('pause')}</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "stopOfferModal",
                            });
                            setSelectedProductId(item)
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.stopIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              {t('stop')}
                            </h3>
                          </div>
                        </Dropdown.Item>
                          </>:
                          itemStatus==1?
                          <>
                          <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setOfferStatus('0');
                            setModalDetail({
                              show: true,
                              flag: "pauseOfferModal",
                            });
                            setSelectedProductId(item)
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.pauseIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">{t('resumeOffer')}</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "stopOfferModal",
                            });
                            setSelectedProductId(item)
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <img src={Images.stopIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              {t('stop')}
                            </h3>
                          </div>
                        </Dropdown.Item>
                        </>
                        :<></>
                          
                          
                        }
                       
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              )
            }): <tr className="border-none text-center">
            {/* Full-width spinner row */}
            <td colSpan="100%">
              <div className="d-flex">
                <p className="text-center w-100">{t('noDataFound')}</p>
              </div>
            </td>
          </tr>
            }

          </tbody>
        </table>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "editOfferModal"
            ? "editVariantModal"
            : modalDetail.flag === "stopOfferModal"
              ? "stopOfferModal"
              : modalDetail.flag === "pauseOfferModal"
                ? "stopOfferModal"
                : modalDetail.flag === "deleteProductModal"
                  ? "stopOfferModal"
                  : modalDetail.flag === "deleteModal"
                    ? "deleteModal"
                    : modalDetail.flag === "editLocationModal"
                      ? "editLocationModal"
                      : modalDetail.flag === "editSalePriceModal"
                        ? "editLocationModal"
                        : ""
        }
        child={
          modalDetail.flag === "editOfferModal" ? (
            <EditOfferModal
            offerData={selectedProductId}
            close={() => handleOnCloseModal()
            
            } />
          ) : modalDetail.flag === "stopOfferModal" ? (
            <StopOfferModal
              productStopFunc={changeProductStatus}
              isStatusChage={isStatusChage}
              close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "pauseOfferModal" ? (
            <PauseOfferModal
            offerStatus={offerStatus}
              isStatusChage={isStatusChage}
              productPauseFunc={changeProductStatus}
              close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "deleteProductModal" ? (
            <DeleteProductModal
             isStatusChage={isStatusChage}
             handleDeleteProduct  ={handleDeleteProduct}
            //  productDeletedFunc={changeProductStatus}
              close={() => handleOnCloseModal()}
              handleModal={handleOpenModal}
            />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editLocationModal" ? (
            <EditLocationModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editSalePriceModal" ? (
            <EditSalePriceModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "editOfferModal" ? (
              <>
                <h2 className="fontSize24 fw500 mb-0 txtDarkblue">
                  {" "}
                  Edit Sale Offer
                </h2>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : modalDetail.flag === "editLocationModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      2 items selected
                    </h6>
                    <h3 className="fontSize24 fw500 mb-0 txtDarkblue">
                      Update visibility
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : modalDetail.flag === "editSalePriceModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      2 items selected
                    </h6>
                    <h3 className="fontSize24 fw500 mb-0 txtDarkblue">
                      Update sale price
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ServiceOffer;
