import { createSlice } from "@reduxjs/toolkit";

export const walletSlice = createSlice({
  name: "wallet", // "name" should not be in quotes.
  initialState: {  // "initialState" should not be in quotes.
    walletSetting: {}
  },
  reducers: {
    setWalletData: (state, action) => {
      state.walletSetting = action.payload;  // No need for optional chaining here
    }
  }
});

export const { setWalletData } = walletSlice.actions;  // Removed optional chaining here
export const selectWalletData = (state) => state.wallet; 
// Accessing the walletSetting
export default walletSlice.reducer; // Removed optional chaining
