import React, { forwardRef, useMemo } from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import * as Images from "../../../../utilities/images";
import { CALENDAR_VIEW_MODES } from "../../../constants/bookings/allbooking";
import { Spacer } from "./Spacer";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotificationButton from "../../../components/NotificationButton";
import { useTranslation } from "react-i18next";

const CalendarHeaderWithOptions = ({
  prevMonth,
  getFormattedHeaderDate,
  nextMonth,
  day,
  dayHandler,
  week,
  weekHandler,
  month,
  monthHandler,
  onPressCalendarIcon = () => {},
  shouldShowCalendarModeOptions = true,
  calendarViewMode,
  onPressCalendarViewMode = () => {},
  onPressListViewMode = () => {},
  onPressNotification = () => {
  },
  onPressSearch = () => {},
  calendarDate,
  setCalendarDate,
}) => {
  const { t } = useTranslation();
  const history=useHistory();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        calenderHeader: {
          backgroundColor: "#fff",
          justifyContent: "center",
          paddingHorizontal: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          zIndex: 1,
        },
        displayFlex: {
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap:"15px"
        },
      
        monthlySchduelNew: {
          backgroundColor: "#fff",
          borderRadius: 3,
          paddingHorizontal: 5,
          justifyContent: "center",
          alignItems: "center",
        },
        leftLight: {
          width: 40,
          height: 40,
          resizeMode: "contain",
        },
        row: {
          flexDirection: "row",
        },
        rowHorizonCenter: {
          alignItems: "center",
        },
        buttonCalender: {
          borderWidth: 0.8,
          borderColor: "#D7DEFF",
          borderRadius: 50,
          paddingVertical: 2,
          paddingHorizontal: 6,
        },
        monthlySchduleDate: {
          // fontFamily: "Montserrat-Medium",
          color: "#263682",
          fontSize: 16,
          paddingHorizontal: 5,
        },
        arrowButtonStl: {
          borderRadius: 2,
          paddingHorizontal: 3,
          paddingVertical: 2,
        },
        flexAlign: {
          flexDirection: "row",
          alignItems: "center",
        },
        calenderModeView: {
          backgroundColor: "#F5F6F7",
          paddingVertical: 6,
          paddingHorizontal: 8,
          borderRadius: 25,
          justifyContent: "center",
          alignItems: "center",
        },
        clickedButtonCon: {
          backgroundColor: "#263682",
          paddingVertical: 8,
          paddingHorizontal: 12,
          borderRadius: 25,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          paddingHorizontal: 20,
        },
        unClickedButtonCon: {
          backgroundColor: "#F5F6F7",
          paddingVertical: 8,
          paddingHorizontal: 12,
          borderRadius: 25,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          paddingHorizontal: 20,
        },
        checkedText: {
          // fontFamily: Fonts.Regular,
          color: "#fff",
          fontSize: 14,
        },
        unCheckedText: {
          // fontFamily: Fonts.Regular,
          color: "#263682",
          fontSize: 14,
        },
        deliveryView: {
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        },
        truckStyle: {
          width: 32,
          height: 32,
          resizeMode: "contain",
        },
        calenderModeIcons: {
          width: 24,
          height: 24,
          resizeMode: "contain",
        },
        title1: {
          color: "#263682",
          fontSize: 24,
          paddingHorizontal: 5,
        },
        calenderPadding: {
          paddingVertical: 6,
          paddingHorizontal: 8,
        },
      }),
    []
  );
  const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px 10px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "30px",
        borderColor: "#D7DEFF",
        gap: "5px",
      }}
      onClick={onClick}
    >
      <img className="users-selected-date" src={Images.leftArrow} alt="img" />
      <p style={{ color: "#263682", marginLeft: "5px", marginRight: "5px" }}>
        {moment.utc(calendarDate).format("MMMM YYYY")}
      </p>

      <img
        className="users-selected-date"
        src={Images.newRightArrow}
        alt="img"
      />
    </div>
  ));

  return (
    <View style={styles.calenderHeader}>
      <View style={styles.displayFlex}>
        <View style={styles.monthlySchduelNew}>
          <View style={styles.displayFlex}>
            <img src={Images?.blueAllbookingsicon || Images?.ArrowLeft} />
            <h3 className="fontSize24 fw500 txtDarkblue mb-0 ps-2">{t('calendar')}</h3>
            <Spacer space={16} horizontal />
            {calendarViewMode == CALENDAR_VIEW_MODES.CALENDAR_VIEW ? (
              <TouchableOpacity
                onPress={onPressCalendarIcon}
                style={[
                  styles.row,
                  styles.rowHorizonCenter,
                  styles.buttonCalender,
                  styles.calenderPadding,
                ]}
              >
                <TouchableOpacity
                  style={styles.arrowButtonStl}
                  onPress={prevMonth}
                >
                  <img
                    src={
                      Images?.leftArrow
                      // "/images/arrowLeftPos.svg"
                    }
                    alt="img"
                  />
                </TouchableOpacity>
                <Text
                  style={styles.monthlySchduleDate}
                >{`${getFormattedHeaderDate()}`}</Text>
                <TouchableOpacity
                  style={styles.arrowButtonStl}
                  onPress={nextMonth}
                >
                  <img
                    src={
                      Images?.newRightArrow
                      // "/images/rightArrowPos.svg"
                    }
                    alt="img"
                  />
                </TouchableOpacity>
              </TouchableOpacity>
            ) : (
              <div style={{ zIndex: 999 }}>
                <ReactDatePicker
                  selected={new Date(calendarDate)}
                  onChange={(date) => {
                    setCalendarDate(moment.utc(date));
                  }}
                  startDate={new Date()}
                  // endDate={endDate}
                  // selectsRange
                  customInput={<DatePickerCustomComponent />}
                />
              </div>
            )}

            <Spacer space={16} horizontal />

            {shouldShowCalendarModeOptions && (
              <View style={[styles.flexAlign, styles.calenderModeView]}>
                <TouchableOpacity
                  style={
                    day ? styles.clickedButtonCon : styles.unClickedButtonCon
                  }
                  onPress={dayHandler}
                >
                  <Text style={day ? styles.checkedText : styles.unCheckedText}>
                    {t("day")}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={
                    week ? styles.clickedButtonCon : styles.unClickedButtonCon
                  }
                  onPress={weekHandler}
                >
                  <Text
                    style={week ? styles.checkedText : styles.unCheckedText}
                  >
                    {t("week")}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={
                    month ? styles.clickedButtonCon : styles.unClickedButtonCon
                  }
                  onPress={monthHandler}
                >
                  <Text
                    style={month ? styles.checkedText : styles.unCheckedText}
                  >
                    {t("month")}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
        <View style={{ flex: 1 }} />

        <View style={styles.deliveryView}>
        <NotificationButton />
          <Spacer space={16} horizontal />
          <TouchableOpacity onPress={onPressSearch}>
            <img
              src={Images?.SearchIcon || Images?.ArrowLeft}
              alt="img"
              // style={[styles.truckStyle]}
            />
          </TouchableOpacity>
        </View>
        <Spacer space={8} horizontal />

        {/* Appointment View type */}
        <View style={styles.flexAlign}>
          <button
            // style={
            //   calendarViewMode === CALENDAR_VIEW_MODES.CALENDAR_VIEW
            //     ? styles.clickedButtonCon
            //     : styles.unClickedButtonCon
            // }
            onClick={onPressCalendarViewMode}
            className={`headerCalenderBtn ${
              calendarViewMode === CALENDAR_VIEW_MODES.CALENDAR_VIEW
                ? "active"
                : ""
            } `}
          >
            <img
              src={Images?.customCalendar}
              alt="img"
              width={16}
              height={16}
              className="headerCalenderlogo"
            />
            <Spacer space={8} horizontal />
            <Text
              style={
                calendarViewMode === CALENDAR_VIEW_MODES.CALENDAR_VIEW
                  ? styles.checkedText
                  : styles.unCheckedText
              }
            >
              {t("calenderView")}
            </Text>
          </button>
          <Spacer space={8} horizontal />
          <button
            // style={
            //   calendarViewMode === CALENDAR_VIEW_MODES.LIST_VIEW
            //     ? styles.clickedButtonCon
            //     : styles.unClickedButtonCon
            // }
            className={`headerCalenderBtn ${
              calendarViewMode === CALENDAR_VIEW_MODES.LIST_VIEW ? "active" : ""
            } `}
            onClick={onPressListViewMode}
          >
            <img
              src={Images?.listViewLogo}
              alt="img"
              width={16}
              height={16}
              className="headerCalenderlogo"
            />
            <Spacer space={8} horizontal />
            <Text
              style={
                calendarViewMode === CALENDAR_VIEW_MODES.LIST_VIEW
                  ? styles.checkedText
                  : styles.unCheckedText
              }
            >
              {t("listView")}
            </Text>
          </button>
        </View>
      </View>
    </View>
  );
};

export default CalendarHeaderWithOptions;
