import React, { useState } from 'react'
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOtpInOrderApiAsync } from '../../dashboard/dashboardSlice';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectLoginAuth } from '../../auth/authSlice';
import { useTranslation } from 'react-i18next';

const VerifyOrderModal = (props) => {
    const { t } = useTranslation();
    const [otp, setOtp] = useState("");
    const toastId = React.useRef(null)
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth);
    const [otpLoading, setOtpLoading] = useState(false)
    const [buttonActive, setButtonActive] = useState(false)
    const generateRandomName = () => {
        return Math.random().toString(36).substr(2, 10);
    };
    const onComplete = (code) => {
        setOtp(code);
        if (code.length == 4) {
            setButtonActive(true)
        }
        else {
            setButtonActive(false)
        }
    }

    const handleVerifyOtp = () => {
        let params = {
            "postData": {
                "order_id": props?.orderData?.id,
                "otp": otp
            },
            "tokenData": auth?.payload?.token
        }
        if(props?.orderData?.delivery_option!= 3){
         params.postData.flag="seller";
        }
        setOtpLoading(true)
        dispatch(verifyOtpInOrderApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
                setOtpLoading(false)
                // let status=props?.orderData?.delivery_option==1?4:props?.orderData?.status?Number(props?.orderData?.status)+1:4;
                if(props?.orderData?.delivery_option==3){
                    let status=props?.orderData?.delivery_option==3?5:4
                props?.handleChangeStatus(props?.orderData?.id,status)
                }else{
                    props?.getOrderDataById();
                }
                props.close()
            }
            )
            .catch((obj) => {
                setOtpLoading(false)
            })
    }

    return (
        <>
            <div className="verify-part">
                <div className="verify-box text-center">
                    <div className="pin-box d-flex justify-content-center" >
                        <OTPInput numInputs={4}
                            className='input_digits_'
                            value={otp}
                            data-cy="pin-field"
                            name={generateRandomName()}
                            autoComplete="new-password"
                            isInputNum={true}
                            // isInputSecure={true}
                            placeholder='0000'
                            onChange={onComplete}
                        // onComplete={(code) => onComplete(code)}
                        />
                    </div>
                </div>
            </div>
            <button onClick={() => handleVerifyOtp()} className={`loginBtnSign mt-5 w-100 ${buttonActive ? 'buttonActive' : ''}`} disabled={otpLoading || !buttonActive} type="submit">
                {otpLoading === true && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                <span>{t('verify')}</span>
            </button>
        </>
    )
}

export default VerifyOrderModal
