import React from "react";
import * as Images from "../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { addThousandSeparator } from '../../../utilities/helpers';


const OfferService = (props) => {
  const { t } = useTranslation();
  const { offerProduct, offerLoading, setOfferLimit } = props;
  const history = useHistory();

  const mainoffer = Array.isArray(offerProduct)
    ? offerProduct.filter((item) => item?.type === "service")
    : [];

  return (
    <div className="col-md-6 mb-4 mb-md-0">
      <div className="sellingProductContainer p-0 offerProductContainer">
        <div className="d-flex align-items-center justify-content-between offerProduct_Head">
          <div className="d-flex align-items-center gap-2">
            <img src={Images.discountLogo} alt="logo" />
            <h2 className="fontSize20 txtBlue fw500 mb-0">{t('offerOngoing')}</h2>
          </div>
          {mainoffer.length > 0 && (
            <button
              className="viewAllBtn"
              onClick={() => history.push("/serviceOffer")}
            >
              {t('viewAll')}
            </button>
          )}
        </div>

        <div className="commonTable sellingProductTable table-responsive me-3 ms-3">
          <table className="w-100">
            <tbody>
              {offerLoading ? (
                <tr>
                  <td colSpan={2}>
                    <div className="d-flex align-item-center justify-content-center">
                      <span className="spinner-border spinner-border-md spinnerDark"></span>
                    </div>
                  </td>
                </tr>
              ) : mainoffer.length > 0 ? (
                mainoffer.map((product, idx) => {
                  let actualPrice = Number(product?.price || 0);
                  let supplies = product?.supplies || [];

                  let pricesDetails = {};

                  supplies.forEach((supply) => {
                    supply?.supply_offers?.forEach((offer) => {
                      let appName = offer?.app_name;
                      let offerPrice = Number(offer?.offer_price || 0);
                      let variantPrice = Number(offer?.actual_price || 0);
                      let priceFlag = offer?.offer?.price_flag; // "amount" or "percentage"

                      let discountedPrice =
                        priceFlag === "amount"
                          ? variantPrice - offerPrice
                          : variantPrice - (offerPrice * variantPrice) / 100;

                      pricesDetails[appName] = {
                        name: appName,
                        actualPrice: variantPrice.toFixed(2),
                        price: Math.max(discountedPrice, 0).toFixed(2), // Ensure price never goes negative
                      };
                    });
                  });

                  return (
                    <tr key={idx}>
                      <td>
                        <div className="">
                          <div className="d-flex align-items-center  gap-3">
                            <img
                              src={product?.image}
                              alt="img"
                              className="productItemImg"
                            />
                            <div className="text-start">
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                {product?.name}
                              </h3>
                              {/* <div className="d-flex align-items-center">
                                {(() => {
                                  const variantAttributes =
                                    supply?.supply_variants?.[0]?.attributes;

                                  const attributes =
                                    typeof variantAttributes === "string"
                                      ? JSON.parse(variantAttributes)
                                      : variantAttributes || {};

                                  const color = attributes?.color || attributes?.Color;

                                  return (
                                    <>
                                      {color && (
                                        <span
                                          className="dot"
                                          style={{ backgroundColor: color }}
                                        ></span>
                                      )}
                                      <h6 className="fontSize12 fw400 txtYellow mb-0 ps-1">
                                        {Object.values(attributes).join(" / ")}
                                      </h6>
                                    </>
                                  );
                                })()}
                              </div> */}
                              {/* <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  ${product?.price}
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  ${pricesDetails?.["b2c"]?.price || actualPrice.toFixed(2)}
                                </h6>
                              </div> */}
                            </div>
                          </div>
                          <div className="d-flex align-items-start gap-4 mt-2">
                            <div className="d-flex align-items-start gap-3">
                              <h6 className="txtDarkblue fontSize12 ">POS</h6>
                              <h6 className="marketPrice fontSize12 ">
                                $
                                {addThousandSeparator(
                                  pricesDetails?.["pos"]?.actualPrice ||
                                    actualPrice.toFixed(2)
                                )}
                              </h6>
                              <h6 className="discountPrice fontSize12 mb-0">
                                $
                                {addThousandSeparator(
                                  pricesDetails?.["pos"]?.price ||
                                    actualPrice.toFixed(2)
                                )}
                              </h6>
                            </div>
                            <div className="d-flex align-items-start gap-3">
                              <h6 className="txtDarkblue fontSize12 ">B2C</h6>

                              <h6 className="marketPrice fontSize12 ">
                                $
                                {addThousandSeparator(
                                  pricesDetails?.["b2c"]?.actualPrice ||
                                    actualPrice.toFixed(2)
                                )}
                              </h6>
                              <h6 className="discountPrice fontSize12 mb-0">
                                $
                                {addThousandSeparator(
                                  pricesDetails?.["b2c"]?.price ||
                                    actualPrice.toFixed(2)
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex align-iten-center justify-content-center">
                        <h4 className="fontSize14 fw400 txtLightBlue mb-0 ps-1">
                          {t('noRecordfound')}
                        </h4>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OfferService;

