import { toast } from "react-toastify";
import { getColorName } from "../../../utilities/helpers";

function formValidator(obj, activePos, activeBtob, activeBtoc) {
  const sku = obj?.variants[0]?.sku || "";

  console.log(obj, "objyyyyy");

  if (!obj?.image) {
    toast.error("Please select product images");
    return false;
  }
  if (!obj?.name) {
    toast.error("Please enter product name");
    return false;
  }
  if (!obj?.service_id) {
    toast.error("Please select master category");
    return false;
  }
  if (!obj?.category_id) {
    toast.error("Please select category");
    return false;
  }
  if (!obj?.sub_category_id) {
    toast.error("Please select sub-category");
    return false;
  }
  if (!obj?.brand_id) {
    toast.error("Please select brand");
    return false;
  }
  if (!obj?.description) {
    toast.error("Please enter product description");
    return false;
  }

  if (!obj?.type) {
    toast.error("Please enter product type");
    return false;
  }
  if (!obj?.barcode) {
    toast.error("Please enter barcode");
    return false;
  }

  if (
    (obj?.barcode && obj?.barcode?.length < 11) ||
    (obj?.barcode && obj?.barcode?.length > 14)
  ) {
    toast.error("Please enter valid barcode");
    return false;
  }

  if (!sku) {
    toast.error("Please enter SKU");
    return false;
  }

  if ((sku && sku?.length < 11) || (sku && sku?.length > 14)) {
    toast.error("Please enter valid SKU");
    return false;
  }

  if (!obj?.cost_price) {
    toast.error("Please enter cost price");
    return false;
  }

  if (!obj?.delivery_options || obj?.delivery_options.length === 0) {
    toast.error("Please select delivery options");
    return false;
  }

  if (obj?.delivery_options.includes("shipping") > 0) {
    const { weight, length, breadth, height } = obj?.dimensions || {};

    if (!weight) {
      toast.error("Please enter product weight");
      return false;
    }

    if (!length) {
      toast.error("Please enter product length");
      return false;
    }

    if (!breadth) {
      toast.error("Please enter product breadth");
      return false;
    }

    if (!height) {
      toast.error("Please enter product height");
      return false;
    }
  }

  if (!obj?.seller_address_ids || obj.seller_address_ids.length === 0) {
    toast.error("Please select store");
    return false;
  }

  if (obj?.offer) {
    const { price_flag, actual_price, offer_price } = obj.offer;

    if (price_flag === "percentage") {
      const offerValue = parseFloat(offer_price);
      if (isNaN(offerValue) || offerValue < 1 || offerValue > 100) {
        toast.error("Percentage discount must be between 1 and 100");
        return false;
      }
    } else if (price_flag === "amount") {
      if (parseFloat(actual_price) < parseFloat(offer_price)) {
        toast.error("Discount amount cannot be greater than the actual price");
        return false;
      }
    }
  }

  const upcSet = new Set();

  if (!Array.isArray(obj.attributes) || obj.attributes.length <= 0) {
    for (const variant of obj.variants || []) {
      // Check each price object for missing selling_price
      for (const price of variant.prices || []) {
        if (
          price.app_name &&
          (!price.selling_price || String(price.selling_price).trim() === "")
        ) {
          toast.error(`Selling price is missing for ${price.app_name}`);
          return false;
        }
      }

      // Check for UPC
      if (!variant.barcode || variant.barcode.trim() === "") {
        toast.error("Please enter UPC");
        return false;
      }

      // Validate UPC length (11-14 characters)
      if (variant.barcode.length < 11 || variant.barcode.length > 14) {
        toast.error("Please enter a valid UPC ");
        return false;
      }
      if (!variant?.image || variant.image.trim() === "") {
        toast.error("Please upload an image");
        return false; // Halt further execution for this variant
      }
      const quantity = parseFloat(variant.quantity);
      const reorderPoint = parseFloat(variant.quantity_reorder_point);

      if (!quantity) {
        toast.error("please enter quantity");
        return false;
      }
      if (!reorderPoint) {
        toast.error("please enter quantity Re-order point");
        return false;
      }
      if (reorderPoint > quantity) {
        toast.error("Quantity Reorder Point cannot be greater than Quantity");
        return false;
      }
    }
  } else {
    for (const variant of obj.variants || []) {
      const formattedAttributes = Object.entries(variant.attributes)
        .map(([key, val]) =>
          val.startsWith("#") && /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(val)
            ? getColorName(val) // Convert hex color to name
            : val
        )
        .join(" / ");
      // Check each price object for missing selling_price
      for (const price of variant.prices || []) {
        console.log(
          "Selling price:",
          price.selling_price,
          "Type:",
          typeof price.selling_price
        );
        if (
          price.app_name &&
          (!price.selling_price || String(price.selling_price).trim() === "")
        ) {
          toast.error(
            `Selling price is missing for ${
              price.app_name
            } in variant: ${formattedAttributes}`
          );
          return false;
        }
      }

      // Check if at least one price object has a valid selling price
      const hasSellingPrice = variant.prices?.some(
        (price) =>
          price.selling_price && String(price.selling_price).trim() !== ""
      );

      if (!hasSellingPrice) {
        toast.error(
          `At least one selling price is required for variant: ${formattedAttributes}`
        );
        return false;
      }

      // Check for UPC
      if (!variant.barcode || variant.barcode.trim() === "") {
        toast.error(
          `Please enter UPC for variant: ${formattedAttributes}`
        );
        return false;
      }

      // Validate UPC length (11-14 characters)
      if (variant.barcode.length < 11 || variant.barcode.length > 14) {
        toast.error(
          `Please enter a valid UPC for variant: ${formattedAttributes}`
        );
        return false;
      }

      // Check for duplicate UPCs
      if (upcSet.has(variant.barcode)) {
        toast.error(`Duplicate UPC found: ${variant.barcode}`);
        return false;
      }

      const quantity = parseFloat(variant.quantity);
      const reorderPoint = parseFloat(variant.quantity_reorder_point);

      if (!reorderPoint) {
        toast.error(
          `please enter quantity Re-order point for variant: ${formattedAttributes}`
        );
        return false;
      }

      if (!quantity) {
        toast.error(
          `please enter quantity for variant: ${formattedAttributes}`
        );
        return false;
      }

      if (reorderPoint > quantity) {
        toast.error(
          `Quantity Reorder Point cannot be greater than Quantity for variant: ${formattedAttributes}`
        );
        return false;
      }

      // if (!variant?.image || variant.image.trim() === "") {
      //   toast.error(
      //     `Please upload an image for variant: ${JSON.stringify(
      //       variant.attributes
      //     )}`
      //   );
      //   return false; // Halt further execution for this variant
      // }

      // Add UPC to the set to check for duplicates
      upcSet.add(variant.upc);
    }
  }

  return true;
}
export { formValidator };
