import React, { useState } from "react";
import * as Images from "../../../../utilities/images";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateDocStatusApiAsync } from "../../myProducts/productSlice";

const DocumentTable = ({
  documentList,
  viewDocuments,
  currentPage,
  perPage,
  setSelectedDocument,
  loading,
  documentStatus,
  getDocuments,
}) => {
  const [loadingVerify, setLoadingVerify] = React.useState(false);
  const [loadingDecline, setLoadingDecline] = React.useState(false);
  const [buttonIndex, setButtonIndex] = useState(-1);

  const history = useHistory();
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDocumentStatus = (id, status) => {
    if (status === "2") setLoadingVerify(true);
    if (status === "1") setLoadingDecline(true);

    let params = {
      postData: {
        id: id,
        driver_verification_status: status,
      },
      tokenData: auth?.payload?.token,
    };

    dispatch(updateDocStatusApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        getDocuments();
      })
      .catch((obj) => {})
      .finally(() => {
        setLoadingVerify(false);
        setLoadingDecline(false);
        setButtonIndex(-1)
      });
  };

  return (
    <div className="commonTable consumerTable table-responsive mt-3">
      <table className="w-100">
        <thead>
          <tr>
            <th>#</th>
            <th className="textLeft">{"Drivers"}</th>
            <th>{"Phone Number"}</th>
            <th>{"Email"}</th>

            <th>{"Documents"}</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <>
              <tr>
                <td colSpan={11}>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="spinner-border spinner-border-md spinnerDark"></span>
                  </div>
                </td>
              </tr>
            </>
          ) : documentList?.length > 0 ? (
            documentList.map((val, idx) => {
              const user_details = val?.user_profiles;
              const fullname = [user_details?.firstname, user_details?.lastname]
                .filter(Boolean)
                .join(" ");
              const fullAddress = [
                user_details?.current_address?.city,
                user_details?.current_address?.state,
                user_details?.current_address?.country,
                user_details?.current_address?.zipcode,
              ]
                .filter(Boolean)
                .join(" ,");
              return (
                <tr
                  style={{
                    cursor: "pointer",
                  }}
                  key={idx}
                >
                  <td
                    className="fontSize12 txtLightBlue"
                    onClick={() =>
                      history.push({
                        pathname: "consumerProfile",
                        state: user_details,
                      })
                    }
                  >
                    {currentPage > 1
                      ? (currentPage - 1) * perPage + idx + 1
                      : idx + 1}
                  </td>
                  <td>
                    <div className="productAvailabilityChild align-items-center">
                      <div className="d-flex align-items-center gap-3 ">
                        <img
                          src={user_details?.profile_photo || Images.userImg_}
                          alt="img"
                          className="userInnerImg"
                        />
                        <div>
                          <h3 className="aboutProduct fw500 mb-1 text-start">
                            {fullname}
                          </h3>
                          <div className="flexBox gap-2">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            <h6 className=" fontSize12 fw500 mb-0 locationText">
                              {fullAddress}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="aboutProduct fw500">
                    {user_details?.full_phone_number}
                  </td>
                  <td className="aboutProduct fw500">{val?.email}</td>
                  <td className="aboutProduct fw500">
                    <button
                      className="viewTable_"
                      type="button"
                      onClick={() => {
                        setSelectedDocument(user_details);
                        viewDocuments();
                      }}
                    >
                      {"View"}
                    </button>
                  </td>
                  {documentStatus === 0 && (
                    <td className="aboutProduct fw500">
                      <div className="flexBox gap-2 justify-content-end">
                        <button
                          className="cancleAppointmentBtn tableDeclineBtn mr-6"
                          type="submit"
                          disabled={loadingVerify || loadingDecline}
                          onClick={() => {
                            handleDocumentStatus(val?.id, "1");
                            setButtonIndex(idx);
                          }}
                        >
                          {(loadingDecline&&buttonIndex==idx) && (
                            <span className="spinner-border spinner-border-sm me-1"></span>
                          )}
                          {t("decline")}
                        </button>

                        <button
                          className="commonBlueBtn tableAcceptBtn"
                          type="submit"
                          disabled={loadingVerify || loadingDecline}
                          onClick={() => {
                            handleDocumentStatus(val?.id, "2");
                            setButtonIndex(idx);
                          }}
                        >
                          {(loadingVerify&&buttonIndex==idx) && (
                            <span className="spinner-border spinner-border-sm me-1"></span>
                          )}

                          {"Verify"}
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="fontSize14 txtLightBlue" colSpan={11}>
                {t("noRecordFound")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentTable;
