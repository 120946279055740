import React, { useEffect, useId, useState } from "react";
import { Dropdown } from "react-bootstrap";
import * as Images from "../../../../utilities/images";
import { getAllCustomersApiAsync } from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
const AddMultiConsumers = ({ setgetMultipleUser,isB2b }) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [selectConsumers, setselectConsumers] = useState([]);
  const [loading, setLoading] = useState(false)
  const auth = useSelector(selectLoginAuth);
  const [selectedIds, setselectedIds] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);

  const [searchConsumer, setsearchConsumer] = useState("");
  const [allConsumers, setallConsumers] = useState([]);
  const dispatch = useDispatch();
  const [searchTimeOut, setsearchTimeOut] = useState(null);
  const handleSelectCosumers = (user,idx) => {
    let userId=user.user_id;
    if(!isB2b){
      setgetMultipleUser(userId);
      setselectedIds([useId]);
      setselectedUsers([user]);
      return;
    }
    if(selectedIds.includes(userId)){
      let filterIds=selectedIds.filter(id=>id!=userId);
      let filterUsers=selectedUsers.filter(users=>users.user_id!=userId);
      setselectedUsers(filterUsers);
      setselectedIds(filterIds);
      setgetMultipleUser(filterIds);
      console.log(filterIds)
    }else{
      setselectedIds([...selectedIds, userId]);
      setselectedUsers([...selectedUsers, user]);
      setgetMultipleUser([...selectedIds, userId]);
    }
  };

  //get all users
  const getAllConsumers = async () => {
    const prams = {
      postData: {
        search: searchConsumer || undefined,
        seller_id: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
        limit: 20,
        page: 1,
        filter: "year",
        
        type: "all_customers",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true)
    dispatch(getAllCustomersApiAsync(prams))
      .then(unwrapResult)
      .then((res) => {
        setLoading(false)
        setallConsumers(res?.payload?.data || []);
      })
      .catch((err) => {
        setLoading(false)
      });
  };
  useEffect(() => {
    if (searchConsumer === "") return;
    // Clear any existing timeout before setting a new one
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    // Set a new timeout to call getAllConsumers after 1500ms
    const searchTimer = setTimeout(() => {
      getAllConsumers();
    }, 1000);

    // Store the timeout ID in the state
    setsearchTimeOut(searchTimer);

    // Cleanup function to clear the timeout when the component unmounts or before the effect runs again
    return () => {
      clearTimeout(searchTimer);
    };
  }, [searchConsumer]);
  useEffect(() => {
    if (dropdownState) {
      getAllConsumers();
    }
  }, [dropdownState]);
  useEffect(()=>{},[])
  const handleDropDown = (value) => {
   
    setDropdownState(value);
  };

  return (
    <>
      <div className="rightMessBoxHeader">
        <div className="row  align-items-center">
          <div className="col-md-12">
            <div className="position-relative">
              <div className="input-group search_group inboxSearchInput">
                <img
                  src={Images.addNewUserImg}
                  alt="img"
                  className="img-fluid searchIcon"
                />
                <input
                  type="text"
                  onClick={() => handleDropDown(true)}
                  className="form-control ps-3"
                  placeholder="Add customer or type number"
                />
              </div>
              {dropdownState ? (
                
                <div className="filterDropdown_ addChatUserDropdown">
                  <div className="text-end pt-2 pb-4">
                    <h3
                      className="fontSize12 fw500 chatInboxMess mb-0 categoryDropClearTxt"
                      onClick={() => {
                        setDropdownState(false);
                      }}
                    >
                      Add
                    </h3>
                  </div>
                  <div className="search_group_parent">
                    <div className="input-group search_group mb-2">
                      <img
                        src={Images.SearchIcon}
                        alt=""
                        className="img-fluid searchIcon"
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here by name or number"
                        value={searchConsumer}
                        onChange={(e) => setsearchConsumer(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="listdropfilterList_ mt-3">
                    
                    <ul className="listItemsprodct_ m-0 p-0">
                    {
                      loading?<>
                      <li className="spinner-border text-primary" role="status">
                      </li>
                      </>:
                    allConsumers?.map((consumer, index) => (
                        <li
                          onClick={() => {
                            handleSelectCosumers(consumer,false);
                          }}
                          className={`selectProduct mb-1 ${
                            selectedIds.includes(consumer.user_id) && "active"
                          }`}
                          key={index}
                        >
                          <div className="flexBox justify-content-between gap-2">
                            <div className="productAvailabilityChild align-items-center gap-2">
                              <img
                                src={Images.teamMember4}
                                alt="img"
                                className="addUserImg"
                              />
                              <div>
                                <h3 className="fontSize14 fw500 txtDarkblue mb-1">
                                  {consumer?.user_details?.firstname +
                                    " " +
                                    consumer?.user_details?.lastname}
                                </h3>
                                <h3 className="fontSize10 txtDarkblue mb-0">
                                  {consumer?.user_details?.phone_number}
                                </h3>
                              </div>
                            </div>
                            {selectedIds.includes(consumer.user_id) && (
                              <img
                                src={Images?.newCheck}
                                alt="img"
                                className="checkIcon"
                              />
                            )}
                          </div>
                        </li>
                      ))}
                      {
                        allConsumers.length==0&&<>
                        <p className="text-center">No data found</p>
                        </>
                      }
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* {
              (!dropdownState)&& */}
              <div className="flexBox gap-2 chatHeaderAddUsers">
              {selectedUsers?.map((consumer, index) => (
                <div
                  onClick={() => {
                    handleSelectCosumers(consumer,true);
                  }}
                  className="addCustomersName mt-2"
                >
                  {consumer?.user_details?.firstname}
                  <img src={Images?.lightBlueCross} alt="img" />
                </div>
              ))}
            </div>
            {/* } */}

            {/* <div className="writeSubjectParent">
              <input
                className="customInput writeSubjectInput"
                placeholder="Write subject here"
                name="text"
                type="text"
              />
              <p className="textInnerHead2 writeSubjectPara pt-2 ps-4">
                Subject is very import for message, this will show on message
                threats. 100 characters maximum.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMultiConsumers;
