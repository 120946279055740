import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import { Link } from 'react-router-dom';
import Loader from '../../../components/UI/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { toast } from 'react-toastify';
import { configureQrCodeApiAsync, getQrCodeAsync } from '../../systemConfig/systemConfigSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

const TwostepscannerModal = (props) => {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState(props.qrCode);
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [code, setCode] = useState('')
  const dispatch = useDispatch()
  const getQrData = () => {
    let data = {
        tokenData: auth?.payload?.token,
    }
    setLoading(true)
    dispatch(getQrCodeAsync(data))
        .then(unwrapResult)
        .then((obj) => {
            setLoading(false)
            setQrCode(obj?.payload ? obj?.payload : {})
        }
        )
        .catch((obj) => {
            setLoading(false)
            toast.error("Unable to generate Qr Code");
        })
}
const handleVerification=()=>{
  if(code.length!=6){
    toast.error("Please enter a 6-digit code")
    return;
  }
  let data = {
    "tokenData": auth?.payload?.token,
    "postData": {
        "code": code,
    }
}
setLoading2(true)
dispatch(configureQrCodeApiAsync(data))
    .then(unwrapResult)
    .then((obj) => {
      setLoading2(false)
      props?.close();
      props?.closeParentModal()
        
    }
    )
    .catch((obj) => {
        setLoading2(false)
        // toast.error("Unable to verify code");
    })
}

  return (
    <div className='twostepscannerMain position-relative'>
      {
        loading?<Loader/>:<>
<div>
        <div
          className="mainqrbox_"
          style={{
            backgroundColor: "#FFFFFF",
            padding: "34px 28px 34px 23px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            textalign: "center",
            gap: "34px"
          }}
        >
          <div className="leftqrCode_">
              <img className='ordersetting mb-4' src={Images.googleauthenticator} alt='pay methode' />
                          <h3 className="appointMain">{t("twostepSecurity")}
            </h3>
            <p className="lighttextPara">
            {t("setpTwogoogleSAuth")}
            </p>
          </div>
        
            <div>
            {
                qrCode&&
                <img className='ordersetting mb-4' src={
                  qrCode?.qrCode
                } alt='pay methode' />
              }
            </div>
     
        </div>
        {/* show option */}
        <div className='w-100 mb-3 mt-3'>
          <label className='form-label ps-4'>{t("verifyCode")}</label>
           <input type="text" 
            value={code}
            onChange={(event)=>{
            setCode(event.target.value);
            }}
            className='customform-control ' placeholder={t('enterCode')} />
        </div>
                                
                                  
      </div>

      <div className="flexBox">
        <button
          className="modalDiscardBtn w-100"
          disabled={loading||loading2}
          onClick={() => {
            props?.close();
            props?.closeParentModal()
          }}
        >
          {t("discard")}
        </button>
        <button
        disabled={loading||loading2 ||!qrCode}
        onClick={handleVerification}
        
        className="modalNextBtn modalDiscardBtn active w-100">
          {loading2 && (
            <span className="spinner-border spinner-border-sm spinnerLight"></span>
          )}
          {t("verify")}
        </button>
      </div>
        </>
      }
      

    </div>
  )
}

export default TwostepscannerModal