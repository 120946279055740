import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../auth/authSlice";
import { getAllOrdersApiAsync } from "../../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { amountFormat } from "../../bookings/BookingCalendar/GlobalMethods";
import { DELIVERY_MODE } from "../../../constants/bookings/allbooking";
import PaginationHeader from "../../../components/PaginationHeader";
import { addThousandSeparator, shortenAddress } from "../../../../utilities/helpers";
import UsersHeader from "./UsersHeader";
import { useTranslation } from "react-i18next";

const UserRewardsPoint = () => {
  const { t } = useTranslation()
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [orderData, setOrderData] = useState([])
  const auth = useSelector(selectLoginAuth)
  const sellerUid = auth?.payload?.uniqe_id;
  const sellerID = auth?.payload?.user_profiles?.id;
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const dispatch = useDispatch();
  const [sellerAddress, setSellerAddress] = useState("")

  useEffect(() => {
    if (sellerUid) {
      let data = {
        tokenData: auth?.payload?.token,
        postData: {
          page: currentPage,
          // user_uid: sellerUid,
          limit: perPage,
          seller_id: sellerID,
          // store_location: storeSelected?.value,
          date: moment(startDate).format("YYYY-MM-DD"),
          ...(sellerAddress && {
            seller_address_id: sellerAddress
          })
        },
      };
      dispatch(
        getAllOrdersApiAsync(data)
      ).then(unwrapResult)
        .then(
          (result) => {
            setOrderData(result?.payload || {})
          }
        ).catch((err) => {

        });;
    }
  }, [
    sellerUid,
    startDate,
    currentPage,
    perPage,
    sellerAddress
  ]);

  return (  
    <>
      <div className="walletStats h-100">
        <div className="flexBox gap-2">
          <img
            src={Images.backArrow}
            alt="img"
            className="cursorPointer"
            onClick={() => {
              history.goBack();
            }}
          />
          <h3 className="fontSize24 fw500 mb-0 txtDarkblue">
            User Reward points{" "}
          </h3>
        </div>
        <div className="row align-items-center mt-3">
        <UsersHeader
            setSellerAddress={setSellerAddress}
            // selectOption={selectOption}
            startDate={startDate}
            setStartDate={setStartDate}
            headerClass="col-xxl-4 col-xl-6"
            />

          <PaginationHeader
            currentPage={currentPage}
            totalPage={orderData?.total_pages}
            perPage={perPage}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            headerClass="col-xxl-8 col-xl-6"
            dataLength={orderData?.total}
          />
      </div>
        <div className="commonTable consumerTable table-responsive mt-3">
          <table className="w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>{t("invoiceId")}</th>
                <th>{t("date")}</th>
                <th>{t("location")}</th>
                <th>{t("buyingAmount")}</th>
                <th>{t("redeemPoints")}</th>
                <th>{t("status")}</th>
              </tr>
            </thead>
            <tbody>
              {orderData?.data?.length > 0 && orderData?.data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td className="fontSize12 txtLightBlue">{idx + 1}</td>

                    <td className="aboutProduct fw500">{item?.id}</td>
                    <td className="aboutProduct fw500">
                      {moment(item?.created_at).format(
                        "YYYY-MM-DD"
                      )}
                    </td>
                    <td className="aboutProduct fw500">
                      {
                        shortenAddress(item?.user_details?.current_address?.street_address, 15) || "____"
                      }
                    </td>
                    <td className="aboutProduct fw500">
                      {amountFormat(item?.payable_amount)}
                    </td>
                    <td className="aboutProduct fw500">
                      {addThousandSeparator(item?.reward_points * 100)}
                    </td>
                    <td className="aboutProduct fw500">
                      <h3 className="shippingbox" style={{
                        display: "inline-block",
                        padding: "5px 10px",
                        borderRadius: "30px",
                        color:
                          DELIVERY_MODE[item?.delivery_option] ===
                            "Delivery"
                            ? "#7233C2"
                            : DELIVERY_MODE[item?.delivery_option] ===
                              "Shipping"
                              ? "#308CAD"
                              : "#4659B5",
                        backgroundColor:
                          DELIVERY_MODE[item?.delivery_option] ===
                            "Delivery"
                            ? "#F5EDFF"
                            : DELIVERY_MODE[item?.delivery_option] ===
                              "Shipping"
                              ? "#BFEEFF99"
                              : "#E4E6F299",
                      }}>
                        {DELIVERY_MODE[item?.delivery_option]}
                      </h3>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UserRewardsPoint;
