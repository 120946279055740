import React from 'react'

const ProductCard = (props) => {
  return (
    <div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-md-0" onClick={props?.push}>
    <div className= {`percentageContainer ${props?.className}`}>
      <img src={props?.img} alt="img" />
      <div className="percentageValues homeBoxesValues">
        <p className="textInner">{props?.heading}</p>
        <h3 className="heading_ ">{props?.value}</h3>
      </div>
    </div>
  </div>
  )
}

export default ProductCard
