import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordApiAsync, selectLoginAuth } from "../../../containers/auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { changePasswordValidationSchema } from "../../../validations/modals.validation"
import { useTranslation } from "react-i18next";


const CreateNewPassModal = (props) => {
  const { t } = useTranslation();
  console.log(props, "propssss")
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [passwordDetails, setPasswordDetails] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  })
  //check if password is matching or not
  const handleSubmit = async (values) => {
    if(loading)return;
    
    //call your server to update password
    setLoading(true);
    const params = {
      token: auth?.payload?.token,
      postData: {
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_password: values.confirm_password
      }
    }
    dispatch(changePasswordApiAsync(params))
      .then(unwrapResult)
      .then(res => {
        toast.success("Password updated successfully");
        setLoading(false);
        props.close();
        props?.getUserPosDetails()
      })
      .catch(err => {
        toast.error("Failed to update password");
        setLoading(false);
      })

  }
  useEffect(() => {
    setPasswordDetails({
      ...passwordDetails,
      old_password: props?.old_password ||''
    })
  }, [props?.old_password]);
  const handlePassword = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setPasswordDetails({ ...passwordDetails, [name]: value });
  }
  return (
    <>
      <div className="resetPassForm">
        <div className="loginForm text-start">
          <div className="loginhead position-relative">
            <h4 className="fontSize36 txtDarkblue">{props.flag == "createNewPassModal" ? "Update Password" : "Create New Password"}</h4>
            {props.flag == "createNewPassModal" && (
              <img src={Images.modalCross} alt="img" className="modalCloseBtn cursorPointer" onClick={() => {
                props?.close();
              }}/>
            )}
            <p className="fontSize18 txtLightBlue mb-0  pt-2">
                {t('previousPasswordTitle')} <br />{t("previousPassword")}
            </p>
          </div>
          <Formik
          initialValues={passwordDetails}
          enableReinitialize={true}
          validationSchema={changePasswordValidationSchema}
          onSubmit={handleSubmit}
          >
          {({ values, handleChange, touched, errors }) => (
          <Form autoComplete="nope" className="form_horizontal w-100">
            <div className=" form-group">
              <label className="fontSize14 fw500 txtDarkblue pb-2  ps-4">{t('newPassword')}</label>
              <Field
                className="customform-control id_password"
                placeholder="Password"
                autoComplete="new-password"
                type={passwordShown1 ? "text" : "password"}
                value={values.new_password}
                name="new_password"
                onChange={handleChange}
              />
              <img
                src={Images.passIcon}
                alt="passIcon"
                className="passIcon resetFormPassIcon"
              />
              <span
                className="toggle_password_ info_icon"
                onClick={() => {
                  setPasswordShown1((prev) => !prev);
                }}
              >
                <span
                  className={
                    passwordShown1
                      ? "show-icon togglePassword"
                      : "hide-icon togglePassword"
                  }
                ></span>
              </span>
              <ErrorMessage name="new_password" component="div" className="error-message" />

            </div>
            <p className="fontSize14 txtLightBlue ps-4 pt-2 mb-4">
              {t('newPasswordTitle')}{" "}
              <br /> end with a space.
            </p>
            <div className="mb-1 form-group">
              <label className="fontSize14 fw500 txtDarkblue pb-2  ps-4">{t('confirmPassword')}</label>
              <Field
                className="customform-control id_password"
                placeholder="Password"
                autoComplete="new-password"
                type={passwordShown2 ? "text" : "password"}
                value={values.confirm_password}
                name="confirm_password"
                onChange={handleChange}
              />
              <img
                src={Images.passIcon}
                alt="passIcon"
                className="passIcon resetFormPassIcon"
              />
              <span
                className="toggle_password_ info_icon"
                onClick={() => {
                  setPasswordShown2((prev) => !prev);
                }}
              >
                <span
                  className={
                    passwordShown2
                      ? "show-icon togglePassword"
                      : "hide-icon togglePassword"
                  }
                ></span>
              </span>
              <ErrorMessage name="confirm_password" component="div" className="error-message" />

            </div>

            <div className="modalfooterBtn align-items-center">
              {props.flag == "createNewPassModal" ? "" : <button
                type="button"
                disabled={loading}
                className="modalDiscardBtn w-100 flexBox gap-2 justify-content-center"
                onClick={() => {
                  props?.close();
                }}
              >
                {t('cancel')}
              </button>}



              <button
                type="submit"
                disabled={loading}
                className={`modalNextBtn modalDiscardBtn w-100 flexBox gap-2 justify-content-center  ${props.flag == "createNewPassModal" ? "ms-0" : "" } ${(values.new_password.length&& values.confirm_password==values.new_password)?'active':''}`}
              >

                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}

                {t('createPassword')}
                <img
                  src={false ? Images.closeTag : Images.arrowTopRight}
                  alt="img"
                />
              </button>
            </div>
          </Form>
          )}
          </Formik>

        </div>

      </div>
    </>
  );
};

export default CreateNewPassModal;
