import React, { forwardRef, useState } from 'react'
import * as Images from "../../../../utilities/images";
import ReactSelect from "react-select";
import PaginationHeader from '../../../components/PaginationHeader';
import CustomModal from '../../bookings/BookingCalendar/CustomModal';
import UploadImageModal from './Modal/UploadImageModal';


const FolderDocument = () => {
    const [key, setKey] = useState(Math.random());



    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };
    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            title: "",
            flag: flag,
        });
        setKey(Math.random());
    };

    const selectOption = [
        { value: "all", label: "All Channels" },
        { value: "pos", label: "Checkout" },
        { value: "b2c", label: "Market" },
        { value: "b2b", label: "Trade" },
    ];

    return (
        <>
            <div className="walletStats h-100">
                <div className="catalogIndexHead d-flex align-items-center justify-content-between">
                    <div className='d-flex'>
                    <img className='me-2 cursorPointer' src={Images.backArrow} alt="" />
                    <h3 className="fontSize24 txtDarkblue fw500 mb-0">
                        Folder/Document
                    </h3>
                    </div>
                    <button className='commonBlueBtn active' onClick={() => handleOpenModal("uploadCatalogImgModal")}>
                        Upload File <img className='ms-1' src={Images.UploadSquarecatalogImg} alt="" />
                    </button>
                </div>
                <div className="row align-items-center mt-4 mb-2">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-6 pe-0 ps-4">
                                <div className="reactSelectParent offerHeaderSelect consumerHeaderSelect">
                                    <ReactSelect
                                        options={selectOption}
                                        placeholder="Select One"
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                minWidth: "120px",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",
                                                color: state.isSelected ? "#fff" : "#636E9F",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.blueDownArrow}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="reactSelectParent offerHeaderSelect consumerHeaderSelect">
                                    <ReactSelect
                                        options={selectOption}
                                        placeholder="Select One"
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                minWidth: "120px",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",
                                                color: state.isSelected ? "#fff" : "#636E9F",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.blueDownArrow}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <PaginationHeader
                        currentPage={0}
                        totalPage={10}
                        perPage={15}
                        setCurrentPage={1}
                        setPerPage={10}
                        headerClass="col-md-8"
                    />
                </div>
                <div className="commonTable table-responsive catalRecentTable">
                    <table className="w-100">
                        <thead>
                            <tr>
                                <th className="text-start fw600 ps-4">File Name</th>
                                <th className="fw600">Folder</th>
                                <th className="fw600">Last Modify</th>
                                <th className="fw600"> File Size</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr className="text-center border-none">
                                    <td colSpan="100%">
                                      <div className="d-flex">
                                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                                      </div>
                                    </td>
                                  </tr>
                                   */}
                            <tr>
                                <td className="orderTrackTableTxt text-start ">
                                    Jobr sales doc
                                </td>
                                <td className="orderTrackTableTxt">Documents</td>
                                <td className="orderTrackTableTxt">21 Jun, 2025 | Henderson</td>
                                <td className="orderTrackTableTxt">236kb</td>
                                <td
                                    className="orderTrackTableTxt cursorPointer"
                                >
                                    <img src={Images.catFolBoxMenuImg} alt="img" />
                                </td>
                            </tr>
                            <tr>
                                <td className="orderTrackTableTxt text-start ps-4">
                                    Jobr sales doc
                                </td>
                                <td className="orderTrackTableTxt">Documents</td>
                                <td className="orderTrackTableTxt">21 Jun, 2025 | Henderson</td>
                                <td className="orderTrackTableTxt">236kb</td>
                                <td
                                    className="orderTrackTableTxt cursorPointer"
                                >
                                    <img src={Images.catFolBoxMenuImg} alt="img" />
                                </td>
                            </tr>
                            <tr>
                                <td className="orderTrackTableTxt text-start ps-4">
                                    Jobr sales doc
                                </td>
                                <td className="orderTrackTableTxt">Documents</td>
                                <td className="orderTrackTableTxt">21 Jun, 2025 | Henderson</td>
                                <td className="orderTrackTableTxt">236kb</td>
                                <td
                                    className="orderTrackTableTxt cursorPointer"
                                >
                                    <img src={Images.catFolBoxMenuImg} alt="img" />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "uploadCatalogImgModal" ? "uplCatalogImgModal" : ""
                }
                child={
                    modalDetail.flag === "uploadCatalogImgModal" ? (
                        <UploadImageModal
                            close={() => handleOnCloseModal()}
                        />
                    ) : (
                        ""
                    )
                }
                header={
                    modalDetail.flag === "uploadCatalogImgModal" ? (
                        <>
                            <div>
                                <h4 className="fonstSize24 fw500 txtDarkblue mb-0">Upload Image</h4>
                            </div>
                            <img className="ms-auto cursorPointer" src={Images.modalCross} alt="img" onClick={() => handleOnCloseModal()} />

                        </>
                    ) : (
                        ""
                    )
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default FolderDocument