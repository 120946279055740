import React, { useState } from "react";
import {
  OrderDown,
  cancleorder,
  incoiceuser_,
  modalCancel,
  pickuporder,
  profileImg1,
  returnlogo,
  serviceuser_,
  unselectedOrder,
  drivefast_,
  upArrow,
  locateOrder,
  clockBlue,
  moneyImg,
  mapimg,
  deliveryMap,
  warningicon,
  fillradio_,
  verifyorder,
  orderDriver,
  done,
  cancelledstep,
  readystep,
  acceptstep,
  openTag,
  closeTag,
  jobrMainLogo,
  orderStartImg,
  productPickupImg,
  orderCancleImg,
  orderAcceptedImg,
  readyToPickupImg,
  orderAcceptDot,
  orderTrackingLine,
  deliverBox,
  userImg_,
} from "../../../../utilities/images";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import * as Images from "../../../../utilities/images";
import { formatMilliseconds, parseStatusDesc } from "../../../../utilities/globalMethods";
const OrderTrackStatusCard = ({ orderDetails, getAllOrderDetails }) => {
  console.log(orderDetails,"Check this")
  const { t } = useTranslation();
  const [hideOrderStatus, setHideOrderStatus] = useState(true);
  let delivery_option = orderDetails?.delivery_option;
  let status = orderDetails?.status;

  const handleOrderStatusView = () => {
    setHideOrderStatus((prev) => !prev);
  };
  let accepetdDate = parseStatusDesc(orderDetails?.status_desc)?.accepetdDate;
  let pickedupDate = parseStatusDesc(orderDetails?.status_desc)?.pickedupDate;
  const arrivesIn =
                  new Date(pickedupDate ?? 0).getTime() -
                  new Date(accepetdDate ?? 0).getTime();
  return (
    <>
      <div className="deliveryStatus">
        <div className="statusHead stausDown" style={{border:"none"}}>
          <div className="statusLeft orderFlex">
            <img
              alt="orderDriver Image"
              loading="lazy"
              width="24"
              height="25"
              decoding="async"
              data-nimg="1"
              className="img-fluid cursorPointer"
              src={orderDriver}
              style={{ color: "transparent" }}
            />
            <div>
            <h4 className="customerLink mb-0">{t('orderStatus')}</h4>
            
          {formatMilliseconds(arrivesIn || 0)!="0 mins"&&  <p className="orderTrackLocation orderTrackingTime fontSize12 fw500 mb-0">{formatMilliseconds(arrivesIn || 0)}</p>}
            </div>
          </div>
          <figure>
            <img
              onClick={handleOrderStatusView}
              src={hideOrderStatus ? openTag : closeTag}
              alt="downSelect"
              className="cursorPointer"
            />
          </figure>
        </div>
        {hideOrderStatus ? (
          <div className="orderStatus ">

            {orderDetails?.order_delivery &&
              delivery_option == "1" &&
              (orderDetails?.order_delivery?.has_returned ||
                orderDetails?.is_returned_order
              ) && (
                <div className="readyPickupstatus pickupBtnDetails">
                  <div className="readyPickupstatus">
                    <div className="positionImg">
                      <img
                        src={productPickupImg}
                        style={{ width: "21px" }}
                        alt="pickup"
                      />
                      <img
                        src={orderTrackingLine}
                        alt="img"
                        className="lineStepImg"
                      />
                    </div>
                    <article>
                      <h3>{t('returned')}</h3>
                      {orderDetails?.order_delivery?.updated_at ? (
                        <h6>
                          {moment(orderDetails?.order_delivery?.updated_at).format(
                            "DD MMM YYYY | HH:mm"
                          )}
                        </h6>
                      ) : (
                        ""
                      )}
                    </article>
                  </div>
                  {orderDetails?.order_delivery?.cancel_order_otp ? (
                    <div className="pickupBtn">
                      <img
                        src={deliverBox}
                        style={{ width: "21px" }}
                        alt="pickup"
                      />
                      <button className="countbtn">
                        {orderDetails?.order_delivery?.cancel_order_otp}
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}

            <div className="readyPickupstatus">
              <div className="positionImg">
                <img
                  src={orderDetails?.order_delivery?.has_returned?Images.crossCircle  :productPickupImg}
                  style={{ width: "21px" }}
                  alt="pickup"
                />
                <img
                  src={orderTrackingLine}
                  alt="img"
                  className="lineStepImg"
                />
              </div>

              <article>
                <h3>{orderDetails?.delivery_option == "3" ? t("completed") : t("delivered")}</h3>
                {orderDetails?.status_desc?.status_5_updated_at ? (
                  <h6>
                    {moment(
                      orderDetails?.status_desc?.status_5_updated_at
                    ).format("DD MMM, YYYY | hh:mm a")}
                  </h6>
                ) : (
                  <></>
                )}
              </article>
            </div>

            {orderDetails?.order_delivery &&
              orderDetails?.delivery_option == "1" &&
              orderDetails?.order_delivery?.cancel_order_otp &&
              !orderDetails?.order_delivery?.has_returned && (
                <div className="readyPickupstatus pickupBtnDetails">
                  <div className="readyPickupstatus">
                    <div className="positionImg">
                      <img
                        src={
                          productPickupImg
                        }
                        alt="pickup"
                      />
                      <img
                        src={
                          orderTrackingLine
                        }
                        alt="pickup"
                      />
                    </div>
                    <article>
                      <h3>{t('returned')}</h3>
                      {orderDetails?.order_delivery?.updated_at ? (
                        <h6>
                          {moment(orderDetails?.order_delivery?.updated_at).format(
                            "DD MMM YYYY | HH:mm A"
                          )}
                        </h6>
                      ) : (
                        ""
                      )}
                    </article>
                  </div>
                  {orderDetails?.order_delivery?.cancel_order_otp ? (
                    <div className="pickupBtn">
                      <button className="countbtn">
                        {orderDetails?.order_delivery?.cancel_order_otp}
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}





            <div className="readyPickupstatus">
              <div className="positionImg">
                <img
                  src={
                    orderDetails?.status >= 4 && orderDetails?.status != 8
                      ? productPickupImg
                      : orderStartImg
                  }
                  alt="verifyorder"
                />
                <img
                  src={orderTrackingLine}
                  alt="img"
                  className="lineStepImg"
                />
              </div>
              <div>
                <h3>{t('productPickup')}</h3>
                {orderDetails?.status >= 4 && orderDetails?.status != 8 && (
                  <h6>
                    {moment(orderDetails?.status_desc?.status_4_updated_at).format(
                      "DD MMM, YYYY | hh:mm a"
                    )}
                  </h6>
                )}
              </div>
              {(
                orderDetails?.status == "4" &&
                orderDetails?.delivery_option == "3"
              ) && (
                  <div className="pickupBtn">
                    <button className="countbtn">
                      {orderDetails?.order_delivery?.seller_otp}
                    </button>
                  </div>
                )}
            </div>

            {/* Assign driver */}
            {orderDetails?.delivery_option != 3 ? (
              <div className="readyPickupstatus">
                <div className="positionImg">
                  <img
                    src={
                      readyToPickupImg
                    }
                    alt="pickup"
                  />
                  <img
                    src={orderTrackingLine}
                    alt="img"
                    className="lineStepImg"
                  />
                </div>
                <article>
                  <h3>{t('assignDriver')}</h3>
                  <h6>
                    {moment(orderDetails?.status_desc?.status_1_updated_at).format(
                      "DD MMM, YYYY | hh:mm a"
                    )}
                  </h6>
                </article>
              </div>
            ) : (
              <></>
            )}

            <div className="readyPickupstatus">
              <div className="positionImg">
                <img
                  src={
                    readyToPickupImg
                  }
                  alt="pickup"
                />
                <img
                  src={orderTrackingLine}
                  alt="img"
                  className="lineStepImg"
                />
              </div>
              <div>
                <h3>{t('readyToPickup')}</h3>
                {orderDetails?.status_desc?.status_3_updated_at ? (
                  <h6>
                    {moment(
                      orderDetails?.status_desc?.status_3_updated_at
                    ).format("DD MMM, YYYY | hh:mm a")}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* order accepted */}
            <div className="readyPickupstatus">
              <div>
                <img
                  src={
                    orderAcceptedImg
                  }
                  alt="pickup"
                />
              </div>
              <div>
                <h3>{t('orderAccepted')}</h3>
                {orderDetails?.status_desc?.status_1_updated_at ? (
                  <h6>
                    {moment(
                      orderDetails?.status_desc?.status_1_updated_at
                    ).format("DD MMM, YYYY | hh:mm a")}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
            </div>

          </div>
        ) : <>
          
      </>}
      {
        orderDetails?.delivery_option==1&&<div className="driver_status_details">
        <div className="shipcontactLeft">
          <img className='me-2 rounded-circle' style={{width:"38px",height:"38px"}} src={orderDetails?.driver_details?.profile_photo|| userImg_} />
          <div className="contctInfo">
            <h3 className="orderTrackTableTxt">
                    {`${orderDetails?.driver_details?.firstname??"---"} ${orderDetails?.driver_details?.lastname??"---"}`}
                  </h3>
                   <h6 className="orderTrackLocation fontSize12 fw500 mb-0" style={{
                    width:"100%"
                   }}>
                    <img
                      src={Images.locateOrder}
                      alt="img"
                      className="location"
                    />
                    {orderDetails?.address ??""}
                  </h6>
          </div>
        </div>
        
      </div>
      }
    </div >
    </>
  );
};

export default OrderTrackStatusCard;
