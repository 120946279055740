import React from 'react'
import * as Images from "../../../../utilities/images";

const BankdetailModal = ({ selectedBank }) => {
    return (
        <div className='bankdetailsModal'>
            <div className='detailsMiddle_'>
                <div className='detailhead_'>
                    <img src={selectedBank?.image || Images.bankIcon_} alt="img" />
                    <h3 className="fontSize24 fw500 txtDarkblue mb-3 ">{selectedBank?.bank_name}</h3>
                </div>
                <div className='detailinfomation_'>
                    <h5 className='headlight16_ bglightColor_ m-0'><span className='nametitle_'>Bank Name:</span> <span>{selectedBank?.bank_name}</span></h5>
                    <h5 className='headlight16_ bglightColor_ bgtrans_ m-0'><span className='nametitle_'>Account Number:</span> <span>{selectedBank?.account}</span></h5>
                    <h5 className='headlight16_ bglightColor_ m-0'><span className='nametitle_'>Account Name:</span> <span>{selectedBank?.account_holder_name || "____"}</span></h5>
                    <h5 className='headlight16_ bglightColor_ bgtrans_ m-0'><span className='nametitle_'>Routing Number:</span> <span>{selectedBank?.routing_number}</span></h5>
                    <h5 className='headlight16_ bglightColor_ m-0'><span className='nametitle_'>Bank City/State:</span> <span>{selectedBank?.city || selectedBank?.state || selectedBank?.country || selectedBank?.country || "____"}</span></h5>
                    <h5 className='headlight16_ bglightColor_ bgtrans_ m-0'><span className='nametitle_'> Address line :</span> <span>{selectedBank?.address_line || "____"}</span></h5>
                </div>
                {/* <button className='skyblueButton_ mt-5' type='submit'>Disconnect</button> */}
            </div>
        </div>
    )
}

export default BankdetailModal