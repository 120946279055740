import React, { useEffect, useState } from 'react'
import { viewEye_, calendar, totalOrder, volume, analysis, grossProfit, profitMargin, totalvolumeicon, grossgreenIcon, totalprofitMargin, totalUnitSold } from '../../../utilities/images';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import products from '../../db/category.json'
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import moment from 'moment-timezone';
import { totalAnalyticsProductSoldApiAsync } from '../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import AnalyticsHeader from './AnalyticsHeader';
import PaginationHeader from './PaginationHeader';
import { addThousandSeparator } from '../../../utilities/helpers';
import { useTranslation } from 'react-i18next';

const TotalProductSold = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const today = new Date();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [totalProductSoldAnalyticsData, setTotalProductSoldAnalyticsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [calanderDate,setCalanderDate] = useState(new Date())

    const handelPerPageResult = (e) => {
        setPerPage(e?.value)
    }

    const handlePageChange = (code) => {
        if (code === "+") {
            // setPerPage((prev => prev > 0 ? prev + 10 : prev))
            setCurrentPage((prev => prev >= 1 ? prev + 1 : prev))
        } else {
            // setPerPage((prev => prev > 10 ? prev - 10 : prev))
            setCurrentPage((prev => prev > 1 ? prev - 1 : prev))
        }
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    
    const totalProductSoldAnalyticsHandle = () => {
        let params = {
            "postData": {
                filter: filterValue,
                channel: channelFilter,
                page: currentPage,
                limit: perPage,
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(totalAnalyticsProductSoldApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setTotalProductSoldAnalyticsData(obj?.payload)
                setLoading(false)
                setTotalRows(obj?.payload?.totalProductSoldList?.total)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        totalProductSoldAnalyticsHandle()
    }, [filterValue, channelFilter, endDate, currentPage, perPage])
    return (
        <>
            <DashboardNavbar title={t('analytics')} backShow="/analytics" />
            <div className='analyticProductSold analyticGross chartsOuter'>
                <AnalyticsHeader
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onTimeSpanSelect={setFilterValue}
                    calanderDate={calanderDate}
                    setCalanderDate={setCalanderDate}
                    filterValue={filterValue}
                    setChannelFilter={setChannelFilter}
                    channelFilter={channelFilter}
                    subhead="vs. Same day last week"
                    onDateChange={handleDateChange}
                    timeSpan={filterValue}
                    header={t('totalProductSold')}
                />
                <div className='grossProfitData'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6  pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalUnitSold} className='img-fluid grossImages' alt='total orderimage' />
                                <p className='groSub mt-4 mb-0'>{t('unitsSold')}</p>
                                <h4 className='groMain'>{addThousandSeparator(totalProductSoldAnalyticsData?.totalProductSoldList?.total ? totalProductSoldAnalyticsData?.totalProductSoldList?.total : 0)}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalvolumeicon} className='img-fluid grossImages' alt='volumeImage' />
                                <p className='groSub mt-4 mb-0'>{t('totalVolume')}</p>
                                <h4 className='groMain'>{totalProductSoldAnalyticsData?.productOverview?.totalVolume ? `$${addThousandSeparator((totalProductSoldAnalyticsData?.productOverview?.totalVolume)?.toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={totalprofitMargin} className='img-fluid grossImages' alt='profitmargin-image' />
                                <p className='groSub mt-4 mb-0'>{t('profitMargin')}</p>
                                <h4 className='groMain'>{totalProductSoldAnalyticsData?.productOverview?.totalMargin ? `$${addThousandSeparator((totalProductSoldAnalyticsData?.productOverview?.totalMargin)?.toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 '>
                            <div className='grossTotalorder'>
                                <img src={grossgreenIcon} className='img-fluid grossImages' alt='grossProfit-image' />
                                <p className='groSub mt-4 mb-0'>{t('grossProfit')}</p>
                                <h4 className='groMain'>{totalProductSoldAnalyticsData?.productOverview?.totalProfit ? `$${addThousandSeparator((totalProductSoldAnalyticsData?.productOverview?.totalProfit)?.toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <PaginationHeader
                    perPage={perPage}
                    handelPerPageResult={handelPerPageResult}
                    currentPage={currentPage}
                    totalPage={totalRows}
                    setCurrentPage={setCurrentPage}
                    totalLength={totalProductSoldAnalyticsData?.totalProductSoldList?.data?.total}
                />
                {
                    loading ? (
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                    ) : (
                        <div className="commonTable productTable table-responsive mt-3">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('productName')}</th>
                                        <th>{t('upc')}</th>
                                        <th>{t('price')}</th>
                                        <th>{t('inStock')}</th>
                                        <th>{t('lastSoldDate')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {totalProductSoldAnalyticsData?.totalProductSoldList?.data?.length > 0 && (
                                        totalProductSoldAnalyticsData?.totalProductSoldList?.data.map(
                                            (row, idx) => (
                                                <tr>
                                                    <td className="fontSize12 txtLightBlue">
                                                    {currentPage > 1 ? (
                                                        (currentPage - 1) * perPage + idx + 1
                                                        ) : idx + 1}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.product_name}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.product_upc}
                                                    </td>

                                                    <td className="fontSize12 grosshead_">
                                                        ${row?.total_price ? addThousandSeparator((row?.total_price)?.toFixed(2)) : "0"}
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        {row?.in_stock_qty}
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        {moment(row.last_sold_date).format('MM/DD/YYYY')}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default TotalProductSold