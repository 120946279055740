import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { Stepper } from 'react-form-stepper';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CampainDetails from './CampainDetails';
import MakePayment from './MakePayment';
import { unwrapResult } from '@reduxjs/toolkit';
import { createCampaignApiAsync } from '../../dashboard/dashboardSlice';
import { excelFileUploadApiAsync, selectLoginAuth } from '../../auth/authSlice';
import { imageUploadAsync } from '../../message/messageSlice';
import moment from 'moment-timezone';
import { checkIfFieldEmpty } from '../../../constants/common.constant';
import { selectMarketingData, updateCampaignApiAsync } from '../marketingSlice';
import { useTranslation } from 'react-i18next';


const CreateCampaign = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(1);
    const auth=useSelector(selectLoginAuth)
    const history=useHistory();
    const { id } = useParams();
    const [excelFile, setExcelFile] = useState(null);
    const [showError, setShowError] = useState(false);
    const marketingData = useSelector(selectMarketingData);
    const campaignList = marketingData?.campaignData;
    const [isUpdating, setIsUpdating] = useState(false)
    let campaign=campaignList?.data?.filter(campaign=>campaign?.id==id)?.[0];
    console.log(campaign,"campaign")
    const [formData, setFormData] = useState({
        name:campaign?.name??"",
        campaign_for:campaign?.campaign_for?? "store",
        promotion_type:campaign?.promotion_type?? "",
        content:campaign?.content??"",//either content or images
        images:campaign?.images
        ?JSON.parse(campaign?.images):null,//either content or images
        min_age:campaign?.min_age?? 18,
        max_age:campaign?.max_age?? 26,
        location:campaign?.location??"",
        city:campaign?.city??"",
        postal_code:campaign?.postal_code?? "160071",
        latitude:campaign?.latitude?? 32.432,
        longitude:campaign?.longitude?? 73.34984,
        radius:campaign?.radius?? "",
        duration:campaign?.duration?? "",
        start_date:campaign?.start_date?new Date(campaign?.start_date):"",
        end_date:campaign?.end_date?new Date(campaign?.end_date):"",
        notifications_count:campaign?.notifications_count?? "",
        sms_count:campaign?.sms_count?? "",
        service_id:(campaign?.service_id||campaign?.product_id) ??"",
        category_id:campaign?.category_id?? "",
        sub_category_id:campaign?.sub_category_id?? "",
        serviceType:campaign?.service_id?"service": "product",
        contactList:null,
        brand_id:campaign?.brand_id??""
    });
    console.log(formData,"formData")
    const [formFields, setFormFields] = useState({})
    const handleStepChange = (step) => {
        setActiveStep(step);
      };
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Campaign";
    }, []);
    
    const handleSteps=()=>{
        if(activeStep==1){
            history.push("/campaignIndex")
        }else{
            setActiveStep(1);
        }
    }
    const handleNextSteps=()=>{
        console.log(formData,"formData")
       if(activeStep==1){
        let fields=validFields(formData);
        let isFiledEmpty=checkIfFieldEmpty(fields);
        if(isFiledEmpty){
            setShowError(true);
            toast.error(`${isFiledEmpty} is required`);
            return;
        }
        setFormFields(fields);
        setActiveStep(2);
    }
       else{
        handleSubmit();
       }
    }
    const handleSubmit=async()=>{
        try {
            setShowError(false);
            setLoading(true);
            if(formFields.promotion_type=="top_banner"&&formFields.images){
                let url=await uploadImage(formFields.images);
                if(!url){
                    toast.error("Unable to upload images")
                    return;
                }
                formFields.images=url;
            }
            if(formFields.campaign_for=="sms"&&formFields.contactList){
              try {
                let fileDetails=await  uploadExcelFile(formFields.contactList);
                if(!fileDetails){
                    toast.error("Unable to upload contact file")
                    return;
                }
                formFields.file=formData?.contactList?.name??"";
                delete formFields.contactList;
                formFields.contact_reference_id=fileDetails;
              } catch (error) {
                
              }
            }
            
            const params={
                postData:{
                    ...formFields,
                    ...((formFields.campaign_for=="store"||formFields.campaign_for=="product")&&{
                        start_date:moment(formFields?.start_date).format("YYYY-MM-DD"),
                    end_date:moment(formFields?.end_date).format("YYYY-MM-DD"),
                    }),
                    payment_method_id:formData?.payment_method_id
                },
                tokenData:auth?.payload?.token
            }
            const data=await dispatch(createCampaignApiAsync(params)).then(unwrapResult);
            if(data){
                history.push("/campaignIndex");
            }
        } catch (error) {
            
        }finally{
            setLoading(false);
        }
    }
    const uploadExcelFile=async(file)=>{
        const formData=new FormData();
        formData.append("file",file);
        const params = {
            file: formData,
            token: auth?.payload?.token
        }
        try {
            const res=await dispatch(excelFileUploadApiAsync(params)).then(unwrapResult);
            return res?.payload?.reference_id;

        } catch (error) {
            return null;
        }

    }
    const validFields=(formData)=>{
        let {content,budget,images,duration,contactList,notifications_count,sms_count,service_id,category_id,sub_category_id,brand_id, product_id,start_date,end_date, serviceType,...rest}=formData;
        let fields={...rest};
        if(formData.promotion_type=="top_banner")fields.images=images;
        else fields.content=content;
        if(formData.campaign_for=="product"||formData.campaign_for=="store"){
            // if(formData.campaign_for=="product")
            fields[(formData.serviceType=="product"&&formData.campaign_for=="product")?"product_id":"service_id"]=service_id;
            fields.category_id=category_id;
            fields.sub_category_id=sub_category_id;
            fields.duration=duration;
            fields.start_date=start_date;
            fields.end_date=end_date;
           if(formData.campaign_for=="product") fields.brand_id=brand_id;
        }else if(formData.campaign_for=="sms"){
            fields.sms_count=sms_count;
            fields.contactList=contactList;
            delete fields.location;
            delete fields.city;
            delete fields.postal_code;
            delete fields.radius;
            delete fields.promotion_type;
        }else {
            fields.notifications_count=notifications_count;
            delete fields.promotion_type;
        }
        return fields;
    }
    let obj={
        campaign_for:"",
        promotion_type:"",
        content:"",
        images:"",
        min_age:"",
        max_age:"",
        location:""
    }

   
    const uploadImage = async(images) => {
        let formData = new FormData();
        images.forEach((val, index) => {
            formData.append(`file`, val);
        });
        // formData.append(`file`, image);
        let params = {
            file: formData,
            token: auth?.payload?.token
        }
     try {
        let obj=await   dispatch(imageUploadAsync(params))
            .then(unwrapResult);
        let url=obj?.payload?.map(file=>file?.filePath)||[];
        return url;
            
     } catch (error) {
        return null;
     }

    }
    const RenderComponent=()=>{
        switch(activeStep){
            case 1:return (
            <CampainDetails
                setFormData={ setFormData}
                formData={formData}
                showError={showError}
                id={id}
            />)
            case 2:return (
            <MakePayment
                setFormData={setFormData}
                formData={formData}
            />)
            default: return <div>Unknown Step</div>
        }
    }
    const addFieldsData=(campaign)=>{
        setFormData(prev=>({
            ...prev,...campaign,
            end_date:campaign?.end_date?new Date(campaign?.end_date):"",
            start_date:campaign?.start_date?new Date(campaign?.start_date):"",

        }))
    }
    const updateDetails=async()=>{
        let fields=validFields(formData);
        let isFiledEmpty=checkIfFieldEmpty(fields);
        if(isFiledEmpty){
            setShowError(true);
            toast.error(`${isFiledEmpty} is required`);
            return;
        }
        setIsUpdating(true)
        let obj={
            name:fields.name,
            promotion_type:fields.promotion_type,
            content:fields.content,
            images:fields.images,
            min_age:fields.min_age,
            location:fields.location,
            radius:fields.radius,
            city:fields.city,
            latitude:fields.latitude,
            longitude:fields.longitude
        }
        console.log(obj)
        const ValidFields={};
        Object.keys(obj).map((key)=>{
            if(obj[key]){
                ValidFields[key]=obj[key];
            }
        })
        let params={
            postData:{
                ...ValidFields,
                id:campaign?.id

            },
            tokenData:auth?.payload?.token
        }
      let data=await  updateCampaignDetail(params);
      if(data){
        history.push("/campaignIndex");
      }
      setIsUpdating(false)

    }
    const updateCampaignDetail=async(params)=>{
            try {
                const data=await dispatch(updateCampaignApiAsync(params)).then(unwrapResult);
                return data;
                
            } catch (error) {
                return null;
            }
    
        }
    return (
        <div className='createCampaign jbrTransfer'>
            <div className='chartsOuter jbrHeight mt-0'>
                <div className='shipprofileHead_'>
                    <h2 onClick={()=>{
                        if(loading)return;
                        history.push("/campaignIndex")
                    }} className='Innerhead'><img className='arrowicon_' src={Images.leftArrowColored} /> {id?t("updateCampaign"):t("createNewCampaign")}  </h2>
                    <div className='right_shippingbtn_'>
                        <button disabled={loading} onClick={handleSteps} className='discardBtn_' type='button'>{t('discard')}</button>
                      {id?<>
                        <button disabled={loading} onClick={updateDetails} className='addNew_' type='button' >
                        {isUpdating && (
                            <div
                                className="text-primary spinner-border"
                                role="status"
                                aria-hidden="true"
                            ></div>
                        )}
                            {t('update')}</button>
                      </>:  <button disabled={loading} onClick={handleNextSteps} className='addNew_' type='button' >
                        {loading && (
                            <div
                                className="text-primary spinner-border"
                                role="status"
                                aria-hidden="true"
                            ></div>
                        )}
                            {activeStep==1?t("next"):id?t('updateCampaign'):t('createCampaign')}</button>}
                    </div>
                </div>
                <div className='createPageInner_ mt-5'>
                {!id&& <div className='row mb-5'>
                    <div className="campaignSteps_ stepperParent">
                        <Stepper
                          activeStep={activeStep}
                          onStepChange={handleStepChange}
                          steps={[
                            {
                              label: t('campaign'),
                            },
                            { label:id?"Preview": t('makePayment') },
                          ]}
                          disabledColor="#E4EBF1"
                          activeColor="red !important"
                        />
                      </div>
                    </div>
                        }
                  {RenderComponent()}
                </div>
            </div>
        </div>

    )
}

export default CreateCampaign